import React, { useState, useEffect } from 'react';
import TitleLabel from "../../CommonComponent/TitleLabel";
import axios from 'axios';
import Paper from "@mui/material/Paper";
import TextField from '@mui/material/TextField';
import Autocomplete from "@mui/material/Autocomplete";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import Loader from '../../CommonComponent/Loader';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import TimePicker from '@mui/lab/TimePicker';
import { DatePicker } from '@mui/lab';
import moment from 'moment';
import { getTimeTableSlotForEditAPIURL, saveTimeTbaleSlotAPIURL, updateTimePeriodTableSlotDataAPIURL, updateTimeSlotDataAPIURL } from '../Services/ManageTimeSlotAPIURL';
import Tooltip from '@mui/material/Tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faSave } from '@fortawesome/free-solid-svg-icons';
import { useNavigate, useParams } from 'react-router-dom';
import EditableTimeTableRow from './TimeSlotEditableForms/EditableTimeTableRow';
import ReadOnlyTimeTableRow from './TimeSlotEditableForms/ReadOnlyTimeTableRow';
import TimeSlotUpdationModal from './TimeTableModal/TimeSlotUpdationModal';

const weekData = [
    {
        id: 1,
        weekName: 'Monday'
    },
    {
        id: 2,
        weekName: 'Tuesday'
    },
    {
        id: 3,
        weekName: 'Wednsday'
    },
    {
        id: 4,
        weekName: 'Thursday'
    },
    {
        id: 5,
        weekName: 'Friday'
    },
    {
        id: 6,
        weekName: 'Saturday'
    },

]

const ManageTimeSlot = () => {
    const authUser = useSelector((state) => state.user.value);
    const titleId = "Manage Time Slot";
    const [loaderOption, setLoaderOption] = useState(false);
    let { mtsNo, EditFlag } = useParams();
    const navigate = useNavigate();

    //Note: This below states are declared for Save & Update(Disabled/unDisabled)
    const saveBtnStyle = "TimeSlotSaveBtn btn btn-primary btn-sm";
    const updateBtnStyle = "TimeSlotUpdateBtn btn btn-primary btn-sm";
    const displayNoneBtnStyle = "d-none";
    const [saveBtn, setSaveBtn] = useState(saveBtnStyle);
    const [updateBtn, setUpdateBtn] = useState(displayNoneBtnStyle);

    const [saveBtnDisabled, setSaveBtnDisabled] = useState(false);
    const [updateBtnDisabled, setUpdateBtnDisabled] = useState(false);

    const [time, setTime] = useState(null);
    const [times, setTimes] = useState(null);
    // console.log(time);


    const handleTimeChange = (newValue) => {
        setTime(newValue);
        if (newValue != null) {
            const hours = String(newValue.getHours()).padStart(2, "0");
            const minutes = String(newValue.getMinutes()).padStart(2, "0");
            const seconds = String(newValue.getSeconds()).padStart(2, "0");
            const times = `${hours}:${minutes}:${seconds}`;
            setTimes(times);
        }

    };

    const [scheduleDate, setScheduleDate] = useState(null);
    // console.log(scheduleDate)
    const [id, setId] = useState('');
    const [noOfPeriods, setNoOfPeriods] = useState('');
    const [periodInMin, setPeriodInMin] = useState('');
    const [noOfRecess, setNoOfRecess] = useState('');
    const [weekObj, setWeekObj] = useState([]);
    const [weekId, setWeekId] = useState([]);
    // console.log(JSON.stringify(weekId))
    const [recessTimes, setRecessTimes] = useState([]);
    const [afterPeriods, setAfterPeriods] = useState([]);
    // console.log(recessTimes + "===" + afterPeriods)

    const [recessTimes1, setRecessTimes1] = useState(0);
    const [recessTimes2, setRecessTimes2] = useState(0);
    const [recessTimes3, setRecessTimes3] = useState(0);
    const [recessTimes4, setRecessTimes4] = useState(0);
    const [recessTimes5, setRecessTimes5] = useState(0);

    const [afterPeriods1, setAfterPeriods1] = useState(0);
    const [afterPeriods2, setAfterPeriods2] = useState(0);
    const [afterPeriods3, setAfterPeriods3] = useState(0);
    const [afterPeriods4, setAfterPeriods4] = useState(0);
    const [afterPeriods5, setAfterPeriods5] = useState(0);

    const [savedTimeSlot, setSavedTimeSlot] = useState([]);

    const [editId, setEditId] = useState(null);

    const [editFormData, setEditFormData] = useState({
        index: "",
        id: "",
        timeTableSlotId: "",
        toTime: ""
    });

    const [deleteId, setDeleteId] = useState(0);
    const [showModal, setShowModal] = useState(1);

    const handleEditClick = (event, timeTable, index) => {
        event.preventDefault();
        setEditId(timeTable.id);
        const formValues = {
            index: index,
            id: timeTable.id,
            timeTableSlotId: mtsNo,
            fromTime: timeTable.fromTime,
            toTime: moment(timeTable.toTime, 'HH:mm:ss').format('HH:mm')
        };

        setEditFormData(formValues);
    };

    const handleCancelClick = () => {
        //console.log("handleCancelClick");
        setEditId(null);
    };

    const handleEditFormChange = (event) => {
        event.preventDefault();

        const fieldName = event.target.getAttribute("name");
        const fieldValue = event.target.value;
        // console.log(fieldValue)
        const newFormData = { ...editFormData };
        newFormData[fieldName] = fieldValue;
        // console.log(JSON.stringify(newFormData))
        setEditFormData(newFormData);
    };

    const updateTimeTableData = (e) => {
        // e.preventDefault();
        setLoaderOption(true);
        setSavedTimeSlot([]);
        // console.log(JSON.stringify(editFormData))
        axios.put(updateTimePeriodTableSlotDataAPIURL, editFormData)
            .then(res => {
                // console.log(JSON.stringify(res.data));
                let recessCount = 1;
                res.data.forEach(detail => {
                    if (detail.isRecess === 1) {
                        detail.recessLabel = `Recess ${recessCount}`;
                        recessCount++;
                    } else {
                        detail.recessLabel = '';
                    }
                });
                setSavedTimeSlot(res.data);
                setEditId(null);

                setLoaderOption(false);
                toast.success("Time period updated successfully.")
            }).catch(err => {
                console.log('Update Timing period table err' + err);
                setLoaderOption(false);
                toast.error("Something went wrong, please check.")
            })
    }



    // console.log("recess time=======" + recessTimes1,
    //     recessTimes2,
    //     recessTimes3,
    //     recessTimes4,
    //     recessTimes5 + "==========After period======" + afterPeriods)

    const handleNoOfRecessChange = (e) => {
        setRecessTimes1(0);
        setRecessTimes2(0);
        setRecessTimes3(0);
        setRecessTimes4(0);
        setRecessTimes5(0);
        setAfterPeriods1(0);
        setAfterPeriods2(0);
        setAfterPeriods3(0);
        setAfterPeriods4(0);
        setAfterPeriods5(0);
        const value = parseInt(e.target.value);
        setNoOfRecess(value);

        if (!isNaN(value)) {
            const newRecessTimes = Array.from({ length: value }, () => '');
            const newAfterPeriods = Array.from({ length: value }, () => '');
            setRecessTimes(newRecessTimes);
            setAfterPeriods(newAfterPeriods);
        } else {
            setRecessTimes([]);
            setAfterPeriods([]);
        }
    };

    const handleRecessTimeChange = (index, e) => {
        const newRecessTimes = [...recessTimes];
        newRecessTimes[index] = e.target.value;
        // console.log(newRecessTimes[index])
        switch (index) {
            case 0:
                setRecessTimes1(e.target.value);
                break;
            case 1:
                setRecessTimes2(e.target.value);
                break;
            case 2:
                setRecessTimes3(e.target.value);
                break;
            case 3:
                setRecessTimes4(e.target.value);
                break;
            case 4:
                setRecessTimes5(e.target.value);
                break;
            default:
                break;
        }
        setRecessTimes(newRecessTimes);
    };

    const handleAfterPeriodChange = (index, e) => {
        const newAfterPeriods = [...afterPeriods];
        newAfterPeriods[index] = e.target.value;
        switch (index) {
            case 0:
                setAfterPeriods1(e.target.value);
                break;
            case 1:
                setAfterPeriods2(e.target.value);
                break;
            case 2:
                setAfterPeriods3(e.target.value);
                break;
            case 3:
                setAfterPeriods4(e.target.value);
                break;
            case 4:
                setAfterPeriods5(e.target.value);
                break;
            default:
                break;
        }
        setAfterPeriods(newAfterPeriods);
    };

    useEffect(() => {
        getData();
    }, [authUser])

    const getData = () => {
        if (EditFlag == 1) {
            setSaveBtnDisabled(false);
            setUpdateBtnDisabled(true);

        }
        else if (EditFlag == 2) {
            getEditDetails();
            setSaveBtnDisabled(true);
            setUpdateBtnDisabled(false);
        }
    }

    const getEditDetails = async () => {
        setLoaderOption(true);
        // console.log(`${getTimeTableSlotForEditAPIURL}?id=${mtsNo}`)
        axios.all([
            axios.get(`${getTimeTableSlotForEditAPIURL}?id=${mtsNo}`)
        ]).then(
            axios.spread((respOne) => {
                setSaveBtn(displayNoneBtnStyle);
                setUpdateBtn(updateBtnStyle);
                // console.log(JSON.stringify(respOne.data.deleteId));

                setDeleteId(respOne.data.deleteId);

                setId(respOne.data.timeTableSlot.id);
                setScheduleDate(respOne.data.timeTableSlot.scheduleDate);
                // console.log(moment(respOne.data.timeTableSlot.startTime, 'HH:mm:ss').toDate())
                setTime(moment(respOne.data.timeTableSlot.startTime, 'HH:mm:ss').toDate());
                setTimes(moment(respOne.data.timeTableSlot.startTime, 'HH:mm:ss').format('HH:mm'));
                setNoOfPeriods(respOne.data.timeTableSlot.periods);
                setPeriodInMin(respOne.data.timeTableSlot.periodTimeMin);
                // Set noOfRecess and corresponding recessTimes
                const numberOfRecess = respOne.data.timeTableSlot.noOfRecess;
                setNoOfRecess(numberOfRecess);
                const newRecessTimes = Array.from({ length: numberOfRecess }, (_, index) => respOne.data.timeTableSlot[`recessTime${index + 1}`]);
                setRecessTimes(newRecessTimes);
                const newAfterPeriods = Array.from({ length: numberOfRecess }, (_, index) => respOne.data.timeTableSlot[`recessAfterPeriod${index + 1}`]);
                setAfterPeriods(newAfterPeriods);

                setRecessTimes1(respOne.data.timeTableSlot.recessTime1)
                setRecessTimes2(respOne.data.timeTableSlot.recessTime2)
                setRecessTimes3(respOne.data.timeTableSlot.recessTime3)
                setRecessTimes4(respOne.data.timeTableSlot.recessTime4)
                setRecessTimes5(respOne.data.timeTableSlot.recessTime5)
                setAfterPeriods1(respOne.data.timeTableSlot.recessAfterPeriod1)
                setAfterPeriods2(respOne.data.timeTableSlot.recessAfterPeriod2)
                setAfterPeriods3(respOne.data.timeTableSlot.recessAfterPeriod3)
                setAfterPeriods4(respOne.data.timeTableSlot.recessAfterPeriod4)
                setAfterPeriods5(respOne.data.timeTableSlot.recessAfterPeriod5)


                setWeekObj(respOne.data.weekData);
                setWeekId(respOne.data.weekData.map(e => e.id).join(','))
                // console.log(respOne.data.weekData)
                // setSavedTimeSlot(respOne.data.timeTableSlotDetail);

                let recessCount = 1;
                respOne.data.timeTableSlotDetail.forEach(detail => {
                    if (detail.isRecess === 1) {
                        detail.recessLabel = `Recess ${recessCount}`;
                        recessCount++;
                    } else {
                        detail.recessLabel = '';
                    }
                });

                setSavedTimeSlot(respOne.data.timeTableSlotDetail);
                setLoaderOption(false);

            })
        )
    }

    // console.log(deleteId);
    const saveTimeSlot = async () => {
        // e.preventDefault();
        // setLoaderOption(true);
        if (moment(scheduleDate, "YYYY-MM-DD", true).isValid() && moment(time, "HH:mm", true).isValid()) {
            const saveObj = {
                "scheduleDate": moment(scheduleDate).format("YYYY-MM-DD"),
                "startTime": times,
                "periods": noOfPeriods,
                "periodTimeMin": periodInMin,
                "noOfRecess": noOfRecess,
                "recessTime1": recessTimes1,
                "recessTime2": recessTimes2,
                "recessTime3": recessTimes3,
                "recessTime4": recessTimes4,
                "recessTime5": recessTimes5,
                "recessAfterPeriod1": afterPeriods1,
                "recessAfterPeriod2": afterPeriods2,
                "recessAfterPeriod3": afterPeriods3,
                "recessAfterPeriod4": afterPeriods4,
                "recessAfterPeriod5": afterPeriods5,
                "sessionYear": authUser.sessionYear,
                "sectionId": authUser.deptId,
                "branchId": authUser.branchId,
                "weekDays": weekId
            }
            // console.log(JSON.stringify(saveObj))
            await axios.post(saveTimeTbaleSlotAPIURL, saveObj)
                .then(res => {
                    if (res.data != null) {
                        setLoaderOption(false);
                        // setScheduleDate(null);
                        // setTime(null);
                        // setNoOfPeriods('');
                        // setPeriodInMin('');
                        // setNoOfRecess('');
                        // setRecessTimes([]);
                        // setAfterPeriods([]);
                        // setWeekObj([]);
                        setSaveBtn(saveBtnStyle);
                        setUpdateBtn(displayNoneBtnStyle);
                        setSaveBtnDisabled(true);
                        // console.log(JSON.stringify(res.data))
                        let recessCount = 1;
                        res.data.forEach(detail => {
                            if (detail.isRecess === 1) {
                                detail.recessLabel = `Recess ${recessCount}`;
                                recessCount++;
                            } else {
                                detail.recessLabel = '';
                            }
                        });
                        setSavedTimeSlot(res.data);
                        toast.success("Time slot saved successfully")
                    }
                    else {
                        setLoaderOption(false);
                        toast.error("Time slot save failed.")
                    }
                }).catch(err => {
                    console.log("Time Table Slot err" + err)
                    setLoaderOption(false);
                    toast.error("Something went wrong, please check.")
                })
        }
        else {
            setLoaderOption(false);
            toast.error("Improper date or time, please check.")
        }

    }

    const updateTimeSlot = async () => {
        // e.preventDefault();
        // console.log("first update")
        if (deleteId == 0) {
            setLoaderOption(true);
            if (moment(scheduleDate, "YYYY-MM-DD", true).isValid() && moment(time, "HH:mm", true).isValid()) {
                const updateObj = {
                    "id": mtsNo,
                    "scheduleDate": moment(scheduleDate).format("YYYY-MM-DD"),
                    "startTime": times,
                    "periods": noOfPeriods,
                    "periodTimeMin": periodInMin,
                    "noOfRecess": noOfRecess,
                    "recessTime1": recessTimes1,
                    "recessTime2": recessTimes2,
                    "recessTime3": recessTimes3,
                    "recessTime4": recessTimes4,
                    "recessTime5": recessTimes5,
                    "recessAfterPeriod1": afterPeriods1,
                    "recessAfterPeriod2": afterPeriods2,
                    "recessAfterPeriod3": afterPeriods3,
                    "recessAfterPeriod4": afterPeriods4,
                    "recessAfterPeriod5": afterPeriods5,
                    "sessionYear": authUser.sessionYear,
                    "sectionId": authUser.deptId,
                    "branchId": authUser.branchId,
                    "weekDays": weekId,

                }
                // console.log(JSON.stringify(updateObj))
                await axios.put(`${updateTimeSlotDataAPIURL}?delete=${deleteId}`, updateObj)
                    .then(res => {
                        // console.log(res.data);
                        if (res.data == "DATAPRESENT") {
                            setLoaderOption(false);
                            setDeleteId(1);
                            setShowModal(0);
                        }
                        else if (res.data != null) {
                            setLoaderOption(false);
                            // setScheduleDate(null);
                            // setTime(null);
                            // setNoOfPeriods('');
                            // setPeriodInMin('');
                            // setNoOfRecess('');
                            // setRecessTimes([]);
                            // setAfterPeriods([]);
                            // setWeekObj([]);
                            setSaveBtn(displayNoneBtnStyle);
                            setUpdateBtn(updateBtnStyle);
                            // console.log(JSON.stringify(res.data))
                            setDeleteId(0);
                            setShowModal(0);
                            let recessCount = 1;
                            res.data.forEach(detail => {
                                if (detail.isRecess === 1) {
                                    detail.recessLabel = `Recess ${recessCount}`;
                                    recessCount++;
                                } else {
                                    detail.recessLabel = '';
                                }
                            });
                            setSavedTimeSlot(res.data);
                            toast.success("Time slot Updated successfully")
                        }
                        else {
                            setLoaderOption(false);
                            toast.error("Time slot update failed.")
                        }
                    }).catch(err => {
                        console.log("Time Table Slot err" + err)
                        setLoaderOption(false);
                        toast.error("Something went wrong, please check.")
                    })
            }
            else {
                setLoaderOption(false);
                toast.error("Improper date or time, please check.")
            }
        }

    }

    const updateDataForDataPresent = async () => {
        setLoaderOption(true);
        // console.log("second update")
        if (moment(scheduleDate, "YYYY-MM-DD", true).isValid() && moment(time, "HH:mm", true).isValid()) {
            const updateObj = {
                "id": mtsNo,
                "scheduleDate": moment(scheduleDate).format("YYYY-MM-DD"),
                "startTime": times,
                "periods": noOfPeriods,
                "periodTimeMin": periodInMin,
                "noOfRecess": noOfRecess,
                "recessTime1": recessTimes1,
                "recessTime2": recessTimes2,
                "recessTime3": recessTimes3,
                "recessTime4": recessTimes4,
                "recessTime5": recessTimes5,
                "recessAfterPeriod1": afterPeriods1,
                "recessAfterPeriod2": afterPeriods2,
                "recessAfterPeriod3": afterPeriods3,
                "recessAfterPeriod4": afterPeriods4,
                "recessAfterPeriod5": afterPeriods5,
                "sessionYear": authUser.sessionYear,
                "sectionId": authUser.deptId,
                "branchId": authUser.branchId,
                "weekDays": weekId,

            }
            // console.log(JSON.stringify(updateObj))
            await axios.put(`${updateTimeSlotDataAPIURL}?delete=${deleteId}`, updateObj)
                .then(res => {
                    if (res.data == "DATAPRESENT") {
                        setLoaderOption(false);
                        setDeleteId(1);
                        setShowModal(0);
                    }
                    else if (res.data != null) {
                        setLoaderOption(false);
                        // setScheduleDate(null);
                        // setTime(null);
                        // setNoOfPeriods('');
                        // setPeriodInMin('');
                        // setNoOfRecess('');
                        // setRecessTimes([]);
                        // setAfterPeriods([]);
                        // setWeekObj([]);
                        setSaveBtn(displayNoneBtnStyle);
                        setUpdateBtn(updateBtnStyle);
                        // console.log(JSON.stringify(res.data))
                        setDeleteId(0);
                        setShowModal(0);
                        let recessCount = 1;
                        res.data.forEach(detail => {
                            if (detail.isRecess === 1) {
                                detail.recessLabel = `Recess ${recessCount}`;
                                recessCount++;
                            } else {
                                detail.recessLabel = '';
                            }
                        });
                        setSavedTimeSlot(res.data);
                        toast.success("Time slot Updated successfully")
                    }
                    else {
                        setLoaderOption(false);
                        toast.error("Time slot update failed.")
                    }
                }).catch(err => {
                    console.log("Time Table Slot err" + err)
                    setLoaderOption(false);
                    toast.error("Something went wrong, please check.")
                })
        }
        else {
            setLoaderOption(false);
            toast.error("Improper date or time, please check.")
        }
    }

    const clearData = () => {
        setScheduleDate(null);
        setTime(null);
        setNoOfPeriods('');
        setPeriodInMin('');
        setNoOfRecess('');
        setRecessTimes([]);
        setAfterPeriods([]);
        setWeekObj([]);
        navigate('/Home/acd406/0')
    }

    const state = {
        button: 1
    };

    const onSubmit = e => {
        e.preventDefault();
        if (state.button === 1) {
            saveTimeSlot();
        }
        if (state.button === 2) {
            updateTimeSlot();
            // console.log('noOfRecess:', noOfRecess);
            // console.log('recessTimes:', JSON.stringify(recessTimes));
            // console.log('afterPeriods:', JSON.stringify(afterPeriods));
        }
        if (state.button === 3) {
            updateTimeTableData();
        }
    };

    const TableHeading = [
        { label: 'SrNo', key: 'id', textAlign: '', display: '' },
        { label: `Timing Of Period`, key: 'timeingOfPeriod', textAlign: 'center', display: '' },
        { label: 'Action', value: 'action', isAction: true, display: '' },
        // { label: 'Save', value: 'save', isAction: true, display: (EditFlag == 1) ? 'none' : '' },
    ];

    if (loaderOption == true) {
        return (
            <>
                <Loader />
            </>
        )
    }
    else {
        return (
            <>
                <TitleLabel titleId={titleId} />
                <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: "350px" }}>

                    <form onSubmit={onSubmit}>
                        <input type="hidden" value={id} onChange={(e) => setId(e.target.value)} name="" />
                        <div className="row">
                            <label htmlFor="lastname3" className="col-sm-2" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>Schedule Date :</label>
                            <div className='col-sm-2'>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        label="Schedule Date"
                                        value={scheduleDate}
                                        onChange={(newValue) => {
                                            setScheduleDate(newValue);

                                        }}
                                        onKeyPress={(e) => (e.key == "Enter") ? scheduleDate : ""}
                                        // readOnly={(EditFlag == 2 && authUser.roleId !== 3) ? true : false}
                                        inputFormat="dd/MM/yyyy"
                                        // inputProps={{ readOnly: (EditFlag == 2 && authUser.roleId !== 3) ? true : false }}
                                        renderInput={(params) =>
                                            <TextField
                                                {...params}
                                                variant="outlined"
                                                margin='dense'
                                                InputLabelProps={{ style: { fontSize: 14 } }}
                                                required
                                                fullWidth
                                                autoComplete='off'
                                                size="small" />}
                                    />
                                </LocalizationProvider>
                            </div>

                            <label htmlFor="lastname3" className="col-sm-2" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>Starting Time :</label>
                            <div className="col-sm-2">
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <TimePicker
                                        label="Time"
                                        value={time}
                                        onChange={handleTimeChange}
                                        ampm={false} // Set ampm prop to false to remove AM/PM indicator
                                        inputFormat="HH:mm"
                                        renderInput={(params) =>
                                            <TextField
                                                size='small'
                                                variant="outlined"
                                                margin='dense'
                                                InputLabelProps={{ style: { fontSize: 14 } }}
                                                required
                                                fullWidth
                                                autoComplete='off'
                                                {...params} />}

                                    />
                                </LocalizationProvider>
                            </div>
                        </div>

                        <div className="row my-2">
                            <label htmlFor="lastname3" className="col-sm-2" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>No. Of Periods :</label>
                            <div className='col-sm-2'>
                                <TextField
                                    required
                                    fullWidth
                                    autoComplete='off'
                                    size='small'
                                    type='number'
                                    id="outlined-basic"
                                    label="No. Of Periods"
                                    variant="outlined"
                                    margin='dense'
                                    value={noOfPeriods}
                                    InputProps={{ inputProps: { min: 1 } }}
                                    onChange={(e) => setNoOfPeriods(e.target.value)}
                                    onKeyPress={(e) => (e.key == "Enter") ? noOfPeriods : ""}

                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                />
                            </div>

                            <label htmlFor="lastname3" className="col-sm-2" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>Period in min. :</label>
                            <div className='col-sm-2'>
                                <TextField
                                    required
                                    fullWidth
                                    autoComplete='off'
                                    size='small'
                                    type='number'
                                    id="outlined-basic"
                                    label="Period in min."
                                    variant="outlined"
                                    margin='dense'
                                    value={periodInMin}
                                    onChange={(e) => setPeriodInMin(e.target.value)}
                                    onKeyPress={(e) => (e.key == "Enter") ? periodInMin : ""}

                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                />
                            </div>
                        </div>

                        <div className="row my-2">
                            <label htmlFor="lastname3" className="col-sm-2" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>No. Of Recess :</label>
                            <div className='col-sm-2'>
                                <TextField
                                    required
                                    type='number'
                                    fullWidth
                                    autoComplete='off'
                                    size='small'
                                    id="outlined-basic"
                                    label="No. Of Recess"
                                    variant="outlined"
                                    margin='dense'
                                    value={noOfRecess}
                                    onChange={handleNoOfRecessChange}
                                    onKeyPress={(e) => (e.key == "Enter") ? noOfRecess : ""}

                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                />
                            </div>

                            <label htmlFor="lastname3" className="col-sm-2" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>Week Days :</label>
                            <div className='col-sm-4'>
                                <Autocomplete
                                    id="combo-box-demo"
                                    options={weekData}
                                    multiple
                                    //filterSelectedOptions
                                    disableCloseOnSelect
                                    PaperComponent={({ children }) => (
                                        <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                    )}
                                    onChange={(event, newValue) => {
                                        setWeekObj(newValue);
                                        const sortedValues = newValue.sort((a, b) => a.id - b.id); // Sort by ID
                                        setWeekId(sortedValues.map(e => e.id).join(','))
                                    }}
                                    value={weekObj}
                                    onKeyPress={(e) => (e.key == "Enter") ? weekObj : ""}
                                    style={{ fontSize: '14px' }}
                                    getOptionLabel={option => option.weekName}
                                    size="small"
                                    renderInput={params => (
                                        <TextField {...params} margin='dense' label="Select Week Day" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }}
                                            required={(weekObj.length == 0) ? true : false}
                                        />
                                    )}
                                />
                            </div>
                        </div>

                        <div className="row my-2">
                            <label htmlFor="recess-time" className="col-sm-2" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px", display: `${(recessTimes.length == 0) ? 'none' : 'block'}` }}>Recess Time :</label>

                            {recessTimes.map((value, index) => (

                                <>
                                    <div className='' style={{ width: '150px' }}>
                                        <TextField
                                            required={(noOfRecess == 0 || noOfRecess == '') ? false : true}
                                            fullWidth
                                            autoComplete='off'
                                            size='small'
                                            type='number'
                                            id={`recess-time-${index}`}
                                            label={`RT ${index + 1}`}
                                            variant="outlined"
                                            margin='dense'
                                            value={value}
                                            onChange={(e) => handleRecessTimeChange(index, e)}
                                            InputLabelProps={{ style: { fontSize: 14 } }}
                                        />
                                    </div>

                                </>

                            ))}
                        </div>

                        <div className="row my-2">
                            <label htmlFor="after-period" className="col-sm-2" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px", display: `${(recessTimes.length == 0) ? 'none' : 'block'}` }}>After Period :</label>
                            {recessTimes.map((value, index) => (

                                <>
                                    <div className='' style={{ width: '150px' }}>
                                        <TextField
                                            required={(noOfRecess == 0 || noOfRecess == '') ? false : true}
                                            fullWidth
                                            autoComplete='off'
                                            size='small'
                                            type='number'
                                            InputProps={{ inputProps: { max: noOfPeriods } }}
                                            id={`after-period-${index}`}
                                            label={`AP ${index + 1}`}
                                            variant="outlined"
                                            margin='dense'
                                            value={afterPeriods[index]}
                                            onChange={(e) => handleAfterPeriodChange(index, e)}
                                            InputLabelProps={{ style: { fontSize: 14 } }}
                                        />
                                    </div>
                                </>

                            ))}
                        </div>

                        <div className="row mt-3">
                            <div className="col-sm-12 offset-sm-2">
                                <button type="submit" onClick={() => (state.button = 1)} disabled={saveBtnDisabled} className={saveBtn}>Save</button>
                                <button type="submit" onClick={() => (state.button = 2)} disabled={updateBtnDisabled} className={updateBtn}
                                    {...(deleteId === 1 && scheduleDate != null && time != null
                                        && (noOfPeriods != '' || noOfPeriods > 0) && (noOfRecess == 0 || noOfRecess != '') && periodInMin != ''
                                        && weekObj.length != 0 &&
                                        afterPeriods.every((val) => val <= noOfPeriods) &&
                                        recessTimes.every((val) => val !== '') &&
                                        afterPeriods.every((val) => val !== '')

                                        ? { 'data-bs-toggle': 'modal', 'data-bs-target': '#UpdateTimeTableModal' } : {})} >Update</button>
                                <button type="button" onClick={() => clearData()} className="btn btn-danger btn-sm" style={{ margin: '0px 4px' }} >Cancel</button>
                            </div>
                        </div>

                        {
                            (savedTimeSlot.length != 0)
                                ?
                                <div className="row mt-4">
                                    <div className="col-sm-8 offset-sm-2">
                                        <div className="table-responsive">

                                            <table className="table table-bordered">
                                                <thead className="table-Default" style={{ backgroundColor: '#CCD1D1' }}>
                                                    <tr>

                                                        {TableHeading.map((item, index) => {
                                                            return (
                                                                <th style={{ fontSize: "14px", fontWeight: "600", textAlign: `${item.textAlign}`, display: `${item.display}` }} key={index} scope="col">{item.label}</th>
                                                            )
                                                        })}

                                                    </tr>
                                                </thead>
                                                <tbody style={{ borderTop: "2px solid #464de4", borderBottom: "2px solid #464de4" }}>
                                                    {
                                                        savedTimeSlot.map((item, index) => {
                                                            return (
                                                                // <tr key={index}>
                                                                //     <td style={{ fontSize: "14px", fontWeight: "500" }}>{index + 1}</td>
                                                                //     <td style={{ fontSize: "14px", fontWeight: "500", textAlign: 'center' }}>{item.fromTime + " == " + item.toTime}</td>

                                                                //     <td>
                                                                //         <Tooltip title="Edit">
                                                                //             <span className='btn btn-primary btn-sm mx-1 p-1'
                                                                //                 data-bs-toggle="tooltip" >
                                                                //                 <FontAwesomeIcon icon={faEdit} />
                                                                //             </span>
                                                                //         </Tooltip>
                                                                //     </td>
                                                                //     <td>
                                                                //         <Tooltip title="Save">
                                                                //             <span className='btn btn-sm mx-1 p-1' style={{ backgroundColor: "#27AE60", color: "white" }}
                                                                //                 data-bs-toggle="tooltip" >
                                                                //                 <FontAwesomeIcon icon={faSave} />
                                                                //             </span>
                                                                //         </Tooltip>
                                                                //     </td>
                                                                // </tr>
                                                                <>
                                                                    {
                                                                        editId === item.id ? (
                                                                            <EditableTimeTableRow
                                                                                editFormData={editFormData}
                                                                                handleCancelClick={handleCancelClick}
                                                                                handleEditFormChange={handleEditFormChange}
                                                                                state={state}
                                                                                index={index}
                                                                                isRecess={item.isRecess}
                                                                                recessLabel={item.recessLabel}
                                                                            />
                                                                        ) : (
                                                                            <ReadOnlyTimeTableRow
                                                                                item={item}
                                                                                index={index}
                                                                                handleEditClick={handleEditClick}
                                                                            />
                                                                        )
                                                                    }
                                                                </>
                                                            )
                                                        })
                                                    }
                                                </tbody>

                                            </table>

                                        </div>
                                    </div>
                                </div>
                                :
                                ''
                        }
                    </form>
                </div>

                <TimeSlotUpdationModal updateDataForDataPresent={updateDataForDataPresent} setDeleteId={setDeleteId} deleteId={deleteId} />
            </>
        )
    }
}

export default ManageTimeSlot
