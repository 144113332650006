import React, { useState, useEffect } from 'react'
import TitleLabel from '../../../CommonComponent/TitleLabel'
import { useSelector } from 'react-redux';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Paper from '@mui/material/Paper';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { useNavigate } from 'react-router-dom';
import Loader from '../../../CommonComponent/Loader';
import makeStyles from "@mui/styles/makeStyles";
import { useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import { getRegisterTypeDataAPIURL } from '../../LibraryServices/RegisterTypeMasterAPIURL';
import { getTitleMasterData } from '../../LibraryServices/TitleMasterAPIURL';
import axios from 'axios';
import {
    getLocationDataAPIURL, getSourceDataAPIURL, getCurrencyDataAPIURL, getAuthorPublisherByTitle,
    getBindingDataAPIURL, getDepartmentDataAPIURL, getLanguageDataAPIURL, getSubjectDataAPIURL,
    getAccessionNoAPIURL, saveAccessionEntryAPIURL, getAccessionRegisterDataAPIURL,
    updateAccessionEntryDataAPIURL, getAccessiondataByidAPIURL, updateBookstatusAPIURL
} from '../Services/AccessionEntryAPIURL';
import { getVendorMaster } from '../../LibraryServices/VendorMasterAPIURL';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import AddNewTitleMasterModal from '../../CommonComponents/AddNewTitleMasterModal';
import AddNewDepartmentModal from '../../CommonComponents/AddNewDepartmentModal';
import AddNewSourceModal from '../../CommonComponents/AddNewSourceModal';
import AddNewLocationModal from '../../CommonComponents/AddNewLocationModal';
import AddNewBindingTypeModal from '../../CommonComponents/AddNewBindingTypeModal';
import AddNewCurrencyModal from '../../CommonComponents/AddNewCurrencyModal';
import AddNewVendorModal from '../../CommonComponents/AddNewVendorModal';
import moment from 'moment';
import { Modal, Button } from "react-bootstrap";
import Switch from '@mui/material/Switch';
import AddNewLanguageModal from '../../../CommonComponent/AddNewLanguageModal';
import { getSubBudgetMasterDataAPIURL } from '../../LibraryServices/SubBudgetTypeMasterAPIURL';


const useStyles = makeStyles(theme => ({
    select: {
        maxHeight: 200,
        "& ul": {
            backgroundColor: "#F4ECF7",
            //maxHeight: 160,
        },
        "& li": {
            // backgroundColor: "#F4ECF7", fontWeight: "550", fontSize: "14px" 
            fontSize: "14px",
            fontWeight: "550"
        },
    },

}));

const AccessionEntry = () => {
    const classes = useStyles();
    const authUser = useSelector((state) => state.user.value);
    const navigate = useNavigate();

    const { acId, EditFlag } = useParams();

    const [titleId, setTitleId] = useState('');

    const [loaderOption, setLoaderOption] = useState(false);

    //Note: This below states are declared for Save & Update(Disabled/unDisabled)
    const saveBtnStyle = "accEntryLibrarySaveBtn btn btn-primary btn-sm";
    const updateBtnStyle = "accEntryLibraryUpdateBtn btn btn-primary btn-sm";
    const displayNoneBtnStyle = "d-none";
    const [saveBtn, setSaveBtn] = useState(saveBtnStyle);
    const [updateBtn, setUpdateBtn] = useState(displayNoneBtnStyle);

    const [saveBtnDisabled, setSaveBtnDisabled] = useState(false);
    const [updateBtnDisabled, setUpdateBtnDisabled] = useState(false);

    const [id, setId] = useState('');
    const [accNo, setAccNo] = useState('');
    const [registerData, setRegisterData] = useState([]);
    const [registerObj, setRegisterObj] = useState(null);
    const [titleData, setTitleData] = useState([]);
    const [titleObj, setTitleObj] = useState(null);
    const [sourceData, setSourceData] = useState([]);
    const [sourceObj, setSourceObj] = useState(null);
    const [subBudgetTypeData, setSubBudgetTypeData] = useState([]);
    const [subBudgetTypeObj, setSubBudgetTypeObj] = useState(null);
    const [author, setAuthor] = useState("");
    const [publisher, setPublisher] = useState("");
    const [quantity, setQuantity] = useState("");
    const [year, setYear] = useState(null);
    const [edition, setEdition] = useState("");
    const [volume, setVolume] = useState("");
    const [locationData, setLocationData] = useState([]);
    const [locationObj, setLocationObj] = useState(null);
    const [subjectData, setSubjectData] = useState([]);
    const [subjectObj, setSubjectObj] = useState(null);
    const [bindingTypeData, setBindingTypeData] = useState([]);
    const [bindingTypeObj, setBindingTypeObj] = useState(null);
    const [callNo, setCallNo] = useState("");
    const [authShortName, setAuthShortName] = useState("");
    const [webLink, setwebLink] = useState("");
    const [remark, setRemark] = useState("");
    const [vendorData, setVendorData] = useState([]);
    const [vendorObj, setVendorObj] = useState(null);
    const [accDate, setAccDate] = useState(null);
    const [currencyData, setCurrencyData] = useState([]);
    const [currencyObj, setCurrencyObj] = useState(null);
    const [departmentData, setDepartmentData] = useState([]);
    const [departmentObj, setDepartmentObj] = useState(null);
    const [bookPrize, setBookPrize] = useState('');
    const [netAmt, setNetAmt] = useState('');
    const [billNo, setBillNo] = useState('');
    const [billDate, setBillDate] = useState(null);
    const [ISBNno, setISBNno] = useState('');
    const [bookPage, setBookPage] = useState('');
    const [size, setSize] = useState('');
    const [languageData, setLanguageData] = useState([]);
    const [languageObj, setLanguageObj] = useState(null);
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);

    const [bookStatus, setBookStatus] = useState(0);

    //console.log(year);

    const [showModalByQuantity, setShowModalByQuantity] = useState(false);
    const [showTitleModal, setShowTitleModal] = useState(false);
    const [showDepartmentModal, setShowDepartmentModal] = useState(false);
    const [showSourceModal, setShowSourceModal] = useState(false);
    const [showCurrencyModal, setShowCurrencyModal] = useState(false);
    const [showBindingTypeModal, setShowBindingTypeModal] = useState(false);
    const [showLocationModal, setShowLocationModal] = useState(false);
    const [showVendorModal, setShowVendorModal] = useState(false);
    const [showLanguageModal, setShowLanguageModal] = useState(false);

    const getNetAmountByQuantityPrize = (prize) => {
        if (prize != '' || quantity != '') {
            let netAmount = parseFloat(quantity) * parseFloat(prize)
            setNetAmt(parseFloat(netAmount).toFixed(2));
        }
    }

    useEffect(() => {
        getData();
    }, [authUser])

    const getData = () => {
        if (EditFlag == 1) {
            getApiData();
            setTitleId("Accession Entry");
            setSaveBtnDisabled(false);
            setUpdateBtnDisabled(true);
        }
        else if (EditFlag == 2) {
            getEditDetails();
            setTitleId("Update Accession Entry")
            setSaveBtnDisabled(true);
            setUpdateBtnDisabled(false);
        }
    }

    const getEditDetails = async () => {
        setLoaderOption(true);
        // console.log(`${getAccessiondataByidAPIURL}?id=${acId}`)
        await axios.get(`${getAccessiondataByidAPIURL}?id=${acId}`)
            .then(res => {
                setSaveBtn(displayNoneBtnStyle);
                setUpdateBtn(updateBtnStyle);

                const registerTypeId = res.data.registerTypeId
                const titleId = res.data.titleId
                const sourceId = res.data.sourceId
                const locationId = res.data.locationId
                const subjectId = res.data.subjectId
                const bindingTypeId = res.data.bindingTypeId
                const vendorId = res.data.vendorId
                const currencyId = res.data.currencyId
                const departmentId = res.data.departmentId
                const languageId = res.data.languageId
                const subBudgetTypeId = res.data.subBudgetTypeId
                const branchedId = res.data.branchId

                setId(res.data.id);
                setAccNo(res.data.accNo);
                setBookStatus(res.data.status);
                setQuantity(res.data.quantity);
                setYear(res.data.year);
                setEdition(res.data.edition);
                setVolume(res.data.volume);
                setCallNo(res.data.callNo);
                setAuthShortName(res.data.authorShortName);
                setwebLink(res.data.webLink);
                setRemark(res.data.remark);
                setAccDate(res.data.accDate);
                setBookPrize(res.data.prize);
                setNetAmt(res.data.netAmt);
                setBillNo(res.data.billNo);
                setBillDate(res.data.billDate);
                setISBNno(res.data.iSBNNo);
                setBookPage(res.data.page);
                setSize(res.data.size);

                axios.get(`${getAuthorPublisherByTitle}?id=${titleId}`)
                    .then(res => {
                        setAuthor(res.data.authorName);
                        setPublisher(res.data.publisherName);
                    })

                axios.get(`${getRegisterTypeDataAPIURL}?branchId=${branchedId}`)
                    .then(res => {
                        setRegisterData(res.data);
                        setRegisterObj((res.data).find(e => e.id == registerTypeId));
                    })

                axios.get(`${getTitleMasterData}?branchId=${branchedId}`)
                    .then(res => {
                        setTitleData(res.data);
                        setTitleObj((res.data).find(e => e.id == titleId));
                    })

                axios.get(`${getLocationDataAPIURL}?branchId=${branchedId}`)
                    .then(res => {
                        setLocationData(res.data);
                        setLocationObj((res.data).find(e => e.id == locationId));
                    })

                axios.get(`${getSourceDataAPIURL}?branchId=${branchedId}`)
                    .then(res => {
                        setSourceData(res.data);
                        setSourceObj((res.data).find(e => e.id == sourceId));
                    })

                axios.get(`${getSubBudgetMasterDataAPIURL}?branchId=${authUser.branchId}`)
                    .then(res => {
                        setSubBudgetTypeData(res.data);
                        setSubBudgetTypeObj((res.data).find(e => e.id == subBudgetTypeId));
                    })

                axios.get(`${getDepartmentDataAPIURL}?branchId=${branchedId}`)
                    .then(res => {
                        setDepartmentData(res.data);
                        setDepartmentObj((res.data).find(e => e.id == departmentId));
                    })

                axios.get(`${getCurrencyDataAPIURL}?branchId=${branchedId}`)
                    .then(res => {
                        setCurrencyData(res.data);
                        setCurrencyObj((res.data).find(e => e.id == currencyId));
                    })

                axios.get(`${getBindingDataAPIURL}?branchId=${branchedId}`)
                    .then(res => {
                        setBindingTypeData(res.data);
                        setBindingTypeObj((res.data).find(e => e.id == bindingTypeId));
                    })

                axios.get(`${getVendorMaster}?branchId=${branchedId}`)
                    .then(res => {
                        setVendorData(res.data);
                        setVendorObj((res.data).find(e => e.id == vendorId));
                    })

                axios.get(`${getLanguageDataAPIURL}`)
                    .then(res => {
                        setLanguageData(res.data);
                        setLanguageObj((res.data).find(e => e.id == languageId));
                    })

                axios.get(`${getSubjectDataAPIURL}`)
                    .then(res => {
                        setSubjectData(res.data);
                        setSubjectObj((res.data).find(e => e.id == subjectId));
                        setLoaderOption(false);
                    })

            })
    }

    const getApiData = async () => {

        if (authUser.branchId != null) {

            await axios.get(`${getRegisterTypeDataAPIURL}?branchId=${authUser.branchId}`)
                .then(res => {
                    setRegisterData(res.data);
                })

            await axios.get(`${getTitleMasterData}?branchId=${authUser.branchId}`)
                .then(res => {
                    setTitleData(res.data);
                })

            await axios.get(`${getLocationDataAPIURL}?branchId=${authUser.branchId}`)
                .then(res => {
                    setLocationData(res.data);
                })

            await axios.get(`${getSourceDataAPIURL}?branchId=${authUser.branchId}`)
                .then(res => {
                    setSourceData(res.data);
                })

            await axios.get(`${getDepartmentDataAPIURL}?branchId=${authUser.branchId}`)
                .then(res => {
                    setDepartmentData(res.data);
                })

            await axios.get(`${getCurrencyDataAPIURL}?branchId=${authUser.branchId}`)
                .then(res => {
                    setCurrencyData(res.data);
                })

            await axios.get(`${getBindingDataAPIURL}?branchId=${authUser.branchId}`)
                .then(res => {
                    setBindingTypeData(res.data);
                })

            await axios.get(`${getVendorMaster}?branchId=${authUser.branchId}`)
                .then(res => {
                    setVendorData(res.data);
                })

            await axios.get(`${getSubBudgetMasterDataAPIURL}?branchId=${authUser.branchId}`)
                .then(res => {
                    setSubBudgetTypeData(res.data);
                })

            await axios.get(`${getAccessionRegisterDataAPIURL}?branchId=${authUser.branchId}`)
                .then(res => {
                    setData(res.data);
                    setFilteredData(res.data);
                })
        }

        await axios.get(`${getLanguageDataAPIURL}`)
            .then(res => {
                setLanguageData(res.data);
            })

        await axios.get(`${getSubjectDataAPIURL}`)
            .then(res => {
                setSubjectData(res.data);
            })

    }

    const getAccNoByRegisterTypeId = async (rId) => {
        await axios.get(`${getAccessionNoAPIURL}?registerTypeId=${rId}&branchId=${authUser.branchId}`)
            .then(res => {
                setAccNo(res.data);
            })
    }

    const getAuthorPublisher = async (tId) => {
        await axios.get(`${getAuthorPublisherByTitle}?id=${tId}`)
            .then(res => {
                setAuthor(res.data.authorName);
                setPublisher(res.data.publisherName);
            })
    }

    const saveBtnClick = async () => {
        setLoaderOption(true);
        if (moment(accDate).format("YYYY-MM-DD") != "Invalid date" && moment(billDate).format("YYYY-MM-DD") != "Invalid date") {
            const saveObj = {
                "registerTypeId": registerObj.id,
                "accNo": accNo,
                "quantity": quantity,
                "titleId": titleObj.id,
                "edition": edition,
                "year": moment(year).format("YYYY"),
                "volume": volume,
                "departmentId": departmentObj.id,
                "sourceId": sourceObj.id,
                "locationId": locationObj.id,
                "callNo": callNo,
                "authorShortName": authShortName,
                "bindingTypeId": bindingTypeObj.id,
                "subjectId": subjectObj.id,
                "webLink": webLink,
                "remark": remark,
                "vendorId": vendorObj.id,
                "accDate": moment(accDate).format("YYYY-MM-DD"),
                "currencyId": currencyObj.id,
                "prize": bookPrize,
                "netAmt": netAmt,
                "billNo": billNo,
                "billDate": moment(billDate).format("YYYY-MM-DD"),
                "iSBNNo": ISBNno,
                "page": bookPage,
                "size": size,
                "languageId": languageObj.id,
                "subBudgetTypeId": subBudgetTypeObj.id,
                "branchId": authUser.branchId
            }
            // console.log(JSON.stringify(saveObj))
            // setLoaderOption(false);
            await axios.post(`${saveAccessionEntryAPIURL}`, saveObj)
                .then(res => {
                    if (res.data == "ËXISTS") {
                        setLoaderOption(false);
                        toast.success("This Accession is already registered.")
                    }
                    else if (res.data == "SAVED") {
                        setLoaderOption(false);
                        clearData();
                        setShowModalByQuantity(false);
                        getData();
                        toast.success("Accession registration done successfully.")
                    }
                }).catch(err => {
                    console.log(err);
                    setLoaderOption(false);
                    toast.error("Something went wrong, please check.")
                })
        }
        else {
            setLoaderOption(false);
            toast.error("Improper date, please check.")
        }

    }

    const updateBtnClick = async () => {
        setLoaderOption(true);
        if (moment(accDate).format("YYYY-MM-DD") != "Invalid date" && moment(billDate).format("YYYY-MM-DD") != "Invalid date") {
            const updateObj = {
                "id": id,
                "registerTypeId": registerObj.id,
                "accNo": accNo,
                "quantity": quantity,
                "titleId": titleObj.id,
                "edition": edition,
                "year": moment(year).format("YYYY"),
                "volume": volume,
                "departmentId": departmentObj.id,
                "sourceId": sourceObj.id,
                "locationId": locationObj.id,
                "callNo": callNo,
                "authorShortName": authShortName,
                "bindingTypeId": bindingTypeObj.id,
                "subjectId": subjectObj.id,
                "webLink": webLink,
                "remark": remark,
                "vendorId": vendorObj.id,
                "accDate": moment(accDate).format("YYYY-MM-DD"),
                "currencyId": currencyObj.id,
                "prize": bookPrize,
                "netAmt": netAmt,
                "billNo": billNo,
                "billDate": moment(billDate).format("YYYY-MM-DD"),
                "iSBNNo": ISBNno,
                "page": bookPage,
                "size": size,
                "languageId": languageObj.id,
                "subBudgetTypeId": subBudgetTypeObj.id,
                "branchId": authUser.branchId
            }
            // console.log(JSON.stringify(updateObj))
            // setLoaderOption(false);
            await axios.put(`${updateAccessionEntryDataAPIURL}`, updateObj)
                .then(res => {
                    if (res.data == "ËXISTS") {
                        setLoaderOption(false);
                        toast.success("This Accession is already registered.")
                    }
                    else if (res.data == "SAVED") {
                        setLoaderOption(false);
                        clearData();
                        setShowModalByQuantity(false);
                        getData();
                        toast.success("Accession registration updated successfully.")
                    }
                }).catch(err => {
                    console.log(err);
                    setLoaderOption(false);
                    toast.error("Something went wrong, please check.")
                })
        }
        else {
            setLoaderOption(false);
            toast.error("Improper date, please check.")
        }

    }

    const clearData = () => {
        setSaveBtn(saveBtnStyle);
        setUpdateBtn(displayNoneBtnStyle);
        navigate(`/Home/la901/`)
    }

    const state = {
        button: 1
    };

    const onSubmit = e => {
        e.preventDefault();
        if (state.button === 1) {
            if (quantity > 1) {
                setShowModalByQuantity(true);
            }
            else {
                saveBtnClick();
            }
        }
        if (state.button === 2) {
            updateBtnClick();
        }
    };

    //console.log(bookStatus);

    const updateStatus = async (status) => {
        await axios.put(`${updateBookstatusAPIURL}?id=${acId}&statusId=${status}`)
            .then(res => {
                if (res.data == "SAVED") {
                    toast.success("Status Updated.")
                }
            }).catch(err => {
                console.log(err);
                toast.error("Something went wrong, please check.")
            })
    }

    if (loaderOption == true) {
        return <Loader />
    }
    else {
        return (
            <>
                <div>
                    <TitleLabel titleId={titleId} />
                    <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: "300px" }}>
                        <form onSubmit={onSubmit}>
                            <input type="hidden" value={id} onChange={(e) => setId(e.target.value)} />

                            <div className='offset-sm-2' hidden={(EditFlag == 1) ? true : false}>
                                <FormControlLabel control={<Switch
                                    onChange={(e) => {
                                        (e.target.checked == true) ? updateStatus(5) : updateStatus(1)
                                    }}
                                    defaultChecked={(bookStatus == 5) ? true : false}
                                />} sx={{ '.MuiFormControlLabel-label': { fontWeight: 550, fontSize: "16px", marginTop: "2px" } }} label="Status" />
                            </div>

                            <div className="row my-2">
                                <label className="col-sm-2" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }} >Register Type :</label>
                                <div className="col-sm-3">
                                    <Autocomplete
                                        id="combo-box-demo"
                                        options={registerData}
                                        readOnly={(EditFlag == 2) ? true : false}
                                        PaperComponent={({ children }) => (
                                            <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                        )}
                                        style={{ fontSize: '14px' }}
                                        getOptionLabel={option => option.registerTypeName}
                                        size="small"
                                        onChange={(event, newValue) => {
                                            setRegisterObj(newValue);
                                            getAccNoByRegisterTypeId(newValue.id);
                                        }}
                                        onKeyPress={(e) => (e.key == "Enter") ? registerObj : ""}
                                        value={registerObj}
                                        renderInput={params => (
                                            <TextField {...params} margin='dense' label="Select Register Type" variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                        )}
                                    />
                                </div>

                                <label className='col-sm-1' style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>Acc No :</label>
                                <div className="col-sm-2">
                                    <TextField
                                        fullWidth
                                        required
                                        type="number"
                                        onKeyDown={(e) => (e.key === " " && accNo.length === 0) ? e.preventDefault() : ""}
                                        autoComplete='off'
                                        margin='dense'
                                        size='small'
                                        variant="outlined"
                                        InputLabelProps={{ style: { fontSize: 14 } }}
                                        onKeyPress={(e) => (e.key == "Enter") ? accNo : ""}
                                        label="Acc No"
                                        value={accNo}
                                        onChange={(e) => setAccNo(e.target.value)}
                                        inputProps={{ readOnly: true }}
                                    />
                                </div>

                                <label className='col-sm-1' style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>Quantity:</label>
                                <div className="col-sm-2">
                                    <TextField
                                        fullWidth
                                        required
                                        type="number"
                                        InputProps={{ inputProps: { min: 1 }, readOnly: (EditFlag == 2) ? true : false }}
                                        onKeyDown={(e) => (e.key === " " && quantity.length === 0) ? e.preventDefault() : ""}
                                        autoComplete='off'
                                        margin='dense'
                                        size='small'
                                        variant="outlined"
                                        InputLabelProps={{ style: { fontSize: 14 } }}
                                        onKeyPress={(e) => (e.key == "Enter") ? quantity : ""}
                                        label="Quantity"
                                        value={quantity}
                                        onChange={(e) => {
                                            setQuantity(parseFloat(e.target.value));
                                            setBookPrize('');
                                            setNetAmt('');
                                        }}
                                    />
                                </div>
                            </div>

                            <div className="row my-2">
                                <label className="col-sm-2" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }} >Title :</label>
                                <div className="col-sm-8">
                                    <Autocomplete
                                        id="combo-box-demo"
                                        options={titleData}
                                        PaperComponent={({ children }) => (
                                            <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                        )}
                                        style={{ fontSize: '14px' }}
                                        getOptionLabel={option => option.titleName}
                                        size="small"
                                        onChange={(event, newValue) => {
                                            setTitleObj(newValue);
                                            if (newValue == null) {
                                                setAuthor('');
                                                setPublisher('');
                                            }
                                            else if (newValue != null) {
                                                getAuthorPublisher(newValue.id);
                                            }

                                        }}
                                        onKeyPress={(e) => (e.key == "Enter") ? titleObj : ""}
                                        value={titleObj}
                                        renderInput={params => (
                                            <TextField {...params} margin='dense' label="Select Title" variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                        )}
                                    />
                                </div>
                                <div className="col-sm-1 mt-2">
                                    <button type='button' className='btn p-0 mt-2' onClick={() => setShowTitleModal(true)}>
                                        <AddCircleIcon fontSize='medium' style={{ color: "#1F883C" }} />
                                    </button>
                                </div>
                            </div>

                            <div className="row my-2">
                                <label className='col-sm-2' style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>Author :</label>
                                <div className="col-sm-4">
                                    <TextField
                                        fullWidth
                                        required
                                        onKeyDown={(e) => (e.key === " " && author.length === 0) ? e.preventDefault() : ""}
                                        autoComplete='off'
                                        margin='dense'
                                        size='small'
                                        variant="outlined"
                                        InputLabelProps={{ style: { fontSize: 14 } }}
                                        onKeyPress={(e) => (e.key == "Enter") ? author : ""}
                                        label="Author"
                                        value={author}
                                        inputProps={{ readOnly: true }}
                                        onChange={(e) => setAuthor(e.target.value)}
                                    />
                                </div>

                                <label className='col-sm-1' style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>Publisher:</label>
                                <div className="col-sm-4">
                                    <TextField
                                        fullWidth
                                        required
                                        onKeyDown={(e) => (e.key === " " && publisher.length === 0) ? e.preventDefault() : ""}
                                        autoComplete='off'
                                        margin='dense'
                                        size='small'
                                        variant="outlined"
                                        InputLabelProps={{ style: { fontSize: 14 } }}
                                        onKeyPress={(e) => (e.key == "Enter") ? publisher : ""}
                                        label="Publisher "
                                        value={publisher}
                                        inputProps={{ readOnly: true }}
                                        onChange={(e) => setPublisher(e.target.value)}
                                    />
                                </div>
                            </div>

                            <div className="row my-2">
                                <label className='col-sm-2' style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>Edition :</label>
                                <div className="col-sm-3">
                                    <TextField
                                        fullWidth
                                        required
                                        type="number"
                                        onKeyDown={(e) => (e.key === " " && edition.length === 0) ? e.preventDefault() : ""}
                                        autoComplete='off'
                                        margin='dense'
                                        size='small'
                                        variant="outlined"
                                        InputLabelProps={{ style: { fontSize: 14 } }}
                                        onKeyPress={(e) => (e.key == "Enter") ? edition : ""}
                                        label="Edition"
                                        value={edition}
                                        onChange={(e) => setEdition(e.target.value)}
                                    />
                                </div>

                                <label className='col-sm-1' style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>Year :</label>
                                <div className="col-sm-2">
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DatePicker
                                            label="Year"
                                            inputFormat="yyyy"
                                            mask="____"
                                            value={year}
                                            onKeyDown={(e) => (e.key === " " && year.length === 0) ? e.preventDefault() : ""}
                                            onKeyPress={(e) => (e.key == "Enter") ? year : ""}
                                            onChange={(newValue) => {
                                                if (newValue === null) {
                                                    setYear(new Date());
                                                }
                                                else if (newValue !== null) {
                                                    setYear(newValue);
                                                }

                                            }}
                                            disableOpenPicker
                                            renderInput={(params) =>
                                                <TextField
                                                    {...params}
                                                    margin='dense'
                                                    variant="outlined"
                                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                                    required
                                                    //style={{ width: "130px", marginLeft: "15px", backgroundColor: "#fff" }}
                                                    fullWidth
                                                    autoComplete='off'
                                                    size="small" />}
                                        />
                                    </LocalizationProvider>
                                </div>

                                <label className='col-sm-1' style={{ fontSize: "15px", fontWeight: "600", marginTop: "12px" }}>Volume :</label>
                                <div className='col-sm-2'>
                                    <FormControl fullWidth margin='dense'>
                                        <InputLabel size="small" id='demo-simple-select-label' style={{ fontSize: '14px' }}>Volume</InputLabel>
                                        <Select
                                            MenuProps={{ classes: { paper: classes.select } }}
                                            size='small'
                                            required
                                            fullWidth
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={volume}
                                            label="Volume"
                                            onChange={(e) => {
                                                setVolume(e.target.value);
                                            }}
                                        >
                                            {
                                                Array.from(Array(100), (e, i) => {
                                                    return (
                                                        <MenuItem value={i} key={i}>{i}</MenuItem>
                                                    )
                                                })
                                            }
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>

                            <div className="row my-2">
                                <label className="col-sm-2" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }} >Department :</label>
                                <div className="col-sm-3">
                                    <Autocomplete
                                        id="combo-box-demo"
                                        options={departmentData}
                                        PaperComponent={({ children }) => (
                                            <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                        )}
                                        style={{ fontSize: '14px' }}
                                        getOptionLabel={option => option.name}
                                        size="small"
                                        onChange={(event, newValue) => {
                                            setDepartmentObj(newValue);
                                        }}
                                        onKeyPress={(e) => (e.key == "Enter") ? departmentObj : ""}
                                        value={departmentObj}
                                        renderInput={params => (
                                            <TextField {...params} margin='dense' label="Select Department" variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                        )}
                                    />
                                </div>
                                <div className="col-sm-1 mt-2">
                                    <button type='button' className='btn p-0 mt-2' onClick={() => setShowDepartmentModal(true)}>
                                        <AddCircleIcon fontSize='medium' style={{ color: "#1F883C" }} />
                                    </button>
                                </div>

                                <label className="col-sm-1" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }} >Source :</label>
                                <div className="col-sm-3">
                                    <Autocomplete
                                        id="combo-box-demo"
                                        options={sourceData}
                                        PaperComponent={({ children }) => (
                                            <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                        )}
                                        style={{ fontSize: '14px' }}
                                        getOptionLabel={option => option.name}
                                        size="small"
                                        onChange={(event, newValue) => {
                                            setSourceObj(newValue);
                                        }}
                                        onKeyPress={(e) => (e.key == "Enter") ? sourceObj : ""}
                                        value={sourceObj}
                                        renderInput={params => (
                                            <TextField {...params} margin='dense' label="Select Source" variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                        )}
                                    />
                                </div>
                                <div className="col-sm-1 mt-2">
                                    <button type='button' className='btn p-0 mt-2' onClick={() => setShowSourceModal(true)}>
                                        <AddCircleIcon fontSize='medium' style={{ color: "#1F883C" }} />
                                    </button>
                                </div>
                            </div>

                            <div className="row my-2 mb-4">
                                <label className="col-sm-2" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }} >Type :</label>
                                <div className="col-sm-3">
                                    <Autocomplete
                                        id="combo-box-demo"
                                        options={subBudgetTypeData}
                                        PaperComponent={({ children }) => (
                                            <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                        )}
                                        style={{ fontSize: '14px' }}
                                        getOptionLabel={option => option.subBudgetTypeName}
                                        size="small"
                                        onChange={(event, newValue) => {
                                            setSubBudgetTypeObj(newValue);
                                        }}
                                        onKeyPress={(e) => (e.key == "Enter") ? subBudgetTypeObj : ""}
                                        value={subBudgetTypeObj}
                                        renderInput={params => (
                                            <TextField {...params} margin='dense' label="Select Type" variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                        )}
                                    />
                                </div>
                            </div>

                            <div className="row mb-2 my-2">
                                <div className="">
                                    <h4 style={{
                                        textAlign: "left",
                                        borderBottom: "1px solid #5D6D7E",
                                        lineHeight: "0.1em",
                                        margin: "10px 0 20px",
                                        color: "#1430CF"
                                    }}><span style={{
                                        background: "#fff",
                                        padding: "0 8px",
                                    }}>Book Details</span></h4>
                                </div>
                            </div>

                            <div className="row my-2">
                                <label className="col-sm-2" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }} >Location :</label>
                                <div className="col-sm-3">
                                    <Autocomplete
                                        id="combo-box-demo"
                                        options={locationData}
                                        PaperComponent={({ children }) => (
                                            <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                        )}
                                        style={{ fontSize: '14px' }}
                                        getOptionLabel={option => option.name}
                                        size="small"
                                        onChange={(event, newValue) => {
                                            setLocationObj(newValue);
                                        }}
                                        onKeyPress={(e) => (e.key == "Enter") ? locationObj : ""}
                                        value={locationObj}
                                        renderInput={params => (
                                            <TextField {...params} margin='dense' label="Select Location" variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                        )}
                                    />
                                </div>
                                <div className="col-sm-1 mt-2">
                                    <button type='button' className='btn p-0 mt-2' onClick={() => setShowLocationModal(true)}>
                                        <AddCircleIcon fontSize='medium' style={{ color: "#1F883C" }} />
                                    </button>
                                </div>

                                <label className='col-sm-1' style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>Call No :</label>
                                <div className='col-sm-5'>
                                    <TextField
                                        fullWidth
                                        required
                                        style={{
                                            width: "150px", '& legend': { display: 'none' },
                                            '& fieldset': { top: 0 },
                                        }}

                                        onKeyDown={(e) => (e.key === " " && callNo.length === 0) ? e.preventDefault() : ""}
                                        autoComplete='off'
                                        margin='dense'
                                        size='small'
                                        variant="outlined"
                                        InputLabelProps={{ style: { fontSize: 14 } }}
                                        onKeyPress={(e) => (e.key == "Enter") ? callNo : ""}
                                        label="Call No"
                                        value={callNo}
                                        onChange={(e) => setCallNo(e.target.value)}
                                    />
                                    <TextField
                                        fullWidth
                                        required
                                        style={{
                                            width: "100px", marginLeft: "4px", '& legend': { display: 'none' },
                                            '& fieldset': { top: 0 },
                                        }}
                                        onKeyDown={(e) => (e.key === " " && authShortName.length === 0) ? e.preventDefault() : ""}
                                        autoComplete='off'
                                        margin='dense'
                                        size='small'
                                        variant="outlined"
                                        InputLabelProps={{ style: { fontSize: 14 } }}
                                        onKeyPress={(e) => (e.key == "Enter") ? authShortName : ""}
                                        label="Auth"
                                        value={authShortName}
                                        onChange={(e) => setAuthShortName(e.target.value)}
                                    />
                                </div>
                            </div>

                            <div className="row my-2">
                                <label className="col-sm-2" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }} >Binding Type :</label>
                                <div className="col-sm-3">
                                    <Autocomplete
                                        id="combo-box-demo"
                                        options={bindingTypeData}
                                        PaperComponent={({ children }) => (
                                            <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                        )}
                                        style={{ fontSize: '14px' }}
                                        getOptionLabel={option => option.name}
                                        size="small"
                                        onChange={(event, newValue) => {
                                            setBindingTypeObj(newValue);
                                        }}
                                        onKeyPress={(e) => (e.key == "Enter") ? bindingTypeObj : ""}
                                        value={bindingTypeObj}
                                        renderInput={params => (
                                            <TextField {...params} margin='dense' label="Select Binding Type" variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                        )}
                                    />
                                </div>
                                <div className="col-sm-1 mt-2">
                                    <button type='button' className='btn p-0 mt-2' onClick={() => setShowBindingTypeModal(true)}>
                                        <AddCircleIcon fontSize='medium' style={{ color: "#1F883C" }} />
                                    </button>
                                </div>

                                <label className="col-sm-1" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }} >Subject :</label>
                                <div className="col-sm-4">
                                    <Autocomplete
                                        id="combo-box-demo"
                                        options={subjectData}
                                        PaperComponent={({ children }) => (
                                            <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                        )}
                                        style={{ fontSize: '14px' }}
                                        getOptionLabel={option => (authUser.branchMedium == 1) ? option.nameMr : option.nameEn}
                                        size="small"
                                        onChange={(event, newValue) => {
                                            setSubjectObj(newValue);
                                        }}
                                        onKeyPress={(e) => (e.key == "Enter") ? subjectObj : ""}
                                        value={subjectObj}
                                        renderInput={params => (
                                            <TextField {...params} margin='dense' label="Select Subject" variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                        )}
                                    />
                                </div>
                            </div>

                            <div className="row my-2 mb-4">
                                <label className='col-sm-2' style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>Web Link :</label>
                                <div className="col-sm-3">
                                    <TextField
                                        fullWidth
                                        required
                                        onKeyDown={(e) => (e.key === " " && webLink.length === 0) ? e.preventDefault() : ""}
                                        autoComplete='off'
                                        margin='dense'
                                        size='small'
                                        variant="outlined"
                                        InputLabelProps={{ style: { fontSize: 14 } }}
                                        onKeyPress={(e) => (e.key == "Enter") ? webLink : ""}
                                        label="Web Link"
                                        value={webLink}
                                        onChange={(e) => setwebLink(e.target.value)}
                                    />
                                </div>

                                <label className='col-sm-1' style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>Remark :</label>
                                <div className="col-sm-5">
                                    <TextField
                                        fullWidth
                                        required
                                        onKeyDown={(e) => (e.key === " " && remark.length === 0) ? e.preventDefault() : ""}
                                        autoComplete='off'
                                        margin='dense'
                                        size='small'
                                        variant="outlined"
                                        InputLabelProps={{ style: { fontSize: 14 } }}
                                        onKeyPress={(e) => (e.key == "Enter") ? remark : ""}
                                        label="Remark"
                                        value={remark}
                                        onChange={(e) => setRemark(e.target.value)}
                                    />
                                </div>
                            </div>

                            <div className="row mb-2 my-2">
                                <div className="">
                                    <h4 style={{
                                        textAlign: "left",
                                        borderBottom: "1px solid #5D6D7E",
                                        lineHeight: "0.1em",
                                        margin: "10px 0 20px",
                                        color: "#1430CF"
                                    }}><span style={{
                                        background: "#fff",
                                        padding: "0 8px",
                                    }}>Bill Details</span></h4>
                                </div>
                            </div>

                            <div className="row my-2">
                                <label className="col-sm-2" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }} >Vendor :</label>
                                <div className="col-sm-5">
                                    <Autocomplete
                                        id="combo-box-demo"
                                        options={vendorData}
                                        PaperComponent={({ children }) => (
                                            <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                        )}
                                        style={{ fontSize: '14px' }}
                                        getOptionLabel={option => option.name}
                                        size="small"
                                        onChange={(event, newValue) => {
                                            setVendorObj(newValue);
                                        }}
                                        onKeyPress={(e) => (e.key == "Enter") ? vendorObj : ""}
                                        value={vendorObj}
                                        renderInput={params => (
                                            <TextField {...params} margin='dense' label="Select Vendor" variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                        )}
                                    />
                                </div>
                                <div className="col-sm-1 mt-2">
                                    <button type='button' className='btn p-0 mt-2' onClick={() => setShowVendorModal(true)}>
                                        <AddCircleIcon fontSize='medium' style={{ color: "#1F883C" }} />
                                    </button>
                                </div>

                                <label className="col-sm-1" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px", marginRight: "0px" }} >Acc Date:</label>
                                <div className='col-sm-2'>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DatePicker
                                            label="Acc Date"
                                            value={accDate}
                                            // minDate={defaultMinDate}
                                            // maxDate={defaultMaxDate}
                                            onChange={(newValue) => {
                                                setAccDate(newValue);
                                                //(newValue > defaultMaxDate || newValue < defaultMinDate) ? setDateCheck("Invalid date") : setDateCheck(null)
                                            }}
                                            onKeyPress={(e) => (e.key == "Enter") ? accDate : ""}
                                            inputFormat="dd/MM/yyyy"
                                            renderInput={(params) =>
                                                <TextField
                                                    {...params}
                                                    margin='dense'
                                                    variant="outlined"
                                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                                    required
                                                    fullWidth
                                                    autoComplete='off'
                                                    size="small" />}
                                        />
                                    </LocalizationProvider>
                                    {/* <span><em style={{ fontSize: "12px", color: "red", fontWeight: "600" }}>{dateCheck}</em></span> */}
                                </div>
                            </div>

                            <div className="row my-2">
                                <label className="col-sm-2" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }} >Currency :</label>
                                <div className="" style={{ width: "225px" }}>
                                    <Autocomplete
                                        id="combo-box-demo"
                                        options={currencyData}
                                        PaperComponent={({ children }) => (
                                            <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                        )}
                                        style={{ fontSize: '14px' }}
                                        getOptionLabel={option => option.name}
                                        size="small"
                                        onChange={(event, newValue) => {
                                            setCurrencyObj(newValue);
                                        }}
                                        onKeyPress={(e) => (e.key == "Enter") ? currencyObj : ""}
                                        value={currencyObj}
                                        renderInput={params => (
                                            <TextField {...params} margin='dense' label="Select Currency" variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                        )}
                                    />
                                </div>
                                <div className="mt-2" style={{ width: "50px" }}>
                                    <button type='button' className='btn p-0 mt-2' onClick={() => setShowCurrencyModal(true)}>
                                        <AddCircleIcon fontSize='medium' style={{ color: "#1F883C" }} />
                                    </button>
                                </div>

                                <label className='col-sm-1' style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>Prize :</label>
                                <div className="col-sm-2">
                                    <TextField
                                        fullWidth
                                        required
                                        type="number"
                                        InputProps={{ inputProps: { min: 1 } }}
                                        onKeyDown={(e) => (e.key === " " && bookPrize.length === 0) ? e.preventDefault() : ""}
                                        autoComplete='off'
                                        margin='dense'
                                        size='small'
                                        variant="outlined"
                                        InputLabelProps={{ style: { fontSize: 14 } }}
                                        onKeyPress={(e) => (e.key == "Enter") ? bookPrize : ""}
                                        label="Prize"
                                        value={bookPrize}
                                        onChange={(e) => {
                                            setBookPrize(e.target.value);
                                            setNetAmt('');
                                        }}
                                        onBlur={(e) => { getNetAmountByQuantityPrize(e.target.value) }}
                                    />
                                </div>

                                <label className='col-sm-1' style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>Net Amt:</label>
                                <div className="col-sm-2">
                                    <TextField
                                        fullWidth
                                        required
                                        InputProps={{ inputProps: { min: 1 } }}
                                        type="number"
                                        onKeyDown={(e) => (e.key === " " && netAmt.length === 0) ? e.preventDefault() : ""}
                                        autoComplete='off'
                                        margin='dense'
                                        size='small'
                                        variant="outlined"
                                        InputLabelProps={{ style: { fontSize: 14 } }}
                                        onKeyPress={(e) => (e.key == "Enter") ? netAmt : ""}
                                        label="Net Amount"
                                        value={netAmt}
                                        onChange={(e) => setNetAmt(e.target.value)}
                                    />
                                </div>
                            </div>

                            <div className="row my-2">
                                <label className='col-sm-2' style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>Bill No :</label>
                                <div className="col-sm-3">
                                    <TextField
                                        fullWidth
                                        required
                                        type="number"
                                        onKeyDown={(e) => (e.key === " " && billNo.length === 0) ? e.preventDefault() : ""}
                                        autoComplete='off'
                                        margin='dense'
                                        size='small'
                                        variant="outlined"
                                        InputLabelProps={{ style: { fontSize: 14 } }}
                                        onKeyPress={(e) => (e.key == "Enter") ? billNo : ""}
                                        label="Bill No"
                                        value={billNo}
                                        onChange={(e) => setBillNo(e.target.value)}
                                    />
                                </div>

                                <label className="col-sm-1" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px", marginRight: "0px" }} >Bill Date:</label>
                                <div className='col-sm-2'>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DatePicker
                                            label="Bill Date"
                                            value={billDate}
                                            // minDate={defaultMinDate}
                                            // maxDate={defaultMaxDate}
                                            onChange={(newValue) => {
                                                setBillDate(newValue);
                                                //(newValue > defaultMaxDate || newValue < defaultMinDate) ? setDateCheck("Invalid date") : setDateCheck(null)
                                            }}
                                            onKeyPress={(e) => (e.key == "Enter") ? billDate : ""}
                                            inputFormat="dd/MM/yyyy"
                                            renderInput={(params) =>
                                                <TextField
                                                    {...params}
                                                    margin='dense'
                                                    variant="outlined"
                                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                                    required
                                                    fullWidth
                                                    autoComplete='off'
                                                    size="small" />}
                                        />
                                    </LocalizationProvider>
                                    {/* <span><em style={{ fontSize: "12px", color: "red", fontWeight: "600" }}>{dateCheck}</em></span> */}
                                </div>

                                <label className='col-sm-1' style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>ISBN No:</label>
                                <div className="col-sm-2">
                                    <TextField
                                        fullWidth
                                        required
                                        type="number"
                                        onKeyDown={(e) => (e.key === " " && ISBNno.length === 0) ? e.preventDefault() : ""}
                                        autoComplete='off'
                                        margin='dense'
                                        size='small'
                                        variant="outlined"
                                        InputLabelProps={{ style: { fontSize: 14 } }}
                                        onKeyPress={(e) => (e.key == "Enter") ? ISBNno : ""}
                                        label="ISBN No"
                                        value={ISBNno}
                                        onChange={(e) => setISBNno(e.target.value)}
                                    />
                                </div>
                            </div>

                            <div className="row my-2">
                                <label className='col-sm-2' style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>Page :</label>
                                <div className="col-sm-3">
                                    <TextField
                                        fullWidth
                                        required
                                        type="number"
                                        onKeyDown={(e) => (e.key === " " && bookPage.length === 0) ? e.preventDefault() : ""}
                                        autoComplete='off'
                                        margin='dense'
                                        size='small'
                                        variant="outlined"
                                        InputLabelProps={{ style: { fontSize: 14 } }}
                                        onKeyPress={(e) => (e.key == "Enter") ? bookPage : ""}
                                        label="Page"
                                        value={bookPage}
                                        onChange={(e) => setBookPage(e.target.value)}
                                    />
                                </div>

                                <label className='col-sm-1' style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>Size :</label>
                                <div className="col-sm-2">
                                    <TextField
                                        fullWidth
                                        required
                                        onKeyDown={(e) => (e.key === " " && size.length === 0) ? e.preventDefault() : ""}
                                        autoComplete='off'
                                        margin='dense'
                                        size='small'
                                        variant="outlined"
                                        InputLabelProps={{ style: { fontSize: 14 } }}
                                        onKeyPress={(e) => (e.key == "Enter") ? size : ""}
                                        label="Size"
                                        value={size}
                                        onChange={(e) => setSize(e.target.value)}
                                    />
                                </div>

                                <label className="col-sm-1" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }} >Language:</label>
                                <div className="col-sm-2">
                                    <Autocomplete
                                        id="combo-box-demo"
                                        options={languageData}
                                        PaperComponent={({ children }) => (
                                            <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                        )}
                                        style={{ fontSize: '14px' }}
                                        getOptionLabel={option => (authUser.branchMedium == 1) ? option.nameMr : option.nameEn}
                                        size="small"
                                        onChange={(event, newValue) => {
                                            setLanguageObj(newValue);
                                        }}
                                        onKeyPress={(e) => (e.key == "Enter") ? languageObj : ""}
                                        value={languageObj}
                                        renderInput={params => (
                                            <TextField {...params} margin='dense' label="Select Language" variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                        )}
                                    />
                                </div>
                                <div className="col-sm-1 mt-2">
                                    <button type='button' className='btn p-0 mt-2' onClick={() => setShowLanguageModal(true)}>
                                        <AddCircleIcon fontSize='medium' style={{ color: "#1F883C" }} />
                                    </button>
                                </div>
                            </div>

                            <div className="row mt-3">
                                <div className="col-sm-4 offset-sm-2">
                                    <button type='submit' disabled={saveBtnDisabled} onClick={() => (state.button = 1)} className={saveBtn}>Save</button>
                                    <button type='submit' disabled={updateBtnDisabled} onClick={() => (state.button = 2)} className={updateBtn} style={{ margin: "0px 4px" }}>Update</button>
                                    <button type='button' onClick={() => clearData()} className='btn btn-sm btn-danger' style={{ margin: "0px 4px" }}>Cancel</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>



                <Modal show={showModalByQuantity}>
                    <Modal.Header>
                        <Modal.Title>Are you sure, you want to register books with {quantity} quantity?</Modal.Title>
                    </Modal.Header>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setShowModalByQuantity(false)}>Close</Button>
                        <Button variant="danger" onClick={saveBtnClick}>Save</Button>
                    </Modal.Footer>
                </Modal>

                <ToastContainer position="top-right" theme="colored" />
                <AddNewTitleMasterModal showTitleModal={showTitleModal} setShowTitleModal={setShowTitleModal} getApiData={getData} />
                <AddNewDepartmentModal showDepartmentModal={showDepartmentModal} setShowDepartmentModal={setShowDepartmentModal} getApiData={getData} />
                <AddNewSourceModal showSourceModal={showSourceModal} setShowSourceModal={setShowSourceModal} getApiData={getData} />
                <AddNewLocationModal showLocationModal={showLocationModal} setShowLocationModal={setShowLocationModal} getApiData={getData} />
                <AddNewBindingTypeModal showBindingTypeModal={showBindingTypeModal} setShowBindingTypeModal={setShowBindingTypeModal} getApiData={getData} />
                <AddNewCurrencyModal showCurrencyModal={showCurrencyModal} setShowCurrencyModal={setShowCurrencyModal} getApiData={getData} />
                <AddNewVendorModal showVendorModal={showVendorModal} setShowVendorModal={setShowVendorModal} getApiData={getData} />
                <AddNewLanguageModal showLanguageModal={showLanguageModal} setShowLanguageModal={setShowLanguageModal} getApiData={getData} />
            </>
        )
    }


}

export default AccessionEntry