const MudranAndSahityaJSON = [
    {
        MenuType: "normal",
        PathName: "/Home/MudranSahityaDash",
        Title: "Dashboard",
        LinkIcon: "home"
    },
    {
        MenuType: "dropdown",
        PathName: "#",
        Title: "Master",
        LinkIcon: "dashboard",
        DropDownSubMenu: [
            {
                id: 1501,
                SubPath: "/Home/ms1501/",
                SubName: "Item Master"
            },
            {
                id: 1502,
                SubPath: "/Home/ms1502/",
                SubName: "Rate Master"
            },           
        ]
    },
    // {
    //     MenuType: "dropdown",
    //     PathName: "#",
    //     Title: "Transaction",
    //     LinkIcon: "monetization_on",
    //     DropDownSubMenu: [
    //         {
    //             id: 1304,
    //             SubPath: "/Home/st1304/",
    //             SubName: "Opening Item"
    //         },
    //         {
    //             id: 1305,
    //             SubPath: "/Home/st1305/",
    //             SubName: "Item Inward"
    //         },
    //         {
    //             id: 1306,
    //             SubPath: "/Home/st1306/",
    //             SubName: "Item Outward"
    //         },
    //     ]
    // },
]

export default MudranAndSahityaJSON;