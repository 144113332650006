import React, { useState, useEffect } from 'react';
import axios from 'axios';
import makeStyles from "@mui/styles/makeStyles";
import PrintIcon from '@mui/icons-material/Print';
import { useSelector } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import Loader from '../../../CommonComponent/Loader';
import TitleLabel from '../../../CommonComponent/TitleLabel';

const useStyles = makeStyles(theme => ({
    select: {
        maxHeight: 160,
        "& ul": {
            backgroundColor: "#F4ECF7",
            //maxHeight: 160,
        },
        "& li": {
            // backgroundColor: "#F4ECF7", fontWeight: "550", fontSize: "14px" 
            fontSize: "14px",
            fontWeight: "550"
        },
    },

}));

const AllMembersReport = () => {
    const classes = useStyles();
    const authUser = useSelector((state) => state.user.value);
    const titleId = "All Members Report";

    const [option, setOption] = useState(1);


    // loader on/off
    const [loaderOption, setLoaderOption] = useState(false);

    const printMasterReport = (e) => {
        e.preventDefault();
        //  localhost:8080/institute/sss/master/getMasterReport?reportOption=21&sid=3&bid=1
        // setLoaderOption(true);
        // axios.get(`${printMasterReportAPIURL}?reportOption=${masterId}&sid=${authUser.deptId}&bid=${authUser.branchId}`)
        //     .then((response) => {

        //         if (response.data == "NOTFOUND") {
        //             //console.log(response.data)  
        //             setLoaderOption(false);
        //             toast.warn("Data not found.")
        //         }
        //         else if (response.data != null) {
        //             //console.log(response.data)                    
        //             window.open(`${getMasterReportAPIURL}${response.data}`);
        //             setLoaderOption(false);
        //         }

        //     })
        //     .catch(error => {
        //         setLoaderOption(false);
        //         toast.error("Something went wrong, try after sometime.")
        //         console.log("Master Report failed: " + error)
        //     })
    }

    if (loaderOption == true) {
        return <><Loader /></>
    }
    else {
        return (
            <>
                <TitleLabel titleId={titleId} />
                <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: "350px" }}>
                    <form onSubmit={printMasterReport}>
                        <div className='row'>
                            <label className="col-sm-1" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Option :</label>
                            <div className='col-sm-3'>
                                <FormControl fullWidth margin='dense'>
                                    <InputLabel id='demo-simple-select-label' size="small" style={{ fontSize: '14px' }}>Option</InputLabel>
                                    <Select
                                        MenuProps={{ classes: { paper: classes.select } }}
                                        size='small'
                                        required
                                        fullWidth
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={option}
                                        label="Option"
                                        onChange={(e) => {
                                            setOption(e.target.value);
                                        }}
                                    >
                                        <MenuItem value={1}>All</MenuItem>
                                        <MenuItem value={2}>Only Living</MenuItem>
                                    </Select>
                                </FormControl>

                            </div>
                        </div>

                        <div className="row mt-3">
                            <div className='col-sm-4 offset-sm-1'>
                                <button
                                    type='submit'
                                    className='btn btn-primary btn-sm'
                                >
                                    Show
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
                {/* <ToastContainer position="top-right" theme="colored" /> */}
            </>

        )
    }


}

export default AllMembersReport
