import React, { useEffect, useState } from 'react'
import Loader from '../../../CommonComponent/Loader'
import TitleLabel from '../../../CommonComponent/TitleLabel'
import { Autocomplete, FormControl, InputLabel, MenuItem, Paper, Select, TextField } from '@mui/material';
import subjectMarkTermData from '../../../CommonComponent/LocalJSON/SubjectMarkTermData';
import makeStyles from "@mui/styles/makeStyles";
import { useSelector } from 'react-redux';
import { getClassDivisionURL } from '../../../Academic/Services/StudentAttendanceAPIURL';
import axios from 'axios';
import { getTermObsMasterAPIURL } from '../../Services/TermObsMasterAPIURL';
import { toast } from 'react-toastify';
import {
    getTermWiseObservationDataAPIURL, saveTermWiseObservationAPIURL,
    getStudentListForTermWiseObsAPIURL, getStudentsByClassDivAPIURL
} from '../../Services/AfterExamAPIURL';
import TermWiseObsTable from './TermWiseObsTable';

const useStyles = makeStyles(theme => ({
    menuPaper: {
        maxHeight: 200,
        "& ul": {
            backgroundColor: "#F4ECF7",
            //maxHeight: 160,
        },
        "& li": {
            // backgroundColor: "#F4ECF7", fontWeight: "550", fontSize: "14px" 
            fontSize: "14px",
            fontWeight: "550"
        },
    }
}));

const TermWiseObservation = () => {
    const titleId = "Term Wise Observation";
    const authUser = useSelector((state) => state.user.value);
    const classes = useStyles();

    const [loaderOption, setLoaderOption] = useState(false);

    //Note: This below states are declared for Save & Update(Disabled/unDisabled)
    const saveBtnStyle = "termWiseObsSaveBtn btn btn-primary btn-sm";
    const updateBtnStyle = "termWiseObsUpdateBtn btn btn-primary btn-sm";
    const displayNoneBtnStyle = "d-none";
    const [saveBtn, setSaveBtn] = useState(saveBtnStyle);
    const [updateBtn, setUpdateBtn] = useState(displayNoneBtnStyle);

    const [id, setId] = useState('');
    const [classDivData, setClassDivData] = useState([]);
    const [classDivObj, setClassDivObj] = useState(null);
    const [termObj, setTermObj] = useState('');
    const [studentData, setStudentData] = useState([]);
    const [studentObj, setStudentObj] = useState(null);
    const [observationData1, setObservationData1] = useState([]);
    const [observationData2, setObservationData2] = useState([]);
    const [observationData3, setObservationData3] = useState([]);

    const [observation1, setObservation1] = useState("")
    const [observation2, setObservation2] = useState("");
    const [observation3, setObservation3] = useState("");

    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);

    useEffect(() => {
        getData();
    }, [authUser])


    const getData = async () => {
        // setLoaderOption(true);
        if (authUser.deptId != null && authUser.branchId != null) {
            await axios.get(`${getClassDivisionURL}?sid=${authUser.deptId}&bid=${authUser.branchId}`)
                .then(res => {
                    setClassDivData(res.data);
                }).catch(err => {
                    console.log("Get class-div err " + err);
                })
        }
    }

    const getStudentsByClassDiv = async (cId, dId) => {
        //setLoaderOption(true);
        await axios.get(`${getStudentsByClassDivAPIURL}?classId=${cId}&divId=${dId}&year=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}`)
            .then((response) => {
                //console.log(cId + "=========" + dId);
                //const studentsByClassDivData = response.data.filter(e => e.currentClassId == cId && e.currentDivisionId == dId)
                //console.log(JSON.stringify(studentsByClassDivData));
                setStudentData(response.data);
                setLoaderOption(false);
            }).catch(err => {
                console.log("Get student list " + err)
            })
    }

    const getObservationByStudents = async (regId) => {
        await axios.get(`${getTermObsMasterAPIURL}?year=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}`)
            .then(res => {
                const obsType1Data = res.data.filter(e => e.observationType == 1)
                const obsType2Data = res.data.filter(e => e.observationType == 2)
                const obsType3Data = res.data.filter(e => e.observationType == 3)
                setObservationData1(obsType1Data);
                setObservationData2(obsType2Data);
                setObservationData3(obsType3Data);
            }).catch(err => {
                console.log("get term obs. master " + err)
            })
    }

    const getStudentProgressList = async (termId) => {
        if (classDivObj != null) {
            await axios.get(`${getStudentListForTermWiseObsAPIURL}?classId=${classDivObj.classId}&divId=${classDivObj.divisionId}&termId=${termId}&year=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}`)
                .then(res => {
                    //console.log(JSON.stringify(res.data));
                    // setObservation1(res.data.specialProgress)
                    // setObservation2(res.data.hobbies)
                    // setObservation3(res.data.needsImprovement)
                    setData(res.data);
                    setFilteredData(res.data);

                }).catch(err => {
                    console.log("get term wise obs.  " + err)
                })
        }
    }

    const getUpdatedTableData = async () => {
        if (classDivObj != null && termObj != '') {
            await axios.get(`${getStudentListForTermWiseObsAPIURL}?classId=${classDivObj.classId}&divId=${classDivObj.divisionId}&termId=${termObj}&year=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}`)
                .then(res => {
                    //console.log(JSON.stringify(res.data));
                    // setObservation1(res.data.specialProgress)
                    // setObservation2(res.data.hobbies)
                    // setObservation3(res.data.needsImprovement)
                    setData(res.data);
                    setFilteredData(res.data);

                }).catch(err => {
                    console.log("get term wise obs.  " + err)
                })
        }
    }

    const clearData = () => {
        setClassDivObj(null);
        setTermObj('');
        setStudentData([]);
        setStudentObj(null);
        setObservation1('');
        setObservation2('');
        setObservation3('');
        setObservationData1([]);
        setObservationData2([]);
        setObservationData3([]);
        setData([]);
        setFilteredData([]);
    }

    const saveBtnClick = async () => {
        setLoaderOption(true);
        const saveObj = {
            "regNo": studentObj.regNo,
            "termId": termObj,
            "specialProgress": observation1,
            "hobbies": observation2,
            "needsImprovement": observation3,
            "sessionYear": authUser.sessionYear,
            "sectionId": authUser.deptId,
            "branchId": authUser.branchId
        }
        // console.log(JSON.stringify(saveObj))
        await axios.post(saveTermWiseObservationAPIURL, saveObj)
            .then(res => {
                if (res.data == "SAVED") {
                    getData();
                    clearData();
                    setLoaderOption(false);
                    toast.success("Saved successfully.");
                }
                else {
                    setLoaderOption(false);
                    toast.error("Save failed.")
                }
            }).catch(err => {
                setLoaderOption(false);
                console.log("Term wise obs. save " + err);
                toast.error("Something went wrong please check.")
            })
    }

    const updateBtnClick = async () => {
        setLoaderOption(true);
        const saveObj = {
            "id": id,
            "regNo": studentObj.regNo,
            "termId": termObj,
            "specialProgress": observation1,
            "hobbies": observation2,
            "needsImprovement": observation3,
            "sessionYear": authUser.sessionYear,
            "sectionId": authUser.deptId,
            "branchId": authUser.branchId
        }
        // console.log(JSON.stringify(saveObj))
        await axios.post(saveTermWiseObservationAPIURL, saveObj)
            .then(res => {
                if (res.data == "SAVED") {
                    getData();
                    getUpdatedTableData();
                    setLoaderOption(false);
                    toast.success("Updated successfully.");
                }
                else {
                    setLoaderOption(false);
                    toast.error("Update failed.")
                }
            }).catch(err => {
                setLoaderOption(false);
                console.log("Term wise obs. update " + err);
                toast.error("Something went wrong please check.")
            })
    }

    const state = {
        button: 1
    }

    const onSubmit = e => {
        e.preventDefault();
        if (state.button == 1) {
            saveBtnClick();
        }
        else if (state.button == 2) {
            updateBtnClick();
        }
    }

    // useEffect(() => {
    //     function handlekeydownEvent(event) {
    //         if (event.key === "Enter") {
    //             console.log("Enter is pressed!")
    //             event.preventDefault();

    //             let btn1 = document.querySelector('.termWiseObsSaveBtn')
    //             if (btn1 !== null) {
    //                 btn1.click();
    //             }
    //         }
    //     }
    //     document.addEventListener('keypress', handlekeydownEvent)
    //     return () => {
    //         document.removeEventListener('keypress', handlekeydownEvent)
    //     }
    // }, []);


    const TableHeading = [
        { label: 'ID', key: 'id' },
        { label: `Student`, key: (authUser.branchMedium == 1) ? 'studentNameMr' : 'studentNameEn' },
        { label: `Special Progress`, key: 'specialProgress' },
        { label: `Interest / Hobby`, key: 'hobbies' },
        { label: `Needs Improvement `, key: 'needsImprovement' },
        { label: 'Edit', value: 'edit', isAction: true },
        { label: 'Delete', value: 'delete', isAction: true }];


    return (
        <>
            {
                (loaderOption == true)
                    ?
                    <Loader />
                    :
                    <>
                        <TitleLabel titleId={titleId} />
                        <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: "500px" }}>
                            <form onSubmit={onSubmit}>
                                <input type="hidden" value={id} onChange={(e) => setId(e.target.value)} name="" />

                                <div className='row'>
                                    <label className="col-sm-2" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }} >Class-Division :</label>
                                    <div className="col-sm-3">
                                        <Autocomplete
                                            id="combo-box-demo"
                                            options={classDivData}
                                            PaperComponent={({ children }) => (
                                                <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                            )}
                                            style={{ fontSize: '14px' }}
                                            getOptionLabel={option => (authUser.branchMedium == 1) ? option.classNameMr + " " + option.divisionNameMr : option.classNameEn + " " + option.divisionNameEn}
                                            size="small"
                                            onChange={(event, newValue) => {
                                                setClassDivObj(newValue);
                                                setTermObj('');
                                                setStudentObj(null);
                                                setObservation1('');
                                                setObservation2('');
                                                setObservation3('');
                                                setStudentData([]);
                                                setObservationData1([]);
                                                setObservationData2([]);
                                                setObservationData3([]);
                                                setData([]);
                                                setFilteredData([]);
                                                if (newValue != null) {
                                                    getStudentsByClassDiv(newValue.classId, newValue.divisionId)
                                                }
                                            }}
                                            onKeyPress={(e) => (e.key == "Enter") ? classDivObj : ""}
                                            value={classDivObj}
                                            renderInput={params => (
                                                <TextField {...params} margin='dense' label="Select Class-Division" variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                            )}
                                        />
                                    </div>

                                    <label className="col-sm-1" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Term :</label>
                                    <div className='col-sm-2'>
                                        <FormControl fullWidth margin='dense'>
                                            <InputLabel size="small" id='demo-simple-select-label' style={{ fontSize: '14px' }}>Term</InputLabel>
                                            <Select
                                                MenuProps={{ classes: { paper: classes.menuPaper } }}
                                                size='small'
                                                fullWidth
                                                labelId="demo-simple-select-label"
                                                required
                                                id="demo-simple-select"
                                                value={termObj}
                                                label="Term"
                                                onChange={(e) => {
                                                    setTermObj(e.target.value);
                                                    setStudentObj(null);
                                                    setObservation1('');
                                                    setObservation2('');
                                                    setObservation3('');
                                                    setObservationData1([]);
                                                    setObservationData2([]);
                                                    setObservationData3([]);
                                                    setData([]);
                                                    setFilteredData([]);
                                                    getStudentProgressList(e.target.value);
                                                }}
                                            >
                                                {subjectMarkTermData.filter(e => e.id != 3).map((item, index) => {
                                                    return (
                                                        <MenuItem value={item.id} key={index}>{(authUser.branchMedium == 1) ? item.nameMr : item.nameEn}</MenuItem>
                                                    )
                                                })}
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div>

                                <div className='row my-2'>
                                    <label className="col-sm-2" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }} >Student :</label>
                                    <div className="col-sm-6">
                                        <Autocomplete
                                            id="combo-box-demo"
                                            options={studentData}
                                            PaperComponent={({ children }) => (
                                                <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                            )}
                                            style={{ fontSize: '14px' }}
                                            getOptionLabel={option => option.regNo + " - " + ((authUser.branchMedium == 1) ? option.studentNameMr + " " + option.classMr + " " + option.divisionMr : option.studentNameEn + " " + option.classEn + " " + option.divisionEn)}
                                            size="small"
                                            onChange={(event, newValue) => {
                                                setStudentObj(newValue);
                                                setObservation1('');
                                                setObservation2('');
                                                setObservation3('');
                                                setObservationData1([]);
                                                setObservationData2([]);
                                                setObservationData3([]);
                                                if (newValue != null) {
                                                    getObservationByStudents(newValue.regNo);
                                                }
                                            }}
                                            onKeyPress={(e) => (e.key == "Enter") ? studentObj : ""}
                                            value={studentObj}
                                            renderInput={params => (
                                                <TextField {...params} margin='dense' label="Select Student" variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                            )}
                                        />
                                    </div>
                                </div>

                                <div className="row my-2">
                                    <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>विशेष प्रगती | <br />Special Progress :</label>
                                    <div className="col-sm-6">
                                        <Autocomplete
                                            id="combo-box-demo"
                                            PaperComponent={({ children }) => (
                                                <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                            )}
                                            freeSolo
                                            disableClearable
                                            onKeyDown={(e) => (e.key === " " && observation1.length === 0) ? e.preventDefault() : ""}
                                            onKeyPress={(e) => (e.key == "Enter") ? observation1 : ""}
                                            options={(observation1 == "") ? [] : observationData1}
                                            value={observation1}
                                            onChange={(event, newValue) => {
                                                setObservation1(((studentObj != null) ? ((studentObj.genderId == 1) ? newValue.boysObservation : newValue.girlsObservation) : ''));
                                            }}
                                            inputValue={observation1}
                                            onInputChange={(event, newInputValue) => {
                                                setObservation1(newInputValue);
                                            }}
                                            style={{ fontSize: '14px' }}
                                            getOptionLabel={option => {

                                                if (typeof option === 'string') {
                                                    return option;
                                                }
                                                // Add "xxx" option created dynamically
                                                if (option.inputValue) {
                                                    //console.log("option........"+JSON.stringify(option.inputValue))
                                                    return ((studentObj != null) ? ((studentObj.genderId == 1) ? option.boysObservation : option.girlsObservation) : '');
                                                }
                                                // Regular option
                                                return ((studentObj != null) ? ((studentObj.genderId == 1) ? option.boysObservation : option.girlsObservation) : '');
                                            }

                                            }
                                            size="small"
                                            renderInput={params => (
                                                <TextField {...params}
                                                    multiline
                                                    rows={2}
                                                    margin='dense' label="विशेष प्रगती | Special Progress" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                            )}
                                        />
                                    </div>
                                </div>

                                <div className="row my-2">
                                    <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>आवड / छंद | <br />Interest / Hobby :</label>
                                    <div className="col-sm-6">
                                        <Autocomplete
                                            id="combo-box-demo"
                                            PaperComponent={({ children }) => (
                                                <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                            )}
                                            freeSolo
                                            disableClearable
                                            onKeyDown={(e) => (e.key === " " && observation2.length === 0) ? e.preventDefault() : ""}
                                            onKeyPress={(e) => (e.key == "Enter") ? observation2 : ""}
                                            options={(observation2 == "") ? [] : observationData2}
                                            value={observation2}
                                            onChange={(event, newValue) => {
                                                setObservation2(((studentObj != null) ? ((studentObj.genderId == 1) ? newValue.boysObservation : newValue.girlsObservation) : ''));
                                            }}
                                            inputValue={observation2}
                                            onInputChange={(event, newInputValue) => {
                                                setObservation2(newInputValue);
                                            }}
                                            style={{ fontSize: '14px' }}
                                            getOptionLabel={option => {

                                                if (typeof option === 'string') {
                                                    return option;
                                                }
                                                // Add "xxx" option created dynamically
                                                if (option.inputValue) {
                                                    //console.log("option........"+JSON.stringify(option.inputValue))
                                                    return ((studentObj != null) ? ((studentObj.genderId == 1) ? option.boysObservation : option.girlsObservation) : '')
                                                }
                                                // Regular option
                                                return ((studentObj != null) ? ((studentObj.genderId == 1) ? option.boysObservation : option.girlsObservation) : '')
                                            }

                                            }
                                            size="small"
                                            renderInput={params => (
                                                <TextField {...params}
                                                    multiline
                                                    rows={2}
                                                    margin='dense' label="आवड / छंद | Interest / Hobby" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                            )}
                                        />
                                    </div>
                                </div>

                                <div className="row my-2">
                                    <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>सुधारणा आवश्यक |<br /> Needs Improvement :</label>
                                    <div className="col-sm-6">
                                        <Autocomplete
                                            id="combo-box-demo"
                                            PaperComponent={({ children }) => (
                                                <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                            )}
                                            freeSolo
                                            disableClearable
                                            onKeyDown={(e) => (e.key === " " && observation3.length === 0) ? e.preventDefault() : ""}
                                            onKeyPress={(e) => (e.key == "Enter") ? observation3 : ""}
                                            options={(observation3 == "") ? [] : observationData3}
                                            value={observation3}
                                            onChange={(event, newValue) => {
                                                setObservation3(((studentObj != null) ? ((studentObj.genderId == 1) ? newValue.boysObservation : newValue.girlsObservation) : ''));
                                            }}
                                            inputValue={observation3}
                                            onInputChange={(event, newInputValue) => {
                                                setObservation3(newInputValue);
                                            }}
                                            style={{ fontSize: '14px' }}
                                            getOptionLabel={option => {

                                                if (typeof option === 'string') {
                                                    return option;
                                                }
                                                // Add "xxx" option created dynamically
                                                if (option.inputValue) {
                                                    //console.log("option........"+JSON.stringify(option.inputValue))
                                                    return ((studentObj != null) ? ((studentObj.genderId == 1) ? option.boysObservation : option.girlsObservation) : '');
                                                }
                                                // Regular option
                                                return ((studentObj != null) ? ((studentObj.genderId == 1) ? option.boysObservation : option.girlsObservation) : '');
                                            }

                                            }
                                            size="small"
                                            renderInput={params => (
                                                <TextField {...params}
                                                    multiline
                                                    rows={2}
                                                    margin='dense' label="सुधारणा आवश्यक | Needs Improvement" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                            )}
                                        />
                                    </div>
                                </div>

                                <div className="row mt-3">
                                    <div className="col-sm-12 offset-sm-2">
                                        <button type='submit' onClick={() => (state.button = 1)} className={saveBtn}>Save</button>
                                        <button type='submit' onClick={() => (state.button = 2)} className={updateBtn}>Update</button>
                                        <button type='button' onClick={() => clearData()} className='btn btn-sm btn-danger ml-2'>Cancel</button>
                                    </div>
                                </div>
                            </form>

                            <div className="mt-3">
                                {(() => {
                                    if (data.length != 0 || filteredData.length != 0 || termObj != '') {
                                        return (
                                            <TermWiseObsTable
                                                TableHeading={TableHeading}
                                                data={data}
                                                filteredData={filteredData}
                                                setFilteredData={setFilteredData}
                                                getData={getData}
                                                setId={setId}
                                                setClassDivObj={setClassDivObj}
                                                classDivData={classDivData}
                                                setTermObj={setTermObj}
                                                setStudentObj={setStudentObj}
                                                studentData={studentData}
                                                setObservation1={setObservation1}
                                                setObservation2={setObservation2}
                                                setObservation3={setObservation3}
                                                observationData1={observationData1}
                                                observationData2={observationData2}
                                                observationData3={observationData3}
                                                getUpdatedTableData={getUpdatedTableData}
                                                clearData={clearData}
                                                setSaveBtn={setSaveBtn}
                                                setUpdateBtn={setUpdateBtn}
                                                displayNoneBtnStyle={displayNoneBtnStyle}
                                                updateBtnStyle={updateBtnStyle}
                                            />
                                        )
                                    }
                                })()}
                            </div>
                        </div>
                    </>
            }
        </>
    )
}

export default TermWiseObservation