import React, { useState, useEffect } from 'react';
import TitleLabel from "../../CommonComponent/TitleLabel";
import axios from 'axios';
import Paper from "@mui/material/Paper";
import TextField from '@mui/material/TextField';
import Autocomplete from "@mui/material/Autocomplete";
import { ToastContainer, toast } from 'react-toastify';
import { useSelector } from "react-redux";
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import DatePicker from '@mui/lab/DatePicker';
import moment from 'moment';
import { getClassWithDiv, currentStudentAgePdfReport, currentStudentAgeExcelReport } from '../Services/CurrentStudentAgeListAPIURL';
import { getExportedReportAPIURL } from '../../CommonServices/APIURL';
import Loader from '../../CommonComponent/Loader';

import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles(theme => ({
    select: {
        maxHeight: 160,
        "& ul": {
            backgroundColor: "#F4ECF7",
            //maxHeight: 160,
        },
        "& li": {
            // backgroundColor: "#F4ECF7", fontWeight: "550", fontSize: "14px" 
            fontSize: "14px",
            fontWeight: "550"
        },
    },

}));

const CurrentStudentAgeListReport = () => {
    const classes = useStyles();
    const authUser = useSelector((state) => state.user.value);
    const titleId = "Current Student Age List";

    const [loaderOption, setLoaderOption] = useState(false);

    const [classDivObj, setClassDivObj] = useState(null);
    const [divisionData, setDivisionData] = useState([]);
    const [langOption, setLangOption] = useState(1);
    const [reportOption, setReportOption] = useState(1);
    const [date, setDate] = useState(null);

    useEffect(() => {
        getData();
    }, [authUser]);

    const getData = async () => {
        if (authUser.deptId != null || authUser.branchId != null) {
            await axios(`${getClassWithDiv}?sid=${authUser.deptId}&bid=${authUser.branchId}`)
                .then(res => {
                    setDivisionData(res.data);
                })
        }
    }

    const printCurrentStudentAgeListPdf = async () => {
        setLoaderOption(true);
        await axios(`${currentStudentAgePdfReport}?sid=${authUser.deptId}&bid=${authUser.branchId}&langId=${langOption}&classId=${classDivObj.classId}&divId=${classDivObj.divisionId}&year=${authUser.sessionYear}&date=${moment(date).format("DD/MM/YYYY")}`)
            .then(res => {
                // console.log(res.data);
                if (res.data === "NOTFOUND") {
                    setLoaderOption(false);
                    toast.warn("Data not found for this class-division.")

                }
                else if (res.data != null) {
                    window.open(`${getExportedReportAPIURL}${res.data}`)
                    setLoaderOption(false);
                }

            })
            .catch((error) => {

                toast.error("Error Occurred, Try after sometime.");
                console.log("error name: " + error);
                setLoaderOption(false);
            });
    }

    const printCurrectStudentAgeListExcel = async () => {
        setLoaderOption(true);
        await axios(`${currentStudentAgeExcelReport}?sid=${authUser.deptId}&bid=${authUser.branchId}&langId=${langOption}&classId=${classDivObj.classId}&divId=${classDivObj.divisionId}&year=${authUser.sessionYear}&date=${moment(date).format("DD/MM/YYYY")}`)
            .then(res => {
                // console.log(res.data);
                if (res.data === "NOTFOUND") {
                    setLoaderOption(false);
                    toast.warn("Data not found for this class-division.")

                }
                else if (res.data != null) {
                    window.open(`${getExportedReportAPIURL}${res.data}`)
                    setLoaderOption(false);
                }

            })
            .catch((error) => {
                toast.error("Error Occurred, Try after sometime.");
                console.log("error name: " + error);
                setLoaderOption(false);
            });
    }

    const getStudentAgeList = e => {
        e.preventDefault();
        if (reportOption == 1) {
            printCurrentStudentAgeListPdf();
        }
        else if (reportOption == 2) {
            printCurrectStudentAgeListExcel();
        }
    }

    if (loaderOption == true) {
        return <Loader />
    }
    else {
        return (
            <>
                <TitleLabel titleId={titleId} />
                <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: '350px' }}>
                    <form onSubmit={getStudentAgeList}>
                        <div className='row'>
                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Language :</label>
                            <div className='col-sm-3'>
                                <FormControl fullWidth margin='dense'>
                                    <InputLabel size="small" id='demo-simple-select-label' style={{ fontSize: '14px' }}>Select Language</InputLabel>
                                    <Select MenuProps={{ classes: { paper: classes.select } }} size='small' required fullWidth labelId="demo-simple-select-label"
                                        id="demo-simple-select" value={langOption} label="Select Language"
                                        onChange={(e) => {
                                            setLangOption(e.target.value);
                                            setReportOption(1);
                                            setClassDivObj(null);
                                        }}>
                                        <MenuItem value={1}>Marathi</MenuItem>
                                        <MenuItem value={2}>English</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                        </div>

                        <div className='row my-2'>
                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Report Option :</label>
                            <div className='col-sm-3'>
                                <FormControl fullWidth margin='dense'>
                                    <InputLabel size="small" id='demo-simple-select-label' style={{ fontSize: '14px' }}>Select Option</InputLabel>
                                    <Select
                                        MenuProps={{ classes: { paper: classes.select } }}
                                        size='small' required fullWidth labelId="demo-simple-select-label" id="demo-simple-select" value={reportOption}
                                        label="Select Option"
                                        onChange={(e) => {
                                            setReportOption(e.target.value);
                                            setClassDivObj(null);
                                        }}>
                                        <MenuItem value={1}>PDF</MenuItem>
                                        <MenuItem value={2}>Excel</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                        </div>

                        <div className='row my-2'>
                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Class - Division :</label>
                            <div className='col-sm-3'>
                                <Autocomplete id="combo-box-demo" options={divisionData} style={{ fontSize: '14px' }} PaperComponent={({ children }) => (
                                    <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                )}
                                    onChange={(event, newValue) => { setClassDivObj(newValue) }}
                                    value={classDivObj}
                                    getOptionLabel={option => (authUser.branchMedium == 1) ? option.classNameMr + " " + option.divisionNameMr : option.classNameEn + " " + option.divisionNameEn}
                                    size="small"
                                    renderInput={params => (
                                        <TextField {...params} margin='dense' label="Select Class-Division" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                    )}/>
                            </div>
                        </div>
                        <div className='row my-2'>
                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Date :</label>
                            <div className='col-sm-3' >
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker label="Date" inputFormat="dd/MM/yyyy" value={date} onChange={(newValue) => { setDate(newValue); }}
                                        renderInput={(params) =>
                                            <TextField {...params} margin='dense' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }}
                                                required={true} fullWidth autoComplete='off' size="small" />} />
                                </LocalizationProvider>
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className='col-sm-12 offset-sm-2'>
                                <button type='submit' className='btn btn-primary btn-sm'>
                                    Show
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
                <ToastContainer position="top-right" theme="colored" />
            </>

        )
    }

}

export default CurrentStudentAgeListReport