import React, { useState, useEffect } from 'react';
import TitleLabel from "../../CommonComponent/TitleLabel";
import axios from 'axios';
import Autocomplete from "@mui/material/Autocomplete";
import TextField from '@mui/material/TextField';
import {
    getStudentList, getStudentListEdit, getStudentLcNo, GetProgressConduct, GetRemark, PostStudentLc,
    GetStudentLcEditData, GetStudentLcDataByRegNo, PutStudentLc,
    getLeftStudentDetailsAPIURL,
    getStudentsListByRegNoAPIURL
} from '../../CommonServices/LeavingCertificateAPIURL';
import { useSelector } from "react-redux";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import Loader from "../../CommonComponent/Loader";
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Paper from "@mui/material/Paper";
import { getCaste, getClass, getDivision, getSubCaste, getReligion } from '../../CommonServices/LeavingCertificateAPIURL';
import AddStudentBySearching from '../../CommonComponent/AddStudenyBySearching';
import { serachStudentByRegNoAPIURL } from '../../Sanstha/Services/StudentProfileAPIURL';
// import { getStudentList } from '../Services/BonafideAPIURL';


const LeavingCertificateForm = () => {
    const [titleId, setTitleId] = useState("");
    const authUser = useSelector((state) => state.user.value);
    let { lcno, EditFlag, regNum } = useParams();
    const navigate = useNavigate();
    const DeleteFlag = 0;
    const printCount = 0;

    // loader on/off
    const [loaderOption, setLoaderOption] = useState(false);

    //Note: This below states are declared for Save & Update(Disabled/unDisabled)
    const saveBtnStyle = "leavingSaveBtn btn btn-primary btn-sm";
    const updateBtnStyle = "leavingUpdateBtn btn btn-primary btn-sm";
    const displayNoneBtnStyle = "d-none";
    const [saveBtn, setSaveBtn] = useState(saveBtnStyle);
    const [updateBtn, setUpdateBtn] = useState(displayNoneBtnStyle);

    const [saveBtnDisabled, setSaveBtnDisabled] = useState(false);
    const [updateBtnDisabled, setUpdateBtnDisabled] = useState(false);

    const [id, setId] = useState();
    const [studentData, setStudentData] = useState([]);
    const [remarksData, setRemarksData] = useState([]);
    const [progressConductData, setProgressConductData] = useState([]);

    const [religionData, setReligionData] = useState([]);
    const [casteData, setCasteData] = useState([]);
    const [subCasteData, setSubCasteData] = useState([]);
    const [currentStdData, setCurrentStdData] = useState([]);
    const [currentDivData, setCurrentDivData] = useState([]);

    const [studentObj, setStudentObj] = useState(null);
    const [lcDate, setLcDate] = useState(null);
    const [schoolLeftDate, setSchoolLeftDate] = useState(null);
    const [lcReceivedDate, setLcReceivedDate] = useState(null);
    const [lcNo, setLcNo] = useState([]);
    const [reasonOfLeavingMr, setReasonOfLeavingMr] = useState("");
    const [reasonOfLeavingEn, setReasonOfLeavingEn] = useState("");
    const [sinceWhen, setSinceWhen] = useState(null);
    //console.log("Since When"+sinceWhen);
    const [remarksId, setRemarksId] = useState(null);
    const [remarksObj, setRemarksObj] = useState(null);
    const [remarksInputMrObj, setRemarksInputMrObj] = useState("");
    const [remarksInputEnObj, setRemarksInputEnObj] = useState("");
    const [progressMrId, setProgressMrId] = useState(null);
    const [progressEnId, setProgressEnId] = useState(null);
    const [progressMrObj, setProgressMrObj] = useState(null);
    const [progressEnObj, setProgressEnObj] = useState(null);
    const [conductMrId, setConductMrId] = useState(null);
    const [conductEnId, setConductEnId] = useState(null);
    const [conductMrObj, setConductMrObj] = useState(null);
    const [conductEnObj, setConductEnObj] = useState(null);

    const [regNo, setRegNo] = useState("");
    const [admissionDate, setAdmissionDate] = useState("");
    const [birthDate, setBirthDate] = useState("");
    const [birthVillageMr, setBirthVillageMr] = useState("");
    const [birthVillageEn, setBirthVillageEn] = useState("");
    const [birthTalukaMr, setBirthTalukaMr] = useState("");
    const [birthTalukaEn, setBirthTalukaEn] = useState("");
    const [birthDistrictMr, setBirthDistrictMr] = useState("");
    const [birthDistrictEn, setBirthDistrictEn] = useState("");
    const [birthStateMr, setBirthStateMr] = useState("");
    const [birthStateEn, setBirthStateEn] = useState("");
    const [birthCountryMr, setBirthCountryMr] = useState("");
    const [birthCountryEn, setBirthCountryEn] = useState("");
    const [motherNameMr, setMotherNameMr] = useState("");
    const [motherNameEn, setMotherNameEn] = useState("");
    const [oldSchool, setOldSchool] = useState("");
    const [oldSchoolStd, setOldSchoolStd] = useState("");
    const [studentReligionMr, setStudentReligionMr] = useState("");
    const [studentReligionEn, setStudentReligionEn] = useState("");
    const [studentCasteMr, setStudentCasteMr] = useState("");
    const [studentCasteEn, setStudentCasteEn] = useState("");
    const [studentSubCasteMr, setStudentSubCasteMr] = useState("");
    const [studentSubCasteEn, setStudentSubCasteEn] = useState("");
    const [studentCurrentStdMr, setStudentCurrentStdMr] = useState("");
    const [studentCurrentStdEn, setStudentCurrentStdEn] = useState("");
    const [studentCurrentDivMr, setStudentCurrentDivMr] = useState("");
    const [studentCurrentDivEn, setStudentCurrentDivEn] = useState("");

    const [countData, setCountData] = useState(null);
    const [offset, setOffset] = useState(0);
    const [limits, setLimits] = useState(500);
    const [numCal, setNumCall] = useState(0);
    // console.log("data=====" + JSON.stringify(studentData) + "length=====" + studentData.length)

    const currentStusZero = studentData.filter(e => e.currentStatus == 0)
    // console.log("currentStusZero length=====" + currentStusZero.length + "============" + "studentData length" + studentData.length)

    const [studentId, setStudentId] = useState("");
    const [studentLcNo, setStudentLcNo] = useState('');
    const [studentDetails, setStudentDetails] = useState({});
    const [division, setDivision] = useState("");
    const [classData, setclassData] = useState("");
    const [selectedStudent, setSelectedStudent] = useState("");


    useEffect(() => {
        if (EditFlag == 1) {
            setTitleId("Create Leaving Certificate")
        }
        else if (EditFlag == 2) {
            setTitleId("Update Leaving Certificate")
        }
        getData();
    }, [numCal, authUser]);

    const getLcURL = `${GetStudentLcEditData}?lcNo=${lcno}&sid=${authUser.deptId}&bid=${authUser.branchId}&regNo=${regNum}`
    //console.log(`${GetStudentLcEditData}?lcNo=${lcno}&sid=${authUser.deptId}&bid=${authUser.branchId}`)
    const getEditDetails = () => {
        setLoaderOption(true);
        axios.all([
            axios.get(getLcURL)
        ]).then(
            axios.spread((respOne) => {
                //console.log(moment(respOne.data.sinceWhen).format("DD/MM/YYYY"))
                setSaveBtn(displayNoneBtnStyle);
                setUpdateBtn(updateBtnStyle);
                setLcNo(respOne.data.lcNo);
                setLcDate(respOne.data.lcDate);
                setSchoolLeftDate(respOne.data.schoolLeftDate);
                setLcReceivedDate(respOne.data.lcReceiveDate);
                setReasonOfLeavingMr(respOne.data.reasonOfLeavingMr);
                setReasonOfLeavingEn(respOne.data.reasonOfLeavingEn);
                setSinceWhen(respOne.data.sinceWhen);
                setRemarksInputMrObj(respOne.data.remarkMr);
                setRemarksInputEnObj(respOne.data.remarkEn);
                const selectProgressMr = respOne.data.progressMrId;
                const selectProgressEn = respOne.data.progressEnId;
                const selectConductMr = respOne.data.conductMrId;
                const selectConductEn = respOne.data.conductEnId;
                const selectStudent = respOne.data.regNo;

                axios.get(`${GetStudentLcDataByRegNo}?regNo=${selectStudent}&sid=${authUser.deptId}&bid=${authUser.branchId}`)
                    .then((response) => {
                        // console.log("Filtered Data: ", response.data);

                        setRegNo(response.data.regCode);
                        setBirthDate(moment(response.data.birthDate).format("DD/MM/YYYY"));
                        setBirthVillageMr(response.data.birthPlaceCityMr);
                        setBirthVillageEn(response.data.birthPlaceCityEn);
                        setBirthTalukaMr(response.data.birthPlaceTalukaMr);
                        setBirthTalukaEn(response.data.birthPlaceTalukaEn);
                        setBirthDistrictMr(response.data.birthPlaceDistrictMr);
                        setBirthDistrictEn(response.data.birthPlaceDistrictEn);
                        setBirthStateMr(response.data.birthPlaceStateMr)
                        setBirthStateEn(response.data.birthPlaceStateEn);
                        setBirthCountryMr(response.data.birthPlaceCountryMr)
                        setBirthCountryEn(response.data.birthPlaceCountryEn);
                        setMotherNameMr(response.data.motherNameMr);
                        setMotherNameEn(response.data.motherNameEn);
                        setOldSchool(response.data.oldSchool);
                        setStudentReligionMr(response.data.religionNameMr);
                        setStudentReligionEn(response.data.religionNameEn);
                        setStudentCasteMr(response.data.casteNameMr);
                        setStudentCasteEn(response.data.casteNameEn);
                        setStudentSubCasteMr(response.data.subCasteNameMr);
                        setStudentSubCasteEn(response.data.subCasteNameEn);
                        setStudentCurrentStdMr(response.data.currentClassMr);
                        setStudentCurrentStdEn(response.data.currentClassEn);
                        setStudentCurrentDivMr(response.data.currentDivisionMr);
                        setStudentCurrentDivEn(response.data.currentDivisionEn);
                        setAdmissionDate(response.data.admissionDate);
                        setOldSchoolStd(response.data.oldSchoolStd);
                        //setLoaderOption(false);
                    })
                //console.log(`${getStudentList}?sid=${authUser.deptId}&bid=${authUser.branchId}`)


                axios.get(`${GetRemark}`)
                    .then((response) => {
                        setRemarksData(response.data);
                        //setLoaderOption(false);
                    })

                axios.get(`${GetProgressConduct}`)
                    .then((response) => {
                        setProgressConductData(response.data);
                        setProgressMrObj(response.data.find(e => e.id == selectProgressMr));
                        setProgressMrId(selectProgressMr);
                        setProgressEnObj(response.data.find(e => e.id == selectProgressEn));
                        setProgressEnId(selectProgressEn);
                        setConductMrObj(response.data.find(e => e.id == selectConductMr));
                        setConductMrId(selectConductMr);
                        setConductEnObj(response.data.find(e => e.id == selectConductEn));
                        setConductEnId(selectConductEn);
                        //setLoaderOption(false);
                    })

                // axios.get(`${getStudentListEdit}?sid=${authUser.deptId}&bid=${authUser.branchId}`)
                //     .then((response) => {
                //         //setLoaderOption(true);
                //         const lcCreated = (response.data).filter(e => e.currentStatus == 1)
                //         setStudentData(lcCreated);
                //         setStudentObj((response.data).find(e => e.regCode == selectStudent));
                //         setStudentId(selectStudent);
                //         setLoaderOption(false);
                //     })
                axios(`${serachStudentByRegNoAPIURL}?regNo=${selectStudent}&sid=${authUser.deptId}&bid=${authUser.branchId}`)
                    .then(res => {
                        if (res.data == "NOTFOUND") {
                            toast.warn("Student not fount with this name.")
                        }
                        else {
                            const filteredData = res.data;

                            // Log the filtered data for debugging
                            // console.log("Filtered Data: ", filteredData);

                            // Set filtered data state
                            const classWithDivision = `${authUser.branchMedium == 1 ? filteredData.currentClassNameMr : filteredData.currentClassNamEn} - ${authUser.branchMedium == 1 ? filteredData.currentDivNameMr : filteredData.currentDivNamEn}`;

                            // Set filtered data state
                            //setclassData(authUser.branchMedium == 1 ? filteredData.currentClassNameMr : filteredData.currentClassNamEn);
                            // setDivision(authUser.branchMedium == 1 ? filteredData.currentDivNameMr : filteredData.currentDivNamEn);
                            setclassData(classWithDivision);
                            setStudentId(filteredData.regCode);
                            setStudentDetails(filteredData)
                            // setStudRegNo(filteredData.regCode);
                            setSelectedStudent(authUser.branchMedium == 1 ? filteredData.studentNameMr : filteredData.studentNameEn);
                            setStudentLcNo(filteredData.lcNo);
                            setLoaderOption(false);
                        }
                    });

            })
        )
    }

    const getDetails = async () => {
        // console.log(`${getStudentList}?sid=${authUser.deptId}&bid=${authUser.branchId}`)
        //setLoaderOption(true);
        if (authUser.deptId != null && authUser.branchId != null) {
            // await axios.get(`${getStudentList}?sid=${authUser.deptId}&bid=${authUser.branchId}`)
            //     .then((response) => {
            //         const lcNotCreated = (response.data).filter(e => e.currentStatus == 0)
            //         setStudentData(lcNotCreated);
            //         //setLoaderOption(false);
            //     })

            if (offset === 0) {
                // setLoaderOption(true)
            }
            // console.log(`${getLeftStudentDetailsAPIURL}?sid=${authUser.deptId}&bid=${authUser.branchId}&limit=${limits}&offset=${offset}`)
            await axios.get(`${getLeftStudentDetailsAPIURL}?sid=${authUser.deptId}&bid=${authUser.branchId}&limit=${limits}&offset=${offset}`)
                .then((res) => {
                    if (res.data !== null) {
                        const tempData = res.data;
                        //console.log(tempData.length)

                        setStudentData((studentData.concat(tempData)));
                        // setStudentData(studentData.concat(tempData));
                        setLoaderOption(false)
                        //console.log("Data : " + JSON.stringify(data))
                        // console.log("____________________________________________")
                        if (tempData.length === limits) {
                            setOffset(offset + limits);
                            setNumCall(numCal + 1);
                            //console.log("Offset ===============" + offset);
                        }
                    }
                })


            await axios.get(`${getStudentLcNo}?sid=${authUser.deptId}&bid=${authUser.branchId}&year=${authUser.sessionYear}`)
                .then((response) => {
                    setLcNo(response.data);
                    //setLoaderOption(false);
                })
        }

        await axios.get(`${GetRemark}`)
            .then((response) => {
                setRemarksData(response.data);
                //setLoaderOption(false);
            })

        await axios.get(`${GetProgressConduct}`)
            .then((response) => {
                setProgressConductData(response.data);
                //setLoaderOption(false);
            })

        await axios.get(`${getReligion}`)
            .then((response) => {
                setReligionData(response.data);
                //setLoaderOption(false);
            })

        await axios.get(`${getCaste}`)
            .then((response) => {
                setCasteData(response.data);
                //setLoaderOption(false);
            })

        await axios.get(`${getSubCaste}`)
            .then((response) => {
                setSubCasteData(response.data);
                //setLoaderOption(false);
            })

        await axios.get(`${getClass}`)
            .then((response) => {
                setCurrentStdData(response.data);
                //setLoaderOption(false);
            })

        await axios.get(`${getDivision}`)
            .then((response) => {
                setCurrentDivData(response.data);
                setLoaderOption(false);
            })


    }

    const getData = async () => {
        if (EditFlag == 2) {
            getEditDetails();
            setSaveBtnDisabled(true);
            setUpdateBtnDisabled(false);
        }
        else if (EditFlag == 1) {
            getDetails();
            setSaveBtnDisabled(false);
            setUpdateBtnDisabled(true);
        }
    }

    const studentDataGet = async (item) => {
        // console.log(JSON.stringify(item));
        // const newstudList = studentData.find(e => e.regCode == item.regCode)
        // console.log(newstudList)
        // setLoaderOption(true);
        // console.log(`${getStudentsListByRegNoAPIURL}?sid=${authUser.deptId}&bid=${authUser.branchId}&regNo=${item.regCode}`)
        await axios.get(`${getStudentsListByRegNoAPIURL}?sid=${authUser.deptId}&bid=${authUser.branchId}&regNo=${item.regCode}`)
            .then((response) => {
                response.data.map(e => {
                    setRegNo(e.regCode);
                    setBirthDate(moment(e.birthDate).format("DD/MM/YYYY"));
                    setBirthVillageMr(e.birthPlaceCityMr);
                    setBirthVillageEn(e.birthPlaceCityEn);
                    setBirthTalukaMr(e.birthPlaceTalukaMr);
                    setBirthTalukaEn(e.birthPlaceTalukaEn);
                    setBirthDistrictMr(e.birthPlaceDistrictMr);
                    setBirthDistrictEn(e.birthPlaceDistrictEn);
                    setBirthStateMr(e.birthPlaceStateMr)
                    setBirthStateEn(e.birthPlaceStateEn);
                    setBirthCountryMr(e.birthPlaceCountryMr)
                    setBirthCountryEn(e.birthPlaceCountryEn);
                    setMotherNameMr(e.motherNameMr);
                    setMotherNameEn(e.motherNameEn);
                    setOldSchool(e.oldSchool);
                    setStudentReligionMr(e.religionNameMr);
                    setStudentReligionEn(e.religionNameEn);
                    setStudentCasteMr(e.casteNameMr);
                    setStudentCasteEn(e.casteNameEn);
                    setStudentSubCasteMr(e.subCasteNameMr);
                    setStudentSubCasteEn(e.subCasteNameEn);
                    setStudentCurrentStdMr(e.currentClassMr);
                    setStudentCurrentStdEn(e.currentClassEn);
                    setStudentCurrentDivMr(e.currentDivisionMr);
                    setStudentCurrentDivEn(e.currentDivisionEn);
                    setAdmissionDate(e.admissionDate);
                    setOldSchoolStd(e.oldSchoolStd);
                    setLoaderOption(false);
                })
            })

    }

    const saveBtnClick = () => {
        setLoaderOption(true);
        if (moment(lcDate).format("DD/MM/YYYY") != "Invalid date" &&
            moment(schoolLeftDate).format("DD/MM/YYYY") != "Invalid date" &&
            moment(lcReceivedDate).format("DD/MM/YYYY") != "Invalid date" &&
            moment(sinceWhen).format("DD/MM/YYYY") != "Invalid date") {
            const saveObject = {
                id: id,
                lcNo: lcNo,
                lcDate: moment(lcDate).format("DD/MM/YYYY"),
                regNo: regNo,
                progressMrId: progressMrId,
                progressEnId: progressEnId,
                conductMrId: conductMrId,
                conductEnId: conductEnId,
                reasonOfLeavingMr: reasonOfLeavingMr,
                reasonOfLeavingEn: reasonOfLeavingEn,
                remarkMr: remarksInputMrObj,
                remarkEn: remarksInputEnObj,
                sinceWhen: moment(sinceWhen).format("DD/MM/YYYY"),
                schoolLeftDate: moment(schoolLeftDate).format("DD/MM/YYYY"),
                lcReceiveDate: moment(lcReceivedDate).format("DD/MM/YYYY"),
                isDeleted: DeleteFlag,
                printCount: printCount,
                secessionYear: authUser.sessionYear,
                sectionId: authUser.deptId,
                branchId: authUser.branchId,
                createdBy: authUser.id,
                userIp: authUser.headers.userIp,

            };
            //console.log("Saved Data=" + JSON.stringify(saveObject));
            axios.post(`${PostStudentLc}`, saveObject)
                .then((response) => {
                    if (response.data != null) {
                        getData();
                        setLoaderOption(false);
                        navigate("/Home/ad207/");
                        toast.success("Saved successfully.");

                    } else {
                        setLoaderOption(false);
                        toast.danger("Operation failed.");
                    }
                });
            setSaveBtn(saveBtnStyle);
            setUpdateBtn(displayNoneBtnStyle);
            //toast.success("Valid")
        }
        else {
            setLoaderOption(false);
            toast.error("Improper date, please check.")
        }


    };

    const updateBtnClick = () => {
        setLoaderOption(true);
        if (moment(lcDate).format("DD/MM/YYYY") != "Invalid date" &&
            moment(schoolLeftDate).format("DD/MM/YYYY") != "Invalid date" &&
            moment(lcReceivedDate).format("DD/MM/YYYY") != "Invalid date" &&
            moment(sinceWhen).format("DD/MM/YYYY") != "Invalid date") {
            const updateObject = {
                lcNo: lcNo,
                lcDate: moment(lcDate).format("DD/MM/YYYY"),
                regNo: regNo,
                progressMrId: progressMrId,
                progressEnId: progressEnId,
                conductMrId: conductMrId,
                conductEnId: conductEnId,
                reasonOfLeavingMr: reasonOfLeavingMr,
                reasonOfLeavingEn: reasonOfLeavingEn,
                remarkMr: remarksInputMrObj,
                remarkEn: remarksInputEnObj,
                sinceWhen: moment(sinceWhen).format("DD/MM/YYYY"),
                schoolLeftDate: moment(schoolLeftDate).format("DD/MM/YYYY"),
                lcReceiveDate: moment(lcReceivedDate).format("DD/MM/YYYY"),
                isDeleted: DeleteFlag,
                printCount: printCount,
                secessionYear: authUser.sessionYear,
                sectionId: authUser.deptId,
                branchId: authUser.branchId,
                createdBy: authUser.id,
                userIp: authUser.headers.userIp,
                userLat: authUser.headers.userLat,
                userLong: authUser.headers.userLong,
                deviceSign: authUser.headers.deviceSign,
                deviceType: authUser.headers.deviceType,
                deviceOS: authUser.headers.deviceOS,
                browserName: authUser.headers.browserName,
                browserVersion: authUser.headers.browserVersion
            };
            // console.log("Updated Data=" + JSON.stringify(updateObject));
            axios.put(`${PutStudentLc}?lcNo=${lcno}&sid=${authUser.deptId}&bid=${authUser.branchId}`, updateObject)
                .then((response) => {
                    if (response.data != null) {
                        setSaveBtn(saveBtnStyle);
                        setUpdateBtn(displayNoneBtnStyle);
                        getData();
                        setLoaderOption(false);
                        navigate("/Home/ad207/");
                        toast.success("Updated successfully.");
                    }

                });
            //toast.success("Valid")
        }
        else {
            setLoaderOption(false);
            toast.error("Improper date, please check.")
        }
    };

    const ClearData = () => {
        navigate("/Home/ad207/")
        setSaveBtn(saveBtnStyle);
        setUpdateBtn(displayNoneBtnStyle);
    }

    const state = {
        button: 1
    };

    const onSubmit = e => {
        e.preventDefault();
        if (state.button === 1) {
            saveBtnClick();
        }
        if (state.button === 2) {
            updateBtnClick();
        }
    };


    useEffect(() => {

        function handlekeydownEvent(event) {
            if (event.key === "Enter") {
                //console.log("Enter is pressed!")
                event.preventDefault();
                if (EditFlag == 1) {
                    // console.log('save Enter is pressed!');
                    let btn1 = document.querySelector('.leavingSaveBtn')
                    if (btn1 !== null) {
                        btn1.click();
                    }
                    setSaveBtnDisabled(false);
                    setUpdateBtnDisabled(true);
                }
                if (EditFlag == 2) {
                    // console.log('update Enter is pressed!');
                    let btn2 = document.querySelector('.leavingUpdateBtn')
                    if (btn2 !== null) {
                        btn2.click();
                    }
                    setSaveBtnDisabled(true);
                    setUpdateBtnDisabled(false);
                }
            }
        }

        document.addEventListener('keypress', handlekeydownEvent)
        return () => {
            document.removeEventListener('keypress', handlekeydownEvent)
        }

    }, []);


    if (loaderOption == true) {
        return <Loader />
    }
    else {
        return (
            <>
                <TitleLabel titleId={titleId} />
                <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px" }}>
                    <form onSubmit={onSubmit}>
                        <input type="hidden" value={id} onChange={(e) => setId(e.target.value)} name="" />
                        {/* <div className='row'>
                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Student :</label>
                            <div className="col-sm-6">
                                <Autocomplete
                                    id="combo-box-demo"
                                    PaperComponent={({ children }) => (
                                        <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                    )}
                                    readOnly={(EditFlag == 2) ? true : false}
                                    options={studentData}
                                    onChange={(event, newValue) => {
                                        setStudentId(newValue.id);
                                        setStudentObj(newValue);
                                        studentDataGet(newValue);
                                    }}
                                    onKeyPress={(e) => (e.key == "Enter") ? studentObj : ""}
                                    value={studentObj}
                                    //disabled={option=>(option.currentStatus)}                                    
                                    style={{ fontSize: '14px' }}
                                    getOptionLabel={option => (authUser.branchMedium == 1) ? option.regCode + " - " + option.studentNameMr : option.regCode + " - " + option.studentNameEn}
                                    size="small"
                                    renderInput={params => (
                                        <TextField {...params} margin='dense' label="Select Student" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                    )}
                                />
                            </div>
                        </div> */}
                        <div className="my-2">
                            <AddStudentBySearching
                                setclassData={setclassData}
                                setDivision={setDivision}
                                setStudentId={setStudentId}
                                setSelectedStudent={setSelectedStudent}
                                classData={classData}
                                division={division}
                                studentId={studentId}
                                selectedStudent={selectedStudent}
                                setStudentLcNo={setStudentLcNo}
                                label="Leaving Certificate"
                                setStudentDetails={studentDataGet}
                                className="col-sm-2"
                                readOnly={(EditFlag == 2) ? true : false}
                                setLoaderOption={setLoaderOption}
                            />
                        </div>

                        <div className="row my-2">
                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >LC No :</label>
                            <div className='col-sm-4'>
                                <TextField
                                    required
                                    fullWidth
                                    autoComplete='off'
                                    size='small'
                                    id="outlined-basic"
                                    label="LC No"
                                    variant="outlined"
                                    margin='dense'
                                    value={lcNo}
                                    onKeyPress={(e) => (e.key == "Enter") ? lcNo : ""}
                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                    inputProps={{ readOnly: true }}
                                />
                            </div>
                        </div>

                        <div className="row my-2">
                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >LC Date :</label>
                            <div className='col-sm-4'>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        label="LC Date"
                                        value={lcDate}
                                        onChange={(newValue) => {
                                            setLcDate(newValue);
                                        }}
                                        onKeyPress={(e) => (e.key == "Enter") ? lcDate : ""}
                                        inputFormat="dd/MM/yyyy"
                                        renderInput={(params) =>
                                            <TextField
                                                {...params}
                                                margin='dense'
                                                variant="outlined"
                                                InputLabelProps={{ style: { fontSize: 14 } }}
                                                required
                                                fullWidth
                                                autoComplete='off'
                                                size="small" />}
                                    />
                                </LocalizationProvider>
                            </div>
                        </div>

                        <div className="row my-2">
                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >School Left Date :</label>
                            <div className='col-sm-4'>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        label="School Left Date"
                                        value={schoolLeftDate}
                                        onChange={(newValue) => {
                                            setSchoolLeftDate(newValue);
                                        }}
                                        onKeyPress={(e) => (e.key == "Enter") ? schoolLeftDate : ""}
                                        inputFormat="dd/MM/yyyy"
                                        renderInput={(params) =>
                                            <TextField
                                                {...params}
                                                margin='dense'
                                                variant="outlined"
                                                InputLabelProps={{ style: { fontSize: 14 } }}
                                                required
                                                fullWidth
                                                autoComplete='off'
                                                size="small" />}
                                    />
                                </LocalizationProvider>
                            </div>
                        </div>

                        <div className="row my-2">
                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >LC Received Date :</label>
                            <div className='col-sm-4'>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        label="LC Received Date"
                                        value={lcReceivedDate}
                                        onChange={(newValue) => {
                                            setLcReceivedDate(newValue);
                                        }}
                                        onKeyPress={(e) => (e.key == "Enter") ? lcReceivedDate : ""}
                                        inputFormat="dd/MM/yyyy"
                                        renderInput={(params) =>
                                            <TextField
                                                {...params}
                                                margin='dense'
                                                variant="outlined"
                                                InputLabelProps={{ style: { fontSize: 14 } }}
                                                required
                                                fullWidth
                                                autoComplete='off'
                                                size="small" />}
                                    />
                                </LocalizationProvider>
                            </div>
                        </div>

                        <div className="row my-2">
                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Reason Of Leaving :</label>
                            <div className='col-sm-4'>
                                <TextField
                                    required
                                    fullWidth
                                    onKeyDown={(e) => (e.key === " " && reasonOfLeavingMr.length === 0) ? e.preventDefault() : ""}
                                    autoComplete='off'
                                    size='small'
                                    id="outlined-basic"
                                    label="Reason Of Leaving Marathi"
                                    variant="outlined"
                                    margin='dense'
                                    value={reasonOfLeavingMr}
                                    onChange={e => setReasonOfLeavingMr(e.target.value)}
                                    onKeyPress={(e) => (e.key == "Enter") ? reasonOfLeavingMr : ""}
                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                    inputProps={{ maxLength: 120 }}
                                />
                            </div>

                            <div className='col-sm-4'>
                                <TextField
                                    onKeyDown={(e) => (e.key === " " && reasonOfLeavingEn.length === 0) ? e.preventDefault() : ""}
                                    fullWidth
                                    autoComplete='off'
                                    size='small'
                                    id="outlined-basic"
                                    label="Reason Of Leaving English"
                                    variant="outlined"
                                    margin='dense'
                                    value={reasonOfLeavingEn}
                                    onChange={e => setReasonOfLeavingEn(e.target.value)}
                                    onKeyPress={(e) => (e.key == "Enter") ? reasonOfLeavingEn : ""}
                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                    inputProps={{ maxLength: 120 }}
                                />
                            </div>
                        </div>

                        <div className="row my-2">
                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Since When :</label>
                            <div className='col-sm-4'>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        label="Since When"
                                        value={sinceWhen}
                                        onChange={(newValue) => {
                                            setSinceWhen(newValue);
                                        }}
                                        onKeyPress={(e) => (e.key == "Enter") ? sinceWhen : ""}
                                        inputFormat="dd/MM/yyyy"
                                        renderInput={(params) =>
                                            <TextField
                                                {...params}
                                                margin='dense'
                                                variant="outlined"
                                                InputLabelProps={{ style: { fontSize: 14 } }}
                                                required
                                                fullWidth
                                                autoComplete='off'
                                                size="small" />}
                                    />
                                </LocalizationProvider>
                            </div>
                        </div>

                        <div className="row my-2">
                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Remarks :</label>
                            <div className='col-sm-4'>
                                <Autocomplete
                                    id="combo-box-demo"
                                    freeSolo
                                    onKeyDown={(e) => (e.key === " " && remarksInputMrObj.length === 0) ? e.preventDefault() : ""}
                                    PaperComponent={({ children }) => (
                                        <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                    )}
                                    options={remarksData}
                                    value={remarksObj}
                                    onChange={(event, newValue) => {
                                        setRemarksId(newValue.id);
                                        setRemarksObj(newValue);

                                    }}
                                    inputValue={remarksInputMrObj}
                                    onInputChange={(event, newInputValue) => {
                                        setRemarksInputMrObj(newInputValue);
                                    }}
                                    onKeyPress={(e) => (e.key == "Enter") ? remarksInputMrObj : ""}
                                    style={{ fontSize: '14px' }}
                                    getOptionLabel={option => {
                                        if (typeof option === 'string') {
                                            return option;
                                        }
                                        // Add "xxx" option created dynamically
                                        if (option.inputValue) {
                                            return option.nameMr;
                                        }
                                        // Regular option
                                        return option.nameMr;
                                    }}
                                    size="small"
                                    renderInput={params => (
                                        <TextField {...params} margin='dense' label="Remarks Marathi" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                    )}
                                />
                            </div>

                            <div className='col-sm-4'>
                                <Autocomplete
                                    id="combo-box-demo"
                                    PaperComponent={({ children }) => (
                                        <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                    )}
                                    freeSolo
                                    onKeyDown={(e) => (e.key === " " && remarksInputEnObj.length === 0) ? e.preventDefault() : ""}
                                    options={remarksData}
                                    value={remarksObj}
                                    onChange={(event, newValue) => {
                                        setRemarksId(newValue.id);
                                        setRemarksObj(newValue);

                                    }}
                                    inputValue={remarksInputEnObj}
                                    onInputChange={(event, newInputValue) => {
                                        setRemarksInputEnObj(newInputValue);
                                    }}
                                    onKeyPress={(e) => (e.key == "Enter") ? remarksInputEnObj : ""}
                                    style={{ fontSize: '14px' }}
                                    getOptionLabel={option => {
                                        if (typeof option === 'string') {
                                            return option;
                                        }
                                        // Add "xxx" option created dynamically
                                        if (option.inputValue) {
                                            return option.nameEn;
                                        }
                                        // Regular option
                                        return option.nameEn;
                                    }
                                    }
                                    size="small"
                                    renderInput={params => (
                                        <TextField {...params} margin='dense' label="Remarks English" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} />
                                    )}
                                />
                            </div>
                        </div>

                        <div className="row my-2">
                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Progress :</label>
                            <div className='col-sm-4'>
                                <Autocomplete
                                    id="combo-box-demo"
                                    PaperComponent={({ children }) => (
                                        <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                    )}
                                    options={progressConductData}
                                    value={progressMrObj}
                                    onChange={(event, newValue) => {
                                        setProgressMrId(newValue.id);
                                        setProgressMrObj(newValue);
                                        setProgressEnId(newValue.id);
                                        setProgressEnObj(newValue);
                                    }}
                                    onKeyPress={(e) => (e.key == "Enter") ? progressMrObj : ""}
                                    style={{ fontSize: '14px' }}
                                    getOptionLabel={option => option.nameMr}
                                    size="small"
                                    renderInput={params => (
                                        <TextField {...params} margin='dense' label="Select Progress Marathi" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                    )}
                                />
                            </div>

                            <div className='col-sm-4'>
                                <Autocomplete
                                    id="combo-box-demo"
                                    PaperComponent={({ children }) => (
                                        <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                    )}
                                    options={progressConductData}
                                    value={progressEnObj}
                                    onChange={(event, newValue) => {
                                        setProgressEnId(newValue.id);
                                        setProgressEnObj(newValue);
                                        setProgressMrId(newValue.id);
                                        setProgressMrObj(newValue);
                                    }}
                                    onKeyPress={(e) => (e.key == "Enter") ? progressEnObj : ""}
                                    style={{ fontSize: '14px' }}
                                    getOptionLabel={option => option.nameEn}
                                    size="small"
                                    renderInput={params => (
                                        <TextField {...params} margin='dense' label="Select Progress English" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                    )}
                                />
                            </div>
                        </div>

                        <div className="row my-2">
                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Conduct :</label>
                            <div className='col-sm-4'>
                                <Autocomplete
                                    id="combo-box-demo"
                                    PaperComponent={({ children }) => (
                                        <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                    )}
                                    options={progressConductData}
                                    onChange={(event, newValue) => {
                                        setConductMrId(newValue.id);
                                        setConductMrObj(newValue);
                                        setConductEnId(newValue.id);
                                        setConductEnObj(newValue);
                                    }}
                                    onKeyPress={(e) => (e.key == "Enter") ? conductMrObj : ""}
                                    value={conductMrObj}
                                    style={{ fontSize: '14px' }}
                                    getOptionLabel={option => option.nameMr}
                                    size="small"
                                    renderInput={params => (
                                        <TextField {...params} margin='dense' label="Select Conduct Marathi" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                    )}
                                />
                            </div>

                            <div className='col-sm-4'>
                                <Autocomplete
                                    id="combo-box-demo"
                                    PaperComponent={({ children }) => (
                                        <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                    )}
                                    options={progressConductData}
                                    onChange={(event, newValue) => {
                                        setConductEnId(newValue.id);
                                        setConductEnObj(newValue);
                                        setConductMrId(newValue.id);
                                        setConductMrObj(newValue);
                                    }}
                                    onKeyPress={(e) => (e.key == "Enter") ? conductEnObj : ""}
                                    value={conductEnObj}
                                    style={{ fontSize: '14px' }}
                                    getOptionLabel={option => option.nameEn}
                                    size="small"
                                    renderInput={params => (
                                        <TextField {...params} margin='dense' label="Select Conduct English" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                    )}
                                />
                            </div>
                        </div>



                        <div style={{ backgroundColor: "#FFFDE7", padding: "12px", marginTop: "12px", border: "1px solid" }}>


                            <div className="row my-2">
                                <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Reg No :</label>
                                <div className='col-sm-4'>
                                    <TextField
                                        required
                                        fullWidth
                                        autoComplete='off'
                                        size='small'
                                        id="outlined-basic"
                                        label="Reg No"
                                        variant="outlined"
                                        margin='dense'
                                        style={{ backgroundColor: "#F8F9F9" }}
                                        value={regNo}
                                        InputLabelProps={{ style: { fontSize: 14 } }}
                                        inputProps={{ readOnly: true }}
                                    />
                                </div>
                            </div>

                            <div className="row my-2">
                                <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Birth Date :</label>
                                <div className='col-sm-4'>
                                    <TextField
                                        required
                                        fullWidth
                                        autoComplete='off'
                                        size='small'
                                        id="outlined-basic"
                                        label="Birth Date"
                                        variant="outlined"
                                        margin='dense'
                                        style={{ backgroundColor: "#F8F9F9" }}
                                        value={birthDate}
                                        InputLabelProps={{ style: { fontSize: 14 } }}
                                        inputProps={{ readOnly: true }}
                                    />
                                </div>
                            </div>

                            <div className="row my-2">
                                <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Birth Village :</label>
                                <div className='col-sm-4'>
                                    <TextField
                                        required
                                        fullWidth
                                        autoComplete='off'
                                        size='small'
                                        id="outlined-basic"
                                        label="Birth Village Marathi"
                                        variant="outlined"
                                        margin='dense'
                                        style={{ backgroundColor: "#F8F9F9" }}
                                        value={birthVillageMr}
                                        InputLabelProps={{ style: { fontSize: 14 } }}
                                        inputProps={{ readOnly: true }}
                                    />
                                </div>

                                <div className='col-sm-4'>
                                    <TextField
                                        required
                                        fullWidth
                                        autoComplete='off'
                                        size='small'
                                        id="outlined-basic"
                                        label="Birth Village English"
                                        variant="outlined"
                                        margin='dense'
                                        style={{ backgroundColor: "#F8F9F9" }}
                                        value={birthVillageEn}
                                        InputLabelProps={{ style: { fontSize: 14 } }}
                                        inputProps={{ readOnly: true }}
                                    />
                                </div>
                            </div>

                            <div className="row my-2">
                                <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Birth Taluka :</label>
                                <div className='col-sm-4'>
                                    <TextField
                                        required
                                        fullWidth
                                        autoComplete='off'
                                        size='small'
                                        id="outlined-basic"
                                        label="Birth Taluka Marathi"
                                        variant="outlined"
                                        margin='dense'
                                        style={{ backgroundColor: "#F8F9F9" }}
                                        value={birthTalukaMr}
                                        InputLabelProps={{ style: { fontSize: 14 } }}
                                        inputProps={{ readOnly: true }}
                                    />
                                </div>

                                <div className='col-sm-4'>
                                    <TextField
                                        required
                                        fullWidth
                                        autoComplete='off'
                                        size='small'
                                        id="outlined-basic"
                                        label="Birth Taluka English"
                                        variant="outlined"
                                        margin='dense'
                                        style={{ backgroundColor: "#F8F9F9" }}
                                        value={birthTalukaEn}
                                        InputLabelProps={{ style: { fontSize: 14 } }}
                                        inputProps={{ readOnly: true }}
                                    />
                                </div>
                            </div>

                            <div className="row my-2">
                                <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Birth District :</label>
                                <div className='col-sm-4'>
                                    <TextField
                                        required
                                        fullWidth
                                        autoComplete='off'
                                        size='small'
                                        id="outlined-basic"
                                        label="Birth District Marathi"
                                        variant="outlined"
                                        margin='dense'
                                        style={{ backgroundColor: "#F8F9F9" }}
                                        value={birthDistrictMr}
                                        InputLabelProps={{ style: { fontSize: 14 } }}
                                        inputProps={{ readOnly: true }}
                                    />
                                </div>

                                <div className='col-sm-4'>
                                    <TextField
                                        required
                                        fullWidth
                                        autoComplete='off'
                                        size='small'
                                        id="outlined-basic"
                                        label="Birth District English"
                                        variant="outlined"
                                        margin='dense'
                                        style={{ backgroundColor: "#F8F9F9" }}
                                        value={birthDistrictEn}
                                        InputLabelProps={{ style: { fontSize: 14 } }}
                                        inputProps={{ readOnly: true }}
                                    />
                                </div>
                            </div>

                            <div className="row my-2">
                                <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Birth State :</label>
                                <div className='col-sm-4'>
                                    <TextField
                                        required
                                        fullWidth
                                        autoComplete='off'
                                        size='small'
                                        id="outlined-basic"
                                        label="Birth State Marathi"
                                        variant="outlined"
                                        margin='dense'
                                        style={{ backgroundColor: "#F8F9F9" }}
                                        value={birthStateMr}
                                        InputLabelProps={{ style: { fontSize: 14 } }}
                                        inputProps={{ readOnly: true }}
                                    />
                                </div>

                                <div className='col-sm-4'>
                                    <TextField
                                        required
                                        fullWidth
                                        autoComplete='off'
                                        size='small'
                                        id="outlined-basic"
                                        label="Birth State English"
                                        variant="outlined"
                                        margin='dense'
                                        style={{ backgroundColor: "#F8F9F9" }}
                                        value={birthStateEn}
                                        InputLabelProps={{ style: { fontSize: 14 } }}
                                        inputProps={{ readOnly: true }}
                                    />
                                </div>
                            </div>

                            <div className="row my-2">
                                <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Birth Country :</label>
                                <div className='col-sm-4'>
                                    <TextField
                                        required
                                        fullWidth
                                        autoComplete='off'
                                        size='small'
                                        id="outlined-basic"
                                        label="Birth Country Marathi"
                                        variant="outlined"
                                        margin='dense'
                                        style={{ backgroundColor: "#F8F9F9" }}
                                        value={birthCountryMr}
                                        InputLabelProps={{ style: { fontSize: 14 } }}
                                        inputProps={{ readOnly: true }}
                                    />
                                </div>

                                <div className='col-sm-4'>
                                    <TextField
                                        required
                                        fullWidth
                                        autoComplete='off'
                                        size='small'
                                        id="outlined-basic"
                                        label="Birth Country English"
                                        variant="outlined"
                                        margin='dense'
                                        style={{ backgroundColor: "#F8F9F9" }}
                                        value={birthCountryEn}
                                        InputLabelProps={{ style: { fontSize: 14 } }}
                                        inputProps={{ readOnly: true }}
                                    />
                                </div>
                            </div>

                            <div className="row my-2">
                                <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Admission Date :</label>
                                <div className='col-sm-4'>
                                    <TextField
                                        required
                                        fullWidth
                                        autoComplete='off'
                                        size='small'
                                        id="outlined-basic"
                                        label="Admission Date"
                                        variant="outlined"
                                        margin='dense'
                                        style={{ backgroundColor: "#F8F9F9" }}
                                        value={admissionDate}
                                        InputLabelProps={{ style: { fontSize: 14 } }}
                                        inputProps={{ readOnly: true }}
                                    />
                                </div>
                            </div>

                            <div className="row my-2">
                                <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Religion :</label>
                                <div className='col-sm-4'>
                                    <TextField
                                        required
                                        fullWidth
                                        autoComplete='off'
                                        size='small'
                                        id="outlined-basic"
                                        label="Religion Marathi"
                                        margin='dense'
                                        value={studentReligionMr}
                                        variant="outlined"
                                        style={{ backgroundColor: "#F8F9F9" }}
                                        InputLabelProps={{ style: { fontSize: 14 } }}
                                        inputProps={{ readOnly: true }}
                                    />
                                </div>

                                <div className='col-sm-4'>
                                    <TextField
                                        required
                                        fullWidth
                                        autoComplete='off'
                                        size='small'
                                        id="outlined-basic"
                                        label="Religion English"
                                        variant="outlined"
                                        margin='dense'
                                        value={studentReligionEn}
                                        style={{ backgroundColor: "#F8F9F9" }}
                                        InputLabelProps={{ style: { fontSize: 14 } }}
                                        inputProps={{ readOnly: true }}
                                    />
                                </div>
                            </div>

                            <div className="row my-2">
                                <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Caste :</label>
                                <div className='col-sm-4'>
                                    <TextField
                                        required
                                        fullWidth
                                        autoComplete='off'
                                        size='small'
                                        id="outlined-basic"
                                        label="Caste Marathi"
                                        variant="outlined"
                                        margin='dense'
                                        value={studentCasteMr}
                                        style={{ backgroundColor: "#F8F9F9" }}
                                        InputLabelProps={{ style: { fontSize: 14 } }}
                                        inputProps={{ readOnly: true }}
                                    />
                                </div>

                                <div className='col-sm-4'>
                                    <TextField
                                        required
                                        fullWidth
                                        autoComplete='off'
                                        size='small'
                                        id="outlined-basic"
                                        label="Caste English"
                                        variant="outlined"
                                        margin='dense'
                                        value={studentCasteEn}
                                        style={{ backgroundColor: "#F8F9F9" }}
                                        InputLabelProps={{ style: { fontSize: 14 } }}
                                        inputProps={{ readOnly: true }}
                                    />
                                </div>
                            </div>

                            <div className="row my-2">
                                <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Sub Caste :</label>
                                <div className='col-sm-4'>
                                    <TextField
                                        required
                                        fullWidth
                                        autoComplete='off'
                                        size='small'
                                        id="outlined-basic"
                                        label="Sub Caste Marathi"
                                        variant="outlined"
                                        margin='dense'
                                        value={studentSubCasteMr}
                                        style={{ backgroundColor: "#F8F9F9" }}
                                        InputLabelProps={{ style: { fontSize: 14 } }}
                                        inputProps={{ readOnly: true }}
                                    />
                                </div>

                                <div className='col-sm-4'>
                                    <TextField
                                        required
                                        fullWidth
                                        autoComplete='off'
                                        size='small'
                                        id="outlined-basic"
                                        label="Sub Caste English"
                                        variant="outlined"
                                        margin='dense'
                                        value={studentSubCasteEn}
                                        style={{ backgroundColor: "#F8F9F9" }}
                                        InputLabelProps={{ style: { fontSize: 14 } }}
                                        inputProps={{ readOnly: true }}
                                    />
                                </div>
                            </div>

                            <div className="row my-2">
                                <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Mother Name :</label>
                                <div className='col-sm-4'>
                                    <TextField
                                        required
                                        fullWidth
                                        autoComplete='off'
                                        size='small'
                                        id="outlined-basic"
                                        label="Mother Name Marathi"
                                        variant="outlined"
                                        margin='dense'
                                        value={motherNameMr}
                                        style={{ backgroundColor: "#F8F9F9" }}
                                        InputLabelProps={{ style: { fontSize: 14 } }}
                                        inputProps={{ readOnly: true }}
                                    />
                                </div>

                                <div className='col-sm-4'>
                                    <TextField
                                        required
                                        fullWidth
                                        autoComplete='off'
                                        size='small'
                                        id="outlined-basic"
                                        label="Mother Name English"
                                        variant="outlined"
                                        margin='dense'
                                        value={motherNameEn}
                                        style={{ backgroundColor: "#F8F9F9" }}
                                        InputLabelProps={{ style: { fontSize: 14 } }}
                                        inputProps={{ readOnly: true }}
                                    />
                                </div>
                            </div>

                            <div className="row my-2">
                                <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Current Std :</label>
                                <div className='col-sm-4'>
                                    <TextField
                                        required
                                        fullWidth
                                        autoComplete='off'
                                        size='small'
                                        id="outlined-basic"
                                        label="Current Std Marathi"
                                        variant="outlined"
                                        margin='dense'
                                        value={studentCurrentStdMr}
                                        style={{ backgroundColor: "#F8F9F9" }}
                                        InputLabelProps={{ style: { fontSize: 14 } }}
                                        inputProps={{ readOnly: true }}
                                    />
                                </div>

                                <div className='col-sm-4'>
                                    <TextField
                                        required
                                        fullWidth
                                        autoComplete='off'
                                        size='small'
                                        id="outlined-basic"
                                        label="Current Std English"
                                        variant="outlined"
                                        margin='dense'
                                        value={studentCurrentStdEn}
                                        style={{ backgroundColor: "#F8F9F9" }}
                                        InputLabelProps={{ style: { fontSize: 14 } }}
                                        inputProps={{ readOnly: true }}
                                    />
                                </div>
                            </div>

                            <div className="row my-2">
                                <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Current Div :</label>
                                <div className='col-sm-4'>
                                    <TextField
                                        required
                                        fullWidth
                                        autoComplete='off'
                                        size='small'
                                        id="outlined-basic"
                                        label="Current Div Marathi"
                                        variant="outlined"
                                        margin='dense'
                                        value={studentCurrentDivMr}
                                        style={{ backgroundColor: "#F8F9F9" }}
                                        InputLabelProps={{ style: { fontSize: 14 } }}
                                        inputProps={{ readOnly: true }}
                                    />
                                </div>

                                <div className='col-sm-4'>
                                    <TextField
                                        required
                                        fullWidth
                                        autoComplete='off'
                                        size='small'
                                        id="outlined-basic"
                                        label="Current Div English"
                                        variant="outlined"
                                        margin='dense'
                                        value={studentCurrentDivEn}
                                        style={{ backgroundColor: "#F8F9F9" }}
                                        InputLabelProps={{ style: { fontSize: 14 } }}
                                        inputProps={{ readOnly: true }}
                                    />
                                </div>
                            </div>

                            <div className="row my-2">
                                <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Old School :</label>
                                <div className='col-sm-4'>
                                    <TextField
                                        required
                                        fullWidth
                                        autoComplete='off'
                                        size='small'
                                        id="outlined-basic"
                                        label="Old School"
                                        variant="outlined"
                                        margin='dense'
                                        value={oldSchool}
                                        style={{ backgroundColor: "#F8F9F9" }}
                                        InputLabelProps={{ style: { fontSize: 14 } }}
                                        inputProps={{ readOnly: true }}
                                    />
                                </div>
                            </div>

                            <div className="row my-2">
                                <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Old School Std :</label>
                                <div className='col-sm-4'>
                                    <TextField
                                        required
                                        fullWidth
                                        autoComplete='off'
                                        size='small'
                                        id="outlined-basic"
                                        label="Old School Std"
                                        variant="outlined"
                                        margin='dense'
                                        value={oldSchoolStd}
                                        style={{ backgroundColor: "#F8F9F9" }}
                                        InputLabelProps={{ style: { fontSize: 14 } }}
                                        inputProps={{ readOnly: true }}
                                    />
                                </div>
                            </div>

                        </div>

                        <div className="col-sm-12 mt-3">
                            <div className="offset-sm-2">
                                <button type="submit" disabled={saveBtnDisabled} className={saveBtn} onClick={() => (state.button = 1)} style={{ margin: '0px 4px' }}>Save</button>
                                <button type="submit" disabled={updateBtnDisabled} onClick={() => (state.button = 2)} className={updateBtn} >Update</button>
                                {/* <button type="button" className="btn btn-primary btn-sm" style={{ margin: '0px 4px' }} onClick={() => printLcReport()}>Print</button> */}
                                <button type="button" onClick={ClearData} className="btn btn-danger btn-sm" style={{ margin: '0px 4px' }} >Cancel</button>
                            </div>
                        </div>


                    </form>
                </div>

                {/* <ToastContainer position="top-right" theme="colored" /> */}

            </>
        )
    }


}

export default LeavingCertificateForm