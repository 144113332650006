import React, { useState, useEffect } from 'react';
import TitleLabel from "../../CommonComponent/TitleLabel";
import TextField from '@mui/material/TextField';
import Autocomplete from "@mui/material/Autocomplete";
import axios from "axios";
import Paper from "@mui/material/Paper";
import { useSelector } from "react-redux";
import AddIcon from '@mui/icons-material/Add';
import { getFeeListData, getFeeDemandData, saveFeeDemandData } from '../Services/FeeDemandAPIURL';
import FeeDemandTextBox from './FeeDemandTextBox/FeeDemandTextBox';
import { ToastContainer, toast } from 'react-toastify';
import Loader from '../../CommonComponent/Loader';

const FeeDemand = () => {
  const authUser = useSelector((state) => state.user.value);
  const titleId = "Fee Demand";

  const [loaderOption, setLoaderOption] = useState(false);

  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isAscending, setAscending] = useState(false);
  const [sortedBy, setSortedBy] = useState(null);

  const onPageChange = (event, nextPage) => {
    setPage(nextPage);
  }
  const onRowsPerPageChange = (e) => {
    setRowsPerPage(parseInt(e.target.value));
    setPage(0);
  }


  const TableHeading = [
    { label: `Class`, key: (authUser.branchMedium == 1) ? 'classNameMr' : 'classNameEn', isAction: true },
    { label: `Amount`, key: 'feeAmount', isAction: true },
  ];

  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [id, setId] = useState();

  const [classData, setClassData] = useState([]);
  const [feeId, setFeeId] = useState([]);
  const [feeObj, setFeeObj] = useState(null);
  const [amount, setAmount] = useState("");


  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {

    await axios(`${getFeeListData}`)
      .then(res => {
        const feeDemandData = (res.data).filter(e => e.feeType !== 5)
        setData(feeDemandData);
      })
  }

  const getClassData = async (fId) => {
    setClassData([]);
    setFilteredData([]);
    // console.log(`${getFeeDemandData}?sid=${authUser.deptId}&bid=${authUser.branchId}&acyr=${authUser.sessionYear}&fid=${fId}`)
    await axios(`${getFeeDemandData}?sid=${authUser.deptId}&bid=${authUser.branchId}&acyr=${authUser.sessionYear}&fid=${fId}`)
      .then(res => {
        setClassData(res.data);
        setFilteredData(res.data);
      })
  }

  const setAmountToAllClass = async () => {
    setLoaderOption(true);
    await axios(`${getFeeDemandData}?sid=${authUser.deptId}&bid=${authUser.branchId}&acyr=${authUser.sessionYear}&fid=${feeId}`)
      .then(res => {
        let updatedData = res.data;
        (res.data).map((item, index) => {
          updatedData[index] = ({ ...item, feeAmount: amount })
        })
        setClassData(updatedData);
        setFilteredData(updatedData);
        setLoaderOption(false);
      })
  }

  const saveFeeAmount = (e) => {
    setLoaderOption(true);
    e.preventDefault();
    axios.post(`${saveFeeDemandData}`, classData)
      .then((response) => {
        if (response.data == "SAVED") {

          setFilteredData([]);
          setClassData([]);
          setFeeObj(null);
          setAmount("");
          setLoaderOption(false);
          toast.success("Fee Demand updation sucessfully done.");
        }
      })
      .catch(error => {
        console.log("Update Fire Query: " + error)
      })
  }

  const onSort = (key, isAction) => {
    if (!isAction) {
      setSortedBy(key)
      if (isAscending) setFilteredData([...data.sort((a, b) => a[key].toString().toLowerCase() < b[key].toString().toLowerCase() ? 1 : -1)])
      else setFilteredData([...data.sort((a, b) => a[key].toString().toLowerCase() > b[key].toString().toLowerCase() ? 1 : -1)])
      setAscending(!isAscending)
    }

  }

  useEffect(() => {
    function handlekeydownEvent(event) {
      if (event.key === "Enter") {
        // console.log("Enter is pressed!")
        if (classData !== null || classData !== "") {
          // console.log('Enter is pressed!');
          event.preventDefault();
          let btn = document.querySelector('.feeDemandBtn')
          if (btn !== null) {
            btn.click();
          }
        }
      }
    }

    document.addEventListener('keypress', handlekeydownEvent)
    return () => {
      document.removeEventListener('keypress', handlekeydownEvent)
    }

  }, []);


  if (loaderOption == true) {
    return <Loader />
  }
  else {
    return (
      <>
        <TitleLabel titleId={titleId} />
        <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: "350px" }}>

          <input type="hidden" value={id} onChange={(e) => setId(e.target.value)} name="" />

          <div className="row">
            <label htmlFor="lastname3" className="col-sm-1" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>Fee :</label>
            <div className='col-sm-4'>
              <Autocomplete
                id="combo-box-demo"
                options={data}
                PaperComponent={({ children }) => (
                  <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                )}
                onChange={(event, newValue) => {
                  setFeeId(newValue.id);
                  setFeeObj(newValue);
                  getClassData(newValue.id);
                }}
                value={feeObj}
                style={{ fontSize: '14px' }}
                getOptionLabel={option => (authUser.branchMedium == 1) ? option.nameMr : option.nameEn}
                size="small"
                renderInput={params => (
                  <TextField {...params} margin='dense' label="Select Fee" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                )}
              />
            </div>
          </div>

          {(classData == null || classData == "")
            ?
            <h4 className='mt-5'>No Records, Select Fee</h4>
            :
            <div>
              <div className='row mt-3'>
                <div className="offset-sm-4 col-sm-2 ">
                  <TextField
                    fullWidth
                    autoComplete='off'
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    size='small'
                    id="outlined-basic"
                    label="Amount"
                    variant="outlined"
                    margin='dense'
                    value={amount}
                    onChange={(e) => setAmount(e.target.value)}
                    inputProps={{ maxLength: 120 }}
                    InputLabelProps={{ style: { fontSize: 14 } }}
                  />
                </div>

                <div className="col-sm-4 mt-3">
                  <button type="button" disabled={(amount == '') ? true : false} className="btn btn-sm btn-warning" onClick={() => { setAmountToAllClass(); setFilteredData([]) }}>Apply</button>
                </div>
              </div>

              <form onSubmit={saveFeeAmount}>
                <div className='offset-sm-1 col-sm-6 mt-2'>
                  <div className='table-responsive' style={{ maxHeight: "500px" }}>
                    <table className="table table-bordered ">
                      <thead className="table-Default" style={{ position: "sticky", overflow: "hidden", top: "0", backgroundColor: "#EAECEE", borderBottom: "2px solid #464de4" }}>
                        <tr style={{ borderBottom: "2px solid #464de4", position: "sticky", overflow: "hidden" }}>
                          {TableHeading.map((item, index) => {
                            return (
                              <th style={{ fontSize: "14px", fontWeight: "600" }} onClick={() => onSort(item.key, item.isAction)} key={index} scope="col">{`${item.label} ${sortedBy === item.key ? isAscending ? '↑' : '↓' : ''}`}</th>
                            )
                          })}
                        </tr>
                      </thead>
                      <tbody style={{ borderTop: "2px solid #464de4", borderBottom: "2px solid #464de4" }}>
                        {filteredData
                          .map((item, index) => {
                            return (
                              <React.Fragment key={index} >
                                <tr style={{ height: "65px" }} >
                                  <td style={{ fontSize: "15px", fontWeight: "500", width: "100px" }}>{(authUser.branchMedium == 1) ? item.classNameMr : item.classNameEn}</td>

                                  <FeeDemandTextBox initialText={item.feeAmount} onChange={(value) => {
                                    let updatedData = classData;
                                    updatedData[index] = ({ ...item, feeAmount: value });
                                    setFilteredData(updatedData);
                                    setClassData(updatedData);
                                    //console.log(JSON.stringify(updatedData));
                                  }} />
                                </tr>
                              </React.Fragment>

                            )
                          })}
                      </tbody>
                      <tfoot style={{ padding: "0px" }}>
                        {/* <TablePagination
                                style={{ padding: "0px" }}
                                rowsPerPageOptions={[10, 25, 50, 100]}
                                count={data.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onRowsPerPageChange={onRowsPerPageChange}
                                onPageChange={onPageChange}
                                classes={{ toolbar: classes.toolbar, caption: classes.caption }}
                                backIconButtonProps={{ "aria-label": "Previous Page" }}
                                nextIconButtonProps={{ "aria-label": "Next Page" }} /> */}
                      </tfoot>
                    </table>

                  </div>

                  <div className='mt-4'>
                    <button className='feeDemandBtn btn btn-primary btn-sm mb-2' type='submit'><AddIcon fontSize="small" />Save Changes</button>
                  </div>
                </div>
              </form>
            </div>
          }


        </div>
        <ToastContainer position="top-right" theme="colored" />
      </>
    )
  }

}

export default FeeDemand