import { TextField } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Loader from '../../CommonComponent/Loader';
import TitleLabel from '../../CommonComponent/TitleLabel';
import { useNavigate, useParams } from 'react-router-dom';
import { getInventoryVendorMasterAPIURL, saveInventoryVendorMasterAPIURL, updateInventoryVendorMasterAPIURL } from '../Services/InventoryVendorMasterAPIURL';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';


const InventoryVendorMaster = () => {
    const titleId = 'Vendor Master Form';
    const authUser = useSelector((state) => state.user.value);
    const [loaderOption, setLoaderOption] = useState(false);

    const navigate = useNavigate();
    const textValidatorRef = useRef(null);
    let { venId, EditFlag } = useParams();

    //Note: This below states are declared for Save & Update(Disabled/unDisabled)
    const saveBtnStyle = "inventoryVendorSaveBtn btn btn-primary btn-sm";
    const updateBtnStyle = "inventoryVendorUpdateBtn btn btn-primary btn-sm";
    const displayNoneBtnStyle = "d-none";
    const [saveBtn, setSaveBtn] = useState(saveBtnStyle);
    const [updateBtn, setUpdateBtn] = useState(displayNoneBtnStyle);

    const [saveBtnDisabled, setSaveBtnDisabled] = useState(false);
    const [updateBtnDisabled, setUpdateBtnDisabled] = useState(false);

    const [id, setId] = useState("");
    const [vendorName, setVendorName] = useState('');
    const [address, setAddress] = useState('');
    const [contact, setContact] = useState('');
    const [email, setEmail] = useState('');
    //console.log(email);
    const [gstNo, setGstNo] = useState('');
    const [panNo, setPanNo] = useState('');

    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);

    useEffect(() => {
        getData();
    }, [authUser])


    const getData = () => {
        if (EditFlag == 1) {
            getApiData();
            setSaveBtnDisabled(false);
            setUpdateBtnDisabled(true);
        }
        else if (EditFlag == 2) {
            getEditDetails();
            setSaveBtnDisabled(true);
            setUpdateBtnDisabled(false);
        }
    }

    const getEditDetails = async () => {
        setLoaderOption(true);
        if (authUser.deptId != null && authUser.branchId != null) {
            await axios.get(`${getInventoryVendorMasterAPIURL}?sid=${authUser.deptId}&bid=${authUser.branchId}`)
                .then(res => {
                    setSaveBtn(displayNoneBtnStyle);
                    setUpdateBtn(updateBtnStyle);

                    const selectedItem = res.data.find(e => e.id == venId)

                    setId(selectedItem.id);
                    setVendorName(selectedItem.vendorName);
                    setEmail(selectedItem.email);
                    setContact(selectedItem.contact);
                    setGstNo(selectedItem.gstNo);
                    setPanNo(selectedItem.panNo);
                    setAddress(selectedItem.address);

                    setLoaderOption(false);
                }).catch(err => {
                    setLoaderOption(false);
                    console.log("Inventory vendor master get err " + err);
                    toast.error("Something went wrong, please check.")
                })
        }
    }

    const getApiData = async () => {
        if (authUser.deptId != null && authUser.branchId != null) {
            await axios.get(`${getInventoryVendorMasterAPIURL}?sid=${authUser.deptId}&bid=${authUser.branchId}`)
                .then(res => {
                    setData(res.data);
                    setFilteredData(res.data);
                }).catch(err => {
                    console.log("Inventory vendor master get err " + err);
                    toast.error("Something went wrong, please check.")
                })
        }
    }

    const clearData = () => {
        setSaveBtn(saveBtnStyle);
        setUpdateBtn(displayNoneBtnStyle);
        setAddress('');
        setVendorName('');
        setContact('');
        setEmail('');
        setGstNo('');
        setPanNo('');
        setSaveBtnDisabled(false);
        setUpdateBtnDisabled(true);
        navigate('/Home/sm1303/')
    }

    const state = {
        button: 1
    }

    const validateEmail = (value) => {
        // Email validation regular expression
        const emailRegex = /\S+@\S+\.\S+/;
        if (!emailRegex.test(value)) {
            return false;
        }
        return true;
    }

    const saveBtnClick = async () => {
        //console.log(validateEmail(textValidatorRef.current.props.value));

        setLoaderOption(true);
        if (validateEmail(textValidatorRef.current.props.value) == true) {
            const saveObj = {
                "vendorName": vendorName,
                "email": email,
                "contact": contact,
                "gstNo": gstNo,
                "panNo": (panNo).toUpperCase(),
                "address": address,
                "sectionId": authUser.deptId,
                "branchId": authUser.branchId
            }
            await axios.post(saveInventoryVendorMasterAPIURL, saveObj)
                .then(res => {
                    if (res.data == "SAVED") {
                        getData();
                        clearData();
                        setLoaderOption(false);
                        toast.success("Saved successfully.");
                    }
                    else if (res.data == "ALREADYEXISTS") {
                        setLoaderOption(false);
                        toast.warn("Vendor with this details is already exists.");
                    }
                    else {
                        setLoaderOption(false);
                        toast.error("Inventory vendor master save failed.")
                    }
                }).catch(err => {
                    setLoaderOption(false);
                    console.log("Inventory vendor master save err " + err);
                    toast.error("Something went wrong, please check.")
                })
        }
        else {
            setLoaderOption(false);
            toast.error("Invalid email, please check.")
        }
    }

    const updateBtnClick = async () => {
        setLoaderOption(true);
        if (validateEmail(textValidatorRef.current.props.value) == true) {
            const updateObj = {
                "id": id,
                "vendorName": vendorName,
                "email": email,
                "contact": contact,
                "gstNo": gstNo,
                "panNo": (panNo).toUpperCase(),
                "address": address,
                "sectionId": authUser.deptId,
                "branchId": authUser.branchId
            }
            await axios.put(updateInventoryVendorMasterAPIURL, updateObj)
                .then(res => {
                    if (res.data == "UPDATED") {
                        getData();
                        clearData();
                        setLoaderOption(false);
                        toast.success("Updated successfully.")
                    }
                    else if (res.data == "ALREADYEXISTS") {
                        setLoaderOption(false);
                        toast.warn("Vendor with this details is already exists.");
                    }
                    else {
                        setLoaderOption(false);
                        toast.error("Inventory vendor master update failed.")
                    }
                }).catch(err => {
                    setLoaderOption(false);
                    console.log("Inventory vendor master update err " + err);
                    toast.error("Something went wrong, please check.")
                })
        }
        else {
            setLoaderOption(false);
            toast.error("Invalid email, please check.")
        }
    }

    const onSubmit = e => {
        e.preventDefault();
        if (state.button == 1) {
            saveBtnClick();
        }
        if (state.button == 2) {
            updateBtnClick();
        }
    }

    useEffect(() => {

        function handlekeydownEvent(event) {
            if (event.key === "Enter") {
                // console.log("Enter is pressed!")
                event.preventDefault();

                let btn1 = document.querySelector('.inventoryVendorSaveBtn')
                if (btn1 !== null) {
                    btn1.click();
                }
                let btn2 = document.querySelector('.inventoryVendorUpdateBtn')
                if (btn2 !== null) {
                    btn2.click();
                }

            }
        }

        document.addEventListener('keypress', handlekeydownEvent)
        return () => {
            document.removeEventListener('keypress', handlekeydownEvent)
        }
    }, [])

    return (
        <>
            {
                (loaderOption == true)
                    ?
                    <Loader />
                    :
                    <>
                        <TitleLabel titleId={titleId} />
                        <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: "350px" }}>
                            <form onSubmit={onSubmit}>
                                <input type="hidden" value={id} onChange={(e) => setId(e.target.value)} name="" />

                                <div className="row">
                                    <label htmlFor="lastname3" className="col-sm-2" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>Vendor Name :</label>
                                    <div className='col-sm-4'>
                                        <TextField
                                            required
                                            fullWidth
                                            onKeyDown={(e) => (e.key === " " && vendorName.length === 0) ? e.preventDefault() : ""}
                                            autoComplete='off'
                                            className=''
                                            size='small'
                                            id="outlined-basic"
                                            label="Vendor Name"
                                            variant="outlined"
                                            margin='dense'
                                            value={vendorName}
                                            onChange={(e) => setVendorName(e.target.value)}
                                            onKeyPress={(e) => (e.key == "Enter") ? vendorName : ""}
                                            inputProps={{ maxLength: 120 }}
                                            InputLabelProps={{ style: { fontSize: 14 } }}
                                        />
                                    </div>
                                </div>

                                <div className="row my-2">
                                    <label htmlFor="lastname3" className="col-sm-2" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>Email :</label>
                                    <div className='col-sm-4'>
                                        {/* <TextField
                                            required
                                            fullWidth
                                            onKeyDown={(e) => (e.key === " " && email.length === 0) ? e.preventDefault() : ""}
                                            autoComplete='off'
                                            className=''
                                            size='small'
                                            id="outlined-basic"
                                            label="Email"
                                            variant="outlined"
                                            margin='dense'
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                            onKeyPress={(e) => (e.key == "Enter") ? email : ""}
                                            inputProps={{ maxLength: 120 }}
                                            InputLabelProps={{ style: { fontSize: 14 } }}
                                        /> */}
                                        <ValidatorForm>
                                            <TextValidator
                                                ref={textValidatorRef}
                                                required
                                                fullWidth
                                                onKeyDown={(e) => (e.key === " " && email.length === 0) ? e.preventDefault() : ""}
                                                label="Email"
                                                autoComplete='off'
                                                size='small'
                                                margin='dense'
                                                onChange={(e) => setEmail(e.target.value)}
                                                onKeyPress={(e) => (e.key == "Enter") ? email : ""}
                                                name="email"
                                                value={email}
                                                variant="outlined"
                                                InputLabelProps={{ style: { fontSize: 14 } }}
                                                inputProps={{ maxLength: 120 }}
                                                validators={['isEmail']}
                                                errorMessages={['email is not valid']}
                                            />
                                        </ValidatorForm>
                                    </div>
                                </div>

                                <div className="row my-2">
                                    <label htmlFor="lastname3" className="col-sm-2" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>Contact :</label>
                                    <div className='col-sm-4'>
                                        <TextField
                                            required
                                            fullWidth
                                            onKeyDown={(e) => (e.key === " " && contact.length === 0) ? e.preventDefault() : ""}
                                            autoComplete='off'
                                            className=''
                                            size='small'
                                            id="outlined-basic"
                                            label="Contact"
                                            variant="outlined"
                                            margin='dense'
                                            value={contact}
                                            onChange={(e) => setContact(e.target.value)}
                                            onKeyPress={(e) => (e.key == "Enter") ? contact : ""}
                                            inputProps={{ maxLength: 120 }}
                                            InputLabelProps={{ style: { fontSize: 14 } }}
                                        />
                                    </div>
                                </div>


                                <div className="row my-2">
                                    <label htmlFor="lastname3" className="col-sm-2" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>GST No. :</label>
                                    <div className='col-sm-4'>
                                        <TextField
                                            required
                                            fullWidth
                                            onKeyDown={(e) => (e.key === " " && gstNo.length === 0) ? e.preventDefault() : ""}
                                            autoComplete='off'
                                            className=''
                                            size='small'
                                            id="outlined-basic"
                                            label="GST No."
                                            variant="outlined"
                                            margin='dense'
                                            value={gstNo}
                                            onChange={(e) => setGstNo(e.target.value)}
                                            onKeyPress={(e) => (e.key == "Enter") ? gstNo : ""}
                                            inputProps={{ maxLength: 120 }}
                                            InputLabelProps={{ style: { fontSize: 14 } }}
                                        />
                                    </div>
                                </div>


                                <div className="row my-2">
                                    <label htmlFor="lastname3" className="col-sm-2" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>PAN No. :</label>
                                    <div className='col-sm-4'>
                                        <TextField
                                            required
                                            fullWidth
                                            onKeyDown={(e) => (e.key === " " && panNo.length === 0) ? e.preventDefault() : ""}
                                            autoComplete='off'
                                            className=''
                                            size='small'
                                            id="outlined-basic"
                                            label="PAN No."
                                            variant="outlined"
                                            margin='dense'
                                            value={panNo}
                                            onChange={(e) => setPanNo(e.target.value)}
                                            onKeyPress={(e) => (e.key == "Enter") ? panNo : ""}
                                            inputProps={{ style: { textTransform: "uppercase" }, maxLength: 120 }}
                                            InputLabelProps={{ style: { fontSize: 14 } }}
                                        />
                                    </div>
                                </div>

                                <div className="row my-2">
                                    <label htmlFor="lastname3" className="col-sm-2" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>Address :</label>
                                    <div className='col-sm-6'>
                                        <TextField
                                            required
                                            fullWidth
                                            multiline
                                            rows={2}
                                            onKeyDown={(e) => (e.key === " " && address.length === 0) ? e.preventDefault() : ""}
                                            autoComplete='off'
                                            className=''
                                            size='small'
                                            id="outlined-basic"
                                            label="Address"
                                            variant="outlined"
                                            margin='dense'
                                            value={address}
                                            onChange={(e) => setAddress(e.target.value)}
                                            onKeyPress={(e) => (e.key == "Enter") ? address : ""}
                                            inputProps={{ maxLength: 120 }}
                                            InputLabelProps={{ style: { fontSize: 14 } }}
                                        />
                                    </div>
                                </div>

                                <div className="row mt-3">
                                    <div className="col-sm-12 offset-sm-2">
                                        <button type='submit' disabled={saveBtnDisabled} onClick={() => (state.button = 1)} className={saveBtn}>Save</button>
                                        <button type='submit' disabled={updateBtnDisabled} onClick={() => (state.button = 2)} className={updateBtn}>Update</button>
                                        <button type='button' onClick={() => clearData()} className='btn btn-sm btn-danger ml-2'>Cancel</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </>
            }
        </>
    )
}

export default InventoryVendorMaster