import React, { useEffect, useState } from 'react'
import TitleLabel from '../../CommonComponent/TitleLabel'
import makeStyles from "@mui/styles/makeStyles";
import { FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { useSelector } from 'react-redux';
import { DatePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import HoilidayMarkingTable from './HoilidayMarkingTable';
import { getHolidayMarkingAPIURL, saveHolidayMarkingAPIURL } from '../Services/HolidayMarkingAPIURL';
import Loader from '../../CommonComponent/Loader';
import { toast } from 'react-toastify';
import axios from 'axios';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
    menuPaper: {
        maxHeight: 200,
        "& ul": {
            backgroundColor: "#F4ECF7",
            //maxHeight: 160,
        },
        "& li": {
            // backgroundColor: "#F4ECF7", fontWeight: "550", fontSize: "14px" 
            fontSize: "14px",
            fontWeight: "550"
        },
    }
}));

const weekDetails = [
    {
        id: 1,
        weekName: 'Monday'
    },
    {
        id: 2,
        weekName: 'Tuesday'
    },
    {
        id: 3,
        weekName: 'Wednesday'
    },
    {
        id: 4,
        weekName: 'Thursday'
    },
    {
        id: 5,
        weekName: 'Friday'
    },
    {
        id: 6,
        weekName: 'Saturday'
    },
    {
        id: 7,
        weekName: 'Sunday'
    },
]

const HolidayMarking = () => {
    const titleId = "Holiday Marking"
    const authUser = useSelector((state) => state.user.value);
    const classes = useStyles();

    const TableHeading = [
        { label: 'Id', key: 'id', textAlign: "" },
        { label: `Holiday Type`, key: 'holidayType', textAlign: "" },
        { label: `Holiday Date`, key: 'holidayDate', textAlign: "" },
        { label: `Day`, key: 'day', textAlign: "" },
        { label: `Holiday Details`, key: 'detail', textAlign: "" },
        { label: 'Delete', value: 'delete', isAction: true, textAlign: "center" }];


    //Note: This below states are declared for Save & Update(Disabled/unDisabled)
    const saveBtnStyle = "holidayMarkingSaveBtn btn btn-primary btn-sm";
    const updateBtnStyle = "holidayMarkingUpdateBtn btn btn-primary btn-sm";
    const displayNoneBtnStyle = "d-none";
    const [saveBtn, setSaveBtn] = useState(saveBtnStyle);
    const [updateBtn, setUpdateBtn] = useState(displayNoneBtnStyle);

    const [loaderOption, setLoaderOption] = useState(false);

    const [option, setOption] = useState(1);
    const [holidayDate, setHolidayDate] = useState(null);
    const [weekObj, setWeekObj] = useState('');
    const [holidayDetails, setHolidayDetails] = useState('');
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);

    useEffect(() => {
        getData();
    }, [authUser])


    const getData = async () => {
        if (authUser.sessionYear != '' && authUser.branchId != null) {
            await axios.get(`${getHolidayMarkingAPIURL}?year=${authUser.sessionYear}&bid=${authUser.branchId}`)
                .then(res => {
                    setData(res.data);
                    setFilteredData(res.data);
                }).catch(err => {
                    console.log("holiday marking get" + err)
                })
        }
    }

    const clearData = () => {
        setSaveBtn(saveBtnStyle);
        setUpdateBtn(displayNoneBtnStyle);
        setOption(1);
        setWeekObj('');
        setHolidayDetails('');
        setHolidayDate(null);
    }

    const saveBtnClick = async () => {
        setLoaderOption(true);
        const saveObj = {
            "holidayType": option,
            "holidayDateFrom": (option == 2) ? moment(holidayDate).format("YYYY-MM-DD") : moment(authUser.fromDate).format("YYYY-MM-DD"),
            "holidayDateTo": (option == 2) ? null : moment(authUser.toDate).format("YYYY-MM-DD"),
            "holidayDay": (option == 2) ? null : weekObj,
            "detail": holidayDetails,
            "sessionYear": authUser.sessionYear,
            "branchId": authUser.branchId
        }
        // console.log(JSON.stringify(saveObj))
        await axios.post(saveHolidayMarkingAPIURL, saveObj)
            .then(res => {
                if (res.data == "SAVED") {
                    setLoaderOption(false);
                    getData();
                    clearData();
                    toast.success("Holiday details created successully.")
                }
                else if (res.data == "ALREADYEXISTS") {
                    setLoaderOption(false);
                    toast.warn("Holiday with this date is already exist.")
                }
                else {
                    setLoaderOption(false);
                    toast.error("Holiday marking save failed.")
                }
            }).catch(err => {
                setLoaderOption(false);
                console.log("Holiday marking save" + err);
                toast.error("Something went wrong, please check.")
            })
    }

    const state = {
        button: 1
    }

    const onSubmit = e => {
        e.preventDefault();
        if (state.button == 1) {
            saveBtnClick();
        }
    }

    return (
        <>
            {
                (loaderOption == true)
                    ?
                    <Loader />
                    :
                    <>
                        <TitleLabel titleId={titleId} />
                        <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: '350px' }}>
                            <form onSubmit={onSubmit}>
                                <div className="row my-2">
                                    <label className="" style={{ width: '140px', fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Holiday Type :</label>
                                    <div className='col-sm-3'>
                                        <FormControl fullWidth margin='dense'>
                                            <InputLabel id='demo-simple-select-label' size="small" style={{ fontSize: '14px' }}>Select Holiday Type</InputLabel>
                                            <Select
                                                size='small'
                                                required
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={option}
                                                label="Select Holiday Type"
                                                MenuProps={{ classes: { paper: classes.menuPaper } }}
                                                onChange={(e) => {
                                                    setOption(e.target.value);
                                                    setWeekObj('');
                                                    setHolidayDate(null);
                                                    setHolidayDetails('');
                                                }}
                                                onKeyPress={(e) => (e.key == "Enter") ? option : ""}
                                            >
                                                <MenuItem value={1}>Weekly Off</MenuItem>
                                                <MenuItem value={2}>Annually Off</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div>

                                {
                                    (() => {
                                        if (option == 1) {
                                            return (
                                                <>
                                                    <div className="row my-2">
                                                        <label className="" style={{ width: '140px', fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Day :</label>
                                                        <div className='col-sm-3'>
                                                            <FormControl fullWidth margin='dense'>
                                                                <InputLabel id='demo-simple-select-label' size="small" style={{ fontSize: '14px' }}>Select Day</InputLabel>
                                                                <Select
                                                                    size='small'
                                                                    required
                                                                    labelId="demo-simple-select-label"
                                                                    id="demo-simple-select"
                                                                    value={weekObj}
                                                                    label="Select Day"
                                                                    MenuProps={{ classes: { paper: classes.menuPaper } }}
                                                                    onChange={(e) => {
                                                                        setWeekObj(e.target.value);
                                                                        setHolidayDate(null);
                                                                        setHolidayDetails('');
                                                                    }}
                                                                    onKeyPress={(e) => (e.key == "Enter") ? option : ""}
                                                                >
                                                                    {
                                                                        weekDetails.map(e => {
                                                                            return (
                                                                                <MenuItem value={e.weekName}>{e.weekName}</MenuItem>
                                                                            )
                                                                        })
                                                                    }

                                                                </Select>
                                                            </FormControl>
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                        }
                                        else if (option == 2) {
                                            return (
                                                <>
                                                    <div className="row my-2">
                                                        <label htmlFor="lastname3" className="" style={{ width: '140px', fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>Holiday Date :</label>
                                                        <div className='col-sm-3'>
                                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                                <DatePicker
                                                                    label="Holiday Date"
                                                                    value={holidayDate}
                                                                    onChange={(newValue) => {
                                                                        setHolidayDate(newValue);
                                                                    }}
                                                                    onKeyPress={(e) => (e.key == "Enter") ? holidayDate : ""}
                                                                    inputFormat="dd/MM/yyyy"
                                                                    renderInput={(params) =>
                                                                        <TextField
                                                                            {...params}
                                                                            margin='dense'
                                                                            variant="outlined"
                                                                            InputLabelProps={{ style: { fontSize: 14 } }}
                                                                            required
                                                                            fullWidth
                                                                            autoComplete='off'
                                                                            size="small" />}
                                                                />
                                                            </LocalizationProvider>
                                                        </div>

                                                        <label htmlFor="lastname3" className="" style={{ width: '150px', fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>Holiday Details :</label>
                                                        <div className='col-sm-5'>
                                                            <TextField
                                                                required
                                                                fullWidth
                                                                onKeyDown={(e) => (e.key === " " && holidayDetails.length === 0) ? e.preventDefault() : ''}
                                                                autoComplete='off'
                                                                className=''
                                                                size='small'
                                                                id="outlined-basic"
                                                                label="Holiday Details"
                                                                variant="outlined"
                                                                margin='dense'
                                                                value={holidayDetails}
                                                                onChange={(e) => setHolidayDetails(e.target.value)}
                                                                onKeyPress={(e) => (e.key == "Enter") ? holidayDetails : ""}
                                                                inputProps={{ maxLength: 120 }}
                                                                InputLabelProps={{ style: { fontSize: 14 } }}
                                                            />
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                        }
                                    })()
                                }

                                <div className="row mt-3">
                                    <div className="col-sm-4" style={{ marginLeft: "140px" }}>
                                        <button type='submit' className={saveBtn} onClick={() => (state.button == 1)}>Accept</button>
                                        {/* <button type='submit' className={updateBtn}>Update</button> */}
                                        <button type='button' className="btn btn-sm btn-danger ml-2" onClick={() => clearData()}>Cancel</button>
                                    </div>
                                </div>
                            </form>

                            <div className="mt-3">
                                <HoilidayMarkingTable
                                    TableHeading={TableHeading}
                                    data={data}
                                    setData={setData}
                                    filteredData={filteredData}
                                    setFilteredData={setFilteredData}
                                    setSaveBtn={setSaveBtn}
                                    setUpdateBtn={setUpdateBtn}
                                    updateBtnStyle={updateBtnStyle}
                                    displayNoneBtnStyle={displayNoneBtnStyle}
                                    setLoaderOption={setLoaderOption}
                                    getData={getData}
                                />
                            </div>
                        </div>
                    </>
            }
        </>
    )
}

export default HolidayMarking