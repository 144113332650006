import React, { useState, useEffect } from 'react';
import TitleLabel from "../../CommonComponent/TitleLabel";
import TextField from '@mui/material/TextField';
import axios from "axios";
import { useSelector } from "react-redux";
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import { ToastContainer, toast } from 'react-toastify';
import Loader from '../../CommonComponent/Loader';
import Autocomplete from "@mui/material/Autocomplete";
import Paper from "@mui/material/Paper";
import makeStyles from "@mui/styles/makeStyles";
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import {
    getCategoryDetailsByDeptReceiptPayament, getAccountDetailsByCategoryIdAPIURL,
    getBudgetAssetEntryAPIURL, getBudgetAccountEntryAPIURL, getDepartmentsByBranch, getSansthaBudgetEntryDetail,
} from './Services/BudgetEntryAPIURL';
import BudgetDetailEntryTable from './BudgetDetailEntryTable';
import BudgetAccountWiseEntryTable from './BudgetAccountWiseEntryTable';
import BudgetAssetEntryTable from './BudgetAssetEntryTable';
import BudgetExternalExaminationReceiptTable from './BudgetExternalExaminationReceiptTable';
import SansthBudgetDetailEntryTable from './SansthBudgetDetailEntryTable';


const useStyles = makeStyles(theme => ({
    menuPaper: {
        maxHeight: 200,
        "& ul": {
            backgroundColor: "#F4ECF7",
            //maxHeight: 160,
        },
        "& li": {
            // backgroundColor: "#F4ECF7", fontWeight: "550", fontSize: "14px" 
            fontSize: "14px",
            fontWeight: "550"
        },
    }
}));

const newData = [
    {
        srNo: 1,
        accNameMr: "Salary Grant",
        budget1: 0,
    },
    {
        srNo: 2,
        accNameMr: "UGC Grant",
        budget1: 0,
    },
    {
        srNo: 3,
        accNameMr: "Non Salary Grant",
        budget1: 0,
    },
]

const BudgetEntryForm = () => {
    const authUser = useSelector((state) => state.user.value);
    const titleId = "Budget Entry";
    const classes = useStyles();
    const navigate = useNavigate();

    const [loaderOption, setLoaderOption] = useState(false);

    const [id, setId] = useState("");
    const [departmentData, setDepartmentData] = useState([]);
    const [departmentObj, setDepartmentObj] = useState(null);
    const [optionId, setOptionId] = useState('');
    const [budgetCategoryData, setBudgetCategoryData] = useState([]);
    const [budgetCategoryObj, setBudgetCategoryObj] = useState(null);
    const [transactionLabel, setTransactionLabel] = useState('');
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);

    const [previousYrTotalBudget, setPreviousYrTotalBudget] = useState(0);
    const [nextYrTotalBudget, setNextYrTotalBudget] = useState(0);
    const [actualDecTotalBudget, setActualDecTotalBudget] = useState(0);
    const [actualMarchTotalBudget, setActualMarchTotalBudget] = useState(0);
    const [accountName, setAccountName] = useState('');

    const [categoryType, setCategoryType] = useState('');

    const [totalAssetEntryBudget, setTotalAssetEntryBudget] = useState(0);
    const [totalAccountEntryBudget, setTotalAccountEntryBudget] = useState(0);

    const [totalActualBudget, setTotalActualBudget] = useState(0);

    const [totalStudentCount, setTotalStudentCount] = useState(0);

    useEffect(() => {
        getData();
    }, [authUser])


    const getData = async () => {
        if (authUser.branchId != null && authUser.deptId != null) {
            //onsole.log(`${getDepartmentsByBranch}?bid=${authUser.branchId}&sid=${authUser.deptId}`)
            await axios.get(`${getDepartmentsByBranch}?bid=${authUser.branchId}&sid=${authUser.deptId}`)
                .then(res => {
                    setDepartmentData(res.data);
                })
        }
    }

    //get category list
    const getCategoryData = async (opId) => {
        // console.log(`${getCategoryDetailsByDeptReceiptPayament}?deptId=${departmentObj.deptId}&optionId=${opId}`)
        await axios.get(`${getCategoryDetailsByDeptReceiptPayament}?deptId=${departmentObj.deptId}&optionId=${opId}`)
            .then(res => {
                setBudgetCategoryData(res.data);
            })
    }

    //get budget data by category
    const getBudgetDetails = async (e) => {
        e.preventDefault();
        setLoaderOption(true);
        setCategoryType(budgetCategoryObj.categoryType);
        setData([]);
        setFilteredData([]);
        //For detail entry budget
        if (budgetCategoryObj.categoryType == 1) {

            if (authUser.deptId == 15) {
                //receipt side for extrnal exam
                if (budgetCategoryObj.optionId == 1) {
                    setAccountName('');
                    setNextYrTotalBudget('');
                    setPreviousYrTotalBudget('');
                    // console.log(`${getSansthaBudgetEntryDetail}?categoryId=${budgetCategoryObj.id}&sessionYear=${authUser.sessionYear}&sid=${departmentObj.deptId}&bid=${authUser.branchId}`)
                    await axios.get(`${getSansthaBudgetEntryDetail}?categoryId=${budgetCategoryObj.id}&sessionYear=${authUser.sessionYear}&sid=${departmentObj.deptId}&bid=${authUser.branchId}`)
                        .then(res => {
                            if (res.data.length == 0) {
                                setLoaderOption(false);
                                toast.warn("Accounts are not found for this category.")
                            }
                            else {
                                setData(res.data);
                                setFilteredData(res.data);
                                //console.log(res.data)
                                res.data.map(e => {
                                    setNextYrTotalBudget(e.nextYearTotal);
                                    setPreviousYrTotalBudget(e.lastYearTotal);

                                    budgetCategoryData.map(item => {
                                        if (item.id == e.categoryId) {
                                            setAccountName(item.nameMr)
                                        }
                                    })
                                })

                                let updatedData = res.data
                                res.data.map((e, index) =>
                                    updatedData[index] = ({ ...e, totalStudWitFee: parseFloat(e.studentCount) * parseFloat(e.fee) })
                                )                              
                                const TotalStudFee = updatedData.reduce((prev, curr) => prev + parseFloat(curr.totalStudWitFee), 0);                              
                                setTotalStudentCount(parseFloat(TotalStudFee));

                                setLoaderOption(false);
                            }
                        }).catch(err => {
                            console.log("get detail entry err:" + err);
                            setLoaderOption(false);
                            toast.error("Something went wrong, please check.")
                        })
                }
                //paymenmt side for extrnal exam
                else {
                    setAccountName('');
                    setActualDecTotalBudget('');
                    setNextYrTotalBudget('');
                    setPreviousYrTotalBudget('');
                    //console.log(`${getSansthaBudgetEntryDetail}?categoryId=${budgetCategoryObj.id}&sessionYear=${authUser.sessionYear}&sid=${departmentObj.deptId}&bid=${authUser.branchId}`)
                    await axios.get(`${getSansthaBudgetEntryDetail}?categoryId=${budgetCategoryObj.id}&sessionYear=${authUser.sessionYear}&sid=${departmentObj.deptId}&bid=${authUser.branchId}`)
                        .then(res => {
                            if (res.data.length == 0) {
                                setLoaderOption(false);
                                toast.warn("Accounts are not found for this category.")
                            }
                            else {
                                setData(res.data);
                                setFilteredData(res.data);
                                //console.log(res.data)
                                res.data.map(e => {
                                    setActualDecTotalBudget(e.budgetTotal);
                                    setNextYrTotalBudget(e.nextYearTotal);
                                    setPreviousYrTotalBudget(e.lastYearTotal);

                                    budgetCategoryData.map(item => {
                                        if (item.id == e.categoryId) {
                                            setAccountName(item.nameMr)
                                        }
                                    })
                                })
                                setLoaderOption(false);
                            }
                        }).catch(err => {
                            console.log("get detail entry err:" + err);
                            setLoaderOption(false);
                            toast.error("Something went wrong, please check.")
                        })
                }

            }
            else {
                setAccountName('');
                setActualDecTotalBudget('');
                setActualMarchTotalBudget('');
                setNextYrTotalBudget('');
                setPreviousYrTotalBudget('');
                // console.log(`${getAccountDetailsByCategoryIdAPIURL}?categoryId=${budgetCategoryObj.id}&sessionYear=${authUser.sessionYear}&sid=${departmentObj.deptId}&bid=${authUser.branchId}`)
                await axios.get(`${getAccountDetailsByCategoryIdAPIURL}?categoryId=${budgetCategoryObj.id}&sessionYear=${authUser.sessionYear}&sid=${departmentObj.deptId}&bid=${authUser.branchId}`)
                    .then(res => {
                        if (res.data.length == 0) {
                            setLoaderOption(false);
                            toast.warn("Accounts are not found for this category.")
                        }
                        else {
                            let upadtedData = res.data
                            upadtedData.map((e, index) => {
                                upadtedData[index] = ({
                                    ...e,
                                    totalBudgetValue: parseFloat(e.actualBudget1) + parseFloat(e.actualBudget2)
                                })
                            })

                            setData(upadtedData);
                            setFilteredData(upadtedData);

                            const TotalActualBudget = upadtedData.reduce((prev, curr) => prev + parseFloat(curr.totalBudgetValue), 0);
                            //console.log(TotalActualBudget)
                            setTotalActualBudget(parseFloat(TotalActualBudget));
                            //console.log(res.data)
                            res.data.map(e => {
                                setActualDecTotalBudget(e.budget1Total);
                                setActualMarchTotalBudget(e.budget2Total);
                                setNextYrTotalBudget(e.nextYearTotal);
                                setPreviousYrTotalBudget(e.lastYearTotal);

                                budgetCategoryData.map(item => {
                                    if (item.id == e.categoryId) {
                                        setAccountName(item.nameMr)
                                    }
                                })
                            })
                            setLoaderOption(false);
                        }
                    }).catch(err => {
                        console.log("get detail entry err:" + err);
                        setLoaderOption(false);
                        toast.error("Something went wrong, please check.")
                    })
            }

        }
        //for account wise entry budget
        else if (budgetCategoryObj.categoryType == 2) {
            // console.log(`${getBudgetAccountEntryAPIURL}?categoryId=${budgetCategoryObj.id}&sessionYear=${authUser.sessionYear}&sid=${departmentObj.deptId}&bid=${authUser.branchId}`)
            await axios.get(`${getBudgetAccountEntryAPIURL}?categoryId=${budgetCategoryObj.id}&sessionYear=${authUser.sessionYear}&sid=${departmentObj.deptId}&bid=${authUser.branchId}`)
                .then(res => {
                    if (res.data.length == 0) {
                        setLoaderOption(false);
                        toast.warn("Accounts are not found for this category.")
                    }
                    else {
                        setData(res.data);
                        setFilteredData(res.data);
                        //console.log(res.data) 
                        const TotalAccountBudget = res.data.reduce((prev, curr) => prev + parseFloat(curr.amount), 0);
                        setTotalAccountEntryBudget(TotalAccountBudget);
                        setLoaderOption(false);
                    }

                }).catch(err => {
                    console.log("get budget account entry err:" + err);
                    setLoaderOption(false);
                    toast.error("Something went wrong, please check.")
                })
        }
        //for asset budget entry
        else if (budgetCategoryObj.categoryType == 3) {

            await axios.get(`${getBudgetAssetEntryAPIURL}?categoryId=${budgetCategoryObj.id}&sessionYear=${authUser.sessionYear}&sid=${departmentObj.deptId}&bid=${authUser.branchId}`)
                .then(res => {
                    if (res.data.length == 0) {
                        setLoaderOption(false);
                        toast.warn("Accounts are not found for this category.")
                    }
                    else {
                        setData(res.data);
                        setFilteredData(res.data);
                        //console.log(res.data)  

                        const TotalAssetBudget = res.data.reduce((prev, curr) => prev + parseFloat(curr.totalRate), 0);
                        setTotalAssetEntryBudget(parseFloat(TotalAssetBudget))
                        setLoaderOption(false);
                    }

                }).catch(err => {
                    console.log("get asset entry err:" + err);
                    setLoaderOption(false);
                    toast.error("Something went wrong, please check.")
                })
        }
    }

    const [futureYear, setFutureYear] = useState('');

    const currentYear = new Date();

    useEffect(() => {
        if (authUser.sessionYear < moment(currentYear).format("YYYY")) {
            let newYear = moment(authUser.fromDate).format("YY")
            //console.log(newYear)
            setFutureYear(parseInt(newYear) + 1);
            //console.log("less year====" + moment(currentYear).format("YY"));
        }
        else {
            setFutureYear(moment(currentYear).add(1, 'year').format("YY"))
            //console.log("This year====" + moment(currentYear).add(1, 'year').format("YY"));
        }
    }, [authUser])

    //BudgetDetailEntryTable
    const TableHeading1 = [
        { label: 'अनु. क्र', rowspan: '2', colspan: '', key: 'srNo', isAction: true },
        { label: `${transactionLabel} तपशील `, rowspan: '', colspan: '1', key: 'accNameMr', isAction: true },
        { label: `${(optionId == 1) ? "जमा" : "खर्च"}`, value: 'budget', rowspan: '', colspan: '1', isAction: true },
        { label: 'Actual Budget', value: 'actual', rowspan: '', colspan: '3', isAction: true },
        { label: `अंदाजे ${(optionId == 1) ? "जमा" : "खर्च"}`, value: 'budget', rowspan: '', colspan: '1', isAction: true }
    ];

    const TableHeading2 = [
        { label: `${accountName}`, key: 'categoryId', isAction: true },
        { label: `${parseInt(authUser.sessionYear)}-${parseInt(futureYear)}`, value: 'budgetLastYear', isAction: true },
        { label: `31/12/${parseInt(authUser.sessionYear)}`, value: 'actualBudget1', isAction: true },
        { label: `31/03/${parseInt(authUser.sessionYear) + 1}`, value: 'actualBudget2', isAction: true },
        { label: `एकूण ${(optionId == 1) ? "जमा" : "खर्च"}`, value: 'total', isAction: true },
        { label: `${parseInt(authUser.sessionYear) + 1}-${parseInt(futureYear) + 1}`, value: 'budgetNextYear', isAction: true }
    ];

    //BudgetAccountWiseEntryTable
    const TableHeading3 = [
        { label: 'अनु. क्र', key: 'srNo' },
        { label: 'तपशील', value: 'accNameMr' },
        { label: `${(optionId == 1) ? "जमा" : "खर्च"}`, value: 'budget', isAction: true },
    ];

    //BudgetAssetEntryTable
    const TableHeading4 = [
        { label: 'अनु. क्र', key: 'srNo' },
        { label: 'तपशील', key: 'accNameMr' },
        { label: 'नगसंख्या', key: 'quantity', isAction: true },
        { label: 'दर', key: 'rate', isAction: true },
        { label: 'एकूण', key: 'totalRate', isAction: true },
    ];

    //BudgetExternalExaminationReceiptTable
    const TableHeading5 = [
        { label: 'अनु. क्र', rowspan: '2', colspan: '', key: 'srNo', isAction: true },
        { label: `${transactionLabel} तपशील `, rowspan: '', colspan: '1', key: 'accNameMr', isAction: true },
        { label: `${(optionId == 1) ? "जमा" : "खर्च"}`, value: 'budget', rowspan: '', colspan: '1', isAction: true },
        { label: `अंदाजे ${(optionId == 1) ? 'जमा' : 'खर्च'}`, value: 'actual', rowspan: '', colspan: '1', isAction: true },
        { label: 'विद्यार्थी संख्या', rowspan: '2', colspan: '', key: 'srNo', isAction: true },
        { label: 'शुल्क', rowspan: '2', colspan: '', key: 'srNo', isAction: true },
        { label: 'एकूण', rowspan: '2', colspan: '', key: 'totalRate', isAction: true },
    ];

    const TableHeading6 = [
        { label: `${accountName}`, key: 'categoryId', isAction: true },
        { label: `${parseInt(authUser.sessionYear)}-${parseInt(futureYear)}`, value: 'budgetLastYear', isAction: true },
        { label: `31/12/${parseInt(authUser.sessionYear)}`, value: 'actualBudget1', isAction: true },
    ];

    //SansthBudgetDetailEntryTable
    const TableHeading7 = [
        { label: 'अनु. क्र', rowspan: '2', colspan: '', key: 'srNo', isAction: true },
        { label: `${transactionLabel} तपशील `, rowspan: '', colspan: '1', key: 'accountName', isAction: true },
        { label: `${(optionId == 1) ? "जमा" : "खर्च"}`, value: 'budget', rowspan: '', colspan: '1', isAction: true },
        { label: 'Actual Budget', value: 'actual', rowspan: '', colspan: '1', isAction: true },
        { label: `अंदाजे ${(optionId == 1) ? "जमा" : "खर्च"}`, value: 'budget', rowspan: '', colspan: '1', isAction: true }
    ];

    const TableHeading8 = [
        { label: `${accountName}`, key: 'categoryId', isAction: true },
        { label: `${parseInt(authUser.sessionYear)}-${futureYear}`, value: 'budgetLastYear', isAction: true },
        { label: `31/12/${parseInt(authUser.sessionYear)}`, value: 'actualBudget1', isAction: true },
        { label: `${parseInt(authUser.sessionYear) + 1}-${parseInt(futureYear) + 1}`, value: 'budgetNextYear', isAction: true }
    ];

    return (
        <>
            {
                (loaderOption == true)
                    ?
                    <Loader />
                    :
                    <>
                        <TitleLabel titleId={titleId} />
                        <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: "300px" }}>
                            {/* Form Data start */}
                            <div className=''>
                                <form onSubmit={getBudgetDetails}>
                                    <div className="form-data">
                                        <input type="hidden" value={id} onChange={(e) => setId(e.target.value)} name="" />

                                        <div className="row my-2">
                                            <label className="" style={{ width: "150px", fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Department :</label>
                                            <div className='col-sm-4'>
                                                <Autocomplete
                                                    id="combo-box-demo"
                                                    options={departmentData}
                                                    PaperComponent={({ children }) => (
                                                        <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                                    )}
                                                    onChange={(event, newValue) => {
                                                        setDepartmentObj(newValue);
                                                        setOptionId('');
                                                        setBudgetCategoryObj(null);
                                                        setCategoryType('');
                                                        setData([]);
                                                        setFilteredData([]);
                                                    }}
                                                    value={departmentObj}
                                                    style={{ fontSize: '14px' }}
                                                    getOptionLabel={option => (authUser.branchMedium == 1) ? option.deptNameMr : option.deptNameEn}
                                                    size="small"
                                                    renderInput={params => (
                                                        <TextField {...params} margin='dense' label="Select Department" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                                    )}
                                                />
                                            </div>

                                            <label className="col-sm-1" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Option :</label>
                                            <div className='col-sm-3'>
                                                <FormControl fullWidth margin='dense'>
                                                    <InputLabel id='demo-simple-select-label' size="small" style={{ fontSize: '14px' }}>Select Option</InputLabel>
                                                    <Select
                                                        size='small'
                                                        required
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        value={optionId}
                                                        label="Select Option"
                                                        MenuProps={{ classes: { paper: classes.menuPaper } }}
                                                        onChange={(e) => {
                                                            setOptionId(e.target.value);
                                                            if (e.target.value == 1) {
                                                                setTransactionLabel("जमा बाजू")
                                                            }
                                                            else if (e.target.value == 2) {
                                                                setTransactionLabel("खर्च बाजू")
                                                            }
                                                            getCategoryData(e.target.value);
                                                            setBudgetCategoryObj(null);
                                                            setCategoryType('');
                                                            setData([]);
                                                            setFilteredData([]);
                                                        }}
                                                        onKeyPress={(e) => (e.key == "Enter") ? optionId : ""}
                                                    >

                                                        <MenuItem value={1}>Receipt</MenuItem>
                                                        <MenuItem value={2}>Payment</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </div>
                                        </div>

                                        <div className="row my-2 mb-5">
                                            <label className="" style={{ width: "150px", fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Category :</label>
                                            <div className='col-sm-7'>
                                                <Autocomplete
                                                    id="combo-box-demo"
                                                    options={budgetCategoryData}
                                                    PaperComponent={({ children }) => (
                                                        <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                                    )}
                                                    onChange={(event, newValue) => {
                                                        setBudgetCategoryObj(newValue);
                                                        setCategoryType('');
                                                        setData([]);
                                                        setFilteredData([]);
                                                    }}
                                                    value={budgetCategoryObj}
                                                    style={{ fontSize: '14px' }}
                                                    getOptionLabel={option => (authUser.branchMedium == 1) ? option.nameMr : option.nameEn}
                                                    size="small"
                                                    renderInput={params => (
                                                        <TextField {...params} margin='dense' label="Select Category" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                                    )}
                                                />
                                            </div>

                                            <div className="col-sm-3 mt-3">
                                                <button type='submit' className='btn btn-sm btn-warning'>VIEW</button>
                                                {/* <button type='button' onClick={() => addAccountDetails()} hidden={(authUser.branchId != 50 || budgetCategoryObj == null) ? true : false} className='btn btn-sm ml-2' style={{ backgroundColor: "#2D9328", color: "#fff" }}>Add Accounts</button> */}
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>

                            <div className="">
                                {(() => {
                                    if (data.length != 0 || filteredData.length != 0) {
                                        if (categoryType == 1) {
                                            if (authUser.deptId == 15) {
                                                if (budgetCategoryObj.optionId == 1) {
                                                    return (
                                                        <>
                                                            <BudgetExternalExaminationReceiptTable
                                                                TableHeading1={TableHeading5}
                                                                TableHeading2={TableHeading6}
                                                                data={data}
                                                                setData={setData}
                                                                filteredData={filteredData}
                                                                setFilteredData={setFilteredData}
                                                                departmentObj={departmentObj}
                                                                setDepartmentObj={setDepartmentObj}
                                                                setOptionId={setOptionId}
                                                                setBudgetCategoryObj={setBudgetCategoryObj}
                                                                previousYrTotalBudget={previousYrTotalBudget}
                                                                nextYrTotalBudget={nextYrTotalBudget}
                                                                actualDecTotalBudget={actualDecTotalBudget}
                                                                actualMarchTotalBudget={actualMarchTotalBudget}
                                                                setPreviousYrTotalBudget={setPreviousYrTotalBudget}
                                                                setNextYrTotalBudget={setNextYrTotalBudget}
                                                                setActualDecTotalBudget={setActualDecTotalBudget}
                                                                setActualMarchTotalBudget={setActualMarchTotalBudget}
                                                                setTotalActualBudget={setTotalActualBudget}
                                                                totalActualBudget={totalActualBudget}
                                                                totalStudentCount={totalStudentCount}
                                                                setTotalStudentCount={setTotalStudentCount}
                                                            />
                                                        </>
                                                    )
                                                }
                                                else {
                                                    return (
                                                        <>
                                                            <SansthBudgetDetailEntryTable
                                                                TableHeading1={TableHeading7}
                                                                TableHeading2={TableHeading8}
                                                                data={data}
                                                                setData={setData}
                                                                filteredData={filteredData}
                                                                setFilteredData={setFilteredData}
                                                                departmentObj={departmentObj}
                                                                setDepartmentObj={setDepartmentObj}
                                                                setOptionId={setOptionId}
                                                                setBudgetCategoryObj={setBudgetCategoryObj}
                                                                previousYrTotalBudget={previousYrTotalBudget}
                                                                nextYrTotalBudget={nextYrTotalBudget}
                                                                actualDecTotalBudget={actualDecTotalBudget}
                                                                setPreviousYrTotalBudget={setPreviousYrTotalBudget}
                                                                setNextYrTotalBudget={setNextYrTotalBudget}
                                                                setActualDecTotalBudget={setActualDecTotalBudget}
                                                            />
                                                        </>
                                                    )
                                                }
                                            }
                                            else {
                                                return (
                                                    <>
                                                        <BudgetDetailEntryTable
                                                            TableHeading1={TableHeading1}
                                                            TableHeading2={TableHeading2}
                                                            data={data}
                                                            setData={setData}
                                                            filteredData={filteredData}
                                                            setFilteredData={setFilteredData}
                                                            departmentObj={departmentObj}
                                                            setDepartmentObj={setDepartmentObj}
                                                            setOptionId={setOptionId}
                                                            setBudgetCategoryObj={setBudgetCategoryObj}
                                                            previousYrTotalBudget={previousYrTotalBudget}
                                                            nextYrTotalBudget={nextYrTotalBudget}
                                                            actualDecTotalBudget={actualDecTotalBudget}
                                                            actualMarchTotalBudget={actualMarchTotalBudget}
                                                            setPreviousYrTotalBudget={setPreviousYrTotalBudget}
                                                            setNextYrTotalBudget={setNextYrTotalBudget}
                                                            setActualDecTotalBudget={setActualDecTotalBudget}
                                                            setActualMarchTotalBudget={setActualMarchTotalBudget}
                                                            setTotalActualBudget={setTotalActualBudget}
                                                            totalActualBudget={totalActualBudget}
                                                        />
                                                    </>
                                                )
                                            }
                                        }
                                        else if (categoryType == 2) {
                                            return (
                                                <>
                                                    <BudgetAccountWiseEntryTable
                                                        TableHeading3={TableHeading3}
                                                        data={data}
                                                        setData={setData}
                                                        filteredData={filteredData}
                                                        setFilteredData={setFilteredData}
                                                        departmentObj={departmentObj}
                                                        setDepartmentObj={setDepartmentObj}
                                                        setOptionId={setOptionId}
                                                        setBudgetCategoryObj={setBudgetCategoryObj}
                                                        getBudgetAccountEntryAPIURL={getBudgetAccountEntryAPIURL}
                                                        budgetCategoryObj={budgetCategoryObj}
                                                        totalAccountEntryBudget={totalAccountEntryBudget}
                                                    />
                                                </>
                                            )
                                        }
                                        else if (categoryType == 3) {
                                            return (
                                                <>
                                                    <BudgetAssetEntryTable
                                                        TableHeading4={TableHeading4}
                                                        data={data}
                                                        setData={setData}
                                                        filteredData={filteredData}
                                                        setFilteredData={setFilteredData}
                                                        departmentObj={departmentObj}
                                                        setDepartmentObj={setDepartmentObj}
                                                        setOptionId={setOptionId}
                                                        setBudgetCategoryObj={setBudgetCategoryObj}
                                                        getBudgetAssetEntryAPIURL={getBudgetAssetEntryAPIURL}
                                                        budgetCategoryObj={budgetCategoryObj}
                                                        totalAssetEntryBudget={totalAssetEntryBudget}
                                                    />
                                                </>
                                            )
                                        }
                                    }
                                })()}
                            </div>
                        </div>
                    </>
            }

            {/* <ToastContainer position="top-right" theme="colored" /> */}
        </>
    )
}

export default BudgetEntryForm