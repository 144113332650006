import { TablePagination, TextField } from '@mui/material';
import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import TitleLabel from '../../../CommonComponent/TitleLabel';
import Loader from '../../../CommonComponent/Loader';
import makeStyles from "@mui/styles/makeStyles";
import { getEndowmentPrizeDataByPrizeIdAPIURL, getPrizeAllocatedListAPIURL, getPrizeAllocatedListForSansthaAPIURL } from '../Services/EndowmentReportAPIURL';
import axios from 'axios';
import { toast } from 'react-toastify';

const useStyles = makeStyles({
    caption: {
        padding: 0,
        fontWeight: 600,
        fontSize: "12px"
    },
    toolbar: {
        fontSize: "12px",
        fontWeight: 600
    },
    selectLabel: {
        fontSize: "12px",
        fontWeight: 500,
        margin: "-10px",
        padding: "0"
    },
    select: {
        fontSize: "12px",
        margin: "0",
        padding: "0"
    },
    displayedRows: {
        fontSize: "12px",
        margin: "-22px",
        padding: "0"
    },
    menuItem: {
        fontSize: "12px",
        fontWeight: 500,
    }
});

const PrizeAllocatedStudentLIst = () => {
    const classes = useStyles();
    const authUser = useSelector((state) => state.user.value);
    const titleId = "Allocated Prizes List"
    const [loaderOption, setLoaderOption] = useState(false);

    const [data, setData] = useState([]);
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [filteredData, setFilteredData] = useState([]);
    const [prizeCode, setPrizeCode] = useState('');
    const [prizeData, setPrizeData] = useState([]);

    const onPageChange = (event, nextPage) => {
        setPage(nextPage);
    }

    const onRowsPerPageChange = (e) => {
        setRowsPerPage(parseInt(e.target.value));
        setPage(0);
    }

    const TableHeading1 = [
        { label: `Id`, key: 'id', isAction: true },
        { label: `Prize For`, key: 'prizeFor', isAction: true },
        { label: `Student / Employee Name`, key: (authUser.branchMedium == 1) ? 'studentNameMr' : 'studentNameEn', isAction: true },
        // { label: `School Name`, key: (authUser.branchMedium == 1) ? 'schoolNameMr' : 'schoolNameEn', isAction: true },
        { label: `Class`, key: (authUser.branchMedium == 1) ? 'classNameMr' : 'classNameEn', isAction: true },
        { label: `Subject`, key: (authUser.branchMedium == 1) ? 'subjectNameMr' : 'subjectNamEn', isAction: true },
        { label: `Sr No.`, key: 'srNo', isAction: true },
        { label: `Amount`, key: 'amount', isAction: true },
    ];

    const TableHeading2 = [
        { label: `Id`, key: 'id', isAction: true },
        { label: `Prize For`, key: 'prizeFor', isAction: true },
        { label: `Student / Employee Name`, key: (authUser.branchMedium == 1) ? 'studentNameMr' : 'studentNameEn', isAction: true },
        { label: `School Name`, key: (authUser.branchMedium == 1) ? 'schoolNameMr' : 'schoolNameEn', isAction: true },
        { label: `Class`, key: (authUser.branchMedium == 1) ? 'classNameMr' : 'classNameEn', isAction: true },
        { label: `Subject`, key: (authUser.branchMedium == 1) ? 'subjectNameMr' : 'subjectNamEn', isAction: true },
        { label: `Sr No.`, key: 'srNo', isAction: true },
        { label: `Amount`, key: 'amount', isAction: true },
    ];


    const getPrizeDetailsData = async () => {
        setLoaderOption(true);

        await axios.get(`${getEndowmentPrizeDataByPrizeIdAPIURL}?prizeCode=${prizeCode}`)
            .then(res => {
                if (res.data == "NOTFOUND") {
                    setLoaderOption(false);
                    toast.warn("Data not found.")
                }
                else {
                    setPrizeData(res.data);
                    setLoaderOption(false);
                }
            }).catch(err => {
                setLoaderOption(false);
                console.log("Student approval get err" + err);
            })
        if (authUser.branchId == 50) {
            // console.log(`${getPrizeAllocatedListForSansthaAPIURL}?prizeCode=${prizeCode}&year=${authUser.sessionYear}`)
            await axios.get(`${getPrizeAllocatedListForSansthaAPIURL}?prizeCode=${prizeCode}&year=${authUser.sessionYear}`)
                .then(res => {
                    if (res.data == "NOTFOUND") {
                        setLoaderOption(false);
                        toast.warn("Data not found.")
                    }
                    else {
                        setData(res.data);
                        setFilteredData(res.data);
                        setLoaderOption(false);
                    }
                }).catch(err => {
                    setLoaderOption(false);
                    console.log("Student approval get err" + err);
                })
        }
        else {

            // console.log(`${getPrizeAllocatedListAPIURL}?prizeCode=${prizeCode}&year=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}`)
            await axios.get(`${getPrizeAllocatedListAPIURL}?prizeCode=${prizeCode}&year=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}`)
                .then(res => {
                    if (res.data == "NOTFOUND") {
                        setLoaderOption(false);
                        toast.warn("Data not found.")
                    }
                    else {
                        setData(res.data);
                        setFilteredData(res.data);
                        setLoaderOption(false);
                    }
                }).catch(err => {
                    setLoaderOption(false);
                    console.log("Student approval get err" + err);
                })
        }

    }

    return (
        <>
            {(loaderOption == true)
                ?
                <Loader />
                :
                <div>
                    <TitleLabel titleId={titleId} />
                    <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: '350px' }}>
                        <form onSubmit={getPrizeDetailsData}>
                            <div className="row my-2">
                                <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >खाते क्र. / Prize Code :</label>
                                <div className='col-sm-3'>
                                    <TextField
                                        required
                                        fullWidth
                                        type="number"
                                        autoComplete='off'
                                        size='small'
                                        id="outlined-basic"
                                        label="खाते क्र. / Prize Code"
                                        value={prizeCode}
                                        onChange={(e) => {
                                            setPrizeCode(e.target.value);
                                            setData([]);
                                            setFilteredData([]);
                                        }}
                                        variant="outlined"
                                        margin='dense'
                                        InputLabelProps={{ style: { fontSize: 14 } }}
                                    //inputProps={{ readOnly: true }}
                                    />
                                </div>
                            </div>

                            <div className="row my-2">
                                <div className="col-sm-4 offset-sm-2">
                                    <button type='submit' className="btn btn-sm btn-primary">Show</button>
                                </div>
                            </div>
                        </form>

                        {(() => {
                            if (data.length != 0 || filteredData.length != 0) {
                                return (
                                    <>
                                        <div className="row mt-5 mb-2" >
                                            <div className="">
                                                <div style={{ float: "left", }}>
                                                    <span className='mt-3' style={{ fontSize: "15px", color: "#DC2C0C", fontWeight: "600" }}><span style={{ fontSize: '15px', fontWeight: '600', color: '#2c3e50' }}>पारितोषिकाचे नाव : </span>{prizeData.prizeName}</span><br />
                                                    <span className='mt-1' style={{ fontSize: "15px", color: "#DC2C0C", fontWeight: "600" }}><span style={{ fontSize: '15px', fontWeight: '600', color: '#2c3e50' }}>देणगीदाराचे नाव : </span>{prizeData.donorName}</span><br />
                                                    <span className='mt-1' style={{ fontSize: "15px", color: "#DC2C0C", fontWeight: "600" }}><span style={{ fontSize: '15px', fontWeight: '600', color: '#2c3e50' }}>योजनेची माहिती : </span>{prizeData.schemeDescription}</span><br />

                                                </div>
                                                {/* <div style={{ float: "right" }}>
                                                    <button
                                                        style={{ width: "100px", backgroundColor: "#2F9C0A", color: "#fff" }}
                                                        type='button'
                                                        onClick={() => printAllBudgetEntryReport()}
                                                        className='btn btn-sm mb-2'
                                                    //disabled={btnDisabled}
                                                    >
                                                        <PrintIcon fontSize="small" /> Print
                                                    </button>
                                                </div> */}
                                            </div>
                                        </div >
                                        <div className="table-data mt-3">
                                            <div className='table-responsive mt-3' style={{ overflowY: "hidden" }}>
                                                <table className="table table-bordered">
                                                    <thead className="table-Default" style={{ position: "sticky", overflow: "hidden", top: "0", backgroundColor: "#EAECEE", borderBottom: "2px solid #464de4" }}>
                                                        <tr>
                                                            {((authUser.branchId == 50) ? TableHeading2 : TableHeading1).map((item, index) => {
                                                                return (
                                                                    <th style={{ fontSize: "14px", fontWeight: "600", lineHeight: "1.4", minWidth: "20px" }} key={index} scope="col">{`${item.label}`}</th>
                                                                )
                                                            })}
                                                        </tr>
                                                    </thead>
                                                    <tbody style={{ borderTop: "2px solid #464de4", borderBottom: "2px solid #464de4" }}>

                                                        {
                                                            filteredData.map((item, index) => {
                                                                return (
                                                                    <tr key={index} className="">
                                                                        <td style={{ fontSize: "15px", fontWeight: "500" }} >{index + 1}</td>
                                                                        <td style={{ fontSize: "15px", fontWeight: "500" }} >{item.prizeFor}</td>
                                                                        <td style={{ fontSize: "15px", fontWeight: "500", lineHeight: '1.5' }} >{(authUser.branchMedium == 1) ? item.studentNameMr : item.studentNameEn}</td>
                                                                        <td style={{ fontSize: "15px", fontWeight: "500", lineHeight: '1.5' }} hidden={(authUser.branchId == 50) ? false : true}>{(authUser.branchMedium == 1) ? item.schoolNameMr : item.schoolNameEn}</td>
                                                                        <td style={{ fontSize: "15px", fontWeight: "500", lineHeight: '1.5' }} >{(authUser.branchMedium == 1) ? item.classNameMr : item.classNameEn}</td>
                                                                        <td style={{ fontSize: "15px", fontWeight: "500" }} >{(authUser.branchMedium == 1) ? item.subjectNameMr : item.subjectNamEn}</td>
                                                                        <td style={{ fontSize: "15px", fontWeight: "500" }} >{item.srNo}</td>
                                                                        <td style={{ fontSize: "15px", fontWeight: "500" }} >{item.amount}</td>
                                                                    </tr>
                                                                )
                                                            })}
                                                    </tbody>
                                                    <tfoot style={{ padding: "0px" }}>
                                                        <tr>
                                                            <TablePagination
                                                                style={{ padding: "0px" }}
                                                                rowsPerPageOptions={[10, 25, 50, 100]}
                                                                count={data.length}
                                                                rowsPerPage={rowsPerPage}
                                                                page={page}
                                                                onRowsPerPageChange={onRowsPerPageChange}
                                                                onPageChange={onPageChange}
                                                                labelRowsPerPage="Rows Per Page :"
                                                                classes={{ menuItem: classes.menuItem, displayedRows: classes.displayedRows, selectIcon: classes.selectIcon, toolbar: classes.toolbar, caption: classes.caption, selectLabel: classes.selectLabel, select: classes.select }}
                                                                backIconButtonProps={{ "aria-label": "Previous Page" }}
                                                                nextIconButtonProps={{ "aria-label": "Next Page" }} />
                                                        </tr>
                                                    </tfoot>
                                                </table>
                                            </div >
                                        </div>
                                    </>
                                )
                            }
                        })()}
                    </div>
                </div >
            }
        </>
    )
}

export default PrizeAllocatedStudentLIst
