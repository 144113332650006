import React, { useEffect, useState } from "react";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import { useSelector } from "react-redux";
import Loader from '../../CommonComponent/Loader';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from "react-router-dom";
import makeStyles from "@mui/styles/makeStyles";
import { saveSansthBudgetDetailEntry } from "./Services/BudgetEntryAPIURL";
import { TextField } from "@mui/material";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'
import DeleteModalBox from '../../CommonComponent/DeleteModalBox';
import Tooltip from '@mui/material/Tooltip';
import SansthaBudgetAccountEntryInput from "./SansthBudgetEntryInput/SansthaBudgetAccountEntryInput";
import { deleteSansthaBudgetEntryAPIURL } from "./Services/BudgetEntryAPIURL";

const useStyles = makeStyles({
    caption: {
        padding: 0,
        fontWeight: 600,
        fontSize: "12px"
    },
    toolbar: {
        fontSize: "12px",
        fontWeight: 600
    },
    selectLabel: {
        fontSize: "12px",
        fontWeight: 500,
        margin: "-10px",
        padding: "0"
    },
    select: {
        fontSize: "12px",
        margin: "0",
        padding: "0"
    },
    displayedRows: {
        fontSize: "12px",
        margin: "-22px",
        padding: "0"
    },
    menuItem: {
        fontSize: "12px",
        fontWeight: 500,
    }
});

const SansthaAccountEntryTable = ({ data, setData, filteredData, setFilteredData, setDepartmentObj,
    setOptionId, setBudgetCategoryObj, departmentObj, previousYrTotalBudget, nextYrTotalBudget, actualDecTotalBudget,
    futureYear, setNextYrTotalBudget, setActualDecTotalBudget, getBudgetDetailsAfterDelete, budgetCategoryObj,
    setPreviousYrTotalBudget }) => {
    const authUser = useSelector((state) => state.user.value);
    const navigate = useNavigate();
    const classes = useStyles();

    const [loaderOption, setLoaderOption] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [accountName, setAccountName] = useState('');

    const [chkAccName, setChkAccName] = useState(false);


    //console.log(chkAccName)

    const saveBudgetEntryDetails = async (e) => {
        e.preventDefault();
        setLoaderOption(true);

        let updatedData = data
        updatedData.map((item, index) => {
            updatedData[index] = ({
                "categoryId": budgetCategoryObj.id,
                "actualBudget": item.actualBudget,
                "nextYearBudget": item.budgetNextYear,
                "budgetNextYear": item.budgetNextYear,
                "budgetLastYear": item.budgetLastYear,
                "srNo": (item.srNo == '') ? index + 1 : item.srNo,
                "accName": item.accNameMr,
                "accNameMr": item.accNameMr,
                "accNameEn": item.accNameEn,
                "sessionYear": authUser.sessionYear,
                "sectionId": departmentObj.deptId,
                "branchId": authUser.branchId,
            })
        })

        // console.log(JSON.stringify(updatedData))
        await axios.post(saveSansthBudgetDetailEntry, updatedData)
            .then(res => {
                if (res.data == "SAVED") {
                    setData([]);
                    setFilteredData([]);
                    setDepartmentObj(null)
                    setOptionId('')
                    setBudgetCategoryObj(null)
                    setLoaderOption(false);
                    toast.success("Budget entry updated successfully.");
                }
            }).catch(err => {
                console.log("Budget entry save err:" + err);
                setLoaderOption(false);
                toast.error("Something went wrong, please check.")
            })

    }

    useEffect(() => {
        setFilteredData([])
        let tempFilteredData =
            data.filter((item) =>
                (item.accNameMr || '').toLowerCase().includes(searchTerm.toLowerCase()) ||
                (item.accNameEn || '').toLowerCase().includes(searchTerm.toLowerCase()) ||
                (item.srNo || '').toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
                (item.budgetLastYear || '').toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
                (item.budgetNextYear || '').toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
                (item.actualBudget || '').toString().toLowerCase().includes(searchTerm.toLowerCase())
            )
        setFilteredData(tempFilteredData)
        //setFilteredData(data.filter((item) => item.studentNameMr.toLowerCase().includes(searchTerm.toLowerCase()) || item.categoryNameMr.toLowerCase().includes(searchTerm.toLowerCase()) || item.genderLabel.toLowerCase().includes(searchTerm.toLowerCase()) || item.regCode.toString().toLowerCase().includes(searchTerm.toLowerCase()) || item.rollNo.toString().toLowerCase().includes(searchTerm.toLowerCase())))
    }, [searchTerm])

    useEffect(() => {
        function handlekeydownEvent(event) {
            if (event.key === "Enter") {
                event.preventDefault();
                let btn = document.querySelector('.budgetEntrySansthaAccountTableBtn')
                if (btn !== null) {
                    if(data.length != 0 || filteredData.length != 0){
                        btn.click();
                    }                   
                }
            }
        }

        document.addEventListener('keypress', handlekeydownEvent)
        return () => {
            document.removeEventListener('keypress', handlekeydownEvent)
        }

    }, []);

    const addAccountDetails = () => {
        setData([]);
        setFilteredData([]);
        setAccountName('')
        let newData = [];
        // data.map((e, index) => {
        newData = {
            // ...e,
            "srNo": '',
            "accName": accountName,
            "accNameMr": accountName,
            "accNameEn": accountName,
            "actualBudget": 0,
            "budgetNextYear": 0,
            "budgetLastYear": 0,
            "nextYearBudget": 0,
            "sessionYear": authUser.sessionYear,
            "sectionId": departmentObj.deptId,
            "branchId": authUser.branchId
        }
        // })
        // console.log(JSON.stringify(filteredData.concat(newData)))
        setFilteredData(filteredData.concat(newData))
        setData(data.concat(newData));
    }

    const [deleteId, setDeleteId] = useState("");
    const DeleteData = async (id) => {
        setLoaderOption(true);
        await axios.delete(`${deleteSansthaBudgetEntryAPIURL}?id=${id}`)
            .then((response) => {
                if (response.data == "DELETED") {
                    getBudgetDetailsAfterDelete();
                    setLoaderOption(false);
                    toast.success("Deleted sucessfully");
                }
            });
    }

    return (
        <>
            {
                (loaderOption == true)
                    ?
                    <Loader />
                    :
                    <>
                        <div className="">
                            <div className='row my-2'>
                                {/* <button className='btn btn-sm mb-3' style={{ float: 'left', backgroundColor: "#09880B", color: "#fff" }} onClick={() => setShowAddAccountModal(true)} >Add New Account</button> */}
                                <label className="col-sm-2" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>Account / तपशील :</label>
                                <div className="col-sm-6">
                                    <TextField
                                        required
                                        fullWidth

                                        autoComplete='off'
                                        className=''
                                        size='small'
                                        id="outlined-basic"
                                        label="Account / तपशील"
                                        variant="outlined"
                                        margin='dense'
                                        value={accountName}
                                        onChange={(e) => setAccountName(e.target.value)}
                                        onKeyPress={(e) => (e.key == "Enter") ? accountName : ""}
                                        inputProps={{ maxLength: 120 }}
                                        InputLabelProps={{ style: { fontSize: 14 } }}
                                    />
                                </div>

                                <div className="col-sm-4 mt-2">
                                    <button className='btn btn-sm' disabled={(accountName == "") ? true : false} onClick={() => addAccountDetails()} style={{ float: 'left', backgroundColor: "#09880B", color: "#fff" }} ><AddIcon fontSize="small" /> Add Account</button>
                                </div>
                            </div>

                            <div className='row mt-3' style={{ float: 'right' }}>
                                <div>
                                    <input
                                        className='form-control form-control-sm'
                                        type="text"
                                        style={{ width: "250px", display: "block", float: "right", marginBottom: "6px", border: "1px solid #C2C1C1" }}
                                        placeholder="Search Here"
                                        onChange={(event) => { setFilteredData([]); setSearchTerm(event.target.value); }}
                                        value={searchTerm}
                                    />
                                </div>{" "}
                            </div>
                            <form onSubmit={saveBudgetEntryDetails}>
                                <div className='table-responsive mb-3' style={{ maxHeight: "500px" }}>
                                    <table className="table table-bordered border-dark">
                                        <thead className="table-Default" style={{ borderTop: "2px solid #959595", borderBottom: "2px solid #464de4" }}>
                                            <tr>
                                                <th style={{ fontSize: "15px", fontWeight: "600", textAlign: "center", lineHeight: "1.4" }} scope="col">Id</th>
                                                <th style={{ fontSize: "15px", fontWeight: "600", textAlign: "center", lineHeight: "1.4" }} scope="col">Account / तपशील</th>
                                                <th style={{ fontSize: "15px", fontWeight: "600", textAlign: "center", lineHeight: "1.4" }} scope="col">{parseInt(authUser.sessionYear)}-{futureYear} अंदाज (खर्च)</th>
                                                <th style={{ fontSize: "15px", fontWeight: "600", textAlign: "center", lineHeight: "1.4" }} scope="col">डिसेंबर {parseInt(authUser.sessionYear)} अखेर (खर्च)</th>
                                                <th style={{ fontSize: "15px", fontWeight: "600", textAlign: "center", lineHeight: "1.4" }} scope="col">{parseInt(authUser.sessionYear) + 1}-{parseInt(futureYear) + 1} अंदाज</th>
                                                {/* <th style={{ fontSize: "15px", fontWeight: "600", textAlign: "center" }} scope="col">Edit</th> */}
                                                <th style={{ fontSize: "15px", fontWeight: "600", textAlign: "center" }} scope="col">Delete</th>
                                            </tr>
                                        </thead>
                                        <tbody style={{ borderTop: "2px solid #464de4", borderBottom: "2px solid #464de4" }}>
                                            {(() => {
                                                if (filteredData.length != 0) {
                                                    return (
                                                        <>
                                                            {
                                                                filteredData.map((item, index) => {
                                                                    return (
                                                                        <>
                                                                            <tr key={index}>
                                                                                <td style={{ fontSize: "14px", fontWeight: "500", width: "80px" }}>{(item.srNo == '') ? index + 1 : item.srNo}</td>

                                                                                <SansthaBudgetAccountEntryInput initialText1={item.accNameMr} initialText2={item.budgetLastYear}
                                                                                    initialText3={item.actualBudget} initialText4={item.budgetNextYear}
                                                                                    onChange={(value1, value3, value4) => {
                                                                                        let updatedData = filteredData
                                                                                        updatedData[index] = ({
                                                                                            ...item,
                                                                                            srNo: item.srNo,
                                                                                            accName: value1,
                                                                                            accNameMr: value1,
                                                                                            accNameEn: value1,
                                                                                            actualBudget: value3,
                                                                                            budgetNextYear: value4,
                                                                                            nextYearBudget: value4,
                                                                                        })
                                                                                        //console.log(JSON.stringify(updatedData))


                                                                                        const TotalDecActualBudget = updatedData.reduce((prev, curr) => prev + parseFloat(curr.actualBudget), 0);
                                                                                        setActualDecTotalBudget(parseFloat(TotalDecActualBudget).toFixed('2'))

                                                                                        const TotalNextYrBudget = updatedData.reduce((prev, curr) => prev + parseFloat(curr.budgetNextYear), 0);
                                                                                        setNextYrTotalBudget(parseFloat(TotalNextYrBudget).toFixed('2'))

                                                                                        const previousYrBudget = updatedData.reduce((prev, curr) => prev + parseFloat(curr.budgetLastYear), 0);
                                                                                        setPreviousYrTotalBudget(parseFloat(previousYrBudget).toFixed('2'))

                                                                                        setData(updatedData);
                                                                                        setFilteredData(updatedData);


                                                                                    }}

                                                                                />

                                                                                <td style={{ width: "100px",textAlign:"center" }}>
                                                                                    <Tooltip title="Delete">
                                                                                        <span className='btn btn-danger btn-sm mx-1 p-1'
                                                                                            onClick={() => setDeleteId(item.entryId)} data-bs-toggle="modal" data-bs-target="#exampleModal">
                                                                                            <FontAwesomeIcon icon={faTrash} />
                                                                                        </span>
                                                                                    </Tooltip>
                                                                                </td>

                                                                            </tr>
                                                                        </>
                                                                    )
                                                                })
                                                            }
                                                        </>
                                                    )
                                                }
                                            })()}
                                        </tbody>
                                        <tfoot style={{ borderBottom: "2px solid #959595" }}>
                                            <tr>
                                                <td style={{ fontSize: "15px", fontWeight: "600" }}></td>
                                                <td style={{ fontSize: "15px", fontWeight: "600", textAlign: "right" }}>Total Rs.</td>
                                                <td style={{ fontSize: "15px", fontWeight: "600", textAlign: "right" }}>{(previousYrTotalBudget)}</td>
                                                <td style={{ fontSize: "15px", fontWeight: "600", textAlign: "right" }}>{(actualDecTotalBudget)}</td>
                                                <td style={{ fontSize: "15px", fontWeight: "600", textAlign: "right" }}>{(nextYrTotalBudget)}</td>
                                                <td style={{ fontSize: "15px", fontWeight: "600" }}></td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>

                                <div className='mt-2'>
                                    <button disabled={(data.length != 0 || filteredData.length != 0) ? false : true} className='budgetEntrySansthaAccountTableBtn btn btn-primary btn-sm mb-2' type="submit"><AddIcon fontSize="small" />Save Changes</button>
                                </div>
                            </form>
                        </div>
                    </>
            }
            <DeleteModalBox deleteId={deleteId} DeleteData={DeleteData} />
        </>

    )
}

export default SansthaAccountEntryTable