import React, { useState, useEffect } from 'react';
import TitleLabel from "../../CommonComponent/TitleLabel";
import TextField from '@mui/material/TextField';
import axios from "axios";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import Loader from '../../CommonComponent/Loader';
import Autocomplete from "@mui/material/Autocomplete";
import Paper from "@mui/material/Paper";
import makeStyles from "@mui/styles/makeStyles";
import moment from 'moment';
import { printAllBudgetEntryReportAPIURL, getCategoryReportDataAllAPIURL, getSchoolListForBudget, getSainikSchoolHostelConsolidatedDataAPIURL, printSanikSchoolHostelConsolidatedReport } from './Services/BudgetReportAPIURL';
import { getExportedReportAPIURL } from '../../CommonServices/APIURL';
import BudgetConsolidateReportTable from './BudgetReportTables/BudgetConsolidateReportTable';
import { getBudgetResolutionNoDateAPIURL } from '../Services/BudgetNoAndDateAPIURL';
import SainikSchoolConsolidatedReportTable from './SansthaBudgetReportTables/SainikSchoolConsolidatedReportTable';

const useStyles = makeStyles(theme => ({
    menuPaper: {
        maxHeight: 200,
        "& ul": {
            backgroundColor: "#F4ECF7",
            //maxHeight: 160,
        },
        "& li": {
            // backgroundColor: "#F4ECF7", fontWeight: "550", fontSize: "14px" 
            fontSize: "14px",
            fontWeight: "550"
        },
    }
}));

const SchoolWiseBudgetReport = () => {
    const authUser = useSelector((state) => state.user.value);
    const titleId = "Schoolwise Budget Report";
    const classes = useStyles();
    const [loaderOption, setLoaderOption] = useState(false);

    const [schoolData, setSchoolData] = useState([]);
    const [schoolObj, setSchoolObj] = useState(null);
    const [departmentData, setDepartmentData] = useState([]);
    const [departmentObj, setDepartmentObj] = useState(null);


    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);

    const [resolutionNo, setResolutionNo] = useState('');
    const [resolutionDate, setResolutionDate] = useState(null);

    const [schoolNm, setSchoolNm] = useState('');

    const [actualSchoolReceiptFeeBudget, setActualSchoolReceiptFeeBudget] = useState(0);
    const [actualSessionReceiptFeeBudget, setActualSessionReceiptFeeBudget] = useState(0);
    const [actualSchoolPaymentFeeBudget, setActualSchoolPaymentFeeBudget] = useState(0);
    const [actualSessionPaymentFeeBudget, setActualSessionPaymentFeeBudget] = useState(0);

    const [actualSchoolReceiptHostelBudget, setActualSchoolReceiptHostelBudget] = useState(0);
    const [actualSchoolPaymentHostelBudget, setActualSchoolPaymentHostelBudget] = useState(0);

    const [studentWiseBudget, setStudentWiseBudget] = useState(0);

    //BudgetConsolidateReportTable
    const TableHeading7 = [
        { label: 'शाळा कीर्द जमा रुपये', textAlign: "", key: 'receipt', isAction: true },
        { label: 'सत्र फी कीर्द जमा रुपये', textAlign: "", key: '', isAction: true },
        { label: 'तपशील', key: 'payment', textAlign: "center", isAction: true },
        { label: 'शाळा कीर्द खर्च रुपये', textAlign: "", key: 'receipt', isAction: true },
        { label: 'सत्र फी कीर्द खर्च रुपये', textAlign: "", key: '', isAction: true },
    ]

    const TableHeading4 = [
        { label: 'रेग्युलर बी.एड. कोर्स कीर्द जमा रुपये', textAlign: "", key: 'receipt', isAction: true },
        { label: 'विनाअनुदानित कोर्सेस कीर्द जमा रुपये', textAlign: "", key: '', isAction: true },
        { label: 'तपशील', key: 'payment', textAlign: "center", isAction: true },
        { label: 'रेग्युलर बी.एड. कोर्स कीर्द खर्च रुपये', textAlign: "", key: 'receipt', isAction: true },
        { label: 'विनाअनुदानित कोर्सेस कीर्द खर्च रुपये', textAlign: "", key: '', isAction: true },
    ]

    //SainikSchoolConsolidatedReportTable
    const TableHeading9 = [
        { label: 'वसतिगृह कीर्द जमा रुपये', textAlign: "center", key: 'receipt', isAction: true },
        { label: 'तपशील', key: 'payment', textAlign: "center", isAction: true },
        { label: 'वसतिगृह कीर्द खर्च रुपये', textAlign: "center", key: 'payment', isAction: true },
    ]

    useEffect(() => {
        getData();
    }, [])

    const getData = async () => {
        // console.log(`${getSchoolListForBudget}`)
        await axios.get(`${getSchoolListForBudget}`)
            .then(res => {
                //setSchoolData(res.data);
                const objIds = res.data.reduce((a, { deptId, schoolName, branchId, headerName }) => {
                    a[deptId] = a[deptId] || { deptId, headerName, schoolName: [], branchId: [] }
                    return {
                        ...a, ...{
                            [deptId]: {
                                deptId,
                                headerName,
                                schoolName: a[deptId].schoolName.concat({
                                    branchId: branchId,
                                    schoolName: schoolName,
                                }),
                            }
                        }
                    }
                }, {})
                const result = Object.values(objIds)

                let newFilter = result.filter(e => e.deptId != 12)
                let updatedData = [{ "deptId": 0, "headerName": "सर्व शाळा" }, ...newFilter, { "headerName": "इंग्लिश मिडीयम", "branchMedium": 2 }]
                //console.log(JSON.stringify(newResult));
                setDepartmentData(updatedData);
            })
    }

    const getSchooliseFromDepartment = async (dId, bMedium) => {
        await axios.get(`${getSchoolListForBudget}`)
            .then(res => {
                if (dId == 0) {
                    setSchoolData(res.data);
                }
                else if (bMedium == 2) {
                    let schoolDataByBranchMedium = res.data.filter(e => e.branchMedium == 2 && e.deptId != 5)
                    setSchoolData(schoolDataByBranchMedium);
                }
                else {
                    let schoolDataByDeptId = res.data.filter(e => e.deptId == dId)
                    setSchoolData(schoolDataByDeptId);
                }
                //setSchoolData(res.data);                
            })
    }

    //print schoolwise consolidated budget details
    const printSchoolWiseBudgetEntryReport = async () => {
        setLoaderOption(true);
        if (schoolObj != null) {
            if (schoolObj.deptId == 14) {
                await axios.get(`${printSanikSchoolHostelConsolidatedReport}?sessionYear=${authUser.sessionYear}&bid=${schoolObj.branchId}&sid=${schoolObj.deptId}&langId=${authUser.branchMedium}`)
                    .then(res => {
                        if (res.data == "NOTFOUND") {
                            setLoaderOption(false);
                            toast.warn("Data not found.");
                        }
                        else {
                            setLoaderOption(false);
                            window.open(`${getExportedReportAPIURL}${res.data}`)
                        }
                    }).catch(err => {
                        console.log("Hostel Consolidated Report err:" + err);
                        setLoaderOption(false);
                        toast.error("Something went wrong, please check.")
                    })
            }
            else {
                await axios.get(`${printAllBudgetEntryReportAPIURL}?sessionYear=${authUser.sessionYear}&sid=${schoolObj.deptId}&bid=${schoolObj.branchId}&langId=${authUser.branchMedium}`)
                    .then(res => {
                        if (res.data == "NOTFOUND") {
                            setLoaderOption(false);
                            toast.warn("Data not found.");
                        }
                        else {
                            setLoaderOption(false);
                            window.open(`${getExportedReportAPIURL}${res.data}`)
                        }
                    }).catch(err => {
                        console.log("All Budget category Report err:" + err);
                        setLoaderOption(false);
                        toast.error("Something went wrong, please check.")
                    })
            }
        }

    }

    //get schoolwise consolidated budget details
    const getCategoryWiseDetails = async () => {
        setLoaderOption(true);
        setData([]);
        setFilteredData([]);
        setResolutionDate(null);
        setResolutionNo('');

        //console.log(JSON.stringify(schoolData))
        if (schoolObj != null) {
            schoolData.map(item => {
                if (item.branchId == schoolObj.branchId && item.deptId == schoolObj.deptId) {
                    setSchoolNm(item.schoolName);
                }
            })

            axios.get(`${getBudgetResolutionNoDateAPIURL}?sessionYear=${authUser.sessionYear}&bid=${schoolObj.branchId}&sid=${schoolObj.deptId}`)
                .then(res => {
                    res.data.map(e => {
                        //console.log(e.date + "----" + e.resolutionNo)
                        setResolutionDate(e.date);
                        setResolutionNo(e.resolutionNo);
                    })
                })

            if (schoolObj.deptId == 14) {
                await axios.get(`${getSainikSchoolHostelConsolidatedDataAPIURL}?sessionYear=${authUser.sessionYear}&bid=${schoolObj.branchId}&sid=${schoolObj.deptId}`)
                    .then(res => {
                        if (res.data == "NOTFOUND") {
                            setLoaderOption(false);
                            toast.warn("Data not found.")
                        }
                        else {
                            //console.log(JSON.stringify(res.data))
                            setData(res.data);
                            setFilteredData(res.data);

                            let receiptHostelData = res.data.filter(e => e.optionId == 1)
                            let paymentHostelData = res.data.filter(e => e.optionId == 2)

                            const TotalActualReceiptHostelBudget = receiptHostelData.reduce((prev, curr) => prev + parseFloat(curr.schoolFee), 0);
                            setActualSchoolReceiptHostelBudget(parseFloat(TotalActualReceiptHostelBudget).toFixed('2'));

                            const TotalActualPaymentHostelBudget = paymentHostelData.reduce((prev, curr) => prev + parseFloat(curr.schoolFee), 0);
                            setActualSchoolPaymentHostelBudget(parseFloat(TotalActualPaymentHostelBudget).toFixed('2'));

                            const objIds = res.data.reduce((a, { studentCount }) => {
                                a[studentCount] = a[studentCount] || { studentCount }
                                return {
                                    ...a, ...{
                                        [studentCount]: {
                                            studentCount,
                                        }
                                    }
                                }
                            }, {})
                            const result = Object.values(objIds)

                            const TotalStudentCount = result.reduce((prev, curr) => prev + parseFloat(curr.studentCount), 0);
                            let studentCountValue = TotalActualPaymentHostelBudget / TotalStudentCount
                            //console.log(studentCountValue)
                            setStudentWiseBudget(parseFloat(studentCountValue).toFixed('2'))

                            setLoaderOption(false);
                        }

                    }).catch(err => {
                        console.log("consolidate report err:" + err);
                        setLoaderOption(false);
                        toast.error("Something went wrong, please check.")
                    })
            }
            else {
                //console.log(`${getCategoryReportDataAllAPIURL}?sessionYear=${authUser.sessionYear}&bid=${schoolObj.branchId}&sid=${schoolObj.deptId}`)
                await axios.get(`${getCategoryReportDataAllAPIURL}?sessionYear=${authUser.sessionYear}&bid=${schoolObj.branchId}&sid=${schoolObj.deptId}`)
                    .then(res => {
                        if (res.data == "NOTFOUND") {
                            setLoaderOption(false);
                            toast.warn("Data not found.")
                        }
                        else {
                            //console.log(JSON.stringify(res.data))
                            setData(res.data);
                            setFilteredData(res.data);

                            let upadtedReceiptData = res.data.filter(e => e.optionId == 1)
                            let upadtedPaymentData = res.data.filter(e => e.optionId == 2)

                            const TotalSchoolReceiptFeeBudget = upadtedReceiptData.reduce((prev, curr) => prev + parseFloat(curr.schoolFee), 0);
                            setActualSchoolReceiptFeeBudget(parseFloat(TotalSchoolReceiptFeeBudget))
                            const TotalSessionReceiptFeeBudget = upadtedReceiptData.reduce((prev, curr) => prev + parseFloat(curr.sessionFee), 0);
                            setActualSessionReceiptFeeBudget(parseFloat(TotalSessionReceiptFeeBudget))

                            const TotalSchoolPaymentFeeBudget = upadtedPaymentData.reduce((prev, curr) => prev + parseFloat(curr.schoolFee), 0);
                            setActualSchoolPaymentFeeBudget(parseFloat(TotalSchoolPaymentFeeBudget))
                            const TotalSessionPaymentFeeBudget = upadtedPaymentData.reduce((prev, curr) => prev + parseFloat(curr.sessionFee), 0);
                            setActualSessionPaymentFeeBudget(parseFloat(TotalSessionPaymentFeeBudget))
                            setLoaderOption(false);
                        }

                    }).catch(err => {
                        console.log("school wise budget err:" + err);
                        setLoaderOption(false);
                        toast.error("Something went wrong, please check.")
                    })
            }
        }

    }

    return (
        <>
            {
                (loaderOption == true)
                    ?
                    <Loader />
                    :
                    <>
                        <TitleLabel titleId={titleId} />
                        <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: "300px" }}>
                            {/* Form Data start */}
                            <div className=''>
                                <form onSubmit={getCategoryWiseDetails}>
                                    <div className="form-data">

                                        <div className="row my-2">
                                            <label className="" style={{ width: "150px", fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Department :</label>
                                            <div className='col-sm-4'>
                                                <Autocomplete
                                                    id="combo-box-demo"
                                                    options={departmentData}
                                                    PaperComponent={({ children }) => (
                                                        <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                                    )}
                                                    onChange={(event, newValue) => {
                                                        setDepartmentObj(newValue);
                                                        setSchoolObj(null);
                                                        setSchoolData([]);
                                                        setData([]);
                                                        setFilteredData([]);
                                                        if (newValue != null) {
                                                            getSchooliseFromDepartment(newValue.deptId, newValue.branchMedium)
                                                        }
                                                    }}
                                                    value={departmentObj}
                                                    style={{ fontSize: '14px' }}
                                                    getOptionLabel={option => option.headerName}
                                                    size="small"
                                                    renderInput={params => (
                                                        <TextField {...params} margin='dense' label="Select Department" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                                    )}
                                                />
                                            </div>
                                        </div>

                                        <div className="row my-2">
                                            <label className="" style={{ width: "150px", fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >School :</label>
                                            <div className='col-sm-6'>
                                                <Autocomplete
                                                    id="combo-box-demo"
                                                    options={schoolData}
                                                    PaperComponent={({ children }) => (
                                                        <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                                    )}
                                                    onChange={(event, newValue) => {
                                                        setSchoolObj(newValue);
                                                        setData([]);
                                                        setFilteredData([]);
                                                    }}
                                                    value={schoolObj}
                                                    style={{ fontSize: '14px' }}
                                                    getOptionLabel={option => option.schoolName}
                                                    size="small"
                                                    renderInput={params => (
                                                        <TextField {...params} margin='dense' label="Select School" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                                    )}
                                                />
                                            </div>
                                        </div>

                                        <div className="row mt-3">
                                            <div className="col-sm-4" style={{ marginLeft: "150px" }}>
                                                <button type='submit' className='btn btn-sm btn-primary'>Show</button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>

                            <div className='mt-4'>
                                {(() => {
                                    if (data.length != 0 || filteredData.length != 0) {
                                        if (schoolObj.deptId == 14) {
                                            return (
                                                <>
                                                    <SainikSchoolConsolidatedReportTable
                                                        TableHeading7={TableHeading9}
                                                        setData={setData}
                                                        setFilteredData={setFilteredData}
                                                        data={data}
                                                        filteredData={filteredData}
                                                        schoolNm={schoolNm}
                                                        resolutionNo={resolutionNo}
                                                        resolutionDate={resolutionDate}
                                                        printAllBudgetEntryReport={printSchoolWiseBudgetEntryReport}
                                                        actualSchoolReceiptHostelBudget={actualSchoolReceiptHostelBudget}
                                                        actualSchoolPaymentHostelBudget={actualSchoolPaymentHostelBudget}
                                                        optionId={1}
                                                        studentWiseBudget={studentWiseBudget}
                                                    />
                                                </>
                                            )
                                        }
                                        else {
                                            return (
                                                <>
                                                    <BudgetConsolidateReportTable
                                                        TableHeading7={(schoolObj.deptId == 5) ? TableHeading4 : TableHeading7}
                                                        setData={setData}
                                                        setFilteredData={setFilteredData}
                                                        data={data}
                                                        filteredData={filteredData}
                                                        schoolNm={schoolNm}
                                                        resolutionNo={resolutionNo}
                                                        resolutionDate={resolutionDate}
                                                        printAllBudgetEntryReport={printSchoolWiseBudgetEntryReport}
                                                        actualSchoolReceiptFeeBudget={actualSchoolReceiptFeeBudget}
                                                        actualSessionReceiptFeeBudget={actualSessionReceiptFeeBudget}
                                                        actualSchoolPaymentFeeBudget={actualSchoolPaymentFeeBudget}
                                                        actualSessionPaymentFeeBudget={actualSessionPaymentFeeBudget}
                                                        optionId={1}
                                                        authUser={schoolObj}
                                                    />
                                                </>
                                            )
                                        }
                                    }
                                })()}
                            </div>
                        </div>
                    </>
            }
        </>
    )
}


export default SchoolWiseBudgetReport