import { BaseURL } from "../../CommonServices/APIURL";

// /getMaxOutwardNo
export const getOutwardEntryNoAPIURL = BaseURL + "inwardOutward/getMaxOutwardNo";

// /http://192.168.29.102:8081/institute-sss/institute/sss/inwardOutward/saveOutwardEntry

// {
//     "outwardNo": 1,
//     "entryDate": "2025-02-05",
//     "duplicateNo": 112,
//     "option": 1,
//     "branchName": "branch Name",
//     "contentOfOutward": "content Of outward",
//     "toWhomSent": "to Whom Sent",
//     "address": "address",
//     "receivedAmount": 100.00,
//     "dispatchedAmount": 100.00,
//     "balanceAmount": 100.00,
//     "remark": "remark",
//     "sessionYear": 2024,
//     "sectionId": 3,
//     "branchId": 1
// }
export const saveOutwardEntryAPIURL = BaseURL + "inwardOutward/saveOutwardEntry";

//updateOutwardEntry
export const updateOutwardEntryAPIURL = BaseURL + "inwardOutward/updateOutwardEntry";

// /deleteOutwardEntry
export const deleteOutwardEntryAPIURL = BaseURL + "inwardOutward/deleteOutwardEntry";

//http://192.168.29.102:8081/institute-sss/institute/sss/inwardOutward/getOutwardEntryList?year=2024&sid=3&bid=1
export const getOutwardEntryAPIURL = BaseURL + "inwardOutward/getOutwardEntryList";


//Report
//http://192.168.195.102:8081/institute-sss/institute/sss/inwardOutward/reports/getOutwardEntryReport?langId=2&year=2024&sid=3&bid=1&fromDate=2024-04-01&toDate=2025-03-31
export const PrintOutwardEntryPdfReportAPIURL = BaseURL + "inwardOutward/reports/getOutwardEntryReport";

//http://192.168.195.102:8081/institute-sss/institute/sss/inwardOutward/reports/getOutwardEntryReportExcel?langId=1&year=2024&sid=3&bid=1&fromDate=2024-04-01&toDate=2025-03-31
export const PrintOutwardEntryExcelReportAPIURL = BaseURL + "inwardOutward/reports/getOutwardEntryReportExcel";
