import React, { useState, useEffect } from 'react'
import AddIcon from '@mui/icons-material/Add';
import { ToastContainer, toast } from 'react-toastify';
import makeStyles from "@mui/styles/makeStyles";
import TitleLabel from "../../CommonComponent/TitleLabel";
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faPrint } from '@fortawesome/free-solid-svg-icons'
import TablePagination from '@mui/material/TablePagination';
import Tooltip from '@mui/material/Tooltip';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { useSelector } from "react-redux";
import { useParams } from 'react-router-dom';
import { getExportedReportAPIURL } from '../../CommonServices/APIURL';
import { getDonationDataList, getDonationFeeList, printDengiPavati, printAllDengiPohochPavatiForAllBranch } from '../Services/DengiPavatiAPIURL';
import { BaseURLInstituteMaster } from '../../CommonServices/InstituteMasterAPIURL';
import Loader from '../../CommonComponent/Loader';
import PrintIcon from '@mui/icons-material/Print';

const useStyles = makeStyles({
    caption: {
        padding: 0,
        fontWeight: 600,
        fontSize: "12px"
    },
    toolbar: {
        fontSize: "12px",
        fontWeight: 600,
        margin: "0",
        padding: "0"
    },
    selectLabel: {
        fontSize: "12px",
        fontWeight: 500,
        margin: "-10px",
        padding: "0"
    },
    select: {
        fontSize: "12px",
        margin: "0",
        padding: "0"
    },
    displayedRows: {
        fontSize: "12px",
        margin: "-22px",
        padding: "0"
    },
    menuItem: {
        fontSize: "12px",
        fontWeight: 500,
    }

});

const DengiPavatiTable = () => {
    const authUser = useSelector((state) => state.user.value);
    let { toastFlag } = useParams();
    const titleId = "Dengi Pavati Table";
    const classes = useStyles();
    const navigate = useNavigate();

    const [loaderOption, setLoaderOption] = useState(false);

    //Note: This below states are declared for Save & Update(Disabled/unDisabled)
    const saveBtnStyle = "btn btn-primary btn-sm";
    const updateBtnStyle = "btn btn-primary btn-sm";
    const displayNoneBtnStyle = "d-none";
    const [saveBtn, setSaveBtn] = useState(saveBtnStyle);
    const [updateBtn, setUpdateBtn] = useState(displayNoneBtnStyle);

    const [isAscending, setAscending] = useState(false);
    const [sortedBy, setSortedBy] = useState(null);
    const [searchTerm, setSearchTerm] = useState("")
    const [data, setData] = useState([]);
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [filteredData, setFilteredData] = useState([]);
    const [feeData, setFeeData] = useState([]);
    const [instituteData, setInstituteData] = useState([]);

    const [selectedMenu, setSelectedMenu] = useState('Fee Type');

    const getFilteredData = (filterId, value) => {
        if (filterId == 1) {
            setFilteredData([]);
            setPage(0);

            const filterDengiPavatiByFee = data.filter(e => e.feeId == value.id)

            setSelectedMenu(((authUser.branchMedium == 1) ? value.nameMr : value.nameEn) + " " + `(${filterDengiPavatiByFee.length})`);

            setFilteredData(filterDengiPavatiByFee);
        }
        else if (filterId == 2) {
            setFilteredData([]);
            setSelectedMenu('Fee Type');
            getData();
        }
    }

    useEffect(() => {
        if (toastFlag == 1) {
            toast.success("Dengi Pavati saved sucessfully.")
        }
        else if (toastFlag == 2) {
            toast.success("Dengi Pavati updated sucessfully.")
        }

    }, []);


    const onPageChange = (event, nextPage) => {
        setPage(nextPage);
    }
    const onRowsPerPageChange = (e) => {
        setRowsPerPage(parseInt(e.target.value));
        setPage(0);
    }


    useEffect(() => {
        getData();
        navigate("/Home/f314/0")
    }, [authUser]);

    const getData = async () => {

        if (authUser.deptId != null && authUser.branchId != null && authUser.sessionYear != "") {
            // console.log(`${getDonationDataList}?sid=${authUser.deptId}&bid=${authUser.branchId}&acyr=${authUser.sessionYear}`)
            await axios(`${getDonationDataList}?sid=${authUser.deptId}&bid=${authUser.branchId}&acyr=${authUser.sessionYear}`)
                .then(res => {
                    setData(res.data);
                    setFilteredData(res.data);
                })
        }

        await axios(`${getDonationFeeList}`)
            .then(res => {
                setFeeData(res.data);
            })

        await axios(`${BaseURLInstituteMaster}`)
            .then(res => {
                //console.log(JSON.stringify(res.data))
                let updatedData = res.data;
                let newData = [...updatedData, { id: 50, schoolNameMr: "सांगली शिक्षण संस्था", schoolNameEn: "Sangli Shikshan Sanstha" }]
                //console.log(JSON.stringify(newData))
                setInstituteData(res.data);
            })
    }

    const dengiPavatiReportPrint = async (tranId) => {
        setLoaderOption(true);
        //console.log(`${printDengiPavati}?langId=${authUser.branchMedium}&acYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&recid=${tranId}`)
        await axios.get(`${printDengiPavati}?langId=${authUser.branchMedium}&acYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&recid=${tranId}`)
            .then((response) => {
                if (response.data == "NOTFOUND") {
                    setLoaderOption(false);
                    toast.warn("Data not found.")
                }
                else if (response.data != null) {
                    //console.log(response.data)                    
                    window.open(`${getExportedReportAPIURL}${response.data}`);
                    setLoaderOption(false);
                }
            })
            .catch(error => {
                toast.error("Something went wrong, try after sometime.")
                console.log("Dengi Pavati Report failed: " + error)
            })
    }

    const allBranchDengiPohochPavatiReport = async () => {
        setLoaderOption(true);
        await axios.get(`${printAllDengiPohochPavatiForAllBranch}?langId=${authUser.branchMedium}&acYear=${authUser.sessionYear}`)
            .then(res => {
                if (res.data == "NOTFOUND") {
                    setLoaderOption(false);
                    toast.warn("Data not found.")
                }
                else {
                    setLoaderOption(false);
                    window.open(`${getExportedReportAPIURL}${res.data}`)
                }
            }).catch(err => {
                setLoaderOption(false);
                console.log("Dengi pohoch pavati all branch err:" + err);
                toast.error("Something went wrong, please check.")
            })
    }



    const onSort = (key, isAction) => {
        if (!isAction) {
            setSortedBy(key)
            if (isAscending) setFilteredData([...data.sort((a, b) => a[key].toString().toLowerCase() < b[key].toString().toLowerCase() ? 1 : -1)])
            else setFilteredData([...data.sort((a, b) => a[key].toString().toLowerCase() > b[key].toString().toLowerCase() ? 1 : -1)])
            setAscending(!isAscending)
        }
    }

    useEffect(() => {
        setFilteredData(
            data.filter(
                (item) =>
                    item.id.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
                    item.transactionDate.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    item.donatorName.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    item.feeNameMr.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    item.feeNameEn.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    item.donatorAddress.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    item.receivedAmount.toString().toLowerCase().includes(searchTerm.toLowerCase())
            )
        );
    }, [searchTerm]);


    const updateData = (item, Editflag) => {
        navigate(`/Home/f314F/${item}/${Editflag}`)
    }


    const TableHeading = [
        { label: 'SrNo', key: 'id' },
        { label: `Transaction Date`, key: 'transactionDate' },
        { label: `Donator Name & Address`, key: 'donatorName' },
        { label: `School`, key: 'branchId' },
        { label: `Fee`, key: (authUser.branchMedium == 1) ? 'feeNameMr' : 'feeNameEn' },
        { label: `Amount`, key: 'receivedAmount' },
        { label: 'Edit', value: 'edit', isAction: true },
        //{ label: 'Delete', value: 'delete', isAction: true },
        { label: 'Print', value: 'print', isAction: true }];

    return (
        <>
            {
                (loaderOption == true)
                    ?
                    <Loader />
                    :
                    <div>
                        <TitleLabel titleId={titleId} />
                        <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: "350px" }}>
                            <div className="mb-5">
                                <div className="btn-group mt-1 mr-2">
                                    <button type="button" className="btn dropdown-toggle" style={{ backgroundColor: "#A654BF", color: "#fff" }} data-bs-toggle="dropdown" aria-expanded="false">
                                        {selectedMenu}
                                    </button>
                                    <ul className="dropdown-menu" style={{ height: "200px", overflowY: "scroll" }}>
                                        {
                                            feeData.map((e, index) => {
                                                return (

                                                    <li><span className="dropdown-item" style={{ fontSize: "14px", fontWeight: "600", cursor: "pointer" }} onClick={() => { getFilteredData(1, e); }}>
                                                        {(authUser.branchMedium == 1) ? e.nameMr : e.nameEn} ({(data.filter(e1 => e1.feeId == e.id)).length})

                                                    </span></li>

                                                )
                                            })
                                        }
                                    </ul>
                                </div>

                                <button className='btn mt-1 mr-2' style={{ backgroundColor: "#0092D6", color: "#fff" }} onClick={() => getFilteredData(2, 0)}>Reset</button>
                            </div>

                            <div className=''>
                                <div className=''>
                                    <button type='button' className='btn btn-primary btn-sm mb-2' style={{ float: 'left' }} onClick={() => navigate("/Home/f314F/0/1")}><AddIcon fontSize="small" /> New Dengi Pavati</button>
                                    <button type='button' className='btn btn-sm mb-2' hidden={(authUser.branchId == 50) ? false : true} onClick={() => allBranchDengiPohochPavatiReport()} style={{ backgroundColor: "#F16621", color: "#fff", float: 'left', margin: "0px 8px" }}><PrintIcon fontSize="small" /> Print</button>
                                </div>

                                <div className='row mb-1' style={{ float: 'right' }}>
                                    <div>
                                        <input
                                            className='form-control form-control-sm'
                                            type="text"
                                            style={{ width: "250px", display: "block", float: "right", marginBottom: "6px", border: "1px solid #C2C1C1" }}
                                            placeholder="Search Here"
                                            value={searchTerm}
                                            onChange={(event) => { setSelectedMenu('Fee Type'); setSearchTerm(event.target.value); setPage(0); }}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className='table-responsive'>
                                <table className="table table-bordered">
                                    <thead className="table-Default">
                                        <tr>
                                            {TableHeading.map((item, index) => {
                                                return (
                                                    <th style={{ fontSize: "14px", fontWeight: "600" }} onClick={() => onSort(item.key, item.isAction)} key={index} scope="col">{`${item.label} ${sortedBy === item.key ? isAscending ? '↑' : '↓' : ''}`}</th>
                                                )
                                            })}
                                        </tr>
                                    </thead>
                                    <tbody style={{ borderTop: "2px solid #464de4", borderBottom: "2px solid #464de4" }}>
                                        {filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            .map((item, index) => {
                                                return (
                                                    <React.Fragment key={index}>
                                                        <tr>
                                                            <td style={{ fontSize: "14px", fontWeight: "500" }}>{item.id}</td>
                                                            <td style={{ fontSize: "14px", fontWeight: "500" }}>{moment(item.transactionDate).format("DD/MM/YYYY")}</td>
                                                            <td style={{ fontSize: "14px", fontWeight: "500", lineHeight: "1.5" }}>{item.donatorName}<br />{item.donatorAddress}</td>
                                                            <td style={{ fontSize: "14px", fontWeight: "500", lineHeight: "1.5" }}>
                                                                {/* {(() => {
                                                                    if (item.branchId == 50) {
                                                                        return authUser.branchName;
                                                                    }
                                                                })()} */}
                                                                {
                                                                    instituteData.map(e => {
                                                                        if (e.id == item.branchId) {
                                                                            return (authUser.branchMedium == 1) ? e.schoolNameMr : e.schoolNameEn
                                                                        }
                                                                    })
                                                                }
                                                            </td>
                                                            <td style={{ fontSize: "14px", fontWeight: "500", lineHeight: "1.5" }}>{(authUser.branchMedium == 1) ? item.feeNameMr : item.feeNameEn}</td>
                                                            <td style={{ fontSize: "14px", fontWeight: "500", textAlign: "right" }}>{parseFloat(item.receivedAmount).toFixed(2)}</td>

                                                            <td>
                                                                <Tooltip title="Edit">
                                                                    <span className='btn btn-primary btn-sm mx-1 p-1'
                                                                        data-bs-toggle="tooltip" onClick={() => updateData(item.id, 2)}>
                                                                        <FontAwesomeIcon icon={faEdit} />
                                                                    </span>
                                                                </Tooltip>
                                                            </td>
                                                            <td>
                                                                <Tooltip title="print">
                                                                    <span className='btn btn-sm mx-1 p-1' style={{ backgroundColor: "#F1C40F", color: "white" }}
                                                                        data-bs-toggle="tooltip"
                                                                        onClick={() => dengiPavatiReportPrint(item.id)}>
                                                                        <FontAwesomeIcon icon={faPrint} />
                                                                    </span>
                                                                </Tooltip>
                                                            </td>
                                                        </tr>
                                                    </React.Fragment>
                                                )
                                            })}
                                    </tbody>
                                    <tfoot style={{ padding: "0px" }}>
                                        <tr>
                                            <TablePagination
                                                sx={{ padding: "1px" }}
                                                rowsPerPageOptions={[10, 25, 50, 100]}
                                                count={data.length}
                                                rowsPerPage={rowsPerPage}
                                                page={page}
                                                onRowsPerPageChange={onRowsPerPageChange}
                                                onPageChange={onPageChange}
                                                labelRowsPerPage="Rows Per Page :"
                                                classes={{ menuItem: classes.menuItem, displayedRows: classes.displayedRows, selectIcon: classes.selectIcon, toolbar: classes.toolbar, caption: classes.caption, selectLabel: classes.selectLabel, select: classes.select }}
                                                backIconButtonProps={{ "aria-label": "Previous Page" }}
                                                nextIconButtonProps={{ "aria-label": "Next Page" }} />
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                        </div>
                    </div>
            }

            {/* <ToastContainer position="top-right" theme="colored" /> */}
        </>
    )
}

export default DengiPavatiTable