import React, { useState, useEffect } from 'react';
import TitleLabel from "../../CommonComponent/TitleLabel";
import { useSelector } from "react-redux";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import TextField from '@mui/material/TextField';
import { useNavigate } from 'react-router-dom';
import Autocomplete from "@mui/material/Autocomplete";
import Paper from "@mui/material/Paper";
import Loader from '../../CommonComponent/Loader';
import moment from 'moment';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { printFeeReceiptExcelReport, getFeeList } from '../Services/FeeReportAPIURL';
import { getExportedReportAPIURL } from '../../CommonServices/APIURL';
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles(theme => ({
    select: {
        maxHeight: 160,
        "& ul": {
            backgroundColor: "#F4ECF7",
            //maxHeight: 160,
        },
        "& li": {
            // backgroundColor: "#F4ECF7", fontWeight: "550", fontSize: "14px" 
            fontSize: "14px",
            fontWeight: "550"
        },
    },

}));


const FeeReceiptSummaryExcelReport = () => {
    const classes = useStyles();
    const titleId = "Fee Receipt Summary Excel Report";
    const authUser = useSelector((state) => state.user.value);
    const navigate = useNavigate();

    // loader on/off
    const [loaderOption, setLoaderOption] = useState(false);

    const defaultMinDate = new Date(`${authUser.sessionYear}/04/01`);
    const defaultMaxDate = new Date(`${authUser.sessionYear + 1}/05/31`);

    const [dateFrom, setDateFrom] = useState(null);
    const [dateTo, setDateTo] = useState(null);
    const [id, setId] = useState();
    const [dateCheckFrom, setDateCheckFrom] = useState(null);
    const [dateCheckTo, setDateCheckTo] = useState(null);
    const [feeData, setFeeData] = useState([]);
    const [feeId, setFeeId] = useState([]);
    const [feeObj, setFeeObj] = useState(null);

    const defaultFromDate = new Date(authUser.sessionYear, 3, 1);
    const defaultToDate = new Date(parseInt(authUser.sessionYear) + 1, 4, 31);

    useEffect(() => {
        if (authUser.sessionYear != '') {
            setDateFrom(moment(defaultFromDate).format("YYYY-MM-DD"))
            setDateTo(moment(defaultToDate).format("YYYY-MM-DD"))
        }
    }, [authUser])

    useEffect(() => {
        getData();
    }, [authUser])


    const getData = async () => {
        await axios(`${getFeeList}`)
            .then((response) => {
                const feeDataForReceipt = (response.data).filter(e => e.feeType !== 5)
                setFeeData(feeDataForReceipt);

            }).catch((error) => {
                console.log(error);
            })
    }

    const FeeReceiptSummarytExcelPrint = async (e) => {
        e.preventDefault();
        if (moment(dateFrom).format("DD/MM/YYYY") !== "Invalid date" && moment(dateTo).format("DD/MM/YYYY") !== "Invalid date" && dateCheckFrom != "Invalid date" && dateCheckTo != "Invalid date") {
            setLoaderOption(true);
            //console.log(`${printFeeReceiptExcelReport}?acYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&langId=${authUser.branchMedium}&feeId=${feeId}&fromDate=${moment(dateFrom).format("DD/MM/YYYY")}&toDate=${moment(dateTo).format("DD/MM/YYYY")}`)
            await axios.get(`${printFeeReceiptExcelReport}?acYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&langId=${authUser.branchMedium}&feeId=${feeId}&fromDate=${moment(dateFrom).format("DD/MM/YYYY")}&toDate=${moment(dateTo).format("DD/MM/YYYY")}`)
                .then((response) => {

                    if (response.data == "NOTFOUND") {
                        setLoaderOption(false);
                        toast.warn("Receipt summary not found for this fee")
                    }
                    else if (response.data != null) {
                        // console.log(response.data)
                        window.open(`${getExportedReportAPIURL}${response.data}`);
                        setLoaderOption(false);
                    }

                })
                .catch(error => {
                    setLoaderOption(false);
                    toast.error("Error Occoured, Try after sometime.");
                    console.log("Fee Receipt Summary Report failed: " + error)
                })
            //toast.success("Valid")
        }
        else {
            setLoaderOption(false);
            toast.error("Improper date, please check.");
        }

    }

    if (loaderOption == true) {
        return <><Loader /></>
    }
    else {
        return (
            <>
                <TitleLabel titleId={titleId} />

                <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: "350px" }}>
                    {/* Form Data start */}
                    <form onSubmit={FeeReceiptSummarytExcelPrint}>
                        <div className="form-data mt-1">
                            <input type="hidden" value={id} onChange={(e) => setId(e.target.value)} name="" />

                            <div className="row">
                                <label style={{ width: "130px", fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>From Date :</label>
                                <div className='col-sm-3'>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DatePicker
                                            label="From Date"
                                            value={dateFrom}
                                            minDate={defaultMinDate}
                                            maxDate={defaultMaxDate}
                                            inputFormat="dd/MM/yyyy"
                                            onChange={(newValue) => {
                                                setDateFrom(newValue);
                                                //(newValue > defaultMaxDate || newValue < defaultMinDate) ? setDateCheckFrom("Invalid date") : setDateCheckFrom(null)
                                                const maxDate = new Date(defaultMaxDate);
                                                maxDate.setDate(maxDate.getDate() + 1); // Add one day to max date to include the full day
                                                if (newValue < defaultMinDate || newValue >= maxDate) {
                                                    setDateCheckFrom("Invalid date");
                                                } else {
                                                    setDateCheckFrom("");
                                                }
                                            }}
                                            openTo="day"
                                            // Show defaultMaxDate selected when the DatePicker is opened
                                            // If dateFrom is not set, defaultMaxDate is used
                                            onOpen={() => setDateFrom(dateFrom || defaultMaxDate)}
                                            renderInput={(params) =>
                                                <TextField
                                                    {...params}
                                                    margin="dense"
                                                    variant="outlined"
                                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                                    required
                                                    fullWidth
                                                    autoComplete='off'
                                                    size="small" />}
                                        />
                                    </LocalizationProvider>
                                    {/* <span><em style={{ fontSize: "12px", color: "red", fontWeight: "600" }}>{dateCheck}</em></span> */}
                                </div>

                                <label className="col-sm-1" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>To Date :</label>
                                <div className='col-sm-3'>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DatePicker
                                            label="To Date"
                                            value={dateTo}
                                            minDate={defaultMinDate}
                                            maxDate={defaultMaxDate}
                                            inputFormat="dd/MM/yyyy"
                                            onChange={(newValue) => {
                                                setDateTo(newValue);
                                                //(newValue > defaultMaxDate || newValue < defaultMinDate) ? setDateCheckTo("Invalid date") : setDateCheckTo(null)
                                                const maxDate = new Date(defaultMaxDate);
                                                maxDate.setDate(maxDate.getDate() + 1); // Add one day to max date to include the full day
                                                if (newValue < defaultMinDate || newValue >= maxDate) {
                                                    setDateCheckFrom("Invalid date");
                                                } else {
                                                    setDateCheckFrom("");
                                                }
                                            }}
                                            openTo="day"
                                            // Show defaultMaxDate selected when the DatePicker is opened
                                            // If dateFrom is not set, defaultMaxDate is used
                                            onOpen={() => setDateTo(dateTo || defaultMaxDate)}
                                            renderInput={(params) =>
                                                <TextField
                                                    {...params}
                                                    margin="dense"
                                                    variant="outlined"
                                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                                    required
                                                    fullWidth
                                                    autoComplete='off'
                                                    size="small" />}
                                        />
                                    </LocalizationProvider>
                                    {/* <span><em style={{ fontSize: "12px", color: "red", fontWeight: "600" }}>{dateCheck}</em></span> */}
                                </div>
                            </div>

                            <div className="row my-2">
                                <label style={{ width: "130px", fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Fee Name :</label>
                                <div className='col-sm-3'>
                                    <Autocomplete
                                        id="combo-box-demo"
                                        PaperComponent={({ children }) => (
                                            <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                        )}
                                        options={feeData}
                                        style={{ fontSize: '14px' }}
                                        getOptionLabel={option => (authUser.branchMedium == 1) ? option.nameMr : option.nameEn}
                                        size="small"
                                        value={feeObj}
                                        onChange={(event, newvalue) => {
                                            setFeeId(newvalue.id);
                                            setFeeObj(newvalue);
                                        }}

                                        renderInput={params => (
                                            <TextField {...params} margin="dense" label="Select Fee" InputLabelProps={{ style: { fontSize: 14 } }} variant="outlined" required />
                                        )}
                                    />
                                </div>
                            </div>

                            <div className="row mt-3">
                                <div className="col-sm-4" style={{ marginLeft: "130px" }}>
                                    <button type="submit" className="btn btn-primary btn-sm">Show</button>
                                    {/* <button type="button" className="btn btn-danger btn-sm" onClick={() => navigate("/Home/")} style={{ margin: '0px 4px' }}>Exit</button> */}
                                </div>
                            </div>

                        </div>
                    </form>
                </div>
                {/* <ToastContainer position="top-right" theme="colored" /> */}
            </>
        )
    }

};

export default FeeReceiptSummaryExcelReport