import React, { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import { TextField, FormControlLabel, Checkbox, Button, Grid, Paper, Typography, Autocomplete, FormGroup, RadioGroup, Radio, InputLabel, Select, MenuItem, FormControl } from '@mui/material';
import Loader from '../../../CommonComponent/Loader';
import TitleLabel from '../../../CommonComponent/TitleLabel';
import { DatePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import makeStyles from "@mui/styles/makeStyles";
import { BaseURLInstituteMaster, getCityData } from '../../../CommonServices/InstituteMasterAPIURL';
import axios from 'axios';
import { getVotersKendraMasterAPIURL } from '../Services/VotersKendraMasterAPIURL';
import { getMemberCategoryMasterAPIURL, getSansthaMemberMasterAPIURL, getStateMasterAPIURL, profileImgPath, saveSansthaMemberMasterAPIURL, signImgPath, updateSansthaMemberMasterAPIURL } from '../Services/SansthaMemberMasterAPIURL';
import { getOldSchool } from '../../../CommonServices/StudentRegistrationAPIURL';
import { getRegionsInSangliAPIURL } from '../Services/RegionsInsangliCityAPIURL';
import { GetYearAPIURL } from '../../../CommonServices/LoginAPIURL';
import moment from 'moment';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';


const useStyles = makeStyles(theme => ({
  select: {
    maxHeight: 160,
    "& ul": {
      backgroundColor: "#F4ECF7",
      //maxHeight: 160,
    },
    "& li": {
      // backgroundColor: "#F4ECF7", fontWeight: "550", fontSize: "14px" 
      fontSize: "14px",
      fontWeight: "550"
    },
  },

}));

const SansthaMemberMaster = () => {
  const classes = useStyles();

  const authUser = useSelector((state) => state.user.value);
  const titleId = "Member Master";
  const [loaderOption, setLoaderOption] = useState(false);

  const { smId, EditFlag } = useParams();

  const navigate = useNavigate();

  //Note: This below states are declared for Save & Update(Disabled/unDisabled)
  const saveBtnStyle = "sansthaMemberSaveBtn btn btn-primary btn-sm";
  const updateBtnStyle = "sansthaMemberUpdateBtn btn btn-primary btn-sm";
  const displayNoneBtnStyle = "d-none";
  const [saveBtn, setSaveBtn] = useState(saveBtnStyle);
  const [updateBtn, setUpdateBtn] = useState(displayNoneBtnStyle);

  const [saveBtnDisabled, setSaveBtnDisabled] = useState(false);
  const [updateBtnDisabled, setUpdateBtnDisabled] = useState(false);

  useEffect(() => {
    if (EditFlag == 1) {
      getData();
      setSaveBtnDisabled(false);
      setUpdateBtnDisabled(true);
    }
    else if (EditFlag == 2) {
      getEditDetails();
      setSaveBtnDisabled(true);
      setUpdateBtnDisabled(false);
    }
  }, [authUser])


  const [id, setId] = useState();
  const [classeses, setClasseses] = useState(null);
  const [classesesData, setClassesesData] = useState([]);
  const [num, setNum] = useState('');
  const [kendraData, setKendraData] = useState([]);
  const [kendraObj, setKendraObj] = useState(null);
  const [memberName, setMemberName] = useState("");
  const [chkBox1, setChkBox1] = useState(0);
  const [gender, setGender] = useState(1);
  const [address, setAddress] = useState("");
  const [karita, setKarita] = useState("");
  const [areaData, setAreaData] = useState([]);
  const [areaObj, setAreaObj] = useState(null);
  const [cityData, setCityData] = useState([]);
  const [city, setCity] = useState(null);
  const [stateNameData, setStateNameData] = useState([]);
  const [stateName, setStateName] = useState(null);
  const [pinCode, setPinCode] = useState("");
  const [education, setEducation] = useState("");
  const [working, setWorking] = useState("");
  const [phone, setPhone] = useState("");
  const [office, setOffice] = useState("");
  const [mobile, setMobile] = useState("");
  const [schoolData, setSchoolData] = useState([]);
  const [school, setSchool] = useState(null);
  const [schoolYearData, setSchoolYearData] = useState([]);
  const [yearIndex, setYearIndex] = useState("");
  const [schoolYear, setSchoolYear] = useState("");
  const [otherDetails, setOtherDetails] = useState("");
  const [chkBox2, setChkBox2] = useState(0);
  const [chkBox3, setChkBox3] = useState(0);
  const [birthDate, setBirthDate] = useState(null);
  const [firmChkBox, setFirmChkBox] = useState(0);
  const [oldSchoolChkBox, setOldSchoolChkBox] = useState(0);
  const [option, setOption] = useState(0);
  const [fromclasseses, setFromClasseses] = useState(null);
  const [fromNum, setFromNum] = useState('');
  const [fromMemberName, setFromMemberName] = useState("");

  const handleYear = (e) => {
    // year[index]
    // setYearIndex(index);
    setSchoolYear(e.target.value);
  }

  const HandleOptionChkBox = (e) => {
    setOption(e.target.value);
  }

  const HandleOldSchoolChkBox = (e) => {
    setOldSchoolChkBox(e.target.checked ? 1 : 0);
  }

  const HandleFirmChkBox = (e) => {
    setFirmChkBox(e.target.checked ? 1 : 0);
  }

  const HandleChkBox1 = (e) => {
    setChkBox1(e.target.checked ? 1 : 0);
  }

  const HandleChkBox2 = (e) => {
    setChkBox2(e.target.checked ? 1 : 0);
  }

  const HandleChkBox3 = (e) => {
    setChkBox3(e.target.checked ? 1 : 0);
  }

  //Gender management(radio button)
  const handleGender = (event) => {
    setGender(event.target.value);
  };

  useEffect(() => {
    getData();
  }, [authUser])

  const getEditDetails = async () => {
    setLoaderOption(true);
    // console.log(`${getVotersKendraMasterAPIURL}`);
    await axios.get(`${getSansthaMemberMasterAPIURL}`)
      .then((response) => {
        const newData = response.data.find(e => e.id == smId)
        setSaveBtn(displayNoneBtnStyle);
        setUpdateBtn(updateBtnStyle);
        setId(newData.id);
        const selectedClasses = newData.varg;
        setNum(newData.number);
        const selectedVotingKendra = newData.voterKendraId;
        setMemberName(newData.name);
        setChkBox2(newData.isAlive);
        setChkBox3(newData.isContact);
        setGender(newData.gender);
        setAddress(newData.address);
        setFirmChkBox(newData.isFirm);
        setKarita(newData.karita);
        const selectedArea = newData.regionId;
        const selectedCity = newData.cityId;
        const selectedState = newData.stateId;
        setPinCode(newData.pinCode);
        setBirthDate(newData.dob);
        setEducation(newData.education);
        setWorking(newData.occupation);
        setPhone(newData.phone);
        setOffice(newData.office);
        setMobile(newData.mobileNo);
        setOldSchoolChkBox(newData.isAlumni);
        const selectedSchool = newData.oldSchool;
        setSchoolYear(newData.oldSchoolYear);
        setOtherDetails(newData.otherInfo);
        setChkBox1(newData.isServant);
        const selectedFromClasses = newData.vargFrom;
        setFromNum(newData.numberFrom);
        setFromMemberName(newData.nameFrom);
        setOption(newData.howContacted);
        console.log(`${profileImgPath}${newData.photo}`)
        setProfileImage(`${profileImgPath}${newData.photo}`)
        setSignatureImage(`${signImgPath}${newData.signImg}`)


        axios.get(`${getCityData}`)
          .then((response) => {
            //console.log(response.data)
            setCityData(response.data);
            setCity(response.data.find((e => e.id == selectedCity)))

          })

        axios.get(`${getVotersKendraMasterAPIURL}`)
          .then(res => {
            setKendraData(res.data);
            // console.log(JSON.stringify(res.data) + "=====" + selectedVotingKendra);
            setKendraObj(res.data.find(e => e.id == selectedVotingKendra))
          })

        axios.get(`${getMemberCategoryMasterAPIURL}`)
          .then(res => {
            setClassesesData(res.data);
            setClasseses(res.data.find((e => e.id == selectedClasses)))
            setFromClasseses(res.data.find((e => e.id == selectedFromClasses)))
          })

        //  axios.get(`${getOldSchool}`)
        //   .then((response) => {
        //     setSchoolData(response.data)
        //   })

        axios.get(`${BaseURLInstituteMaster}`)
          .then((response) => {
            setSchoolData(response.data)
            setSchool(response.data.find((e => e.id == selectedSchool)))

          })


        axios.get(`${getRegionsInSangliAPIURL}`)
          .then(res => {
            setAreaData(res.data);
            setAreaObj(res.data.find((e => e.id == selectedArea)))

          })

        // console.log(GetYearAPIURL)
        axios.get(GetYearAPIURL)
          .then((response) => {
            setSchoolYearData(response.data);
          })

        axios.get(getStateMasterAPIURL)
          .then((response) => {
            setStateNameData(response.data);
            setStateName(response.data.find((e => e.id == selectedState)))
          })

        setLoaderOption(false);

      }).catch(error => {
        setLoaderOption(false);
        console.log(error);
        toast.error("Something went wrong, please check.")
      })
  }

  const getData = async () => {
    setLoaderOption(true);
    await axios.get(`${getCityData}`)
      .then((response) => {
        //console.log(response.data)
        setCityData(response.data);
      })

    await axios.get(`${getVotersKendraMasterAPIURL}`)
      .then(res => {
        setKendraData(res.data);
      })

    await axios.get(`${getMemberCategoryMasterAPIURL}`)
      .then(res => {
        setClassesesData(res.data);
      })

    // await axios.get(`${getOldSchool}`)
    //   .then((response) => {
    //     setSchoolData(response.data)
    //   })

    await axios.get(`${BaseURLInstituteMaster}`)
      .then((response) => {
        setSchoolData(response.data)
      })


    await axios.get(`${getRegionsInSangliAPIURL}`)
      .then(res => {
        setAreaData(res.data);
      })

    // console.log(GetYearAPIURL)
    await axios.get(GetYearAPIURL)
      .then((response) => {
        setSchoolYearData(response.data);
      })

    await axios.get(getStateMasterAPIURL)
      .then((response) => {
        setStateNameData(response.data);
      })
    setLoaderOption(false);
  }

  const saveBtnClick = async () => {
    setLoaderOption(true);
    const formData = new FormData();

    formData.append("varg", parseInt(classeses.id));
    formData.append("number", num);
    formData.append("voterKendraId", kendraObj.id);
    formData.append("name", memberName);
    formData.append("isAlive", chkBox2);
    formData.append("isContact", chkBox3);
    formData.append("gender", gender);
    formData.append("address", address);
    formData.append("isFirm", firmChkBox);
    formData.append("karita", firmChkBox == 1 ? karita : '');
    formData.append("regionId", areaObj.regionNo);
    formData.append("cityId", city.id);
    formData.append("stateId", stateName.id);
    formData.append("pinCode", pinCode);
    formData.append("dob", moment(birthDate).format("DD/MM/YYYY"));
    formData.append("education", education);
    formData.append("occupation", working);
    formData.append("phone", phone);
    formData.append("office", office);
    formData.append("mobileNo", mobile);
    formData.append("isAlumni", oldSchoolChkBox);
    formData.append("oldSchool", oldSchoolChkBox == 1 ? school.id : 0);
    formData.append("oldSchoolYear", oldSchoolChkBox == 1 ? schoolYear : '');
    formData.append("otherInfo", otherDetails);
    formData.append("isServant", chkBox1);
    formData.append("vargFrom", parseInt(fromclasseses.id));
    formData.append("numberFrom", fromNum);
    formData.append("nameFrom", fromMemberName);
    formData.append("howContacted", option);

    // **Only append images if they are not null**
    if (profileImageFile) {
      formData.append("photo", profileImageFile);
    }
    if (signatureImageFile) {
      formData.append("signImg", signatureImageFile);
    }

    console.log(Object.fromEntries(formData));

    axios.post(`${saveSansthaMemberMasterAPIURL}`, formData)
      .then((response) => {
        if (response.data === "ALREADYEXISTS") {
          setLoaderOption(false);
          toast.warn("Member with this number already exists.");
        } else if (response.data === "SAVED") {
          getData();
          setLoaderOption(false);
          toast.success("Saved Successfully.");
          navigate("/Home/m1401/");
        } else {
          setLoaderOption(false);
          toast.error("Operation failed.");
        }
      })
      .catch((error) => {
        setLoaderOption(false);
        console.log("Save Member master error: " + error);
        toast.error("Something went wrong, please check.");
      });
  };

  const updateBtnClick = async () => {
    setLoaderOption(true);
    const formData = new FormData();
    formData.append("id", smId);
    formData.append("varg", parseInt(classeses.id));
    formData.append("number", num);
    formData.append("voterKendraId", kendraObj.id);
    formData.append("name", memberName);
    formData.append("isAlive", chkBox2);
    formData.append("isContact", chkBox3);
    formData.append("gender", gender);
    formData.append("address", address);
    formData.append("isFirm", firmChkBox);
    formData.append("karita", firmChkBox == 1 ? karita : '');
    formData.append("regionId", areaObj.regionNo);
    formData.append("cityId", city.id);
    formData.append("stateId", stateName.id);
    formData.append("pinCode", pinCode);
    formData.append("dob", moment(birthDate).format("DD/MM/YYYY"));
    formData.append("education", education);
    formData.append("occupation", working);
    formData.append("phone", phone);
    formData.append("office", office);
    formData.append("mobileNo", mobile);
    formData.append("isAlumni", oldSchoolChkBox);
    formData.append("oldSchool", oldSchoolChkBox == 1 ? school.id : 0);
    formData.append("oldSchoolYear", oldSchoolChkBox == 1 ? schoolYear : '');
    formData.append("otherInfo", otherDetails);
    formData.append("isServant", chkBox1);
    formData.append("vargFrom", parseInt(fromclasseses.id));
    formData.append("numberFrom", fromNum);
    formData.append("nameFrom", fromMemberName);
    formData.append("howContacted", option);
    // **Only append images if they are not null**
    if (profileImageFile) {
      formData.append("photo", profileImageFile);
    }
    if (signatureImageFile) {
      formData.append("signImg", signatureImageFile);
    }

    console.log(Object.fromEntries(formData))

    axios.put(`${updateSansthaMemberMasterAPIURL}`, formData)
      .then((response) => {
        if (response.data == "ALREADYEXISTS") {
          setLoaderOption(false);
          toast.warn("Member with this number is alraedy exists.")
        }
        else if (response.data == "UPDATED") {
          getData();
          setLoaderOption(false);
          toast.success("Updated Successfully.")
          navigate("/Home/m1401/")
        }
        else {
          setLoaderOption(false);
          toast.error("Operation failed.");
        }
      }).catch((error) => {
        setLoaderOption(false);
        console.log("Save Member master error: " + error);
        toast.error("Something went wrong, please check.")
      });

  }

  const state = {
    button: 1
  };

  const onSubmit = e => {
    e.preventDefault();
    if (state.button === 1) {
      saveBtnClick();
    }
    if (state.button === 2) {
      updateBtnClick();
    }
  };

  const [profileImage, setProfileImage] = useState(null);
  const [signatureImage, setSignatureImage] = useState(null);
  const [profileImageFile, setProfileImageFile] = useState(null);
  const [signatureImageFile, setSignatureImageFile] = useState(null);

  const handleImageUpload = (event, setImage, setImageFile) => {
    const file = event.target.files[0];
    // console.log(file)
    if (file) {
      setImageFile(file)
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  if (loaderOption) {
    return <Loader />;
  }
  else {
    return (
      <>
        <TitleLabel titleId={titleId} />
        <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: "350px", position: "relative" }}>
          <form onSubmit={onSubmit}>
            <div className="container position-relative">
            
              <div
                className="d-none d-md-block"
                style={{ position: "absolute", top: "3px", right: "3px" }}
              >
                <label
                  className="border rounded d-flex align-items-center justify-content-center"
                  style={{
                    width: "150px",
                    height: "150px",
                    backgroundColor: "#f0f0f0",
                    fontWeight: "bold",
                    overflow: "hidden",
                    cursor: "pointer",
                  }}
                >
                  {profileImage ? (
                    <img
                      src={profileImage}
                      alt="Profile"
                      style={{ width: "100%", height: "100%", objectFit: "cover" }}
                    />
                  ) : (
                    "Upload Photo"
                  )}
                  <input
                    type="file"
                    accept="image/*"
                    onChange={(e) => handleImageUpload(e, setProfileImage, setProfileImageFile)}
                    style={{ display: "none" }}
                  />
                </label>

                <label
                  className="border rounded d-flex align-items-center justify-content-center mt-2"
                  style={{
                    width: "150px",
                    height: "50px",
                    backgroundColor: "#f0f0f0",
                    fontWeight: "bold",
                    overflow: "hidden",
                    cursor: "pointer",
                  }}
                >
                  {signatureImage ? (
                    <img
                      src={signatureImage}
                      alt="Signature"
                      style={{ width: "100%", height: "100%", objectFit: "contain" }}
                    />
                  ) : (
                    "Upload Signature"
                  )}
                  <input
                    type="file"
                    accept="image/*"
                    onChange={(e) => handleImageUpload(e, setSignatureImage, setSignatureImageFile)}
                    style={{ display: "none" }}
                  />
                </label>
              </div>

             
              <div className="d-md-none text-center mt-3">
                <label
                  className="border rounded d-flex align-items-center justify-content-center"
                  style={{
                    width: "150px",
                    height: "150px",
                    backgroundColor: "#f0f0f0",
                    fontWeight: "bold",
                    margin: "auto",
                    overflow: "hidden",
                    cursor: "pointer",
                  }}
                >
                  {profileImage ? (
                    <img
                      src={profileImage}
                      alt="Profile"
                      style={{ width: "100%", height: "100%", objectFit: "cover" }}
                    />
                  ) : (
                    "Upload Photo"
                  )}
                  <input
                    type="file"
                    accept="image/*"
                    onChange={(e) => handleImageUpload(e, setProfileImage, setProfileImageFile)}
                    style={{ display: "none" }}
                  />
                </label>

                <label
                  className="border rounded d-flex align-items-center justify-content-center mt-2"
                  style={{
                    width: "150px",
                    height: "50px",
                    backgroundColor: "#f0f0f0",
                    fontWeight: "bold",
                    margin: "auto",
                    overflow: "hidden",
                    cursor: "pointer",
                  }}
                >
                  {signatureImage ? (
                    <img
                      src={signatureImage}
                      alt="Signature"
                      style={{ width: "100%", height: "100%", objectFit: "contain" }}
                    />
                  ) : (
                    "Upload Signature"
                  )}
                  <input
                    type="file"
                    accept="image/*"
                    onChange={(e) => handleImageUpload(e, setSignatureImage, setSignatureImageFile)}
                    style={{ display: "none" }}
                  />
                </label>
              </div>
            </div>

            <input type="hidden" value={id} onChange={(e) => setId(e.target.value)} name="" />

            <div className="row">
              <label className="col-sm-1" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >वर्ग :</label>
              <div className='col-sm-2'>
                <Autocomplete
                  id="combo-box-demo"
                  PaperComponent={({ children }) => (
                    <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                  )}

                  onKeyPress={(e) => (e.key == "Enter") ? classeses : ""}
                  options={classesesData}
                  value={classeses}
                  onChange={(event, newValue) => {
                    //setNarrationId(newValue.id);
                    //console.log(newValue.narrationDetail);
                    setClasseses(newValue);
                  }}
                  style={{ fontSize: '14px' }}
                  isOptionEqualToValue={(option, value) => option.id === value.id}  // ✅ Fix added here
                  getOptionLabel={option => (authUser.branchMedium == 1) ? option.nameMr : option.nameEn}
                  size="small"
                  renderInput={params => (
                    <TextField {...params}
                      margin='dense' label="वर्ग" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                  )}
                />
              </div>

              <label className="col-sm-1" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >नंबर :</label>
              <div className='col-sm-1'>
                <TextField
                  required
                  onKeyDown={(e) => (e.key === " " && num.length === 0) ? e.preventDefault() : ""}
                  fullWidth
                  margin='dense'
                  value={num}
                  onChange={e => setNum(e.target.value)}
                  onKeyPress={(e) => (e.key == "Enter") ? num : ""}
                  autoComplete='off'
                  size='small'
                  id="outlined-basic"
                  label="नंबर"
                  variant="outlined"
                  InputLabelProps={{ style: { fontSize: 14 } }}
                  inputProps={{ maxLength: 120 }}
                />
              </div>


              <label className="" style={{ width: '100px', fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >मतदान केंद्र :</label>
              <div className='col-sm-4'>
                <Autocomplete
                  id="combo-box-demo"
                  PaperComponent={({ children }) => (
                    <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                  )}

                  onKeyPress={(e) => (e.key == "Enter") ? kendraObj : ""}
                  options={kendraData}
                  value={kendraObj}
                  onChange={(event, newValue) => {
                    //setNarrationId(newValue.id);
                    //console.log(newValue.narrationDetail);
                    setKendraObj(newValue);
                  }}
                  style={{ fontSize: '14px' }}
                  isOptionEqualToValue={(option, value) => option.id === value.id}  // ✅ Fix added here
                  getOptionLabel={option => option.voterKendraNo + " - " + option.voterKendraName}
                  size="small"
                  renderInput={params => (
                    <TextField {...params}
                      margin='dense' label="मतदान केंद्र" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                  )}
                />
              </div>

            </div>

            <div className="row my-2">
              <label className="col-sm-1" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >नाव :</label>
              <div className='col-sm-4'>
                <TextField
                  required
                  onKeyDown={(e) => (e.key === " " && memberName.length === 0) ? e.preventDefault() : ""}
                  fullWidth
                  margin='dense'
                  value={memberName}
                  onChange={e => setMemberName(e.target.value)}
                  onKeyPress={(e) => (e.key == "Enter") ? memberName : ""}
                  autoComplete='off'
                  size='small'
                  id="outlined-basic"
                  label="नाव"
                  variant="outlined"
                  InputLabelProps={{ style: { fontSize: 14 } }}
                  inputProps={{ maxLength: 120 }}
                />
              </div>

              <div className="col-sm-6 mt-2">
                <FormGroup row={true}>
                  {/* <FormControlLabel control={<Checkbox checked={Boolean(chkBox1)} onChange={HandleChkBox1} />} label="पुरुष ?" /> */}
                  <FormControlLabel control={<Checkbox checked={Boolean(chkBox2)} onChange={HandleChkBox2} />} label="हयात आहे ?" />
                  <FormControlLabel control={<Checkbox checked={Boolean(chkBox3)} onChange={HandleChkBox3} />} label="संपर्क आहे ?" />
                </FormGroup>
              </div>
            </div>

            <div className="row my-2">
              <label className="col-sm-1" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >लिंग :</label>
              <div className="col-sm-6">
                <RadioGroup row aria-label="लिंग" name="customized-radios" value={gender} onChange={handleGender} onKeyPress={(e) => (e.key == "Enter") ? { handleGender } : ""}>
                  <FormControlLabel value={1} control={<Radio color='primary' />} label="Male" />
                  <FormControlLabel value={2} control={<Radio color='primary' />} label="Female" />
                </RadioGroup>
              </div>
            </div>

            <div className="row my-2">
              <label className="col-sm-1" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >पत्ता :</label>
              <div className='col-sm-4'>
                <TextField
                  required
                  onKeyDown={(e) => (e.key === " " && address.length === 0) ? e.preventDefault() : ""}
                  fullWidth
                  multiline
                  rows={3}
                  margin='dense'
                  value={address}
                  onChange={e => setAddress(e.target.value)}
                  onKeyPress={(e) => (e.key == "Enter") ? address : ""}
                  autoComplete='off'
                  size='small'
                  id="outlined-basic"
                  label="पत्ता"
                  variant="outlined"
                  InputLabelProps={{ style: { fontSize: 14 } }}
                  inputProps={{ maxLength: 120 }}
                />
              </div>

              <div className="col-sm-1 mt-1">
                <FormControlLabel control={<Checkbox checked={Boolean(firmChkBox)} onChange={HandleFirmChkBox} />} label="Firm?" />
              </div>

              <label hidden={firmChkBox == 0 ? true : false} className="col-sm-1" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >करिता :</label>
              <div className='col-sm-3'>
                <TextField
                  hidden={firmChkBox == 0 ? true : false}
                  required={firmChkBox == 1 ? true : false}
                  onKeyDown={(e) => (e.key === " " && karita.length === 0) ? e.preventDefault() : ""}
                  fullWidth
                  multiline
                  rows={3}
                  margin='dense'
                  value={karita}
                  onChange={e => setKarita(e.target.value)}
                  onKeyPress={(e) => (e.key == "Enter") ? karita : ""}
                  autoComplete='off'
                  size='small'
                  id="outlined-basic"
                  label="करिता"
                  variant="outlined"
                  InputLabelProps={{ style: { fontSize: 14 } }}
                  inputProps={{ maxLength: 120 }}
                />
              </div>
            </div>

            <div className="row my-2">
              <label className="col-sm-1" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >भाग :</label>
              <div className='col-sm-4'>
                <Autocomplete
                  id="combo-box-demo"
                  PaperComponent={({ children }) => (
                    <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                  )}

                  onKeyPress={(e) => (e.key == "Enter") ? areaObj : ""}
                  options={areaData}
                  value={areaObj}
                  onChange={(event, newValue) => {
                    //setNarrationId(newValue.id);
                    //console.log(newValue.narrationDetail);
                    setAreaObj(newValue);
                  }}
                  style={{ fontSize: '14px' }}
                  isOptionEqualToValue={(option, value) => option.id === value.id}  // ✅ Fix added here
                  getOptionLabel={option => option.regionName}
                  size="small"
                  renderInput={params => (
                    <TextField {...params}
                      margin='dense' label="भाग" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                  )}
                />
              </div>

              <label className="col-sm-1" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >गाव :</label>
              <div className='col-sm-4'>
                <Autocomplete
                  id="combo-box-demo"
                  PaperComponent={({ children }) => (
                    <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                  )}

                  onKeyPress={(e) => (e.key == "Enter") ? city : ""}
                  options={cityData}
                  value={city}
                  onChange={(event, newValue) => {
                    //setNarrationId(newValue.id);
                    //console.log(newValue.narrationDetail);
                    setCity(newValue);
                  }}
                  style={{ fontSize: '14px' }}
                  isOptionEqualToValue={(option, value) => option.id === value.id}  // ✅ Fix added here
                  getOptionLabel={option => (authUser.branchMedium == 1) ? option.nameMr : option.nameEn}
                  size="small"
                  renderInput={params => (
                    <TextField {...params}
                      margin='dense' label="गाव" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                  )}
                />
              </div>
            </div>

            <div className="row my-2">
              <label className="col-sm-1" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >राज्य :</label>
              <div className='col-sm-4'>
                <Autocomplete
                  id="combo-box-demo"
                  PaperComponent={({ children }) => (
                    <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                  )}

                  onKeyPress={(e) => (e.key == "Enter") ? stateName : ""}
                  options={stateNameData}
                  value={stateName}
                  onChange={(event, newValue) => {
                    //setNarrationId(newValue.id);
                    //console.log(newValue.narrationDetail);
                    setStateName(newValue);
                  }}
                  style={{ fontSize: '14px' }}
                  isOptionEqualToValue={(option, value) => option.id === value.id}  // ✅ Fix added here
                  getOptionLabel={option => (authUser.branchMedium == 1) ? option.nameMr : option.nameEn}
                  size="small"
                  renderInput={params => (
                    <TextField {...params}
                      margin='dense' label="राज्य" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                  )}
                />
              </div>

              <label className="col-sm-1" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >पिनकोड :</label>
              <div className='col-sm-4'>
                <TextField
                  required
                  onKeyDown={(e) => (e.key === " " && pinCode.length === 0) ? e.preventDefault() : ""}
                  fullWidth
                  margin='dense'
                  value={pinCode}
                  onChange={e => setPinCode(e.target.value)}
                  onKeyPress={(e) => (e.key == "Enter") ? pinCode : ""}
                  autoComplete='off'
                  size='small'
                  id="outlined-basic"
                  label="पिनकोड"
                  variant="outlined"
                  InputLabelProps={{ style: { fontSize: 14 } }}
                  inputProps={{ maxLength: 120 }}
                />
              </div>
            </div>

            <div className="row my-2">
              <label className="col-sm-1" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>जन्मतारिख:</label>
              <div className='col-sm-4'>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label="जन्मतारिख"
                    value={birthDate}
                    inputFormat='dd/MM/yyyy'
                    onChange={(newValue) => {
                      setBirthDate(newValue);
                    }}
                    renderInput={(params) =>
                      <TextField
                        {...params}
                        margin='dense'
                        variant="outlined"
                        InputLabelProps={{ style: { fontSize: 14 } }}
                        required
                        fullWidth
                        autoComplete='off'
                        size="small" />}
                  />
                </LocalizationProvider>
              </div>

              <label className="col-sm-1" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >शिक्षण :</label>
              <div className='col-sm-4'>
                <TextField
                  required
                  onKeyDown={(e) => (e.key === " " && education.length === 0) ? e.preventDefault() : ""}
                  fullWidth
                  margin='dense'
                  value={education}
                  onChange={e => setEducation(e.target.value)}
                  onKeyPress={(e) => (e.key == "Enter") ? education : ""}
                  autoComplete='off'
                  size='small'
                  id="outlined-basic"
                  label="शिक्षण"
                  variant="outlined"
                  InputLabelProps={{ style: { fontSize: 14 } }}
                  inputProps={{ maxLength: 120 }}
                />
              </div>
            </div>

            <div className="row my-2">
              <label className="col-sm-1" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >व्यवसाय :</label>
              <div className='col-sm-4'>
                <TextField
                  required
                  onKeyDown={(e) => (e.key === " " && working.length === 0) ? e.preventDefault() : ""}
                  fullWidth
                  margin='dense'
                  value={working}
                  onChange={e => setWorking(e.target.value)}
                  onKeyPress={(e) => (e.key == "Enter") ? working : ""}
                  autoComplete='off'
                  size='small'
                  id="outlined-basic"
                  label="व्यवसाय"
                  variant="outlined"
                  InputLabelProps={{ style: { fontSize: 14 } }}
                  inputProps={{ maxLength: 120 }}
                />
              </div>

              <label className="col-sm-1" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >फोन :</label>
              <div className='col-sm-4'>
                <TextField
                  required
                  onKeyDown={(e) => (e.key === " " && phone.length === 0) ? e.preventDefault() : ""}
                  fullWidth
                  margin='dense'
                  value={phone}
                  onChange={e => setPhone(e.target.value)}
                  onKeyPress={(e) => (e.key == "Enter") ? phone : ""}
                  autoComplete='off'
                  size='small'
                  id="outlined-basic"
                  label="फोन"
                  variant="outlined"
                  InputLabelProps={{ style: { fontSize: 14 } }}
                  inputProps={{ maxLength: 120 }}
                />
              </div>
            </div>

            <div className="row my-2">
              <label className="col-sm-1" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >ऑफिस :</label>
              <div className='col-sm-4'>
                <TextField
                  required
                  onKeyDown={(e) => (e.key === " " && office.length === 0) ? e.preventDefault() : ""}
                  fullWidth
                  margin='dense'
                  value={office}
                  onChange={e => setOffice(e.target.value)}
                  onKeyPress={(e) => (e.key == "Enter") ? office : ""}
                  autoComplete='off'
                  size='small'
                  id="outlined-basic"
                  label="ऑफिस"
                  variant="outlined"
                  InputLabelProps={{ style: { fontSize: 14 } }}
                  inputProps={{ maxLength: 120 }}
                />
              </div>

              <label className="col-sm-1" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >मोबाईल :</label>
              <div className='col-sm-4'>
                <TextField
                  required
                  onKeyDown={(e) => (e.key === " " && mobile.length === 0) ? e.preventDefault() : ""}
                  fullWidth
                  margin='dense'
                  value={mobile}
                  onChange={e => setMobile(e.target.value)}
                  onKeyPress={(e) => (e.key == "Enter") ? mobile : ""}
                  autoComplete='off'
                  size='small'
                  id="outlined-basic"
                  label="मोबाईल"
                  variant="outlined"
                  InputLabelProps={{ style: { fontSize: 14 } }}
                  inputProps={{ maxLength: 120 }}
                />
              </div>
            </div>

            <div className="row my-2">
              <div className="mt-1" style={{ width: '290px' }}>
                <FormControlLabel control={<Checkbox checked={Boolean(oldSchoolChkBox)} onChange={HandleOldSchoolChkBox} />} label="संस्थेच्या शाळेचा माजी विद्यार्थी आहे" />
              </div>

              <div className='col-sm-5' hidden={oldSchoolChkBox == 0 ? true : false}>
                <Autocomplete
                  id="combo-box-demo"
                  PaperComponent={({ children }) => (
                    <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                  )}

                  onKeyPress={(e) => (e.key == "Enter") ? school : ""}
                  options={schoolData}
                  value={school}
                  onChange={(event, newValue) => {
                    //setNarrationId(newValue.id);
                    //console.log(newValue.narrationDetail);
                    setSchool(newValue);
                  }}
                  style={{ fontSize: '14px' }}
                  isOptionEqualToValue={(option, value) => option.id === value.id}  // ✅ Fix added here
                  getOptionLabel={option => (authUser.branchMedium == 1) ? option.schoolNameMr : option.schoolNameEn}
                  size="small"
                  renderInput={params => (
                    <TextField {...params}
                      margin='dense' label="शाळेचे नाव" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required={oldSchoolChkBox == 1 ? true : false} />
                  )}
                />
              </div>

              <div className='col-sm-2' hidden={oldSchoolChkBox == 0 ? true : false}>
                {/* <Autocomplete
                  id="combo-box-demo"
                  PaperComponent={({ children }) => (
                    <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                  )}

                  onKeyPress={(e) => (e.key == "Enter") ? schoolYear : ""}
                  options={schoolYearData}
                  value={schoolYear}
                  onChange={(event, newValue) => {
                    //setNarrationId(newValue.id);
                    //console.log(newValue.narrationDetail);
                    setSchoolYear(newValue);
                  }}
                  style={{ fontSize: '14px' }}
                  getOptionLabel={option => option.yearLabel}
                  size="small"
                  renderInput={params => (
                    <TextField {...params}
                      margin='dense' label="वर्ष" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required={oldSchoolChkBox == 1 ? true : false} />
                  )}
                /> */}
                <FormControl fullWidth margin='dense'>
                  <InputLabel id="demo-simple-select-label" size="small" style={{ fontSize: "14px" }}>वर्ष</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    required
                    id="demo-simple-select"
                    size='small'
                    value={schoolYear}
                    label="वर्ष"
                    onChange={(event) => { handleYear(event); }}
                    onKeyPress={(e) => (e.key == "Enter") ? schoolYear : ""}
                  >
                    {
                      schoolYearData.map((e, index) => {
                        return (
                          <MenuItem key={index} value={e.yearLabel}>{e.yearLabel}</MenuItem>
                        )
                      })
                    }
                  </Select>
                </FormControl>
              </div>
            </div>

            <div className="row my-2">
              <label className="col-sm-1" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >इतर माहिती :</label>
              <div className='col-sm-7'>
                <TextField
                  required
                  onKeyDown={(e) => (e.key === " " && otherDetails.length === 0) ? e.preventDefault() : ""}
                  fullWidth
                  multiline
                  rows={3}
                  margin='dense'
                  value={otherDetails}
                  onChange={e => setOtherDetails(e.target.value)}
                  onKeyPress={(e) => (e.key == "Enter") ? otherDetails : ""}
                  autoComplete='off'
                  size='small'
                  id="outlined-basic"
                  label="इतर माहिती"
                  variant="outlined"
                  InputLabelProps={{ style: { fontSize: 14 } }}
                  inputProps={{ maxLength: 120 }}
                />
              </div>

              <div className="col-sm-2">
                <FormControlLabel control={<Checkbox checked={Boolean(chkBox1)} onChange={HandleChkBox1} />} label="सेवक आहे ?" />
              </div>
            </div>

            {/* <hr /> */}
            <div className="row mb-2 my-3">
              <div className="">
                <h4 style={{
                  textAlign: "left",
                  borderBottom: "1px solid #5D6D7E",
                  lineHeight: "0.1em",
                  margin: "10px 0 20px",
                  color: "#1430CF"
                }}><span style={{
                  background: "#fff",
                  padding: "0 8px",
                }}>कोणाकडून</span></h4>
              </div>
            </div>

            <div className="row">
              <label className="col-sm-1" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >वर्ग :</label>
              <div className='col-sm-1'>
                <Autocomplete
                  id="combo-box-demo"
                  PaperComponent={({ children }) => (
                    <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                  )}

                  onKeyPress={(e) => (e.key == "Enter") ? fromclasseses : ""}
                  options={classesesData}
                  value={fromclasseses}
                  onChange={(event, newValue) => {
                    //setNarrationId(newValue.id);
                    //console.log(newValue.narrationDetail);
                    setFromClasseses(newValue);
                  }}
                  style={{ fontSize: '14px' }}
                  isOptionEqualToValue={(option, value) => option.id === value.id}  // ✅ Fix added here
                  getOptionLabel={option => (authUser.branchMedium == 1) ? option.nameMr : option.nameEn}
                  size="small"
                  renderInput={params => (
                    <TextField {...params}
                      margin='dense' label="वर्ग" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                  )}
                />
              </div>

              <label className="col-sm-1" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >नंबर :</label>
              <div className='col-sm-1'>
                <TextField
                  required
                  onKeyDown={(e) => (e.key === " " && fromNum.length === 0) ? e.preventDefault() : ""}
                  fullWidth
                  margin='dense'
                  value={fromNum}
                  onChange={e => setFromNum(e.target.value)}
                  onKeyPress={(e) => (e.key == "Enter") ? fromNum : ""}
                  autoComplete='off'
                  size='small'
                  id="outlined-basic"
                  label="नंबर"
                  variant="outlined"
                  InputLabelProps={{ style: { fontSize: 14 } }}
                  inputProps={{ maxLength: 120 }}
                />
              </div>

              <label className="col-sm-1" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >नाव :</label>
              <div className='col-sm-4'>
                <TextField
                  required
                  onKeyDown={(e) => (e.key === " " && fromMemberName.length === 0) ? e.preventDefault() : ""}
                  fullWidth
                  margin='dense'
                  value={fromMemberName}
                  onChange={e => setFromMemberName(e.target.value)}
                  onKeyPress={(e) => (e.key == "Enter") ? fromMemberName : ""}
                  autoComplete='off'
                  size='small'
                  id="outlined-basic"
                  label="नाव"
                  variant="outlined"
                  InputLabelProps={{ style: { fontSize: 14 } }}
                  inputProps={{ maxLength: 120 }}
                />
              </div>

            </div>

            <div className="row my-2">
              <label className="" style={{ width: '150px', fontSize: "14px", fontWeight: "600", marginTop: "10px" }}>संपर्क कसा केला :</label>
              {/* <div className='col-sm-3'>
                <FormControl fullWidth margin='dense'>
                  <InputLabel id='demo-simple-select-label' size="small" style={{ fontSize: '14px' }}>संपर्क कसा केला</InputLabel>
                  <Select
                    MenuProps={{ classes: { paper: classes.select } }}
                    size='small'
                    required
                    fullWidth
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={option}
                    label="संपर्क कसा केला"
                    onChange={(e) => {
                      setOption(e.target.value);
                    }}
                  >
                    <MenuItem value={1}>ई मेल द्वारे</MenuItem>
                    <MenuItem value={2}>पत्रा द्वारे</MenuItem>
                    <MenuItem value={2}>समक्ष</MenuItem>

                  </Select>
                </FormControl>

              </div> */}
              <div className="col-sm-8">
                <RadioGroup row aria-label="संपर्क कसा केला" name="customized-radios" value={option} onChange={HandleOptionChkBox} onKeyPress={(e) => (e.key == "Enter") ? { handleGender } : ""}>
                  <FormControlLabel value={1} control={<Radio color='primary' />} label="ई मेल द्वारे" />
                  <FormControlLabel value={2} control={<Radio color='primary' />} label="पत्रा द्वारे" />
                  <FormControlLabel value={3} control={<Radio color='primary' />} label="समक्ष" />
                </RadioGroup>
              </div>
            </div>


            <div className="row mt-3">
              <div className="col-sm-12">
                <button type="submit" disabled={saveBtnDisabled} onClick={() => (state.button = 1)} className={saveBtn} >Save</button>
                <button type="submit" disabled={updateBtnDisabled} onClick={() => (state.button = 2)} className={updateBtn} >Update</button>
                <button type="button" onClick={() => navigate("/Home/m1401/")} className="btn btn-danger btn-sm" style={{ margin: '0px 4px' }} >Cancel</button>
              </div>
            </div>
          </form>
        </div>
      </>
    );
  }

};

export default SansthaMemberMaster;