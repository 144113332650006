import React, { useState, useEffect } from 'react';
import TitleLabel from "../../CommonComponent/TitleLabel";
import axios from 'axios';
import Autocomplete from "@mui/material/Autocomplete";
import TextField from '@mui/material/TextField';
import { useSelector } from "react-redux";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import Loader from "../../CommonComponent/Loader";
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { BaseIP } from '../../CommonServices/APIURL';
import Paper from "@mui/material/Paper";
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import { getClassData } from '../../CommonServices/ClassMasterAPIURL'
import { generateLCReportManuallyAPIURL, getPrintLcAPIURL } from '../Services/LcPrintAPIURL';


const ManualLeavingCertificateForm = () => {
    const [titleId, setTitleId] = useState("Manual Leaving Certificate");
    const authUser = useSelector((state) => state.user.value);
    const navigate = useNavigate();

    // loader on/off
    const [loaderOption, setLoaderOption] = useState(false);

    //Master Data
    const LanguageData = [{ id: 1, name: "Marathi" }, { id: 2, name: "English" }]
    const [classData, setClassData] = useState([])


    //Form Data
    const [languageObj, setLanguageObj] = useState(null)
    const [fname, setFname] = useState("")
    const [mname, setMname] = useState("")
    const [lname, setLname] = useState("")
    const [lcNo, setLcNo] = useState("");
    const [lcDate, setLcDate] = useState(null);
    const [reasonOfLeaving, setReasonOfLeaving] = useState("");
    const [remark, setRemark] = useState("");

    const [regNo, setRegNo] = useState("");
    const [progress, setProgress] = useState("");
    const [conduct, setCconduct] = useState("");

    const [sinceWhenDate, setSinceWhenDate] = useState(null);
    const [schoolLeftDate, setSchoolLeftDate] = useState(null);
    const [lcReciveDate, setLcReciveDate] = useState(null);

    const [aadharNo, setAadharNo] = useState("");
    const [saralId, setSaralId] = useState("");

    const [birthDate, setBirthDate] = useState(null);
    const [admDate, setAdmDate] = useState(null);

    const [admClass, setAdmClass] = useState("")
    const [oldSchool, setOldSchool] = useState("")
    const [oldSchoolStd, setOldSchoolStd] = useState("")

    const [motherName, setMotherName] = useState("")

    const [nationality, setNationality] = useState("")
    const [motherTongue, setMotherTongue] = useState("")
    const [religion, setReligion] = useState("")
    const [caste, setCaste] = useState("")
    const [subCaste, setSubCaste] = useState("")

    const [currentClassObj, setCurrentClassObj] = useState(null)

    const [birthPlaceCity, setBirthPlaceCity] = useState("")
    const [birthPlaceTaluka, setbirthPlaceTaluka] = useState("")
    const [birthPlaceDistrict, setbirthPlaceDistrict] = useState("")
    const [birthPlaceState, setbirthPlaceState] = useState("")
    const [birthPlaceCountry, setbirthPlaceCountry] = useState("")

    const [waterMark, setWaterMark] = useState(0);

    const [sessionYear, setSessionYear] = useState(null);

    const [nationalCode, setNationalCode] = useState(null);


    const HandleWaterMarkCheckbox = (e) => {
        setWaterMark(e.target.checked);
        if (e.target.checked == true) {
            setWaterMark(1);
        }
        else {
            setWaterMark(0);
        }
    }

    const getClassDataFunction = async () => {
        await axios.get(`${getClassData}?sid=${authUser.deptId}&bid=${authUser.branchId}`)
            .then((res) => {
                if (res.data !== null) {
                    setClassData(res.data)
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    useEffect(() => {
        if (authUser.deptId !== null && authUser.branchId !== null) {
            getClassDataFunction()
        }
    }, [authUser])

    // console.log(moment(schoolLeftDate).format("YYYY-MM-DD"))

    const saveFun = async (e) => {
        e.preventDefault();
        const saveObj = {
            "langId": languageObj.id,
            "isWatermark": waterMark,
            "lcNo": lcNo,
            "lcDate": moment(lcDate).format("YYYY-MM-DD"),
            "classId": currentClassObj.id,
            "firstName": fname,
            "middleName": mname,
            "lastName": lname,
            "motherName": motherName,
            "regNo": regNo,
            "progress": progress,
            "conduct": conduct,
            "reasonOfLeaving": reasonOfLeaving,
            "remark": remark,
            "sinceWhen": moment(sinceWhenDate).format("DD/MM/YYYY"),
            "schoolLeftDate": moment(schoolLeftDate).format("YYYY-MM-DD"),
            "lcReceiveDate": moment(lcReciveDate).format("YYYY-MM-DD"),
            "secessionYear": moment(sessionYear).format("YYYY"),
            "sectionId": authUser.deptId,
            "branchId": authUser.branchId,
            "aadharNo": aadharNo,
            "saralId": saralId,
            "birthDate": moment(birthDate).format("YYYY-MM-DD"),
            "admissionDate": moment(admDate).format("YYYY-MM-DD"),
            "admissionClass": admClass,
            "oldSchool": oldSchool,
            "oldSchoolStd": oldSchoolStd,
            "nationality": nationality,
            "motherTongue": motherTongue,
            "religion": religion,
            "caste": caste,
            "subCaste": subCaste,
            "birthPlaceCity": birthPlaceCity,
            "birthPlaceTaluka": birthPlaceTaluka,
            "birthPlaceDistrict": birthPlaceDistrict,
            "birthPlaceState": birthPlaceState,
            "birthPlaceCountry": birthPlaceCountry,
            "stdPenNo": (nationalCode == null) ? 0 : nationalCode
        }
        // console.log(JSON.stringify(saveObj))
        await axios.post(generateLCReportManuallyAPIURL, saveObj)
            .then((res) => {
                if (res.data !== null) {
                    window.open(`${getPrintLcAPIURL}${res.data}`)
                    toast.success("Sucessfully LC Printed")
                }
            })
            .catch((err) => {
                toast.error("Error occured")
                console.log(err)
            })
    }


    if (loaderOption == true) {
        return <Loader />
    }
    else {
        return (
            <>
                <TitleLabel titleId={titleId} />
                <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px" }}>
                    <form onSubmit={saveFun}>

                        <div className="row">
                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Language:</label>
                            <div className='col-sm-3'>
                                <Autocomplete
                                    id="combo-box-demo"
                                    PaperComponent={({ children }) => (
                                        <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                    )}
                                    options={LanguageData}
                                    onChange={(event, newValue) => {
                                        setLanguageObj(newValue)
                                    }}
                                    value={languageObj}
                                    style={{ fontSize: '14px' }}
                                    getOptionLabel={option => option.name}
                                    size="small"
                                    renderInput={params => (
                                        <TextField {...params} margin='dense' label="Select Language" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                    )}
                                />
                            </div>

                            <div className='col-sm-2 mt-1 '>
                                <FormControlLabel control={<Checkbox checked={waterMark} onChange={HandleWaterMarkCheckbox} />} label="Watermark" />
                            </div>

                        </div>
                        <div className="row my-3">
                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Reg No:</label>
                            <div className='col-sm-3'>
                                <TextField
                                    required
                                    fullWidth
                                    type="number"
                                    autoComplete='off'
                                    size='small'
                                    id="outlined-basic"
                                    label="Reg No"
                                    value={regNo}
                                    onChange={(e) => setRegNo(e.target.value)}
                                    variant="outlined"
                                    margin='dense'
                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                />
                            </div>
                            <label className="col-sm-1" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >LC No:</label>
                            <div className='col-sm-2'>
                                <TextField
                                    required
                                    fullWidth
                                    type="number"
                                    autoComplete='off'
                                    size='small'
                                    id="outlined-basic"
                                    label="LC No"
                                    value={lcNo}
                                    onChange={(e) => setLcNo(e.target.value)}
                                    variant="outlined"
                                    margin='dense'
                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                />
                            </div>

                            <label className="col-sm-1" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >LC Date:</label>
                            <div className='col-sm-2'>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        label="LC Date"
                                        value={lcDate}
                                        onChange={(newValue) => {
                                            setLcDate(newValue);
                                        }}
                                        inputFormat="dd/MM/yyyy"
                                        renderInput={(params) =>
                                            <TextField
                                                {...params}
                                                margin='dense'
                                                variant="outlined"
                                                InputLabelProps={{ style: { fontSize: 14 } }}
                                                required
                                                fullWidth
                                                autoComplete='off'
                                                size="small" />}
                                    />
                                </LocalizationProvider>
                            </div>
                        </div>

                        <div className="row my-3">
                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Student Name:</label>
                            <div className='col-sm-3'>
                                <TextField
                                    required
                                    fullWidth
                                    autoComplete='off'
                                    size='small'
                                    id="outlined-basic"
                                    label="First Name"
                                    value={fname}
                                    onChange={(e) => setFname(e.target.value)}
                                    variant="outlined"
                                    margin='dense'
                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                />
                            </div>
                            <div className='col-sm-3'>
                                <TextField
                                    required
                                    fullWidth
                                    autoComplete='off'
                                    size='small'
                                    id="outlined-basic"
                                    value={mname}
                                    onChange={(e) => setMname(e.target.value)}
                                    label="Middle Name"
                                    variant="outlined"
                                    margin='dense'
                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                />
                            </div>
                            <div className='col-sm-3'>
                                <TextField
                                    required
                                    fullWidth
                                    autoComplete='off'
                                    size='small'
                                    id="outlined-basic"
                                    value={lname}
                                    onChange={(e) => setLname(e.target.value)}
                                    label="Last Name"
                                    variant="outlined"
                                    margin='dense'
                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                />
                            </div>
                        </div>


                        <div className="row my-3">
                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Reason of Leaving:</label>
                            <div className='col-sm-10'>
                                <TextField
                                    required
                                    fullWidth
                                    autoComplete='off'
                                    size='small'
                                    id="outlined-basic"
                                    label="Reason of Leaving"
                                    value={reasonOfLeaving}
                                    onChange={(e) => setReasonOfLeaving(e.target.value)}
                                    variant="outlined"
                                    margin='dense'
                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                />
                            </div>
                        </div>

                        <div className="row my-3">
                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Remark:</label>
                            <div className='col-sm-10'>
                                <TextField
                                    required
                                    fullWidth
                                    autoComplete='off'
                                    size='small'
                                    id="outlined-basic"
                                    label="Remark"
                                    value={remark}
                                    onChange={(e) => setRemark(e.target.value)}
                                    variant="outlined"
                                    margin='dense'
                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                />
                            </div>
                        </div>

                        <div className="row my-3">
                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Since When Date:</label>
                            <div className='col-sm-2'>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        label="Since When"
                                        value={sinceWhenDate}
                                        onChange={(newValue) => {
                                            setSinceWhenDate(newValue);
                                        }}
                                        inputFormat="dd/MM/yyyy"
                                        renderInput={(params) =>
                                            <TextField
                                                {...params}
                                                margin='dense'
                                                variant="outlined"
                                                InputLabelProps={{ style: { fontSize: 14 } }}
                                                required
                                                fullWidth
                                                autoComplete='off'
                                                size="small" />}
                                    />
                                </LocalizationProvider>
                            </div>

                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >School Left Date:</label>
                            <div className='col-sm-2'>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        label="Left Date"
                                        value={schoolLeftDate}
                                        onChange={(newValue) => {
                                            setSchoolLeftDate(newValue);
                                        }}
                                        inputFormat="dd/MM/yyyy"
                                        renderInput={(params) =>
                                            <TextField
                                                {...params}
                                                margin='dense'
                                                variant="outlined"
                                                InputLabelProps={{ style: { fontSize: 14 } }}
                                                required
                                                fullWidth
                                                autoComplete='off'
                                                size="small" />}
                                    />
                                </LocalizationProvider>
                            </div>

                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >LC Receive Date:</label>
                            <div className='col-sm-2'>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        label="LC Receive Date"
                                        value={lcReciveDate}
                                        onChange={(newValue) => {
                                            setLcReciveDate(newValue);
                                        }}
                                        inputFormat="dd/MM/yyyy"
                                        renderInput={(params) =>
                                            <TextField
                                                {...params}
                                                margin='dense'
                                                variant="outlined"
                                                InputLabelProps={{ style: { fontSize: 14 } }}
                                                required
                                                fullWidth
                                                autoComplete='off'
                                                size="small" />}
                                    />
                                </LocalizationProvider>
                            </div>
                        </div>

                        <div className="row my-3">
                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Birth Date:</label>
                            <div className='col-sm-2'>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        label="Birth Date"
                                        value={birthDate}
                                        onChange={(newValue) => {
                                            setBirthDate(newValue);
                                        }}
                                        inputFormat="dd/MM/yyyy"
                                        renderInput={(params) =>
                                            <TextField
                                                {...params}
                                                margin='dense'
                                                variant="outlined"
                                                InputLabelProps={{ style: { fontSize: 14 } }}
                                                required
                                                fullWidth
                                                autoComplete='off'
                                                size="small" />}
                                    />
                                </LocalizationProvider>
                            </div>

                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Admission Date:</label>
                            <div className='col-sm-2'>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        label="Adm Date"
                                        value={admDate}
                                        onChange={(newValue) => {
                                            setAdmDate(newValue);
                                        }}
                                        inputFormat="dd/MM/yyyy"
                                        renderInput={(params) =>
                                            <TextField
                                                {...params}
                                                margin='dense'
                                                variant="outlined"
                                                InputLabelProps={{ style: { fontSize: 14 } }}
                                                required
                                                fullWidth
                                                autoComplete='off'
                                                size="small" />}
                                    />
                                </LocalizationProvider>
                            </div>

                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Year :</label>
                            <div className='col-sm-2'>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        label="Year"
                                        value={sessionYear}
                                        onChange={(newValue) => {
                                            setSessionYear(newValue);
                                        }}
                                        inputFormat="yyyy"
                                        views={['year']}
                                        renderInput={(params) =>
                                            <TextField
                                                {...params}
                                                margin='dense'
                                                variant="outlined"
                                                InputLabelProps={{ style: { fontSize: 14 } }}
                                                required
                                                fullWidth
                                                autoComplete='off'
                                                size="small" />}
                                    />
                                </LocalizationProvider>
                            </div>
                        </div>

                        <div className="row my-3">
                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Aadhar No:</label>
                            <div className='col-sm-3'>
                                <TextField
                                    required
                                    fullWidth
                                    type="number"
                                    autoComplete='off'
                                    size='small'
                                    id="outlined-basic"
                                    label="Aadhar No"
                                    value={aadharNo}
                                    onChange={(e) => setAadharNo(e.target.value)}
                                    variant="outlined"
                                    margin='dense'
                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                />
                            </div>

                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Saral Id:</label>
                            <div className='col-sm-3'>
                                <TextField
                                    required
                                    fullWidth
                                    autoComplete='off'
                                    size='small'
                                    id="outlined-basic"
                                    label="Saral Id"
                                    value={saralId}
                                    onChange={(e) => setSaralId(e.target.value)}
                                    variant="outlined"
                                    margin='dense'
                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                />
                            </div>
                        </div>

                        <div className="row my-3">
                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Admission Class:</label>
                            <div className='col-sm-3'>
                                <TextField
                                    required
                                    fullWidth
                                    autoComplete='off'
                                    size='small'
                                    id="outlined-basic"
                                    label="Adm Class"
                                    value={admClass}
                                    onChange={(e) => setAdmClass(e.target.value)}
                                    variant="outlined"
                                    margin='dense'
                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                />
                            </div>

                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Old School:</label>
                            <div className='col-sm-5'>
                                <TextField
                                    required
                                    fullWidth
                                    autoComplete='off'
                                    size='small'
                                    id="outlined-basic"
                                    label="Old School Name"
                                    value={oldSchool}
                                    onChange={(e) => setOldSchool(e.target.value)}
                                    variant="outlined"
                                    margin='dense'
                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                />
                            </div>
                        </div>

                        <div className="row my-3">
                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >old School Std:</label>
                            <div className='col-sm-3'>
                                <TextField
                                    required
                                    fullWidth
                                    autoComplete='off'
                                    size='small'
                                    id="outlined-basic"
                                    label="Old School Std"
                                    value={oldSchoolStd}
                                    onChange={(e) => setOldSchoolStd(e.target.value)}
                                    variant="outlined"
                                    margin='dense'
                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                />
                            </div>

                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Mother Name:</label>
                            <div className='col-sm-3'>
                                <TextField
                                    required
                                    fullWidth
                                    autoComplete='off'
                                    size='small'
                                    id="outlined-basic"
                                    label="Mother Name"
                                    value={motherName}
                                    onChange={(e) => setMotherName(e.target.value)}
                                    variant="outlined"
                                    margin='dense'
                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                />
                            </div>
                        </div>

                        <div className="row my-3">
                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Nationality:</label>
                            <div className='col-sm-3'>
                                <TextField
                                    required
                                    fullWidth
                                    autoComplete='off'
                                    size='small'
                                    id="outlined-basic"
                                    label="Nationality"
                                    value={nationality}
                                    onChange={(e) => setNationality(e.target.value)}
                                    variant="outlined"
                                    margin='dense'
                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                />
                            </div>

                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Mother Tongue:</label>
                            <div className='col-sm-3'>
                                <TextField
                                    required
                                    fullWidth
                                    autoComplete='off'
                                    size='small'
                                    id="outlined-basic"
                                    label="Mother Tongue"
                                    value={motherTongue}
                                    onChange={(e) => setMotherTongue(e.target.value)}
                                    variant="outlined"
                                    margin='dense'
                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                />
                            </div>
                        </div>

                        <div className="row my-3">
                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Religion:</label>
                            <div className='col-sm-3'>
                                <TextField
                                    required
                                    fullWidth
                                    autoComplete='off'
                                    size='small'
                                    id="outlined-basic"
                                    label="Religion"
                                    value={religion}
                                    onChange={(e) => setReligion(e.target.value)}
                                    variant="outlined"
                                    margin='dense'
                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                />
                            </div>

                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Caste:</label>
                            <div className='col-sm-3'>
                                <TextField
                                    required
                                    fullWidth
                                    autoComplete='off'
                                    size='small'
                                    id="outlined-basic"
                                    label="Caste"
                                    value={caste}
                                    onChange={(e) => setCaste(e.target.value)}
                                    variant="outlined"
                                    margin='dense'
                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                />
                            </div>
                        </div>

                        <div className="row my-3">
                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Sub Caste:</label>
                            <div className='col-sm-3'>
                                <TextField
                                    required
                                    fullWidth
                                    autoComplete='off'
                                    size='small'
                                    id="outlined-basic"
                                    label="Sub Caste"
                                    value={subCaste}
                                    onChange={(e) => setSubCaste(e.target.value)}
                                    variant="outlined"
                                    margin='dense'
                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                />
                            </div>

                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Current Class:</label>
                            <div className='col-sm-3'>
                                <Autocomplete
                                    id="combo-box-demo"
                                    PaperComponent={({ children }) => (
                                        <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                    )}
                                    options={classData}
                                    onChange={(event, newValue) => {
                                        setCurrentClassObj(newValue)
                                    }}
                                    value={currentClassObj}
                                    style={{ fontSize: '14px' }}
                                    getOptionLabel={option => (authUser.branchMedium === 1) ? option.nameMr : option.nameEn}
                                    size="small"
                                    renderInput={params => (
                                        <TextField {...params} margin='dense' label="Select Current Class" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                    )}
                                />
                            </div>

                        </div>

                        <div className="row my-3">
                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Progress:</label>
                            <div className='col-sm-3'>
                                <TextField
                                    required
                                    fullWidth
                                    autoComplete='off'
                                    size='small'
                                    id="outlined-basic"
                                    label="Progress"
                                    value={progress}
                                    onChange={(e) => setProgress(e.target.value)}
                                    variant="outlined"
                                    margin='dense'
                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                />
                            </div>

                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Conduct:</label>
                            <div className='col-sm-3'>
                                <TextField
                                    required
                                    fullWidth
                                    autoComplete='off'
                                    size='small'
                                    id="outlined-basic"
                                    label="Conduct"
                                    value={conduct}
                                    onChange={(e) => setCconduct(e.target.value)}
                                    variant="outlined"
                                    margin='dense'
                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                />
                            </div>
                        </div>

                        <div className="row my-3">
                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Birth City:</label>
                            <div className='col-sm-3'>
                                <TextField
                                    required
                                    fullWidth
                                    autoComplete='off'
                                    size='small'
                                    id="outlined-basic"
                                    label="Birth City"
                                    value={birthPlaceCity}
                                    onChange={(e) => setBirthPlaceCity(e.target.value)}
                                    variant="outlined"
                                    margin='dense'
                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                />
                            </div>

                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Birth Taluka:</label>
                            <div className='col-sm-3'>
                                <TextField
                                    required
                                    fullWidth
                                    autoComplete='off'
                                    size='small'
                                    id="outlined-basic"
                                    label="Birth Taluka"
                                    value={birthPlaceTaluka}
                                    onChange={(e) => setbirthPlaceTaluka(e.target.value)}
                                    variant="outlined"
                                    margin='dense'
                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                />
                            </div>
                        </div>

                        <div className="row my-3">
                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Birth District:</label>
                            <div className='col-sm-3'>
                                <TextField
                                    required
                                    fullWidth
                                    autoComplete='off'
                                    size='small'
                                    id="outlined-basic"
                                    label="Birth District"
                                    value={birthPlaceDistrict}
                                    onChange={(e) => setbirthPlaceDistrict(e.target.value)}
                                    variant="outlined"
                                    margin='dense'
                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                />
                            </div>

                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Birth State:</label>
                            <div className='col-sm-3'>
                                <TextField
                                    required
                                    fullWidth
                                    autoComplete='off'
                                    size='small'
                                    id="outlined-basic"
                                    label="Birth State"
                                    value={birthPlaceState}
                                    onChange={(e) => setbirthPlaceState(e.target.value)}
                                    variant="outlined"
                                    margin='dense'
                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                />
                            </div>
                        </div>
                        <div className="row my-3">
                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Birth Country:</label>
                            <div className='col-sm-3'>
                                <TextField
                                    required
                                    fullWidth
                                    autoComplete='off'
                                    size='small'
                                    id="outlined-basic"
                                    label="Birth Country"
                                    value={birthPlaceCountry}
                                    onChange={(e) => setbirthPlaceCountry(e.target.value)}
                                    variant="outlined"
                                    margin='dense'
                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                />
                            </div>

                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >National Code:</label>
                            <div className='col-sm-3'>
                                <TextField
                                    required
                                    fullWidth
                                    autoComplete='off'
                                    size='small'
                                    id="outlined-basic"
                                    label="National Code"
                                    value={nationalCode}
                                    onChange={(e) => setNationalCode(e.target.value)}
                                    type='number'
                                    variant="outlined"
                                    margin='dense'
                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                />
                            </div>
                        </div>

                        <div className="row my-3">
                            <div className='col-sm-6 offset-sm-2'>
                                <button className='btn btn-primary btn-sm'>Print LC</button>
                                <button type="button" onClick={() => navigate("/Home/ad207/")} className="btn btn-danger btn-sm" style={{ margin: '0px 4px' }} >Cancel</button>
                            </div>
                        </div>


                    </form>
                </div>
                <ToastContainer position="top-right" theme="colored" />

            </>
        )
    }


}

export default ManualLeavingCertificateForm