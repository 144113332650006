import { BaseURL } from "../../CommonServices/APIURL";

//http://192.168.195.102:8081/institute-sss/institute/sss/inwardOutward/getMaxInwardNo?year=2024&sid=3&bid=1
export const getInwardEntryNoAPIURL = BaseURL + "inwardOutward/getMaxInwardNo";

// /Post
// http://192.168.29.102:8081/institute-sss/institute/sss/inwardOutward/saveInwardEntry

// {
//     "inwardNo": 2024311,
//     "entryDate": "2025-02-05",
//     "letterDate": "2025-02-05",
//     "option": 1,
//     "branchName": "branch Name",
//     "replyToLetter": 1,
//     "replyToOutwardNo": 20240432,
//     "replyToOutwardDate": "2025-02-05",
//     "contentOfInward": "content Of Inward",
//     "letterReceivedDate": "2025-02-05",
//     "detailsOfReply": 1,
//     "replyOutwardNo": 20240433,
//     "replyOutwardDate": "2025-02-05",
//     "remark": "remark",
//     "sessionYear": 2024,
//     "sectionId": 3,
//     "branchId": 1
// }
export const saveInwardEntryAPIURL = BaseURL + "inwardOutward/saveInwardEntry";

// /updateInwardEntry
export const updateInwardEntryAPIURL = BaseURL + "inwardOutward/updateInwardEntry";

// /http://192.168.29.102:8081/institute-sss/institute/sss/inwardOutward/getInwardEntryList?year=2024&sid=3&bid=1
export const getInwardEntryAPIURL = BaseURL + "inwardOutward/getInwardEntryList";


//deleteInwardEntry
export const deleteInwardEntryAPIURL = BaseURL + "inwardOutward/deleteInwardEntry";



//Report
// http://192.168.195.102:8081/institute-sss/institute/sss/inwardOutward/reports/getInwardEntryReport?langId=2&year=2024&sid=3&bid=1&fromDate=2024-04-01&toDate=2025-03-31
export const printInwardEntryPdfReportAPIURL = BaseURL + "inwardOutward/reports/getInwardEntryReport";

//http://192.168.195.102:8081/institute-sss/institute/sss/inwardOutward/reports/getInwardEntryReportExcel?langId=1&year=2024&sid=3&bid=1&fromDate=2024-04-01&toDate=2025-03-31
export const printInwardEntryExcelReportAPIURL = BaseURL + "inwardOutward/reports/getInwardEntryReportExcel";



