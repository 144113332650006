import React, { useState, useEffect } from 'react';
import TitleLabel from "../../CommonComponent/TitleLabel";
import Autocomplete from "@mui/material/Autocomplete";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import TextField from '@mui/material/TextField';
import { useSelector } from "react-redux";
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import moment from 'moment';
import Loader from '../../CommonComponent/Loader';
import Paper from "@mui/material/Paper";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import makeStyles from "@mui/styles/makeStyles";
import { getClassBySectionBranch } from '../../FeeManagement/Services/FeeReportAPIURL';
import { getClassWithDivData } from '../../Academic/Services/LessonPlanAPIURL';
import { useNavigate, useParams } from 'react-router-dom';
import { getSubjectData } from '../../Academic/Services/LessonPlanAPIURL';
import { getHomeWorkMobileAPIURL, saveHomeWorkMobileAPIURL, updateHomeWorkMobileAPIURL } from '../Services/MobileAppAllAPI';

const useStyles = makeStyles({
    select: {
        maxHeight: 200,
        "& ul": {
            backgroundColor: "#F4ECF7",
            //maxHeight: 160,
        },
        "& li": {
            // backgroundColor: "#F4ECF7", fontWeight: "550", fontSize: "14px" 
            fontSize: "14px",
            fontWeight: "550"
        },
    },
});



const HomeWork = () => {

    const authUser = useSelector((state) => state.user.value);
    const [loaderOption, setLoaderOption] = useState(false);
    const classes = useStyles();
    const { hId, EditFlag } = useParams();
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);

    const [titleId, setTitleId] = useState('');

    //Note: This below states are declared for Save & Update(Disabled/unDisabled)
    const saveBtnStyle = "mobileHomeWorkSaveBtn btn btn-primary btn-sm";
    const updateBtnStyle = "mobileHomeWorkUpdateBtn btn btn-primary btn-sm";
    const displayNoneBtnStyle = "d-none";
    const [saveBtn, setSaveBtn] = useState(saveBtnStyle);
    const [updateBtn, setUpdateBtn] = useState(displayNoneBtnStyle);

    const [saveBtnDisabled, setSaveBtnDisabled] = useState(false);
    const [updateBtnDisabled, setUpdateBtnDisabled] = useState(true);

    const [id, setId] = useState('');
    const [subjectData, setSubjectData] = useState([]);
    const [subjectObj, setSubjectObj] = useState(null);
    const [homeWorkTitle, setHomeWorkTitle] = useState('');
    const [dateValue, setDateValue] = useState(null);
    const [classData, setClassData] = useState([]);
    const [classObj, setClassObj] = useState(null);
    const [desce, setDesce] = useState("");

    useEffect(() => {
        if (EditFlag == 1) {
            setTitleId("Home Work");
        }
        else if (EditFlag == 2) {
            setTitleId("Update Home Work Details");
        }
        getData();
    }, [authUser])

    const getData = () => {
        if (EditFlag == 1) {
            getApiData();
            setSaveBtnDisabled(false);
            setUpdateBtnDisabled(true);
        }
        else if (EditFlag == 2) {
            getEditDetails();
            setSaveBtnDisabled(true);
            setUpdateBtnDisabled(false);
        }
    }

    const getEditDetails = async () => {
        setLoaderOption(true);
        await axios.get(`${getHomeWorkMobileAPIURL}?bid=${authUser.branchId}`)
            .then(res => {
                res.data.map(e => {
                    setSaveBtn(displayNoneBtnStyle)
                    setUpdateBtn(updateBtnStyle)

                    if (e.id == hId) {
                        setId(e.id)
                        setHomeWorkTitle(e.homeworkTitle)
                        // console.log(e.homeworkDate)
                        setDateValue(e.homeworkDate)

                        setDesce(e.homeworkDesc)

                        axios.get(`${getSubjectData}`)
                            .then(res => {
                                setSubjectObj(res.data.find(item => item.id == e.subjectId))
                                setSubjectData(res.data);
                            })

                        axios.get(`${getClassWithDivData}?sid=${authUser.deptId}&bid=${authUser.branchId}`)
                            .then(res => {
                                setClassObj(res.data.find(item => item.divisionId == e.divId && item.classId == e.classId))
                                setClassData(res.data);
                            })
                    }
                })
                setLoaderOption(false);
            })
    }

    const getApiData = async () => {
        await axios.get(`${getSubjectData}`)
            .then(res => {
                setSubjectData(res.data);
            })

        await axios.get(`${getClassWithDivData}?sid=${authUser.deptId}&bid=${authUser.branchId}`)
            .then(res => {
                setClassData(res.data);
            })
    }

    const clearData = () => {
        setSaveBtn(saveBtnStyle);
        setUpdateBtn(displayNoneBtnStyle);
        setSubjectObj(null);
        setHomeWorkTitle('');
        setDateValue(null);
        setClassObj(null);
        setDesce("");
        navigate('/Home/ma1003/')
    }

    const saveBtnClick = async () => {
        setLoaderOption(true);
        const saveObj = {
            "classId": classObj.classId,
            "divId": classObj.divisionId,
            "subjectId": subjectObj.id,
            "homeworkDate": moment(dateValue).format("YYYY-MM-DD"),
            "homeworkTitle": homeWorkTitle,
            "homeworkDesc": desce,
            "branchId": authUser.branchId
        }
        // console.log(JSON.stringify(saveObj))
        await axios.post(saveHomeWorkMobileAPIURL, saveObj)
            .then(res => {
                if (res.data == "SAVED") {
                    getData();
                    clearData();
                    setLoaderOption(false);
                    toast.success("Saved successfully.")
                }
            }).catch(err => {
                console.log('home work save error:' + err);
                setLoaderOption(false);
                toast.error("Something went wrong, please check.")
            })
    }

    const updateBtnClick = async () => {
        setLoaderOption(true);
        const updateObj = {
            "id": id,
            "classId": classObj.classId,
            "divId": classObj.divisionId,
            "subjectId": subjectObj.id,
            "homeworkDate": moment(dateValue).format("YYYY-MM-DD"),
            "homeworkTitle": homeWorkTitle,
            "homeworkDesc": desce,
            "branchId": authUser.branchId
        }
        // console.log(JSON.stringify(updateObj))
        await axios.put(updateHomeWorkMobileAPIURL, updateObj)
            .then(res => {
                if (res.data == "UPDATED") {
                    getData();
                    clearData();
                    setLoaderOption(false);
                    toast.success("Updated successfully.")
                }
            }).catch(err => {
                console.log('home work update error:' + err);
                setLoaderOption(false);
                toast.error("Something went wrong, please check.")
            })
    }

    const state = {
        button: 1
    }

    const onSubmit = (e) => {
        e.preventDefault();
        if (state.button == 1) {
            saveBtnClick();
        }
        if (state.button == 2) {
            updateBtnClick();
        }
    }

    useEffect(() => {

        function handlekeydownEvent(event) {
            if (event.key === "Enter") {
                // console.log("Enter is pressed!")
                event.preventDefault();

                let btn1 = document.querySelector('.mobileHomeWorkSaveBtn')
                if (btn1 !== null) {
                    btn1.click();
                }
                let btn2 = document.querySelector('.mobileHomeWorkUpdateBtn')
                if (btn2 !== null) {
                    btn2.click();
                }
            }
        }

        document.addEventListener('keypress', handlekeydownEvent)
        return () => {
            document.removeEventListener('keypress', handlekeydownEvent)
        }

    }, []);

    return (
        <>
            {
                (loaderOption === true) ?
                    <Loader />
                    :
                    <>
                        <TitleLabel titleId={titleId} />
                        <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: "300px" }}>
                            {/* Form Data start */}
                            <form onSubmit={onSubmit}>
                                <div className="form-data">
                                    <input type="hidden" value={id} onChange={(e) => setId(e.target.value)} />

                                    <div className="row">
                                        <label className="" style={{ width: "145px", fontSize: "15px", fontWeight: "600", marginTop: "16px" }} >Class-Division :</label>
                                        <div className='col-sm-3'>
                                            <Autocomplete
                                                id="combo-box-demo"
                                                options={classData}
                                                PaperComponent={({ children }) => (
                                                    <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                                )}
                                                onChange={(event, newValue) => {
                                                    setClassObj(newValue);
                                                    setSubjectObj(null);
                                                    setHomeWorkTitle('');
                                                    setDateValue(null);
                                                    setDesce("");
                                                }}
                                                value={classObj}
                                                onKeyPress={(e) => (e.key == "Enter") ? classObj : ''}
                                                style={{ fontSize: '14px' }}
                                                getOptionLabel={option => (authUser.branchMedium == 1) ? option.classNameMr + " " + option.divisionNameMr : option.classNameEn + " " + option.divisionNameEn}
                                                size="small"
                                                renderInput={params => (
                                                    <TextField {...params} margin='dense' label="Select Class-Division" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                                )}
                                            />
                                        </div>

                                        <label className="" style={{ width: "145px", fontSize: "15px", fontWeight: "600", marginTop: "16px" }} >Subject :</label>
                                        <div className='col-sm-3'>
                                            <Autocomplete
                                                id="combo-box-demo"
                                                options={subjectData}
                                                PaperComponent={({ children }) => (
                                                    <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                                )}
                                                onChange={(event, newValue) => {
                                                    setSubjectObj(newValue);
                                                    setHomeWorkTitle('');
                                                    setDateValue(null);
                                                    setDesce("");
                                                }}
                                                value={subjectObj}
                                                onKeyPress={(e) => (e.key == "Enter") ? subjectObj : ''}
                                                style={{ fontSize: '14px' }}
                                                getOptionLabel={option => (authUser.branchMedium == 1) ? option.nameMr : option.nameEn}
                                                size="small"
                                                renderInput={params => (
                                                    <TextField {...params} margin='dense' label="Select Subject" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                                )}
                                            />
                                        </div>
                                    </div>

                                    <div className="row my-2">
                                        <label className="" style={{ width: "145px", fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Date :</label>
                                        <div className='col-sm-3'>
                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                <DatePicker
                                                    label="Date"
                                                    value={dateValue}
                                                    inputFormat="dd/MM/yyyy"
                                                    onChange={(newValue) => {
                                                        setDateValue(newValue);
                                                        // setHomeWorkTitle('');
                                                        // setDesce("");
                                                    }}
                                                    onKeyPress={(e) => (e.key == "Enter") ? dateValue : ''}
                                                    renderInput={(params) =>
                                                        <TextField
                                                            {...params}
                                                            margin="dense"
                                                            variant="outlined"
                                                            InputLabelProps={{ style: { fontSize: 14 } }}
                                                            required
                                                            fullWidth
                                                            autoComplete='off'
                                                            size="small" />}
                                                />
                                            </LocalizationProvider>
                                        </div>

                                        <label className="" style={{ width: "145px", fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Home Work Title :</label>
                                        <div className='' style={{ width: "500px" }}>
                                            <TextField
                                                fullWidth
                                                required
                                                value={homeWorkTitle}
                                                onChange={(e) => setHomeWorkTitle(e.target.value)}
                                                onKeyPress={(e) => (e.key == "Enter") ? homeWorkTitle : ''}
                                                autoComplete='off'
                                                size='small'
                                                id="outlined-basic"
                                                label="Home Work Title"
                                                variant="outlined"
                                                margin='dense'
                                                InputLabelProps={{ style: { fontSize: 14, marginRight: '1px' } }}
                                                inputProps={{ maxLength: 120 }}
                                            />
                                        </div>
                                    </div>

                                    <div className="row my-2">
                                        <label className="" style={{ width: "145px", fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Details :</label>
                                        <div className='col-sm-10'>
                                            <TextField
                                                fullWidth
                                                value={desce}
                                                onChange={(e) => setDesce(e.target.value)}
                                                onKeyPress={(e) => (e.key == "Enter") ? desce : ''}
                                                multiline
                                                rows={4}
                                                autoComplete='off'
                                                size='small'
                                                id="outlined-basic"
                                                label="Details"
                                                variant="outlined"
                                                margin='dense'
                                                InputLabelProps={{ style: { fontSize: 14, marginRight: '1px' } }}
                                            />
                                        </div>
                                    </div>

                                    <div className="row mt-3">
                                        <div className="col-sm-4" style={{ marginLeft: "145px" }}>
                                            <button type="submit" onClick={() => (state.button = 1)} disabled={saveBtnDisabled} className={saveBtn}>Save</button>
                                            <button type="submit" onClick={() => (state.button = 2)} disabled={updateBtnDisabled} className={updateBtn} >Update</button>
                                            <button type="button" className="btn btn-danger btn-sm mx-1" onClick={() => clearData()}>Cancel</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </>
            }
            <ToastContainer position="top-right" theme="colored" />
        </>
    )
}

export default HomeWork