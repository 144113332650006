import React, { useEffect, useState } from "react";
import {
    TextField,
    Button,
    Modal,
    Box,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Typography,
    Grid,
    RadioGroup,
    FormControlLabel,
    Radio,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import makeStyles from "@mui/styles/makeStyles";
import { serachStudentByNameAPIURL, serachStudentByRegNoAPIURL } from "../Sanstha/Services/StudentProfileAPIURL";
import { useSelector } from "react-redux";
import axios from "axios";
import { toast } from "react-toastify";
import Loader from "./Loader";

const useStyles = makeStyles(() => ({
    select: {
        "& li": {
            fontSize: "14px",
            fontWeight: "550"
        },
    },
}));

const AddStudentBySearching = ({ setclassData, setDivision, setStudentId, setSelectedStudent, classData, division, studentId, selectedStudent, setStudentLcNo, label, setStudentDetails, className, readOnly, setLoaderOption }) => {
    const classes = useStyles();
    const authUser = useSelector((state) => state.user.value);

    const [open, setOpen] = useState(false);
    const [language, setLanguage] = useState(authUser ? parseInt(authUser.branchMedium) : "");
    const [studName, setStudName] = useState("");
    const [studRegNo, setStudRegNo] = useState(studentId);
    // const [loaderOption, setLoaderOption] = useState(false);

    // Update language when authUser changes
    useEffect(() => {
        if (authUser) {
            setLanguage(parseInt(authUser.branchMedium));
        }
    }, [authUser]);

    // console.log(language);


    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
        setStudentData([]);
        setStudName("");
        setLanguage(parseInt(authUser.branchMedium));
    }
    const handleChange = (event) => setLanguage(event.target.value);

    const [studentData, setStudentData] = useState([]);

    // Sample Data for Bootstrap Table
    // const studentData = [
    //     { regNo: "101", name: "Amit", class: "10", division: "A", status: "Active" },
    //     { regNo: "102", name: "Sneha", class: "9", division: "B", status: "Inactive" },
    //     { regNo: "103", name: "Rahul", class: "8", division: "C", status: "Active" },
    // ];

    const searchStudentByName = async (e) => {
        // e.preventDefault();
        // setLoaderOption(true);
        setStudentData([]);

        // console.log(`${serachStudentByNameAPIURL}?langId=${language}&fName=${studName}&mName=${studName}&lName=${studName}&sid=${authUser.deptId}&bid=${authUser.branchId}`)
        axios.get(`${serachStudentByNameAPIURL}?langId=${language}&fName=${studName}&mName=${studName}&lName=${studName}&sid=${authUser.deptId}&bid=${authUser.branchId}`)
            .then(res => {
                if (res.data == "NOTFOUND") {
                    setLoaderOption(false);
                    toast.warn("Student not fount with this name.")
                }
                else {
                    const onlyEnrollStudentList = res.data.filter(e => e.currentStatus == 0)
                    setStudentData((label == "Leaving Certificate") ? onlyEnrollStudentList : res.data);
                    setLoaderOption(false);
                }
            });

    }

    const searchStudentByRegNo = async (rNo) => {
        // setLoaderOption(true);
        // Search by registration number (exact match)
        // filteredData = studentData.filter(student => student.regCode == searchedRegNo.trim());
        // console.log(`${serachStudentByRegNoAPIURL}?regNo=${rNo}&sid=${authUser.deptId}&bid=${authUser.branchId}`)
        axios(`${serachStudentByRegNoAPIURL}?regNo=${rNo}&sid=${authUser.deptId}&bid=${authUser.branchId}`)
            .then(res => {
                if (res.data == "NOTFOUND") {
                    setLoaderOption(false);
                    toast.warn("Student not fount with this name.")
                }
                else {
                    const filteredData = res.data;

                    // Log the filtered data for debugging
                    // console.log("Filtered Data: ", filteredData);

                    // Set filtered data state
                    if (label == "Leaving Certificate") {
                        if (filteredData.currentStatus == 1) {
                            setLoaderOption(false);
                            toast.warn("LC for this student is already exists.")
                        }
                        else {
                            updateData(filteredData);
                            setLoaderOption(false);
                        }
                    }
                    else {
                        updateData(filteredData);
                        setLoaderOption(false);
                    }
                }
            });


    }

    const updateData = (item) => {
        // setLoaderOption(true);
        const classWithDivision = `${authUser.branchMedium == 1 ? item.currentClassNameMr : item.currentClassNameEn} - ${authUser.branchMedium == 1 ? item.currentDivNameMr : item.currentDivNameEn}`;
        // console.log(item)
        if (label == "LC No") {
            if (item.lcNo === 0) {
                setLoaderOption(false);
                toast.warn("LC not found for this student.");
            } else {
                setStudentDetails(item);
                setclassData(classWithDivision); // Combined Class and Division
                setStudentId(item.regCode);
                setStudRegNo(item.regCode);
                setSelectedStudent(authUser.branchMedium == 1 ? item.studentNameMr : item.studentNameEn);
                setStudentLcNo(item.lcNo);
                handleClose(); // Close the modal after setting data
                setLoaderOption(false);
            }
        } else {
            setStudentDetails(item);
            setclassData(classWithDivision); // Combined Class and Division
            setStudentId(item.regCode);
            setStudRegNo(item.regCode);
            setSelectedStudent(authUser.branchMedium == 1 ? item.studentNameMr : item.studentNameEn);
            setStudentLcNo(item.lcNo);
            handleClose(); // Close the modal after setting data
            setLoaderOption(false);
        }
    };



    // if (loaderOption == true) {
    //     return (
    //         <Loader />
    //     )
    // }
    // else {
    return (
        <>
            {/* Main Input & Button Row */}
            <div className="row">
                <label
                    htmlFor="regNo"
                    className={className}
                    style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }}
                >
                    Student :
                </label>
                <div className="col-sm-2">
                    <TextField
                        fullWidth
                        required
                        autoComplete="off"
                        size="small"
                        id="regNo"
                        label="Reg No"
                        variant="outlined"
                        margin="dense"
                        value={studRegNo}
                        onChange={(e) => {
                            setStudRegNo(e.target.value);
                            setclassData("");
                            setDivision("");
                            setStudentId("");
                            setSelectedStudent("");
                            setStudentData([]);
                        }}
                        inputProps={{ readOnly: readOnly }}
                        onBlur={(e) => searchStudentByRegNo(e.target.value)}
                        InputLabelProps={{ style: { fontSize: 14 } }}
                    />
                </div>

                <div className='col-sm-3'>
                    <TextField
                        required
                        fullWidth
                        autoComplete='off'
                        size='small'
                        id="outlined-basic"
                        label="Student Name"
                        variant="outlined"
                        margin='dense'
                        value={selectedStudent}
                        onChange={(e) => setSelectedStudent(e.target.value)}
                        onKeyPress={(e) => (e.key == "Enter") ? selectedStudent : ""}
                        inputProps={{ readOnly: true }}
                        InputLabelProps={{ style: { fontSize: 14 } }}
                    />
                </div>

                <div className='col-sm-2'>
                    <TextField
                        required
                        fullWidth
                        autoComplete='off'
                        size='small'
                        id="outlined-basic"
                        label="Class-Div"
                        variant="outlined"
                        margin='dense'
                        value={classData}
                        onChange={(e) => setclassData(e.target.value)}
                        onKeyPress={(e) => (e.key == "Enter") ? classData : ""}
                        inputProps={{ readOnly: true }}
                        InputLabelProps={{ style: { fontSize: 14 } }}
                    />
                </div>

                {/* <div className='col-sm-2'>
                        <TextField
                            required
                            fullWidth
                            autoComplete='off'
                            size='small'
                            id="outlined-basic"
                            label="Division"
                            variant="outlined"
                            margin='dense'
                            value={division}
                            onChange={(e) => setDivision(e.target.value)}
                            onKeyPress={(e) => (e.key == "Enter") ? division : ""}
                            inputProps={{ readOnly: true }}
                            InputLabelProps={{ style: { fontSize: 14 } }}
                        />
                    </div> */}

                <div hidden={readOnly} className="col-sm-3 mt-2">
                    <Button
                        type="button"
                        variant="contained"
                        color="primary"
                        startIcon={<SearchIcon />}
                        onClick={handleOpen}
                    >
                        Advance Search
                    </Button>
                </div>
            </div>

            {/* <div className="row my-2" hidden={readOnly}>
    
                </div> */}

            {/* Modal for Advanced Search */}
            <Modal open={open} onClose={handleClose}>
                <Box
                    sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: 500,
                        bgcolor: "background.paper",
                        boxShadow: 24,
                        p: 4,
                        borderRadius: 2,
                    }}
                >
                    <Typography variant="h6" sx={{ mb: 2 }}>
                        Search By Name
                    </Typography>

                    {/* Select Dropdown */}

                    {/* <FormControl fullWidth size="small" sx={{ mb: 2 }}>
                            <InputLabel style={{ fontSize: "14px" }}>Language</InputLabel>
                            <Select
                                value={language}
                                onChange={handleChange}
                                label="Language"
                                MenuProps={{ classes: { paper: classes.select } }}
                            >
                                <MenuItem value={1}>Marathi</MenuItem>
                                <MenuItem value={2}>English</MenuItem>
                            </Select>
                        </FormControl> */}
                    {/* <div className="row">
                            <label className="col-sm-1 my-1 mt-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Language:</label>
                            <div className='col-sm-3'> */}
                    <RadioGroup row aria-label="Language" margin="dense" name="customized-radios" value={language} onChange={(e) => setLanguage(parseInt(e.target.value))}>
                        <FormControlLabel value={1} control={<Radio color='primary' />} label="Marathi" />
                        <FormControlLabel value={2} control={<Radio color='primary' />} label="English" />
                    </RadioGroup>
                    {/* </div>
                        </div> */}

                    {/* Input Box */}
                    <TextField
                        fullWidth
                        label="Enter Name"
                        variant="outlined"
                        size="small"
                        autoComplete="off"
                        sx={{ mb: 2 }}
                        InputLabelProps={{ style: { fontSize: 14 } }}
                        value={studName}
                        onChange={(e) => setStudName(e.target.value)}
                    />

                    {/* Buttons */}
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <Button type="button" variant="contained" color="primary" fullWidth onClick={searchStudentByName}>
                                Search
                            </Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button type="button" variant="contained" color="secondary" fullWidth onClick={handleClose}>
                                Close
                            </Button>
                        </Grid>
                    </Grid>


                    {/* Bootstrap Table */}
                    {
                        studentData.length != 0
                            ?
                            <div className="table-responsive mt-3" style={{ maxHeight: '300px' }}>
                                <table className="table table-bordered table-striped">
                                    <thead className="" style={{ backgroundColor: '#5d6d7e', color: '#fff' }}>
                                        <tr>
                                            <th style={{ fontSize: '14px', lineHeight: '1.5' }}>Reg No</th>
                                            <th style={{ fontSize: '14px', lineHeight: '1.5' }}>Student Name</th>
                                            <th style={{ fontSize: '14px', lineHeight: '1.5' }}>Class</th>
                                            <th style={{ fontSize: '14px', lineHeight: '1.5' }}>Division</th>
                                            <th style={{ fontSize: '14px', lineHeight: '1.5' }}>Status</th>
                                        </tr>
                                    </thead>
                                    <tbody className="table-sm">
                                        {studentData.map((student, index) => (
                                            <tr key={index}>
                                                <td onClick={() => updateData(student)} style={{ cursor: 'pointer' }}>{student.regCode}</td>
                                                <td onClick={() => updateData(student)} style={{ cursor: 'pointer' }}>
                                                    {authUser.branchMedium == 1 ? student.studentNameMr : student.studentNameEn}
                                                </td>
                                                <td onClick={() => updateData(student)} style={{ cursor: 'pointer' }}>
                                                    {authUser.branchMedium == 1 ? student.currentClassNameMr : student.currentClassNamEn}
                                                </td>
                                                <td onClick={() => updateData(student)} style={{ cursor: 'pointer' }}>
                                                    {authUser.branchMedium == 1 ? student.currentDivNameMr : student.currentDivNamEn}
                                                </td>
                                                <td onClick={() => updateData(student)} style={{ cursor: 'pointer' }}>
                                                    <span
                                                        className={`badge ${student.currentStatusName === "Enroll" ? "bg-success" : "bg-danger"}`}
                                                    >
                                                        {student.currentStatusName}
                                                    </span>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>

                                </table>
                            </div>
                            :
                            ''
                    }
                </Box>
            </Modal>
        </>
    );
    // }
};

export default AddStudentBySearching;
