import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { getCityData } from '../../../CommonServices/InstituteMasterAPIURL';
import TitleLabel from '../../../CommonComponent/TitleLabel';
import { Autocomplete, Paper, TextField } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { getVotersKendraMasterAPIURL } from '../Services/VotersKendraMasterAPIURL';
import { getRegionsInSangliAPIURL, saveRegionsInSangliAPIURL, updateRegionsInSangliAPIURL } from '../Services/RegionsInsangliCityAPIURL';
import { toast } from 'react-toastify';

const RegionsInSnagliCity = () => {
    const titleId = "Regions In Sangli City";
    const authUser = useSelector((state) => state.user.value);
    // loader on/off
    const [loaderOption, setLoaderOption] = useState(false);
    const navigate = useNavigate();

    let { rsId, EditFlag } = useParams();

    //Note: This below states are declared for Save & Update(Disabled/unDisabled)
    const saveBtnStyle = "regionsInSangliSaveBtn btn btn-primary btn-sm";
    const updateBtnStyle = "regionsInSangliUpdateBtn btn btn-primary btn-sm";
    const displayNoneBtnStyle = "d-none";
    const [saveBtn, setSaveBtn] = useState(saveBtnStyle);
    const [updateBtn, setUpdateBtn] = useState(displayNoneBtnStyle);

    const [saveBtnDisabled, setSaveBtnDisabled] = useState(false);
    const [updateBtnDisabled, setUpdateBtnDisabled] = useState(false);

    const [id, setId] = useState();
    const [votingKendraData, setVotingKendraData] = useState([]);
    const [votingKendra, setVotingKendra] = useState(null);
    const [areaNo, setAreaNo] = useState("");
    const [areaName, setAreaName] = useState("");


    useEffect(() => {
        if (EditFlag == 1) {
            getData();
            setSaveBtnDisabled(false);
            setUpdateBtnDisabled(true);
        }
        else if (EditFlag == 2) {
            getEditDetails();
            setSaveBtnDisabled(true);
            setUpdateBtnDisabled(false);
        }
    }, [authUser])

    const getData = async () => {
        await axios.get(`${getVotersKendraMasterAPIURL}`)
            .then((response) => {
                //console.log(response.data)
                setVotingKendraData(response.data);
            })
    }

    const getEditDetails = async () => {
        setLoaderOption(true);
        // console.log(`${getVotersKendraMasterAPIURL}`);
        await axios.get(`${getRegionsInSangliAPIURL}`)
            .then((response) => {
                const newData = response.data.find(e => e.id == rsId)
                setSaveBtn(displayNoneBtnStyle);
                setUpdateBtn(updateBtnStyle);
                setAreaNo(newData.regionNo);
                setAreaName(newData.regionName);

                axios.get(`${getVotersKendraMasterAPIURL}`)
                    .then((response) => {
                        // console.log(newData.voterKendraId + "" + JSON.stringify(response.data))
                        setVotingKendraData(response.data);
                        setVotingKendra(response.data.find(e => e.id == newData.voterKendraId))
                    })
                setId(newData.id)
                setLoaderOption(false);

            }).catch(error => {
                setLoaderOption(false);
                console.log(error);
                toast.error("Something went wrong, please check.")
            })
    }

    const ClearData = () => {
        setId("");
        setVotingKendra(null);
        setAreaNo("");
        setAreaName("");
        navigate('/Home/m1403/')
        setSaveBtn(saveBtnStyle);
        setUpdateBtn(displayNoneBtnStyle);
    }

    const state = {
        button: 1
    };

    const saveBtnClick = async () => {
        setLoaderOption(true);
        const saveObj = {
            "id": id,
            "regionNo": areaNo,
            "regionName": areaName,
            "voterKendraId": votingKendra.id

        }
        // console.log(JSON.stringify(saveObj))
        await axios.post(saveRegionsInSangliAPIURL, saveObj)
            .then((response) => {
                if (response.data == "ALREADYEXISTS") {
                    setLoaderOption(false);
                    toast.warn(`Data with this details is already exists.`)
                }
                else if (response.data == "SAVED") {
                    ClearData();
                    getData();
                    setLoaderOption(false);
                    toast.success("Saved successfully.")
                }

            }).catch(error => {
                setLoaderOption(false);
                console.log(error);
                toast.error("Something went wrong, please check.")
            })
    }

    const updateBtnClick = async () => {
        setLoaderOption(true);
        const updateObj = {
            "id": rsId,
            "regionNo": areaNo,
            "regionName": areaName,
            "voterKendraId": votingKendra.id
        }
        // console.log(JSON.stringify(updateObj))
        await axios.put(updateRegionsInSangliAPIURL, updateObj)
            .then((response) => {
                if (response.data == "ALREADYEXISTS") {
                    setLoaderOption(false);
                    toast.warn(`Data with this details is already exists.`)
                }
                else if (response.data == "UPDATED") {
                    ClearData();
                    getData();
                    setLoaderOption(false);
                    toast.success("Updated successfully.")
                }

            }).catch(error => {
                setLoaderOption(false);
                console.log(error);
                toast.error("Something went wrong, please check.")
            })
    }

    const onSubmit = e => {
        e.preventDefault();
        if (state.button === 1) {
            saveBtnClick();
        }
        if (state.button === 2) {
            updateBtnClick();
        }
    };

    useEffect(() => {

        function handlekeydownEvent(event) {
            if (event.key === "Enter") {
                // console.log("Enter is pressed!")
                event.preventDefault();
                if (EditFlag == 1) {
                    // console.log('save Enter is pressed!');
                    let btn1 = document.querySelector('.regionsInSanglSaveBtn')
                    if (btn1 !== null) {
                        btn1.click();
                    }
                    setSaveBtnDisabled(false);
                    setUpdateBtnDisabled(true);
                }
                if (EditFlag == 2) {
                    // console.log('update Enter is pressed!');
                    let btn2 = document.querySelector('.regionsInSanglUpdateBtn')
                    if (btn2 !== null) {
                        btn2.click();
                    }
                    setSaveBtnDisabled(true);
                    setUpdateBtnDisabled(false);
                }
            }
        }

        document.addEventListener('keypress', handlekeydownEvent)
        return () => {
            document.removeEventListener('keypress', handlekeydownEvent)
        }

    }, []);

    return (
        <div>
            <TitleLabel titleId={titleId} />
            <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", position: "relative" }}>
                <form onSubmit={onSubmit}>
                    <input type="hidden" value={id} onChange={(e) => setId(e.target.value)} name="" />

                    <div className="row">
                        <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >भाग नंबर :</label>
                        <div className='col-sm-2'>
                            <TextField
                                required
                                onKeyDown={(e) => (e.key === " " && areaNo.length === 0) ? e.preventDefault() : ""}
                                fullWidth
                                margin='dense'
                                type='number'
                                value={areaNo}
                                onChange={e => setAreaNo(e.target.value)}
                                onKeyPress={(e) => (e.key == "Enter") ? areaNo : ""}
                                autoComplete='off'
                                size='small'
                                id="outlined-basic"
                                label="भाग नंबर"
                                variant="outlined"
                                InputLabelProps={{ style: { fontSize: 14 } }}
                                inputProps={{ maxLength: 120 }}
                            />
                        </div>
                    </div>

                    <div className="row my-2">
                        <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >भाग :</label>
                        <div className='col-sm-4'>
                            <TextField
                                required
                                onKeyDown={(e) => (e.key === " " && areaName.length === 0) ? e.preventDefault() : ""}
                                fullWidth
                                margin='dense'
                                value={areaName}
                                onChange={e => setAreaName(e.target.value)}
                                onKeyPress={(e) => (e.key == "Enter") ? areaName : ""}
                                autoComplete='off'
                                size='small'
                                id="outlined-basic"
                                label="भाग"
                                variant="outlined"
                                InputLabelProps={{ style: { fontSize: 14 } }}
                                inputProps={{ maxLength: 120 }}
                            />
                        </div>
                    </div>


                    <div className="row my-2">
                        <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >मतदान केंद्र :</label>
                        <div className='col-sm-4'>
                            <Autocomplete
                                id="combo-box-demo"
                                PaperComponent={({ children }) => (
                                    <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                )}
                                onKeyPress={(e) => (e.key == "Enter") ? votingKendra : ""}
                                options={votingKendraData}
                                value={votingKendra}
                                onChange={(event, newValue) => {
                                    //setNarrationId(newValue.id);
                                    //console.log(newValue.narrationDetail);
                                    setVotingKendra(newValue);
                                }}
                                style={{ fontSize: '14px' }}
                                getOptionLabel={option => option.voterKendraNo + " . " + option.voterKendraName}
                                size="small"
                                renderInput={params => (
                                    <TextField {...params}
                                        margin='dense' label="मतदान केंद्र" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                )}
                            />
                        </div>

                        <div className="col-sm-4 mt-3">
                            <button type='button' className='btn btn-sm btn-warning'>List Members For This Kendra</button>
                        </div>
                    </div>


                    <div className="row mt-3">
                        <div className="col-sm-12 offset-sm-2">
                            <button type="submit" disabled={saveBtnDisabled} onClick={() => (state.button = 1)} className={saveBtn} >Save</button>
                            <button type="submit" disabled={updateBtnDisabled} onClick={() => (state.button = 2)} className={updateBtn} >Update</button>
                            <button type="button" onClick={ClearData} className="btn btn-danger btn-sm" style={{ margin: '0px 4px' }} >Cancel</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}


export default RegionsInSnagliCity
