import { BaseIP, BaseURL, BaseURLMaster, dBaseURL, dBaseURLMaster } from "./APIURL";

//temp deceleration data


//  /institute/sss/administration/studentEntry
//GetMapping - /studentEntryList  RequestParam("sid") Long sectionId, @RequestParam("bid") Long branchId
//PostMapping - /saveStudentEntry pass object

//Student API Link
export const MotherToungMasterGetMapping = BaseURLMaster + "Mothertongues/";
export const ReligionMasterGetMapping = BaseURLMaster + "Religions/";
export const NationalityMasterGetMapping = BaseURLMaster + "Nationalities/";
export const DisabilityMasterGetMapping = BaseURLMaster + "Disibilities/";
export const CategoryMasterGetMapping = BaseURLMaster + "Categories/";
export const DesignationMasterGetMapping = BaseURLMaster + "Designations/";
export const CasteCategoryAPIURL = BaseURLMaster + "Categories";

//new student entry
export const studentBaseUrlStudEntryMaster = BaseURL + "administration/studentEntry/";
export const StudentRegisterPostMapping = studentBaseUrlStudEntryMaster + "saveStudentEntry/";

//Get new reg code for registration
export const newRegCode = studentBaseUrlStudEntryMaster + "getStudentNewRegNo/";

//getMapping
export const getStudentList = studentBaseUrlStudEntryMaster + "studentEntryList/";
export const getStudentListBySessionYear = studentBaseUrlStudEntryMaster + "studentEntryListBySessionYear/";
export const getStudentListDaud = "http://192.168.29.102:8080/institute/sss/administration/studentEntry/studentEntryList/";
export const getStudentDataByRegNo = studentBaseUrlStudEntryMaster + "getStudentDataByRegCode/";

//postMapping
export const updateStudentMasterData = studentBaseUrlStudEntryMaster + "updateStudentEntry/";

//get Class data
export const getClassData = BaseURLMaster + "getClassesBySectionAndBranch";

//getDivisionData
export const getDivisionData = BaseURLMaster + "getDivisionsByClassAndSectionAndBranch";

//getOldSchooldata
export const getOldSchool = BaseURLMaster + "oldSchools";

//chk roll no assigned or not
//localhost:8080/institute/sss/administration/studentEntry/checkRollNoAssignOrNot?sid=3&bid=1&regNo=12885
export const chkAllocatedStudentAPIURL = BaseURL + "administration/studentEntry/checkRollNoAssignOrNot";

//get student list by branchid
// /getStudentListByBranchId
export const getStudentListByBranchAPIURL = BaseURL + "administration/LC/getStudentListByBranchId"

//institute/sss/administration/studentImage/getStudentImageByRegNo

// regNo, sid, bid

export const getStudentImageAPIURL = BaseURL + "administration/studentImage/getStudentImageByRegNo";

export const studImgPath = "http://157.66.191.17:8080" + "/zData/std_img/"
