import { BaseIP, BaseURL, BaseURLMaster, dBaseURL } from "./APIURL";

//LC BaseUrl
export const BaseURLLc = BaseURL + "administration/LC/"

//LC APIURL
export const getStudentList = BaseURLLc + "getStudentLcBaseData";
export const getStudentListEdit = BaseURLLc + "getStudentLcBaseDataEdit";

export const getLeftStudentDetailsAPIURL = BaseURLLc + "getEnrolledStudentBaseData";

export const getStudentsListByRegNoAPIURL = BaseURLLc + "getEnrolledStudentBaseDataByRegNo"

export const getStudentLcNo = BaseURLLc + "getLcNo/";
export const GetStudentLcData = BaseURLLc + "getIssueLeavingCertificateData";
//export const GetStudentLcDataDaud ="http://192.168.1.102:8080/institute/sss/administration/LC/getIssueLeavingCertificateData";
export const PostStudentLc = BaseURLLc + "saveIssueLeavingCertificateData";
export const PutStudentLc = BaseURLLc + "updateIssueLeavingCertificateData";
//localhost:8080/institute/sss/administration/LC/deleteIssuedLC?sid=3&bid=1&regNo=11517
export const deleteStudentLc = BaseURLLc + "deleteIssuedLC";


//Get LC Data on editClick
//getStudentLcBaseData/
export const getStudentListForEdit = BaseURLLc + "getStudentLcBaseData/"
export const GetStudentLcEditData = BaseURLLc + "getIssuedLCData/";

//Get LcdataByRegNo
export const GetStudentLcDataByRegNo = BaseURLLc + "getStudentLcBaseDataByRegNo";

//ProgressConduct and remark APIURL
export const GetProgressConduct = BaseURLMaster + "ProgressConducts/";
export const GetRemark = BaseURLMaster + "Leavings/";

//Lc print APIURL
export const LcPrintBaseURL = BaseURL + "administration/reports/";
export const LcPrintURL = LcPrintBaseURL + "generateLCReport"

//class Data
export const getClass = BaseURLMaster + "Classes/";

//DivisionData
export const getDivision = BaseURLMaster + "Divisions/";

//ReligionData
export const getReligion = BaseURLMaster + "Religions/";

//CasteData
export const getCaste = BaseURLMaster + "Castes/";

//subCasteData
export const getSubCaste = BaseURLMaster + "SubCastes/";


//Duplicate Lc APIURL
//localhost:8080/institute/sss/administration/DuplicateLC/getDuplicateLcData?lcNo=1777&sid=3&bid=1
export const getDuplicateLcData = BaseURL + "administration/DuplicateLC/getDuplicateLcData";

//localhost:8080/institute/sss/administration/DuplicateLC/saveDuplicateLcData?langId=2&waterMark=1
export const saveAndPrintDuplicateLc = BaseURL + "administration/DuplicateLC/saveDuplicateLcData";
// {
//     "lcNo":1777,
//     "regNo":12618,
//     "oldLcNo":123,
//     "duplicateLcDate":"22/08/2022",
//     "academicYear":2020,
//     "sectionId":3,
//     "branchId":1
// }