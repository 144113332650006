import React, { useState, useEffect } from 'react';
import TitleLabel from "../../CommonComponent/TitleLabel";
import TextField from '@mui/material/TextField';
import axios from 'axios';
import Autocomplete from "@mui/material/Autocomplete";
import { subjectGetData } from '../Services/SubjectAllocationAPIURL';
import Checkbox from '@mui/material/Checkbox';
import { ToastContainer, toast } from 'react-toastify';
import { useSelector } from "react-redux";
import Paper from "@mui/material/Paper";
import '../Styles/TableStyle.css';
import AddIcon from '@mui/icons-material/Add';
import Loader from '../../CommonComponent/Loader';
import { getClassWithDiv } from '../Services/StudentRollNoAPIURL';

import StudentWiseCheckBox from './RollNoAssignRow/StudentWiseCheckBox';
import { getExternalExamAllocationAPIURL, saveExternalExamAllocationAPIURL } from '../Services/ExternalExamAllocationAPIURL';
import { getExternalExamAPIURL } from '../../Masters/Services/ExternalExamAPIURL';



const ExternalExamAllocation = () => {
    const authUser = useSelector((state) => state.user.value);
    //console.log(JSON.stringify(authUser));
    const titleId = "External Exam Allocation";

    const [loaderOption, setLoaderOption] = useState(false);

    //Note: This below states are declared for Save & Update(Disabled/unDisabled)
    const saveBtnStyle = "btn btn-primary btn-sm";
    const updateBtnStyle = "btn btn-primary btn-sm";
    const displayNoneBtnStyle = "d-none";
    const [saveBtn, setSaveBtn] = useState(saveBtnStyle);
    const [updateBtn, setUpdateBtn] = useState(displayNoneBtnStyle);

    const [id, setId] = useState();
    const [examData, setexamData] = useState([]);
    const [studentData, setStudentData] = useState([]);
    const [classDivData, setClassDivData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [examObj, setexamObj] = useState(null);
    const [classDivObj, setClassDivObj] = useState(null);
    const [searchTerm, setSearchTerm] = useState("");
    const [checkValue, setCheckValue] = useState([]);
    const [checkAllSub, setCheckAllSub] = useState(false);
    const [tempData, setTempData] = useState([]);
    const [checkAllocated, setCheckAllocated] = useState(false);

    const [rememberUser, setRememberUser] = useState(false) //use false for unchecked initially
    //console.log(JSON.stringify(filteredData))
    // console.log("checkValue======" + JSON.stringify(checkValue))

    const handleCheckChange = (val, Id, regNo, studNameMr, studNameEn, rNo) => {
        //setRememberUser(!rememberUser)
        if (val == true) {
            //setRememberUser(!rememberUser)
            let SaveObjectData = studentData
            studentData.map((e, index) => {

                if (e.regCode == regNo) {
                    SaveObjectData[index] = {
                        regCode: regNo,
                        studentNameMr: studNameMr,
                        studentNameEn: studNameEn,
                        rollNo: rNo,
                        classId: classDivObj.classId,
                        divisionId: classDivObj.divisionId,
                        externalExamId : examObj.id,
                        sessionYear: authUser.sessionYear,
                        sectionId: authUser.deptId,
                        branchId: authUser.branchId,
                        isAllocated: 1
                    }

                }
            })
            let allocated = SaveObjectData.filter(e => e.isAllocated == 1)
            let updated = allocated
            allocated.map((e, index) => {
                updated[index] = {
                    ...e,
                    classId: classDivObj.classId,
                    divisionId: classDivObj.divisionId,
                    externalExamId : examObj.id,
                    sessionYear: authUser.sessionYear,
                    sectionId: authUser.deptId,
                    branchId: authUser.branchId,
                }
            })
            //console.log("updated====="+JSON.stringify(updated))
            let updated1 = SaveObjectData.filter(e => e.isAllocated == 0)
            let updated2 = updated1
            updated1.map((e, index) => {
                updated2[index] = {
                    ...e,
                    classId: classDivObj.classId,
                    divisionId: classDivObj.divisionId,
                    externalExamId : examObj.id,
                    sessionYear: authUser.sessionYear,
                    sectionId: authUser.deptId,
                    branchId: authUser.branchId,
                }
            })
            //console.log("updated2===="+JSON.stringify(updated2))
            setCheckValue(updated.concat(updated2));

            if (updated1.length === 0) {
                setCheckAllSub(true);
            }

        }
        else if (val == false) {
            //setRememberUser(rememberUser)
            // const removedData = checkValue.filter((item) => item.regCode != regNo)
            // setCheckValue(removedData);
            let SaveObjectData = studentData
            studentData.map((e, index) => {
                if (e.regCode == regNo) {
                    SaveObjectData[index] = {
                        regCode: regNo,
                        studentNameMr: studNameMr,
                        studentNameEn: studNameEn,
                        rollNo: rNo,
                        classId: classDivObj.classId,
                        divisionId: classDivObj.divisionId,
                        externalExamId : examObj.id,
                        sessionYear: authUser.sessionYear,
                        sectionId: authUser.deptId,
                        branchId: authUser.branchId,
                        isAllocated: 0
                    }
                }
            })
            let allocated = SaveObjectData.filter(e => e.isAllocated == 1)
            let updated = allocated
            allocated.map((e, index) => {
                updated[index] = {
                    ...e,
                    classId: classDivObj.classId,
                    divisionId: classDivObj.divisionId,
                    externalExamId : examObj.id,
                    sessionYear: authUser.sessionYear,
                    sectionId: authUser.deptId,
                    branchId: authUser.branchId,
                }
            })
            //console.log("updated====="+JSON.stringify(updated))
            let updated1 = SaveObjectData.filter(e => e.isAllocated == 0)
            let updated2 = updated1
            updated1.map((e, index) => {
                updated2[index] = {
                    ...e,
                    classId: classDivObj.classId,
                    divisionId: classDivObj.divisionId,
                    externalExamId : examObj.id,
                    sessionYear: authUser.sessionYear,
                    sectionId: authUser.deptId,
                    branchId: authUser.branchId,
                }
            })
            //console.log("updated2===="+JSON.stringify(updated2))
            setCheckValue(updated.concat(updated2));
            setCheckAllSub(false);
        }
    }

    useEffect(() => {
        getData();
    }, [authUser]);


    //get subject data
    const getData = async () => {

        await axios.get(`${getExternalExamAPIURL}`)
            .then((response) => {
                setexamData(response.data);

            })

        if (authUser.deptId !== null || authUser.branchId !== null) {
            await axios.get(`${getClassWithDiv}?sid=${authUser.deptId}&bid=${authUser.branchId}`)
                .then((response) => {
                    setClassDivData(response.data);
                })
        }
    }

    const getStudentDataForAllocation = async (e) => {
        e.preventDefault();
        setLoaderOption(true);
        setStudentData([]);
        setFilteredData([]);
        setTempData([]);
        setCheckValue([]);
        await axios(`${getExternalExamAllocationAPIURL}?acYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&classId=${classDivObj.classId}&divId=${classDivObj.divisionId}&extExamId=${examObj.id}`)
            .then(res => {
                if (res.data === "NOTFOUND") {
                    setStudentData([]);
                    setFilteredData([]);
                    setTempData([]);
                    setCheckValue([]);
                    setLoaderOption(false);
                }
                else {
                    setStudentData(res.data);
                    setFilteredData(res.data);
                    setTempData(res.data);
                    setLoaderOption(false);


                    //singleChecked data
                    let SaveObj = (res.data).filter(e => {
                        return e.isAllocated == 1
                    })
                    //console.log(JSON.stringify(SaveObj))
                    let updatedData = SaveObj;
                    SaveObj.map((e, index) => {
                        updatedData[index] = ({
                            ...e,
                            classId: classDivObj.classId,
                            divisionId: classDivObj.divisionId,
                            externalExamId : examObj.id,
                            sessionYear: authUser.sessionYear,
                            sectionId: authUser.deptId,
                            branchId: authUser.branchId,
                        })
                    })
                    //console.log(JSON.stringify(updatedData))
                    setCheckValue(updatedData);

                    //allchecked data
                    let allCheckedData = (res.data).filter(e => {
                        return e.isAllocated == 1
                    })
                    let updateData = allCheckedData;
                    allCheckedData.map((e, index) => {
                        updateData[index] = ({
                            ...e,
                            classId: classDivObj.classId,
                            divisionId: classDivObj.divisionId,
                            externalExamId : examObj.id,
                            sessionYear: authUser.sessionYear,
                            sectionId: authUser.deptId,
                            branchId: authUser.branchId,
                        })
                    })
                    getChekedData(updateData);
                }




            }).catch(error => {
                console.log(error)
            })

    }

    const checkAllSubjects = async (e) => {
        setCheckAllSub(e.target.checked);
        if (e.target.checked === true) {
            setCheckValue([]);

            axios.get(`${getExternalExamAllocationAPIURL}?acYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&classId=${classDivObj.classId}&divId=${classDivObj.divisionId}&extExamId=${examObj.id}`)
                .then((response) => {
                    setStudentData([])
                    setFilteredData([])
                    let updatedData = response.data;
                    response.data.map((e, index) => {
                        updatedData[index] = ({
                            ...e,
                            classId: classDivObj.classId,
                            divisionId: classDivObj.divisionId,
                            externalExamId : examObj.id,
                            sessionYear: authUser.sessionYear,
                            sectionId: authUser.deptId,
                            branchId: authUser.branchId,
                            isAllocated: 1
                        })
                    })
                    setCheckValue(updatedData);
                    setStudentData(updatedData)
                    setFilteredData(updatedData)
                })


            //setRememberUser(true);
        }
        else if (e.target.checked === false) {
            setCheckValue([]);
            axios.get(`${getExternalExamAllocationAPIURL}?acYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&classId=${classDivObj.classId}&divId=${classDivObj.divisionId}&extExamId=${examObj.id}`)
                .then((response) => {
                    setStudentData([])
                    setFilteredData([])
                    let updatedData = response.data;
                    response.data.map((e, index) => {
                        updatedData[index] = ({
                            ...e,
                            classId: classDivObj.classId,
                            divisionId: classDivObj.divisionId,
                            externalExamId : examObj.id,
                            sessionYear: authUser.sessionYear,
                            sectionId: authUser.deptId,
                            branchId: authUser.branchId,
                            isAllocated: 0
                        })
                    })
                    setCheckValue([]);
                    setStudentData(updatedData)
                    setFilteredData(updatedData)
                })
            //setRememberUser(false);
        }

    }


    //check all checboxes are checked or not
    const getChekedData = async (list) => {

        await axios(`${getExternalExamAllocationAPIURL}?acYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&classId=${classDivObj.classId}&divId=${classDivObj.divisionId}&extExamId=${examObj.id}`)
            .then(res => {

                let updatedData = res.data;
                (res.data).sort((a, b) => a.rollNo > b.rollNo ? 1 : -1).map((item, index) => {
                    updatedData[index] = ({
                        ...item,
                        classId: classDivObj.classId,
                        divisionId: classDivObj.divisionId,
                        externalExamId : examObj.id,
                        sessionYear: authUser.sessionYear,
                        sectionId: authUser.deptId,
                        branchId: authUser.branchId,
                        isAllocated: 1
                    })
                })
                const checkedData = list.length === updatedData.length && list.every((o, i) => Object.keys(o).length === Object.keys(updatedData[i]).length && Object.keys(o).every(k => o[k] === updatedData[i][k]))

                //console.log(checkedData);
                if (checkedData === true) {
                    setCheckAllSub(true);
                } else {
                    setCheckAllSub(false);
                }



            }).catch(error => {
                console.log(error)
            })
        //console.log("filteredData====="+JSON.stringify(filteredData));


    }

    const saveStudentList = (e) => {
        setLoaderOption(true);
        e.preventDefault();
        if (checkValue.length === 0) {
            const removedAllData = [{
                regCode: 0,
                classId: classDivObj.classId,
                divisionId: classDivObj.divisionId,
                externalExamId : examObj.id,
                sessionYear: authUser.sessionYear,
                sectionId: authUser.deptId,
                branchId: authUser.branchId,
                isAllocated: 0
            }]
            // console.log("removedAllData====" + JSON.stringify(removedAllData))
            axios.post(`${saveExternalExamAllocationAPIURL}`, removedAllData)
                .then((response) => {
                    if (response.data == "SAVED") {
                        setFilteredData([]);
                        setSearchTerm("");
                        setStudentData([]);
                        setTempData([]);
                        setClassDivObj(null);
                        setexamObj(null);
                        setLoaderOption(false);
                        toast.success("External Exam allocation successfully done.");
                    }

                })
                .catch(error => {
                    console.log("Update Fire Query: " + error)
                })
        }
        else {
            // console.log("Save====" + JSON.stringify(checkValue))
            axios.post(`${saveExternalExamAllocationAPIURL}`, checkValue)
                .then((response) => {
                    if (response.data == "SAVED") {
                        setFilteredData([]);
                        setSearchTerm("");
                        setStudentData([]);
                        setTempData([]);
                        setClassDivObj(null);
                        setexamObj(null);
                        setLoaderOption(false);
                        toast.success("External Exam allocation successfully done.");
                    }

                })
                .catch(error => {
                    console.log("Update Fire Query: " + error)
                })
        }

    }

    useEffect(() => {
        setFilteredData([]);
        let tempFilteredData =
            studentData.filter(
                (item) =>
                    item.regCode.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
                    item.rollNo.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
                    item.studentNameMr.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    item.studentNameEn.toLowerCase().includes(searchTerm.toLowerCase())
            )
        setFilteredData(tempFilteredData);
    }, [searchTerm])

    useEffect(() => {
        function handlekeydownEvent(event) {
            if (event.key === "Enter") {
                if (classDivObj !== null || classDivObj !== "") {
                    // console.log('Enter is pressed!');
                    event.preventDefault();
                    let btn = document.querySelector('.studWiseSubBtn')
                    if (btn !== null) {
                        btn.click();
                    }
                }
            }
        }

        document.addEventListener('keypress', handlekeydownEvent)
        return () => {
            document.removeEventListener('keypress', handlekeydownEvent)
        }
    }, []);


    if (loaderOption == true) {
        return <><Loader /></>
    }
    else {
        return (
            <>
                <TitleLabel titleId={titleId} />
                <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: "350px" }}>

                    <form onSubmit={getStudentDataForAllocation}>
                        <input type="hidden" value={id} onChange={(e) => setId(e.target.value)} name="" />

                        <div className='row my-2'>
                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Exam :</label>
                            <div className="col-sm-3">
                                <Autocomplete
                                    required={true}
                                    id="combo-box-demo"
                                    PaperComponent={({ children }) => (
                                        <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                    )}
                                    renderOption={(props, option) => {
                                        return (
                                            <li {...props} key={option.id}>
                                                {(authUser.branchMedium == 1) ? option.nameMr : option.nameEn}
                                            </li>
                                        );
                                    }}
                                    options={examData}
                                    onChange={(event, newValue) => {
                                        setexamObj(newValue);
                                        setStudentData([]);
                                        setTempData([]);
                                        setFilteredData([]);
                                        setClassDivObj(null);
                                    }}
                                    value={examObj}
                                    style={{ fontSize: '14px' }}
                                    getOptionLabel={option => (authUser.branchMedium == 1) ? option.nameMr : option.nameEn}
                                    size="small"
                                    renderInput={params => (
                                        <TextField {...params} margin="dense" label="Select Exam" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                    )}
                                />
                            </div>
                        </div>

                        <div className='row my-2'>
                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Class-Division:</label>
                            <div className="col-sm-3">
                                <Autocomplete
                                    required={true}
                                    id="combo-box-demo"
                                    PaperComponent={({ children }) => (
                                        <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                    )}
                                    options={classDivData}
                                    onChange={(event, newValue) => {
                                        setClassDivObj(newValue);
                                        setStudentData([]);
                                        setTempData([]);
                                        setFilteredData([]);
                                    }}
                                    value={classDivObj}
                                    style={{ fontSize: '14px' }}
                                    getOptionLabel={option => (authUser.branchMedium == 1) ? option.classNameMr + " " + option.divisionNameMr : option.classNameEn + " " + option.divisionNameEn}
                                    size="small"
                                    renderInput={params => (
                                        <TextField {...params} margin="dense" label="Select Class-Division" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                    )}
                                />
                            </div>

                            <div className="col-sm-4 mt-3">
                                <button type="submit" className="btn btn-sm btn-primary">View</button>
                            </div>
                        </div>
                    </form>

                    {(studentData == null || studentData == [] || studentData == "")
                        ?
                        <h4 className='mt-5'>No data found, Please select class-division & exam.</h4>
                        :
                        <div>
                            <div>
                                <div className='row col-sm-4 mb-1 mt-2' style={{ float: 'right' }}>
                                    <div>
                                        <input
                                            className='form-control form-control-sm'
                                            type="text"
                                            style={{ width: "250px", display: "block", float: "right", marginBottom: "6px", border: "1px solid #C2C1C1" }}
                                            placeholder="Search Here"
                                            onChange={(event) => { setFilteredData([]); setSearchTerm(event.target.value) }}
                                            value={searchTerm}
                                        />
                                    </div>
                                </div>
                                <form onSubmit={saveStudentList}>
                                    <div className='table-responsive mt-4' style={{ maxHeight: "500px" }}>
                                        <table className="table table-bordered">
                                            <thead className="" style={{ position: "sticky", zIndex: "2", overflow: "hidden", top: "0", backgroundColor: "#EAECEE", borderBottom: "2px solid #464de4" }}>
                                                <tr>
                                                    <th style={{ fontSize: "15px", fontWeight: "600" }} >Reg No</th>
                                                    <th style={{ fontSize: "15px", fontWeight: "600" }}>Student Name</th>
                                                    <th style={{ fontSize: "15px", fontWeight: "600" }} >Roll No</th>
                                                    {/* <th style={{ fontSize: "15px", fontWeight: "600" }} >Status</th> */}
                                                    <th style={{ fontSize: "15px", fontWeight: "600", margin: "0px", padding: "2px" }}>
                                                        <Checkbox sx={{ '& .MuiSvgIcon-root': { fontSize: 23 } }}
                                                            checked={checkAllSub}
                                                            onChange={(e) => checkAllSubjects(e)} /> Status</th>

                                                </tr>
                                            </thead>
                                            <tbody style={{ borderTop: "2px solid #464de4", borderBottom: "2px solid #464de4" }}>
                                                {filteredData.sort((a, b) => a.rollNo > b.rollNo ? 1 : -1).map((item, index) => {
                                                    return (

                                                        <tr key={index} className="table-sm">
                                                            <td style={{ fontSize: "15px", fontWeight: "500", paddingLeft: "10px" }} >{item.regCode}</td>
                                                            <td style={{ fontSize: "15px", fontWeight: "500" }} >{(authUser.branchMedium == 1) ? item.studentNameMr : item.studentNameEn}</td>
                                                            <td style={{ fontSize: "15px", fontWeight: "500", paddingLeft: "20px", width: "120px" }} >{item.rollNo}</td>
                                                            <td style={{ fontSize: "15px", fontWeight: "500", margin: "0px", padding: "0px" }}>


                                                                <Checkbox defaultChecked={(item.isAllocated == 1) ? true : false} sx={{ '& .MuiSvgIcon-root': { fontSize: 30 } }}
                                                                    onChange={(e) => {
                                                                        let val = e.target.checked;
                                                                        //console.log("checked")
                                                                        handleCheckChange(val, item.id, item.regCode, item.studentNameMr, item.studentNameEn, item.rollNo);
                                                                    }}
                                                                />


                                                            </td>
                                                        </tr>

                                                    )
                                                })}
                                            </tbody>
                                        </table>
                                    </div>

                                    <div className="row mt-3">
                                        <div className="col-sm-12">
                                            <button type="submit" className="studWiseSubBtn btn btn-sm"
                                                style={{
                                                    backgroundColor: "#045de9",
                                                    backgroundImage: "linear-gradient(315deg, #045de9 0%, #09c6f9 74%)",
                                                    fontWeight: "550"
                                                }}
                                            ><AddIcon fontSize="small" />Save Changes</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    }

                </div>
                <ToastContainer position="top-right" theme="colored" />
            </>

        )
    }


}

export default ExternalExamAllocation
