import React, { useState, useEffect } from 'react';
import TitleLabel from '../../CommonComponent/TitleLabel';
import axios from 'axios';
import Paper from "@mui/material/Paper";
import TextField from '@mui/material/TextField';
import Autocomplete from "@mui/material/Autocomplete";
import { ToastContainer, toast } from 'react-toastify';
import { useSelector } from "react-redux";
import { getClassDivisionURL } from '../../Administration/Services/PramotShiftStudentsAPIURL';
import AddIcon from '@mui/icons-material/Add';
import Loader from '../../CommonComponent/Loader';
import { getStudentListForBoardSeatNo, saveStudentBoardSeatNo } from '../Services/StudInternalSeatNoAPIURL';
import StudentBoardSeatNoInput from './StudBoardSeatNoTextBox/StudentBoardSeatNoInput';

const StudentBoardSeatNo = () => {
    const authUser = useSelector((state) => state.user.value);
    const titleId = "Board Seat No"

    const TableHeading = [{ label: 'Reg No', key: 'regNo' }, { label: `Student Name`, key: (authUser.branchMedium == 1) ? 'studentNameMr' : 'studentNameEn' }, { label: `Gender`, key: 'gender' }, { label: `Roll No`, key: 'rollNo' }, { label: `Seat No`, key: 'boardSeatNo' }];

    // loader on/off
    const [loaderOption, setLoaderOption] = useState(false);

    //Note: This below states are declared for Save & Update(Disabled/unDisabled)
    const saveBtnStyle = "btn btn-primary btn-sm";
    const updateBtnStyle = "btn btn-primary btn-sm";
    const displayNoneBtnStyle = "d-none";
    const [saveBtn, setSaveBtn] = useState(saveBtnStyle);
    const [updateBtn, setUpdateBtn] = useState(displayNoneBtnStyle);

    const [isAscending, setAscending] = useState(false);
    const [sortedBy, setSortedBy] = useState(null);

    const [id, setId] = useState('');
    const [classDivData, setClassDivData] = useState([]);
    const [classDivObj, setClassDivObj] = useState(null);
    const [filteredData, setFilteredData] = useState([]);
    const [data, setData] = useState([]);
    const [tempData, setTempData] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [prefix, setPrefix] = useState('');

    useEffect(() => {
        getData();
    }, [authUser]);

    const getData = async () => {
        if (authUser.deptId != null || authUser.branchId != null) {
            await axios(`${getClassDivisionURL}?sid=${authUser.deptId}&bid=${authUser.branchId}`)
                .then(res => {
                    setClassDivData(res.data);
                })
        }
    }

    const getStudentListForSeatNo = async (cId, dId) => {
        setLoaderOption(true);
        setData([]);
        setFilteredData([]);
        setTempData([]);
        setSearchTerm("");
        setAscending(false);
        setSortedBy(null);
        setPrefix('')

        //console.log(`${getStudentListForBoardSeatNo}?classId=${cId}&divId=${dId}&sessionYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}`)
        await axios(`${getStudentListForBoardSeatNo}?classId=${cId}&divId=${dId}&sessionYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}`)
            .then(res => {
                //console.log(JSON.stringify(res.data))              
                if (res.data == "NOTFOUND") {
                    setLoaderOption(false);
                    toast.warn("Students are not allocated for this class-division")
                }
                else {
                    setData(res.data);
                    setFilteredData(res.data);
                    setTempData(res.data);

                    let getPrefix = res.data.filter(e => e.boardSeatNo != 0)

                    getPrefix.filter(e => {
                        if (e.prefix != null || e.prefix != '') {
                            setPrefix(e.prefix)
                        }
                    })

                    // if (prefix != '') {
                    //     let updatedData = res.data
                    //     updatedData.map((e, index) => {
                    //         updatedData[index] = ({ ...e, prefix: prefix })
                    //     })
                    //     setData(updatedData);
                    //     setFilteredData(updatedData);
                    //     setTempData(updatedData);
                    // }

                    setLoaderOption(false);
                }

            })
    }

    const [seatNum, setSeatNum] = useState('');
    const [regNum, setRegNum] = useState('');

    const saveSeatNo = (e) => {
        setLoaderOption(true);
        e.preventDefault();

        const newStudData = data.filter(e => e.boardSeatNo != 0)

        //console.log("seatNum=" + seatNum + "regNum=" + regNum)

        const snoNew = newStudData.find((item, ind) => {
            if (item.boardSeatNo == seatNum && item.regNo !== regNum) {
                return item;
            }
        })

        //console.log(snoNew)

        if (!snoNew) {
            //console.log(JSON.stringify(data))
            let newData = data.filter(e => e.boardSeatNo != 0)
            //console.log(newData)
            let updatedData = newData
            updatedData.map((e, index) => {
                updatedData[index] = ({ ...e, prefix: prefix })
            })
            let saveObj = []
            if (prefix != '') {
                saveObj = updatedData
            }
            else if (newData.length == 0) {
                saveObj = data
            }
            else {
                saveObj = newData
            }
            // console.log(JSON.stringify(saveObj))
            axios.post(`${saveStudentBoardSeatNo}`, saveObj)
                .then((response) => {
                    if (response.data == "SAVED") {
                        setFilteredData([]);
                        setData([]);
                        setTempData([]);
                        setClassDivObj(null);
                        setSearchTerm("");
                        setAscending(false);
                        setSortedBy(null);
                        setPrefix('');
                        setSeatNum('');
                        setRegNum('');
                        setLoaderOption(false);
                        toast.success("Board seat number updation successfully done.");
                    }

                })
                .catch(error => {
                    console.log("Update Fire Query: " + error)
                })
            //setLoaderOption(false);
        }
        else {
            setLoaderOption(false);
            //console.log(JSON.stringify(data))  
            // setSeatNum('');
            // setRegNum('');
            toast.warn("Seat No " + seatNum + " already exists.")
        }
    }

    const onSort = (key, isAction) => {

        if (!isAction) {
            setSortedBy(key)
            if (isAscending) setFilteredData([...data.sort((a, b) => a[key].toString().toLowerCase() < b[key].toString().toLowerCase() ? 1 : -1)])
            else setFilteredData([...data.sort((a, b) => a[key].toString().toLowerCase() > b[key].toString().toLowerCase() ? 1 : -1)])
            setAscending(!isAscending)
            //console.log("Data============" + JSON.stringify(data))

        }
        setFilteredData(data);
        setData(data);
        getSortedData(data, key, isAction);
    }

    //for table sorting
    const getSortedData = async (list, key, isAction) => {

        await axios(`${getStudentListForBoardSeatNo}?classId=${classDivObj.classId}&divId=${classDivObj.divisionId}&sessionYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}`)
            .then(res => {
                //console.log(res); 
                if (!isAction) {
                    setSortedBy(key)
                    if (isAscending) setFilteredData([...(res.data).sort((a, b) => a[key].toString().toLowerCase() < b[key].toString().toLowerCase() ? 1 : -1)])
                    else setFilteredData([...(res.data).sort((a, b) => a[key].toString().toLowerCase() > b[key].toString().toLowerCase() ? 1 : -1)])
                    setAscending(!isAscending)
                    //console.log("Data============" + JSON.stringify(data))
                }
                setFilteredData([]);
                const checkedData = list.length === data.length && list.every((o, i) => Object.keys(o).length === Object.keys(data[i]).length && Object.keys(o).every(k => o[k] === data[i][k]))
                //setData(checkedData);
                //console.log("checkedData......" + JSON.stringify(checkedData))
                if (checkedData == true) {
                    setFilteredData(data);
                }
            })
    }

    useEffect(() => {
        setFilteredData([])
        let tempFilteredData =
            data.filter((item) =>
                item.studentNameMr.toLowerCase().includes(searchTerm.toLowerCase()) ||
                item.studentNameEn.toLowerCase().includes(searchTerm.toLowerCase()) ||
                item.gender.toLowerCase().includes(searchTerm.toLowerCase()) ||
                item.regNo.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
                item.boardSeatNo.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
                item.rollNo.toString().toLowerCase().includes(searchTerm.toLowerCase()))
        setFilteredData(tempFilteredData)
        //setFilteredData(data.filter((item) => item.studentNameMr.toLowerCase().includes(searchTerm.toLowerCase()) || item.categoryNameMr.toLowerCase().includes(searchTerm.toLowerCase()) || item.genderLabel.toLowerCase().includes(searchTerm.toLowerCase()) || item.regCode.toString().toLowerCase().includes(searchTerm.toLowerCase()) || item.rollNo.toString().toLowerCase().includes(searchTerm.toLowerCase())))
    }, [searchTerm])

    useEffect(() => {
        let updatedData = tempData;

        tempData.map((e, index) => {
            if (e.regNo == regNum) {
                updatedData[index] = ({ ...e, boardSeatNo: seatNum })
            }
        })
        //console.log(JSON.stringify(tempData))
        setData(tempData);
        if (searchTerm == "") {
            setFilteredData(tempData);
        }
    }, [regNum, searchTerm])

    const handelkeyPress = (event) => {
        function handlekeydownEvent(event) {
            if (event.key === "Enter") {
                if (data !== null || data != "") {
                    event.preventDefault();
                    let btn = document.querySelector('.myBoardSeatNoBtn')
                    if (btn !== null) {
                        //console.log('Enter is pressed!');
                        btn.click();
                    }
                }
            }
        }

        document.addEventListener('keypress', handlekeydownEvent)
        return () => {
            document.removeEventListener('keypress', handlekeydownEvent)
        }

    };

    useEffect(() => {
        //console.log('useEffect Called');
        document.addEventListener("keypress", handelkeyPress);

        return () => {
            // remove the event handelkeyPress
            document.removeEventListener("keypress", handelkeyPress);
        };
    }, []);


    if (loaderOption == true) {
        return <Loader />
    }
    else {
        return (
            <>
                <TitleLabel titleId={titleId} />
                <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: "350px" }}>

                    <input type="hidden" value={id} onChange={(e) => setId(e.target.value)} name="" />

                    <div className="row">
                        {/* <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Class-Division:</label> */}
                        <div className='col-sm-3'>
                            <Autocomplete
                                PaperComponent={({ children }) => (
                                    <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                )}
                                id="combo-box-demo"
                                onChange={(e, newValue) => {
                                    setClassDivObj(newValue);
                                    if (newValue != null) {
                                        getStudentListForSeatNo(newValue.classId, newValue.divisionId);
                                    }
                                }}
                                value={classDivObj}
                                options={classDivData}
                                style={{ fontSize: '14px' }}
                                getOptionLabel={option => (authUser.branchMedium == 1) ? option.classNameMr + " " + option.divisionNameMr : option.classNameEn + " " + option.divisionNameEn}
                                size="small"
                                renderInput={params => (
                                    <TextField {...params} label="Select Class-Division" margin='dense' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                )}
                            />
                        </div>
                    </div>

                    {
                        (data == null || data == "")
                            ?
                            <h4 className='mt-5'>No records found, Select Class-Division</h4>
                            :
                            <div>
                                <div style={{ float: "right", padding: "15px", }}>
                                    <div className='my-2'>
                                        <label className='' style={{ width: "100px", fontSize: "15px", fontWeight: "600", marginTop: "16px" }} >Prefix :</label>
                                        <TextField
                                            //required
                                            onKeyDown={(e) => (e.key === " " && prefix.length === 0) ? e.preventDefault() : ""}
                                            fullWidth
                                            margin='dense'
                                            value={prefix}
                                            onChange={e => setPrefix(e.target.value)}
                                            style={{ width: "150px" }}
                                            autoComplete='off'
                                            size='small'
                                            id="outlined-basic"
                                            label="Prefix"
                                            variant="outlined"
                                            onKeyPress={e => (e.key == "Enter") ? prefix : ''}
                                            InputLabelProps={{ style: { fontSize: 14 } }}
                                        />
                                    </div>
                                    <div className='' >
                                        <input
                                            className='form-control form-control-sm'
                                            type="text"
                                            style={{ width: "250px", display: "block", border: "1px solid #C2C1C1" }}
                                            placeholder="Search Here"
                                            onChange={(event) => { setFilteredData([]); setSearchTerm(event.target.value) }}
                                            value={searchTerm}
                                        />
                                    </div>
                                </div>


                                <form onSubmit={saveSeatNo}>
                                    <div className='table-responsive mt-4' style={{ maxHeight: "480px" }}>
                                        <table className="table table-bordered">
                                            <thead className="" style={{ position: "sticky", zIndex: "2", overflow: "hidden", top: "0", backgroundColor: "#EAECEE", borderBottom: "2px solid #464de4" }}>
                                                <tr style={{ borderBottom: "2px solid #464de4", position: "sticky", overflow: "hidden" }}>
                                                    {TableHeading.map((item, index) => {
                                                        return (
                                                            <th style={{ fontSize: "14px", fontWeight: "600" }} onClick={() => { onSort(item.key, item.isAction) }} key={index} scope="col">{`${item.label} ${sortedBy === item.key ? isAscending ? '↑' : '↓' : ''}`}</th>
                                                        )
                                                    })}

                                                </tr>
                                            </thead>
                                            <tbody style={{ borderTop: "2px solid #464de4", borderBottom: "2px solid #464de4" }}>
                                                {filteredData.map((item, index) => {
                                                    return (
                                                        <tr key={index} className="table-sm">
                                                            <td style={{ fontSize: "15px", fontWeight: "500", paddingLeft: "20px", width: "120px" }} >{item.regNo}</td>
                                                            <td style={{ fontSize: "15px", fontWeight: "500" }} >{(authUser.branchMedium == 1) ? item.studentNameMr : item.studentNameEn}</td>
                                                            <td style={{ fontSize: "15px", fontWeight: "500", paddingLeft: "20px", width: "120px" }} >{item.gender}</td>
                                                            <td style={{ fontSize: "15px", fontWeight: "500", paddingLeft: "20px", width: "120px" }} >{item.rollNo}</td>

                                                            <StudentBoardSeatNoInput initialText={item.boardSeatNo} checkData={data} rCode={item.regNo}
                                                                onChange={(value) => {
                                                                    let updatedData = filteredData;
                                                                    updatedData[index] = ({ ...item, boardSeatNo: value });
                                                                    setFilteredData(updatedData);
                                                                    setData(updatedData);
                                                                    setSeatNum(value);
                                                                    setRegNum(updatedData[index].regNo)
                                                                    //console.log(JSON.stringify(updatedData));

                                                                    //setStudentCounter(studentCounter + 1)
                                                                }} />

                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>
                                    </div>

                                    <div className="row mt-3">
                                        <div className="col-sm-12">
                                            <button type="submit" className="myBoardSeatNoBtn btn btn-sm btn-primary"><AddIcon fontSize="small" />Save Changes</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                    }

                </div>
                {/* <ToastContainer position="top-right" theme="colored" /> */}
            </>
        )
    }


}

export default StudentBoardSeatNo