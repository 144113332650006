import { Autocomplete, Paper, TextField } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import TitleLabel from '../../CommonComponent/TitleLabel';
import CircularProgress from '@mui/material/CircularProgress';
import Loader from '../../CommonComponent/Loader';
import { DatePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import MedicalVisitTable from './MedicalVisitTable';
import { getStudentListByBranchAPIURL } from '../../CommonServices/StudentRegistrationAPIURL';
import { getMedicalVisitAPIURL, saveMedicalVisitAPIURL, updateMedicalVisitAPIURL } from '../Services/MedicalVisitAPIURL';
import moment from 'moment';
import { getStudentListForHostelByBid } from '../Services/MaterialAllocationAPIURL';

function sleep(delay = 0) {
    return new Promise((resolve) => {
        setTimeout(resolve, delay);
    });
}


const MedicalVisitForm = () => {
    const titleId = 'Medical Visit';
    const authUser = useSelector((state) => state.user.value);
    const [loaderOption, setLoaderOption] = useState(false);

    const TableHeading = [
        { label: 'ID', key: 'id' },
        { label: `Student`, key: (authUser.branchMedium == 1) ? 'studentNameMr' : 'studentNameEn' },
        { label: `Date`, key: 'date' },
        { label: `Reason`, key: 'reason' },
        { label: `Solution`, key: 'solution' },
        { label: 'Edit', value: 'edit', isAction: true },
        { label: 'Delete', value: 'delete', isAction: true }];

    //Note: This below states are declared for Save & Update(Disabled/unDisabled)
    const saveBtnStyle = "medicalVisitSaveBtn btn btn-primary btn-sm";
    const updateBtnStyle = "medicalVisitUpdateBtn btn btn-primary btn-sm";
    const displayNoneBtnStyle = "d-none";
    const [saveBtn, setSaveBtn] = useState(saveBtnStyle);
    const [updateBtn, setUpdateBtn] = useState(displayNoneBtnStyle);

    const [id, setId] = useState('');
    const [studentData, setStudentData] = useState([]);
    const [studentObj, setStudentObj] = useState(null);
    const [date, setDate] = useState(null);
    const [reason, setReason] = useState('');
    const [solution, setSolution] = useState('');
    const [medicine, setMedicine] = useState('');
    const [otherConsult, setOtherConsult] = useState('');
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [readOnlyField, setReadOnlyField] = useState(false);

    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState([]);
    const loading = open && studentData.length === 0;

    useEffect(() => {
        let active = true;

        if (!loading) {
            return undefined;
        }

        (async () => {
            await sleep(1e3); // For demo purposes.

            if (active) {
                setOptions([...studentData]);
            }
        })();

        return () => {
            active = false;
        };
    }, [loading]);

    useEffect(() => {
        if (!open) {
            setOptions([]);
        }
    }, [open]);

    useEffect(() => {
        getData();
    }, [authUser])

    const getData = async () => {
        setLoaderOption(true);
      
        if (authUser.sessionYear != '' && authUser.branchId != null) {
            //console.log(`${getStudentListByBranchAPIURL}?bid=${authUser.branchId}`)
            await axios.get(`${getStudentListForHostelByBid}?year=${authUser.sessionYear}&bid=${authUser.branchId}`)
                .then(res => {
                    const presentStudentList = res.data.filter(e => e.currentStatus == 0)
                    setStudentData(presentStudentList);
                    setLoaderOption(false);
                }).catch(err => {
                    setLoaderOption(false);
                    console.log('get student list err' + err)
                })

            await axios.get(`${getMedicalVisitAPIURL}?bid=${authUser.branchId}&year=${authUser.sessionYear}`)
                .then(res => {
                    setData(res.data);
                    setFilteredData(res.data);
                    setLoaderOption(false);
                }).catch(err => {
                    console.log('get medical visit err' + err)
                    setLoaderOption(false);
                })
        }
    }

    const clearData = () => {
        setSaveBtn(saveBtnStyle);
        setUpdateBtn(displayNoneBtnStyle);
        setStudentObj(null);
        setDate(null);
        setReason('');
        setSolution('');
        setMedicine('');
        setOtherConsult('');
        setReadOnlyField(false);
    }

    const saveBtnClick = async () => {
        setLoaderOption(true);
        if (moment(date).format('DD/MM/YYYY') != 'Invalid date') {
            const saveObj = {
                "regNo": studentObj.regCode,
                "date": moment(date).format('YYYY-MM-DD'),
                "reason": reason,
                "solution": solution,
                "medicine": medicine,
                "otherConsult": otherConsult,
                "sessionYear": authUser.sessionYear,
                "branchId": authUser.branchId
            }
            await axios.post(saveMedicalVisitAPIURL, saveObj)
                .then(res => {
                    if (res.data == "SAVED") {
                        getData();
                        clearData();
                        setLoaderOption(false);
                        toast.success("Saved successfully.")
                    }
                    // else if (res.data == "ALREADYEXISTS") {
                    //     setLoaderOption(false);
                    //     toast.warn("Allocation for this student is already done.")
                    // }
                    else {
                        setLoaderOption(false);
                        toast.error("Medical visit save failed.")
                    }
                }).catch(err => {
                    console.log("Medical visit save err" + err);
                    setLoaderOption(false);
                    toast.error("Something went wrong, please check.")
                })
        }
        else {
            setLoaderOption(false);
            toast.error('Improper date, please check.')
        }
    }

    const updateBtnClick = async () => {
        setLoaderOption(true);
        if (moment(date).format("DD/MM/YYYY") != 'Invalid date') {
            const updateObj = {
                "id": id,
                "date": moment(date).format('YYYY-MM-DD'),
                "reason": reason,
                "solution": solution,
                "medicine": medicine,
                "otherConsult": otherConsult,
                "sessionYear": authUser.sessionYear,
                "branchId": authUser.branchId
            }
            await axios.put(updateMedicalVisitAPIURL, updateObj)
                .then(res => {
                    if (res.data == "UPDATED") {
                        getData();
                        clearData();
                        setLoaderOption(false);
                        toast.success("Updated successfully.")
                    }
                    // else if (res.data == "ALREADYEXISTS") {
                    //     setLoaderOption(false);
                    //     toast.warn("Allocation for this student is already done.")
                    // }
                    else {
                        setLoaderOption(false);
                        toast.error("Medical visit update failed.")
                    }
                }).catch(err => {
                    console.log("Medical visit update err" + err);
                    setLoaderOption(false);
                    toast.error("Something went wrong, please check.")
                })
        }
        else {
            setLoaderOption(false);
            toast.error('Improper date, please check.')
        }
    }

    const state = {
        button: 1
    }

    const onSubmit = e => {
        e.preventDefault();
        if (state.button == 1) {
            saveBtnClick();
        }
        if (state.button == 2) {
            updateBtnClick();
        }
    }

    useEffect(() => {

        function handlekeydownEvent(event) {
            if (event.key === "Enter") {
                // console.log("Enter is pressed!")
                event.preventDefault();

                let btn1 = document.querySelector('.medicalVisitSaveBtn')
                if (btn1 !== null) {
                    btn1.click();
                }
                let btn2 = document.querySelector('.medicalVisitUpdateBtn')
                if (btn2 !== null) {
                    btn2.click();
                }
            }
        }
        document.addEventListener('keypress', handlekeydownEvent)
        return () => {
            document.removeEventListener('keypress', handlekeydownEvent)
        }

    }, []);

    return (
        <>
            {
                (loaderOption == true)
                    ?
                    <Loader />
                    :
                    <>
                        <TitleLabel titleId={titleId} />
                        <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: '350px' }}>
                            {/* Form Data start */}
                            <form onSubmit={onSubmit}>
                                <div className="form-data mt-1">
                                    <input type="hidden" value={id} onChange={(e) => setId(e.target.value)} name="" />

                                    <div className="row">
                                        <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Date :</label>
                                        <div className='col-sm-3'>
                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                <DatePicker
                                                    label="Date"
                                                    value={date}
                                                    onChange={(newValue) => {
                                                        setDate(newValue);
                                                    }}
                                                    onKeyPress={(e) => (e.key == "Enter") ? date : ""}
                                                    inputFormat="dd/MM/yyyy"
                                                    renderInput={(params) =>
                                                        <TextField
                                                            {...params}
                                                            margin='dense'
                                                            variant="outlined"
                                                            InputLabelProps={{ style: { fontSize: 14 } }}
                                                            required
                                                            fullWidth
                                                            autoComplete='off'
                                                            size="small" />}
                                                />
                                            </LocalizationProvider>
                                        </div>

                                        <label className="col-sm-1" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Student :</label>
                                        <div className='col-sm-4'>
                                            <Autocomplete
                                                id="combo-box-demo"
                                                open={open}
                                                onOpen={() => {
                                                    setOpen(true);
                                                }}
                                                onClose={() => {
                                                    setOpen(false);
                                                }}
                                                loading={loading}
                                                options={studentData}
                                                PaperComponent={({ children }) => (
                                                    <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                                )}
                                                onChange={(event, newValue) => {
                                                    setStudentObj(newValue);
                                                    setReason('');
                                                    setSolution('');
                                                    setMedicine('');
                                                    setOtherConsult('');
                                                }}
                                                readOnly={(readOnlyField == true) ? true : false}
                                                onKeyPress={(e) => (e.key == "Enter") ? studentObj : ""}
                                                value={studentObj}
                                                style={{ fontSize: '14px' }}
                                                getOptionLabel={option => (authUser.branchMedium == 1) ? option.regCode + " - " + option.studentNameMr : option.regCode + " - " + option.studentNameEn}
                                                renderOption={(props, option) => (
                                                    <div className='' {...props}>
                                                        <div className=''>
                                                            {(authUser.branchMedium == 1) ? option.regCode + " - " + option.studentNameMr + "  " + option.currentClassMr + "  " + option.currentDivisionMr : option.regCode + " - " + option.studentNameEn + "  " + option.currentClassEn + "  " + option.currentDivisionEn}
                                                        </div>

                                                    </div>
                                                )}
                                                size="small"
                                                renderInput={params => (
                                                    <TextField {...params}
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            endAdornment: (
                                                                <React.Fragment>
                                                                    {loading ? <CircularProgress color="inherit" size={20} style={{ marginRight: "25px" }} /> : null}
                                                                    {params.InputProps.endAdornment}
                                                                </React.Fragment>
                                                            ),
                                                        }}
                                                        margin='dense' label="Select Student" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }}
                                                        required />
                                                )}
                                            />
                                        </div>
                                    </div>

                                    <div className="row my-2">
                                        <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Reason :</label>
                                        <div className='col-sm-7'>
                                            <TextField
                                                required
                                                fullWidth
                                                onKeyDown={(e) => (e.key === " " && reason.length === 0) ? e.preventDefault() : ""}
                                                autoComplete='off'
                                                size='small'
                                                id="outlined-basic"
                                                label="Reason"
                                                variant="outlined"
                                                margin='dense'
                                                value={reason}
                                                onChange={e => setReason(e.target.value)}
                                                onKeyPress={(e) => (e.key == "Enter") ? reason : ""}
                                                InputLabelProps={{ style: { fontSize: 14 } }}
                                            />
                                        </div>
                                    </div>

                                    <div className="row my-2">
                                        <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Solution :</label>
                                        <div className='col-sm-7'>
                                            <TextField
                                                required
                                                fullWidth
                                                onKeyDown={(e) => (e.key === " " && solution.length === 0) ? e.preventDefault() : ""}
                                                autoComplete='off'
                                                size='small'
                                                id="outlined-basic"
                                                label="Solution"
                                                variant="outlined"
                                                margin='dense'
                                                value={solution}
                                                onChange={e => setSolution(e.target.value)}
                                                onKeyPress={(e) => (e.key == "Enter") ? solution : ""}
                                                InputLabelProps={{ style: { fontSize: 14 } }}
                                            />
                                        </div>
                                    </div>

                                    <div className="row my-2">
                                        <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Medicine :</label>
                                        <div className='col-sm-7'>
                                            <TextField
                                                required
                                                fullWidth
                                                onKeyDown={(e) => (e.key === " " && medicine.length === 0) ? e.preventDefault() : ""}
                                                autoComplete='off'
                                                size='small'
                                                id="outlined-basic"
                                                label="Medicine"
                                                variant="outlined"
                                                margin='dense'
                                                value={medicine}
                                                onChange={e => setMedicine(e.target.value)}
                                                onKeyPress={(e) => (e.key == "Enter") ? medicine : ""}
                                                InputLabelProps={{ style: { fontSize: 14 } }}
                                            />
                                        </div>
                                    </div>

                                    <div className="row my-2">
                                        <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Other Consult :</label>
                                        <div className='col-sm-7'>
                                            <TextField
                                                // required
                                                fullWidth
                                                onKeyDown={(e) => (e.key === " " && otherConsult.length === 0) ? e.preventDefault() : ""}
                                                autoComplete='off'
                                                size='small'
                                                id="outlined-basic"
                                                label="Other Consult"
                                                variant="outlined"
                                                margin='dense'
                                                value={otherConsult}
                                                onChange={e => setOtherConsult(e.target.value)}
                                                onKeyPress={(e) => (e.key == "Enter") ? otherConsult : ""}
                                                InputLabelProps={{ style: { fontSize: 14 } }}
                                            />
                                        </div>
                                    </div>

                                    <div className="row mt-3">
                                        <div className="col-sm-4 offset-sm-2">
                                            <button type='submit' onClick={() => (state.button = 1)} className={saveBtn}>Save</button>
                                            <button type='submit' onClick={() => (state.button = 2)} className={updateBtn}>Update</button>
                                            <button type='button' onClick={() => clearData()} className='btn btn-sm btn-danger ml-2'>Cancel</button>
                                        </div>
                                    </div>
                                </div>
                            </form>

                            <div className="mt-3">
                                <MedicalVisitTable
                                    TableHeading={TableHeading}
                                    data={data}
                                    filteredData={filteredData}
                                    setFilteredData={setFilteredData}
                                    getData={getData}
                                    setId={setId}
                                    setStudentObj={setStudentObj}
                                    studentData={studentData}
                                    setDate={setDate}
                                    setReason={setReason}
                                    setSolution={setSolution}
                                    setMedicine={setMedicine}
                                    setOtherConsult={setOtherConsult}
                                    setReadOnlyField={setReadOnlyField}
                                    clearData={clearData}
                                    setSaveBtn={setSaveBtn}
                                    setUpdateBtn={setUpdateBtn}
                                    updateBtnStyle={updateBtnStyle}
                                    displayNoneBtnStyle={displayNoneBtnStyle}
                                />
                            </div>
                        </div>
                    </>
            }
        </>
    )
}

export default MedicalVisitForm