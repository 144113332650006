import React, { useEffect, useState } from 'react'
import Button from 'react-bootstrap/Button'
import ArrowCircleDownOutlinedIcon from '@mui/icons-material/ArrowCircleDownOutlined'
import './aluminiReg.css'
import logo from './logo.png'

import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import { BaseURLInstituteMaster } from '../CommonServices/InstituteMasterAPIURL'
import axios from 'axios'
import { Paper } from '@mui/material'
import { saveAluminiRegistrationAPIURL } from './AluminiRegistrationAPIURL';
import { ToastContainer, toast } from 'react-toastify'
import Loader from '../CommonComponent/Loader'
import { useSelector } from 'react-redux'
import moment from 'moment'

const AluminiRegistration = () => {
  const [fullscreen, setFullscreen] = useState(true)
  const [show, setShow] = useState(false)
  const authUser = useSelector((state) => state.user.value);

  const [instituteData, setInstituteData] = useState([]);
  const [instituteObj, setInstituteObj] = useState(null);
  const [firstName, setFirstName] = useState('');
  const [middleName, setMiddleName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [mobile, setMobile] = useState('');
  const [yrOfPassing, setYrOfPassing] = useState('');
  const [birthDate, setBirthDate] = useState('');
  const [profession, setProfession] = useState('');
  const [maritalStatus, setMaritalStatus] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [pincode, setPinCode] = useState('');
  const [address, setAddress] = useState('');

  const [loaderOption, setLoaderOption] = useState(false);

  useEffect(() => {
    getData();
  }, [])


  const getData = async () => {
    await axios.get(BaseURLInstituteMaster)
      .then(res => {
        setInstituteData(res.data);
      }).catch(err => {
        console.log("Get institute err " + err)
      })
  }

  const handleLinkClick = (event) => {
    event.preventDefault()
    const targetElement = document.getElementById('formCont')
    targetElement.scrollIntoView({ behavior: 'smooth' })
  }

  // One time slot every 30 minutes.
  const timeSlots = Array.from(new Array(24 * 2)).map(
    (_, index) =>
      `${index < 20 ? '0' : ''}${Math.floor(index / 2)}:${index % 2 === 0 ? '00' : '30'
      }`,
  )

  const clearData = () => {
    setInstituteObj(null);
    setFirstName('');
    setMiddleName('');
    setLastName('');
    setEmail('');
    setMobile('');
    setYrOfPassing('');
    setBirthDate('');
    setProfession('');
    setMaritalStatus('');
    setCity('');
    setState('');
    setPinCode('');
    setAddress('');
  }

  const saveAluminiReg = async (e) => {
    e.preventDefault();
    //setLoaderOption(true);
    const saveObj = {
      "firstName": firstName,
      "middleName": middleName,
      "lastName": lastName,
      "email": email,
      "mobileNo": mobile,
      "yearOfPassing": yrOfPassing,
      "dateOfBirth": birthDate,
      "profession": profession,
      "maritalStatus": maritalStatus,
      "city": city,
      "state": state,
      "pinCode": pincode,
      "address": address,
      "sessionYear": moment(new Date()).format("YYYY"),
      "branchId": instituteObj.id
    }
    // console.log(JSON.stringify(saveObj))
    await axios.post(saveAluminiRegistrationAPIURL, saveObj)
      .then(res => {
        if (res.data == "SAVED") {
          clearData();
          setLoaderOption(false);
          toast.success("Registration done successfully.")
        }
        else if (res.data == "ALREADYEXISTS") {
          setLoaderOption(false);
          toast.warn("Registration with the same details already done.")
        }
        else {
          setLoaderOption(false);
          toast.error("Registration failed.")
        }
      }).catch(err => {
        setLoaderOption(false);
        console.log("Alumini reg save err " + err);
        toast.error("Something went wrong, please check.")
      })
  }

  return (
    <>
      {
        (loaderOption == true)
          ?
          <Loader />
          :
          <div className="wrapperForm">
            <div className="wrapperInner">
              {/* <div className='row'><img src={logo} style={{ width: '100px' }} /></div> */}
              <img src={logo} style={{ width: '100px', marginBottom: 10 }} />
              <div className="row text-center">
                <span className="headerTextCont">
                  <h1 className="headerText" style={{ color: '#fff' }}>
                    ALUMINI
                  </h1>
                  <strong style={{ color: '#fff', fontWeight: '600' }}>
                    REGISTRATION
                  </strong>
                </span>
              </div>
              {/* <ArrowCircleDownOutlinedIcon className="arrowIcon" fontSize='22px' /> */}
              <center>
                <svg className="arrow" onClick={handleLinkClick}>
                  <path className="a1" d="M0 0 L30 22 L60 0"></path>
                  <path className="a2" d="M0 20 L30 42 L60 20"></path>
                  <path className="a3" d="M0 40 L30 62 L60 40"></path>
                </svg>
              </center>
            </div>

            <div className="formDesign" id="formCont">
              <div className="container pt-4">
                <div className="row">
                  <h1
                    style={{
                      textTransform: 'uppercase',
                      marginTop: 10,
                      fontWeight: '700',
                    }}
                  >
                    Alumini Registration
                  </h1>
                  <hr style={{ backgroundColor: '#D5D8DC' }} />

                  <form onSubmit={saveAluminiReg}>
                    <div className="form-group">
                      <label style={{ fontWeight: 'bold' }}>
                        Select School / College
                      </label>
                      <Autocomplete
                        options={instituteData}
                        fullWidth
                        value={instituteObj}
                        onChange={(event, newValue) => {
                          setInstituteObj(newValue);
                        }}
                        getOptionLabel={(option) => option.schoolNameMr + " / " + option.schoolNameEn}
                        PaperComponent={({ children }) => (
                          <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            // label="Select School / College"
                            margin="dense"
                            size="small"
                            className="form-control"
                            placeholder="Select School / College"
                            required
                          />
                        )}
                      />
                    </div>
                    <div className="row">
                      <div className="form-group col-md-4">
                        <label style={{ fontWeight: 'bold' }}>First Name</label>
                        <input
                          type="text"
                          required
                          className="form-control"
                          aria-describedby="fname"
                          placeholder="Enter first name"
                          value={firstName}
                          onChange={(e) => setFirstName(e.target.value)}
                        />
                      </div>

                      <div className="form-group col-md-4">
                        <label style={{ fontWeight: 'bold' }}>Middle Name</label>
                        <input
                          type="text"
                          required
                          className="form-control"
                          aria-describedby="mname"
                          placeholder="Enter middle name"
                          value={middleName}
                          onChange={(e) => setMiddleName(e.target.value)}
                        />
                      </div>

                      <div className="form-group col-md-4">
                        <label style={{ fontWeight: 'bold' }}>Last Name</label>
                        <input
                          type="text"
                          required
                          className="form-control"
                          aria-describedby="lname"
                          placeholder="Enter last name"
                          value={lastName}
                          onChange={(e) => setLastName(e.target.value)}
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="form-group col-md-4">
                        <label style={{ fontWeight: 'bold' }}>Email Id</label>
                        <input
                          type="email"
                          className="form-control"
                          aria-describedby="email"
                          placeholder="Enter email id"
                          required
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>

                      <div className="form-group col-md-4">
                        <label style={{ fontWeight: 'bold' }}>Mobile</label>
                        <input
                          type="number"
                          onBlur={(e) => e.target.blur()}
                          className="form-control"
                          aria-describedby="mobile"
                          placeholder="Enter mobile no"
                          value={mobile}
                          onChange={(e) => setMobile(e.target.value)}
                        />
                      </div>

                      <div className="form-group col-md-4">
                        <label style={{ fontWeight: 'bold' }}>
                          Year Of Passing
                        </label>
                        <input
                          type="text"
                          required
                          className="form-control"
                          aria-describedby="yrOfPassing"
                          placeholder="yyyy-yy"
                          value={yrOfPassing}
                          onChange={(e) => setYrOfPassing(e.target.value)}
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="form-group col-md-4">
                        <label style={{ fontWeight: 'bold' }}>Date of Birth</label>
                        <input
                          type="date"
                          className="form-control"
                          aria-describedby="birthDate"
                          required
                          value={birthDate}
                          onChange={(e) => setBirthDate(e.target.value)}
                        />
                      </div>

                      <div className="form-group col-md-4">
                        <label style={{ fontWeight: 'bold' }}>Profession</label>
                        <input
                          type="text"
                          className="form-control"
                          aria-describedby="profession"
                          placeholder="Enter profession"
                          value={profession}
                          onChange={(e) => setProfession(e.target.value)}
                        />
                      </div>

                      <div className="form-group col-md-4">
                        <label style={{ fontWeight: 'bold' }}>Marital Status</label>
                        <select
                          className="form-control"
                          id="exampleFormControlSelect2"
                          value={maritalStatus}
                          onChange={(e) => setMaritalStatus(e.target.value)}
                        >
                          <option value="">Select Option</option>
                          <option value="Married">Married</option>
                          <option value="Unmarried">Unmarried</option>
                        </select>
                      </div>
                    </div>

                    <div className="row">
                      <div className="form-group col-md-4">
                        <label style={{ fontWeight: 'bold' }}>City</label>
                        <input
                          type="text"
                          className="form-control"
                          aria-describedby="city"
                          placeholder="Enter city"
                          value={city}
                          onChange={(e) => setCity(e.target.value)}
                        />
                      </div>

                      <div className="form-group col-md-4">
                        <label style={{ fontWeight: 'bold' }}>State</label>
                        <input
                          type="text"
                          className="form-control"
                          aria-describedby="state"
                          placeholder="Enter state"
                          value={state}
                          onChange={(e) => setState(e.target.value)}
                        />
                      </div>

                      <div className="form-group col-md-4">
                        <label style={{ fontWeight: 'bold' }}>Pincode</label>
                        <input
                          type="number"
                          onBlur={(e) => e.target.blur()}
                          className="form-control"
                          name="pincode"
                          aria-describedby="Pincode"
                          placeholder="Enter pincode"
                          value={pincode}
                          onChange={(e) => setPinCode(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="form-group">
                      <label style={{ fontWeight: 'bold' }}>Address:</label>
                      <textarea
                        className="form-control"
                        rows="5"
                        id="address"
                        placeholder='Enter Address'
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                      ></textarea>
                    </div>

                    <input
                      type="submit"
                      className="btn btn-primary form-control mb-3"
                      value={'REGISTER'}
                    />
                  </form>
                </div>
              </div>
            </div>

            <div className="footerCont">
              <strong style={{ fontWeight: '600', fontSize: '12px' }}>
                © 2023-24 सांगली शिक्षण संस्था
              </strong>{' '}
              <br />
              <strong style={{ fontWeight: '600', fontSize: '12px' }}>
                Managed By :
                <a href="https://technoaarv.com" target="_blank">
                  TechnoAarv Solution
                </a>
              </strong>
            </div>
          </div>
      }
      <ToastContainer position="top-right" theme="colored" />
    </>
  )
}

export default AluminiRegistration
