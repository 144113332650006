import { BaseURL } from "../../../CommonServices/APIURL";

// /Post
// http://192.168.29.102:8081/institute-sss/institute/sss/sansthaVoting/saveVotingCityMaster

// {
//     "cityId":1,
//     "kendraNo":2,
//     "kendraName":"kendraName"
// }
export const saveCityMasterAPIURL = BaseURL + "sansthaVoting/saveVotingCityMaster";

//http://192.168.29.102:8081/institute-sss/institute/sss/sansthaVoting/getVotingCityMasterList
export const getCityMasterAPIURL = BaseURL + "sansthaVoting/getVotingCityMasterList";

//http://192.168.29.102:8081/institute-sss/institute/sss/sansthaVoting/updateVotingCityMaster
export const updateCityMasterAPIURL = BaseURL + "sansthaVoting/updateVotingCityMaster";

//http://192.168.29.102:8081/institute-sss/institute/sss/sansthaVoting/deleteVotingCityMaster?id=3
export const deleteCityMasterAPIURL = BaseURL + "sansthaVoting/deleteVotingCityMaster";
