import React, { useState, useEffect } from 'react';
import axios from "axios";
import './Dashboard.css'
import TitleLabel from './CommonComponent/TitleLabel';

import boy from './assets/dashboard/boy.png';
import team from './assets/dashboard/team.png';
import girl from './assets/dashboard/girl.png';
import teamwork from './assets/dashboard/teamwork.png';
import lecture from './assets/dashboard/lecture.png';
import graduates from './assets/dashboard/graduates.png';
import { useSelector } from "react-redux";
import {
  getCardData, getStudentSummery, getCardDataForSanstha, getStudentSummeryForSanstha,
  getStudentsBdayListAPIURL, getEmployeeBdayListAPIURL, getStudCategorySummaryAPIURL, getStudMinoritySummaryAPIURL,
  getStudentCategorySummarySansthaAPIURL,
  getStudentMinoritySummarySansthaAPIURL,
  getSchoolWiseEmployeeAttendaceAPIURL,
  getClassDivWiseFeeSummaryAPIURL,
  getSchoolWiseFeeSummaryAPIURL
} from './CommonServices/DashboardAPIURL';
import CommentIcon from '@mui/icons-material/Comment';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import DashboardAllStudentCount from './CommonComponent/DashboardAllStudentCount';
import SansthaDashboardAllStudentCount from './CommonComponent/SansthaDashboardAllStudentCount';

import { getSansthaNoticeDataAPIURL, sansthaNoticeFileLoaction } from './Sanstha/Services/SansthaAPIURL';

import HowToRegIcon from '@mui/icons-material/HowToReg';
import Link from '@mui/material/Link';
import { getStudentRegistrationDataAPIURL } from './MobileApp/Services/MobileAppAllAPI';
import { ClassMasterGetMapping } from './CommonServices/ClassMasterAPIURL';

import { useNavigate } from 'react-router-dom';
import { Breadcrumbs, IconButton, Popper, Paper, TextField, Typography } from '@mui/material';
import { emphasize, styled } from '@mui/material/styles';
import Chip from '@mui/material/Chip';

import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import moment from 'moment';

import CakeIcon from '@mui/icons-material/Cake';
import CategoryIcon from '@mui/icons-material/Category';
import SchoolIcon from '@mui/icons-material/School';
import {
  Chart as ChartJs,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

import GroupsIcon from '@mui/icons-material/Groups';
import Loader from './CommonComponent/Loader';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import DatePicker from '@mui/lab/DatePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { LocalizationProvider } from '@mui/lab';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';

// const categoryData = [
//     {
//         schoolName: "abc",
//         sc: "2",
//         nt: "4",
//         obc: "5",
//         total: "11"
//     },
//     {
//         schoolName: "pqr",
//         sc: "5",
//         nt: "3",
//         obc: "2",
//         total: "10"

//     },
//     {
//         schoolName: "lmn",
//         sc: "1",
//         nt: "0",
//         obc: "5",
//         total: "6"

//     }
// ]


ChartJs.register(
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend
)

//for link style
const StyledBreadcrumb = styled(Chip)(({ theme }) => {
  const backgroundColor =
    theme.palette.mode === 'light'
      ? theme.palette.grey[200]
      : theme.palette.grey[900];
  return {
    backgroundColor,
    height: theme.spacing(3),
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightRegular,
    '&:hover, &:focus': {
      backgroundColor: emphasize(backgroundColor, 0.06),
    },
    '&:active': {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(backgroundColor, 0.12),
    },
  };
});


const Dashboard = () => {
  //const titleId = "DashBoard";
  const authUser = useSelector((state) => state.user.value);

  const navigate = useNavigate();

  const [loaderOption, setLoaderOption] = useState(false);

  const [data, setData] = useState([]);
  const [sansthaData, setsansthaData] = useState([]);

  const [sansthaNoticeData, setSansthaNoticeData] = useState([]);

  const [sansthaNoticeTitle, setSansthaNoticeTitle] = useState("");
  const [sansthaNoticeDesc, setSansthaNoticeDesc] = useState("");

  const [studSummary, setStudSummary] = useState([]);
  const [sansthaStudSummary, setSansthaStudSummary] = useState([]);

  const [pendingStudentByBranch, setPendingStudentByBranch] = useState([]);
  const [classData, setClassData] = useState([]);

  const [studBdayData, setStudBdayData] = useState([]);
  const [empBdayData, setEmpBdayData] = useState([]);
  const [studCategoryData, setStudCategoryData] = useState([]);
  const [studMinorityData, setStudMinorityData] = useState([]);

  const [sansthaCategoryData, setSansthaCategoryData] = useState([]);
  const [sansthaMinorityData, setSansthaMinorityData] = useState([]);

  const [employeeAttendData, setEmployeeAttendData] = useState([]);

  const [selectedDate, setSelectedDate] = useState(new Date());
  const [open, setOpen] = useState(false);

  const [classDivFeeSummary, setClassDivFeeSummary] = useState([]);
  const [totalFeeCollection, setTotalFeeCollection] = useState(0);
  const [totalFeePending, setTotalFeePending] = useState(0);
  const [totalFeeDemand, setTotalFeeDemand] = useState(0);


  const handleIconClick = () => {
    setOpen(true);
  };

  useEffect(() => {
    getClassData();

    if (authUser.roleId == 6) {
      if (authUser.sessionYear != null) {
        getSansthaAPIData();
        getSansthaStudSummary();
        getSansthaCategoryMinorityData();
      }
      getEmployeeAttendaceList();
      
    }
    else {
      if (authUser.sessionYear != null && authUser.deptId != null && authUser.branchId != null) {
        getAPIData();
        getStudSummary();
       
      }
    }

    if (authUser.roleId !== null && authUser.branchId !== null) {
      getSansthaNoticeData();
    }

    if (authUser.branchId != null) {
      getPendingStudentList();
    }

    if (authUser.sessionYear != null && authUser.deptId != null && authUser.branchId != null) {
      getCategoryMinoritySummary();
      getCurrentYearFeeSummary();
    }

  }, [authUser])

  const getClassData = async () => {
    await axios.get(`${ClassMasterGetMapping}`)
      .then(res => {
        setClassData(res.data);
      })
  }

  const getSansthaNoticeData = async () => {
    //let link = "http://192.168.1.102:8080/institute/sss/sanstha/notice/getSansthaNoticeData"
    await axios.get(`${getSansthaNoticeDataAPIURL}?bid=${authUser.branchId}&roleId=${authUser.roleId}`)
      .then((res) => {
        if (res.data === "NOTFOUND") {
          setSansthaNoticeData([]);
          // console.log(JSON.stringify(res.data))
        }
        else {
          setSansthaNoticeData(res.data);
          // console.log(JSON.stringify(res.data))
        }
      })
      .catch((err) => {
        console.log(err);
      })
  }

  const getSansthaAPIData = async () => {
    //console.log(`${getCardDataForSanstha}`);
    await axios(`${getCardDataForSanstha}`)
      .then(res => {
        setData(res.data);
      })
  }

  const getSansthaStudSummary = async () => {
    //console.log(`${getStudentSummeryForSanstha}?acyr=${authUser.sessionYear}`)
    await axios(`${getStudentSummeryForSanstha}?acyr=${authUser.sessionYear}`)
      .then(res => {
        setSansthaStudSummary(res.data);
      })
  }

  const getSansthaCategoryMinorityData = async () => {
    // setLoaderOption(true);
    await axios(`${getStudentCategorySummarySansthaAPIURL}?acyr=${authUser.sessionYear}`)
      .then(res => {
        setSansthaCategoryData(res.data);
        ///setLoaderOption(false);
      })

    await axios(`${getStudentMinoritySummarySansthaAPIURL}?acyr=${authUser.sessionYear}`)
      .then(res => {
        setSansthaMinorityData(res.data);
        setLoaderOption(false);
      })
  }

  const getEmployeeAttendaceList = async () => {
    await axios(`${getSchoolWiseEmployeeAttendaceAPIURL}?date=${moment(selectedDate).format("YYYY-MM-DD")}`)
      .then(res => {
        setEmployeeAttendData(res.data);
        //console.log(res.data)
        ///setLoaderOption(false);
      })
  }

  const getEmployeeAttendaceListByDate = async (selectData) => {
    await axios(`${getSchoolWiseEmployeeAttendaceAPIURL}?date=${moment(selectData).format("YYYY-MM-DD")}`)
      .then(res => {
        setEmployeeAttendData(res.data);
        console.log(res.data)
        ///setLoaderOption(false);
      })
  }

  const getAPIData = async () => {
    //console.log(`${getCardData}?acyr=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}`);
    await axios(`${getCardData}?acyr=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}`)
      .then(res => {
        setData(res.data);
      })
  }

  const getStudSummary = async () => {
    //console.log(`${getStudentSummery}?acyr=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}`)
    await axios(`${getStudentSummery}?acyr=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}`)
      .then(res => {
        setStudSummary(res.data);
      })
  }

  const getPendingStudentList = async () => {
    // await axios.get(`${getStudentRegistrationDataAPIURL}?bid=${authUser.branchId}`)
    //   .then(res => {
    //     let pendigData = res.data.filter(e => e.status == 3)
    //     setPendingStudentByBranch(pendigData);
    //   })
  }

  const getStudEmpBdayList = async () => {
    await axios(`${getStudentsBdayListAPIURL}?acyr=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}`)
      .then(res => {
        setStudBdayData(res.data);
      })

    await axios(`${getEmployeeBdayListAPIURL}?sid=${authUser.deptId}&bid=${authUser.branchId}`)
      .then(res => {
        setEmpBdayData(res.data);
      })
  }

  const getCategoryMinoritySummary = async () => {
    await axios(`${getStudCategorySummaryAPIURL}?acyr=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}`)
      .then(res => {
        // console.log(JSON.stringify(res.data));
        setStudCategoryData(res.data);
      })

    await axios(`${getStudMinoritySummaryAPIURL}?acyr=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}`)
      .then(res => {
        setStudMinorityData(res.data);
      })
  }

  const getCurrentYearFeeSummary = async () => {
    if (authUser.roleId == 6) {
      await axios.get(`${getSchoolWiseFeeSummaryAPIURL}?acyr=${authUser.sessionYear}`)
        .then(res => {
          setClassDivFeeSummary(res.data);

          const totalFeeCollectionAmt = res.data.reduce((total, item) => {
            return total + parseFloat(item.totalFeeCollection);
          }, 0);
          setTotalFeeCollection(totalFeeCollectionAmt)
          const totalFeePendingAmt = res.data.reduce((total, item) => {
            return total + parseFloat(item.totalFeePending);
          }, 0);
          setTotalFeePending(totalFeePendingAmt)
          const totalFeeDemandAmt = res.data.reduce((total, item) => {
            return total + parseFloat(item.totalFeeDemand);
          }, 0);
          setTotalFeeDemand(totalFeeDemandAmt)

        })
    }
    else {
      await axios.get(`${getClassDivWiseFeeSummaryAPIURL}?langId=${authUser.branchMedium}&acyr=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}`)
        .then(res => {
          setClassDivFeeSummary(res.data);

          const totalFeeCollectionAmt = res.data.reduce((total, item) => {
            return total + parseFloat(item.totalFeeCollection);
          }, 0);
          setTotalFeeCollection(totalFeeCollectionAmt)
          const totalFeePendingAmt = res.data.reduce((total, item) => {
            return total + parseFloat(item.totalFeePending);
          }, 0);
          setTotalFeePending(totalFeePendingAmt)
          const totalFeeDemandAmt = res.data.reduce((total, item) => {
            return total + parseFloat(item.totalFeeDemand);
          }, 0);
          setTotalFeeDemand(totalFeeDemandAmt)

        })
    }
  }


  //Minority bar chart
  // Group data by classdiv and catMinEn
  const minorityGroupedData = {};

  // Handle undefined or null values
  studMinorityData.forEach((item) => {
    if (!item) return;

    const classdiv = (authUser.branchMedium == 1) ? `${item.classMr} - ${item.divMr}` : `${item.classEn} - ${item.divEn}`;

    if (!minorityGroupedData[classdiv]) {
      minorityGroupedData[classdiv] = {};
    }

    const catMinEn = (authUser.branchMedium == 1) ? item.catMinMr : item.catMinEn || 'Others';

    if (!minorityGroupedData[classdiv][catMinEn]) {
      minorityGroupedData[classdiv][catMinEn] = {
        boysCount: 0,
        girlsCount: 0,
      };
    }

    minorityGroupedData[classdiv][catMinEn].boysCount += item.boysCount || 0;
    minorityGroupedData[classdiv][catMinEn].girlsCount += item.girlsCount || 0;
  });

  // Prepare data for chart
  const minorities = Array.from(
    new Set(studMinorityData.map((item) => (authUser.branchMedium == 1) ? item.catMinMr : item.catMinEn || 'Others'))
  );

  // Define fixed colors for each category
  const fixedColorsMinority = [
    '#FF6B5B',
    '#59FF5B',
    '#69DDFF',
    '#FDD759',
    '#7773FE',
    '#FF66BC',
    '#D05FFE',
    '#FC8B52',
    '#807C78'
    // Add more fixed colors for other categories
  ];

  const getRandomColor = (usedColors) => {
    let color;
    do {
      const hue = Math.floor(Math.random() * 360);
      const lightness = Math.floor(Math.random() * 20) + 70; // Adjust the range to get lighter colors
      color = `hsl(${hue}, 100%, ${lightness}%)`;
    } while (usedColors.has(color));
    usedColors.add(color);
    return color;
  };


  const providedDataLength = studMinorityData.length;
  const maxBarThicknessLarge = 10; // Define your maximum desired bar thickness for large datasets
  const maxBarThicknessSmall = 30; // Define your maximum desired bar thickness for small datasets
  // console.log(providedDataLength)
  const maxBarThickness = providedDataLength <= 40 ? maxBarThicknessSmall : maxBarThicknessLarge;

  const minorityChartData = {
    labels: Object.keys(minorityGroupedData),
    datasets: minorities.map((minority, index) => {
      const barThickness = maxBarThickness;

      return {
        label: minority,
        data: Object.values(minorityGroupedData).map((classData) =>
          (classData[minority] ? classData[minority].boysCount + classData[minority].girlsCount : 0)
        ),
        backgroundColor: index < fixedColorsMinority.length ? fixedColorsMinority[index] : getRandomColor(new Set(fixedColorsMinority)),
        barThickness: barThickness,
        barPercentage: 1,
      };
    }),
  };



  // Chart options with switched x and y scales for a horizontal bar chart
  const minorityOptions = {
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
    indexAxis: 'x',
    responsive: true,
    maintainAspectRatio: false,
  };




  //Category bar chart
  // Group data by classdiv and catMinEn
  const categoryGroupedData = {};

  // Handle undefined or null values
  studCategoryData.forEach((item) => {
    if (!item) return;

    const classdiv = (authUser.branchMedium == 1) ? `${item.classMr} - ${item.divMr}` : `${item.classEn} - ${item.divEn}`;

    if (!categoryGroupedData[classdiv]) {
      categoryGroupedData[classdiv] = {};
    }

    const catMinEn = (authUser.branchMedium == 1) ? item.catMinMr : item.catMinEn || 'Others';

    if (!categoryGroupedData[classdiv][catMinEn]) {
      categoryGroupedData[classdiv][catMinEn] = {
        boysCount: 0,
        girlsCount: 0,
      };
    }

    categoryGroupedData[classdiv][catMinEn].boysCount += item.boysCount || 0;
    categoryGroupedData[classdiv][catMinEn].girlsCount += item.girlsCount || 0;
  });

  // Prepare data for chart
  const categories = Array.from(
    new Set(studCategoryData.map((item) => (authUser.branchMedium == 1) ? item.catMinMr : item.catMinEn || 'Others'))
  );

  // Define fixed colors for each category
  const fixedColorsCategory = [
    '#FF6B5B',
    '#59FF5B',
    '#69DDFF',
    '#FDD759',
    '#7773FE',
    '#FF66BC',
    '#D05FFE',
    '#FC8B52',
    '#807C78'
    // Add more fixed colors for other categories
  ];

  const providedDataLengthCategory = studCategoryData.length;
  const maxBarThicknessLargeCategory = 10; // Define your maximum desired bar thickness for large datasets
  const maxBarThicknessSmallCategory = 20; // Define your maximum desired bar thickness for small datasets
  // console.log(providedDataLengthCategory)
  const maxBarThicknessCategory = providedDataLengthCategory <= 40 ? maxBarThicknessSmallCategory : maxBarThicknessLargeCategory;

  const categoryChartData = {
    labels: Object.keys(categoryGroupedData), // classdiv
    datasets: categories.map((category, index) => {
      const barThickness = maxBarThicknessCategory;
      return {
        label: category,
        data: Object.values(categoryGroupedData).map((classData) =>
          (classData[category] ? classData[category].boysCount + classData[category].girlsCount : 0)
        ),
        backgroundColor: index < fixedColorsCategory.length ? fixedColorsCategory[index] : getRandomColor(new Set(fixedColorsCategory)),
        barThickness: barThickness,
        // categoryPercentage: 0.9, // Adjust this value to increase/decrease the gap
        barPercentage: 1, // Adjust this value to increase/decrease the gap
      }
    }),
  };

  // Chart options with switched x and y scales for a horizontal bar chart
  const categoryOptions = {
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
    indexAxis: 'y',
    responsive: true,
    maintainAspectRatio: false,
  };

  useEffect(() => {
    // Cleanup localStorage on component unmount
    return () => localStorage.removeItem('chartColors');
  }, []);


  const tableHeadersCategory = sansthaCategoryData.length > 0 ? Object.keys(sansthaCategoryData[0].studentData) : [];
  const tableHeadersMinority = sansthaMinorityData.length > 0 ? Object.keys(sansthaMinorityData[0].studentData) : [];


  if (loaderOption == true) {
    return (
      <Loader />
    )
  }
  else {
    return (
      <>
        {/* <TitleLabel titleId={titleId} /> */}
        <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: "500px" }}>
          <div className="row">
            <div className="col-md-4 col-xl-3">
              <div className="card bg-c-pink order-card">
                <div className="card-block">

                  <span className="text-right" >
                    <img src={graduates} className="f-left mt-2" style={{ width: "80px" }} />
                    <span className="f-right" style={{ fontSize: "26px", fontWeight: "600" }}>{data.totalStudents}</span>
                    <br /><br /><br />
                    <h6>Students</h6>
                  </span>
                  {/* <p className="m-b-0">Completed Orders<span className="f-right">351</span></p> */}
                </div>
              </div>
            </div>

            <div className="col-md-4 col-xl-3">
              <div className="card bg-c-blue order-card">
                <div className="card-block">
                  {/* <h6 className="m-b-20"> </h6> */}
                  <h4 className="text-right">
                    {/* <MaleIcon fontSize='large' className="f-left" /> */}

                    <span className="f-left text-center" style={{ lineHeight: "2" }}> <img src={boy} style={{ width: "65px" }} /> <br /> <span>{data.totalBoys}</span></span>

                    <span className="f-right text-center" style={{ lineHeight: "2" }}> <img src={girl} style={{ width: "65px" }} /> <br /> <span>{data.totalGirls}</span></span>
                  </h4>
                </div>
              </div>
            </div>



            <div className="col-md-4 col-xl-3">
              <div className="card bg-c-yellow order-card">
                <div className="card-block">
                  <span className="text-right" >
                    <img src={team} className="f-left mt-2" style={{ width: "80px" }} />
                    <span className="f-right" style={{ fontSize: "26px", fontWeight: "600" }}>{data.totalStaff}</span>
                    <br /><br /><br />
                    <h6 >Staff</h6>
                  </span>

                </div>
              </div>
            </div>
            <div className="col-md-4 col-xl-3">
              <div className="card bg-c-green order-card">
                <div className="card-block">
                  <h4 className="text-right">
                    {/* <MaleIcon fontSize='large' className="f-left" /> */}

                    <span className="f-left text-center" style={{ lineHeight: "2" }}> <img src={lecture} style={{ width: "65px" }} /> <br /> <span>{data.teachingStaff}</span></span>

                    <span className="f-right text-center" style={{ lineHeight: "2" }}> <img src={teamwork} style={{ width: "65px" }} /> <br /> <span>{data.nonTeachingStaff}</span></span>
                  </h4>
                </div>
              </div>
            </div>
          </div>



          {(() => {
            if (authUser.roleId == 6) {
              return (
                <>

                  <div className='row'>
                    <div className="col-sm-6">
                      <div style={{ padding: "6px 10px", borderTop: "3px solid #464de4", borderRadius: "4px", boxShadow: "0px 2px 10px #6C85A4", minHeight: "270px" }}>

                        {/* Header */}
                        <div style={{ borderBottom: "1px solid #DDDCFC", paddingBottom: "4px", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                          <div style={{ display: "flex", alignItems: "center" }}>
                            <HowToRegIcon style={{ color: "#AFAFAF", marginRight: "4px", fontSize: '20px', marginTop: "-4px" }} />
                            <span style={{ fontSize: "18px", fontWeight: "600", color: "#787B7F" }}>Employee Attendance</span>
                          </div>

                          {/* Breadcrumb with Calendar Icon and Date */}
                          <Breadcrumbs aria-label="breadcrumb" separator="">
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <DatePicker
                                value={selectedDate}
                                onChange={(newValue) => {
                                  setSelectedDate(newValue);
                                  setOpen(false); // Close the DatePicker after selecting a date
                                  getEmployeeAttendaceListByDate(newValue);
                                }}
                                open={open}
                                onOpen={() => setOpen(true)}
                                onClose={() => setOpen(false)}
                                renderInput={({ inputRef }) => (
                                  <IconButton ref={inputRef} onClick={handleIconClick}>
                                    <CalendarTodayIcon style={{ color: "#0044CB", fontSize: "20px" }} />
                                  </IconButton>
                                )}
                              />
                            </LocalizationProvider>
                            <Typography style={{ marginLeft: "3px", fontSize: "15px", fontWeight: "600", color: "#d3252f" }}>
                              {moment(selectedDate).format("DD-MM-YYYY")}
                            </Typography>
                          </Breadcrumbs>
                        </div>

                        {/* Body */}
                        <div className='bodyWrapper' style={{ maxHeight: "180px", overflowY: "scroll", margin: "4px 0px" }}>
                          <div className='table-responsive'>
                            <table className='table'>
                              <thead style={{ borderBottom: "2px solid #EBEDEF", backgroundColor: "#e7eff9" }}>
                                <tr>
                                  <th style={{ fontSize: "14px", fontWeight: 600, padding: "8px" }}>Sr No.</th>
                                  <th style={{ fontSize: "14px", fontWeight: 600, padding: "8px" }}>School Name</th>
                                  <th style={{ fontSize: "14px", fontWeight: 600, padding: "8px" }}>Present</th>
                                  <th style={{ fontSize: "14px", fontWeight: 600, padding: "8px" }}>Absent</th>
                                  <th style={{ fontSize: "14px", fontWeight: 600, padding: "8px" }}>Total</th>
                                </tr>
                              </thead>
                              <tbody>
                                {
                                  employeeAttendData.map((item, index) => {
                                    return (
                                      <tr key={index}>
                                        <td style={{ fontSize: "13px", padding: "8px" }}> {index + 1} </td>
                                        <td style={{ fontSize: "13px", padding: "8px", fontWeight: "480", lineHeight: '1.5' }}>{(authUser.branchMedium == 1) ? item.schoolNameMr : item.schoolNameEn}</td>
                                        <td style={{ fontSize: "13px", padding: "8px", fontWeight: "480", textAlign: 'center' }}>{item.present}</td>
                                        <td style={{ fontSize: "13px", padding: "8px", fontWeight: "480", textAlign: 'center' }}>{item.absent}</td>
                                        <td style={{ fontSize: "13px", padding: "8px", fontWeight: "480", textAlign: 'center' }}>{item.total}</td>
                                      </tr>
                                    )
                                  })
                                }
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-sm-6">
                      <div style={{ padding: "6px 10px", borderTop: "3px solid #464de4", borderRadius: "4px", boxShadow: "0px 2px 10px #6C85A4", minHeight: "270px" }}>
                        {
                          (authUser.roleId === 6) ?
                            <SansthaDashboardAllStudentCount dataChart={sansthaStudSummary} />
                            : <DashboardAllStudentCount dataChart={studSummary} />
                        }
                      </div>

                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm-12 mt-3">
                      <div style={{ padding: "6px 10px", borderTop: "3px solid #464de4", borderRadius: "4px", boxShadow: "0px 2px 10px #6C85A4", minHeight: "300px", marginBottom: "30px" }}>
                        {/* Header */}
                        <div style={{ borderBottom: "1px solid #DDDCFC", paddingBottom: "4px" }}>
                          <span><CurrencyRupeeIcon style={{ color: "#AFAFAF", marginRight: "4px", fontSize: '20px', marginTop: "-4px" }} /></span>
                          <span style={{ fontSize: "18px", fontWeight: "600", color: "#787B7F" }}>Fee Summary for Current Year</span>
                        </div>
                        {/* Body */}
                        <div className='bodyWrapper' style={{ maxHeight: "200px", overflowY: "scroll", margin: "4px 0px" }}>
                          <div>
                            <div className='table-responsive'>
                              <table className='table'>
                                <thead style={{ borderBottom: "2px solid #EBEDEF", backgroundColor: "#e7eff9" }}>
                                  <tr>
                                    <th style={{ fontSize: "14px", fontWeight: 600, padding: "8px" }}>Sr No.</th>
                                    <th style={{ fontSize: "14px", fontWeight: 600, padding: "8px" }}>School Name</th>
                                    <th style={{ fontSize: "14px", fontWeight: 600, padding: "8px" }}>Total Fee Collection</th>
                                    <th style={{ fontSize: "14px", fontWeight: 600, padding: "8px" }}>Total Fee Pending</th>
                                    <th style={{ fontSize: "14px", fontWeight: 600, padding: "8px" }}>Total Fee Demand</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {classDivFeeSummary.map((item, index) => (
                                    <tr key={index}>
                                      <td style={{ fontSize: "13px", padding: "8px", fontWeight: "480" }}>{index + 1}</td>
                                      <td style={{ fontSize: "13px", padding: "8px", fontWeight: "480" }}>{item.schoolName}</td>
                                      <td style={{ fontSize: "13px", padding: "8px", fontWeight: "480" }}>{parseFloat(item.totalFeeCollection).toFixed('2') + " " + "₹"}</td>
                                      <td style={{ fontSize: "13px", padding: "8px", fontWeight: "480" }}>{parseFloat(item.totalFeePending).toFixed('2') + " " + "₹"}</td>
                                      <td style={{ fontSize: "13px", padding: "8px", fontWeight: "480" }}>{parseFloat(item.totalFeeDemand).toFixed('2') + " " + "₹"}</td>
                                    </tr>
                                  ))}
                                </tbody>
                                <tfoot>
                                  {(() => {
                                    if (classDivFeeSummary.length != 0) {
                                      return (
                                        <>
                                          <tr style={{ backgroundColor: "#f0f0f0" }}>
                                            <td colSpan="2" style={{ fontSize: "14px", padding: "8px", fontWeight: 600 }}>
                                              Total
                                            </td>
                                            <td style={{ fontSize: "14px", padding: "8px", fontWeight: 600 }}>
                                              {totalFeeCollection.toFixed(2) + " ₹"}
                                            </td>
                                            <td style={{ fontSize: "14px", padding: "8px", fontWeight: 600 }}>
                                              {totalFeePending.toFixed(2) + " ₹"}
                                            </td>
                                            <td style={{ fontSize: "14px", padding: "8px", fontWeight: 600 }}>
                                              {totalFeeDemand.toFixed(2) + " ₹"}
                                            </td>
                                            <td colSpan="2"></td>
                                          </tr>
                                        </>
                                      )
                                    }
                                  })()}
                                </tfoot>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm-12 mt-2">
                      <div style={{ padding: "6px 10px", borderTop: "3px solid #464de4", borderRadius: "4px", boxShadow: "0px 2px 10px #6C85A4", minHeight: "300px", marginBottom: "30px" }}>
                        {/* Header */}
                        <div style={{ borderBottom: "1px solid #DDDCFC", paddingBottom: "4px" }}>
                          <span><CategoryIcon style={{ color: "#AFAFAF", marginRight: "4px", fontSize: '20px', marginTop: "-4px" }} /></span>
                          <span style={{ fontSize: "18px", fontWeight: "600", color: "#787B7F" }}>Category Summary for Current Year</span>
                        </div>
                        {/* Body */}
                        <div className='bodyWrapper' style={{ maxHeight: "200px", overflowY: "scroll", margin: "4px 0px" }}>
                          <div>
                            <div className='table-responsive'>
                              <table className='table'>
                                <thead style={{ borderBottom: "2px solid #EBEDEF", backgroundColor: "#e7eff9" }}>
                                  <tr>
                                    <th style={{ fontSize: "14px", fontWeight: 600, padding: "8px" }}>Sr No.</th>
                                    <th style={{ fontSize: "14px", fontWeight: 600, padding: "8px" }}>School Name</th>
                                    {tableHeadersCategory.map((header) => (
                                      <th key={header} style={{ fontSize: "14px", fontWeight: 600, padding: "8px", textAlign: 'center' }}>{header}</th>
                                    ))}
                                    <th style={{ fontSize: "14px", fontWeight: 600, padding: "8px" }}>Total</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {sansthaCategoryData.map((item, index) => (
                                    <tr key={index}>
                                      <td style={{ fontSize: "13px", padding: "8px", fontWeight: "480" }}>{index + 1}</td>
                                      <td style={{ fontSize: "13px", padding: "8px", fontWeight: "480" }}>{item.schoolName}</td>
                                      {tableHeadersCategory.map((header) => (
                                        <td key={header} style={{ fontSize: "13px", padding: "8px", fontWeight: "480", textAlign: 'center' }}>
                                          {item.studentData[header]}
                                        </td>
                                      ))}
                                      <td style={{ fontSize: "13px", padding: "8px", fontWeight: "480", textAlign: 'center' }}>{item.total}</td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <div className="row my-1">
                    <div className="col-sm-12">
                      <div style={{ padding: "6px 10px", borderTop: "3px solid #464de4", borderRadius: "4px", boxShadow: "0px 2px 10px #6C85A4", minHeight: "300px", marginBottom: "30px" }}>
                        
                        <div style={{ borderBottom: "1px solid #DDDCFC", paddingBottom: "4px" }}>
                          <span><GroupsIcon style={{ color: "#AFAFAF", marginRight: "4px", fontSize: '20px', marginTop: "-4px" }} /></span>
                          <span style={{ fontSize: "18px", fontWeight: "600", color: "#787B7F" }}>Minority Summary for Current Year</span>
                        </div>
                       
                        <div className='bodyWrapper' style={{ maxHeight: "180px", overflowY: "scroll", margin: "4px 0px" }}>
                          <div>
                            <div className='table-responsive'>
                              <table className='table'>
                                <thead style={{ borderBottom: "2px solid #EBEDEF", backgroundColor: "#e7eff9" }}>
                                  <tr>
                                    <th style={{ fontSize: "14px", fontWeight: 600, padding: "8px" }}>Sr No.</th>
                                    <th style={{ fontSize: "14px", fontWeight: 600, padding: "8px" }}>School Name</th>
                                    {tableHeadersMinority.map((header) => (
                                      <th key={header} style={{ fontSize: "14px", fontWeight: 600, padding: "8px", textAlign: 'center' }}>{header}</th>
                                    ))}
                                    <th style={{ fontSize: "14px", fontWeight: 600, padding: "8px" }}>Total</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {sansthaMinorityData.map((item, index) => (
                                    <tr key={index}>
                                      <td style={{ fontSize: "13px", padding: "8px", fontWeight: "480" }}>{index + 1}</td>
                                      <td style={{ fontSize: "13px", padding: "8px", fontWeight: "480" }}>{item.schoolName}</td>
                                      {tableHeadersMinority.map((header) => (
                                        <td key={header} style={{ fontSize: "13px", padding: "8px", fontWeight: "480", textAlign: 'center' }}>
                                          {item.studentData[header]}
                                        </td>
                                      ))}
                                      <td style={{ fontSize: "13px", padding: "8px", fontWeight: "480", textAlign: 'center' }}>{item.total}</td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}
                </>
              )
            }
            else {
              return (
                <>
                  <div className="row">
                    <div className="col-sm-12 mt-3">
                      <div style={{ padding: "6px 10px", borderTop: "3px solid #464de4", borderRadius: "4px", boxShadow: "0px 2px 10px #6C85A4", minHeight: "300px", marginBottom: "30px" }}>
                        {/* Header */}
                        <div style={{ borderBottom: "1px solid #DDDCFC", paddingBottom: "4px" }}>
                          <span><CurrencyRupeeIcon style={{ color: "#AFAFAF", marginRight: "4px", fontSize: '20px', marginTop: "-4px" }} /></span>
                          <span style={{ fontSize: "18px", fontWeight: "600", color: "#787B7F" }}>Fee Summary for Current Year</span>
                        </div>
                        {/* Body */}
                        <div className='bodyWrapper' style={{ maxHeight: "200px", overflowY: "scroll", margin: "4px 0px" }}>
                          <div>
                            <div className='table-responsive'>
                              <table className='table'>
                                <thead style={{ borderBottom: "2px solid #EBEDEF", backgroundColor: "#e7eff9" }}>
                                  <tr>
                                    <th style={{ fontSize: "14px", fontWeight: 600, padding: "8px" }}>Sr No.</th>
                                    <th style={{ fontSize: "14px", fontWeight: 600, padding: "8px" }}>Class-Division Name</th>
                                    <th style={{ fontSize: "14px", fontWeight: 600, padding: "8px" }}>Total Fee Collection</th>
                                    <th style={{ fontSize: "14px", fontWeight: 600, padding: "8px" }}>Total Fee Pending</th>
                                    <th style={{ fontSize: "14px", fontWeight: 600, padding: "8px" }}>Total Fee Demand</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {classDivFeeSummary.map((item, index) => (
                                    <tr key={index}>
                                      <td style={{ fontSize: "13px", padding: "8px", fontWeight: "480" }}>{index + 1}</td>
                                      <td style={{ fontSize: "13px", padding: "8px", fontWeight: "480" }}>{item.className + " " + item.divisionName}</td>
                                      <td style={{ fontSize: "13px", padding: "8px", fontWeight: "480" }}>{parseFloat(item.totalFeeCollection).toFixed('2') + " " + "₹"}</td>
                                      <td style={{ fontSize: "13px", padding: "8px", fontWeight: "480" }}>{parseFloat(item.totalFeePending).toFixed('2') + " " + "₹"}</td>
                                      <td style={{ fontSize: "13px", padding: "8px", fontWeight: "480" }}>{parseFloat(item.totalFeeDemand).toFixed('2') + " " + "₹"}</td>
                                    </tr>
                                  ))}
                                </tbody>
                                <tfoot>
                                  {(() => {
                                    if (classDivFeeSummary.length != 0) {
                                      return (
                                        <>
                                          <tr style={{ backgroundColor: "#f0f0f0" }}>
                                            <td colSpan="2" style={{ fontSize: "14px", padding: "8px", fontWeight: 600 }}>
                                              Total
                                            </td>
                                            <td style={{ fontSize: "14px", padding: "8px", fontWeight: 600 }}>
                                              {totalFeeCollection.toFixed(2) + " ₹"}
                                            </td>
                                            <td style={{ fontSize: "14px", padding: "8px", fontWeight: 600 }}>
                                              {totalFeePending.toFixed(2) + " ₹"}
                                            </td>
                                            <td style={{ fontSize: "14px", padding: "8px", fontWeight: 600 }}>
                                              {totalFeeDemand.toFixed(2) + " ₹"}
                                            </td>
                                            <td colSpan="2"></td>
                                          </tr>
                                        </>
                                      )
                                    }
                                  })()}
                                </tfoot>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div className='row my-2'>
                    <div className="col-sm-12">
                      <div style={{ padding: "6px 10px", borderTop: "3px solid #464de4", borderRadius: "4px", boxShadow: "0px 2px 10px #6C85A4", minHeight: '270px' }}>
                        {/* Header */}
                        <div style={{ borderBottom: "1px solid #DDDCFC", paddingBottom: "4px" }}>
                          <span><CategoryIcon style={{ color: "#AFAFAF", marginRight: "4px", fontSize: '20px', marginTop: "-4px" }} /></span>
                          <span style={{ fontSize: "18px", fontWeight: "600", color: "#787B7F" }}>Student Category Summary</span>
                        </div>
                        {/* Body */}
                        {/* <div className='' style={{ height: '500px', overflowY: "scroll", margin: "4px 0px",width:'100%' }}> */}
                        <div className="chart-container" style={{ width: '100%', height: '500px' }}>

                          <Bar data={categoryChartData} options={categoryOptions} />
                        </div>
                        {/* </div> */}
                      </div>
                    </div>
                  </div>


                  <div className="row my-2">
                    <div className="col-sm-12">
                      <div style={{ padding: "6px 10px", borderTop: "3px solid #464de4", borderRadius: "4px", boxShadow: "0px 2px 10px #6C85A4", minHeight: "270px" }}>
                        {/* Header */}
                        <div style={{ borderBottom: "1px solid #DDDCFC", paddingBottom: "4px" }}>
                          <span><SchoolIcon style={{ color: "#AFAFAF", marginRight: "4px", fontSize: '20px', marginTop: "-4px" }} /></span>
                          <span style={{ fontSize: "18px", fontWeight: "600", color: "#787B7F" }}>Student Minority Summary</span>
                        </div>

                        <div className="" style={{ width: '100%', height: '500px' }}>
                          <Bar data={minorityChartData} options={minorityOptions} />
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )
            }
          })()}



          {(() => {
            if (authUser.roleId != 6) {
              return (
                <>
                  <div className='row my-2'>
                    {/* <div className="col-sm-6">
                      <div style={{ padding: "6px 10px", borderTop: "3px solid #464de4", borderRadius: "4px", boxShadow: "0px 2px 10px #6C85A4", minHeight: "270px" }}>
                       
                        <div style={{ borderBottom: "1px solid #DDDCFC", paddingBottom: "4px" }}>
                          <span><HowToRegIcon style={{ color: "#AFAFAF", marginRight: "4px", fontSize: '20px', marginTop: "-4px" }} /></span>
                          <span style={{ fontSize: "18px", fontWeight: "600", color: "#787B7F" }}>Mobile User Approval</span>
                        </div>
                        
                        <div className='bodyWrapper' style={{ maxHeight: "180px", overflowY: "scroll", margin: "4px 0px" }}>
                          <div>
                            <div className='table-responsive'>
                              <table className='table'>
                                <thead style={{ borderBottom: "2px solid #EBEDEF", backgroundColor: "#e7eff9", }}>
                                  <tr>
                                    <th style={{ fontSize: "14px", fontWeight: 600, padding: "8px" }}>Id</th>
                                    <th style={{ fontSize: "14px", fontWeight: 600, padding: "8px" }}>Name</th>
                                    <th style={{ fontSize: "14px", fontWeight: 600, padding: "8px" }}>Reg No</th>
                                    <th style={{ fontSize: "14px", fontWeight: 600, padding: "8px" }}>Class</th>
                                    
                                  </tr>
                                </thead>
                                <tbody>
                                  {
                                    pendingStudentByBranch.map((item, index) => {
                                      return (
                                        <tr key={index}>
                                          <td style={{ fontSize: "13px", padding: "8px" }}> {index + 1} </td>
                                          <td style={{ fontSize: "13px", padding: "8px", fontWeight: "480" }}>{item.name}</td>
                                          <td style={{ fontSize: "13px", padding: "8px", fontWeight: "480" }}>{item.regId}</td>
                                          <td style={{ fontSize: "13px", padding: "8px", fontWeight: "480" }}>
                                            {
                                              classData.map(e => {
                                                if (e.id == item.classId) {
                                                  return (authUser.branchMedium == 1) ? e.nameMr : e.nameEn
                                                }
                                              })
                                            }
                                          </td>
                                        </tr>
                                      )
                                    })
                                  }
                                </tbody>
                              </table>
                            </div>
                          </div>
                          <div className="" style={{ display: "flex", justifyContent: "end" }}>
                            <Breadcrumbs aria-label="breadcrumb" style={{ bottom: "10px", position: "absolute", marginTop: (pendingStudentByBranch.length == 0) ? "160px" : "100px" }}>
                              <StyledBreadcrumb component="button"
                                onClick={() => { navigate('/Home/ma1001/'); }}
                                style={{ fontSize: "15px", fontWeight: "600", color: "#0044CB", textDecoration: "Underline", padding: "2px" }} label='Go To Approval' />
                            </Breadcrumbs>
                          </div>
                        </div>
                      </div>
                    </div> */}

                    <div className="col-sm-6">
                      <div style={{ padding: "6px 10px", borderTop: "3px solid #464de4", borderRadius: "4px", boxShadow: "0px 2px 10px #6C85A4", minHeight: "270px" }}>
                        {/* Header */}
                        <div style={{ borderBottom: "1px solid #DDDCFC", paddingBottom: "4px" }}>
                          <span><AccountBalanceIcon style={{ color: "#AFAFAF", marginRight: "4px", fontSize: '18px', marginTop: "-4px" }} /></span>
                          <span style={{ fontSize: "18px", fontWeight: "600", color: "#787B7F" }}>Sanstha Message</span>
                        </div>
                        {/* Body */}
                        <div className='bodyWrapper' style={{ maxHeight: "220px", overflowY: "scroll", margin: "4px 0px" }}>

                          {
                            sansthaNoticeData.map((item, index) => {
                              if (item.fileOption === 1) {
                                return (
                                  <div key={index} onClick={() => { setSansthaNoticeTitle(item.noticeSubject); setSansthaNoticeDesc(item.fileDescription) }} data-bs-toggle="modal" data-bs-target="#sansthamodal" style={{ padding: "4px", margin: "6px 0px", borderLeft: "2px solid #DDDCFC", fontSize: "14px", fontWeight: "500", backgroundColor: "#EFF1F2", cursor: "pointer" }}>
                                    <table>
                                      <tbody>
                                        <tr>
                                          <td style={{ padding: "4px 4px 4px 0px" }}>
                                            <CommentIcon style={{ color: "#464de4", fontSize: '16px' }} />
                                          </td>
                                          <td style={{ padding: "4px" }}>{item.noticeSubject}
                                            <small className="badge" style={{ fontSize: "11px", fontWeight: "600", padding: "1px", marginLeft: "4px", backgroundColor: "#18D540" }}> <AccessTimeIcon style={{ fontSize: "12px", fontWeight: "600" }} /> {item.days}</small>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                )
                              }
                              else if (item.fileOption === 2) {
                                return (
                                  <div key={index} onClick={() => window.open(`${sansthaNoticeFileLoaction}${item.fileDetail}`)} style={{ padding: "4px", margin: "6px 0px", borderLeft: "2px solid #DDDCFC", fontSize: "14px", fontWeight: "500", backgroundColor: "#EFF1F2", cursor: "pointer" }}>
                                    <table>
                                      <tbody>
                                        <tr>
                                          <td style={{ padding: "4px 4px 4px 0px" }}>
                                            <PictureAsPdfIcon style={{ color: "#464de4", fontSize: '16px' }} />
                                          </td>
                                          <td style={{ padding: "4px" }}>{item.noticeSubject}
                                            <small className="badge" style={{ fontSize: "11px", fontWeight: "600", padding: "1px", marginLeft: "4px", backgroundColor: "#18D540" }}> <AccessTimeIcon style={{ fontSize: "12px", fontWeight: "600" }} /> {item.days}</small>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                )
                              }

                            })
                          }

                        </div>
                      </div>
                    </div>

                    <div className="col-sm-6">
                      <div style={{ padding: "6px 10px", borderTop: "3px solid #464de4", borderRadius: "4px", boxShadow: "0px 2px 10px #6C85A4", minHeight: "270px" }}>
                        {
                          (authUser.roleId === 6) ?
                            <SansthaDashboardAllStudentCount dataChart={sansthaStudSummary} />
                            : <DashboardAllStudentCount dataChart={studSummary} />
                        }
                      </div>

                    </div>
                  </div >
                </>
              )
            }
          })()}



          {/* <div className="row my-2">
            {(() => {
              if (authUser.roleId != 6) {
                return (
                  <>
                    <div className="col-sm-6">
                      <div style={{ padding: "6px 10px", borderTop: "3px solid #464de4", borderRadius: "4px", boxShadow: "0px 2px 10px #6C85A4", minHeight: "270px" }}>
                       
                        <div style={{ borderBottom: "1px solid #DDDCFC", paddingBottom: "4px" }}>
                          <span><AccountBalanceIcon style={{ color: "#AFAFAF", marginRight: "4px", fontSize: '18px', marginTop: "-4px" }} /></span>
                          <span style={{ fontSize: "18px", fontWeight: "600", color: "#787B7F" }}>Sanstha Message</span>
                        </div>
                       
                        <div className='bodyWrapper' style={{ maxHeight: "220px", overflowY: "scroll", margin: "4px 0px" }}>

                          {
                            sansthaNoticeData.map((item, index) => {
                              if (item.fileOption === 1) {
                                return (
                                  <div key={index} onClick={() => { setSansthaNoticeTitle(item.noticeSubject); setSansthaNoticeDesc(item.fileDescription) }} data-bs-toggle="modal" data-bs-target="#sansthamodal" style={{ padding: "4px", margin: "6px 0px", borderLeft: "2px solid #DDDCFC", fontSize: "14px", fontWeight: "500", backgroundColor: "#EFF1F2", cursor: "pointer" }}>
                                    <table>
                                      <tbody>
                                        <tr>
                                          <td style={{ padding: "4px 4px 4px 0px" }}>
                                            <CommentIcon style={{ color: "#464de4", fontSize: '16px' }} />
                                          </td>
                                          <td style={{ padding: "4px" }}>{item.noticeSubject}
                                            <small className="badge" style={{ fontSize: "11px", fontWeight: "600", padding: "1px", marginLeft: "4px", backgroundColor: "#18D540" }}> <AccessTimeIcon style={{ fontSize: "12px", fontWeight: "600" }} /> {item.days}</small>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                )
                              }
                              else if (item.fileOption === 2) {
                                return (
                                  <div key={index} onClick={() => window.open(`${sansthaNoticeFileLoaction}${item.fileDetail}`)} style={{ padding: "4px", margin: "6px 0px", borderLeft: "2px solid #DDDCFC", fontSize: "14px", fontWeight: "500", backgroundColor: "#EFF1F2", cursor: "pointer" }}>
                                    <table>
                                      <tbody>
                                        <tr>
                                          <td style={{ padding: "4px 4px 4px 0px" }}>
                                            <PictureAsPdfIcon style={{ color: "#464de4", fontSize: '16px' }} />
                                          </td>
                                          <td style={{ padding: "4px" }}>{item.noticeSubject}
                                            <small className="badge" style={{ fontSize: "11px", fontWeight: "600", padding: "1px", marginLeft: "4px", backgroundColor: "#18D540" }}> <AccessTimeIcon style={{ fontSize: "12px", fontWeight: "600" }} /> {item.days}</small>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                )
                              }

                            })
                          }

                        </div>
                      </div>
                    </div>
                  </>
                )
              }
            })()}
          </div> */}

        </div>

        {/* Modal */}
        <div className="modal fade" id="sansthamodal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header p-2">
                <h4 className="modal-title" id="exampleModalLabel">{sansthaNoticeTitle}</h4>
              </div>
              <div className="modal-body p-3">
                <p>{sansthaNoticeDesc}</p>
              </div>
              <div className="modal-footer p-2">
                <button type="button" className="btn btn-danger" data-bs-dismiss="modal">Close</button>
              </div>
            </div>
          </div>
        </div>

      </>
    )
  }
}

export default Dashboard