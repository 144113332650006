import React, { useState } from "react";
import BrandingImg from "./BrandingImg";
import LoginForm from "./LoginForm";
import AfterloginForm from "./AfterLoginForm";
const Content = () => {

    const [showLogin, setShowLogin] = useState(true);
    const [loginData, setLoginData] = useState([]);
    //console.log("content: " + loginData);

    //Get Login Data
    const [id, setId] = useState("");
    const [name, setName] = useState("");
    const [uname, setUname] = useState("");
    const [email, setEmail] = useState("");
    const [rolesId, setRolesId] = useState();
    const [roles, setRoles] = useState("");
    const [department, setDepartment] = useState("");
    const [branches, setBranches] = useState("");
    const [branchName, setBranchName] = useState("");
    const [branchId, setBranchId] = useState("");
    const [branchMedium, setBranchMedium] = useState("");
    const [accountingType, setAccountingType] = useState("");
    const [feeType, setFeeType] = useState("");
    const [trustId, setTrustId] = useState("");
    //console.log("new Branch Name" + branchName);
    const [headers, setHeaders] = useState({});

    return (
        <>
            <div className="container">

                <div className="row bg-light p-0 m-0" style={{ boxShadow: "0px 7px 20px #2E2E2E" }}>

                    <div className="col-sm-8 p-0 m-0">
                        <BrandingImg />
                    </div>
                    <div className="col-sm-4 p-0 m-0">
                        {
                            showLogin
                                ?
                                <LoginForm
                                    setRolesId={setRolesId}
                                    setBranchName={setBranchName}
                                    setBranchId={setBranchId}
                                    setEmail={setEmail}
                                    setBranches={setBranches}
                                    setDepartment={setDepartment}
                                    setRoles={setRoles}
                                    setUname={setUname}
                                    setName={setName}
                                    setShowLogin={setShowLogin}
                                    setLoginData={setLoginData}
                                    setId={setId}
                                    setBranchMedium={setBranchMedium}
                                    setAccountingType={setAccountingType}
                                    setFeeType={setFeeType}
                                    setTrustId={setTrustId}
                                    setHeaders={setHeaders}
                                />
                                :
                                <AfterloginForm
                                    id={id}
                                    name={name}
                                    uname={uname}
                                    email={email}
                                    rolesId={rolesId}
                                    roles={roles}
                                    department={department}
                                    branches={branches}
                                    branchName={branchName}
                                    branchId={branchId}
                                    setShowLogin={setShowLogin}
                                    branchMedium={branchMedium}
                                    accountingType={accountingType}
                                    feeType={feeType}
                                    trustId={trustId}
                                    headers={headers}
                                />
                        }
                    </div>
                </div>

            </div>

        </>
    )
}
export default Content;