import React, { useEffect, useState } from 'react'
import TitleLabel from '../../CommonComponent/TitleLabel'
import { DatePicker, LocalizationProvider } from '@mui/lab'
import { Autocomplete, FormControl, FormControlLabel, InputLabel, MenuItem, Paper, Radio, RadioGroup, Select, TextField } from '@mui/material'
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import makeStyles from "@mui/styles/makeStyles";
import { useSelector } from 'react-redux';
import { getInwardEntryAPIURL, getInwardEntryNoAPIURL, saveInwardEntryAPIURL, updateInwardEntryAPIURL } from '../Services/InwardEntryAPIURL';
import axios from 'axios';
import Loader from '../../CommonComponent/Loader';
import { BaseURLInstituteMaster } from '../../CommonServices/InstituteMasterAPIURL';
import { toast } from 'react-toastify';
import moment from 'moment';
import { useNavigate, useParams } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
    menuPaper: {
        maxHeight: 200,
        "& ul": {
            backgroundColor: "#F4ECF7",
            //maxHeight: 160,
        },
        "& li": {
            // backgroundColor: "#F4ECF7", fontWeight: "550", fontSize: "14px" 
            fontSize: "14px",
            fontWeight: "550"
        },
    }
}));

const InwardEntryForm = () => {
    const titleId = "Inward Entry Form"
    const classes = useStyles();
    const authUser = useSelector((state) => state.user.value);

    const navigate = useNavigate();

    const { ieId, EditFlag } = useParams();
    const [loaderOption, setLoaderOption] = useState(false);

    //Note: This below states are declared for Save & Update(Disabled/unDisabled)

    const saveBtnStyle = "inwardEntrySaveBtn btn btn-primary btn-sm";
    const updateBtnStyle = "inwardEntryUpdateBtn btn btn-primary btn-sm";
    const displayNoneBtnStyle = "d-none";
    const [saveBtn, setSaveBtn] = useState(saveBtnStyle);
    const [updateBtn, setUpdateBtn] = useState(displayNoneBtnStyle);

    const [saveBtnDisabled, setSaveBtnDisabled] = useState(false);
    const [updateBtnDisabled, setUpdateBtnDisabled] = useState(false);

    const [id, setId] = useState('');
    const [entryDate, setEntryDate] = useState(null);
    const [inwardNo, setInwardNo] = useState('');
    const [letterDate, setLetterDate] = useState(null);
    const [optionId, setOptionId] = useState('');
    const [branch, setBranch] = useState("");
    const [branchData, setBranchData] = useState([]);
    const [receivedFrom, setReceivedFrom] = useState("");
    const [replyToOurLetter, setReplyToOurLetter] = useState(2);
    const [outwardNo, setOutwardNo] = useState('');
    const [outwardDate, setOutwardDate] = useState(null);
    const [contentOfLetter, setContentOfLetter] = useState("");
    const [letterRecDate, setLetterRecDate] = useState(null);
    const [remark, setRemark] = useState("");
    const [detailsOfReply, setDetailsOfReply] = useState(2);
    const [outwardNo1, setOutwardNo1] = useState('');
    const [outwardDate1, setOutwardDate1] = useState(null);

    useEffect(() => {
        if (EditFlag == 1) {
            getData();
            setSaveBtnDisabled(false);
            setUpdateBtnDisabled(true);
        }
        else if (EditFlag == 2) {
            getEditDetails();
            setSaveBtnDisabled(true);
            setUpdateBtnDisabled(false);
        }
    }, [authUser])

    const getData = async () => {
        setLoaderOption(true);
        if (authUser.deptId != null && authUser.branchId != null && authUser.sessionYear != '') {
            await axios(`${getInwardEntryNoAPIURL}?year=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}`)
                .then(res => {
                    setInwardNo(res.data);
                    setLoaderOption(false);
                })
        }

        // console.log(BaseURLInstituteMaster)
        axios.get(BaseURLInstituteMaster)
            .then((response) => {
                setBranchData(response.data);
                setLoaderOption(false);
            })

    }

    const getEditDetails = async () => {
        setLoaderOption(true);
        if (authUser.deptId != null && authUser.branchId != null && authUser.sessionYear != '') {
            await axios.get(`${getInwardEntryAPIURL}?year=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}`)
                .then(res => {
                    setSaveBtnDisabled(true);
                    setUpdateBtnDisabled(false);
                    setSaveBtn(displayNoneBtnStyle);
                    setUpdateBtn(updateBtnStyle);
                    const tempData = res.data.find(e => e.id == ieId)

                    // console.log(tempData)

                    setId(tempData.id)
                    setEntryDate(tempData.entryDate);
                    setInwardNo(tempData.inwardNo)
                    setLetterDate(tempData.letterDate);
                    setOptionId(tempData.option)
                    setBranch(tempData.branchName)
                    setReplyToOurLetter(tempData.replyToLetter);
                    setOutwardNo(tempData.replyToOutwardNo)
                    setOutwardDate(tempData.replyToOutwardDate);
                    setContentOfLetter(tempData.contentOfInward)
                    setLetterRecDate(tempData.letterReceivedDate);
                    setRemark(tempData.remark)
                    setDetailsOfReply(tempData.detailsOfReply);
                    setOutwardNo1(tempData.replyOutwardNo)
                    setOutwardDate1(tempData.replyOutwardDate);

                    axios.get(BaseURLInstituteMaster)
                        .then((response) => {
                            setBranchData(response.data);
                            setLoaderOption(false);
                        })

                    setLoaderOption(false);
                }).catch(err => {
                    console.log("Get inventory vendor master err " + err)
                })
        }
    }


    const saveBtnClick = async () => {
        setLoaderOption(true);
        const saveObj = {
            "inwardNo": inwardNo,
            "entryDate": moment(entryDate).format("YYYY-MM-DD"),
            "letterDate": moment(letterDate).format("YYYY-MM-DD"),
            "option": optionId,
            "branchName": branch,
            "replyToLetter": replyToOurLetter,
            "replyToOutwardNo": outwardNo,
            "replyToOutwardDate": (replyToOurLetter == 1) ? moment(outwardDate).format("YYYY-MM-DD") : null,
            "contentOfInward": contentOfLetter,
            "letterReceivedDate": moment(letterRecDate).format("YYYY-MM-DD"),
            "detailsOfReply": detailsOfReply,
            "replyOutwardNo": outwardNo1,
            "replyOutwardDate": (detailsOfReply == 1) ? moment(outwardDate1).format("YYYY-MM-DD") : null,
            "remark": remark,
            "sessionYear": authUser.sessionYear,
            "sectionId": authUser.deptId,
            "branchId": authUser.branchId
        }
        // console.log(JSON.stringify(saveObj))
        await axios.post(saveInwardEntryAPIURL, saveObj)
            .then(res => {
                if (res.data == "SAVED") {
                    clearData();
                    getData();
                    setLoaderOption(false);
                    toast.success("Saved Successfully.")
                }
                else {
                    setLoaderOption(false);
                    toast.error("Inward Entry Save Failed.")
                }
            }).catch(err => {
                console.log("Inward Entry save" + err);
                setLoaderOption(false);
                toast.error("Something went wrong, please check.")
            })
    }

    const updateBtnClick = async () => {
        setLoaderOption(true);
        const updateObj = {
            "id": id,
            "inwardNo": inwardNo,
            "entryDate": moment(entryDate).format("YYYY-MM-DD"),
            "letterDate": moment(letterDate).format("YYYY-MM-DD"),
            "option": optionId,
            "branchName": branch,
            "replyToLetter": replyToOurLetter,
            "replyToOutwardNo": outwardNo,
            "replyToOutwardDate": (replyToOurLetter == 1) ? moment(outwardDate).format("YYYY-MM-DD") : null,
            "contentOfInward": contentOfLetter,
            "letterReceivedDate": moment(letterRecDate).format("YYYY-MM-DD"),
            "detailsOfReply": detailsOfReply,
            "replyOutwardNo": outwardNo1,
            "replyOutwardDate": (detailsOfReply == 1) ? moment(outwardDate1).format("YYYY-MM-DD") : null,
            "remark": remark,
            "sessionYear": authUser.sessionYear,
            "sectionId": authUser.deptId,
            "branchId": authUser.branchId
        }
        // console.log(JSON.stringify(updateObj))
        await axios.put(updateInwardEntryAPIURL, updateObj)
            .then(res => {
                if (res.data == "UPDATED") {
                    clearData();
                    getData();
                    setLoaderOption(false);
                    toast.success("Updated Successfully.")
                }
                else {
                    setLoaderOption(false);
                    toast.error("Inward Entry update Failed.")
                }
            }).catch(err => {
                console.log("Inward Entry update" + err);
                setLoaderOption(false);
                toast.error("Something went wrong, please check.")
            })
    }

    const clearData = () => {
        setId('');
        setEntryDate(null);
        setInwardNo('');
        setLetterDate(null);
        setOptionId('');
        setBranch("");
        setReceivedFrom("");
        setReplyToOurLetter(2);
        setOutwardNo('');
        setOutwardDate(null);
        setContentOfLetter("");
        setLetterRecDate(null);
        setRemark("");
        setDetailsOfReply(2);
        setOutwardNo1('');
        setOutwardDate1(null);
        setSaveBtnDisabled(false);
        setUpdateBtnDisabled(true);
        setSaveBtn(saveBtnStyle);
        setUpdateBtn(displayNoneBtnStyle);
        navigate(`/Home/ie1104/`)
    }


    const state = {
        button: 1
    };

    const onSubmit = e => {
        e.preventDefault();
        if (state.button === 1) {
            saveBtnClick();
        }
        if (state.button === 2) {
            updateBtnClick();
        }
    };

    if (loaderOption == true) {
        return (
            <Loader />
        )
    }
    else {
        return (
            <>
                <TitleLabel titleId={titleId} />
                <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px" }}>
                    {/* Form Data start */}
                    <form onSubmit={onSubmit}>
                        <div className="form-data mt-1">
                            <input type="hidden" value={id} onChange={(e) => setId(e.target.value)} name="" />
                            <div className="row my-2">
                                <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Inward No :</label>
                                <div className='col-sm-3'>
                                    <TextField
                                        required
                                        fullWidth
                                        autoComplete='off'
                                        size='small'
                                        id="outlined-basic"
                                        label="Inward No"
                                        variant="outlined"
                                        margin='dense'
                                        value={inwardNo}
                                        onKeyPress={(e) => (e.key == "Enter") ? inwardNo : ""}
                                        InputLabelProps={{ style: { fontSize: 14 } }}
                                        inputProps={{ readOnly: true }}
                                    />
                                </div>

                                <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Entry Date :</label>
                                <div className='col-sm-3'>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DatePicker
                                            label="Entry Date"
                                            value={entryDate}
                                            onChange={(newValue) => {
                                                setEntryDate(newValue);
                                            }}
                                            onKeyPress={(e) => (e.key == "Enter") ? entryDate : ""}
                                            inputFormat="dd/MM/yyyy"
                                            renderInput={(params) =>
                                                <TextField
                                                    {...params}
                                                    margin='dense'
                                                    variant="outlined"
                                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                                    required
                                                    fullWidth
                                                    autoComplete='off'
                                                    size="small" />}
                                        />
                                    </LocalizationProvider>
                                </div>
                            </div>

                            <div className="row my-2">
                                <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Letter Date :</label>
                                <div className='col-sm-3'>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DatePicker
                                            label="Letter Date"
                                            value={letterDate}
                                            onChange={(newValue) => {
                                                setLetterDate(newValue);
                                            }}
                                            onKeyPress={(e) => (e.key == "Enter") ? letterDate : ""}
                                            inputFormat="dd/MM/yyyy"
                                            renderInput={(params) =>
                                                <TextField
                                                    {...params}
                                                    margin='dense'
                                                    variant="outlined"
                                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                                    required
                                                    fullWidth
                                                    autoComplete='off'
                                                    size="small" />}
                                        />
                                    </LocalizationProvider>
                                </div>

                                <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>option :</label>
                                <div className="col-sm-3">
                                    <FormControl fullWidth margin='dense'>
                                        <InputLabel size="small" id='demo-simple-select-label' style={{ fontSize: '14px' }}>Select option</InputLabel>
                                        <Select
                                            size='small'
                                            MenuProps={{ classes: { paper: classes.menuPaper } }}
                                            required
                                            fullWidth
                                            margin='dense'
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={optionId}
                                            label="Select option"
                                            onChange={(e) => {
                                                setOptionId(e.target.value);
                                                setBranch("");
                                            }}
                                        >
                                            <MenuItem style={{ backgroundColor: "#F4ECF7", fontWeight: "550", fontSize: "14px" }} value={1}>Inside Branch</MenuItem>
                                            <MenuItem style={{ backgroundColor: "#F4ECF7", fontWeight: "550", fontSize: "14px" }} value={2}>Other</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>

                            {(() => {
                                if (optionId == 1) {
                                    return (
                                        <>
                                            <div className="row my-2">
                                                <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Branch :</label>
                                                <div className='col-sm-8'>

                                                    <Autocomplete
                                                        id="combo-box-demo"
                                                        PaperComponent={({ children }) => (
                                                            <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                                        )}
                                                        freeSolo
                                                        disableClearable
                                                        onKeyDown={(e) => (e.key === " " && branch.length === 0) ? e.preventDefault() : ""}
                                                        options={(branch === "") ? [] : branchData}
                                                        value={branch}
                                                        onChange={(event, newValue) => {
                                                            //setbranchId(newValue.id);
                                                            //console.log(newValue.branchDetail);
                                                            setBranch((authUser.branchMedium == 1) ? newValue.schoolNameMr : newValue.schoolNameEn);
                                                        }}
                                                        inputValue={branch}
                                                        onInputChange={(event, newInputValue) => {
                                                            //setbranchId(newInputValue.id);
                                                            //console.log(newInputValue);
                                                            setBranch(newInputValue);
                                                        }}
                                                        onKeyPress={(e) => (e.key == "Enter") ? branch : ""}
                                                        style={{ fontSize: '14px' }}
                                                        getOptionLabel={option => {

                                                            if (typeof option === 'string') {
                                                                return option;
                                                            }
                                                            // Add "xxx" option created dynamically
                                                            if (option.inputValue) {
                                                                //console.log("option........"+JSON.stringify(option.inputValue))
                                                                return (authUser.branchMedium == 1) ? option.id + " . " + option.schoolNameMr : option.id + " . " + option.schoolNameEn;
                                                            }
                                                            // Regular option
                                                            return (authUser.branchMedium == 1) ? option.id + " . " + option.schoolNameMr : option.id + " . " + option.schoolNameEn;
                                                        }

                                                        }
                                                        size="small"
                                                        renderInput={params => (
                                                            <TextField {...params} margin='dense' label="Branch" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required={(optionId == 1) ? true : false} />
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                        </>
                                    )
                                }
                                else if (optionId == 2) {
                                    return (
                                        <>
                                            <div className="row my-2">
                                                <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Other :</label>
                                                <div className='col-sm-8'>
                                                    <TextField
                                                        required={(optionId == 2) ? true : false}
                                                        fullWidth
                                                        autoComplete='off'
                                                        size='small'
                                                        id="outlined-basic"
                                                        label="Other"
                                                        variant="outlined"
                                                        margin='dense'
                                                        value={branch}
                                                        onChange={(e) => {
                                                            setBranch(e.target.value);
                                                        }}
                                                        onKeyPress={(e) => (e.key == "Enter") ? branch : ""}
                                                        InputLabelProps={{ style: { fontSize: 14 } }}
                                                    />
                                                </div>
                                            </div>
                                        </>
                                    )
                                }
                            })()}

                            <div className="row my-2">
                                {/* <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Received From :</label>
                                <div className='col-sm-4'>
                                    <TextField
                                        required
                                        fullWidth
                                        autoComplete='off'
                                        size='small'
                                        id="outlined-basic"
                                        label="Received From"
                                        variant="outlined"
                                        margin='dense'
                                        value={receivedFrom}
                                        onChange={(e) => {
                                            setReceivedFrom(e.target.value);
                                        }}
                                        onKeyPress={(e) => (e.key == "Enter") ? receivedFrom : ""}
                                        InputLabelProps={{ style: { fontSize: 14 } }}
                                    />
                                </div> */}

                                <label className="col-sm-2 mt-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Reply To Our Which Letter :</label>
                                <div className='col-sm-3'>
                                    <RadioGroup
                                        row
                                        name="customized-radios"
                                        value={replyToOurLetter}
                                        onKeyPress={(e) => (e.key == "Enter") ? replyToOurLetter : ""}
                                        onChange={(e) => { setReplyToOurLetter(parseInt(e.target.value)); setOutwardNo(''); setOutwardDate(null) }}>
                                        <FormControlLabel value={1} control={<Radio color='primary' />} label="Yes" />
                                        <FormControlLabel value={2} control={<Radio color='primary' />} label="No" />
                                    </RadioGroup>
                                </div>
                            </div>

                            {(() => {
                                if (replyToOurLetter == 1) {
                                    return (
                                        <>
                                            <div className="row my-2">
                                                <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Outward No :</label>
                                                <div className='col-sm-3'>
                                                    <TextField
                                                        required={(replyToOurLetter == 1) ? true : false}
                                                        fullWidth
                                                        autoComplete='off'
                                                        size='small'
                                                        id="outlined-basic"
                                                        label="Outward No"
                                                        variant="outlined"
                                                        margin='dense'
                                                        value={outwardNo}
                                                        onChange={(e) => {
                                                            setOutwardNo(e.target.value);
                                                        }}
                                                        onKeyPress={(e) => (e.key == "Enter") ? outwardNo : ""}
                                                        InputLabelProps={{ style: { fontSize: 14 } }}
                                                    />
                                                </div>

                                                <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Outward Date :</label>
                                                <div className='col-sm-3'>
                                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                        <DatePicker
                                                            label="Outward Date"
                                                            value={outwardDate}
                                                            onChange={(newValue) => {
                                                                setOutwardDate(newValue);
                                                            }}
                                                            onKeyPress={(e) => (e.key == "Enter") ? outwardDate : ""}
                                                            inputFormat="dd/MM/yyyy"
                                                            renderInput={(params) =>
                                                                <TextField
                                                                    {...params}
                                                                    margin='dense'
                                                                    variant="outlined"
                                                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                                                    required={(replyToOurLetter == 1) ? true : false}
                                                                    fullWidth
                                                                    autoComplete='off'
                                                                    size="small" />}
                                                        />
                                                    </LocalizationProvider>
                                                </div>
                                            </div>

                                        </>
                                    )
                                }
                            })()}

                            <div className="row my-2">
                                <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Contents Of Inward Letter :</label>
                                <div className='col-sm-8'>
                                    <TextField
                                        required
                                        fullWidth
                                        rows={3}
                                        multiline
                                        autoComplete='off'
                                        size='small'
                                        id="outlined-basic"
                                        label="Contents Of Inward Letter"
                                        variant="outlined"
                                        margin='dense'
                                        value={contentOfLetter}
                                        onChange={(e) => {
                                            setContentOfLetter(e.target.value);
                                        }}
                                        onKeyPress={(e) => (e.key == "Enter") ? contentOfLetter : ""}
                                        InputLabelProps={{ style: { fontSize: 14 } }}
                                    />
                                </div>
                            </div>

                            <div className="row my-2">
                                <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Letter Received Date :</label>
                                <div className='col-sm-3'>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DatePicker
                                            label="Letter Received Date"
                                            value={letterRecDate}
                                            onChange={(newValue) => {
                                                setLetterRecDate(newValue);
                                            }}
                                            onKeyPress={(e) => (e.key == "Enter") ? letterRecDate : ""}
                                            inputFormat="dd/MM/yyyy"
                                            renderInput={(params) =>
                                                <TextField
                                                    {...params}
                                                    margin='dense'
                                                    variant="outlined"
                                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                                    required
                                                    fullWidth
                                                    autoComplete='off'
                                                    size="small" />}
                                        />
                                    </LocalizationProvider>
                                </div>

                                <label className="col-sm-2 mt-3" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Details Of Reply :</label>
                                <div className='col-sm-3 mt-1'>
                                    <RadioGroup
                                        row
                                        name="customized-radios"
                                        value={detailsOfReply}
                                        onKeyPress={(e) => (e.key == "Enter") ? detailsOfReply : ""}
                                        onChange={(e) => { setDetailsOfReply(parseInt(e.target.value)); setOutwardNo1(''); setOutwardDate1(null) }}>
                                        <FormControlLabel value={1} control={<Radio color='primary' />} label="Yes" />
                                        <FormControlLabel value={2} control={<Radio color='primary' />} label="No" />
                                    </RadioGroup>
                                </div>
                            </div>

                            {(() => {
                                if (detailsOfReply == 1) {
                                    return (
                                        <>
                                            <div className="row my-2">
                                                <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Outward No :</label>
                                                <div className='col-sm-3'>
                                                    <TextField
                                                        required={(detailsOfReply == 1) ? true : false}
                                                        fullWidth
                                                        autoComplete='off'
                                                        size='small'
                                                        id="outlined-basic"
                                                        label="Outward No"
                                                        variant="outlined"
                                                        margin='dense'
                                                        value={outwardNo1}
                                                        onChange={(e) => {
                                                            setOutwardNo1(e.target.value);
                                                        }}
                                                        onKeyPress={(e) => (e.key == "Enter") ? outwardNo1 : ""}
                                                        InputLabelProps={{ style: { fontSize: 14 } }}
                                                    />
                                                </div>

                                                <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Outward Date :</label>
                                                <div className='col-sm-3'>
                                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                        <DatePicker
                                                            label="Outward Date"
                                                            value={outwardDate1}
                                                            onChange={(newValue) => {
                                                                setOutwardDate1(newValue);
                                                            }}
                                                            onKeyPress={(e) => (e.key == "Enter") ? outwardDate1 : ""}
                                                            inputFormat="dd/MM/yyyy"
                                                            renderInput={(params) =>
                                                                <TextField
                                                                    {...params}
                                                                    margin='dense'
                                                                    variant="outlined"
                                                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                                                    required={(detailsOfReply == 1) ? true : false}
                                                                    fullWidth
                                                                    autoComplete='off'
                                                                    size="small" />}
                                                        />
                                                    </LocalizationProvider>
                                                </div>
                                            </div>

                                        </>
                                    )
                                }
                            })()}

                            <div className="row my-2">
                                <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Remark :</label>
                                <div className='col-sm-8'>
                                    <TextField
                                        required
                                        fullWidth
                                        rows={2}
                                        multiline
                                        autoComplete='off'
                                        size='small'
                                        id="outlined-basic"
                                        label="Remark"
                                        variant="outlined"
                                        margin='dense'
                                        value={remark}
                                        onChange={(e) => {
                                            setRemark(e.target.value);
                                        }}
                                        onKeyPress={(e) => (e.key == "Enter") ? remark : ""}
                                        InputLabelProps={{ style: { fontSize: 14 } }}
                                    />
                                </div>
                            </div>

                            <div className="row mt-3">
                                <div className="col-sm-12 offset-sm-2">
                                    <button type="submit" disabled={saveBtnDisabled} onClick={() => (state.button = 1)} className={saveBtn} >Save</button>
                                    <button type="submit" disabled={updateBtnDisabled} onClick={() => (state.button = 2)} className={updateBtn} >Update</button>
                                    <button type="button" onClick={clearData} className="btn btn-danger btn-sm" style={{ margin: '0px 4px' }} >Cancel</button>
                                </div>
                            </div>
                        </div>
                    </form >
                </div >
            </>
        )
    }
}

export default InwardEntryForm
