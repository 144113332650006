import React, { useState, useEffect, useRef } from 'react';
import TitleLabel from "../../CommonComponent/TitleLabel";
import axios from 'axios';
import Paper from "@mui/material/Paper";
import TextField from '@mui/material/TextField';
import Autocomplete from "@mui/material/Autocomplete";
import makeStyles from "@mui/styles/makeStyles";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import Loader from '../../CommonComponent/Loader';
import { GetStudentLcData } from '../Services/LcPrintAPIURL';
import TablePagination from '@mui/material/TablePagination'
import Tooltip from '@mui/material/Tooltip';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash, faEye } from '@fortawesome/free-solid-svg-icons'
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { saveStudentPhotoAPIURL, deleteStudentImageAPIURL, getAllStudentsImageDetails, viewStudentImageAPIURL, imagePath } from '../Services/StudentIdcardAPIURL';
import DeleteModalBox from '../../CommonComponent/DeleteModalBox';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { getCurrentStudentList, getStudentList } from '../../FeeManagement/Services/FeeReceiptAPIURL';
import CircularProgress from '@mui/material/CircularProgress';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import warningImg from '../../assets/warningImg/warning.png'
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { getLeftStudentDetailsAPIURL } from '../../CommonServices/LeavingCertificateAPIURL';
import AddStudentBySearching from '../../CommonComponent/AddStudenyBySearching';

const useStyles = makeStyles({
  caption: {
    padding: 0,
    fontWeight: 600,
    fontSize: "12px"
  },
  toolbar: {
    fontSize: "12px",
    fontWeight: 600
  },
  selectLabel: {
    fontSize: "12px",
    fontWeight: 500,
    margin: "-10px",
    padding: "0"
  },
  select: {
    fontSize: "12px",
    margin: "0",
    padding: "0"
  },
  displayedRows: {
    fontSize: "12px",
    margin: "-22px",
    padding: "0"
  },
  menuItem: {
    fontSize: "12px",
    fontWeight: 500,
  }
});

function sleep(delay = 0) {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
}

const StudentsIdCard = () => {
  const authUser = useSelector((state) => state.user.value);
  const titleId = "Student Photo";
  const classes = useStyles();

  const [loaderOption, setLoaderOption] = useState(false);
  const [image, setImage] = useState(null);
  const [studentData, setStudentData] = useState([]);
  const [studentObj, setStudentObj] = useState(null);
  const [id, setId] = useState('');

  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const loading = open && options.length === 0;

  useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      await sleep(1e3); // For demo purposes.

      if (active) {
        setOptions([...studentData]);
      }
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  const [showImage, setShowImage] = useState(null);

  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [searchTerm, setSearchTerm] = useState("");
  const [offset, setOffset] = useState(0);
  const [limits, setLimits] = useState(200);
  const [numCal, setNumCall] = useState(0);
  const [fileInSize, setFileInSize] = useState('');

  const [openWarningModal, setOpenWarningModal] = useState(false);

  const [studentLcNo, setStudentLcNo] = useState('');
  const [studentDetails, setStudentDetails] = useState({});
  const [division, setDivision] = useState("");
  const [classData, setclassData] = useState("");
  const [selectedStudent, setSelectedStudent] = useState("");
  const [studentId, setStudentId] = useState('');

  const onPageChange = (event, nextPage) => {
    setPage(nextPage);
  }

  const onRowsPerPageChange = (e) => {
    setRowsPerPage(parseInt(e.target.value));
    setPage(0);
  }

  //state and ref for capture image
  const [result, setResult] = useState(false);
  const handleFileInput = useRef(null);

  useEffect(() => {
    chkDevice();
  }, [])

  //check respective device has wbcamera or camera
  const chkDevice = async () => {
    if (navigator.mediaDevices != null) {
      // setResult(false);
      navigator.mediaDevices.enumerateDevices()
        .then(function (devices) {
          //alert(devices)
          const videoDevices = devices.some(device => device.kind != 'videoinput')
          setResult(videoDevices)
        }).catch(err => {
          toast.error("Error:" + err)
        })
    }
  }

  //open camera function
  const handleClick = async () => {
    if (result == true) {
      //toast.warn("Webcam or camera is not found in this device.")
      setOpenWarningModal(true);
    }
    else {
      handleFileInput.current.click();
    }
  };

  //set or get image path code
  // const onChangePicture = e => {
  //   setImage(e.target.files[0]);
  //   if (e.target.files[0] != null) {
  //     setShowImage(URL.createObjectURL(e.target.files[0]));
  //     //alert(e.target.files[0].size)
  //   }
  //   else {
  //     setShowImage(null);
  //   }
  // };

  const onChangePicture = e => {
    setImage(e.target.files[0]);
    // console.log(e.target.files[0])
    if (e.target.files[0] != null) {
      setShowImage(URL.createObjectURL(e.target.files[0]));
      const fileSizeInBytes = e.target.files[0].size;
      const fileSizeInMB = fileSizeInBytes / (1024 * 1024);
      setFileInSize(fileSizeInMB.toFixed(2))
      // alert(`File size: ${fileSizeInMB.toFixed(2)} MB`);

    }
    else {
      setShowImage(null);
    }
  };

  useEffect(() => {
    if (authUser.deptId !== null && authUser.branchId !== null) {
      getData();
    }
  }, [authUser, numCal])


  const getData = async () => {

    // if (offset === 0) {
    //   setLoaderOption(true)
    // }
    // console.log(`${getCurrentStudentList}?sid=${authUser.deptId}&bid=${authUser.branchId}&year=${authUser.sessionYear}&limit=${limits}&offset=${offset}`)
    // await axios.get(`${getCurrentStudentList}?sid=${authUser.deptId}&bid=${authUser.branchId}&year=${authUser.sessionYear}&limit=${limits}&offset=${offset}`)
    //   .then((res) => {
    //     if (res.data !== null) {
    //       const tempData = res.data;
    //       setStudentData(studentData.concat(tempData));
    //       setLoaderOption(false)
    //       if (tempData.length === limits) {
    //         setOffset(offset + limits);
    //         setNumCall(numCal + 1);
    //       }
    //     }
    //   })

    // console.log(`${getLeftStudentDetailsAPIURL}?sid=${authUser.deptId}&bid=${authUser.branchId}&limit=${limits}&offset=${offset}`)
    await axios.get(`${getLeftStudentDetailsAPIURL}?sid=${authUser.deptId}&bid=${authUser.branchId}&limit=${limits}&offset=${offset}`)
      .then((res) => {
        if (res.data !== null) {
          const tempData = res.data;
          setStudentData(studentData.concat(tempData));
          setLoaderOption(false)
          if (tempData.length === limits) {
            setOffset(offset + limits);
            setNumCall(numCal + 1);
          }
        }
      })
  }

  useEffect(() => {
    getStudentDeatils();
  }, [authUser])


  const getStudentDeatils = async () => {
    //setLoaderOption(true);
    if (authUser.deptId != null && authUser.branchId != null) {
      await axios.get(`${getAllStudentsImageDetails}?sid=${authUser.deptId}&bid=${authUser.branchId}`)
        .then(res => {
          setData(res.data);
          setFilteredData(res.data);
          setLoaderOption(false);
        })
    }
  }

  const clearData = () => {
    setImage(null);
    setStudentObj(null);
    setId('');
    setShowImage(null);
    setSelectedStudent("");
    setclassData("");
    setStudentId("");
    setDivision("");
  }

  // const saveBtnClick = async (e) => {
  //   e.preventDefault();
  //   setLoaderOption(true);
  //   const formData = new FormData();
  //   formData.append("img", image);
  //   formData.append("regNo", studentId)
  //   formData.append("sid", authUser.deptId)
  //   formData.append("bid", authUser.branchId)

  //   console.log(Object.fromEntries(formData))

  //   await axios.post(saveStudentPhotoAPIURL, formData)
  //     .then(res => {
  //       if (res.data == "SAVED") {
  //         setLoaderOption(false);
  //         clearData();
  //         getStudentDeatils();
  //         toast.success("Photo upload done successfully.")
  //       }
  //     }).catch(err => {
  //       setLoaderOption(false);
  //       console.log("Student photo upload" + err)
  //       toast.error("Something went wrong, please check.")
  //     })
  // }

  const saveBtnClick = async (e) => {
    e.preventDefault();
    setLoaderOption(true);
    const formData = new FormData();
    formData.append("img", image);
    formData.append("regNo", studentId)
    formData.append("sid", authUser.deptId)
    formData.append("bid", authUser.branchId)


    // console.log(formDataToObj(formData))
    if (fileInSize > 1) {
      setLoaderOption(false);
      toast.warn("Image size should be less than 1 MB.")
    }
    else {
      // alert(JSON.stringify(formDataToObj(formData)));

      await axios.post(saveStudentPhotoAPIURL, formData)
        .then(res => {
          if (res.data == "SAVED") {
            setLoaderOption(false);
            clearData();
            getStudentDeatils();
            toast.success("Photo upload done successfully.")
          }
        }).catch(err => {
          setLoaderOption(false);
          console.log("Student photo upload" + err)
          toast.error("Something went wrong, please check.")
        })
    }

  }


  const TableHeading = [
    { label: 'Id', key: 'id' },
    { label: `Student Name`, key: 'studentNameMr' },
    { label: `Class-Division`, key: 'classDivision' },
    { label: `Student Image`, key: 'image', isAction: true },
    { label: 'Delete', value: 'delete', isAction: true }
  ];

  const [deleteId, setDeleteId] = useState('');

  const DeleteData = async (id) => {
    setLoaderOption(true);
    await axios.delete(`${deleteStudentImageAPIURL}?regNo=${id}&sid=${authUser.deptId}&bid=${authUser.branchId}`)
      .then(res => {
        if (res.data == "DELETED") {
          setLoaderOption(false);
          getStudentDeatils();
          toast.success("Deleted Successfully.")
        }
      }).catch(err => {
        setLoaderOption(false);
        console.log("Student photo delete" + err);
        toast.error("Something went wrong, please check.")
      })
  }

  //open the image from table
  const showStudentImage = (studImage) => {
    // console.log(`${imagePath}${studImage}`);
    window.open(`${imagePath}${studImage}`);
  }

  useEffect(() => {
    setFilteredData([]);
    let tempFilteredData = data.filter((item) => (
      item.studentNameMr.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.studentNameEn.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.classMr.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.classEn.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.divMr.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.divEn.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.id.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.regNo.toString().toLowerCase().includes(searchTerm.toLowerCase())
    ))
    setFilteredData(tempFilteredData);
  }, [searchTerm])



  return (
    <>
      {
        (loaderOption == true)
          ?
          <Loader />
          :
          <>
            <TitleLabel titleId={titleId} />
            <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: "350px" }}>

              <div className="row">
                <div className="col-sm-9">
                  <p className='' style={{ backgroundColor: "#FA6969", borderRadius: "4px", fontWeight: "520", fontSize: "13px", margin: "4px", padding: "4px", color: "#fff", lineHeight: "1.4" }}>Note : If you want to change the image, then delete it first and upload it again. Upload a maximum of 1 MB of photos. </p>
                </div>
              </div>

              <form onSubmit={saveBtnClick}>
                <input type='hidden' value={id} onChange={(e) => setId(e.target.value)} />

                {/* <div className="row my-2">
                  <label className="col-sm-2" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }} >Student :</label>
                  <div className='col-sm-5'>
                    <Autocomplete
                      id="combo-box-demo"
                      options={studentData}
                      PaperComponent={({ children }) => (
                        <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                      )}
                      onChange={(event, newValue) => {
                        setStudentObj(newValue);
                      }}
                      onKeyPress={(e) => (e.key == "Enter") ? studentObj : ""}
                      value={studentObj}
                      style={{ fontSize: '14px' }}
                      getOptionLabel={option => (authUser.branchMedium == 1) ? option.regCode + " - " + option.studentNameMr : option.regCode + " - " + option.studentNameEn}
                      renderOption={(props, option) => (
                        <div className='' {...props}>
                          <div className=''>
                            {(authUser.branchMedium == 1) ? option.regCode + " - " + option.studentNameMr + "  " + option.currentClassMr + "  " + option.currentDivisionMr : option.regCode + " - " + option.studentNameEn + "  " + option.currentClassEn + "  " + option.currentDivisionEn}
                          </div>

                        </div>
                      )}
                      size="small"
                      renderInput={params => (
                        <TextField {...params}
                          margin='dense' label="Select Student" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }}
                          required />
                      )}
                    />
                  </div>
                </div> */}
                <div className="my-2">
                  <AddStudentBySearching
                    setclassData={setclassData}
                    setDivision={setDivision}
                    setStudentId={setStudentId}
                    setSelectedStudent={setSelectedStudent}
                    classData={classData}
                    division={division}
                    studentId={studentId}
                    selectedStudent={selectedStudent}
                    setStudentLcNo={setStudentLcNo}
                    label="Student Photo"
                    setStudentDetails={setStudentDetails}
                    className="col-sm-2"
                    readOnly={false}
                    setLoaderOption={setLoaderOption}
                  />
                </div>

                <div className="row my-2">
                  <label className="col-sm-2" style={{ fontSize: "15px", fontWeight: "600", marginTop: "12px" }} >Select Image :</label>
                  <div className='col-sm-3 mt-2'>
                    <input
                      type="file"
                      name="file"
                      onChange={onChangePicture}
                      className='form-control form-control-sm'
                      accept="image/*"
                      required={(showImage == null) ? true : false}
                    //onKeyPress={(e) => (e.key == "Enter") ? setFile(e.target.files[0]) : ''}
                    />
                  </div>

                  <div className="col-sm-4 mt-1">
                    <button type='button' onClick={handleClick} className='btn btn-sm' style={{ backgroundColor: "#D8600D", color: "#fff" }}><CameraAltIcon fontSize='small' /> Capture Photo</button>
                    {
                      (handleFileInput != null)
                        ?
                        <input
                          style={{ display: "none" }}
                          type="file"
                          name="file"
                          accept="image/*"
                          capture="user"
                          ref={handleFileInput}
                          onChange={(e) => onChangePicture(e)}
                        />
                        :
                        ''
                    }
                  </div>
                </div>

                <div className="offset-sm-2" hidden={(showImage == null) ? true : false}>
                  <div className="previewProfilePic">
                    <img className="playerProfilePic_home_tile" src={showImage && showImage} style={{ width: "150px", height: "120px", border: "1px solid #BDC3C7" }}></img>
                  </div>
                </div>

                <div className="row mt-2">
                  <div className="col-sm-4 offset-sm-2">
                    <button type='submit' className='btn btn-sm btn-primary'><FileUploadIcon fontSize="small" />Upload</button>
                  </div>
                </div>
              </form>


              {/* <div className='row mt-4' style={{ float: 'right' }}>
                <div>
                  <input
                    className='form-control form-control-sm'
                    type="text"
                    style={{ width: "250px", display: "block", float: "right", marginBottom: "6px", border: "1px solid #C2C1C1" }}
                    placeholder="Search Here"
                    onChange={(event) => { setFilteredData([]); setSearchTerm(event.target.value); setPage(0); }}
                    value={searchTerm}
                  />
                </div>
              </div>

              <div className='mt-4'>
                <div className='table-responsive' >
                  <table className="table table-bordered">
                    <thead className="table-Default">
                      <tr>
                        {TableHeading.map((item, index) => {
                          return (
                            <th style={{ fontSize: "14px", fontWeight: "600" }} key={index} scope="col">{item.label}</th>
                          )
                        })}
                      </tr>
                    </thead>
                    <tbody style={{ borderTop: "2px solid #464de4", borderBottom: "2px solid #464de4" }}>
                      {filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((item, index) => {
                          return (
                            <React.Fragment key={index}>
                              <tr>
                                <td style={{ fontSize: "15px", fontWeight: "500", width: "100px" }}>{item.id}</td>
                                <td style={{ fontSize: "15px", fontWeight: "500" }}>{item.regNo + " - " + ((authUser.branchMedium == 1) ? item.studentNameMr : item.studentNameEn)}</td>
                                <td style={{ fontSize: "15px", fontWeight: "500", width: "180px" }}>{(authUser.branchMedium == 1) ? (item.classMr + " " + item.divMr) : (item.classEn + " " + item.divEn)}</td>

                                <td style={{ width: "150px" }}>
                                  <span className="badge" onClick={() => showStudentImage(item.studentImg)} style={{ backgroundColor: "#268704", cursor: "pointer", fontSize: "13px" }}><VisibilityIcon fontSize='small' /> View Image</span>
                                </td>

                                <td style={{ width: "150px" }}>
                                  <Tooltip title="Delete">
                                    <span className='btn btn-danger btn-sm mx-1 p-1'
                                      data-bs-toggle="modal" data-bs-target="#exampleModal"
                                      onClick={() => setDeleteId(item.regNo)}
                                    >
                                      <FontAwesomeIcon icon={faTrash} />
                                    </span>
                                  </Tooltip>
                                </td>


                              </tr>
                            </React.Fragment>
                          )
                        })}
                    </tbody>
                    <tfoot style={{ padding: "0px" }}>
                      <tr>
                        <TablePagination
                          style={{ padding: "0px" }}
                          rowsPerPageOptions={[10, 25, 50, 100]}
                          count={data.length}
                          rowsPerPage={rowsPerPage}
                          page={page}
                          onRowsPerPageChange={onRowsPerPageChange}
                          onPageChange={onPageChange}
                          labelRowsPerPage="Rows Per Page :"
                          classes={{ menuItem: classes.menuItem, displayedRows: classes.displayedRows, selectIcon: classes.selectIcon, toolbar: classes.toolbar, caption: classes.caption, selectLabel: classes.selectLabel, select: classes.select }}
                          backIconButtonProps={{ "aria-label": "Previous Page" }}
                          nextIconButtonProps={{ "aria-label": "Next Page" }} />
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div> */}
            </div>

            <Modal show={openWarningModal} onHide={() => setOpenWarningModal(false)} size="sm">
              <Form>
                <Modal.Body>
                  <div className="row" style={{ justifyContent: "center", display: "flex" }}>
                    <span className="text-center" style={{ lineHeight: "2" }}> <img src={warningImg} style={{ width: "65px" }} /></span><br />
                  </div><br />
                  <div className="row">
                    <span style={{ fontSize: "15px", fontWeight: "600", color: "#000", textAlign: "center" }}>Webcam or camera is not found.</span>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="danger" type='button' onClick={() => setOpenWarningModal(false)}>
                    OK
                  </Button>
                </Modal.Footer>
              </Form>
            </Modal>


            <DeleteModalBox deleteId={deleteId} DeleteData={DeleteData} />
          </>
      }
    </>
  )
}

export default StudentsIdCard