import React, { useEffect, useState } from "react";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import { useSelector } from "react-redux";
import Loader from '../../CommonComponent/Loader';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import makeStyles from "@mui/styles/makeStyles";
import moment from "moment/moment";
import BudgetAssetEntryInput from './BudgetEntryTextBoxes/BudgetAssetEntryInput';
import { saveBudgetAssetEntryAPIURL } from "./Services/BudgetEntryAPIURL";

const useStyles = makeStyles({
  caption: {
    padding: 0,
    fontWeight: 600,
    fontSize: "12px"
  },
  toolbar: {
    fontSize: "12px",
    fontWeight: 600
  },
  selectLabel: {
    fontSize: "12px",
    fontWeight: 500,
    margin: "-10px",
    padding: "0"
  },
  select: {
    fontSize: "12px",
    margin: "0",
    padding: "0"
  },
  displayedRows: {
    fontSize: "12px",
    margin: "-22px",
    padding: "0"
  },
  menuItem: {
    fontSize: "12px",
    fontWeight: 500,
  }
});

const BudgetAssetEntryTable = ({ TableHeading4, data, setData, filteredData, setFilteredData, setDepartmentObj,
  setOptionId, setBudgetCategoryObj, departmentObj, getBudgetAssetEntryAPIURL, budgetCategoryObj, totalAssetEntryBudget }) => {
  const authUser = useSelector((state) => state.user.value);
  const navigate = useNavigate();
  const classes = useStyles();

  const [loaderOption, setLoaderOption] = useState(false);

  const [searchTerm, setSearchTerm] = useState("");
  const [isAscending, setAscending] = useState(false);
  const [sortedBy, setSortedBy] = useState(null);

  const onSort = (key, isAction) => {
    if (!isAction) {
      setSortedBy(key)
      if (isAscending) setFilteredData([...data.sort((a, b) => a[key].toString().toLowerCase() < b[key].toString().toLowerCase() ? 1 : -1)])
      else setFilteredData([...data.sort((a, b) => a[key].toString().toLowerCase() > b[key].toString().toLowerCase() ? 1 : -1)])
      setAscending(!isAscending)
      //console.log("Data============" + JSON.stringify(data))

    }
    setFilteredData(data);
    setData(data);
    getSortedData(data, key, isAction);
  }

  //for table sorting
  const getSortedData = async (list, key, isAction) => {

    await axios(`${getBudgetAssetEntryAPIURL}?categoryId=${budgetCategoryObj.id}&sessionYear=${authUser.sessionYear}&sid=${departmentObj.id}&bid=${authUser.branchId}`)
      .then(res => {
        //console.log(res); 
        if (!isAction) {
          setSortedBy(key)
          if (isAscending) setFilteredData([...(res.data).sort((a, b) => a[key].toString().toLowerCase() < b[key].toString().toLowerCase() ? 1 : -1)])
          else setFilteredData([...(res.data).sort((a, b) => a[key].toString().toLowerCase() > b[key].toString().toLowerCase() ? 1 : -1)])
          setAscending(!isAscending)
          //console.log("Data============" + JSON.stringify(data))
        }
        setFilteredData([]);
        const checkedData = list.length === data.length && list.every((o, i) => Object.keys(o).length === Object.keys(data[i]).length && Object.keys(o).every(k => o[k] === data[i][k]))
        //setData(checkedData);
        //console.log("checkedData......" + JSON.stringify(checkedData))
        if (checkedData == true) {
          setFilteredData(data);
        }
      })
  }

  const saveAssetBudgetEntry = async (e) => {
    e.preventDefault();
    setLoaderOption(true);
    let updatedData = data
    updatedData.map((item, index) => {
      updatedData[index] = ({
        "categoryId": item.categoryId,
        "quantity": item.quantity,
        "rate": item.rate,
        "totalRate": item.totalRate,
        "srNo": item.srNo,
        "sessionYear": authUser.sessionYear,
        "sectionId": departmentObj.deptId,
        "branchId": authUser.branchId,
        "sessionSectionId": authUser.deptId
      })
    })
    // console.log(JSON.stringify(updatedData))
    await axios.post(saveBudgetAssetEntryAPIURL, updatedData)
      .then(res => {
        if (res.data == "SAVED") {
          setData([]);
          setFilteredData([]);
          setDepartmentObj(null)
          setOptionId('')
          setBudgetCategoryObj(null)
          setLoaderOption(false);
          toast.success("Budget entry updated successfully.");
        }
      }).catch(err => {
        console.log("Budget entry save err:" + err);
        setLoaderOption(false);
        toast.error("Something went wrong, please check.")
      })
  }

  useEffect(() => {
    setFilteredData([])
    let tempFilteredData =
      data.filter((item) =>
        item.accNameMr.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.accNameEn.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.srNo.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.quantity.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.rate.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.totalRate.toString().toLowerCase().includes(searchTerm.toLowerCase()))
    setFilteredData(tempFilteredData)
    //setFilteredData(data.filter((item) => item.studentNameMr.toLowerCase().includes(searchTerm.toLowerCase()) || item.categoryNameMr.toLowerCase().includes(searchTerm.toLowerCase()) || item.genderLabel.toLowerCase().includes(searchTerm.toLowerCase()) || item.regCode.toString().toLowerCase().includes(searchTerm.toLowerCase()) || item.rollNo.toString().toLowerCase().includes(searchTerm.toLowerCase())))
  }, [searchTerm])

  useEffect(() => {
    function handlekeydownEvent(event) {
      if (event.key === "Enter") {
        event.preventDefault();
        let btn = document.querySelector('.budgetEntryAssetTableBtn')
        if (btn !== null) {
          btn.click();
        }
      }
    }

    document.addEventListener('keypress', handlekeydownEvent)
    return () => {
      document.removeEventListener('keypress', handlekeydownEvent)
    }

  }, []);

  return (
    <>
      {
        (loaderOption == true)
          ?
          <Loader />
          :
          <div className="">
            <div className='row mb-2' style={{ float: 'right' }}>
              <div>
                <input
                  className='form-control form-control-sm'
                  type="text"
                  style={{ width: "250px", display: "block", float: "right", marginBottom: "6px", border: "1px solid #C2C1C1" }}
                  placeholder="Search Here"
                  onChange={(event) => { setFilteredData([]); setSearchTerm(event.target.value) }}
                  value={searchTerm}
                />
              </div>
            </div>
            <form onSubmit={saveAssetBudgetEntry}>
              <div className='table-responsive mb-3' style={{ maxHeight: "400px" }}>
                <table className="table table-bordered border-dark">
                  <thead className="table-Default" style={{ borderTop: "2px solid #959595", borderBottom: "2px solid #464de4" }}>
                    <tr>
                      {TableHeading4.map((item, index) => {
                        return (
                          <th style={{ fontSize: "14px", fontWeight: "600" }}  key={index} scope="col">{item.label}</th>
                        )
                      })}
                    </tr>
                  </thead>
                  <tbody style={{ borderTop: "2px solid #464de4", borderBottom: "2px solid #464de4" }}>
                    {
                      filteredData.map((item, index) => {

                        return (
                          <>
                            <tr key={index}>
                              <td style={{ fontSize: "14px", fontWeight: "500", width: "100px" }}>{item.srNo}</td>
                              <td style={{ fontSize: "14px", fontWeight: "500", lineHeight: "1.4" }}>{item.accNameMr}</td>

                              <BudgetAssetEntryInput initialText1={item.quantity} initialText2={item.rate} initialText3={item.totalRate}
                                onChange={(value1, value2, value3) => {
                                  let updatedData = filteredData
                                  updatedData[index] = ({
                                    ...item,
                                    quantity: value1,
                                    rate: value2,
                                    totalRate: value3
                                  })

                                  setData(updatedData)
                                }}
                              />
                            </tr>
                          </>
                        )
                      })
                    }
                  </tbody>
                  <tfoot style={{ borderBottom: "2px solid #959595" }}>
                    <tr>
                      <td style={{ fontSize: "15px", fontWeight: "600", textAlign: "right" }}></td>
                      <td style={{ fontSize: "15px", fontWeight: "600", textAlign: "right" }}>एकूण</td>
                      <td style={{ fontSize: "15px", fontWeight: "600", textAlign: "right" }}></td>
                      <td style={{ fontSize: "15px", fontWeight: "600", textAlign: "right" }}></td>
                      <td style={{ fontSize: "15px", fontWeight: "600", textAlign: "right" }}>{(totalAssetEntryBudget).toFixed('2')}</td>
                    </tr>
                  </tfoot>
                </table>
              </div>

              <div className='mt-2'>
                <button className='budgetEntryAssetTableBtn btn btn-primary btn-sm mb-2' type="submit"><AddIcon fontSize="small" />Save Changes</button>
              </div>
            </form>
          </div>
      }
    </>
  )
}

export default BudgetAssetEntryTable