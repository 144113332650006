import React, { useEffect, useState } from 'react'
import TitleLabel from '../CommonComponent/TitleLabel'
import { useSelector } from 'react-redux';
import makeStyles from "@mui/styles/makeStyles";
import { Autocomplete, Paper, TextField } from '@mui/material';
import { EmployeeMasterBranchWiseGetData } from '../CommonServices/EmployeeMasterAPIURL';
import axios from 'axios';
import { DatePicker, LocalizationProvider, TimePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import Loader from '../CommonComponent/Loader';
import moment from 'moment';
import { toast } from 'react-toastify';
import { getOfficeWorkInOutAPIURL, saveOfficeWorkInOutAPIURL, updateOfficeWorkInOutAPIURL } from './Services/OfficeWorkInOutAPIURL';
import OfficeWorkInOutTable from './OfficeWorkInOutTable';

const useStyles = makeStyles(theme => ({
    select: {
        maxHeight: 160,
        "& ul": {
            backgroundColor: "#F4ECF7",
            //maxHeight: 160,
        },
        "& li": {
            // backgroundColor: "#F4ECF7", fontWeight: "550", fontSize: "14px" 
            fontSize: "14px",
            fontWeight: "550"
        },
    },

}));


const OfficeWorkInOut = () => {
    const classes = useStyles();
    const authUser = useSelector((state) => state.user.value);
    const titleId = "Office Work In Out"

    // loader on/off
    const [loaderOption, setLoaderOption] = useState(false);

    //Note: This below states are declared for Save & Update(Disabled/unDisabled)
    const saveBtnStyle = "btn btn-primary btn-sm";
    const updateBtnStyle = "btn btn-primary btn-sm";
    const displayNoneBtnStyle = "d-none";
    const [saveBtn, setSaveBtn] = useState(saveBtnStyle);
    const [updateBtn, setUpdateBtn] = useState(displayNoneBtnStyle);

    const TableHeading = [
        { label: 'ID', key: 'id' },
        { label: `Employee Name`, key: (authUser.branchMedium == 1) ? 'empNameMr' : 'empNameEn' },
        { label: `Date`, key: 'date' },
        { label: `Time From`, key: 'timeFrom' },
        { label: `Time To`, key: 'timeTo' },
        { label: 'Edit', value: 'edit', isAction: true },
        { label: 'Delete', value: 'delete', isAction: true }];

    const [id, setId] = useState();
    const [employeeData, setEmployeeData] = useState([]);
    const [employeeObj, setEmployeeObj] = useState(null);
    const [DateValue, setDateValue] = useState(null);
    const [reason, setReason] = useState("");

    const [fromTime, setFromTime] = useState(null);
    const [fromTimes, setFromTimes] = useState(null);

    const [toTime, setToTime] = useState(null);
    const [toTimes, setToTimes] = useState(null);

    const [saveBtnDisabled, setSaveBtnDisabled] = useState(false);
    const [updateBtnDisabled, setUpdateBtnDisabled] = useState(false);

    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    // console.log(time);

    const handleTimeToChange = (newValue) => {
        setToTime(newValue);
        if (newValue != null) {
            const hours = String(newValue.getHours()).padStart(2, "0");
            const minutes = String(newValue.getMinutes()).padStart(2, "0");
            const seconds = String(newValue.getSeconds()).padStart(2, "0");
            const times = `${hours}:${minutes}:${seconds}`;
            setToTimes(times);
        }

    };

    const handleTimeFromChange = (newValue) => {
        setFromTime(newValue);
        if (newValue != null) {
            const hours = String(newValue.getHours()).padStart(2, "0");
            const minutes = String(newValue.getMinutes()).padStart(2, "0");
            const seconds = String(newValue.getSeconds()).padStart(2, "0");
            const times = `${hours}:${minutes}:${seconds}`;
            setFromTimes(times);
        }

    };

    useEffect(() => {
        getData();
    }, [authUser])

    const getData = async () => {
        if (authUser.branchId != null) {
            await axios.get(`${EmployeeMasterBranchWiseGetData}?bid=${authUser.branchId}`)
                .then(res => {
                    setEmployeeData(res.data);
                })
        }

        if (authUser.branchId != null && authUser.deptId != null) {
            await axios.get(`${getOfficeWorkInOutAPIURL}?sid=${authUser.deptId}&bid=${authUser.branchId}`)
                .then(res => {
                    setData(res.data);
                    setFilteredData(res.data);
                }).catch(err => {
                    console.log("Office in out Master get " + err)
                })
        }

    }

    const saveOfficeWorkOut = async () => {
        setLoaderOption(true);
        // console.log(JSON.stringify(employeeObj))
        const saveObj = {
            "empId": employeeObj.employeeId,
            "date": moment(DateValue).format("YYYY-MM-DD"),
            "reason": reason,
            "timeFrom": fromTimes,
            "timeTo": toTimes,
            "sectionId": authUser.deptId,
            "branchId": authUser.branchId

        }
        // console.log(JSON.stringify(saveObj))
        await axios.post(saveOfficeWorkInOutAPIURL, saveObj)
            .then(res => {
                if (res.data == "SAVED") {
                    setLoaderOption(false);
                    ClearData();
                    getData();
                    toast.success("Saved Successfully.")
                }
                else {
                    setLoaderOption(false);
                    toast.error("Save Failed.")
                }
            }).catch(err => {
                setLoaderOption(false);
                console.log("Office work in out err" + err);
                toast.error("Something went wrong, please check.")
            })

    }

    const updateOfficeWorkOut = async () => {
        setLoaderOption(true);
        // console.log(JSON.stringify(employeeObj))
        const updateObj = {
            "id": id,
            "empId": employeeObj.employeeId,
            "date": moment(DateValue).format("YYYY-MM-DD"),
            "reason": reason,
            "timeFrom": fromTimes,
            "timeTo": toTimes,
            "sectionId": authUser.deptId,
            "branchId": authUser.branchId

        }
        // console.log(JSON.stringify(updateObj))
        await axios.put(updateOfficeWorkInOutAPIURL, updateObj)
            .then(res => {
                if (res.data == "UPDATED") {
                    setLoaderOption(false);
                    ClearData();
                    getData();
                    toast.success("Updated Successfully.")
                }
                else {
                    setLoaderOption(false);
                    toast.error("update Failed.")
                }
            }).catch(err => {
                setLoaderOption(false);
                console.log("Office work in out update err" + err);
                toast.error("Something went wrong, please check.")
            })
    }

    const state = {
        button: 1
    };

    const onSubmit = e => {
        e.preventDefault();
        if (state.button === 1) {
            saveOfficeWorkOut();
        }
        if (state.button === 2) {
            updateOfficeWorkOut();
        }
    };

    const ClearData = () => {
        setSaveBtn(saveBtnStyle);
        setUpdateBtn(displayNoneBtnStyle);
        setEmployeeObj(null);
        setDateValue(null);
        setFromTime(null);
        setFromTimes(null);
        setToTime(null);
        setToTimes(null);
        setReason('');
    }


    if (loaderOption == true) {
        return (
            <Loader />
        )
    }
    else {
        return (
            <>
                <TitleLabel titleId={titleId} />
                <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: '350px' }}>
                    <form onSubmit={onSubmit}>
                        <input type="hidden" value={id} onChange={(e) => setId(e.target.value)} name="" />

                        <div className='row my-2'>
                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Employee :</label>
                            <div className='col-sm-3'>
                                <Autocomplete
                                    id="combo-box-demo"
                                    //disableClearable
                                    options={employeeData}
                                    style={{ fontSize: '14px' }}
                                    PaperComponent={({ children }) => (
                                        <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                    )}
                                    onChange={(event, item) => {
                                        setEmployeeObj(item);
                                    }}
                                    value={employeeObj}
                                    getOptionLabel={option => (authUser.branchMedium == 1) ? option.employeeNameMr : option.employeeNameEn}
                                    size="small"
                                    renderInput={params => (
                                        <TextField {...params} margin='dense' inputProps={{ ...params.inputProps, autoComplete: "off" }} label="Select Employee" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                    )}
                                />
                            </div>

                            <label className="col-sm-1" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px", marginRight: "0px" }} >Date :</label>
                            <div className='col-sm-3'>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        label="Date"
                                        value={DateValue}
                                        onChange={(newValue) => {
                                            setDateValue(newValue);

                                        }}
                                        onKeyPress={(e) => (e.key == "Enter") ? DateValue : ""}
                                        inputFormat="dd/MM/yyyy"
                                        renderInput={(params) =>
                                            <TextField
                                                {...params}
                                                margin='dense'
                                                variant="outlined"
                                                InputLabelProps={{ style: { fontSize: 14 } }}
                                                required
                                                fullWidth
                                                autoComplete='off'
                                                size="small" />}
                                    />
                                </LocalizationProvider>
                                {/* <span><em style={{ fontSize: "12px", color: "red", fontWeight: "600" }}>{dateCheck}</em></span> */}
                            </div>
                        </div>

                        <div className="row my-2">
                            <label htmlFor="lastname3" className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Reason :</label>
                            <div className='col-sm-7'>
                                <TextField
                                    required
                                    fullWidth
                                    autoComplete='off'
                                    size='small'
                                    id="outlined-basic"
                                    label="Reason"
                                    variant="outlined"
                                    margin='dense'
                                    value={reason}
                                    onChange={(e) => setReason(e.target.value)}
                                    onKeyPress={(e) => (e.key == "Enter") ? reason : ""}
                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                />
                            </div>
                        </div>

                        <div className="row my-2">
                            <label htmlFor="lastname3" className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Time From :</label>
                            <div className="col-sm-3">
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <TimePicker
                                        label="From Time"
                                        value={fromTime}
                                        onChange={handleTimeFromChange}
                                        ampm={false} // Set ampm prop to false to remove AM/PM indicator
                                        inputFormat="HH:mm"
                                        renderInput={(params) =>
                                            <TextField
                                                size='small'
                                                variant="outlined"
                                                margin='dense'
                                                InputLabelProps={{ style: { fontSize: 14 } }}
                                                required
                                                fullWidth
                                                autoComplete='off'
                                                {...params} />}

                                    />
                                </LocalizationProvider>
                            </div>

                            <label htmlFor="lastname3" className="col-sm-1" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>To :</label>
                            <div className="col-sm-3">
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <TimePicker
                                        label="To Time"
                                        value={toTime}
                                        onChange={handleTimeToChange}
                                        ampm={false} // Set ampm prop to false to remove AM/PM indicator
                                        inputFormat="HH:mm"
                                        renderInput={(params) =>
                                            <TextField
                                                size='small'
                                                variant="outlined"
                                                margin='dense'
                                                InputLabelProps={{ style: { fontSize: 14 } }}
                                                required
                                                fullWidth
                                                autoComplete='off'
                                                {...params} />}

                                    />
                                </LocalizationProvider>
                            </div>
                        </div>

                        <div className="col-sm-12 mt-3">
                            <div className="offset-sm-2">
                                <button type="submit" onClick={() => (state.button = 1)} className={saveBtn}>Save</button>
                                <button type="submit" onClick={() => (state.button = 2)} className={updateBtn} >Update</button>
                                <button type="button" onClick={ClearData} className="btn btn-danger btn-sm" style={{ margin: '0px 4px' }} >Cancel</button>
                            </div>
                        </div>
                    </form>

                    <div className='mt-2'>
                        <OfficeWorkInOutTable
                            TableHeading={TableHeading}
                            data={data}
                            filteredData={filteredData}
                            setFilteredData={setFilteredData}
                            setSaveBtn={setSaveBtn}
                            setUpdateBtn={setUpdateBtn}
                            updateBtnStyle={updateBtnStyle}
                            displayNoneBtnStyle={displayNoneBtnStyle}
                            setId={setId}
                            setEmployeeObj={setEmployeeObj}
                            setDateValue={setDateValue}
                            setFromTime={setFromTime}
                            setToTime={setToTime}
                            getData={getData}
                            setLoaderOption={setLoaderOption}
                            employeeData={employeeData}
                            setReason={setReason}
                            setFromTimes={setFromTimes}
                            setToTimes={setToTimes}
                        />
                    </div>
                </div>

            </>
        )
    }
}

export default OfficeWorkInOut
