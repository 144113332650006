import React, { useState, useEffect } from 'react';
import TitleLabel from "../../CommonComponent/TitleLabel";
import { useSelector } from "react-redux";
import axios from 'axios';
import Autocomplete from "@mui/material/Autocomplete";
import TextField from '@mui/material/TextField';
import Paper from "@mui/material/Paper";
import { ToastContainer, toast } from 'react-toastify';
import Checkbox from '@mui/material/Checkbox';
import SearchIcon from '@mui/icons-material/Search';
import AddBoxIcon from '@mui/icons-material/AddBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons'
import Loader from '../../CommonComponent/Loader';
import {
    getClassesBySectionAndBranch,
    getDivisionsByClassAndSectionAndBranch,
    getAllocatedExamData,
    getAllocatedSubExamData,
    getSubjectListByClassAndDivision,
    getSubExamMarkData,
    saveSubExamMarkAllocationData,
    getSubjectExamMarkDataAfterSave
} from "../Services/ExamMarkAllocationAPIURL";
import termJSON from '../../CommonComponent/LocalJSON/TermData';
import ExamMarkAllocationTableRow from "./ExamMarkAllocationTable/ExamMarkAllocationTableRow";
import makeStyles from "@mui/styles/makeStyles";
import { BaseURLInstituteMaster, BaseURLSection } from '../../CommonServices/InstituteMasterAPIURL';

const useStyles = makeStyles(theme => ({
    select: {
        maxHeight: 160,
        "& ul": {
            backgroundColor: "#F4ECF7",
            //maxHeight: 160,
        },
        "& li": {
            // backgroundColor: "#F4ECF7", fontWeight: "550", fontSize: "14px" 
            fontSize: "14px",
            fontWeight: "550"
        },
    },

}));

const ExamMarkAllocation = () => {
    const classes = useStyles();
    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;

    const titleId = "Exam Marks Allocation";
    const authUser = useSelector((state) => state.user.value);

    const [loaderOption, setLoaderOption] = useState(false);

    const TableHeading1 = [
        { label: `Sub Exam`, key: 'sub' },
        { label: `Min`, key: 'Min' },
        { label: `Max`, key: 'Max' },
    ];

    const TableHeading2 = [
        { label: `Subject`, key: 'sub' },
        { label: `Min`, key: 'Min' },
        { label: `Max`, key: 'Max' },
        { label: `Edit`, key: 'edit' }
    ];

    const optionsJSON = [
        {
            id: 1,
            name: "All Division"
        },
        {
            id: 2,
            name: "Division Wise"
        }
    ];

    const [maxMarkMain, setMaxMarkMain] = useState("");
    const [minMarkMain, setMinMarkMain] = useState("");
    const [data, setData] = useState([]);
    const [classData, setClassData] = useState([]);
    const [classDataObj, setClassDataObj] = useState(null);
    const [divisionData, setDivisionData] = useState([]);
    const [classDivisionObj, setClassDivisionObj] = useState(null);
    const [divisionOptObj, setDivisionOptObj] = useState(1);
    const [termObj, setTermObj] = useState(null);
    const [examObj, setExamObj] = useState(null);
    const [subjectId, setSubjectId] = useState(null);
    const [subExamMarkData, setSubExamMarkData] = useState([]);
    const [tempSubExamMarkData, setTempSubExamMarkData] = useState([]);
    //console.log("subExamMarkData List : " + JSON.stringify(subExamMarkData))
    //Subject List Table
    const [subListByCandD, setSubListByCandD] = useState([]);
    //console.log("Subject List : " + JSON.stringify(subListByCandD))
    //Exam List Table
    const [subExamData, setSubExamData] = useState([]);
    const [examData, setExamData] = useState([]);
    const [sendingSubjectList, setSendingSubjectList] = useState([]);
    const [savedExamMarkData, setSavedExamMarkData] = useState([]);
    const [wrongValue, setWrongValue] = useState(0);
    const [departmentData, setDepartmentData] = useState([]);
    const [departmentObj, setDepartmentObj] = useState(null);
    const [bMedium, setBMedium] = useState(1);
    const [branchData, setBranchData] = useState([]);
    const [branchObj, setBranchObj] = useState(null);

    useEffect(() => {
        getData();
    }, [])

    const getData = async () => {
        await axios.get(`${BaseURLSection}`)
            .then((response) => {
                //const sortData = (response.data).filter(e => e.acApl === 0)
                setDepartmentData(response.data);
            })

        // await axios.get(ClassMasterGetMapping)
        //     .then(res => {
        //         setClassData(res.data);
        //     })

        await axios.get(BaseURLInstituteMaster)
            .then(res => {
                setBranchData(res.data);
            })
    }

    //get class by section & branch
    const getClassesBySecAndBra = async (sId) => {
        await axios(`${getClassesBySectionAndBranch}?sid=${sId}&bid=${branchObj.id}`)
            .then(res => {
                setClassData(res.data);
            });
    }

    // get division by class
    const getDivisionData = async (classId) => {
        //console.log(`${getDivisionsByClassAndSectionAndBranch}?cid=${classId}&sid=${departmentObj.id}&bid=${branchObj.id}`)
        await axios(`${getDivisionsByClassAndSectionAndBranch}?cid=${classId}&sid=${departmentObj.id}&bid=${branchObj.id}`)
            .then(res => {
                setDivisionData(res.data);
            });
        // await axios.get(DivisioneMasterGetMapping)
        //     .then(res => {
        //         const newDivisiondata = res.data.filter(e => e.classId == classId)
        //         setDivisionData(newDivisiondata);
        //     })
    }

    //get exam by class-div
    const getExamData = async (divId) => {
        // let link = getAllocatedExamData + "?acyr=" + authUser.sessionYear + "&sid=" + departmentObj.id + "&bid=" + branchObj.id + "&cid=" + classDataObj.id+ "&did=" + divId;
        // console.log(link);
        await axios(`${getAllocatedExamData}?acyr=${authUser.sessionYear}&sid=${departmentObj.id}&bid=${branchObj.id}&cid=${classDataObj.id}&did=${divId}`)
            .then(res => {
                setExamData(res.data);
            });
    }

    //get subject by exam
    const getSubjectAndExamData = async (e) => {
        e.preventDefault();
        setLoaderOption(true);
        setSubListByCandD([]);
        setSubExamMarkData([]);
        setTempSubExamMarkData([]);
        setSendingSubjectList([]);
        setMaxMarkMain("");
        setMinMarkMain("");

        // console.log(`${getSubjectListByClassAndDivision}?acyr=${authUser.sessionYear}&sid=${departmentObj.id}&bid=${branchObj.id}&cid=${classDataObj.id}&did=${classDivisionObj.id}`)
        await axios(`${getSubjectListByClassAndDivision}?acyr=${authUser.sessionYear}&sid=${departmentObj.id}&bid=${branchObj.id}&cid=${classDataObj.id}&did=${classDivisionObj.id}`)
            .then(res => {
                if (res.data == "" || res.data == null || res.data == []) {
                    setLoaderOption(false);
                    toast.warn("Subject is not allocated");
                }
                else {
                    setSubListByCandD(res.data);
                    setLoaderOption(false);
                }
            }).catch(error => {
                console.log(error);
                setLoaderOption(false);
                toast.error("Something went wrong, please check.")
            })

        // console.log("datadatadatadatadatadatadatadata" + `${getSubExamMarkData}?acyr=${authUser.sessionYear}&sid=${departmentObj.id}&bid=${branchObj.id}&cid=${classDataObj.id}&did=${classDivisionObj.id}&eid=${examObj.id}&tid=${termObj.id}&sbjid=${subjectId}&branchMedium=${bMedium}`);
        await axios(`${getSubExamMarkData}?acyr=${authUser.sessionYear}&sid=${departmentObj.id}&bid=${branchObj.id}&cid=${classDataObj.id}&did=${classDivisionObj.id}&eid=${examObj.id}&tid=${termObj.id}&sbjid=${0}&branchMedium=${bMedium}`)
            .then(res => {
                if (res.data == "" || res.data == null || res.data == []) {
                    setLoaderOption(false);
                    toast.warn("Sub exam mark data not found");
                }
                else {
                    let updateData = res.data;
                    (res.data).map((e, index) => {
                        updateData[index] = ({ ...e, applyToAllDivision: divisionOptObj })
                    })
                    // console.log(JSON.stringify(updateData))
                    setSubExamMarkData(updateData);
                    setTempSubExamMarkData(updateData);
                    setLoaderOption(false);
                }

            }).catch(error => {
                console.log(error);
                setLoaderOption(false);
                toast.error("Something went wrong, please check.")
            });

        getSavedSubMarkData();
    }

    //get sub exam by exam
    const getSavedSubMarkData = async () => {
        setLoaderOption(false);
        // console.log("===================" + `${getSubjectExamMarkDataAfterSave}?acyr=${authUser.sessionYear}&sid=${departmentObj.id}&bid=${branchObj.id}&cid=${classDataObj.id}&did=${classDivisionObj.id}&eid=${examObj.id}&tid=${termObj.id}&sbjid=${0}&branchMedium=${bMedium}`);
        await axios(`${getSubjectExamMarkDataAfterSave}?acyr=${authUser.sessionYear}&sid=${departmentObj.id}&bid=${branchObj.id}&cid=${classDataObj.id}&did=${classDivisionObj.id}&eid=${examObj.id}&tid=${termObj.id}&sbjid=${0}&branchMedium=${bMedium}`)
            .then(res => {
                if (res.data == "" || res.data == null || res.data == []) {
                    toast.warn("Saved exam mark data not found");
                }
                else {
                    setSavedExamMarkData(res.data);
                }

            });
    }

    const editExamMarks = async (subMarkId) => {
        // console.log(`${getSubExamMarkData}?acyr=${authUser.sessionYear}&sid=${departmentObj.id}&bid=${branchObj.id}&cid=${classDataObj.id}&did=${classDivisionObj.id}&eid=${examObj.id}&tid=${termObj.id}&sbjid=${subMarkId}&branchMedium=${bMedium}`)
        await axios(`${getSubExamMarkData}?acyr=${authUser.sessionYear}&sid=${departmentObj.id}&bid=${branchObj.id}&cid=${classDataObj.id}&did=${classDivisionObj.id}&eid=${examObj.id}&tid=${termObj.id}&sbjid=${subMarkId}&branchMedium=${bMedium}`)
            .then(res => {
                setSubExamMarkData([]);
                setSubExamMarkData(res.data)

                let selectedSub = subListByCandD.filter((e) => e.id === subMarkId)
                setSendingSubjectList(selectedSub);

                let getnonZeroList = (res.data).filter((e) => e.subExamId === 0)
                if (getnonZeroList.length > 0) {
                    setMaxMarkMain(getnonZeroList[0].maxMark);
                    setMinMarkMain(getnonZeroList[0].minMark);
                }
                else {
                    setMaxMarkMain("");
                    setMinMarkMain("");
                }
                // console.log(JSON.stringify(getnonZeroList))
            });
    }

    //console.log("sendingSubjectListsendingSubjectListsendingSubjectList::" + JSON.stringify(sendingSubjectList))
    //save exam mark allocation
    const saveExamMarksData = async () => {
        setLoaderOption(true);
        if (maxMarkMain !== "" && minMarkMain !== "") {
            let filterZeroMarkData = subExamMarkData.filter((e) => e.subExamId === 0)

            if (filterZeroMarkData.length === 1) {
                let changeData = filterZeroMarkData[0];
                changeData = { ...changeData, subExamId: 0, maxMark: maxMarkMain, minMark: minMarkMain }

                let NonZeroMarkDataArray = subExamMarkData.filter((e) => e.subExamId !== 0)
                //let NonZeroMarkData = NonZeroMarkDataArray[0];
                let sendingMarkData = NonZeroMarkDataArray.concat(changeData);

                sendingMarkData.map((e, index) => {
                    sendingMarkData[index] = { ...e, branchMedium: bMedium, applyToAllDivision: divisionOptObj, branchId: branchObj.id, sectionId: departmentObj.id }
                })

                //console.log("divisionOptObj=====" + divisionOptObj)

                // console.log("Old Entry:" + JSON.stringify(sendingMarkData)) 

                const saveObject = {
                    "SubjectMaster": sendingSubjectList,
                    "ExamMarkAllocationDto": sendingMarkData,
                }
                //console.log(JSON.stringify(sendingSubjectList))
                // console.log(`${saveSubExamMarkAllocationData}, ${JSON.stringify(saveObject)}`)

                await axios.post(`${saveSubExamMarkAllocationData}`, saveObject)
                    .then((response) => {
                        if (response.data == "SAVED") {
                            setSubExamMarkData([]);
                            getSavedSubMarkData();
                            setSubExamMarkData(tempSubExamMarkData);
                            setSendingSubjectList([])
                            setLoaderOption(false);
                            setMaxMarkMain("");
                            setMinMarkMain("");
                            toast.success("Exam mark saved");

                        }
                    })
                    .catch(error => {
                        console.log("Save Exam Mark Allocation: " + error)
                        toast.error("Something went wrong.");
                    })

            }
            else {
                let changeData = subExamMarkData[0];
                changeData = { ...changeData, subExamId: 0, maxMark: maxMarkMain, minMark: minMarkMain }

                let sendingMarkData = subExamMarkData.concat(changeData);

                sendingMarkData.map((e, index) => {
                    sendingMarkData[index] = { ...e, branchMedium: bMedium, applyToAllDivision: divisionOptObj, branchId: branchObj.id, sectionId: departmentObj.id }
                })

                //console.log("divisionOptObj=====" + divisionOptObj)

                // console.log("New Entry: " + JSON.stringify(sendingMarkData))

                const saveObject = {
                    "SubjectMaster": sendingSubjectList,
                    "ExamMarkAllocationDto": sendingMarkData,
                }
                //console.log(JSON.stringify(saveObject))
                // console.log(`${saveSubExamMarkAllocationData}, ${JSON.stringify(saveObject)}`)

                await axios.post(`${saveSubExamMarkAllocationData}`, saveObject)
                    .then((response) => {
                        if (response.data == "SAVED") {
                            setSubExamMarkData([]);
                            getSavedSubMarkData();
                            setSubExamMarkData(tempSubExamMarkData);
                            setSendingSubjectList([]);
                            setLoaderOption(false);
                            setMaxMarkMain("");
                            setMinMarkMain("");
                            toast.success("Exam mark saved");
                        }
                    })
                    .catch(error => {
                        console.log("Save Exam Mark Allocation: " + error)
                        toast.error("Something went wrong.");
                    })
            }

        }
        else {
            setLoaderOption(false);
            toast.warn("Please Enter Max Mark")
        }
    }

    //get sub exam mark data
    const resetMarkEditionTable = async (resetMarkCheckData) => {
        let num = Object.keys(resetMarkCheckData).length;

        // console.log(`${getSubExamMarkData}?acyr=${authUser.sessionYear}&sid=${departmentObj.id}&bid=${branchObj.id}&cid=${classDataObj.id}&did=${classDivisionObj.id}&eid=${examObj.id}&tid=${termObj.id}&sbjid=${0}&branchMedium=${bMedium}`)
        if (num > 1) {
            await axios(`${getSubExamMarkData}?acyr=${authUser.sessionYear}&sid=${departmentObj.id}&bid=${branchObj.id}&cid=${classDataObj.id}&did=${classDivisionObj.id}&eid=${examObj.id}&tid=${termObj.id}&sbjid=${0}&branchMedium=${bMedium}`)
                .then(res => {
                    setSubExamMarkData([]);
                    setSubExamMarkData(res.data);
                });
        }
        //setSubExamMarkData(tempSubExamMarkData);

    }

    if (loaderOption == true) {
        return <Loader />
    }
    else {
        return (
            <>
                <TitleLabel titleId={titleId} />
                <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: "500px" }}>
                    <form onSubmit={getSubjectAndExamData}>
                        <div className="row">
                            <label className="col-sm-1" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }} >Branch :</label>
                            <div className="col-sm-7">
                                <Autocomplete
                                    id="combo-box-demo"
                                    options={branchData}
                                    PaperComponent={({ children }) => (
                                        <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                    )}
                                    style={{ fontSize: '14px' }}
                                    getOptionLabel={option => (authUser.branchMedium == 1) ? option.schoolNameMr : option.schoolNameEn}
                                    size="small"
                                    onChange={(event, newValue) => {
                                        setBranchObj(newValue);
                                        setDepartmentObj(null);
                                        setClassDataObj(null);
                                        setClassDivisionObj(null);
                                        setTermObj(null);
                                        setExamObj(null);
                                        setBMedium(1);
                                        setSubExamMarkData([]);
                                        setSavedExamMarkData([]);
                                        setExamData([]);
                                        setClassData([]);
                                        setDivisionData([]);
                                        setSubExamData([]);
                                        setDivisionOptObj(1);
                                    }}
                                    value={branchObj}
                                    renderInput={params => (
                                        <TextField {...params} margin='dense' label="Select Branch" variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                    )}
                                />
                            </div>
                        </div>

                        <div className="row my-2">
                            <label className='col-sm-1' style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Department:</label>
                            <div className="col-sm-3 ">
                                <Autocomplete
                                    id="combo-box-demo"
                                    options={departmentData}
                                    PaperComponent={({ children }) => (
                                        <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                    )}
                                    style={{ fontSize: '14px' }}
                                    getOptionLabel={option => (authUser.branchMedium == 1) ? option.nameMr : option.nameEn}
                                    size="small"
                                    onChange={(event, newValue) => {
                                        setDepartmentObj(newValue);
                                        setClassDataObj(null);
                                        setClassDivisionObj(null);
                                        setTermObj(null);
                                        setExamObj(null);
                                        setBMedium(1);
                                        setDivisionOptObj(1);
                                        setSubExamMarkData([]);
                                        setSavedExamMarkData([]);
                                        setExamData([]);
                                        setClassData([]);
                                        setDivisionData([]);
                                        setSubExamData([]);
                                        if (newValue != null) {
                                            getClassesBySecAndBra(newValue.id);
                                        }
                                    }}
                                    value={departmentObj}
                                    renderInput={params => (
                                        <TextField {...params} margin='dense' label="Select Department" variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                    )}
                                />
                            </div>

                            <label className="col-sm-1" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }} >Medium:</label>
                            <div className='col-sm-3'>
                                <FormControl fullWidth margin="dense">
                                    <InputLabel id='demo-simple-select-label' size="small" style={{ fontSize: '14px', marginBottom: "50px" }}>Medium</InputLabel>
                                    <Select
                                        MenuProps={{ classes: { paper: classes.select } }}
                                        size='small'
                                        fullWidth
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={bMedium}
                                        label="Medium"
                                        onChange={(e) => {
                                            setBMedium(e.target.value);
                                            setClassDataObj(null);
                                            setClassDivisionObj(null);
                                            setTermObj(null);
                                            setExamObj(null);
                                            setSubExamMarkData([]);
                                            setSavedExamMarkData([]);
                                            setExamData([]);
                                            setDivisionData([]);
                                            setSubExamData([]);
                                            setDivisionOptObj(1);
                                        }}
                                    >
                                        <MenuItem value={1}>Marathi</MenuItem>
                                        <MenuItem value={2}>English</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                        </div>

                        <div className="row my-2">
                            <div className="col-sm-1">
                                <label style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Class : </label>
                            </div>
                            <div className="col-sm-3">
                                <Autocomplete
                                    id="combo-box-demo"
                                    options={classData}
                                    value={classDataObj}
                                    onChange={(event, newValue) => {
                                        setClassDataObj(newValue);
                                        setClassDivisionObj(null);
                                        setTermObj(null);
                                        setExamObj(null);
                                        setSubExamMarkData([]);
                                        setSavedExamMarkData([]);
                                        setExamData([]);
                                        setDivisionData([]);
                                        setSubExamData([]);
                                        setDivisionOptObj(1);
                                        if (newValue != null) {
                                            getDivisionData(newValue.id);
                                        }
                                    }}
                                    PaperComponent={({ children }) => (
                                        <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                    )}
                                    style={{ fontSize: '14px' }}
                                    getOptionLabel={option => (bMedium == 1) ? option.nameMr : option.nameEn}
                                    size="small"
                                    renderInput={params => (
                                        <TextField {...params} margin='dense' label="Select Class" variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                    )}
                                />
                            </div>
                            <div className="col-sm-1">
                                <label style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Division: </label>
                            </div>
                            <div className="col-sm-3">
                                <Autocomplete
                                    id="combo-box-demo"
                                    options={divisionData}
                                    value={classDivisionObj}
                                    onChange={(event, newValue) => {
                                        setClassDivisionObj(newValue);
                                        setTermObj(null);
                                        setExamObj(null);
                                        setSubExamMarkData([]);
                                        setSavedExamMarkData([]);
                                        setExamData([]);
                                        setSubExamData([]);
                                        setDivisionOptObj(1);
                                        if (newValue != null) {
                                            getExamData(newValue.id);
                                        }
                                    }}
                                    PaperComponent={({ children }) => (
                                        <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                    )}
                                    style={{ fontSize: '14px' }}
                                    getOptionLabel={option => (bMedium == 1) ? option.nameMr : option.nameEn}
                                    size="small"
                                    renderInput={params => (
                                        <TextField {...params} margin='dense' label="Select Division" variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                    )}
                                />
                            </div>
                            <div className="col-sm-1">
                                <label style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Option: </label>
                            </div>
                            <div className="col-sm-3">
                                <FormControl fullWidth margin='dense'>
                                    <InputLabel id="demo-simple-select-label" size="small" style={{ fontSize: "14px" }}>Select Option</InputLabel>
                                    <Select
                                        MenuProps={{ classes: { paper: classes.select } }}
                                        labelId="demo-simple-select-label"
                                        required
                                        id="demo-simple-select"
                                        size='small'
                                        value={divisionOptObj}
                                        label="Select Option"
                                        onChange={(event) => {
                                            setDivisionOptObj(event.target.value);
                                            setExamObj(null);
                                            setTermObj(null);
                                            setSubExamMarkData([]);
                                            setSavedExamMarkData([]);
                                            setSubExamData([]);
                                        }}
                                    >
                                        <MenuItem value={1}>All Division</MenuItem>
                                        <MenuItem value={2}>Division Wise</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>


                        </div>

                        <div className="row my-2">
                            <div className="col-sm-1">
                                <label style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Term: </label>
                            </div>
                            <div className="col-sm-3">
                                <Autocomplete
                                    id="combo-box-demo"
                                    options={termJSON}
                                    value={termObj}
                                    onChange={(event, newValue) => {
                                        setTermObj(newValue);
                                        setExamObj(null);
                                        setSubExamMarkData([]);
                                        setSavedExamMarkData([]);
                                        setSubExamData([]);
                                    }}
                                    PaperComponent={({ children }) => (
                                        <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                    )}
                                    style={{ fontSize: '14px' }}
                                    getOptionLabel={option => (bMedium == 1) ? option.nameMr : option.nameEn}
                                    size="small"
                                    renderInput={params => (
                                        <TextField {...params} margin='dense' label="Select Term" variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                    )}
                                />
                            </div>
                            <div className="col-sm-1">
                                <label style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Exam: </label>
                            </div>
                            <div className="col-sm-3">
                                <Autocomplete
                                    id="combo-box-demo"
                                    options={examData}
                                    value={examObj}
                                    onChange={(event, newValue) => {
                                        setExamObj(newValue);
                                        setSubExamMarkData([]);
                                        setSavedExamMarkData([]);
                                        setSubExamData([]);
                                    }}
                                    PaperComponent={({ children }) => (
                                        <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                    )}
                                    style={{ fontSize: '14px' }}
                                    getOptionLabel={option => (bMedium == 1) ? option.nameMr : option.nameEn}
                                    size="small"
                                    renderInput={params => (
                                        <TextField {...params} margin='dense' label="Select Exam" variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                    )}
                                />
                            </div>
                            {/* <div className="col-sm-1">
                                <label style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>SubExam: </label>
                            </div>
                            <div className="col-sm-3">
                                <Autocomplete
                                    id="combo-box-demo"
                                    options={data}
                                    PaperComponent={({ children }) => (
                                        <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                    )}
                                    style={{ fontSize: '14px' }}
                                    getOptionLabel={option => option.accountNameEn}
                                    size="small"
                                    renderInput={params => (
                                        <TextField {...params} margin='dense' label="Select Sub Exam" variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} />
                                    )}
                                />
                            </div> */}
                            <div className="col-sm-4 mt-2">
                                <button type='submit' className='btn btn-primary btn-sm mb-2' style={{ float: 'left' }} ><SearchIcon fontSize="small" />VIEW</button>
                                {/* <button type='button' className='btn btn-warning btn-sm mb-2 ml-2'><AddIcon fontSize='small' /> Set Previous Marks</button> */}
                            </div>

                        </div>
                        <hr />
                        {
                            (subListByCandD == "" || subListByCandD == [] || savedExamMarkData == "" || savedExamMarkData == [])
                                ?
                                <span></span>
                                :
                                <div className="row mt-4">
                                    <div className="col-sm-6">
                                        <div className="col-md-12">
                                            <div className='row mb-4'>
                                                <div className='col-sm-2'>
                                                    <label style={{ fontSize: "14px", fontWeight: "600", marginTop: "8px" }}>Subject: </label>
                                                </div>
                                                <div className='col-sm-8'>
                                                    <Autocomplete
                                                        multiple
                                                        id="checkboxes-tags-demo"
                                                        size='small'
                                                        options={subListByCandD}
                                                        value={sendingSubjectList}
                                                        defaultValue={sendingSubjectList}
                                                        disableCloseOnSelect
                                                        onChange={(event, newValue) => {
                                                            //console.log(JSON.stringify(newValue));
                                                            setSendingSubjectList(newValue);
                                                            resetMarkEditionTable(newValue);
                                                        }}
                                                        getOptionLabel={(option) => option.nameMr}
                                                        renderOption={(props, option, { selected }) => (
                                                            <li {...props}>
                                                                <Checkbox
                                                                    icon={icon}
                                                                    checkedIcon={checkedIcon}
                                                                    style={{ marginRight: 8 }}
                                                                    checked={selected}
                                                                />
                                                                {option.nameMr}
                                                            </li>
                                                        )}
                                                        renderInput={(params) => (
                                                            <TextField {...params} label="Select Subject" margin='dense' placeholder="Subject" InputLabelProps={{ style: { fontSize: 14 } }} />
                                                        )}
                                                    />
                                                </div>

                                                <div className='col-sm-3  offset-sm-2'>
                                                    <TextField type='number' value={minMarkMain} onChange={(e) => setMinMarkMain(e.target.value)} label="Min Count" margin="dense" size='small' onWheel={(e) => e.target.blur()} />
                                                </div>

                                                <div className='col-sm-3'>
                                                    <TextField type='number' value={maxMarkMain} onChange={(e) => setMaxMarkMain(e.target.value)} label="Max Count" margin="dense" size='small' onWheel={(e) => e.target.blur()} />
                                                </div>
                                            </div>

                                        </div>

                                        {
                                            (sendingSubjectList == "" || sendingSubjectList == [] || sendingSubjectList == null)
                                                ? <span></span>
                                                :
                                                <div className="col-md-12" style={{ backgroundColor: "#63a4ff", backgroundImage: "linear-gradient(315deg, #63a4ff 0%, #83eaf1 74%)", color: "#fff", boxShadow: "2px 5px 10px #7F8C8D" }}>
                                                    <div className='table-responsive' style={{ overflow: "hidden" }}>
                                                        <table className="table mt-2">
                                                            <thead className="table-Default">
                                                                <tr style={{ borderBottom: "2px solid #036796" }}>
                                                                    {TableHeading1.map((item, index) => {
                                                                        return (
                                                                            <th style={{ fontSize: "12px", padding: "8px", fontSize: "14px", color: "#000000" }} scope="col"><strong>{item.label}</strong></th>
                                                                        )
                                                                    })}
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    subExamMarkData.map((item, index) => {
                                                                        if (item.subExamId > 0) {
                                                                            return (
                                                                                <tr key={index} style={{ height: "50px", borderBottom: "1px solid #819DAA" }}>
                                                                                    <td className='p-2' style={{ fontSize: "14px", fontWeight: "600" }}>{(bMedium == 1) ? item.subExamNameMr : item.subExamNameEn}</td>

                                                                                    <ExamMarkAllocationTableRow initialText1={item.minMark} initialText2={item.maxMark}
                                                                                        onChange={(value1, value2) => {
                                                                                            let updatedData = subExamMarkData;
                                                                                            updatedData[index] = ({ ...item, minMark: value1, maxMark: value2, termId: termObj.id });
                                                                                            setSubExamMarkData(updatedData);
                                                                                            //console.log(JSON.stringify(subExamMarkData));
                                                                                        }} />
                                                                                </tr>
                                                                            )
                                                                        }

                                                                    })
                                                                }
                                                                {/* <tr style={{ height: "30px", borderBottom: "1px solid #04A9A0", backgroundColor: "#B6E3E1" }}>
                                                                    <td className='p-2' style={{ fontSize: "14px", fontWeight: "600" }}>
                                                                        {(authUser.branchMedium == 1) ? "एकूण" : "Total"}
                                                                    </td>
                                                                    <td style={{ fontSize: "14px", fontWeight: "600" }} className='p-2'>{minMarkCount}</td>
                                                                    <td style={{ fontSize: "14px", fontWeight: "600" }} className='p-2'>{maxMarkCount}</td>
                                                                    <td style={{ fontSize: "14px", fontWeight: "600" }} className='p-2'></td>
                                                                </tr> */}

                                                            </tbody>
                                                            <tfoot></tfoot>
                                                        </table>
                                                    </div>
                                                    <div className="col-sm-12 my-2">

                                                        <span>
                                                            <button type='button' style={{ marginLeft: "-12px", border: "2px solid #09c6f9" }} onClick={() => saveExamMarksData()} disabled={(sendingSubjectList == "" || sendingSubjectList == null || sendingSubjectList == [] || wrongValue == 1) ? true : false} className='btn btn-light btn-sm mb-2' >
                                                                <AddBoxIcon fontSize="small" style={{ color: "#09c6f9", marginLeft: "-4px" }} /> <strong style={{ color: "#09c6f9" }}>SAVE</strong>
                                                            </button>
                                                        </span>
                                                        <span className='badge' style={{ fontSize: "12px", marginLeft: "8px", color: "#EA0606", fontWeight: "600", backgroundColor: "white", padding: "4px", borderRadius: "8px" }}>
                                                            {(wrongValue === 1) ? " * Max Marks should be greater than Min Marks." : ""}
                                                        </span>
                                                    </div>
                                                </div>
                                        }
                                    </div>

                                    <div className="col-sm-6">
                                        <div className="col-md-12 mb-3" style={{ backgroundColor: "white", boxShadow: "2px 5px 10px #7F8C8D", borderBottom: "1px solid #464de4" }}>
                                            <div className='table-responsive' style={{ overflow: "hidden" }}>
                                                <table className="table mt-2">
                                                    <thead className="table-Default">
                                                        <tr style={{ backgroundColor: "#fff", borderBottom: "2px solid #464de4" }}>
                                                            {TableHeading2.map((item, index) => {
                                                                return (
                                                                    <th style={{ fontSize: "14px", padding: "8px", color: "#5A5757" }} scope="col"><strong>{item.label}</strong></th>
                                                                )
                                                            })}
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            savedExamMarkData.map(e => {
                                                                return (
                                                                    <tr>
                                                                        <td style={{ fontSize: "14px", fontWeight: "600" }}>{(bMedium == 1) ? e.subjectNameMr : e.subjectNameEn}</td>
                                                                        <td style={{ fontSize: "14px", fontWeight: "600" }}>{e.minMark}</td>
                                                                        <td style={{ fontSize: "14px", fontWeight: "600" }}>{e.maxMark}</td>
                                                                        <td>
                                                                            <span className='btn btn-primary btn-sm mx-1 p-1'
                                                                                onClick={() => editExamMarks(e.subjectId)}

                                                                            >
                                                                                <FontAwesomeIcon icon={faEdit} />
                                                                            </span>
                                                                            {/* <span onClick={() => editExamMarks(e.subjectId)} style={{ cursor: "pointer" }}>
                                                                                <EditIcon fontSize='small' />
                                                                            </span> */}
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            })
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        }
                    </form>
                </div>
                {/* <ToastContainer position="top-right" theme="colored" /> */}
            </>
        )
    }

}

export default ExamMarkAllocation