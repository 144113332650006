import React, { useEffect, useState } from 'react'
import TitleLabel from '../../CommonComponent/TitleLabel';
import { useSelector } from 'react-redux';
import Loader from '../../CommonComponent/Loader';
import { FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import axios from 'axios';
import makeStyles from "@mui/styles/makeStyles";
import { toast } from 'react-toastify';
import { getTermObsMasterAPIURL, saveTermObsMasterAPIURL, updateTermObsMasterAPIURL } from '../Services/TermObsMasterAPIURL';
import TermObservationMasterTable from './TermObservationMasterTable';

const useStyles = makeStyles(theme => ({
    menuPaper: {
        maxHeight: 200,
        "& ul": {
            backgroundColor: "#F4ECF7",
            //maxHeight: 160,
        },
        "& li": {
            // backgroundColor: "#F4ECF7", fontWeight: "550", fontSize: "14px" 
            fontSize: "14px",
            fontWeight: "550"
        },
    }
}));


const TermObservationMaster = () => {
    const authUser = useSelector((state) => state.user.value);
    const titleId = "Term Observation Master"
    const classes = useStyles();

    // loader on/off
    const [loaderOption, setLoaderOption] = useState(false);

    //Note: This below states are declared for Save & Update(Disabled/unDisabled)
    const saveBtnStyle = "termObsMasterSaveBtn btn btn-primary btn-sm";
    const updateBtnStyle = "termObsMasterUpdateBtn btn btn-primary btn-sm";
    const displayNoneBtnStyle = "d-none";
    const [saveBtn, setSaveBtn] = useState(saveBtnStyle);
    const [updateBtn, setUpdateBtn] = useState(displayNoneBtnStyle);

    const TableHeading = [
        { label: 'ID', key: 'id' },
        { label: `Boys Observation`, key: 'boysObservation' },
        { label: `Girls Observation`, key: 'girlsObservation' },
        { label: `Observation Type`, key: (authUser.branchMedium == 1) ? 'observationTypeMr' : 'observationTypeEn' },
        { label: 'Edit', value: 'edit', isAction: true },
        { label: 'Delete', value: 'delete', isAction: true }];

    const [id, setId] = useState('');
    const [obsType, setObsType] = useState('');
    const [boysObservation, setBoysObservation] = useState('');
    const [girlsObservation, setGirlsObservation] = useState('');

    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);

    useEffect(() => {
        getData();
    }, [authUser])


    const getData = async () => {
        if (authUser.sessionYear != '' && authUser.deptId != '' && authUser.branchId != '') {
            await axios.get(`${getTermObsMasterAPIURL}?year=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}`)
                .then(res => {
                    setData(res.data);
                    setFilteredData(res.data);
                }).catch(err => {
                    console.log("get term obs. master " + err)
                })
        }
    }

    const clearData = () => {
        setSaveBtn(saveBtnStyle);
        setUpdateBtn(displayNoneBtnStyle);
        setObsType('');
        setBoysObservation('');
        setGirlsObservation('');
    }

    const saveBtnClick = async () => {
        setLoaderOption(true);
        const saveObj = {
            "observationType": obsType,
            "boysObservation": boysObservation,
            "girlsObservation": girlsObservation,
            "sessionYear": authUser.sessionYear,
            "sectionId": authUser.deptId,
            "branchId": authUser.branchId
        }
        await axios.post(saveTermObsMasterAPIURL, saveObj)
            .then(res => {
                if (res.data == "SAVED") {
                    getData();
                    clearData();
                    setLoaderOption(false);
                    toast.success("Saved successfully.")
                }
                else if (res.data == "ALREADYEXISTS") {
                    setLoaderOption(false);
                    toast.warn("Thso observation is already exists.")
                }
                else {
                    setLoaderOption(false);
                    toast.error("Save failed.")
                }
            }).catch(err => {
                console.log("Term obs. master save err " + err);
                setLoaderOption(false);
                toast.error("Something went wrong, please check.")
            })
    }

    const updateBtnClick = async () => {
        setLoaderOption(true);
        const updateObj = {
            "id": id,
            "observationType": obsType,
            "boysObservation": boysObservation,
            "girlsObservation": girlsObservation,
            "sessionYear": authUser.sessionYear,
            "sectionId": authUser.deptId,
            "branchId": authUser.branchId
        }

        await axios.put(updateTermObsMasterAPIURL, updateObj)
            .then(res => {
                if (res.data == "UPDATED") {
                    getData();
                    clearData();
                    setLoaderOption(false);
                    toast.success("Updated successfully.")
                }
                else if (res.data == "ALREADYEXISTS") {
                    setLoaderOption(false);
                    toast.warn("Thso observation is already exists.")
                }
                else {
                    setLoaderOption(false);
                    toast.error("Update failed.")
                }
            }).catch(err => {
                console.log("Term obs. master update err " + err);
                setLoaderOption(false);
                toast.error("Something went wrong, please check.")
            })
    }

    const state = {
        button: 1
    }

    const onSubmit = e => {
        e.preventDefault();
        if (state.button == 1) {
            saveBtnClick();
        }
        else if (state.button == 2) {
            updateBtnClick();
        }
    }

    useEffect(() => {
        function handlekeydownEvent(event) {
            if (event.key === "Enter") {
                // console.log("Enter is pressed!")
                event.preventDefault();

                let btn1 = document.querySelector('.termObsMasterSaveBtn')
                if (btn1 !== null) {
                    btn1.click();
                }
                let btn2 = document.querySelector('.termObsMasterUpdateBtn')
                if (btn2 !== null) {
                    btn2.click();
                }
            }
        }
        document.addEventListener('keypress', handlekeydownEvent)
        return () => {
            document.removeEventListener('keypress', handlekeydownEvent)
        }
    }, []);

    return (
        <>
            {
                (loaderOption == true)
                    ?
                    <Loader />
                    :
                    <>
                        <TitleLabel titleId={titleId} />
                        <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: "500px" }}>
                            <form onSubmit={onSubmit}>
                                <input type="hidden" value={id} onChange={(e) => setId(e.target.value)} name="" />

                                <div className='row'>
                                    <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Observation Type :</label>
                                    <div className='col-sm-3'>
                                        <FormControl fullWidth margin='dense'>
                                            <InputLabel size="small" id='demo-simple-select-label' style={{ fontSize: '14px' }}>Observation Type</InputLabel>
                                            <Select
                                                MenuProps={{ classes: { paper: classes.menuPaper } }}
                                                size='small'
                                                fullWidth
                                                labelId="demo-simple-select-label"
                                                required
                                                id="demo-simple-select"
                                                value={obsType}
                                                label="Observation Type"
                                                onChange={(e) => {
                                                    setObsType(e.target.value);
                                                }}
                                            >
                                                <MenuItem value={1}>{(authUser.branchMedium == 1) ? "विशेष प्रगती" : "Special Progress"}</MenuItem>
                                                <MenuItem value={2}>{(authUser.branchMedium == 1) ? "आवड / छंद" : "Interest / Hobby"}</MenuItem>
                                                <MenuItem value={3}>{(authUser.branchMedium == 1) ? "सुधारणा आवश्यक" : "Needs Improvement"}</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div>

                                <div className="row my-2">
                                    <label className="col-sm-2" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }} >Boys Observation :</label>
                                    <div className='col-sm-6'>
                                        <TextField
                                            multiline
                                            value={boysObservation}
                                            onChange={e => setBoysObservation(e.target.value)}
                                            onKeyPress={(e) => (e.key == "Enter") ? boysObservation : ""}
                                            required
                                            fullWidth
                                            onKeyDown={(e) => (e.key == ' ' || boysObservation.length == 0) ? e.preventDefault : ''}
                                            autoComplete='off'
                                            size='small'
                                            id="outlined-basic"
                                            label="Boys Observation"
                                            variant="outlined"
                                            margin='dense'
                                            InputLabelProps={{ style: { fontSize: 14 } }}
                                            inputProps={{ maxLength: 120 }}
                                        />
                                    </div>
                                </div>

                                <div className="row my-2">
                                    <label className="col-sm-2" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }} >Girls Observation :</label>
                                    <div className='col-sm-6'>
                                        <TextField
                                            multiline
                                            value={girlsObservation}
                                            onChange={e => setGirlsObservation(e.target.value)}
                                            onKeyPress={(e) => (e.key == "Enter") ? girlsObservation : ""}
                                            required
                                            fullWidth
                                            onKeyDown={(e) => (e.key == ' ' || girlsObservation.length == 0) ? e.preventDefault : ''}
                                            autoComplete='off'
                                            size='small'
                                            id="outlined-basic"
                                            label="Girls Observation"
                                            variant="outlined"
                                            margin='dense'
                                            InputLabelProps={{ style: { fontSize: 14 } }}
                                            inputProps={{ maxLength: 120 }}
                                        />
                                    </div>
                                </div>

                                <div className="row mt-3">
                                    <div className="col-sm-12 offset-sm-2">
                                        <button type='submit' onClick={() => (state.button = 1)} className={saveBtn}>Save</button>
                                        <button type='submit' onClick={() => (state.button = 2)} className={updateBtn}>Update</button>
                                        <button type='button' className='btn btn-sm btn-danger ml-2'>Cancel</button>
                                    </div>
                                </div>
                            </form>

                            <div className='mt-3'>
                                <TermObservationMasterTable
                                    TableHeading={TableHeading}
                                    data={data}
                                    filteredData={filteredData}
                                    setFilteredData={setFilteredData}
                                    getData={getData}
                                    clearData={clearData}
                                    setId={setId}
                                    setBoysObservation={setBoysObservation}
                                    setGirlsObservation={setGirlsObservation}
                                    setObsType={setObsType}
                                    setSaveBtn={setSaveBtn}
                                    setUpdateBtn={setUpdateBtn}
                                    updateBtnStyle={updateBtnStyle}
                                    displayNoneBtnStyle={displayNoneBtnStyle}
                                />
                            </div>
                        </div>
                    </>
            }
        </>
    )
}

export default TermObservationMaster