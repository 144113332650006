import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import axios from "axios";
import MasterForm4LabelTable from "./MasterForm4LabelTable";
import labelName from "../Services/MasterForm4LabelArray";
import { BaseURLMaster } from "../../CommonServices/APIURL";
import TitleLabel from "../../CommonComponent/TitleLabel";
import { ToastContainer, toast } from 'react-toastify';
import TextField from '@mui/material/TextField';
import 'react-toastify/dist/ReactToastify.css';
import Loader from "../../CommonComponent/Loader";

const MasterForm4Label = () => {
    let { formid } = useParams();
    //Note: This below states are declared for Save & Update(Disabled/unDisabled)

    const [loaderOption, setLoaderOption] = useState(false);

    const saveBtnStyle = "fourLabelFormSaveBtn btn btn-primary btn-sm";
    const updateBtnStyle = "fourLabelFormUpdateBtn btn btn-primary btn-sm";
    const displayNoneBtnStyle = "d-none";
    const [saveBtn, setSaveBtn] = useState(saveBtnStyle);
    const [updateBtn, setUpdateBtn] = useState(displayNoneBtnStyle);


    //const BaseURL = "http://192.168.1.111:8080/institute/sss/master/";
    const [titleId, setTitleId] = useState("");
    const [getMapping, setGetMapping] = useState("");
    const [postMapping, setPostMapping] = useState("");
    const [putMapping, setPutMapping] = useState("");
    const [deleteMapping, setDeleteMapping] = useState("");

    useEffect(() => {
        labelName.map((labels) => {

            if (labels.id == formid) {
                setTitleId(labels.title);
                setGetMapping(BaseURLMaster + "" + labels.GetMapping);
                setPostMapping(BaseURLMaster + "" + labels.PostMapping);
                setPutMapping(BaseURLMaster + "" + labels.PutMapping);
                setDeleteMapping(BaseURLMaster + "" + labels.DeleteMapping);
                // console.log(labels.GetMapping)
            }
        })
        if (getMapping != "") {
            getData();
        }

    }, [getMapping, postMapping, putMapping, deleteMapping, titleId, formid])



    const [id, setId] = useState("");
    const [englishName, setEnglishName] = useState("");
    const [marathiName, setMarathiName] = useState("");
    const [udise, setUdise] = useState("");
    //console.log(para);

    const TableHeading = [{ label: 'ID', key: 'id' }, { label: `${titleId} Name Mr`, key: 'nameMr' }, { label: `${titleId} Name En`, key: 'nameEn' }, { label: `U-DISE Code`, key: 'udiseNo' }, { label: 'Edit', value: 'edit', isAction: true }, { label: 'Delete', value: 'delete', isAction: true }];

    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState(data);


    // useEffect(() => {
    //     //getData();
    // }, [])

    const getData = async () => {
        await axios.get(`${getMapping}`)
            .then((response) => {
                setData(response.data);
                setFilteredData(response.data);
            })
    }

    const saveBtnClick = async () => {
        setLoaderOption(true);
        const saveObject = {
            id: id,
            nameMr: marathiName,
            nameEn: englishName,
            udiseNo: udise
        };
        await axios.post(`${postMapping}`, saveObject)
            .then((response) => {
                if (response.data != null) {
                    getData();
                    setLoaderOption(false);
                    toast.success("Saved sucessfully.");
                } else {
                    toast.error("Operation failed.");
                }
            });

        setMarathiName("");
        setEnglishName("");
        setId("");
        setUdise("");
        setSaveBtn(saveBtnStyle);
        setUpdateBtn(displayNoneBtnStyle);
    };

    const updateBtnClick = async () => {
        setLoaderOption(true);
        const updateObject = {
            id: id,
            nameMr: marathiName,
            nameEn: englishName,
            udiseNo: udise
        };
        await axios.put(`${putMapping}` + "/" + id, updateObject)
            .then((response) => {
                if (response.data != null) {
                    getData();
                    setLoaderOption(false);
                    toast.success("Update sucessfully.");
                }
            });
        setId("");
        setMarathiName("");
        setEnglishName("");
        setUdise("");
        setSaveBtn(saveBtnStyle);
        setUpdateBtn(displayNoneBtnStyle);
    };

    const ClearData = () => {
        setId("");
        setMarathiName("");
        setEnglishName("");
        setUdise("");
        setSaveBtn(saveBtnStyle);
        setUpdateBtn(displayNoneBtnStyle);
    }

    const state = {
        button: 1
    };

    const onSubmit = e => {
        e.preventDefault();
        if (state.button === 1) {
            saveBtnClick();
        }
        if (state.button === 2) {
            updateBtnClick();
        }
    };

    const handleKeyDownMr = (e) => {
        if (e.key === " " && marathiName.length === 0) {
            e.preventDefault();
        }
    };

    const handleKeyDownEn = (e) => {
        if (e.key === " " && englishName.length === 0) {
            e.preventDefault();
        }
    };

    const handleKeyDownUdise = (e) => {
        if (e.key === " " && udise.length === 0) {
            e.preventDefault();
        }
    };

    useEffect(() => {

        function handlekeydownEvent(event) {
            if (event.key === "Enter") {
                // console.log("Enter is pressed!")
                event.preventDefault();

                let btn1 = document.querySelector('.fourLabelFormSaveBtn')
                if (btn1 !== null) {
                    btn1.click();
                }
                let btn2 = document.querySelector('.fourLabelFormUpdateBtn')
                if (btn2 !== null) {
                    btn2.click();
                }

            }
        }

        document.addEventListener('keypress', handlekeydownEvent)
        return () => {
            document.removeEventListener('keypress', handlekeydownEvent)
        }

    }, []);

    if (loaderOption == true) {
        return <Loader />
    }
    else {
        return (
            <>
                <TitleLabel titleId={titleId} />
                <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: "500px" }}>
                    {/* Form Data start */}
                    <form onSubmit={onSubmit}>
                        <div className="form-data mt-1">
                            <input type="hidden" value={id} onChange={(e) => setId(e.target.value)} name="" />
                            <div className="row">
                                <label htmlFor="lastname3" className="col-sm-3" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>{titleId} Name :</label>
                                <div className='col-sm-4'>
                                    <TextField
                                        required
                                        fullWidth
                                        onKeyDown={handleKeyDownMr}
                                        autoComplete='off'
                                        className=''
                                        size='small'
                                        id="outlined-basic"
                                        margin='dense'
                                        label={titleId + " " + "Marathi"}
                                        variant="outlined"
                                        value={marathiName}
                                        onChange={(e) => setMarathiName(e.target.value)}
                                        onKeyPress={(e) => (e.key == "Enter") ? marathiName : ""}
                                        inputProps={{ maxLength: 120 }}
                                        InputLabelProps={{ style: { fontSize: 14 } }}
                                    />
                                </div>
                                <div className='col-sm-4'>
                                    <TextField
                                        required
                                        fullWidth
                                        onKeyDown={handleKeyDownEn}
                                        autoComplete='off'
                                        size='small'
                                        value={englishName}
                                        onChange={(e) => setEnglishName(e.target.value)}
                                        onKeyPress={(e) => (e.key == "Enter") ? englishName : ""}
                                        id="outlined-basic"
                                        label={titleId + " " + "English"}
                                        variant="outlined"
                                        margin='dense'
                                        inputProps={{ maxLength: 120 }}
                                        InputLabelProps={{ style: { fontSize: 14 } }}
                                    />
                                </div>
                            </div>

                            <div className="row my-2">
                                <label htmlFor="lastname3" className="col-sm-3" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>U-Dise No :</label>
                                <div className='col-sm-4'>
                                    <TextField
                                        required
                                        fullWidth
                                        onKeyDown={handleKeyDownUdise}
                                        autoComplete='off'
                                        className=''
                                        size='small'
                                        id="outlined-basic"
                                        label="U-Dise No"
                                        variant="outlined"
                                        margin='dense'
                                        value={udise}
                                        onChange={(e) => setUdise(e.target.value)}
                                        onKeyPress={(e) => (e.key == "Enter") ? udise : ""}
                                        inputProps={{ maxLength: 120 }}
                                        InputLabelProps={{ style: { fontSize: 14 } }}
                                    />
                                </div>
                            </div>

                            <div className="row mt-3">
                                <div className="col-sm-12 offset-sm-3">
                                    <button type="submit" onClick={() => (state.button = 1)} className={saveBtn} >Save</button>
                                    <button type="submit" onClick={() => (state.button = 2)} className={updateBtn} >Update</button>
                                    <button type="button" onClick={ClearData} className="btn btn-danger btn-sm" style={{ margin: '0px 4px' }} >Cancel</button>

                                </div>
                            </div>
                        </div>
                    </form>
                    {/* Form Data end */}

                    <div className="tableData">
                        <MasterForm4LabelTable
                            deleteMapping={deleteMapping}
                            TableHeading={TableHeading}
                            setFilteredData={setFilteredData}
                            filteredData={filteredData}
                            setId={setId}
                            setMarathiName={setMarathiName}
                            setEnglishName={setEnglishName}
                            setUdise={setUdise}
                            getData={getData}
                            data={data}
                            setData={setData}
                            saveBtnStyle={saveBtnStyle}
                            updateBtnStyle={updateBtnStyle}
                            displayNoneBtnStyle={displayNoneBtnStyle}
                            setSaveBtn={setSaveBtn}
                            setUpdateBtn={setUpdateBtn}
                        />
                    </div>

                </div>
                <ToastContainer position="top-right" theme="colored" />
            </>
        )
    }

}
export default MasterForm4Label;