import React, { useState, useEffect } from 'react';
import TitleLabel from "../../CommonComponent/TitleLabel";
import TextField from '@mui/material/TextField';
import axios from "axios";
import { useSelector } from "react-redux";
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import { ToastContainer, toast } from 'react-toastify';
import Loader from '../../CommonComponent/Loader';
import Autocomplete from "@mui/material/Autocomplete";
import Paper from "@mui/material/Paper";
import makeStyles from "@mui/styles/makeStyles";
import { getSectionURL } from '../../CommonServices/EmployeeMasterAPIURL';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import { allBudgetCategoriesAPIURL } from '../BudgetMaster/Services/BudgetCategoriesAPIURL';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import PaymentsIcon from '@mui/icons-material/Payments';
import {
    getCategoryReportDataCategoryWiseAPIURL, getCategoryReportDataAllAPIURL, printAllBudgetEntryReportAPIURL,
    printCategoryWiseBudgetEntryReportAPIURL, printCategoryWiseAssetReportAPIURL, getCategoryBySectionAndBranch,
    getSainikSchoolHostelConsolidatedDataAPIURL,
    printSanikSchoolHostelConsolidatedReport
} from './Services/BudgetReportAPIURL';
import { BaseURLInstituteMaster } from '../../CommonServices/InstituteMasterAPIURL';
import { getExportedReportAPIURL } from '../../CommonServices/APIURL';
import BudgetCategoryDetailReportTable from './BudgetReportTables/BudgetCategoryDetailReportTable';
import BudgetAssetEntryReportTable from './BudgetReportTables/BudgetAssetEntryReportTable';
import { getBudgetResolutionNoDateAPIURL } from '../Services/BudgetNoAndDateAPIURL';
import { getBudgetAssetEntryAPIURL } from '../Transaction/Services/BudgetEntryAPIURL';
import SainikSchoolConsolidatedReportTable from './SansthaBudgetReportTables/SainikSchoolConsolidatedReportTable';


const useStyles = makeStyles(theme => ({
    menuPaper: {
        maxHeight: 200,
        "& ul": {
            backgroundColor: "#F4ECF7",
            //maxHeight: 160,
        },
        "& li": {
            // backgroundColor: "#F4ECF7", fontWeight: "550", fontSize: "14px" 
            fontSize: "14px",
            fontWeight: "550"
        },
    }
}));

const SainikSchoolHostelBudgetReport = () => {
    const authUser = useSelector((state) => state.user.value);
    const titleId = "Sainik School Hostel Budget Report";
    const classes = useStyles();
    const [loaderOption, setLoaderOption] = useState(false);

    const [previousYrTotalBudget, setPreviousYrTotalBudget] = useState(0);
    const [nextYrTotalBudget, setNextYrTotalBudget] = useState(0);
    const [actualDecTotalBudget, setActualDecTotalBudget] = useState(0);
    const [actualMarchTotalBudget, setActualMarchTotalBudget] = useState(0);

    const [futureYear, setFutureYear] = useState('');
    const [categoryData, setCategoryData] = useState([]);
    const [categoryObj, setCategoryObj] = useState(null);
    const [optionId, setOptionId] = useState(1);

    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);

    const [side, setSide] = useState([]);
    const [budgetSide, setBudgetSide] = useState([]);
    const [categoryNm, setCategoryNm] = useState('');

    const [schoolData, setSchoolData] = useState([]);
    const [schoolNm, setSchoolNm] = useState('');

    const [categoryType, setCategoryType] = useState('');

    const [resolutionNo, setResolutionNo] = useState('');
    const [resolutionDate, setResolutionDate] = useState(null);

    const [actualSchoolReceiptHostelBudget, setActualSchoolReceiptHostelBudget] = useState(0);
    const [actualSchoolPaymentHostelBudget, setActualSchoolPaymentHostelBudget] = useState(0);

    const [studentWiseBudget, setStudentWiseBudget] = useState(0);

    const [totalAssetEntryBudget, setTotalAssetEntryBudget] = useState(0);

    const [totalActualBudget, setTotalActualBudget] = useState(0);

    const currentYear = new Date();

    useEffect(() => {
        if (authUser.sessionYear < moment(currentYear).format("YYYY")) {
            setFutureYear(moment(currentYear).format("YY"));
        }
        // if (authUser.sessionYear == moment(currentYear).format("YYYY")) {
        //     setFutureYear(moment(currentYear).format("YY"));
        // }
        else {
            setFutureYear(moment(currentYear).add(1, 'year').format("YY"))
        }
    }, [authUser])

    //BudgetCategoryDetailReportTable
    const TableHeading2 = [
        { label: 'जमा बाजू तपशील', rowspan: (optionId == 1) ? '2' : '', colspan: (optionId == 1) ? '' : '1', key: 'receipt', isAction: true },
        { label: 'जमा', value: 'budget', rowspan: '', colspan: '1', isAction: true },
        { label: 'Actual Budget', value: 'actual', rowspan: '', colspan: '3', isAction: true },
        { label: 'अंदाजे जमा', value: 'budget', rowspan: '', colspan: '1', isAction: true },
    ];

    const TableHeading3 = [
        { label: 'खर्च बाजू तपशील', rowspan: (optionId == 1) ? '2' : '', colspan: (optionId == 1) ? '' : '1', key: 'payment', isAction: true },
        { label: 'खर्च', value: 'budget', rowspan: '', colspan: '1', isAction: true },
        { label: 'Actual', value: 'actual', rowspan: '', colspan: '3', isAction: true },
        { label: 'अंदाजे खर्च', value: 'budget', rowspan: '', colspan: '1', isAction: true }
    ];

    const TableHeading5 = [
        { label: `${(categoryObj != null) ? categoryObj.nameMr : ''}`, key: 'categoryId', isAction: true },
        { label: `${parseInt(authUser.sessionYear)}-${futureYear}`, value: 'budgetLastYear', isAction: true },
        { label: `31/12/${parseInt(authUser.sessionYear)}`, value: 'actualBudget1', isAction: true },
        { label: `31/03/${parseInt(authUser.sessionYear) + 1}`, value: 'actualBudget2', isAction: true },
        { label: `एकूण ${(categoryObj != null) ? ((categoryObj.optionId == 1) ? "जमा" : "खर्च") : ''}`, value: 'actualBudget2', isAction: true },
        { label: `${parseInt(authUser.sessionYear) + 1}-${parseInt(futureYear) + 1}`, value: 'budgetNextYear', isAction: true },

    ];

    //SainikSchoolConsolidatedReportTable
    const TableHeading7 = [
        { label: 'वसतिगृह कीर्द जमा रुपये', textAlign: "center", key: 'receipt', isAction: true },
        { label: 'तपशील', key: 'payment', textAlign: "center", isAction: true },
        { label: 'वसतिगृह कीर्द खर्च रुपये', textAlign: "center", key: 'payment', isAction: true },
    ]

    //BudgetAssetEntryReportTable
    const TableHeading8 = [
        { label: 'तपशील', key: 'accNameMr', textAlign: "", isAction: true },
        { label: 'नगसंख्या', key: 'quantity', textAlign: "", isAction: true },
        { label: 'दर', key: 'rate', textAlign: "center", isAction: true },
        { label: 'एकूण', key: 'totalRate', textAlign: "center", isAction: true },
    ]


    useEffect(() => {
        getData();
    }, [])


    const getData = async () => {
        await axios.get(`${BaseURLInstituteMaster}`)
            .then(res => {
                setSchoolData(res.data);
            })

        //console.log(`${getCategoryBySectionAndBranch}?sid=${authUser.deptId}&bid=${authUser.branchId}`)
        await axios.get(`${getCategoryBySectionAndBranch}?sid=14&bid=36`)
            .then(res => {
                let newData = res.data.filter(e => e.categoryType != 2)
                setCategoryData(newData);
            })

    }

    //get budget details
    const getCategoryWiseDetails = async () => {

        setLoaderOption(true);
        setData([]);
        setFilteredData([]);
        //set school name by schoolid
        schoolData.map(item => {
            if (item.id == 36) {
                setSchoolNm(item.schoolNameMr);
            }
        })

        //get budget resolution date and no
        axios.get(`${getBudgetResolutionNoDateAPIURL}?sessionYear=${authUser.sessionYear}&bid=36&sid=14`)
            .then(res => {
                res.data.map(e => {
                    setResolutionDate(e.date);
                    setResolutionNo(e.resolutionNo);
                })
            })

        //categorywise budget details
        if (optionId == 2) {
            setCategoryType(categoryObj.categoryType)

            if (categoryObj.categoryType == 1) {
                setActualDecTotalBudget('');
                setActualMarchTotalBudget('');
                setNextYrTotalBudget('');
                setPreviousYrTotalBudget('');
                setSide([]);
                setBudgetSide([]);
                //console.log(categoryObj.deptId)

                // console.log(`${getCategoryReportDataCategoryWiseAPIURL}?categoryId=${categoryObj.id}&sessionYear=${authUser.sessionYear}&sid=14&bid=36`)
                await axios.get(`${getCategoryReportDataCategoryWiseAPIURL}?categoryId=${categoryObj.id}&sessionYear=${authUser.sessionYear}&sid=14&bid=36`)
                    .then(res => {
                        if (res.data == "NOTFOUND") {
                            setLoaderOption(false);
                            toast.warn("Data not found.")
                        }
                        else {
                            let upadtedData = res.data
                            upadtedData.map((e, index) => {
                                upadtedData[index] = ({
                                    ...e,
                                    totalActualBudget: parseFloat(e.actualBudget1) + parseFloat(e.actualBudget2)
                                })
                            })


                            setData(upadtedData);
                            setFilteredData(upadtedData);

                            const TotalActualBudget = upadtedData.reduce((prev, curr) => prev + parseFloat(curr.totalActualBudget), 0);
                            //console.log(TotalActualBudget)
                            setTotalActualBudget(parseFloat(TotalActualBudget).toFixed('2'));
                            //console.log(res.data);

                            res.data.map(e => {
                                setActualDecTotalBudget((e.budget1Total).toFixed('2'));
                                setActualMarchTotalBudget((e.budget2Total).toFixed('2'));
                                setNextYrTotalBudget((e.nextYearTotal).toFixed('2'));
                                setPreviousYrTotalBudget((e.lastYearTotal).toFixed('2'));

                                if (e.optionId == 1) {
                                    setSide(TableHeading2);
                                    setBudgetSide(TableHeading5)
                                }
                                else if (e.optionId == 2) {
                                    setSide(TableHeading3);
                                    setBudgetSide(TableHeading5)
                                }

                            })
                            setLoaderOption(false);
                        }

                    }).catch(err => {
                        console.log("categorywise detail report err:" + err);
                        setLoaderOption(false);
                        toast.error("Something went wrong, please check.")
                    })
            }
            else if (categoryObj.categoryType == 3) {
                await axios.get(`${getBudgetAssetEntryAPIURL}?categoryId=${categoryObj.id}&sessionYear=${authUser.sessionYear}&sid=14&bid=36`)
                    .then(res => {
                        if (res.data.length == 0) {
                            setLoaderOption(false);
                            toast.warn("Data not found.")
                        }
                        else {
                            setData(res.data);
                            setFilteredData(res.data);

                            const TotalAssetBudget = res.data.reduce((prev, curr) => prev + parseFloat(curr.totalRate), 0);
                            setTotalAssetEntryBudget(parseFloat(TotalAssetBudget))
                            //console.log(res.data)                       
                            setLoaderOption(false);
                        }

                    }).catch(err => {
                        console.log("categorywise asset report err:" + err);
                        setLoaderOption(false);
                        toast.error("Something went wrong, please check.")
                    })
            }

        }
        //consolidated budget details
        else if (optionId == 1) {

            await axios.get(`${getSainikSchoolHostelConsolidatedDataAPIURL}?sessionYear=${authUser.sessionYear}&bid=36&sid=14`)
                .then(res => {
                    if (res.data == "NOTFOUND") {
                        setLoaderOption(false);
                        toast.warn("Data not found.")
                    }
                    else {
                        //console.log(JSON.stringify(res.data))
                        const objIds = res.data.reduce((a, { studentCount }) => {
                            a[studentCount] = a[studentCount] || { studentCount }
                            return {
                                ...a, ...{
                                    [studentCount]: {
                                        studentCount,
                                    }
                                }
                            }
                        }, {})
                        const result = Object.values(objIds)


                        setData(res.data);
                        setFilteredData(res.data);

                        let receiptHostelData = res.data.filter(e => e.optionId == 1)
                        let paymentHostelData = res.data.filter(e => e.optionId == 2)

                        const TotalActualReceiptHostelBudget = receiptHostelData.reduce((prev, curr) => prev + parseFloat(curr.schoolFee), 0);
                        setActualSchoolReceiptHostelBudget(parseFloat(TotalActualReceiptHostelBudget).toFixed('2'));

                        const TotalActualPaymentHostelBudget = paymentHostelData.reduce((prev, curr) => prev + parseFloat(curr.schoolFee), 0);
                        setActualSchoolPaymentHostelBudget(parseFloat(TotalActualPaymentHostelBudget).toFixed('2'));


                        const TotalStudentCount = result.reduce((prev, curr) => prev + parseFloat(curr.studentCount), 0);
                        let studentCountValue = TotalActualPaymentHostelBudget / TotalStudentCount
                        //console.log(studentCountValue)
                        setStudentWiseBudget(parseFloat(studentCountValue).toFixed('2'))

                        setLoaderOption(false);
                    }

                }).catch(err => {
                    console.log("consolidate report err:" + err);
                    setLoaderOption(false);
                    toast.error("Something went wrong, please check.")
                })

        }
    }

    //print budget details
    const printAllBudgetEntryReport = async () => {
        setLoaderOption(true);
        //categorywise print budget details
        if (optionId == 1) {
            setLoaderOption(true);
            await axios.get(`${printSanikSchoolHostelConsolidatedReport}?sessionYear=${authUser.sessionYear}&bid=36&sid=14&langId=${authUser.branchMedium}`)
                .then(res => {
                    if (res.data == "NOTFOUND") {
                        setLoaderOption(false);
                        toast.warn("Data not found.");
                    }
                    else {
                        setLoaderOption(false);
                        window.open(`${getExportedReportAPIURL}${res.data}`)
                    }
                }).catch(err => {
                    console.log("Hostel Consolidated Report err:" + err);
                    setLoaderOption(false);
                    toast.error("Something went wrong, please check.")
                })
        }
        //consolidated print budget details
        else if (optionId == 2) {

            if (categoryObj.categoryType == 1) {
                // console.log(`${printCategoryWiseBudgetEntryReportAPIURL}?categoryId=${categoryObj.id}&sessionYear=${authUser.sessionYear}&sid=14&bid=36&langId=${authUser.branchMedium}`)
                await axios.get(`${printCategoryWiseBudgetEntryReportAPIURL}?categoryId=${categoryObj.id}&sessionYear=${authUser.sessionYear}&sid=14&bid=36&langId=${authUser.branchMedium}`)
                    .then(res => {
                        if (res.data == "NOTFOUND") {
                            setLoaderOption(false);
                            toast.warn("Data not found.");
                        }
                        else {
                            setLoaderOption(false);
                            window.open(`${getExportedReportAPIURL}${res.data}`)
                        }
                    }).catch(err => {
                        console.log("Budget category wise Detail Report err:" + err);
                        setLoaderOption(false);
                        toast.error("Something went wrong, please check.")
                    })
            }
            else if (categoryObj.categoryType == 3) {
                await axios.get(`${printCategoryWiseAssetReportAPIURL}?categoryId=${categoryObj.id}&sessionYear=${authUser.sessionYear}&sid=14&bid=36&langId=${authUser.branchMedium}`)
                    .then(res => {
                        if (res.data == "NOTFOUND") {
                            setLoaderOption(false);
                            toast.warn("Data not found.");
                        }
                        else {
                            setLoaderOption(false);
                            window.open(`${getExportedReportAPIURL}${res.data}`)
                        }
                    }).catch(err => {
                        console.log("Budget category wise Asset Report err:" + err);
                        setLoaderOption(false);
                        toast.error("Something went wrong, please check.")
                    })
            }
        }
    }


    return (
        <>
            {
                (loaderOption == true)
                    ?
                    <Loader />
                    :
                    <>
                        <TitleLabel titleId={titleId} />
                        <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: "300px" }}>
                            {/* Form Data start */}
                            <div className=''>
                                <form>
                                    <div className="form-data">
                                        <div className="row my-2">
                                            <label className="" style={{ width: "150px", fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Option :</label>
                                            <div className='col-sm-3'>
                                                <FormControl fullWidth margin='dense'>
                                                    <InputLabel id='demo-simple-select-label' size="small" style={{ fontSize: '14px' }}>Select Option</InputLabel>
                                                    <Select
                                                        size='small'
                                                        required
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        value={optionId}
                                                        label="Select Option"
                                                        MenuProps={{ classes: { paper: classes.menuPaper } }}
                                                        onChange={(e) => {
                                                            setOptionId(e.target.value);
                                                            setData([]);
                                                            setFilteredData([]);
                                                            setCategoryObj(null);
                                                        }}
                                                        onKeyPress={(e) => (e.key == "Enter") ? optionId : ""}
                                                    >

                                                        <MenuItem value={1}>Consolidated</MenuItem>
                                                        <MenuItem value={2}>Category Wise</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </div>
                                        </div>

                                        <div className="row my-2" hidden={(optionId == 1) ? true : false}>
                                            <label className="" style={{ width: "150px", fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Category :</label>
                                            <div className='col-sm-4'>
                                                <Autocomplete
                                                    id="combo-box-demo"
                                                    options={categoryData}
                                                    PaperComponent={({ children }) => (
                                                        <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                                    )}
                                                    onChange={(event, newValue) => {
                                                        setCategoryObj(newValue);
                                                        setData([]);
                                                        setFilteredData([]);
                                                    }}
                                                    value={categoryObj}
                                                    style={{ fontSize: '14px' }}
                                                    getOptionLabel={option => (authUser.branchMedium == 1) ? option.nameMr : option.nameEn}
                                                    // renderOption={(props, option) => (
                                                    //     <div className='' {...props}>
                                                    //         <div className=''>
                                                    //             {(authUser.branchMedium == 1) ? option.nameMr : option.nameEn} <br />
                                                    //             <div
                                                    //                 className=''
                                                    //                 style={{
                                                    //                     display: "flex",
                                                    //                     flex: 1,
                                                    //                     fontSize: "11px",
                                                    //                     flexDirection: "row",
                                                    //                     //justifyContent: "space-between",
                                                    //                     padding: "0px 5px",
                                                    //                     color: "#464964",
                                                    //                     fontWeight: "550"
                                                    //                     //gridRow: 1
                                                    //                 }}
                                                    //             >
                                                    //                 <span><AccountBalanceIcon style={{ color: "#354AFA", fontSize: "14px" }} /> {(authUser.branchMedium == 1) ? option.deptNameMr : option.deptNameEn}</span>
                                                    //                 <span className='ml-1 mr-1 mb-1' style={{ fontSize: "14px" }}>|</span>
                                                    //                 <span>{(option.side == "Receipt") ? <ReceiptLongIcon style={{ color: "#354AFA", fontSize: "14px" }} /> : <PaymentsIcon style={{ color: "#354AFA", fontSize: "14px" }} />} {option.side}</span>
                                                    //             </div>
                                                    //         </div>

                                                    //     </div>
                                                    // )}
                                                    size="small"
                                                    renderInput={params => (
                                                        <TextField {...params} margin='dense' label="Select Category" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                                    )}
                                                />
                                            </div>
                                        </div>

                                        <div className="row mt-3">
                                            <div className="col-sm-4" style={{ marginLeft: "150px" }}>
                                                <button type='button' disabled={((optionId == 2) ? (categoryObj == null || categoryObj == '') : '') ? true : false} onClick={() => getCategoryWiseDetails()} className='btn btn-sm btn-primary'>Show</button>
                                            </div>
                                        </div>
                                    </div>
                                </form>

                                <div className='mt-2'>
                                    {(() => {
                                        if (data.length != 0 || filteredData.length != 0) {
                                            if (optionId == 1) {
                                                return (
                                                    <>
                                                        <SainikSchoolConsolidatedReportTable
                                                            TableHeading7={TableHeading7}
                                                            setData={setData}
                                                            setFilteredData={setFilteredData}
                                                            data={data}
                                                            filteredData={filteredData}
                                                            schoolNm={schoolNm}
                                                            resolutionNo={resolutionNo}
                                                            resolutionDate={resolutionDate}
                                                            printAllBudgetEntryReport={printAllBudgetEntryReport}
                                                            actualSchoolReceiptHostelBudget={actualSchoolReceiptHostelBudget}
                                                            actualSchoolPaymentHostelBudget={actualSchoolPaymentHostelBudget}
                                                            optionId={optionId}
                                                            studentWiseBudget={studentWiseBudget}
                                                        />
                                                    </>
                                                )
                                            }
                                            else if (optionId == 2) {
                                                if (categoryType == 1) {
                                                    return (
                                                        <>
                                                            <BudgetCategoryDetailReportTable
                                                                setData={setData}
                                                                setFilteredData={setFilteredData}
                                                                data={data}
                                                                filteredData={filteredData}
                                                                side={side}
                                                                budgetSide={budgetSide}
                                                                previousYrTotalBudget={previousYrTotalBudget}
                                                                actualDecTotalBudget={actualDecTotalBudget}
                                                                actualMarchTotalBudget={actualMarchTotalBudget}
                                                                nextYrTotalBudget={nextYrTotalBudget}
                                                                optionId={optionId}
                                                                categoryNm={categoryNm}
                                                                schoolNm={schoolNm}
                                                                printAllBudgetEntryReport={printAllBudgetEntryReport}
                                                                resolutionNo={resolutionNo}
                                                                resolutionDate={resolutionDate}
                                                                totalActualBudget={totalActualBudget}
                                                            />
                                                        </>
                                                    )
                                                }
                                                else if (categoryType == 3) {
                                                    return (
                                                        <>
                                                            <BudgetAssetEntryReportTable
                                                                TableHeading8={TableHeading8}
                                                                setData={setData}
                                                                setFilteredData={setFilteredData}
                                                                data={data}
                                                                filteredData={filteredData}
                                                                schoolNm={schoolNm}
                                                                resolutionNo={resolutionNo}
                                                                resolutionDate={resolutionDate}
                                                                printAllBudgetEntryReport={printAllBudgetEntryReport}
                                                                totalAssetEntryBudget={totalAssetEntryBudget}
                                                            />
                                                        </>
                                                    )
                                                }
                                            }
                                        }
                                    })()}
                                </div>
                            </div>
                        </div>
                    </>
            }
        </>
    )
}

export default SainikSchoolHostelBudgetReport