import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from "@mui/material/Autocomplete";
import axios from 'axios';
import { toast } from 'react-toastify';
import Paper from "@mui/material/Paper";
import { DesignationMasterGetMapping, } from '../../../CommonServices/StudentRegistrationAPIURL';
import { saveFooterSignature, getFooterSignature } from '../../../CommonServices/InstituteMasterAPIURL';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const FooterSignature = ({ branchId }) => {
    //Note: This below states are declared for Save & Update(Disabled/unDisabled)
    const saveBtnStyle = "btn btn-primary btn-sm";
    const [saveBtn, setSaveBtn] = useState(saveBtnStyle);
    const navigate = useNavigate();

    const [id, setId] = useState('');
    const [signature1, setSignature1] = useState(null)
    const [signature2, setSignature2] = useState(null)
    const [signature3, setSignature3] = useState(null)
    const [lcSignature1, setLcSignature1] = useState(null)
    const [lcSignature2, setLcSignature2] = useState(null)
    const [lcSignature3, setLcSignature3] = useState(null)
    const [designationData, setDesignationData] = useState([]);

    useEffect(() => {
        getData();
    }, [])


    const getData = async () => {
        await axios.get(`${DesignationMasterGetMapping}`)
            .then((response) => {
                const dataWithNA = [{ id: 0, nameMr: 'N.A.', nameEn: 'N.A.' }, ...response.data];
                setDesignationData(dataWithNA);
            })

        await axios.get(`${getFooterSignature}?bid=${branchId}`)
            .then((response) => {
                const signatureData = response.data;
                if (signatureData) {
                    setSignature1({ nameMr: signatureData ? signatureData.leftSignMr : null, nameEn: signatureData ? signatureData.leftSignEn : null });
                    setSignature2({ nameMr: signatureData ? signatureData.centerSignMr : null, nameEn: signatureData ? signatureData.centerSignEn : null });
                    setSignature3({ nameMr: signatureData ? signatureData.rightSignMr : null, nameEn: signatureData ? signatureData.rightSignEn : null });
                    setLcSignature1({ nameMr: signatureData ? signatureData.lcLeftSignMr : null, nameEn: signatureData ? signatureData.lcLeftSignEn : null });
                    setLcSignature2({ nameMr: signatureData ? signatureData.lcCenterSignMr : null, nameEn: signatureData ? signatureData.lcCenterSignEn : null });
                    setLcSignature3({ nameMr: signatureData ? signatureData.lcRightSignMr : null, nameEn: signatureData ? signatureData.lcRightSignEn : null });
                }
            })
    }

    const saveBtnClick = () => {

        const saveObj = {
            leftSignMr: signature1 ? signature1.nameMr : null,
            leftSignEn: signature1 ? signature1.nameEn : null,
            centerSignMr: signature2 ? signature2.nameMr : null,
            centerSignEn: signature2 ? signature2.nameEn : null,
            rightSignMr: signature3 ? signature3.nameMr : null,
            rightSignEn: signature3 ? signature3.nameEn : null,

            lcLeftSignMr: lcSignature1 ? lcSignature1.nameMr : null,
            lcLeftSignEn: lcSignature1 ? lcSignature1.nameEn : null,
            lcCenterSignMr: lcSignature2 ? lcSignature2.nameMr : null,
            lcCenterSignEn: lcSignature2 ? lcSignature2.nameEn : null,
            lcRightSignMr: lcSignature3 ? lcSignature3.nameMr : null,
            lcRightSignEn: lcSignature3 ? lcSignature3.nameEn : null,

            branchId: branchId,
        }
        // console.log(JSON.stringify(saveObj))


        axios.post(saveFooterSignature, saveObj)
            .then((response) => {
                if (response.data == "SAVED") {
                    getData();
                    toast.success("Data Saved.")
                } else {
                    toast.error("Operation failed.");
                }
            });
    }

    return (
        <>
            <form>
                <input type="hidden" value={id} onChange={(e) => setId(e.target.value)} name="" />
                <div className="row my-1 d-flex justify-content-center align-items-center">
                    <label className="col-sm-1  text-center" style={{ fontSize: "18px", fontWeight: "600" }} >Exam</label>
                </div>
                <div className="row my-1">
                    <label className="col-sm-1" style={{ fontSize: "15px", fontWeight: "600", marginTop: "5px" }} >Left Side :</label>
                    <div className='col-sm-3'>
                        <Autocomplete
                            id="combo-box-demo-1"
                            options={designationData}
                            PaperComponent={({ children }) => (
                                <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                            )}
                            value={signature1}
                            onChange={(event, newValue) => {
                                setSignature1(newValue);
                            }}
                            style={{ fontSize: '14px' }}
                            getOptionLabel={option => (option.nameMr && option.nameEn) ? option.nameMr + ' / ' + option.nameEn : ''}
                            size="small"
                            renderInput={params => (
                                <TextField {...params} margin='dense' label="Left Side Signature" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} />
                            )}
                        />
                    </div>

                    <label className="col-sm-1" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }} >Center :</label>
                    <div className='col-sm-3'>
                        <Autocomplete
                            id="combo-box-demo-2"
                            options={designationData}
                            PaperComponent={({ children }) => (
                                <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                            )}
                            value={signature2}
                            onChange={(event, newValue) => {
                                setSignature2(newValue);
                            }}
                            style={{ fontSize: '14px' }}
                            getOptionLabel={option => (option.nameMr && option.nameEn) ? option.nameMr + ' / ' + option.nameEn : ''}
                            size="small"
                            renderInput={params => (
                                <TextField {...params} margin='dense' label="Center Signature" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} />
                            )}
                        />
                    </div>

                    <label className="col-sm-1" style={{ fontSize: "15px", fontWeight: "600", marginTop: "5px" }} >Right Side :</label>
                    <div className='col-sm-3'>
                        <Autocomplete
                            id="combo-box-demo-3"
                            options={designationData}
                            PaperComponent={({ children }) => (
                                <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                            )}
                            value={signature3}
                            onChange={(event, newValue) => {
                                setSignature3(newValue);
                            }}
                            style={{ fontSize: '14px' }}
                            getOptionLabel={option => (option.nameMr && option.nameEn) ? option.nameMr + ' / ' + option.nameEn : ''}
                            size="small"
                            renderInput={params => (
                                <TextField {...params} margin='dense' label="Right Side Signature" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} />
                            )}
                        />
                    </div>
                </div>
                <div className="row my-1 d-flex justify-content-center align-items-center">
                    <label className="col-sm-1 text-center" style={{ fontSize: "18px", fontWeight: "600", marginTop: "16px" }} >LC</label>
                </div>
                <div className="row my-1">
                    <label className="col-sm-1" style={{ fontSize: "15px", fontWeight: "600", marginTop: "5px" }} >Left Side :</label>
                    <div className='col-sm-3'>
                        <Autocomplete
                            id="combo-box-demo-1"
                            options={designationData}
                            PaperComponent={({ children }) => (
                                <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                            )}
                            value={lcSignature1}
                            onChange={(event, newValue) => {
                                setLcSignature1(newValue);
                            }}
                            style={{ fontSize: '14px' }}
                            getOptionLabel={option => (option.nameMr && option.nameEn) ? option.nameMr + ' / ' + option.nameEn : ''}
                            size="small"
                            renderInput={params => (
                                <TextField {...params} margin='dense' label="Left Side Signature" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} />
                            )}
                        />
                    </div>

                    <label className="col-sm-1" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }} >Center :</label>
                    <div className='col-sm-3'>
                        <Autocomplete
                            id="combo-box-demo-2"
                            options={designationData}
                            PaperComponent={({ children }) => (
                                <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                            )}
                            value={lcSignature2}
                            onChange={(event, newValue) => {
                                setLcSignature2(newValue);
                            }}
                            style={{ fontSize: '14px' }}
                            getOptionLabel={option => (option.nameMr && option.nameEn) ? option.nameMr + ' / ' + option.nameEn : ''}
                            size="small"
                            renderInput={params => (
                                <TextField {...params} margin='dense' label="Center Signature" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} />
                            )}
                        />
                    </div>

                    <label className="col-sm-1" style={{ fontSize: "15px", fontWeight: "600", marginTop: "5px" }} >Right Side :</label>
                    <div className='col-sm-3'>
                        <Autocomplete
                            id="combo-box-demo-3"
                            options={designationData}
                            PaperComponent={({ children }) => (
                                <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                            )}
                            value={lcSignature3}
                            onChange={(event, newValue) => {
                                setLcSignature3(newValue);
                            }}
                            style={{ fontSize: '14px' }}
                            getOptionLabel={option => (option.nameMr && option.nameEn) ? option.nameMr + ' / ' + option.nameEn : ''}
                            size="small"
                            renderInput={params => (
                                <TextField {...params} margin='dense' label="Right Side Signature" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} />
                            )}
                        />
                    </div>
                </div>

                <div className="row mt-3">
                    <div className="col-sm-4 offset-sm-1">
                        <button type="button" className={saveBtn} onClick={() => saveBtnClick()}>Save</button>
                        <button type="button" className="btn btn-danger btn-sm" style={{ margin: '0px 4px' }} onClick={() => navigate("/Home/m129/0")}>Cancel</button>
                    </div>
                </div>
            </form>
        </>
    )
}

export default FooterSignature