import React, { useState, useEffect } from 'react';
import TitleLabel from "../../../CommonComponent/TitleLabel";
import TextField from '@mui/material/TextField';
import Autocomplete from "@mui/material/Autocomplete";
import axios from "axios";
import Paper from "@mui/material/Paper";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import Loader from "../../../CommonComponent/Loader";
import AddIcon from '@mui/icons-material/Add';
import { classDivisionURL } from '../../../Administration/Services/CategoryReportAPIURL';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import subjectMarkTermData from '../../../CommonComponent/LocalJSON/SubjectMarkTermData';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import TermWiseHealthInput from './TermWiseHealthTextBox/TermWiseHealthInput';
import makeStyles from "@mui/styles/makeStyles";
import { getStudentsForTermWiseHealthAPIURL, saveTermwiseHealthAPIURL } from '../../Services/AfterExamAPIURL';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
    menuPaper: {
        maxHeight: 200,
        "& ul": {
            backgroundColor: "#F4ECF7",
            //maxHeight: 160,
        },
        "& li": {
            // backgroundColor: "#F4ECF7", fontWeight: "550", fontSize: "14px" 
            fontSize: "14px",
            fontWeight: "550"
        },
    }
}));


const TermWiseHealth = () => {
    const authUser = useSelector((state) => state.user.value);
    const classes = useStyles();
    const titleId = "Term Wise Health";

    const TableHeading = [
        { label: 'Reg No', key: 'regNo' },
        { label: `Student Name`, key: (authUser.branchMedium == 1) ? 'studentNameMr' : 'studentNameEn' },
        { label: `Roll No`, key: 'rollNo' },
        { label: 'Height (in cm)', key: 'height', isAction: true },
        { label: 'Weight (in kg)', key: 'weight', isAction: true },
        { label: 'BMI', key: 'bmi', isAction: true },
        { label: 'Medical Opinion', key: 'medicalOpinion', isAction: true }];

    const [loaderOption, setLoaderOption] = useState(false);

    const [isAscending, setAscending] = useState(false);
    const [sortedBy, setSortedBy] = useState(null);

    const [id, setId] = useState('');
    const [classDivData, setClassDivData] = useState([]);
    const [classDivObj, setClassDivObj] = useState(null);
    const [termObj, setTermObj] = useState('');
    const [date, setDate] = useState(null);
    const [filteredData, setFilteredData] = useState([]);
    const [data, setData] = useState([]);
    const [tempData, setTempData] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");

    const [height, setHeight] = useState(0);
    const [weight, setWeight] = useState(0);
    const [bmi, setBmi] = useState(0);
    const [medicalOpinion, setMedicalOpinion] = useState('');
    const [regNum, setRegNum] = useState('');

    useEffect(() => {
        getData();
    }, [authUser])

    const getData = async () => {
        if (authUser.deptId != null && authUser.branchId != null) {
            await axios.get(`${classDivisionURL}?sid=${authUser.deptId}&bid=${authUser.branchId}`)
                .then(res => {
                    setClassDivData(res.data);
                }).catch(error => {
                    console.log(error);
                })
        }
    }


    const onSort = (key, isAction) => {
        if (!isAction) {
            setSortedBy(key)
            if (isAscending) setFilteredData([...data.sort((a, b) => a[key] < b[key] ? 1 : -1)])
            else setFilteredData([...data.sort((a, b) => a[key] > b[key] ? 1 : -1)])
            setAscending(!isAscending)
        }
        setFilteredData(data);
        setData(data);
        getSortedData(data, key, isAction);
    }

    //data sorting
    const getSortedData = async (list, key, isAction) => {
        await axios(`${getStudentsForTermWiseHealthAPIURL}?classId=${classDivObj.classId}&divId=${classDivObj.divisionId}&year=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&termId=${termObj}`)
            .then(res => {
                //console.log(res); 
                if (!isAction) {
                    setSortedBy(key)
                    if (isAscending) setFilteredData([...(res.data).sort((a, b) => a[key].toString().toLowerCase() < b[key].toString().toLowerCase() ? 1 : -1)])
                    else setFilteredData([...(res.data).sort((a, b) => a[key].toString().toLowerCase() > b[key].toString().toLowerCase() ? 1 : -1)])
                    setAscending(!isAscending)
                    //console.log("Data============" + JSON.stringify(data))
                }
                setFilteredData([]);
                const checkedData = list.length === data.length && list.every((o, i) => Object.keys(o).length === Object.keys(data[i]).length && Object.keys(o).every(k => o[k] === data[i][k]))
                //setData(checkedData);
                //console.log("checkedData......" + JSON.stringify(checkedData))
                if (checkedData == true) {
                    setFilteredData(data);
                }
            }
            )
    }

    useEffect(() => {
        setFilteredData(
            data.filter(
                (item) =>
                    item.studentNameMr.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    item.studentNameEn.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    item.regNo.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
                    item.rollNo.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
                    item.weight.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
                    item.bmi.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
                    item.medicalOpinion.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    item.height.toString().toLowerCase().includes(searchTerm.toLowerCase())
            )
        );
    }, [searchTerm]);

    const getStudentListForTermWiseHealth = async (e) => {
        e.preventDefault();
        setLoaderOption(true);
        setData([]);
        setFilteredData([]);
        setSearchTerm('');
        setSortedBy(null);
        setAscending(false);

        await axios(`${getStudentsForTermWiseHealthAPIURL}?classId=${classDivObj.classId}&divId=${classDivObj.divisionId}&year=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&termId=${termObj}`)
            .then(res => {
                setData(res.data);
                setFilteredData(res.data);
                setTempData(res.data);
                setLoaderOption(false);
            }).catch(err => {
                setLoaderOption(false);
                console.log("Get student list err " + err);
                toast.error("Something went wrong, please check.")
            })
    }

    const saveTermWiseHealth = async (e) => {
        e.preventDefault();
        setLoaderOption(true);
        let BMI = 0;
        if (height == 0 && weight == 0) {
            BMI = 0;
        }
        else {
            let Height = parseFloat(height) / 100
            //Next, we can use the formula to calculate BMI:
            BMI = parseFloat(weight) / (parseFloat(Height) * parseFloat(Height))
        }


        const updatedData = data
        updatedData.map((e, index) => {
            updatedData[index] = ({
                ...e,
                "termId": termObj,
                "date": moment(date).format("YYYY-MM-DD"),
                "sessionYear": authUser.sessionYear,
                "sectionId": authUser.deptId,
                "branchId": authUser.branchId
            })
        })

        const newUpdateddata = updatedData
        newUpdateddata.map((e, index) => {
            if (e.regNo == regNum) {
                newUpdateddata[index] = ({
                    ...e,
                    "bmi": parseFloat(BMI).toFixed('2'),
                })
            }
        })
        // console.log(JSON.stringify(newUpdateddata));

        await axios.post(saveTermwiseHealthAPIURL, newUpdateddata)
            .then(res => {
                if (res.data == "SAVED") {
                    setData([]);
                    setFilteredData([]);
                    setLoaderOption(false);
                    toast.success("Term wise health saved successfully.")
                }
                else {
                    setLoaderOption(false);
                    toast.error("save failed.");
                }
            }).catch(err => {
                setLoaderOption(false);
                console.log("term wise health save " + err);
                toast.error("Something went wrong, please chcek.")
            })
    }

    useEffect(() => {

        let updateData = tempData
        tempData.map((e, index) => {
            if (e.regNo == regNum) {
                updateData[index] = ({
                    ...e,
                    height: height,
                    weight: weight,
                    bmi: bmi,
                    medicalOpinion: medicalOpinion,
                })
            }
        })
        setData(tempData);

        if (searchTerm == "") {
            setFilteredData(tempData);
        }

    }, [searchTerm, regNum])


    useEffect(() => {
        function handlekeydownEvent(event) {
            if (event.key === "Enter") {
                if (data == null || data == "" || data == []) {
                    event.preventDefault();
                    let btn = document.querySelector('.termWiseHealthBtn')
                    if (btn !== null) {
                        // console.log('Enter is pressed!');
                        btn.click();
                    }
                }
            }
        }

        document.addEventListener('keypress', handlekeydownEvent)
        return () => {
            document.removeEventListener('keypress', handlekeydownEvent)
        }


    }, []);

    return (
        <>
            {
                (loaderOption == true)
                    ?
                    <Loader />
                    :
                    <div>
                        <TitleLabel titleId={titleId} />
                        <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 0px 2px grey", marginTop: "16px", minHeight: "350px" }}>
                            {/* Endowment form starts */}
                            <form onSubmit={getStudentListForTermWiseHealth}>
                                <input type="hidden" value={id} onChange={(e) => setId(e.target.value)} name="" />

                                <div className="row my-2">
                                    <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Class-Division :</label>
                                    <div className='col-sm-3'>
                                        <Autocomplete
                                            id="combo-box-demo"
                                            options={classDivData}
                                            PaperComponent={({ children }) => (
                                                <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                            )}
                                            value={classDivObj}
                                            onChange={(event, newValue) => {
                                                setClassDivObj(newValue);
                                            }}
                                            style={{ fontSize: '14px' }}
                                            getOptionLabel={option => (authUser.branchMedium == 1) ? option.classNameMr + " " + option.divisionNameMr : option.classNameEn + " " + option.divisionNameEn}
                                            size="small"
                                            renderInput={params => (
                                                <TextField {...params} margin='dense' label="Select Class-division" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                            )}
                                        />
                                    </div>

                                    <label className="col-sm-1" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Term :</label>
                                    <div className='col-sm-3'>
                                        <FormControl fullWidth margin='dense'>
                                            <InputLabel size="small" id='demo-simple-select-label' style={{ fontSize: '14px' }}>Term</InputLabel>
                                            <Select
                                                MenuProps={{ classes: { paper: classes.menuPaper } }}
                                                size='small'
                                                fullWidth
                                                labelId="demo-simple-select-label"
                                                required
                                                id="demo-simple-select"
                                                value={termObj}
                                                label="Term"
                                                onChange={(e) => {
                                                    setTermObj(e.target.value);
                                                }}
                                            >
                                                {subjectMarkTermData.filter(e => e.id != 3).map((item, index) => {
                                                    return (
                                                        <MenuItem value={item.id} key={index}>{(authUser.branchMedium == 1) ? item.nameMr : item.nameEn}</MenuItem>
                                                    )
                                                })}
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div>

                                <div className="row my-2">
                                    <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Date :</label>
                                    <div className='col-sm-3'>
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <DatePicker
                                                label="Date"
                                                inputFormat="dd/MM/yyyy"
                                                value={date}
                                                onChange={(newValue) => {
                                                    if (newValue === null) {
                                                        setDate(new Date());
                                                    }
                                                    else if (newValue !== null) {
                                                        setDate(newValue);
                                                    }

                                                }}
                                                renderInput={(params) =>
                                                    <TextField
                                                        {...params}
                                                        margin='dense'
                                                        variant="outlined"
                                                        InputLabelProps={{ style: { fontSize: 14 } }}
                                                        required
                                                        fullWidth
                                                        autoComplete='off'
                                                        size="small" />}
                                            />
                                        </LocalizationProvider>
                                    </div>

                                    <div className="col-sm-4 mt-3">
                                        <button type='submit' className='btn btn-sm btn-warning'>Fill Students</button>
                                    </div>
                                </div>
                            </form>

                            {
                                (data.length == 0)
                                    ?
                                    <h4 className='mt-5'>No Records, Select class-division, term and date</h4>
                                    :
                                    <div className="mt-4">
                                        <div className='row col-sm-4 my-1' style={{ float: 'right' }}>
                                            <div>
                                                <input
                                                    className='form-control form-control-sm'
                                                    type="text"
                                                    style={{ width: "250px", display: "block", float: "right", marginBottom: "6px", border: "1px solid #C2C1C1" }}
                                                    placeholder="Search Here"
                                                    onChange={(event) => { setFilteredData([]); setSearchTerm(event.target.value) }}
                                                    value={searchTerm}
                                                />
                                            </div>
                                        </div>
                                        <form onSubmit={saveTermWiseHealth}>
                                            <div className='table-responsive' style={{ maxHeight: "510px" }}>
                                                <table className="table table-bordered ">
                                                    <thead className="table-Default" style={{ position: "sticky", overflow: "hidden", top: "0", backgroundColor: "#EAECEE", borderBottom: "2px solid #464de4" }}>
                                                        <tr style={{ borderBottom: "2px solid #464de4", position: "sticky", overflow: "hidden" }}>
                                                            {TableHeading.map((item, index) => {
                                                                return (
                                                                    <th style={{ fontSize: "14px", fontWeight: "600" }} onClick={() => onSort(item.key, item.isAction)} key={index} scope="col">{`${item.label} ${sortedBy === item.key ? isAscending ? '↑' : '↓' : ''}`}</th>
                                                                )
                                                            })}
                                                        </tr>
                                                    </thead>
                                                    <tbody style={{ borderTop: "2px solid #464de4", borderBottom: "2px solid #464de4" }}>
                                                        {filteredData
                                                            .map((item, index) => {
                                                                return (

                                                                    <tr key={index} style={{ height: "65px" }}>
                                                                        <td style={{ fontSize: "15px", fontWeight: "500", width: "100px" }}>{item.regNo}</td>
                                                                        <td style={{ fontSize: "15px", fontWeight: "500", width: "250px", lineHeight: '1.5' }}>{(authUser.branchMedium == 1) ? item.studentNameMr : item.studentNameEn}</ td>
                                                                        <td style={{ fontSize: "15px", fontWeight: "500", width: "80px" }}>{item.rollNo}</td>

                                                                        <TermWiseHealthInput
                                                                            initialText1={(item.height == "") ? 0 : item.height}
                                                                            initialText2={(item.weight == "") ? 0 : item.weight}
                                                                            initialText3={(item.bmi == "") ? 0 : item.bmi}
                                                                            initialText4={item.medicalOpinion}
                                                                            onChange={(value1, value2, value3, value4) => {
                                                                                const updatedData = filteredData;
                                                                                updatedData[index] = ({
                                                                                    ...item,
                                                                                    height: value1,
                                                                                    weight: value2,
                                                                                    bmi: value3,
                                                                                    medicalOpinion: value4,
                                                                                })
                                                                                setData(updatedData);
                                                                                setFilteredData(updatedData);
                                                                                setRegNum(updatedData[index].regNo);
                                                                                setHeight(value1);
                                                                                setWeight(value2);
                                                                                setBmi(value3);
                                                                                setMedicalOpinion(value4);
                                                                            }}
                                                                        />
                                                                    </tr>

                                                                )
                                                            })}
                                                    </tbody>
                                                </table>

                                            </div>

                                            <div className='mt-4'>
                                                <button type='submit' className='termWiseHealthBtn btn btn-primary btn-sm mb-2'><AddIcon fontSize="small" />Save Changes</button>
                                            </div>
                                        </form>
                                    </div>
                            }



                        </div>
                    </div>
            }

        </>
    )
}

export default TermWiseHealth