import React, { useState, useEffect } from 'react';
import TitleLabel from "../../CommonComponent/TitleLabel";
import TextField from '@mui/material/TextField';
import Autocomplete from "@mui/material/Autocomplete";
import axios from "axios";
import Paper from "@mui/material/Paper";
import { useSelector } from "react-redux";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { ToastContainer, toast } from 'react-toastify';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import Loader from "../../CommonComponent/Loader";
import { useNavigate } from 'react-router-dom';
import { getExportedReportAPIURL } from '../../CommonServices/APIURL';
import { getSansthaFeeListAPIURL, saveSansthaOtherFeeDataAPIURL, getSansthFeeDetailAPIURL, updateSansthOtherFeeDataAPIURL } from '../Services/SansthaReceiptAPIURL'
import { saveDengiPavati, getDataforEditDetails, updateDataforDengiPavati } from '../Services/DengiPavatiAPIURL';


const SansthaReceipt = () => {
    // const titleId = "Dengi Pavati"
    const authUser = useSelector((state) => state.user.value);
    const navigate = useNavigate();
    const [titleId, setTitleId] = useState("");
    let { dNo, EditFlag } = useParams();

    const defaultMinDate = new Date(`${authUser.sessionYear}/04/01`);
    const defaultMaxDate = new Date(`${authUser.sessionYear + 1}/03/31`);

    // loader on/off
    const [loaderOption, setLoaderOption] = useState(false);

    //Note: This below states are declared for Save & Update(Disabled/unDisabled)
    const saveBtnStyle = "sansthaReceiptSaveBtn btn btn-primary btn-sm";
    const updateBtnStyle = "sansthaReceiptUpdateBtn btn btn-primary btn-sm";
    const displayNoneBtnStyle = "d-none";
    const [saveBtn, setSaveBtn] = useState(saveBtnStyle);
    const [updateBtn, setUpdateBtn] = useState(displayNoneBtnStyle);

    const [saveBtnDisabled, setSaveBtnDisabled] = useState(false);
    const [updateBtnDisabled, setUpdateBtnDisabled] = useState(false);

    const showAmountError = {
        display: "block"
    }
    const hideAmountError = {
        display: "none"
    }
    const [amountCheck, setAmountCheck] = useState(hideAmountError);
    const [dateCheck, setDateCheck] = useState(null);

    const [id, setId] = useState("");
    const [feeData, setFeeData] = useState([]);
    const [feeObj, setFeeObj] = useState(null);
    const [amount, setAmount] = useState("");
    const [cashReceipt, setCashReceipt] = useState(1);
    const [description, setDescription] = useState("");
    const [feeDescription, setFeeDescription] = useState("");
    const [receiptNo, setReceiptNo] = useState("");
    const [receiptDate, setReceiptDate] = useState(null);
    //console.log(receiptDate)
    const [receiptsName, setReceiptsName] = useState("");
    const [chequeNo, setChequeNo] = useState("");
    const [editDataObj, setEditDataObj] = useState([]);


    const HandleCashReceiptCheckbox = (e) => {
        setCashReceipt(e.target.checked);
        if (e.target.checked == true) {
            setCashReceipt(1);
            setDescription("");
            setChequeNo("");
        }
        else {
            setCashReceipt(0);
        }
    }

    useEffect(() => {
        if (EditFlag == 1) {
            setTitleId("Sanstha Receipt")
        }
        else if (EditFlag == 2) {
            setTitleId("Update Sanstha Receipt")
        }
        getData();
    }, [authUser]);


    const getAPIData = async () => {
        await axios(`${getSansthaFeeListAPIURL}`)
            .then(res => {
                setFeeData(res.data);
            })
    }

    //get Table data for edit
    const getEditDetails = async () => {
        setLoaderOption(true);
        //console.log(`${getDataforEditDetails}?tid=${dNo}&acyr=${authUser.sessionYear}`)
        axios.get(`${getSansthFeeDetailAPIURL}?tid=${dNo}&acyr=${authUser.sessionYear}`)
            .then((respOne) => {
                // console.log(JSON.stringify(respOne.data))
                setEditDataObj(respOne.data)
                setSaveBtn(displayNoneBtnStyle);
                setUpdateBtn(updateBtnStyle);
                setReceiptNo(respOne.data.transactionIdString);
                setReceiptDate(respOne.data.receiptDate);
                setReceiptsName(respOne.data.receiptsName);
                setAmount(respOne.data.receivedAmount);
                const SelectedFee = respOne.data.feeId;
                setDescription(respOne.data.description);
                setFeeDescription(respOne.data.feeDescription);
                setChequeNo(respOne.data.chequeData);
                setCashReceipt(respOne.data.isCash);

                axios.get(`${getSansthaFeeListAPIURL}`)
                    .then(res => {
                        setFeeData(res.data);
                        setFeeObj(res.data.find(e => e.id == SelectedFee));
                        setLoaderOption(false);
                    })
            })
    }

    const getData = async () => {
        if (EditFlag == 2) {
            getEditDetails();
            setSaveBtnDisabled(true);
            setUpdateBtnDisabled(false);
        }
        else if (EditFlag == 1) {
            getAPIData();
            setSaveBtnDisabled(false);
            setUpdateBtnDisabled(true);
        }
    }

    const saveSansthaReceiptCollection = () => {
        setLoaderOption(true);
        if (amount > 0 && amount != null && amount != "" && moment(receiptDate).format("DD/MM/YYYY") !== "Invalid date" && dateCheck !== "Invalid date") {
            const saveObject = {
                receiptDate: moment(receiptDate).format("DD/MM/YYYY"),
                receiptsName: receiptsName,
                feeId: feeObj.id,
                receivedAmount: amount,
                isCash: cashReceipt,
                description: description,
                feeDescription: feeDescription,
                chequeData: chequeNo,
                sessionYear: authUser.sessionYear,
                sectionId: authUser.deptId,
                branchId: authUser.branchId
            }
            //console.log(JSON.stringify(saveObject))
            axios.post(`${saveSansthaOtherFeeDataAPIURL}`, saveObject)
                .then((response) => {
                    if (response.data === "SAVED") {
                        getData();
                        setLoaderOption(false);
                        toast.success("Sanstha Receipt Saved successfully.");

                    } else {
                        toast.error("Operation failed.");
                    }
                })
                .catch(error => {
                    console.log("Save Fire Query: " + error)
                })
            setFeeObj(null);
            setReceiptsName("");
            setAmount("");
            setCashReceipt(1);
            setDescription("");
            setChequeNo("");
            setFeeDescription("");
            //toast.success("valid Date")
        }
        else {
            if (moment(receiptDate).format("DD/MM/YYYY") === "Invalid date" || dateCheck == "Invalid date") {
                //console.log("Date Is Invalid")      
                setLoaderOption(false);
                toast.error("Improper date, please check.")
            }
            else {
                setLoaderOption(false);
                toast.error("Amount should be grater than 0/Zero")
            }
        }
    }

    const updateSanstaReceiptCollection = () => {
        setLoaderOption(true);
        if (amount > 0 && amount != null && amount != "") {
            const updateObject = {
                id: parseInt(editDataObj.id),
                receiptId: editDataObj.receiptId,
                receiptDate: moment(receiptDate).format("DD/MM/YYYY"),
                receiptsName: receiptsName,
                feeId: feeObj.id,
                receivedAmount: amount,
                isCash: cashReceipt,
                description: description,
                feeDescription: feeDescription,
                transactionIdString: receiptNo,
                chequeData: chequeNo,
                sessionYear: authUser.sessionYear,
                sectionId: authUser.deptId,
                branchId: authUser.branchId
            }
            // console.log(JSON.stringify(updateObject))
            axios.put(`${updateSansthOtherFeeDataAPIURL}`, updateObject)
                .then((response) => {
                    if (response.data === "SAVED") {
                        //toast.success("Dengi Pavati Updated successfully.");
                        getData();
                        setLoaderOption(false);
                        setSaveBtn(saveBtnStyle);
                        setUpdateBtn(displayNoneBtnStyle);

                        navigate("/Home/f322/2")

                    } else {
                        toast.error("Operation failed.");
                    }
                })
                .catch(error => {
                    console.log("Update Fire Query: " + error)
                })
        }
        else {
            setLoaderOption(false);
            toast.error("Amount should be grater than 0/Zero");
        }

    }

    const ClearData = () => {
        navigate("/Home/f322/0")
        setSaveBtn(saveBtnStyle);
        setUpdateBtn(displayNoneBtnStyle);
    }

    const state = {
        button: 1
    };

    const onSubmit = e => {
        e.preventDefault();
        if (state.button === 1) {
            saveSansthaReceiptCollection();
        }
        if (state.button === 2) {
            updateSanstaReceiptCollection();
        }
    };

    useEffect(() => {

        function handlekeydownEvent(event) {
            if (event.key === "Enter") {
                // console.log("Enter is pressed!")
                event.preventDefault();
                if (EditFlag == 1) {
                    // console.log('save Enter is pressed!');
                    let btn1 = document.querySelector('.sansthaReceiptSaveBtn')
                    if (btn1 !== null) {
                        btn1.click();
                    }
                    setSaveBtnDisabled(false);
                    setUpdateBtnDisabled(true);
                }
                if (EditFlag == 2) {
                    // console.log('update Enter is pressed!');
                    let btn2 = document.querySelector('.sansthaReceiptUpdateBtn')
                    if (btn2 !== null) {
                        btn2.click();
                    }
                    setSaveBtnDisabled(true);
                    setUpdateBtnDisabled(false);
                }
            }
        }

        document.addEventListener('keypress', handlekeydownEvent)
        return () => {
            document.removeEventListener('keypress', handlekeydownEvent)
        }

    }, []);

    if (loaderOption == true) {
        return <Loader />
    }
    else {
        return (
            <>
                <TitleLabel titleId={titleId} />
                <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: "460px" }}>
                    <form onSubmit={onSubmit}>
                        <input type="hidden" value={id} onChange={(e) => setId(e.target.value)} />
                        <div className="row my-2">
                            <label htmlFor="lastname3" className="col-sm-2" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>RC No :</label>
                            <div className='col-sm-3'>
                                <TextField
                                    fullWidth
                                    type="text"
                                    autoComplete='off'
                                    size='small'
                                    id="outlined-basic"
                                    label="Receipt No"
                                    variant="outlined"
                                    margin='dense'
                                    value={receiptNo}
                                    onChange={(e) => setReceiptNo(e.target.value)}
                                    onKeyPress={(e) => (e.key == "Enter") ? receiptNo : ""}
                                    inputProps={{ readOnly: true }}
                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                />
                            </div>

                            <label htmlFor="lastname3" className="col-sm-1" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>Date :</label>
                            <div className='col-sm-3'>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        label="Receipt Date"
                                        value={receiptDate}
                                        minDate={defaultMinDate}
                                        maxDate={defaultMaxDate}
                                        onChange={(newValue) => {
                                            setReceiptDate(newValue);
                                            //(newValue > defaultMaxDate || newValue < defaultMinDate) ? setDateCheck("Invalid date") : setDateCheck(null)
                                            const maxDate = new Date(defaultMaxDate);
                                            maxDate.setDate(maxDate.getDate() + 1); // Add one day to max date to include the full day
                                            if (newValue < defaultMinDate || newValue >= maxDate) {
                                                setDateCheck("Invalid date");
                                            } else {
                                                setDateCheck("");
                                            }
                                        }}
                                        openTo="day"
                                        // Show defaultMaxDate selected when the DatePicker is opened
                                        // If dateFrom is not set, defaultMaxDate is used
                                        onOpen={() => setReceiptDate(receiptDate || defaultMaxDate)}
                                        onKeyPress={(e) => (e.key == "Enter") ? receiptDate : ""}
                                        readOnly={(EditFlag == 2) ? true : false}
                                        inputFormat="dd/MM/yyyy"
                                        inputProps={{ readOnly: (EditFlag == 2) ? true : false }}
                                        renderInput={(params) =>
                                            <TextField
                                                {...params}
                                                margin='dense'
                                                variant="outlined"
                                                InputLabelProps={{ style: { fontSize: 14 } }}
                                                required
                                                fullWidth
                                                autoComplete='off'
                                                size="small" />}
                                    />
                                </LocalizationProvider>
                                {/* <span><em style={{ fontSize: "12px", color: "red", fontWeight: "600" }}>{dateCheck}</em></span> */}
                            </div>
                        </div>

                        <div className="row my-2">
                            <label htmlFor="lastname3" className="col-sm-2" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>Name :</label>
                            <div className='col-sm-7'>
                                <TextField
                                    required
                                    fullWidth
                                    autoComplete='off'
                                    size='small'
                                    id="outlined-basic"
                                    label="Donator Name"
                                    variant="outlined"
                                    margin='dense'
                                    value={receiptsName}
                                    onChange={(e) => setReceiptsName(e.target.value)}
                                    onKeyPress={(e) => (e.key == "Enter") ? receiptsName : ""}
                                    inputProps={{ maxLength: 120 }}
                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                />
                            </div>
                        </div>


                        <div className="row mt-2">
                            <label htmlFor="lastname3" className="col-sm-2" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>Fee:</label>
                            <div className='col-sm-3'>
                                <Autocomplete
                                    id="combo-box-demo"
                                    options={feeData}
                                    PaperComponent={({ children }) => (
                                        <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                    )}
                                    onChange={(event, newValue) => {
                                        setFeeObj(newValue);
                                    }}
                                    value={feeObj}
                                    onKeyPress={(e) => (e.key == "Enter") ? feeObj : ""}
                                    style={{ fontSize: '14px' }}
                                    getOptionLabel={option => (authUser.branchMedium == 1) ? option.nameMr : option.nameEn}
                                    size="small"
                                    renderInput={params => (
                                        <TextField {...params} margin='dense' label="Select Fee" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                    )}
                                />
                            </div>

                            <label htmlFor="lastname3" className="col-sm-1" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>Amount:</label>
                            <div className='col-sm-3'>
                                <TextField
                                    required
                                    fullWidth
                                    type="number"
                                    onWheel={(e) => e.target.blur()}
                                    autoComplete='off'
                                    size='small'
                                    id="outlined-basic"
                                    label="Amount"
                                    variant="outlined"
                                    margin='dense'
                                    value={amount}
                                    onChange={(e) => {
                                        setAmount(e.target.value);
                                        (parseFloat(e.target.value) > 0) ? setAmountCheck(hideAmountError) : setAmountCheck(showAmountError)
                                    }}
                                    onKeyPress={(e) => (e.key == "Enter") ? amount : ""}
                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                />
                                <span style={amountCheck}><em style={{ fontSize: "12px", color: "red", fontWeight: "600" }}>* Amount should be greater than 0/Zero</em></span>
                            </div>
                        </div>

                        <div className="row mt-2">
                            <div className='col-sm-7 offset-sm-2'>
                                <TextField
                                    fullWidth
                                    autoComplete='off'
                                    size='small'
                                    id="outlined-basic"
                                    label="Fee Description"
                                    variant="outlined"
                                    margin='dense'
                                    value={feeDescription}
                                    onChange={(e) => setFeeDescription(e.target.value)}
                                    onKeyPress={(e) => (e.key == "Enter") ? feeDescription : ""}
                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                />
                            </div>
                        </div>

                        <div className='row mt-2'>
                            <div className='col-sm-2 mt-1'>
                                <FormControlLabel style={{ fontSize: "15px", fontWeight: "600" }} control={<Checkbox checked={cashReceipt} onChange={HandleCashReceiptCheckbox} />} label="Cash Receipt" />
                            </div>

                            <div className='col-sm-7'>
                                <TextField
                                    fullWidth
                                    autoComplete='off'
                                    size='small'
                                    id="outlined-basic"
                                    label="Description"
                                    variant="outlined"
                                    margin='dense'
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)}
                                    onKeyPress={(e) => (e.key == "Enter") ? description : ""}
                                    style={(cashReceipt == 1) ? { backgroundColor: "#dee2e6" } : { backgroundColor: "white" }}
                                    inputProps={{ maxLength: 120, readOnly: (cashReceipt == 1) ? true : false }}
                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                    required={(cashReceipt == 0) ? true : false}
                                />
                            </div>
                        </div>

                        <div className="row my-2">
                            <label htmlFor="lastname3" className="col-sm-2" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>Cheque No :</label>
                            <div className='col-sm-3'>
                                <TextField
                                    fullWidth
                                    type="number"
                                    autoComplete='off'
                                    size='small'
                                    id="outlined-basic"
                                    label="Cheque No"
                                    variant="outlined"
                                    margin='dense'
                                    value={chequeNo}
                                    onChange={e => setChequeNo(e.target.value)}
                                    onKeyPress={(e) => (e.key == "Enter") ? chequeNo : ""}
                                    style={(cashReceipt == 1) ? { backgroundColor: "#dee2e6" } : { backgroundColor: "white" }}
                                    inputProps={{ maxLength: 120, readOnly: (cashReceipt == 1) ? true : false }}
                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                    required={(cashReceipt == 0) ? true : false}
                                />
                            </div>
                        </div>

                        <div className="col-sm-12 mt-3">
                            <div className="offset-sm-2">
                                <button type="submit" onClick={() => (state.button = 1)} disabled={saveBtnDisabled} className={saveBtn}>Save</button>
                                <button type="submit" onClick={() => (state.button = 2)} disabled={updateBtnDisabled} className={updateBtn} >Update</button>
                                <button type="button" onClick={() => ClearData()} className="btn btn-danger btn-sm" style={{ margin: '0px 4px' }} >Cancel</button>
                            </div>
                        </div>
                    </form>
                </div>
                <ToastContainer position="top-right" theme="colored" />
            </>
        )
    }
}

export default SansthaReceipt