import React, { useState, useEffect } from 'react';
import TitleLabel from "../../CommonComponent/TitleLabel";
import TextField from '@mui/material/TextField';
import axios from "axios";
import { useSelector } from "react-redux";
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import { toast } from 'react-toastify';
import Loader from '../../CommonComponent/Loader';
import Autocomplete from "@mui/material/Autocomplete";
import Paper from "@mui/material/Paper";
import makeStyles from "@mui/styles/makeStyles";
import moment from 'moment';
import { getSansthaBudgetEntryDetail, } from '../Transaction/Services/BudgetEntryAPIURL';
import SansthaDetailEntryReportTable from './SansthaBudgetReportTables/SansthaDetailEntryReportTable';
import SansthaConsolidatedBudgetReportTable from './SansthaBudgetReportTables/SansthaConsolidatedBudgetReportTable';
import {
    getCategoryBySectionAndBranch, getSansthaConsolidatedReportDataAPIURL,
    printSansthaConolidatedReportAPIURL, printSansthaCategoryWiseBudgetReportAPIURL
} from './Services/BudgetReportAPIURL';
import { getExportedReportAPIURL } from '../../CommonServices/APIURL';


const useStyles = makeStyles(theme => ({
    menuPaper: {
        maxHeight: 200,
        "& ul": {
            backgroundColor: "#F4ECF7",
            //maxHeight: 160,
        },
        "& li": {
            // backgroundColor: "#F4ECF7", fontWeight: "550", fontSize: "14px" 
            fontSize: "14px",
            fontWeight: "550"
        },
    }
}));

const SansthaBudgetReport = () => {
    const authUser = useSelector((state) => state.user.value);
    const titleId = "Budget Report";
    const classes = useStyles();
    const [loaderOption, setLoaderOption] = useState(false);

    const [previousYrTotalBudget, setPreviousYrTotalBudget] = useState(0);
    const [nextYrTotalBudget, setNextYrTotalBudget] = useState(0);
    const [actualDecTotalBudget, setActualDecTotalBudget] = useState(0);

    const [futureYear, setFutureYear] = useState('');
    const [categoryData, setCategoryData] = useState([]);
    const [categoryObj, setCategoryObj] = useState(null);
    const [optionId, setOptionId] = useState(1);

    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);

    const [accountName, setAccountName] = useState('');

    const [receiptData, setReceiptData] = useState([]);
    const [paymentData, setPaymentData] = useState([]);
    const [differenceAmtLastYr, setDifferenceAmtLastYr] = useState(0);
    const [differenceAmtNextYr, setDifferenceAmtNextYr] = useState(0);

    const [receiptTotalLastYr, setReceiptTotalLastYr] = useState(0);
    const [paymentTotalLastYr, setPaymentTotalLastYr] = useState(0);
    const [receiptTotalNextYr, setReceiptTotalNextYr] = useState(0);
    const [paymentTotalNextYr, setPaymentTotalNextYr] = useState(0);

    const currentYear = new Date();

    //get year from current session year
    useEffect(() => {
        if (authUser.sessionYear < moment(currentYear).format("YYYY")) {
            let newYear = moment(authUser.fromDate).format("YY")
            //console.log(newYear)
            setFutureYear(parseInt(newYear) + 1);
        }
        else {
            setFutureYear(moment(currentYear).add(1, 'year').format("YY"))
        }
    }, [authUser])


    useEffect(() => {
        getData();
    }, [authUser])


    //get category by section and branch
    const getData = async () => {
        //console.log(`${allBudgetCategoriesAPIURL}`)
        // await axios.get(`${allBudgetCategoriesAPIURL}`)
        //     .then(res => {
        //         let newData = res.data.filter(e => e.categoryType != 2)
        //         let newFilterData = newData.filter(e => e.deptId == 12)
        //         setCategoryData(newFilterData);
        //     })
        if (authUser.branchId != null && authUser.deptId != null) {
            // console.log(`${getCategoryBySectionAndBranch}?sid=${authUser.deptId}&bid=${authUser.branchId}`)
            await axios.get(`${getCategoryBySectionAndBranch}?sid=${authUser.deptId}&bid=${authUser.branchId}`)
                .then(res => {

                    let newData = res.data.filter(e => e.categoryType != 2)
                    setCategoryData(res.data);
                })
        }

    }

    //get budget details
    const getCategoryWiseDetails = async (e) => {
        e.preventDefault();
        setLoaderOption(true);
        setData([]);
        setFilteredData([]);
        if (optionId == 2) {
            setAccountName('');
            setNextYrTotalBudget('');
            setPreviousYrTotalBudget('');
            setActualDecTotalBudget(0);
            //console.log(`${getSansthaBudgetEntryDetail}?categoryId=${categoryObj.id}&sessionYear=${authUser.sessionYear}&sid=${categoryObj.deptId}&bid=${authUser.branchId}`)
            await axios.get(`${getSansthaBudgetEntryDetail}?categoryId=${categoryObj.id}&sessionYear=${authUser.sessionYear}&sid=${categoryObj.deptId}&bid=${authUser.branchId}`)
                .then(res => {
                    if (res.data.length == 0) {
                        setLoaderOption(false);
                        toast.warn("Accounts are not found for this category.")
                    }
                    else {
                        setData(res.data);
                        setFilteredData(res.data);
                        //console.log(res.data)
                        res.data.map(e => {
                            setNextYrTotalBudget((e.nextYearTotal).toFixed('2'));
                            setPreviousYrTotalBudget((e.lastYearTotal).toFixed('2'));

                            const TotalActualDecBudget = res.data.reduce((prev, curr) => prev + parseFloat(curr.actualBudget), 0);
                            setActualDecTotalBudget(parseFloat(TotalActualDecBudget).toFixed('2'))

                            categoryData.map(item => {
                                if (item.id == e.categoryId) {
                                    setAccountName(item.nameMr)
                                }
                            })
                        })
                        setLoaderOption(false);
                    }
                }).catch(err => {
                    console.log("get detail entry err:" + err);
                    setLoaderOption(false);
                    toast.error("Something went wrong, please check.")
                })
        }
        else if (optionId == 1) {
            await axios.get(`${getSansthaConsolidatedReportDataAPIURL}?sessionYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}`)
                .then(res => {
                    if (res.data == "NOTFOUND") {
                        setLoaderOption(false);
                        toast.warn("Data not found.");
                    }
                    else {
                        let filterReceiptData = res.data.filter(e => e.optionId == 1);
                        setReceiptData(filterReceiptData);

                        let filterPaymentData = res.data.filter(e => e.optionId == 2);
                        setPaymentData(filterPaymentData);
                        //console.log(res.data)

                        const TotalReceiptBudgetLastYr = filterReceiptData.reduce((prev, curr) => prev + parseFloat(curr.budgetLastYear), 0);
                        setReceiptTotalLastYr(parseFloat(TotalReceiptBudgetLastYr).toFixed('2'))
                        const TotalReceiptBudgetNextYr = filterReceiptData.reduce((prev, curr) => prev + parseFloat(curr.budgetNextYear), 0);
                        setReceiptTotalNextYr(parseFloat(TotalReceiptBudgetNextYr).toFixed('2'))

                        const TotalPaymentBudgetLastYr = filterPaymentData.reduce((prev, curr) => prev + parseFloat(curr.budgetLastYear), 0);
                        setPaymentTotalLastYr(parseFloat(TotalPaymentBudgetLastYr).toFixed('2'))
                        const TotalPaymentBudgetNextYr = filterPaymentData.reduce((prev, curr) => prev + parseFloat(curr.budgetNextYear), 0);
                        setPaymentTotalNextYr(parseFloat(TotalPaymentBudgetNextYr).toFixed('2'))

                        setDifferenceAmtLastYr((parseFloat(TotalReceiptBudgetLastYr) - parseFloat(TotalPaymentBudgetLastYr)).toFixed('2'));
                        setDifferenceAmtNextYr((parseFloat(TotalReceiptBudgetNextYr) - parseFloat(TotalPaymentBudgetNextYr)).toFixed('2'));

                        setLoaderOption(false);
                    }

                }).catch(err => {
                    console.log("get detail entry err:" + err);
                    setLoaderOption(false);
                    toast.error("Something went wrong, please check.")
                })
        }
    }

    //print budget details
    const printSansthReport = async () => {
        setLoaderOption(true);
        if (optionId == 1) {
            await axios.get(`${printSansthaConolidatedReportAPIURL}?langId=${authUser.branchMedium}&sessionYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}`)
                .then(res => {
                    if (res.data == "NOTFOUND") {
                        setLoaderOption(false);
                        toast.warn("Data not found.")
                    }
                    else {
                        setLoaderOption(false);
                        window.open(`${getExportedReportAPIURL}${res.data}`);
                    }
                }).catch(err => {
                    console.log("sanstha consolidated report" + err);
                    setLoaderOption(false);
                    toast.error("Something went wrong, please check.")
                })
        }
        else if (optionId == 2) {
            await axios.get(`${printSansthaCategoryWiseBudgetReportAPIURL}?langId=${authUser.branchMedium}&sessionYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&categoryId=${categoryObj.id}`)
                .then(res => {
                    if (res.data == "NOTFOUND") {
                        setLoaderOption(false);
                        toast.warn("Data not found.")
                    }
                    else {
                        setLoaderOption(false);
                        window.open(`${getExportedReportAPIURL}${res.data}`);
                    }
                }).catch(err => {
                    console.log("sanstha consolidated report" + err);
                    setLoaderOption(false);
                    toast.error("Something went wrong, please check.")
                })
        }
    }


    return (
        <>
            {
                (loaderOption == true)
                    ?
                    <Loader />
                    :
                    <>
                        <TitleLabel titleId={titleId} />
                        <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: "300px" }}>
                            {/* Form Data start */}
                            <div className=''>
                                <form onSubmit={getCategoryWiseDetails}>
                                    <div className="form-data">
                                        <div className="row my-2">
                                            <label className="" style={{ width: "120px", fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Option :</label>
                                            <div className='col-sm-3'>
                                                <FormControl fullWidth margin='dense'>
                                                    <InputLabel id='demo-simple-select-label' size="small" style={{ fontSize: '14px' }}>Select Option</InputLabel>
                                                    <Select
                                                        size='small'
                                                        required
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        value={optionId}
                                                        label="Select Option"
                                                        MenuProps={{ classes: { paper: classes.menuPaper } }}
                                                        onChange={(e) => {
                                                            setOptionId(e.target.value);
                                                            setData([]);
                                                            setFilteredData([]);
                                                            setCategoryObj(null);
                                                            setReceiptData([]);
                                                            setPaymentData([]);
                                                        }}
                                                        onKeyPress={(e) => (e.key == "Enter") ? optionId : ""}
                                                    >

                                                        <MenuItem value={1}>Consolidated</MenuItem>
                                                        <MenuItem value={2}>Category Wise</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </div>
                                        </div>

                                        <div className="row my-2" hidden={(optionId == 1) ? true : false}>
                                            <label className="" style={{ width: "120px", fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Category :</label>
                                            <div className='col-sm-4'>
                                                <Autocomplete
                                                    id="combo-box-demo"
                                                    options={categoryData}
                                                    PaperComponent={({ children }) => (
                                                        <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                                    )}
                                                    onChange={(event, newValue) => {
                                                        setCategoryObj(newValue);
                                                        setData([]);
                                                        setFilteredData([]);
                                                        setReceiptData([]);
                                                        setPaymentData([]);
                                                    }}
                                                    value={categoryObj}
                                                    style={{ fontSize: '14px' }}
                                                    getOptionLabel={option => (authUser.branchMedium == 1) ? option.nameMr : option.nameEn}
                                                    // renderOption={(props, option) => (
                                                    //     <div className='' {...props}>
                                                    //         <div className=''>
                                                    //             {(authUser.branchMedium == 1) ? option.nameMr : option.nameEn} <br />
                                                    //             <div
                                                    //                 className=''
                                                    //                 style={{
                                                    //                     display: "flex",
                                                    //                     flex: 1,
                                                    //                     fontSize: "11px",
                                                    //                     flexDirection: "row",
                                                    //                     //justifyContent: "space-between",
                                                    //                     padding: "0px 5px",
                                                    //                     color: "#464964",
                                                    //                     fontWeight: "550"
                                                    //                     //gridRow: 1
                                                    //                 }}
                                                    //             >
                                                    //                 <span><AccountBalanceIcon style={{ color: "#354AFA", fontSize: "14px" }} /> {(authUser.branchMedium == 1) ? option.deptNameMr : option.deptNameEn}</span>
                                                    //                 <span className='ml-1 mr-1 mb-1' style={{ fontSize: "14px" }}>|</span>
                                                    //                 <span>{(option.side == "Receipt") ? <ReceiptLongIcon style={{ color: "#354AFA", fontSize: "14px" }} /> : <PaymentsIcon style={{ color: "#354AFA", fontSize: "14px" }} />} {option.side}</span>
                                                    //             </div>
                                                    //         </div>

                                                    //     </div>
                                                    // )}
                                                    size="small"
                                                    renderInput={params => (
                                                        <TextField {...params} margin='dense' label="Select Category" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required={(optionId == 2) ? true : false} />
                                                    )}
                                                />
                                            </div>
                                        </div>

                                        <div className="row mt-3">
                                            <div className="col-sm-4" style={{ marginLeft: "120px" }}>
                                                <button type='submit' className='btn btn-sm btn-primary'>Show</button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>

                            <div className=''>
                                {(() => {
                                    if (optionId == 1) {
                                        if (receiptData.length != 0 || paymentData.length != 0) {
                                            return (
                                                <>
                                                    <SansthaConsolidatedBudgetReportTable
                                                        futureYear={futureYear}
                                                        receiptData={receiptData}
                                                        paymentData={paymentData}
                                                        setReceiptData={setReceiptData}
                                                        setPaymentData={setPaymentData}
                                                        differenceAmtLastYr={differenceAmtLastYr}
                                                        differenceAmtNextYr={differenceAmtNextYr}
                                                        receiptTotalLastYr={receiptTotalLastYr}
                                                        paymentTotalLastYr={paymentTotalLastYr}
                                                        receiptTotalNextYr={receiptTotalNextYr}
                                                        paymentTotalNextYr={paymentTotalNextYr}
                                                        printSansthReport={printSansthReport}
                                                    />
                                                </>
                                            )
                                        }
                                    }
                                    else if (optionId == 2) {
                                        if (data.length != 0 || filteredData.length != 0) {
                                            return (
                                                <>
                                                    <SansthaDetailEntryReportTable
                                                        futureYear={futureYear}
                                                        data={data}
                                                        setData={setData}
                                                        filteredData={filteredData}
                                                        setFilteredData={setFilteredData}
                                                        nextYrTotalBudget={nextYrTotalBudget}
                                                        previousYrTotalBudget={previousYrTotalBudget}
                                                        actualDecTotalBudget={actualDecTotalBudget}
                                                        accountName={accountName}
                                                        printSansthReport={printSansthReport}
                                                    />
                                                </>
                                            )
                                        }
                                    }
                                })()}

                            </div>
                        </div>
                    </>
            }
            {/* <ToastContainer position="top-right" theme="colored" /> */}
        </>
    )
}

export default SansthaBudgetReport