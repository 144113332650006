import moment from 'moment';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf, faFileExcel } from '@fortawesome/free-solid-svg-icons'; // Import FontAwesome icons
import { getExportedReportAPIURL } from '../../CommonServices/APIURL';
import { useSelector } from 'react-redux';
import { printInwardEntryExcelReportAPIURL, printInwardEntryPdfReportAPIURL } from '../Services/InwardEntryAPIURL';
import { toast } from 'react-toastify';
import axios from 'axios';
import Loader from '../../CommonComponent/Loader';

const InwardReportTableView = ({ data, dateFrom, dateTo, dateCheckFrom, dateCheckTo, setLoaderOption, loaderOption }) => {
    const authUser = useSelector((state) => state.user.value);


    const printInwardPdfReport = async () => {

        setLoaderOption(true);
        if (moment(dateFrom).format("DD/MM/YYYY") != 'Invalid date' && moment(dateTo).format("DD/MM/YYYY") != 'Invalid date' && dateCheckFrom != 'Invalid date' && dateCheckTo != 'Invalid date') {

            // console.log(`${printInwardEntryPdfReportAPIURL}?langId=${authUser.branchMedium}&year=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&fromDate=${moment(dateFrom).format("YYYY-MM-DD")}&toDate=${moment(dateTo).format("YYYY-MM-DD")}`)
            await axios.get(`${printInwardEntryPdfReportAPIURL}?langId=${authUser.branchMedium}&year=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&fromDate=${moment(dateFrom).format("YYYY-MM-DD")}&toDate=${moment(dateTo).format("YYYY-MM-DD")}`)
                .then(res => {
                    if (res.data == "NOTFOUND") {
                        setLoaderOption(false);
                        toast.warn("Data not found.")
                    }
                    else {
                        window.open(`${getExportedReportAPIURL}${res.data}`);
                        setLoaderOption(false);
                    }
                }).catch(err => {
                    setLoaderOption(false);
                    console.log("Balance sheet schedule report err " + err);
                    toast.error("Something went wrong, please chcek.")
                })

        }
        else {
            setLoaderOption(false);
            toast.error("Improper date, please check.")
        }
    }

    const printInwardExcelReport = async () => {

        setLoaderOption(true);
        if (moment(dateFrom).format("DD/MM/YYYY") != 'Invalid date' && moment(dateTo).format("DD/MM/YYYY") != 'Invalid date' && dateCheckFrom != 'Invalid date' && dateCheckTo != 'Invalid date') {

            // console.log(`${printInwardEntryExcelReportAPIURL}?langId=${authUser.branchMedium}&year=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&fromDate=${moment(dateFrom).format("YYYY-MM-DD")}&toDate=${moment(dateTo).format("YYYY-MM-DD")}`)
            await axios.get(`${printInwardEntryExcelReportAPIURL}?langId=${authUser.branchMedium}&year=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&fromDate=${moment(dateFrom).format("YYYY-MM-DD")}&toDate=${moment(dateTo).format("YYYY-MM-DD")}`)
                .then(res => {
                    if (res.data == "NOTFOUND") {
                        setLoaderOption(false);
                        toast.warn("Data not found.")
                    }
                    else {
                        window.open(`${getExportedReportAPIURL}${res.data}`);
                        setLoaderOption(false);
                    }
                }).catch(err => {
                    setLoaderOption(false);
                    console.log("Balance sheet schedule report err " + err);
                    toast.error("Something went wrong, please chcek.")
                })

        }
        else {
            setLoaderOption(false);
            toast.error("Improper date, please check.")
        }
    }

    if (loaderOption == true) {
        return (
            <Loader />
        )
    }
    else {
        return (
            <>
                <div className='table-responsive'>
                    {/* Icons Section (Right-aligned) */}
                    <div style={{ display: "flex", justifyContent: "flex-end", marginBottom: "10px" }}>
                        <FontAwesomeIcon
                            icon={faFilePdf}
                            style={{ fontSize: "28px", color: "red", cursor: "pointer", marginRight: "15px" }}
                            onClick={() => printInwardPdfReport()}
                        />
                        <FontAwesomeIcon
                            icon={faFileExcel}
                            style={{ fontSize: "28px", color: "green", cursor: "pointer" }}
                            onClick={() => printInwardExcelReport()}
                        />
                    </div>

                    <table className="table table-bordered">
                        <thead className="table-Default">
                            <tr>
                                <th rowSpan={2} style={{ fontSize: "14px", fontWeight: "600", verticalAlign: "middle", lineHeight: '1.5' }}>Sr.No.</th>
                                <th rowSpan={2} style={{ fontSize: "14px", fontWeight: "600", textAlign: "center", verticalAlign: "middle", lineHeight: '1.5' }}>Date of Entry</th>
                                <th rowSpan={2} style={{ fontSize: "14px", fontWeight: "600", textAlign: "center", verticalAlign: "middle", lineHeight: '1.5' }}>Inward No</th>
                                <th rowSpan={2} style={{ fontSize: "14px", fontWeight: "600", textAlign: "center", verticalAlign: "middle", lineHeight: '1.5' }}>Letter Date</th>
                                <th rowSpan={2} style={{ fontSize: "14px", fontWeight: "600", textAlign: "center", verticalAlign: "middle", lineHeight: '1.5' }}>Received From</th>

                                {/* Merged Header for "Reply To Our Which Letter" */}
                                <th colSpan={2} style={{ fontSize: "14px", fontWeight: "600", textAlign: "center", lineHeight: '1.5' }}>Reply To Our Which Letter</th>
                                <th rowSpan={2} style={{ fontSize: "14px", fontWeight: "600", textAlign: "center", verticalAlign: "middle", lineHeight: '1.5' }}>Content Of Inward Letter</th>
                                <th rowSpan={2} style={{ fontSize: "14px", fontWeight: "600", textAlign: "center", verticalAlign: "middle", lineHeight: '1.5' }}>Letter Received On</th>

                                {/* Merged Header for "Details Of Reply" */}
                                <th colSpan={2} style={{ fontSize: "14px", fontWeight: "600", textAlign: "center" }}>Details Of Reply</th>

                                <th rowSpan={2} style={{ fontSize: "14px", fontWeight: "600", textAlign: "center", verticalAlign: "middle", lineHeight: '1.5' }}>Remark</th>
                            </tr>

                            {/* Sub-columns under merged headers */}
                            <tr>
                                <th style={{ fontSize: "14px", fontWeight: "600", textAlign: "center" }}>No.</th>
                                <th style={{ fontSize: "14px", fontWeight: "600", textAlign: "center" }}>Date</th>

                                <th style={{ fontSize: "14px", fontWeight: "600", textAlign: "center" }}>No.</th>
                                <th style={{ fontSize: "14px", fontWeight: "600", textAlign: "center" }}>Date</th>
                            </tr>
                        </thead>
                        <tbody style={{ borderTop: "2px solid #464de4", borderBottom: "2px solid #464de4" }}>
                            {data.map((item, index) => (
                                <tr key={index}>
                                    <td style={{ fontSize: "14px", fontWeight: "500", lineHeight: "1.4" }}>{index + 1}</td>
                                    <td style={{ fontSize: "14px", fontWeight: "500", lineHeight: '1.5' }}>{moment(item.entryDate).format("DD/MM/YYYY")}</td>
                                    <td style={{ fontSize: "14px", fontWeight: "500", lineHeight: '1.5' }}>{item.inwardNo}</td>
                                    <td style={{ fontSize: "14px", fontWeight: "500", lineHeight: '1.5' }}>{moment(item.letterDate).format("DD/MM/YYYY")}</td>
                                    <td style={{ fontSize: "14px", fontWeight: "500", lineHeight: '1.5' }}>{item.branchName}</td>
                                    <td style={{ fontSize: "14px", fontWeight: "500", lineHeight: '1.5' }}>{item.replyToOutwardNo}</td>
                                    <td style={{ fontSize: "14px", fontWeight: "500", lineHeight: '1.5' }}>{(item.replyToOutwardDate == null) ? '-' : moment(item.replyToOutwardDate).format("DD/MM/YYYY")}</td>
                                    <td style={{ fontSize: "14px", fontWeight: "500", lineHeight: '1.5' }}>{item.contentOfInward}</td>
                                    <td style={{ fontSize: "14px", fontWeight: "500", lineHeight: '1.5' }}>{item.letterReceivedDate}</td>
                                    <td style={{ fontSize: "14px", fontWeight: "500", lineHeight: '1.5' }}>{item.replyOutwardNo}</td>
                                    <td style={{ fontSize: "14px", fontWeight: "500", lineHeight: '1.5' }}>{(item.replyOutwardDate == null) ? '-' : moment(item.replyOutwardDate).format("DD/MM/YYYY")}</td>
                                    <td style={{ fontSize: "14px", fontWeight: "500", lineHeight: '1.5' }}>{item.remark}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </>
        );
    }
};

export default InwardReportTableView;
