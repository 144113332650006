import React, { useState, useEffect } from 'react'
import axios from 'axios';
import TablePagination from '@mui/material/TablePagination'
import makeStyles from "@mui/styles/makeStyles";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'
import { toast, ToastContainer } from 'react-toastify';
import Tooltip from '@mui/material/Tooltip';
import { deleteGroupSettingAPIURL } from '../../Services/ExamSettingsAPIURL';
import DeleteEmpLeaveEntryModal from '../../../Payrole/Transaction/DeleteEmpLeaveEntryModal';
import DeleteModalBox from '../../../CommonComponent/DeleteModalBox';
import GroupSettingModal from '../ExamResultSettingModalBoxes/GroupSettingModal';


const useStyles = makeStyles({
    caption: {
        padding: 0,
        fontWeight: 600,
        fontSize: "12px"
    },
    toolbar: {
        fontSize: "12px",
        fontWeight: 600
    },
    selectLabel: {
        fontSize: "12px",
        fontWeight: 500,
        margin: "-10px",
        padding: "0"
    },
    select: {
        fontSize: "12px",
        margin: "0",
        padding: "0"
    },
    displayedRows: {
        fontSize: "12px",
        margin: "-22px",
        padding: "0"
    },
    menuItem: {
        fontSize: "12px",
        fontWeight: 500,
    }
});

const GroupSettingTable = ({ grouTableHeading, setGroupId, setGroupNameMr, setGroupNameEn, setPassMinMark, setSubTableData, getData, groupTableData, setGroupTableData, groupTableFilterData, setGroupTableFilterData, saveGroupBtnStyle, updateGroupBtnStyle, displayGroupNoneBtnStyle, setSaveGroupBtn, setUpdateGroupBtn, divisionOptObj, retrieveData, setOpenAccordion }) => {
    const classes = useStyles();
    const [isAscending, setAscending] = useState(false);
    const [sortedBy, setSortedBy] = useState(null);
    const [searchTerm, setSearchTerm] = useState("")
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10)

    const onPageChange = (event, nextPage) => {
        setPage(nextPage);
    }
    const onRowsPerPageChange = (e) => {
        setRowsPerPage(parseInt(e.target.value));
        setPage(0);
    }

    const UpdateData = (item) => {
        setGroupId(item.id);
        setGroupNameMr(item.groupNameMr);
        setGroupNameEn(item.groupNameEn);
        setPassMinMark(item.minPassingMark);
        setSubTableData(item.groupSubTableData);
        setSaveGroupBtn(displayGroupNoneBtnStyle);
        setUpdateGroupBtn(updateGroupBtnStyle);

    }

    const [showModal, setShowModal] = useState(false);
    const [deleteId, setDeleteId] = useState(null);

    const handleShowModal = (id) => {
        setShowModal(true);
        setDeleteId(id);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const DeleteData = (item) => {
        // console.log(`${deleteGroupSettingAPIURL}?id=${deleteId.id}&classId=${deleteId.classId}&divId=${deleteId.divisionId}&year=${deleteId.sessionYear}&sid=${deleteId.sectionId}&branchMedium=${deleteId.branchMedium}&allDivision=${divisionOptObj}`)
        axios.delete(`${deleteGroupSettingAPIURL}?id=${deleteId.id}&classId=${deleteId.classId}&divId=${deleteId.divisionId}&year=${deleteId.sessionYear}&sid=${deleteId.sectionId}&branchMedium=${deleteId.branchMedium}&allDivision=${divisionOptObj}`)
            .then((response) => {
                if (response.data == "DELETED") {
                    //setLoaderOption(false);
                    getData();
                    retrieveData();
                    setOpenAccordion(3);
                    setShowModal(false);
                    // console.log("Deleted");
                    toast.success("Deleted successfully.");
                }
                else {
                    //setLoaderOption(false);
                    toast.error("Leave entry delete failed.")
                }
            }).catch(err => {
                //setLoaderOption(false);
                console.log("Leave entry delete" + err);
                toast.error("Something went wrong, please check.")
            })

    }

    const onSort = (key, isAction) => {
        if (!isAction) {
            setSortedBy(key)
            if (isAscending) setGroupTableFilterData([...groupTableData.sort((a, b) => a[key] < b[key] ? 1 : -1)])
            else setGroupTableFilterData([...groupTableData.sort((a, b) => a[key] > b[key] ? 1 : -1)])
            setAscending(!isAscending)
        }
    }

    useEffect(() => {
        setGroupTableFilterData(
            groupTableData.filter(
                (item) =>
                    item.groupNameMr.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    item.groupNameEn.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    item.minPassingMark.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
                    item.id.toString().toLowerCase().includes(searchTerm.toLowerCase())
            )
        );
    }, [searchTerm]);

    return (
        <>
            <div className='row my-2 mt-1'>
                <div >
                    <input
                        className='form-control form-control-sm'
                        type="text"
                        style={{ width: "200px", display: "block", float: "right", marginBottom: "6px", border: "1px solid #C2C1C1" }}
                        placeholder="Search Here"
                        value={searchTerm}
                        onChange={(event) => { setSearchTerm(event.target.value); }}
                    />
                </div>
            </div>

            <div className='table-responsive'
                style={{
                    backgroundColor: " #fefefe",
                    backgroundImage: "linear-gradient(315deg, #fefefe 0%, #00a4e4 74%)"
                }}
            >
                <table className="table table-borderless" >
                    <thead className="table-Default" style={{ borderBottom: "2px solid #464de4" }}>
                        <tr>
                            {grouTableHeading.map((item, index) => {
                                return (
                                    <th style={{ fontSize: "14px", fontWeight: "600" }} onClick={() => onSort(item.key, item.isAction)} key={index} scope="col">{`${item.label} ${sortedBy === item.key ? isAscending ? '↑' : '↓' : ''}`}</th>
                                )
                            })}
                        </tr>
                    </thead>
                    <tbody >
                        {groupTableFilterData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((item, index) => {
                                return (
                                    <React.Fragment key={index}>
                                        <tr>
                                            <td style={{ fontSize: "15px", fontWeight: "500" }}>{index + 1}</td>
                                            <td style={{ fontSize: "15px", fontWeight: "500" }}>{item.groupNameMr}</td>
                                            <td style={{ fontSize: "15px", fontWeight: "500" }}>{item.groupNameEn}</td>
                                            <td style={{ fontSize: "15px", fontWeight: "500" }}>{item.minPassingMark}</td>

                                            <td>
                                                <Tooltip title="Edit">
                                                    <span className='btn btn-primary btn-sm mx-1 p-1'
                                                        onClick={() => UpdateData(item)} data-bs-toggle="tooltip">
                                                        <FontAwesomeIcon icon={faEdit} />
                                                    </span>
                                                </Tooltip>
                                            </td>
                                            <td>
                                                <Tooltip title="Delete">
                                                    <span className='btn btn-danger btn-sm mx-1 p-1'
                                                        // data-bs-toggle="modal" data-bs-target="#exampleModal"
                                                        data-bs-toggle="tooltip"
                                                        onClick={() => handleShowModal(item)}                                                >
                                                        <FontAwesomeIcon icon={faTrash} />
                                                    </span>
                                                </Tooltip>
                                            </td>
                                        </tr>
                                    </React.Fragment>
                                )
                            })}
                    </tbody>
                    <tfoot style={{ padding: "0px" }}>
                        <tr>
                            <TablePagination
                                style={{ padding: "0px" }}
                                rowsPerPageOptions={[10, 25, 50, 100]}
                                count={groupTableData.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onRowsPerPageChange={onRowsPerPageChange}
                                onPageChange={onPageChange}
                                labelRowsPerPage="Rows Per Page :"
                                classes={{ menuItem: classes.menuItem, displayedRows: classes.displayedRows, selectIcon: classes.selectIcon, toolbar: classes.toolbar, caption: classes.caption, selectLabel: classes.selectLabel, select: classes.select }}
                                backIconButtonProps={{ "aria-label": "Previous Page" }}
                                nextIconButtonProps={{ "aria-label": "Next Page" }} />
                        </tr>
                    </tfoot>
                </table>
            </div>
            {/* <ToastContainer position="top-right" theme="colored" /> */}
            {/* <DeleteModalBox deleteId={deleteId} DeleteData={DeleteData} /> */}

            <GroupSettingModal
                show={showModal}
                onClose={handleCloseModal}
                onDelete={() => DeleteData(deleteId)}
                message="Are you sure you want to delete this?"
            />
        </>

    )
}


export default GroupSettingTable
