import React, { useState, useEffect } from 'react';
import TitleLabel from "../../CommonComponent/TitleLabel";
import { useSelector } from "react-redux";
import axios from 'axios';
import TextField from '@mui/material/TextField';
import { ToastContainer, toast } from 'react-toastify';
import Loader from '../../CommonComponent/Loader';
import {
    saveLastYearYearExamMarkAllocation, saveLastYearYearExamResultSettingExamList,
    saveLastYearYearExamResultSettingSubjectList, saveLastYearYearExamAllocation,
    saveLastYearSubjectAllocation, getExamAllocationDetalis, getExamMarkAllocationDetalis,
    getExamSettingAllocationDetails, getSubjectAllocationDetails
} from '../Services/BulkAllocationAPIURL';
import makeStyles from "@mui/styles/makeStyles";
//import './ProcessingLoader.css';

const useStyles = makeStyles(theme => ({
    select: {
        maxHeight: 160,
        "& ul": {
            backgroundColor: "#F4ECF7",
            //maxHeight: 160,
        },
        "& li": {
            // backgroundColor: "#F4ECF7", fontWeight: "550", fontSize: "14px" 
            fontSize: "14px",
            fontWeight: "550"
        },
    },

}));

const BulkMarkAllocation = () => {
    const titleId = "Bulk Allocation";
    const authUser = useSelector((state) => state.user.value);
    const classes = useStyles();

    const [fromYear, setFromYear] = useState('');

    const [loaderOption, setLoaderOption] = useState(false);
    
    const [enableDisableSubAllocBtn, setEnableDisableSubAllocBtn] = useState(false);
    const [enableDisableExamAllocBtn, setEnableDisableExamAllocBtn] = useState(true);
    const [enableDisableExamMarkAllocBtn, setEnableDisableExamMarkAllocBtn] = useState(true);
    const [enableDisableExamSettingBtn, setEnableDisableExamSettingBtn] = useState(true);
    const [enableDisableSubSettingBtn, setEnableDisableSubSettingBtn] = useState(true);

    useEffect(() => {
        getData();
    }, [authUser])


    //get all details to check bulk allocation is done
    const getData = async () => {
        //setLoaderOption(true);
        if (authUser.sessionYear != "" && authUser.branchId != null && authUser.deptId != null) {
            await axios.get(`${getSubjectAllocationDetails}?sessionYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}`)
                .then(res => {
                    // console.log(res.data);
                    if (res.data == "ALLOCATED") {
                        setEnableDisableSubAllocBtn(false);
                        setEnableDisableExamAllocBtn(false);
                        setLoaderOption(false);
                    }
                    else if (res.data == null) {
                        setEnableDisableExamAllocBtn(true);
                        setEnableDisableExamMarkAllocBtn(true);
                        setEnableDisableExamSettingBtn(true);
                        setEnableDisableSubSettingBtn(true);
                        setLoaderOption(false);
                    }
                })
        }

        if (authUser.sessionYear != "" && authUser.branchMedium != null && authUser.deptId != null) {
            await axios.get(`${getExamAllocationDetalis}?sessionYear=${authUser.sessionYear}&sid=${authUser.deptId}&branchMedium=${authUser.branchMedium}`)
                .then(res => {
                    if (res.data == "ALLOCATED") {
                        setEnableDisableExamAllocBtn(false);
                        setEnableDisableExamMarkAllocBtn(false);
                        setLoaderOption(false);
                    }
                    else if (res.data == null) {
                        setEnableDisableExamAllocBtn(true);
                        setEnableDisableExamMarkAllocBtn(true);
                        setEnableDisableExamSettingBtn(true);
                        setEnableDisableSubSettingBtn(true);
                        setLoaderOption(false);
                    }
                })

            await axios.get(`${getExamMarkAllocationDetalis}?sessionYear=${authUser.sessionYear}&sid=${authUser.deptId}&branchMedium=${authUser.branchMedium}`)
                .then(res => {
                    if (res.data == "ALLOCATED") {
                        setEnableDisableExamAllocBtn(false);
                        setEnableDisableExamMarkAllocBtn(false);
                        setEnableDisableExamSettingBtn(false);
                        setLoaderOption(false);
                    }
                    else if (res.data == null) {
                        setEnableDisableExamAllocBtn(true);
                        setEnableDisableExamMarkAllocBtn(true);
                        setEnableDisableExamSettingBtn(true);
                        setEnableDisableSubSettingBtn(true);
                        setLoaderOption(false);
                    }
                })

            await axios.get(`${getExamSettingAllocationDetails}?sessionYear=${authUser.sessionYear}&sid=${authUser.deptId}&branchMedium=${authUser.branchMedium}`)
                .then(res => {
                    if (res.data == "ALLOCATED") {
                        setEnableDisableExamAllocBtn(false);
                        setEnableDisableExamMarkAllocBtn(false);
                        setEnableDisableExamSettingBtn(false);
                        setEnableDisableSubSettingBtn(false);
                        setLoaderOption(false);
                    }
                    else if (res.data == null) {
                        setEnableDisableExamAllocBtn(true);
                        setEnableDisableExamMarkAllocBtn(true);
                        setEnableDisableExamSettingBtn(true);
                        setEnableDisableSubSettingBtn(true);
                        setLoaderOption(false);
                    }
                })
        }
    }

    //get form year less than 1 session year
    useEffect(() => {
        if (authUser.sessionYear != '') {
            setFromYear(parseInt(authUser.sessionYear) - 1)
        }
    }, [authUser])

    //bulk save subject allocation
    const saveLastYearSubAllocation = async () => {
        setLoaderOption(true);
        if (fromYear < authUser.sessionYear && fromYear != "") {
            await axios.post(`${saveLastYearSubjectAllocation}?sessionYear=${authUser.sessionYear}&sid=${authUser.deptId}&fromYear=${fromYear}&bid=${authUser.branchId}`)
                .then(res => {
                    if (res.data == "NOTFOUND") {
                        setLoaderOption(false);
                        toast.warn(`Data not found for year ${fromYear}`)
                    }
                    else if (res.data == "SAVED") {
                        setLoaderOption(false);
                        setEnableDisableExamAllocBtn(false);
                        toast.success(`Subject Allocation done successfully`)
                    }
                }).catch(err => {
                    console.log("Bulk Subject Allocation" + err);
                    setLoaderOption(false);
                    toast.error("Something went wrong, please check.")
                })
            // setLoaderOption(false);
            // toast.success("Valid");
        }
        else {
            if (fromYear >= authUser.sessionYear) {
                setLoaderOption(false);
                toast.warn("Data not found.")
            }
            else {
                setLoaderOption(false);
                toast.warn("Year field should not be blank")
            }
        }
    }

    //bulk save exam allocation
    const saveLastYearExamAllocation = async () => {
        setLoaderOption(true);
        if (fromYear < authUser.sessionYear && fromYear != "") {
            await axios.post(`${saveLastYearYearExamAllocation}?sessionYear=${authUser.sessionYear}&sid=${authUser.deptId}&branchMedium=${authUser.branchMedium}&fromYear=${fromYear}`)
                .then(res => {
                    if (res.data == "NOTFOUND") {
                        setLoaderOption(false);
                        toast.warn(`Data not found for year ${fromYear}`)
                    }
                    else if (res.data == "SAVED") {
                        setLoaderOption(false);
                        setEnableDisableExamMarkAllocBtn(false);
                        toast.success(`Exam Allocation done successfully`)
                    }
                }).catch(err => {
                    console.log("Bulk Exam Allocation" + err);
                    setLoaderOption(false);
                    toast.error("Something went wrong, please check.")
                })
        }
        else {
            if (fromYear >= authUser.sessionYear) {
                setLoaderOption(false);
                toast.warn("Data not found.")
            }
            else {
                setLoaderOption(false);
                toast.warn("Year field should not be blank")
            }
        }
    }

    //bulk save exam mark allocation
    const saveLastYearExamMarkAllocationMarks = async () => {
        setLoaderOption(true);
        if (fromYear < authUser.sessionYear && fromYear != "") {
            await axios.post(`${saveLastYearYearExamMarkAllocation}?sessionYear=${authUser.sessionYear}&sid=${authUser.deptId}&branchMedium=${authUser.branchMedium}&fromYear=${fromYear}`)
                .then(res => {
                    if (res.data == "NOTFOUND") {
                        setLoaderOption(false);
                        toast.warn(`Data not found for year ${fromYear}`)
                    }
                    else if (res.data == "SAVED") {
                        setLoaderOption(false);
                        setEnableDisableExamSettingBtn(false);
                        toast.success(`Exam mark Allocation done successfully`)
                    }
                }).catch(err => {
                    console.log("Bulk Exam Mark Allocation" + err);
                    setLoaderOption(false);
                    toast.error("Something went wrong, please check.")
                })
        }
        else {
            if (fromYear >= authUser.sessionYear) {
                setLoaderOption(false);
                toast.warn("Data not found.")
            }
            else {
                setLoaderOption(false);
                toast.warn("Year field should not be blank")
            }
        }
    }

    //bulk save exam setting
    const saveLastYearResultSettingExamListMarks = async () => {
        setLoaderOption(true);
        if (fromYear < authUser.sessionYear && fromYear != "") {
            await axios.post(`${saveLastYearYearExamResultSettingExamList}?sessionYear=${authUser.sessionYear}&sid=${authUser.deptId}&branchMedium=${authUser.branchMedium}&fromYear=${fromYear}`)
                .then(res => {
                    if (res.data == "NOTFOUND") {
                        setLoaderOption(false);
                        toast.warn(`Data not found for year ${fromYear}`)
                    }
                    else if (res.data == "SAVED") {
                        setLoaderOption(false);
                        setEnableDisableSubSettingBtn(false);
                        toast.success(`Exam Setting done successfully`)
                    }
                }).catch(err => {
                    console.log("Bulk Exam List Allocation" + err);
                    setLoaderOption(false);
                    toast.error("Something went wrong, please check.")
                })
        }
        else {
            if (fromYear >= authUser.sessionYear) {
                setLoaderOption(false);
                toast.warn("Data not found.")
            }
            else {
                setLoaderOption(false);
                toast.warn("Year field should not be blank")
            }
        }
    }

    //bulk save subject setting
    const saveLastYearResultSettingSubjectListMarks = async () => {
        setLoaderOption(true);
        if (fromYear < authUser.sessionYear && fromYear != "") {
            await axios.post(`${saveLastYearYearExamResultSettingSubjectList}?sessionYear=${authUser.sessionYear}&sid=${authUser.deptId}&branchMedium=${authUser.branchMedium}&fromYear=${fromYear}`)
                .then(res => {
                    if (res.data == "NOTFOUND") {
                        setLoaderOption(false);
                        toast.warn(`Data not found for year ${fromYear}`)
                    }
                    else if (res.data == "SAVED") {
                        setLoaderOption(false);
                        toast.success(`Subject setting done successfully`)
                    }
                }).catch(err => {
                    console.log("Bulk Subject Setting Allocation" + err);
                    setLoaderOption(false);
                    toast.error("Something went wrong, please check.")
                })
        }
        else {
            if (fromYear >= authUser.sessionYear) {
                setLoaderOption(false);
                toast.warn("Data not found.")
            }
            else {
                setLoaderOption(false);
                toast.warn("Year field should not be blank")
            }
        }
    }

    // const saveAllExamdetails = (e) => {
    //     e.preventDefault();
    //     if (option == 1) {
    //         saveLastYearExamAllocation();
    //     }
    //     else if (option == 2) {
    //         saveLastYearExamMarkAllocationMarks();
    //     }
    //     else if (option == 3) {
    //         if (applyFor == 1) {
    //             saveLastYearResultSettingExamListMarks();
    //         }
    //         else if (applyFor == 2) {
    //             saveLastYearResultSettingSubjectListMarks();
    //         }
    //     }
    //     else if (option == 4) {

    //     }
    // }


    return (
        <>
            {
                (loaderOption == true)
                    ?
                    <>
                        {/* <div className='loaderBody'>
                            <div className="loading">
                                <h4>Processing</h4>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                        </div> */}
                        <Loader />
                    </>
                    :
                    <>
                        <TitleLabel titleId={titleId} />
                        <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: "350px" }}>
                            <form>
                                <div className="row">
                                    {/* <label className='col-sm-2' style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>From Year :</label> */}
                                    <div className="col-sm-3">
                                        <TextField
                                            required
                                            fullWidth
                                            value={fromYear}
                                            onChange={e => setFromYear(e.target.value)}
                                            onKeyPress={(e) => (e.key == "Enter") ? fromYear : ""}
                                            type="From Year"
                                            size='small'
                                            id="outlined-required"
                                            label="From Year"
                                            variant="outlined"
                                            margin='dense'
                                            autoComplete='off'
                                            InputLabelProps={{ style: { fontSize: 14 } }}
                                            inputProps={{ maxLength: 4 }}
                                        />
                                    </div>
                                </div>

                                <div className="row mt-3">
                                    <div className="col-sm-12">
                                        <button type='button' disabled={enableDisableSubAllocBtn} onClick={() => saveLastYearSubAllocation()} className='btn btn-sm' style={{ backgroundColor: "#0D21E4", color: "#fff" }}>Subject Allocation</button>
                                        <button type='button' disabled={enableDisableExamAllocBtn} onClick={() => saveLastYearExamAllocation()} className='btn btn-sm btn-warning ml-2' style={{ backgroundColor: "#DE5303", color: "#fff" }}>Exam Allocation</button>
                                        <button type='button' disabled={enableDisableExamMarkAllocBtn} onClick={() => saveLastYearExamMarkAllocationMarks()} className='btn btn-sm ml-2' style={{ backgroundColor: "#099A21", color: "#fff" }}>Exam Mark Allocation</button>
                                        <button type='button' disabled={enableDisableExamSettingBtn} onClick={() => saveLastYearResultSettingExamListMarks()} className='btn btn-sm ml-2' style={{ backgroundColor: "#B31466", color: "#fff" }}>Exam Setting</button>
                                        <button type='button' disabled={enableDisableSubSettingBtn} onClick={() => saveLastYearResultSettingSubjectListMarks()} className='btn btn-sm ml-2' style={{ backgroundColor: "#CD2904", color: "#fff" }}>Subject Setting</button>
                                    </div>
                                </div>

                                {/* <div className="row mt-3">
                                    <span className='badge' style={{ backgroundColor: "#FA6969", borderRadius: "6px", fontWeight: "520", fontSize: "13px", margin: "4px", padding: "6px" }}>After successfull allocation go and check to respective.</span>
                                </div> */}

                                {/* {(() => {
                                    if (loaderOption == true) {
                                        return (
                                            <>
                                                <Box sx={{ width: '100%' }}>
                                                    <LinearProgress />
                                                </Box>
                                            </>
                                        )
                                    }
                                })()} */}

                            </form>
                        </div>
                    </>
            }
            {/* <ToastContainer position="top-right" theme="colored" /> */}
        </>

    )
}

export default BulkMarkAllocation