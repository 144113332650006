import React, { useState, useEffect } from 'react';
import TitleLabel from "../../CommonComponent/TitleLabel";
import { deleteLessonPlanAPIURL, getClassWithDivData, getSubjectData, updateLessonPlanAPIURL } from '../Services/LessonPlanAPIURL';
import TextField from '@mui/material/TextField';
import Autocomplete from "@mui/material/Autocomplete";
import axios from 'axios';
import { useSelector } from "react-redux";
import Paper from "@mui/material/Paper";
import { toast, ToastContainer } from 'react-toastify';
import Loader from '../../CommonComponent/Loader';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'
import TablePagination from '@mui/material/TablePagination'
import Tooltip from '@mui/material/Tooltip';
import makeStyles from "@mui/styles/makeStyles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DeleteModalBox from '../../CommonComponent/DeleteModalBox';
import { saveLessonPlanAPIURL, getLessonPlanDetailsAPIURL } from '../Services/LessonPlanAPIURL';

const useStyles = makeStyles({
    caption: {
        padding: 0,
        fontWeight: 600,
        fontSize: "12px"
    },
    toolbar: {
        fontSize: "12px",
        fontWeight: 600
    },
    selectLabel: {
        fontSize: "12px",
        fontWeight: 500,
        margin: "-10px",
        padding: "0"
    },
    select: {
        fontSize: "12px",
        margin: "0",
        padding: "0"
    },
    displayedRows: {
        fontSize: "12px",
        margin: "-22px",
        padding: "0"
    },
    menuItem: {
        fontSize: "12px",
        fontWeight: 500,
    }
});

const LessonPlan = () => {
    const titleId = "Lesson Plan";
    const authUser = useSelector((state) => state.user.value);
    const classes = useStyles();

    //Note: This below states are declared for Save & Update(Disabled/unDisabled)
    const saveBtnStyle = "lessonPlanSaveBtn btn btn-primary btn-sm";
    const updateBtnStyle = "lessonPlanUpdateBtn btn btn-primary btn-sm";
    const displayNoneBtnStyle = "d-none";
    const [saveBtn, setSaveBtn] = useState(saveBtnStyle);
    const [updateBtn, setUpdateBtn] = useState(displayNoneBtnStyle);

    const [loaderOption, setLoaderOption] = useState(false);

    const [id, setId] = useState('');
    const [classDivObj, setClassDivObj] = useState(null);
    const [divisionData, setDivisionData] = useState([]);
    const [subjectData, setSubjectData] = useState([]);
    const [subjectObj, setSubjectObj] = useState(null);
    const [lesson, setLesson] = useState('');
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [isAscending, setAscending] = useState(false);
    const [sortedBy, setSortedBy] = useState(null);
    const [searchTerm, setSearchTerm] = useState("")
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const [disableFieldsOnUpdate, setDisableFieldsOnUpdate] = useState(false);

    const onPageChange = (event, nextPage) => {
        setPage(nextPage);
    }

    const onRowsPerPageChange = (e) => {
        setRowsPerPage(parseInt(e.target.value));
        setPage(0);
    }

    const onSort = (key, isAction) => {
        if (!isAction) {
            setSortedBy(key)
            if (isAscending) setFilteredData([...data.sort((a, b) => a[key] < b[key] ? 1 : -1)])
            else setFilteredData([...data.sort((a, b) => a[key] > b[key] ? 1 : -1)])
            setAscending(!isAscending)
        }
    }

    useEffect(() => {
        getData();
    }, [authUser]);

    const getData = async () => {
        await axios(`${getSubjectData}`)
            .then(res => {
                setSubjectData(res.data);
            })

        if (authUser.branchId != null && authUser.deptId != null) {
            await axios(`${getClassWithDivData}?sid=${authUser.deptId}&bid=${authUser.branchId}`)
                .then(res => {
                    setDivisionData(res.data);
                })
        }

        if (authUser.branchId != null && authUser.deptId != null && authUser.sessionYear != "") {
            await axios(`${getLessonPlanDetailsAPIURL}?sessionYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}`)
                .then(res => {
                    setData(res.data);
                    setFilteredData(res.data)
                })
        }

    }

    const clearData = () => {
        setDisableFieldsOnUpdate(false);
        setSaveBtn(saveBtnStyle);
        setUpdateBtn(displayNoneBtnStyle);
        setClassDivObj(null);
        setSubjectObj(null);
        setLesson('');
    }

    const saveBtnClick = async () => {
        setLoaderOption(true);
        const saveObj = {
            "classId": classDivObj.classId,
            "divisionId": classDivObj.divisionId,
            "subjectId": subjectObj.id,
            "lessonPlan": lesson,
            "sessionYear": authUser.sessionYear,
            "sectionId": authUser.deptId,
            "branchId": authUser.branchId
        }
        // console.log(JSON.stringify(saveObj))
        await axios.post(saveLessonPlanAPIURL, saveObj)
            .then(res => {
                if (res.data == "SAVED") {
                    getData();
                    clearData();
                    setLoaderOption(false);
                    toast.success("Saved Successfully.")
                }
            }).catch(err => {
                console.log("Lesson Plan Error:" + err);
                setLoaderOption(false);
                toast.error("Something went wrong, please check.")
            })
    }

    const updateBtnClick = async () => {
        setLoaderOption(true);
        const updateObj = {
            "id": id,
            "classId": classDivObj.classId,
            "divisionId": classDivObj.divisionId,
            "subjectId": subjectObj.id,
            "lessonPlan": lesson,
            "sessionYear": authUser.sessionYear,
            "sectionId": authUser.deptId,
            "branchId": authUser.branchId
        }
        // console.log(JSON.stringify(updateObj))
        await axios.put(updateLessonPlanAPIURL, updateObj)
            .then(res => {
                if (res.data == "SAVED") {
                    clearData();
                    getData();
                    setLoaderOption(false);
                    toast.success("Updated Successfully.")
                }
            }).catch(err => {
                console.log("lesson plan update error:" + err);
                setLoaderOption(false);
                toast.error("Something went wrong, please check.")
            })
    }

    const state = {
        button: 1
    };

    const onSubmit = e => {
        e.preventDefault();
        if (state.button === 1) {
            saveBtnClick();
        }
        if (state.button === 2) {
            updateBtnClick();
        }
    };

    useEffect(() => {

        function handlekeydownEvent(event) {
            if (event.key === "Enter") {
                // console.log("Enter is pressed!")
                event.preventDefault();

                let btn1 = document.querySelector('.lessonPlanSaveBtn')
                if (btn1 !== null) {
                    btn1.click();
                }
                let btn2 = document.querySelector('.lessonPlanUpdateBtn')
                if (btn2 !== null) {
                    btn2.click();
                }

            }
        }

        document.addEventListener('keypress', handlekeydownEvent)
        return () => {
            document.removeEventListener('keypress', handlekeydownEvent)
        }

    }, []);

    const [deleteId, setDeleteId] = useState('');

    const DeleteData = async (id) => {
        setLoaderOption(true);
        await axios.delete(`${deleteLessonPlanAPIURL}?id=${id}`)
            .then(res => {
                if (res.data == "DELETED") {
                    setLoaderOption(false);
                    getData();
                    toast.success("Deleted Successfully.")
                }
            }).catch(err => {
                console.log("Lesson plan delete error:" + err);
                setLoaderOption(false);
                toast.error("Something went wrong, please check.")
            })
    }

    const UpdateData = (item) => {
        setDisableFieldsOnUpdate(true);
        //console.log(item);
        setSaveBtn(displayNoneBtnStyle);
        setUpdateBtn(updateBtnStyle);
        setId(item.id);
        setClassDivObj(divisionData.find(e => e.classId == item.classId && e.divisionId == item.divisionId));
        setSubjectObj(subjectData.find(e => e.id == item.subjectId));
        setLesson(item.lesson);
    }

    useEffect(() => {
        setFilteredData([]);
        let tempFilteredData = data.filter((item, index) => (
            item.classNameMr.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.classNameEn.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.subjectNameMr.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.subjectNameEn.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.id.toString().toLowerCase().includes(searchTerm.toLowerCase())
        ))
        setFilteredData(tempFilteredData);
    }, [searchTerm])

    const TableHeading = [
        { label: 'Id', key: 'id' },
        { label: `Class-Division`, key: (authUser.branchMedium == 1) ? 'classNameMr' : 'classNameEn' },
        { label: `Subject`, key: (authUser.branchMedium == 1) ? 'subjectNameMr' : 'subjectNameEn' },
        { label: `Edit`, key: 'edit', isAction: true },
        { label: 'Delete', key: 'delete', isAction: true }
    ];

    return (
        <>
            {
                (loaderOption == true)
                    ?
                    <Loader />
                    :
                    <div>
                        <TitleLabel titleId={titleId} />
                        <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: "350px" }}>

                            <form onSubmit={onSubmit}>

                                <input type="hidden" value={id} onChange={(e) => setId(e.target.value)} />

                                <div className='row my-2'>
                                    <label className="" style={{ width: "130px", fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Class-Division :</label>
                                    <div className='col-sm-3'>
                                        <Autocomplete
                                            id="combo-box-demo"
                                            readOnly={(disableFieldsOnUpdate == true) ? true : false}
                                            options={divisionData}
                                            style={{ fontSize: '14px' }}
                                            PaperComponent={({ children }) => (
                                                <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                            )}
                                            onChange={(event, newValue) => {
                                                setClassDivObj(newValue)
                                            }}
                                            value={classDivObj}
                                            onKeyPress={(e) => (e.key == "Enter") ? classDivObj : ''}
                                            getOptionLabel={option => (authUser.branchMedium == 1) ? option.classNameMr + " " + option.divisionNameMr : option.classNameEn + " " + option.divisionNameEn}
                                            size="small"
                                            renderInput={params => (
                                                <TextField {...params} margin="dense" label="Select Class-Division" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                            )}
                                        />
                                    </div>

                                    <label className="col-sm-1" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Subject:</label>
                                    <div className='col-sm-3'>
                                        <Autocomplete
                                            id="combo-box-demo"
                                            options={subjectData}
                                            readOnly={(disableFieldsOnUpdate == true) ? true : false}
                                            style={{ fontSize: '14px' }}
                                            PaperComponent={({ children }) => (
                                                <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                            )}
                                            onChange={(event, newValue) => {
                                                setSubjectObj(newValue)
                                            }}
                                            value={subjectObj}
                                            onKeyPress={(e) => (e.key == "Enter") ? subjectObj : ''}
                                            getOptionLabel={option => (authUser.branchMedium == 1) ? option.nameMr : option.nameEn}
                                            size="small"
                                            renderInput={params => (
                                                <TextField {...params} margin="dense" label="Select Subject" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                            )}
                                        />
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="responsive col-sm-6" style={{ marginLeft: "130px" }}>
                                        <TextField
                                            id="outlined-multiline-static"
                                            label="Lessons"
                                            margin="dense"
                                            multiline
                                            rows={3}
                                            value={lesson}
                                            onChange={(e) => setLesson(e.target.value)}
                                            onKeyPress={(e) => (e.key == "Enter") ? lesson : ''}
                                            onKeyDown={(e) => (e.key === " " && lesson.length === 0) ? e.preventDefault() : ""}
                                            variant="outlined"
                                            InputLabelProps={{ style: { fontSize: 14 } }}
                                            style={{ width: '625px' }}
                                        />
                                    </div>
                                </div>

                                <div className="row mt-3">
                                    <div className="col-sm-12" style={{ marginLeft: "130px" }}>
                                        <button type="submit" className={saveBtn} onClick={() => (state.button = 1)}>Save</button>
                                        <button type="submit" className={updateBtn} onClick={() => (state.button = 2)} style={{ margin: "0px 4px" }}>Update</button>
                                        <button type="button" className='btn btn-sm btn-danger' onClick={() => clearData()} style={{ margin: "0px 4px" }}>Cancel</button>
                                    </div>
                                </div>


                                <div className='row mt-4 mb-1' style={{ float: 'right' }}>
                                    <div className=''>
                                        <input
                                            className='form-control form-control-sm'
                                            type="text"
                                            style={{ width: "250px", display: "block", float: "right", marginBottom: "6px", border: "1px solid #C2C1C1" }}
                                            placeholder="Search Here"
                                            onChange={(event) => { setFilteredData([]); setSearchTerm(event.target.value); setPage(0); }}
                                            value={searchTerm}
                                        />
                                    </div>
                                </div>

                                <div className="mt-4">
                                    <div className='table-responsive'>
                                        <table className="table table-bordered">
                                            <thead className="table-Default">
                                                <tr>
                                                    {TableHeading.map((item, index) => {
                                                        return (
                                                            <th style={{ fontSize: "14px", fontWeight: "600" }} onClick={() => onSort(item.key, item.isAction)} key={index} scope="col">{`${item.label} ${sortedBy === item.key ? isAscending ? '↑' : '↓' : ''}`}</th>
                                                        )
                                                    })}
                                                </tr>
                                            </thead>
                                            <tbody style={{ borderTop: "2px solid #464de4", borderBottom: "2px solid #464de4" }}>
                                                {filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                    .reverse().map((item, index) => {
                                                        return (
                                                            <tr key={index}>
                                                                <td style={{ fontSize: "15px", fontWeight: "500", width: "150px" }}>{item.id}</td>
                                                                <td style={{ fontSize: "15px", fontWeight: "500", width: "250px" }}>{(authUser.branchMedium == 1) ? item.classNameMr : item.classNameEn}</td>
                                                                <td style={{ fontSize: "15px", fontWeight: "500" }}>{(authUser.branchMedium == 1) ? item.subjectNameMr : item.subjectNameEn}</td>
                                                                <td style={{ fontSize: "15px", fontWeight: "500", width: "150px" }}>
                                                                    <Tooltip title="Edit">
                                                                        <button type='button' className='btn btn-primary btn-sm mx-1 p-1'
                                                                            onClick={() => UpdateData(item)} data-bs-toggle="tooltip"
                                                                        >
                                                                            <FontAwesomeIcon icon={faEdit} />
                                                                        </button>
                                                                    </Tooltip>
                                                                </td>
                                                                <td style={{ fontSize: "15px", fontWeight: "500", width: "150px" }}>
                                                                    <Tooltip title="Delete">
                                                                        <button type='button' className='btn btn-danger btn-sm mx-1 p-1'
                                                                            data-bs-toggle="modal" data-bs-target="#exampleModal"
                                                                            onClick={() => setDeleteId(item.id)}
                                                                        >
                                                                            <FontAwesomeIcon icon={faTrash} />
                                                                        </button>
                                                                    </Tooltip>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })}
                                            </tbody>

                                            <tfoot>
                                                <tr>
                                                    <TablePagination
                                                        style={{ padding: "0px" }}
                                                        rowsPerPageOptions={[10, 25, 50, 100]}
                                                        count={data.length}
                                                        rowsPerPage={rowsPerPage}
                                                        page={page}
                                                        onRowsPerPageChange={onRowsPerPageChange}
                                                        onPageChange={onPageChange}
                                                        labelRowsPerPage="Rows Per Page :"
                                                        classes={{ menuItem: classes.menuItem, displayedRows: classes.displayedRows, selectIcon: classes.selectIcon, toolbar: classes.toolbar, caption: classes.caption, selectLabel: classes.selectLabel, select: classes.select }}
                                                        backIconButtonProps={{ "aria-label": "Previous Page" }}
                                                        nextIconButtonProps={{ "aria-label": "Next Page" }} />
                                                </tr>
                                            </tfoot>
                                        </table>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
            }
            <ToastContainer position="top-right" theme="colored" />
            <DeleteModalBox deleteId={deleteId} DeleteData={DeleteData} />
        </>
    )
}

export default LessonPlan