import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import TitleLabel from "../../CommonComponent/TitleLabel";
import Autocomplete from "@mui/material/Autocomplete";
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import MasterFormSubExamTable from "./MasterFormSubExamTable";
import { BaseURLMasterExams } from '../../CommonServices/ExamMasterAPIURL'
import Paper from "@mui/material/Paper";
import { BaseURLMasterSubExam, SubExamSaveSubExam, SubExamUpdateSubExam } from '../../CommonServices/SubExamMasterAPIURL'
import { useSelector } from "react-redux";
import Loader from '../../CommonComponent/Loader';

const MasterFormSubExam = () => {
    const titleId = "Sub Exam"
    const authUser = useSelector((state) => state.user.value);

    const [loaderOption, setLoaderOption] = useState(false);

    //Note: This below states are declared for Save & Update(Disabled/unDisabled)
    const saveBtnStyle = "subExamSaveBtn btn btn-primary btn-sm";
    const updateBtnStyle = "subExamUpdateBtn btn btn-primary btn-sm";
    const displayNoneBtnStyle = "d-none";
    const [saveBtn, setSaveBtn] = useState(saveBtnStyle);
    const [updateBtn, setUpdateBtn] = useState(displayNoneBtnStyle);

    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState(data);
    const [id, setId] = useState()
    const TableHeading = [
        { label: 'ID', key: 'id' },
        { label: `${titleId} Name Mr`, key: 'nameMr' },
        { label: `${titleId} Name En`, key: 'nameEn' },
        { label: 'Short Name', key: 'shortName' },
        { label: 'Index', key: 'examIndex' },
        { label: 'Exam Name', key: (authUser.branchMedium == 1) ? 'examNameMr' : 'examNameEn' },
        { label: 'Edit', value: 'edit', isAction: true },
        { label: 'Delete', value: 'delete', isAction: true }
    ];

    //State declaration for form fields.
    const [newId, setNewId] = useState("");
    const [subExamData, setSubExamData] = useState([]);
    const [subExamId, setSubExamId] = useState(null);
    const [shortName, setShortName] = useState("");
    const [index, setIndex] = useState("");
    const [subExamMarathiName, setSubExamMarathiName] = useState("");
    const [subExamEnglishName, setSubExamEnglishName] = useState("");
    const [selUpdateId, setSelUpdate] = useState();

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        await axios(BaseURLMasterExams)
            .then(res => {
                setData(res.data);
            });

        await axios(BaseURLMasterSubExam)
            .then(res => {
                setSubExamData(res.data);
                setFilteredData(res.data);
            });
    };

    //Save Data function
    const saveBtnClick = () => {
        setLoaderOption(true);
        const saveObject = {
            id: newId,
            nameMr: subExamMarathiName,
            nameEn: subExamEnglishName,
            shortName: shortName,
            index: index,
            examId: selUpdateId
        };
        axios.post(`${SubExamSaveSubExam}`, saveObject)
            .then((response) => {
                if (response.data != null) {
                    setNewId("");
                    setSubExamId(null);
                    setShortName("");
                    setIndex("");
                    setSubExamMarathiName("");
                    setSubExamEnglishName("");
                    setSaveBtn(saveBtnStyle);
                    setUpdateBtn(displayNoneBtnStyle);
                    getData();
                    setLoaderOption(false);
                    toast.success("Saved successfully.");
                } else {
                    toast.error("Operation failed.");
                }
            });

    }

    //Update DAta Function

    const updateBtnClick = () => {
        setLoaderOption(true);
        const updateObject = {
            nameMr: subExamMarathiName,
            nameEn: subExamEnglishName,
            shortName: shortName,
            index: index,
            examId: selUpdateId
        };
        axios.put(`${SubExamUpdateSubExam}` + newId, updateObject)
            .then((response) => {
                if (response.data != null) {
                    getData();
                    setLoaderOption(false);
                    setNewId("");
                    setSubExamId(null);
                    setShortName("");
                    setIndex("");
                    setSubExamMarathiName("");
                    setSubExamEnglishName("");
                    setSelUpdate("");
                    setSaveBtn(saveBtnStyle);
                    setUpdateBtn(displayNoneBtnStyle);
                    toast.success("Update successfully.");
                }
                else {
                    toast.success("Operation Failed.");
                }
            });
    }

    //Clear DAta Function
    const ClearData = () => {
        setNewId("");
        setSubExamId(null);
        setShortName("");
        setIndex("");
        setSubExamMarathiName("");
        setSubExamEnglishName("");
        setSelUpdate("");
        setSaveBtn(saveBtnStyle);
        setUpdateBtn(displayNoneBtnStyle);
    }

    //OnSubmit Function
    const state = {
        button: 1
    };
    const onSubmit = e => {
        e.preventDefault();
        if (state.button === 1) {
            saveBtnClick();
        }
        if (state.button === 2) {
            updateBtnClick();
        }
    };

    const handleKeyDownMr = (e) => {
        if (e.key === " " && subExamMarathiName.length === 0) {
            e.preventDefault();
        }
    };

    const handleKeyDownEn = (e) => {
        if (e.key === " " && subExamEnglishName.length === 0) {
            e.preventDefault();
        }
    };

    const handleKeyDownSname = (e) => {
        if (e.key === " " && shortName.length === 0) {
            e.preventDefault();
        }
    };

    useEffect(() => {

        function handlekeydownEvent(event) {
            if (event.key === "Enter") {
                // console.log("Enter is pressed!")
                event.preventDefault();

                let btn1 = document.querySelector('.subExamSaveBtn')
                if (btn1 !== null) {
                    btn1.click();
                }
                let btn2 = document.querySelector('.subExamUpdateBtn')
                if (btn2 !== null) {
                    btn2.click();
                }

            }
        }

        document.addEventListener('keypress', handlekeydownEvent)
        return () => {
            document.removeEventListener('keypress', handlekeydownEvent)
        }

    }, []);

    if (loaderOption == true) {
        return <Loader />
    }
    else {
        return (
            <>
                <TitleLabel titleId={titleId} />
                <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px" }}>
                    <form onSubmit={onSubmit}>
                        <input type="hidden" value={newId} onChange={e => setNewId(e.target.value)} />
                        <div className='row my-2'>
                            <label className="col-sm-2" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }} >Exam Name:</label>
                            <div className="col-sm-4 ">
                                <Autocomplete
                                    id="combo-box-demo"
                                    options={data}
                                    PaperComponent={({ children }) => (
                                        <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                    )}
                                    style={{ fontSize: '14px' }}
                                    getOptionLabel={option => (authUser.branchMedium == 1) ? option.nameMr : option.nameEn}
                                    size="small"
                                    renderInput={params => (
                                        <TextField {...params} margin='dense' label="Select Exam" variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                    )}
                                    onChange={(event, newValue) => {
                                        setSubExamId(newValue);
                                        setSelUpdate(newValue.id);
                                    }}
                                    onKeyPress={(e) => (e.key == "Enter") ? subExamId : ""}
                                    value={subExamId}
                                />
                            </div>
                        </div>

                        <div className="row my-2">
                            <label className="col-sm-2 " style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }} >{titleId} Name:</label>
                            <div className='col-sm-4'>
                                <TextField
                                    value={subExamMarathiName}
                                    onChange={e => setSubExamMarathiName(e.target.value)}
                                    onKeyPress={(e) => (e.key == "Enter") ? subExamMarathiName : ""}
                                    required
                                    fullWidth
                                    onKeyDown={handleKeyDownMr}
                                    autoComplete='off'
                                    size='small'
                                    id="outlined-basic"
                                    label="Sub Exam Name Marathi"
                                    variant="outlined"
                                    margin='dense'
                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                    inputProps={{ maxLength: 120 }}
                                />
                            </div>

                            <div className='col-sm-4'>
                                <TextField
                                    value={subExamEnglishName}
                                    onChange={e => setSubExamEnglishName(e.target.value)}
                                    onKeyPress={(e) => (e.key == "Enter") ? subExamEnglishName : ""}
                                    required
                                    fullWidth
                                    onKeyDown={handleKeyDownEn}
                                    autoComplete='off'
                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                    size='small'
                                    id="outlined-basic"
                                    label="Sub Exam Name English"
                                    variant="outlined"
                                    margin='dense'
                                    inputProps={{ maxLength: 120 }}
                                />
                            </div>
                        </div>

                        <div className="row my-2">
                            <label className="col-sm-2 " style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }} >Short Name:</label>
                            <div className='col-sm-4'>
                                <TextField
                                    value={shortName}
                                    onChange={e => setShortName(e.target.value)}
                                    onKeyPress={(e) => (e.key == "Enter") ? shortName : ""}
                                    required
                                    fullWidth
                                    onKeyDown={handleKeyDownSname}
                                    autoComplete='off'
                                    size='small'
                                    id="outlined-basic"
                                    label="Short Name"
                                    variant="outlined"
                                    margin='dense'
                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                    inputProps={{ maxLength: 120 }}
                                />
                            </div>
                        </div>

                        <div className="row my-2">
                            <label className="col-sm-2 " style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }} >Index:</label>
                            <div className='col-sm-4'>
                                <TextField
                                    value={index}
                                    onChange={e => setIndex(e.target.value)}
                                    onKeyPress={(e) => (e.key == "Enter") ? index : ""}
                                    required
                                    fullWidth
                                    type="number"
                                    autoComplete='off'
                                    size='small'
                                    id="outlined-basic"
                                    label="Index"
                                    variant="outlined"
                                    margin='dense'
                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                    inputProps={{ maxLength: 10 }}
                                />
                            </div>
                        </div>

                        <div className="col-sm-12 mt-3">

                            <div className="offset-sm-2">
                                <button type="submit" onClick={() => (state.button = 1)} className={saveBtn} >Save</button>
                                <button type="submit" onClick={() => (state.button = 2)} className={updateBtn} >Update</button>
                                <button type="button" onClick={ClearData} className="btn btn-danger btn-sm" style={{ margin: '0px 4px' }} >Cancel</button>

                            </div>
                        </div>
                    </form>

                    <MasterFormSubExamTable
                        TableHeading={TableHeading}
                        setId={setId}
                        setSubExamId={setSubExamId}
                        setShortName={setShortName}
                        setIndex={setIndex}
                        setSubExamMarathiName={setSubExamMarathiName}
                        setSubExamEnglishName={setSubExamEnglishName}
                        subExamData={subExamData}
                        data={data}
                        setFilteredData={setFilteredData}
                        filteredData={filteredData}
                        setNewId={setNewId}
                        getData={getData}
                        setSubExamData={setSubExamData}
                        setSelUpdate={setSelUpdate}
                        saveBtnStyle={saveBtnStyle}
                        updateBtnStyle={updateBtnStyle}
                        displayNoneBtnStyle={displayNoneBtnStyle}
                        setSaveBtn={setSaveBtn}
                        setUpdateBtn={setUpdateBtn}
                    />
                </div>
                <ToastContainer position="top-right" theme="colored" />
            </>
        )
    }

};

export default MasterFormSubExam;
