import React, { useState } from 'react'

const ApaarIdAssignTextBox = ({ initialText, onChange }) => {

  const [isEdit, setEdit] = useState(false);
  const [text, setText] = useState(initialText);

  return (
    // isEdit ?

    <td style={{ width: "220px", height: "70px" }}>
      <input
        type='text'
        className='form-control form-control-sm'
        onWheel={(e) => e.target.blur()}
        //style={{ width: "120px" }}
        style={(isEdit === true) ? { width: "200px", fontSize: "14px", } : { width: "200px", fontSize: "14px", border: "0px" }}
        onFocus={() => setEdit(true)}
        onBlur={() => {
          if (text == "" || text == null) {
            setText(0)
          }
          setEdit(false);
          onChange(text)
        }}
        onKeyPress={(e) => (e.key == "Enter") ? onChange(text) : ""}
      
        //autoFocus
        onChange={(e) => setText(e.target.value)}
        value={text}
        maxLength="12"
      />
    </td>
    // :
    // <td style={{ width: "160px", height: "70px" }} onClick={() => setEdit(true)}>{text}</td>
  )
}

export default ApaarIdAssignTextBox
