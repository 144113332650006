import React, { useState, useEffect } from 'react';
import axios from 'axios';
import PrintIcon from '@mui/icons-material/Print';
import { ToastContainer, toast } from 'react-toastify';
import { useSelector } from "react-redux";
import Loader from '../../../CommonComponent/Loader';
import TitleLabel from '../../../CommonComponent/TitleLabel';
import { printActualCopyReportAPIURL } from '../Services/EndowmentReportAPIURL';
import { getExportedReportAPIURL } from '../../../CommonServices/APIURL';


const ActualCopy = () => {
    const authUser = useSelector((state) => state.user.value);
    const titleId = "Actual Copy";

    const [loaderOption, setLoaderOption] = useState(false);

    const printActualCopyReport = async (e) => {
        e.preventDefault();
        setLoaderOption(true);

        // console.log(`${printActualCopyReportAPIURL}?sessionYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}`)
        await axios.get(`${printActualCopyReportAPIURL}?sessionYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}`)
            .then((response) => {
                if (response.data == "NOTFOUND") {
                    //console.log(response.data)  
                    setLoaderOption(false);
                    toast.warn("Data not found.")
                }
                else if (response.data != null) {
                    //console.log(response.data)                    
                    window.open(`${getExportedReportAPIURL}${response.data}`);
                    setLoaderOption(false);
                }
            })
            .catch(error => {
                toast.error("Something went wrong, try after sometime.")
                console.log("Endowment Report failed: " + error)
            })

    }

    return (
        <>
            {
                (loaderOption == true)
                    ?
                    <Loader />
                    :
                    <div>
                        <TitleLabel titleId={titleId} />
                        <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: "350px" }}>
                            <form onSubmit={printActualCopyReport}>
                                <div className=''>
                                    <button
                                        type='submit'
                                        className='btn btn-primary btn-sm mb-2'
                                    >
                                        <PrintIcon fontSize="small" /> Print
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
            }

            <ToastContainer position="top-right" theme="colored" />
        </>
    )
}

export default ActualCopy
