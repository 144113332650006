import React, { useState, useEffect } from 'react';
import TitleLabel from "../../CommonComponent/TitleLabel";
import Autocomplete from "@mui/material/Autocomplete";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { getDaybookReport } from "../../CommonServices/VoucherEntryAPIURL";
import { getExportedReportAPIURL } from "../../CommonServices/APIURL";
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import moment from 'moment';
import Loader from '../../CommonComponent/Loader';
import Paper from "@mui/material/Paper";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles(theme => ({
    select: {
        maxHeight: 160,
        "& ul": {
            backgroundColor: "#F4ECF7",
            //maxHeight: 160,
        },
        "& li": {
            // backgroundColor: "#F4ECF7", fontWeight: "550", fontSize: "14px" 
            fontSize: "14px",
            fontWeight: "550"
        },
    },

}));


const ReportOptionData = [
    {
        id: 1,
        optionName: "Date Wise"
    }
]

const monthList = [
    {
        id: 1,
        name: "January",
    },
    {
        id: 2,
        name: "February",
    },
    {
        id: 3,
        name: "March",
    },
    {
        id: 4,
        name: "April",
    },
    {
        id: 5,
        name: "May",
    },
    {
        id: 6,
        name: "June",
    },
    {
        id: 7,
        name: "July",
    },
    {
        id: 8,
        name: "August",
    },
    {
        id: 9,
        name: "September",
    },
    {
        id: 10,
        name: "October",
    },
    {
        id: 11,
        name: "November",
    },
    {
        id: 12,
        name: "December",
    }
]


const Daybook = () => {
    const classes = useStyles();
    const titleId = "Daybook Report";
    const authUser = useSelector((state) => state.user.value);
    const navigate = useNavigate();
    // loader on/off
    const [loaderOption, setLoaderOption] = useState(false);

    const defaultMinDate = new Date(`${authUser.sessionYear}/04/01`);
    const defaultMaxDate = new Date(`${authUser.sessionYear + 1}/03/31`);

    const [dateFrom, setDateFrom] = useState(null);
    const [printVno, setPrintVno] = useState(0);
    //console.log(monthWise);
    const [dateTo, setDateTo] = useState(null);
    const [reportOptionId, setReportOptionId] = useState(1);
    const [reportOptionObj, setReportOptionObj] = useState(ReportOptionData[0]);
    const [id, setId] = useState();
    const [dailySummary, setDailySummary] = useState(false);
    const [dateCheckFrom, setDateCheckFrom] = useState(null);
    const [dateCheckTo, setDateCheckTo] = useState(null);
    const [languageId, setLanguageId] = useState(1);

    const defaultFromDate = new Date(authUser.sessionYear, 3, 1);
    const defaultToDate = new Date(parseInt(authUser.sessionYear) + 1, 2, 31);

    const HandlePrintBlankCheckbox = (e) => {
        setPrintVno(e.target.checked);
        if (e.target.checked == true) {
            setPrintVno(1);
        }
        else {
            setPrintVno(0);
        }
    }

    useEffect(() => {
        if (authUser.sessionYear != '') {
            setDateFrom(moment(defaultFromDate).format("YYYY-MM-DD"))
            setDateTo(moment(defaultToDate).format("YYYY-MM-DD"))
        }
    }, [authUser])

    const HandleDailySummaryCheckBox = (e) => {
        setDailySummary(e.target.checked);

        if (e.target.checked == true) {
            setDailySummary(1);

        }
        else {
            setDailySummary(0);

        }
    }

    const dateReportCall = () => {
        if (moment(dateFrom).format("DD/MM/YYYY") !== "Invalid date" && moment(dateTo).format("DD/MM/YYYY") !== "Invalid date" && dateCheckFrom != "Invalid date" && dateCheckTo != "Invalid date") {
            setLoaderOption(true);
            axios.get(`${getDaybookReport}?acyr=${authUser.sessionYear}&langId=${languageId}&sid=${authUser.deptId}&bid=${authUser.branchId}&printVno=${printVno}&fromDate=${moment(dateFrom).format("DD/MM/YYYY")}&toDate=${moment(dateTo).format("DD/MM/YYYY")}&deptName=${authUser.deptName}`)
                .then((response) => {

                    if (response.data == "NOTFOUND") {
                        setLoaderOption(false);
                        toast.warn("voucher not found for this date")
                    }
                    else if (response.data != null) {
                        // console.log(response.data)
                        window.open(`${getExportedReportAPIURL}${response.data}`);
                        setLoaderOption(false);
                    }

                })
                .catch(error => {
                    setLoaderOption(false);
                    toast.error("Error Occoured, Try after sometime.");
                    console.log("Daybook Report failed: " + error)
                })
            //toast.success("Valid")
        }
        else {
            setLoaderOption(false);
            toast.error("Invalid Date")
        }

    }



    if (loaderOption == true) {
        return <><Loader /></>
    }
    else {
        return (
            <>
                <TitleLabel titleId={titleId} />

                <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: "350px" }}>
                    {/* Form Data start */}
                    <form onSubmit={dateReportCall}>
                        <div className="form-data mt-1">
                            <input type="hidden" value={id} onChange={(e) => setId(e.target.value)} name="" />

                            <div className='row'>
                                <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Language :</label>
                                <div className='col-sm-3'>
                                    <FormControl fullWidth margin='dense'>
                                        <InputLabel size="small" id='demo-simple-select-label' style={{ fontSize: '14px' }}>Select Language</InputLabel>
                                        <Select
                                            MenuProps={{ classes: { paper: classes.select } }}
                                            size='small'
                                            required
                                            fullWidth

                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={languageId}
                                            label="Select Language"
                                            onChange={(e) => {
                                                setLanguageId(e.target.value);

                                            }}
                                        >
                                            <MenuItem value={1}>Marathi</MenuItem>
                                            <MenuItem value={2}>English</MenuItem>
                                        </Select>
                                    </FormControl>

                                </div>
                            </div>

                            <div className="row my-2">
                                <label className="col-sm-2" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>Date From :</label>
                                <div className='col-sm-3'>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DatePicker
                                            label="Date From"
                                            value={dateFrom}
                                            minDate={defaultMinDate}
                                            maxDate={defaultMaxDate}
                                            inputFormat="dd/MM/yyyy"
                                            onChange={(newValue) => {
                                                setDateFrom(newValue);
                                                //(newValue > defaultMaxDate || newValue < defaultMinDate) ? setDateCheckFrom("Invalid date") : setDateCheckFrom(null)
                                                const maxDate = new Date(defaultMaxDate);
                                                maxDate.setDate(maxDate.getDate() + 1); // Add one day to max date to include the full day
                                                if (newValue < defaultMinDate || newValue >= maxDate) {
                                                    setDateCheckFrom("Invalid date");
                                                } else {
                                                    setDateCheckFrom("");
                                                }
                                            }}
                                            openTo="day"
                                            // Show defaultMaxDate selected when the DatePicker is opened
                                            // If dateFrom is not set, defaultMaxDate is used
                                            onOpen={() => setDateFrom(dateFrom || defaultMaxDate)}
                                            renderInput={(params) =>
                                                <TextField
                                                    {...params}
                                                    margin='dense'
                                                    variant="outlined"
                                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                                    required={(reportOptionId == 1) ? true : false}
                                                    fullWidth
                                                    autoComplete='off'
                                                    size="small" />}
                                        />
                                    </LocalizationProvider>
                                </div>

                                <label className="col-sm-1" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>Date To :</label>
                                <div className='col-sm-3'>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DatePicker
                                            label="Date To"
                                            value={dateTo}
                                            minDate={defaultMinDate}
                                            maxDate={defaultMaxDate}
                                            inputFormat="dd/MM/yyyy"
                                            onChange={(newValue) => {
                                                setDateTo(newValue);
                                                //(newValue > defaultMaxDate || newValue < defaultMinDate) ? setDateCheckTo("Invalid date") : setDateCheckTo(null)
                                                const maxDate = new Date(defaultMaxDate);
                                                maxDate.setDate(maxDate.getDate() + 1); // Add one day to max date to include the full day
                                                if (newValue < defaultMinDate || newValue >= maxDate) {
                                                    setDateCheckTo("Invalid date");
                                                } else {
                                                    setDateCheckTo("");
                                                }
                                            }}
                                            openTo="day"
                                            // Show defaultMaxDate selected when the DatePicker is opened
                                            // If dateFrom is not set, defaultMaxDate is used
                                            onOpen={() => setDateTo(dateTo || defaultMaxDate)}
                                            renderInput={(params) =>
                                                <TextField
                                                    {...params}
                                                    variant="outlined"
                                                    margin='dense'
                                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                                    required={(reportOptionId == 1) ? true : false}
                                                    fullWidth
                                                    autoComplete='off'
                                                    size="small" />}
                                        />
                                    </LocalizationProvider>
                                </div>
                                {/* <div className="row mt-3"> */}
                                <div className='col-sm-3'>
                                    <FormControlLabel control={<Checkbox checked={printVno} onChange={HandlePrintBlankCheckbox} />} sx={{ '.MuiFormControlLabel-label': { fontWeight: 540, fontSize: "16px", marginTop: "2px" } }} label="Print Voucher No." />
                                </div>
                                {/* </div> */}

                            </div>

                            <div className="row mt-3">
                                <div className="col-sm-12 offset-sm-2">
                                    <button type="submit" className="btn btn-primary btn-sm">Show</button>
                                    <button type="button" className="btn btn-danger btn-sm ml-2" onClick={() => { setDateFrom(null); setDateTo(null); }} >Cancel</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <ToastContainer position="top-right" theme="colored" />
            </>
        )
    }


};

export default Daybook;
