import React, { useEffect, useState } from 'react'
import { faCheck, faCheckSquare, faTimes, faFingerprintSlash, faCalendarTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal'
import { Box, Tooltip } from '@mui/material';
import axios from 'axios';
import { getEmployeeAttendaceDetailsByEmpId } from '../Services/EmployeeTransactionsAPIURL';
import { toast } from 'react-toastify';
import Loader from '../../CommonComponent/Loader';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import $ from 'jquery';
import '../empAttendTable.css'

const EmpBioAttendanceMonthWise = ({ option, tableheading, fromDate, data, setLoaderOption, setData, filteredData, setFilteredData, employeeObj, schoolObj, authUser,
    setShowModal, setEmpAttendDate, setEmpName, setEmpOnTime, setEmpOffTime, setEmpType, setEmpBioId
}) => {

    const [searchTerm, setSearchTerm] = useState("")
    // const [loaderOption, setLoaderOption] = useState(false);

    const dateObj = new Date(fromDate);
    const monthName = dateObj.toLocaleString('default', { month: 'long' });

    useEffect(() => {
        setFilteredData([]);
        let tempFilteredData = data.filter(item => (
            item.empNameMr.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.empNameEn.toLowerCase().includes(searchTerm.toLowerCase())
        ))
        setFilteredData(tempFilteredData);
    }, [searchTerm])

    const getEmployeeAttendDetails = async (e, item) => {
        setLoaderOption(true);
        setShowModal(true);
        setEmpAttendDate('');
        setEmpName('');
        setEmpOnTime('');
        setEmpOffTime('');
        setEmpType('');

        // console.log(`${getEmployeeAttendaceDetailsByEmpId}?date=${moment(fromDate).format(`YYYY-MM-${item.date}`)}&empId=${e.empId}&bid=${(authUser.branchId == 50) ? schoolObj.id : authUser.branchId}`)
        await axios.get(`${getEmployeeAttendaceDetailsByEmpId}?date=${moment(fromDate).format(`YYYY-MM-${item.date}`)}&empId=${e.empId}&bid=${(authUser.branchId == 50) ? schoolObj.id : authUser.branchId}`)
            .then(res => {
                if (res.data != null) {
                    setEmpAttendDate(res.data.attDate)
                    setEmpName((authUser.branchMedium == 1) ? res.data.empNameMr : res.data.empNameEn)
                    setEmpOnTime(res.data.onTime)
                    setEmpOffTime(res.data.offTime)
                    setEmpType(res.data.empType);
                    setEmpBioId(res.data.bioId);
                    setLoaderOption(false);
                }

            }).catch(err => {
                setLoaderOption(false);
                console.log("get emp details " + err);
                toast.error("Something went wrong, please check.")
            })
    }

    useEffect(() => {
        $(function () {
            var tableContainer = $(".large-table-container-3");
            var table = $(".large-table-container-3 table");
            var fakeContainer = $(".large-table-fake-top-scroll-container-3");
            var fakeDiv = $(".large-table-fake-top-scroll-container-3 div");

            var tableWidth = table.width();
            fakeDiv.width(tableWidth);

            fakeContainer.scroll(function () {
                tableContainer.scrollLeft(fakeContainer.scrollLeft());
            });
            tableContainer.scroll(function () {
                fakeContainer.scrollLeft(tableContainer.scrollLeft());
            });
        });

    }, [])


    return (
        <>
            <div class="card" style={{ boxShadow: "0px 3px 10px grey", }}>
                <div class="card-header" hidden={(option == 1 && authUser.branchId != 50) ? true : false} style={{ backgroundColor: "#E8E8E8" }}>
                    <h6 className='mt-2' hidden={(authUser.branchId != 50) ? true : false}>School Name :  <span style={{ color: "#0568D9" }}> {(schoolObj != null) ? ((authUser.branchMedium == 1) ? schoolObj.schoolNameMr : schoolObj.schoolNameEn) : ''}</span></h6>
                    <h6 className='mt-2' hidden={(option == 1) ? true : false}>Employee Name :  <span style={{ color: "#0568D9" }}>{(employeeObj != null) ? ((authUser.branchMedium == 1) ? employeeObj.employeeNameMr : employeeObj.employeeNameEn) : ''}</span></h6>
                    <h6 className='mt-2' hidden={(option == 1) ? true : false}>Employee Type :  <span style={{ color: "#0568D9" }}>{(employeeObj != null) ? ((employeeObj.employeeType == 1) ? 'Teaching' : 'Non-Teaching') : ''}</span></h6>
                </div>
                <div class="card-body">
                    <div className="" style={{ display: 'flex', justifyContent: 'center' }}>
                        <span className='mt-1' style={{ fontSize: "18px", color: "#2857FA", fontWeight: "600", justifyContent: "center" }}><strong>{monthName}  {moment(fromDate).format('YYYY')}</strong></span>
                    </div>
                    <div className=''>
                        <input
                            className='form-control form-control-sm'
                            type="text"
                            style={{ width: "250px", display: "block", float: "right", marginBottom: "8px", border: "1px solid #C2C1C1" }}
                            placeholder="Search Here"
                            value={searchTerm}
                            onChange={(event) => { setFilteredData([]); setSearchTerm(event.target.value); }}
                        />
                    </div>

                    <div class="large-table-fake-top-scroll-container-3">
                        <div>&nbsp;</div>
                    </div>
                    <div className='large-table-container-3'>
                        <table className="table table-bordered">
                            <thead className='table-default'>
                                <tr>
                                    <th style={{ fontSize: "15px", fontWeight: "600", lineHeight: '1.5' }} rowspan="2">Bio.Id Status</th>
                                    <th style={{ fontSize: "15px", fontWeight: "600", lineHeight: '1.5' }} rowspan="2" hidden={(option == 2) ? true : false}>Employee Name</th>
                                    {/* <th style={{ fontSize: "15px", fontWeight: "600", lineHeight: '1.5',textAlign:'center' }} colspan={tableheading.length + 1}>{moment(fromDate).format('MM/YYYY')}</th> */}
                                    <th style={{ fontSize: "15px", fontWeight: "600" }}>Day</th>
                                    {
                                        tableheading.map(e => {
                                            //console.log(JSON.stringify(e.monthDetails))
                                            return (

                                                <th style={{ fontSize: "14px", fontWeight: "500" }}>{e.day}</th>

                                            )
                                        })
                                    }
                                </tr>
                                <tr>
                                    <th style={{ fontSize: "15px", fontWeight: "600" }}>Date</th>
                                    {
                                        tableheading.map(e => {
                                            return (

                                                <th style={{ fontSize: "14px", fontWeight: "500" }}>{e.date}</th>

                                            )
                                        })
                                    }
                                </tr>

                            </thead>
                            <tbody>
                                {
                                    filteredData.map(e => {
                                        return (
                                            <>
                                                <tr>
                                                    <td style={{ fontSize: "14px", fontWeight: "500", width: "25px" }}>
                                                        {(() => {
                                                            if (e.bioStatus == 0) {
                                                                return (
                                                                    <>
                                                                        <Tooltip title="Not Allocated">
                                                                            <ThumbDownIcon style={{ color: "#DF2204", cursor: "pointer" }} />
                                                                        </Tooltip>
                                                                    </>
                                                                )
                                                            }
                                                            else if (e.bioStatus == 1) {
                                                                return (
                                                                    <>
                                                                        <Tooltip title="Allocated">
                                                                            <ThumbUpIcon style={{ color: "#2C9704", cursor: "pointer" }} />
                                                                        </Tooltip>
                                                                    </>
                                                                )
                                                            }
                                                        })()}
                                                    </td>
                                                    <td colSpan="2" style={{ fontSize: "14px", fontWeight: "500", lineHeight: "1.5", width: "200px" }} hidden={(option == 2) ? true : false}>{(authUser.branchMedium == 1) ? e.empNameMr : e.empNameEn}</td>
                                                    <td style={{ fontSize: "14px", fontWeight: "500", lineHeight: "1.5", width: "200px" }} hidden={(option == 1) ? true : false}></td>
                                                    {
                                                        e.monthDetails.map(item => {
                                                            //console.log(JSON.stringify(item.isPresent))
                                                            if (item.isPresent == 0) {
                                                                if (item.isLeave == 1) {
                                                                    return (
                                                                        <>
                                                                            <td td style={{ fontSize: "14px", fontWeight: "500", textAlign: 'center' }}>
                                                                                <span className='btn btn-sm' style={{ backgroundColor: "#0068AB", color: "#fff", paddingRight: '3px', paddingLeft: "3px", paddingTop: '2px', paddingBottom: '2px' }}
                                                                                >
                                                                                    <FontAwesomeIcon icon={faCalendarTimes} />
                                                                                </span>
                                                                            </td>
                                                                        </>
                                                                    )
                                                                }
                                                                else {
                                                                    return (
                                                                        <>
                                                                            <td style={{ fontSize: "14px", fontWeight: "500", textAlign: 'center' }}>
                                                                                <span className='btn btn-sm' style={{ backgroundColor: "#FF4135", color: "#fff", paddingRight: '3px', paddingLeft: "3px", paddingTop: '1px', paddingBottom: '1px' }}
                                                                                >
                                                                                    <FontAwesomeIcon icon={faTimes} />
                                                                                </span>
                                                                            </td>

                                                                        </>
                                                                    )
                                                                }

                                                            }
                                                            else if (item.isPresent == 1) {
                                                                return (
                                                                    <td style={{ fontSize: "14px", fontWeight: "500", textAlign: 'center' }}>
                                                                        <span className='btn btn-sm' style={{ backgroundColor: "#02960D", color: "#fff", padding: '1px' }}
                                                                            onClick={() => { getEmployeeAttendDetails(e, item); }}
                                                                        >
                                                                            <FontAwesomeIcon icon={faCheck} />
                                                                        </span>
                                                                    </td>
                                                                )
                                                            }

                                                        })
                                                    }

                                                </tr>
                                            </>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    )
}

export default EmpBioAttendanceMonthWise