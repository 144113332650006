import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import TitleLabel from '../../CommonComponent/TitleLabel'
import Autocomplete from "@mui/material/Autocomplete";
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useSelector } from "react-redux";
import Paper from "@mui/material/Paper";
import { getExamData, getSubExamByExamId, getClassDivision, saveClassDivByExam } from '../Services/ExamAllocationAPIURL';
import Loader from '../../CommonComponent/Loader';
import AddIcon from '@mui/icons-material/Add';
import makeStyles from "@mui/styles/makeStyles";
import { BaseURLInstituteMaster, BaseURLSection } from '../../CommonServices/InstituteMasterAPIURL';

const useStyles = makeStyles(theme => ({
    select: {
        maxHeight: 160,
        "& ul": {
            backgroundColor: "#F4ECF7",
            //maxHeight: 160,
        },
        "& li": {
            // backgroundColor: "#F4ECF7", fontWeight: "550", fontSize: "14px" 
            fontSize: "14px",
            fontWeight: "550"
        },
    },
}));

const ExamSubExamAllocation = () => {
    const classes = useStyles();
    const titleId = "Exam Allocation"
    const authUser = useSelector((state) => state.user.value);

    const [loaderOption, setLoaderOption] = useState(false);

    //Note: This below states are declared for Save & Update(Disabled/unDisabled)
    const saveBtnStyle = "btn btn-primary btn-sm";
    const updateBtnStyle = "btn btn-primary btn-sm";
    const displayNoneBtnStyle = "d-none";
    const [saveBtn, setSaveBtn] = useState(saveBtnStyle);
    const [updateBtn, setUpdateBtn] = useState(displayNoneBtnStyle);

    const [classDivData, setClassDivData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);

    const [examData, setExamData] = useState([]);
    const [examId, setExamId] = useState(0);
    const [examObj, setExamObj] = useState(null);
    const [subExamData, setSubExamData] = useState([]);
    const [subExamId, setSubExamId] = useState(0);
    const [subExamObj, setSubExamObj] = useState(null);
    const [searchTerm, setSearchTerm] = useState("");
    const [enableSubExam, setEnableSubExam] = useState(2);
    const [checkValue, setCheckValue] = useState([]);
    const [checkAllSub, setCheckAllSub] = useState(false);

    const [tempData, setTempData] = useState([]);
    const [Val, setVal] = useState(false);
    const [checkDivId, setCheckDivId] = useState('');

    const [departmentData, setDepartmentData] = useState([]);
    const [departmentObj, setDepartmentObj] = useState(null);
    const [bMedium, setBMedium] = useState(1);
    const [branchData, setBranchData] = useState([]);
    const [branchObj, setBranchObj] = useState(null);

    let checkData = classDivData.filter(e => e.isAllocated === 1)
    //console.log(checkData.length);
    //console.log(JSON.stringify(checkValue))

    //check or uncheck checkbox function
    const handleCheckChange = (val, cId, divId, sessionYr, Bid, Sid) => {
        //console.log("for save sessionYr=" + sessionYr)
        setVal(val);

        const SaveObjectData = {
            classId: cId,
            divisionId: divId,
            examId: examId,
            subExamId: subExamId,
            sessionYear: sessionYr,
            sectionId: departmentObj.id,
            branchId: branchObj.id,
            branchMedium: bMedium
        }
        if (val == true) {
            //console.log("Class Div Data=" + JSON.stringify(SaveObjectData))
            setCheckValue(checkValue.concat(SaveObjectData));

            //if checkbox is checked
            let updatedData = classDivData;
            classDivData.map((e, index) => {
                if (e.divisionId == SaveObjectData.divisionId) {
                    updatedData[index] = ({
                        ...e,
                        sectionId: departmentObj.id,
                        branchId: branchObj.id,
                        branchMedium: bMedium,
                        examId: examId,
                        subExamId: subExamId,
                        isAllocated: 1
                    })
                }
            })
            //console.log("updatedData=======" + JSON.stringify(updatedData));
            if (searchTerm == "") {
                setClassDivData(updatedData);
                setFilteredData(updatedData)
            }

            let checkdata = classDivData.filter(e => e.isAllocated === 0)
            //console.log(checkdata.length);
            //console.log("Checkvalue=" + (checkValue.length + 1) + "------" + "filteredData=" + filteredData.length)
            if (checkdata.length === 0) {
                setCheckAllSub(true);
            }
        }
        else if (val == false) {
            setCheckValue(checkValue.filter((item) => item.divisionId != divId))
            setCheckAllSub(false);
            //console.log("Class Div Data="+JSON.stringify(SaveObjectData))              

            //if checkbox is unChecked
            let updatedData = classDivData;
            classDivData.map((e, index) => {
                if (e.divisionId == SaveObjectData.divisionId) {
                    updatedData[index] = ({
                        ...e,
                        sectionId: departmentObj.id,
                        branchId: branchObj.id,
                        branchMedium: bMedium,
                        examId: examId,
                        subExamId: subExamId,
                        isAllocated: 0
                    })
                }
            })
            //console.log("updatedData=======" + JSON.stringify(updatedData));
            if (searchTerm == "") {
                setClassDivData(updatedData);
                setFilteredData(updatedData);
            }
        }
        setCheckDivId(SaveObjectData.divisionId);
    }

    //check or uncheck checkbox after search
    useEffect(() => {

        if (Val == true) {
            let updatedDataTrue = classDivData
            classDivData.map((e, index) => {
                if (e.divisionId == checkDivId) {
                    updatedDataTrue[index] = ({
                        ...e,
                        sectionId: departmentObj.id,
                        branchId: branchObj.id,
                        branchMedium: bMedium,
                        examId: examId,
                        subExamId: subExamId,
                        isAllocated: 1
                    })
                }
            })
            //console.log("Val trueeeee======="+JSON.stringify(updatedDataTrue))
            if (searchTerm == "") {
                setCheckDivId('');
                setClassDivData(updatedDataTrue);
                setFilteredData(updatedDataTrue);
            }
        }
        else if (Val == false) {
            //console.log(checkDivId)
            let updatedDataFalse = classDivData
            classDivData.map((e, index) => {
                if (e.divisionId == checkDivId) {
                    updatedDataFalse[index] = ({
                        ...e,
                        sectionId: departmentObj.id,
                        branchId: branchObj.id,
                        branchMedium: bMedium,
                        examId: examId,
                        subExamId: subExamId,
                        isAllocated: 0
                    })
                }
            })
            //console.log("Val Falseeeee======="+JSON.stringify(updatedDataFalse))
            if (searchTerm == "") {
                setCheckDivId('');
                setClassDivData(updatedDataFalse);
                setFilteredData(updatedDataFalse);
            }
        }

        if (checkAllSub == false && Val == false) {
            let updatedDataFalse = classDivData
            classDivData.map((e, index) => {
                if (e.divisionId == checkDivId) {
                    updatedDataFalse[index] = ({
                        ...e,
                        sectionId: departmentObj.id,
                        branchId: branchObj.id,
                        branchMedium: bMedium,
                        examId: examId,
                        subExamId: subExamId,
                        isAllocated: 0
                    })
                }
            })
            //console.log("check falseeeeee===" + JSON.stringify(updatedDataFalse));
            if (searchTerm == "") {
                setClassDivData(updatedDataFalse);
                setFilteredData(updatedDataFalse);
            }
        }
        else if (checkAllSub == true) {
            let updatedDataTrue = classDivData
            classDivData.map((e, index) => {
                //if (e.divisionId == checkDivId) {
                updatedDataTrue[index] = ({
                    ...e,
                    sectionId: departmentObj.id,
                    branchId: branchObj.id,
                    branchMedium: bMedium,
                    examId: examId,
                    subExamId: subExamId,
                    isAllocated: 1
                })
                //}
            })
            //console.log("check trueeeeee===" + JSON.stringify(updatedDataTrue));         

            if (searchTerm == "") {
                setCheckDivId('');
                setClassDivData(updatedDataTrue);
                setFilteredData(updatedDataTrue);
            }
        }
    }, [searchTerm, Val, checkAllSub, checkDivId])

    //check all checkboxes at a time
    const checkAllSubjects = (e) => {
        setCheckAllSub(e.target.checked);
        setCheckValue([]);
        if (e.target.checked == true) {
            setCheckAllSub(true)
            setCheckValue([]);
            axios(`${getClassDivision}?acyr=${authUser.sessionYear}&sid=${departmentObj.id}&bid=${branchObj.id}&examid=${examId}&subexamid=${subExamId}&branchMedium=${bMedium}`)
                .then(res => {
                    setClassDivData([])
                    setFilteredData([])
                    let updatedData = res.data;
                    res.data.map((e, index) => {
                        updatedData[index] = ({
                            ...e,
                            sectionId: departmentObj.id,
                            branchId: branchObj.id,
                            branchMedium: bMedium,
                            examId: examId,
                            subExamId: subExamId,
                            isAllocated: 1
                        })
                    })
                    setCheckValue(updatedData);
                    setClassDivData(updatedData);
                    setFilteredData(updatedData);
                })
        }
        else if (e.target.checked == false) {
            setCheckAllSub(false)
            axios(`${getClassDivision}?acyr=${authUser.sessionYear}&sid=${departmentObj.id}&bid=${branchObj.id}&examid=${examId}&subexamid=${subExamId}&branchMedium=${bMedium}`)
                .then(res => {
                    setClassDivData([])
                    setFilteredData([])
                    let updatedData = res.data;
                    res.data.map((e, index) => {
                        updatedData[index] = ({
                            ...e,
                            sectionId: departmentObj.id,
                            branchId: branchObj.id,
                            branchMedium: bMedium,
                            examId: examId,
                            subExamId: subExamId,
                            isAllocated: 0
                        })
                    })
                    setClassDivData(updatedData);
                    setFilteredData(updatedData);
                })

            let removedAllData;
            removedAllData = [{
                classId: 0,
                divisionId: 0,
                examId: examId,
                subExamId: subExamId,
                sessionYear: authUser.sessionYear,
                sectionId: departmentObj.id,
                branchId: branchObj.id,
                branchMedium: bMedium
            }]
            setCheckValue(removedAllData);
        }
    }

    useEffect(() => {
        getData();
    }, [authUser]);

    //get API Data
    const getData = async () => {
        await axios(`${getExamData}`)
            .then(res => {
                setExamData(res.data);
            });

        await axios.get(`${BaseURLSection}`)
            .then((response) => {
                //const sortData = (response.data).filter(e => e.acApl === 0)
                setDepartmentData(response.data);
            })

        //console.log(BaseURLInstituteMaster);
        await axios.get(BaseURLInstituteMaster)
            .then(res => {
                //const newFilteredData = res.data.filter(e => e.id == 1 || e.id == 17 || e.id == 18 || e.id == 19)
                setBranchData(res.data);
            })
    };

    //get class-div data by branch,section and medium
    const getClassDivData = async (e) => {
        e.preventDefault();
        setLoaderOption(true);
        setFilteredData([]);
        setClassDivData([]);
        setCheckValue([]);
        setSearchTerm("");
        //console.log(`${getClassDivision}?acyr=${authUser.sessionYear}&sid=${departmentObj.id}&bid=${branchObj.id}&examid=${examId}&subexamid=${subExamId}`)
        await axios(`${getClassDivision}?acyr=${authUser.sessionYear}&sid=${departmentObj.id}&bid=${branchObj.id}&examid=${examId}&subexamid=${subExamId}&branchMedium=${bMedium}`)
            .then(res => {
                // console.log(res.data);
                if (res.data.length == 0) {
                    setLoaderOption(false);
                    toast.warn("Data not found.")
                }
                else { }
                setClassDivData(res.data);
                setFilteredData(res.data);
                setTempData(res.data);

                let SaveObj = (res.data).filter(e => {
                    return e.isAllocated == 1
                })
                let updatedData = SaveObj;
                SaveObj.map((item, index) => {
                    updatedData[index] = ({ ...item, sectionId: departmentObj.id, branchId: branchObj.id, branchMedium: bMedium, examId: examId, subExamId: subExamId })
                })
                setCheckValue(checkValue.concat(updatedData))

                //allchecked data
                let allCheckedData = (res.data).filter(e => {
                    return e.isAllocated == 1
                })
                let updateData = allCheckedData;
                allCheckedData.map((e, index) => {
                    updateData[index] = ({ ...e, sectionId: departmentObj.id, branchId: branchObj.id, branchMedium: bMedium, examId: examId, subExamId: subExamId, isAllocated: 1 })
                })
                // console.log(allCheckedData.length);
                getChekedData(updateData);

                setLoaderOption(false);
            });
    }

    //to check all class-div are checked or not
    const getChekedData = (list) => {
        setLoaderOption(false);
        axios(`${getClassDivision}?acyr=${authUser.sessionYear}&sid=${departmentObj.id}&bid=${branchObj.id}&examid=${examId}&subexamid=${subExamId}&branchMedium=${bMedium}`)
            .then(res => {
                let updatedData = res.data;
                (res.data).map((item, index) => {
                    updatedData[index] = ({
                        ...item,
                        sectionId: departmentObj.id,
                        branchId: branchObj.id,
                        branchMedium: bMedium,
                        examId: examId,
                        subExamId: subExamId,
                        isAllocated: 1
                    })
                })

                //to check two objects or their length are equal
                const checkedData = list.length === updatedData.length && list.every((o, i) => Object.keys(o).length === Object.keys(updatedData[i]).length && Object.keys(o).every(k => o[k] === updatedData[i][k]))

                if (checkedData == true) {
                    setCheckAllSub(true);
                } else {
                    setCheckAllSub(false);
                }
            })
    }

    //get sub-exma by exam id
    const getSubExam = async (ExamId) => {
        await axios(`${getSubExamByExamId}` + ExamId)
            .then(res => {
                setSubExamData(res.data);
            });
    }

    //save Exam Allocation
    const saveData = () => {
        setLoaderOption(true);
        let removedAllData;
        if (checkValue.length === 0) {
            // toast.error("Empty Array........")
            removedAllData = [{
                classId: 0,
                divisionId: 0,
                examId: examId,
                subExamId: subExamId,
                sessionYear: authUser.sessionYear,
                sectionId: departmentObj.id,
                branchId: branchObj.id,
                branchMedium: bMedium
            }]
            //console.log(JSON.stringify(removedAllData));
            axios.post(`${saveClassDivByExam}`, removedAllData)
                .then((response) => {
                    if (response.data != null) {
                        getData();
                        setFilteredData([]);
                        setClassDivData([]);
                        setTempData([]);
                        // setExamObj(null);
                        // setSubExamObj(null);
                        // setEnableSubExam(2);
                        // setSubExamId(0);
                        setCheckValue([]);
                        setSearchTerm("");
                        // setDepartmentObj(null);
                        // setBMedium(1);
                        // setBranchObj(null);
                        setLoaderOption(false);
                        toast.success("Exam Allocation successfully done.");
                    } else {
                        toast.error("Operation failed.");
                    }
                }).catch((error) => {
                    console.log("error name: " + error);
                });
        }
        else {
            // console.log(JSON.stringify(checkValue));
            axios.post(`${saveClassDivByExam}`, checkValue)
                .then((response) => {
                    if (response.data != null) {
                        getData();
                        setFilteredData([]);
                        setClassDivData([]);
                        setTempData([]);
                        // setExamObj(null);
                        // setSubExamObj(null);
                        // setEnableSubExam(2);
                        // setSubExamId(0);
                        setCheckValue([]);
                        setSearchTerm("");
                        // setDepartmentObj(null);
                        // setBMedium(1);
                        // setBranchObj(null);
                        setLoaderOption(false);
                        toast.success("Exam Allocation successfully done.");
                    } else {
                        toast.error("Operation failed.");
                    }
                }).catch((error) => {
                    console.log("error name: " + error);
                });
        }
    }

    //search function
    useEffect(() => {
        setFilteredData([])
        let tempFilteredData =
            classDivData.filter(
                (item) =>
                    item.classNameMr.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    item.classNameEn.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    item.divisionNameEn.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    item.divisionNameMr.toLowerCase().includes(searchTerm.toLowerCase())
            )
        setFilteredData(tempFilteredData);
    }, [searchTerm]);

    const FormTableHeading = [{ label: "Class", key: "class" }, { label: "Division", key: "division" }, { label: "Status", key: "isAllocated" }]

    if (loaderOption == true) {
        return <Loader />
    }
    else {
        return (
            <>
                <TitleLabel titleId={titleId} />
                <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px" }}>
                    <form onSubmit={getClassDivData}>
                        <div className="row">
                            <label className="col-sm-2" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }} >Branch :</label>
                            <div className="col-sm-7">
                                <Autocomplete
                                    id="combo-box-demo"
                                    options={branchData}
                                    PaperComponent={({ children }) => (
                                        <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                    )}
                                    style={{ fontSize: '14px' }}
                                    getOptionLabel={option => (authUser.branchMedium == 1) ? option.schoolNameMr : option.schoolNameEn}
                                    size="small"
                                    onChange={(event, newValue) => {
                                        setBranchObj(newValue);
                                        setDepartmentObj(null);
                                        setBMedium(1);
                                        setExamObj(null);
                                        setEnableSubExam(2);
                                        setSubExamObj(null);
                                        setFilteredData([]);
                                        setClassDivData([]);
                                    }}
                                    value={branchObj}
                                    renderInput={params => (
                                        <TextField {...params} margin='dense' label="Select Branch" variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                    )}
                                />
                            </div>
                        </div>

                        <div className="row my-2">
                            <label className="col-sm-2" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }} >Department :</label>
                            <div className="col-sm-3 ">
                                <Autocomplete
                                    id="combo-box-demo"
                                    options={departmentData}
                                    PaperComponent={({ children }) => (
                                        <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                    )}
                                    style={{ fontSize: '14px' }}
                                    getOptionLabel={option => (authUser.branchMedium == 1) ? option.nameMr : option.nameEn}
                                    size="small"
                                    onChange={(event, newValue) => {
                                        setDepartmentObj(newValue);
                                        setBMedium(1);
                                        setExamObj(null);
                                        setEnableSubExam(2);
                                        setSubExamObj(null);
                                        setFilteredData([]);
                                        setClassDivData([]);
                                    }}
                                    value={departmentObj}
                                    renderInput={params => (
                                        <TextField {...params} margin='dense' label="Select Department" variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                    )}
                                />
                            </div>

                            <label className="col-sm-2" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }} >Medium :</label>
                            <div className='col-sm-2'>
                                <FormControl fullWidth margin="dense">
                                    <InputLabel id='demo-simple-select-label' size="small" style={{ fontSize: '14px', marginBottom: "50px" }}>Medium</InputLabel>
                                    <Select
                                        MenuProps={{ classes: { paper: classes.select } }}
                                        size='small'
                                        fullWidth
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={bMedium}
                                        label="Medium"
                                        onChange={(e) => {
                                            setBMedium(e.target.value);
                                            setExamObj(null);
                                            setEnableSubExam(2);
                                            setSubExamObj(null);
                                            setFilteredData([]);
                                            setClassDivData([]);
                                        }}
                                    >
                                        <MenuItem value={1}>Marathi</MenuItem>
                                        <MenuItem value={2}>English</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                        </div>

                        <div className='row my-2'>
                            <label className="col-sm-2" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }} >Exam Name :</label>
                            <div className="col-sm-3 ">
                                <Autocomplete
                                    id="combo-box-demo"
                                    options={examData}
                                    PaperComponent={({ children }) => (
                                        <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                    )}
                                    style={{ fontSize: '14px' }}
                                    getOptionLabel={option => (bMedium == 1) ? option.nameMr : option.nameEn}
                                    size="small"
                                    onChange={(event, newValue) => {
                                        setExamObj(newValue);
                                        setSubExamObj(null);
                                        setEnableSubExam(2);
                                        setFilteredData([]);
                                        setClassDivData([]);
                                        if (newValue != null) {
                                            setExamId(newValue.id);
                                            getSubExam(newValue.id);
                                        }
                                        else {
                                            setSubExamObj(null);
                                            setEnableSubExam(2);
                                            setFilteredData([]);
                                            setClassDivData([]);
                                        }
                                    }}
                                    value={examObj}
                                    renderInput={params => (
                                        <TextField {...params} margin='dense' label="Select Exam" variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                    )}
                                />
                            </div>

                            <label className="col-sm-2" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }} >Sub-Exam Applicable :</label>
                            <div className='col-sm-2'>
                                <FormControl fullWidth margin="dense">
                                    <InputLabel id='demo-simple-select-label' size="small" style={{ fontSize: '14px', marginBottom: "50px" }}>Applicable</InputLabel>
                                    <Select
                                        MenuProps={{ classes: { paper: classes.select } }}
                                        size='small'
                                        fullWidth
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={enableSubExam}
                                        label="Applicable"
                                        onChange={(e) => {
                                            setEnableSubExam(e.target.value);
                                            setSubExamObj(null);
                                            setFilteredData([]);
                                            setClassDivData([]);
                                            if (e.target.value == 2) {
                                                setSubExamId(0);
                                            }
                                        }}
                                    >
                                        <MenuItem value={1}>Yes</MenuItem>
                                        <MenuItem value={2}>No</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                        </div>

                        {(() => {
                            if (enableSubExam == 1) {
                                return (
                                    <div className='row my-2'>
                                        <label className="col-sm-2" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }} >Sub Exam Name :</label>
                                        <div className="col-sm-3">
                                            <Autocomplete
                                                id="combo-box-demo"
                                                PaperComponent={({ children }) => (
                                                    <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                                )}
                                                options={subExamData}
                                                style={{ fontSize: '14px' }}
                                                getOptionLabel={option => (bMedium == 1) ? option.nameMr : option.nameEn}
                                                size="small"
                                                onChange={(event, newValue) => {
                                                    setSubExamObj(newValue);
                                                    setFilteredData([]);
                                                    setClassDivData([]);
                                                    if (newValue != null) {
                                                        setSubExamId(newValue.id);
                                                    }
                                                    else {
                                                        setFilteredData([]);
                                                        setClassDivData([]);
                                                    }
                                                }}
                                                value={subExamObj}
                                                renderInput={params => (
                                                    <TextField {...params} margin='dense' label="Select Sub Exam" variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }}
                                                        required={(enableSubExam == 1) ? true : false} />
                                                )}
                                            />
                                        </div>
                                    </div>
                                )
                            }
                        })()}

                        <div className="row mt-3">
                            <div className="col-sm-4 offset-sm-2">
                                <button type="submit" className="btn btn-sm btn-warning">Fill Class-Division</button>
                            </div>
                        </div>
                    </form>

                    {
                        (classDivData == null || classDivData == "")
                            ?
                            <h4 className='mt-5'>No Records Found, Select Exam</h4>
                            :
                            <div>
                                <div className='row my-1'>
                                    <div className='offset-sm-7 mt-1'>
                                        <input
                                            className='form-control form-control-sm'
                                            type="text"
                                            style={{ width: "250px", display: "block", float: "left", marginBottom: "6px", border: "1px solid #C2C1C1" }}
                                            placeholder="Search Here"
                                            value={searchTerm}
                                            onChange={(event) => { setFilteredData([]); setSearchTerm(event.target.value) }}
                                        />
                                    </div>
                                </div>

                                <div className='table-responsive col-sm-8 offset-sm-2 mt-2' style={{ maxHeight: "320px" }} >
                                    <table className="table table-bordered">
                                        <thead className="" style={{ position: "sticky", zIndex: "2", overflow: "hidden", top: "0", backgroundColor: "#EAECEE", borderBottom: "2px solid #464de4" }}>
                                            <tr>
                                                {/* {FormTableHeading.map((item, index) => {
                                                        return (
                                                            <th style={{ fontSize: "14px", fontWeight: "600" }} scope="col" key={index}>{item.label}</th>
                                                        )
                                                    })} */}
                                                <th style={{ fontSize: "15px", fontWeight: "600" }}>Class</th>
                                                <th style={{ fontSize: "15px", fontWeight: "600" }}>Division</th>
                                                <th style={{ fontSize: "15px", fontWeight: "600", margin: "0px", padding: "2px" }}><Checkbox
                                                    sx={{ '& .MuiSvgIcon-root': { fontSize: 24 } }}
                                                    checked={(checkAllSub == true) ? true : false} onChange={checkAllSubjects} /> Status</th>
                                            </tr>
                                        </thead>
                                        <tbody style={{ borderTop: "2px solid #464de4", borderBottom: "2px solid #464de4" }}>
                                            {filteredData.map((item, index) => {
                                                return (
                                                    <tr key={index} className="table-sm">
                                                        <td style={{ fontSize: "15px", fontWeight: "500" }}>{(bMedium == 1) ? item.classNameMr : item.classNameEn}</td>
                                                        <td style={{ fontSize: "15px", fontWeight: "500", width: "250px" }}>{(bMedium == 1) ? item.divisionNameMr : item.divisionNameEn}</td>
                                                        <td style={{ fontSize: "15px", fontWeight: "500", margin: "0px", padding: "0px", width: "200px" }}>
                                                            <Checkbox defaultChecked={(item.isAllocated == 1) ? true : false} sx={{ '& .MuiSvgIcon-root': { fontSize: 30 } }}
                                                                onChange={(e) => {
                                                                    let val = e.target.checked;
                                                                    handleCheckChange(val, item.classId, item.divisionId, item.sessionYear, item.branchId, item.sectionId);
                                                                }}
                                                            />
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                </div>

                                <div className="col-sm-12 mt-3">
                                    <div className="offset-sm-2">
                                        <button type="button" className={saveBtn} onClick={() => saveData()}><AddIcon fontSize="small" />Save Changes</button>
                                    </div>
                                </div>
                            </div>
                    }
                </div>
                {/* <ToastContainer position="top-right" theme="colored" /> */}
            </>
        )
    }

}

export default ExamSubExamAllocation