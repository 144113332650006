const labelName = [

    {
        id: 2,
        title: "Designation",
        GetMapping: "Designations",
        PostMapping: "saveDesignations",
        PutMapping: "updateDesignation",
        DeleteMapping: "deleteDesignation"
    },
    {
        id: 3,
        title: "Occupation",
        GetMapping: "Occupations",
        PostMapping: "saveOccupations",
        PutMapping: "updateOccupation",
        DeleteMapping: "deleteOccupation"
    },
    {
        id: 4,
        title: "Academic Qualifications",
        GetMapping: "AcademicQuals",
        PostMapping: "saveAcademicQuals",
        PutMapping: "updateAcademicQual",
        DeleteMapping: "deleteAcademicQual"
    },
    {
        id: 5,
        title: "Professional Qualifications",
        GetMapping: "ProfessionalQuals",
        PostMapping: "saveProfessionalQuals",
        PutMapping: "updateProfessionalQual",
        DeleteMapping: "deleteProfessionalQual"
    },
    {
        id: 6,
        title: "Mother Tongue",
        GetMapping: "Mothertongues",
        PostMapping: "saveMothertongue",
        PutMapping: "updateMothertongue",
        DeleteMapping: "deleteMothertongue"
    },
    {
        id: 8,
        title: "Caste",
        GetMapping: "Castes",
        PostMapping: "saveCaste",
        PutMapping: "updateCaste",
        DeleteMapping: "deleteCaste"
    },
    {
        id: 10,
        title: "Nationalities",
        GetMapping: "Nationalities",
        PostMapping: "saveNationalities",
        PutMapping: "updateNationality",
        DeleteMapping: "deleteNationality"
    },
    {
        id: 14,
        title: "Concession",
        GetMapping: "Concessions",
        PostMapping: "saveConcession",
        PutMapping: "updateConcession",
        DeleteMapping: "deleteConcession"
    },
    {
        id: 15,
        title: "Disibilities",
        GetMapping: "Disibilities",
        PostMapping: "saveDisibility",
        PutMapping: "updateDisibility",
        DeleteMapping: "deleteDisibility"
    },
    {
        id: 18,
        title: "Progress Conduct",
        GetMapping: "ProgressConducts",
        PostMapping: "saveProgressConduct",
        PutMapping: "updateProgressConduct",
        DeleteMapping: "deleteProgressConduct"
    },
    {
        id: 21,
        title: "Leaving Remarks",
        GetMapping: "Leavings",
        PostMapping: "saveLeaving",
        PutMapping: "updateLeaving",
        DeleteMapping: "deleteLeaving"
    },
    {
        id: 123,
        title: "Award",
        GetMapping: "getAwardData",
        PostMapping: "saveAwardData",
        PutMapping: "updateAwardData",
        DeleteMapping: "deleteAwardData"
    },
    {
        id: 128,
        title: "Stream Master",
        GetMapping: "Streams",
        PostMapping: "saveStream",
        PutMapping: "updateStream",
        DeleteMapping: "deleteStream"
    },
    {
        id: 132,
        title: "City",
        GetMapping: "Cities",
        PostMapping: "saveCity",
        PutMapping: "updateCity",
        DeleteMapping: "deleteCity"
    },
    {
        id: 133,
        title: "Document",
        GetMapping: "getStudentDocuments",
        PostMapping: "saveStudentDocument",
        PutMapping: "updateStudentDocument",
        DeleteMapping: "deleteStudentDocument"
    },
    {
        id: 141,
        title: "State",
        GetMapping: "getStateMasterList",
        PostMapping: "saveStateMaster",
        PutMapping: "updateStateMaster",
        DeleteMapping: "deleteStateMaster"
    },
];

export default labelName;