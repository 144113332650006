import React, { useState, useEffect } from 'react';
import TitleLabel from "../../CommonComponent/TitleLabel";
import TextField from '@mui/material/TextField';
import axios from 'axios';
import Autocomplete from "@mui/material/Autocomplete";
import { EmployeeMasterGetMapping } from '../../CommonServices/ClassTeacherAllocationAPIURL';
import { ClassDivisionGetData, ClassDivisionSaveData } from '../../CommonServices/ClassTeacherAllocationAPIURL';
import { useSelector } from "react-redux";
import Checkbox from '@mui/material/Checkbox';
import { ToastContainer, toast } from 'react-toastify';
import Paper from "@mui/material/Paper";
import '../Styles/TableStyle.css'
import Loader from '../../CommonComponent/Loader';


const ClassTeacherAllocation = () => {
    const titleId = "Class Teacher Allocation";
    const authUser = useSelector((state) => state.user.value);

    const [loaderOption, setLoaderOption] = useState(false);

    //Note: This below states are declared for Save & Update(Disabled/unDisabled)
    const saveBtnStyle = "classTeacherAllocationBtn btn btn-primary btn-sm";
    const updateBtnStyle = "btn btn-primary btn-sm";
    const displayNoneBtnStyle = "d-none";
    const [saveBtn, setSaveBtn] = useState(saveBtnStyle);
    const [updateBtn, setUpdateBtn] = useState(displayNoneBtnStyle);

    const [id, setId] = useState();
    const [employeeData, setEmployeeData] = useState([]);
    const [classDivisionData, setClassDivisionData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [employeeId, setEmployeeId] = useState(0);
    const [employeeObj, setEmployeeObj] = useState(null);
    const [searchTerm, setSearchTerm] = useState("")
    const [selectedId, setSelectedId] = useState(false);
    //console.log(classDivisionData);
    const [classId, setClassId] = useState(0);
    const [divisionId, setDivisionId] = useState(0);
    const [tempData, setTempData] = useState([]);
    const [divisionIdFalse, setDivisionIdFalse] = useState('');
    const [divisionIdTrue, setDivisionIdTrue] = useState('');
    const [Val, setVal] = useState(false);
    const [checkValue, setCheckValue] = useState({});
    // console.log(JSON.stringify(checkValue));
    //console.log("Val======" + Val + "searchterm===" + searchTerm + "divisionIdFalse=====" + divisionIdFalse + "divisionIdTrue=====" + divisionIdTrue)

    const HandleDivClassSelectCheckbox = (event, cId, dId, isAllow) => {
        setVal(event.target.checked);

        if (event.target.checked === true) {
            const saveObject = {
                id: id,
                classId: cId,
                divisionId: dId,
                employeeId: employeeId,
                secessionYear: authUser.sessionYear,
                sectionId: authUser.deptId,
                branchId: authUser.branchId,
            }

            setCheckValue(saveObject);
            setClassId(cId);
            setDivisionId(dId);

            setDivisionIdTrue(dId);
            setDivisionIdFalse('');

            //if checkbox is checked
            let updatedData = tempData;
            tempData.map((e, index) => {
                if (e.divisionId == divisionIdTrue) {
                    updatedData[index] = ({ ...e, isAllocated: 1 })
                }

            })
            //console.log("updatedData=======" + JSON.stringify(updatedData));
            if (searchTerm == "") {
                setClassDivisionData(updatedData);
                setFilteredData(updatedData)
            }
        }
        else if (event.target.checked === false) {

            const removeObject = {
                id: id,
                classId: 0,
                divisionId: 0,
                employeeId: employeeId,
                secessionYear: authUser.sessionYear,
                sectionId: authUser.deptId,
                branchId: authUser.branchId,
            }

            setCheckValue(removeObject);

            setClassId(0);
            setDivisionId(0);

            setDivisionIdFalse(dId);
            setDivisionIdTrue('');

            //if checkbox is unChecked
            let updatedData = tempData;
            tempData.map((e, index) => {
                if (e.divisionId == divisionIdFalse) {
                    updatedData[index] = ({ ...e, isAllocated: 0 })
                }

            })
            //console.log("updatedData=======" + JSON.stringify(updatedData));

            if (searchTerm == "") {
                setClassDivisionData(updatedData);
                setFilteredData(updatedData);
            }
        }
        setSelectedId(event.target.checked == true ? dId : 0)
    }


    useEffect(() => {

        //if checkbox is checked after search
        let updatedData2 = tempData;
        tempData.map((e, index) => {
            if (e.divisionId == divisionIdTrue) {
                updatedData2[index] = ({ ...e, isAllocated: 1 })
            }
        })

        //if checkbox is unChecked after search
        let updatedData = tempData;
        tempData.map((e, index) => {
            if (e.divisionId == divisionIdFalse) {
                updatedData[index] = ({ ...e, isAllocated: 0 })
            }
        })

        if (Val == false) {
            setDivisionIdTrue('');
            //setCheckAllSub(false);
            if (searchTerm == "") {
                setClassDivisionData(updatedData);
                setFilteredData(updatedData);
            }
        }
        else if (Val == true) {
            setDivisionIdFalse('');
            if (searchTerm == "") {
                setClassDivisionData(updatedData2);
                setFilteredData(updatedData2);
            }
        }

    }, [searchTerm, divisionIdTrue, divisionIdFalse, Val])

    useEffect(() => {
        getData();
    }, [authUser]);


    const getData = async () => {
        setLoaderOption(true);
        //console.log(`${EmployeeMasterGetMapping}?bid=${authUser.branchId}`);
        if (authUser.branchId != null) {
            await axios.get(`${EmployeeMasterGetMapping}?bid=${authUser.branchId}`)
                .then((response) => {
                    setEmployeeData(response.data);
                    setLoaderOption(false);
                })
        }
    }

    const saveData = (e) => {
        setLoaderOption(true);
        e.preventDefault();
        // const saveObject = {
        //     id: id,
        //     classId: classId,
        //     divisionId: divisionId,
        //     employeeId: employeeId,
        //     secessionYear: authUser.sessionYear,
        //     sectionId: authUser.deptId,
        //     branchId: authUser.branchId,
        // }
        // console.log(JSON.stringify(checkValue));
        axios.post(`${ClassDivisionSaveData}`, checkValue)
            .then((response) => {
                if (response.data != null) {
                    getData();
                    setClassDivisionData([]);
                    setFilteredData([]);
                    setTempData([]);
                    setEmployeeId(null);
                    setEmployeeObj(null);
                    setSearchTerm("");
                    setLoaderOption(false);
                    toast.success("Class Teacher Allocation successfully done.");

                } else {
                    toast.error("Operation failed.");
                }
            }).catch((error) => {
                console.log("error name: " + error);
            });
    }

    // useEffect(() => {
    //     getClassDivData();
    // }, [authUser])

    const getClassDivData = async (empId) => {
        setLoaderOption(true);
        setClassDivisionData([]);
        setFilteredData([]);
        setSearchTerm("");
        // if (authUser.branchId != null && authUser.deptId != null && authUser.sessionYear != "" && employeeObj != null) {
        await axios.get(`${ClassDivisionGetData}?sid=${authUser.deptId}&bid=${authUser.branchId}&acyr=${authUser.sessionYear}&empid=${empId}`)
            .then((response) => {
                //console.log(JSON.stringify(response.data))                
                setClassDivisionData(response.data);
                setFilteredData(response.data);
                getDisabledData(response.data);
                setTempData(response.data);

                let saveObj = (response.data).filter(e => {
                    return e.isAllocated == 1
                })

                let updatedData = []
                saveObj.find((e, index) => {
                    updatedData = {
                        id: e.id,
                        classId: e.classId,
                        divisionId: e.divisionId,
                        employeeId: empId,
                        secessionYear: authUser.sessionYear,
                        sectionId: authUser.deptId,
                        branchId: authUser.branchId,
                    }
                })
                setCheckValue(updatedData);
                setLoaderOption(false);
            })
        // }
    }

    const getDisabledData = (list) => {
        //console.log(selectedId);
        list.map(item => {
            if (item.isAllocated == 1) {
                setSelectedId(true);
            } else {
                setSelectedId(false);
            }
        })
    }

    useEffect(() => {
        setFilteredData([])
        let tempFilteredData =
            classDivisionData.filter(
                (item) =>
                    item.classNameMr.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    item.divisionNameMr.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    item.classNameEn.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    item.divisionNameEn.toLowerCase().includes(searchTerm.toLowerCase())
            )
        setFilteredData(tempFilteredData);
    }, [searchTerm]);

    useEffect(() => {
        function handlekeydownEvent(event) {
            if (event.key === "Enter") {
                if (employeeObj !== null || employeeObj !== "") {
                    // console.log('Enter is pressed!');
                    event.preventDefault();
                    let btn = document.querySelector('.classTeacherAllocationBtn')
                    if (btn !== null) {
                        btn.click();
                    }
                }
            }
        }

        document.addEventListener('keypress', handlekeydownEvent)
        return () => {
            document.removeEventListener('keypress', handlekeydownEvent)
        }

    }, []);

    const FormTableHeading = [{ label: "Class", key: "class" }, { label: "Division", key: "division" }, { label: "Status", key: "status" }]

    if (loaderOption == true) {
        return <Loader />
    }
    else {
        return (
            <>
                <TitleLabel titleId={titleId} />
                <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: "350px" }}>

                    <input type="hidden" value={id} onChange={(e) => setId(e.target.value)} name="" />

                    <div className='row'>
                        <label className="col-sm-1" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Teacher:</label>
                        <div className="col-sm-3">
                            <Autocomplete
                                id="combo-box-demo"
                                PaperComponent={({ children }) => (
                                    <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                )}
                                options={employeeData}
                                onChange={(event, newValue) => {
                                    setEmployeeObj(newValue);
                                    setFilteredData([]);
                                    setClassDivisionData([]);
                                    if (newValue != null) {
                                        setEmployeeId(newValue.id);
                                        getClassDivData(newValue.id);
                                    }
                                }}
                                value={employeeObj}
                                style={{ fontSize: '14px' }}
                                getOptionLabel={option => (authUser.branchMedium == 1) ? option.employeeNameMr : option.employeeNameEn}
                                size="small"
                                renderInput={params => (
                                    <TextField {...params} label="Select Teacher" margin='dense' autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                )}
                            />
                        </div>
                    </div>

                    {
                        (classDivisionData.length == 0)
                            ?
                            <h4 className='mt-5'>No Records found, Select Teacher</h4>
                            :
                            <div>
                                <div className='row my-1'>
                                    <div className='offset-sm-4 mt-2'>
                                        <input
                                            className='form-control form-control-sm'
                                            type="text"
                                            style={{ width: "250px", display: "block", float: "left", marginBottom: "6px", border: "1px solid #C2C1C1" }}
                                            placeholder="Search Here"
                                            value={searchTerm}
                                            onChange={(event) => { setFilteredData([]); setSearchTerm(event.target.value) }}
                                        />
                                    </div>
                                </div>
                                <form onSubmit={saveData}>
                                    <div className='table-responsive col-sm-6 offset-sm-1' style={{ maxHeight: "330px" }} >
                                        <table className="table table-bordered">
                                            <thead className="" style={{ position: "sticky", zIndex: "1", overflow: "hidden", top: "0", backgroundColor: "#EAECEE", borderBottom: "2px solid #464de4" }}>
                                                <tr>
                                                    {FormTableHeading.map((item, index) => {
                                                        return (
                                                            <th style={{ fontSize: "14px", fontWeight: "600" }} scope="col" key={index}>{item.label}</th>
                                                        )
                                                    })}
                                                </tr>
                                            </thead>
                                            <tbody style={{ borderTop: "2px solid #464de4", borderBottom: "2px solid #464de4" }}>
                                                {filteredData.map((item) => {
                                                    return (
                                                        <tr key={item.id} className="table-sm">
                                                            <td style={{ fontSize: "15px", fontWeight: "500", width: "150px" }}>{(authUser.branchMedium == 1) ? item.classNameMr : item.classNameEn}</td>
                                                            <td style={{ fontSize: "15px", fontWeight: "500", width: "150px" }}>{(authUser.branchMedium == 1) ? item.divisionNameMr : item.divisionNameEn}</td>
                                                            <td style={{ fontSize: "15px", fontWeight: "500", width: "150px" }}>
                                                                <Checkbox sx={{ '& .MuiSvgIcon-root': { fontSize: 30 } }} defaultChecked={(item.isAllocated == 1) ? 1 : 0} onChange={e => HandleDivClassSelectCheckbox(e, item.classId, item.divisionId, item.isAllocated)} name="checkFlag"
                                                                    disabled={selectedId && selectedId != item.isAllocated && selectedId !== item.divisionId}
                                                                />

                                                            </td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>
                                    </div>

                                    <div className="col-sm-12 mt-3">
                                        <div className="offset-sm-1">
                                            <button type="submit" className={saveBtn}>Save</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                    }


                </div>
                <ToastContainer position="top-right" theme="colored" />
            </>
        )
    }

}

export default ClassTeacherAllocation