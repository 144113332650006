import React, { useState } from 'react'
import { ToastContainer, toast } from 'react-toastify';

const RollNoAssignTextBox = ({ initialText, checkData, rCode, onChange }) => {

    const [isEdit, setEdit] = useState(false);
    const [text, setText] = useState(initialText);
    const [data, setData] = useState(checkData);

    const checkRollNo = (rno) => {
        if (rno == 0) {
            setEdit(false);
            onChange(text);
        }
        else {
            const rnoNew = data.find((item, ind) => {
                if (item.rollNo == rno && item.regCode !== rCode) {
                    return item;
                }
            })

            if (!rnoNew) {
                setEdit(false); onChange(text);
            }
            else {
                setText(0);
                onChange(0);
                setEdit(false);
                toast.warn("Roll No " + rno + " already exists.")
            }
            // console.log(rnoNew);
        }
    }

    //return <input className='form-control form-control-sm' style={{width:"80px"}} type='number' onBlur={()=>onChange(text)} onChange={(e) => setText((e.target.value != "")?e.target.value : "0")} value={text} />

    return (
        <>
            <td style={{ width: "120px", height: "70px" }}>
                <input
                    type='number'
                    className='form-control form-control-sm'
                    onWheel={(e) => e.target.blur()}
                    //style={{ width: "80px", fontSize:"16px", fontWeight:"600" }}
                    style={(isEdit === true) ? { width: "80px", fontSize: "16px", fontWeight: "600", } : { width: "80px", fontSize: "16px", fontWeight: "600", border: "0px" }}
                    onFocus={() => setEdit(true)}
                    onBlur={() => {
                        if (text == "" || text == null) {
                            setText(0);
                            checkRollNo(0)
                        }
                        else {
                            checkRollNo(text);
                        }
                    }}
                    //onBlur={() => { checkRollNo(text) }}
                    //onBlur={() => { setEdit(false); onChange(text) }}                    
                    //onChange={(e) => setText((e.target.value != "") ? e.target.value : "0")}
                    onChange={(e) => setText(e.target.value)}
                    onKeyPress={(e) => (e.key == "Enter") ? onChange(text) : ''}
                    value={text}
                    min="0"
                />
            </td>

        </>
    );

}

export default RollNoAssignTextBox