import React, { useState, useEffect } from 'react';
import TitleLabel from "../../CommonComponent/TitleLabel";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from '@mui/material/TextField';
import { useSelector } from "react-redux";
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import Loader from '../../CommonComponent/Loader';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Paper from "@mui/material/Paper";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { getExportedReportAPIURL } from '../../CommonServices/APIURL';
import {
    classWithDivisionURL, PrintAllAndFinalSubjectMarkSheet, printAllAndTermWiseSubMarkSheet,
    printAllFinalAndAboveEightSubMarkSheet, printAllAboveEightAndTermWiseSubMarkSheet, getExamData,
    printTermAndExamWiseResultSheetReport, getExamListForResultSheet, getLastProcessResultUpdatedDate,
    processResultSheetAPIURL,
    printFinalMarkSheetAllSubjectJrCollegeReport
} from '../Services/ExamReportAPIURL';
import subjectMarkTermData from '../../CommonComponent/LocalJSON/SubjectMarkTermData';
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles(theme => ({
    menuPaper: {
        maxHeight: 200,
        "& ul": {
            backgroundColor: "#F4ECF7",
            //maxHeight: 160,
        },
        "& li": {
            // backgroundColor: "#F4ECF7", fontWeight: "550", fontSize: "14px" 
            fontSize: "14px",
            fontWeight: "550"
        },
    }
}));

const ResultSheet = () => {
    const classes = useStyles();
    const titleId = "Result Sheet";
    const authUser = useSelector((state) => state.user.value);

    // loader on/off
    const [loaderOption, setLoaderOption] = useState(false);

    //const [reportOptionId, setReportOptionId] = useState(1);
    const [id, setId] = useState();
    const [classDivData, setClassDivData] = useState([]);
    const [classDivObj, setClassDivObj] = useState(null);
    const [termObj, setTermObj] = useState('');
    const [printBlank, setPrintBlank] = useState(0);
    const [reportOptionId, setReportOptionId] = useState(1)
    const [examData, setExamData] = useState([]);
    const [examObj, setExamObj] = useState(null);
    const [pageSize, setPageSize] = useState(null);
    const [updatedDate, setUpdatedDate] = useState("");
    const [govtRule, setGovtRule] = useState(0);

    const HandleGovtRuleCheckbox = (e) => {
        setGovtRule(e.target.checked);
        if (e.target.checked == true) {
          setGovtRule(1);
        }
        else {
          setGovtRule(0);
        }
      }
    

    const HandlePrintBlankCheckbox = (e) => {
        setPrintBlank(e.target.checked);
        if (e.target.checked == true) {
            setPrintBlank(1);
        }
        else {
            setPrintBlank(0);
        }
    }

    useEffect(() => {
        getData();
    }, [authUser])

    //process result sheet
    const processResultSheet = async () => {
        setLoaderOption(true);
        // console.log(`${processResultSheetAPIURL}?classId=${classDivObj.classId}&divId=${classDivObj.divisionId}&sessionYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&termId=${termObj}&branchMedium=${authUser.branchMedium}`)
        await axios.post(`${processResultSheetAPIURL}?classId=${classDivObj.classId}&divId=${classDivObj.divisionId}&sessionYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&termId=${termObj}&branchMedium=${authUser.branchMedium}`)
            .then(res => {
                if (res.data == "SAVED") {
                    getExamListTermWise(termObj);
                    setLoaderOption(false);
                    toast.success("Result processing done successfully.")
                }
            }).catch(error => {
                console.log(error);
                setLoaderOption(false);
                toast.error("Something went wrong, please check.")
            })
    }

    //class-division list & exam list
    const getData = async () => {
        if (authUser.deptId != null && authUser.branchId != null) {
            await axios(`${classWithDivisionURL}?sid=${authUser.deptId}&bid=${authUser.branchId}`)
                .then((response) => {
                    setClassDivData(response.data);

                }).catch((error) => {
                    console.log(error);
                })
        }
    }

    //get term wise exam and last updated date
    const getExamListTermWise = async (tId) => {
        setExamData([]);
        if (classDivObj != null) {
            //console.log(`${getExamListForResultSheet}?classId=${classDivObj.classId}&divId=${classDivObj.divisionId}&acYear=${authUser.sessionYear}&sid=${authUser.deptId}&termId=${tId}&branchMedium=${authUser.branchMedium}`)
            await axios(`${getExamListForResultSheet}?classId=${classDivObj.classId}&divId=${classDivObj.divisionId}&acYear=${authUser.sessionYear}&sid=${authUser.deptId}&termId=${tId}&branchMedium=${authUser.branchMedium}`)
                .then((response) => {
                    setExamData(response.data);

                }).catch((error) => {
                    console.log(error);
                })

            // console.log(`${getLastProcessResultUpdatedDate}?classId=${classDivObj.classId}&divId=${classDivObj.divisionId}&sid=${authUser.deptId}&bid=${authUser.branchId}&termId=${tId}&sessionYear=${authUser.sessionYear}`)
            // await axios.get(`${getLastProcessResultUpdatedDate}?classId=${classDivObj.classId}&divId=${classDivObj.divisionId}&sid=${authUser.deptId}&bid=${authUser.branchId}&termId=${tId}&sessionYear=${authUser.sessionYear}`)
            //     .then(res => {
            //         if (res.data == "" || res.data == null) {
            //             setUpdatedDate("Data Not Updated.");
            //         }
            //         else {
            //             setUpdatedDate(res.data);
            //         }

            //     })
        }
    }

    //All subjects & Term wise --
    const allAndTermWiseResultSheetPrint = async () => {
        setLoaderOption(true);
        // console.log(`${printAllAndTermWiseSubMarkSheet}?langId=${authUser.branchMedium}&classId=${classDivObj.classId}&divId=${classDivObj.divisionId}&termId=${termObj}&acYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&printBlank=${printBlank}&branchMedium=${authUser.branchMedium}&pageSize=${pageSize}&govRule=${govtRule}`)
        await axios.get(`${printAllAndTermWiseSubMarkSheet}?langId=${authUser.branchMedium}&classId=${classDivObj.classId}&divId=${classDivObj.divisionId}&termId=${termObj}&acYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&printBlank=${printBlank}&branchMedium=${authUser.branchMedium}&pageSize=${pageSize}&govRule=${govtRule}`)
            .then((response) => {

                if (response.data === "NOTFOUND") {
                    setLoaderOption(false);
                    toast.warn("Data Not Found.")
                }
                else if (response.data === "Subject Teacher Not Assigned") {
                    setLoaderOption(false);
                    toast.warn("Subject Teacher not found for this class-division.")
                }
                else if (response.data === "Class Teacher Not Assigned") {
                    setLoaderOption(false);
                    toast.warn("Class Teacher not found for this class-division.")
                }
                else if (response.data != null) {
                    // console.log(response.data)
                    window.open(`${getExportedReportAPIURL}${response.data}`);
                    setLoaderOption(false);
                }
            })
            .catch(error => {
                setLoaderOption(false);
                toast.error("Error Occurred, Try after sometime.");
                console.log("Subject MarkSheet failed: " + error)
            })
    }

    //All subjects,above 8 class-div & Term wise --
    const allAboveEightAndTermResultSheetPrint = async () => {
        setLoaderOption(true);
        // console.log(`${printAllAboveEightAndTermWiseSubMarkSheet}?langId=${authUser.branchMedium}&classId=${classDivObj.classId}&divId=${classDivObj.divisionId}&termId=${termObj}&acYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&printBlank=${printBlank}&branchMedium=${authUser.branchMedium}&pageSize=${pageSize}`)
        await axios.get(`${printAllAboveEightAndTermWiseSubMarkSheet}?langId=${authUser.branchMedium}&classId=${classDivObj.classId}&divId=${classDivObj.divisionId}&termId=${termObj}&acYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&printBlank=${printBlank}&branchMedium=${authUser.branchMedium}&pageSize=${pageSize}`)
            .then((response) => {

                if (response.data === "NOTFOUND") {
                    setLoaderOption(false);
                    toast.warn("Data Not Found.")
                }
                else if (response.data === "Subject Teacher Not Assigned") {
                    setLoaderOption(false);
                    toast.warn("Subject Teacher not found for this class-division.")
                }
                else if (response.data === "Class Teacher Not Assigned") {
                    setLoaderOption(false);
                    toast.warn("Class Teacher not found for this class-division.")
                }
                else if (response.data != null) {
                    // console.log(response.data)
                    window.open(`${getExportedReportAPIURL}${response.data}`);
                    setLoaderOption(false);
                }
            })
            .catch(error => {
                setLoaderOption(false);
                toast.error("Error Occurred, Try after sometime.");
                console.log("Subject MarkSheet failed: " + error)
            })
    }

    //All Subjects & Final term --
    const allAndFinalResultSheetPrint = async () => {
        setLoaderOption(true);
        // console.log(`${PrintAllAndFinalSubjectMarkSheet}?langId=${authUser.branchMedium}&classId=${classDivObj.classId}&divId=${classDivObj.divisionId}&acYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&printBlank=${printBlank}&branchMedium=${authUser.branchMedium}&pageSize=${pageSize}&govRule=${govtRule}`)
        await axios.get(`${PrintAllAndFinalSubjectMarkSheet}?langId=${authUser.branchMedium}&classId=${classDivObj.classId}&divId=${classDivObj.divisionId}&acYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&printBlank=${printBlank}&branchMedium=${authUser.branchMedium}&pageSize=${pageSize}&govRule=${govtRule}`)
            .then((response) => {

                if (response.data === "NOTFOUND") {
                    setLoaderOption(false);
                    toast.warn("Data Not Found.")
                }
                else if (response.data === "Subject Teacher Not Assigned") {
                    setLoaderOption(false);
                    toast.warn("Subject Teacher not found for this class-division.")
                }
                else if (response.data === "Class Teacher Not Assigned") {
                    setLoaderOption(false);
                    toast.warn("Class Teacher not found for this class-division.")
                }
                else if (response.data != null) {
                    // console.log(response.data)
                    window.open(`${getExportedReportAPIURL}${response.data}`);
                    setLoaderOption(false);
                }
            })
            .catch(error => {
                setLoaderOption(false);
                toast.error("Error Occurred, Try after sometime.");
                console.log("Subject MarkSheet failed: " + error)
            })
    }

    //All subjects,above 8 class-div & Final term --
    const allFinalAndAboveEightResultSheetPrint = async () => {
        setLoaderOption(true);
        // console.log(`${printAllFinalAndAboveEightSubMarkSheet}?langId=${authUser.branchMedium}&classId=${classDivObj.classId}&divId=${classDivObj.divisionId}&acYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&printBlank=${printBlank}&branchMedium=${authUser.branchMedium}&pageSize=${pageSize}`)
        await axios.get(`${printAllFinalAndAboveEightSubMarkSheet}?langId=${authUser.branchMedium}&classId=${classDivObj.classId}&divId=${classDivObj.divisionId}&acYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&printBlank=${printBlank}&branchMedium=${authUser.branchMedium}&pageSize=${pageSize}`)
            .then((response) => {

                if (response.data === "NOTFOUND") {
                    setLoaderOption(false);
                    toast.warn("Data Not Found.")
                }
                else if (response.data === "Subject Teacher Not Assigned") {
                    setLoaderOption(false);
                    toast.warn("Subject Teacher not found for this class-division.")
                }
                else if (response.data === "Class Teacher Not Assigned") {
                    setLoaderOption(false);
                    toast.warn("Class Teacher not found for this class-division.")
                }
                else if (response.data != null) {
                    // console.log(response.data)
                    window.open(`${getExportedReportAPIURL}${response.data}`);
                    setLoaderOption(false);
                }
            })
            .catch(error => {
                setLoaderOption(false);
                toast.error("Error Occurred, Try after sometime.");
                console.log("Subject MarkSheet failed: " + error)
            })
    }

    //term and exam wise report --
    const allTermAndExamWiseResultSheetPrint = async () => {
        setLoaderOption(true);
        // console.log(`${printTermAndExamWiseResultSheetReport}?langId=${authUser.branchMedium}&classId=${classDivObj.classId}&divId=${classDivObj.divisionId}&termId=${termObj}&acYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&printBlank=${printBlank}&examId=${examObj.id}&branchMedium=${authUser.branchMedium}&pageSize=${pageSize}`)
        await axios.get(`${printTermAndExamWiseResultSheetReport}?langId=${authUser.branchMedium}&classId=${classDivObj.classId}&divId=${classDivObj.divisionId}&termId=${termObj}&acYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&printBlank=${printBlank}&examId=${examObj.id}&branchMedium=${authUser.branchMedium}&pageSize=${pageSize}`)
            .then((response) => {

                if (response.data === "NOTFOUND") {
                    setLoaderOption(false);
                    toast.warn("Data Not Found.")
                }
                else if (response.data === "Subject Teacher Not Assigned") {
                    setLoaderOption(false);
                    toast.warn("Subject Teacher not found for this class-division.")
                }
                else if (response.data === "Class Teacher Not Assigned") {
                    setLoaderOption(false);
                    toast.warn("Class Teacher not found for this class-division.")
                }
                else if (response.data != null) {
                    // console.log(response.data)
                    window.open(`${getExportedReportAPIURL}${response.data}`);
                    setLoaderOption(false);
                }
            })
            .catch(error => {
                setLoaderOption(false);
                toast.error("Error Occurred, Try after sometime.");
                console.log("Subject MarkSheet failed: " + error)
            })
    }

    //for All subjects option for term wise option
    const getReportForallsubjectsTermWise = () => {
        if (classDivObj.classId === 9 || classDivObj.classId === 10) {
            allAboveEightAndTermResultSheetPrint();
        }
        else {
            allAndTermWiseResultSheetPrint();
        }
    }

    //All Subject,jr college & Final
    const jrCollegeAllSubjectFinalMarkSheetPrint = async () => {
        setLoaderOption(true);
        // console.log(`${printFinalMarkSheetAllSubjectJrCollegeReport}?langId=${authUser.branchMedium}&classId=${classDivObj.classId}&divId=${classDivObj.divisionId}&acYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&printBlank=${printBlank}&branchMedium=${authUser.branchMedium}&pageSize=${pageSize}`)
        await axios.get(`${printFinalMarkSheetAllSubjectJrCollegeReport}?langId=${authUser.branchMedium}&classId=${classDivObj.classId}&divId=${classDivObj.divisionId}&acYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&printBlank=${printBlank}&branchMedium=${authUser.branchMedium}&pageSize=${pageSize}`)
            .then((response) => {

                if (response.data === "NOTFOUND") {
                    setLoaderOption(false);
                    toast.warn("Data Not Found.")
                }
                else if (response.data === "Subject Teacher Not Assigned") {
                    setLoaderOption(false);
                    toast.warn("Subject Teacher not found for this class-division.")
                }
                else if (response.data === "Class Teacher Not Assigned") {
                    setLoaderOption(false);
                    toast.warn("Class Teacher not found for this class-division.")
                }
                else if (response.data != null) {
                    // console.log(response.data)
                    window.open(`${getExportedReportAPIURL}${response.data}`);
                    setLoaderOption(false);
                }
            })
            .catch(error => {
                setLoaderOption(false);
                toast.error("Error Occurred, Try after sometime.");
                console.log("Subject MarkSheet failed: " + error)
            })
    }

    //for All subjects option for final term
    const getFinalTermWiseResultSheet = () => {
        if (classDivObj.classId === 9 || classDivObj.classId === 10) {
            allFinalAndAboveEightResultSheetPrint();
        }
        else {
            if (classDivObj != null) {
                if (authUser.deptId == 4 || (authUser.deptId != 1 && classDivObj.classId > 10)) {
                    jrCollegeAllSubjectFinalMarkSheetPrint();
                }
                else {
                    allAndFinalResultSheetPrint();
                }
            }

        }
    }

    //for All subjects option exam wise
    const getExamWiseResultSheet = () => {
        if (termObj == 3) {
            getFinalTermWiseResultSheet();
        }
        else {
            allTermAndExamWiseResultSheetPrint();
        }
    }

    //call reports onSubmit
    const getResultSheetReport = e => {
        e.preventDefault();
        if (termObj == 3 && reportOptionId == 1) {
            getFinalTermWiseResultSheet();
        }
        else if (reportOptionId == 2) {
            getExamWiseResultSheet();
        }
        else {
            getReportForallsubjectsTermWise();
        }
    }

    return (
        <>
            {
                (loaderOption == true)
                    ?
                    <Loader />
                    :
                    <>
                        <TitleLabel titleId={titleId} />

                        <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: "350px" }}>
                            {/* Form Data start */}
                            <form onSubmit={getResultSheetReport}>
                                <div className="form-data">
                                    <input type="hidden" value={id} onChange={(e) => setId(e.target.value)} name="" />

                                    <div className="row my-1">
                                        <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Option :</label>
                                        <div className='col-sm-3'>
                                            <FormControl fullWidth margin='dense'>
                                                <InputLabel size="small" id='demo-simple-select-label' style={{ fontSize: '14px' }}>Option</InputLabel>
                                                <Select
                                                    MenuProps={{ classes: { paper: classes.menuPaper } }}
                                                    size='small'
                                                    fullWidth
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={reportOptionId}
                                                    label="Option"
                                                    onChange={(e) => {
                                                        setReportOptionId(e.target.value);
                                                        setTermObj('');
                                                        setExamObj(null);
                                                        setClassDivObj(null);
                                                        setPrintBlank(0);
                                                        setExamData([]);
                                                    }}
                                                >
                                                    <MenuItem value={1}>Term wise</MenuItem>
                                                    <MenuItem value={2}>Exam wise</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </div>
                                    </div>

                                    <div className="row my-2">
                                        <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Class - Division :</label>
                                        <div className='col-sm-3'>
                                            <Autocomplete
                                                id="combo-box-demo"
                                                PaperComponent={({ children }) => (
                                                    <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                                )}
                                                options={classDivData}
                                                style={{ fontSize: '14px' }}
                                                getOptionLabel={option => (authUser.branchMedium == 1) ? option.classNameMr + " " + option.divisionNameMr : option.classNameEn + " " + option.divisionNameEn}
                                                size="small"
                                                value={classDivObj}
                                                onChange={(event, newValue) => {
                                                    setClassDivObj(newValue);
                                                    setTermObj('');
                                                    setExamObj(null);
                                                    setPrintBlank(0);
                                                    setExamData([]);
                                                }}
                                                renderInput={params => (
                                                    <TextField {...params} margin="dense" label="Select Class-Division" InputLabelProps={{ style: { fontSize: 14 } }} variant="outlined" required />
                                                )}
                                            />
                                        </div>
                                    </div>

                                    {(() => {
                                        if (classDivObj != null) {
                                            return (
                                                <div className="row my-2">
                                                    <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Page Size :</label>
                                                    <div className='col-sm-3'>
                                                        <FormControl fullWidth margin='dense'>
                                                            <InputLabel size="small" id='demo-simple-select-label' style={{ fontSize: '14px' }}>Page Size</InputLabel>
                                                            <Select
                                                                MenuProps={{ classes: { paper: classes.select } }}
                                                                size='small'
                                                                fullWidth
                                                                labelId="demo-simple-select-label"
                                                                id="demo-simple-select"
                                                                value={pageSize}
                                                                label="Page Size"
                                                                onChange={(e) => {
                                                                    setPageSize(e.target.value);
                                                                }}
                                                            >
                                                                <MenuItem value={1} hidden={(authUser.deptId == 1 || classDivObj.classId <= 8) ? true : false}>Portrait</MenuItem>
                                                                <MenuItem value={2}>Landscape</MenuItem>
                                                                <MenuItem value={3} hidden={(classDivObj.classId > 8) ? true : false}>Legal</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    })()}

                                    <div className="row my-2">
                                        <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Term :</label>
                                        <div className='col-sm-3'>
                                            <FormControl fullWidth margin='dense'>
                                                <InputLabel size="small" id='demo-simple-select-label' style={{ fontSize: '14px' }}>Term</InputLabel>
                                                <Select
                                                    MenuProps={{ classes: { paper: classes.menuPaper } }}
                                                    size='small'
                                                    fullWidth
                                                    labelId="demo-simple-select-label"

                                                    id="demo-simple-select"
                                                    value={termObj}
                                                    label="Term"
                                                    onChange={(e) => {
                                                        setTermObj(e.target.value);
                                                        setExamObj(null);
                                                        getExamListTermWise(e.target.value);
                                                    }}
                                                >
                                                    {subjectMarkTermData.map((item, index) => {
                                                        return (
                                                            <MenuItem value={item.id} key={index}>{(authUser.branchMedium == 1) ? item.nameMr : item.nameEn}</MenuItem>
                                                        )
                                                    })}
                                                </Select>
                                            </FormControl>
                                        </div>

                                        <div className='col-sm-3 mt-2' hidden={(reportOptionId == 2) ? true : false}>
                                            <FormControlLabel control={<Checkbox checked={printBlank} onChange={HandlePrintBlankCheckbox} />} sx={{ '.MuiFormControlLabel-label': { fontWeight: 550, fontSize: "15px", marginTop: "2px" } }} label="Print Blank" />
                                        </div>

                                        {(() => {
                                            if (reportOptionId == 2) {
                                                return (
                                                    <>
                                                        <label className="col-sm-1" hidden={(termObj == 3) ? true : false} style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Exam :</label>
                                                        <div className='col-sm-3' hidden={(termObj == 3) ? true : false}>
                                                            <Autocomplete
                                                                id="combo-box-demo"
                                                                PaperComponent={({ children }) => (
                                                                    <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                                                )}
                                                                options={examData}
                                                                style={{ fontSize: '14px' }}
                                                                getOptionLabel={option => (authUser.branchMedium == 1) ? option.nameMr : option.nameEn}
                                                                size="small"
                                                                value={examObj}
                                                                onChange={(event, newValue) => {
                                                                    setExamObj(newValue);
                                                                }}
                                                                renderInput={params => (
                                                                    <TextField {...params} margin="dense" label="Select Exam" InputLabelProps={{ style: { fontSize: 14 } }} variant="outlined"
                                                                        required={(reportOptionId == 2 && termObj != 3) ? true : false} />
                                                                )}
                                                            />
                                                        </div>

                                                        <div className='col-sm-3 mt-2'>
                                                            <FormControlLabel control={<Checkbox checked={printBlank} onChange={HandlePrintBlankCheckbox} />} sx={{ '.MuiFormControlLabel-label': { fontWeight: 550, fontSize: "15px", marginTop: "2px" } }} label="Print Blank" />
                                                        </div>
                                                    </>
                                                )
                                            }
                                        })()}
                                    </div>

                                    <div className="row mt-2">
                                        <div className="col-sm-12 offset-sm-2">
                                            {/* {(() => {
                                                if (classDivObj != null) {
                                                    if (classDivObj.classId == 5 || classDivObj.classId == 8) {
                                                        return ( */}
                                                            <FormControlLabel control={<Checkbox checked={govtRule} onChange={HandleGovtRuleCheckbox} />} sx={{ '.MuiFormControlLabel-label': { fontWeight: 550, fontSize: "15px", marginTop: "2px" } }} label="Govt. Rule" />
                                                        {/* )
                                                    }
                                                }
                                            })()} */}
                                        </div>
                                    </div>



                                    {/* {(() => {
                                        if (termObj == 3 && reportOptionId == 1) {
                                            return (
                                                <>
                                                    <div className="row mt-2">
                                                        <div className="col-sm-4 offset-sm-2">
                                                            <button type="button" onClick={() => getFinalTermWiseResultSheet()} disabled={(classDivObj == null || classDivObj == "" || termObj == null || termObj == "") ? true : false} className="btn btn-primary btn-sm" >Show</button>
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                        }
                                        else if (reportOptionId == 2) {
                                            return (
                                                <>
                                                    <div className="row mt-3">
                                                        <div className="col-sm-4 offset-sm-2">
                                                            <button type="button" onClick={() => getExamWiseResultSheet()} disabled={((termObj == 3) ? ((classDivObj == null || classDivObj == "" || termObj == null || termObj == "") ? true : false) : (classDivObj == null || classDivObj == "" || termObj == null || termObj == "" || examObj == null || examObj == '') ? true : false)} className="btn btn-primary btn-sm" >Show</button>
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                        }
                                        else {
                                            return (
                                                <>
                                                    <div className="row mt-2" hidden={(termObj == 3) ? true : false}>
                                                        <div className="col-sm-4 offset-sm-2">
                                                            <button type="button" onClick={() => getReportForallsubjectsTermWise()} disabled={(reportOptionId == 1) ? ((classDivObj == null || classDivObj == "" || termObj == null || termObj == "") ? true : false) : ((classDivObj == null || classDivObj == "" || termObj == null || termObj == "" || examObj == null || examObj == '') ? true : false)} className="btn btn-primary btn-sm" >Show</button>
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                        }
                                    })()} */}

                                    <div className="row mt-3">
                                        <div className="col-sm-4 offset-sm-2">
                                            <button type="submit" className="btn btn-primary btn-sm">Show</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </>
            }
            {/* <ToastContainer position="top-right" theme="colored" /> */}
        </>
    )
}

export default ResultSheet