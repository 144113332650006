import React, { useState, useEffect } from 'react'
import TitleLabel from "../../CommonComponent/TitleLabel";
import AddIcon from '@mui/icons-material/Add';
import { useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import makeStyles from "@mui/styles/makeStyles";
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash, faPlusCircle, faPrint, faRegular, faClone } from '@fortawesome/free-solid-svg-icons';
import Tooltip from '@mui/material/Tooltip';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import TablePagination from '@mui/material/TablePagination'
import { GetStudentLcData, LcPrintURL, GetStudentLcDataDaud, deleteStudentLc, getDuplicateLcData, saveAndPrintDuplicateLc } from '../../CommonServices/LeavingCertificateAPIURL';
import { getExportedReportAPIURL } from '../../CommonServices/APIURL';
import Loader from '../../CommonComponent/Loader';
import DeleteLcModal from './DeleteLcModal';
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import DuplicateLcModal from './DuplicateLcModal';

const useStyles = makeStyles({
  caption: {
    padding: 0,
    fontWeight: 600,
    fontSize: "12px"
  },
  toolbar: {
    fontSize: "12px",
    fontWeight: 600
  },
  selectLabel: {
    fontSize: "12px",
    fontWeight: 500,
    margin: "-10px",
    padding: "0"
  },
  select: {
    fontSize: "12px",
    margin: "0",
    padding: "0"
  },
  displayedRows: {
    fontSize: "12px",
    margin: "-22px",
    padding: "0"
  },
  menuItem: {
    fontSize: "12px",
    fontWeight: 500,
  }
});

const LeavingCertificateTable = () => {
  const titleId = "Leaving Certificate Table";
  let { toastFlag } = useParams();
  const classes = useStyles();
  const navigate = useNavigate();
  const authUser = useSelector((state) => state.user.value);

  const TableHeading = [{ label: 'LC No', key: 'lcNo' },
  { label: `LC Date`, key: 'lcDate' },
  { label: `Student Name`, key: (authUser.branchMedium == 1) ? 'studentNameMr' : 'studentNameEn' },
  { label: `Class`, key: (authUser.branchMedium == 1) ? 'classNameMr' : 'classNameEn' },
  { label: 'Edit', value: 'edit', isAction: true },
  { label: 'Duplicate LC', value: 'duplicateLc', isAction: true },
  { label: 'Print', value: 'print', isAction: true }];

  const TableHeading2 = [{ label: 'LC No', key: 'lcNo' },
  { label: `LC Date`, key: 'lcDate' },
  { label: `Student Name`, key: (authUser.branchMedium == 1) ? 'studentNameMr' : 'studentNameEn' },
  { label: `Class`, key: (authUser.branchMedium == 1) ? 'classNameMr' : 'classNameEn' },
  { label: 'Edit', value: 'edit', isAction: true },
  { label: 'Duplicate LC', value: 'duplicateLc', isAction: true },
  { label: 'Print', value: 'print', isAction: true },
  { label: 'Delete', value: 'delete', isAction: true }];

  const [loaderOption, setLoaderOption] = useState(false);

  //Note: This below states are declared for Save & Update(Disabled/unDisabled)

  const saveBtnStyle = "btn btn-primary btn-sm";
  const updateBtnStyle = "btn btn-primary btn-sm";
  const displayNoneBtnStyle = "d-none";
  const [saveBtn, setSaveBtn] = useState(saveBtnStyle);
  const [updateBtn, setUpdateBtn] = useState(displayNoneBtnStyle);

  const [isAscending, setAscending] = useState(false);
  const [sortedBy, setSortedBy] = useState(null);
  const [data, setData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("")
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [waterMark, setWaterMark] = useState(0);
  const [filteredData, setFilteredData] = useState([]);

  //Duplicate Lc Modal States
  const [oldLcNo, setOldLcNo] = useState('');
  const [academicYear, setAcademicYear] = useState('');
  const [dupLcDate, setDupLcDate] = useState(new Date());
  const [languageId, setLanguageId] = useState(1);



  const HandleWaterMarkCheckbox = (e) => {
    setWaterMark(e.target.checked);
    if (e.target.checked == true) {
      setWaterMark(1);
    }
    else {
      setWaterMark(0);
    }
  }

  //console.log("filteredData : " + JSON.stringify(filteredData))
  //console.log("Data : " + JSON.stringify(data))

  const onPageChange = (event, nextPage) => {
    setPage(nextPage);
  }
  const onRowsPerPageChange = (e) => {
    setRowsPerPage(parseInt(e.target.value));
    setPage(0);
  }

  //Student Entry required data
  const [countData, setCountData] = useState(null);
  const [offset, setOffset] = useState(0);
  const [limits, setLimits] = useState(200);
  const [numCal, setNumCall] = useState(0);

  useEffect(() => {
    if (authUser.branchId != null && authUser.deptId != null) {
      getData();
    }
  }, [numCal, authUser])


  // const getData = async () => {
  //   //console.log(`${GetStudentLcData}?sid=${authUser.deptId}&bid=${authUser.branchId}`)
  //   setLoaderOption(true);
  //   if (authUser.deptId != null || authUser.branchId != null) {
  //     await axios(`${GetStudentLcData}?sid=${authUser.deptId}&bid=${authUser.branchId}`)
  //       .then(res => {
  //         setData(res.data);
  //         setFilteredData(res.data);
  //         setLoaderOption(false);
  //       })
  //   }
  // }
  const getData = async () => {

    if (offset === 0) {
      setLoaderOption(true);
    }
    // console.log(`${GetStudentLcData}?sid=${authUser.deptId}&bid=${authUser.branchId}&limit=${limits}&offset=${offset}`)
    await axios.get(`${GetStudentLcData}?sid=${authUser.deptId}&bid=${authUser.branchId}&limit=${limits}&offset=${offset}`)
      .then((res) => {
        if (res.data !== null) {
          const tempData = res.data;
          //console.log(tempData.length)
          setData(data.concat(tempData));
          setFilteredData(filteredData.concat(tempData));
          setLoaderOption(false);
          //console.log("Data : " + JSON.stringify(tempData))
          //console.log("____________________________________________")
          if (tempData.length === limits) {
            setOffset(offset + limits);
            setNumCall(numCal + 1);
            setLoaderOption(false);
            //console.log("Offset ===============" + offset);
          }
        }
      })
  }

  const printLcReport = async (LcNo, regCode) => {
    setLoaderOption(true);
    //console.log(`${LcPrintURL}?sid=${authUser.deptId}&bid=${authUser.branchId}&langId=${authUser.branchMedium}&lcNo=${LcNo}&waterMark=${waterMark}`)
    await axios(`${LcPrintURL}?sid=${authUser.deptId}&bid=${authUser.branchId}&langId=${authUser.branchMedium}&lcNo=${LcNo}&waterMark=${waterMark}&regNo=${regCode}`)
      .then(res => {
        if (res.data === "NOTFOUND") {
          setLoaderOption(false);
          toast.warn("LC not found.")
        }
        else if (res.data != null) {
          setLoaderOption(false);
          window.open(`${getExportedReportAPIURL}${res.data}`)
        }
      })
      .catch((error) => {
        console.log("error name: " + error);
      });
  }


  const onSort = (key, isAction) => {
    if (!isAction) {
      setSortedBy(key)
      if (isAscending) setFilteredData([...data.sort((a, b) => a[key].toString().toLowerCase() < b[key].toString().toLowerCase() ? 1 : -1)])
      else setFilteredData([...data.sort((a, b) => a[key].toString().toLowerCase() > b[key].toString().toLowerCase() ? 1 : -1)])
      setAscending(!isAscending)
    }
  }

  useEffect(() => {
    setFilteredData(
      data.filter(
        (item) =>
          (item.lcNo || '').toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
          (item.lcDate || '').toLowerCase().includes(searchTerm.toLowerCase()) ||
          (item.regCode || '').toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
          (item.studentNameMr || '').toLowerCase().includes(searchTerm.toLowerCase()) ||
          (item.studentNameEn || '').toLowerCase().includes(searchTerm.toLowerCase()) ||
          (item.classNameMr || '').toLowerCase().includes(searchTerm.toLowerCase()) ||
          (item.classNameEn || '').toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
  }, [searchTerm]);


  const UpdateData = (item, regCode, EditFlag) => {
    navigate(`/Home/ad207F/${item}/${regCode}/${EditFlag}`);
  }

  const [deleteId, setDeleteId] = useState("");

  const deleteLcModal = async (id) => {
    setData([]);
    setFilteredData([]);
    const deleteObj = {
      createdBy: authUser.id,
      userIp: authUser.headers.userIp,
      userLat: authUser.headers.userLat,
      userLong: authUser.headers.userLong,
      deviceSign: authUser.headers.deviceSign,
      deviceType: authUser.headers.deviceType,
      deviceOS: authUser.headers.deviceOS,
      browserName: authUser.headers.browserName,
      browserVersion: authUser.headers.browserVersion
    };

    await axios.delete(`${deleteStudentLc}?sid=${authUser.deptId}&bid=${authUser.branchId}&regNo=${id}`, {
      data: deleteObj // ✅ Wrap deleteObj inside a `data` key
    })
      .then((response) => {
        if (response.data === "DELETED") {
          let updatedData = data.filter(e => e.regCode !== id);
          setData(updatedData);
          setFilteredData(updatedData);
          getData();
          toast.success("Deleted successfully");
        }
      })
      .catch(error => {
        console.error("Error deleting record:", error);
        toast.error("Failed to delete");
      });

  }

  //get data for duplicate LC
  const getDuplicateLcReportData = async (lcNum, regCode) => {
    setWaterMark(0);
    // setLoaderOption(true);
    await axios.get(`${getDuplicateLcData}?lcNo=${lcNum}&sid=${authUser.deptId}&bid=${authUser.branchId}&regNo=${regCode}`)
      .then((res) => {
        setDupLcDate((res.data.duplicateLcDate == null) ? dupLcDate : res.data.duplicateLcDate);
        setOldLcNo((res.data.oldLcNo == null) ? res.data.lcNo : res.data.oldLcNo);
        setAcademicYear(res.data.academicYear);
        setLoaderOption(false);

      })
  }

  const [lcNum, setLcNum] = useState('');
  const [regNum, setRegNum] = useState('');

  //save and print duplicate LC
  const savePrintDuplicateLc = async (e) => {
    setLoaderOption(true);
    e.preventDefault();
    if (moment(dupLcDate).format("DD/MM/YYYY") !== "Invalid date") {
      const saveObj = {
        "lcNo": lcNum,
        "regNo": regNum,
        "oldLcNo": oldLcNo,
        "duplicateLcDate": moment(dupLcDate).format("DD/MM/YYYY"),
        "academicYear": academicYear,
        "sectionId": authUser.deptId,
        "branchId": authUser.branchId
      }
      //console.log(JSON.stringify(saveObj))
      await axios.post(`${saveAndPrintDuplicateLc}?langId=${languageId}&waterMark=${waterMark}`, saveObj)
        .then(res => {
          if (res.data !== null) {
            setLoaderOption(false);
            window.open(`${getExportedReportAPIURL}${res.data}`)

            // get modal
            const modal = document.getElementById('duplicateLcModal');

            // change state like in hidden modal
            modal.classList.remove('show');
            modal.setAttribute('aria-hidden', 'true');
            modal.setAttribute('style', 'display: none');

            // get modal backdrop
            const modalBackdrops = document.getElementsByClassName('modal-backdrop');

            // remove opened modal backdrop
            document.body.removeChild(modalBackdrops[0]);
          }
        })
        .catch((error) => {
          console.log("error name: " + error);
        });
      //toast.success("Valid")
    }
    else {
      setLoaderOption(false);
      toast.error("Improper date, please check.")
    }

  }

  return (
    <>
      {(loaderOption == true)
        ?
        <Loader />
        :
        <div>
          <TitleLabel titleId={titleId} />
          <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: '350px' }}>
            <div className=''>
              <button className='btn btn-primary btn-sm mb-2' style={{ float: 'left' }} onClick={() => navigate("/Home/ad207F/0/0/1")}>New LC</button>
            </div>

            <div className=''>
              <button className='btn btn-warning btn-sm mb-2 ml-2' style={{ float: 'left' }} onClick={() => navigate("/Home/ad207MF/")}>Manual LC</button>
            </div>

            {/* <div className=''>
              <button className='btn btn-sm mb-2 ml-2' hidden={(authUser.roleId == 1) ? false : true} style={{ float: 'left',backgroundColor: "#3FB837", color: "#fff" }} >Cancel LC</button>
            </div> */}

            <div className='row col-sm-4 mb-1' style={{ float: 'right' }}>
              <div>
                <input
                  className='form-control form-control-sm'
                  type="text"
                  style={{ width: "250px", display: "block", float: "right", marginBottom: "6px", border: "1px solid #C2C1C1" }}
                  placeholder="Search Here"
                  value={searchTerm}
                  onChange={(event) => { setSearchTerm(event.target.value); setPage(0); }}
                />
              </div>
            </div>

            <div className='table-responsive'>
              <table className="table table-bordered">
                <thead className="table-Default">
                  <tr>
                    {
                      (authUser.roleId == 1)
                        ?
                        TableHeading2.map((item, index) => {
                          return (
                            <th style={{ fontSize: "14px", fontWeight: "600", lineHeight: "1.5" }} onClick={() => onSort(item.key, item.isAction)} key={index} scope="col">{`${item.label} ${sortedBy === item.key ? isAscending ? '↑' : '↓' : ''}`}</th>
                          )
                        })
                        :
                        TableHeading.map((item, index) => {
                          return (
                            <th style={{ fontSize: "14px", fontWeight: "600", lineHeight: "1.5" }} onClick={() => onSort(item.key, item.isAction)} key={index} scope="col">{`${item.label} ${sortedBy === item.key ? isAscending ? '↑' : '↓' : ''}`}</th>
                          )
                        })
                    }

                  </tr>
                </thead>
                <tbody style={{ borderTop: "2px solid #464de4", borderBottom: "2px solid #464de4" }}>
                  {filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((item, index) => {
                      return (

                        <tr key={index}>

                          <td style={{ fontSize: "15px", fontWeight: "500" }}>{item.lcNo}</td>
                          <td style={{ fontSize: "15px", fontWeight: "500" }}>{item.lcDate}</td>
                          <td style={{ fontSize: "15px", fontWeight: "500", lineHeight: "1.4" }}>{(authUser.branchMedium == 1) ? item.regCode + " - " + item.studentNameMr : item.regCode + " - " + item.studentNameEn}</td>
                          <td style={{ fontSize: "15px", fontWeight: "500" }}>{(authUser.branchMedium == 1) ? item.classNameMr : item.classNameEn}</td>
                          <td>
                            <Tooltip title="Edit">
                              <span className='btn btn-primary btn-sm mx-1 p-1'
                                onClick={() => UpdateData(item.lcNo, item.regCode, 2)} data-bs-toggle="tooltip">
                                <FontAwesomeIcon icon={faEdit} />
                              </span>
                            </Tooltip>
                          </td>
                          <td style={{ width: "100px" }}>
                            <Tooltip title="Duplicate LC">
                              <span className='btn btn-sm mx-1 p-1' style={{ backgroundColor: "#D35400" }}
                                onClick={() => { getDuplicateLcReportData(item.lcNo, item.regCode); setRegNum(item.regCode); setLcNum(item.lcNo); }} data-bs-toggle="modal" data-bs-target="#duplicateLcModal">
                                <FontAwesomeIcon icon={faClone} style={{ color: "#FFFFFF" }} />
                              </span>
                            </Tooltip>
                          </td>
                          <td>
                            <Tooltip title="print">
                              <span className='btn btn-sm mx-1 p-1' style={{ backgroundColor: "#F1C40F", color: "white" }}
                                onClick={() => printLcReport(item.lcNo, item.regCode)} data-bs-toggle="tooltip">
                                <FontAwesomeIcon icon={faPrint} />
                              </span>
                            </Tooltip>
                          </td>

                          {(() => {
                            if (authUser.roleId === 1) {
                              return (
                                <td>
                                  <Tooltip title="Delete">
                                    <span className='btn btn-danger btn-sm mx-1 p-1'
                                      onClick={() => setDeleteId(item.regCode)} data-bs-toggle="modal" data-bs-target="#deleteLcModal">
                                      <FontAwesomeIcon icon={faTrash} />
                                    </span>
                                  </Tooltip>
                                </td>
                              )
                            }
                          })()}

                        </tr>

                      )
                    })}
                </tbody>
                <tfoot style={{ padding: "0px" }}>
                  <tr>
                    <TablePagination
                      style={{ padding: "0px" }}
                      rowsPerPageOptions={[10, 25, 50, 100]}
                      count={data.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onRowsPerPageChange={onRowsPerPageChange}
                      onPageChange={onPageChange}
                      labelRowsPerPage="Rows Per Page :"
                      classes={{ menuItem: classes.menuItem, displayedRows: classes.displayedRows, selectIcon: classes.selectIcon, toolbar: classes.toolbar, caption: classes.caption, selectLabel: classes.selectLabel, select: classes.select }}
                      backIconButtonProps={{ "aria-label": "Previous Page" }}
                      nextIconButtonProps={{ "aria-label": "Next Page" }} />
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
          <DeleteLcModal deleteId={deleteId} deleteLcModal={deleteLcModal} setData={setData} setFilteredData={setFilteredData} />

        </div>
      }
      <DuplicateLcModal
        oldLcNo={oldLcNo}
        setOldLcNo={setOldLcNo}
        academicYear={academicYear}
        setAcademicYear={setAcademicYear}
        dupLcDate={dupLcDate}
        setDupLcDate={setDupLcDate}
        languageId={languageId}
        setLanguageId={setLanguageId}
        HandleWaterMarkCheckbox={HandleWaterMarkCheckbox}
        waterMark={waterMark}
        setWaterMark={setWaterMark}
        savePrintDuplicateLc={savePrintDuplicateLc}
        loaderOption={loaderOption}
      />

      {/* <ToastContainer position="top-right" theme="colored" /> */}

    </>
  )


}

export default LeavingCertificateTable