import { DatePicker, LocalizationProvider } from '@mui/lab'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import { Autocomplete, Paper, TextField } from '@mui/material'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import Loader from '../../CommonComponent/Loader'
import TitleLabel from '../../CommonComponent/TitleLabel'
import { getLeaveMasterAPIURL } from '../Services/LeaveMasterAPIURL'
import EmployeeLeaveEntryTable from './EmployeeLeaveEntryTable'
import { getEmpAvailableDaysAPIURL, getEmployeeLeaveEntryAPIURL, saveEmployeeLeaveEntryAPIURL, updateEmployeeLeaveEntryAPIURL } from '../Services/EmployeeTransactionsAPIURL'
import moment from 'moment'
import { toast } from 'react-toastify'
import { EmployeeMasterBranchWiseGetData } from '../../CommonServices/EmployeeMasterAPIURL'

const EmployeeLeaveEntry = () => {
  const titleId = "Employee Leave Entry"
  const authUser = useSelector((state) => state.user.value);

  const TableHeading = [
    { label: 'ID', key: 'id' },
    { label: `Employee Name`, key: (authUser.branchMedium == 1) ? 'empNameMr' : 'empNameEn' },
    { label: `Leave Name`, key: 'leaveName' },
    { label: `Leave Qty.`, key: 'qty' },
    { label: `Date`, key: 'date' },
    { label: `Reason`, key: 'reason' },
    { label: 'Edit', value: 'edit', isAction: true },
    { label: 'Delete', value: 'delete', isAction: true }];

  //Note: This below states are declared for Save & Update(Disabled/unDisabled)
  const saveBtnStyle = "leaveEntrySaveBtn btn btn-primary btn-sm";
  const updateBtnStyle = "leaveEntryUpdateBtn btn btn-primary btn-sm";
  const displayNoneBtnStyle = "d-none";
  const [saveBtn, setSaveBtn] = useState(saveBtnStyle);
  const [updateBtn, setUpdateBtn] = useState(displayNoneBtnStyle);

  const [loaderOption, setLoaderOption] = useState(false);

  const [id, setId] = useState('');
  const [leaveTypeData, setLeaveTypeData] = useState([]);
  const [leaveTypeObj, setLeaveTypeObj] = useState(null);
  const [fromDate, setFromDate] = useState(null);
  //console.log(fromDate)
  const [toDate, setToDate] = useState(null);
  const [reason, setReason] = useState('');
  const [employeeData, setEmployeeData] = useState([]);
  const [employeeObj, setEmployeeObj] = useState(null);
  const [noOfDays, setNoOfDays] = useState('');
  const [leaveDays, setLeaveDays] = useState('');
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [uniqueId, setUniqueId] = useState('');

  useEffect(() => {
    getData();
  }, [authUser])


  const getData = async () => {
    if (authUser.branchId != null) {
      await axios.get(`${EmployeeMasterBranchWiseGetData}?bid=${authUser.branchId}`)
        .then((response) => {
          setEmployeeData(response.data);
        })
    }

    await axios.get(`${getLeaveMasterAPIURL}`)
      .then((response) => {
        setLeaveTypeData(response.data);
      })

    if (authUser.sessionYear != '' && authUser.branchId != null) {
      await axios.get(`${getEmployeeLeaveEntryAPIURL}?year=${authUser.sessionYear}&bid=${authUser.branchId}`)
        .then((response) => {
          setData(response.data);
          setFilteredData(response.data);
        }).catch(err => {
          console.log('get employee leave entry err' + err)
        })
    }
  }

  const getEmployeeAvailabelDays = async (leaveId) => {
    setNoOfDays('');
    if (employeeObj != null) {
      await axios.get(`${getEmpAvailableDaysAPIURL}?empId=${employeeObj.employeeId}&leaveId=${leaveId}&year=${authUser.sessionYear}&bid=${authUser.branchId}`)
        .then((response) => {
          setNoOfDays(response.data);
        }).catch(err => {
          console.log('get emp available days' + err);
        })
    }
  }

  const clearData = () => {
    setSaveBtn(saveBtnStyle);
    setUpdateBtn(displayNoneBtnStyle);
    setId('');
    setLeaveTypeObj(null);
    setFromDate(null);
    setReason('');
    setEmployeeObj(null);
    setNoOfDays('');
    setLeaveDays('');
  }

  const saveBtnClick = async () => {
    setLoaderOption(true);
    const saveObj = {
      "empId": employeeObj.employeeId,
      "leaveId": leaveTypeObj.id,
      "leaveQty": leaveDays,
      "date": moment(fromDate).format("YYYY-MM-DD"),
      "reason": reason,
      "sessionYear": authUser.sessionYear,
      "branchId": authUser.branchId
    }
    // console.log(JSON.stringify(saveObj))
    await axios.post(saveEmployeeLeaveEntryAPIURL, saveObj)
      .then(res => {
        if (res.data == "SAVED") {
          setLoaderOption(false);
          clearData();
          getData();
          toast.success("Saved Successfully.");
        }
        else if (res.data == "ALREADYEXISTS") {
          setLoaderOption(false);
          toast.warn('Leave entry with this details is already exists.')
        }
        else {
          setLoaderOption(false);
          toast.error("Employee leave entry save failed.")
        }
      }).catch(err => {
        setLoaderOption(false);
        console.log("Employee leave entry save" + err);
        toast.error("Something went wrong, please check.")
      })
  }

  const updateBtnClick = async () => {
    setLoaderOption(true);
    const updateObj = {
      "id": id,
      "empId": employeeObj.employeeId,
      "leaveId": leaveTypeObj.id,
      "leaveQty": leaveDays,
      "date": moment(fromDate).format("YYYY-MM-DD"),
      "reason": reason,
      "uniqueId": uniqueId,
      "sessionYear": authUser.sessionYear,
      "branchId": authUser.branchId
    }
    // console.log(JSON.stringify(updateObj))
    await axios.put(updateEmployeeLeaveEntryAPIURL, updateObj)
      .then(res => {
        if (res.data == "UPDATED") {
          setLoaderOption(false);
          clearData();
          getData();
          toast.success("Updated Successfully.");
        }
        else if (res.data == "ALREADYEXISTS") {
          setLoaderOption(false);
          toast.warn('Leave entry with this details is already exists.')
        }
        else {
          setLoaderOption(false);
          toast.error("Employee leave entry update failed.")
        }
      }).catch(err => {
        setLoaderOption(false);
        console.log("Employee leave entry update" + err);
        toast.error("Something went wrong, please check.")
      })
  }

  const state = {
    button: 1
  }

  const onSubmit = e => {
    e.preventDefault();
    if (state.button == 1) {
      saveBtnClick();
    }
    if (state.button == 2) {
      updateBtnClick();
    }
  }

  return (
    <>
      {
        (loaderOption == true)
          ?
          <Loader />
          :
          <>
            <TitleLabel titleId={titleId} />
            <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: '350px' }}>
              <form onSubmit={onSubmit}>
                <input type='hidden' value={id} onChange={(e) => setId(e.target.value)} />
                <div className="row">
                  <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Employee :</label>
                  <div className='col-sm-4'>
                    <Autocomplete
                      id="combo-box-demo"
                      options={employeeData}
                      PaperComponent={({ children }) => (
                        <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                      )}
                      onChange={(event, newValue) => {
                        setEmployeeObj(newValue);
                        setLeaveTypeObj(null);
                        setFromDate(null);
                        setReason('');
                        setNoOfDays('');
                        setLeaveDays('');
                      }}
                      value={employeeObj}
                      style={{ fontSize: '14px' }}
                      getOptionLabel={option => option.employeeId + " - " + ((authUser.branchMedium == 1) ? option.employeeNameMr : option.employeeNameEn)}
                      renderOption={(props, option) => (
                        <div className='' {...props}>
                          <div className=''>
                            {option.employeeId + " - " + ((authUser.branchMedium == 1) ? option.employeeNameMr : option.employeeNameEn)}
                          </div>
                        </div>
                      )}
                      size="small"
                      renderInput={params => (
                        <TextField {...params} margin='dense' label="Select Employee" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                      )}
                    />
                  </div>
                </div>

                <div className="row my-2">
                  <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Leave :</label>
                  <div className='col-sm-3'>
                    <Autocomplete
                      id="combo-box-demo"
                      options={leaveTypeData}
                      PaperComponent={({ children }) => (
                        <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                      )}
                      onChange={(event, newValue) => {
                        setLeaveTypeObj(newValue);
                        setFromDate(null);
                        setReason('');
                        setNoOfDays('');
                        setLeaveDays('');
                        if (newValue != null) {
                          getEmployeeAvailabelDays(newValue.id);
                        }
                      }}
                      value={leaveTypeObj}
                      style={{ fontSize: '14px' }}
                      getOptionLabel={option => option.leaveName}
                      size="small"
                      renderInput={params => (
                        <TextField {...params} margin='dense' label="Select Leave" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                      )}
                    />
                  </div>
                </div>

                <div className="row my-2">
                  <label htmlFor="lastname3" className="col-sm-2" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>Available Days :</label>
                  <div className='col-sm-2'>
                    <TextField
                      required
                      fullWidth
                      type='number'
                      onWheel={(e) => e.target.blur()}
                      onKeyDown={(e) => (e.key === " " && noOfDays.length === 0) ? e.preventDefault() : ''}
                      autoComplete='off'
                      className=''
                      size='small'
                      id="outlined-basic"
                      label="Available Days"
                      variant="outlined"
                      margin='dense'
                      value={noOfDays}
                      onChange={(e) => setNoOfDays(e.target.value)}
                      onKeyPress={(e) => (e.key == "Enter") ? noOfDays : ""}
                      inputProps={{ maxLength: 120 }}
                      InputProps={{ readOnly: true }}
                      InputLabelProps={{ style: { fontSize: 14 } }}
                    />
                  </div>

                  <label htmlFor="lastname3" className="" style={{ width: "150px", fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>Leave Quantity :</label>
                  <div className='col-sm-2'>
                    <TextField
                      required
                      fullWidth
                      type='number'
                      onWheel={(e) => e.target.blur()}
                      onKeyDown={(e) => (e.key === " " && leaveDays.length === 0) ? e.preventDefault() : ''}
                      autoComplete='off'
                      className=''
                      size='small'
                      id="outlined-basic"
                      label="Leave Quantity"
                      variant="outlined"
                      margin='dense'
                      value={leaveDays}
                      onChange={(e) => setLeaveDays(e.target.value)}
                      onKeyPress={(e) => (e.key == "Enter") ? noOfDays : ""}
                      inputProps={{ maxLength: 120 }}
                      InputLabelProps={{ style: { fontSize: 14 } }}
                    />
                  </div>
                </div>

                <div className="row my-2">
                  <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >From Date :</label>
                  <div className='col-sm-3'>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        label="From Date"
                        value={fromDate}
                        onChange={(newValue) => {
                          setFromDate(newValue);
                        }}
                        onKeyPress={(e) => (e.key == "Enter") ? fromDate : ""}
                        inputFormat="dd/MM/yyyy"
                        renderInput={(params) =>
                          <TextField
                            {...params}
                            margin='dense'
                            variant="outlined"
                            InputLabelProps={{ style: { fontSize: 14 } }}
                            required
                            fullWidth
                            autoComplete='off'
                            size="small" />}
                      />
                    </LocalizationProvider>
                  </div>
                </div>

                <div className="row my-2">
                  <label htmlFor="lastname3" className="col-sm-2" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>Reason :</label>
                  <div className='col-sm-6'>
                    <TextField
                      required
                      fullWidth
                      onKeyDown={(e) => (e.key === " " && reason.length === 0) ? e.preventDefault() : ''}
                      autoComplete='off'
                      className=''
                      size='small'
                      id="outlined-basic"
                      label="Reason"
                      variant="outlined"
                      margin='dense'
                      value={reason}
                      onChange={(e) => setReason(e.target.value)}
                      onKeyPress={(e) => (e.key == "Enter") ? reason : ""}
                      inputProps={{ maxLength: 120 }}
                      InputLabelProps={{ style: { fontSize: 14 } }}
                    />
                  </div>
                </div>

                <div className="row mt-3">
                  <div className="col-sm-4 offset-sm-2">
                    <button type='submit' onClick={() => (state.button = 1)} className={saveBtn}>Save</button>
                    <button type='submit' onClick={() => (state.button = 2)} className={updateBtn}>Update</button>
                    <button type='button' className='btn btn-sm btn-danger ml-2' onClick={() => clearData()}>Cancel</button>
                  </div>
                </div>
              </form>

              <div className="mt-4">
                <EmployeeLeaveEntryTable
                  TableHeading={TableHeading}
                  data={data}
                  filteredData={filteredData}
                  setFilteredData={setFilteredData}
                  setSaveBtn={setSaveBtn}
                  setUpdateBtn={setUpdateBtn}
                  updateBtnStyle={updateBtnStyle}
                  displayNoneBtnStyle={displayNoneBtnStyle}
                  getData={getData}
                  setLoaderOption={setLoaderOption}
                  setId={setId}
                  setLeaveTypeObj={setLeaveTypeObj}
                  setLeaveDays={setLeaveDays}
                  setFromDate={setFromDate}
                  setReason={setReason}
                  setEmployeeObj={setEmployeeObj}
                  setNoOfDays={setNoOfDays}
                  leaveTypeData={leaveTypeData}
                  employeeData={employeeData}
                  setUniqueId={setUniqueId}
                />
              </div>
            </div>
          </>
      }
    </>
  )
}

export default EmployeeLeaveEntry