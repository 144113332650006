import React, { useState, useEffect } from 'react';
import TitleLabel from "../../CommonComponent/TitleLabel";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from '@mui/material/TextField';
import { useSelector } from "react-redux";
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import Loader from '../../CommonComponent/Loader';
import Paper from "@mui/material/Paper";
import { printFeeDemandSummary, printFeeWiseFeeDemandSummary, getClassBySectionBranch, printFeeDemandSummaryClassWise } from '../Services/FeeReportAPIURL';
import { getExportedReportAPIURL } from '../../CommonServices/APIURL';
import { getFeeList } from '../Services/FeeReportAPIURL';
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles(theme => ({
    select: {
        maxHeight: 160,
        "& ul": {
            backgroundColor: "#F4ECF7",
            //maxHeight: 160,
        },
        "& li": {
            // backgroundColor: "#F4ECF7", fontWeight: "550", fontSize: "14px" 
            fontSize: "14px",
            fontWeight: "550"
        },
    },

}));

const FeeDemandSummaryReport = () => {
    const classes = useStyles();
    const titleId = "Fee Demand Summary";
    const authUser = useSelector((state) => state.user.value);

    // loader on/off
    const [loaderOption, setLoaderOption] = useState(false);

    const [reportOptionId, setReportOptionId] = useState(1);
    const [id, setId] = useState();
    const [feeData, setFeeData] = useState([]);
    const [feeId, setFeeId] = useState([]);
    const [feeObj, setFeeObj] = useState(null);
    const [classDivData, setClassDivData] = useState([]);
    const [classDivObj, setClassDivObj] = useState(null);

    useEffect(() => {
        getData();
    }, [authUser])


    const getData = async () => {
        await axios(`${getFeeList}`)
            .then((response) => {
                const feeDemandData = (response.data).filter(e => e.feeType !== 5)
                setFeeData(feeDemandData);

            }).catch((error) => {
                console.log(error);
            })
        await axios(`${getClassBySectionBranch}?sid=${authUser.deptId}&bid=${authUser.branchId}`)
            .then((response) => {
                setClassDivData(response.data);

            }).catch((error) => {
                console.log(error);
            })
    }

    const FeeDemandSummaryPrint = async () => {
        setLoaderOption(true);
        await axios.get(`${printFeeDemandSummary}?acYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&langId=${authUser.branchMedium}`)
            .then((response) => {

                if (response.data == "NOTFOUND") {
                    setLoaderOption(false);
                    toast.warn("Fee Demand Summary not found.")
                }
                else if (response.data != null) {
                    // console.log(response.data)
                    window.open(`${getExportedReportAPIURL}${response.data}`);
                    setLoaderOption(false);
                }

            })
            .catch(error => {
                setLoaderOption(false);
                toast.error("Error Occoured, Try after sometime.");
                console.log("Fee Demand Summary Report failed: " + error)
            })
    }

    const FeeDemandSummaryFeeWisePrint = async () => {
        setLoaderOption(true);
        await axios.get(`${printFeeWiseFeeDemandSummary}?acYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&langId=${authUser.branchMedium}&feeId=${feeId}`)
            .then((response) => {

                if (response.data == "NOTFOUND") {
                    setLoaderOption(false);
                    toast.warn("Fee Demand Summary not found for this fee")
                }
                else if (response.data != null) {
                    // console.log(response.data)
                    window.open(`${getExportedReportAPIURL}${response.data}`);
                    setLoaderOption(false);
                }

            })
            .catch(error => {
                setLoaderOption(false);
                toast.error("Error Occoured, Try after sometime.");
                console.log("Fee Demand Summary Report failed: " + error)
            })
    }

    const FeeDemandSummaryClassWisePrint = async () => {
        setLoaderOption(true);
        await axios.get(`${printFeeDemandSummaryClassWise}?acYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&langId=${authUser.branchMedium}&classId=${classDivObj.id}`)
            .then((response) => {

                if (response.data == "NOTFOUND") {
                    setLoaderOption(false);
                    toast.warn("Fee Demand Summary not found for this Class")
                }
                else if (response.data != null) {
                    // console.log(response.data)
                    window.open(`${getExportedReportAPIURL}${response.data}`);
                    setLoaderOption(false);
                }

            })
            .catch(error => {
                setLoaderOption(false);
                toast.error("Error Occoured, Try after sometime.");
                console.log("Fee Demand Summary Report failed: " + error)
            })
    }

    const getFeeDemandSummaryReport = e => {
        e.preventDefault();
        if (reportOptionId == 1) {
            FeeDemandSummaryPrint();
        }
        else if (reportOptionId == 2) {
            FeeDemandSummaryFeeWisePrint();
        }
        else if (reportOptionId == 3) {
            FeeDemandSummaryClassWisePrint();
        }
    }

    if (loaderOption == true) {
        return <><Loader /></>
    }
    else {
        return (
            <>
                <TitleLabel titleId={titleId} />

                <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: "350px" }}>
                    {/* Form Data start */}
                    <form onSubmit={getFeeDemandSummaryReport}>
                        <div className="form-data">
                            <input type="hidden" value={id} onChange={(e) => setId(e.target.value)} name="" />

                            <div className="row my-1">
                                <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Report Option :</label>
                                <div className='col-sm-3'>
                                    <FormControl fullWidth margin='dense'>
                                        <InputLabel size="small" id='demo-simple-select-label' style={{ fontSize: '14px' }}>Select Option</InputLabel>
                                        <Select
                                            MenuProps={{ classes: { paper: classes.select } }}
                                            size='small'
                                            required
                                            fullWidth

                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={reportOptionId}
                                            label="Select Option"
                                            onChange={(e) => {
                                                setReportOptionId(e.target.value);
                                                setFeeObj(null);
                                                setClassDivObj(null);
                                            }}
                                        >
                                            <MenuItem value={1}>All</MenuItem>
                                            <MenuItem value={2}>Fee Wise</MenuItem>
                                            <MenuItem value={3}>Class Wise</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>

                            {(() => {
                                if (reportOptionId == 2) {
                                    return (
                                        <>
                                            <div className="row my-2">
                                                <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Fee Name :</label>
                                                <div className='col-sm-3'>
                                                    <Autocomplete
                                                        id="combo-box-demo"
                                                        PaperComponent={({ children }) => (
                                                            <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                                        )}
                                                        options={feeData}
                                                        style={{ fontSize: '14px' }}
                                                        getOptionLabel={option => (authUser.branchMedium == 1) ? option.nameMr : option.nameEn}
                                                        size="small"
                                                        value={feeObj}
                                                        onChange={(event, newvalue) => {
                                                            setFeeId(newvalue.id);
                                                            setFeeObj(newvalue);
                                                        }}

                                                        renderInput={params => (
                                                            <TextField {...params} margin="dense" label="Select Fee" InputLabelProps={{ style: { fontSize: 14 } }} variant="outlined"
                                                                required={(reportOptionId == 2) ? true : false} />
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                        </>
                                    )
                                }
                                else if (reportOptionId == 3) {
                                    return (
                                        <>
                                            <div className="row my-2">
                                                <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Class :</label>
                                                <div className='col-sm-3'>
                                                    <Autocomplete
                                                        id="combo-box-demo"
                                                        PaperComponent={({ children }) => (
                                                            <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                                        )}
                                                        options={classDivData}
                                                        style={{ fontSize: '14px' }}
                                                        getOptionLabel={option => (authUser.branchMedium == 1) ? option.nameMr : option.nameEn}
                                                        size="small"
                                                        value={classDivObj}
                                                        onChange={(event, newvalue) => {
                                                            setClassDivObj(newvalue);
                                                        }}

                                                        renderInput={params => (
                                                            <TextField {...params} margin="dense" label="Select Class" InputLabelProps={{ style: { fontSize: 14 } }} variant="outlined"
                                                                required={(reportOptionId == 3) ? true : false} />
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                        </>
                                    )
                                }
                            })()}
                            <div className="row mt-3">
                                <div className="col-sm-4 offset-sm-2">
                                    <button type="submit" className="btn btn-primary btn-sm">Show</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <ToastContainer position="top-right" theme="colored" />
            </>
        )
    }
}



export default FeeDemandSummaryReport