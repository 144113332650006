import React, { useState, useEffect } from 'react';
import TitleLabel from "../../CommonComponent/TitleLabel";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import { useSelector } from "react-redux";
import TextField from '@mui/material/TextField';
import Loader from '../../CommonComponent/Loader';
import axios from 'axios';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import moment from 'moment';
import Autocomplete from "@mui/material/Autocomplete";
import Paper from "@mui/material/Paper";
import { ToastContainer, toast } from 'react-toastify';
import { getExportedReportAPIURL } from '../../CommonServices/APIURL';
import { bulkMultiFeeReceiptAPIURL, printBulkFeeReceipt, printBulkFeeReceiptClassDivWise } from '../Services/FeeReportAPIURL';
import { classWithDivisionURL } from '../Services/FeeReportAPIURL';
import makeStyles from "@mui/styles/makeStyles";
import { getFeeListData } from '../Services/FeeDemandAPIURL';

const useStyles = makeStyles(theme => ({
    select: {
        maxHeight: 160,
        "& ul": {
            backgroundColor: "#F4ECF7",
            //maxHeight: 160,
        },
        "& li": {
            // backgroundColor: "#F4ECF7", fontWeight: "550", fontSize: "14px" 
            fontSize: "14px",
            fontWeight: "550"
        },
    },

}));


const BulkFeeReceipt = () => {
    const classes = useStyles();
    const titleId = "Bulk Fee Receipt";
    const authUser = useSelector((state) => state.user.value);

    let disableEditFlag = sessionStorage.getItem('FormProcessingFlag'); // Assuming it's a string
    disableEditFlag = JSON.parse(disableEditFlag); // Parse string to object

    // loader on/off
    const [loaderOption, setLoaderOption] = useState(false);

    const [dateCheckFrom, setDateCheckFrom] = useState(null);
    const [dateCheckTo, setDateCheckTo] = useState(null);

    const defaultMinDate = new Date(`${authUser.sessionYear}/04/01`);
    const defaultMaxDate = new Date(`${authUser.sessionYear + 1}/05/31`);

    const [dateFrom, setDateFrom] = useState(null);
    const [dateTo, setDateTo] = useState(null);
    const [id, setId] = useState();
    const [reportOptionId, setReportOptionId] = useState(1);
    const [classDivData, setclassDivData] = useState([]);
    const [classDivObj, setclassDivObj] = useState(null);
    const [feeData, setFeeData] = useState([]);
    const [feeObj, setFeeObj] = useState(null);

    const defaultFromDate = new Date(authUser.sessionYear, 3, 1);
    const defaultToDate = new Date(parseInt(authUser.sessionYear) + 1, 4, 31);

    useEffect(() => {
        if (authUser.sessionYear != '') {
            setDateFrom(moment(defaultFromDate).format("YYYY-MM-DD"))
            setDateTo(moment(defaultToDate).format("YYYY-MM-DD"))
        }
    }, [authUser])

    useEffect(() => {
        getData();
    }, [authUser])


    const getData = async () => {
        if (authUser.deptId != null || authUser.branchId != null) {
            await axios(`${classWithDivisionURL}?sid=${authUser.deptId}&bid=${authUser.branchId}`)
                .then((response) => {
                    setclassDivData(response.data);

                }).catch((error) => {
                    console.log(error);
                })
        }

        await axios(`${getFeeListData}`)
            .then(res => {
                const feeDemandData = (res.data).filter(e => e.feeType !== 5)
                setFeeData(feeDemandData);
            })

    }

    const bulkFeeReceiptPrint = async () => {
        if (moment(dateFrom).format("DD/MM/YYYY") !== "Invalid date" && moment(dateTo).format("DD/MM/YYYY") !== "Invalid date" && dateCheckFrom != "Invalid date" && dateCheckTo != "Invalid date") {
            setLoaderOption(true);
            await axios.get(`${printBulkFeeReceipt}?acYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&langId=${authUser.branchMedium}&fromDate=${moment(dateFrom).format("DD/MM/YYYY")}&toDate=${moment(dateTo).format("DD/MM/YYYY")}&feeId=${feeObj.id}`)
                .then((response) => {

                    if (response.data == "NOTFOUND") {
                        setLoaderOption(false);
                        toast.warn("Receipt not found for this date")
                    }
                    else if (response.data != null) {
                        //console.log(response.data)
                        window.open(`${getExportedReportAPIURL}${response.data}`);
                        setLoaderOption(false);
                    }

                })
                .catch(error => {
                    setLoaderOption(false);
                    toast.error("Error Occoured, Try after sometime.");
                    console.log("Bulk Fee Receipt Report failed: " + error)
                })
            //toast.success("Valid")
        }
        else {
            setLoaderOption(false);
            toast.error("Improper date, please check.");
        }

    }

    const bulkFeeReceiptPrintClassDivWise = async () => {
        if (moment(dateFrom).format("DD/MM/YYYY") !== "Invalid date" && moment(dateTo).format("DD/MM/YYYY") !== "Invalid date" && dateCheckFrom != "Invalid date" && dateCheckTo != "Invalid date") {
            setLoaderOption(true);
            await axios.get(`${printBulkFeeReceiptClassDivWise}?acYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&langId=${authUser.branchMedium}&classId=${classDivObj.classId}&divId=${classDivObj.divisionId}&fromDate=${moment(dateFrom).format("DD/MM/YYYY")}&toDate=${moment(dateTo).format("DD/MM/YYYY")}&feeId=${feeObj.id}`)
                .then((response) => {

                    if (response.data == "NOTFOUND") {
                        setLoaderOption(false);
                        toast.warn("Receipt not found for this class-division or date")
                    }
                    else if (response.data != null) {
                        //console.log(response.data)
                        window.open(`${getExportedReportAPIURL}${response.data}`);
                        setLoaderOption(false);
                    }

                })
                .catch(error => {
                    setLoaderOption(false);
                    toast.error("Error Occoured, Try after sometime.");
                    console.log("Bulk Fee Receipt Report failed: " + error)
                })
            //toast.success("Valid")
        }
        else {
            setLoaderOption(false);
            toast.error("Improper date, please check.");
        }
    }

    const getBulkFeeReceiptReport = e => {
        e.preventDefault();
        if (reportOptionId == 1) {
            bulkFeeReceiptPrint();
        }
        else if (reportOptionId == 2) {
            bulkFeeReceiptPrintClassDivWise();
        }
    }


    const printBulkMultiFeeReceiptReport = async (e) => {
        e.preventDefault();
        if (moment(dateFrom).format("DD/MM/YYYY") !== "Invalid date" && moment(dateTo).format("DD/MM/YYYY") !== "Invalid date" && dateCheckFrom != "Invalid date" && dateCheckTo != "Invalid date") {
            setLoaderOption(true);
            // console.log(`${bulkMultiFeeReceiptAPIURL}?option=${reportOptionId}&year=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&langId=${authUser.branchMedium}&classId=${(reportOptionId == 2) ? classDivObj.classId : 0}&divId=${(reportOptionId == 2) ? classDivObj.divisionId : 0}&dateFrom=${moment(dateFrom).format("YYYY-MM-DD")}&dateTo=${moment(dateTo).format("YYYY-MM-DD")}`)
            await axios.get(`${bulkMultiFeeReceiptAPIURL}?option=${reportOptionId}&year=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&langId=${authUser.branchMedium}&classId=${(reportOptionId == 2) ? classDivObj.classId : 0}&divId=${(reportOptionId == 2) ? classDivObj.divisionId : 0}&dateFrom=${moment(dateFrom).format("YYYY-MM-DD")}&dateTo=${moment(dateTo).format("YYYY-MM-DD")}`)
                .then((response) => {

                    if (response.data == "NOTFOUND") {
                        setLoaderOption(false);
                        toast.warn("Receipt not found for this class-division or date")
                    }
                    else if (response.data != null) {
                        //console.log(response.data)
                        window.open(`${getExportedReportAPIURL}${response.data}`);
                        setLoaderOption(false);
                    }

                })
                .catch(error => {
                    setLoaderOption(false);
                    toast.error("Error Occoured, Try after sometime.");
                    console.log("Bulk Fee Receipt Report failed: " + error)
                })
            //toast.success("Valid")
        }
        else {
            setLoaderOption(false);
            toast.error("Improper date, please check.");
        }
    }

    if (loaderOption == true) {
        return <><Loader /></>
    }
    else {
        return (
            <>
                <TitleLabel titleId={titleId} />

                <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: "350px" }}>
                    {/* Form Data start */}
                    {(() => {
                        if (disableEditFlag && disableEditFlag[4] == 1) {
                            return (
                                <>
                                    <form onSubmit={printBulkMultiFeeReceiptReport}>
                                        <div className="form-data">
                                            <input type="hidden" value={id} onChange={(e) => setId(e.target.value)} name="" />

                                            {/* <div className="row my-1">
                                                <label htmlFor="lastname3" className="col-sm-2" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>Fee :</label>
                                                <div className='col-sm-4'>
                                                    <Autocomplete
                                                        id="combo-box-demo"
                                                        options={feeData}
                                                        PaperComponent={({ children }) => (
                                                            <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                                        )}
                                                        onChange={(event, newValue) => {
                                                            setFeeObj(newValue);
                                                            setclassDivObj(null);
                                                            setDateFrom(moment(defaultFromDate).format("YYYY-MM-DD"))
                                                            setDateTo(moment(defaultToDate).format("YYYY-MM-DD"))
                                                            setReportOptionId(1);
                                                        }}
                                                        value={feeObj}
                                                        style={{ fontSize: '14px' }}
                                                        getOptionLabel={option => (authUser.branchMedium == 1) ? option.nameMr : option.nameEn}
                                                        size="small"
                                                        renderInput={params => (
                                                            <TextField {...params} margin='dense' label="Select Fee" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                                        )}
                                                    />
                                                </div>
                                            </div> */}

                                            <div className="row my-2">
                                                <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Option :</label>
                                                <div className='col-sm-3'>
                                                    <FormControl fullWidth margin='dense'>
                                                        <InputLabel size="small" id='demo-simple-select-label' style={{ fontSize: '14px' }}>Select Option</InputLabel>
                                                        <Select
                                                            MenuProps={{ classes: { paper: classes.select } }}
                                                            size='small'
                                                            required
                                                            fullWidth
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            value={reportOptionId}
                                                            label="Select Option"
                                                            onChange={(e) => {
                                                                setReportOptionId(e.target.value);
                                                                setclassDivObj(null);
                                                                setDateFrom(moment(defaultFromDate).format("YYYY-MM-DD"))
                                                                setDateTo(moment(defaultToDate).format("YYYY-MM-DD"))
                                                            }}
                                                        >

                                                            <MenuItem value={1}>Date Wise</MenuItem>
                                                            <MenuItem value={2}>Class-division Wise</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </div>
                                            </div>

                                            {(() => {
                                                if (reportOptionId == 2) {
                                                    return (
                                                        <>
                                                            <div className="row my-2">
                                                                <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Class-Division :</label>
                                                                <div className='col-sm-3'>
                                                                    <Autocomplete
                                                                        id="combo-box-demo"
                                                                        PaperComponent={({ children }) => (
                                                                            <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                                                        )}
                                                                        options={classDivData}
                                                                        style={{ fontSize: '14px' }}
                                                                        getOptionLabel={option => (authUser.branchMedium == 1) ? option.classNameMr + " " + option.divisionNameMr : option.classNameEn + " " + option.divisionNameEn}
                                                                        size="small"
                                                                        value={classDivObj}
                                                                        onChange={(event, newvalue) => {
                                                                            setclassDivObj(newvalue);
                                                                        }}
                                                                        renderInput={params => (
                                                                            <TextField {...params} margin="dense" label="Select Class-Division" InputLabelProps={{ style: { fontSize: 14 } }} variant="outlined"
                                                                                required={(reportOptionId == 2) ? true : false} />
                                                                        )}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </>
                                                    )
                                                }
                                            })()}

                                            <div className="row my-2">
                                                <label className="col-sm-2" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>Date From :</label>
                                                <div className='col-sm-3'>
                                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                        <DatePicker
                                                            label="Date From"
                                                            value={dateFrom}
                                                            minDate={defaultMinDate}
                                                            maxDate={defaultMaxDate}
                                                            inputFormat="dd/MM/yyyy"
                                                            onChange={(newValue) => {
                                                                setDateFrom(newValue);
                                                                //(newValue > defaultMaxDate || newValue < defaultMinDate) ? setDateCheckFrom("Invalid date") : setDateCheckFrom(null)
                                                                const maxDate = new Date(defaultMaxDate);
                                                                maxDate.setDate(maxDate.getDate() + 1); // Add one day to max date to include the full day
                                                                if (newValue < defaultMinDate || newValue >= maxDate) {
                                                                    setDateCheckFrom("Invalid date");
                                                                } else {
                                                                    setDateCheckFrom("");
                                                                }
                                                            }}
                                                            openTo="day"
                                                            // Show defaultMaxDate selected when the DatePicker is opened
                                                            // If dateFrom is not set, defaultMaxDate is used
                                                            onOpen={() => setDateFrom(dateFrom || defaultMaxDate)}
                                                            renderInput={(params) =>
                                                                <TextField
                                                                    {...params}
                                                                    variant="outlined"
                                                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                                                    required
                                                                    fullWidth
                                                                    margin="dense"
                                                                    autoComplete='off'
                                                                    size="small" />}
                                                        />
                                                    </LocalizationProvider>
                                                    {/* <span><em style={{ fontSize: "12px", color: "red", fontWeight: "600" }}>{dateCheckFrom}</em></span> */}
                                                </div>

                                                <label className="col-sm-1" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>Date To :</label>
                                                <div className='col-sm-3'>
                                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                        <DatePicker
                                                            label="Date To"
                                                            value={dateTo}
                                                            minDate={defaultMinDate}
                                                            maxDate={defaultMaxDate}
                                                            inputFormat="dd/MM/yyyy"
                                                            onChange={(newValue) => {
                                                                setDateTo(newValue);
                                                                //(newValue > defaultMaxDate || newValue < defaultMinDate) ? setDateCheckTo("Invalid date") : setDateCheckTo(null)
                                                                const maxDate = new Date(defaultMaxDate);
                                                                maxDate.setDate(maxDate.getDate() + 1); // Add one day to max date to include the full day
                                                                if (newValue < defaultMinDate || newValue >= maxDate) {
                                                                    setDateCheckTo("Invalid date");
                                                                } else {
                                                                    setDateCheckTo("");
                                                                }
                                                            }}
                                                            openTo="day"
                                                            // Show defaultMaxDate selected when the DatePicker is opened
                                                            // If dateFrom is not set, defaultMaxDate is used
                                                            onOpen={() => setDateTo(dateTo || defaultMaxDate)}
                                                            renderInput={(params) =>
                                                                <TextField
                                                                    {...params}
                                                                    variant="outlined"
                                                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                                                    required
                                                                    fullWidth
                                                                    margin="dense"
                                                                    autoComplete='off'
                                                                    size="small" />}
                                                        />
                                                    </LocalizationProvider>
                                                    {/* <span><em style={{ fontSize: "12px", color: "red", fontWeight: "600" }}>{dateCheckTo}</em></span> */}
                                                </div>
                                            </div>

                                            <div className="row mt-3">
                                                <div className="col-sm-4 offset-sm-2">
                                                    <button type="submit" className="btn btn-primary btn-sm">Show</button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </>
                            )
                        }
                        else {
                            return (
                                <>
                                    <form onSubmit={getBulkFeeReceiptReport}>
                                        <div className="form-data">
                                            <input type="hidden" value={id} onChange={(e) => setId(e.target.value)} name="" />

                                            <div className="row my-1">
                                                <label htmlFor="lastname3" className="col-sm-2" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>Fee :</label>
                                                <div className='col-sm-4'>
                                                    <Autocomplete
                                                        id="combo-box-demo"
                                                        options={feeData}
                                                        PaperComponent={({ children }) => (
                                                            <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                                        )}
                                                        onChange={(event, newValue) => {
                                                            setFeeObj(newValue);
                                                            setclassDivObj(null);
                                                            setDateFrom(moment(defaultFromDate).format("YYYY-MM-DD"))
                                                            setDateTo(moment(defaultToDate).format("YYYY-MM-DD"))
                                                            setReportOptionId(1);
                                                        }}
                                                        value={feeObj}
                                                        style={{ fontSize: '14px' }}
                                                        getOptionLabel={option => (authUser.branchMedium == 1) ? option.nameMr : option.nameEn}
                                                        size="small"
                                                        renderInput={params => (
                                                            <TextField {...params} margin='dense' label="Select Fee" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                                        )}
                                                    />
                                                </div>
                                            </div>

                                            <div className="row my-2">
                                                <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Option :</label>
                                                <div className='col-sm-3'>
                                                    <FormControl fullWidth margin='dense'>
                                                        <InputLabel size="small" id='demo-simple-select-label' style={{ fontSize: '14px' }}>Select Option</InputLabel>
                                                        <Select
                                                            MenuProps={{ classes: { paper: classes.select } }}
                                                            size='small'
                                                            required
                                                            fullWidth
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            value={reportOptionId}
                                                            label="Select Option"
                                                            onChange={(e) => {
                                                                setReportOptionId(e.target.value);
                                                                setclassDivObj(null);
                                                                setDateFrom(moment(defaultFromDate).format("YYYY-MM-DD"))
                                                                setDateTo(moment(defaultToDate).format("YYYY-MM-DD"))
                                                            }}
                                                        >
                                                            <MenuItem value={1}>Date Wise</MenuItem>
                                                            <MenuItem value={2}>Class-division Wise</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </div>
                                            </div>

                                            {(() => {
                                                if (reportOptionId == 2) {
                                                    return (
                                                        <>
                                                            <div className="row my-2">
                                                                <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Class-Division :</label>
                                                                <div className='col-sm-3'>
                                                                    <Autocomplete
                                                                        id="combo-box-demo"
                                                                        PaperComponent={({ children }) => (
                                                                            <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                                                        )}
                                                                        options={classDivData}
                                                                        style={{ fontSize: '14px' }}
                                                                        getOptionLabel={option => (authUser.branchMedium == 1) ? option.classNameMr + " " + option.divisionNameMr : option.classNameEn + " " + option.divisionNameEn}
                                                                        size="small"
                                                                        value={classDivObj}
                                                                        onChange={(event, newvalue) => {
                                                                            setclassDivObj(newvalue);
                                                                        }}
                                                                        renderInput={params => (
                                                                            <TextField {...params} margin="dense" label="Select Class-Division" InputLabelProps={{ style: { fontSize: 14 } }} variant="outlined"
                                                                                required={(reportOptionId == 2) ? true : false} />
                                                                        )}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </>
                                                    )
                                                }
                                            })()}

                                            <div className="row my-2">
                                                <label className="col-sm-2" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>Date From :</label>
                                                <div className='col-sm-3'>
                                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                        <DatePicker
                                                            label="Date From"
                                                            value={dateFrom}
                                                            minDate={defaultMinDate}
                                                            maxDate={defaultMaxDate}
                                                            inputFormat="dd/MM/yyyy"
                                                            onChange={(newValue) => {
                                                                setDateFrom(newValue);
                                                                //(newValue > defaultMaxDate || newValue < defaultMinDate) ? setDateCheckFrom("Invalid date") : setDateCheckFrom(null)
                                                                const maxDate = new Date(defaultMaxDate);
                                                                maxDate.setDate(maxDate.getDate() + 1); // Add one day to max date to include the full day
                                                                if (newValue < defaultMinDate || newValue >= maxDate) {
                                                                    setDateCheckFrom("Invalid date");
                                                                } else {
                                                                    setDateCheckFrom("");
                                                                }
                                                            }}
                                                            openTo="day"
                                                            // Show defaultMaxDate selected when the DatePicker is opened
                                                            // If dateFrom is not set, defaultMaxDate is used
                                                            onOpen={() => setDateFrom(dateFrom || defaultMaxDate)}
                                                            renderInput={(params) =>
                                                                <TextField
                                                                    {...params}
                                                                    variant="outlined"
                                                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                                                    required
                                                                    fullWidth
                                                                    margin="dense"
                                                                    autoComplete='off'
                                                                    size="small" />}
                                                        />
                                                    </LocalizationProvider>
                                                    {/* <span><em style={{ fontSize: "12px", color: "red", fontWeight: "600" }}>{dateCheckFrom}</em></span> */}
                                                </div>

                                                <label className="col-sm-1" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>Date To :</label>
                                                <div className='col-sm-3'>
                                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                        <DatePicker
                                                            label="Date To"
                                                            value={dateTo}
                                                            minDate={defaultMinDate}
                                                            maxDate={defaultMaxDate}
                                                            inputFormat="dd/MM/yyyy"
                                                            onChange={(newValue) => {
                                                                setDateTo(newValue);
                                                                //(newValue > defaultMaxDate || newValue < defaultMinDate) ? setDateCheckTo("Invalid date") : setDateCheckTo(null)
                                                                const maxDate = new Date(defaultMaxDate);
                                                                maxDate.setDate(maxDate.getDate() + 1); // Add one day to max date to include the full day
                                                                if (newValue < defaultMinDate || newValue >= maxDate) {
                                                                    setDateCheckTo("Invalid date");
                                                                } else {
                                                                    setDateCheckTo("");
                                                                }
                                                            }}
                                                            openTo="day"
                                                            // Show defaultMaxDate selected when the DatePicker is opened
                                                            // If dateFrom is not set, defaultMaxDate is used
                                                            onOpen={() => setDateTo(dateTo || defaultMaxDate)}
                                                            renderInput={(params) =>
                                                                <TextField
                                                                    {...params}
                                                                    variant="outlined"
                                                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                                                    required
                                                                    fullWidth
                                                                    margin="dense"
                                                                    autoComplete='off'
                                                                    size="small" />}
                                                        />
                                                    </LocalizationProvider>
                                                    {/* <span><em style={{ fontSize: "12px", color: "red", fontWeight: "600" }}>{dateCheckTo}</em></span> */}
                                                </div>
                                            </div>

                                            <div className="row mt-3">
                                                <div className="col-sm-4 offset-sm-2">
                                                    <button type="submit" className="btn btn-primary btn-sm">Show</button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </>
                            )
                        }
                    })()}
                </div>
                <ToastContainer position="top-right" theme="colored" />
            </>
        )
    }


}

export default BulkFeeReceipt