import React, { useEffect, useState } from 'react'
import TitleLabel from '../../CommonComponent/TitleLabel'
import { DatePicker, LocalizationProvider } from '@mui/lab'
import { Autocomplete, FormControl, FormControlLabel, InputLabel, MenuItem, Paper, Radio, RadioGroup, Select, TextField } from '@mui/material'
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import makeStyles from "@mui/styles/makeStyles";
import { BaseURLInstituteMaster } from '../../CommonServices/InstituteMasterAPIURL';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { getOutwardEntryAPIURL, getOutwardEntryNoAPIURL, saveOutwardEntryAPIURL, updateOutwardEntryAPIURL } from '../Services/OutwardEntryAPIURL';
import { toast } from 'react-toastify';
import moment from 'moment';
import Loader from '../../CommonComponent/Loader';

const useStyles = makeStyles(theme => ({
    menuPaper: {
        maxHeight: 200,
        "& ul": {
            backgroundColor: "#F4ECF7",
            //maxHeight: 160,
        },
        "& li": {
            // backgroundColor: "#F4ECF7", fontWeight: "550", fontSize: "14px" 
            fontSize: "14px",
            fontWeight: "550"
        },
    }
}));

const OutwardEntryForm = () => {
    const titleId = "Outward Entry"
    const classes = useStyles();
    const authUser = useSelector((state) => state.user.value);

    const navigate = useNavigate();

    const { oeId, EditFlag } = useParams();
    const [loaderOption, setLoaderOption] = useState(false);

    //Note: This below states are declared for Save & Update(Disabled/unDisabled)

    const saveBtnStyle = "outwardEntrySaveBtn btn btn-primary btn-sm";
    const updateBtnStyle = "outwardEntryUpdateBtn btn btn-primary btn-sm";
    const displayNoneBtnStyle = "d-none";
    const [saveBtn, setSaveBtn] = useState(saveBtnStyle);
    const [updateBtn, setUpdateBtn] = useState(displayNoneBtnStyle);

    const [saveBtnDisabled, setSaveBtnDisabled] = useState(false);
    const [updateBtnDisabled, setUpdateBtnDisabled] = useState(false);

    const [id, setId] = useState('');
    const [entryDate, setEntryDate] = useState(null);
    const [outwardNo, setOutwardNo] = useState('');
    const [contentOfLetter, setContentOfLetter] = useState("");
    const [remark, setRemark] = useState("");
    const [duplicateNo, setDuplicateNo] = useState('');
    const [optionId, setOptionId] = useState('');
    const [branch, setBranch] = useState("");
    const [branchData, setBranchData] = useState([]);
    const [toWhomSentName, setToWhomSentName] = useState("");
    const [toWhomSentAddress, setToWhomSentAddress] = useState("");
    const [receivedRs, setReceivedRs] = useState('');
    const [dispatchedRs, setDispatchedRs] = useState('');
    const [balance, setBalance] = useState('');
    const [reference, setReference] = useState('');


    useEffect(() => {
        if (EditFlag == 1) {
            getData();
            setSaveBtnDisabled(false);
            setUpdateBtnDisabled(true);
        }
        else if (EditFlag == 2) {
            getEditDetails();
            setSaveBtnDisabled(true);
            setUpdateBtnDisabled(false);
        }
    }, [authUser])

    const getData = async () => {
        setLoaderOption(true);
        if (authUser.deptId != null && authUser.branchId != null && authUser.sessionYear != '') {
            await axios(`${getOutwardEntryNoAPIURL}?year=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}`)
                .then(res => {
                    setOutwardNo(res.data);
                    setLoaderOption(false);
                })
        }

        // console.log(BaseURLInstituteMaster)
        axios.get(BaseURLInstituteMaster)
            .then((response) => {
                setBranchData(response.data);
                setLoaderOption(false);
            })

    }

    const getEditDetails = async () => {
        setLoaderOption(true);
        if (authUser.deptId != null && authUser.branchId != null && authUser.sessionYear != '') {
            await axios.get(`${getOutwardEntryAPIURL}?year=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}`)
                .then(res => {
                    setSaveBtnDisabled(true);
                    setUpdateBtnDisabled(false);
                    setSaveBtn(displayNoneBtnStyle);
                    setUpdateBtn(updateBtnStyle);
                    const tempData = res.data.find(e => e.id == oeId)

                    // console.log(tempData)

                    setId(tempData.id)
                    setEntryDate(tempData.entryDate);
                    setOutwardNo(tempData.outwardNo);
                    setDuplicateNo(tempData.duplicateNo);
                    setOptionId(tempData.option)
                    setBranch(tempData.branchName)
                    setContentOfLetter(tempData.contentOfOutward)
                    setRemark(tempData.remark)
                    setToWhomSentName(tempData.toWhomSent)
                    setToWhomSentAddress(tempData.address)
                    setReceivedRs(tempData.receivedAmount)
                    setDispatchedRs(tempData.dispatchedAmount)
                    setBalance(tempData.balanceAmount)
                    setReference(tempData.reference)

                    axios.get(BaseURLInstituteMaster)
                        .then((response) => {
                            setBranchData(response.data);
                            setLoaderOption(false);
                        })

                    setLoaderOption(false);
                }).catch(err => {
                    console.log("Get inventory vendor master err " + err)
                })
        }
    }

    const saveBtnClick = async () => {
        setLoaderOption(true);
        const saveObj = {
            "outwardNo": outwardNo,
            "entryDate": moment(entryDate).format("YYYY-MM-DD"),
            "duplicateNo": duplicateNo,
            "option": optionId,
            "branchName": branch,
            "contentOfOutward": contentOfLetter,
            "toWhomSent": toWhomSentName,
            "address": toWhomSentAddress,
            "receivedAmount": receivedRs,
            "dispatchedAmount": dispatchedRs,
            "balanceAmount": balance,
            "remark": remark,
            "reference": reference,
            "sessionYear": authUser.sessionYear,
            "sectionId": authUser.deptId,
            "branchId": authUser.branchId
        }
        // console.log(JSON.stringify(saveObj))
        await axios.post(saveOutwardEntryAPIURL, saveObj)
            .then(res => {
                if (res.data == "SAVED") {
                    clearData();
                    getData();
                    setLoaderOption(false);
                    toast.success("Saved Successfully.")
                }
                else {
                    setLoaderOption(false);
                    toast.error("Outward Entry Save Failed.")
                }
            }).catch(err => {
                console.log("Outward Entry save" + err);
                setLoaderOption(false);
                toast.error("Something went wrong, please check.")
            })
    }

    const updateBtnClick = async () => {
        setLoaderOption(true);
        const updateObj = {
            "id": id,
            "outwardNo": outwardNo,
            "entryDate": moment(entryDate).format("YYYY-MM-DD"),
            "duplicateNo": duplicateNo,
            "option": optionId,
            "branchName": branch,
            "contentOfOutward": contentOfLetter,
            "toWhomSent": toWhomSentName,
            "address": toWhomSentAddress,
            "receivedAmount": receivedRs,
            "dispatchedAmount": dispatchedRs,
            "balanceAmount": balance,
            "remark": remark,
            "reference": reference,
            "sessionYear": authUser.sessionYear,
            "sectionId": authUser.deptId,
            "branchId": authUser.branchId
        }
        // console.log(JSON.stringify(updateObj))
        await axios.put(updateOutwardEntryAPIURL, updateObj)
            .then(res => {
                if (res.data == "UPDATED") {
                    clearData();
                    getData();
                    setLoaderOption(false);
                    toast.success("Updated Successfully.")
                }
                else {
                    setLoaderOption(false);
                    toast.error("Outward Entry update Failed.")
                }
            }).catch(err => {
                console.log("Outward Entry update" + err);
                setLoaderOption(false);
                toast.error("Something went wrong, please check.")
            })
    }

    const clearData = () => {
        setId('');
        setEntryDate(null);
        setOutwardNo('');
        setContentOfLetter("");
        setRemark("");
        setDuplicateNo('');
        setOptionId('');
        setBranch("");
        setToWhomSentName("");
        setToWhomSentAddress("");
        setReceivedRs('');
        setDispatchedRs('');
        setBalance('');
        setReference('');
        setSaveBtnDisabled(false);
        setUpdateBtnDisabled(true);
        setSaveBtn(saveBtnStyle);
        setUpdateBtn(displayNoneBtnStyle);
        navigate(`/Home/oe1105/`)
    }

    const state = {
        button: 1
    };

    const onSubmit = e => {
        e.preventDefault();
        if (state.button === 1) {
            saveBtnClick();
        }
        if (state.button === 2) {
            updateBtnClick();
        }
    };

    if (loaderOption == true) {
        return (
            <Loader />
        )
    }
    else {
        return (
            <>
                <TitleLabel titleId={titleId} />
                <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px" }}>
                    {/* Form Data start */}
                    <form onSubmit={onSubmit}>
                        <div className="form-data mt-1">
                            <input type="hidden" value={id} onChange={(e) => setId(e.target.value)} name="" />
                            <div className="row my-2">
                                <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Outward No :</label>
                                <div className='col-sm-3'>
                                    <TextField
                                        required
                                        fullWidth
                                        autoComplete='off'
                                        size='small'
                                        id="outlined-basic"
                                        label="Outward No"
                                        variant="outlined"
                                        margin='dense'
                                        value={outwardNo}
                                        onKeyPress={(e) => (e.key == "Enter") ? outwardNo : ""}
                                        InputLabelProps={{ style: { fontSize: 14 } }}
                                        inputProps={{ readOnly: true }}
                                    />
                                </div>

                                <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Entry Date :</label>
                                <div className='col-sm-3'>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DatePicker
                                            label="Entry Date"
                                            value={entryDate}
                                            onChange={(newValue) => {
                                                setEntryDate(newValue);
                                            }}
                                            onKeyPress={(e) => (e.key == "Enter") ? entryDate : ""}
                                            inputFormat="dd/MM/yyyy"
                                            renderInput={(params) =>
                                                <TextField
                                                    {...params}
                                                    margin='dense'
                                                    variant="outlined"
                                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                                    required
                                                    fullWidth
                                                    autoComplete='off'
                                                    size="small" />}
                                        />
                                    </LocalizationProvider>
                                </div>
                            </div>

                            <div className="row my-2">
                                <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Duplicate No :</label>
                                <div className='col-sm-3'>
                                    <TextField
                                        required
                                        fullWidth
                                        type='number'
                                        autoComplete='off'
                                        size='small'
                                        id="outlined-basic"
                                        label="Duplicate No"
                                        variant="outlined"
                                        margin='dense'
                                        value={duplicateNo}
                                        onKeyPress={(e) => (e.key == "Enter") ? duplicateNo : ""}
                                        InputLabelProps={{ style: { fontSize: 14 } }}
                                        onChange={(e) => {
                                            setDuplicateNo(e.target.value);
                                        }}
                                    />
                                </div>

                                <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>option :</label>
                                <div className="col-sm-3">
                                    <FormControl fullWidth margin='dense'>
                                        <InputLabel size="small" id='demo-simple-select-label' style={{ fontSize: '14px' }}>Select option</InputLabel>
                                        <Select
                                            size='small'
                                            MenuProps={{ classes: { paper: classes.menuPaper } }}
                                            required
                                            fullWidth
                                            margin='dense'
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={optionId}
                                            label="Select option"
                                            onChange={(e) => {
                                                setOptionId(e.target.value);
                                                setBranch("");
                                            }}
                                        >
                                            <MenuItem style={{ backgroundColor: "#F4ECF7", fontWeight: "550", fontSize: "14px" }} value={1}>Inside Branch</MenuItem>
                                            <MenuItem style={{ backgroundColor: "#F4ECF7", fontWeight: "550", fontSize: "14px" }} value={2}>Other</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>

                            {(() => {
                                if (optionId == 1) {
                                    return (
                                        <>
                                            <div className="row my-2">
                                                <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Branch :</label>
                                                <div className='col-sm-8'>

                                                    <Autocomplete
                                                        id="combo-box-demo"
                                                        PaperComponent={({ children }) => (
                                                            <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                                        )}
                                                        freeSolo
                                                        disableClearable
                                                        onKeyDown={(e) => (e.key === " " && branch.length === 0) ? e.preventDefault() : ""}
                                                        options={(branch === "") ? [] : branchData}
                                                        value={branch}
                                                        onChange={(event, newValue) => {
                                                            //setbranchId(newValue.id);
                                                            //console.log(newValue.branchDetail);
                                                            setBranch((authUser.branchMedium == 1) ? newValue.schoolNameMr : newValue.schoolNameEn);
                                                        }}
                                                        inputValue={branch}
                                                        onInputChange={(event, newInputValue) => {
                                                            //setbranchId(newInputValue.id);
                                                            //console.log(newInputValue);
                                                            setBranch(newInputValue);
                                                        }}
                                                        onKeyPress={(e) => (e.key == "Enter") ? branch : ""}
                                                        style={{ fontSize: '14px' }}
                                                        getOptionLabel={option => {

                                                            if (typeof option === 'string') {
                                                                return option;
                                                            }
                                                            // Add "xxx" option created dynamically
                                                            if (option.inputValue) {
                                                                //console.log("option........"+JSON.stringify(option.inputValue))
                                                                return (authUser.branchMedium == 1) ? option.id + " . " + option.schoolNameMr : option.id + " . " + option.schoolNameEn;
                                                            }
                                                            // Regular option
                                                            return (authUser.branchMedium == 1) ? option.id + " . " + option.schoolNameMr : option.id + " . " + option.schoolNameEn;
                                                        }

                                                        }
                                                        size="small"
                                                        renderInput={params => (
                                                            <TextField {...params} margin='dense' label="Branch" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required={(optionId == 1) ? true : false} />
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                        </>
                                    )
                                }
                                else if (optionId == 2) {
                                    return (
                                        <>
                                            <div className="row my-2">
                                                <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Other :</label>
                                                <div className='col-sm-8'>
                                                    <TextField
                                                        required={(optionId == 2) ? true : false}
                                                        fullWidth
                                                        autoComplete='off'
                                                        size='small'
                                                        id="outlined-basic"
                                                        label="Other"
                                                        variant="outlined"
                                                        margin='dense'
                                                        value={branch}
                                                        onChange={(e) => {
                                                            setBranch(e.target.value);
                                                        }}
                                                        onKeyPress={(e) => (e.key == "Enter") ? branch : ""}
                                                        InputLabelProps={{ style: { fontSize: 14 } }}
                                                    />
                                                </div>
                                            </div>
                                        </>
                                    )
                                }
                            })()}


                            <div className="row my-2">
                                <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Contents Of Outward Letter :</label>
                                <div className='col-sm-8'>
                                    <TextField
                                        required
                                        fullWidth
                                        rows={3}
                                        multiline
                                        autoComplete='off'
                                        size='small'
                                        id="outlined-basic"
                                        label="Contents Of Outward Letter"
                                        variant="outlined"
                                        margin='dense'
                                        value={contentOfLetter}
                                        onChange={(e) => {
                                            setContentOfLetter(e.target.value);
                                        }}
                                        onKeyPress={(e) => (e.key == "Enter") ? contentOfLetter : ""}
                                        InputLabelProps={{ style: { fontSize: 14 } }}
                                    />
                                </div>
                            </div>

                            <div className="row my-2">
                                <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >To Whom Sent :</label>
                                <div className='col-sm-8'>
                                    <TextField
                                        required
                                        fullWidth
                                        autoComplete='off'
                                        size='small'
                                        id="outlined-basic"
                                        label="Name"
                                        variant="outlined"
                                        margin='dense'
                                        value={toWhomSentName}
                                        onChange={(e) => {
                                            setToWhomSentName(e.target.value);
                                        }}
                                        onKeyPress={(e) => (e.key == "Enter") ? toWhomSentName : ""}
                                        InputLabelProps={{ style: { fontSize: 14 } }}
                                    />
                                </div>
                            </div>

                            <div className="row my-2">
                                <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Address :</label>
                                <div className='col-sm-8'>
                                    <TextField
                                        required
                                        fullWidth
                                        autoComplete='off'
                                        size='small'
                                        id="outlined-basic"
                                        label="Address"
                                        variant="outlined"
                                        margin='dense'
                                        value={toWhomSentAddress}
                                        onChange={(e) => {
                                            setToWhomSentAddress(e.target.value);
                                        }}
                                        onKeyPress={(e) => (e.key == "Enter") ? toWhomSentAddress : ""}
                                        InputLabelProps={{ style: { fontSize: 14 } }}
                                    />
                                </div>
                            </div>

                            <div className="row my-2">
                                <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Reference :</label>
                                <div className='col-sm-8'>
                                    <TextField
                                        required
                                        fullWidth
                                        autoComplete='off'
                                        size='small'
                                        id="outlined-basic"
                                        label="Reference"
                                        variant="outlined"
                                        margin='dense'
                                        value={reference}
                                        onChange={(e) => {
                                            setReference(e.target.value);
                                        }}
                                        onKeyPress={(e) => (e.key == "Enter") ? reference : ""}
                                        InputLabelProps={{ style: { fontSize: 14 } }}
                                    />
                                </div>
                            </div>

                            <div className="row my-2">
                                <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Postage :</label>
                                <div className='col-sm-2'>
                                    <TextField
                                        required
                                        fullWidth
                                        type='number'
                                        autoComplete='off'
                                        size='small'
                                        id="outlined-basic"
                                        label="Received Rs."
                                        variant="outlined"
                                        margin='dense'
                                        value={receivedRs}
                                        onChange={(e) => {
                                            setReceivedRs(e.target.value);
                                        }}
                                        onKeyPress={(e) => (e.key == "Enter") ? receivedRs : ""}
                                        InputLabelProps={{ style: { fontSize: 14 } }}
                                    />
                                </div>

                                <div className='col-sm-2'>
                                    <TextField
                                        required
                                        type='number'
                                        fullWidth
                                        autoComplete='off'
                                        size='small'
                                        id="outlined-basic"
                                        label="Dispatched Rs."
                                        variant="outlined"
                                        margin='dense'
                                        value={dispatchedRs}
                                        onChange={(e) => {
                                            setDispatchedRs(e.target.value);
                                        }}
                                        onKeyPress={(e) => (e.key == "Enter") ? dispatchedRs : ""}
                                        InputLabelProps={{ style: { fontSize: 14 } }}
                                    />
                                </div>

                                <div className='col-sm-2'>
                                    <TextField
                                        required
                                        fullWidth
                                        type='number'
                                        autoComplete='off'
                                        size='small'
                                        id="outlined-basic"
                                        label="Balance"
                                        variant="outlined"
                                        margin='dense'
                                        value={balance}
                                        onChange={(e) => {
                                            setBalance(e.target.value);
                                        }}
                                        onKeyPress={(e) => (e.key == "Enter") ? balance : ""}
                                        InputLabelProps={{ style: { fontSize: 14 } }}
                                    />
                                </div>
                            </div>

                            <div className="row my-2">
                                <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Remark :</label>
                                <div className='col-sm-8'>
                                    <TextField
                                        required
                                        fullWidth
                                        rows={2}
                                        multiline
                                        autoComplete='off'
                                        size='small'
                                        id="outlined-basic"
                                        label="Remark"
                                        variant="outlined"
                                        margin='dense'
                                        value={remark}
                                        onChange={(e) => {
                                            setRemark(e.target.value);
                                        }}
                                        onKeyPress={(e) => (e.key == "Enter") ? remark : ""}
                                        InputLabelProps={{ style: { fontSize: 14 } }}
                                    />
                                </div>
                            </div>

                            <div className="row mt-3">
                                <div className="col-sm-12 offset-sm-2">
                                    <button type="submit" disabled={saveBtnDisabled} onClick={() => (state.button = 1)} className={saveBtn} >Save</button>
                                    <button type="submit" disabled={updateBtnDisabled} onClick={() => (state.button = 2)} className={updateBtn} >Update</button>
                                    <button type="button" onClick={clearData} className="btn btn-danger btn-sm" style={{ margin: '0px 4px' }} >Cancel</button>
                                </div>
                            </div>
                        </div>
                    </form >
                </div >
            </>
        )
    }
}


export default OutwardEntryForm
