import React, { useState, useEffect } from 'react';
import TitleLabel from "../../CommonComponent/TitleLabel";
import TextField from '@mui/material/TextField';
import axios from "axios";
import { useSelector } from "react-redux";
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import { ToastContainer, toast } from 'react-toastify';
import Loader from '../../CommonComponent/Loader';
import Autocomplete from "@mui/material/Autocomplete";
import Paper from "@mui/material/Paper";
import makeStyles from "@mui/styles/makeStyles";
import { getSectionURL } from '../../CommonServices/EmployeeMasterAPIURL';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import PaymentsIcon from '@mui/icons-material/Payments';
import { useNavigate, useParams } from 'react-router-dom';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { allBudgetCategoriesAPIURL } from './Services/BudgetCategoriesAPIURL';
import { allBudgetAccountList, getBudgetAccountById, saveBudgetAccountAPIURL, updateBudgetAccountAPIURL } from './Services/BudgetAccountAPIURL';

const BudgetAccountForm = () => {
    const authUser = useSelector((state) => state.user.value);
    const titleId = "Budget Account";

    const navigate = useNavigate();

    let { baId, EditFlag } = useParams();

    const [loaderOption, setLoaderOption] = useState(false);

    //Note: This below states are declared for Save & Update(Disabled/unDisabled)
    const saveBtnStyle = "budgetAccountSaveBtn btn btn-primary btn-sm";
    const updateBtnStyle = "budgetAccountUpdateBtn btn btn-primary btn-sm";
    const displayNoneBtnStyle = "d-none";
    const [saveBtn, setSaveBtn] = useState(saveBtnStyle);
    const [updateBtn, setUpdateBtn] = useState(displayNoneBtnStyle);

    const [saveBtnDisabled, setSaveBtnDisabled] = useState(false);
    const [updateBtnDisabled, setupdateBtnDisabled] = useState(false);

    const [id, setId] = useState("");
    const [budgetCategoryData, setBudgetCategoryData] = useState([]);
    const [budgetCategoryObj, setBudgetCategoryObj] = useState(null);
    const [budgetAccountNameMr, setBudgetAccountNameMr] = useState('');
    const [budgetAccountNameEn, setBudgetAccountNameEn] = useState('');
    const [srNo, setSrNo] = useState('');

    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);

    const [chkConsolidated, setChkConsolidated] = useState(0);

    const HandleConsolidatedCheckbox = (e) => {
        setChkConsolidated(e.target.checked);
        if (e.target.checked == true) {
            setChkConsolidated(1);
        }
        else {
            setChkConsolidated(0);
        }
    }

    useEffect(() => {
        getData();
    }, [])

    const getData = () => {
        if (EditFlag == 1) {
            getApiData();
            setSaveBtnDisabled(false);
            setupdateBtnDisabled(true);
        }
        else if (EditFlag == 2) {
            getEditDetails();
            setSaveBtnDisabled(true);
            setupdateBtnDisabled(false);
        }
    }

    const getApiData = async () => {
        setLoaderOption(true);
        await axios.get(`${allBudgetCategoriesAPIURL}`)
            .then(res => {
                setBudgetCategoryData(res.data);
                setLoaderOption(false);
            })

        await axios.get(`${allBudgetAccountList}`)
            .then(res => {
                setData(res.data);
                setFilteredData(res.data);
                setLoaderOption(false);
            })
    }

    const getEditDetails = async () => {
        setLoaderOption(true);
        await axios.get(`${getBudgetAccountById}?id=${baId}`)
            .then(res => {
                setSaveBtn(displayNoneBtnStyle);
                setUpdateBtn(updateBtnStyle);
                setId(res.data.id);
                setBudgetAccountNameMr(res.data.nameMr);
                setBudgetAccountNameEn(res.data.nameEn);
                setSrNo(res.data.srNo);
                const selectedCategoryId = res.data.budgetCategoryId;
                setChkConsolidated(res.data.forConsolidated);

                axios.get(`${allBudgetCategoriesAPIURL}`)
                    .then(res => {
                        setBudgetCategoryData(res.data);
                        setBudgetCategoryObj(res.data.find(e => e.id == selectedCategoryId));
                        setLoaderOption(false);
                    })
            })
    }

    const clearData = () => {
        setSaveBtn(saveBtnStyle);
        setUpdateBtn(displayNoneBtnStyle);
        navigate('/Home/bm1006/')
    }

    const saveBtnClick = async () => {
        setLoaderOption(true);
        const saveObj = {
            "budgetCategoryId": budgetCategoryObj.id,
            "nameMr": budgetAccountNameMr,
            "nameEn": budgetAccountNameEn,
            "srNo": srNo,
            "forConsolidated": chkConsolidated
        }
        await axios.post(saveBudgetAccountAPIURL, saveObj)
            .then(res => {
                if (res.data == "EXISTS") {
                    setLoaderOption(false);
                    toast.warn("Account with this details is already exists.")
                }
                else if (res.data == "SrNoEXISTS") {
                    setLoaderOption(false);
                    toast.warn(`Account with ${srNo} Sr No is already exists.`)
                }
                else if (res.data == "SAVED") {
                    clearData();
                    getData();
                    setLoaderOption(false);
                    toast.success("Saved Successfully.")
                }
            }).catch(err => {
                console.log("Budget account save err:" + err);
                setLoaderOption(false);
                toast.error("Something went wrong, please check.")
            })
    }

    const updateBtnClick = async () => {
        setLoaderOption(true);
        const updateObj = {
            "id": id,
            "budgetCategoryId": budgetCategoryObj.id,
            "nameMr": budgetAccountNameMr,
            "nameEn": budgetAccountNameEn,
            "srNo": srNo,
            "forConsolidated": chkConsolidated
        }
        await axios.put(updateBudgetAccountAPIURL, updateObj)
            .then(res => {
                if (res.data == "EXISTS") {
                    setLoaderOption(false);
                    toast.warn("Account with this details is already exists.")
                }
                else if (res.data == "SrNoEXISTS") {
                    setLoaderOption(false);
                    toast.warn(`Account with ${srNo} Sr No is already exists.`)
                }
                else if (res.data == "SAVED") {
                    clearData();
                    getData();
                    setLoaderOption(false);
                    toast.success("Updated Successfully.")
                }
            }).catch(err => {
                console.log("Budget account update err:" + err);
                setLoaderOption(false);
                toast.error("Something went wrong, please check.")
            })
    }

    const state = {
        button: 1
    }

    const onSubmit = (e) => {
        e.preventDefault();
        if (state.button === 1) {
            saveBtnClick();
        }
        if (state.button === 2) {
            updateBtnClick();
        }
    }

    useEffect(() => {

        function handlekeydownEvent(event) {
            if (event.key === "Enter") {
                // console.log("Enter is pressed!")
                event.preventDefault();
                if (EditFlag == 1) {
                    // console.log('save Enter is pressed!');
                    let btn1 = document.querySelector('.budgetAccountSaveBtn')
                    if (btn1 !== null) {
                        btn1.click();
                    }
                    setSaveBtnDisabled(false);
                    setupdateBtnDisabled(true);
                }
                if (EditFlag == 2) {
                    // console.log('update Enter is pressed!');
                    let btn2 = document.querySelector('.budgetAccountUpdateBtn')
                    if (btn2 !== null) {
                        btn2.click();
                    }
                    setSaveBtnDisabled(true);
                    setupdateBtnDisabled(false);
                }
            }
        }

        document.addEventListener('keypress', handlekeydownEvent)
        return () => {
            document.removeEventListener('keypress', handlekeydownEvent)
        }

    }, []);


    return (
        <>
            {
                (loaderOption == true)
                    ?
                    <Loader />
                    :
                    <>
                        <TitleLabel titleId={titleId} />
                        <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: "350px" }}>
                            {/* Form Data start */}
                            <form onSubmit={onSubmit}>
                                <div className="form-data">
                                    <input type="hidden" value={id} onChange={(e) => setId(e.target.value)} name="" />

                                    <div className="row my-2">
                                        <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Category :</label>
                                        <div className='col-sm-6'>
                                            <Autocomplete
                                                id="combo-box-demo"
                                                options={budgetCategoryData}
                                                PaperComponent={({ children }) => (
                                                    <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                                )}
                                                onChange={(event, newValue) => {
                                                    setBudgetCategoryObj(newValue);
                                                }}
                                                value={budgetCategoryObj}
                                                style={{ fontSize: '14px' }}
                                                getOptionLabel={option => (authUser.branchMedium == 1) ? option.nameMr : option.nameEn}
                                                renderOption={(props, option) => (
                                                    <div className='' {...props}>
                                                        <div className=''>
                                                            {(authUser.branchMedium == 1) ? option.nameMr : option.nameEn} <br />
                                                            <div
                                                                className=''
                                                                style={{
                                                                    display: "flex",
                                                                    flex: 1,
                                                                    fontSize: "11px",
                                                                    flexDirection: "row",
                                                                    //justifyContent: "space-between",
                                                                    padding: "0px 5px",
                                                                    color: "#464964",
                                                                    fontWeight: "550"
                                                                    //gridRow: 1
                                                                }}
                                                            >
                                                                <span><AccountBalanceIcon style={{ color: "#354AFA", fontSize: "14px" }} /> {(authUser.branchMedium == 1) ? option.deptNameMr : option.deptNameEn}</span>
                                                                <span className='ml-1 mr-1 mb-1' style={{ fontSize: "14px" }}>|</span>
                                                                <span>{(option.side == "Receipt") ? <ReceiptLongIcon style={{ color: "#354AFA", fontSize: "14px" }} /> : <PaymentsIcon style={{ color: "#354AFA", fontSize: "14px" }} />} {option.side}</span>
                                                            </div>
                                                        </div>

                                                    </div>
                                                )}
                                                size="small"
                                                renderInput={params => (
                                                    <TextField {...params} margin='dense' label="Select Category" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                                )}
                                            />
                                        </div>
                                    </div>

                                    <div className="row">
                                        <label htmlFor="lastname3" className="col-sm-2" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>Account Name :</label>
                                        <div className='col-sm-4'>
                                            <TextField
                                                required
                                                fullWidth
                                                onKeyDown={(e) => (e.key === " " && budgetAccountNameMr.length === 0) ? e.preventDefault() : ''}
                                                autoComplete='off'
                                                className=''
                                                size='small'
                                                id="outlined-basic"
                                                label="Account Name Marathi"
                                                variant="outlined"
                                                margin='dense'
                                                value={budgetAccountNameMr}
                                                onChange={(e) => setBudgetAccountNameMr(e.target.value)}
                                                onKeyPress={(e) => (e.key == "Enter") ? budgetAccountNameMr : ""}
                                                inputProps={{ maxLength: 120 }}
                                                InputLabelProps={{ style: { fontSize: 14 } }}
                                            />
                                        </div>

                                        <div className='col-sm-4'>
                                            <TextField
                                                required
                                                fullWidth
                                                onKeyDown={(e) => (e.key === " " && budgetAccountNameEn.length === 0) ? e.preventDefault() : ''}
                                                autoComplete='off'
                                                size='small'
                                                value={budgetAccountNameEn}
                                                onChange={(e) => setBudgetAccountNameEn(e.target.value)}
                                                onKeyPress={(e) => (e.key == "Enter") ? budgetAccountNameEn : ""}
                                                id="outlined-basic"
                                                label="Account Name English"
                                                variant="outlined"
                                                margin='dense'
                                                inputProps={{ maxLength: 120 }}
                                                InputLabelProps={{ style: { fontSize: 14 } }}
                                            />
                                        </div>
                                    </div>

                                    <div className="row my-2">
                                        <label htmlFor="lastname3" className="col-sm-2" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>Sr No. :</label>
                                        <div className='col-sm-3'>
                                            <TextField
                                                required
                                                fullWidth
                                                type="number"
                                                onKeyDown={(e) => (e.key === " " && srNo.length === 0) ? e.preventDefault() : ''}
                                                autoComplete='off'
                                                className=''
                                                size='small'
                                                id="outlined-basic"
                                                label="Sr No."
                                                variant="outlined"
                                                margin='dense'
                                                value={srNo}
                                                onChange={(e) => setSrNo(e.target.value)}
                                                onKeyPress={(e) => (e.key == "Enter") ? srNo : ""}
                                                inputProps={{ maxLength: 120 }}
                                                InputLabelProps={{ style: { fontSize: 14 } }}
                                            />
                                        </div>

                                        <div className='col-sm-4 mt-2'>
                                            <FormControlLabel control={<Checkbox checked={chkConsolidated} onChange={HandleConsolidatedCheckbox} />} sx={{ '.MuiFormControlLabel-label': { fontWeight: 550, fontSize: "15px", marginTop: "2px" } }} label="Add to Consolidated" />
                                        </div>
                                    </div>

                                    <div className="row mt-3">
                                        <div className="col-sm-4 offset-sm-2">
                                            <button type='submit' disabled={saveBtnDisabled} onClick={() => (state.button = 1)} className={saveBtn}>Save</button>
                                            <button type='submit' disabled={updateBtnDisabled} onClick={() => (state.button = 2)} className={updateBtn}>Update</button>
                                            <button type='button' className='btn btn-sm btn-danger' onClick={() => clearData()} style={{ margin: "0px 4px" }}>Cancel</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </>
            }
            <ToastContainer position="top-right" theme="colored" />
        </>
    )
}

export default BudgetAccountForm