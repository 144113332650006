import React, { useState, useEffect } from 'react';
import TitleLabel from "../../CommonComponent/TitleLabel";
import TextField from '@mui/material/TextField';
import axios from 'axios';
import Autocomplete from "@mui/material/Autocomplete";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import Paper from "@mui/material/Paper";
import Checkbox from '@mui/material/Checkbox';
import Loader from '../../CommonComponent/Loader';
import { EmployeeMasterGetMapping } from '../../CommonServices/ClassTeacherAllocationAPIURL';
import { classWithDiv, getSubjectData, saveSubjectTeacherAllocation } from '../Services/SubjectAllocationToTeacherAPIURL';

const SubjectAllocationToTeacher = () => {
    const titleId = "Class wise Subject to Teacher";
    const authUser = useSelector((state) => state.user.value);

    const [loaderOption, setLoaderOption] = useState(false);

    //Note: This below states are declared for Save & Update(Disabled/unDisabled)
    const saveBtnStyle = "subAllocationToTeacherBtn btn btn-primary btn-sm";
    const updateBtnStyle = "btn btn-primary btn-sm";
    const displayNoneBtnStyle = "d-none";
    const [saveBtn, setSaveBtn] = useState(saveBtnStyle);
    const [updateBtn, setUpdateBtn] = useState(displayNoneBtnStyle);

    const [id, setId] = useState();
    const [employeeData, setEmployeeData] = useState([]);
    const [subjectData, setSubjectData] = useState([]);
    const [classDivData, setClassDivData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [subjectObj, setSubjectObj] = useState(null);
    const [employeeObj, setEmployeeObj] = useState(null);
    const [searchTerm, setSearchTerm] = useState("");
    const [checkValue, setCheckValue] = useState([]);
    // console.log(JSON.stringify(checkValue));

    const [tempData, setTempData] = useState([]);
    const [divisionIdFalse, setDivisionIdFalse] = useState('');
    const [divisionIdTrue, setDivisionIdTrue] = useState('');
    const [Val, setVal] = useState(false);

    const handleCheckChange = (val, divId, cId, sessionYr, Bid, Sid, isAllocate) => {
        setVal(val);

        const SaveObjectData = {
            classId: cId,
            divisionId: divId,
            employeeId: employeeObj.id,
            subjectId: subjectObj.id,
            sessionYear: sessionYr,
            sectionId: Sid,
            branchId: Bid
        }
        if (val == true) {
            setCheckValue(checkValue.concat(SaveObjectData));

            setDivisionIdTrue(SaveObjectData.divisionId);
            setDivisionIdFalse('');

            //if checkbox is checked
            let updatedData = tempData;
            tempData.map((e, index) => {
                if (e.divisionId == divisionIdTrue) {
                    updatedData[index] = ({ ...e, employeeId: employeeObj.id, subjectId: subjectObj.id, isAllocated: 1 })
                }

            })
            //console.log("updatedData=======" + JSON.stringify(updatedData));
            if (searchTerm == "") {
                setClassDivData(updatedData);
                setFilteredData(updatedData)
            }
        }
        else if (val == false) {
            const removedData = checkValue.filter((item) => item.divisionId != divId)
            setCheckValue(removedData);

            setDivisionIdFalse(SaveObjectData.divisionId);
            setDivisionIdTrue('');

            //if checkbox is unChecked
            let updatedData = tempData;
            tempData.map((e, index) => {
                if (e.divisionId == divisionIdFalse) {
                    updatedData[index] = ({ ...e, employeeId: employeeObj.id, subjectId: subjectObj.id, isAllocated: 0 })
                }

            })
            //console.log("updatedData=======" + JSON.stringify(updatedData));
            if (searchTerm == "") {
                setClassDivData(updatedData);
                setFilteredData(updatedData);
            }
        }
    }

    useEffect(() => {

        //if checkbox is checked after search
        let updatedData2 = tempData;
        tempData.map((e, index) => {
            if (e.divisionId == divisionIdTrue) {
                updatedData2[index] = ({ ...e, employeeId: employeeObj.id, subjectId: subjectObj.id, isAllocated: 1 })
            }

        })

        //if checkbox is unChecked after search
        let updatedData = tempData;
        tempData.map((e, index) => {
            if (e.divisionId == divisionIdFalse) {
                updatedData[index] = ({ ...e, employeeId: employeeObj.id, subjectId: subjectObj.id, isAllocated: 0 })
            }

        })


        if (Val == false) {
            setDivisionIdTrue('');
            //setCheckAllSub(false);
            if (searchTerm == "") {
                setClassDivData(updatedData);
                setFilteredData(updatedData);
            }
        }
        else if (Val == true) {
            setDivisionIdFalse('');
            if (searchTerm == "") {
                setClassDivData(updatedData2);
                setFilteredData(updatedData2);
            }
        }

    }, [searchTerm, divisionIdTrue, divisionIdFalse, Val])


    useEffect(() => {
        getData();
    }, [authUser]);

    const getData = async () => {
        //console.log(`${EmployeeMasterGetMapping}?bid=${authUser.branchId}`);
        setLoaderOption(true);
        if (authUser.branchId != null) {
            await axios.get(`${EmployeeMasterGetMapping}?bid=${authUser.branchId}`)
                .then((response) => {
                    setEmployeeData(response.data);
                    setLoaderOption(false);
                })
        }

        if (authUser.branchId != null || authUser.sessionYear != "" || authUser.branchId != null) {
            //console.log(`${getSubjectData}?acyr=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}`)
            await axios.get(`${getSubjectData}?acyr=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}`)
                .then((response) => {
                    setSubjectData(response.data);
                    setLoaderOption(false);
                })
        }
    }

    const getClassDivisionList = async (e) => {
        e.preventDefault();
        setLoaderOption(true);
        setClassDivData([]);
        setFilteredData([]);
        setCheckValue([]);
        setSearchTerm("");
        await axios.get(`${classWithDiv}?acyr=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&subid=${subjectObj.id}&empid=${employeeObj.id}`)
            .then((response) => {
                setClassDivData(response.data);
                setFilteredData(response.data);
                setTempData(response.data);
                setLoaderOption(false);

                let SaveObj = (response.data).filter(e => {
                    return e.isAllocated == 1
                })
                let updatedData = SaveObj;
                SaveObj.map((e, index) => {
                    updatedData[index] = ({ ...e, employeeId: employeeObj.id, subjectId: subjectObj.id })
                })
                setCheckValue(updatedData);
            })
    }

    const saveData = (e) => {
        setLoaderOption(true);
        e.preventDefault();
        let removedAllData;
        if (checkValue.length === 0) {
            removedAllData = [{
                classId: 0,
                divisionId: 0,
                employeeId: employeeObj.id,
                subjectId: subjectObj.id,
                sessionYear: authUser.sessionYear,
                sectionId: authUser.deptId,
                branchId: authUser.branchId
            }]
            //console.log("remove Allllllllll" + JSON.stringify(removedAllData));
            axios.post(`${saveSubjectTeacherAllocation}`, removedAllData)
                .then((response) => {
                    if (response.data != null) {
                        getData();
                        setFilteredData([]);
                        setClassDivData([]);
                        setTempData([]);
                        setSubjectObj(null);
                        setEmployeeObj(null);
                        setCheckValue([]);
                        setSearchTerm("");
                        setLoaderOption(false);
                        toast.success("Subject allocation to teacher successfully done.");
                    } else {
                        toast.danger("Operation failed.");
                    }
                }).catch((error) => {
                    console.log("error name: " + error);
                });
        }
        else {
            //console.log(JSON.stringify("saved dataaaaaaa" + checkValue));
            axios.post(`${saveSubjectTeacherAllocation}`, checkValue)
                .then((response) => {
                    if (response.data != null) {
                        getData();
                        setFilteredData([]);
                        setClassDivData([]);
                        setTempData([]);
                        setSubjectObj(null);
                        setEmployeeObj(null);
                        setCheckValue([]);
                        setSearchTerm("");
                        setLoaderOption(false);
                        toast.success("Subject allocation to teacher successfully done.");
                    } else {
                        toast.error("Operation failed.");
                    }
                }).catch((error) => {
                    console.log("error name: " + error);
                });
        }
    }

    useEffect(() => {
        setFilteredData([])
        let tempFilteredData =
            classDivData.filter(
                (item) =>
                    item.classNameMr.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    item.classNameEn.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    item.divisionNameEn.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    item.divisionNameMr.toLowerCase().includes(searchTerm.toLowerCase())
            )
        setFilteredData(tempFilteredData);
    }, [searchTerm]);

    useEffect(() => {
        function handlekeydownEvent(event) {
            if (event.key === "Enter") {
                if (subjectObj == null || subjectObj == "" || employeeObj == null || employeeObj == "") {
                    // console.log('Enter is pressed!');
                    event.preventDefault();
                    let btn = document.querySelector('.subAllocationToTeacherBtn')
                    if (btn !== null) {
                        btn.click();
                    }
                }
            }
        }

        document.addEventListener('keypress', handlekeydownEvent)
        return () => {
            document.removeEventListener('keypress', handlekeydownEvent)
        }

    }, []);

    if (loaderOption == true) {
        return <Loader />
    }
    else {
        return (
            <>
                <TitleLabel titleId={titleId} />

                <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: "350px" }}>
                    {/* Form Data start */}

                    <div className="form-data mt-1">
                        <form onSubmit={getClassDivisionList}>
                            <input type="hidden" value={id} onChange={(e) => setId(e.target.value)} name="" />
                            <div className='row'>
                                <label className="col-sm-1" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Teacher :</label>
                                <div className='col-sm-3'>
                                    <Autocomplete
                                        id="combo-box-demo"
                                        PaperComponent={({ children }) => (
                                            <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                        )}
                                        options={employeeData}
                                        style={{ fontSize: '14px' }}
                                        getOptionLabel={option => (authUser.branchMedium == 1) ? option.employeeNameMr : option.employeeNameEn}
                                        size="small"
                                        value={employeeObj}
                                        onChange={(event, newValue) => {
                                            setEmployeeObj(newValue);
                                            setClassDivData([]);
                                            setFilteredData([]);
                                            setSearchTerm("");
                                            setSubjectObj(null);
                                        }}
                                        renderInput={params => (
                                            <TextField {...params} margin="dense" label="Select Teacher" InputLabelProps={{ style: { fontSize: 14 } }} variant="outlined" required />
                                        )}
                                    />
                                </div>

                                <label className="col-sm-1" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Subject :</label>
                                <div className='col-sm-3'>
                                    <Autocomplete
                                        id="combo-box-demo"
                                        PaperComponent={({ children }) => (
                                            <Paper style={{ background: "#F4ECF7", fontWeight: "600" }} >{children}</Paper>
                                        )}
                                        options={subjectData}
                                        style={{ fontSize: '14px' }}
                                        renderOption={(props, option) => {
                                            return (
                                                <li {...props} key={option.id}>
                                                    {(authUser.branchMedium == 1) ? option.nameMr : option.nameEn}
                                                </li>
                                            );
                                        }}
                                        getOptionLabel={option => (authUser.branchMedium == 1) ? option.nameMr : option.nameEn}
                                        size="small"
                                        value={subjectObj}
                                        onChange={(event, newValue) => {
                                            setSubjectObj(newValue);
                                            setClassDivData([]);
                                            setFilteredData([]);
                                            setSearchTerm("");
                                        }}
                                        renderInput={params => (
                                            <TextField {...params} margin="dense" label="Select Subject" InputLabelProps={{ style: { fontSize: 14 } }} variant="outlined" required />
                                        )}
                                    />
                                </div>
                            </div>

                            <div className='row mt-3'>
                                <div className="col-sm-4 offset-sm-1">
                                    <button type="submit" className="btn btn-sm btn-primary">Fill Class-Division</button>
                                </div>
                            </div>
                        </form>

                        {(classDivData == null || classDivData == "" || classDivData == [])
                            ?
                            <h4 className='mt-5'>No Records Found, Select Employee And Subject</h4>
                            :
                            <div>
                                <div className='row my-1'>
                                    <div className='offset-sm-5 mt-1'>
                                        <input
                                            className='form-control form-control-sm'
                                            type="text"
                                            style={{ width: "250px", display: "block", float: "left", marginBottom: "6px", border: "1px solid #C2C1C1" }}
                                            placeholder="Search Here"
                                            value={searchTerm}
                                            onChange={(event) => { setFilteredData([]); setSearchTerm(event.target.value) }}
                                        />
                                    </div>
                                </div>
                                {/* className='bodyWrapper' style={{ maxHeight: "220px", overflowY: "scroll", margin: "4px 0px" }} */}
                                <form onSubmit={saveData}>
                                    <div className='bodyWrapper col-sm-7 offset-sm-1' style={{ maxHeight: "332px", overflowY: "scroll" }} >
                                        <table className="table table-bordered">
                                            <thead className="" style={{ position: "sticky", zIndex: "2", overflow: "hidden", top: "0", backgroundColor: "#EAECEE", borderBottom: "2px solid #464de4" }}>
                                                <tr>
                                                    <th style={{ fontSize: "15px", fontWeight: "600" }}>Class</th>
                                                    <th style={{ fontSize: "15px", fontWeight: "600" }}>Division</th>
                                                    <th style={{ fontSize: "15px", fontWeight: "600" }}>Status</th>
                                                </tr>
                                            </thead>
                                            <tbody style={{ borderTop: "2px solid #464de4", borderBottom: "2px solid #464de4" }}>
                                                {filteredData.map((item, index) => {
                                                    return (
                                                        <tr key={index} className="table-sm">

                                                            <td style={{ fontSize: "15px", fontWeight: "500" }}>{(authUser.branchMedium == 1) ? item.classNameMr : item.classNameEn}</td>
                                                            <td style={{ fontSize: "15px", fontWeight: "500" }}>{(authUser.branchMedium == 1) ? item.divisionNameMr : item.divisionNameEn}</td>
                                                            <td style={{ fontSize: "15px", fontWeight: "500" }}>
                                                                <Checkbox defaultChecked={(item.isAllocated === 1) ? true : false} sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
                                                                    onChange={(e) => {
                                                                        let val = e.target.checked;
                                                                        handleCheckChange(val, item.divisionId, item.classId, item.sessionYear, item.branchId, item.sectionId, item.isAllocated);
                                                                    }}
                                                                />
                                                            </td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>
                                    </div>

                                    <div className="col-sm-12 mt-3">
                                        <div className="offset-sm-1">
                                            <button type="submit" className={saveBtn}>Save</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        }
                    </div>

                </div>
                <ToastContainer position="top-right" theme="colored" />
            </>
        )
    }

}

export default SubjectAllocationToTeacher