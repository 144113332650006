import {
    Autocomplete, Checkbox, FormControl, FormControlLabel,
    FormLabel,
    InputLabel, MenuItem, Paper, Radio, RadioGroup, Select, TextField
} from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import Loader from '../../CommonComponent/Loader';
import makeStyles from "@mui/styles/makeStyles";
import TitleLabel from '../../CommonComponent/TitleLabel';
import { toast } from 'react-toastify';
import axios from 'axios';
import {
    getGroupHeadAPIURL, saveGroupHeadAPIURL,
    updateGroupHeadAPIURL, getNegativeBalGroupForGroupHeadAPIURL
} from '../Services/GroupHeadAPIURL';
import { useNavigate, useParams } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
    menuPaper: {
        maxHeight: 200,
        "& ul": {
            backgroundColor: "#F4ECF7",
            //maxHeight: 160,
        },
        "& li": {
            // backgroundColor: "#F4ECF7", fontWeight: "550", fontSize: "14px" 
            fontSize: "14px",
            fontWeight: "550"
        },
    }
}));

const GroupHeadData = [
    {
        id: 1,
        Head1: "Income",
        Head2: "Asset"
    },
    {
        id: 2,
        Head1: "Expenditure",
        Head2: "Liability",
    },
];

const GroupBookData = [
    {
        id: 1,
        Heading: "Income And Expenditure"
    },
    {
        id: 2,
        Heading: "Balance Sheet"
    }
]

const GroupHead = () => {
    const titleId = 'Group Head Form';
    const classes = useStyles();
    const authUser = useSelector((state) => state.user.value);
    const navigate = useNavigate();
    let { ghId, EditFlag } = useParams();
    const [loaderOption, setLoaderOption] = useState(false);

    //Note: This below states are declared for Save & Update(Disabled/unDisabled)
    const saveBtnStyle = "groupHeadSaveBtn btn btn-primary btn-sm";
    const updateBtnStyle = "groupHeadUpdateBtn btn btn-primary btn-sm";
    const displayNoneBtnStyle = "d-none";
    const [saveBtn, setSaveBtn] = useState(saveBtnStyle);
    const [updateBtn, setUpdateBtn] = useState(displayNoneBtnStyle);

    const [saveBtnDisabled, setSaveBtnDisabled] = useState(false);
    const [updateBtnDisabled, setUpdateBtnDisabled] = useState(false);

    const [id, setId] = useState('');
    const [groupHead, setGroupHead] = useState("");
    const [groupBook, setGroupBook] = useState("");
    const [groupNameMr, setGroupNameMr] = useState('');
    const [groupNameEn, setGroupNameEn] = useState('');
    const [index, setIndex] = useState('');
    const [negativeBalGroupData, setNegativeBalGroupData] = useState([]);
    const [negativeBalGroupObj, setNegativeBalGroupObj] = useState(null);
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [detailedViewChkBox, setDetailedViewChkBox] = useState(1);

    //Detailed View chk box onchage function
    const HandleDetailedViewCheckbox = (e) => {
        setDetailedViewChkBox(e.target.checked);
        if (e.target.checked == true) {
            setDetailedViewChkBox(1);
        }
        else if (e.target.checked == false) {
            setDetailedViewChkBox(0);
        }
    }

    useEffect(() => {
        getData();
    }, [authUser])

    const getData = () => {
        if (EditFlag == 1) {
            getApiData();
            setSaveBtnDisabled(false);
            setUpdateBtnDisabled(true);
        }
        else if (EditFlag == 2) {
            getEditDetails();
            setSaveBtnDisabled(true);
            setUpdateBtnDisabled(false);
        }
    }

    //get data for updation
    const getEditDetails = async () => {
        setLoaderOption(true);
        await axios.get(`${getGroupHeadAPIURL}?sid=${authUser.deptId}`)
            .then(res => {
                setSaveBtn(displayNoneBtnStyle);
                setUpdateBtn(updateBtnStyle);

                const selectedItem = res.data.find(e => e.id == ghId);

                setId(selectedItem.id);
                setGroupHead(selectedItem.groupHead)
                setGroupBook(selectedItem.groupType)
                setGroupNameMr(selectedItem.nameMr)
                setGroupNameEn(selectedItem.nameEn)
                setIndex(selectedItem.ind)
                setDetailedViewChkBox(selectedItem.detailView)

                axios.get(`${getNegativeBalGroupForGroupHeadAPIURL}?groupType=${selectedItem.groupType}&groupHead=${selectedItem.groupHead}&sid=${authUser.deptId}`)
                    .then(res => {
                        setNegativeBalGroupData(res.data);
                        setNegativeBalGroupObj(res.data.find(e => e.id == selectedItem.negativeBalGroup))
                        setLoaderOption(false);
                    }).catch(err => {
                        setLoaderOption(false);
                        console.log("negative bal get" + err)
                    })
            }).catch(err => {
                console.log("Group head get" + err)
                setLoaderOption(false);
            })
    }

    //get all api data
    const getApiData = async () => {
        if (authUser.deptId != null) {
            await axios.get(`${getGroupHeadAPIURL}?sid=${authUser.deptId}`)
                .then(res => {
                    setData(res.data);
                    setFilteredData(res.data);
                }).catch(err => {
                    console.log("Group head get" + err)
                })
        }
    }

    //get negative balance group from group head
    const getNegativeBalGroup = async (gHead) => {
        await axios.get(`${getNegativeBalGroupForGroupHeadAPIURL}?groupType=${groupBook}&groupHead=${gHead}&sid=${authUser.deptId}`)
            .then(res => {
                setNegativeBalGroupData(res.data);
            }).catch(err => {
                console.log("negative bal get" + err)
            })
    }

    //clear form details
    const clearData = () => {
        setSaveBtn(saveBtnStyle);
        setUpdateBtn(displayNoneBtnStyle);
        setGroupHead("");
        setGroupBook("");
        setGroupNameMr('');
        setGroupNameEn('');
        setIndex('');
        setNegativeBalGroupData([]);
        setNegativeBalGroupObj(null);
        setSaveBtnDisabled(false);
        setUpdateBtnDisabled(true);
        navigate('/Home/ac619/');
    }

    //save group head
    const saveBtnClick = async () => {
        setLoaderOption(true);
        const saveObject = {
            "nameMr": groupNameMr,
            "nameEn": groupNameEn,
            "groupType": groupBook,
            "groupHead": groupHead,
            "negativeBalGroup": (negativeBalGroupObj == null) ? null : negativeBalGroupObj.id,
            "index": index,
            "detailView": detailedViewChkBox,
            "sectionId": authUser.deptId
        };
        // console.log(JSON.stringify(saveObject))
        await axios.post(`${saveGroupHeadAPIURL}`, saveObject)
            .then((response) => {
                // console.log(response.data)
                if (response.data == "SAVED") {
                    getData();
                    clearData();
                    setLoaderOption(false);
                    toast.success("Saved successfully.");
                }
                else {
                    setLoaderOption(false);
                    toast.error("Operation failed.");
                }
            }).catch(err => {
                setLoaderOption(false);
                console.log('group head save' + err);
                toast.error("Something went wrong, please check.")
            })
    };

    //update group head
    const updateBtnClick = async () => {
        setLoaderOption(true);
        const updateObject = {
            "id": id,
            "nameMr": groupNameMr,
            "nameEn": groupNameEn,
            "groupType": groupBook,
            "groupHead": groupHead,
            "negativeBalGroup": (negativeBalGroupObj == null) ? null : negativeBalGroupObj.id,
            "index": index,
            "detailView": detailedViewChkBox,
            "sectionId": authUser.deptId
        };
        // console.log(JSON.stringify(updateObject));
        await axios.put(`${updateGroupHeadAPIURL}`, updateObject)
            .then((response) => {
                if (response.data == "UPDATED") {
                    getData();
                    clearData();
                    setLoaderOption(false);
                    toast.success("Updated sucessfully.");
                }
                else {
                    setLoaderOption(false);
                    toast.error("Operation failed.");
                }
            }).catch(err => {
                setLoaderOption(false);
                console.log('group head update' + err);
                toast.error("Something went wrong, please check.")
            })
    };

    const state = {
        button: 1
    }

    //onSubmit function
    const onSubmit = e => {
        e.preventDefault();
        if (state.button == 1) {
            saveBtnClick();
        }
        if (state.button == 2) {
            updateBtnClick();
        }
    }

    //save and update on enter click
    useEffect(() => {

        function handlekeydownEvent(event) {
            if (event.key === "Enter") {
                // console.log("Enter is pressed!")
                event.preventDefault();

                let btn1 = document.querySelector('.groupHeadSaveBtn')
                if (btn1 !== null) {
                    btn1.click();
                }
                let btn2 = document.querySelector('.groupHeadUpdateBtn')
                if (btn2 !== null) {
                    btn2.click();
                }

            }
        }

        document.addEventListener('keypress', handlekeydownEvent)
        return () => {
            document.removeEventListener('keypress', handlekeydownEvent)
        }

    }, []);

    return (
        <>
            {
                (loaderOption == true)
                    ?
                    <Loader />
                    :
                    <>
                        <TitleLabel titleId={titleId} />
                        <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: '500px' }}>
                            {/* Form Data start */}
                            <form onSubmit={onSubmit}>
                                <div className="form-data mt-1">
                                    <input type="hidden" value={id} onChange={(e) => setId(e.target.value)} name="" />

                                    <div className="row">
                                        <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Group Name :</label>
                                        <div className='col-sm-4'>
                                            <TextField
                                                required
                                                fullWidth
                                                onKeyDown={(e) => (e.key === " " && groupNameMr.length === 0) ? e.preventDefault() : ""}
                                                autoComplete='off'
                                                size='small'
                                                id="outlined-basic"
                                                label="Group Name Marathi"
                                                variant="outlined"
                                                margin='dense'
                                                value={groupNameMr}
                                                onChange={(e) => setGroupNameMr(e.target.value)}
                                                onKeyPress={(e) => (e.key == "Enter") ? groupNameMr : ""}
                                                InputLabelProps={{ style: { fontSize: 14 } }}
                                                inputProps={{ maxLength: 120 }}
                                            />
                                        </div>

                                        <div className='col-sm-4'>
                                            <TextField
                                                required
                                                fullWidth
                                                onKeyDown={(e) => (e.key === " " && groupNameEn.length === 0) ? e.preventDefault() : ""}
                                                autoComplete='off'
                                                size='small'
                                                id="outlined-basic"
                                                label="Group Name English"
                                                variant="outlined"
                                                margin='dense'
                                                value={groupNameEn}
                                                onChange={(e) => setGroupNameEn(e.target.value)}
                                                onKeyPress={(e) => (e.key == "Enter") ? groupNameEn : ""}
                                                InputLabelProps={{ style: { fontSize: 14 } }}
                                                inputProps={{ maxLength: 120 }}
                                            />
                                        </div>
                                    </div>

                                    <div className="row my-2">
                                        <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Group Type :</label>
                                        <div className='col-sm-4'>
                                            <FormControl fullWidth margin='dense'>
                                                <InputLabel id='demo-simple-select-label' size="small" style={{ fontSize: '14px' }}>Group Type</InputLabel>
                                                <Select
                                                    size='small'
                                                    fullWidth
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    MenuProps={{ classes: { paper: classes.menuPaper } }}
                                                    value={groupBook}
                                                    onChange={(e) => {
                                                        setGroupBook(e.target.value);
                                                        setGroupHead('');
                                                        setNegativeBalGroupData([]);
                                                        setNegativeBalGroupObj(null);
                                                    }}
                                                    onKeyPress={(e) => (e.key == "Enter") ? groupBook : ""}
                                                    label="Group Type"
                                                >
                                                    {GroupBookData.map((item, index) => {
                                                        return (
                                                            <MenuItem key={index} value={item.id}>{item.Heading}</MenuItem>
                                                        )
                                                    })}
                                                </Select>
                                            </FormControl>
                                        </div>
                                    </div>

                                    <div className="row my-2">
                                        <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Group Head :</label>
                                        <div className='col-sm-4'>
                                            <FormControl fullWidth margin='dense'>
                                                <InputLabel id='demo-simple-select-label' size="small" style={{ fontSize: '14px' }}>Group Head</InputLabel>
                                                <Select
                                                    size='small'
                                                    fullWidth
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    MenuProps={{ classes: { paper: classes.menuPaper } }}
                                                    value={groupHead}
                                                    onChange={(e) => {
                                                        setGroupHead(e.target.value);
                                                        getNegativeBalGroup(e.target.value);
                                                        setNegativeBalGroupData([]);
                                                        setNegativeBalGroupObj(null);
                                                    }}
                                                    onKeyPress={(e) => (e.key == "Enter") ? groupHead : ""}
                                                    label="Group Head"
                                                >
                                                    {GroupHeadData.map((item) => {
                                                        if (`${groupBook}` == 1) {
                                                            return (
                                                                <MenuItem key={item.id} value={item.id}>{item.Head1}</MenuItem>
                                                            )
                                                        }
                                                        else if (`${groupBook}` == 2) {
                                                            return (
                                                                <MenuItem key={item.id} value={item.id}>{item.Head2}</MenuItem>
                                                            )
                                                        }
                                                    })}
                                                </Select>
                                            </FormControl>
                                        </div>
                                    </div>

                                    <div className="row my-2">
                                        <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Negative Bal. Group :</label>
                                        <div className='col-sm-4'>
                                            <Autocomplete
                                                fullWidth
                                                PaperComponent={({ children }) => (
                                                    <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                                )}
                                                id="combo-box-demo"
                                                options={negativeBalGroupData}
                                                onChange={(event, newValue) => {
                                                    setNegativeBalGroupObj(newValue)
                                                }}
                                                value={negativeBalGroupObj}
                                                onKeyPress={(e) => (e.key == "Enter") ? negativeBalGroupObj : ""}
                                                style={{ fontSize: '14px' }}
                                                getOptionLabel={option => (authUser.branchMedium == 1) ? option.nameMr : option.nameEn}
                                                size="small"
                                                renderInput={params => (
                                                    <TextField {...params} margin='dense' label="Select Negative Bal. Group" variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }}

                                                    />
                                                )}
                                            />
                                        </div>
                                    </div>

                                    <div className="row my-2">
                                        <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Index :</label>
                                        <div className='col-sm-3'>
                                            <TextField
                                                required
                                                fullWidth
                                                type='number'
                                                onWheel={(e) => e.target.blur()}
                                                onKeyDown={(e) => (e.key === " " && index.length === 0) ? e.preventDefault() : ""}
                                                autoComplete='off'
                                                size='small'
                                                id="outlined-basic"
                                                label="Index"
                                                variant="outlined"
                                                margin='dense'
                                                value={index}
                                                onChange={(e) => setIndex(e.target.value)}
                                                onKeyPress={(e) => (e.key == "Enter") ? index : ""}
                                                InputLabelProps={{ style: { fontSize: 14 } }}
                                                inputProps={{ maxLength: 120 }}
                                            />
                                        </div>
                                    </div>

                                    <div className="row mt-3">
                                        <div className="offset-sm-2">
                                            <FormControl>
                                                <RadioGroup
                                                    row
                                                    aria-labelledby="demo-radio-buttons-group-label"                                                    
                                                    name="radio-buttons-group"
                                                    value={detailedViewChkBox}
                                                    onChange={(e) => setDetailedViewChkBox(e.target.value)}
                                                >
                                                    <FormControlLabel sx={{ '.MuiFormControlLabel-label': { fontWeight: 550, fontSize: "15px", marginTop: "2px" } }} value={1} control={<Radio />} label="Schedule Detail" />
                                                    <FormControlLabel sx={{ '.MuiFormControlLabel-label': { fontWeight: 550, fontSize: "15px", marginTop: "2px" } }} value={2} control={<Radio />} label="Account Closing" />
                                                    <FormControlLabel sx={{ '.MuiFormControlLabel-label': { fontWeight: 550, fontSize: "15px", marginTop: "2px" } }} value={3} control={<Radio />} label="Account Detail" />
                                                </RadioGroup>
                                            </FormControl>
                                        </div>
                                    </div>

                                    <div className="row mt-3">
                                        <div className="col-sm-4 offset-sm-2">
                                            <button type='submit' disabled={saveBtnDisabled} onClick={() => (state.button = 1)} className={saveBtn}>Save</button>
                                            <button type='submit' disabled={updateBtnDisabled} onClick={() => (state.button = 2)} className={updateBtn}>Update</button>
                                            <button type='button' onClick={() => clearData()} className='btn btn-sm btn-danger ml-2'>Cancel</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </>
            }
        </>
    )
}

export default GroupHead