import React, { useState, useEffect } from 'react';
import TitleLabel from "../../../CommonComponent/TitleLabel";
import axios from 'axios';
import Paper from "@mui/material/Paper";
import TextField from '@mui/material/TextField';
import Autocomplete from "@mui/material/Autocomplete";
import PrintIcon from '@mui/icons-material/Print';
import { ToastContainer, toast } from 'react-toastify';
import { useSelector } from "react-redux";
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Loader from '../../../CommonComponent/Loader';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import optionJSON from '../Services/AccessionReportOptionJSON';
import { BaseUrlLibraryMaster, BaseURLMaster } from '../../../CommonServices/APIURL';
import { getSubBudgetMasterDataAPIURL } from '../../LibraryServices/SubBudgetTypeMasterAPIURL';
import { getRegisterTypeDataAPIURL } from '../../LibraryServices/RegisterTypeMasterAPIURL';
import { printAccessionReportAPIURL } from '../Services/AccessionReportAPIURL';
import { getExportedReportAPIURL } from '../../../CommonServices/APIURL';

import makeStyles from "@mui/styles/makeStyles";
import moment from 'moment';

const useStyles = makeStyles(theme => ({
    select: {
        maxHeight: 160,
        "& ul": {
            backgroundColor: "#F4ECF7",
            //maxHeight: 160,
        },
        "& li": {
            // backgroundColor: "#F4ECF7", fontWeight: "550", fontSize: "14px" 
            fontSize: "14px",
            fontWeight: "550"
        },
    },

}));

const AccessionReport = () => {
    const classes = useStyles();
    const authUser = useSelector((state) => state.user.value);
    const titleId = "Accession Report";

    const [loaderOption, setLoaderOption] = useState(false);

    const [view, setView] = useState(1);
    const [optionlabel, setOptionLabel] = useState('');
    const [optionObj, setOptionObj] = useState(null);
    const [purchaseDate, setPurchaseDate] = useState(null);
    const [accNoFrom, setAccNoFrom] = useState('');
    const [accNoTo, setAccNoTo] = useState('');
    const [allAccession, setAllAccession] = useState(1);
    const [selectiveAccNo, setSelectiveAccNo] = useState(0);
    const [selectedAccNo, setSelectedAccNo] = useState('');
    const [typeData, setTypeData] = useState(null);
    const [typeObj, setTypeObj] = useState(null);
    const [registerTypeData, setRegisterTypeData] = useState([]);
    const [registerTypeObj, setRegisterTypeObj] = useState(null);
    const [getOptionWiseData, setGetOptionWiseData] = useState([]);
    const [getOptionWiseObj, setGetOptionWiseObj] = useState(null);
    const [getMappingData, setGetMappingData] = useState('');
    const [allRegisterType, setAllRegisterType] = useState(1);
    //console.log(moment(purchaseDate).format("YYYY-MM-DD"))
    //console.log(optionlabel + "=======" + getMappingData);

    const handleSelectiveAccNoCheckBox = (e) => {
        setSelectiveAccNo(e.target.checked);
        setSelectedAccNo('');
        if (e.target.checked == true) {
            setSelectiveAccNo(1);
            setAllAccession(1);
            setAccNoFrom(0);
            setAccNoTo(0);
            //setAllAccession(0);.....for save
        }
        else if (e.target.checked == false) {
            setSelectiveAccNo(0);
            setSelectedAccNo(null);

        }
    }

    const handleAccessionCheckBox = (e) => {
        setAllAccession(e.target.checked);
        setAccNoFrom('');
        setAccNoTo('');
        if (e.target.checked == true) {
            setAllAccession(1);
            setAccNoFrom(0);
            setAccNoTo(0);
        }
        else if (e.target.checked == false) {
            setAllAccession(0);
            setSelectiveAccNo(0);
        }
    }

    const handleRegisterTypeCheckBox = (e) => {
        setAllRegisterType(e.target.checked);
        setRegisterTypeObj(null);
        if (e.target.checked == true) {
            setAllRegisterType(0);
        }
        else if (e.target.checked == false) {
            setAllRegisterType(1);
        }
    }

    const mapData = (opId) => {
        optionJSON.map(e => {
            if (e.id == opId) {
                setOptionLabel(e.optionName);
                if (e.id == 8 || e.id == 10) {
                    setGetMappingData(BaseURLMaster + "" + e.getMapping)
                }
                else if (e.id == 1 || e.id == 6) {
                    setGetMappingData(e.getMapping)
                }
                else {
                    setGetMappingData(BaseUrlLibraryMaster + "" + e.getMapping)
                }
            }
        })
    }

    useEffect(() => {
        if (optionObj != null) {
            if (((optionObj.id != 8 || optionObj.id != 10) ? authUser.branchId != null : '') || getMappingData != '') {
                getData();
            }
        }
    }, [authUser, optionObj, getMappingData])


    const getData = async () => {
        if (optionObj.id == 8 || optionObj.id == 10) {
            await axios.get(`${getMappingData}`)
                .then(res => {
                    setGetOptionWiseData(res.data);
                })
        }
        else {
            await axios.get(`${getMappingData}?branchId=${authUser.branchId}`)
                .then(res => {
                    setGetOptionWiseData(res.data);
                })
        }

    }

    useEffect(() => {
        if (authUser.branchId != null) {
            getSubBudgetRegisterTypeData();
        }
    }, [authUser])


    const getSubBudgetRegisterTypeData = async () => {
        await axios.get(`${getRegisterTypeDataAPIURL}?branchId=${authUser.branchId}`)
            .then(res => {
                setRegisterTypeData(res.data);
            })

        await axios.get(`${getSubBudgetMasterDataAPIURL}?branchId=${authUser.branchId}`)
            .then(res => {
                const newTypeData = res.data
                let updatedData = [{
                    "id": 0,
                    "subBudgetTypeName": "All",
                    "shortName": "all",
                    "branchId": authUser.branchId
                },
                ...newTypeData]
                setTypeData(updatedData);
            })
    }

    const printAccessionReport = async (e) => {
        e.preventDefault();
        setLoaderOption(true);
        if (typeObj != null || registerTypeObj != null || getOptionWiseObj != null || optionObj != null) {

            if (((purchaseDate == null) ? moment(new Date()).format("YYYY-MM-DD") : moment(purchaseDate).format("YYYY-MM-DD")) != "Invalid date") {
                const arr = selectedAccNo.split(',').map(element => {
                    return Number(element);
                });
                let newSelectedAccNo = arr.join(',')
                // console.log(`${printAccessionReportAPIURL}?pdfOrExcel=${view}&registerType=${(registerTypeObj == null) ? allRegisterType : registerTypeObj.id}&option=${optionObj.id}&optionId=${(getOptionWiseObj == null) ? 0 : getOptionWiseObj.id}&getAllAccNo=${(selectiveAccNo == 1) ? 0 : allAccession}&accNoFrom=${(allAccession == 1) ? 0 : accNoFrom}&accNoTo=${(allAccession == 1) ? 0 : accNoTo}&isSelective=${selectiveAccNo}&selectiveAccNo=${(selectedAccNo == '') ? 0 : newSelectedAccNo}&budgetType=${typeObj.id}&date=${(purchaseDate == null) ? null : moment(purchaseDate).format("YYYY-MM-DD")}&branchId=${authUser.branchId}&languageId=${authUser.branchMedium}`)
                await axios.get(`${printAccessionReportAPIURL}?pdfOrExcel=${view}&registerType=${(registerTypeObj == null) ? allRegisterType : registerTypeObj.id}&option=${optionObj.id}&optionId=${(getOptionWiseObj == null) ? 0 : getOptionWiseObj.id}&getAllAccNo=${(selectiveAccNo == 1) ? 0 : allAccession}&accNoFrom=${(allAccession == 1) ? 0 : accNoFrom}&accNoTo=${(allAccession == 1) ? 0 : accNoTo}&isSelective=${selectiveAccNo}&selectiveAccNo=${(selectedAccNo == '') ? 0 : newSelectedAccNo}&budgetType=${typeObj.id}&date=${(purchaseDate == null) ? null : moment(purchaseDate).format("YYYY-MM-DD")}&branchId=${authUser.branchId}&languageId=${authUser.branchMedium}`)
                    .then(res => {
                        if (res.data == "NOTFOUND") {
                            setLoaderOption(false);
                            toast.warn("Accession not found with this data.");
                        }
                        else {
                            setLoaderOption(false);
                            window.open(`${getExportedReportAPIURL}${res.data}`)
                        }
                    }).catch(err => {
                        setLoaderOption(false);
                        console.log("Accession report error:" + err)
                        toast.error("Something went wrong,please check.")
                    })
            }
            else {
                setLoaderOption(false);
                toast.error("Improper date, please check.")
            }
        }
    }


    return (
        <>
            {
                (loaderOption == true)
                    ?
                    <Loader />
                    :
                    <div>
                        <TitleLabel titleId={titleId} />
                        <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px" }}>
                            <form onSubmit={printAccessionReport}>
                                <div className='row'>
                                    <label className="col-sm-2" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>View :</label>
                                    <div className='col-sm-3'>
                                        <FormControl fullWidth margin='dense'>
                                            <InputLabel id='demo-simple-select-label' size="small" style={{ fontSize: '14px' }}>View</InputLabel>
                                            <Select
                                                MenuProps={{ classes: { paper: classes.select } }}
                                                size='small'
                                                required
                                                fullWidth

                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={view}
                                                label="View"
                                                onChange={(e) => {
                                                    setView(e.target.value);
                                                    setOptionObj(null);
                                                    setGetOptionWiseObj(null);
                                                    setRegisterTypeObj(null);
                                                    setPurchaseDate(null);
                                                    setTypeObj(null);
                                                    setAllAccession(1);
                                                    setSelectiveAccNo(0);
                                                    setAllRegisterType(1);
                                                }}
                                            >
                                                <MenuItem value={1}>PDF</MenuItem>
                                                <MenuItem value={2}>Excel</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div>

                                <div className="row my-2">
                                    <label className="col-sm-2" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }} >Option :</label>
                                    <div className="col-sm-3">
                                        <Autocomplete
                                            id="combo-box-demo"
                                            options={optionJSON}
                                            PaperComponent={({ children }) => (
                                                <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                            )}
                                            style={{ fontSize: '14px' }}
                                            getOptionLabel={option => (option.id > 1) ? `${option.optionName} Wise` : option.optionName}
                                            size="small"
                                            onChange={(event, newValue) => {
                                                setOptionObj(newValue);
                                                setGetOptionWiseObj(null);
                                                setRegisterTypeObj(null);
                                                setPurchaseDate(null);
                                                setTypeObj(null);
                                                setAllAccession(1);
                                                setSelectiveAccNo(0);
                                                setAllRegisterType(1);
                                                if (newValue != null) {
                                                    mapData(newValue.id);
                                                }
                                            }}
                                            onKeyPress={(e) => (e.key == "Enter") ? optionObj : ""}
                                            value={optionObj}
                                            renderInput={params => (
                                                <TextField {...params} margin='dense' label="Select Option" variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                            )}
                                        />
                                    </div>
                                </div>

                                {(() => {
                                    if (optionObj != null) {
                                        if (optionObj.id !== 1 && optionObj.id !== 6) {
                                            return (
                                                <>
                                                    <div className="row my-2">
                                                        <label className="col-sm-2" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }} >{optionlabel} :</label>
                                                        <div className="col-sm-5">
                                                            <Autocomplete
                                                                id="combo-box-demo"
                                                                options={getOptionWiseData}
                                                                PaperComponent={({ children }) => (
                                                                    <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                                                )}
                                                                style={{ fontSize: '14px' }}
                                                                //getOptionLabel={option => (optionObj.id == 8 || optionObj.id == 10) ? option.nameMr : option.name}
                                                                getOptionLabel={(option) => {
                                                                    if (optionObj.id == 4 || optionObj.id == 5 || optionObj.id == 7 || optionObj.id == 9) {
                                                                        return option.name
                                                                    }
                                                                    else if (optionObj.id == 8 || optionObj.id == 10) {
                                                                        return (authUser.branchMedium == 1) ? option.nameMr : option.nameEn
                                                                    }
                                                                    else if (optionObj.id == 2) {
                                                                        return option.titleName
                                                                    }
                                                                    else if (optionObj.id == 3) {
                                                                        return option.authorName
                                                                    }
                                                                }}
                                                                size="small"
                                                                onChange={(event, newValue) => {
                                                                    setGetOptionWiseObj(newValue);
                                                                    setRegisterTypeObj(null);
                                                                    setPurchaseDate(null);
                                                                    setTypeObj(null);
                                                                    setAllAccession(1);
                                                                    setSelectiveAccNo(0);
                                                                    setAllRegisterType(1);
                                                                }}
                                                                onKeyPress={(e) => (e.key == "Enter") ? getOptionWiseObj : ""}
                                                                value={getOptionWiseObj}
                                                                renderInput={params => (
                                                                    <TextField {...params} margin='dense' label={"Select" + " " + optionlabel} variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }}
                                                                        required={(optionObj.id !== 1 && optionObj.id !== 6) ? true : false} />
                                                                )}
                                                            />
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                        }
                                        else if (optionObj.id == 6) {
                                            return (
                                                <>
                                                    <div className="row my-2">
                                                        <label className='col-sm-2' style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>Purchase Date :</label>
                                                        <div className="col-sm-3">
                                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                                <DatePicker
                                                                    label="Purchase Date"
                                                                    inputFormat="dd/MM/yyyy"
                                                                    value={purchaseDate}
                                                                    onKeyDown={(e) => (e.key === " " && purchaseDate.length === 0) ? e.preventDefault() : ""}
                                                                    onKeyPress={(e) => (e.key == "Enter") ? purchaseDate : ""}
                                                                    onChange={(newValue) => {
                                                                        if (newValue === null) {
                                                                            setPurchaseDate(new Date());
                                                                        }
                                                                        else if (newValue !== null) {
                                                                            setPurchaseDate(newValue);
                                                                        }
                                                                        setRegisterTypeObj(null);
                                                                        setTypeObj(null);
                                                                        setAllAccession(1);
                                                                        setSelectiveAccNo(0);
                                                                        setAllRegisterType(1);
                                                                    }}
                                                                    //disableOpenPicker
                                                                    renderInput={(params) =>
                                                                        <TextField
                                                                            {...params}
                                                                            margin='dense'
                                                                            variant="outlined"
                                                                            InputLabelProps={{ style: { fontSize: 14 } }}
                                                                            required={(optionObj.id == 6) ? true : false}
                                                                            //style={{ width: "130px", marginLeft: "15px", backgroundColor: "#fff" }}
                                                                            fullWidth
                                                                            autoComplete='off'
                                                                            size="small" />}
                                                                />
                                                            </LocalizationProvider>
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                        }
                                    }
                                })()}

                                <div className="row my-2">
                                    <label className="col-sm-2" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }} >Register Type :</label>
                                    <div className="col-sm-3">
                                        <Autocomplete
                                            id="combo-box-demo"
                                            disabled={(allRegisterType == 0) ? true : false}
                                            options={registerTypeData}
                                            PaperComponent={({ children }) => (
                                                <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                            )}
                                            style={{ fontSize: '14px' }}
                                            getOptionLabel={option => option.registerTypeName}
                                            size="small"
                                            onChange={(event, newValue) => {
                                                setRegisterTypeObj(newValue);
                                                setTypeObj(null);
                                                setAllAccession(1);
                                                setSelectiveAccNo(0);
                                            }}
                                            onKeyPress={(e) => (e.key == "Enter") ? registerTypeObj : ""}
                                            value={registerTypeObj}
                                            renderInput={params => (
                                                <TextField {...params} margin='dense' label="Select Register Type" variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }}
                                                    required={((allRegisterType == 1) ? true : false)} />
                                            )}
                                        />
                                    </div>
                                    <div className="col-sm-2 mt-2">
                                        <FormControlLabel control={<Checkbox sx={{ '& .MuiSvgIcon-root': { fontSize: 24 } }} checked={(allRegisterType == 0) ? true : false} onChange={handleRegisterTypeCheckBox} />} sx={{ '.MuiFormControlLabel-label': { fontWeight: 550, fontSize: "16px", marginTop: "2px" } }} label="All" />
                                    </div>
                                </div>

                                <div className="row my-2">
                                    <label className="col-sm-2" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }} >Type :</label>
                                    <div className="col-sm-3">
                                        <Autocomplete
                                            id="combo-box-demo"
                                            options={typeData}
                                            PaperComponent={({ children }) => (
                                                <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                            )}
                                            style={{ fontSize: '14px' }}
                                            getOptionLabel={option => option.subBudgetTypeName}
                                            size="small"
                                            onChange={(event, newValue) => {
                                                setTypeObj(newValue);
                                                setAllAccession(1);
                                                setSelectiveAccNo(0);
                                            }}
                                            onKeyPress={(e) => (e.key == "Enter") ? typeObj : ""}
                                            value={typeObj}
                                            renderInput={params => (
                                                <TextField {...params} margin='dense' label="Select Type" variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                            )}
                                        />
                                    </div>
                                </div>

                                {(() => {
                                    if (allAccession == 1 && selectiveAccNo == 0) {
                                        return (
                                            <>
                                                <div className="row offset-sm-2">
                                                    <div className="col-sm-1 mt-2">
                                                        <FormControlLabel control={<Checkbox sx={{ '& .MuiSvgIcon-root': { fontSize: 24 } }} checked={allAccession} onChange={handleAccessionCheckBox} />} sx={{ '.MuiFormControlLabel-label': { fontWeight: 550, fontSize: "16px", marginTop: "2px" } }} label="All" />
                                                    </div>
                                                    <div className="col-sm-2 mt-2">
                                                        <FormControlLabel control={<Checkbox sx={{ '& .MuiSvgIcon-root': { fontSize: 24 } }} checked={selectiveAccNo} onChange={handleSelectiveAccNoCheckBox} />} sx={{ '.MuiFormControlLabel-label': { fontWeight: 550, fontSize: "16px", marginTop: "2px" } }} label="Selective" />
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    }
                                    else {
                                        return (
                                            <>
                                                <div className="row my-2" hidden={(selectiveAccNo == 1) ? true : false}>
                                                    <div className="col-sm-2 mt-2">
                                                        <FormControlLabel control={<Checkbox sx={{ '& .MuiSvgIcon-root': { fontSize: 24 } }} checked={allAccession} onChange={handleAccessionCheckBox} />} sx={{ '.MuiFormControlLabel-label': { fontWeight: 550, fontSize: "16px", marginTop: "2px" } }} label="All" />
                                                    </div>

                                                    <label className='' hidden={(allAccession == 1) ? true : false} style={{ width: "130px", fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>Acc No. From :</label>
                                                    <div className="col-sm-2">
                                                        <TextField
                                                            fullWidth
                                                            required={(allAccession == 0) ? true : false}
                                                            type="number"
                                                            hidden={(allAccession == 1) ? true : false}
                                                            onKeyDown={(e) => (e.key === " " && accNoFrom.length === 0) ? e.preventDefault() : ""}
                                                            autoComplete='off'
                                                            margin='dense'
                                                            size='small'
                                                            variant="outlined"
                                                            InputLabelProps={{ style: { fontSize: 14 } }}
                                                            onKeyPress={(e) => (e.key == "Enter") ? accNoFrom : ""}
                                                            label="From"
                                                            value={accNoFrom}
                                                            onChange={(e) => setAccNoFrom(e.target.value)}
                                                        />
                                                    </div>

                                                    <label className='col-sm-1' hidden={(allAccession == 1) ? true : false} style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>To :</label>
                                                    <div className="col-sm-2">
                                                        <TextField
                                                            fullWidth
                                                            required={(allAccession == 0) ? true : false}
                                                            type="number"
                                                            hidden={(allAccession == 1) ? true : false}
                                                            onKeyDown={(e) => (e.key === " " && accNoTo.length === 0) ? e.preventDefault() : ""}
                                                            autoComplete='off'
                                                            margin='dense'
                                                            size='small'
                                                            variant="outlined"
                                                            InputLabelProps={{ style: { fontSize: 14 } }}
                                                            onKeyPress={(e) => (e.key == "Enter") ? accNoTo : ""}
                                                            label="To"
                                                            value={accNoTo}
                                                            onChange={(e) => setAccNoTo(e.target.value)}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="row my-2">
                                                    <div className="col-sm-2 mt-2">
                                                        <FormControlLabel control={<Checkbox sx={{ '& .MuiSvgIcon-root': { fontSize: 24 } }} checked={selectiveAccNo} onChange={handleSelectiveAccNoCheckBox} />} sx={{ '.MuiFormControlLabel-label': { fontWeight: 550, fontSize: "16px", marginTop: "2px" } }} label="Selective" />
                                                    </div>

                                                    <label className='' hidden={(selectiveAccNo == 0) ? true : false} style={{ width: "130px", fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>Acc No :</label>
                                                    <div className="col-sm-3">
                                                        <TextField
                                                            fullWidth
                                                            required={(selectiveAccNo == 1) ? true : false}
                                                            hidden={(selectiveAccNo == 0) ? true : false}
                                                            onKeyDown={(e) => (e.key === " " && selectedAccNo.length === 0) ? e.preventDefault() : ""}
                                                            autoComplete='off'
                                                            margin='dense'
                                                            size='small'
                                                            variant="outlined"
                                                            InputLabelProps={{ style: { fontSize: 14 } }}
                                                            onKeyPress={(e) => (e.key == "Enter") ? selectedAccNo : ""}
                                                            label="Acc No"
                                                            value={selectedAccNo}
                                                            onChange={(e) => setSelectedAccNo(e.target.value)}
                                                        />
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    }
                                })()}

                                <div className="row mt-2">
                                    <div className="col-sm-4 offset-sm-2">
                                        <button type='submit' className='btn btn-sm btn-primary'>Show</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
            }
        </>
    )
}

export default AccessionReport