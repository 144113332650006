import React, { useState, useEffect } from 'react';
import TitleLabel from "../../CommonComponent/TitleLabel";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from '@mui/material/TextField';
import { useSelector } from "react-redux";
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import Loader from '../../CommonComponent/Loader';
import Paper from "@mui/material/Paper";
import { getFeeList, printMultiFeeDueListReport } from '../Services/FeeReportAPIURL';
import { getExportedReportAPIURL } from '../../CommonServices/APIURL';
import { printFeeDueList, printClassDivisionFeeDueList, classWithDivisionURL, printStudentWiseFeeDueList } from '../Services/FeeReportAPIURL';
import { getStudentList } from '../Services/FeeReceiptAPIURL';
import CircularProgress from '@mui/material/CircularProgress';
import makeStyles from "@mui/styles/makeStyles";
import AddStudentBySearching from '../../CommonComponent/AddStudenyBySearching';

const useStyles = makeStyles(theme => ({
    select: {
        maxHeight: 160,
        "& ul": {
            backgroundColor: "#F4ECF7",
            //maxHeight: 160,
        },
        "& li": {
            // backgroundColor: "#F4ECF7", fontWeight: "550", fontSize: "14px" 
            fontSize: "14px",
            fontWeight: "550"
        },
    },

}));

const feeTypeDetails = [
    {
        id: 1,
        feeLabel: "One Time"
    },
    {
        id: 2,
        feeLabel: "Yearly"
    },
    {
        id: 3,
        feeLabel: "Donation"
    },
    {
        id: 4,
        feeLabel: "Sanstha Receipt"
    },
    {
        id: 5,
        feeLabel: "EBC Fee"
    }
]


function sleep(delay = 0) {
    return new Promise((resolve) => {
        setTimeout(resolve, delay);
    });
}

const FeeDueList = () => {
    const classes = useStyles();
    const titleId = "Fee Due List";
    const authUser = useSelector((state) => state.user.value);

    // loader on/off
    const [loaderOption, setLoaderOption] = useState(false);

    const [feeData, setFeeData] = useState([]);
    const [feeId, setFeeId] = useState([]);
    const [feeObj, setFeeObj] = useState(null);
    const [id, setId] = useState();
    const [reportOptionId, setReportOptionId] = useState(1);
    const [classDivData, setClassDivData] = useState([]);
    const [classDivId, setClassDivId] = useState([]);
    const [classDivObj, setClassDivObj] = useState(null);
    const [studentData, setStudentData] = useState([]);
    const [studentObj, setStudentObj] = useState(null);
    const [offset, setOffset] = useState(0);
    const [limits, setLimits] = useState(200);
    const [numCal, setNumCall] = useState(0);

    const [feeTypeData, setFeeTypeData] = useState(feeTypeDetails);
    const [feeType, setFeeType] = useState(null);

    const [studentLcNo, setStudentLcNo] = useState('');
    const [studentDetails, setStudentDetails] = useState({});
    const [division, setDivision] = useState("");
    const [classData, setclassData] = useState("");
    const [selectedStudent, setSelectedStudent] = useState("");
    const [studentId, setStudentId] = useState('');

    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState([]);
    const loading = open && options.length === 0;


    useEffect(() => {
        getData();
    }, [authUser, numCal])


    const getData = async () => {
        await axios(`${getFeeList}`)
            .then((response) => {
                const feeDataForReceipt = (response.data).filter(e => e.feeType !== 5)
                setFeeData(feeDataForReceipt);

            }).catch((error) => {
                console.log(error);
            })
        await axios(`${classWithDivisionURL}?sid=${authUser.deptId}&bid=${authUser.branchId}`)
            .then((response) => {
                setClassDivData(response.data);

            }).catch((error) => {
                console.log(error);
            })
        if (authUser.deptId != null || authUser.branchId != null) {
            if (offset === 0) {
                // setLoaderOption(true)
            }
            await axios.get(`${getStudentList}?sid=${authUser.deptId}&bid=${authUser.branchId}&limit=${limits}&offset=${offset}`)
                .then((res) => {
                    if (res.data !== null) {
                        const tempData = res.data;
                        setStudentData(studentData.concat(tempData));
                        setLoaderOption(false)
                        if (tempData.length === limits) {
                            setOffset(offset + limits);
                            setNumCall(numCal + 1);
                        }
                    }
                })
        }

    }

    useEffect(() => {
        let active = true;

        if (!loading) {
            return undefined;
        }

        (async () => {
            await sleep(1e3); // For demo purposes.

            if (active) {
                setOptions([...studentData]);
            }
        })();

        return () => {
            active = false;
        };
    }, [loading]);

    useEffect(() => {
        if (!open) {
            setOptions([]);
        }
    }, [open]);


    const FeeDueListPrint = async () => {
        //setFeeObj(null);
        setLoaderOption(true);
        //console.log(`${printFeeDueList}?acYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&langId=${authUser.branchMedium}&feeId=${feeId}`)
        await axios.get(`${printFeeDueList}?acYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&langId=${authUser.branchMedium}&feeId=${feeId}`)
            .then((response) => {

                if (response.data == "NOTFOUND") {
                    //console.log(response.data)                    
                    setLoaderOption(false);
                    toast.warn("Data not exists")
                }
                else if (response.data != null) {
                    //console.log(response.data)
                    window.open(`${getExportedReportAPIURL}${response.data}`);
                    setLoaderOption(false);
                }

            })
            .catch(error => {
                setLoaderOption(false);
                toast.error("Error Occoured, Try after sometime.");
                console.log("Fee Due List Report failed: " + error)
            })
    }

    const FeeDueListWithClassDivPrint = async () => {
        // setFeeObj(null);
        // setClassDivObj(null);
        setLoaderOption(true);
        //console.log(`${printClassDivisionFeeDueList}?acYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&langId=${authUser.branchMedium}&feeId=${feeId}&classId=${classDivObj.classId}&divisionId=${classDivObj.divisionId}`)
        await axios.get(`${printClassDivisionFeeDueList}?acYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&langId=${authUser.branchMedium}&feeId=${feeId}&classId=${classDivObj.classId}&divisionId=${classDivObj.divisionId}`)
            .then((response) => {

                if (response.data === "NOTFOUND") {
                    setLoaderOption(false);
                    toast.warn("Data not exist with this class-Division.");
                }
                else if (response.data != null) {
                    //console.log(response.data)
                    window.open(`${getExportedReportAPIURL}${response.data}`);
                    setLoaderOption(false);
                }

            })
            .catch(error => {
                setLoaderOption(false);
                toast.error("Error Occoured, Try after sometime.");
                console.log("Fee Due List Report failed: " + error)
            })
    }

    const FeeDueListWithStudentPrint = async () => {
        // setFeeObj(null);
        // setClassDivObj(null);
        setLoaderOption(true);
        //console.log(`${printClassDivisionFeeDueList}?acYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&langId=${authUser.branchMedium}&feeId=${feeId}&classId=${classDivObj.classId}&divisionId=${classDivObj.divisionId}`)
        await axios.get(`${printStudentWiseFeeDueList}?acYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&langId=${authUser.branchMedium}&feeId=${feeId}&regNo=${studentId}`)
            .then((response) => {

                if (response.data === "NOTFOUND") {
                    setLoaderOption(false);
                    toast.warn("Data not exist with this Student.");
                }
                else if (response.data != null) {
                    //console.log(response.data)
                    window.open(`${getExportedReportAPIURL}${response.data}`);
                    setLoaderOption(false);
                }

            })
            .catch(error => {
                setLoaderOption(false);
                toast.error("Error Occoured, Try after sometime.");
                console.log("Fee Due List Report failed: " + error)
            })
    }


    const printFeeDueListForMultiFee = async () => {
        // setFeeObj(null);
        // setClassDivObj(null);
        setLoaderOption(true);
        console.log(`${printMultiFeeDueListReport}?langId=${authUser.branchMedium}&option=${reportOptionId}&classId=${(reportOptionId == 2) ? classDivObj.classId : 0}&divId=${(reportOptionId == 2) ? classDivObj.divisionId : 0}&year=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&feeType=${feeType.id}&regNo=${(reportOptionId == 3) ? studentId : 0}`)
        await axios.get(`${printMultiFeeDueListReport}?langId=${authUser.branchMedium}&option=${reportOptionId}&classId=${(reportOptionId == 2) ? classDivObj.classId : 0}&divId=${(reportOptionId == 2) ? classDivObj.divisionId : 0}&year=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&feeType=${feeType.id}&regNo=${(reportOptionId == 3) ? studentId : 0}`)
            .then((response) => {

                if (response.data === "NOTFOUND") {
                    setLoaderOption(false);
                    toast.warn("Data not exist with this Student.");
                }
                else if (response.data != null) {
                    //console.log(response.data)
                    window.open(`${getExportedReportAPIURL}${response.data} `);
                    setLoaderOption(false);
                }

            })
            .catch(error => {
                setLoaderOption(false);
                toast.error("Error Occoured, Try after sometime.");
                console.log("Fee Due List Report failed: " + error)
            })
    }

    const getFeeDueListReport = e => {
        e.preventDefault();
        if (authUser.feeType == 2) {
            printFeeDueListForMultiFee();
        }
        else {
            if (reportOptionId == 1) {
                FeeDueListPrint();
            }
            else if (reportOptionId == 2) {
                FeeDueListWithClassDivPrint();
            }
            else if (reportOptionId == 3) {
                FeeDueListWithStudentPrint();
            }
        }
    }


    if (loaderOption == true) {
        return <><Loader /></>
    }
    else {
        return (
            <>
                <TitleLabel titleId={titleId} />

                <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: '300px' }}>
                    {/* Form Data start */}
                    <form onSubmit={getFeeDueListReport}>
                        <div className="form-data">
                            <input type="hidden" value={id} onChange={(e) => setId(e.target.value)} name="" />

                            <div className="row my-1">
                                <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Report Option:</label>
                                <div className='col-sm-3'>
                                    <FormControl fullWidth margin='dense'>
                                        <InputLabel size="small" id='demo-simple-select-label' style={{ fontSize: '14px' }}>Select Option</InputLabel>
                                        <Select
                                            MenuProps={{ classes: { paper: classes.select } }}
                                            size='small'
                                            required
                                            fullWidth

                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={reportOptionId}
                                            label="Select Option"
                                            onChange={(e) => {
                                                setReportOptionId(e.target.value);
                                                setFeeType(null);
                                                setFeeObj(null);
                                                setClassDivObj(null);
                                                setStudentObj(null);
                                                setSelectedStudent("");
                                                setclassData("");
                                                setStudentId("");
                                                setDivision("");
                                            }}
                                        >
                                            <MenuItem value={1}>All</MenuItem>
                                            <MenuItem value={2}>Class-division Wise</MenuItem>
                                            <MenuItem value={3}>Student Wise</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>

                            {(() => {
                                if (authUser.feeType == 2) {
                                    return (
                                        <div className="row my-2">
                                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Fee Type :</label>
                                            <div className='col-sm-3'>
                                                <Autocomplete
                                                    id="combo-box-demo"
                                                    PaperComponent={({ children }) => (
                                                        <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                                    )}
                                                    options={feeTypeData}
                                                    style={{ fontSize: '14px' }}
                                                    getOptionLabel={option => option.feeLabel}
                                                    size="small"
                                                    value={feeType}
                                                    onChange={(event, newvalue) => {
                                                        setFeeType(newvalue);
                                                        setStudentObj(null);
                                                        setClassDivObj(null);
                                                        setSelectedStudent("");
                                                        setclassData("");
                                                        setStudentId("");
                                                        setDivision("");
                                                    }}
                                                    renderInput={params => (
                                                        <TextField {...params} margin="dense" label="Select Fee Type" InputLabelProps={{ style: { fontSize: 14 } }} variant="outlined" required />
                                                    )}
                                                />
                                            </div>
                                        </div>
                                    )
                                }
                                else {
                                    return (
                                        <div className="row my-2">
                                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Fee Name :</label>
                                            <div className='col-sm-4'>
                                                <Autocomplete
                                                    id="combo-box-demo"
                                                    PaperComponent={({ children }) => (
                                                        <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                                    )}
                                                    options={feeData}
                                                    style={{ fontSize: '14px' }}
                                                    getOptionLabel={option => (authUser.branchMedium == 1) ? option.nameMr : option.nameEn}
                                                    size="small"
                                                    value={feeObj}
                                                    onChange={(event, newvalue) => {
                                                        setFeeId(newvalue.id);
                                                        setFeeObj(newvalue);
                                                        setStudentObj(null);
                                                        setClassDivObj(null);
                                                        // setSelectedStudent("");
                                                        // setclassData("");
                                                        // setStudentId("");
                                                        // setDivision("");
                                                    }}

                                                    renderInput={params => (
                                                        <TextField {...params} margin="dense" label="Select Fee" InputLabelProps={{ style: { fontSize: 14 } }} variant="outlined" required />
                                                    )}
                                                />
                                            </div>
                                        </div>
                                    )
                                }
                            })()}


                            {(() => {
                                if (reportOptionId == 2) {
                                    return (
                                        <>
                                            <div className="row my-2">
                                                <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Class-Division :</label>
                                                <div className='col-sm-4'>
                                                    <Autocomplete
                                                        PaperComponent={({ children }) => (
                                                            <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                                        )}
                                                        id="combo-box-demo"
                                                        options={classDivData}
                                                        style={{ fontSize: '14px' }}
                                                        getOptionLabel={option => (authUser.branchMedium == 1) ? option.classNameMr + option.divisionNameMr : option.classNameEn + option.divisionNameEn}
                                                        size="small"
                                                        value={classDivObj}
                                                        onChange={(event, newvalue) => {
                                                            setClassDivId(newvalue.id);
                                                            setClassDivObj(newvalue);
                                                            setSelectedStudent("");
                                                            setclassData("");
                                                            setStudentId("");
                                                            setDivision("");
                                                        }}

                                                        renderInput={params => (
                                                            <TextField {...params} margin="dense" label="Select Class-Division" InputLabelProps={{ style: { fontSize: 14 } }} variant="outlined"
                                                                required={(reportOptionId == 2) ? true : false} />
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                        </>
                                    )
                                }
                                else if (reportOptionId == 3) {
                                    return (
                                        <>
                                            {/* <div className="row my-2">
                                                <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Student :</label>
                                                <div className='col-sm-4'>
                                                    <Autocomplete
                                                        id="combo-box-demo"
                                                        PaperComponent={({ children }) => (
                                                            <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                                        )}
                                                        open={open}
                                                        onOpen={() => {
                                                            setOpen(true);
                                                        }}
                                                        onClose={() => {
                                                            setOpen(false);
                                                        }}
                                                        loading={loading}
                                                        options={studentData}
                                                        style={{ fontSize: '14px' }}
                                                        getOptionLabel={option => (authUser.branchMedium == 1) ? option.regCode + " - " + option.studentNameMr : option.regCode + " - " + option.studentNameEn}
                                                        size="small"
                                                        value={studentObj}
                                                        onChange={(event, newvalue) => {
                                                            setStudentObj(newvalue);
                                                        }}

                                                        renderInput={params => (
                                                            <TextField {...params}
                                                                InputProps={{
                                                                    ...params.InputProps,
                                                                    endAdornment: (
                                                                        <React.Fragment>
                                                                            {loading ? <CircularProgress color="inherit" size={20} style={{ marginRight: "25px" }} /> : null}
                                                                            {params.InputProps.endAdornment}
                                                                        </React.Fragment>
                                                                    ),
                                                                }}
                                                                margin="dense" label="Select Student" InputLabelProps={{ style: { fontSize: 14 } }} variant="outlined"
                                                                required={(reportOptionId == 3) ? true : false} />
                                                        )}
                                                    />
                                                </div>
                                            </div> */}
                                            <div className="my-2">
                                                <AddStudentBySearching
                                                    setclassData={setclassData}
                                                    setDivision={setDivision}
                                                    setStudentId={setStudentId}
                                                    setSelectedStudent={setSelectedStudent}
                                                    classData={classData}
                                                    division={division}
                                                    studentId={studentId}
                                                    selectedStudent={selectedStudent}
                                                    setStudentLcNo={setStudentLcNo}
                                                    label="Fee Due List"
                                                    setStudentDetails={setStudentDetails}
                                                    className="col-sm-2"
                                                    readOnly={false}
                                                    setLoaderOption={setLoaderOption}
                                                />
                                            </div>
                                        </>
                                    )
                                }
                            })()}
                        </div>

                        <div className="row mt-3">
                            <div className="col-sm-4 offset-sm-2">
                                <button type="submit" className="btn btn-primary btn-sm">Show</button>
                            </div>
                        </div>
                    </form>
                </div>
                <ToastContainer position="top-right" theme="colored" />
            </>
        )
    }


}

export default FeeDueList