import React, { useState, useEffect } from "react";
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { useNavigate } from "react-router";
import axios from 'axios';
import { AfterLoginURL, GetYearAPIURL, getFormDataByRoleAndBranch } from '../../CommonServices/LoginAPIURL';
import { useDispatch } from 'react-redux';
import { login } from '../../reduxFeature/user';
import { MediumURL } from '../../CommonServices/LoginAPIURL';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { toast } from "react-toastify";

const AfterloginForm = ({ id, name, uname, email, rolesId, roles, department, branches, branchName, branchId, setShowLogin, branchMedium, accountingType, feeType, trustId, headers }) => {

    const dispatch = useDispatch();

    const navigate = useNavigate();


    //All data for  login storage

    const [roleId, setRoleId] = useState();
    const [roleName, setRoleName] = useState("");

    const [deptIndex, setDeptIndex] = useState("");
    const [deptId, setDeptId] = useState("");
    const [deptName, setDeptName] = useState("");

    const [branchIds, setBranchIds] = useState();
    const [branchNames, setBranchnames] = useState("");

    const [yearIndex, setYearIndex] = useState("");
    const [yearLabel, setYearLabel] = useState("");
    const [sessionYear, setSessionYear] = useState("");
    const [fromDate, setFromDate] = useState("");
    const [toDate, setTodate] = useState("");
    const [accApl, setAccApl] = useState("");
    const [newBranchMedium, setNewBranchMedium] = useState(1);
    const [uName, setUname] = useState("");


    // console.log("Role id: "+roleId+" Role Name:"+roleName+" Depi Id: "+deptId+ " Dept Name: "+deptName+ " branch id: "+branchIds+ "branch name: "+branchNames+ " yearlabel: "+yearLabel+ " sessionYear: "+sessionYear+ " fromDate: "+fromDate+ " toDate: "+toDate);

    const handleFinalSubmit = async () => {

        const userLoginData = {
            id: id,
            roleId: roleId,
            roleName: roleName,
            deptId: deptId,
            deptName: deptName,
            branchId: branchIds,
            branchName: branchNames,
            yearLabel: yearLabel,
            sessionYear: sessionYear,
            fromDate: fromDate,
            toDate: toDate,
            accApl: accApl,
            // branchMedium: (deptId === 4 && (branchIds === 17 || branchIds === 1)) ? 2 : branchMedium,
            branchMedium: newBranchMedium,
            username: uName,
            accountingType: accountingType,
            feeType: feeType,
            trustId: trustId,
            headers: headers

        }
        // console.log(userLoginData)
        dispatch(login({ id: id, roleId: roleId, roleName: roleName, deptId: deptId, deptName: deptName, branchId: branchIds, branchName: branchNames, yearLabel: yearLabel, sessionYear: sessionYear, fromDate: fromDate, toDate: toDate, accApl: accApl, branchMedium: newBranchMedium, username: uName, accountingType: accountingType, feeType: feeType, trustId: trustId, headers: headers }));
        sessionStorage.setItem("inst-sss-d", JSON.stringify(userLoginData));
        sessionStorage.setItem('menuName', "");
        localStorage.setItem('isDashboard', 'true');
        if (roleId == 9) {
            navigate("/Home/LibraryDashboard")
        }
        else if (roleId === 11) {
            navigate("/Home/HostelDashboard")
        }
        else if (accApl === 1) {
            if (roleId === 10) {
                navigate("/Home/f314/0")
            } else {
                navigate("/Home/ac04/0")
            }
        }
        else if (roleId === 13) {
            navigate("/Home/schoolEndow");
            // console.log("aaaa")
        }
        else {
            navigate("/Home/dashboard");
        }

        //(accApl === 1) ? navigate("/Home/ac04") : navigate("/Home/dashboard");
        //navigate("/Home/dashboard");

        const newHeaders = { ...headers, userId: id, branchId: branchIds, sectionId: deptId }
        // console.log(AfterLoginURL + "======" + JSON.stringify(newHeaders))
        axios
            .post(`${AfterLoginURL}`, newHeaders)
            .then((response) => {
                if (response.data != null) {
                    // console.log(response.data);
                } else {
                    // console.log("else");
                    toast.error("Operation failed.");
                }
            })
            .catch((error) => {
                console.log(error);
                toast.error(error);
            });

        await axios.get(`${getFormDataByRoleAndBranch}?roleId=${roleId}&bid=${branchIds}`)
            .then(res => {
                sessionStorage.setItem('FormProcessingFlag', JSON.stringify(res.data));
            })

    };

    const [year, setYear] = useState([]);

    useEffect(() => {
        getYear();
        setRoleId(rolesId);
        setRoleName(roles);
        setBranchIds(branchId);
        setBranchnames(branchName);
        setUname(uname)
        setDeptIndex(0)
        setDeptId(department[0].id);
        setDeptName((branchMedium == 1) ? department[0].nameMr : department[0].nameEn);
        setAccApl(department[0].acApl);

        axios.get(`${MediumURL}?bid=${branchId}&sid=${department[0].id}`)
            .then((response) => {
                setNewBranchMedium(response.data);
            }).catch((error) => {
                // Handle error
                console.error("Error fetching data:", error);
            });
    }, []);


    const getYear = () => {
        // console.log(GetYearAPIURL);
        axios.get(GetYearAPIURL)
            .then((response) => {
                setYear(response.data);
                setYearIndex(0);
                setYearLabel(response.data[0].yearLabel);
                setSessionYear(response.data[0].sessionYear);
                setFromDate(response.data[0].fromDate);
                setTodate(response.data[0].toDate);
            })
    }

    const backToLogin = () => {
        setShowLogin(true);
        navigate("/");
        // localStorage.removeItem('isDashboard', 'true');
    }
    //console.log(branchMedium + "bsddfffffffffffffffff")

    const handleBranchMedium = (index) => {
        // alert(department[index].nameMr);
        setDeptIndex(index);
        setDeptId(department[index].id);
        setDeptName((branchMedium == 1) ? department[index].nameMr : department[index].nameEn);
        setAccApl(department[index].acApl);

        axios.get(`${MediumURL}?bid=${branchId}&sid=${department[index].id}`)
            .then((response) => {
                setNewBranchMedium(response.data);
            }).catch((error) => {
                // Handle error
                console.error("Error fetching data:", error);
            });

    }

    const handleYear = (index) => {
        // year[index]
        setYearIndex(index);
        setYearLabel(year[index].yearLabel);
        setSessionYear(year[index].sessionYear);
        setFromDate(year[index].fromDate);
        setTodate(year[index].toDate);
    }

    useEffect(() => {
        function handlekeydownEvent(event) {
            if (event.key === "Enter") {
                // console.log("Enter is pressed!")
                event.preventDefault();

                let btn1 = document.querySelector('.myProceedBtn')
                if (btn1 !== null) {
                    btn1.click();
                }

            }
        }

        document.addEventListener('keypress', handlekeydownEvent)
        return () => {
            document.removeEventListener('keypress', handlekeydownEvent)
        }

    }, []);

    return (
        <>
            <div className="col-sm-12 p-0 m-0">
                <form onSubmit={handleFinalSubmit}>
                    <div className="mt-4" style={{ padding: "5px" }}>
                        <h3 style={{ color: "#004692", padding: "16px 0px 0px 16px" }}><strong>PROFILE DETAILS</strong></h3>


                        <div className="mt-4">
                            <div className="col-sm-12 mt-3">
                                <TextField
                                    required={true}
                                    fullWidth
                                    size='small'
                                    id="input-with-sx"
                                    label="Branch Name"
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    value={branchName}
                                    variant="outlined"
                                    InputLabelProps={{ style: { fontSize: 14 } }} />
                            </div>


                            <div className="col-sm-12 mt-4">

                                <FormControl fullWidth margin='dense'>
                                    <InputLabel id="demo-simple-select-label" size="small" style={{ fontSize: "14px" }}>Select Option</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        required
                                        id="demo-simple-select"
                                        size='small'
                                        value={deptIndex}
                                        label="Select Option"
                                        onChange={(event) => { handleBranchMedium(event.target.value); }}
                                        onKeyPress={(e) => (e.key == "Enter") ? deptIndex : ""}
                                    >
                                        {
                                            department.map((e, index) => {
                                                return (
                                                    <MenuItem key={index} value={index}>{(branchMedium == 1) ? e.nameMr : e.nameEn}</MenuItem>
                                                )
                                            })
                                        }
                                    </Select>
                                </FormControl>

                            </div>

                            <div className="col-sm-12 mt-4">
                                <FormControl fullWidth margin='dense'>
                                    <InputLabel id="demo-simple-select-label" size="small" style={{ fontSize: "14px" }}>Select Option</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        required
                                        id="demo-simple-select"
                                        size='small'
                                        value={yearIndex}
                                        label="Select Option"
                                        onChange={(event) => { handleYear(event.target.value); }}
                                        onKeyPress={(e) => (e.key == "Enter") ? yearIndex : ""}
                                    >
                                        {
                                            year.map((e, index) => {
                                                return (
                                                    <MenuItem key={index} value={index}>{e.yearLabel}</MenuItem>
                                                )
                                            })
                                        }
                                    </Select>
                                </FormControl>
                            </div>
                        </div>

                        <div className='col-sm-12 mt-4 mb-3'>
                            <Button type='submit' className="myProceedBtn" style={{ backgroundColor: "#0776EA", boxShadow: "0px 5px 10px #6C7A89", borderRadius: "20px", padding: "8px 12px", color: "white" }} variant='contained'>PROCEED</Button>
                            <Button className='mx-2' onClick={backToLogin} style={{ backgroundColor: "#C3222C", boxShadow: "0px 5px 10px #6C7A89", borderRadius: "20px", padding: "8px 12px", color: "white" }} variant='contained'>CANCEL</Button>
                        </div>
                    </div>
                </form>
            </div>
        </>
    )
}

export default AfterloginForm;