import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import axios from "axios";
import TitleLabel from "../../CommonComponent/TitleLabel";
import { BaseURLMaster, dBaseURLMaster } from "../../CommonServices/APIURL";
import { ToastContainer, toast } from 'react-toastify';
import TextField from '@mui/material/TextField';
import 'react-toastify/dist/ReactToastify.css';
import { useSelector } from "react-redux";
import Loader from '../../CommonComponent/Loader';
import labelName from "../Services/MasterForm1LabelArray";
import MasterForm2LabelTable from "./MasterForm2LabelTable";


const MasterForm2Label = () => {

    const authUser = useSelector((state) => state.user.value);
    let { formid } = useParams();
    const [loaderOption, setLoaderOption] = useState(false);



    //Note: This below states are declared for Save & Update(Disabled/unDisabled)
    const saveBtnStyle = "oneLabelFormSaveBtn btn btn-primary btn-sm";
    const updateBtnStyle = "oneLabelFormUpdateBtn btn btn-primary btn-sm";
    const displayNoneBtnStyle = "d-none";
    const [saveBtn, setSaveBtn] = useState(saveBtnStyle);
    const [updateBtn, setUpdateBtn] = useState(displayNoneBtnStyle);


    const [id, setId] = useState("");

    const [titleId, setTitleId] = useState("");
    const [getMapping, setGetMapping] = useState("");
    const [postMapping, setPostMapping] = useState("");
    const [putMapping, setPutMapping] = useState("");
    const [deleteMapping, setDeleteMapping] = useState("");

    const [name, setName] = useState("");

    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);

    const TableHeading = [{ label: 'ID', key: 'id' }, { label: `${titleId} Name`, key: 'nameMr' }, { label: 'Edit', value: 'edit', isAction: true }, { label: 'Delete', value: 'delete', isAction: true }];

    const mapData = () => {
        labelName.map((labels) => {
            if (labels.id == formid) {
                setTitleId(labels.title);
                setGetMapping(BaseURLMaster + "" + labels.GetMapping);
                setPostMapping(BaseURLMaster + "" + labels.PostMapping);
                setPutMapping(BaseURLMaster + "" + labels.PutMapping);
                setDeleteMapping(BaseURLMaster + "" + labels.DeleteMapping);
                // console.log(labels.GetMapping)               
            }
        });
    }

    useEffect(() => {
        mapData();
        //getData();
        if (getMapping != "") {
            getData();
        }

    }, [getMapping, postMapping, putMapping, deleteMapping, titleId, formid])

    const getData = async () => {
        await axios.get(`${getMapping}`)
            .then((response) => {
                setData(response.data);
                setFilteredData(response.data);
            })
    }

    const saveBtnClick = async () => {
        setLoaderOption(true);

        const saveObject = {
            id: id,
            nameMr: name,

        };
        //console.log(JSON.stringify(saveObject))
        await axios.post(`${postMapping}`, saveObject)
            .then((response) => {
                setLoaderOption(false);
                if (response.data == "ALREADYEXIST") {
                    setLoaderOption(false);
                    toast.warn(`${name} ${titleId} name is Already Exists.`)
                }
                else if (response.data != null) {
                    getData();
                    setName("");
                    setId("");
                    setSaveBtn(saveBtnStyle);
                    setUpdateBtn(displayNoneBtnStyle);
                    setLoaderOption(false);
                    toast.success("Saved successfully.");
                } else {
                    setLoaderOption(false);
                    toast.error("Operation failed.");
                }
            });


    };

    const updateBtnClick = async () => {
        setLoaderOption(true);
        const updateObject = {
            id: id,
            nameMr: name
        };

        await axios.put(`${putMapping}` + "/" + id, updateObject)
            .then((response) => {
                setLoaderOption(false);
                if (response.data == "ALREADYEXIST") {
                    setLoaderOption(false);
                    toast.warn(`${name} ${titleId} name is Already Exists.`)
                }
                else if (response.data != null) {
                    getData();
                    setId("");
                    setName("");
                    setSaveBtn(saveBtnStyle);
                    setUpdateBtn(displayNoneBtnStyle);
                    setLoaderOption(false);
                    toast.success("Update sucessfully.");
                } else {
                    setLoaderOption(false);
                    toast.danger("Operation failed.");
                }
            });


    };

    const ClearData = () => {
        setId("");
        setName("");
        setSaveBtn(saveBtnStyle);
        setUpdateBtn(displayNoneBtnStyle);
    }

    const state = {
        button: 1
    };

    const onSubmit = e => {
        e.preventDefault();
        if (state.button === 1) {
            saveBtnClick();
        }
        if (state.button === 2) {
            updateBtnClick();
        }
    };

    useEffect(() => {

        function handlekeydownEvent(event) {
            if (event.key === "Enter") {
                // console.log("Enter is pressed!")
                event.preventDefault();

                let btn1 = document.querySelector('.oneLabelFormSaveBtn')
                if (btn1 !== null) {
                    btn1.click();
                }
                let btn2 = document.querySelector('.oneLabelFormUpdateBtn')
                if (btn2 !== null) {
                    btn2.click();
                }

            }
        }

        document.addEventListener('keypress', handlekeydownEvent)
        return () => {
            document.removeEventListener('keypress', handlekeydownEvent)
        }

    }, []);


    return (
        <>
            {(loaderOption == true)
                ?
                <Loader />
                :
                <div>
                    <TitleLabel titleId={titleId} />
                    <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: '350px' }}>
                        {/* Form Data start */}
                        <form onSubmit={onSubmit}>
                            <div className="form-data mt-1">
                                <input type="hidden" value={id} onChange={(e) => setId(e.target.value)} name="" />
                                <div className="row">
                                    <label htmlFor="lastname3" className="col-sm-2" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>{titleId} Name :</label>
                                    <div className='col-sm-4'>
                                        <TextField
                                            required
                                            fullWidth
                                            onKeyDown={(e) => (e.key === " " && name.length === 0) ? e.preventDefault() : ""}
                                            autoComplete='off'
                                            className=''
                                            size='small'
                                            id="outlined-basic"
                                            label={titleId + " " + "Name"}
                                            variant="outlined"
                                            margin='dense'
                                            value={name}
                                            onChange={(e) => setName(e.target.value)}
                                            onKeyPress={(e) => (e.key == "Enter") ? name : ""}
                                            inputProps={{ maxLength: 120 }}
                                            InputLabelProps={{ style: { fontSize: 14 } }}
                                        />
                                    </div>
                                </div>

                                <div className="col-sm-12 mt-3">
                                    <div className="offset-sm-2">
                                        <button type="submit" onClick={() => (state.button = 1)} className={saveBtn} >Save</button>
                                        <button type="submit" onClick={() => (state.button = 2)} className={updateBtn} >Update</button>
                                        <button type="button" onClick={ClearData} className="btn btn-danger btn-sm" style={{ margin: '0px 4px' }} >Cancel</button>

                                    </div>
                                </div>
                            </div>
                        </form>
                        <div className="tableData">
                            <MasterForm2LabelTable
                                deleteMapping={deleteMapping}
                                TableHeading={TableHeading}
                                setId={setId}
                                setName={setName}
                                getData={getData}
                                data={data}
                                setFilteredData={setFilteredData}
                                filteredData={filteredData}
                                setData={setData}
                                saveBtnStyle={saveBtnStyle}
                                updateBtnStyle={updateBtnStyle}
                                displayNoneBtnStyle={displayNoneBtnStyle}
                                setSaveBtn={setSaveBtn}
                                setUpdateBtn={setUpdateBtn}
                            />
                        </div>
                    </div>
                </div>
            }

            <ToastContainer position="top-right" theme="colored" />
        </>
    )
}

export default MasterForm2Label