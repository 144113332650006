import axios from "axios";
import CryptoJS from "crypto-js";


export const getUserIP = async () => {
    try {
        const response = await axios.get('https://api.ipify.org?format=json');
        return response.data.ip;
    } catch (error) {
        console.error('Error fetching IP address:', error);
        return null;
    }
};

// Helper functions to get device and location data
export const getUserLocation = () => {
    return new Promise((resolve) => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    resolve({
                        lat: Number(position.coords.latitude.toFixed(7)), // 7 decimal places
                        long: Number(position.coords.longitude.toFixed(7)), // 7 decimal places
                    });
                },
                (error) => {
                    console.error("Error retrieving location:", error.message);

                    let errorMessage = "Unknown error";
                    switch (error.code) {
                        case error.PERMISSION_DENIED:
                            errorMessage = "User denied the request for Geolocation.";
                            break;
                        case error.POSITION_UNAVAILABLE:
                            errorMessage = "Location information is unavailable.";
                            break;
                        case error.TIMEOUT:
                            errorMessage = "The request to get user location timed out.";
                            break;
                        case error.UNKNOWN_ERROR:
                            errorMessage = "An unknown error occurred.";
                            break;
                    }

                    console.warn("Geolocation Error:", errorMessage);

                    resolve({
                        lat: 0,
                        long: 0,
                        error: errorMessage, // Return error message for debugging
                    });
                },
                { enableHighAccuracy: true, timeout: 10000, maximumAge: 0 } // Adjust settings
            );
        } else {
            console.warn("Geolocation is not supported by this browser.");
            resolve({
                lat: 0,
                long: 0,
                error: "Geolocation is not supported",
            });
        }
    });
};

// export const getUserLocation = () => {
//     return new Promise((resolve) => {
//         if (navigator.geolocation) {
//             // Ask for permission first
//             if (window.confirm("This application needs your location to proceed. Do you want to allow access?")) {
//                 navigator.geolocation.getCurrentPosition(
//                     (position) => {
//                         resolve({
//                             lat: Number(position.coords.latitude.toFixed(7)), // 7 decimal places
//                             long: Number(position.coords.longitude.toFixed(7)), // 7 decimal places
//                         });
//                     },
//                     (error) => {
//                         console.warn("Geolocation Error:", error.message);
//                         resolve({
//                             lat: 0,
//                             long: 0,
//                         }); // Proceed with lat: 0, long: 0
//                     },
//                     { enableHighAccuracy: true, timeout: 10000, maximumAge: 0 }
//                 );
//             } else {
//                 console.warn("User denied location access.");
//                 resolve({
//                     lat: 0,
//                     long: 0,
//                 }); // Proceed with lat: 0, long: 0
//             }
//         } else {
//             console.warn("Geolocation is not supported by this browser.");
//             resolve({
//                 lat: 0,
//                 long: 0,
//             }); // Proceed with lat: 0, long: 0
//         }
//     });
// };


// export const getUserLocation = () => {
//     return new Promise((resolve) => {
//         if (navigator.geolocation) {
//             navigator.permissions.query({ name: "geolocation" }).then((permissionStatus) => {
//                 if (permissionStatus.state === "denied") {
//                     // Alert user & open settings
//                     if (window.confirm("Location access is denied. Do you want to enable it in settings?")) {
//                         openLocationSettings();
//                     }
//                     resolve({ lat: 0, long: 0 });
//                 } else {
//                     navigator.geolocation.getCurrentPosition(
//                         (position) => {
//                             resolve({
//                                 lat: Number(position.coords.latitude.toFixed(7)),
//                                 long: Number(position.coords.longitude.toFixed(7)),
//                             });
//                         },
//                         (error) => {
//                             console.warn("Geolocation Error:", error.message);
//                             if (error.code === error.PERMISSION_DENIED) {
//                                 if (window.confirm("Location access was denied. Open settings to enable?")) {
//                                     openLocationSettings();
//                                 }
//                             }
//                             resolve({ lat: 0, long: 0 });
//                         },
//                         { enableHighAccuracy: true, timeout: 10000, maximumAge: 0 }
//                     );
//                 }
//             });
//         } else {
//             console.warn("Geolocation is not supported by this browser.");
//             alert("Your browser does not support location services.");
//             resolve({ lat: 0, long: 0 });
//         }
//     });
// };

// // Function to open location settings
// const openLocationSettings = () => {
//     const isAndroid = /Android/i.test(navigator.userAgent);
//     const isiOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);

//     if (isAndroid) {
//         // Open Android location settings
//         window.location.href = "intent://settings#Intent;scheme=android.settings.LOCATION_SOURCE_SETTINGS;end;";
//     } else if (isiOS) {
//         // Open iOS settings (Only works in Safari)
//         window.location.href = "App-Prefs:root=LOCATION_SERVICES";
//     } else {
//         alert("Please enable location manually in your browser settings.");
//     }
// };


export const getDeviceDetails = () => {
    const userAgent = navigator.userAgent;
    let browserName = "Unknown";
    let browserVersion = "Unknown Version";
    let deviceType = /Mobi|Android|iPhone|iPad|iPod/i.test(userAgent) ? "Mobile" : "Desktop";
    let deviceOS = "Unknown";
    let deviceOSVersion = "Unknown OS Version";
    let renderingEngine = "Unknown";

    // Detect OS and OS version
    if (/Windows NT 10.0/i.test(userAgent)) {
        deviceOS = "Windows";
        deviceOSVersion = "10";
    } else if (/Windows NT 6.3/i.test(userAgent)) {
        deviceOS = "Windows";
        deviceOSVersion = "8.1";
    } else if (/Windows NT 6.2/i.test(userAgent)) {
        deviceOS = "Windows";
        deviceOSVersion = "8";
    } else if (/Windows NT 6.1/i.test(userAgent)) {
        deviceOS = "Windows";
        deviceOSVersion = "7";
    } else if (/Mac OS X (\d+[_\d]+)/i.test(userAgent)) {
        deviceOS = "macOS";
        deviceOSVersion = userAgent.match(/Mac OS X (\d+[_\d]+)/i)[1].replace(/_/g, ".");
    } else if (/Android (\d+(\.\d+)?)/i.test(userAgent)) {
        deviceOS = "Android";
        deviceOSVersion = userAgent.match(/Android (\d+(\.\d+)?)/i)[1];
    } else if (/iPhone OS (\d+[_\d]+)/i.test(userAgent)) {
        deviceOS = "iOS";
        deviceOSVersion = userAgent.match(/iPhone OS (\d+[_\d]+)/i)[1].replace(/_/g, ".");
    } else if (/iPad.*OS (\d+[_\d]+)/i.test(userAgent)) {
        deviceOS = "iPadOS";
        deviceOSVersion = userAgent.match(/iPad.*OS (\d+[_\d]+)/i)[1].replace(/_/g, ".");
    }

    // Detect browser and version
    if (/Edg\/(\d+\.\d+)/i.test(userAgent)) {
        browserName = "Microsoft Edge";
        browserVersion = userAgent.match(/Edg\/(\d+\.\d+)/i)[1];
        renderingEngine = "Blink";
    } else if (/Chrome\/(\d+\.\d+)/i.test(userAgent)) {
        browserName = "Google Chrome";
        browserVersion = userAgent.match(/Chrome\/(\d+\.\d+)/i)[1];
        renderingEngine = "Blink";
    } else if (/Firefox\/(\d+\.\d+)/i.test(userAgent)) {
        browserName = "Mozilla Firefox";
        browserVersion = userAgent.match(/Firefox\/(\d+\.\d+)/i)[1];
        renderingEngine = "Gecko";
    } else if (/Safari\/(\d+\.\d+)/i.test(userAgent) && !/Chrome/i.test(userAgent)) {
        browserName = "Safari";
        browserVersion = userAgent.match(/Version\/(\d+\.\d+)/i)?.[1] || "Unknown";
        renderingEngine = "WebKit";
    } else if (/MSIE (\d+\.\d+)/i.test(userAgent)) {
        browserName = "Internet Explorer";
        browserVersion = userAgent.match(/MSIE (\d+\.\d+)/i)[1];
        renderingEngine = "Trident";
    } else if (/Trident.*rv:(\d+\.\d+)/i.test(userAgent)) {
        browserName = "Internet Explorer";
        browserVersion = userAgent.match(/Trident.*rv:(\d+\.\d+)/i)[1];
        renderingEngine = "Trident";
    }

    return {
        deviceType,
        deviceOS,
        deviceOSVersion,
        browserName,
        browserVersion,
        renderingEngine,
        browserUserAgent: userAgent,
    };
};

export const generateDeviceSignature = (data) => {
    return CryptoJS.SHA256(JSON.stringify(data)).toString();
};
