import React, { useState, useEffect } from 'react';
import TitleLabel from "../../CommonComponent/TitleLabel";
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { useSelector } from "react-redux";
import Paper from "@mui/material/Paper";
import TextField from '@mui/material/TextField';
import Autocomplete from "@mui/material/Autocomplete";
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import { categoryDataURl, classDivisionURL, allCategoryReport, categoryClassDivisionReport, categoryWiseReport, classDivAndCategoryWiseReport } from '../Services/CategoryReportAPIURL'
import { getMasterReportAPIURL } from '../../Masters/Services/MasterReportAPIURL';
import { faClosedCaptioning } from '@fortawesome/free-solid-svg-icons';
import { TramRounded } from '@mui/icons-material';
import Loader from '../../CommonComponent/Loader';
import Checkbox from '@mui/material/Checkbox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles(theme => ({
    select: {
        maxHeight: 160,
        "& ul": {
            backgroundColor: "#F4ECF7",
            //maxHeight: 160,
        },
        "& li": {
            // backgroundColor: "#F4ECF7", fontWeight: "550", fontSize: "14px" 
            fontSize: "14px",
            fontWeight: "550"
        },
    },

}));

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const optionData = [
    {
        id: 1,
        optionName: "All"
    },
    {
        id: 2,
        optionName: "Class-Division Wise"
    },
    {
        id: 3,
        optionName: "Category Wise"
    },
    {
        id: 4,
        optionName: "Class-Division & Category Wise"
    }
]



const CategorywiseStudReport = () => {
    const classes = useStyles();
    const authUser = useSelector((state) => state.user.value);
    const titleId = "Category Report";

    const [loaderOption, setLoaderOption] = useState(false);


    const [categoryData, setCategoryData] = useState([]);
    const [categoryId, setCategoryId] = useState(null);
    const [classCategoryData, setClassCategoryData] = useState([]);
    const [classData, setClassData] = useState([]);
    const [optionObj, setOptionObj] = useState(null);
    const [optionId, setOptionId] = useState(1);
    const [categoryObj, setCategoryObj] = useState(null);
    const [selectedCategoryObj, setSelectedCategoryObj] = useState([]);
    const [selectedCategoryId, setSelectedCategoryId] = useState([]);
    const [classObj, setClassObj] = useState(null);
    const [classCategoryObj, setClassCategoryObj] = useState(null);
    const [classId, setClassId] = useState("");
    const [divisionId, setDivisionId] = useState("");
    const [gender, setGender] = useState("");

    //console.log(selectedCategoryId);


    useEffect(() => {
        getData();
    }, [authUser]);

    const getData = async () => {
        await axios(`${classDivisionURL}?sid=${authUser.deptId}&bid=${authUser.branchId}`)
            .then(res => {
                setClassData(res.data);
            })
        await axios(`${categoryDataURl}`)
            .then(res => {
                setCategoryData(res.data);
            })

    }

    //all categories stud report
    const allCategoryWiseReport = async () => {
        setLoaderOption(true);
        await axios.get(`${allCategoryReport}?langId=${authUser.branchMedium}&acYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&gender=${gender.value}`)
            .then((response) => {
                if (response.data == "NOTFOUND") {
                    //console.log(response.data)
                    setLoaderOption(false);
                    toast.warn("Data not exists.")
                }
                else if (response.data != null) {
                    //console.log(response.data)   

                    window.open(`${getMasterReportAPIURL}${response.data}`);
                    setLoaderOption(false);
                }
            })
            .catch(error => {
                setLoaderOption(false);
                toast.error("Something went wrong, try after sometime.")
                console.log("Category Report failed: " + error)
            })
    }

    //class div wise report
    const classDivisionWiseCategoryReport = async () => {
        setLoaderOption(true);
        await axios.get(`${categoryClassDivisionReport}?langId=${authUser.branchMedium}&acYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&classId=${classId}&divId=${divisionId}&gender=${gender.value}`)
            .then((response) => {
                if (response.data == "NOTFOUND") {
                    //console.log(response.data)  
                    setLoaderOption(false);
                    toast.warn("Data not exists with this class-division")
                }
                else if (response.data != null) {
                    //console.log(response.data)  

                    window.open(`${getMasterReportAPIURL}${response.data}`);
                    setLoaderOption(false);
                }
            })
            .catch(error => {
                setLoaderOption(false);
                toast.error("Something went wrong, try after sometime.")
                console.log("Category Report failed: " + error)
            })
    }

    //category wise
    const categoryWiseCategoryReport = async () => {
        setLoaderOption(true);
        //  console.log(`${categoryWiseReport}?langId=${authUser.branchMedium}&acYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&categoryId=${selectedCategoryId}`)
        await axios.get(`${categoryWiseReport}?langId=${authUser.branchMedium}&acYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&categoryId=${selectedCategoryId}&gender=${gender.value}`)
            .then((response) => {
                if (response.data === "NOTFOUND") {
                    setLoaderOption(false);
                    toast.warn("Data not exist with this Category");
                }
                else if (response.data != null) {
                    //console.log(response.data)                                     
                    window.open(`${getMasterReportAPIURL}${response.data}`);
                    setLoaderOption(false);
                }
            })
            .catch(error => {
                setLoaderOption(false);
                toast.error("Something went wrong, try after sometime.")
                console.log("Category Report failed: " + error)
            })
    }

    //categort and class-div wise
    const categoryAndClassDivWiseCategoryReport = async () => {
        setLoaderOption(TramRounded);
        // console.log(`${classDivAndCategoryWiseReport}?langId=${authUser.branchMedium}&acYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&classId=${classId}&divId=${divisionId}&categoryId=${selectedCategoryId}`)
        await axios.get(`${classDivAndCategoryWiseReport}?langId=${authUser.branchMedium}&acYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&classId=${classId}&divId=${divisionId}&categoryId=${selectedCategoryId}&gender=${gender.value}`)
            .then((response) => {
                if (response.data === "NOTFOUND") {
                    setLoaderOption(false);
                    toast.warn("Data not exist with this class-Division or category");
                }
                else if (response.data != null) {
                    //console.log(response.data)                               
                    window.open(`${getMasterReportAPIURL}${response.data}`);
                    setLoaderOption(false);
                }
            })
            .catch(error => {
                setLoaderOption(false);
                toast.error("Something went wrong, try after sometime.")
                console.log("Category Report failed: " + error)
            })
    }

    const getCategoryWiseStudentReport = e => {
        e.preventDefault();
        if (optionId == 1) {
            allCategoryWiseReport();
        }
        else if (optionId == 2) {
            classDivisionWiseCategoryReport();
        }
        else if (optionId == 3) {
            categoryWiseCategoryReport();
        }
        else if (optionId == 4) {
            categoryAndClassDivWiseCategoryReport();
        }
    }

    if (loaderOption == true) {
        return <Loader />
    }
    else {
        return (
            <>
                <TitleLabel titleId={titleId} />

                <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: '350px' }}>
                    <form onSubmit={getCategoryWiseStudentReport}>
                        <div className='row'>
                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Report Option :</label>
                            <div className='col-sm-3'>
                                <FormControl fullWidth margin='dense'>
                                    <InputLabel id='demo-simple-select-label' size="small" style={{ fontSize: '14px' }}>Select Report Option</InputLabel>
                                    <Select
                                        MenuProps={{ classes: { paper: classes.select } }}
                                        size='small'
                                        required
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={optionId}
                                        label="Select Report Option"
                                        onChange={(e) => {
                                            setOptionId(e.target.value);
                                            setClassObj(null);
                                            setCategoryObj(null);
                                            setSelectedCategoryObj([]);
                                        }}
                                    >
                                        {optionData.map(item => {
                                            return (
                                                <MenuItem value={item.id}>{item.optionName}</MenuItem>
                                            )
                                        })}
                                    </Select>
                                </FormControl>
                            </div>
                        </div>

                        {(() => {
                            if (optionId == 2) {
                                return (
                                    <>
                                        <div className='row my-2'>
                                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Class-Division :</label>
                                            <div className='col-sm-4'>
                                                <Autocomplete
                                                    id="combo-box-demo"
                                                    options={classData}
                                                    style={{ fontSize: '14px' }}
                                                    PaperComponent={({ children }) => (
                                                        <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                                    )}
                                                    onChange={(event, item) => {
                                                        setClassObj(item);
                                                        if (item != null) {
                                                            setClassId(item.classId);
                                                            setDivisionId(item.divisionId);
                                                        }
                                                    }}
                                                    value={classObj}
                                                    getOptionLabel={option => (authUser.branchMedium == 1) ? option.classNameMr + " " + option.divisionNameMr : option.classNameEn + " " + option.divisionNameEn}
                                                    size="small"
                                                    renderInput={params => (
                                                        <TextField {...params} margin='dense' inputProps={{ ...params.inputProps, autoComplete: "off" }} label="Select Class-Division" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }}
                                                            required={(optionId == 2) ? true : false} />
                                                    )}
                                                />
                                            </div>
                                        </div>
                                    </>
                                )
                            }
                            else if (optionId == 3) {
                                return (
                                    <>
                                        <div className='row my-2'>
                                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Category :</label>
                                            <div className='col-sm-4'>
                                                <Autocomplete
                                                    id="combo-box-demo"
                                                    multiple
                                                    //filterSelectedOptions
                                                    disableCloseOnSelect
                                                    options={categoryData}
                                                    style={{ fontSize: '14px' }}
                                                    PaperComponent={({ children }) => (
                                                        <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                                    )}
                                                    onChange={(event, item) => {
                                                        setSelectedCategoryObj(item);
                                                        if (item != null) {
                                                            setSelectedCategoryId(item.map(e => e.id).join(','));
                                                        }
                                                    }}
                                                    renderOption={(props, option, { selected }) => (
                                                        <li {...props}>
                                                            <Checkbox
                                                                icon={icon}
                                                                checkedIcon={checkedIcon}
                                                                style={{ marginRight: 8 }}
                                                                checked={selected}
                                                            />
                                                            {(authUser.branchMedium == 1) ? option.nameMr : option.nameEn}
                                                        </li>
                                                    )}
                                                    value={selectedCategoryObj}
                                                    getOptionLabel={option => (authUser.branchMedium == 1) ? option.nameMr : option.nameEn}
                                                    size="small"
                                                    renderInput={params => (
                                                        <TextField {...params} margin='dense' inputProps={{ ...params.inputProps, autoComplete: "off" }} label="Select Category" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }}
                                                            required={(optionId == 3 && selectedCategoryObj.length == 0) ? true : false} />
                                                    )}
                                                />
                                            </div>
                                        </div>
                                    </>
                                )
                            }
                            else if (optionId == 4) {
                                return (
                                    <>
                                        <div className='row my-2'>
                                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Class-Division :</label>
                                            <div className='col-sm-4'>
                                                <Autocomplete
                                                    id="combo-box-demo"
                                                    options={classData}
                                                    style={{ fontSize: '14px' }}
                                                    PaperComponent={({ children }) => (
                                                        <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                                    )}
                                                    onChange={(event, item) => {
                                                        setClassObj(item);
                                                        if (item != null) {
                                                            setClassId(item.classId);
                                                            setDivisionId(item.divisionId);
                                                        }
                                                        setSelectedCategoryObj([]);
                                                    }}
                                                    value={classObj}
                                                    getOptionLabel={option => (authUser.branchMedium == 1) ? option.classNameMr + " " + option.divisionNameMr : option.classNameEn + " " + option.divisionNameEn}
                                                    size="small"
                                                    renderInput={params => (
                                                        <TextField {...params} margin='dense' inputProps={{ ...params.inputProps, autoComplete: "off" }} label="Select Class-Division" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }}
                                                            required={(optionId == 4) ? true : false} />
                                                    )}
                                                />
                                            </div>
                                        </div>

                                        <div className='row my-2'>
                                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Category :</label>
                                            <div className='col-sm-4'>
                                                <Autocomplete
                                                    id="combo-box-demo"
                                                    multiple
                                                    //filterSelectedOptions
                                                    disableCloseOnSelect
                                                    options={categoryData}
                                                    style={{ fontSize: '14px' }}
                                                    PaperComponent={({ children }) => (
                                                        <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                                    )}
                                                    onChange={(event, item) => {
                                                        setSelectedCategoryObj(item);
                                                        if (item != null) {
                                                            setSelectedCategoryId(item.map(e => e.id).join(','));
                                                        }

                                                    }}
                                                    renderOption={(props, option, { selected }) => (
                                                        <li {...props}>
                                                            <Checkbox
                                                                icon={icon}
                                                                checkedIcon={checkedIcon}
                                                                style={{ marginRight: 8 }}
                                                                checked={selected}
                                                            />
                                                            {(authUser.branchMedium == 1) ? option.nameMr : option.nameEn}
                                                        </li>
                                                    )}
                                                    value={selectedCategoryObj}
                                                    getOptionLabel={option => (authUser.branchMedium == 1) ? option.nameMr : option.nameEn}
                                                    size="small"
                                                    renderInput={params => (
                                                        <TextField {...params} margin='dense' inputProps={{ ...params.inputProps, autoComplete: "off" }} label="Select Category" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }}
                                                            required={(optionId == 4 && selectedCategoryObj.length == 0) ? true : false} />
                                                    )}
                                                />
                                            </div>
                                        </div>
                                    </>
                                )
                            }
                        })()}
                        <div className='row'>

                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>
                                Gender :
                            </label>

                            <div className='col-sm-3'>
                                <FormControl fullWidth margin='dense'>
                                    <Autocomplete
                                        size="small"
                                        id="gender-select"
                                        options={[
                                            { label: "All", value: "0" },
                                            { label: "Boys", value: "1" },
                                            { label: "Girls", value: "2" }
                                        ]}
                                        getOptionLabel={(option) => option.label}
                                        value={gender || null}
                                        onChange={(event, newValue) => setGender(newValue)}

                                        renderInput={(params) => (
                                            <TextField {...params} label="Select Gender" variant="outlined" />
                                        )}
                                        PaperComponent={({ children }) => (
                                            <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                        )}
                                    />
                                </FormControl>
                            </div>

                        </div>



                        <div className="row mt-3">
                            <div className="col-sm-4 offset-sm-2">
                                <button type="submit" className="btn btn-primary btn-sm">Show</button>
                            </div>
                        </div>
                    </form>

                </div>
                {/* <ToastContainer position="top-right" theme="colored" /> */}
            </>
        )
    }

}

export default CategorywiseStudReport