import React, { useState, useEffect } from 'react';
import TitleLabel from "../../CommonComponent/TitleLabel";
import axios from 'axios';
import Paper from "@mui/material/Paper";
import TextField from '@mui/material/TextField';
import Autocomplete from "@mui/material/Autocomplete";
import PrintIcon from '@mui/icons-material/Print';
import { ToastContainer, toast } from 'react-toastify';
import { useSelector } from "react-redux";
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Loader from '../../CommonComponent/Loader';
import { bulkLcPrint, getPrintLcAPIURL } from '../Services/LcPrintAPIURL';
import { useNavigate } from 'react-router-dom';
import { getClassDivisionURL } from '../Services/PramotShiftStudentsAPIURL';
import { getStudentData, getLcDefaultData, saveStudentForLc } from '../Services/StudentBulkLcAPIURL';
import { getStudentList } from '../Services/BonafideAPIURL';
import ConfirmBoxLC from './ConfirmBoxLC';


const StudentsBulkLc = () => {
  // loader on/off
  const [loaderOption, setLoaderOption] = useState(false);

  const authUser = useSelector((state) => state.user.value);
  const navigate = useNavigate()
  const titleId = "Student Bulk LC";

  //Note: This below states are declared for Save & Update(Disabled/unDisabled)
  const saveBtnStyle = "btn btn-primary btn-sm";
  const updateBtnStyle = "btn btn-primary btn-sm";
  const displayNoneBtnStyle = "d-none";
  const [saveBtn, setSaveBtn] = useState(saveBtnStyle);
  const [updateBtn, setUpdateBtn] = useState(displayNoneBtnStyle);

  //const [languageObj, setLanguageObj] = useState(1);
  const [languageId, setLanguageId] = useState(1);
  const [waterMark, setWaterMark] = useState(0);

  const [classDivData, setClassDivData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [lcDefaultData, setLcDefaultData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [studentData, setStudentData] = useState([]);
  const [classDivObj, setClassDivObj] = useState(null);
  const [checkValue, setCheckValue] = useState([]);
  const [classDivName, setClassDivName] = useState("");
  const [tempData, setTempData] = useState([]);
  const [commonData, setCommonData] = useState([]);

  const [regNum, setRegNum] = useState('');
  const [regNumTrue, setRegNumTrue] = useState('');
  const [Val, setVal] = useState(false);

  //console.log("Val======" + Val + "searchterm===" + searchTerm + "regNum=====" + regNum + "regNumTrue=====" + regNumTrue)

  const checkedData = studentData.filter(e => e.isAllocated === 1);
  const [totalSelectedCheckboxes, setTotalSelectedCheckboxes] = useState(checkedData.length);
  //console.log(JSON.stringify(checkValue.sort((a, b) => a.regCode > b.regCode ? 1 : -1)))
  //console.log("checkValue=======" + JSON.stringify(checkValue));

  const handleCheckChange = (val, id, regNo, studnameMr, studenameEn, rNo, gender, sessionYr, Bid, Sid, isAllocate) => {
    setTotalSelectedCheckboxes(document.querySelectorAll('input[type=checkbox]:checked').length);

    setVal(val);
    const SaveObjectData = {
      id: id,
      regCode: regNo,
      studentNameMr: studnameMr,
      studentNameEn: studenameEn,
      rollNo: rNo,
      genderId: gender,
      sessionYear: sessionYr,
      sectionId: Sid,
      branchId: Bid
    }

    if (val == true) {

      setCheckValue(checkValue.concat(SaveObjectData));
      setRegNumTrue(SaveObjectData.regCode);
      setRegNum('');
      //console.log(SaveObjectData.regCode)     

      //if checkbox is checked
      let updatedData = tempData;
      tempData.map((e, index) => {
        if (e.regCode == regNumTrue) {
          updatedData[index] = ({ ...e, isAllocated: 1 })
        }
      })
      //console.log("updatedData=======" + JSON.stringify(updatedData));
      if (searchTerm == "") {
        setStudentData(updatedData);
        setFilteredData(updatedData)
      }

    }
    else if (val == false) {
      const removedData = checkValue.filter((item) => item.regCode != regNo)
      setRegNum(SaveObjectData.regCode);
      setCheckValue(removedData);

      setRegNumTrue('');

      //if checkbox is unChecked
      let updatedData = tempData;
      tempData.map((e, index) => {
        if (e.regCode == regNum) {
          updatedData[index] = ({ ...e, isAllocated: 0 })
        }
      })
      //console.log("updatedData=======" + JSON.stringify(updatedData));

      if (searchTerm == "") {
        setStudentData(updatedData);
        setFilteredData(updatedData);
      }

    }
  }

  useEffect(() => {

    //if checkbox is checked after search
    let updatedData2 = tempData;
    tempData.map((e, index) => {
      if (e.regCode == regNumTrue) {
        updatedData2[index] = ({ ...e, isAllocated: 1 })
      }

    })

    //if checkbox is unChecked after search
    let updatedData = tempData;
    tempData.map((e, index) => {
      if (e.regCode == regNum) {
        updatedData[index] = ({ ...e, isAllocated: 0 })
      }
    })


    if (Val == false) {
      setRegNumTrue('');
      if (searchTerm == "") {
        setStudentData(updatedData);
        setFilteredData(updatedData);
      }
    }
    else if (Val == true) {
      setRegNum('');
      if (searchTerm == "") {
        setStudentData(updatedData2);
        setFilteredData(updatedData2);
      }
    }

  }, [searchTerm, regNum, regNumTrue, Val])

  useEffect(() => {
    getData();
  }, [authUser])

  const getData = async () => {
    if (authUser.deptId != null || authUser.branchId != null) {
      await axios.get(`${getClassDivisionURL}?sid=${authUser.deptId}&bid=${authUser.branchId}`)
        .then((response) => {
          //console.log(JSON.stringify(response.data))
          setClassDivData(response.data)

        })
    }
    if (authUser.deptId != null || authUser.branchId != null) {
      await axios.get(`${getLcDefaultData}?sid=${authUser.deptId}&bid=${authUser.branchId}`)
        .then((response) => {
          //console.log(JSON.stringify(response.data));
          setLcDefaultData(response.data);
        })
    }
  }

  const getStudentList = async (dId) => {
    setStudentData([]);
    setFilteredData([]);
    setTempData([]);
    //setCheckValue([]);
    //console.log(`${getStudentData}?syear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&cdid=${dId}`)
    await axios.get(`${getStudentData}?syear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&cdid=${dId}`)
      .then((response) => {
        //console.log(JSON.stringify(response.data))
        setFilteredData(response.data);
        setStudentData(response.data);
        setTempData(response.data);

        let SaveObj = (response.data).filter(e => {
          return e.isAllocated == 1
        })
        let updatedData = SaveObj;

        SaveObj.map((e, index) => {
          updatedData[index] = ({
            id: e.id,
            regCode: e.regCode,
            studentNameMr: e.studentNameMr,
            studentNameEn: e.studentNameEn,
            rollNo: e.rollNo,
            genderId: e.genderId,
            sessionYear: e.sessionYear,
            sectionId: e.sectionId,
            branchId: e.branchId
          })
        })
        setCheckValue(updatedData);
      })
  }

  const getLcData = async () => {
    navigate('/Home/ad210F/');
  }

  const proceedData = () => {
    setTotalSelectedCheckboxes(document.querySelectorAll('input[type=checkbox]:checked').length);
  }

  const confirmBulkLc = () => {
    if (lcDefaultData === "No Data" || lcDefaultData == "" || lcDefaultData == null || lcDefaultData == []) {
      toast.warn("No default LC data found.")
    }

    else {
      setLoaderOption(true);
      //console.log("SaveObject=====" + JSON.stringify(checkValue))
      //toast.success("Proceed for bulk LC.")
      const newCheckValue = checkValue.map(e => ({
        ...e,
        createdBy: authUser.id,
        userIp: authUser.headers.userIp,
        userLat: authUser.headers.userLat,
        userLong: authUser.headers.userLong,
        deviceSign: authUser.headers.deviceSign,
        deviceType: authUser.headers.deviceType,
        deviceOS: authUser.headers.deviceOS,
        browserName: authUser.headers.browserName,
        browserVersion: authUser.headers.browserVersion
      }));

      axios.post(`${saveStudentForLc}`, newCheckValue)
        .then((response) => {
          if (response.data != null) {
            getData();
            //navigate('/Home/ad210/');
            setClassDivObj(null);
            setFilteredData([]);
            setStudentData([]);
            setTempData([]);
            setCheckValue([]);
            setLoaderOption(false);
            toast.success("Bulk LC saved Successfully.");
          }
          else {
            toast.error("LC Default Failed.")
          }
        }).catch((error) => {
          console.warn("Error:" + error)
        })
    }

  }

  useEffect(() => {
    setFilteredData([])
    let tempFilteredData =
      studentData.filter(
        (item) =>
          item.studentNameMr.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.studentNameEn.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.regCode.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.rollNo.toString().toLowerCase().includes(searchTerm.toLowerCase())

      )
    setFilteredData(tempFilteredData);
  }, [searchTerm]);


  const [isAscending, setAscending] = useState(false);
  const [sortedBy, setSortedBy] = useState(null);

  const onSort = (key, isAction) => {

    if (!isAction) {
      setSortedBy(key)
      if (isAscending) setFilteredData([...studentData.sort((a, b) => a[key].toString().toLowerCase() < b[key].toString().toLowerCase() ? 1 : -1)])
      else setFilteredData([...studentData.sort((a, b) => a[key].toString().toLowerCase() > b[key].toString().toLowerCase() ? 1 : -1)])
      setAscending(!isAscending)
      //console.log("Data============" + JSON.stringify(data))

    }
    setFilteredData(studentData);
    setStudentData(studentData);
    getSortedData(studentData, key, isAction);
  }

  const getSortedData = async (list, key, isAction) => {

    await axios(`${getStudentData}?syear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&cdid=${classDivObj.divisionId}`)
      .then(res => {
        //console.log(res); 
        if (!isAction) {
          setSortedBy(key)
          if (isAscending) setFilteredData([...(res.data).sort((a, b) => a[key].toString().toLowerCase() < b[key].toString().toLowerCase() ? 1 : -1)])
          else setFilteredData([...(res.data).sort((a, b) => a[key].toString().toLowerCase() > b[key].toString().toLowerCase() ? 1 : -1)])
          setAscending(!isAscending)
          //console.log("Data============" + JSON.stringify(data))
        }
        setFilteredData([]);
        const checkData = list.length === studentData.length && list.every((o, i) => Object.keys(o).length === Object.keys(studentData[i]).length && Object.keys(o).every(k => o[k] === studentData[i][k]))

        //console.log("checkedData......" + JSON.stringify(checkedData))
        if (checkData == true) {
          setFilteredData(studentData);
        }
      })
  }


  if (loaderOption == true) {
    return <><Loader /></>
  }
  else {
    return (
      <>
        <TitleLabel titleId={titleId} />
        <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: "350px" }}>
          <form>
            <div className="row mx-1">
              <div className='col-sm-12'>
                <button type='button' className='btn btn-primary btn-sm' onClick={() => getLcData()}>LC Default</button>
              </div>
            </div>

            <div className="row my-1 mt-3 mx-1">
              <div className='col-sm-3'>
                <Autocomplete
                  id="combo-box-demo"
                  PaperComponent={({ children }) => (
                    <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                  )}
                  options={classDivData}
                  onChange={(event, newValue) => {
                    setClassDivObj(newValue);
                    getStudentList(newValue.divisionId);
                    setSearchTerm("");
                    setAscending(false);
                    setSortedBy(null);
                    setClassDivName(newValue.classNameEn + " " + newValue.divisionNameEn)
                  }}
                  value={classDivObj}
                  style={{ fontSize: '14px' }}
                  getOptionLabel={option => (authUser.branchMedium == 1) ? option.classNameMr + " " + option.divisionNameMr : option.classNameEn + " " + option.divisionNameEn}
                  size="small"
                  renderInput={params => (
                    <TextField {...params} margin='dense' label="Select Class-Division" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                  )}
                />
              </div>
            </div>
            {
              (studentData == null || studentData == "" || studentData == [])
                ?
                <h4 className='mt-5'>No Records Found, Select class And division</h4>
                :
                <div>
                  <div className='row my-1'>
                    <div className='offset-sm-5 mt-1'>
                      <input
                        className='form-control form-control-sm'
                        type="text"
                        style={{ width: "250px", display: "block", float: "left", marginBottom: "6px", border: "1px solid #C2C1C1" }}
                        placeholder="Search Here"
                        value={searchTerm}
                        onChange={(event) => { setFilteredData([]); setSearchTerm(event.target.value) }}
                      />
                    </div>
                  </div>
                  <div className='table-responsive col-sm-8 mt-2' style={{ maxHeight: "330px" }} >
                    <table className="table table-bordered">
                      <thead className="" style={{ position: "sticky", zIndex: "2", overflow: "hidden", top: "0", backgroundColor: "#EAECEE", borderBottom: "2px solid #464de4" }}>

                        <tr>

                          <th style={{ fontSize: "15px", fontWeight: "600" }} onClick={() => onSort("regCode")}>Reg No{sortedBy === "regCode" ? isAscending ? '↑' : '↓' : ''}</th>
                          <th style={{ fontSize: "15px", fontWeight: "600" }} onClick={() => onSort((authUser.branchMedium == 1) ? "studentNameMr" : "studentNameEn")}>Student{sortedBy === ((authUser.branchMedium == 1) ? "studentNameMr" : "studentNameEn") ? isAscending ? '↑' : '↓' : ''}</th>
                          <th style={{ fontSize: "15px", fontWeight: "600" }} onClick={() => onSort("rollNo")}>Roll No{sortedBy === "rollNo" ? isAscending ? '↑' : '↓' : ''}</th>
                          <th style={{ fontSize: "15px", fontWeight: "600" }}>
                            {/* , margin: "0px", padding: "2px"  */}
                            {/* <Checkbox sx={{ '& .MuiSvgIcon-root': { fontSize: 22 } }}
                        checked={(checkAllSub == true) ? true : false}
                        onChange={checkAllSubjects} />  */}
                            Status</th>
                        </tr>
                      </thead>
                      <tbody style={{ borderTop: "2px solid #464de4", borderBottom: "2px solid #464de4" }}>
                        {filteredData.map((item, index) => {

                          return (
                            <tr key={index} className="table-sm">

                              <td style={{ fontSize: "15px", fontWeight: "500" }} >{item.regCode}</td>
                              <td style={{ fontSize: "15px", fontWeight: "500", lineHeight: "1.3" }} >{(authUser.branchMedium == 1) ? item.studentNameMr : item.studentNameEn}</td>
                              <td style={{ fontSize: "15px", fontWeight: "500" }} >{item.rollNo}</td>
                              <td style={{ fontSize: "15px", fontWeight: "500", margin: "0px", padding: "0px" }} >
                                <Checkbox defaultChecked={(item.isAllocated === 1) ? true : false} sx={{ '& .MuiSvgIcon-root': { fontSize: 30 } }}
                                  onChange={(e) => {
                                    let val = e.target.checked;
                                    handleCheckChange(val, item.id, item.regCode, item.studentNameMr, item.studentNameEn, item.rollNo, item.genderId, item.sessionYear, item.branchId, item.sectionId, item.isAllocated);
                                  }}
                                />
                              </td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                  </div>


                  <div className="col-sm-12 mt-3">
                    <div className="">
                      <button type="button" className="btn btn-sm" style={{
                        backgroundColor: "#0beef9",
                        backgroundImage: "linear-gradient(315deg, #0beef9 0%, #48a9fe 74%)",
                        fontWeight: "550"
                      }} onClick={() => proceedData()} data-bs-toggle="modal" data-bs-target="#exampleModal">Proceed To Bulk LC</button>
                    </div>
                  </div>
                </div>
            }

          </form>
        </div>
        <ToastContainer position="top-right" theme="colored" />
        <ConfirmBoxLC classDivName={classDivName} confirmBulkLc={confirmBulkLc} totalSelectedCheckboxes={totalSelectedCheckboxes} />
      </>
    )
  }

}

export default StudentsBulkLc