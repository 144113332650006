import React, { useState, useEffect, useRef } from 'react';
import TitleLabel from "../../CommonComponent/TitleLabel";
import TextField from '@mui/material/TextField';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Autocomplete from "@mui/material/Autocomplete";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import BloodGroupDropDown from "../../CommonComponent/BloodGroupDropDown";
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import axios from 'axios';
import moment from 'moment';
import { useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { BaseURLMasterCastes } from '../../CommonServices/CasteMasterAPIURL';
import { SubCasteGetMapping } from '../../CommonServices/SubCasteMasterAPIURL';
import { BaseURLMasterMinorities } from '../../CommonServices/MinorityMasterAPIURL';
import { BaseURLInstituteMaster } from '../../CommonServices/InstituteMasterAPIURL';
import Loader from "../../CommonComponent/Loader";
import Paper from "@mui/material/Paper";
import { EmployeeMasterPostMapping, getEmployeeDataById, EmployeeMasterPutMapping, getSectionURL, EmployeeMasterGetMapping, empImagePath, removeEmployeeImage } from '../../CommonServices/EmployeeMasterAPIURL';
import { ToastContainer, toast } from 'react-toastify';
import { MotherToungMasterGetMapping, ReligionMasterGetMapping, DesignationMasterGetMapping, CategoryMasterGetMapping, DisabilityMasterGetMapping } from '../../CommonServices/StudentRegistrationAPIURL';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
// Common Components
import AddNewReligionModal from "../../CommonComponent/AddNewReligionModal";
import AddNewCaste from "../../CommonComponent/AddNewCaste";
import AddNewSubCaste from "../../CommonComponent/AddNewSubCaste";
import AddNewCasteCategory from "../../CommonComponent/AddNewCasteCategory";
import AddNewMinority from "../../CommonComponent/AddNewMinority";
import AddNewMotherTongueModal from "../../CommonComponent/AddNewMotherTongueModal";
import warningImg from '../../assets/warningImg/warning.png'
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';

import makeStyles from "@mui/styles/makeStyles";
import { Checkbox } from '@mui/material';
import { getBloodGroupMasterAPIURL } from '../Services/GuardianDetailsAPIURL';

const useStyles = makeStyles(theme => ({
    select: {
        maxHeight: 160,
        "& ul": {
            backgroundColor: "#F4ECF7",
            //maxHeight: 160,
        },
        "& li": {
            // backgroundColor: "#F4ECF7", fontWeight: "550", fontSize: "14px" 
            fontSize: "14px",
            fontWeight: "550"
        },
    },

}));

const natureOfAppointment = [
    {
        id: 1,
        natureType: "Permanent"
    },
    {
        id: 2,
        natureType: "CHB"
    },
    {
        id: 3,
        natureType: "Special"
    }
]

const employeeTypeData = [
    {
        id: 1,
        employee: "Teaching"
    },
    {
        id: 2,
        employee: "Non Teaching"
    }
]

const MasterFormEmployee = () => {
    const classes = useStyles();
    const [titleId, setTitleId] = useState("");
    let { empId, EditFlag } = useParams();
    const authUser = useSelector((state) => state.user.value);
    const navigate = useNavigate();

    const [openWarningModal, setOpenWarningModal] = useState(false);
    const [delAndUpdateWarnModal, setDelAndUpdateWarnModal] = useState(false);

    //state and ref for capture image
    const [result, setResult] = useState(false);
    const handleFileInput = useRef(null);

    useEffect(() => {
        chkDevice();
    }, [])

    //check respective device has wbcamera or camera
    const chkDevice = async () => {
        if (navigator.mediaDevices != null) {
            // setResult(false);
            navigator.mediaDevices.enumerateDevices()
                .then(function (devices) {
                    //alert(devices)
                    const videoDevices = devices.some(device => device.kind != 'videoinput')
                    setResult(videoDevices)
                }).catch(err => {
                    toast.error("Error:" + err)
                })
        }
    }

    //open camera function
    const handleClick = async () => {
        if (result == true) {
            //toast.warn("Webcam or camera is not found in this device.")
            setOpenWarningModal(true);
        }
        else {
            handleFileInput.current.click();
        }
    };

    // loader on/off
    const [loaderOption, setLoaderOption] = useState(false);

    useEffect(() => {

        if (EditFlag == 1) {
            setTitleId("Employee Registration")
        }
        else if (EditFlag == 2) {
            setTitleId("Update Employee")
        }
        else if (EditFlag == 3) {
            setTitleId("Employee Details")
        }
        getData();

    }, [authUser]);

    //Note: This below states are declared for Save & Update(Disabled/unDisabled)
    const saveBtnStyle = "employeeMasterSaveBtn btn btn-primary btn-sm";
    const updateBtnStyle = "employeeMasterUpdateBtn btn btn-primary btn-sm";
    const displayNoneBtnStyle = "d-none";
    const [saveBtn, setSaveBtn] = useState(saveBtnStyle);
    const [updateBtn, setUpdateBtn] = useState(displayNoneBtnStyle);

    const [saveBtnDisabled, setSaveBtnDisabled] = useState(false);
    const [updateBtnDisabled, setUpdateBtnDisabled] = useState(false);

    //Call APIURL Data
    const [religionData, setReligionData] = useState([]);
    const [casteData, setCasteData] = useState([]);
    const [subCasteData, setSubCasteData] = useState([]);
    const [categoryData, setCategoryData] = useState([]);
    const [disabilityData, setDisabilityData] = useState([]);
    const [motherToungData, setMotherToungData] = useState([]);
    const [designationData, setDesignationData] = useState([]);
    const [minorityData, setMinorityData] = useState([]);
    const [schoolData, setSchoolData] = useState([]);
    const [deptData, setDeptData] = useState([]);
    const [employeeData, setEmployeeData] = useState([]);
    const [bloodGroupData, setBloodGroupData] = useState([]);
    const [bloodGroupObj, setBloodGroupObj] = useState(null);

    //state declared for textfield
    const [employeeId, setEmployeeId] = useState();
    const [motherTongueId, setMotherTongueId] = useState(null);
    const [motherTongueObj, setMotherTongueObj] = useState(null);
    const [deptId, setDeptId] = useState(null);
    const [deptObj, setDeptObj] = useState(null);
    const [religionId, setReligionId] = useState(null);
    const [religionObj, setReligionObj] = useState(null);
    const [casteId, setCasteId] = useState(null);
    const [casteObj, setCasteObj] = useState(null);
    const [subCasteId, setSubCasteId] = useState(null);
    const [subCasteObj, setSubCasteObj] = useState(null);
    const [CasteCategoryId, setCasteCategoryId] = useState(null);
    const [CasteCategoryObj, setCasteCategoryObj] = useState(null);
    const [minorityId, setMinorityId] = useState(null);
    const [minorityObj, setMinorityObj] = useState(null);
    const [disabilityId, setDisabilityId] = useState(0);
    const [disabilityObj, setDisabilityObj] = useState(null);
    const [designationId, setDesignationId] = useState(null);
    const [designationObj, setDesignationObj] = useState(null);
    const [natureOfAppointId, setNatureOfAppointId] = useState('');
    const [natureOfAppointObj, setNatureOfAppointObj] = useState(null);
    const [schoolId, setSchoolId] = useState(null);
    const [schoolObj, setSchoolObj] = useState(null);
    const [employeeType, setEmployeeType] = useState(null);
    const [employeeTypeObj, setEmployeeTypeObj] = useState(null);
    const [firstNameMr, setFirstNameMr] = useState("");
    const [firstNameEn, setFirstNameEn] = useState("");
    const [middleNameMr, setMiddleNameMr] = useState("");
    const [middleNameEn, setMiddleNameEn] = useState("");
    const [lastNameMr, setLastNameMr] = useState("");
    const [lastNameEn, setLastNameEn] = useState("");
    const [panNo, setPanNo] = useState("");
    const [aadharNo, setAadharNo] = useState("");
    const [pfAccountNo, setPfAccountNo] = useState("");
    const [shalarthId, setShalarthId] = useState("");
    const [address, setAddress] = useState("");
    const [city, setCity] = useState("");
    const [disabilityDetails, setDisabilityDetails] = useState(2);
    const [birthDate, setBirthDate] = useState(null);
    const [marritalStatus, setMarritalStatus] = useState('');
    const [pfJoiningDate, setPfJoiningDate] = useState(null);
    const [pfRelievingDate, setPfRelievingDate] = useState(null);
    const [appointDate, setAppointDate] = useState(null);
    const [retirementDate, setRetirementDate] = useState(null);
    const [gender, setGender] = useState(1);
    const [email, setEmail] = useState('');
    const [mobile, setmobile] = useState();
    const [bloodGroup, setBloodGroup] = useState('');
    const [oldName, setOldName] = useState("");
    const [newFilteredData, setNewFilteredData] = useState([]);
    const [workingStatus, setWorkingStatus] = useState('');
    const [image, setImage] = useState(null);
    const [showImage, setShowImage] = useState(null);
    const [biometricId, setBiometricId] = useState('');
    const [permanentaddress, setPermanentAddress] = useState("");
    const [permanentcity, setPermanentCity] = useState("");
    const [imgError, setImgError] = useState('');

    const [isDeleteFlag, setIsDeleteFlag] = useState(0)

    const [addressChkBox, setAddressChkBox] = useState(0);

    const HandleAddressChkBox = (e) => {
        setAddressChkBox(e.target.checked);
        if (e.target.checked == true) {
            setAddressChkBox(1);
            setPermanentAddress(address);
            setPermanentCity(city);
        }
        else {
            setAddressChkBox(0);
            setPermanentAddress("");
            setPermanentCity("");
        }
    }


    //set or get image path code
    const onChangePicture = e => {
        setImage(e.target.files[0]);
        if (e.target.files[0] != null) {
            let inMbSize = e.target.files[0].size / (1024 * 1024)
            //if (inMbSize <= 1) {
            setShowImage(URL.createObjectURL(e.target.files[0]));
            // }
            // else {
            //     toast.warn("Image Size should be less than 1MB.")
            // }

            //alert((inMbSize).toFixed('2'))
        }
        else {
            setShowImage(null);
        }
        //alert(JSON.stringify(e.target.files[0]))
    };

    //console.log(image)

    //get retirement date from birth date & appoint date
    const getRetirementDate = (newvalue) => {
        //setRetirementDate(null);
        const checkYear = moment(newvalue).format("YYYY")
        const checkDate = new Date(moment(newvalue).endOf('month').format(`${parseInt(checkYear) + 30}/MM/DD`))


        let year = moment(birthDate).format("YYYY")
        let changedDate = new Date(moment(birthDate).endOf('month').format(`${parseInt(year) + 58}/MM/DD`))
        //console.log("changedDate=======" + moment(changedDate).format("DD/MM/YYYY") + "-----" + "checkdate======" + moment(checkDate).format("DD/MM/YYYY"))


        if (changedDate < checkDate) {
            //console.log(moment(changedDate).format("DD/MM/YYYY"));
            const newDateYear = moment(changedDate).format("YYYY")
            //console.log(newDateYear)
            setRetirementDate(changedDate)
        }
        else if (changedDate > checkDate) {
            //console.log(moment(checkDate).format("DD/MM/YYYY"));
            const checkDateYear = moment(checkDate).format("YYYY")
            //console.log(checkDateYear)
            setRetirementDate(checkDate)
        }

        //console.log(retirementDate)
    }

    //get API Data
    const getAPIData = async () => {
        await axios.get(`${ReligionMasterGetMapping}`)
            .then((response) => {
                setReligionData(response.data);
            })
        await axios.get(`${BaseURLMasterCastes}`)
            .then((response) => {
                setCasteData(response.data);
            })
        await axios.get(`${SubCasteGetMapping}`)
            .then((response) => {
                setSubCasteData(response.data);
            })
        await axios.get(`${CategoryMasterGetMapping}`)
            .then((response) => {
                setCategoryData(response.data);
            })
        await axios.get(`${MotherToungMasterGetMapping}`)
            .then((response) => {
                setMotherToungData(response.data);
            })
        await axios.get(`${DesignationMasterGetMapping}`)
            .then((response) => {
                setDesignationData(response.data);
            })
        await axios.get(`${DisabilityMasterGetMapping}`)
            .then((response) => {
                setDisabilityData(response.data);
            })
        await axios.get(`${BaseURLMasterMinorities}`)
            .then((response) => {
                setMinorityData(response.data);
            })
        await axios.get(`${BaseURLInstituteMaster}`)
            .then((response) => {
                setSchoolData(response.data);
            })
        await axios.get(`${getSectionURL}`)
            .then((response) => {
                setDeptData(response.data);
            })

        //console.log(`${EmployeeMasterGetMapping}`)
        await axios(`${EmployeeMasterGetMapping}`)
            .then(res => {
                setEmployeeData(res.data);
            })

        await axios(`${getBloodGroupMasterAPIURL}`)
            .then(res => {
                const newData = [{ id: 0, nameMr: "Unknown" }, ...res.data]
                setBloodGroupData(newData);
            })
    }

    // console.log("Employee Data===" + JSON.stringify(employeeData));
    //console.log(employeeData.some(e => e.employeeNameMr === "test test test"))

    //get Edit Details
    const getEditDetails = async () => {
        setLoaderOption(true);
        // console.log(`${getEmployeeDataById}` + empId)
        await axios.get(`${getEmployeeDataById}` + empId)
            .then((response) => {
                //console.log(response.data.workingStatus)
                if (EditFlag == 2) {
                    setSaveBtn(displayNoneBtnStyle);
                    setUpdateBtn(updateBtnStyle);
                }
                else if (EditFlag == 3) {
                    setSaveBtn(displayNoneBtnStyle);
                    setUpdateBtn(displayNoneBtnStyle);
                }

                setEmployeeId(response.data.id);
                setFirstNameMr(response.data.firstNameMr);
                setFirstNameEn(response.data.firstNameEn);
                setMiddleNameMr(response.data.middleNameMr);
                setMiddleNameEn(response.data.middleNameEn);
                setLastNameMr(response.data.lastNameMr);
                setLastNameEn(response.data.lastNameEn);
                setAddress(response.data.address);
                setCity(response.data.city);
                setmobile(response.data.mobileNo);
                setEmail(response.data.emailId);
                setBirthDate(response.data.dateOfBirth);
                setGender(response.data.gender);
                // setBloodGroup(response.data.bloodGroup);
                const selectedBloddGroup = response.data.bloodGroup
                const SelectedMotherTongue = response.data.mothertounghId;
                const selectedReligion = response.data.religionId;
                const selectedCaste = response.data.casteId;
                const selectedSubCaste = response.data.subCasteId;
                const selectedCasteCategory = response.data.categoryId;
                const selectedMinority = response.data.minorityId;
                setPanNo(response.data.panno);
                setAadharNo(response.data.aadharNo);
                const selectedDisability = response.data.disabilityId;
                setDisabilityDetails(response.data.isDisability);
                setMarritalStatus(response.data.isMarried);
                setShalarthId(response.data.shalarthId);
                setPfAccountNo(response.data.pfAccountNo);
                setPfJoiningDate(response.data.pfJoiningDate);
                setPfRelievingDate(response.data.pfRelivingDate);
                const selectedSection = response.data.sectionId;
                const selectedDesignation = response.data.designationId;
                const selectedEmployeeType = response.data.employeeType;
                setEmployeeTypeObj(employeeTypeData.find(e => e.id == selectedEmployeeType));
                setEmployeeType(selectedEmployeeType);
                setAppointDate(response.data.appointDate);
                setRetirementDate(response.data.retirementDate);
                const selectedNatureOfAppoint = response.data.natureOfAppointment;
                setNatureOfAppointObj(natureOfAppointment.find(e => e.id == selectedNatureOfAppoint));
                setNatureOfAppointId(selectedNatureOfAppoint);
                const selectedSchool = response.data.branchId;
                setOldName(response.data.oldName);
                setWorkingStatus(response.data.workingStatus);
                setBiometricId(response.data.biometricId);
                setPermanentAddress(response.data.permanentAddress);
                setPermanentCity(response.data.permanentCity);
                if (response.data.employeeImg != null) {
                    //console.log(`${empImagePath}${response.data.employeeImg}`);
                    setShowImage(`${empImagePath}${response.data.employeeImg}`);
                    setImage(null);
                }


                axios.get(`${MotherToungMasterGetMapping}`)
                    .then((response) => {
                        setMotherToungData(response.data);
                        setMotherTongueObj(response.data.find(e => e.id == SelectedMotherTongue));
                        setMotherTongueId(SelectedMotherTongue);
                    })

                axios.get(`${ReligionMasterGetMapping}`)
                    .then((response) => {
                        setReligionData(response.data);
                        setReligionObj(response.data.find(e => e.id == selectedReligion));
                        setReligionId(selectedReligion);
                    })

                axios.get(`${BaseURLMasterCastes}`)
                    .then((response) => {
                        setCasteData(response.data);
                        setCasteObj(response.data.find(e => e.id == selectedCaste));
                        setCasteId(selectedCaste);
                    })
                axios.get(`${SubCasteGetMapping}`)
                    .then((response) => {
                        setSubCasteData(response.data);
                        setSubCasteObj(response.data.find(e => e.id == selectedSubCaste));
                        setSubCasteId(selectedSubCaste);
                    })

                axios.get(`${CategoryMasterGetMapping}`)
                    .then((response) => {
                        setCategoryData(response.data);
                        setCasteCategoryObj(response.data.find(e => e.id == selectedCasteCategory));
                        setCasteCategoryId(selectedCasteCategory);
                    })

                axios.get(`${BaseURLMasterMinorities}`)
                    .then((response) => {
                        setMinorityData(response.data);
                        setMinorityObj(response.data.find(e => e.id == selectedMinority));
                        setMinorityId(selectedMinority);
                    })

                axios.get(`${DisabilityMasterGetMapping}`)
                    .then((response) => {
                        setDisabilityData(response.data);
                        setDisabilityObj(response.data.find(e => e.id == selectedDisability));
                        setDisabilityId(selectedDisability);
                    })

                axios.get(`${getSectionURL}`)
                    .then((response) => {
                        setDeptData(response.data);
                        setDeptObj(response.data.find(e => e.id == selectedSection));
                        setDeptId(selectedSection);
                    })

                axios.get(`${DesignationMasterGetMapping}`)
                    .then((response) => {
                        setDesignationData(response.data);
                        setDesignationObj(response.data.find(e => e.id == selectedDesignation));
                        setDesignationId(selectedDesignation);
                    })

                axios.get(`${BaseURLInstituteMaster}`)
                    .then((response) => {
                        setSchoolData(response.data);
                        setSchoolObj(response.data.find(e => e.id == selectedSchool));
                        setSchoolId(selectedSchool);
                        setLoaderOption(false);
                    })

                axios.get(`${EmployeeMasterGetMapping}`)
                    .then(res => {
                        setEmployeeData(res.data);
                        let newData = (res.data).filter(e => e.id !== response.data.id)
                        //console.log(JSON.stringify(newData))
                        setNewFilteredData(newData);
                    })

                axios(`${getBloodGroupMasterAPIURL}`)
                    .then(res => {
                        const newData = [{ id: 0, nameMr: "Unknown" }, ...res.data]
                        setBloodGroupData(newData);
                        setBloodGroupObj(newData.find(e => e.id == selectedBloddGroup))
                    })

            })
    }

    const getData = async () => {
        if (EditFlag == 2) {
            getEditDetails();
            setSaveBtnDisabled(true);
            setUpdateBtnDisabled(false);
        }
        else if (EditFlag == 3) {
            getEditDetails();
            setSaveBtnDisabled(true);
            setUpdateBtnDisabled(true);
        }
        else if (EditFlag == 1) {
            getAPIData();
            setSaveBtnDisabled(false);
            setUpdateBtnDisabled(true);
        }
    }

    const saveData = () => {
        setLoaderOption(true);
        if (moment(birthDate).format("DD/MM/YYYY") !== "Invalid date" &&
            // moment(pfJoiningDate).format("DD/MM/YYYY") !== "Invalid date" &&           
            // moment(pfRelievingDate).format("DD/MM/YYYY") !== "Invalid date" &&
            moment(appointDate).format("DD/MM/YYYY") !== "Invalid date" &&
            moment(retirementDate).format("DD/MM/YYYY") !== "Invalid date" &&
            ((employeeData.some(e => e.employeeNameMr === firstNameMr + " " + middleNameMr + " " + lastNameMr))) == false &&
            ((employeeData.some(e => e.employeeNameEn === firstNameEn + " " + middleNameEn + " " + lastNameEn))) == false &&
            (employeeData.some(e => e.aadharNo === aadharNo)) == false
        ) {
            const saveEmployeeEntry = {
                id: employeeId,
                firstNameMr: firstNameMr,
                firstNameEn: firstNameEn,
                middleNameMr: middleNameMr,
                middleNameEn: middleNameEn,
                lastNameMr: lastNameMr,
                lastNameEn: lastNameEn,
                employeeNameMr: "",
                employeeNameEn: "",
                address: address,
                city: city,
                mobileNo: mobile,
                emailId: email,
                dateOfBirth: moment(birthDate).format("DD/MM/YYYY"),
                gender: gender,
                bloodGroup: bloodGroupObj.id,
                mothertounghId: motherTongueId,
                religionId: religionId,
                casteId: casteId,
                subCasteId: (subCasteId == "" || subCasteId == [] || subCasteId == null) ? null : subCasteId,
                categoryId: CasteCategoryId,
                minorityId: minorityId,
                panno: panNo,
                aadharNo: aadharNo,
                isDisability: disabilityDetails,
                disabilityId: disabilityId,
                isMarried: marritalStatus,
                shalarthId: shalarthId,
                pfAccountNo: pfAccountNo,
                pfJoiningDate: moment(pfJoiningDate).format("DD/MM/YYYY"),
                pfRelivingDate: moment(pfRelievingDate).format("DD/MM/YYYY"),
                sectionId: deptId,
                designationId: designationId,
                employeeType: employeeType,
                appointDate: moment(appointDate).format("DD/MM/YYYY"),
                retirementDate: moment(retirementDate).format("DD/MM/YYYY"),
                natureOfAppointment: natureOfAppointId,
                branchId: schoolId,
                oldName: oldName,
                workingStatus: workingStatus,
                permanentCity: permanentcity,
                permanentAddress: permanentaddress,
                biometricId: (biometricId == '') ? 0 : parseInt(biometricId),
                createdBy: authUser.id,
                userIp: authUser.headers.userIp,
            }

            const formData = new FormData();
            formData.append("employeeImg", image);
            formData.append("firstNameMr", firstNameMr);
            formData.append("firstNameEn", firstNameEn);
            formData.append("middleNameMr", middleNameMr);
            formData.append("middleNameEn", middleNameEn);
            formData.append("lastNameMr", lastNameMr);
            formData.append("lastNameEn", lastNameEn);
            formData.append("employeeNameMr", "");
            formData.append("employeeNameEn", "");
            formData.append("address", address);
            formData.append("city", city);
            formData.append("mobileNo", mobile);
            formData.append("emailId", email);
            formData.append("dateOfBirth", moment(birthDate).format("DD/MM/YYYY"));
            formData.append("gender", gender);
            formData.append("bloodGroup", bloodGroupObj.id);
            formData.append("mothertounghId", motherTongueId);
            formData.append("religionId", religionId);
            formData.append("casteId", casteId);
            formData.append("subCasteId", (subCasteId == "" || subCasteId == [] || subCasteId == null) ? 0 : subCasteId);
            formData.append("categoryId", CasteCategoryId);
            formData.append("minorityId", minorityId);
            formData.append("panno", panNo);
            formData.append("aadharNo", aadharNo);
            formData.append("isDisability", disabilityDetails);
            formData.append("disabilityId", disabilityId);
            formData.append("isMarried", marritalStatus);
            formData.append("shalarthId", shalarthId);
            formData.append("pfAccountNo", pfAccountNo);
            formData.append("pfJoiningDate", moment(pfJoiningDate).format("DD/MM/YYYY"));
            formData.append("pfRelivingDate", moment(pfRelievingDate).format("DD/MM/YYYY"));
            formData.append("sectionId", deptId);
            formData.append("designationId", designationId);
            formData.append("employeeType", employeeType);
            formData.append("appointDate", moment(appointDate).format("DD/MM/YYYY"));
            formData.append("retirementDate", moment(retirementDate).format("DD/MM/YYYY"));
            formData.append("natureOfAppointment", natureOfAppointId);
            formData.append("branchId", schoolId);
            formData.append("oldName", oldName);
            formData.append("workingStatus", workingStatus);
            formData.append("permanentAddress", permanentaddress);
            formData.append("permanentCity", permanentcity);
            formData.append("biometricId", !biometricId ? 0 : parseInt(biometricId));
            formData.append("createdBy", authUser.id)
            formData.append("userIp", authUser.headers.userIp)

            const saveFormData = new FormData();
            saveFormData.append("firstNameMr", firstNameMr);
            saveFormData.append("firstNameEn", firstNameEn);
            saveFormData.append("middleNameMr", middleNameMr);
            saveFormData.append("middleNameEn", middleNameEn);
            saveFormData.append("lastNameMr", lastNameMr);
            saveFormData.append("lastNameEn", lastNameEn);
            saveFormData.append("employeeNameMr", "");
            saveFormData.append("employeeNameEn", "");
            saveFormData.append("address", address);
            saveFormData.append("city", city);
            saveFormData.append("mobileNo", mobile);
            saveFormData.append("emailId", email);
            saveFormData.append("dateOfBirth", moment(birthDate).format("DD/MM/YYYY"));
            saveFormData.append("gender", gender);
            saveFormData.append("bloodGroup", bloodGroupObj.id);
            saveFormData.append("mothertounghId", motherTongueId);
            saveFormData.append("religionId", religionId);
            saveFormData.append("casteId", casteId);
            saveFormData.append("subCasteId", (subCasteId == "" || subCasteId == [] || subCasteId == null) ? 0 : subCasteId);
            saveFormData.append("categoryId", CasteCategoryId);
            saveFormData.append("minorityId", minorityId);
            saveFormData.append("panno", panNo);
            saveFormData.append("aadharNo", aadharNo);
            saveFormData.append("isDisability", disabilityDetails);
            saveFormData.append("disabilityId", disabilityId);
            saveFormData.append("isMarried", marritalStatus);
            saveFormData.append("shalarthId", shalarthId);
            saveFormData.append("pfAccountNo", pfAccountNo);
            saveFormData.append("pfJoiningDate", moment(pfJoiningDate).format("DD/MM/YYYY"));
            saveFormData.append("pfRelivingDate", moment(pfRelievingDate).format("DD/MM/YYYY"));
            saveFormData.append("sectionId", deptId);
            saveFormData.append("designationId", designationId);
            saveFormData.append("employeeType", employeeType);
            saveFormData.append("appointDate", moment(appointDate).format("DD/MM/YYYY"));
            saveFormData.append("retirementDate", moment(retirementDate).format("DD/MM/YYYY"));
            saveFormData.append("natureOfAppointment", natureOfAppointId);
            saveFormData.append("branchId", schoolId);
            saveFormData.append("oldName", oldName);
            saveFormData.append("workingStatus", workingStatus);
            saveFormData.append("permanentAddress", permanentaddress);
            saveFormData.append("permanentCity", permanentcity);
            saveFormData.append("biometricId", !biometricId ? 0 : parseInt(biometricId));
            saveFormData.append("createdBy", authUser.id)
            saveFormData.append("userIp", authUser.headers.userIp)

            //console.log("Employee Data:" + JSON.stringify(saveEmployeeEntry));


            if (image != null) {
                axios.post(`${EmployeeMasterPostMapping}`, formData)
                    .then((response) => {
                        if (response.data == "BIOIDEXISTS") {
                            setLoaderOption(false);
                            toast.warn(`Employee with ${biometricId} biometric id is already exists.`)
                        }
                        else if (response.data != null) {
                            getData();
                            setLoaderOption(false);
                            toast.success("Employee registration successfully done.")
                            navigate("/Home/m009/1")
                        }
                        else {
                            setLoaderOption(false);
                            toast.error("Operation failed.");
                        }
                    }).catch((error) => {
                        setLoaderOption(false);
                        console.log("Save employee error: " + error);
                        toast.error("Something went wrong, please check.")
                    });
            }
            else {
                axios.post(`${EmployeeMasterPostMapping}`, saveFormData)
                    .then((response) => {
                        // console.log(Object.fromEntries(saveFormData))
                        if (response.data == "BIOIDEXISTS") {
                            setLoaderOption(false);
                            toast.warn(`Employee with ${biometricId} biometric id is already exists.`)
                        }
                        else if (response.data != null) {
                            getData();
                            setLoaderOption(false);
                            toast.success("Employee registration successfully done.")
                            navigate("/Home/m009/1")
                        }
                        else {
                            setLoaderOption(false);
                            toast.error("Operation failed.");
                        }
                    }).catch((error) => {
                        setLoaderOption(false);
                        console.log("Save employee error: " + error);
                        toast.error("Something went wrong, please check.")
                    });
            }
            //toast.success("valid")
        }
        else if (moment(birthDate).format("DD/MM/YYYY") === "Invalid date" ||
            // moment(pfJoiningDate).format("DD/MM/YYYY") === "Invalid date" ||           
            // moment(pfRelievingDate).format("DD/MM/YYYY") === "Invalid date" ||
            moment(appointDate).format("DD/MM/YYYY") === "Invalid date" ||
            moment(retirementDate).format("DD/MM/YYYY") === "Invalid date") {
            setLoaderOption(false);
            toast.error("Improper date, please check.")
        }
        else if ((employeeData.some(e => e.employeeNameMr === firstNameMr + " " + middleNameMr + " " + lastNameMr)) == true ||
            ((employeeData.some(e => e.employeeNameEn === firstNameEn + " " + middleNameEn + " " + lastNameEn))) == true) {
            setLoaderOption(false);
            toast.warn("This employee is already exists.")
        }
        else if ((employeeData.some(e => e.aadharNo === aadharNo)) == true) {
            setLoaderOption(false);
            toast.warn("This aadhar number is already exists.")
        }
    }

    const updateData = (delOptionId) => {
        setLoaderOption(true);
        if (moment(birthDate).format("DD/MM/YYYY") !== "Invalid date" &&
            moment(appointDate).format("DD/MM/YYYY") !== "Invalid date" &&
            // moment((pfJoiningDate == null) ? new Date() : pfJoiningDate).format("DD/MM/YYYY") !== "Invalid date" &&
            // moment((pfRelievingDate == null) ? new Date() : pfRelievingDate).format("DD/MM/YYYY") !== "Invalid date" &&
            moment(retirementDate).format("DD/MM/YYYY") !== "Invalid date"
            &&
            //imgError < 1e+6 &&
            ((newFilteredData.some(e => e.employeeNameMr === firstNameMr + " " + middleNameMr + " " + lastNameMr))) == false &&
            ((newFilteredData.some(e => e.employeeNameEn === firstNameEn + " " + middleNameEn + " " + lastNameEn))) == false &&
            (newFilteredData.some(e => e.aadharNo === aadharNo)) == false
        ) {

            const formData = new FormData();
            formData.append("employeeImg", image);
            formData.append("firstNameMr", firstNameMr);
            formData.append("firstNameEn", firstNameEn);
            formData.append("middleNameMr", middleNameMr);
            formData.append("middleNameEn", middleNameEn);
            formData.append("lastNameMr", lastNameMr);
            formData.append("lastNameEn", lastNameEn);
            formData.append("employeeNameMr", "");
            formData.append("employeeNameEn", "");
            formData.append("address", address);
            formData.append("city", city);
            formData.append("mobileNo", mobile);
            formData.append("emailId", email);
            formData.append("dateOfBirth", moment(birthDate).format("DD/MM/YYYY"));
            formData.append("gender", parseInt(gender));
            formData.append("bloodGroup", parseInt(bloodGroupObj.id));
            formData.append("mothertounghId", parseInt(motherTongueId));
            formData.append("religionId", parseInt(religionId));
            formData.append("casteId", parseInt(casteId));
            formData.append("subCasteId", (subCasteId == "" || subCasteId == [] || subCasteId == null) ? 0 : parseInt(subCasteId));
            formData.append("categoryId", parseInt(CasteCategoryId));
            formData.append("minorityId", parseInt(minorityId));
            formData.append("panno", panNo);
            formData.append("aadharNo", aadharNo);
            formData.append("isDisability", disabilityDetails);
            formData.append("disabilityId", parseInt(disabilityId));
            formData.append("isMarried", parseInt(marritalStatus));
            formData.append("shalarthId", shalarthId);
            formData.append("pfAccountNo", pfAccountNo);
            formData.append("pfJoiningDate", moment(pfJoiningDate).format("DD/MM/YYYY"));
            formData.append("pfRelivingDate", moment(pfRelievingDate).format("DD/MM/YYYY"));
            formData.append("sectionId", parseInt(deptId));
            formData.append("designationId", parseInt(designationId));
            formData.append("employeeType", parseInt(employeeType));
            formData.append("appointDate", moment(appointDate).format("DD/MM/YYYY"));
            formData.append("retirementDate", moment(retirementDate).format("DD/MM/YYYY"));
            formData.append("natureOfAppointment", parseInt(natureOfAppointId));
            formData.append("branchId", parseInt(schoolId));
            formData.append("oldName", oldName);
            formData.append("workingStatus", workingStatus);
            formData.append("permanentAddress", permanentaddress);
            formData.append("permanentCity", permanentcity);
            formData.append("biometricId", !biometricId ? 0 : parseInt(biometricId));
            formData.append("isDelete", delOptionId)
            formData.append("sessionYear", authUser.sessionYear)
            formData.append("createdBy", authUser.id)
            formData.append("userIp", authUser.headers.userIp)
            formData.append("userLat", authUser.headers.userLat)
            formData.append("userLong", authUser.headers.userLong)
            formData.append("deviceSign", authUser.headers.deviceSign)
            formData.append("deviceType", authUser.headers.deviceType)
            formData.append("deviceOS", authUser.headers.deviceOS)
            formData.append("browserName", authUser.headers.browserName)
            formData.append("browserVersion", authUser.headers.browserVersion)

            const updateFormData = new FormData();
            updateFormData.append("firstNameMr", firstNameMr);
            updateFormData.append("firstNameEn", firstNameEn);
            updateFormData.append("middleNameMr", middleNameMr);
            updateFormData.append("middleNameEn", middleNameEn);
            updateFormData.append("lastNameMr", lastNameMr);
            updateFormData.append("lastNameEn", lastNameEn);
            updateFormData.append("employeeNameMr", "");
            updateFormData.append("employeeNameEn", "");
            updateFormData.append("address", address);
            updateFormData.append("city", city);
            updateFormData.append("mobileNo", mobile);
            updateFormData.append("emailId", email);
            updateFormData.append("dateOfBirth", moment(birthDate).format("DD/MM/YYYY"));
            updateFormData.append("gender", parseInt(gender));
            updateFormData.append("bloodGroup", parseInt(bloodGroupObj.id));
            updateFormData.append("mothertounghId", parseInt(motherTongueId));
            updateFormData.append("religionId", parseInt(religionId));
            updateFormData.append("casteId", parseInt(casteId));
            updateFormData.append("subCasteId", (subCasteId == "" || subCasteId == [] || subCasteId == null) ? 0 : parseInt(subCasteId));
            updateFormData.append("categoryId", parseInt(CasteCategoryId));
            updateFormData.append("minorityId", parseInt(minorityId));
            updateFormData.append("panno", panNo);
            updateFormData.append("aadharNo", aadharNo);
            updateFormData.append("isDisability", disabilityDetails);
            updateFormData.append("disabilityId", parseInt(disabilityId));
            updateFormData.append("isMarried", parseInt(marritalStatus));
            updateFormData.append("shalarthId", shalarthId);
            updateFormData.append("pfAccountNo", pfAccountNo);
            updateFormData.append("pfJoiningDate", moment(pfJoiningDate).format("DD/MM/YYYY"));
            updateFormData.append("pfRelivingDate", moment(pfRelievingDate).format("DD/MM/YYYY"));
            updateFormData.append("sectionId", parseInt(deptId));
            updateFormData.append("designationId", parseInt(designationId));
            updateFormData.append("employeeType", parseInt(employeeType));
            updateFormData.append("appointDate", moment(appointDate).format("DD/MM/YYYY"));
            updateFormData.append("retirementDate", moment(retirementDate).format("DD/MM/YYYY"));
            updateFormData.append("natureOfAppointment", parseInt(natureOfAppointId));
            updateFormData.append("branchId", parseInt(schoolId));
            updateFormData.append("oldName", oldName);
            updateFormData.append("workingStatus", workingStatus);
            updateFormData.append("permanentAddress", permanentaddress);
            updateFormData.append("permanentCity", permanentcity);
            updateFormData.append("biometricId", !biometricId ? 0 : parseInt(biometricId));
            updateFormData.append("isDelete", delOptionId)
            updateFormData.append("sessionYear", authUser.sessionYear)
            updateFormData.append("createdBy", authUser.id)
            updateFormData.append("userIp", authUser.headers.userIp)
            updateFormData.append("userLat", authUser.headers.userLat)
            updateFormData.append("userLong", authUser.headers.userLong)
            updateFormData.append("deviceSign", authUser.headers.deviceSign)
            updateFormData.append("deviceType", authUser.headers.deviceType)
            updateFormData.append("deviceOS", authUser.headers.deviceOS)
            updateFormData.append("browserName", authUser.headers.browserName)
            updateFormData.append("browserVersion", authUser.headers.browserVersion)


            // console.log(Object.fromEntries(updateFormData))

            const updateObject = {
                firstNameMr: firstNameMr,
                firstNameEn: firstNameEn,
                middleNameMr: middleNameMr,
                middleNameEn: middleNameEn,
                lastNameMr: lastNameMr,
                lastNameEn: lastNameEn,
                employeeNameMr: "",
                employeeNameEn: "",
                address: address,
                city: city,
                mobileNo: mobile,
                emailId: email,
                dateOfBirth: moment(birthDate).format("DD/MM/YYYY"),
                gender: gender,
                bloodGroup: bloodGroupObj.id,
                mothertounghId: motherTongueId,
                religionId: religionId,
                casteId: casteId,
                subCasteId: (subCasteId == "" || subCasteId == [] || subCasteId == null) ? null : subCasteId,
                categoryId: CasteCategoryId,
                minorityId: minorityId,
                panno: panNo,
                aadharNo: aadharNo,
                isDisability: disabilityDetails,
                disabilityId: disabilityId,
                isMarried: marritalStatus,
                shalarthId: shalarthId,
                pfAccountNo: pfAccountNo,
                pfJoiningDate: moment(pfJoiningDate).format("DD/MM/YYYY"),
                pfRelivingDate: moment(pfRelievingDate).format("DD/MM/YYYY"),
                sectionId: deptId,
                designationId: designationId,
                employeeType: employeeType,
                appointDate: moment(appointDate).format("DD/MM/YYYY"),
                retirementDate: moment(retirementDate).format("DD/MM/YYYY"),
                natureOfAppointment: natureOfAppointId,
                branchId: schoolId,
                oldName: oldName,
                workingStatus: workingStatus,
                permanentAddress: permanentaddress,
                permanentCity: permanentcity,
                employeeImg: "",
                biometricId: (biometricId == '') ? 0 : parseInt(biometricId),
                createdBy: authUser.id,
                userIp: authUser.headers.userIp,
                userLat: authUser.headers.userLat,
                userLong: authUser.headers.userLong,
                deviceSign: authUser.headers.deviceSign,
                deviceType: authUser.headers.deviceType,
                deviceOS: authUser.headers.deviceOS,
                browserName: authUser.headers.browserName,
                browserVersion: authUser.headers.browserVersion
            };

            if (image != null) {
                axios.put(`${EmployeeMasterPutMapping}` + empId, formData)
                    .then((response) => {
                        // console.log('====================================');
                        // console.log("formData=-=-=-=-=-=" + response.data);
                        // console.log('====================================');
                        if (response.data === "TEACHERALLOCATED") {
                            setLoaderOption(false);
                            setDelAndUpdateWarnModal(true)
                        }
                        else if (response.data == "BIOIDEXISTS") {
                            setLoaderOption(false);
                            toast.warn(`Employee with ${biometricId} biometric id is already exists.`)
                        }
                        else if (response.data != null) {
                            //console.log("Update successfully.");

                            setSaveBtn(saveBtnStyle);
                            setUpdateBtn(displayNoneBtnStyle);
                            getData();
                            setLoaderOption(false);
                            toast.success("Employee updation successfully done.")
                            navigate("/Home/m009/2")
                        }

                    }).catch(err => {
                        console.log("update err" + err);
                        setLoaderOption(false);
                        toast.error("Something went wrong, please check.")
                    })
            }
            else {
                axios.put(`${EmployeeMasterPutMapping}` + empId, updateFormData)
                    .then((response) => {
                        // console.log('====================================');
                        // console.log("updateFormData=-=-=-=-=-=" + response.data);
                        // console.log('====================================');
                        if (response.data === "TEACHERALLOCATED") {
                            setLoaderOption(false);
                            setDelAndUpdateWarnModal(true)
                        }
                        else if (response.data == "BIOIDEXISTS") {
                            setLoaderOption(false);
                            toast.warn(`Employee with ${biometricId} biometric id is already exists.`)
                        }
                        else if (response.data != null) {
                            //console.log("Update successfully.");

                            setSaveBtn(saveBtnStyle);
                            setUpdateBtn(displayNoneBtnStyle);
                            getData();
                            setLoaderOption(false);
                            toast.success("Employee updation successfully done.")
                            navigate("/Home/m009/2")
                        }

                    }).catch(err => {
                        console.log("update err" + err);
                        setLoaderOption(false);
                        toast.error("Something went wrong, please check.")
                    })
            }
            //toast.success("Valid")
        }
        else
            if (moment(birthDate).format("DD/MM/YYYY") === "Invalid date" ||
                // moment((pfJoiningDate == null) ? new Date() : pfJoiningDate).format("DD/MM/YYYY") === "Invalid date" ||
                // moment((pfRelievingDate == null) ? new Date() : pfRelievingDate).format("DD/MM/YYYY") === "Invalid date" ||
                moment(appointDate).format("DD/MM/YYYY") === "Invalid date" ||
                moment(retirementDate).format("DD/MM/YYYY") === "Invalid date") {
                setLoaderOption(false);
                toast.error("Improper date, please check.")
            }
            else if ((newFilteredData.some(e => e.employeeNameMr === firstNameMr + " " + middleNameMr + " " + lastNameMr)) == true ||
                ((newFilteredData.some(e => e.employeeNameEn === firstNameEn + " " + middleNameEn + " " + lastNameEn))) == true) {
                setLoaderOption(false);
                toast.warn("This employee is already exists.")
            }
            else if ((newFilteredData.some(e => e.aadharNo === aadharNo)) == true) {
                setLoaderOption(false);
                toast.warn("This aadhar number is already exists.")
            }
        // else if(imgError > 1e+6){
        //     setLoaderOption(false);
        //     toast.warn("Image Size should be less than 1 MB.")
        // }

    };

    //remove image code
    const removeImage = async () => {
        setLoaderOption(true);
        if (empId == 0) {
            setShowImage(null);
            setImage(null);
            setLoaderOption(false);
        }
        else {
            await axios.delete(`${removeEmployeeImage}?id=${empId}`)
                .then(res => {
                    if (res.data == "DELETED") {
                        setImage(null);
                        setShowImage(null);
                        setLoaderOption(false);
                        toast.success("Image Removed Successfully.");
                    }
                }).catch(err => {
                    setLoaderOption(false);
                    console.log("Image Removal" + err);
                    toast.error("Something went wrong, please check.")
                })
        }

    }

    const ClearData = () => {
        navigate("/Home/m009/0")
        setSaveBtn(saveBtnStyle);
        setUpdateBtn(displayNoneBtnStyle);
    }

    const state = {
        button: 1
    };

    const onSubmit = e => {
        e.preventDefault();
        if (state.button === 1) {
            saveData();
        }
        if (state.button === 2) {
            updateData(0);
        }
    };

    useEffect(() => {

        function handlekeydownEvent(event) {
            if (event.key === "Enter") {
                // console.log("Enter is pressed!")
                event.preventDefault();
                if (EditFlag == 1) {
                    // console.log('save Enter is pressed!');
                    let btn1 = document.querySelector('.employeeMasterSaveBtn')
                    if (btn1 !== null) {
                        btn1.click();
                    }
                    setSaveBtnDisabled(false);
                    setUpdateBtnDisabled(true);
                }
                if (EditFlag == 2) {
                    // console.log('update Enter is pressed!');
                    let btn2 = document.querySelector('.employeeMasterUpdateBtn')
                    if (btn2 !== null) {
                        btn2.click();
                    }
                    setSaveBtnDisabled(true);
                    setUpdateBtnDisabled(false);
                }
            }
        }

        document.addEventListener('keypress', handlekeydownEvent)
        return () => {
            document.removeEventListener('keypress', handlekeydownEvent)
        }

    }, []);



    if (loaderOption == true) {
        return <Loader />
    }
    else {
        return (
            <>
                <TitleLabel titleId={titleId} />
                <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px" }}>
                    <form onSubmit={onSubmit}>
                        <input type="hidden" value={employeeId} onChange={(e) => setEmployeeId(e.target.value)} name="" />
                        <div className="row">
                            <label className="col-sm-2 my-1 mt-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Gender :</label>
                            <div className='col-sm-3'>
                                <RadioGroup row aria-label="gender" margin="dense" name="customized-radios" value={gender} onChange={(e) => setGender(parseInt(e.target.value))}>
                                    <FormControlLabel value={1} control={<Radio color='primary' />} label="Male" />
                                    <FormControlLabel value={2} control={<Radio color='primary' />} label="Female" />
                                </RadioGroup>
                            </div>
                        </div>

                        <div className="row my-2">
                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Marital Status :</label>
                            <div className='col-sm-4'>
                                <Box>
                                    <FormControl fullWidth margin='dense'>
                                        <InputLabel id='demo-simple-select-label' size="small" style={{ fontSize: '14px' }}>Marital Satatus</InputLabel>
                                        <Select
                                            size='small'
                                            required
                                            PaperComponent={({ children }) => (
                                                <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                            )}
                                            fullWidth
                                            MenuProps={{ classes: { paper: classes.select } }}
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select1"
                                            value={marritalStatus}
                                            label="Marrital Status"
                                            onChange={(e) => {
                                                setMarritalStatus(e.target.value);
                                            }}
                                            onKeyPress={(e) => (e.key == "Enter") ? marritalStatus : ""}
                                        >
                                            <MenuItem value={1}>{(authUser.branchMedium == 1) ? "विवाहित" : "Married"}</MenuItem>
                                            <MenuItem value={2}>{(authUser.branchMedium == 1) ? "अविवाहित" : "Unmarried"}</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Box>
                            </div>
                        </div>

                        <div className="row my-2">
                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >First Name :</label>
                            <div className='col-sm-4'>
                                <TextField
                                    required
                                    fullWidth
                                    onKeyDown={(e) => (e.key === " " && firstNameMr.length === 0) ? e.preventDefault() : ""}
                                    autoComplete='off'
                                    size='small'
                                    id="outlined-basic"
                                    label="First Name Marathi"
                                    variant="outlined"
                                    margin='dense'
                                    value={firstNameMr}
                                    onChange={e => setFirstNameMr(e.target.value)}
                                    onKeyPress={(e) => (e.key == "Enter") ? firstNameMr : ""}
                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                />
                            </div>

                            <div className='col-sm-4'>
                                <TextField
                                    required
                                    fullWidth
                                    onKeyDown={(e) => (e.key === " " && firstNameEn.length === 0) ? e.preventDefault() : ""}
                                    autoComplete='off'
                                    size='small'
                                    id="outlined-basic"
                                    label="First Name English"
                                    variant="outlined"
                                    margin='dense'
                                    value={firstNameEn}
                                    onChange={e => setFirstNameEn(e.target.value)}
                                    onKeyPress={(e) => (e.key == "Enter") ? firstNameEn : ""}
                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                />
                            </div>
                        </div>

                        <div className="row my-2">
                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Middle Name :</label>
                            <div className='col-sm-4'>
                                <TextField
                                    required
                                    fullWidth
                                    onKeyDown={(e) => (e.key === " " && middleNameMr.length === 0) ? e.preventDefault() : ""}
                                    autoComplete='off'
                                    size='small'
                                    id="outlined-basic"
                                    label="Middle Name Marathi"
                                    variant="outlined"
                                    margin='dense'
                                    value={middleNameMr}
                                    onChange={e => setMiddleNameMr(e.target.value)}
                                    onKeyPress={(e) => (e.key == "Enter") ? middleNameMr : ""}
                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                />
                            </div>

                            <div className='col-sm-4'>
                                <TextField
                                    required
                                    fullWidth
                                    onKeyDown={(e) => (e.key === " " && middleNameEn.length === 0) ? e.preventDefault() : ""}
                                    autoComplete='off'
                                    size='small'
                                    id="outlined-basic"
                                    label="Middal Name English"
                                    variant="outlined"
                                    margin='dense'
                                    value={middleNameEn}
                                    onChange={e => setMiddleNameEn(e.target.value)}
                                    onKeyPress={(e) => (e.key == "Enter") ? middleNameEn : ""}
                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                />
                            </div>
                        </div>

                        <div className="row my-2">
                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Last Name :</label>
                            <div className='col-sm-4'>
                                <TextField
                                    required
                                    fullWidth
                                    onKeyDown={(e) => (e.key === " " && lastNameMr.length === 0) ? e.preventDefault() : ""}
                                    autoComplete='off'
                                    size='small'
                                    id="outlined-basic"
                                    label="Last Name Marathi"
                                    variant="outlined"
                                    margin='dense'
                                    value={lastNameMr}
                                    onChange={e => setLastNameMr(e.target.value)}
                                    onKeyPress={(e) => (e.key == "Enter") ? lastNameMr : ""}
                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                />
                            </div>

                            <div className='col-sm-4'>
                                <TextField
                                    required
                                    fullWidth
                                    onKeyDown={(e) => (e.key === " " && lastNameEn.length === 0) ? e.preventDefault() : ""}
                                    autoComplete='off'
                                    size='small'
                                    id="outlined-basic"
                                    label="Last Name English"
                                    variant="outlined"
                                    margin='dense'
                                    value={lastNameEn}
                                    onChange={e => setLastNameEn(e.target.value)}
                                    onKeyPress={(e) => (e.key == "Enter") ? lastNameEn : ""}
                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                />
                            </div>
                        </div>

                        <div className="row my23" hidden={(gender == 1 || marritalStatus == 2) ? true : false}>
                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Old Name :</label>
                            <div className='col-sm-8'>
                                <TextField
                                    fullWidth
                                    onKeyDown={(e) => (e.key === " " && oldName.length === 0) ? e.preventDefault() : ""}
                                    autoComplete='off'
                                    size='small'
                                    id="outlined-basic"
                                    label="Full Name"
                                    variant="outlined"
                                    margin='dense'
                                    value={oldName}
                                    onChange={e => setOldName(e.target.value)}
                                    onKeyPress={(e) => (e.key == "Enter") ? oldName : ""}
                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                    inputProps={{ maxLength: 120 }}
                                />
                            </div>
                        </div>

                        <div className="row my-2">
                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Current Address :</label>
                            <div className='col-sm-8'>
                                <TextField
                                    required
                                    fullWidth
                                    onKeyDown={(e) => (e.key === " " && address.length === 0) ? e.preventDefault() : ""}
                                    autoComplete='off'
                                    size='small'
                                    id="outlined-basic"
                                    label="Current Address"
                                    variant="outlined"
                                    margin='dense'
                                    value={address}
                                    onChange={e => setAddress(e.target.value)}
                                    onKeyPress={(e) => (e.key == "Enter") ? address : ""}
                                    InputLabelProps={{ style: { fontSize: 14 }, shrink: address !== "" }}
                                />
                            </div>
                        </div>

                        <div className="row my-2">
                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Current City :</label>
                            <div className='col-sm-4'>
                                <TextField
                                    required
                                    fullWidth
                                    onKeyDown={(e) => (e.key === " " && city.length === 0) ? e.preventDefault() : ""}
                                    autoComplete='off'
                                    size='small'
                                    id="outlined-basic"
                                    label="Current City"
                                    variant="outlined"
                                    margin='dense'
                                    value={city}
                                    onChange={e => setCity(e.target.value)}
                                    onKeyPress={(e) => (e.key == "Enter") ? city : ""}
                                    InputLabelProps={{ style: { fontSize: 14 }, shrink: city !== "" }}
                                />
                            </div>
                        </div>

                        <div className="row my-2">
                            <div className='col-sm-12 mt-2'>
                                <FormControlLabel
                                    style={{ color: "#D33F33", fontWeight: '600' }}
                                    control={<Checkbox size="small" checked={addressChkBox} onChange={HandleAddressChkBox} />}
                                    label={<span style={{ fontWeight: 'bold' }}>Use Current Address for Permanent Address</span>}
                                />
                            </div>
                        </div>

                        <div className="row my-2">
                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Permanent Address :</label>
                            <div className='col-sm-8'>
                                <TextField
                                    required
                                    fullWidth
                                    onKeyDown={(e) => (e.key === " " && permanentaddress.length === 0) ? e.preventDefault() : ""}
                                    autoComplete='off'
                                    size='small'
                                    id="outlined-basic"
                                    label="Permanent Address"
                                    variant="outlined"
                                    margin='dense'
                                    value={permanentaddress}
                                    onChange={e => setPermanentAddress(e.target.value)}
                                    onKeyPress={(e) => (e.key == "Enter") ? permanentaddress : ""}
                                    InputLabelProps={{ style: { fontSize: 14 }, shrink: permanentaddress !== "" }}
                                />
                            </div>
                        </div>

                        <div className="row my-2">
                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Permanent City :</label>
                            <div className='col-sm-4'>
                                <TextField
                                    required
                                    fullWidth
                                    onKeyDown={(e) => (e.key === " " && permanentcity.length === 0) ? e.preventDefault() : ""}
                                    autoComplete='off'
                                    size='small'
                                    id="outlined-basic"
                                    label="Permanent City"
                                    variant="outlined"
                                    margin='dense'
                                    value={permanentcity}
                                    onChange={e => setPermanentCity(e.target.value)}
                                    onKeyPress={(e) => (e.key == "Enter") ? permanentcity : ""}
                                    InputLabelProps={{ style: { fontSize: 14 }, shrink: permanentcity !== "" }}
                                />
                            </div>
                        </div>

                        <div className="row my-2">
                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Mobile No :</label>
                            <div className='col-sm-4'>
                                <TextField
                                    required
                                    fullWidth
                                    onKeyDown={(e) => (e.key === " " && mobile.length === 0) ? e.preventDefault() : ""}
                                    autoComplete='off'
                                    type="number"
                                    onWheel={(e) => e.target.blur()}
                                    size='small'
                                    id="outlined-basic"
                                    label="Mobile No"
                                    variant="outlined"
                                    margin='dense'
                                    //error={isError}
                                    value={mobile}
                                    onChange={(e) => {
                                        setmobile(e.target.value);
                                        // if (e.target.value.length > 13) {
                                        //     setIsError(true);
                                        // }
                                    }}
                                    onKeyPress={(e) => (e.key == "Enter") ? mobile : ""}
                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                    onInput={(e) => { e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 12) }}
                                />
                            </div>
                        </div>

                        <div className="row my-2">
                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Email Id :</label>
                            <div className='col-sm-4'>
                                <ValidatorForm>
                                    <TextValidator
                                        required
                                        fullWidth
                                        onKeyDown={(e) => (e.key === " " && email.length === 0) ? e.preventDefault() : ""}
                                        label="Email"
                                        autoComplete='off'
                                        size='small'
                                        margin='dense'
                                        onChange={(e) => setEmail(e.target.value)}
                                        onKeyPress={(e) => (e.key == "Enter") ? email : ""}
                                        name="email"
                                        value={email}
                                        variant="outlined"
                                        InputLabelProps={{ style: { fontSize: 14 } }}
                                        inputProps={{ maxLength: 120 }}
                                        validators={['isEmail']}
                                        errorMessages={['email is not valid']}
                                    />
                                </ValidatorForm>
                            </div>
                        </div>

                        <div className="row my-2">
                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Date Of Birth :</label>
                            <div className='col-sm-4'>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        label="Date Of Birth"
                                        value={birthDate}
                                        onChange={(newValue) => {
                                            setBirthDate(newValue);
                                            setRetirementDate(null);
                                            setAppointDate(null);
                                            if (newValue == null) {
                                                setRetirementDate(null);
                                                setAppointDate(null);
                                            }
                                        }}
                                        onKeyPress={(e) => (e.key == "Enter") ? birthDate : ""}
                                        inputFormat="dd/MM/yyyy"
                                        renderInput={(params) =>
                                            <TextField
                                                {...params}
                                                margin='dense'
                                                variant="outlined"
                                                InputLabelProps={{ style: { fontSize: 14 } }}
                                                required
                                                fullWidth
                                                autoComplete='off'
                                                size="small" />}
                                    />
                                </LocalizationProvider>
                            </div>
                        </div>



                        <div className="row my-2">
                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Blood Group :</label>
                            <div className="col-sm-4">
                                {/* <FormControl fullWidth sx={{ minWidth: 80 }} margin='dense'>
                                    <InputLabel id='demo-simple-select-label' size="small" style={{ fontSize: '14px' }}>Blood Group</InputLabel>
                                    <Select
                                        size='small'

                                        PaperComponent={({ children }) => (
                                            <Paper style={{ backgroundColor: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                        )}
                                        MenuProps={{ classes: { paper: classes.select } }}

                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={bloodGroup}
                                        label="Blood Group"
                                        onChange={(e) => setBloodGroup(e.target.value)}
                                        onKeyPress={(e) => (e.key == "Enter") ? bloodGroup : ""}
                                    >
                                        <MenuItem value={0}>Unknown</MenuItem>
                                        <MenuItem value={1}>A Positive (A+)</MenuItem>
                                        <MenuItem value={2}>A Negative (A-)</MenuItem>
                                        <MenuItem value={3}>B Positive (B+)</MenuItem>
                                        <MenuItem value={4}>B Negative (B-)</MenuItem>
                                        <MenuItem value={5}>O Positive (O+)</MenuItem>
                                        <MenuItem value={6}>O Negative (O-)</MenuItem>
                                        <MenuItem value={7}>AB Positive (AB+)</MenuItem>
                                        <MenuItem value={8}>AB Negative (AB-)</MenuItem>
                                    </Select>
                                </FormControl> */}

                                <Autocomplete
                                    id="combo-box-demo"
                                    PaperComponent={({ children }) => (
                                        <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                    )}
                                    options={bloodGroupData}
                                    onChange={(event, newValue) => {
                                        setBloodGroupObj(newValue);
                                    }}
                                    onKeyPress={(e) => (e.key == "Enter") ? bloodGroupObj : ""}
                                    value={bloodGroupObj}
                                    style={{ fontSize: '14px' }}
                                    getOptionLabel={option => option.nameMr}
                                    size="small"
                                    renderInput={params => (
                                        <TextField {...params} margin='dense' label="Select Blood Group" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                    )}
                                />
                            </div>
                        </div>


                        <div className='row my-2'>
                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Mother Tongue :</label>
                            <div className="col-sm-4 ">
                                <Autocomplete
                                    id="combo-box-demo"
                                    PaperComponent={({ children }) => (
                                        <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                    )}
                                    options={motherToungData}
                                    onChange={(event, newValue) => {
                                        setMotherTongueObj(newValue);
                                        if (newValue != null) {
                                            setMotherTongueId(newValue.id);
                                        }
                                    }}
                                    onKeyPress={(e) => (e.key == "Enter") ? motherTongueObj : ""}
                                    value={motherTongueObj}
                                    style={{ fontSize: '14px' }}
                                    getOptionLabel={option => (authUser.branchMedium == 1) ? option.nameMr : option.nameEn}
                                    size="small"
                                    renderInput={params => (
                                        <TextField {...params} margin='dense' label="Select Mother Tongue" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                    )}
                                />
                            </div>
                            <div className="col-sm-4 mt-2">
                                <button type='button' className='btn p-0 mt-2' data-bs-toggle="modal" data-bs-target="#motherTongueModal">
                                    <NoteAddIcon fontSize='medium' style={{ color: "#1F883C" }} />
                                </button>
                            </div>
                        </div>

                        <div className='row my-2'>
                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Religion :</label>
                            <div className="col-sm-4">
                                <Autocomplete
                                    id="combo-box-demo"
                                    PaperComponent={({ children }) => (
                                        <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                    )}
                                    options={religionData}
                                    onChange={(event, newValue) => {
                                        setReligionObj(newValue);
                                        if (newValue != null) {
                                            setReligionId(newValue.id);
                                        }
                                    }}
                                    onKeyPress={(e) => (e.key == "Enter") ? religionObj : ""}
                                    value={religionObj}
                                    style={{ fontSize: '14px' }}
                                    getOptionLabel={option => (authUser.branchMedium == 1) ? option.nameMr : option.nameEn}
                                    size="small"
                                    renderInput={params => (
                                        <TextField {...params} margin='dense' label="Select Religion" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                    )}
                                />
                            </div>
                            <div className="col-sm-4 mt-2">
                                <button type='button' className='btn p-0 mt-2' data-bs-toggle="modal" data-bs-target="#religionModal">
                                    <NoteAddIcon fontSize='medium' style={{ color: "#1F883C" }} />
                                </button>
                            </div>
                        </div>

                        <div className='row my-2'>
                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Caste :</label>
                            <div className="col-sm-4 ">
                                <Autocomplete
                                    id="combo-box-demo"
                                    PaperComponent={({ children }) => (
                                        <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                    )}
                                    options={casteData}
                                    onChange={(event, newValue) => {
                                        setCasteObj(newValue);
                                        if (newValue != null) {
                                            setCasteId(newValue.id);
                                        }
                                    }}
                                    onKeyPress={(e) => (e.key == "Enter") ? casteObj : ""}
                                    value={casteObj}
                                    style={{ fontSize: '14px' }}
                                    getOptionLabel={option => (authUser.branchMedium == 1) ? option.nameMr : option.nameEn}
                                    size="small"
                                    renderInput={params => (
                                        <TextField {...params} margin='dense' label="Select Caste" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                    )}
                                />
                            </div>
                            <div className="col-sm-4 mt-2">
                                <button type='button' className='btn p-0 mt-2' data-bs-toggle="modal" data-bs-target="#casteModal">
                                    <NoteAddIcon fontSize='medium' style={{ color: "#1F883C" }} />
                                </button>
                            </div>
                        </div>

                        <div className='row my-2'>
                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Sub Caste :</label>
                            <div className="col-sm-4 ">
                                <Autocomplete
                                    id="combo-box-demo"
                                    PaperComponent={({ children }) => (
                                        <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                    )}
                                    options={subCasteData}
                                    onChange={(event, newValue) => {
                                        setSubCasteObj(newValue);
                                        if (newValue != null) {
                                            setSubCasteId(newValue.id)
                                        }
                                    }}
                                    onKeyPress={(e) => (e.key == "Enter") ? subCasteObj : ""}
                                    value={subCasteObj}
                                    style={{ fontSize: '14px' }}
                                    getOptionLabel={option => (authUser.branchMedium == 1) ? option.nameMr : option.nameEn}
                                    size="small"
                                    renderInput={params => (
                                        <TextField {...params} margin='dense' label="Select Sub Caste" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} />
                                    )}
                                />
                            </div>
                            <div className="col-sm-4 mt-2">
                                <button type='button' className='btn p-0 mt-2' data-bs-toggle="modal" data-bs-target="#subCasteModal">
                                    <NoteAddIcon fontSize='medium' style={{ color: "#1F883C" }} />
                                </button>
                            </div>
                        </div>

                        <div className='row my-2'>
                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Caste Category :</label>
                            <div className="col-sm-4 ">
                                <Autocomplete
                                    id="combo-box-demo"
                                    PaperComponent={({ children }) => (
                                        <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                    )}
                                    options={categoryData}
                                    onChange={(event, newValue) => {
                                        setCasteCategoryObj(newValue);
                                        if (newValue != null) {
                                            setCasteCategoryId(newValue.id);
                                        }
                                    }}
                                    onKeyPress={(e) => (e.key == "Enter") ? CasteCategoryObj : ""}
                                    value={CasteCategoryObj}
                                    style={{ fontSize: '14px' }}
                                    getOptionLabel={option => (authUser.branchMedium == 1) ? option.nameMr : option.nameEn}
                                    size="small"
                                    renderInput={params => (
                                        <TextField {...params} margin='dense' label="Select Caste Category" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                    )}
                                />
                            </div>
                            {/* <div className="col-sm-4">
                                <button type='button' className='btn p-0 mt-2' data-bs-toggle="modal" data-bs-target="#casteCategoryModal">
                                    <NoteAddIcon fontSize='medium' style={{ color: "#1F883C" }} />
                                </button>
                            </div> */}
                        </div>

                        <div className="row my-2">
                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Minority :</label>
                            <div className='col-sm-4'>
                                <Autocomplete
                                    id="combo-box-demo"
                                    PaperComponent={({ children }) => (
                                        <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                    )}
                                    options={minorityData}
                                    onChange={(event, newValue) => {
                                        setMinorityObj(newValue);
                                        if (newValue != null) {
                                            setMinorityId(newValue.id);
                                        }
                                    }}
                                    value={minorityObj}
                                    onKeyPress={(e) => (e.key == "Enter") ? minorityObj : ""}
                                    style={{ fontSize: '14px' }}
                                    getOptionLabel={option => (authUser.branchMedium == 1) ? option.nameMr : option.nameEn}
                                    size="small"
                                    renderInput={params => (
                                        <TextField {...params} margin='dense' label="Select Minority" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                    )}
                                />
                            </div>
                            <div className="col-sm-4 mt-2">
                                <button type='button' className='btn p-0 mt-2' data-bs-toggle="modal" data-bs-target="#minorityModal">
                                    <NoteAddIcon fontSize='medium' style={{ color: "#1F883C" }} />
                                </button>
                            </div>
                        </div>

                        <div className="row my-2">
                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >PAN No :</label>
                            <div className='col-sm-4'>
                                <TextField
                                    fullWidth
                                    autoComplete='off'
                                    onKeyDown={(e) => (e.key === " " && panNo.length === 0) ? e.preventDefault() : ""}
                                    size='small'
                                    id="outlined-basic"
                                    label="PAN No"
                                    variant="outlined"
                                    margin='dense'
                                    value={panNo}
                                    onChange={e => setPanNo(e.target.value)}
                                    onKeyPress={(e) => (e.key == "Enter") ? panNo : ""}
                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                // inputProps={{
                                //     // pattern: "[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}",
                                //     // style: { textTransform: "uppercase" },
                                //     maxLength: 10
                                // }}
                                />
                            </div>
                        </div>

                        <div className="row my-2">
                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Aadhar No :</label>
                            <div className='col-sm-4'>
                                <TextField
                                    required
                                    fullWidth
                                    onKeyDown={(e) => (e.key === " " && aadharNo.length === 0) ? e.preventDefault() : ""}
                                    type="number"
                                    onWheel={(e) => e.target.blur()}
                                    autoComplete='off'
                                    size='small'
                                    id="outlined-basic"
                                    label="Aadhar No"
                                    variant="outlined"
                                    margin='dense'
                                    value={aadharNo}
                                    onChange={e => setAadharNo(e.target.value)}
                                    onKeyPress={(e) => (e.key == "Enter") ? aadharNo : ""}
                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                    onInput={(e) => { e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 12) }}
                                />
                            </div>
                        </div>

                        <div className="row my-2">
                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Disability :</label>
                            <div className='col-sm-4'>
                                <Box>
                                    <FormControl fullWidth margin='dense'>
                                        <InputLabel id='demo-simple-select-label' size="small" style={{ fontSize: '14px' }}>Select Disability</InputLabel>
                                        <Select
                                            size='small'
                                            PaperComponent={({ children }) => (
                                                <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                            )}
                                            fullWidth
                                            MenuProps={{ classes: { paper: classes.select } }}
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={disabilityDetails}
                                            label="Select Disability"
                                            onChange={(e) => {
                                                setDisabilityDetails(e.target.value);
                                                setDisabilityObj(null);
                                            }}
                                            onKeyPress={(e) => (e.key == "Enter") ? disabilityDetails : ""}
                                        >
                                            <MenuItem value={1}>Yes</MenuItem>
                                            <MenuItem value={2}>No</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Box>

                            </div>
                        </div>

                        <div className="row my-2">
                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Disability Details :</label>
                            <div className='col-sm-4'>
                                <Autocomplete
                                    id="combo-box-demo"
                                    PaperComponent={({ children }) => (
                                        <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                    )}
                                    options={(disabilityDetails == 1) ? disabilityData : []}
                                    onChange={(event, newValue) => {
                                        setDisabilityObj(newValue);
                                        if (newValue != null) {
                                            setDisabilityId(newValue.id);
                                        }
                                    }}
                                    onKeyPress={(e) => (e.key == "Enter") ? disabilityObj : ""}
                                    value={disabilityObj}
                                    style={{ fontSize: '14px' }}
                                    getOptionLabel={option => (authUser.branchMedium == 1) ? option.nameMr : option.nameEn}
                                    size="small"
                                    renderInput={params => (
                                        <TextField {...params} margin='dense' label="Select Disability Details" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} />
                                    )}
                                />
                            </div>
                        </div>

                        <div className="row my-2">
                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Shalarth Id :</label>
                            <div className='col-sm-4'>
                                <TextField
                                    fullWidth
                                    autoComplete='off'
                                    onKeyDown={(e) => (e.key === " " && shalarthId.length === 0) ? e.preventDefault() : ""}
                                    size='small'
                                    id="outlined-basic"
                                    label="Shalarth Id"
                                    margin='dense'
                                    variant="outlined"
                                    value={shalarthId}
                                    onChange={e => setShalarthId(e.target.value)}
                                    onKeyPress={(e) => (e.key == "Enter") ? shalarthId : ""}
                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                    inputProps={{ maxLength: 120 }}
                                />
                            </div>
                        </div>

                        <div className="row my-2">
                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >PF Account No :</label>
                            <div className='col-sm-4'>
                                <TextField
                                    fullWidth
                                    autoComplete='off'
                                    onKeyDown={(e) => (e.key === " " && pfAccountNo.length === 0) ? e.preventDefault() : ""}
                                    size='small'
                                    id="outlined-basic"
                                    label="PF Account No"
                                    variant="outlined"
                                    margin='dense'
                                    value={pfAccountNo}
                                    onChange={e => setPfAccountNo(e.target.value)}
                                    onKeyPress={(e) => (e.key == "Enter") ? pfAccountNo : ""}
                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                    inputProps={{ maxLength: 120 }}
                                />
                            </div>
                        </div>

                        <div className="row my-2">
                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >PF Date Of Joining :</label>
                            <div className='col-sm-4'>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        label="PF Date Of Joining"
                                        value={pfJoiningDate}
                                        onChange={(newValue) => {
                                            setPfJoiningDate(newValue);
                                        }}
                                        onKeyPress={(e) => (e.key == "Enter") ? pfJoiningDate : ""}
                                        inputFormat="dd/MM/yyyy"
                                        renderInput={(params) =>
                                            <TextField
                                                {...params}
                                                variant="outlined"
                                                margin='dense'
                                                InputLabelProps={{ style: { fontSize: 14 } }}
                                                fullWidth
                                                autoComplete='off'
                                                size="small" />}
                                    />
                                </LocalizationProvider>
                            </div>
                        </div>

                        <div className="row my-2">
                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >PF Date Of Relieving :</label>
                            <div className='col-sm-4'>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        label="PF Date Of Relieving"
                                        value={pfRelievingDate}
                                        inputFormat="dd/MM/yyyy"
                                        onChange={(newValue) => {
                                            setPfRelievingDate(newValue);
                                        }}
                                        onKeyPress={(e) => (e.key == "Enter") ? pfRelievingDate : ""}
                                        renderInput={(params) =>
                                            <TextField
                                                {...params}
                                                variant="outlined"
                                                margin='dense'
                                                InputLabelProps={{ style: { fontSize: 14 } }}
                                                fullWidth
                                                autoComplete='off'
                                                size="small" />}
                                    />
                                </LocalizationProvider>
                            </div>
                        </div>

                        <div className="row my-2">
                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Section(Department) :</label>
                            <div className='col-sm-4'>
                                <Autocomplete
                                    PaperComponent={({ children }) => (
                                        <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                    )}
                                    id="combo-box-demo"
                                    size="small"
                                    style={{ fontSize: "12px" }}
                                    options={deptData}
                                    value={deptObj}
                                    onChange={(event, newValue) => {
                                        setDeptObj(newValue);
                                        if (newValue != null) {
                                            setDeptId(newValue.id);
                                        }
                                    }}
                                    onKeyPress={(e) => (e.key == "Enter") ? deptObj : ""}
                                    getOptionLabel={option => (authUser.branchMedium == 1) ? option.nameMr : option.nameEn}
                                    renderInput={(params) => <TextField {...params} margin='dense' label="Select Department" InputLabelProps={{ style: { fontSize: 14 } }} required />}

                                />
                            </div>
                        </div>

                        <div className='row my-2'>
                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Designation :</label>
                            <div className="col-sm-4 ">
                                <Autocomplete
                                    id="combo-box-demo"
                                    PaperComponent={({ children }) => (
                                        <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                    )}
                                    options={designationData}
                                    onChange={(event, newValue) => {
                                        setDesignationObj(newValue);
                                        if (newValue != null) {
                                            setDesignationId(newValue.id);
                                        }
                                    }}
                                    value={designationObj}
                                    onKeyPress={(e) => (e.key == "Enter") ? designationObj : ""}
                                    style={{ fontSize: '14px' }}
                                    getOptionLabel={option => (authUser.branchMedium == 1) ? option.nameMr : option.nameEn}
                                    size="small"
                                    renderInput={params => (
                                        <TextField {...params} margin='dense' label="Select Designation" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                    )}
                                />
                            </div>
                        </div>

                        <div className="row my-2">
                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Employee Type :</label>
                            <div className='col-sm-4'>
                                <Autocomplete
                                    id="combo-box-demo"
                                    PaperComponent={({ children }) => (
                                        <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                    )}
                                    options={employeeTypeData}
                                    onChange={(event, newValue) => {
                                        setEmployeeTypeObj(newValue);
                                        if (newValue != null) {
                                            setEmployeeType(newValue.id);
                                        }
                                    }}
                                    value={employeeTypeObj}
                                    onKeyPress={(e) => (e.key == "Enter") ? employeeTypeObj : ""}
                                    style={{ fontSize: '14px' }}
                                    getOptionLabel={option => option.employee}
                                    size="small"
                                    renderInput={params => (
                                        <TextField {...params} margin='dense' label="Select Employee Type" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                    )}
                                />
                            </div>
                        </div>

                        <div className="row my-2">
                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Appoint Date :</label>
                            <div className='col-sm-4'>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        label="Appoint Date"
                                        value={appointDate}
                                        inputFormat="dd/MM/yyyy"
                                        onChange={(newValue) => {
                                            setAppointDate(newValue);
                                            //setRetirementDate(null);
                                            if (newValue == null) {
                                                setRetirementDate(null);
                                            }
                                            else {
                                                getRetirementDate(newValue);
                                            }


                                        }}
                                        onKeyPress={(e) => (e.key == "Enter") ? appointDate : ""}
                                        renderInput={(params) =>
                                            <TextField
                                                {...params}
                                                margin='dense'
                                                variant="outlined"
                                                InputLabelProps={{ style: { fontSize: 14 } }}
                                                required
                                                fullWidth
                                                autoComplete='off'
                                                size="small" />}
                                    />
                                </LocalizationProvider>
                            </div>
                        </div>

                        <div className="row my-2">
                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Retirement Date :</label>
                            <div className='col-sm-4'>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        label="Retirement Date"
                                        value={retirementDate}
                                        inputFormat="dd/MM/yyyy"
                                        onChange={(newValue) => {
                                            setRetirementDate(newValue);
                                        }}
                                        onKeyPress={(e) => (e.key == "Enter") ? retirementDate : ""}
                                        renderInput={(params) =>
                                            <TextField
                                                {...params}
                                                margin='dense'
                                                variant="outlined"
                                                InputLabelProps={{ style: { fontSize: 14 } }}
                                                required
                                                fullWidth
                                                autoComplete='off'
                                                size="small" />}
                                    />
                                </LocalizationProvider>
                            </div>
                        </div>

                        <div className="row my-2">
                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "10px" }} >Nature Of Appointment :</label>
                            <div className='col-sm-4'>
                                <Autocomplete
                                    id="combo-box-demo"
                                    PaperComponent={({ children }) => (
                                        <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                    )}
                                    options={natureOfAppointment}
                                    onChange={(event, newValue) => {
                                        setNatureOfAppointObj(newValue);
                                        if (newValue != null) {
                                            setNatureOfAppointId(newValue.id);
                                        }
                                    }}
                                    onKeyPress={(e) => (e.key == "Enter") ? natureOfAppointObj : ""}
                                    value={natureOfAppointObj}
                                    style={{ fontSize: '14px' }}
                                    getOptionLabel={option => option.natureType}
                                    size="small"
                                    renderInput={params => (
                                        <TextField {...params} margin='dense' label="Select Nature Of Appointment" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                    )}
                                />
                            </div>
                        </div>

                        <div className="row my-2">
                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Working Status :</label>
                            <div className='col-sm-4'>
                                <Box>
                                    <FormControl fullWidth margin='dense'>
                                        <InputLabel id='demo-simple-select-label' size="small" style={{ fontSize: '14px' }}>Working Status</InputLabel>
                                        <Select
                                            size='small'
                                            required
                                            PaperComponent={({ children }) => (
                                                <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                            )}
                                            fullWidth
                                            MenuProps={{ classes: { paper: classes.select } }}
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={workingStatus}
                                            label="Working Status"
                                            onChange={(e) => setWorkingStatus(e.target.value)}
                                            onKeyPress={(e) => (e.key == "Enter") ? workingStatus : ""}
                                        >
                                            <MenuItem value={1}>Current</MenuItem>
                                            <MenuItem value={2}>Terminate</MenuItem>
                                            <MenuItem value={3}>Voluntary</MenuItem>
                                            <MenuItem value={4}>Retired</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Box>
                            </div>
                        </div>

                        <div className="row my-2">
                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Biometric Id :</label>
                            <div className='col-sm-4'>
                                <TextField
                                    fullWidth
                                    autoComplete='off'
                                    onWheel={(e) => e.target.blur()}
                                    type='number'
                                    onKeyDown={(e) => (e.key === " " && biometricId.length === 0) ? e.preventDefault() : ""}
                                    size='small'
                                    id="outlined-basic"
                                    label="Biometric Id"
                                    variant="outlined"
                                    margin='dense'
                                    value={biometricId}
                                    onChange={e => setBiometricId(e.target.value)}
                                    onKeyPress={(e) => (e.key == "Enter") ? biometricId : ""}
                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                    inputProps={{ maxLength: 120 }}
                                />
                            </div>
                        </div>

                        <div className="row my-3">
                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >School Name :</label>
                            <div className='col-sm-6'>
                                <Autocomplete
                                    id="combo-box-demo"
                                    PaperComponent={({ children }) => (
                                        <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                    )}
                                    options={schoolData}
                                    onChange={(event, newValue) => {
                                        setSchoolObj(newValue);
                                        if (newValue != null) {
                                            setSchoolId(newValue.id);
                                        }
                                    }}
                                    value={schoolObj}
                                    onKeyPress={(e) => (e.key == "Enter") ? schoolObj : ""}
                                    style={{ fontSize: '14px' }}
                                    getOptionLabel={option => (authUser.branchMedium == 1) ? option.schoolNameMr : option.schoolNameEn}
                                    size="small"
                                    renderInput={params => (
                                        <TextField {...params} margin='dense' label="Select School Name" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                    )}
                                />
                            </div>
                        </div>

                        <div className="row py-2 mb-2" style={{ backgroundColor: "#FEF9E7", border: "1px solid #922B21", minHeight: "150px" }}>
                            {/* <div className="col-sm-6"> */}
                            <h5>IMAGE UPLOAD</h5>
                            <div className="row">
                                <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "12px" }} >Select Image :</label>
                                <div className='col-sm-3 mt-2'>
                                    <input
                                        type="file"
                                        name="file"
                                        onChange={(e) => onChangePicture(e)}
                                        className='form-control form-control-sm'
                                        accept="image/*"
                                    //required
                                    //onKeyPress={(e) => (e.key == "Enter") ? setFile(e.target.files[0]) : ''}
                                    />
                                </div>
                                <div className="col-sm-4 mt-1">
                                    <button type='button' onClick={handleClick} className='btn btn-sm' style={{ backgroundColor: "#2C8F04", color: "#fff" }}><CameraAltIcon fontSize='small' /> Capture Photo</button>
                                    {
                                        (handleFileInput != null)
                                            ?
                                            <input
                                                style={{ display: "none" }}
                                                type="file"
                                                name="file"
                                                accept="image/*"
                                                capture="user"
                                                ref={handleFileInput}
                                                onChange={(e) => onChangePicture(e)}
                                            />
                                            :
                                            ''
                                    }

                                </div>
                            </div>

                            <div className="row mt-2">
                                <div className="offset-sm-2" hidden={(showImage == null) ? true : false}>
                                    <div className="previewProfilePic">
                                        <img className="playerProfilePic_home_tile" src={showImage && showImage} style={{ width: "180px", height: "150px", border: "1px solid #BDC3C7" }}></img>
                                    </div>
                                </div>
                            </div>

                            <div className="row mt-2" hidden={(showImage == null) ? true : false}>
                                <div className="col-sm-4 offset-sm-2">
                                    <button type='button' onClick={() => { removeImage() }} className='btn btn-sm' style={{ backgroundColor: "#E76602", color: "#fff" }}>Remove Image</button>
                                </div>
                            </div>
                            {/* </div> */}

                            {/* <div className="col-sm-6" hidden={(EditFlag == 1) ? true : false}>
                                <h5>RECEIVED IMAGE</h5>
                                <table className='table table-bordered mt-2'>
                                    <thead>
                                        <tr>
                                            <th style={{ fontSize: "14px", fontWeight: 600 }}>ID</th>
                                            <th style={{ fontSize: "14px", fontWeight: 600 }}>Image</th>
                                            
                                        </tr>
                                    </thead>
                                    <tbody>
                                        
                                    </tbody>
                                </table>
                            </div> */}
                        </div>

                        <div className="row mt-3">
                            <div className="col-sm-4 offset-sm-2">
                                <button type="submit" disabled={saveBtnDisabled} onClick={() => (state.button = 1)} className={saveBtn} >Save</button>
                                <button type="submit" disabled={updateBtnDisabled} onClick={() => (state.button = 2)} className={updateBtn} >Update</button>
                                <button type="button" onClick={ClearData} className="btn btn-danger btn-sm" style={{ margin: '0px 4px' }} >Cancel</button>
                            </div>
                        </div>
                    </form>
                </div>
                {/* <ToastContainer position="top-right" theme="colored" /> */}
                <AddNewReligionModal setReligionData={setReligionData} />
                <AddNewCaste setCasteData={setCasteData} />
                <AddNewSubCaste setSubCasteData={setSubCasteData} />
                <AddNewCasteCategory setCasteCategoryData={setCategoryData} />
                <AddNewMinority setMinorityData={setMinorityData} />
                <AddNewMotherTongueModal setMotherToungData={setMotherToungData} />

                <Modal show={openWarningModal} onHide={() => setOpenWarningModal(false)} size="sm">
                    <Form>
                        <Modal.Body>
                            <div className="row" style={{ justifyContent: "center", display: "flex" }}>
                                <span className="text-center" style={{ lineHeight: "2" }}> <img src={warningImg} style={{ width: "65px" }} /></span><br />
                            </div><br />
                            <div className="row">
                                <span style={{ fontSize: "15px", fontWeight: "600", color: "#000", textAlign: "center" }}>Webcam or camera is not found.</span>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="danger" type='button' onClick={() => setOpenWarningModal(false)}>
                                OK
                            </Button>
                        </Modal.Footer>
                    </Form>
                </Modal>

                {/* Delete and Update teacher  Allocation Confirmation Box */}
                <Modal show={delAndUpdateWarnModal} onHide={() => setDelAndUpdateWarnModal(false)} size="sm">
                    <Form>
                        <Modal.Body>
                            <div className="row" style={{ justifyContent: "center", display: "flex" }}>
                                WARNING
                            </div><br />
                            <div className="row">
                                <span style={{ fontSize: "15px", fontWeight: "600", color: "#000", textAlign: "center" }}>
                                    Subject and Class allocation will be removed, Do you want to continue.</span>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="success" type='button' onClick={() => {
                                updateData(1)
                            }}>
                                YES
                            </Button>
                            <Button variant="danger" type='button' onClick={() => setDelAndUpdateWarnModal(false)}>
                                NO
                            </Button>
                        </Modal.Footer>
                    </Form>
                </Modal>
            </>
        )
    }
};

export default MasterFormEmployee;