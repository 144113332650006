const SansthaJSON = [
    {
        MenuType: "normal",
        PathName: "/Home/sansthaDashboard/",
        Title: "Dashboard",
        LinkIcon: "home"
    },
    {
        MenuType: "dropdown",
        PathName: "#",
        Title: "Master",
        LinkIcon: "dashboard",
        DropDownSubMenu: [
            {
                id: 141,
                SubPath: "/Home/m001/141",
                SubName: "State Master"
            },
            {
                id: 1405,
                SubPath: "/Home/m01/1405",
                SubName: "Member Category Master"
            },
            {
                id: 1401,
                SubPath: "/Home/m1401/",
                SubName: "Member Master"
            },
            {
                id: 1402,
                SubPath: "/Home/m1402/",
                SubName: "Voter Kendra Master"
            },
            {
                id: 1403,
                SubPath: "/Home/m1403/",
                SubName: "Regions in Sangli Master"
            },
            {
                id: 1404,
                SubPath: "/Home/m1404/",
                SubName: "City Master"
            },

        ]
    },
    {
        MenuType: "megamenu",
        PathName: "#",
        Title: "Reports",
        LinkIcon: "assignment",
        MegaMenuDropDown: [
            {
                HeaddingName: "Reports",
                MegaMenuSubItems: [
                    {
                        id: 1406,
                        SubPath: "/Home/sr1406/",
                        SubName: "All Members Report"
                    },
                    {
                        id: 1407,
                        SubPath: "/Home/sr1407/",
                        SubName: "New Deatils Statistics"
                    },
                    {
                        id: 1408,
                        SubPath: "/Home/sr1408/",
                        SubName: "Old Detail Statistics"
                    },
                    {
                        id: 1409,
                        SubPath: "/Home/sr1409/",
                        SubName: "Statistics Summary"
                    },

                    {
                        id: 1410,
                        SubPath: "/Home/sr1410/",
                        SubName: "Complete Register"
                    },
                    {
                        id: 1411,
                        SubPath: "/Home/sr1411/",
                        SubName: "Sevak Member List"
                    },


                ]

            },
            {
                HeaddingName: " ",
                MegaMenuSubItems: [
                    {
                        id: 1412,
                        SubPath: "/Home/sr1412/",
                        SubName: "Male Female Count"
                    },
                    {
                        id: 1413,
                        SubPath: "/Home/sr1413/",
                        SubName: "Regionwise Count For Sangli"
                    },
                    {
                        id: 1414,
                        SubPath: "/Home/sr1414/",
                        SubName: "Schoolwise Alumni"
                    },
                    {
                        id: 1415,
                        SubPath: "/Home/sr1415/",
                        SubName: "City List"
                    },
                    {
                        id: 1416,
                        SubPath: "/Home/s1416/",
                        SubName: "Member Referrals Report"
                    },
                    {
                        id: 1417,
                        SubPath: "/Home/sr1417/",
                        SubName: "Member Report By Contact Method"
                    },

                ]

            },
            {
                HeaddingName: "Voters List",
                MegaMenuSubItems: [
                    {
                        id: 1418,
                        SubPath: "/Home/adm1418/",
                        SubName: "MemberTypewise Centerwise"
                    },
                    {
                        id: 1419,
                        SubPath: "/Home/adm1419/",
                        SubName: "Centerwise All Member Voter List"
                    },
                    {
                        id: 1420,
                        SubPath: "/Home/sr1420/",
                        SubName: "Election Program Letter"
                    },

                ]

            },
        ]
    },
    {
        MenuType: "dropdown",
        PathName: "#",
        Title: "Utilities",
        LinkIcon: "miscellaneous_services",
        DropDownSubMenu: [
            {
                id: 1421,
                SubPath: "/Home/su1421/",
                SubName: "Export To Excel"
            },
            {
                id: 1422,
                SubPath: "/Home/su1422/",
                SubName: "Voter Kendra Assigned From Region in Sangli"
            },
            {
                id: 1423,
                SubPath: "/Home/su1423/",
                SubName: "Voter Kendra Assigned By City"
            },
            {
                id: 1424,
                SubPath: "/Home/su1424/",
                SubName: "Voting Date"
            },


        ]
    },
]

export default SansthaJSON;