import React, { useEffect, useState } from 'react'
import TitleLabel from '../../CommonComponent/TitleLabel';
import { useSelector } from 'react-redux';
import Loader from '../../CommonComponent/Loader';
import { Autocomplete, Paper, TextField } from '@mui/material';
import { getClassWithDivData } from '../../Academic/Services/LessonPlanAPIURL';
import { getSubjectDataAPIURL } from '../../Library/Acquisition/Services/AccessionEntryAPIURL';
import axios from 'axios';
import { getClassesBySectionAndBranch } from '../Services/ExamMarkAllocationAPIURL';
import SubjectObservationMasterTable from './SubjectObservationMasterTable';
import { toast } from 'react-toastify';
import { getSubjectObsMasterAPIURL, saveSubjectObsMasterAPIURL, updateSubjectObsMasterAPIURL } from '../Services/SubjectObsMasterAPIURL';

const SubjectObservationMaster = () => {
    const authUser = useSelector((state) => state.user.value);
    const titleId = "Subject Observation Master"

    const TableHeading = [
        { label: 'ID', key: 'id' },
        { label: `Class`, key: (authUser.branchMedium == 1) ? 'classMr' : 'classEn' },
        { label: `Subject`, key: (authUser.branchMedium == 1) ? 'subjectMr' : 'subjectEn' },
        { label: `Boys Observation`, key: 'boysObservation' },
        { label: `Girls Observation`, key: 'girlsObservation' },
        { label: 'Edit', value: 'edit', isAction: true },
        { label: 'Delete', value: 'delete', isAction: true }];

    // loader on/off
    const [loaderOption, setLoaderOption] = useState(false);

    //Note: This below states are declared for Save & Update(Disabled/unDisabled)
    const saveBtnStyle = "subObsMasterSaveBtn btn btn-primary btn-sm";
    const updateBtnStyle = "subObsMasterUpdateBtn btn btn-primary btn-sm";
    const displayNoneBtnStyle = "d-none";
    const [saveBtn, setSaveBtn] = useState(saveBtnStyle);
    const [updateBtn, setUpdateBtn] = useState(displayNoneBtnStyle);

    const [id, setId] = useState('');
    const [classDivData, setClassDivData] = useState([]);
    const [classDivObj, setClassDivObj] = useState(null);
    const [subjectData, setSubjectData] = useState([]);
    const [subjectObj, setSubjectObj] = useState(null);
    const [boysObservation, setBoysObservation] = useState('');
    const [girlsObservation, setGirlsObservation] = useState('');

    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);

    useEffect(() => {
        getData();
    }, [authUser])


    const getData = async () => {
        if (authUser.deptId != null && authUser.branchId != null) {
            await axios.get(`${getClassesBySectionAndBranch}?sid=${authUser.deptId}&bid=${authUser.branchId}`)
                .then(res => {
                    setClassDivData(res.data);
                }).catch(err => {
                    console.log("Get class data " + err);
                })

            if (authUser.sessionYear != '') {
                await axios.get(`${getSubjectObsMasterAPIURL}?year=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}`)
                    .then(res => {
                        setData(res.data);
                        setFilteredData(res.data);
                    }).catch(err => {
                        console.log("get sub obs master " + err)
                    })
            }
        }

        await axios.get(getSubjectDataAPIURL)
            .then(res => {
                setSubjectData(res.data);
            }).catch(err => {
                console.log("get subject list err " + err);
            })
    }

    const clearData = () => {
        setSaveBtn(saveBtnStyle);
        setUpdateBtn(displayNoneBtnStyle);
        setClassDivObj(null);
        setSubjectObj(null);
        setBoysObservation("");
        setGirlsObservation("");
    }

    const saveBtnClick = async () => {
        setLoaderOption(true);
        const saveObj = {
            "classId": classDivObj.id,
            "subjectId": subjectObj.id,
            "boysObservation": boysObservation,
            "girlsObservation": girlsObservation,
            "sessionYear": authUser.sessionYear,
            "sectionId": authUser.deptId,
            "branchId": authUser.branchId
        }
        await axios.post(saveSubjectObsMasterAPIURL, saveObj)
            .then(res => {
                if (res.data == "SAVED") {
                    getData();
                    clearData();
                    setLoaderOption(false);
                    toast.success("Saved successfully.")
                }
                else {
                    setLoaderOption(false);
                    toast.error("Save failed.")
                }
            }).catch(err => {
                setLoaderOption(false);
                console.log("save subject obs master " + err);
                toast.error("Something went wrong, please check.")
            })
    }

    const updateBtnClick = async () => {
        setLoaderOption(true);
        const updateObj = {
            "id": id,
            "classId": classDivObj.id,
            "subjectId": subjectObj.id,
            "boysObservation": boysObservation,
            "girlsObservation": girlsObservation,
            "sessionYear": authUser.sessionYear,
            "sectionId": authUser.deptId,
            "branchId": authUser.branchId
        }
        await axios.put(updateSubjectObsMasterAPIURL, updateObj)
            .then(res => {
                if (res.data == "UPDATED") {
                    getData();
                    clearData();
                    setLoaderOption(false);
                    toast.success("Updated successfully.")
                }
                else {
                    setLoaderOption(false);
                    toast.error("Update failed.")
                }
            }).catch(err => {
                setLoaderOption(false);
                console.log("update subject obs master " + err);
                toast.error("Something went wrong, please check.")
            })
    }

    const state = {
        button: 1
    }

    const onSubmit = e => {
        e.preventDefault();
        if (state.button == 1) {
            saveBtnClick();
        }
        else if (state.button == 2) {
            updateBtnClick();
        }
    }

    useEffect(() => {
        function handlekeydownEvent(event) {
            if (event.key === "Enter") {
                // console.log("Enter is pressed!")
                event.preventDefault();

                let btn1 = document.querySelector('.subObsMasterSaveBtn')
                if (btn1 !== null) {
                    btn1.click();
                }
                let btn2 = document.querySelector('.subObsMasterUpdateBtn')
                if (btn2 !== null) {
                    btn2.click();
                }
            }
        }
        document.addEventListener('keypress', handlekeydownEvent)
        return () => {
            document.removeEventListener('keypress', handlekeydownEvent)
        }
    }, []);

    return (
        <>
            {
                (loaderOption == true)
                    ?
                    <Loader />
                    :
                    <>
                        <TitleLabel titleId={titleId} />
                        <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: "350px" }}>
                            <form onSubmit={onSubmit}>
                                <input type="hidden" value={id} onChange={(e) => setId(e.target.value)} name="" />

                                <div className='row'>
                                    <label className="col-sm-2" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }} >Class :</label>
                                    <div className="col-sm-3">
                                        <Autocomplete
                                            id="combo-box-demo"
                                            options={classDivData}
                                            PaperComponent={({ children }) => (
                                                <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                            )}
                                            style={{ fontSize: '14px' }}
                                            getOptionLabel={option => (authUser.branchMedium == 1) ? option.nameMr : option.nameEn}
                                            size="small"
                                            onChange={(event, newValue) => {
                                                setClassDivObj(newValue);
                                                setSubjectObj(null);
                                                setBoysObservation('');
                                                setGirlsObservation('');
                                            }}
                                            onKeyPress={(e) => (e.key == "Enter") ? classDivObj : ""}
                                            value={classDivObj}
                                            renderInput={params => (
                                                <TextField {...params} margin='dense' label="Select Class" variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                            )}
                                        />
                                    </div>
                                </div>

                                <div className='row my-2'>
                                    <label className="col-sm-2" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }} >Subject :</label>
                                    <div className="col-sm-3">
                                        <Autocomplete
                                            id="combo-box-demo"
                                            options={subjectData}
                                            PaperComponent={({ children }) => (
                                                <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                            )}
                                            style={{ fontSize: '14px' }}
                                            getOptionLabel={option => (authUser.branchMedium == 1) ? option.nameMr : option.nameEn}
                                            size="small"
                                            onChange={(event, newValue) => {
                                                setSubjectObj(newValue);
                                                setBoysObservation('');
                                                setGirlsObservation('');
                                            }}
                                            onKeyPress={(e) => (e.key == "Enter") ? subjectObj : ""}
                                            value={subjectObj}
                                            renderInput={params => (
                                                <TextField {...params} margin='dense' label="Select Subject" variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                            )}
                                        />
                                    </div>
                                </div>

                                <div className="row my-2">
                                    <label className="col-sm-2" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }} >Boys Observation :</label>
                                    <div className='col-sm-6'>
                                        <TextField
                                            value={boysObservation}
                                            onChange={e => setBoysObservation(e.target.value)}
                                            onKeyPress={(e) => (e.key == "Enter") ? boysObservation : ""}
                                            required
                                            fullWidth
                                            onKeyDown={(e) => (e.key == ' ' || boysObservation.length == 0) ? e.preventDefault : ''}
                                            autoComplete='off'
                                            size='small'
                                            id="outlined-basic"
                                            label="Boys Observation"
                                            variant="outlined"
                                            margin='dense'
                                            InputLabelProps={{ style: { fontSize: 14 } }}
                                            inputProps={{ maxLength: 120 }}
                                        />
                                    </div>
                                </div>

                                <div className="row my-2">
                                    <label className="col-sm-2" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }} >Girls Observation :</label>
                                    <div className='col-sm-6'>
                                        <TextField
                                            value={girlsObservation}
                                            onChange={e => setGirlsObservation(e.target.value)}
                                            onKeyPress={(e) => (e.key == "Enter") ? girlsObservation : ""}
                                            required
                                            fullWidth
                                            onKeyDown={(e) => (e.key == ' ' || girlsObservation.length == 0) ? e.preventDefault : ''}
                                            autoComplete='off'
                                            size='small'
                                            id="outlined-basic"
                                            label="Girls Observation"
                                            variant="outlined"
                                            margin='dense'
                                            InputLabelProps={{ style: { fontSize: 14 } }}
                                            inputProps={{ maxLength: 120 }}
                                        />
                                    </div>
                                </div>

                                <div className="row mt-3">
                                    <div className="col-sm-12 offset-sm-2">
                                        <button type='submit' onClick={() => (state.button = 1)} className={saveBtn}>Save</button>
                                        <button type='submit' onClick={() => (state.button = 2)} className={updateBtn}>Update</button>
                                        <button type='button' onClick={() => clearData()} className='btn btn-sm btn-danger ml-2'>Cancel</button>
                                    </div>
                                </div>
                            </form>

                            <div className="mt-3">
                                <SubjectObservationMasterTable
                                    TableHeading={TableHeading}
                                    data={data}
                                    filteredData={filteredData}
                                    setFilteredData={setFilteredData}
                                    setId={setId}
                                    setClassDivObj={setClassDivObj}
                                    classDivData={classDivData}
                                    setBoysObservation={setBoysObservation}
                                    setGirlsObservation={setGirlsObservation}
                                    setSubjectObj={setSubjectObj}
                                    subjectData={subjectData}
                                    getData={getData}
                                    clearData={clearData}
                                    setSaveBtn={setSaveBtn}
                                    setUpdateBtn={setUpdateBtn}
                                    updateBtnStyle={updateBtnStyle}
                                    displayNoneBtnStyle={displayNoneBtnStyle}
                                />
                            </div>
                        </div>
                    </>
            }
        </>
    )
}

export default SubjectObservationMaster