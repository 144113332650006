import React, { useState, useEffect } from 'react';
import TitleLabel from "../../CommonComponent/TitleLabel";
import { useSelector } from "react-redux";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import TextField from '@mui/material/TextField';
import { useNavigate } from 'react-router-dom';
import Loader from '../../CommonComponent/Loader';
import { toast } from 'react-toastify';
import { getExportedReportAPIURL } from '../../CommonServices/APIURL';
import { printBalanceSheetReportAPIURL, printBlanceSheetScheduleReportAPIURL, getScheduledGroupHeaderListReportAPIURL } from '../Services/AccountMasterReportAPI';
import moment from 'moment';
import axios from 'axios';
import { Autocomplete, FormControl, InputLabel, MenuItem, Paper, Select } from '@mui/material';
import makeStyles from "@mui/styles/makeStyles";
import { getGroupHeadAPIURL } from '../Services/GroupHeadAPIURL';

const useStyles = makeStyles(theme => ({
    menuPaper: {
        maxHeight: 200,
        "& ul": {
            backgroundColor: "#F4ECF7",
            //maxHeight: 160,
        },
        "& li": {
            // backgroundColor: "#F4ECF7", fontWeight: "550", fontSize: "14px" 
            fontSize: "14px",
            fontWeight: "550"
        },
    }
}));

const BalanceSheet = () => {
    const titleId = "Balance Sheet Report";
    const authUser = useSelector((state) => state.user.value);
    const navigate = useNavigate();
    const classes = useStyles();

    const [loaderOption, setLoaderOption] = useState(false);

    const defaultMinDate = new Date(`${authUser.sessionYear}/04/01`);
    const defaultMaxDate = new Date(`${authUser.sessionYear + 1}/03/31`);

    const [dateFrom, setDateFrom] = useState(null);
    const [dateTo, setDateTo] = useState(null);
    const [id, setId] = useState();
    const [dateCheckFrom, setDateCheckFrom] = useState('');
    const [dateCheckTo, setDateCheckTo] = useState('');
    const [option, setOption] = useState(1);
    const [groupData, setGroupData] = useState([]);
    const [groupObj, setGroupObj] = useState([]);
    const [groupId, setGroupId] = useState([]);
    //console.log(groupId)

    const defaultFromDate = new Date(authUser.sessionYear, 3, 1);
    const defaultToDate = new Date(parseInt(authUser.sessionYear) + 1, 2, 31);

    useEffect(() => {
        if (authUser.sessionYear != '') {
            setDateFrom(moment(defaultFromDate).format("YYYY-MM-DD"))
            setDateTo(moment(defaultToDate).format("YYYY-MM-DD"))
        }
        getData();
    }, [authUser])

    // const getData = async () => {
    //     if (authUser.deptId != null) {
    //         await axios.get(`${getGroupHeadAPIURL}?sid=${authUser.deptId}`)
    //             .then(res => {
    //                 const filterScheduleData = res.data.filter(e => e.detailView == 1)
    //                 setGroupData(filterScheduleData);
    //             }).catch(err => {
    //                 console.log("Get Group err " + err)
    //             })
    //     }
    // }

    const getData = async () => {
        if (authUser.deptId != null) {
            await axios.get(`${getScheduledGroupHeaderListReportAPIURL}?sid=${authUser.deptId}`)
                .then(res => {
                    setGroupData(res.data);
                }).catch(err => {
                    console.log("Get Group err " + err)
                })
        }
    }

    const getBalanceSheetReport = async (e) => {
        e.preventDefault();
        setLoaderOption(true);
        if (moment(dateFrom).format("DD/MM/YYYY") != 'Invalid date' && moment(dateTo).format("DD/MM/YYYY") != 'Invalid date' && dateCheckFrom != 'Invalid date' && dateCheckTo != 'Invalid date') {
            if (option == 1) {
                // console.log(`${printBalanceSheetReportAPIURL}?langId=2&year=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&fromDate=${moment(dateFrom).format("YYYY-MM-DD")}&toDate=${moment(dateTo).format("YYYY-MM-DD")}`)
                await axios.get(`${printBalanceSheetReportAPIURL}?langId=2&year=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&fromDate=${moment(dateFrom).format("YYYY-MM-DD")}&toDate=${moment(dateTo).format("YYYY-MM-DD")}`)
                    .then(res => {
                        if (res.data == "NOTFOUND") {
                            setLoaderOption(false);
                            toast.warn("Data not found.")
                        }
                        else {
                            window.open(`${getExportedReportAPIURL}${res.data}`);
                            setLoaderOption(false);
                        }
                    }).catch(err => {
                        setLoaderOption(false);
                        console.log("Print balance sheet err " + err);
                        toast.error("Something went wrong, please check.")
                    })
            }
            else if (option == 2) {
                // console.log(`${printBlanceSheetScheduleReportAPIURL}?groupHeadId=${groupId}&langId=2&year=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&fromDate=${moment(dateFrom).format("YYYY-MM-DD")}&toDate=${moment(dateTo).format("YYYY-MM-DD")}`)
                await axios.get(`${printBlanceSheetScheduleReportAPIURL}?groupHeadId=${groupId}&langId=2&year=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&fromDate=${moment(dateFrom).format("YYYY-MM-DD")}&toDate=${moment(dateTo).format("YYYY-MM-DD")}`)
                    .then(res => {
                        if (res.data == "NOTFOUND") {
                            setLoaderOption(false);
                            toast.warn("Data not found.")
                        }
                        else {
                            window.open(`${getExportedReportAPIURL}${res.data}`);
                            setLoaderOption(false);
                        }
                    }).catch(err => {
                        setLoaderOption(false);
                        console.log("Balance sheet schedule report err " + err);
                        toast.error("Something went wrong, please chcek.")
                    })
            }
        }
        else {
            setLoaderOption(false);
            toast.error("Improper date, please check.")
        }
    }

    return (
        <>
            {
                (loaderOption == true)
                    ?
                    <Loader />
                    :
                    <>
                        <TitleLabel titleId={titleId} />

                        <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: "350px" }}>
                            {/* Form Data start */}
                            <form onSubmit={getBalanceSheetReport}>
                                <div className="form-data">
                                    <input type="hidden" value={id} onChange={(e) => setId(e.target.value)} name="" />

                                    <div className="row">
                                        <label className="" style={{ width: "110px", fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Option :</label>
                                        <div className='col-sm-3'>
                                            <FormControl fullWidth margin='dense'>
                                                <InputLabel id='demo-simple-select-label' size="small" style={{ fontSize: '14px' }}>Select Option</InputLabel>
                                                <Select
                                                    size='small'
                                                    required
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={option}
                                                    label="Select Option"
                                                    MenuProps={{ classes: { paper: classes.menuPaper } }}
                                                    onChange={(e) => {
                                                        setOption(e.target.value);
                                                        setGroupObj([]);
                                                        setGroupId([]);
                                                    }}
                                                    onKeyPress={(e) => (e.key == "Enter") ? option : ""}
                                                >
                                                    <MenuItem value={1}>Balance Sheet</MenuItem>
                                                    <MenuItem value={2}>Schedule Report</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </div>
                                    </div>

                                    {(() => {
                                        if (option == 2) {
                                            return (
                                                <>
                                                    <div className="row my-2">
                                                        <label className="" style={{ width: "110px", fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Group :</label>
                                                        <div className='col-sm-7'>
                                                            <Autocomplete
                                                                id="combo-box-demo"
                                                                multiple
                                                                filterSelectedOptions
                                                                disableCloseOnSelect
                                                                options={groupData}
                                                                PaperComponent={({ children }) => (
                                                                    <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                                                )}
                                                                onChange={(event, newValue) => {
                                                                    setGroupObj(newValue);
                                                                    if (newValue != null) {
                                                                        setGroupId(newValue.map(e => e.id).join(','))
                                                                    }
                                                                }}
                                                                value={groupObj}
                                                                style={{ fontSize: '14px' }}
                                                                getOptionLabel={option => (authUser.branchMedium == 1) ? option.nameMr : option.nameEn}
                                                                size="small"
                                                                renderInput={params => (
                                                                    <TextField {...params} margin='dense' label="Select Group" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required={(option == 2 && groupObj.length == 0) ? true : false} />
                                                                )}
                                                            />
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                        }
                                    })()}

                                    <div className="row my-2">
                                        <label className="" style={{ width: "110px", fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>Date From :</label>
                                        <div className='col-sm-3'>
                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                <DatePicker
                                                    label="Date From"
                                                    value={dateFrom}
                                                    minDate={defaultMinDate}
                                                    maxDate={defaultMaxDate}
                                                    inputFormat="dd/MM/yyyy"
                                                    onChange={(newValue) => {
                                                        setDateFrom(newValue);
                                                        const maxDate = new Date(defaultMaxDate);
                                                        maxDate.setDate(maxDate.getDate() + 1); // Add one day to max date to include the full day
                                                        if (newValue < defaultMinDate || newValue >= maxDate) {
                                                            setDateCheckFrom("Invalid date");
                                                        } else {
                                                            setDateCheckFrom("");
                                                        }
                                                    }}
                                                    openTo="day"
                                                    // Show defaultMaxDate selected when the DatePicker is opened
                                                    // If dateFrom is not set, defaultMaxDate is used
                                                    onOpen={() => setDateFrom(dateFrom || defaultMaxDate)}
                                                    renderInput={(params) =>
                                                        <TextField
                                                            {...params}
                                                            margin="dense"
                                                            variant="outlined"
                                                            InputLabelProps={{ style: { fontSize: 14 } }}
                                                            required
                                                            fullWidth
                                                            autoComplete='off'
                                                            size="small" />}
                                                />
                                            </LocalizationProvider>
                                        </div>

                                        <label className="col-sm-1" style={{ fontSize: "15px", marginTop: "16px", fontWeight: "600" }}>Date To :</label>
                                        <div className='col-sm-3'>
                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                <DatePicker
                                                    label="Date To"
                                                    value={dateTo}
                                                    minDate={defaultMinDate}
                                                    maxDate={defaultMaxDate}
                                                    inputFormat="dd/MM/yyyy"
                                                    onChange={(newValue) => {
                                                        setDateTo(newValue);
                                                        const maxDate = new Date(defaultMaxDate);
                                                        maxDate.setDate(maxDate.getDate() + 1); // Add one day to max date to include the full day
                                                        if (newValue < defaultMinDate || newValue >= maxDate) {
                                                            setDateCheckTo("Invalid date");
                                                        } else {
                                                            setDateCheckTo("");
                                                        }
                                                    }}
                                                    openTo="day"
                                                    // Show defaultMaxDate selected when the DatePicker is opened
                                                    // If dateFrom is not set, defaultMaxDate is used
                                                    onOpen={() => setDateTo(dateTo || defaultMaxDate)}
                                                    renderInput={(params) =>
                                                        <TextField
                                                            {...params}
                                                            margin="dense"
                                                            variant="outlined"
                                                            InputLabelProps={{ style: { fontSize: 14 } }}
                                                            required
                                                            fullWidth
                                                            autoComplete='off'
                                                            size="small" />}
                                                />
                                            </LocalizationProvider>
                                        </div>
                                    </div>

                                    <div className="row mt-3">
                                        <div className="col-sm-12" style={{ marginLeft: "110px" }}>
                                            <button type="submit" className="btn btn-primary btn-sm">Show</button>
                                            <button type="button" className="btn btn-danger btn-sm ml-2" onClick={() => navigate("/Home/ac04/0")}>Exit</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </>
            }
        </>
    )
};

export default BalanceSheet;
