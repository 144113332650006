import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import { useSelector } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import CancelIcon from '@mui/icons-material/Cancel';

const SchoolEndowmentTextBox = ({ initialText1, initialText2, initialText3, initialText4,
    getSchoolNameByEndowmentId, id, newData, setNewData, schoolIndex, isLast, myRef, setUpdateBtnDisabled,
    onChange, initialText7, initialText5, initialText6 }) => {
    //console.log(i);
    const authUser = useSelector((state) => state.user.value);

    const [text1, setText1] = useState(initialText1);
    const [text2, setText2] = useState(initialText3);
    const [text3, setText3] = useState(initialText2);

    const [checkEndowNo, setCheckEndowNo] = useState(false);

    //console.log("branchId==" + initialText1 + "amountInPercent=" + initialText2)

    //console.log(newData);

    //remove the added school from id
    const removeInputFields = () => {
        //console.log("removed id===" + id);
        //console.log("newData=======" + JSON.stringify(newData));
        let updated = newData.filter((e, index) => {
            // console.log("comapred id=====" + e.id)
            return e.id !== id
        })
        //console.log("updated=======" + JSON.stringify(updated));
        setNewData(updated);

    }

    //get school name by endowment id api
    const getSchoolNameById = async (eId) => {
        //console.log(eId);
        if (eId <= 0) {
            setText2("");
            setText1("");
            toast.error("School no. should not be less than or equal to zero.")
        }
        else if (eId !== "") {
            axios.get(`${getSchoolNameByEndowmentId}?endowmentId=${eId}`)
                .then((response) => {
                    //console.log(response.data);
                    if (response.data == "") {
                        setCheckEndowNo(true);
                        setText2("");
                    }
                    else {
                        setCheckEndowNo(false);
                        setText2(response.data);
                    }

                })
        }
    }


    return (
        <>
            {/* <div className='' style={{ backgroundColor: "#F9EBEA", borderTop: "1px solid #EAEAEA", borderBottom: "1px solid #EAEAEA" }}>
                <div className="container"> */}
            <div className="row" >
                <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>शाळा {schoolIndex + 1} :</label>
                {/* <div className="col-sm-2"> */}
                <TextField
                    required={(text1 == "") ? true : false}
                    fullWidth
                    inputRef={myRef}
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    autoComplete='off'
                    style={{ width: "100px", marginLeft: "10px" }}
                    size='small'
                    value={text1}
                    onBlur={() => {
                        onChange(text1, text3);
                        if (checkEndowNo == true) {
                            setUpdateBtnDisabled(true);
                            toast.warn(`School endowment no. ${text1} is not allocated to any school.`)
                        }
                        else {
                            setUpdateBtnDisabled(false);
                        }
                    }}
                    onChange={(e) => {
                        setText1(e.target.value);
                        getSchoolNameById(parseInt(e.target.value));
                        if (e.target.value == '') {
                            setText2("");
                            //setUpdateBtnDisabled(true);                           
                        }
                    }}
                    id="outlined-basic"
                    label="शाळा क्र."
                    variant="outlined"
                    margin='dense'
                    InputLabelProps={{ style: { fontSize: 14 } }}
                />
                {/* </div> */}

                {/* <div className="col-sm-3"> */}
                <TextField
                    required={(text2 == "") ? true : false}
                    fullWidth
                    inputRef={myRef}
                    autoComplete='off'
                    style={{ width: "400px", marginLeft: "8px" }}
                    size='small'
                    value={text2}
                    onBlur={() => { onChange(text1, text3); }}
                    onChange={(e) => {
                        setText2(e.target.value);
                    }}
                    id="outlined-basic"
                    label="शाळेचे नाव"
                    variant="outlined"
                    margin='dense'
                    //readOnly={true}
                    inputProps={{ readOnly: true }}
                    InputLabelProps={{ style: { fontSize: 14 } }}
                />
                {/* </div> */}

                <label hidden={(initialText5 == 2 && initialText7 == 2 && initialText6 == 2) ? true : false} className='col-sm-2' style={{ fontSize: "14px", fontWeight: "600", marginTop: "8px" }} >प्रत्येक शाळेला येणारी रक्कम ( % ) :</label>
                {/* <div className='col-sm-2'> */}
                <TextField
                    required={(text3 == "") ? true : false}
                    hidden={(initialText5 == 2 && initialText7 == 2 && initialText6 == 2) ? true : false}
                    fullWidth
                    inputRef={myRef}
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    autoComplete='off'
                    size='small'
                    id="outlined-basic"
                    label="रक्कम"
                    InputProps={{ inputProps: { min: 1 } }}
                    style={{ width: "100px" }}
                    value={text3}
                    onBlur={() => {
                        onChange(text1, text3);
                    }}
                    onChange={(e) => {
                        setText3(e.target.value);
                    }}
                    variant="outlined"
                    margin='dense'
                    InputLabelProps={{ style: { fontSize: 14 } }}
                />
                {/* </div> */}

                <div className="col-sm-1">
                    {(newData.length !== 0) ?
                        <button type="button" className='btn p-0 mt-3' onClick={() => removeInputFields()}>
                            <CancelIcon fontSize='medium' style={{ color: "#FA4A3F" }} />
                        </button> : ''}
                </div>
            </div>
            {/* </div>
            </div> */}


            {/* <ToastContainer position="top-right" theme="colored" /> */}
        </>
    )
}

export default SchoolEndowmentTextBox