import React, { useEffect, useState } from 'react'
import TitleLabel from '../../CommonComponent/TitleLabel'
import makeStyles from "@mui/styles/makeStyles";
import { Autocomplete, FormControl, FormControlLabel, InputLabel, MenuItem, Paper, Select, Switch, TextField } from '@mui/material';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { BaseURLInstituteMaster } from '../../CommonServices/InstituteMasterAPIURL';
import Loader from '../../CommonComponent/Loader';
import { toast } from 'react-toastify';
import { getAllSchoolExamMarkStatusAPIURL, getDepartmentListByBranchIdAPIURL, saveExamMarkStatusDeptWiseAPIURL } from '../Services/ExamReportAPIURL';
import AddIcon from '@mui/icons-material/Add';

const useStyles = makeStyles(theme => ({
    menuPaper: {
        maxHeight: 200,
        "& ul": {
            backgroundColor: "#F4ECF7",
            //maxHeight: 160,
        },
        "& li": {
            // backgroundColor: "#F4ECF7", fontWeight: "550", fontSize: "14px" 
            fontSize: "14px",
            fontWeight: "550"
        },
    }
}));

const ExamMarkStatus = () => {
    const classes = useStyles();
    const authUser = useSelector((state) => state.user.value);
    const titleId = "Exam Mark Status";

    const [loaderOption, setLoaderOption] = useState(false);

    const [option, setOption] = useState(1);
    const [markStatusToggle, setMarkStatusToggle] = useState(0);
    const [branchData, setBranchData] = useState([]);
    const [branchObj, setBranchObj] = useState(null);
    const label = `Exam Mark Status for Year ${authUser.sessionYear}`

    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [checkValue, setCheckValue] = useState([]);
    // console.log(markStatusToggle)

    const [markStatusData, setMarkStatusData] = useState('');

    const handleMarkStatusToggle = (e) => {
        setMarkStatusToggle(e.target.checked);
        if (e.target.checked == true) {
            setMarkStatusToggle(1);
        }
        else if (e.target.checked == false) {
            setMarkStatusToggle(0);
        }
    }

    const handleCheckChange = (val, dId) => {
        setCheckValue([]);
        if (val == true) {
            const updatedData = filteredData
            updatedData.map((e, index) => {
                if (e.deptId == dId) {
                    updatedData[index] = ({
                        ...e,
                        "status": 1,
                        "sessionYear": authUser.sessionYear,
                        "sectionId": e.deptId,
                        "branchId": branchObj.id
                    })
                }

            })
            setCheckValue(updatedData)
        }
        else if (val == false) {
            const updatedData = filteredData
            updatedData.map((e, index) => {
                if (e.deptId == dId) {
                    updatedData[index] = ({
                        ...e,
                        "status": 0,
                        "sessionYear": authUser.sessionYear,
                        "sectionId": e.deptId,
                        "branchId": branchObj.id
                    })
                }
            })
            setCheckValue(updatedData)
        }
    }

    useEffect(() => {
        getData();
    }, [authUser])


    const getData = async () => {
        setLoaderOption(true);
        await axios.get(BaseURLInstituteMaster)
            .then(res => {
                const newBranchData = res.data.filter(e => e.id != 50 && e.id != 39)
                setBranchData(newBranchData);
                //setLoaderOption(false);
            }).catch(err => {
                setLoaderOption(false);
                console.log('get institute err ' + err);
            })

        if (authUser.sessionYear != '') {
            // console.log(`${getAllSchoolExamMarkStatusAPIURL}?year=${authUser.sessionYear}`)
            await axios.get(`${getAllSchoolExamMarkStatusAPIURL}?year=${authUser.sessionYear}`)
                .then(res => {
                    let markStatus = res.data
                    setMarkStatusToggle(res.data);
                    setLoaderOption(false);
                }).catch(err => {
                    setLoaderOption(false);
                    console.log('get AllSchoolExamMarkStatus err ' + err);
                })
        }
    }


    const clearData = () => {
        setData([]);
        setFilteredData([]);
        setOption(1);
        setBranchObj(null);
    }

    const getDepartmentsBySchool = async (e) => {
        e.preventDefault();
        setLoaderOption(true);
        if (option == 1) {
            const saveObj = [];
            await axios.post(`${saveExamMarkStatusDeptWiseAPIURL}?option=${option}&allSclStatus=${markStatusToggle}&year=${authUser.sessionYear}`, saveObj)
                .then(res => {
                    if (res.data == "SAVED") {
                        setLoaderOption(false);
                        getData();
                        toast.success("Status updated successfully.")
                    }
                    else {
                        setLoaderOption(false);
                        toast.error("Status updation failed.")
                    }
                }).catch(err => {
                    setLoaderOption(false);
                    console.log("Status updation err " + err);
                    toast.error("Something went wrong, please check.")
                })
        }
        else if (option == 2) {
            await axios.get(`${getDepartmentListByBranchIdAPIURL}?bid=${branchObj.id}&year=${authUser.sessionYear}`)
                .then(res => {
                    // console.log(JSON.stringify(res.data));
                    setData(res.data);
                    setFilteredData(res.data);
                    setLoaderOption(false);
                }).catch(err => {
                    setLoaderOption(false);
                    console.log("get dept details err " + err);
                    toast.error("Something went wrong, please check.")
                })
        }

    }


    const saveDepartmentWiseMarkStatus = async (e) => {
        e.preventDefault();
        setLoaderOption(true);
        // console.log(JSON.stringify(checkValue))
        await axios.post(`${saveExamMarkStatusDeptWiseAPIURL}?option=${option}&allSclStatus=${markStatusToggle}&year=${authUser.sessionYear}`, checkValue)
            .then(res => {
                if (res.data == "SAVED") {
                    clearData();
                    getData();
                    //setLoaderOption(false);
                    toast.success("Mark status updation successfully done.")
                }
                else {
                    setLoaderOption(false);
                    toast.error("Mark status updation save failed.")
                }
            }).catch(err => {
                setLoaderOption(false);
                console.log("Mark status updation err " + err)
                toast.error("Something went wrong, please check.")
            })
    }

    return (
        <>
            {
                (loaderOption == true)
                    ?
                    <Loader />
                    :
                    <>
                        <TitleLabel titleId={titleId} />
                        <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: '500px' }}>
                            <form onSubmit={getDepartmentsBySchool}>
                                <div className='row'>
                                    <label className="col-sm-1" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Option :</label>
                                    <div className='col-sm-3'>
                                        <FormControl fullWidth margin='dense'>
                                            <InputLabel size="small" id='demo-simple-select-label' style={{ fontSize: '14px' }}>Option</InputLabel>
                                            <Select
                                                MenuProps={{ classes: { paper: classes.menuPaper } }}
                                                size='small'
                                                fullWidth
                                                labelId="demo-simple-select-label"
                                                required
                                                id="demo-simple-select"
                                                value={option}
                                                label="Term"
                                                onChange={(e) => {
                                                    setOption(e.target.value);
                                                    setData([]);
                                                    setFilteredData([]);
                                                    setBranchObj(null);
                                                }}
                                            >
                                                <MenuItem value={1}>All Schools</MenuItem>
                                                <MenuItem value={2}>School Wise</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div>

                                {(() => {
                                    if (option == 1) {
                                        return (
                                            <>
                                                <div className="row mt-3">
                                                    <div className="col-sm-12 offset-sm-1">
                                                        <FormControlLabel control={<Switch defaultChecked={(markStatusToggle == 1) ? true : false} onChange={handleMarkStatusToggle} />} sx={{ '.MuiFormControlLabel-label': { fontWeight: 550, fontSize: "15px", marginTop: "2px" } }} label={label} />
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    }
                                    else if (option == 2) {
                                        return (
                                            <>
                                                <div className="row my-2">
                                                    <label className="col-sm-1" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }} >School :</label>
                                                    <div className="col-sm-6">
                                                        <Autocomplete
                                                            id="combo-box-demo"
                                                            options={branchData}
                                                            PaperComponent={({ children }) => (
                                                                <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                                            )}
                                                            style={{ fontSize: '14px' }}
                                                            getOptionLabel={option => (authUser.branchMedium == 1) ? option.schoolNameMr : option.schoolNameEn}
                                                            size="small"
                                                            onChange={(event, newValue) => {
                                                                setBranchObj(newValue);
                                                                setData([]);
                                                                setFilteredData([]);
                                                            }}
                                                            onKeyPress={(e) => (e.key == "Enter") ? branchObj : ""}
                                                            value={branchObj}
                                                            renderInput={params => (
                                                                <TextField {...params} margin='dense' label="Select School" variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }}
                                                                    required={(option == 2) ? true : false} />
                                                            )}
                                                        />
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    }
                                })()}

                                <div className="row mt-3">
                                    <div className="col-sm-12 offset-sm-1">
                                        <button type='submit' className='btn btn-sm btn-primary'>{(option == 1) ? 'Save' : 'Show'}</button>
                                        <button type='button' className='btn btn-sm btn-danger ml-2' onClick={clearData}>Cancel</button>
                                    </div>
                                </div>
                            </form>

                            {(() => {
                                if (option == 2) {
                                    if (filteredData.length != 0 || data.length != 0) {
                                        return (
                                            <>
                                                <form onSubmit={saveDepartmentWiseMarkStatus}>
                                                    <div className='row' style={{ maxHeight: "350px", marginTop: '40px' }} >
                                                        <div className="table-responsive col-sm-6 offset-sm-1">
                                                            {/* <table className="table table-bordered">
                                                                <thead className="" style={{ position: "sticky", zIndex: "2", overflow: "hidden", top: "0", backgroundColor: "#EAECEE", borderBottom: "2px solid #464de4" }}>

                                                                    <tr>
                                                                        <th style={{ fontSize: "15px", fontWeight: "600" }}>Sr.No.</th>
                                                                        <th style={{ fontSize: "15px", fontWeight: "600" }}>Department</th>
                                                                        <th style={{ fontSize: "15px", fontWeight: "600" }}>Mark Status ({authUser.sessionYear})</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody style={{ borderTop: "2px solid #464de4" }}>
                                                                    {filteredData.map((item, index) => {

                                                                        return (
                                                                            <tr key={index} className="">
                                                                                <td style={{ fontSize: "15px", fontWeight: "500" }} >{index + 1}</td>
                                                                                <td style={{ fontSize: "15px", fontWeight: "500" }} >{(authUser.branchMedium == 1) ? item.deptNameMr : item.deptNameEn}</td>
                                                                                <td style={{ fontSize: "15px", fontWeight: "500", margin: "0px", padding: "0px" }} >
                                                                                    <Switch defaultChecked={(item.status === 1) ? true : false} sx={{ '& .MuiSvgIcon-root': { fontSize: 30 } }}
                                                                                        onChange={(e) => {
                                                                                            let val = e.target.checked;
                                                                                            handleCheckChange(val, item.deptId);
                                                                                        }}
                                                                                    />
                                                                                </td>
                                                                            </tr>
                                                                        )
                                                                    })}
                                                                </tbody>
                                                            </table> */}
                                                            <ul class="list-group">
                                                                <li class="list-group-item" style={{ display: 'flex', justifyContent: 'center' }}>
                                                                    <span style={{ fontSize: "16px", fontWeight: "600" }} >Departments</span>
                                                                </li>
                                                                {filteredData.map((item, index) => {
                                                                    return (
                                                                        <>
                                                                            <li class="list-group-item">
                                                                                <Switch defaultChecked={(item.status === 1) ? true : false} sx={{ '& .MuiSvgIcon-root': { fontSize: 30 } }}
                                                                                    onChange={(e) => {
                                                                                        let val = e.target.checked;
                                                                                        handleCheckChange(val, item.deptId);
                                                                                    }}
                                                                                />
                                                                                <span style={{ fontSize: "15px", fontWeight: "530" }} >{(authUser.branchMedium == 1) ? item.deptNameMr : item.deptNameEn}</span>
                                                                            </li>
                                                                        </>
                                                                    )
                                                                })}
                                                            </ul>
                                                        </div>
                                                    </div>

                                                    <div className="row mt-3">
                                                        <div className="col-sm-12 offset-sm-1">
                                                            <button type="submit" className="btn btn-sm btn-warning">
                                                                <AddIcon fontSize="small" />
                                                                Save Changes</button>
                                                        </div>
                                                    </div>
                                                </form>
                                            </>
                                        )
                                    }
                                }
                            })()}
                        </div>
                    </>
            }
        </>
    )
}

export default ExamMarkStatus