import React, { useState, useEffect } from 'react';
import TitleLabel from "../../CommonComponent/TitleLabel";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from '@mui/material/TextField';
import { useSelector } from "react-redux";
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import Loader from '../../CommonComponent/Loader';
import Paper from "@mui/material/Paper";
import { getExportedReportAPIURL } from '../../CommonServices/APIURL';
import { classWithDivisionURL, printPraptraClassDivWise } from '../Services/ExamReportAPIURL';

const PrapatraA = () => {
    const titleId = "Prapatra A";
    const authUser = useSelector((state) => state.user.value);

    // loader on/off
    const [loaderOption, setLoaderOption] = useState(false);

    //const [reportOptionId, setReportOptionId] = useState(1);
    const [id, setId] = useState();
    const [classDivData, setClassDivData] = useState([]);
    const [classDivObj, setClassDivObj] = useState(null);

    useEffect(() => {
        getData();
    }, [authUser])


    const getData = async () => {
        await axios(`${classWithDivisionURL}?sid=${authUser.deptId}&bid=${authUser.branchId}`)
            .then((response) => {
                setClassDivData(response.data);

            }).catch((error) => {
                console.log(error);
            })
    }

    const praptraAclassDivWisePrint = async (e) => {
        e.preventDefault();
        setLoaderOption(true);
        // console.log(`${printPraptraClassDivWise}?langId=${authUser.branchMedium}&classId=${classDivObj.classId}&divId=${classDivObj.divisionId}&acYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&branchMedium=${authUser.branchMedium}`)
        await axios.get(`${printPraptraClassDivWise}?langId=${authUser.branchMedium}&classId=${classDivObj.classId}&divId=${classDivObj.divisionId}&acYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&branchMedium=${authUser.branchMedium}`)
            .then((response) => {

                if (response.data === "NOTFOUND") {
                    setLoaderOption(false);
                    toast.warn("Data Not Found.")
                }
                else if (response.data != null) {
                    // console.log(response.data)
                    window.open(`${getExportedReportAPIURL}${response.data}`);
                    setLoaderOption(false);
                }
            })
            .catch(error => {
                setLoaderOption(false);
                toast.error("Error Occurred, Try after sometime.");
                console.log("Praptra A Report failed: " + error)
            })
    }

    if (loaderOption == true) {
        return <><Loader /></>
    }
    else {
        return (
            <>
                <TitleLabel titleId={titleId} />

                <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: "350px" }}>
                    {/* Form Data start */}
                    <form onSubmit={praptraAclassDivWisePrint}>
                        <div className="form-data">
                            <input type="hidden" value={id} onChange={(e) => setId(e.target.value)} name="" />

                            <div className="row">
                                <div className='col-sm-3'>
                                    <Autocomplete
                                        id="combo-box-demo"
                                        PaperComponent={({ children }) => (
                                            <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                        )}
                                        options={classDivData}
                                        style={{ fontSize: '14px' }}
                                        getOptionLabel={option => (authUser.branchMedium == 1) ? option.classNameMr + " " + option.divisionNameMr : option.classNameEn + " " + option.divisionNameEn}
                                        size="small"
                                        value={classDivObj}
                                        onChange={(event, newvalue) => {
                                            setClassDivObj(newvalue);
                                        }}
                                        renderInput={params => (
                                            <TextField {...params} margin="dense" label="Select Class-Division" InputLabelProps={{ style: { fontSize: 14 } }} variant="outlined" required />
                                        )}
                                    />
                                </div>
                            </div>

                            <div className="row mt-3">
                                <div className="col-sm-4">
                                    <button type="submit" className="btn btn-primary btn-sm">Show</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                {/* <ToastContainer position="top-right" theme="colored" /> */}
            </>
        )
    }


}

export default PrapatraA