import { BaseURL } from "../../../CommonServices/APIURL";

//http://192.168.29.102:8081/institute-sss/institute/sss/sansthaVoting/saveRegionsInSangli
export const saveRegionsInSangliAPIURL = BaseURL + "sansthaVoting/saveRegionsInSangli";

//http://192.168.29.102:8081/institute-sss/institute/sss/sansthaVoting/updateRegionsInSangli
export const updateRegionsInSangliAPIURL = BaseURL + "sansthaVoting/updateRegionsInSangli";

//http://192.168.29.102:8081/institute-sss/institute/sss/sansthaVoting/deleteRegionsInSangli?id=2
export const deleteRegionsInSangliAPIURL = BaseURL + "sansthaVoting/deleteRegionsInSangli";

//http://192.168.29.102:8081/institute-sss/institute/sss/sansthaVoting/getRegionsInSangliList
export const getRegionsInSangliAPIURL = BaseURL + "sansthaVoting/getRegionsInSangliList";
