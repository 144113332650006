import React, { useState, useEffect } from 'react';
import TitleLabel from "../../CommonComponent/TitleLabel";
import TextField from '@mui/material/TextField';
import axios from 'axios';
import Autocomplete from "@mui/material/Autocomplete";
import { subjectGetData, getClassDivDataForSubAllocation, postClassDivDataForSubAllocation } from '../Services/SubjectAllocationAPIURL';
import Checkbox from '@mui/material/Checkbox';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { ToastContainer, toast } from 'react-toastify';
import { useSelector } from "react-redux";
import Paper from "@mui/material/Paper";
import '../Styles/TableStyle.css';
import AddIcon from '@mui/icons-material/Add';
import Loader from '../../CommonComponent/Loader';

const SubjectAllocation = () => {
    const authUser = useSelector((state) => state.user.value);
    //console.log(JSON.stringify(authUser));
    const titleId = "Subject Allocation";

    const [loaderOption, setLoaderOption] = useState(false);

    //Note: This below states are declared for Save & Update(Disabled/unDisabled)
    const saveBtnStyle = "btn btn-primary btn-sm";
    const updateBtnStyle = "btn btn-primary btn-sm";
    const displayNoneBtnStyle = "d-none";
    const [saveBtn, setSaveBtn] = useState(saveBtnStyle);
    const [updateBtn, setUpdateBtn] = useState(displayNoneBtnStyle);

    const [id, setId] = useState();
    const [subjectData, setSubjectData] = useState([]);
    const [classDivData, setClassDivData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [subjectId, setSubjectId] = useState(0);
    const [subjectObj, setSubjectObj] = useState(null);
    const [optionId, setOptionId] = useState(2);
    const [searchTerm, setSearchTerm] = useState("");
    const [checkValue, setCheckValue] = useState([]);
    const [checkAllSub, setCheckAllSub] = useState(false);

    const [tempData, setTempData] = useState([]);
    const [Val, setVal] = useState(false);

    //console.log("Val======" + Val + "searchterm===" + searchTerm + "divisionId=====" + divisionId + "divisionIdTrue=====" + divisionIdTrue)

    //console.log("checkValue=======" + JSON.stringify(checkValue));
    //console.log("Checkvalue="+checkValue.length+"------"+"filteredData="+filteredData.length)
    const [checkDivId, setCheckDivId] = useState('');

    //pass object if checkbox checked
    const handleCheckChange = (val, divId, cId, sessionYr, Bid, Sid, isAllocate) => {
        //console.log("Props=" + optionId)
        setVal(val);

        const SaveObjectData = {
            classId: cId,
            divisionId: divId,
            optionId: optionId,
            subjectId: subjectId,
            secessionYear: sessionYr,
            sectionId: Sid,
            branchId: Bid
        }
        if (val == true) {
            setCheckValue(checkValue.concat(SaveObjectData));

            //if checkbox is checked
            let updatedData = classDivData;
            classDivData.map((e, index) => {
                if (e.divisionId == SaveObjectData.divisionId) {
                    updatedData[index] = ({ ...e, optionId: optionId, subjectId: subjectId, isAllocated: 1 })
                }

            })
            let checkdata = updatedData.filter(e => e.isAllocated === 0)
            //console.log(checkdata.length);
            //console.log("Checkvalue=" + (checkValue.length + 1) + "------" + "filteredData=" + filteredData.length)
            if (checkdata.length === 0) {
                setCheckAllSub(true);
            }

            //console.log("updatedData=======" + JSON.stringify(updatedData));
            if (searchTerm == "") {
                setClassDivData(updatedData);
                setFilteredData(updatedData)
            }
        }
        else if (val == false) {
            const removedData = checkValue.filter((item) => item.divisionId != divId)
            setCheckValue(removedData);
            setCheckAllSub(false);

            //if checkbox is unChecked
            let updatedData = classDivData;
            classDivData.map((e, index) => {
                if (e.divisionId == SaveObjectData.divisionId) {
                    updatedData[index] = ({ ...e, optionId: optionId, subjectId: subjectId, isAllocated: 0 })
                }

            })
            //console.log("updatedData=======" + JSON.stringify(updatedData));
            if (searchTerm == "") {
                setClassDivData(updatedData);
                setFilteredData(updatedData);
            }

        }
        setCheckDivId(SaveObjectData.divisionId);
    }


    useEffect(() => {

        if (Val == true) {
            let updatedDataTrue = classDivData
            classDivData.map((e, index) => {
                if (e.divisionId == checkDivId) {
                    updatedDataTrue[index] = ({ ...e, optionId: optionId, subjectId: subjectId, isAllocated: 1 })
                }
            })
            if (searchTerm == "") {
                setCheckDivId('');
                setClassDivData(updatedDataTrue);
                setFilteredData(updatedDataTrue);
            }
        }
        else if (Val == false) {
            //console.log(checkDivId)
            let updatedDataFalse = classDivData
            classDivData.map((e, index) => {
                if (e.divisionId == checkDivId) {
                    updatedDataFalse[index] = ({ ...e, optionId: optionId, subjectId: subjectId, isAllocated: 0 })
                }
            })
            //console.log(JSON.stringify(updatedDataFalse))
            if (searchTerm == "") {
                setCheckDivId('');
                setClassDivData(updatedDataFalse);
                setFilteredData(updatedDataFalse);
            }
        }

        if (checkAllSub == false && Val == false) {
            let updatedDataFalse = classDivData
            classDivData.map((e, index) => {
                if (e.divisionId == checkDivId) {
                    updatedDataFalse[index] = ({ ...e, optionId: optionId, subjectId: subjectId, isAllocated: 0 })
                }
            })
            if (searchTerm == "") {
                setClassDivData(updatedDataFalse);
                setFilteredData(updatedDataFalse);
            }
        }
        else if (checkAllSub == true) {
            let updatedDataTrue = classDivData
            classDivData.map((e, index) => {
                //if (e.divisionId == checkDivId) {
                updatedDataTrue[index] = ({ ...e, optionId: optionId, subjectId: subjectId, isAllocated: 1 })
                //}
            })
            if (searchTerm == "") {
                setCheckDivId('');
                setClassDivData(updatedDataTrue);
                setFilteredData(updatedDataTrue);
            }
        }
    }, [searchTerm, Val, checkAllSub, checkDivId])


    //check all checkboxes code
    const checkAllSubjects = (e) => {
        setCheckValue([]);
        setCheckAllSub(e.target.checked);
        if (e.target.checked == true) {
            setCheckValue([]);
            setCheckAllSub(true);
            axios.get(`${getClassDivDataForSubAllocation}?sid=${authUser.deptId}&bid=${authUser.branchId}&acyr=${authUser.sessionYear}&subid=${subjectId}&optionId=${optionId}`)
                .then((response) => {
                    setClassDivData([])
                    setFilteredData([])
                    let updatedData = response.data;
                    response.data.map((e, index) => {
                        updatedData[index] = ({ ...e, optionId: optionId, subjectId: subjectId, isAllocated: 1 })
                    })
                    setCheckValue(updatedData);
                    setClassDivData(updatedData)
                    setFilteredData(updatedData)
                })

            //setFilteredData(updatedData)
            //console.log("true=======" + JSON.stringify(updatedData))
        }
        else if (e.target.checked == false) {
            setCheckValue([]);
            setCheckAllSub(false);
            axios.get(`${getClassDivDataForSubAllocation}?sid=${authUser.deptId}&bid=${authUser.branchId}&acyr=${authUser.sessionYear}&subid=${subjectId}&optionId=${optionId}`)
                .then((response) => {
                    setClassDivData([])
                    setFilteredData([])
                    let updatedData = response.data;
                    response.data.map((e, index) => {
                        updatedData[index] = ({ ...e, optionId: optionId, subjectId: subjectId, isAllocated: 0 })
                    })
                    //setChecked(updatedData);
                    setClassDivData(updatedData)
                    setFilteredData(updatedData)
                })

            //if all data removed from classDivData or checkvalue.length === 0
            let removedAllData;
            removedAllData = [{
                classId: 0,
                divisionId: 0,
                optionId: optionId,
                subjectId: subjectId,
                secessionYear: authUser.sessionYear,
                sectionId: authUser.deptId,
                branchId: authUser.branchId
            }]
            setCheckValue(removedAllData);
            //setFilteredData(updatedData)
            //console.log("false========" + JSON.stringify(updatedData))
        }
    }

    useEffect(() => {
        getData();
    }, [authUser]);


    //get subject data
    const getData = async () => {
        await axios.get(`${subjectGetData}`)
            .then((response) => {
                setSubjectData(response.data);

            })
    }

    //get class-div data for subject allocation
    const getclassDivision = async (e) => {
        e.preventDefault();
        setLoaderOption(true);
        setFilteredData([]);
        setClassDivData([]);
        setSearchTerm("");
        setCheckValue([]);
        // console.log(`${getClassDivDataForSubAllocation}?sid=${authUser.deptId}&bid=${authUser.branchId}&acyr=${authUser.sessionYear}&subid=${subjectId}&optionId=${optionId}`)
        await axios.get(`${getClassDivDataForSubAllocation}?sid=${authUser.deptId}&bid=${authUser.branchId}&acyr=${authUser.sessionYear}&subid=${subjectId}&optionId=${optionId}`)
            .then((response) => {
                setClassDivData(response.data);
                setFilteredData(response.data);
                setTempData(response.data);
                setLoaderOption(false);


                //singleChecked data
                let SaveObj = (response.data).filter(e => {
                    return e.isAllocated == 1
                })
                let updatedData = SaveObj;
                SaveObj.map((e, index) => {
                    updatedData[index] = ({ ...e, optionId: optionId, subjectId: subjectId })
                })
                setCheckValue(updatedData);

                //allchecked data
                let allCheckedData = (response.data).filter(e => {
                    return e.isAllocated == 1
                })
                let updateData = allCheckedData;
                allCheckedData.map((e, index) => {
                    updateData[index] = ({ ...e, optionId: optionId, subjectId: subjectId, isAllocated: 1 })
                })
                getChekedData(updateData);

            })

    }

    //check all checboxes are checked or not
    const getChekedData = (list) => {

        axios(`${getClassDivDataForSubAllocation}?sid=${authUser.deptId}&bid=${authUser.branchId}&acyr=${authUser.sessionYear}&subid=${subjectId}&optionId=${optionId}`)
            .then(res => {
                let updatedData = res.data;
                (res.data).map((item, index) => {
                    updatedData[index] = ({ ...item, optionId: optionId, subjectId: subjectId, isAllocated: 1 })
                })

                const checkedData = list.length === updatedData.length && list.every((o, i) => Object.keys(o).length === Object.keys(updatedData[i]).length && Object.keys(o).every(k => o[k] === updatedData[i][k]))

                if (checkedData == true) {
                    setCheckAllSub(true);
                } else {
                    setCheckAllSub(false);
                }
            })
    }

    //save subject allocation to class-div
    const saveData = (e) => {
        setLoaderOption(true);
        e.preventDefault();
        let removedAllData;
        if (checkValue.length === 0) {
            removedAllData = [{
                classId: 0,
                divisionId: 0,
                optionId: optionId,
                subjectId: subjectId,
                secessionYear: authUser.sessionYear,
                sectionId: authUser.deptId,
                branchId: authUser.branchId
            }]
            // console.log(JSON.stringify(removedAllData));
            axios.post(`${postClassDivDataForSubAllocation}`, removedAllData)
                .then((response) => {
                    if (response.data != null) {
                        getData();
                        setFilteredData([]);
                        setClassDivData([]);
                        setTempData([]);
                        setSubjectObj(null);
                        setOptionId(2);
                        setCheckValue([]);
                        setSearchTerm("");
                        setLoaderOption(false);
                        toast.success("Subject Allocation successfully done.");
                    } else {
                        toast.danger("Operation failed.");
                    }
                }).catch((error) => {
                    console.log("error name: " + error);
                });
        }
        else {
            // console.log(JSON.stringify(checkValue));
            axios.post(`${postClassDivDataForSubAllocation}`, checkValue)
                .then((response) => {
                    if (response.data != null) {
                        getData();
                        setFilteredData([]);
                        setClassDivData([]);
                        setTempData([]);
                        setSubjectObj(null);
                        setOptionId(2);
                        setCheckValue([]);
                        setSearchTerm("");
                        setLoaderOption(false);
                        toast.success("Subject Allocation successfully done.");
                    } else {
                        toast.error("Operation failed.");
                    }
                }).catch((error) => {
                    console.log("error name: " + error);
                });
        }
    }

    useEffect(() => {
        setFilteredData([])
        let tempFilteredData =
            classDivData.filter(
                (item) =>
                    item.classNameMr.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    item.classNameEn.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    item.divisionNameEn.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    item.divisionNameMr.toLowerCase().includes(searchTerm.toLowerCase())
            )
        setFilteredData(tempFilteredData);
    }, [searchTerm]);

    useEffect(() => {
        function handlekeydownEvent(event) {
            if (event.key === "Enter") {
                if (subjectObj !== null || subjectObj !== "") {
                    // console.log('Enter is pressed!');
                    event.preventDefault();
                    let btn = document.querySelector('.subAllocationBtn')
                    if (btn !== null) {
                        btn.click();
                    }
                }
            }
        }

        document.addEventListener('keypress', handlekeydownEvent)
        return () => {
            document.removeEventListener('keypress', handlekeydownEvent)
        }

    }, []);

    if (loaderOption == true) {
        return <Loader />
    }
    else {
        return (
            <>
                <TitleLabel titleId={titleId} />
                <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: "350px" }}>

                    <form onSubmit={getclassDivision}>
                        <input type="hidden" value={id} onChange={(e) => setId(e.target.value)} name="" />

                        <div className='row my-2'>
                            <label className="col-sm-1" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Subject:</label>
                            <div className="col-sm-3">
                                <Autocomplete
                                    required={true}
                                    id="combo-box-demo"
                                    PaperComponent={({ children }) => (
                                        <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                    )}
                                    renderOption={(props, option) => {
                                        return (
                                            <li {...props} key={option.id}>
                                                {(authUser.branchMedium == 1) ? option.nameMr : option.nameEn}
                                            </li>
                                        );
                                    }}
                                    options={subjectData}
                                    onChange={(event, newValue) => {
                                        setFilteredData([]);
                                        setClassDivData([]);
                                        setCheckValue([]);
                                        setSubjectObj(newValue);
                                        if (newValue != null) {
                                            setSubjectId(newValue.id);
                                        }
                                    }}
                                    value={subjectObj}
                                    style={{ fontSize: '14px' }}
                                    getOptionLabel={option => (authUser.branchMedium == 1) ? option.nameMr : option.nameEn}
                                    size="small"
                                    renderInput={params => (
                                        <TextField {...params} margin="dense" label="Select Subject" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                    )}
                                />
                            </div>

                            <div className="col-sm-4 mt-3">
                                <button type="submit" className="btn btn-sm btn-primary">Fill Class-Division</button>
                            </div>
                        </div>

                        <div className='row my-3' hidden={true}>
                            <label className="col-sm-1" style={{ fontSize: "14px", fontWeight: "600", marginTop: "10px" }} >Option:</label>
                            <div className="col-sm-3">
                                <FormControl fullWidth>
                                    <InputLabel id='demo-simple-select-label' style={{ fontSize: '14px' }}>Select Option</InputLabel>
                                    <Select
                                        size='small'
                                        required
                                        fullWidth
                                        margin='dense'
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={optionId}
                                        label="Select Option"
                                        onChange={(e) => {
                                            setOptionId(e.target.value);
                                            setFilteredData([]);
                                            setClassDivData([]);
                                            // if (e.target.value == 1) {
                                            //     setSubjectObj(null);
                                            // }
                                        }}
                                    >
                                        <MenuItem style={{ backgroundColor: "#F4ECF7", fontWeight: "550", fontSize: "14px" }} value={1}>Optional</MenuItem>
                                        <MenuItem style={{ backgroundColor: "#F4ECF7", fontWeight: "550", fontSize: "14px" }} value={2}>Compulsory</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                    </form>

                    {
                        (classDivData == null || classDivData == "")
                            ?
                            <h4 className='mt-5'>No Records Found, Select Subject</h4>
                            :
                            <div>
                                <div className='row my-1 mx-1'>
                                    <div className='offset-sm-4 mt-4'>
                                        <input
                                            className='form-control form-control-sm'
                                            type="text"
                                            style={{ width: "250px", display: "block", float: "left", marginBottom: "6px", border: "1px solid #C2C1C1" }}
                                            placeholder="Search Here"
                                            value={searchTerm}
                                            onChange={(event) => { setFilteredData([]); setSearchTerm(event.target.value) }}
                                        />
                                    </div>
                                </div>
                                {/* className='bodyWrapper' style={{ maxHeight: "220px", overflowY: "scroll", margin: "4px 0px" }} */}
                                <form onSubmit={saveData}>
                                    <div className='table-responsive col-sm-6 offset-sm-1 mt-2' style={{ maxHeight: "350px" }} >
                                        <table className="table table-bordered">
                                            <thead className="" style={{ position: "sticky", zIndex: "2", overflow: "hidden", top: "0", backgroundColor: "#EAECEE", borderBottom: "2px solid #464de4" }}>

                                                <tr>
                                                    {/* {FormTableHeading.map((item, index) => {
                                                        return (
                                                            <th style={{ fontSize: "14px", fontWeight: "600" }} scope="col" key={index}>{item.label}</th>
                                                        )
                                                    })} */}
                                                    <th style={{ fontSize: "15px", fontWeight: "600" }}>Class</th>
                                                    <th style={{ fontSize: "15px", fontWeight: "600" }}>Division</th>
                                                    <th style={{ fontSize: "15px", fontWeight: "600", margin: "0px", padding: "2px" }}>
                                                        <Checkbox sx={{ '& .MuiSvgIcon-root': { fontSize: 24 } }}
                                                            checked={checkAllSub}
                                                            onChange={checkAllSubjects} /> Status</th>
                                                </tr>
                                            </thead>
                                            <tbody style={{ borderTop: "2px solid #464de4", borderBottom: "2px solid #464de4" }}>
                                                {filteredData.map((item, index) => {

                                                    return (
                                                        <tr key={index} className="table-sm">
                                                            <td style={{ fontSize: "15px", fontWeight: "500" }} >{(authUser.branchMedium == 1) ? item.classNameMr : item.classNameEn}</td>
                                                            <td style={{ fontSize: "15px", fontWeight: "500" }} >{(authUser.branchMedium == 1) ? item.divisionNameMr : item.divisionNameEn}</td>
                                                            <td style={{ fontSize: "15px", fontWeight: "500", margin: "0px", padding: "0px" }} >
                                                                <Checkbox defaultChecked={(item.isAllocated === 1) ? true : false} sx={{ '& .MuiSvgIcon-root': { fontSize: 30 } }}
                                                                    onChange={(e) => {
                                                                        let val = e.target.checked;
                                                                        handleCheckChange(val, item.divisionId, item.classId, item.secessionYear, item.branchId, item.sectionId, item.isAllocated, item);
                                                                    }}
                                                                />
                                                            </td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>
                                    </div>

                                    <div className="col-sm-12 mt-3">
                                        <div className="offset-sm-1">
                                            <button type="submit" className="subAllocationBtn btn btn-sm btn-warning"><AddIcon fontSize="small" />Save Changes</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                    }
                </div>
                {/* <ToastContainer position="top-right" theme="colored" /> */}

            </>
        )
    }

}

export default SubjectAllocation