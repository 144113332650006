import React, { useState, useEffect } from 'react';
import TitleLabel from "../../CommonComponent/TitleLabel";
import axios from 'axios';
import Paper from "@mui/material/Paper";
import TextField from '@mui/material/TextField';
import Autocomplete from "@mui/material/Autocomplete";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import AddIcon from '@mui/icons-material/Add';
import Loader from '../../CommonComponent/Loader';
import { getClassWithDiv } from '../../CommonServices/ClassMasterAPIURL';
import { getSubjectDataAPIURL, saveSubjectSerialAPIURL } from '../Services/CatalogReportAPIURL';
import { useNavigate } from 'react-router-dom';
import SetSubjectSerialTextBox from './SubjectSerialTextBox/SetSubjectSerialTextBox';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';


const SetSubjectSerial = () => {

    const authUser = useSelector((state) => state.user.value);
    const titleId = "Subject Serial Allocation";
    const [loaderOption, setLoaderOption] = useState(false);
    const navigate = useNavigate();

    const [isAscending, setAscending] = useState(false);
    const [sortedBy, setSortedBy] = useState(null);
    const [classDivObj, setClassDivObj] = useState(null);
    const [divisionData, setDivisionData] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [allDivisions, setAllDivisions] = useState(0);
    //console.log(JSON.stringify(checkValue));

    const HandleAllDivisionCheckbox = (e) => {
        setAllDivisions(e.target.checked);
        if (e.target.checked == true) {
            setAllDivisions(1);
        }
        else {
            setAllDivisions(0);
        }
    }

    useEffect(() => {
        getData();
    }, [authUser])

    const getData = async () => {
        await axios.get(`${getClassWithDiv}?sid=${authUser.deptId}&bid=${authUser.branchId}`)
            .then(res => {
                setDivisionData(res.data);
            }).catch(error => {
                console.log(error);
            })
    }


    const TableHeading = [
        { label: `Subject`, key: (authUser.branchMedium == 1) ? 'subjectNameMr' : 'subjectNameEn', isAction: true },
        { label: `Serial No`, key: 'serialNo', isAction: true },
    ];

    const onSort = (key, isAction) => {
        if (!isAction) {
            setSortedBy(key)
            if (isAscending) setFilteredData([...data.sort((a, b) => a[key].toString().toLowerCase() < b[key].toString().toLowerCase() ? 1 : -1)])
            else setFilteredData([...data.sort((a, b) => a[key].toString().toLowerCase() > b[key].toString().toLowerCase() ? 1 : -1)])
            setAscending(!isAscending)
        }

    }

    const getSubjectDetails = async (cId, dId) => {
        setLoaderOption(true);
        setData([]);
        setFilteredData([]);
        //console.log(allDivisions)
        setAllDivisions(0);
        await axios.get(`${getSubjectDataAPIURL}?sid=${authUser.deptId}&bid=${authUser.branchId}&acYear=${authUser.sessionYear}&classId=${cId}&divId=${dId}`)
            .then(res => {
                //console.log(JSON.stringify(res.data))
                setData(res.data);
                setFilteredData(res.data);
                setLoaderOption(false);
            }).catch(error => {
                console.log(error);
            })
    }

    const [subId, setSubId] = useState('');
    const [serialNo, setSerialNo] = useState('');
    //console.log(serialNo)


    // const saveSubjectSerial = async () => {
    //     setSerialNo('');
    //     setSubId('');
    //     const srNoNew = data.find((item, ind) => {
    //         if (item.srNo == serialNo && item.subjectId != subId) {
    //             return item;
    //         }
    //     })

    //     console.log(JSON.stringify(data))

    //     if (!srNoNew) {
    //         setLoaderOption(true);
    //         await axios.post(`${saveSubjectSerialAPIURL}?allDivisionAllocation=${allDivisions}`, data)
    //             .then(res => {
    //                 if (res.data === "SAVED") {
    //                     setData([]);
    //                     setFilteredData([]);
    //                     setClassDivObj(null);
    //                     setLoaderOption(false);
    //                     toast.success("Subject serial allocation successfully done.")
    //                 }
    //             }).catch(error => {
    //                 console.log(error);
    //             })
    //         //toast.success("Serial No" + serialNo + "subId=" + subId)
    //     }
    //     else if (serialNo == 0 && subId !== "") {
    //         setLoaderOption(true);
    //         await axios.post(`${saveSubjectSerialAPIURL}?allDivisionAllocation=${allDivisions}`, data)
    //             .then(res => {
    //                 if (res.data === "SAVED") {
    //                     setData([]);
    //                     setFilteredData([]);
    //                     setClassDivObj(null);
    //                     setLoaderOption(false);
    //                     toast.success("Subject serial allocation successfully done.")
    //                 }
    //             }).catch(error => {
    //                 console.log(error);
    //             })
    //     }
    //     else if (serialNo == "" && subId == "") {
    //         setData([]);
    //         setFilteredData([]);
    //         setClassDivObj(null);
    //         toast.success("Subject serial allocation successfully done.")
    //         if (allDivisions == 1) {
    //             setLoaderOption(true);
    //             await axios.post(`${saveSubjectSerialAPIURL}?allDivisionAllocation=${allDivisions}`, data)
    //                 .then(res => {
    //                     if (res.data === "SAVED") {
    //                         setData([]);
    //                         setFilteredData([]);
    //                         setClassDivObj(null);
    //                         setLoaderOption(false);
    //                         toast.success("Subject serial allocation successfully done.")
    //                     }
    //                 }).catch(error => {
    //                     console.log(error);
    //                 })
    //         }
    //         //toast.success("Serial No" + serialNo + "subId=" + subId)
    //     }
    //     //toast.success("Serial No" + serialNo + "subId=" + subId)
    // }

    const saveSubjectSerial = async (e) => {
        setLoaderOption(true);
        e.preventDefault();
        const newStudData = data.filter(e => e.srNo != 0)

        // console.log("serialNo===" + serialNo + "subId===" + subId)

        const srNoNew = newStudData.find((item, ind) => {
            if (item.srNo == serialNo && item.subjectId !== subId) {
                return item;
            }
        })

        // console.log(srNoNew)

        if (!srNoNew) {
            await axios.post(`${saveSubjectSerialAPIURL}?allDivisionAllocation=${allDivisions}`, data)
                .then(res => {
                    if (res.data === "SAVED") {
                        setData([]);
                        setFilteredData([]);
                        setClassDivObj(null);
                        setLoaderOption(false);
                        toast.success("Subject serial allocation successfully done.")
                    }
                }).catch(error => {
                    console.log(error);
                })

        }
        else {
            setLoaderOption(false);
            // console.log(JSON.stringify(data))
            // setSerialNo('');
            // setSubId('');
            toast.warn("Serial No " + serialNo + " already exists.")
        }
    }

    useEffect(() => {
        setFilteredData([]);
        let tempFilteredData = data.filter(item =>
            item.subjectNameMr.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.subjectNameEn.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.srNo.toString().toLowerCase().includes(searchTerm.toLowerCase())
        )
        setFilteredData(tempFilteredData);
    }, [searchTerm])

    const handelkeyPress = (event) => {
        if (event.key === "Enter") {
            if (data !== null || data != "") {
                event.preventDefault();
                let btn = document.querySelector('.mySerialBtn')
                if (btn !== null) {
                    // console.log('Enter is pressed!');
                    btn.click();
                }
            }
        }
    };

    useEffect(() => {
        // console.log('useEffect Called');
        document.addEventListener("keypress", handelkeyPress);

        return () => {
            // remove the event handelkeyPress
            document.removeEventListener("keypress", handelkeyPress);
        };
    }, []);



    if (loaderOption == true) {
        return <Loader />
    }
    else {
        return (
            <>
                <TitleLabel titleId={titleId} />
                <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: "350px" }}>

                    <div className="row mb-3">
                        <div className='col-sm-4 mx-3'>
                            <button className='btn btn-danger btn-sm' onClick={() => navigate('/Home/acd420/')}>Back</button>
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-sm-3 mx-3'>
                            <Autocomplete
                                id="combo-box-demo"
                                options={divisionData}
                                style={{ fontSize: '14px' }}
                                PaperComponent={({ children }) => (
                                    <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                )}
                                onChange={(event, newValue) => {
                                    setClassDivObj(newValue);
                                    if (newValue != null) {
                                        getSubjectDetails(newValue.classId, newValue.divisionId);
                                    }
                                    else if (newValue == null) {
                                        setFilteredData([]);
                                        setData([]);
                                    }
                                }}
                                value={classDivObj}
                                getOptionLabel={option => (authUser.branchMedium == 1) ? option.classNameMr + " " + option.divisionNameMr : option.classNameEn + " " + option.divisionNameEn}
                                size="small"
                                renderInput={params => (
                                    <TextField {...params} label="Select Class-Division" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                )}
                            />
                        </div>
                    </div>

                    {
                        (data == null || data == "" || data == [])
                            ?
                            <h4 className='mt-5 mx-3'>No Records, Select Class-Division</h4>
                            :
                            <div>
                                <form onSubmit={saveSubjectSerial}>
                                    <div className='col-sm-8 mt-5'>
                                        <div className='table-responsive mt-3' style={{ maxHeight: "470px" }}>
                                            <table className="table table-bordered ">
                                                <thead className="table-Default" style={{ position: "sticky", overflow: "hidden", top: "0", backgroundColor: "#EAECEE", borderBottom: "2px solid #464de4" }}>
                                                    <tr style={{ borderBottom: "2px solid #464de4", position: "sticky", overflow: "hidden" }}>
                                                        {TableHeading.map((item, index) => {
                                                            return (
                                                                <th style={{ fontSize: "14px", fontWeight: "600" }} onClick={() => onSort(item.key, item.isAction)} key={index} scope="col">{`${item.label} ${sortedBy === item.key ? isAscending ? '↑' : '↓' : ''}`}</th>
                                                            )
                                                        })}
                                                    </tr>
                                                </thead>
                                                <tbody style={{ borderTop: "2px solid #464de4", borderBottom: "2px solid #464de4" }}>
                                                    {filteredData
                                                        .map((item, index) => {
                                                            return (
                                                                <React.Fragment key={index} >
                                                                    <tr style={{ height: "60px" }} >
                                                                        <td style={{ fontSize: "15px", fontWeight: "500", width: "200px", lineHeight: "1.3" }}>{(authUser.branchMedium == 1) ? item.subjectNameMr : item.subjectNameEn}</td>

                                                                        <SetSubjectSerialTextBox initialText={item.srNo} checkData={data} subCode={item.subjectId} onChange={(value) => {
                                                                            let updatedData = filteredData;
                                                                            updatedData[index] = ({ ...item, srNo: value });
                                                                            setFilteredData(updatedData);
                                                                            setData(updatedData);
                                                                            setSerialNo(value);
                                                                            setSubId(updatedData[index].subjectId)
                                                                            //console.log(JSON.stringify(updatedData))                                                                      

                                                                        }} />
                                                                    </tr>
                                                                </React.Fragment>
                                                            )
                                                        })}
                                                </tbody>
                                            </table>
                                        </div>

                                        <div className="row mt-5">
                                            <div className='col-sm-4'>
                                                <FormControlLabel control={<Checkbox checked={allDivisions} onChange={HandleAllDivisionCheckbox} />} sx={{ '.MuiFormControlLabel-label': { fontWeight: 600, fontSize: "15px" } }} label="Apply For All Divisions" />
                                            </div>

                                            <div className='col-sm-4'>
                                                <button className='mySerialBtn btn btn-primary btn-sm mb-2' type="submit"><AddIcon fontSize="small" />Save Changes</button>
                                            </div>
                                        </div>

                                    </div>
                                </form>
                            </div>
                    }
                </div>
                <ToastContainer position="top-right" theme="colored" />
            </>

        )
    }
}



export default SetSubjectSerial