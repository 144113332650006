import React, { useState, useEffect } from 'react';
import TitleLabel from "../../CommonComponent/TitleLabel";
import axios from 'axios';
import PrintIcon from '@mui/icons-material/Print';
import { ToastContainer, toast } from 'react-toastify';
import { useSelector } from "react-redux";
import { accMasterGetAPI, getPrintAccMasterAPIURL } from '../Services/AccountMasterReportAPI';
import Loader from '../../CommonComponent/Loader';
import Paper from "@mui/material/Paper";

const AccountMasterReport = () => {
    const authUser = useSelector((state) => state.user.value);
    const titleId = "Account Master Report";

    const [loaderOption, setLoaderOption] = useState(false);

    const printAccountMasterReport = () => {
        setLoaderOption(true);
        axios.get(`${accMasterGetAPI}?sid=${authUser.deptId}&bid=${authUser.branchId}&year=${authUser.sessionYear}`)
            .then((response) => {

                if (response.data == "NOTFOUND") {
                    //console.log(response.data)   
                    setLoaderOption(false);
                    toast.warn("Data not found.")
                }
                else if (response.data != null) {
                    // console.log(response.data)
                    window.open(`${getPrintAccMasterAPIURL}${response.data}`);
                    setLoaderOption(false);
                }

            })
            .catch(error => {

                toast.error("Error Occoured, Try after sometime.");
                console.log("Account Master Report failed: " + error)
            })
    }


    return (
        <>
            {
                (loaderOption == true)
                    ?
                    <Loader />
                    :
                    <div>
                        <TitleLabel titleId={titleId} />
                        <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: "350px" }}>
                            <div className=''>
                                <button
                                    type='button'
                                    onClick={() => printAccountMasterReport()}
                                    className='btn btn-primary btn-sm mb-2'
                                //disabled={btnDisabled}
                                >
                                    <PrintIcon fontSize="small" /> Print
                                </button>

                                {/* <button type="submit" className="btn btn-danger btn-md" style={{ margin: '0px 4px' }} onClick={() => navigate("/Home/")}>Exit</button> */}
                            </div>
                        </div>
                    </div>
            }

            <ToastContainer position="top-right" theme="colored" />
        </>
    )
}

export default AccountMasterReport