import React, { useState, useEffect } from 'react';
import TitleLabel from "../../CommonComponent/TitleLabel";
import TextField from '@mui/material/TextField';
import Autocomplete from "@mui/material/Autocomplete";
import axios from "axios";
import Paper from "@mui/material/Paper";
import { useSelector } from "react-redux";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { ToastContainer, toast } from 'react-toastify';
import {
    getStudentList, getStudentListForEdit, getFeeData, getTransactionNo, getFeeDetails, printFeeReceipt, saveFeeCollectionData,
    getStudentDataFeeCollectionByRegNo, updateStudentDataFeeCollection, getBankData
} from '../Services/FeeReceiptAPIURL';
import { GetYearAPIURL } from '../../CommonServices/LoginAPIURL';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import Loader from "../../CommonComponent/Loader";
import { useNavigate } from 'react-router-dom';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { getExportedReportAPIURL } from '../../CommonServices/APIURL';
import CircularProgress from '@mui/material/CircularProgress';
import makeStyles from "@mui/styles/makeStyles";
import { toDate } from 'date-fns';
import MultipleFeeReceiptInput from './MultipleFeeReceiptTextBox/MultipleFeeReceiptInput';
import { getFeeList } from '../../CommonServices/FeeMasterAPIURL';
import AddStudenyBySearching from '../../CommonComponent/AddStudenyBySearching';
import { serachStudentByRegNoAPIURL } from '../../Sanstha/Services/StudentProfileAPIURL';

const useStyles = makeStyles(theme => ({
    select: {
        maxHeight: 160,
        "& ul": {
            backgroundColor: "#F4ECF7",
            //maxHeight: 160,
        },
        "& li": {
            // backgroundColor: "#F4ECF7", fontWeight: "550", fontSize: "14px" 
            fontSize: "14px",
            fontWeight: "550"
        },
    },

}));

function sleep(delay = 0) {
    return new Promise((resolve) => {
        setTimeout(resolve, delay);
    });
}

const FeeReceipt = () => {
    const classes = useStyles();
    const authUser = useSelector((state) => state.user.value);
    let { tId, EditFlag } = useParams();
    const navigate = useNavigate();
    const titleId = "Fee Receipt";

    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState([]);
    const loading = open && options.length === 0;

    const defaultMinDate = new Date(`${authUser.sessionYear}/04/01`);
    const defaultMaxDate = new Date(`${authUser.sessionYear + 1}/05/31`);

    // loader on/off
    const [loaderOption, setLoaderOption] = useState(false);

    //Note: This below states are declared for Save & Update(Disabled/unDisabled)
    const saveBtnStyle = "feeReceiptSaveBtn btn btn-primary btn-sm";
    const updateBtnStyle = "feeReceiptUpdateBtn btn btn-primary btn-sm";
    const displayNoneBtnStyle = "d-none";
    const [saveBtn, setSaveBtn] = useState(saveBtnStyle);
    const [updateBtn, setUpdateBtn] = useState(displayNoneBtnStyle);

    const [saveBtnDisabled, setSaveBtnDisabled] = useState(false);
    const [updateBtnDisabled, setUpdateBtnDisabled] = useState(false);

    const [id, setId] = useState("");
    const [tNo, setTno] = useState("");

    let todaysdate = new Date();
    const [tDate, setTdate] = useState(todaysdate);
    const [studentData, setStudentData] = useState([]);
    //console.log(studentData.length)
    const [yearData, setYearData] = useState([]);
    const [bankData, setBankData] = useState([]);
    const [yearId, setYearId] = useState([]);
    const [yearObj, setYearObj] = useState(null);
    const [studentId, setStudentId] = useState([]);
    const [studentObj, setStudentObj] = useState(null);

    const [division, setDivision] = useState("");
    const [classData, setclassData] = useState("");
    const [selectedStudent, setSelectedStudent] = useState("");

    const [feeData, setFeeData] = useState([]);
    const [feeId, setFeeId] = useState([]);
    const [feeObj, setFeeObj] = useState(null);
    const [amount, setAmount] = useState("");
    const [cashReceipt, setCashReceipt] = useState(1);
    const [pendingAmount, setPendingAmount] = useState("");
    const [receivedAmount, setReceivedAmount] = useState("");
    const [classDataEn, setClassDataEn] = useState("");
    const [feeShortName, setFeeShortName] = useState("");
    const [description, setDescription] = useState("");
    const [showGuardianNm, setShowGuardianNm] = useState(0);
    const [guardianNm, setGuardianNm] = useState("");

    const [classOnUpdate, setClassOnUpdate] = useState('');
    const [divOnUpdate, setDivOnUpdate] = useState('');
    const [classDivUpdatedName, setClassDivUpdatedName] = useState('');
    const [offset, setOffset] = useState(0);
    const [limits, setLimits] = useState(400);
    const [numCal, setNumCall] = useState(0);

    const [feeType, setFeeType] = useState(null);
    //console.log(JSON.stringify(feeData))

    const [tableFeeData, setTableFeeData] = useState([]);
    const [tableFeeId, setTableFeeId] = useState('');

    const [studentLcNo, setStudentLcNo] = useState('');
    const [studentDetails, setStudentDetails] = useState({});

    const HandleGuardianNameCheckbox = (e) => {
        // setShowGuardianNm(e.target.checked);
        // if (e.target.checked == true) {
        //     setShowGuardianNm(1);
        // }
        // else {
        //     setShowGuardianNm(0);
        //     setGuardianNm("");
        // }
        setShowGuardianNm(e.target.checked ? 1 : 0);
        if (!e.target.checked) {
            setGuardianNm("");
        }
    }

    const showAmountError = {
        display: "block"
    }
    const hideAmountError = {
        display: "none"
    }
    const [amountCheck, setAmountCheck] = useState(hideAmountError);
    const [dateCheck, setDateCheck] = useState("");

    const HandleCashReceiptCheckbox = (e) => {
        // setCashReceipt(e.target.checked);
        // if (e.target.checked == true) {
        //     setCashReceipt(1);
        //     setDescription("");
        // }
        // else {
        //     setCashReceipt(0);
        // }
        setCashReceipt(e.target.checked ? 1 : 0);
        if (e.target.checked) {
            setDescription("");
        }

    }

    useEffect(() => {
        getData();
    }, [authUser]);

    const getData = async () => {
        if (EditFlag == 2) {
            getEditDetails();
            setSaveBtnDisabled(true);
            setUpdateBtnDisabled(false);
        }
        else if (EditFlag == 1) {
            getAPIData();
            setSaveBtnDisabled(false);
            setUpdateBtnDisabled(true);
        }
    }

    const getEditDetails = () => {
        // console.log(`${getStudentDataFeeCollectionByRegNo}` + tId)
        setLoaderOption(true);
        if (authUser.sessionYear != "" && authUser.deptId != null && authUser.branchId != null) {
            axios.all([
                axios.get(`${getStudentDataFeeCollectionByRegNo}` + tId)
            ]).then(
                axios.spread((respOne) => {
                    // console.log(JSON.stringify(respOne.data.transactionDate))
                    setSaveBtn(displayNoneBtnStyle);
                    setUpdateBtn(updateBtnStyle);
                    setTno(respOne.data.transactionIdString);
                    setTdate(respOne.data.transactionDate);
                    const selectedStudent = respOne.data.studentId;
                    setclassData((authUser.branchMedium == 1) ? respOne.data.classNameMr : respOne.data.classNameEn);
                    setDivision((authUser.branchMedium == 1) ? respOne.data.divisionNameMr : respOne.data.divisionNameEn);
                    setClassDivUpdatedName(respOne.data.classNameEn + "" + respOne.data.divisionNameEn)
                    setClassOnUpdate(respOne.data.classId);
                    setDivOnUpdate(respOne.data.divisionId);
                    const selectedFee = respOne.data.feeId;
                    const selectedYear = respOne.data.collectionYear;
                    //console.log(selectedYear)
                    setReceivedAmount(respOne.data.feeAmount);
                    setCashReceipt(respOne.data.isCash);
                    setDescription(respOne.data.description);
                    setShowGuardianNm(respOne.data.isGuardian)
                    setGuardianNm(respOne.data.guardianName);

                    axios(`${GetYearAPIURL}`)
                        .then(res => {
                            setYearData(res.data);
                            setYearObj(res.data.find(e => e.id == selectedYear))
                            setYearId(selectedYear);
                            //setLoaderOption(false);
                        })

                    axios(`${getFeeData}`)
                        .then(respThree => {
                            const feeDataForReceipt = (respThree.data).filter(e => e.feeType !== 5)
                            setFeeData(feeDataForReceipt);
                            setFeeObj(respThree.data.find(e => e.id == selectedFee))
                            setFeeId(selectedFee);
                            //setLoaderOption(false);
                        })

                    axios(`${getBankData}`)
                        .then(res => {
                            setBankData(res.data);
                            //setLoaderOption(false);
                        })

                    axios(`${getFeeDetails}?acyr=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&studid=${selectedStudent}&fid=${selectedFee}`)
                        .then(res => {
                            setAmount(res.data.feeAmount);
                            setPendingAmount(res.data.pendingAmount);
                            //setLoaderOption(false);
                        })

                    // axios(`${getStudentListForEdit}?sid=${authUser.deptId}&bid=${authUser.branchId}`)
                    //     .then(respTwo => {
                    //         setStudentData(respTwo.data);
                    //         setStudentObj(respTwo.data.find(e => e.regCode == selectedStudent))
                    //         setStudentId(selectedStudent);
                    //         setLoaderOption(false);
                    //     })

                    axios(`${serachStudentByRegNoAPIURL}?regNo=${selectedStudent}&sid=${authUser.deptId}&bid=${authUser.branchId}`)
                        .then(res => {
                            if (res.data == "NOTFOUND") {
                                toast.warn("Student not fount with this name.")
                            }
                            else {
                                const filteredData = res.data;

                                // Log the filtered data for debugging
                                // console.log("Filtered Data: ", filteredData);

                                const classWithDivision = `${authUser.branchMedium == 1 ? filteredData.currentClassNameMr : filteredData.currentClassNamEn} - ${authUser.branchMedium == 1 ? filteredData.currentDivNameMr : filteredData.currentDivNamEn}`;

                                // Set filtered data state
                                //setclassData(authUser.branchMedium == 1 ? filteredData.currentClassNameMr : filteredData.currentClassNamEn);
                                // setDivision(authUser.branchMedium == 1 ? filteredData.currentDivNameMr : filteredData.currentDivNamEn);
                                setclassData(classWithDivision);
                                setStudentId(filteredData.regCode);
                                // setStudRegNo(filteredData.regCode);
                                setSelectedStudent(authUser.branchMedium == 1 ? filteredData.studentNameMr : filteredData.studentNameEn);
                                setStudentLcNo(filteredData.lcNo);
                                setStudentDetails(filteredData)
                                setLoaderOption(false);
                            }
                        });


                })
            )
        }
    }

    const getAPIData = async () => {
        //console.log(`${GetYearAPIURL}`)
        //setLoaderOption(true);
        await axios(`${GetYearAPIURL}`)
            .then(res => {
                const year = (res.data).filter(e => e.yearLabel == authUser.yearLabel)
                setYearData(res.data);
                setYearId((year && year.length > 0) ? year[0].id : '')
                //setLoaderOption(false);
            })

        await axios(`${getFeeData}`)
            .then(res => {
                const feeDataForReceipt = (res.data).filter(e => e.feeType !== 5)
                setFeeData(feeDataForReceipt);
                //setLoaderOption(false);
            })


        // new addedF
        // await axios(`${getFeeList}`)
        //     .then(res => {
        //         //const feeDataForReceipt = (res.data).filter(e => e.feeType !== 5)
        //         //setFeeData(res.data);
        //         //setLoaderOption(false);
        //         //console.log(JSON.stringify(res.data))

        //         const objIds = res.data.reduce((a, { nameMr, nameEn, id, feeType, shortName, isShowGuardianName, feeTypeName }) => {
        //             a[feeType] = a[feeType] || { feeDetails: [] }
        //             return {
        //                 ...a, ...{
        //                     [feeType]: {
        //                         feeType,
        //                         feeTypeName,
        //                         feeDetails: a[feeType].feeDetails.concat({
        //                             feeType: feeType,
        //                             id: id,
        //                             nameMr: nameMr,
        //                             nameEn: nameEn,
        //                             shortName: shortName,
        //                             isShowGuardianName: isShowGuardianName
        //                         }),
        //                     }
        //                 }
        //             }
        //         }, {})
        //         const result = Object.values(objIds)

        //         const feeDataForReceipt = result.filter(e => e.feeType !== 5)

        //         setFeeData(feeDataForReceipt);

        //     })

        await axios(`${getBankData}`)
            .then(res => {
                setBankData(res.data);
                //setLoaderOption(false);
            })

        //console.log(`${getStudentList}?sid=${authUser.deptId}&bid=${authUser.branchId}`)
        if (authUser.deptId != null && authUser.branchId != null) {

            if (offset === 0) {
                // setLoaderOption(true)
            }
            // console.log(`${getStudentList}?sid=${authUser.deptId}&bid=${authUser.branchId}&limit=${limits}&offset=${offset}`)
            await axios.get(`${getStudentList}?sid=${authUser.deptId}&bid=${authUser.branchId}&limit=${limits}&offset=${offset}`)
                .then((res) => {
                    if (res.data !== null) {
                        const tempData = res.data;
                        //console.log(tempData.length)
                        setStudentData(studentData.concat(tempData));
                        setLoaderOption(false)
                        //console.log("Data : " + JSON.stringify(data))
                        // console.log("____________________________________________")
                        if (tempData.length === limits) {
                            setOffset(offset + limits);
                            setNumCall(numCal + 1);
                            //console.log("Offset ===============" + offset);
                        }
                    }
                })
        }

        const maxDate = new Date(defaultMaxDate);
        maxDate.setDate(maxDate.getDate() + 1); // Add one day to max date to include the full day
        if (tDate < defaultMinDate || tDate >= maxDate) {
            setDateCheck("Invalid date");
        } else {
            setDateCheck("");
        }
    }

    useEffect(() => {
        let active = true;

        if (!loading) {
            return undefined;
        }

        (async () => {
            await sleep(1e3); // For demo purposes.

            if (active) {
                setOptions([...studentData]);
            }
        })();

        return () => {
            active = false;
        };
    }, [loading]);

    useEffect(() => {
        if (!open) {
            setOptions([]);
        }
    }, [open]);

    const setTransactionNo = async (feeSn, FId) => {
        //console.log(`${getTransactionNo}?acyr=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&studid=${studentId}&feesn=${feeSn}&cdnm=${classDataEn}`)
        await axios(`${getTransactionNo}?acyr=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&studid=${studentId}&feesn=${feeSn}&cdnm=${classDataEn}&feeId=${FId}`)
            .then(res => {
                setTno(res.data);
            })

        await axios(`${getFeeDetails}?acyr=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&studid=${studentId}&fid=${FId}`)
            .then(res => {
                // console.log(res.data.feeAmount)
                setAmount(res.data.feeAmount);
                setPendingAmount(res.data.pendingAmount);
            })
    }

    const saveFeeCollection = async () => {
        setLoaderOption(true);
        if (moment(tDate).format("DD/MM/YYYY") !== "Invalid date" && dateCheck !== "Invalid date") {
            const saveObject = {
                transactionDate: moment(tDate).format("DD/MM/YYYY"),
                collectionYear: yearId,
                studentId: studentId,
                classDivName: classDataEn,
                feeId: feeId,
                feeShortName: feeShortName,
                receivedAmount: receivedAmount,
                isCash: cashReceipt,
                isGuardian: showGuardianNm,
                description: description,
                guardianName: guardianNm,
                sessionYear: authUser.sessionYear,
                sectionId: authUser.deptId,
                branchId: authUser.branchId,
                createdBy: authUser.id,
                userIp: authUser.headers.userIp,
            }
            //console.log(JSON.stringify(saveObject))
            await axios.post(`${saveFeeCollectionData}`, saveObject)
                .then((response) => {
                    if (response.data != null) {
                        getData();
                        setLoaderOption(false);
                        toast.success("Fee Receipt Saved sucessfully.");

                    } else {
                        setLoaderOption(false);
                        toast.error("Operation failed.");
                    }
                })
                .catch(error => {
                    console.log("Save Fire Query: " + error)
                })
            setTno("");
            setStudentObj(null);
            setClassDataEn("");
            setFeeObj(null);
            setYearObj(null);
            //setYearId([]);
            setclassData("");
            setDivision("");
            setAmount("");
            setPendingAmount("");
            setFeeShortName("");
            setReceivedAmount("");
            setCashReceipt(1);
            setDescription("");
            setSelectedStudent("");
            //toast.success("valid Date")
        }
        else {
            setLoaderOption(false);
            toast.error("Improper date, please check.")
        }
    }

    const updateFeeCollection = async () => {
        setLoaderOption(true);
        // console.log(studentDetails)
        if (moment(tDate).format("DD/MM/YYYY") !== "Invalid date" && dateCheck !== "Invalid date"
            && studentDetails.currentClassId == classOnUpdate && studentDetails.currentDivisionId == divOnUpdate
        ) {
            const updateObject = {
                receivedAmount: receivedAmount,
                isCash: cashReceipt,
                description: description,
                guardianName: guardianNm,
                isGuardian: showGuardianNm,
                collectionYear: yearId,
                studentId: studentId,
                transactionDate: moment(tDate).format("DD/MM/YYYY"),
                createdBy: authUser.id,
                userIp: authUser.headers.userIp,
                userLat: authUser.headers.userLat,
                userLong: authUser.headers.userLong,
                deviceSign: authUser.headers.deviceSign,
                deviceType: authUser.headers.deviceType,
                deviceOS: authUser.headers.deviceOS,
                browserName: authUser.headers.browserName,
                browserVersion: authUser.headers.browserVersion
            };
            // console.log(JSON.stringify(updateObject))
            await axios.put(`${updateStudentDataFeeCollection}` + tId, updateObject)
                .then((response) => {
                    if (response.data != null) {
                        getData();
                        setLoaderOption(false);
                        setSaveBtn(saveBtnStyle);
                        setUpdateBtn(displayNoneBtnStyle);
                        navigate("/Home/f311/2")
                    }
                });
        }
        else {
            if (moment(tDate).format("DD/MM/YYYY") == "Invalid date" || dateCheck == "Invalid date") {
                setLoaderOption(false);
                toast.error("Improper date, please check.")
            }
            else {
                setLoaderOption(false);
                toast.warn(`You can't update student out of  ${classDivUpdatedName}  class.`)
            }
        }

    };

    const ClearData = () => {
        navigate("/Home/f311/0")
        setSaveBtn(saveBtnStyle);
        setUpdateBtn(displayNoneBtnStyle);
    }

    const state = {
        button: 1
    };

    const onSubmit = e => {
        e.preventDefault();
        if (state.button === 1) {
            saveFeeCollection();
        }
        if (state.button === 2) {
            updateFeeCollection();
        }
    };

    useEffect(() => {

        function handlekeydownEvent(event) {
            if (event.key === "Enter") {
                //console.log("Enter is pressed!")
                event.preventDefault();
                if (EditFlag == 1) {
                    // console.log('save Enter is pressed!');
                    setSaveBtnDisabled(false);
                    setUpdateBtnDisabled(true);
                    let btn1 = document.querySelector('.feeReceiptSaveBtn')
                    if (btn1 !== null) {
                        btn1.click();
                    }
                }
                if (EditFlag == 2) {
                    // console.log('update Enter is pressed!');
                    setSaveBtnDisabled(true);
                    setUpdateBtnDisabled(false);
                    let btn2 = document.querySelector('.feeReceiptUpdateBtn')
                    if (btn2 !== null) {
                        btn2.click();
                    }
                }
            }
        }

        document.addEventListener('keypress', handlekeydownEvent)
        return () => {
            document.removeEventListener('keypress', handlekeydownEvent)
        }

    }, []);

    const TableHeading = [
        { label: "Sr No", key: 'id', isAction: true },
        { label: `Fee`, key: (authUser.branchMedium == 1) ? 'feeNameMr' : 'feeNameEn', isAction: true },
        { label: `Fee Amount`, key: 'feeAmount', isAction: true },
        { label: `Pending Amount`, key: 'pendingAmount', isAction: true },
        { label: `Receivable Amount`, key: 'feeAmount', isAction: true },
        { label: `Concession`, key: 'pendingAmount', isAction: true },
    ];


    // new added 
    const getAllFeesByFeeType = async (e) => {
        setFeeType(e);
        // console.log(JSON.stringify(e))
        await axios(`${getFeeData}`)
            .then(res => {
                // console.log(JSON.stringify(res.data));
                const feeDataForReceipt = (res.data).filter(item => item.feeType == e.feeType)
                // console.log(JSON.stringify(feeDataForReceipt));
                setTableFeeData(feeDataForReceipt);
                // setLoaderOption(false);


            })

        // console.log(`${getFeeDetails}?acyr=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&studid=${studentId}&fid=${e.id}`)
        await axios(`${getFeeDetails}?acyr=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&studid=${studentId}&fid=${e.id}`)
            .then(res => {
                // console.log(res.data.feeAmount)
                // setAmount(res.data.feeAmount);
                //setPendingAmount(res.data.pendingAmount);
                //setLoaderOption(false);
            })

    }


    if (loaderOption == true) {
        return <Loader />
    }
    else {
        return (
            <>
                <TitleLabel titleId={titleId} />
                <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: "480px" }}>
                    <form onSubmit={onSubmit}>

                        <input type="hidden" value={id} onChange={(e) => setId(e.target.value)} name="" />
                        <div className="row">
                            <label htmlFor="lastname3" className="col-sm-1" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>T No :</label>
                            <div className='col-sm-3'>
                                <TextField
                                    required
                                    fullWidth
                                    autoComplete='off'
                                    size='small'
                                    id="outlined-basic"
                                    label="T No"
                                    variant="outlined"
                                    margin='dense'
                                    value={tNo}
                                    onChange={(e) => setTno(e.target.value)}
                                    onKeyPress={(e) => (e.key == "Enter") ? tNo : ""}
                                    inputProps={{ readOnly: true }}
                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                />
                            </div>

                            <label htmlFor="lastname3" className="col-sm-1" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>T Date :</label>
                            <div className='col-sm-3'>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        label="T Date"
                                        value={tDate}
                                        minDate={defaultMinDate}
                                        maxDate={defaultMaxDate}
                                        onChange={(newValue) => {
                                            setTdate(newValue);
                                            // (moment(newValue).format("DD/MM/YYYY") == "Invalid date") ? setDateCheck("Invalid Date") : setDateCheck("")
                                            //console.log(newValue)
                                            //(newValue > defaultMaxDate || newValue < defaultMinDate) ? setDateCheck("Invalid date") : setDateCheck("")
                                            const maxDate = new Date(defaultMaxDate);
                                            maxDate.setDate(maxDate.getDate() + 1); // Add one day to max date to include the full day
                                            if (newValue < defaultMinDate || newValue >= maxDate) {
                                                setDateCheck("Invalid date");
                                            } else {
                                                setDateCheck("");
                                            }
                                        }}
                                        openTo="day"
                                        // Show defaultMaxDate selected when the DatePicker is opened
                                        // If dateFrom is not set, defaultMaxDate is used
                                        onOpen={() => setTdate(tDate || defaultMaxDate)}
                                        onKeyPress={(e) => (e.key == "Enter") ? tDate : ""}
                                        readOnly={(EditFlag == 2 && authUser.roleId !== 3) ? true : false}
                                        inputFormat="dd/MM/yyyy"
                                        inputProps={{ readOnly: (EditFlag == 2 && authUser.roleId !== 3) ? true : false }}
                                        renderInput={(params) =>
                                            <TextField
                                                {...params}
                                                variant="outlined"
                                                margin='dense'
                                                InputLabelProps={{ style: { fontSize: 14 } }}
                                                required
                                                fullWidth
                                                autoComplete='off'
                                                size="small" />}
                                    />
                                </LocalizationProvider>
                                {/* <span><em style={{ fontSize: "12px", color: "red", fontWeight: "600" }}>{dateCheck}</em></span> */}
                            </div>

                            <label htmlFor="lastname3" className="col-sm-1" style={{ fontSize: "15px", fontWeight: "600", marginTop: "12px" }}>Year:</label>
                            <div className='col-sm-3 mt-2'>
                                <FormControl fullWidth>
                                    <InputLabel size="small" id='demo-simple-select-label' style={{ fontSize: '14px' }}>Select Year</InputLabel>
                                    <Select
                                        MenuProps={{ classes: { paper: classes.select } }}
                                        size='small'
                                        required
                                        fullWidth
                                        readOnly={(EditFlag == 2 && authUser.roleId !== 3) ? true : false}
                                        margin='dense'
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={yearId}
                                        defaultValue={[yearData[0]]}
                                        label="Select Year"
                                        onChange={(e) => setYearId(e.target.value)}
                                        onKeyPress={(e) => (e.key == "Enter") ? yearId : ""}
                                    >
                                        {yearData.map((item, index) => {
                                            return (
                                                <MenuItem key={index} value={item.id}>{item.yearLabel}</MenuItem>
                                            )
                                        })}
                                    </Select>
                                </FormControl>
                            </div>
                        </div>

                        <div className="my-2">
                            <AddStudenyBySearching
                                setclassData={setclassData}
                                setDivision={setDivision}
                                setStudentId={setStudentId}
                                setSelectedStudent={setSelectedStudent}
                                classData={classData}
                                division={division}
                                studentId={studentId}
                                selectedStudent={selectedStudent}
                                setStudentLcNo={setStudentLcNo}
                                label="Fee Receipt"
                                setStudentDetails={setStudentDetails}
                                className="col-sm-1"
                                readOnly={(EditFlag == 2 && authUser.roleId !== 3) ? true : false}
                                setLoaderOption={setLoaderOption}
                            />
                        </div>

                        {/* <div className="row mt-2">
                            <label htmlFor="lastname3" className="col-sm-1" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>Student:</label>
                            <div className='col-sm-4'>
                                <Autocomplete
                                    id="combo-box-demo"
                                    open={open}
                                    onOpen={() => {
                                        setOpen(true);
                                    }}
                                    onClose={() => {
                                        setOpen(false);
                                    }}
                                    loading={loading}
                                    options={studentData}
                                    readOnly={(EditFlag == 2 && authUser.roleId !== 3) ? true : false}
                                    PaperComponent={({ children }) => (
                                        <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                    )}
                                    onChange={(event, newValue) => {
                                        setStudentObj(newValue);
                                        setclassData('');
                                        setDivision('');
                                        if (newValue != null) {
                                            setclassData((authUser.branchMedium == 1) ? newValue.currentClassMr : newValue.currentClassEn);
                                            setDivision((authUser.branchMedium == 1) ? newValue.currentDivisionMr : newValue.currentDivisionEn);
                                            setClassDataEn(newValue.currentClassEn + newValue.currentDivisionEn);
                                            setStudentId(newValue.regCode);
                                        }
                                        if (EditFlag == 1) {
                                            setFeeObj(null);
                                            setPendingAmount("");
                                            setAmount("");
                                            setTno("");
                                            setReceivedAmount('');
                                            setShowGuardianNm(0);
                                            setCashReceipt(1);
                                            setDescription('');
                                            setGuardianNm('');
                                        }
                                        //console.log(newValue.currentClassEn + newValue.currentDivisionEn)
                                    }}
                                    onKeyPress={(e) => (e.key == "Enter") ? studentObj : ""}
                                    value={studentObj}
                                    style={{ fontSize: '14px' }}
                                    getOptionLabel={option => (authUser.branchMedium == 1) ? option.regCode + " - " + option.studentNameMr : option.regCode + " - " + option.studentNameEn}
                                    renderOption={(props, option) => (
                                        <div className='' {...props}>
                                            <div className=''>
                                                {(authUser.branchMedium == 1) ? option.regCode + " - " + option.studentNameMr + "  " + option.currentClassMr + "  " + option.currentDivisionMr : option.regCode + " - " + option.studentNameEn + "  " + option.currentClassEn + "  " + option.currentDivisionEn}
                                            </div>

                                        </div>
                                    )}
                                    size="small"
                                    renderInput={params => (
                                        <TextField {...params}
                                            InputProps={{
                                                ...params.InputProps,
                                                endAdornment: (
                                                    <React.Fragment>
                                                        {loading ? <CircularProgress color="inherit" size={20} style={{ marginRight: "25px" }} /> : null}
                                                        {params.InputProps.endAdornment}
                                                    </React.Fragment>
                                                ),
                                            }}
                                            margin='dense' label="Select Student" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }}
                                            required />
                                    )}
                                />
                            </div>

                            <div className='col-sm-4'>
                                <TextField
                                    required
                                    fullWidth
                                    autoComplete='off'
                                    size='small'
                                    id="outlined-basic"
                                    label="Student Name"
                                    variant="outlined"
                                    margin='dense'
                                    value={selectedStudent}
                                    onChange={(e) => setSelectedStudent(e.target.value)}
                                    onKeyPress={(e) => (e.key == "Enter") ? selectedStudent : ""}
                                    inputProps={{ readOnly: true }}
                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                />
                            </div>

                            <div className='col-sm-2'>
                                <TextField
                                    required
                                    fullWidth
                                    autoComplete='off'
                                    size='small'
                                    id="outlined-basic"
                                    label="Class"
                                    variant="outlined"
                                    margin='dense'
                                    value={classData}
                                    onChange={(e) => setclassData(e.target.value)}
                                    onKeyPress={(e) => (e.key == "Enter") ? classData : ""}
                                    inputProps={{ readOnly: true }}
                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                />
                            </div>

                            <div className='col-sm-2'>
                                <TextField
                                    required
                                    fullWidth
                                    autoComplete='off'
                                    size='small'
                                    id="outlined-basic"
                                    label="Division"
                                    variant="outlined"
                                    margin='dense'
                                    value={division}
                                    onChange={(e) => setDivision(e.target.value)}
                                    onKeyPress={(e) => (e.key == "Enter") ? division : ""}
                                    inputProps={{ readOnly: true }}
                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                />
                            </div>
                        </div> */}



                        <div className='row mt-2 '>
                            <div className='col-sm-3 mt-1'>
                                <FormControlLabel style={{ fontSize: "15px", fontWeight: "600" }} control={<Checkbox checked={Boolean(showGuardianNm)} onChange={HandleGuardianNameCheckbox} />} label="Show Guardian Name" />
                            </div>

                            {(() => {
                                if (showGuardianNm == 1) {
                                    return (
                                        <div className='col-sm-6'>
                                            <TextField
                                                fullWidth
                                                autoComplete='off'
                                                size='small'
                                                id="outlined-basic"
                                                label="Parent / Guardian Name"
                                                variant="outlined"
                                                margin='dense'
                                                value={guardianNm}
                                                onChange={(e) => setGuardianNm(e.target.value)}
                                                onKeyPress={(e) => (e.key == "Enter") ? guardianNm : ""}
                                                required={(showGuardianNm == 1) ? true : false}
                                                InputLabelProps={{ style: { fontSize: 14 } }}
                                            />
                                        </div>
                                    )
                                }
                            })()}

                        </div>

                        <div className="row mt-2">
                            <label htmlFor="lastname3" className="col-sm-1" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>Fee:</label>
                            <div className='col-sm-4'>
                                <Autocomplete
                                    id="combo-box-demo"
                                    options={feeData}
                                    readOnly={((EditFlag == 2)) ? true : false}
                                    PaperComponent={({ children }) => (
                                        <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                    )}
                                    onChange={(event, newValue) => {

                                        setFeeObj(newValue);
                                        if (newValue != null) {
                                            setFeeId(newValue.id);
                                            setTransactionNo(newValue.shortName, newValue.id);
                                            setFeeShortName(newValue.shortName);
                                        }
                                        else if (newValue == null) {
                                            setAmount('');
                                            setPendingAmount('');
                                        }

                                    }}
                                    value={feeObj}
                                    onKeyPress={(e) => (e.key == "Enter") ? feeObj : ""}
                                    style={{ fontSize: '14px' }}
                                    getOptionLabel={option => (authUser.branchMedium == 1) ? option.nameMr : option.nameEn}
                                    size="small"
                                    renderInput={params => (
                                        <TextField {...params} margin='dense' label="Select Fee" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                    )}
                                />
                            </div>

                            <div className='col-sm-2'>
                                <TextField
                                    required
                                    fullWidth
                                    type="number"
                                    autoComplete='off'
                                    size='small'
                                    id="outlined-basic"
                                    label="Fee Amount"
                                    variant="outlined"
                                    margin='dense'
                                    value={amount}
                                    onChange={(e) => setAmount(e.target.value)}
                                    onKeyPress={(e) => (e.key == "Enter") ? amount : ""}
                                    inputProps={{ readOnly: true }}
                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                />
                            </div>

                            <div className='col-sm-2'>
                                <TextField
                                    required
                                    fullWidth
                                    type="number"
                                    autoComplete='off'
                                    size='small'
                                    id="outlined-basic"
                                    label="Pending Amount"
                                    variant="outlined"
                                    margin='dense'
                                    value={pendingAmount}
                                    onChange={(e) => setPendingAmount(parseFloat(e.target.value).toFixed(2))}
                                    inputProps={{ readOnly: true }}
                                    onKeyPress={(e) => (e.key == "Enter") ? pendingAmount : ""}
                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                />
                            </div>


                        </div>

                        <div className="row  mt-2">
                            <label htmlFor="lastname3" className="col-sm-2" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>Received Amount:</label>
                            <div className='col-sm-3'>
                                <TextField
                                    required
                                    fullWidth
                                    type="number"
                                    onWheel={(e) => e.target.blur()}
                                    autoComplete='off'
                                    size='small'
                                    id="outlined-basic"
                                    label="Amount"
                                    variant="outlined"
                                    margin='dense'
                                    value={receivedAmount}
                                    onChange={(e) => {
                                        setReceivedAmount(e.target.value);
                                        // (parseFloat(e.target.value) > 0) ? setAmountCheck(hideAmountError) : setAmountCheck(showAmountError)

                                    }}
                                    inputProps={{ min: 1 }}
                                    onKeyPress={(e) => (e.key == "Enter") ? receivedAmount : ""}
                                    InputLabelProps={{ style: { fontSize: 14 } }}

                                />
                                <span style={amountCheck}><em style={{ fontSize: "12px", color: "red", fontWeight: "600" }}>* Amount should be greater than 0</em></span>
                            </div>
                        </div>

                        {/* <div className="row my-2">
                            <label className="col-sm-1" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Fee Type:</label>
                            <div className='col-sm-4'>

                                <FormControl fullWidth margin='dense'>
                                    <InputLabel id='demo-simple-select-label' size="small" style={{ fontSize: '14px' }}>Select Fee Type</InputLabel>
                                    <Select
                                        size='small'
                                        required
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={feeType}
                                        label="Select Fee Type"
                                        MenuProps={{ classes: { paper: classes.menuPaper } }}
                                        onChange={(e) => getAllFeesByFeeType(e)}
                                        onKeyPress={(e) => (e.key == "Enter") ? feeType : ""}
                                    >
                                        {feeData.map((item, index) => {
                                            return (
                                                <MenuItem value={item.feeType} key={index}>{item.feeTypeName}</MenuItem>
                                            )
                                        })}
                                    </Select>
                                </FormControl> */}

                        {/* new added */}
                        {/* <Autocomplete
                                    id="combo-box-demo"
                                    options={feeData}
                                    //readOnly={((EditFlag == 2)) ? true : false}
                                    PaperComponent={({ children }) => (
                                        <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                    )}
                                    onChange={(event, newValue) => {

                                        getAllFeesByFeeType(newValue);


                                    }}
                                    value={feeType}
                                    onKeyPress={(e) => (e.key == "Enter") ? feeType : ""}
                                    style={{ fontSize: '14px' }}
                                    getOptionLabel={option => option.feeTypeName}
                                    size="small"
                                    renderInput={params => (
                                        <TextField {...params} margin='dense' label="Select Fee" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                    )}
                                /> */}
                        {/* </div>
                        </div> */}

                        {/* new added */}
                        {/* {
                            feeType != null && tableFeeData.length > 0
                                ?
                                <>
                                    <div className='row mt-4'>
                                        <div className="col-sm-7 offset-sm-1">
                                            <div className='table-responsive' style={{ maxHeight: "500px" }}>
                                                <table className="table table-bordered ">
                                                    <thead className="table-Default" style={{ position: "sticky", overflow: "hidden", top: "0", backgroundColor: "#EAECEE", borderBottom: "2px solid #464de4" }}>
                                                        <tr style={{ borderBottom: "2px solid #464de4", position: "sticky", overflow: "hidden" }}>
                                                            {TableHeading.map((item, index) => {
                                                                return (
                                                                    <th style={{ fontSize: "14px", fontWeight: "600", lineHeight: '1.5' }} key={index} scope="col">{item.label}</th>
                                                                )
                                                            })}
                                                        </tr>
                                                    </thead>
                                                    <tbody style={{ borderTop: "2px solid #464de4", borderBottom: "2px solid #464de4" }}>
                                                        {tableFeeData
                                                            .map((item, index) => {
                                                                return (
                                                                    <React.Fragment key={index} >
                                                                        <tr style={{ height: "65px" }} >
                                                                            <td style={{ fontSize: "14px", fontWeight: "500", lineHeight: '1.5' }}>{index + 1}</td>
                                                                            <td style={{ fontSize: "14px", fontWeight: "500", lineHeight: '1.5' }}>{(authUser.branchMedium == 1) ? item.nameMr : item.nameEn}</td> */}
                        {/* <td style={{ fontSize: "15px", fontWeight: "500", width: "80px" }}>0</td>
                                                                            <td style={{ fontSize: "15px", fontWeight: "500", width: "80px" }}>0</td>
                                                                            <td style={{ fontSize: "15px", fontWeight: "500", width: "80px" }}>0</td>
                                                                            <td style={{ fontSize: "15px", fontWeight: "500", width: "80px" }}>0</td>                                                                           */}
                        {/* <MultipleFeeReceiptInput
                                                                                s1={item.s1}
                                                                                s2={item.s2}
                                                                                s3={item.s3}
                                                                                s4={item.s4}
                                                                                onChange={(s1text, s2text, s3text, s4text) => {
                                                                                    let updatedData = tableFeeData;
                                                                                    updatedData[index] = ({ ...item, s1: s1text, s2: s2text, s3: s3text, s4: s4text });

                                                                                    //console.log(updatedData);
                                                                                }}
                                                                            />
                                                                        </tr>
                                                                    </React.Fragment>

                                                                )
                                                            })}
                                                    </tbody>

                                                </table>

                                            </div>
                                        </div>


                                    </div>
                                </>
                                :
                                ""
                        } */}

                        <div className='row mt-3'>
                            <div className='col-sm-2 mt-1'>
                                <FormControlLabel style={{ fontSize: "15px", fontWeight: "600" }} control={<Checkbox checked={Boolean(cashReceipt)} onChange={HandleCashReceiptCheckbox} />} label="Cash Receipt" />
                            </div>

                            <div className='col-sm-7'>
                                {/* <TextField
                                    fullWidth
                                    autoComplete='off'
                                    size='small'
                                    id="outlined-basic"
                                    label="Description"
                                    variant="outlined"
                                    margin='dense'
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)}
                                    style={(cashReceipt == 1) ? { backgroundColor: "#dee2e6" } : { backgroundColor: "white" }}
                                    inputProps={{ readOnly: (cashReceipt == 1) ? true : false }}
                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                /> */}

                                <Autocomplete
                                    id="combo-box-demo"
                                    PaperComponent={({ children }) => (
                                        <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                    )}
                                    readOnly={(cashReceipt == 1) ? true : false}
                                    freeSolo
                                    disableClearable
                                    onKeyDown={(e) => (e.key === " " && description.length === 0) ? e.preventDefault() : ""}
                                    options={(description === "") ? [] : bankData}
                                    value={description}
                                    onChange={(event, newValue) => {
                                        setDescription(newValue.nameMr);
                                    }}
                                    inputValue={description}
                                    onInputChange={(event, newInputValue) => {
                                        setDescription(newInputValue);
                                    }}
                                    style={{ fontSize: '14px' }}
                                    getOptionLabel={option => {

                                        if (typeof option === 'string') {
                                            return option;
                                        }
                                        // Add "xxx" option created dynamically
                                        if (option.inputValue) {
                                            //console.log("option........"+JSON.stringify(option.inputValue))
                                            return option.nameMr;
                                        }
                                        // Regular option
                                        return option.nameMr;
                                    }

                                    }
                                    onKeyPress={(e) => (e.key == "Enter") ? description : ""}
                                    size="small"
                                    renderInput={params => (
                                        <TextField {...params} style={(cashReceipt == 1) ? { backgroundColor: "#dee2e6" } : { backgroundColor: "white" }} margin='dense' label="Description" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }}
                                            required={(cashReceipt == 0) ? true : false}
                                        />
                                    )}
                                />
                            </div>
                        </div>

                        <div className="col-sm-12 mt-3">
                            <div className="offset-sm-2">
                                <button type="submit" onClick={() => (state.button = 1)} disabled={saveBtnDisabled} className={saveBtn}>Save</button>
                                <button type="submit" onClick={() => (state.button = 2)} disabled={updateBtnDisabled} className={updateBtn} >Update</button>
                                <button type="button" onClick={ClearData} className="btn btn-danger btn-sm" style={{ margin: '0px 4px' }} >Cancel</button>
                            </div>
                        </div>
                    </form>
                </div>
                {/* <ToastContainer position="top-right" theme="colored" /> */}
            </>
        )
    }


}

export default FeeReceipt