import { BaseURL } from "../../../CommonServices/APIURL";

//Post
// http://192.168.29.102:8081/institute-sss/institute/sss/sansthaVoting/saveVoterKendraMaster

// {
//     "voterKendraNo": 2,
//     "voterKendraName": "voterKendraName",
//     "voterKendraAdd": "voterKendraAdd"
// }

// SAVED,  ALREADYEXISTS
export const saveVotersKendraMasterAPIURL = BaseURL + "sansthaVoting/saveVoterKendraMaster";

//http://192.168.29.102:8081/institute-sss/institute/sss/sansthaVoting/updateVoterKendraMaster
export const updateVotersKendraMasterAPIURL = BaseURL + "sansthaVoting/updateVoterKendraMaster";

//http://192.168.29.102:8081/institute-sss/institute/sss/sansthaVoting/getVoterKendraMasterList
export const getVotersKendraMasterAPIURL = BaseURL + "sansthaVoting/getVoterKendraMasterList";

//http://192.168.29.102:8081/institute-sss/institute/sss/sansthaVoting/deleteVoterKendraMaster?id=2
export const deleteVotersKendraMasterAPIURL = BaseURL + "sansthaVoting/deleteVoterKendraMaster";

