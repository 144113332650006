import React, { useState, useEffect } from 'react';
import TitleLabel from "../../CommonComponent/TitleLabel";
import axios from 'axios';
import Paper from "@mui/material/Paper";
import TextField from '@mui/material/TextField';
import Autocomplete from "@mui/material/Autocomplete";
import PrintIcon from '@mui/icons-material/Print';
import { ToastContainer, toast } from 'react-toastify';
import { useSelector } from "react-redux";
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Loader from '../../CommonComponent/Loader';
import makeStyles from "@mui/styles/makeStyles";
import { DatePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { getTypeMasteAPIURL } from '../Services/TypeMasteAPIURL';
import { printInvestmentReportAPIURL } from '../Services/InvestmentEntryAPIURL';
import { getExportedReportAPIURL } from '../../CommonServices/APIURL';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
    select: {
        maxHeight: 160,
        "& ul": {
            backgroundColor: "#F4ECF7",
            //maxHeight: 160,
        },
        "& li": {
            // backgroundColor: "#F4ECF7", fontWeight: "550", fontSize: "14px" 
            fontSize: "14px",
            fontWeight: "550"
        },
    },

}));

const InvestmentReport = () => {
    const classes = useStyles();
    const authUser = useSelector((state) => state.user.value);
    const titleId = "Investment Report";

    const [loaderOption, setLoaderOption] = useState(false);


    const [view, setView] = useState(1);
    const [option, setOption] = useState(1);
    const [subOption, setSubOption] = useState(1);
    const [typeMasteData, setTypeMasterData] = useState([]);
    const [typeMasterObj, setTypeMasterObj] = useState(null);
    const [dateFrom, setDateFrom] = useState(null);
    const [dateTo, setDateTo] = useState(null);

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        await axios.get(`${getTypeMasteAPIURL}`)
            .then((response) => {
                setTypeMasterData(response.data);
            })

    }

    const printInvestmentReport = async (e) => {
        e.preventDefault();
        if (moment((dateFrom == null) ? new Date() : dateFrom).format("DD/MM/YYYY") !== "Invalid date" && moment((dateTo == null) ? new Date() : dateTo).format("DD/MM/YYYY") !== "Invalid date") {
            setLoaderOption(true);
            // console.log(`${printInvestmentReportAPIURL}?sid=${authUser.deptId}&bid=${authUser.branchId}&fromDate=${(subOption == 1) ? null : moment(dateFrom).format("DD/MM/YYYY")}&toDate=${(subOption == 1) ? null : moment(dateTo).format("DD/MM/YYYY")}&langId=${authUser.branchMedium}&rptType=${view}&option=${option}&typeId=${option == 2 ? typeMasterObj.id : 0} &subOption=${subOption}`)
            await axios.get(`${printInvestmentReportAPIURL}?sid=${authUser.deptId}&bid=${authUser.branchId}&fromDate=${(subOption == 1) ? null : moment(dateFrom).format("DD/MM/YYYY")}&toDate=${(subOption == 1) ? null : moment(dateTo).format("DD/MM/YYYY")}&langId=${authUser.branchMedium}&rptType=${view}&option=${option}&typeId=${option == 2 ? typeMasterObj.id : 0} &subOption=${subOption}`)
                .then((response) => {

                    if (response.data == "NOTFOUND") {
                        setLoaderOption(false);
                        toast.warn("Data not found, please check.")
                    }
                    else if (response.data != null) {
                        //console.log(response.data)
                        setLoaderOption(false);
                        window.open(`${getExportedReportAPIURL}${response.data}`);
                    }

                })
                .catch(error => {
                    setLoaderOption(false);
                    toast.error("Error Occoured, Try after sometime.");
                    console.log("Receipt and chalan failed: " + error)
                })
            //toast.success("Valid")
        }
        else {
            setLoaderOption(false);
            toast.error("Improper date, please check");
        }
    }



    if (loaderOption == true) {
        return (
            <Loader />
        )
    }
    else {
        return (
            <>
                <TitleLabel titleId={titleId} />
                <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: '350px' }}>

                    <form onSubmit={printInvestmentReport}>
                        <div className="row my-2">
                            <label className="" style={{ width: '120px', fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>View :</label>
                            <div className='col-sm-3'>
                                <FormControl fullWidth margin='dense'>
                                    <InputLabel id='demo-simple-select-label' style={{ fontSize: '14px' }}>View</InputLabel>
                                    <Select
                                        MenuProps={{ classes: { paper: classes.select } }}
                                        size='small'
                                        required
                                        fullWidth

                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={view}
                                        label="View"
                                        onChange={(e) => {
                                            setView(e.target.value);
                                            setOption(1);
                                            setTypeMasterObj(null);
                                            setSubOption(1);
                                            setDateFrom(null);
                                            setDateTo(null);
                                        }}
                                    >
                                        <MenuItem value={1}>PDF</MenuItem>
                                        <MenuItem value={2}>Excel</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                        </div>

                        <div className='row my-2'>
                            <label className="" style={{ width: '120px', fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Option :</label>
                            <div className='col-sm-3'>
                                <FormControl fullWidth margin='dense'>
                                    <InputLabel id='demo-simple-select-label' style={{ fontSize: '14px' }}>Option</InputLabel>
                                    <Select
                                        MenuProps={{ classes: { paper: classes.select } }}
                                        size='small'
                                        required
                                        fullWidth

                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={option}
                                        label="Option"
                                        onChange={(e) => {
                                            setOption(e.target.value);
                                            setTypeMasterObj(null);
                                            setSubOption(1);
                                            setDateFrom(null);
                                            setDateTo(null);
                                        }}
                                    >
                                        <MenuItem value={1}>All</MenuItem>
                                        <MenuItem value={2}>Type Wise</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>

                            <label className="" style={{ width: '120px', fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Sub-Option :</label>
                            <div className='col-sm-3'>
                                <FormControl fullWidth margin='dense'>
                                    <InputLabel id='demo-simple-select-label' style={{ fontSize: '14px' }}>Sub-Option</InputLabel>
                                    <Select
                                        MenuProps={{ classes: { paper: classes.select } }}
                                        size='small'
                                        required
                                        fullWidth

                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={subOption}
                                        label="Sub-Option"
                                        onChange={(e) => {
                                            setSubOption(e.target.value);
                                            setTypeMasterObj(null);
                                            setDateFrom(null);
                                            setDateTo(null);
                                        }}
                                    >
                                        <MenuItem value={1}>All</MenuItem>
                                        <MenuItem value={2}>Pavati Date Wise</MenuItem>
                                        <MenuItem value={3}>Validity Date Wise</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                        </div>

                        {(() => {
                            if (option == 2) {
                                return (
                                    <>
                                        <div className="row my-2">
                                            <label className="" style={{ width: '120px', fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Type :</label>
                                            <div className='col-sm-3'>
                                                <Autocomplete
                                                    PaperComponent={({ children }) => (
                                                        <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                                    )}
                                                    id="combo-box-demo"
                                                    onChange={(e, newValue) => {
                                                        setTypeMasterObj(newValue);                                                        
                                                        setDateFrom(null);
                                                        setDateTo(null);
                                                    }}
                                                    value={typeMasterObj}
                                                    options={typeMasteData}
                                                    onKeyPress={(e) => (e.key == "Enter") ? typeMasterObj : ""}
                                                    style={{ fontSize: '14px' }}
                                                    getOptionLabel={option => (authUser.branchMedium == 1) ? option.nameMr : option.nameEn}
                                                    size="small"
                                                    renderInput={params => (
                                                        <TextField {...params} label="Select Type" margin='dense' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required={(option == 2) ? true : false} />
                                                    )}
                                                />
                                            </div>
                                        </div>
                                    </>
                                )
                            }
                        })()}

                        {(() => {
                            if (subOption != 1) {
                                return (
                                    <>
                                        <div className="row my-2">
                                            <label className="" style={{ width: '120px', fontSize: "14px", fontWeight: "600", marginTop: "16px" }}> Date From :</label>
                                            <div className='col-sm-3'>
                                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                    <DatePicker
                                                        label="From"
                                                        value={dateFrom}

                                                        inputFormat="dd/MM/yyyy"
                                                        onChange={(newValue) => {
                                                            setDateFrom(newValue);

                                                        }}
                                                        openTo="day"
                                                        // Show defaultMaxDate selected when the DatePicker is opened
                                                        // If dateFrom is not set, defaultMaxDate is used
                                                        onOpen={() => setDateFrom(dateFrom)}
                                                        renderInput={(params) =>
                                                            <TextField
                                                                {...params}
                                                                margin="dense"
                                                                variant="outlined"
                                                                InputLabelProps={{ style: { fontSize: 14 } }}
                                                                required={(subOption != 1) ? true : false}
                                                                fullWidth
                                                                autoComplete='off'
                                                                size="small" />}
                                                    />
                                                </LocalizationProvider>
                                                {/* <span style={dateCheck}><em style={{fontSize:"12px",color:"red",fontWeight:"600"}}>*Invalid Date Format</em></span> */}
                                            </div>

                                            <label className="" style={{ width: '120px', fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>To :</label>
                                            <div className='col-sm-3'>
                                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                    <DatePicker
                                                        label="To"
                                                        value={dateTo}

                                                        inputFormat="dd/MM/yyyy"
                                                        onChange={(newValue) => {
                                                            setDateTo(newValue);

                                                        }}

                                                        onOpen={() => setDateTo(dateTo)}
                                                        renderInput={(params) =>
                                                            <TextField
                                                                {...params}
                                                                margin="dense"
                                                                variant="outlined"
                                                                InputLabelProps={{ style: { fontSize: 14 } }}
                                                                required={(subOption != 1) ? true : false}
                                                                fullWidth
                                                                autoComplete='off'
                                                                size="small" />}
                                                    />
                                                </LocalizationProvider>
                                                {/* <span style={dateCheck}><em style={{fontSize:"12px",color:"red",fontWeight:"600"}}>*Invalid Date Format</em></span> */}
                                            </div>
                                        </div>
                                    </>
                                )
                            }
                        })()}

                        <div className="row mt-3">
                            <div className="col-sm-4" style={{ marginLeft: '120px' }}>
                                <button type="submit" className="btn btn-primary btn-sm" >Show</button>
                            </div>
                        </div>
                    </form>
                </div>
            </>
        )
    }
}

export default InvestmentReport
