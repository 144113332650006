import React, { useEffect, useState } from "react";
import axios from "axios";
import TitleLabel from "../../../CommonComponent/TitleLabel";
import { ToastContainer, toast } from 'react-toastify';
import { useSelector } from "react-redux";
import Loader from "../../../CommonComponent/Loader";
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from "react-router-dom";
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'
import TablePagination from '@mui/material/TablePagination'
import Tooltip from '@mui/material/Tooltip';
import makeStyles from "@mui/styles/makeStyles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Switch from '@mui/material/Switch';
import { deleteVotersKendraMasterAPIURL, getVotersKendraMasterAPIURL } from "../Services/VotersKendraMasterAPIURL";
import DeleteModalBox from "../../../CommonComponent/DeleteModalBox";

const label = { inputProps: { 'aria-label': 'Switch demo' } };

const useStyles = makeStyles({
    caption: {
        padding: 0,
        fontWeight: 600,
        fontSize: "12px"
    },
    toolbar: {
        fontSize: "12px",
        fontWeight: 600
    },
    selectLabel: {
        fontSize: "12px",
        fontWeight: 500,
        margin: "-10px",
        padding: "0"
    },
    select: {
        fontSize: "12px",
        margin: "0",
        padding: "0"
    },
    displayedRows: {
        fontSize: "12px",
        margin: "-22px",
        padding: "0"
    },
    menuItem: {
        fontSize: "12px",
        fontWeight: 500,
    }
});

const VotersKendraMasterTable = () => {
    const titleId = "Voters Kendra Master Table";
    const authUser = useSelector((state) => state.user.value);
    const navigate = useNavigate();
    const classes = useStyles();

    const [loaderOption, setLoaderOption] = useState(false);

    const [searchTerm, setSearchTerm] = useState("");
    const [isAscending, setAscending] = useState(false);
    const [sortedBy, setSortedBy] = useState(null);
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const onPageChange = (event, nextPage) => {
        setPage(nextPage);
    }
    const onRowsPerPageChange = (e) => {
        setRowsPerPage(parseInt(e.target.value));
        setPage(0);
    }

    const onSort = (key, isAction) => {
        if (!isAction) {
            setSortedBy(key)
            if (isAscending) setFilteredData([...data.sort((a, b) => a[key] < b[key] ? 1 : -1)])
            else setFilteredData([...data.sort((a, b) => a[key] > b[key] ? 1 : -1)])
            setAscending(!isAscending)
        }
    }

    useEffect(() => {

        getData();

    }, [authUser])


    const getData = async () => {
        // console.log(`${getAccessionRegisterDataAPIURL}?branchId=${authUser.branchId}`)
        await axios.get(`${getVotersKendraMasterAPIURL}`)
            .then(res => {
                setData(res.data);
                setFilteredData(res.data);
            })
    }

    const UpdateData = (item, EditFlag) => {
        navigate(`/Home/m1402F/${item}/${EditFlag}`)
    }

    const [deleteId, setDeleteId] = useState('');

    const DeleteData = async (id) => {
        setLoaderOption(true);
        await axios.delete(`${deleteVotersKendraMasterAPIURL}?id=${id}`)
            .then(res => {
                if (res.data == "DELETED") {
                    getData();
                    setLoaderOption(false);
                    toast.success("Deleted Successfully.")
                }
            })
    }

    useEffect(() => {
        setFilteredData([]);
        let tempFilteredData = data.filter((item) => (
            item.voterKendraNo.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.voterKendraName.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.voterKendraAdd.toLowerCase().includes(searchTerm.toLowerCase())
        ))
        setFilteredData(tempFilteredData);
    }, [searchTerm])

    const TableHeading = [
        { label: 'मतदान केंद्र नंबर', key: 'voterKendraNo' },
        { label: `मतदान केंद्राचे नाव`, key: 'voterKendraName' },
        { label: `मतदान केंद्राचा पत्ता`, key: 'voterKendraAdd' },
        { label: 'Edit', value: 'edit', isAction: true },
        { label: 'Delete', value: 'status', isAction: true }
    ];

    // Acc Status  1=Available,2-Issue,3-Binding, 4- Withdrwal,5-Donot Issue


    return (
        <>
            {
                (loaderOption == true)
                    ?
                    <Loader />
                    :
                    <div>
                        <TitleLabel titleId={titleId} />

                        <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: "300px" }}>
                            <div className=''>
                                <button className='btn btn-primary btn-sm mb-3' style={{ float: 'left' }} onClick={() => navigate(`/Home/m1402F/0/1`)} ><AddIcon fontSize="small" />New Voters Kendra</button>
                            </div>
                            <div className='row mt-2' style={{ float: 'right' }}>
                                <div>
                                    <input
                                        className='form-control form-control-sm'
                                        type="text"
                                        style={{ width: "250px", display: "block", float: "right", marginBottom: "6px", border: "1px solid #C2C1C1" }}
                                        placeholder="Search Here"
                                        onChange={(event) => { setFilteredData([]); setSearchTerm(event.target.value); setPage(0); }}
                                        value={searchTerm}
                                    />
                                </div>
                            </div>

                            <div className="mt-4">
                                <div className='table-responsive'>
                                    <table className="table table-bordered">
                                        <thead className="table-Default">
                                            <tr>
                                                {TableHeading.map((item, index) => {
                                                    return (
                                                        <th style={{ fontSize: "14px", fontWeight: "600" }} onClick={() => onSort(item.key, item.isAction)} key={index} scope="col">{`${item.label} ${sortedBy === item.key ? isAscending ? '↑' : '↓' : ''}`}</th>
                                                    )
                                                })}
                                            </tr>
                                        </thead>
                                        <tbody style={{ borderTop: "2px solid #464de4", borderBottom: "2px solid #464de4" }}>
                                            {filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                .reverse().map((item, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td style={{ fontSize: "15px", fontWeight: "500" }}>{item.voterKendraNo}</td>
                                                            <td style={{ fontSize: "15px", fontWeight: "500" }}>{item.voterKendraName}</td>
                                                            <td style={{ fontSize: "15px", fontWeight: "500" }}>{item.voterKendraAdd}</td>

                                                            <td>
                                                                <Tooltip title="Edit">
                                                                    <button className='btn btn-primary btn-sm mx-1 p-1'
                                                                        onClick={() => UpdateData(item.id, 2)} data-bs-toggle="tooltip"
                                                                    //</Tooltip>disabled={(authUser.roleId === 4 || authUser.roleId === 3) ? true : false}
                                                                    >
                                                                        <FontAwesomeIcon icon={faEdit} />
                                                                    </button>
                                                                </Tooltip>
                                                            </td>
                                                            <td>
                                                                <Tooltip title="Delete">
                                                                    <button className='btn btn-danger btn-sm mx-1 p-1'
                                                                        data-bs-toggle="modal" data-bs-target="#exampleModal"
                                                                        onClick={() => setDeleteId(item.id)}
                                                                    //disabled={(authUser.roleId === 4 || authUser.roleId === 3) ? true : false}   
                                                                    >
                                                                        <FontAwesomeIcon icon={faTrash} />
                                                                    </button>
                                                                </Tooltip>
                                                            </td>
                                                        </tr>
                                                    )
                                                })}
                                        </tbody>

                                        <tfoot>
                                            <tr>
                                                <TablePagination
                                                    style={{ padding: "0px" }}
                                                    rowsPerPageOptions={[10, 25, 50, 100]}
                                                    count={data.length}
                                                    rowsPerPage={rowsPerPage}
                                                    page={page}
                                                    onRowsPerPageChange={onRowsPerPageChange}
                                                    onPageChange={onPageChange}
                                                    labelRowsPerPage="Rows Per Page :"
                                                    classes={{ menuItem: classes.menuItem, displayedRows: classes.displayedRows, selectIcon: classes.selectIcon, toolbar: classes.toolbar, caption: classes.caption, selectLabel: classes.selectLabel, select: classes.select }}
                                                    backIconButtonProps={{ "aria-label": "Previous Page" }}
                                                    nextIconButtonProps={{ "aria-label": "Next Page" }} />
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
            }
            <DeleteModalBox deleteId={deleteId} DeleteData={DeleteData} />

            {/* <ToastContainer position="top-right" theme="colored" /> */}
        </>
    )
}


export default VotersKendraMasterTable
