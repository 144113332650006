import { BaseIP, BaseURL, BaseURLMaster } from "../../../CommonServices/APIURL";

export const getMemberCategoryMasterAPIURL = BaseURLMaster + "getMemberCategoryMasterList";

//getStateMasterList
export const getStateMasterAPIURL = BaseURLMaster + "getStateMasterList";


// /http://192.168.29.102:8081/institute-sss/institute/sss/sansthaVoting/saveMemberMaster
export const saveSansthaMemberMasterAPIURL = BaseURL + "sansthaVoting/saveMemberMaster";

export const updateSansthaMemberMasterAPIURL = BaseURL + "sansthaVoting/updateMemberMaster";

//http://192.168.29.102:8081/institute-sss/institute/sss/sansthaVoting/getMemberMasterList
export const getSansthaMemberMasterAPIURL = BaseURL + "sansthaVoting/getMemberMasterList";

// /http://192.168.29.102:8081/institute-sss/institute/sss/sansthaVoting/deleteMemberMaster?id=1
export const deleteSansthaMemberMasterAPIURL = BaseURL + "sansthaVoting/deleteMemberMaster";

//ip/sanstha/photo/photo.jpg
export const profileImgPath = BaseIP + "/sanstha/photo/";

// ip/sanstha/sign/singImg.jpg
export const signImgPath = BaseIP + "/sanstha/sign/";
