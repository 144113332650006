import { Autocomplete, Paper, TextField } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import TitleLabel from '../../CommonComponent/TitleLabel';
import CircularProgress from '@mui/material/CircularProgress';
import HostelAllocationTable from './HostelAllocationTable';
import Loader from '../../CommonComponent/Loader';
import { DatePicker, LocalizationProvider, TimePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { getLeaveListByStudAPIURL, getStudeReportingTableDataAPIURL, getStudentListForReportingAPIURL, saveStudentReportingAPIURL } from '../Services/StudReportingAPIURL';
import StudentReportingTable from './StudentReportingTable';
import moment from 'moment';

function sleep(delay = 0) {
    return new Promise((resolve) => {
        setTimeout(resolve, delay);
    });
}


const StudentReporting = () => {
    const titleId = 'Student Reporting';
    const authUser = useSelector((state) => state.user.value);
    const [loaderOption, setLoaderOption] = useState(false);

    const TableHeading = [
        { label: 'ID', key: 'id' },
        { label: `Student`, key: (authUser.branchMedium == 1) ? 'nameMr' : 'nameEn' },
        { label: `Leave`, key: 'buildingName' },
        { label: `Reporting Date`, key: 'roomName' },
        { label: `Time`, key: 'bedName' },
        { label: 'Delete', value: 'delete', isAction: true }];

    const [id, setId] = useState('');
    const [studentData, setStudentData] = useState([]);
    const [studentObj, setStudentObj] = useState(null);
    const [leaveData, setLeaveData] = useState([]);
    const [leaveObj, setLeaveObj] = useState(null);
    const [reportingDate, setReportingDate] = useState(null);
    const [reportingTime, setReportingTime] = useState(null);
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);

    const [time, setTime] = useState(null);
    const [times, setTimes] = useState(null);
    // console.log(time);


    const handleTimeChange = (newValue) => {
        setTime(newValue);
        if (newValue != null) {
            const hours = String(newValue.getHours()).padStart(2, "0");
            const minutes = String(newValue.getMinutes()).padStart(2, "0");
            const seconds = String(newValue.getSeconds()).padStart(2, "0");
            const times = `${hours}:${minutes}:${seconds}`;
            setTimes(times);
        }

    };

    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState([]);
    const loading = open && studentData.length === 0;

    useEffect(() => {
        let active = true;

        if (!loading) {
            return undefined;
        }

        (async () => {
            await sleep(1e3); // For demo purposes.

            if (active) {
                setOptions([...studentData]);
            }
        })();

        return () => {
            active = false;
        };
    }, [loading]);

    useEffect(() => {
        if (!open) {
            setOptions([]);
        }
    }, [open]);

    useEffect(() => {
        getData();
    }, [authUser])


    const getData = async () => {
        setLoaderOption(true);
        // console.log(`${getStudentListForReportingAPIURL}?year=${authUser.sessionYear}&bid=${authUser.branchId}`)
        await axios.get(`${getStudentListForReportingAPIURL}?year=${authUser.sessionYear}&bid=${authUser.branchId}`)
            .then(res => {
                setStudentData(res.data);
                setLoaderOption(false);
            })

        await axios.get(`${getStudeReportingTableDataAPIURL}?year=${authUser.sessionYear}&bid=${authUser.branchId}`)
            .then(res => {
                setData(res.data);
                setFilteredData(res.data);
                setLoaderOption(false);
            })
    }

    const getLeaveDataByStudent = async (regCode) => {
        setLeaveData([]);
        // console.log(`${getLeaveListByStudAPIURL}?regNo=${regCode}&year=${authUser.sessionYear}&bid=${authUser.branchId}`)
        await axios.get(`${getLeaveListByStudAPIURL}?regNo=${regCode}&year=${authUser.sessionYear}&bid=${authUser.branchId}`)
            .then(res => {
                setLeaveData(res.data);
                setLoaderOption(false);
            })
    }

    const saveStudentReporting = async (e) => {
        e.preventDefault();
        setLoaderOption(true);
        const saveObj = {
            "regNo": studentObj.regNo,
            "leaveId": leaveObj.id,
            "reportingDate": moment(reportingDate).format("YYYY-MM-DD"),
            "reportingTime": times,
            "sessionYear": authUser.sessionYear,
            "branchId": authUser.branchId,
            "studentLeaveId":leaveObj.studentLeaveId
        }
        // console.log(JSON.stringify(saveObj))
        await axios.post(saveStudentReportingAPIURL, saveObj)
            .then(res => {
                if (res.data == "SAVED") {
                    setLoaderOption(false);
                    toast.success("Saved successfully.");
                    getData();
                    clearData();
                }
                else {
                    setLoaderOption(false);
                    toast.error("Student reporting save failed.")
                }
            }).catch(err => {
                setLoaderOption(false);
                toast.error("Something went wrong, please check.")
            })

    }

    const clearData = () => {
        setStudentObj(null);
        setLeaveData([]);
        setLeaveObj(null);
        setReportingDate(null);
        setTimes(null);
        setTime(null);
    }


    return (
        <>
            {
                (loaderOption == true)
                    ?
                    <Loader />
                    :
                    <>
                        <TitleLabel titleId={titleId} />
                        <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: '350px' }}>

                            <div className="row">
                                <div className="col-sm-7">
                                    <p className='' style={{ backgroundColor: "#FA6969", borderRadius: "4px", fontWeight: "600", fontSize: "13px", margin: "4px", padding: "4px", color: "#fff", lineHeight: "1.4" }}>* Only leave student data will be display here.</p>
                                </div>
                            </div>
                            <form onSubmit={saveStudentReporting}>
                                <div className="form-data mt-1">
                                    <input type="hidden" value={id} onChange={(e) => setId(e.target.value)} name="" />

                                    <div className="row">
                                        <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Student :</label>
                                        <div className='col-sm-5'>
                                            <Autocomplete
                                                id="combo-box-demo"
                                                open={open}
                                                onOpen={() => {
                                                    setOpen(true);
                                                }}
                                                onClose={() => {
                                                    setOpen(false);
                                                }}
                                                loading={loading}
                                                options={studentData}
                                                PaperComponent={({ children }) => (
                                                    <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                                )}
                                                onChange={(event, newValue) => {
                                                    setStudentObj(newValue);
                                                    setLeaveObj(null);
                                                    setReportingDate(null);
                                                    setTime(null);
                                                    if (newValue != null) {
                                                        getLeaveDataByStudent(newValue.regNo);
                                                    }
                                                    else {
                                                        setLeaveData([]);
                                                    }
                                                }}

                                                onKeyPress={(e) => (e.key == "Enter") ? studentObj : ""}
                                                value={studentObj}
                                                style={{ fontSize: '14px' }}
                                                getOptionLabel={option => (authUser.branchMedium == 1) ? option.regNo + " - " + option.nameMr : option.regNo + " - " + option.nameEn}
                                                renderOption={(props, option) => (
                                                    <div className='' {...props}>
                                                        <div className=''>
                                                            {(authUser.branchMedium == 1) ? option.regNo + " - " + option.nameMr + "  " + option.classMr + "  " + option.divMr : option.regNo + " - " + option.nameEn + "  " + option.classEn + "  " + option.divEn}
                                                        </div>
                                                    </div>
                                                )}
                                                size="small"
                                                renderInput={params => (
                                                    <TextField {...params}
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            endAdornment: (
                                                                <React.Fragment>
                                                                    {loading ? <CircularProgress color="inherit" size={20} style={{ marginRight: "25px" }} /> : null}
                                                                    {params.InputProps.endAdornment}
                                                                </React.Fragment>
                                                            ),
                                                        }}
                                                        margin='dense' label="Select Student" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }}
                                                        required />
                                                )}
                                            />
                                        </div>
                                    </div>

                                    <div className="row">
                                        <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Leave :</label>
                                        <div className='col-sm-5'>
                                            <Autocomplete
                                                id="combo-box-demo"

                                                options={leaveData}
                                                PaperComponent={({ children }) => (
                                                    <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                                )}
                                                onChange={(event, newValue) => {
                                                    setLeaveObj(newValue);
                                                    setReportingDate(null);
                                                    setTime(null);
                                                }}

                                                onKeyPress={(e) => (e.key == "Enter") ? leaveObj : ""}
                                                value={leaveObj}
                                                style={{ fontSize: '14px' }}
                                                getOptionLabel={option => option.leaveName}
                                                // renderOption={(props, option) => (
                                                //     <div className='' {...props}>
                                                //         <div className=''>
                                                //             {(authUser.branchMedium == 1) ? option.regCode + " - " + option.studentNameMr + "  " + option.currentClassMr + "  " + option.currentDivisionMr : option.regCode + " - " + option.studentNameEn + "  " + option.currentClassEn + "  " + option.currentDivisionEn}
                                                //         </div>
                                                //     </div>
                                                // )}
                                                size="small"
                                                renderInput={params => (
                                                    <TextField {...params}

                                                        margin='dense' label="Select Leave" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }}
                                                        required />
                                                )}
                                            />
                                        </div>
                                    </div>

                                    <div className="row my-2">
                                        <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Reporting Date :</label>
                                        <div className='col-sm-2'>
                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                <DatePicker
                                                    label="Reporting Date"
                                                    value={reportingDate}
                                                    onChange={(newValue) => {
                                                        setReportingDate(newValue);
                                                    }}
                                                    onKeyPress={(e) => (e.key == "Enter") ? reportingDate : ""}
                                                    inputFormat="dd/MM/yyyy"
                                                    renderInput={(params) =>
                                                        <TextField
                                                            {...params}
                                                            margin='dense'
                                                            variant="outlined"
                                                            InputLabelProps={{ style: { fontSize: 14 } }}
                                                            required
                                                            fullWidth
                                                            autoComplete='off'
                                                            size="small" />}
                                                />
                                            </LocalizationProvider>
                                        </div>

                                        <label htmlFor="lastname3" className="col-sm-1" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>Time :</label>
                                        <div className="col-sm-2">
                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                <TimePicker
                                                    label="Time"
                                                    value={time}
                                                    onChange={handleTimeChange}
                                                    ampm={false} // Set ampm prop to false to remove AM/PM indicator
                                                    inputFormat="HH:mm"
                                                    renderInput={(params) =>
                                                        <TextField
                                                            size='small'
                                                            variant="outlined"
                                                            margin='dense'
                                                            InputLabelProps={{ style: { fontSize: 14 } }}
                                                            required
                                                            fullWidth
                                                            autoComplete='off'
                                                            {...params} />}

                                                />
                                            </LocalizationProvider>
                                        </div>
                                    </div>

                                    <div className="row mt-3">
                                        <div className="col-sm-12 offset-sm-2">
                                            <button type="submit" className='btn btn-sm btn-primary'>Save</button>
                                            <button type="button" className="btn btn-danger btn-sm" style={{ margin: '0px 4px' }} >Cancel</button>
                                        </div>
                                    </div>
                                </div>
                            </form>


                            <div className='mt-3'>
                                <StudentReportingTable
                                    TableHeading={TableHeading}
                                    data={data}
                                    setFilteredData={setFilteredData}
                                    filteredData={filteredData}
                                    getData={getData}
                                    setId={setId}
                                    studentData={studentData}
                                    setStudentObj={setStudentObj}
                                    clearData={clearData}
                                />
                            </div>
                        </div>


                    </>
            }
        </>
    )
}

export default StudentReporting
