import React, { useState, useEffect } from 'react';
import Paper from "@mui/material/Paper";
import Autocomplete from "@mui/material/Autocomplete";
import { ToastContainer, toast } from 'react-toastify';
import TitleLabel from "../../CommonComponent/TitleLabel";
import TextField from '@mui/material/TextField';
import { useSelector } from "react-redux";
import axios from 'axios';
import Loader from '../../CommonComponent/Loader';
import { getClassBySectionBranch } from '../../FeeManagement/Services/FeeReportAPIURL';
import subjectMarkTermData from '../../CommonComponent/LocalJSON/SubjectMarkTermData';
import { getExamData, getExamListForResultSheet, getSubjectData, getSubjectDataForSubMarkSheet } from '../Services/ExamReportAPIURL';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import makeStyles from "@mui/styles/makeStyles";
import {
    printTermWiseSubMeritList,
    printExamWiseSubMeritList,
    getsubjectListByClass
} from '../Services/StudentRankAPIURL';
import { getExportedReportAPIURL } from '../../CommonServices/APIURL';

const useStyles = makeStyles(theme => ({
    select: {
        maxHeight: 160,
        "& ul": {
            backgroundColor: "#F4ECF7",
            //maxHeight: 160,
        },
        "& li": {
            // backgroundColor: "#F4ECF7", fontWeight: "550", fontSize: "14px" 
            fontSize: "14px",
            fontWeight: "550"
        },
    },

}));

const SubMeritList = () => {
    const titleId = "Subject Merit List";
    const authUser = useSelector((state) => state.user.value);
    const classes = useStyles();

    const [loaderOption, setLoaderOption] = useState(false);

    const [classData, setClassData] = useState([]);
    const [classObj, setClassObj] = useState(null);
    const [examData, setExamData] = useState([]);
    const [examObj, setExamObj] = useState(null);
    const [term, setTerm] = useState('');
    const [rankCount, setRankCount] = useState('');
    const [reportOptionId, setReportOptionId] = useState(1);
    const [subjectData, setSubjectData] = useState([]);
    const [subjectObj, setSubjectObj] = useState(null);


    useEffect(() => {
        getData();
    }, [authUser])


    const getData = async () => {
        if (authUser.deptId != null && authUser.branchId != null) {
            await axios.get(`${getClassBySectionBranch}?sid=${authUser.deptId}&bid=${authUser.branchId}`)
                .then(res => {
                    setClassData(res.data);
                })
        }

        await axios.get(`${getExamData}`)
            .then(res => {
                setExamData(res.data);
            })
    }

    const getSubjects = async (cId) => {
        setSubjectData([]);

        // console.log(`${getsubjectListByClass}?sid=${authUser.deptId}&bid=${authUser.branchId}&acYear=${authUser.sessionYear}&classId=${cId}&branchMedium=${authUser.branchMedium}`)
        await axios(`${getsubjectListByClass}?sid=${authUser.deptId}&bid=${authUser.branchId}&acYear=${authUser.sessionYear}&classId=${cId}&branchMedium=${authUser.branchMedium}`)
            .then((response) => {
                //console.log(response.data);
                let filterMarkSubjects = response.data.filter(e => e.markOrGrade == 1)
                setSubjectData(filterMarkSubjects);

            }).catch((error) => {
                console.log(error);
            })
    }


    const getTermWiseSubMeritList = async () => {
        setLoaderOption(true);

        const saveObj = {
            "id": subjectObj.id,
            "subjectNameEn": subjectObj.subjectNameMr,
            "subjectNameMr": subjectObj.subjectNameEn,
            "viewType": subjectObj.viewType,
            "markOrGrade": subjectObj.markOrGrade
        }

        await axios.post(`${printTermWiseSubMeritList}?langId=${authUser.branchMedium}&classId=${classObj.id}&termId=${term}&rankCount=${(rankCount == '') ? 0 : rankCount}&sessionYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&medium=${authUser.branchMedium}`, saveObj)
            .then(res => {
                if (res.data == "NOTFOUND") {
                    setLoaderOption(false);
                    toast.warn("Data not found.");
                }
                else {
                    window.open(`${getExportedReportAPIURL}${res.data}`);
                    setLoaderOption(false);
                }
            }).catch(err => {
                console.log("Term wise sub merit list" + err);
                setLoaderOption(false);
                toast.error("something went wrong, please check.")
            })
    }

    const getExamWiseSubMeritList = async () => {
        setLoaderOption(true);

        const saveObj = {
            "id": subjectObj.id,
            "subjectNameEn": subjectObj.subjectNameMr,
            "subjectNameMr": subjectObj.subjectNameEn,
            "viewType": subjectObj.viewType,
            "markOrGrade": subjectObj.markOrGrade
        }

        await axios.post(`${printExamWiseSubMeritList}?langId=${authUser.branchMedium}&classId=${classObj.id}&termId=${term}&rankCount=${(rankCount == '') ? 0 : rankCount}&sessionYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&medium=${authUser.branchMedium}&examId=${(term == 3) ? 0 : examObj.id}`, saveObj)
            .then(res => {
                if (res.data == "NOTFOUND") {
                    setLoaderOption(false);
                    toast.warn("Data not found.");
                }
                else {
                    window.open(`${getExportedReportAPIURL}${res.data}`);
                    setLoaderOption(false);
                }
            }).catch(err => {
                console.log("Exam wise sub merit list" + err);
                setLoaderOption(false);
                toast.error("something went wrong, please check.")
            })
    }

    const getSubMeriList = (e) => {
        e.preventDefault();
        if (reportOptionId == 1) {
            getTermWiseSubMeritList();
        }
        else if (reportOptionId == 2) {
            getExamWiseSubMeritList();
        }
    }

    return (
        <>
            {
                (loaderOption == true)
                    ?
                    <Loader />
                    :
                    <>
                        <TitleLabel titleId={titleId} />
                        <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: "350px" }}>
                            <form onSubmit={getSubMeriList}>
                                <div className="row my-2">
                                    <label className='' style={{ width: "120px", fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Class :</label>
                                    <div className='col-sm-3'>
                                        <Autocomplete
                                            id="combo-box-demo"
                                            PaperComponent={({ children }) => (
                                                <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                            )}
                                            options={classData}
                                            style={{ fontSize: '14px' }}
                                            getOptionLabel={option => (authUser.branchMedium == 1) ? option.nameMr : option.nameEn}
                                            size="small"
                                            value={classObj}
                                            onChange={(event, newValue) => {
                                                setClassObj(newValue);
                                                setReportOptionId(1);
                                                setExamObj(null);
                                                setSubjectObj(null);
                                                setRankCount('');
                                                setTerm('');
                                                if (newValue != null) {
                                                    getSubjects(newValue.id)
                                                }
                                            }}
                                            renderInput={params => (
                                                <TextField margin='dense' {...params} label="Select Class" variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                            )}
                                        />
                                    </div>

                                    <label className="" style={{ width: "120px", fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Option :</label>
                                    <div className='col-sm-3'>
                                        <FormControl fullWidth margin='dense'>
                                            <InputLabel size="small" id='demo-simple-select-label' style={{ fontSize: '14px' }}>Select Option</InputLabel>
                                            <Select
                                                MenuProps={{ classes: { paper: classes.select } }}
                                                size='small'
                                                required
                                                fullWidth
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={reportOptionId}
                                                label="Select Option"
                                                onChange={(e) => {
                                                    setReportOptionId(e.target.value);
                                                    setExamObj(null);
                                                    setSubjectObj(null);
                                                    setRankCount('');
                                                    setTerm('');
                                                    if (classObj != null) {
                                                        getSubjects(classObj.id)
                                                    }
                                                }}
                                            >
                                                <MenuItem value={1}>Term Wise</MenuItem>
                                                <MenuItem value={2}>Exam Wise</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div>

                                <div className="row my-2">
                                    <label className="" style={{ width: "120px", fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Term :</label>
                                    <div className='col-sm-3'>
                                        <FormControl fullWidth margin='dense'>
                                            <InputLabel size="small" id='demo-simple-select-label' style={{ fontSize: '14px' }}>Select Term</InputLabel>
                                            <Select
                                                MenuProps={{ classes: { paper: classes.select } }}
                                                size='small'
                                                required
                                                fullWidth
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={term}
                                                label="Select Term"
                                                onChange={(e) => {
                                                    setTerm(e.target.value);
                                                    setExamObj(null);
                                                    setSubjectObj(null);
                                                    setRankCount('');
                                                    if (classObj != null) {
                                                        getSubjects(classObj.id)
                                                    }
                                                }}
                                            >
                                                {subjectMarkTermData.map((item, index) => {
                                                    return (
                                                        <MenuItem value={item.id} key={index}>{(authUser.branchMedium == 1) ? item.nameMr : item.nameEn}</MenuItem>
                                                    )
                                                })}
                                            </Select>
                                        </FormControl>
                                    </div>

                                    {(() => {
                                        if (reportOptionId == 2) {
                                            return (
                                                <>
                                                    {/* <div className="row my-2"> */}
                                                    <label className='' hidden={(term == 3) ? true : false} style={{ width: "120px", fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Exam :</label>
                                                    <div className='col-sm-3' hidden={(term == 3) ? true : false}>
                                                        <Autocomplete
                                                            id="combo-box-demo"
                                                            PaperComponent={({ children }) => (
                                                                <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                                            )}
                                                            options={examData}
                                                            style={{ fontSize: '14px' }}
                                                            getOptionLabel={option => (authUser.branchMedium == 1) ? option.nameMr : option.nameEn}
                                                            size="small"
                                                            value={examObj}
                                                            onChange={(event, newValue) => {
                                                                setExamObj(newValue);
                                                                setSubjectObj(null);
                                                                setRankCount('');
                                                                if (classObj != null) {
                                                                    getSubjects(classObj.id)
                                                                }
                                                            }}
                                                            renderInput={params => (
                                                                <TextField margin='dense' {...params} label="Select Exam" variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required={(reportOptionId == 2 && term != 3) ? true : false} />
                                                            )}
                                                        />
                                                    </div>
                                                    {/* </div> */}
                                                </>
                                            )
                                        }
                                        else if (reportOptionId == 1) {
                                            return (
                                                <>
                                                    <label className='' style={{ width: "120px", fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Subject :</label>
                                                    <div className='col-sm-3'>
                                                        <Autocomplete
                                                            id="combo-box-demo"
                                                            PaperComponent={({ children }) => (
                                                                <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                                            )}
                                                            options={subjectData}
                                                            style={{ fontSize: '14px' }}
                                                            getOptionLabel={option => (authUser.branchMedium == 1) ? option.subjectNameMr : option.subjectNameEn}
                                                            size="small"
                                                            value={subjectObj}
                                                            onChange={(event, newValue) => {
                                                                setSubjectObj(newValue);
                                                                setRankCount('');
                                                            }}
                                                            renderInput={params => (
                                                                <TextField margin='dense' {...params} label="Select Subject" variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required={(reportOptionId == 2) ? true : false} />
                                                            )}
                                                        />
                                                    </div>
                                                </>
                                            )
                                        }
                                    })()}
                                </div>

                                <div className="row my-2">

                                    <label hidden={(reportOptionId == 1) ? true : false} className='' style={{ width: "120px", fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Subject :</label>
                                    <div className='col-sm-3' hidden={(reportOptionId == 1) ? true : false}>
                                        <Autocomplete
                                            id="combo-box-demo"
                                            PaperComponent={({ children }) => (
                                                <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                            )}
                                            options={subjectData}
                                            style={{ fontSize: '14px' }}
                                            getOptionLabel={option => (authUser.branchMedium == 1) ? option.subjectNameMr : option.subjectNameEn}
                                            size="small"
                                            value={subjectObj}
                                            onChange={(event, newValue) => {
                                                setSubjectObj(newValue);
                                                setRankCount('');
                                            }}
                                            renderInput={params => (
                                                <TextField margin='dense' {...params} label="Select Subject" variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required={(reportOptionId == 2) ? true : false} />
                                            )}
                                        />
                                    </div>

                                    <label className='' style={{ width: "120px", fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Rank Count :</label>
                                    <div className='col-sm-3'>
                                        <TextField
                                            fullWidth
                                            type="number"
                                            autoComplete='off'
                                            size='small'
                                            id="outlined-basic"
                                            label="Rank Count"
                                            variant="outlined"
                                            margin='dense'
                                            onWheel={(e) => e.target.blur()}
                                            value={rankCount}
                                            onChange={(e) => setRankCount(e.target.value)}
                                            InputLabelProps={{ style: { fontSize: 14 } }}
                                            inputProps={{ maxLength: 120 }}
                                        />
                                    </div>
                                </div>

                                <div className="row mt-3">
                                    <div className="col-sm-4" style={{ marginLeft: "120px" }}>
                                        <button type='submit' className='btn btn-sm btn-primary'>Show</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </>
            }
        </>
    )
}

export default SubMeritList