const schoolEndowmentJSON = [
    {
        MenuType: "dropdown",
        PathName: "#",
        Title: "Master",
        LinkIcon: "dashboard",
        DropDownSubMenu: [
            {
                id: 1401,
                SubPath: "/Home/schoolEndow/",
                SubName: "School Endowment"
            },
            {
                id: 1402,
                SubPath: "/Home/sansthaEndow/",
                SubName: "Sanstha Endowment"
            },
        ]
    },
    {
        id: 721,
        MenuType: "normal",
        PathName: "/Home/se721/",
        Title: "Process Exam Data",
        LinkIcon: "menu_book"
    },
    {
        MenuType: "dropdown",
        PathName: "#",
        Title: "Transaction",
        LinkIcon: "account_balance_wallet",
        DropDownSubMenu: [
            {
                id: 722,
                SubPath: "/Home/et722/722",
                SubName: "Student To Prize Allocation"
            },
            {
                id: 723,
                SubPath: "/Home/et722/723",
                SubName: "Student To Sanstha Prize Allocation"
            },
        ]
    },
    {
        MenuType: "megamenu",
        PathName: "#",
        Title: "Reports",
        LinkIcon: "receipt_long",
        MegaMenuDropDown: [
            {
                HeaddingName: "Endowment Reports",
                MegaMenuSubItems: [
                    {
                        id: 705,
                        SubPath: "/Home/er705/",
                        SubName: "Endowment Transactions"
                    },
                    {
                        id: 712,
                        SubPath: "/Home/er712/",
                        SubName: "Classwise Endowment Report"
                    },
                    {
                        id: 713,
                        SubPath: "/Home/er713/",
                        SubName: "Subjectwise Endowment Report"
                    },
                    {
                        id: 714,
                        SubPath: "/Home/er714/",
                        SubName: "Genderwise Endowment Report"
                    },
                    {
                        id: 717,
                        SubPath: "/Home/er717/",
                        SubName: "Schoolwise Endowmentwise Detail Report"
                    },
                ]

            },
            {
                HeaddingName: " ",
                MegaMenuSubItems: [
                    {
                        id: 727,
                        SubPath: "/Home/er727/",
                        SubName: "Schoolwise Endowment List"
                    },
                    {
                        id: 709,
                        SubPath: "/Home/er709/",
                        SubName: "Sanstha Award Distribution"
                    },
                    {
                        id: 721,
                        SubPath: "/Home/er721/",
                        SubName: "StudentWise Award Distribution"
                    },
                    {
                        id: 723,
                        SubPath: "/Home/er723/",
                        SubName: "SchoolWise Award Distribution"
                    },
                    {
                        id: 722,
                        SubPath: "/Home/er722/",
                        SubName: "Allocated Prizes List"
                    },

                ]
            },
            {
                HeaddingName: " ",
                MegaMenuSubItems: [
                    {
                        id: 724,
                        SubPath: "/Home/er724/",
                        SubName: "Office Copy Report"
                    },
                    {
                        id: 725,
                        SubPath: "/Home/er725/",
                        SubName: "Donor's Copy Report"
                    },
                    {
                        id: 726,
                        SubPath: "/Home/er726/",
                        SubName: "Actual Copy Report"
                    },
                    {
                        id: 728,
                        SubPath: "/Home/er728/",
                        SubName: "Address Print Report"
                    },
                ]
            }

        ]
    },
]

export default schoolEndowmentJSON;