import React, { useState, useEffect } from 'react';
import makeStyles from "@mui/styles/makeStyles";
import TitleLabel from "../../CommonComponent/TitleLabel";
import axios from 'axios';
import AddIcon from '@mui/icons-material/Add';
import TablePagination from '@mui/material/TablePagination'
import { useNavigate } from 'react-router-dom';
import { useSelector } from "react-redux";
import Loader from '../../CommonComponent/Loader';
import { getAllEntranceExamMarksDataAPIURL } from '../Services/AdmFormEntranceAPIURL';

const useStyles = makeStyles({
    caption: {
        padding: 0,
        fontWeight: 600,
        fontSize: "12px"
    },
    toolbar: {
        fontSize: "12px",
        fontWeight: 600
    },
    selectLabel: {
        fontSize: "12px",
        fontWeight: 500,
        margin: "-10px",
        padding: "0"
    },
    select: {
        fontSize: "12px",
        margin: "0",
        padding: "0"
    },
    displayedRows: {
        fontSize: "12px",
        margin: "-22px",
        padding: "0"
    },
    menuItem: {
        fontSize: "12px",
        fontWeight: 500,
    }
});

const AdmissionEntranceTable = () => {
    const authUser = useSelector((state) => state.user.value);
    const titleId = "Admission Entrance Table";
    const classes = useStyles();

    const navigate = useNavigate();

    const [loaderOption, setLoaderOption] = useState(false)
    const [isAscending, setAscending] = useState(false);
    const [sortedBy, setSortedBy] = useState(null);
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState(data);
    const [searchTerm, setSearchTerm] = useState("");
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(50);

    const getTableData = async () => {
        setLoaderOption(true);
        // console.log(`${getAllEntranceExamMarksDataAPIURL}?acYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}`)
        await axios.get(`${getAllEntranceExamMarksDataAPIURL}?acYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}`)
            .then((res) => {
                if (res.data !== null) {
                    setData(res.data);
                    setFilteredData(res.data);
                    setLoaderOption(false);
                    //console.log(JSON.stringify(res.data))
                }
            })
            .catch((err) => {
                setLoaderOption(false);
                console.log(err);
            })
    }

    useEffect(() => {
        if (authUser.sessionYear !== null && authUser.deptId !== null && authUser.branchId !== null) {
            getTableData();
        }
    }, [authUser])

    const onPageChange = (event, nextPage) => {
        setPage(nextPage);
    }

    const onRowsPerPageChange = (e) => {
        setRowsPerPage(parseInt(e.target.value));
        setPage(0);
    }

    const onSort = (key, isAction) => {
        if (!isAction) {
            setSortedBy(key)
            if (isAscending) setFilteredData([...data.sort((a, b) => a[key].toString().toLowerCase() < b[key].toString().toLowerCase() ? 1 : -1)])
            else setFilteredData([...data.sort((a, b) => a[key].toString().toLowerCase() > b[key].toString().toLowerCase() ? 1 : -1)])
            setAscending(!isAscending)
        }
    }

    useEffect(() => {
        setFilteredData(
            data.filter((item) =>
                (item.firstNameMr || '').toLowerCase().includes(searchTerm.toLowerCase()) ||
                (item.firstNameEn || '').toLowerCase().includes(searchTerm.toLowerCase()) ||
                (item.middleNameMr || '').toLowerCase().includes(searchTerm.toLowerCase()) ||
                (item.middleNameEn || '').toLowerCase().includes(searchTerm.toLowerCase()) ||
                (item.lastNameMr || '').toLowerCase().includes(searchTerm.toLowerCase()) ||
                (item.lastNameEn || '').toLowerCase().includes(searchTerm.toLowerCase()) ||
                (item.formNo || '').toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
                (item.classNameMr || '').toLowerCase().includes(searchTerm.toLowerCase()) ||
                (item.classNameEn || '').toLowerCase().includes(searchTerm.toLowerCase()) ||
                (item.medicalStatusMr || '').toLowerCase().includes(searchTerm.toLowerCase()) ||
                (item.physicalStatusMr || '').toLowerCase().includes(searchTerm.toLowerCase()) ||
                (item.medicalStatusEn || '').toLowerCase().includes(searchTerm.toLowerCase()) ||
                (item.physicalStatusEn || '').toLowerCase().includes(searchTerm.toLowerCase()) ||
                (item.seatNo || '').toLowerCase().includes(searchTerm.toLowerCase()) ||
                (item.entranceMarks || '').toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
                (item.totalInterviewMark || '').toString().toLowerCase().includes(searchTerm.toLowerCase())
            ))
    }, [searchTerm])

    const TableHeading = [
        { label: 'Form No', key: 'formNo' },
        { label: 'Seat No', key: 'seatNo' },
        { label: `Student Name`, key: (authUser.branchMedium == 1) ? 'firstNameMr' : 'firstNameEn' },
        { label: `Class`, key: (authUser.branchMedium == 1) ? 'classNameMr' : 'classNameEn' },
        { label: `Written Marks`, key: 'entranceMarks' },
        { label: `Interview Marks`, key: 'totalInterviewMark' },
        { label: `Physical`, key: (authUser.branchMedium == 1) ? "physicalStatusMr" : "physicalStatusEn" },
        { label: `Medical`, key: (authUser.branchMedium == 1) ? "medicalStatusMr" : "medicalStatusEn" }
    ];

    return (
        <>
            {
                (loaderOption === true) ?
                    <Loader />
                    :
                    <div>
                        <TitleLabel titleId={titleId} />
                        <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: '350px' }}>
                            <div className=''>
                                <button className='btn btn-primary btn-sm mb-2' style={{ float: 'left' }} onClick={() => navigate("/Home/adm322F/")} ><AddIcon fontSize="small" />New Entrance</button>
                            </div>
                            <div className='row mb-1' style={{ float: 'right' }}>
                                <div>
                                    <input
                                        className='form-control form-control-sm'
                                        type="text"
                                        style={{ width: "250px", display: "block", float: "right", marginBottom: "6px", border: "1px solid #C2C1C1" }}
                                        placeholder="Search Here"
                                        onChange={(event) => { setSearchTerm(event.target.value); setPage(0); }}
                                        value={searchTerm}
                                    />
                                </div>
                            </div>

                            <div className='table-responsive'>
                                <table className="table table-bordered">
                                    <thead className="table-Default">
                                        <tr>
                                            {TableHeading.map((item, index) => {
                                                return (
                                                    <th style={{ fontSize: "14px", fontWeight: "600" }} onClick={() => onSort(item.key, item.isAction)} key={index} scope="col">{`${item.label} ${sortedBy === item.key ? isAscending ? '↑' : '↓' : ''}`}</th>
                                                )
                                            })}
                                        </tr>
                                    </thead>
                                    <tbody style={{ borderTop: "2px solid #464de4", borderBottom: "2px solid #464de4" }}>
                                        {filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            .map((item, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td style={{ fontSize: "15px", fontWeight: "500", width: '100px' }}>{item.formNo}</td>
                                                        <td style={{ fontSize: "15px", fontWeight: "500", width: '100px', textAlign: `${(item.seatNo == null) ? 'center' : ''}` }}>{(item.seatNo == null) ? ' - ' : item.seatNo}</td>
                                                        <td style={{ fontSize: "15px", fontWeight: "500", lineHeight: "1.5" }}>
                                                            {(authUser.branchMedium == 1) ? item.firstNameMr + " " + item.middleNameMr + " " + item.lastNameMr : item.firstNameEn + " " + item.middleNameEn + " " + item.lastNameEn}
                                                        </td>

                                                        <td style={{ fontSize: "15px", fontWeight: "500", width: '100px' }}>{(authUser.branchMedium == 1) ? item.classNameMr : item.classNameEn}</td>
                                                        <td style={{ fontSize: "15px", fontWeight: "500", width: '150px' }}>{item.entranceMarks}</td>
                                                        <td style={{ fontSize: "15px", fontWeight: "500", width: '150px' }}>{item.totalInterviewMark}</td>
                                                        <td style={{ fontSize: "15px", fontWeight: "500", width: '100px', textAlign: 'center' }}>
                                                            {(authUser.branchMedium == 1) ? ((item.physicalStatusMr == " ") ? ' - ' : item.physicalStatusMr) : ((item.physicalStatusMrEn == " ") ? ' - ' : item.physicalStatusMEn)}
                                                        </td>
                                                        <td style={{ fontSize: "15px", fontWeight: "500", width: '100px', textAlign: 'center' }}>
                                                            {(authUser.branchMedium == 1) ? ((item.medicalStatusMr == " ") ? ' - ' : item.medicalStatusMr) : ((item.medicalStatusEn == " ") ? ' - ' : item.medicalStatusEn)}
                                                        </td>

                                                    </tr>
                                                )
                                            })}
                                    </tbody>

                                    <tfoot>
                                        <tr>
                                            <TablePagination
                                                style={{ padding: "0px" }}
                                                rowsPerPageOptions={[10, 25, 50, 100]}
                                                count={data.length}
                                                rowsPerPage={rowsPerPage}
                                                page={page}
                                                onRowsPerPageChange={onRowsPerPageChange}
                                                onPageChange={onPageChange}
                                                labelRowsPerPage="Rows Per Page :"
                                                classes={{ menuItem: classes.menuItem, displayedRows: classes.displayedRows, selectIcon: classes.selectIcon, toolbar: classes.toolbar, caption: classes.caption, selectLabel: classes.selectLabel, select: classes.select }}
                                                backIconButtonProps={{ "aria-label": "Previous Page" }}
                                                nextIconButtonProps={{ "aria-label": "Next Page" }} />
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                        </div>

                    </div>
            }
        </>
    )
}

export default AdmissionEntranceTable