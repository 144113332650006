import React, { useState, useEffect } from 'react';
import TitleLabel from "../../../CommonComponent/TitleLabel";
import TextField from '@mui/material/TextField';
import Autocomplete from "@mui/material/Autocomplete";
import axios from "axios";
import Paper from "@mui/material/Paper";
import { useSelector } from "react-redux";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import { ToastContainer, toast } from 'react-toastify';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import Loader from "../../../CommonComponent/Loader";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useNavigate } from 'react-router-dom';
import {
    getPrizeCodeAPIURL, saveEndowmentMasterAPIURL, getEndowmentDataForUpdateAPIURL,
    updateEndowmentmasterAPIURL, getSchoolNameByEndowmentId, savePrizeDistributionData,
    getPrizeDistributionCondition, saveEndowmentAmountDetails, updateEndowmentAmountDetails,
    getEndowmentAmountDetails, deletePrizeDistributionData, deleteEndowmentAmountDetails, getSchoolListForEndowment
} from '../Services/EndowmentMasterAPIURL';
import { ClassMasterGetMapping } from '../../../CommonServices/ClassMasterAPIURL';
import { getSubjectData } from '../../../Exam/Services/ExamReportAPIURL';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import meritData from '../../../CommonComponent/MeritNoData';
import makeStyles from "@mui/styles/makeStyles";
import EditConditionsTextBox from './ConditionEditTextField/EditConditionsTextBox';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { getCityData } from '../../../CommonServices/InstituteMasterAPIURL';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import SchoolEndowmentTextBox from './SchoolEndowmentTextBox';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import CancelIcon from '@mui/icons-material/Cancel';
import EditIcon from '@mui/icons-material/Edit';
import { getSubjectEndowment } from '../Services/EndowmentSubjectAPIURL';
import Tooltip from '@mui/material/Tooltip';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { BaseURLSection } from '../../../CommonServices/InstituteMasterAPIURL';
import Checkbox from '@mui/material/Checkbox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { Modal, Button } from "react-bootstrap";
import { getRegionMasterAPI } from '../Services/RegionMasterAPIURL';
import { getRegionToSChoolAPI } from '../Services/RegionToSchoolAPIURL';
import { getDeptToSChoolAPI } from '../Services/DeptToSchoolAPIURL';


const useStyles = makeStyles(theme => ({
    select: {
        maxHeight: 160,
        "& ul": {
            backgroundColor: "#F4ECF7",
            //maxHeight: 160,
        },
        "& li": {
            // backgroundColor: "#F4ECF7", fontWeight: "550", fontSize: "14px" 
            fontSize: "14px",
            fontWeight: "550"
        },
    },
}));



const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;


const EndowmentMaster = () => {
    const classes = useStyles();

    const authUser = useSelector((state) => state.user.value);

    let myRef = {}

    let { eId, EditFlag } = useParams();

    const navigate = useNavigate();
    const titleId = "Endowment Register";

    // loader on/off
    const [loaderOption, setLoaderOption] = useState(false);

    //Note: This below states are declared for Save & Update(Disabled/unDisabled)
    const saveBtnStyle = "btn btn-primary btn-sm";
    const updateBtnStyle = "btn btn-primary btn-sm";
    const displayNoneBtnStyle = "d-none";
    const [saveBtn, setSaveBtn] = useState(saveBtnStyle);
    const [updateBtn, setUpdateBtn] = useState(displayNoneBtnStyle);

    const [saveBtnDisabled, setSaveBtnDisabled] = useState(false);
    const [updateBtnDisabled, setUpdateBtnDisabled] = useState(false);

    //Endowment register states
    const [id, setId] = useState("");
    const [prizeCode, setPrizeCode] = useState('');
    const [paritoshikName, setParitoshikName] = useState("");
    const [donatorName, setDonatorName] = useState("");
    const [donatorAddress, setDonatorAddress] = useState("");
    const [newSchooldata, setnewSchooldata] = useState([]);
    //console.log(newSchooldata)
    const [yojanaInfo, setYojanaInfo] = useState("");
    const [cityData, setCityData] = useState([]);
    const [cityObj, setCityObj] = useState(null);
    const [email, setEmail] = useState('');
    const [mobile, setmobile] = useState();
    const [amount, setAmount] = useState('');
    const [pavatiNo, setPavatiNo] = useState('');
    const [pavatidate, setPavatiDate] = useState(null);
    const [tharavNo, setTharavNo] = useState('');
    const [tharavdate, setTharavDate] = useState(null);
    const [yojanaYear, setYojanaYear] = useState('');
    const [validityDate, setValidityDate] = useState(null);
    const [prizeAmount, setPrizeAmount] = useState('');
    //console.log("pavatidate===" + pavatidate + "=====" + "tharavdate=====" + tharavdate)
    //console.log(error)

    //ctrl + shift + -->
    //Prize distribution states
    const [data, setData] = useState([]);
    const [condition, setCondition] = useState('');
    const [option, setOption] = useState(1);
    const [enableCondition, setEnableCondition] = useState(false);
    const [classData, setClassData] = useState([]);
    const [classObj, setClassObj] = useState(null);
    const [subjectData, setSubjectData] = useState([]);
    const [subjectObj, setSubjectObj] = useState(null);
    const [gender, setGender] = useState(1);
    const [schoolEndowmentNo, setSchoolEndowmentNo] = useState('');
    const [schoolName, setSchoolName] = useState("");
    const [sansthaInfo, setSansthaInfo] = useState("");
    const [meritObj, setMeritObj] = useState(null);
    const [schoolWiseAmount, setSchoolWiseAmount] = useState('');
    const [optionPrizeFor, setOptionPrizeFor] = useState(1);
    const [addNewSchool, setAddNewSchool] = useState(0);
    const [newData, setNewData] = useState([]);
    const [subjectType, setSubjectType] = useState('');
    const [schoolDataForEndow, setSchoolDataForEndow] = useState([]);
    const [schoolObjForEndow, setSchoolObjForEndow] = useState(null);
    const [prizeType, setPrizeType] = useState(1);
    const [allSchools, setAllSchools] = useState(1);
    const [selectedSchoolObj, setSelectedSchoolObj] = useState([]);
    const [selectedSchoolId, setSelectedSchoolId] = useState([]);
    const [endowSubdata, setEndowmentSubData] = useState([]);
    const [chkZeroAmount, setCheckZeroAmount] = useState(false);

    const [regionData, setRegionData] = useState([]);
    const [regionObj, setRegionObj] = useState(null);
    const [deptData, setDeptData] = useState([]);
    const [deptObj, setDeptObj] = useState(null);

    const [newUpdatedData, setNewUpdatedData] = useState([]);
    //console.log(chkZeroAmount)
    const [m1, setM1] = useState('');
    const [m2, setM2] = useState('');
    const [m3, setM3] = useState('');
    const [m4, setM4] = useState('');
    const [m5, setM5] = useState('');
    const [m6, setM6] = useState('');
    const [m7, setM7] = useState('');
    const [m8, setM8] = useState('');

    const [meritValues, setMeritValues] = useState([]);
    const [amountId, setAmountId] = useState('');
    const [tableData, setTableData] = useState([]);
    //console.log("tableData===" + JSON.stringify(tableData))
    const [tableId, setTableId] = useState('');
    const [schoolEndowmentData, setSchoolEndowmentData] = useState([]);
    //console.log(JSON.stringify(schoolEndowmentData))
    const [prizeId, setPrizeId] = useState('');
    const [etran, setEtran] = useState('');
    //console.log(prizeId + "------" + etran)
    const [percentageData, setPercentageData] = useState([]);

    //for amount details section
    const saveDisabled = "btn p-0 mt-3 ml-2";
    const updateDisabled = "btn p-0 mt-3 ml-2";
    const noneBtnStyle = "d-none";
    const [saveBtnAmt, setSaveBtnAmt] = useState(saveDisabled);
    const [updateBtnAmt, setUpdateBtnAmt] = useState(noneBtnStyle);




    //get school name by endowment id in prize distribution
    const getSchoolNameById = async (eId) => {
        //console.log(eId);
        if (eId <= 0) {
            setSchoolEndowmentNo("");
            toast.warn("School no should not be less than or equal to zero.")
        }
        else if (eId !== "") {
            axios.get(`${getSchoolNameByEndowmentId}?endowmentId=${eId}`)
                .then((response) => {
                    // console.log(response.data);
                    if (response.data == "") {
                        setSchoolName("");
                        toast.warn(`School endowment no ${eId} is not allocated to any school.`)
                    }
                    else {
                        setSchoolName(response.data);
                    }

                })
        }
    }

    //get amount details data
    const getTableData = async () => {
        //setTableData([]);
        clearAllValues();
        if (prizeAmount > 0 &&
            moment((pavatidate == null) ? new Date("9999-12-31") : pavatidate).format("DD/MM/YYYY") !== "Invalid date" &&
            moment((tharavdate == null) ? new Date() : tharavdate).format("DD/MM/YYYY") !== "Invalid date" &&
            moment((validityDate == null) ? new Date() : validityDate).format("DD/MM/YYYY") !== "Invalid date") {
            tableData.map((e, index) => { return setTableId(index) })
            const saveObj = {
                "id": tableId + 1,
                "prizeCode": prizeCode,
                "receiptNo": (pavatiNo === '') ? null : pavatiNo,
                "receiptDate": pavatidate == null ? new Date() : pavatidate,
                "tharavNo": (tharavNo === '') ? null : tharavNo,
                "tharavDate": (tharavdate == null) ? new Date() : tharavdate,
                "schemeStartYear": (yojanaYear === '') ? null : yojanaYear,
                "validityDate": (validityDate == null) ? new Date() : validityDate,
                "amount": prizeAmount
            }
            setTableData(tableData.concat(saveObj));
            let newdata = tableData.concat(saveObj)
            const final1 = newdata.reduce((prev, curr) => prev + parseFloat(curr.amount), 0);
            //console.log(final1);
            setAmount(parseFloat(final1).toFixed(2))
            // console.log("tableData===" + JSON.stringify(tableData))
            //setAfterData(tableData);
        }
        else {
            if (
                moment((pavatidate == null) ? new Date("9999-12-31") : pavatidate).format("DD/MM/YYYY") === "Invalid date" ||
                moment((tharavdate == null) ? new Date() : tharavdate).format("DD/MM/YYYY") === "Invalid date" ||
                moment((validityDate == null) ? new Date() : validityDate).format("DD/MM/YYYY") === "Invalid date") {
                //console.log("Date Is Invalid")  
                setLoaderOption(false);
                toast.error("Improper date, please check.")
            }
            else {
                setLoaderOption(false);
                toast.error("Amount should be grater than 0/Zero")
            }
        }
    }

    //for percentage details section
    const savePerDisabled = "btn p-0 mr-2 mt-2";
    const updatePerDisabled = "btn p-0 mr-2 mt-2";
    const nonePerBtnStyle = "d-none";
    const [saveBtnPer, setSaveBtnPer] = useState(savePerDisabled);
    const [updateBtnPer, setUpdateBtnPer] = useState(nonePerBtnStyle);

    //add percentage section data
    const addPercentageData = () => {
        clearPerSectionValues();
        const saveObj = {
            "classId": (classObj == null) ? 0 : classObj.id,
            "noOfStudent": meritObj.id,
            "subjectCategory": subjectType,
            "subjectId": subjectObj.id,
            "genderId": (optionPrizeFor == 2 || optionPrizeFor == 3) ? 0 : gender,
            "amountDivideInStudent": m1,
        }
        setPercentageData(percentageData.concat(saveObj))
    }

    //clear percentage section data
    const clearPerSectionValues = () => {
        setSubjectType('');
        setSubjectObj(null);
        setClassObj(null);
        //setSubjectData([]);
        setM1('');
        setMeritObj(null);
        setGender(1);
        setSaveBtnPer(savePerDisabled);
        setUpdateBtnPer(nonePerBtnStyle);
    }

    //set percentage data for updation
    const getDataForUpdate = (item) => {
        setSubjectType(item.subjectCategory);
        setSubjectObj(subjectData.find(e => e.id == item.subjectId))
        setMeritObj(meritData.find(e => e.id == item.noOfStudent));
        setClassObj(classData.find(e => e.id == item.classId))
        setM1(item.amountDivideInStudent);
        setGender(item.genderId);
        setSaveBtnPer(nonePerBtnStyle);
        setUpdateBtnPer(updatePerDisabled);
    }

    useEffect(() => {
        getData();
    }, [authUser, eId, EditFlag])

    const getData = async () => {
        if (EditFlag == 2) {
            getEditDetails();
            setSaveBtnDisabled(true);
            setUpdateBtnDisabled(false);
            setEnableCondition(true);
        }
        else if (EditFlag == 1) {
            getAPIData();
            setSaveBtnDisabled(false);
            setUpdateBtnDisabled(true);
        }
    }

    //get subjects by subject type
    const getSubjectListById = async (sId) => {
        if (sId == 1) {
            await axios.get(`${getSubjectData}`)
                .then((res) => {
                    setSubjectData(res.data);
                    //setSubjectObj(res.data[0])
                }).catch(error => {
                    console.log(error);
                })
        }
        else if (sId == 2) {
            await axios.get(`${getSubjectEndowment}`)
                .then((res) => {
                    setSubjectData(res.data);
                    //setSubjectObj(res.data[0])
                }).catch(error => {
                    console.log(error);
                })
        }
        else {
            setSubjectData([]);
        }
    }

    //get api data
    const getAPIData = async () => {

        await axios.get(getPrizeCodeAPIURL)
            .then(res => {
                setPrizeCode(res.data)
            }).catch(error => {
                console.log(error);
            })

        await axios.get(`${ClassMasterGetMapping}`)
            .then((res) => {
                setClassData(res.data);
            }).catch(error => {
                console.log(error);
            })

        await axios.get(getCityData)
            .then(res => {
                setCityData(res.data)
            }).catch(error => {
                console.log(error);
            })

        await axios.get(getSchoolListForEndowment)
            .then(res => {
                setSchoolDataForEndow(res.data)
            }).catch(error => {
                console.log(error);
            })

        await axios.get(getRegionToSChoolAPI)
            .then(res => {
                setRegionData(res.data)
            }).catch(error => {
                console.log(error);
            })

        await axios.get(getDeptToSChoolAPI)
            .then(res => {
                setDeptData(res.data)
            }).catch(error => {
                console.log(error);
            })
    }

    //get details for updation
    const getEditDetails = async () => {
        setLoaderOption(true);
        // console.log(`${getEndowmentDataForUpdateAPIURL}${eId}/${authUser.sessionYear}`)
        if (authUser.sessionYear != "") {
            axios.all([
                axios.get(`${getEndowmentDataForUpdateAPIURL}${eId}/${authUser.sessionYear}`)
            ]).then(
                axios.spread((respOne, index) => {
                    //console.log(JSON.stringify(respOne.data.prizeDistribution))
                    setSaveBtn(displayNoneBtnStyle);
                    setUpdateBtn(updateBtnStyle);

                    setId(respOne.data.endowmentRegister.id);
                    setPrizeCode(respOne.data.endowmentRegister.prizeCode);
                    setParitoshikName(respOne.data.endowmentRegister.prizeName);
                    setDonatorName(respOne.data.endowmentRegister.donorName);
                    setDonatorAddress(respOne.data.endowmentRegister.donorAddress);
                    setAmount(respOne.data.endowmentRegister.totalAmount);
                    setYojanaInfo(respOne.data.endowmentRegister.schemeDescription);
                    const selectedCity = respOne.data.endowmentRegister.city;
                    setmobile(respOne.data.endowmentRegister.mobileNo);
                    setEmail(respOne.data.endowmentRegister.email);
                    //console.log(selectedCity)                
                    //setSubjectType(respOne.data.subjectCategory);


                    setData(respOne.data.prizeDistribution);
                    // console.log(JSON.stringify(respOne.data.prizeDistribution))

                    // let updatedData = respOne.data.prizeDistribution
                    // respOne.data.prizeDistribution.map(e => {
                    //     if (e.branchType == 1 && e.forBranch == 0) {
                    //         setPrizeDescription(e.prizeDescription)
                    //     }
                    // })

                    respOne.data.prizeDistribution.map((e) => {
                        if (e.subjectCategory == 1) {
                            axios.get(`${getSubjectData}`)
                                .then((res) => {
                                    setSubjectData(res.data);
                                    //setSubjectObj(res.data[0])
                                }).catch(error => {
                                    console.log(error);
                                })
                        }
                        else if (e.subjectCategory == 2) {
                            axios.get(`${getSubjectEndowment}`)
                                .then((res) => {
                                    setEndowmentSubData(res.data);
                                    //setSubjectObj(res.data[0])
                                }).catch(error => {
                                    console.log(error);
                                })
                        }
                        else {
                            setSubjectData([]);
                            setEndowmentSubData([]);
                        }
                    })

                    setNewData(respOne.data.schoolData);

                    (respOne.data.prizeDistribution).map(e => {
                        setnewSchooldata(e.schoolDataList);
                    })



                    axios.get(`${ClassMasterGetMapping}`)
                        .then((res) => {
                            setClassData(res.data);
                        }).catch(error => {
                            console.log(error);
                        })


                    axios.get(getCityData)
                        .then(res => {
                            setCityData(res.data);
                            setCityObj((res.data).find(e => e.id == selectedCity))
                        }).catch(error => {
                            console.log(error);
                        })

                    axios.get(getSchoolListForEndowment)
                        .then(res => {
                            setSchoolDataForEndow(res.data)
                        }).catch(error => {
                            console.log(error);
                        })

                    axios.get(getRegionToSChoolAPI)
                        .then(res => {
                            setRegionData(res.data)
                        }).catch(error => {
                            console.log(error);
                        })

                    axios.get(getDeptToSChoolAPI)
                        .then(res => {
                            setDeptData(res.data)
                        }).catch(error => {
                            console.log(error);
                        })


                    axios.get(`${getEndowmentAmountDetails}?prizeCode=${respOne.data.endowmentRegister.prizeCode}`)
                        .then(res => {
                            setTableData(res.data);
                            const final1 = (res.data).reduce((prev, curr) => prev + parseFloat(curr.amount), 0);
                            //console.log(final1);
                            setAmount(parseFloat(final1).toFixed(2))
                        }).catch(error => {
                            console.log(error);
                        })

                    setLoaderOption(false);
                })
            )
        }
    }

    //save endowment
    const saveBtnClick = async () => {
        setLoaderOption(true);
        let updatedData = tableData
        tableData.map((e, index) => {
            updatedData[index] = ({
                ...e,
                "receiptDate": moment(e.receiptDate).format("DD/MM/YYYY"),
                "tharavDate": moment(e.tharavDate).format("DD/MM/YYYY"),
                "validityDate": moment(e.validityDate).format("DD/MM/YYYY")
            })
        })

        if (amount > 0) {
            const saveObj = {
                "endowmentMaster": {
                    "id": id,
                    "prizeCode": prizeCode,
                    "prizeName": paritoshikName,
                    "donorName": donatorName,
                    "donorAddress": donatorAddress,
                    "totalAmount": amount,
                    "schemeDescription": yojanaInfo,
                    "city": (cityObj == null) ? 0 : cityObj.id,
                    "mobileNo": mobile,
                    "email": email,
                },
                "masterDetails": updatedData
            }
            // console.log(JSON.stringify(saveObj))
            await axios.post(saveEndowmentMasterAPIURL, saveObj)
                .then((res) => {
                    if (res.data.message === "SAVED") {
                        // getData();
                        setSaveBtnDisabled(true);
                        setUpdateBtnDisabled(false)
                        //clearForm();
                        setEnableCondition(true);
                        setLoaderOption(false);
                        eId = res.data.endMaster.id;
                        navigate(`/Home/m701F/${eId}/2`);
                        getEditDetails();
                        toast.success("Endowment Created Successfully.");
                    }

                })
            //setLoaderOption(false);
            // toast.success("valid date")
        }
        else {
            setLoaderOption(false);
            toast.error("Amount should be grater than 0/Zero")
        }

    }

    //update endowment with conditions
    const updateBtnClick = async () => {
        setLoaderOption(true);
        //console.log(JSON.stringify(data));
        let updatedData = tableData
        tableData.map((e, index) => {
            updatedData[index] = ({
                ...e,
                "receiptDate": moment(e.receiptDate).format("DD/MM/YYYY"),
                "tharavDate": moment(e.tharavDate).format("DD/MM/YYYY"),
                "validityDate": moment(e.validityDate).format("DD/MM/YYYY")
            })
        })

        //console.log(data)

        //if (chkZeroAmount == false) {
        const updateObj = {

            "endowmentMaster": {
                "id": id,
                "prizeCode": prizeCode,
                "prizeName": paritoshikName,
                "donorName": donatorName,
                "donorAddress": donatorAddress,
                "totalAmount": amount,
                "schemeDescription": yojanaInfo,
                "city": (cityObj == null) ? 0 : cityObj.id,
                "mobileNo": mobile,
                "email": email,
            },
            "prizeDistribution": data,
            "schoolData": newData,
            "masterDetails": updatedData
        }
        // console.log(JSON.stringify(updateObj))

        // let totalAmtInPercent = Object.values(newUpdatedData).reduce((prev, next) => prev + parseFloat(next.amountInPercent), 0);
        // console.log("totalAmtInPercent=" + totalAmtInPercent)

        if (newUpdatedData <= 100) {
            await axios.put(updateEndowmentmasterAPIURL, updateObj)
                .then((res) => {

                    if (res.data === "SAVED") {
                        getData();
                        navigate("/Home/m701/0");
                        setLoaderOption(false);
                        toast.success("Endowment Updated Successfully.");
                    }

                })
            setLoaderOption(false);
        }
        else {
            // console.log("Amount should not be less than or equal to zero/0 or greater than 100. ")
            setLoaderOption(false);
            toast.error('Total amount in percent should not be greater than 100.')
        }


        // toast.success("valid date")
        // }
        // else if (chkZeroAmount == true) {
        //     setLoaderOption(false);
        //     //console.log("zerooooo")
        //     toast.error("Amount in percent should be grater than 0/Zero")
        // }
    }

    //clear condition form
    const clearForm = () => {
        setCondition(0);
        setAddNewSchool(0);
        setSchoolName('');
        setClassObj(null);
        setSubjectObj(null);
        setSchoolWiseAmount('');
        setSchoolEndowmentNo('');
        setMeritObj(null);
        setOption(1);
        setGender(1);
        setOptionPrizeFor(1);
        setSubjectType('');
        setPrizeType(1);
        setSchoolEndowmentData([]);
        setSansthaInfo('');
        setPercentageData([]);
        clearPerSectionValues();
        setSchoolObjForEndow(null);
    }

    //navigate to endowment table
    const ClearData = () => {
        navigate("/Home/m701/0")
        setSaveBtn(saveBtnStyle);
        setUpdateBtn(displayNoneBtnStyle);
    }

    //clear values from amount details section
    const clearAllValues = () => {
        setPavatiNo('');
        setPavatiDate(null);
        setTharavNo('');
        setTharavDate(null);
        setYojanaYear('');
        setValidityDate(null);
        setPrizeAmount('');
        setSaveBtnAmt(saveDisabled);
        setUpdateBtnAmt(noneBtnStyle);
    }

    //set amount details data for updation
    const updateData = (item) => {
        setPavatiNo(item.receiptNo);
        setPavatiDate(item.receiptDate);
        setTharavDate(item.tharavDate);
        setTharavNo(item.tharavNo);
        setYojanaYear(item.schemeStartYear);
        setValidityDate(item.validityDate);
        //console.log(item.tharavDate + "=======" + item.receiptDate + "========" + item.id);
        setPrizeAmount(item.amount);
        setAmountId(item.id);
        setSaveBtnAmt(noneBtnStyle);
        setUpdateBtnAmt(updateDisabled);
    }

    //update amount details

    const updateAmountData = async () => {
        setLoaderOption(true);

        if (prizeAmount > 0 &&
            moment((pavatidate == null) ? new Date("9999-12-31") : pavatidate).format("DD/MM/YYYY") !== "Invalid date" &&
            moment((tharavdate == null) ? new Date() : tharavdate).format("DD/MM/YYYY") !== "Invalid date" &&
            moment((validityDate == null) ? new Date() : validityDate).format("DD/MM/YYYY") !== "Invalid date") {
            const updateObj = {
                "id": amountId,
                "prizeCode": prizeCode,
                "receiptNo": (pavatiNo === '') ? null : pavatiNo,
                "receiptDate": (pavatidate == null) ? new Date() : moment(pavatidate).format("DD/MM/YYYY"),
                "tharavNo": (tharavNo === '') ? null : tharavNo,
                "tharavDate": (tharavdate == null) ? new Date() : moment(tharavdate).format("DD/MM/YYYY"),
                "schemeStartYear": (yojanaYear === '') ? null : yojanaYear,
                "validityDate": (validityDate == null) ? new Date() : moment(validityDate).format("DD/MM/YYYY"),
                "amount": prizeAmount
            }
            // console.log(JSON.stringify(updateObj))
            await axios.put(updateEndowmentAmountDetails, updateObj)
                .then((res) => {
                    //console.log(res.data);                    
                    if (res.data === "SAVED") {
                        getData();
                        setSaveBtnAmt(saveDisabled);
                        setUpdateBtnAmt(noneBtnStyle);
                        clearAllValues();
                        setLoaderOption(false);
                        //toast.success("Endowment Created Successfully.");
                    }

                })
            setLoaderOption(false);
            // toast.success("valid date")
        }
        else {
            if (
                moment((pavatidate == null) ? new Date("9999-12-31") : pavatidate).format("DD/MM/YYYY") === "Invalid date" ||
                moment((tharavdate == null) ? new Date() : tharavdate).format("DD/MM/YYYY") === "Invalid date" ||
                moment((validityDate == null) ? new Date() : validityDate).format("DD/MM/YYYY") === "Invalid date") {
                //console.log("Date Is Invalid")  
                setLoaderOption(false);
                toast.error("Improper date, please check.")
            }
            else {
                setLoaderOption(false);
                toast.error("Amount should be grater than 0/Zero")
            }
        }
    }

    //modal states
    const [modal, setModal] = useState(false);
    const [deleteAmtId, setDeleteAmtId] = useState('');

    const handleModal = (id) => {
        setDeleteAmtId(id);
        setModal(true);
    }

    //delete amount details
    const deleteAmtData = (id) => {
        axios.delete(`${deleteEndowmentAmountDetails}?id=${id}`)
            .then((response) => {
                if (response.data == "DELETED") {
                    getData();
                    setModal(false);
                    toast.success("Deleted successfully");
                }
            });
    }

    const state = {
        button: 1
    };

    //onSubmit Function
    const onSubmit = e => {
        e.preventDefault();
        if (state.button === 1) {
            saveBtnClick();
        }
        if (state.button === 2) {
            updateBtnClick();
        }
    };

    //check schoool is already allocated or not
    let res2 = []
    if (schoolEndowmentData.length != 0) {
        res2 = schoolDataForEndow.filter((el1) => {
            if (schoolEndowmentData.some((el2) => el2.branchId === el1.endowmentId) == false) {
                return el1
            }
        })
        //console.log(res2)
    }

    // console.log(schoolEndowmentNo)

    //add new school
    const saveSchoolData = () => {
        setSchoolObjForEndow(null);
        setSchoolWiseAmount("");

        let bId = '';
        let amtInPercent = '';
        if (option == 2 && prizeType == 2 && allSchools == 2) {
            bId = 0
            amtInPercent = 100
        }
        else if (option == 2 && prizeType == 1) {
            bId = 0
            amtInPercent = 100
        }
        else {
            amtInPercent = schoolWiseAmount
            bId = schoolObjForEndow.endowmentId
        }

        // console.log(schoolObjForEndow.endowmentId)

        const saveObj = {
            "prizeCode": prizeCode,
            "branchId": bId,
            "prizeFor": optionPrizeFor,
            "amountInPercent": amtInPercent,
            "prizeFrom": option,
            "prizeDescription": (sansthaInfo == "") ? null : sansthaInfo,
            "schoolNameByBranchId": schoolObjForEndow.schoolName
        }
        // console.log(JSON.stringify(saveObj))
        let totalAmtInPercent = schoolEndowmentData.concat(saveObj).reduce((prev, next) => prev + parseFloat(next.amountInPercent), 0);
        // console.log("totalAmtInPercent=" + totalAmtInPercent)

        if (totalAmtInPercent <= 100) {
            setSchoolEndowmentData([]);

            setSchoolEndowmentData(schoolEndowmentData.concat(saveObj))
        }
        else {
            // console.log("Amount should not be less than or equal to zero/0 or greater than 100. ")
            toast.error('Total amount in percent should not be greater than 100.')
        }
    }

    //save prize distribution conditions
    const savePrizeDistribution = async (e) => {
        e.preventDefault();
        let totalMarks = [];
        if (m1 == '' && m2 == '' && m3 == '' && m4 == '' && m5 == '' && m6 == '' && m7 == '' && m8 == '') {
            totalMarks = 100
        }
        else if (m2 == '' && m3 == '' && m4 == '' && m5 == '' && m6 == '' && m7 == '' && m8 == '') {
            totalMarks = [m1].join(',')
        }
        else if (m3 == '' && m4 == '' && m5 == '' && m6 == '' && m7 == '' && m8 == '') {
            totalMarks = [m1, m2].join(',')
        }
        else if (m4 == '' && m5 == '' && m6 == '' && m7 == '' && m8 == '') {
            totalMarks = [m1, m2, m3].join(',')
        }
        else if (m5 == '' && m6 == '' && m7 == '' && m8 == '') {
            totalMarks = [m1, m2, m3, m4].join(',')
        }
        else if (m6 == '' && m7 == '' && m8 == '') {
            totalMarks = [m1, m2, m3, m4, m5].join(',')
        }
        else if (m7 == '' && m8 == '') {
            totalMarks = [m1, m2, m3, m4, m5, m6].join(',')
        }       
        else if (m8 == '') {
            totalMarks = [m1, m2, m3, m4, m5, m6, m7].join(',')
        }
        else {
            totalMarks = [m1, m2, m3, m4, m5, m6, m7].join(',')
        }

        //console.log(totalMarks)

        let bId = '';
        let amtInPercent = '';
        if (option == 2 && prizeType == 2 && allSchools == 2) {
            bId = 0
            amtInPercent = schoolWiseAmount
        }
        else if (option == 2 && prizeType == 1) {
            bId = 0
            amtInPercent = 100
        }
        else {
            amtInPercent = schoolWiseAmount
            bId = schoolEndowmentNo
        }

        let regDeptId;
        if (option == 2) {
            if (prizeType == 3) {
                regDeptId = regionObj.id;
            }
            else if (prizeType == 4) {
                regDeptId = deptObj.departmentId;
            }
        }
        else {
            regDeptId = 0;
        }

        // console.log(schoolEndowmentNo)

        const saveObject = {
            "prizeCode": prizeCode,
            "branchId": (regDeptId != 0) ? 0 : bId,
            "prizeFor": optionPrizeFor,
            "branchType": (option == 1) ? 0 : prizeType,
            "amountInPercent": amtInPercent,
            "noOfStudent": meritObj.id,
            "prizeFrom": option,
            "prizeDescription": (sansthaInfo == "") ? null : sansthaInfo,
            "classId": (classObj == null) ? 0 : classObj.id,
            "subjectCategory": subjectType,
            "subjectId": subjectObj.id,
            "genderId": (optionPrizeFor == 2 || optionPrizeFor == 3) ? 0 : gender,
            "amountDivideInStudent": totalMarks,
            "forBranch": (regDeptId != 0) ? 2 : ((prizeType == 1) ? 0 : allSchools),
            "schoolDataList": (option == 2 && prizeType == 2 && allSchools == 2) ? selectedSchoolObj : [],
            "regDeptId": regDeptId
        }

        let updatedData = schoolEndowmentData
        schoolEndowmentData.map((e, index) => {
            updatedData[index] = ({
                ...e,
                "branchId": (regDeptId != 0) ? 0 : e.branchId,
                "noOfStudent": meritObj.id,
                "amountDivideInStudent": totalMarks,
                "branchType": (option == 1) ? 0 : prizeType,
                "classId": (classObj == null) ? 0 : classObj.id,
                "subjectCategory": subjectType,
                "subjectId": subjectObj.id,
                "forBranch": (regDeptId != 0) ? 2 : ((prizeType == 1) ? 0 : allSchools),
                "genderId": (optionPrizeFor == 2 || optionPrizeFor == 3) ? 0 : gender,
                "schoolDataList": (option == 2 && prizeType == 2 && allSchools == 2) ? selectedSchoolObj : [],
                "regDeptId": regDeptId
            })
        })

        let saveObj = [];
        if (option == 1 || (option == 2 && prizeType == 2 && allSchools == 1)) {
            saveObj = updatedData
        }
        else if ((option == 2 && prizeType == 1) || (option == 2 && prizeType == 2 && allSchools == 2)) {
            saveObj = [];
        }
        else {
            saveObj = updatedData.concat(saveObject)
        }

        // console.log(JSON.stringify(saveObj))

        let addConditions = {}

        {
            (saveObj.length == 0)
                ?
                addConditions = {
                    "prizeDistribution": [saveObject],
                }
                :
                addConditions = {
                    "prizeDistribution": [saveObject],
                    "schoolData": saveObj
                }
        }
        // console.log(JSON.stringify(addConditions))
        await axios.post(savePrizeDistributionData, addConditions)
            .then(res => {
                if (res.data == "SAVED") {
                    getData();
                    clearForm();
                    // navigate("/Home/m701/0")
                    toast.success("Prize Distribution done successfully.");
                    setLoaderOption(false);
                }
            }).catch(error => {
                console.log(error);
            })

        // setLoaderOption(false);
        //toast.success("valid")

    }

    //remove school data by index
    const removeInputFields = (index) => {
        // console.log(index);
        setSchoolEndowmentData([]);
        const rows = [...schoolEndowmentData];
        rows.splice(index, 1);
        setSchoolEndowmentData(rows);
        // console.log(JSON.stringify(rows))
    }

    //delete conditions
    const DeleteData = () => {
        //console.log(`${deletePrizeDistributionData}?etranNo=${etran}&prizeCode=${prizeId}`)
        axios.delete(`${deletePrizeDistributionData}?etranNo=${etran}&prizeCode=${prizeId}`)
            .then((response) => {
                if (response.data == "DELETED") {
                    getData();
                    toast.success("Condition deleted successfully");
                }
            });
    }

    return (
        <>
            {(loaderOption == true)
                ?
                <Loader />
                :
                <div>
                    <TitleLabel titleId={titleId} />
                    <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 0px 2px grey", marginTop: "16px", minHeight: "500px" }}>
                        {/* Endowment form starts */}
                        <form onSubmit={onSubmit}>

                            <input type="hidden" value={id} onChange={(e) => setId(e.target.value)} name="" />

                            <div className="row my-2">
                                <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >खाते क्र. :</label>
                                <div className='col-sm-2'>
                                    <TextField
                                        //required
                                        fullWidth
                                        type="number"
                                        autoComplete='off'
                                        size='small'
                                        id="outlined-basic"
                                        label="खाते क्र."
                                        value={prizeCode}
                                        onChange={(e) => setPrizeCode(e.target.value)}
                                        variant="outlined"
                                        margin='dense'
                                        InputLabelProps={{ style: { fontSize: 14 } }}
                                        inputProps={{ readOnly: EditFlag == 2 ? true : false }}
                                    />
                                </div>
                            </div>

                            <div className="row my-2">
                                <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "550", marginTop: "16px" }}>पारितोषिकाचे नाव:</label>
                                <div className='col-sm-6'>
                                    <TextField
                                        required
                                        fullWidth
                                        autoComplete='off'
                                        size='small'
                                        id="outlined-basic"
                                        label="पारितोषिकाचे नाव"
                                        value={paritoshikName}
                                        onChange={(e) => setParitoshikName(e.target.value)}
                                        variant="outlined"
                                        margin='dense'
                                        InputLabelProps={{ style: { fontSize: 14 } }}
                                    />
                                </div>
                            </div>

                            <div className="row my-2">
                                <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "550", marginTop: "16px" }}>देणगीदाराचे नाव:</label>
                                <div className='col-sm-6'>
                                    <TextField
                                        //required
                                        fullWidth
                                        autoComplete='off'
                                        size='small'
                                        id="outlined-basic"
                                        label="देणगीदाराचे नाव"
                                        value={donatorName}
                                        onChange={(e) => setDonatorName(e.target.value)}
                                        variant="outlined"
                                        margin='dense'
                                        InputLabelProps={{ style: { fontSize: 14 } }}
                                    />
                                </div>
                            </div>

                            <div className="row my-2">
                                <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "550", marginTop: "16px" }}>पत्ता :</label>
                                <div className='col-sm-6'>
                                    <TextField
                                        //required
                                        fullWidth
                                        multiline
                                        rows={2}
                                        autoComplete='off'
                                        size='small'
                                        id="outlined-basic"
                                        label="पत्ता"
                                        value={donatorAddress}
                                        onChange={(e) => setDonatorAddress(e.target.value)}
                                        variant="outlined"
                                        margin='dense'
                                        InputLabelProps={{ style: { fontSize: 14 } }}
                                    />
                                </div>
                            </div>

                            <div className="row my-2">
                                <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>शहर :</label>
                                <div className='col-sm-3'>
                                    <Autocomplete
                                        id="combo-box-demo"
                                        options={cityData}
                                        PaperComponent={({ children }) => (
                                            <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                        )}
                                        value={cityObj}
                                        onChange={(event, newValue) => {
                                            setCityObj(newValue);
                                        }}
                                        style={{ fontSize: '14px' }}
                                        getOptionLabel={option => (authUser.branchMedium == 1) ? option.nameMr : option.nameEn}
                                        size="small"
                                        renderInput={params => (
                                            <TextField {...params} margin='dense' label="शहर" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} />
                                        )}
                                    />
                                </div>
                            </div>

                            <div className="row my-2">
                                <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Mobile No :</label>
                                <div className='col-sm-3'>
                                    <TextField
                                        fullWidth
                                        onKeyDown={(e) => (e.key === " " && mobile.length === 0) ? e.preventDefault() : ""}
                                        autoComplete='off'
                                        type="number"
                                        size='small'
                                        id="outlined-basic"
                                        label="Mobile No"
                                        variant="outlined"
                                        margin='dense'
                                        value={mobile}
                                        onChange={(e) => {
                                            setmobile(e.target.value);
                                        }}
                                        InputLabelProps={{ style: { fontSize: 14 } }}
                                        onInput={(e) => { e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 12) }}
                                    />
                                </div>

                                <label className="col-sm-1" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Email Id :</label>
                                <div className='col-sm-3'>
                                    <ValidatorForm>
                                        <TextValidator
                                            fullWidth
                                            onKeyDown={(e) => (e.key === " " && email.length === 0) ? e.preventDefault() : ""}
                                            label="Email"
                                            autoComplete='off'
                                            size='small'
                                            margin='dense'
                                            onChange={(e) => {
                                                setEmail(e.target.value);
                                            }}
                                            name="email"
                                            value={email}
                                            variant="outlined"
                                            InputLabelProps={{ style: { fontSize: 14 } }}
                                            inputProps={{ maxLength: 120 }}
                                            validators={['isEmail']}
                                            errorMessages={['email is not valid']}
                                        />
                                    </ValidatorForm>
                                </div>
                            </div>

                            <div className="row py-2 mb-4 mt-4" style={{ backgroundColor: "#F5EEF8", border: "1px solid #BEBEBE" }}>
                                {/* <form> */}
                                <div className="mt-3">
                                    <TextField
                                        //required
                                        fullWidth
                                        autoComplete='off'
                                        size='small'
                                        style={{ width: "100px", marginLeft: "15px", backgroundColor: "#fff" }}
                                        id="outlined-basic"
                                        label="पावती क्र."
                                        value={pavatiNo}
                                        onChange={(e) => setPavatiNo(e.target.value)}
                                        onWheel={(e) => e.target.blur()}
                                        variant="outlined"
                                        margin='dense'
                                        InputLabelProps={{ style: { fontSize: 14 } }}
                                    />

                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DatePicker
                                            label="पावती दिनांक"
                                            inputFormat="dd/MM/yyyy"
                                            value={pavatidate}
                                            onChange={(newValue) => {
                                                if (newValue === null) {
                                                    setPavatiDate(new Date());
                                                }
                                                else if (newValue !== null) {
                                                    setPavatiDate(newValue);
                                                }

                                            }}
                                            disableOpenPicker
                                            renderInput={(params) =>
                                                <TextField
                                                    {...params}
                                                    margin='dense'
                                                    variant="outlined"
                                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                                    //required
                                                    style={{ width: "130px", marginLeft: "15px", backgroundColor: "#fff" }}
                                                    fullWidth
                                                    autoComplete='off'
                                                    size="small" />}
                                        />
                                    </LocalizationProvider>

                                    <TextField
                                        //required
                                        fullWidth
                                        autoComplete='off'
                                        size='small'
                                        id="outlined-basic"
                                        label="ठराव क्र."
                                        style={{ width: "100px", marginLeft: "15px", backgroundColor: "#fff" }}
                                        value={tharavNo}
                                        onChange={(e) => setTharavNo(e.target.value)}
                                        onWheel={(e) => e.target.blur()}
                                        variant="outlined"
                                        margin='dense'
                                        InputLabelProps={{ style: { fontSize: 14 } }}
                                    />

                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DatePicker
                                            label="ठराव दिनांक"
                                            inputFormat="dd/MM/yyyy"
                                            value={tharavdate}
                                            onChange={(newValue) => {
                                                if (newValue === null) {
                                                    setTharavDate(new Date());
                                                }
                                                else if (newValue !== null) {
                                                    setTharavDate(newValue);
                                                }

                                            }}
                                            disableOpenPicker
                                            renderInput={(params) =>
                                                <TextField
                                                    {...params}
                                                    margin='dense'
                                                    variant="outlined"
                                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                                    //required
                                                    style={{ width: "130px", marginLeft: "15px", backgroundColor: "#fff" }}
                                                    fullWidth
                                                    autoComplete='off'
                                                    size="small" />}
                                        />
                                    </LocalizationProvider>

                                    <TextField
                                        //required
                                        fullWidth
                                        autoComplete='off'
                                        size='small'
                                        id="outlined-basic"
                                        label="योजना सुरुवातीचे वर्ष"
                                        style={{ width: "120px", marginLeft: "15px", backgroundColor: "#fff" }}
                                        value={yojanaYear}
                                        onChange={(e) => setYojanaYear(e.target.value)}
                                        variant="outlined"
                                        margin='dense'
                                        InputLabelProps={{ style: { fontSize: 14 } }}
                                    />

                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DatePicker
                                            label="वैधता दिनांक"
                                            inputFormat="dd/MM/yyyy"
                                            value={validityDate}
                                            onChange={(newValue) => {
                                                if (newValue === null) {
                                                    setValidityDate(new Date());
                                                }
                                                else if (newValue !== null) {
                                                    setValidityDate(newValue);
                                                }

                                            }}
                                            renderInput={(params) =>
                                                <TextField
                                                    {...params}
                                                    margin='dense'
                                                    variant="outlined"
                                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                                    style={{ width: "150px", marginLeft: "15px", backgroundColor: "#fff" }}
                                                    //required
                                                    fullWidth
                                                    autoComplete='off'
                                                    size="small" />}
                                        />
                                    </LocalizationProvider>

                                    <TextField
                                        required={(tableData.length == 0) ? true : false}
                                        fullWidth
                                        type="number"
                                        autoComplete='off'
                                        size='small'
                                        id="outlined-basic"
                                        label="रक्कम"
                                        style={{ width: "150px", marginLeft: "15px", backgroundColor: "#fff" }}
                                        value={prizeAmount}
                                        onChange={(e) => setPrizeAmount(parseFloat(e.target.value))}
                                        onWheel={(e) => e.target.blur()}
                                        variant="outlined"
                                        margin='dense'
                                        InputLabelProps={{ style: { fontSize: 14 } }}
                                    />

                                    <button type='button' disabled={(prizeAmount == '') ? true : false} onClick={() => { getTableData(); }} className={saveBtnAmt}>
                                        <AddCircleIcon fontSize='medium' style={{ color: "#1F883C" }} />
                                    </button>
                                    <button type='button' disabled={(prizeAmount == '') ? true : false} onClick={() => updateAmountData()} className={updateBtnAmt}>
                                        <CheckCircleIcon fontSize='medium' style={{ color: "#1F883C" }} />
                                    </button>
                                    <button type="button" className='btn p-0 mt-3' onClick={() => clearAllValues()}>
                                        <CancelIcon fontSize='medium' style={{ color: "#FA4A3F" }} />
                                    </button>
                                </div>

                                <div className="table-responsive mt-4 mb-2" style={{ maxHeight: "300px" }}>
                                    <table className="table">
                                        <thead className=''>
                                            <tr>
                                                <th style={{ fontSize: "14px", fontWeight: "600", lineHeight: "1.4", minWidth: "20px" }}>पावती क्र.</th>
                                                <th style={{ fontSize: "14px", fontWeight: "600", lineHeight: "1.4", minWidth: "20px" }}>पावती दिनांक</th>
                                                <th style={{ fontSize: "14px", fontWeight: "600", lineHeight: "1.4", minWidth: "20px" }}>ठराव क्र.</th>
                                                <th style={{ fontSize: "14px", fontWeight: "600", lineHeight: "1.4", minWidth: "20px" }}>ठराव दिनांक</th>
                                                <th style={{ fontSize: "14px", fontWeight: "600", lineHeight: "1.4", minWidth: "20px" }}>योजना सुरुवातीचे वर्ष</th>
                                                <th style={{ fontSize: "14px", fontWeight: "600", lineHeight: "1.4", minWidth: "20px" }}>वैधता दिनांक</th>
                                                <th style={{ fontSize: "14px", fontWeight: "600", lineHeight: "1.4", minWidth: "20px" }}>रक्कम</th>
                                                <th hidden={(EditFlag == 1) ? true : false} style={{ fontSize: "14px", fontWeight: "600", minWidth: "20px" }}>Edit</th>
                                                <th style={{ fontSize: "14px", fontWeight: "600", lineHeight: "1.4", minWidth: "20px" }}>Delete</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {(() => {
                                                if (tableData.length != 0) {
                                                    return (
                                                        tableData.map((item, index) => {
                                                            //console.log(item.validityDate)
                                                            return (
                                                                <tr key={index}>
                                                                    <td style={{ fontSize: "15px", fontWeight: "500" }}>{item.receiptNo}</td>
                                                                    <td style={{ fontSize: "15px", fontWeight: "500" }}>{(item.receiptDate == "Invalid date") ? moment(new Date()).format("DD/MM/YYYY") : moment(item.receiptDate).format("DD/MM/YYYY")}</td>
                                                                    <td style={{ fontSize: "15px", fontWeight: "500" }}>{item.tharavNo}</td>
                                                                    <td style={{ fontSize: "15px", fontWeight: "500" }}>{(item.tharavDate == "Invalid date") ? moment(new Date()).format("DD/MM/YYYY") : moment(item.tharavDate).format("DD/MM/YYYY")}</td>
                                                                    <td style={{ fontSize: "15px", fontWeight: "500" }}><div style={{ textAlign: "center" }}>{item.schemeStartYear}</div></td>
                                                                    <td style={{ fontSize: "15px", fontWeight: "500" }}>{(item.validityDate == "Invalid date") ? moment(new Date()).format("DD/MM/YYYY") : moment(item.validityDate).format("DD/MM/YYYY")}</td>
                                                                    <td style={{ fontSize: "15px", fontWeight: "500" }}>{item.amount}</td>
                                                                    <td hidden={(EditFlag == 1) ? true : false}>
                                                                        <Tooltip title="Edit">
                                                                            <button type='button' className='btn btn-sm' onClick={() => updateData(item)}>
                                                                                <EditIcon fontSize='medium' style={{ color: '#587FF8' }} />
                                                                            </button>
                                                                        </Tooltip>
                                                                    </td>
                                                                    <td >
                                                                        <Tooltip title="Delete">
                                                                            <button type='button' className='btn btn-sm' onClick={() => handleModal(item.id)}>
                                                                                <DeleteIcon fontSize='medium' style={{ color: '#D73016' }} />
                                                                            </button>
                                                                        </Tooltip>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })
                                                    )
                                                }
                                            })()}

                                        </tbody>
                                    </table>
                                </div>
                                {/* </form> */}
                            </div>

                            <div className="row my-2">
                                <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >एकूण रक्कम :</label>
                                <div className='col-sm-3'>
                                    <TextField
                                        required
                                        fullWidth
                                        //type="number"
                                        autoComplete='off'
                                        size='small'
                                        id="outlined-basic"
                                        label="रक्कम"
                                        variant="outlined"
                                        margin='dense'
                                        value={amount}
                                        onChange={(e) => setAmount(parseFloat(e.target.value).toFixed(2))}
                                        inputProps={{ readOnly: true }}
                                        InputLabelProps={{ style: { fontSize: 14 } }}
                                    />
                                </div>
                            </div>

                            <div className="row my-2">
                                <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "550", marginTop: "16px" }}>योजनेची माहिती :</label>
                                <div className='col-sm-7'>
                                    <TextField
                                        required
                                        fullWidth
                                        multiline
                                        rows={3}
                                        autoComplete='off'
                                        size='small'
                                        id="outlined-basic"
                                        label="योजनेची माहिती"
                                        value={yojanaInfo}
                                        onChange={(e) => setYojanaInfo(e.target.value)}
                                        variant="outlined"
                                        margin='dense'
                                        InputLabelProps={{ style: { fontSize: 14 } }}
                                    />
                                </div>
                            </div>

                            {/* get condition on edit */}
                            {(() => {
                                if (EditFlag == 2 && data.length !== 0) {
                                    //console.log("updatedData=====" + JSON.stringify(data));

                                    return (
                                        <>
                                            {
                                                data.map((item, index) => {
                                                    {
                                                        // const region = regionData.find(e => e.regionId == item.regDeptId);
                                                        // const regionName = region ? region.regionName : null;
                                                        return (
                                                            <EditConditionsTextBox
                                                                classData={classData}
                                                                meritData={meritData}
                                                                subjectData={subjectData}
                                                                schoolDataForEndow={schoolDataForEndow}
                                                                initialText1={item.prizeFrom}
                                                                initialText2={item.prizeFor}
                                                                initialText3={item.branchId}
                                                                initialText4={item.amountInPercent}
                                                                initialText5={item.classId}
                                                                initialText6={item.noOfStudent}
                                                                initialText7={item.subjectId}
                                                                initialText8={item.genderId}
                                                                initialText9={item.schoolNameMr}
                                                                initialText10={(authUser.branchMedium == 1) ? item.classNameMr : item.classNameEn}
                                                                initialText11={(authUser.branchMedium == 1) ? item.subjectNameMr : item.subjectNameEn}
                                                                initialText12={meritData.find(e => e.id == item.noOfStudent)}
                                                                initialText13={item.etranNo}
                                                                initialText14={item.prizeCode}
                                                                initialText15={item.subjectCategory}
                                                                initialText16={item.amountDivideInStudent}
                                                                initialText17={item.prizeDescription}
                                                                initialText18={item.branchType}
                                                                initialText19={item.forBranch}
                                                                initialText20={item.regDeptId}
                                                                initialText21={item.regionName}
                                                                initialText22={(authUser.branchMedium == 1) ? item.deptNameMr : item.deptNameEn}
                                                                setUpdateBtnDisabled={setUpdateBtnDisabled}
                                                                classes={classes}
                                                                i={item.id}
                                                                item={item}
                                                                myRef={myRef}
                                                                setAddNewSchool={setAddNewSchool}
                                                                saveSchoolData={saveSchoolData}
                                                                addNewSchool={addNewSchool}
                                                                schoolEndowmentData={schoolEndowmentData}
                                                                data={data}
                                                                newData={newData}
                                                                setNewData={setNewData}
                                                                setData={setData}
                                                                getData={getData}
                                                                setSubjectData={setSubjectData}
                                                                getSubjectData={getSubjectData}
                                                                getSubjectEndowment={getSubjectEndowment}
                                                                setEndowmentSubData={setEndowmentSubData}
                                                                endowSubdata={endowSubdata}
                                                                setPrizeId={setPrizeId}
                                                                setEtran={setEtran}
                                                                newSchooldata={item.schoolDataList}
                                                                setCheckZeroAmount={setCheckZeroAmount}
                                                                setSchoolEndowmentData={setSchoolEndowmentData}
                                                                setNewUpdatedData={setNewUpdatedData}
                                                                regionData={regionData}
                                                                deptData={deptData}
                                                                onChange={(value1, value2, value3,
                                                                    value4, value5, value6, value7, value8,
                                                                    value9, value10, value11, value12, value13, value14, value15, value16, value17, value18) => {
                                                                    let newRegDeptId;
                                                                    let amtInPer;

                                                                    // console.log(value12 + "" + value16 + "" + value17)
                                                                    if (value1 == 2) {
                                                                        if (value12 == 3) {
                                                                            newRegDeptId = value16
                                                                            // console.log(regionData.find(e => e.regionId == initialText20))
                                                                        }
                                                                        else if (value12 == 4) {
                                                                            newRegDeptId = value17
                                                                            // console.log(deptData.find(e => e.departmentId == initialText20))
                                                                        }
                                                                    }

                                                                    if (value1 == 2) {
                                                                        if (value12 == 3 || value12 == 4) {
                                                                            amtInPer = value18
                                                                            // console.log(regionData.find(e => e.regionId == initialText20))
                                                                        }
                                                                        else if (value1 == 2 && value12 == 2 && value13 == 2) {
                                                                            amtInPer = value18;
                                                                        }
                                                                    }
                                                                    else {
                                                                        if (value1 == 2 && value12 == 1) {
                                                                            amtInPer = 100;
                                                                        }
                                                                        else {
                                                                            amtInPer = value4;
                                                                        }
                                                                    }

                                                                    let updatedData = data;
                                                                    // console.log(value15)
                                                                    updatedData[index] = ({
                                                                        ...item,
                                                                        prizeFrom: value1,
                                                                        prizeFor: value2,
                                                                        branchId: ((value1 == 2 && value12 == 1)) ? 0 : value3,
                                                                        amountInPercent: amtInPer,
                                                                        classId: value5,
                                                                        noOfStudent: value6,
                                                                        subjectId: value7,
                                                                        subjectCategory: value9,
                                                                        genderId: value8,
                                                                        amountDivideInStudent: ((value10 == "") ? "100" : value10),
                                                                        prizeDescription: value11,
                                                                        branchType: (value1 == 1) ? 0 : value12,
                                                                        forBranch: ((value1 == 2 && value12 == 1) || value1 == 1) ? 0 : value13,
                                                                        schoolDataList: value15,
                                                                        regDeptId: newRegDeptId
                                                                    });
                                                                    setData(updatedData);
                                                                    // console.log(value16)
                                                                    // console.log(JSON.stringify(updatedData));
                                                                }}
                                                            />
                                                        )
                                                    }
                                                })
                                            }
                                        </>
                                    )
                                }
                            })()}


                            <div className="row mt-4">
                                <div className="col-sm-12 offset-sm-2">
                                    <button type="submit" onClick={() => (state.button = 1)} disabled={saveBtnDisabled} className={saveBtn} style={{ margin: '0px 4px' }}>Save</button>
                                    <button type="submit" onClick={() => (state.button = 2)} disabled={updateBtnDisabled} className={updateBtn} style={{ margin: '0px 4px' }}>Update</button>
                                    <button type="button" onClick={ClearData} className="btn btn-danger btn-sm" style={{ margin: '0px 4px' }} >Cancel</button>
                                    <button type="button" disabled={(enableCondition == false) ? true : false} onClick={() => { setCondition(1); }} className="btn btn-sm" style={{ margin: '0px 4px', backgroundColor: "#5CAB4C", color: "#fff" }}>Add Condition</button>
                                </div>
                            </div>
                        </form>
                        {/* Endowment form ends */}


                        {(() => {
                            if (condition == 1) {
                                return (
                                    <>
                                        <div className="row py-2 mb-2 mt-4" style={{ backgroundColor: "#FBFBDB", border: "1px solid #922B21" }}>

                                            {/* prize distribution form starts */}
                                            <form onSubmit={savePrizeDistribution}>
                                                <div className="row my-3">
                                                    <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >बक्षिस कोणाकडे :</label>
                                                    <div className='col-sm-3'>
                                                        <FormControl fullWidth margin="dense">
                                                            <InputLabel id='demo-simple-select-label' size="small" style={{ fontSize: '14px' }}>बक्षिस कोणाकडे</InputLabel>
                                                            <Select
                                                                MenuProps={{ classes: { paper: classes.select } }}
                                                                size='small'
                                                                fullWidth
                                                                labelId="demo-simple-select-label"
                                                                id="demo-simple-select"
                                                                value={option}
                                                                label="बक्षिस कोणाकडे"
                                                                onChange={(e) => {
                                                                    setOption(e.target.value);
                                                                    setOptionPrizeFor(1);
                                                                    setSchoolEndowmentNo('');
                                                                    setSchoolObjForEndow(null);
                                                                    setSchoolName("");
                                                                    setSchoolWiseAmount('');
                                                                    setClassObj(null);
                                                                    setSubjectObj(null);
                                                                    setMeritObj(null);
                                                                    setSchoolEndowmentData([]);
                                                                    setPercentageData([]);
                                                                    setPrizeType(1);
                                                                    setRegionObj(null);
                                                                    setDeptObj(null);
                                                                    if (optionPrizeFor == 1) {
                                                                        setGender(1);
                                                                    }
                                                                }}
                                                            >
                                                                <MenuItem value={1}>School</MenuItem>
                                                                <MenuItem value={2}>Sanstha</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </div>
                                                </div>

                                                <div className="row my-3">
                                                    <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >बक्षिस कोणाला :</label>
                                                    <div className='col-sm-3'>
                                                        <FormControl fullWidth margin="dense">
                                                            <InputLabel id='demo-simple-select-label' size="small" style={{ fontSize: '14px' }}>बक्षिस कोणाला</InputLabel>
                                                            <Select
                                                                MenuProps={{ classes: { paper: classes.select } }}
                                                                size='small'
                                                                fullWidth
                                                                labelId="demo-simple-select-label"
                                                                id="demo-simple-select"
                                                                value={optionPrizeFor}
                                                                label="बक्षिस कोणाला"
                                                                onChange={(e) => {
                                                                    setOptionPrizeFor(e.target.value);
                                                                    setSchoolEndowmentNo('');
                                                                    setSchoolObjForEndow(null);
                                                                    setSchoolName("");
                                                                    setSchoolWiseAmount('');
                                                                    setClassObj(null);
                                                                    setSubjectObj(null);
                                                                    setMeritObj(null);
                                                                    setSchoolEndowmentData([]);
                                                                    setPercentageData([]);
                                                                    setPrizeType(1);
                                                                    setRegionObj(null);
                                                                    setDeptObj(null);
                                                                    if (optionPrizeFor == 1) {
                                                                        setGender(1);
                                                                    }
                                                                }}
                                                            >
                                                                <MenuItem value={1}>Student</MenuItem>
                                                                <MenuItem value={2}>Teacher</MenuItem>
                                                                <MenuItem hidden={(option == 1) ? true : false} value={3}>Institute</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </div>
                                                </div>

                                                {(() => {
                                                    if (option == 2) {
                                                        return (
                                                            <>
                                                                <div className="row my-2">
                                                                    <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >बक्षिस वितरण :</label>
                                                                    <div className='col-sm-4'>
                                                                        <FormControl fullWidth margin="dense">
                                                                            <InputLabel id='demo-simple-select-label' size="small" style={{ fontSize: '14px' }}>बक्षिस वितरण</InputLabel>
                                                                            <Select
                                                                                MenuProps={{ classes: { paper: classes.select } }}
                                                                                size='small'
                                                                                fullWidth
                                                                                labelId="demo-simple-select-label"
                                                                                id="demo-simple-select"
                                                                                value={prizeType}
                                                                                label="बक्षिस वितरण"
                                                                                onChange={(e) => {
                                                                                    setPrizeType(e.target.value);
                                                                                    setSchoolEndowmentNo('');
                                                                                    setSchoolObjForEndow(null);
                                                                                    setSchoolName("");
                                                                                    setSchoolWiseAmount('');
                                                                                    setClassObj(null);
                                                                                    setSubjectObj(null);
                                                                                    setMeritObj(null);
                                                                                    setSchoolEndowmentData([]);
                                                                                    setPercentageData([]);
                                                                                    setAllSchools(1);
                                                                                    setSelectedSchoolObj([]);
                                                                                    setRegionObj(null);
                                                                                    setDeptObj(null);
                                                                                    if (optionPrizeFor == 1) {
                                                                                        setGender(1);
                                                                                    }
                                                                                }}
                                                                            >
                                                                                <MenuItem value={1}>All Schools</MenuItem>
                                                                                {/* <MenuItem value={2}>Department Wise</MenuItem> */}
                                                                                <MenuItem value={2}>School And Department Wise</MenuItem>
                                                                                <MenuItem value={3}>Region Wise</MenuItem>
                                                                                <MenuItem value={4}>Department Wise</MenuItem>
                                                                            </Select>
                                                                        </FormControl>
                                                                    </div>


                                                                    {(() => {
                                                                        if (prizeType == 2) {
                                                                            return (
                                                                                <>

                                                                                    {/* <label className="col-sm-2 mt-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >बक्षिस वितरण :</label> */}
                                                                                    <div className='col-sm-3'>
                                                                                        <FormControl fullWidth margin="dense">
                                                                                            <InputLabel id='demo-simple-select-label' size="small" style={{ fontSize: '14px' }}>बक्षिसाचे वाटप</InputLabel>
                                                                                            <Select
                                                                                                MenuProps={{ classes: { paper: classes.select } }}
                                                                                                size='small'
                                                                                                fullWidth
                                                                                                labelId="demo-simple-select-label"
                                                                                                id="demo-simple-select"
                                                                                                value={allSchools}
                                                                                                label="बक्षिसाचे वाटप"
                                                                                                onChange={(e) => {
                                                                                                    setAllSchools(e.target.value);
                                                                                                    setSchoolEndowmentNo('');
                                                                                                    setSchoolObjForEndow(null);
                                                                                                    setSchoolName("");
                                                                                                    setSchoolWiseAmount('');
                                                                                                    setClassObj(null);
                                                                                                    setSubjectObj(null);
                                                                                                    setMeritObj(null);
                                                                                                    setSchoolEndowmentData([]);
                                                                                                    setPercentageData([]);
                                                                                                    setSelectedSchoolObj([]);
                                                                                                    setRegionObj(null);
                                                                                                    setDeptObj(null);
                                                                                                    if (optionPrizeFor == 1) {
                                                                                                        setGender(1);
                                                                                                    }
                                                                                                }}
                                                                                            >
                                                                                                <MenuItem value={1}>प्रत्येकी</MenuItem>
                                                                                                {/* <MenuItem value={2}>Department Wise</MenuItem> */}
                                                                                                <MenuItem value={2}>यापैकी</MenuItem>
                                                                                            </Select>
                                                                                        </FormControl>
                                                                                    </div>

                                                                                </>
                                                                            )
                                                                        }
                                                                    })()}
                                                                </div>
                                                            </>
                                                        )
                                                    }

                                                })()}


                                                {(() => {
                                                    if (prizeType == 3 && option == 2) {
                                                        return (
                                                            <>
                                                                <div className="row my-2">
                                                                    <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>शहर :</label>
                                                                    <div className='col-sm-3'>
                                                                        <Autocomplete
                                                                            id="combo-box-demo"
                                                                            options={regionData}
                                                                            PaperComponent={({ children }) => (
                                                                                <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                                                            )}
                                                                            value={regionObj}
                                                                            onChange={(event, newValue) => {
                                                                                setRegionObj(newValue);
                                                                            }}
                                                                            style={{ fontSize: '14px' }}
                                                                            getOptionLabel={option => option.regionName}
                                                                            size="small"
                                                                            renderInput={params => (
                                                                                <TextField {...params} margin='dense' label="शहर" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} />
                                                                            )}
                                                                        />
                                                                    </div>

                                                                    <label className='col-sm-2' style={{ fontSize: "14px", fontWeight: "600", marginTop: "8px" }} >प्रत्येक शाळेला येणारी रक्कम ( % ) :</label>
                                                                    <div className='col-sm-2'>
                                                                        <TextField
                                                                            required={(regionData.length == 0) ? true : false}
                                                                            fullWidth
                                                                            type="text"
                                                                            onWheel={(e) => e.target.blur()}
                                                                            autoComplete='off'
                                                                            size='small'
                                                                            id="outlined-basic"
                                                                            label="रक्कम"
                                                                            // InputProps={{ inputProps: { min: 0, max: (totalAmtInPercent == 0) ? 100 : (100 - totalAmtInPercent) } }}
                                                                            // InputProps={{ inputProps: { min: 0 } }}
                                                                            //style={{ width: "100px" }}
                                                                            value={schoolWiseAmount}
                                                                            onChange={(e) => setSchoolWiseAmount(e.target.value)}
                                                                            variant="outlined"
                                                                            margin='dense'
                                                                            InputLabelProps={{ style: { fontSize: 14 } }}
                                                                        // inputProps={{ readOnly: true }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </>
                                                        )
                                                    }
                                                    else if (prizeType == 4 && option == 2) {
                                                        return (
                                                            <>
                                                                <div className="row my-2">
                                                                    <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>विभाग :</label>
                                                                    <div className='col-sm-3'>
                                                                        <Autocomplete
                                                                            id="combo-box-demo"
                                                                            options={deptData}
                                                                            PaperComponent={({ children }) => (
                                                                                <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                                                            )}
                                                                            value={deptObj}
                                                                            onChange={(event, newValue) => {
                                                                                setDeptObj(newValue);
                                                                            }}
                                                                            style={{ fontSize: '14px' }}
                                                                            getOptionLabel={option => (authUser.branchMedium == 1) ? option.deptNameMr : option.deptNameEn}
                                                                            size="small"
                                                                            renderInput={params => (
                                                                                <TextField {...params} margin='dense' label="विभाग" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} />
                                                                            )}
                                                                        />
                                                                    </div>

                                                                    <label className='col-sm-2' style={{ fontSize: "14px", fontWeight: "600", marginTop: "8px" }} >प्रत्येक शाळेला येणारी रक्कम ( % ) :</label>
                                                                    <div className='col-sm-2'>
                                                                        <TextField
                                                                            required={(deptData.length == 0) ? true : false}
                                                                            fullWidth
                                                                            type="text"
                                                                            onWheel={(e) => e.target.blur()}
                                                                            autoComplete='off'
                                                                            size='small'
                                                                            id="outlined-basic"
                                                                            label="रक्कम"
                                                                            // InputProps={{ inputProps: { min: 0, max: (totalAmtInPercent == 0) ? 100 : (100 - totalAmtInPercent) } }}
                                                                            // InputProps={{ inputProps: { min: 0 } }}
                                                                            //style={{ width: "100px" }}
                                                                            value={schoolWiseAmount}
                                                                            onChange={(e) => setSchoolWiseAmount(e.target.value)}
                                                                            variant="outlined"
                                                                            margin='dense'
                                                                            InputLabelProps={{ style: { fontSize: 14 } }}
                                                                        // inputProps={{ readOnly: true }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </>
                                                        )
                                                    }
                                                    else if (option == 1 || (option == 2 && prizeType == 2 && allSchools == 1)) {
                                                        return (
                                                            <>

                                                                <div className="row my-2">
                                                                    <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>शाळा :</label>
                                                                    <div className='col-sm-5'>
                                                                        <Autocomplete
                                                                            id="combo-box-demo"
                                                                            options={(schoolEndowmentData.length != 0) ? res2 : schoolDataForEndow}
                                                                            PaperComponent={({ children }) => (
                                                                                <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                                                            )}
                                                                            filterSelectedOptions
                                                                            value={schoolObjForEndow}
                                                                            onChange={(event, newValue) => {
                                                                                setSchoolObjForEndow(newValue);
                                                                                setSchoolWiseAmount('');
                                                                                if (newValue == null) {
                                                                                    setSchoolWiseAmount('');
                                                                                }
                                                                            }}
                                                                            style={{ fontSize: '14px' }}
                                                                            getOptionLabel={option => option.endowmentId + " . " + option.schoolName}
                                                                            size="small"
                                                                            renderInput={params => (
                                                                                <TextField {...params} margin='dense' label="शाळा" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }}
                                                                                    required={(schoolEndowmentData.length == 0) ? true : false}
                                                                                />
                                                                            )}
                                                                        />
                                                                    </div>

                                                                    <label className='col-sm-2' style={{ fontSize: "14px", fontWeight: "600", marginTop: "8px" }} >प्रत्येक शाळेला येणारी रक्कम ( % ) :</label>
                                                                    <div className='col-sm-2'>
                                                                        <TextField
                                                                            required={(schoolEndowmentData.length == 0) ? true : false}
                                                                            fullWidth
                                                                            type="text"
                                                                            onWheel={(e) => e.target.blur()}
                                                                            autoComplete='off'
                                                                            size='small'
                                                                            id="outlined-basic"
                                                                            label="रक्कम"
                                                                            // InputProps={{ inputProps: { min: 0, max: (totalAmtInPercent == 0) ? 100 : (100 - totalAmtInPercent) } }}
                                                                            // InputProps={{ inputProps: { min: 0 } }}
                                                                            //style={{ width: "100px" }}
                                                                            value={schoolWiseAmount}
                                                                            onChange={(e) => setSchoolWiseAmount(e.target.value)}
                                                                            variant="outlined"
                                                                            margin='dense'
                                                                            InputLabelProps={{ style: { fontSize: 14 } }}
                                                                        // inputProps={{ readOnly: true }}
                                                                        />
                                                                    </div>

                                                                    <div className="col-sm-1" hidden={(schoolObjForEndow == "" || schoolObjForEndow == null || schoolWiseAmount == '') ? true : false}>
                                                                        <button type='button' className='btn p-0 mt-3' onClick={() => { setAddNewSchool(1); saveSchoolData(); }}>
                                                                            <AddCircleIcon fontSize='medium' style={{ color: "#1F883C" }} />
                                                                        </button>
                                                                    </div>
                                                                </div>

                                                                {
                                                                    (() => {
                                                                        //console.log(JSON.stringify(schoolEndowmentData))
                                                                        if (schoolEndowmentData.length != 0) {

                                                                            return (
                                                                                schoolEndowmentData.map((e, index) => {
                                                                                    return (
                                                                                        <>
                                                                                            <div className="row my-2">
                                                                                                <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>शाळा {index + 1}:</label>

                                                                                                <TextField
                                                                                                    //required
                                                                                                    style={{ width: "100px", marginLeft: "10px" }}
                                                                                                    fullWidth
                                                                                                    type="number"
                                                                                                    onWheel={(e) => e.target.blur()}
                                                                                                    autoComplete='off'
                                                                                                    size='small'
                                                                                                    value={e.branchId}
                                                                                                    onChange={(e) => {
                                                                                                        //setText1(e.target.value);
                                                                                                        // getSchoolNameById(parseInt(e.target.value));
                                                                                                        // if (e.target.value == '') {
                                                                                                        //     setText2("");
                                                                                                        // }
                                                                                                    }}
                                                                                                    id="outlined-basic"
                                                                                                    label="शाळा क्र."
                                                                                                    variant="outlined"
                                                                                                    margin='dense'
                                                                                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                                                                                    inputProps={{ readOnly: true }}
                                                                                                />

                                                                                                <TextField
                                                                                                    //required
                                                                                                    fullWidth
                                                                                                    autoComplete='off'
                                                                                                    style={{ width: "400px", marginLeft: "8px" }}
                                                                                                    size='small'
                                                                                                    value={e.schoolNameByBranchId}
                                                                                                    onChange={(e) => {
                                                                                                        //setText2(e.target.value);
                                                                                                    }}
                                                                                                    id="outlined-basic"
                                                                                                    label="शाळेचे नाव"
                                                                                                    variant="outlined"
                                                                                                    margin='dense'
                                                                                                    readOnly={true}
                                                                                                    inputProps={{ readOnly: true }}
                                                                                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                                                                                />
                                                                                                {/* </div> */}

                                                                                                <label className='col-sm-2' style={{ fontSize: "14px", fontWeight: "600", marginTop: "8px" }} >प्रत्येक शाळेला येणारी रक्कम ( % ) :</label>

                                                                                                <TextField
                                                                                                    //required
                                                                                                    fullWidth
                                                                                                    type="text"
                                                                                                    style={{ width: "100px" }}
                                                                                                    // onWheel={(e) => e.target.blur()}
                                                                                                    // autoComplete='off'
                                                                                                    size='small'
                                                                                                    id="outlined-basic"
                                                                                                    label="रक्कम"
                                                                                                    //InputProps={{ inputProps: { min: 1, max: (totalAmtInPercent == 0) ? 100 : (100 - totalAmtInPercent) } }}
                                                                                                    // InputProps={{ inputProps: { min: 0 } }}
                                                                                                    value={e.amountInPercent}
                                                                                                    //onChange={(e) => setText3(e.target.value)}
                                                                                                    variant="outlined"
                                                                                                    margin='dense'
                                                                                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                                                                                    // inputProps={{ readOnly: true }}
                                                                                                    readOnly
                                                                                                />


                                                                                                <div className="col-sm-1">
                                                                                                    {(schoolEndowmentData.length !== 0) ?
                                                                                                        <button type="button" className='btn p-0 mt-3' onClick={() => removeInputFields(index)}>
                                                                                                            <CancelIcon fontSize='medium' style={{ color: "#FA4A3F" }} />
                                                                                                        </button> : ''}
                                                                                                </div>
                                                                                            </div>
                                                                                        </>
                                                                                    )
                                                                                })
                                                                            )
                                                                        }
                                                                    })()
                                                                }
                                                            </>
                                                        )
                                                    }
                                                    else if (option == 2 && prizeType == 2 && allSchools == 2) {
                                                        return (
                                                            <>
                                                                <div className="row my-2">
                                                                    <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>शाळा :</label>
                                                                    <div className='col-sm-7'>
                                                                        <Autocomplete
                                                                            id="combo-box-demo"
                                                                            multiple
                                                                            filterSelectedOptions
                                                                            disableClearable
                                                                            disableCloseOnSelect
                                                                            options={schoolDataForEndow}
                                                                            style={{ fontSize: '14px' }}
                                                                            PaperComponent={({ children }) => (
                                                                                <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                                                            )}
                                                                            onChange={(event, item) => {
                                                                                setSelectedSchoolObj(item);
                                                                                if (item != null) {
                                                                                    setSelectedSchoolId(item.map(e => e.endowmentId).join(','));
                                                                                }

                                                                            }}
                                                                            // renderOption={(props, option, { selected }) => (
                                                                            //     <li {...props}>
                                                                            //         <Checkbox
                                                                            //             icon={icon}
                                                                            //             checkedIcon={checkedIcon}
                                                                            //             style={{ marginRight: 8 }}
                                                                            //             checked={selected}
                                                                            //         />
                                                                            //         {option.endowmentId + " . " + option.schoolName}
                                                                            //     </li>
                                                                            // )}
                                                                            value={selectedSchoolObj}
                                                                            getOptionLabel={option => option.endowmentId + " . " + option.schoolName}
                                                                            size="small"
                                                                            renderInput={params => (
                                                                                <TextField {...params} margin='dense' inputProps={{ ...params.inputProps, autoComplete: "off" }} label="शाळा" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} />
                                                                            )}
                                                                        />
                                                                    </div>
                                                                </div>

                                                                <div className="row my-2">
                                                                    <label className='col-sm-2' style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >रक्कम ( % ) :</label>
                                                                    <div className='col-sm-3'>
                                                                        <TextField
                                                                            required={(selectedSchoolObj.length == 0) ? true : false}
                                                                            fullWidth
                                                                            type="text"
                                                                            onWheel={(e) => e.target.blur()}
                                                                            autoComplete='off'
                                                                            size='small'
                                                                            id="outlined-basic"
                                                                            label="रक्कम"
                                                                            // InputProps={{ inputProps: { min: 0, max: (totalAmtInPercent == 0) ? 100 : (100 - totalAmtInPercent) } }}
                                                                            // InputProps={{ inputProps: { min: 0 } }}
                                                                            //style={{ width: "100px" }}
                                                                            value={schoolWiseAmount}
                                                                            onChange={(e) => setSchoolWiseAmount(e.target.value)}
                                                                            variant="outlined"
                                                                            margin='dense'
                                                                            InputLabelProps={{ style: { fontSize: 14 } }}
                                                                        // inputProps={{ readOnly: true }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </>
                                                        )
                                                    }
                                                })()}


                                                <div className="row my-2" hidden={(optionPrizeFor == 2 || optionPrizeFor == 3) ? true : false}>
                                                    <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>इयत्ता :</label>
                                                    <div className='col-sm-3'>
                                                        <Autocomplete
                                                            id="combo-box-demo"
                                                            options={classData}
                                                            PaperComponent={({ children }) => (
                                                                <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                                            )}
                                                            value={classObj}
                                                            onChange={(event, newValue) => {
                                                                setClassObj(newValue);
                                                            }}
                                                            style={{ fontSize: '14px' }}
                                                            getOptionLabel={option => (authUser.branchMedium == 1) ? option.nameMr : option.nameEn}
                                                            size="small"
                                                            renderInput={params => (
                                                                <TextField {...params} margin='dense' label="इयत्ता" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} />
                                                            )}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="row my-2">
                                                    <label className='col-sm-2' style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >क्रमांक :</label>
                                                    <div className='col-sm-2'>
                                                        <Autocomplete
                                                            id="combo-box-demo"
                                                            options={meritData}
                                                            PaperComponent={({ children }) => (
                                                                <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                                            )}
                                                            style={{ fontSize: '14px' }}
                                                            value={meritObj}
                                                            onChange={(event, newValue) => {
                                                                setMeritObj(newValue);
                                                                setMeritValues(Array(newValue?.id || 0).fill(''));
                                                                setM1('');
                                                                setM2('');
                                                                setM3('');
                                                                setM4('');
                                                                setM5('');
                                                                setM6('');
                                                                setM7('');
                                                                setM8('');
                                                            }}
                                                            getOptionLabel={option => (option.meritNo || '')}
                                                            size="small"
                                                            renderInput={params => (
                                                                <TextField {...params} margin='dense' label="क्रमांक" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                                            )}
                                                        />
                                                    </div>

                                                    <label className='col-sm-2' hidden={(meritObj == null || meritObj.id <= 1 || meritObj.id >= 8) ? true : false} style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >बक्षिसाची विभागणी ( % ) :</label>
                                                    {/* {meritObj !== null && meritObj.id > 1 && (
                                                        // <div style={{ display: 'flex', gap: '8px', flexWrap: 'wrap' }}>
                                                        <>
                                                            {Array.from({ length: meritObj.id }, (_, index) => (
                                                                <TextField
                                                                    key={index}
                                                                    required={(meritObj.id > 1 || meritObj.id != null) ? true : false}
                                                                    fullWidth
                                                                    type="number"
                                                                    style={{ width: "100px", marginLeft: '8px' }}
                                                                    onWheel={(e) => e.target.blur()}
                                                                    value={meritValues[index] || ''}
                                                                    onChange={(e) => {
                                                                        const updatedValues = [...meritValues];
                                                                        updatedValues[index] = e.target.value;
                                                                        setMeritValues(updatedValues);
                                                                    }}
                                                                    autoComplete="off"
                                                                    size="small"
                                                                    id={`percentage-${index + 1}`}
                                                                    label={`${index + 1} टक्केवारी`}
                                                                    variant="outlined"
                                                                    margin="dense"
                                                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                                                />
                                                            ))}
                                                        </>
                                                        //    </div>
                                                    )} */}

                                                    {(() => {
                                                        if (meritObj !== null) {
                                                            if (meritObj.id == 2) {
                                                                return (
                                                                    <>
                                                                        <TextField
                                                                            required={(meritObj.id > 1 || meritObj.id <= 3) ? true : false}
                                                                            fullWidth
                                                                            type="number"
                                                                            style={{ width: "100px" }}
                                                                            onWheel={(e) => e.target.blur()}
                                                                            value={m1}
                                                                            onChange={(e) => setM1(e.target.value)}
                                                                            autoComplete='off'
                                                                            size='small'
                                                                            id="outlined-basic"
                                                                            label="1st टक्केवारी"
                                                                            variant="outlined"
                                                                            margin='dense'
                                                                            InputLabelProps={{ style: { fontSize: 14 } }}
                                                                        />

                                                                        <TextField
                                                                            required={(meritObj.id > 1 || meritObj.id <= 3) ? true : false}
                                                                            fullWidth
                                                                            type="number"
                                                                            style={{ width: "100px", marginLeft: '8px' }}
                                                                            onWheel={(e) => e.target.blur()}
                                                                            value={m2}
                                                                            onChange={(e) => setM2(e.target.value)}
                                                                            autoComplete='off'
                                                                            size='small'
                                                                            id="outlined-basic"
                                                                            label="2nd टक्केवारी"
                                                                            variant="outlined"
                                                                            margin='dense'
                                                                            InputLabelProps={{ style: { fontSize: 14 } }}
                                                                        />
                                                                    </>
                                                                )
                                                            }
                                                            else if (meritObj.id == 3) {
                                                                return (
                                                                    <>
                                                                        <TextField
                                                                            required={(meritObj.id > 1 || meritObj.id <= 4) ? true : false}
                                                                            fullWidth
                                                                            type="number"
                                                                            style={{ width: "100px" }}
                                                                            onWheel={(e) => e.target.blur()}
                                                                            value={m1}
                                                                            onChange={(e) => setM1(e.target.value)}
                                                                            autoComplete='off'
                                                                            size='small'
                                                                            id="outlined-basic"
                                                                            label="1st टक्केवारी"
                                                                            variant="outlined"
                                                                            margin='dense'
                                                                            InputLabelProps={{ style: { fontSize: 14 } }}
                                                                        />

                                                                        <TextField
                                                                            required={(meritObj.id > 1 || meritObj.id <= 4) ? true : false}
                                                                            fullWidth
                                                                            type="number"
                                                                            style={{ width: "100px", marginLeft: '8px' }}
                                                                            onWheel={(e) => e.target.blur()}
                                                                            value={m2}
                                                                            onChange={(e) => setM2(e.target.value)}
                                                                            autoComplete='off'
                                                                            size='small'
                                                                            id="outlined-basic"
                                                                            label="2nd टक्केवारी"
                                                                            variant="outlined"
                                                                            margin='dense'
                                                                            InputLabelProps={{ style: { fontSize: 14 } }}
                                                                        />

                                                                        <TextField
                                                                            required={(meritObj.id > 1 || meritObj.id <= 4) ? true : false}
                                                                            fullWidth
                                                                            type="number"
                                                                            style={{ width: "100px", marginLeft: '8px' }}
                                                                            onWheel={(e) => e.target.blur()}
                                                                            value={m3}
                                                                            onChange={(e) => setM3(e.target.value)}
                                                                            autoComplete='off'
                                                                            size='small'
                                                                            id="outlined-basic"
                                                                            label="3rd टक्केवारी"
                                                                            variant="outlined"
                                                                            margin='dense'
                                                                            InputLabelProps={{ style: { fontSize: 14 } }}
                                                                        />
                                                                    </>
                                                                )
                                                            }
                                                            else if (meritObj.id == 4) {
                                                                return (
                                                                    <>
                                                                        <TextField
                                                                            required={(meritObj.id > 1 || meritObj.id <= 5) ? true : false}
                                                                            fullWidth
                                                                            type="number"
                                                                            style={{ width: "100px" }}
                                                                            onWheel={(e) => e.target.blur()}
                                                                            value={m1}
                                                                            onChange={(e) => setM1(e.target.value)}
                                                                            autoComplete='off'
                                                                            size='small'
                                                                            id="outlined-basic"
                                                                            label="1st टक्केवारी"
                                                                            variant="outlined"
                                                                            margin='dense'
                                                                            InputLabelProps={{ style: { fontSize: 14 } }}
                                                                        />

                                                                        <TextField
                                                                            required={(meritObj.id > 1 || meritObj.id <= 5) ? true : false}
                                                                            fullWidth
                                                                            type="number"
                                                                            style={{ width: "100px", marginLeft: '8px' }}
                                                                            onWheel={(e) => e.target.blur()}
                                                                            value={m2}
                                                                            onChange={(e) => setM2(e.target.value)}
                                                                            autoComplete='off'
                                                                            size='small'
                                                                            id="outlined-basic"
                                                                            label="2nd टक्केवारी"
                                                                            variant="outlined"
                                                                            margin='dense'
                                                                            InputLabelProps={{ style: { fontSize: 14 } }}
                                                                        />

                                                                        <TextField
                                                                            required={(meritObj.id > 1 || meritObj.id <= 5) ? true : false}
                                                                            fullWidth
                                                                            type="number"
                                                                            style={{ width: "100px", marginLeft: '8px' }}
                                                                            onWheel={(e) => e.target.blur()}
                                                                            value={m3}
                                                                            onChange={(e) => setM3(e.target.value)}
                                                                            autoComplete='off'
                                                                            size='small'
                                                                            id="outlined-basic"
                                                                            label="3rd टक्केवारी"
                                                                            variant="outlined"
                                                                            margin='dense'
                                                                            InputLabelProps={{ style: { fontSize: 14 } }}
                                                                        />

                                                                        <TextField
                                                                            required={(meritObj.id > 1 || meritObj.id <= 5) ? true : false}
                                                                            fullWidth
                                                                            type="number"
                                                                            style={{ width: "100px", marginLeft: '8px' }}
                                                                            onWheel={(e) => e.target.blur()}
                                                                            value={m4}
                                                                            onChange={(e) => setM4(e.target.value)}
                                                                            autoComplete='off'
                                                                            size='small'
                                                                            id="outlined-basic"
                                                                            label="4th टक्केवारी"
                                                                            variant="outlined"
                                                                            margin='dense'
                                                                            InputLabelProps={{ style: { fontSize: 14 } }}
                                                                        />
                                                                    </>
                                                                )
                                                            }
                                                            else if (meritObj.id == 5) {
                                                                return (
                                                                    <>
                                                                        <TextField
                                                                            required={(meritObj.id > 1 || meritObj.id <= 6) ? true : false}
                                                                            fullWidth
                                                                            type="number"
                                                                            style={{ width: "100px" }}
                                                                            onWheel={(e) => e.target.blur()}
                                                                            value={m1}
                                                                            onChange={(e) => setM1(e.target.value)}
                                                                            autoComplete='off'
                                                                            size='small'
                                                                            id="outlined-basic"
                                                                            label="1st टक्केवारी"
                                                                            variant="outlined"
                                                                            margin='dense'
                                                                            InputLabelProps={{ style: { fontSize: 14 } }}
                                                                        />

                                                                        <TextField
                                                                            required={(meritObj.id > 1 || meritObj.id <= 6) ? true : false}
                                                                            fullWidth
                                                                            type="number"
                                                                            style={{ width: "100px", marginLeft: '8px' }}
                                                                            onWheel={(e) => e.target.blur()}
                                                                            value={m2}
                                                                            onChange={(e) => setM2(e.target.value)}
                                                                            autoComplete='off'
                                                                            size='small'
                                                                            id="outlined-basic"
                                                                            label="2nd टक्केवारी"
                                                                            variant="outlined"
                                                                            margin='dense'
                                                                            InputLabelProps={{ style: { fontSize: 14 } }}
                                                                        />

                                                                        <TextField
                                                                            required={(meritObj.id > 1 || meritObj.id <= 6) ? true : false}
                                                                            fullWidth
                                                                            type="number"
                                                                            style={{ width: "100px", marginLeft: '8px' }}
                                                                            onWheel={(e) => e.target.blur()}
                                                                            value={m3}
                                                                            onChange={(e) => setM3(e.target.value)}
                                                                            autoComplete='off'
                                                                            size='small'
                                                                            id="outlined-basic"
                                                                            label="3rd टक्केवारी"
                                                                            variant="outlined"
                                                                            margin='dense'
                                                                            InputLabelProps={{ style: { fontSize: 14 } }}
                                                                        />

                                                                        <TextField
                                                                            required={(meritObj.id > 1 || meritObj.id <= 6) ? true : false}
                                                                            fullWidth
                                                                            type="number"
                                                                            style={{ width: "100px", marginLeft: '8px' }}
                                                                            onWheel={(e) => e.target.blur()}
                                                                            value={m4}
                                                                            onChange={(e) => setM4(e.target.value)}
                                                                            autoComplete='off'
                                                                            size='small'
                                                                            id="outlined-basic"
                                                                            label="4th टक्केवारी"
                                                                            variant="outlined"
                                                                            margin='dense'
                                                                            InputLabelProps={{ style: { fontSize: 14 } }}
                                                                        />

                                                                        <TextField
                                                                            required={(meritObj.id > 1 || meritObj.id <= 6) ? true : false}
                                                                            fullWidth
                                                                            type="number"
                                                                            style={{ width: "100px", marginLeft: '8px' }}
                                                                            onWheel={(e) => e.target.blur()}
                                                                            value={m5}
                                                                            onChange={(e) => setM5(e.target.value)}
                                                                            autoComplete='off'
                                                                            size='small'
                                                                            id="outlined-basic"
                                                                            label="5th टक्केवारी"
                                                                            variant="outlined"
                                                                            margin='dense'
                                                                            InputLabelProps={{ style: { fontSize: 14 } }}
                                                                        />
                                                                    </>
                                                                )
                                                            }
                                                            else if (meritObj.id == 6) {
                                                                return (
                                                                    <>
                                                                        <TextField
                                                                            required={(meritObj.id > 1 || meritObj.id <= 7) ? true : false}
                                                                            fullWidth
                                                                            type="number"
                                                                            style={{ width: "100px" }}
                                                                            onWheel={(e) => e.target.blur()}
                                                                            value={m1}
                                                                            onChange={(e) => setM1(e.target.value)}
                                                                            autoComplete='off'
                                                                            size='small'
                                                                            id="outlined-basic"
                                                                            label="1st टक्केवारी"
                                                                            variant="outlined"
                                                                            margin='dense'
                                                                            InputLabelProps={{ style: { fontSize: 14 } }}
                                                                        />

                                                                        <TextField
                                                                            required={(meritObj.id > 1 || meritObj.id <= 7) ? true : false}
                                                                            fullWidth
                                                                            type="number"
                                                                            style={{ width: "100px", marginLeft: '8px' }}
                                                                            onWheel={(e) => e.target.blur()}
                                                                            value={m2}
                                                                            onChange={(e) => setM2(e.target.value)}
                                                                            autoComplete='off'
                                                                            size='small'
                                                                            id="outlined-basic"
                                                                            label="2nd टक्केवारी"
                                                                            variant="outlined"
                                                                            margin='dense'
                                                                            InputLabelProps={{ style: { fontSize: 14 } }}
                                                                        />

                                                                        <TextField
                                                                            required={(meritObj.id > 1 || meritObj.id <= 7) ? true : false}
                                                                            fullWidth
                                                                            type="number"
                                                                            style={{ width: "100px", marginLeft: '8px' }}
                                                                            onWheel={(e) => e.target.blur()}
                                                                            value={m3}
                                                                            onChange={(e) => setM3(e.target.value)}
                                                                            autoComplete='off'
                                                                            size='small'
                                                                            id="outlined-basic"
                                                                            label="3rd टक्केवारी"
                                                                            variant="outlined"
                                                                            margin='dense'
                                                                            InputLabelProps={{ style: { fontSize: 14 } }}
                                                                        />

                                                                        <TextField
                                                                            required={(meritObj.id > 1 || meritObj.id <= 7) ? true : false}
                                                                            fullWidth
                                                                            type="number"
                                                                            style={{ width: "100px", marginLeft: '8px' }}
                                                                            onWheel={(e) => e.target.blur()}
                                                                            value={m4}
                                                                            onChange={(e) => setM4(e.target.value)}
                                                                            autoComplete='off'
                                                                            size='small'
                                                                            id="outlined-basic"
                                                                            label="4th टक्केवारी"
                                                                            variant="outlined"
                                                                            margin='dense'
                                                                            InputLabelProps={{ style: { fontSize: 14 } }}
                                                                        />

                                                                        <TextField
                                                                            required={(meritObj.id > 1 || meritObj.id <= 7) ? true : false}
                                                                            fullWidth
                                                                            type="number"
                                                                            style={{ width: "100px", marginLeft: '8px' }}
                                                                            onWheel={(e) => e.target.blur()}
                                                                            value={m5}
                                                                            onChange={(e) => setM5(e.target.value)}
                                                                            autoComplete='off'
                                                                            size='small'
                                                                            id="outlined-basic"
                                                                            label="5th टक्केवारी"
                                                                            variant="outlined"
                                                                            margin='dense'
                                                                            InputLabelProps={{ style: { fontSize: 14 } }}
                                                                        />

                                                                        <div className="offset-sm-2">
                                                                            <TextField
                                                                                required={(meritObj.id > 1 || meritObj.id <= 7) ? true : false}
                                                                                fullWidth
                                                                                type="number"
                                                                                style={{ width: "100px", marginLeft: '8px' }}
                                                                                onWheel={(e) => e.target.blur()}
                                                                                value={m6}
                                                                                onChange={(e) => setM6(e.target.value)}
                                                                                autoComplete='off'
                                                                                size='small'
                                                                                id="outlined-basic"
                                                                                label="6th टक्केवारी"
                                                                                variant="outlined"
                                                                                margin='dense'
                                                                                InputLabelProps={{ style: { fontSize: 14 } }}
                                                                            />
                                                                        </div>

                                                                    </>
                                                                )
                                                            }
                                                            else if (meritObj.id == 7) {
                                                                return (
                                                                    <>
                                                                        <TextField
                                                                            required={(meritObj.id > 1 || meritObj.id <= 8) ? true : false}
                                                                            fullWidth
                                                                            type="number"
                                                                            style={{ width: "100px" }}
                                                                            onWheel={(e) => e.target.blur()}
                                                                            value={m1}
                                                                            onChange={(e) => setM1(e.target.value)}
                                                                            autoComplete='off'
                                                                            size='small'
                                                                            id="outlined-basic"
                                                                            label="1st टक्केवारी"
                                                                            variant="outlined"
                                                                            margin='dense'
                                                                            InputLabelProps={{ style: { fontSize: 14 } }}
                                                                        />

                                                                        <TextField
                                                                            required={(meritObj.id > 1 || meritObj.id <= 8) ? true : false}
                                                                            fullWidth
                                                                            type="number"
                                                                            style={{ width: "100px", marginLeft: '8px' }}
                                                                            onWheel={(e) => e.target.blur()}
                                                                            value={m2}
                                                                            onChange={(e) => setM2(e.target.value)}
                                                                            autoComplete='off'
                                                                            size='small'
                                                                            id="outlined-basic"
                                                                            label="2nd टक्केवारी"
                                                                            variant="outlined"
                                                                            margin='dense'
                                                                            InputLabelProps={{ style: { fontSize: 14 } }}
                                                                        />

                                                                        <TextField
                                                                            required={(meritObj.id > 1 || meritObj.id <= 8) ? true : false}
                                                                            fullWidth
                                                                            type="number"
                                                                            style={{ width: "100px", marginLeft: '8px' }}
                                                                            onWheel={(e) => e.target.blur()}
                                                                            value={m3}
                                                                            onChange={(e) => setM3(e.target.value)}
                                                                            autoComplete='off'
                                                                            size='small'
                                                                            id="outlined-basic"
                                                                            label="3rd टक्केवारी"
                                                                            variant="outlined"
                                                                            margin='dense'
                                                                            InputLabelProps={{ style: { fontSize: 14 } }}
                                                                        />

                                                                        <TextField
                                                                            required={(meritObj.id > 1 || meritObj.id <= 8) ? true : false}
                                                                            fullWidth
                                                                            type="number"
                                                                            style={{ width: "100px", marginLeft: '8px' }}
                                                                            onWheel={(e) => e.target.blur()}
                                                                            value={m4}
                                                                            onChange={(e) => setM4(e.target.value)}
                                                                            autoComplete='off'
                                                                            size='small'
                                                                            id="outlined-basic"
                                                                            label="4th टक्केवारी"
                                                                            variant="outlined"
                                                                            margin='dense'
                                                                            InputLabelProps={{ style: { fontSize: 14 } }}
                                                                        />

                                                                        <TextField
                                                                            required={(meritObj.id > 1 || meritObj.id <= 8) ? true : false}
                                                                            fullWidth
                                                                            type="number"
                                                                            style={{ width: "100px", marginLeft: '8px' }}
                                                                            onWheel={(e) => e.target.blur()}
                                                                            value={m5}
                                                                            onChange={(e) => setM5(e.target.value)}
                                                                            autoComplete='off'
                                                                            size='small'
                                                                            id="outlined-basic"
                                                                            label="5th टक्केवारी"
                                                                            variant="outlined"
                                                                            margin='dense'
                                                                            InputLabelProps={{ style: { fontSize: 14 } }}
                                                                        />

                                                                        <div className="offset-sm-2">
                                                                            <TextField
                                                                                required={(meritObj.id > 1 || meritObj.id <= 8) ? true : false}
                                                                                fullWidth
                                                                                type="number"
                                                                                style={{ width: "100px", marginLeft: '8px' }}
                                                                                onWheel={(e) => e.target.blur()}
                                                                                value={m6}
                                                                                onChange={(e) => setM6(e.target.value)}
                                                                                autoComplete='off'
                                                                                size='small'
                                                                                id="outlined-basic"
                                                                                label="6th टक्केवारी"
                                                                                variant="outlined"
                                                                                margin='dense'
                                                                                InputLabelProps={{ style: { fontSize: 14 } }}
                                                                            />

                                                                            <TextField
                                                                                required={(meritObj.id > 1 || meritObj.id <= 8) ? true : false}
                                                                                fullWidth
                                                                                type="number"
                                                                                style={{ width: "100px", marginLeft: '8px' }}
                                                                                onWheel={(e) => e.target.blur()}
                                                                                value={m7}
                                                                                onChange={(e) => setM7(e.target.value)}
                                                                                autoComplete='off'
                                                                                size='small'
                                                                                id="outlined-basic"
                                                                                label="7th टक्केवारी"
                                                                                variant="outlined"
                                                                                margin='dense'
                                                                                InputLabelProps={{ style: { fontSize: 14 } }}
                                                                            />
                                                                        </div>
                                                                    </>
                                                                )
                                                            }
                                                        }
                                                    })()}
                                                </div>

                                                <div className="row my-2">
                                                    <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >विषय प्रकार :</label>
                                                    <div className='col-sm-3'>
                                                        <FormControl fullWidth
                                                            margin="dense">
                                                            <InputLabel id='demo-simple-select-label' size="small" style={{ fontSize: '14px' }}>विषय प्रकार</InputLabel>
                                                            <Select
                                                                MenuProps={{ classes: { paper: classes.select } }}
                                                                size='small'
                                                                labelId="demo-simple-select-label"
                                                                id="demo-simple-select"
                                                                value={subjectType}
                                                                label="विषय प्रकार"
                                                                onChange={(e) => {
                                                                    setSubjectType(e.target.value);
                                                                    setSubjectObj(null);
                                                                    getSubjectListById(e.target.value);
                                                                }}
                                                            >
                                                                <MenuItem value={1}>Academic</MenuItem>
                                                                <MenuItem value={2}>Non-Academic</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </div>

                                                    <label className="col-sm-1" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>विषय :</label>
                                                    <div className='col-sm-3'>
                                                        <Autocomplete
                                                            id="combo-box-demo"
                                                            options={subjectData}
                                                            PaperComponent={({ children }) => (
                                                                <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                                            )}
                                                            value={subjectObj}
                                                            onChange={(event, newValue) => {
                                                                setSubjectObj(newValue);
                                                            }}
                                                            style={{ fontSize: '14px' }}
                                                            getOptionLabel={option => (authUser.branchMedium == 1) ? option.nameMr : option.nameEn}
                                                            size="small"
                                                            renderInput={params => (
                                                                <TextField {...params} margin='dense' label="विषय" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                                            )}
                                                        />
                                                    </div>
                                                </div>

                                                {/* <div className="row py-2 mb-4 mt-4 ml-1 mr-1" style={{ backgroundColor: "#FFE6E6", border: "1px solid #2C3E50", borderRadius: "2px" }}>
                                                    <div className='' style={{ width: "150px" }} hidden={(optionPrizeFor == 2 || optionPrizeFor == 3) ? true : false}>
                                                        <Autocomplete
                                                            id="combo-box-demo"
                                                            options={classData}
                                                            PaperComponent={({ children }) => (
                                                                <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                                            )}
                                                            value={classObj}
                                                            onChange={(event, newValue) => {
                                                                setClassObj(newValue);
                                                            }}
                                                            style={{ fontSize: '14px' }}
                                                            getOptionLabel={option => (authUser.branchMedium == 1) ? option.nameMr : option.nameEn}
                                                            size="small"
                                                            renderInput={params => (
                                                                <TextField {...params}
                                                                    style={{ backgroundColor: "#F4F6F7" }}
                                                                    margin='dense' label="इयत्ता" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required={(percentageData.length == 0) ? true : false} />
                                                            )}
                                                        />
                                                    </div>
                                                    <div className='' style={{ width: "160px" }}>
                                                        <FormControl fullWidth
                                                            margin="dense">
                                                            <InputLabel id='demo-simple-select-label' size="small" style={{ fontSize: '14px' }}>विषय प्रकार</InputLabel>
                                                            <Select
                                                                required={(percentageData.length == 0) ? true : false}
                                                                MenuProps={{ classes: { paper: classes.select } }}
                                                                size='small'
                                                                labelId="demo-simple-select-label"
                                                                id="demo-simple-select1"
                                                                value={subjectType}
                                                                style={{ backgroundColor: "#F4F6F7" }}
                                                                label="विषय प्रकार"
                                                                sx={{
                                                                    '& #demo-simple-select1': {
                                                                        fontSize: '14px',
                                                                        fontWeight: "500"
                                                                    }
                                                                }}
                                                                onChange={(e) => {
                                                                    setSubjectType(e.target.value);
                                                                    setSubjectObj(null);
                                                                    getSubjectListById(e.target.value);
                                                                }}
                                                            >
                                                                <MenuItem value={1}>Academic</MenuItem>
                                                                <MenuItem value={2}>Non-Academic</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </div>

                                                    <div className='' style={{ width: "210px" }}>
                                                        <Autocomplete
                                                            id="combo-box-demo"
                                                            options={subjectData}
                                                            PaperComponent={({ children }) => (
                                                                <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                                            )}
                                                            value={subjectObj}
                                                            onChange={(event, newValue) => {
                                                                setSubjectObj(newValue);
                                                            }}
                                                            style={{ fontSize: '14px' }}
                                                            getOptionLabel={option => (authUser.branchMedium == 1) ? option.nameMr : option.nameEn}
                                                            size="small"
                                                            renderInput={params => (
                                                                <TextField {...params}
                                                                    style={{ backgroundColor: "#F4F6F7" }}
                                                                    margin='dense' label="विषय" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required={(percentageData.length == 0) ? true : false} />
                                                            )}
                                                        />
                                                    </div>

                                                    <div className='' style={{ width: "120px" }}>
                                                        <Autocomplete
                                                            id="combo-box-demo"
                                                            options={meritData}
                                                            PaperComponent={({ children }) => (
                                                                <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                                            )}
                                                            style={{ fontSize: '14px' }}
                                                            value={meritObj}
                                                            onChange={(event, newValue) => {
                                                                setMeritObj(newValue);
                                                                // setM1('');
                                                                // setM2('');
                                                                // setM3('');
                                                                // setM4('');
                                                                // setM5('');
                                                            }}
                                                            getOptionLabel={option => (option.meritNo || '')}
                                                            size="small"
                                                            renderInput={params => (
                                                                <TextField {...params}
                                                                    style={{ backgroundColor: "#F4F6F7" }}
                                                                    margin='dense' label="क्रमांक" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required={(percentageData.length == 0) ? true : false} />
                                                            )}
                                                        />
                                                    </div>

                                                    <div className='' hidden={(optionPrizeFor == 2 || optionPrizeFor == 3) ? true : false} style={{ width: "180px" }}>
                                                        <FormControl fullWidth
                                                            margin="dense">
                                                            <InputLabel id='demo-simple-select-label' size="small" style={{ fontSize: '14px' }}>लिंग</InputLabel>
                                                            <Select
                                                                required={(percentageData.length == 0) ? true : false}
                                                                MenuProps={{ classes: { paper: classes.select } }}
                                                                size='small'
                                                                labelId="demo-simple-select-label"
                                                                id="demo-simple-select2"
                                                                sx={{
                                                                    '& #demo-simple-select2': {
                                                                        fontSize: '14px',
                                                                        fontWeight: "500"
                                                                    }
                                                                }}
                                                                value={gender}
                                                                style={{ backgroundColor: "#F4F6F7" }}
                                                                onChange={(e) => setGender(e.target.value)}
                                                                label="लिंग"
                                                            >
                                                                <MenuItem value={1}>विद्यार्थी / विद्यार्थिनी</MenuItem>
                                                                <MenuItem value={2}>विद्यार्थिनी</MenuItem>
                                                                <MenuItem value={3}>विद्यार्थी</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </div>

                                                    <div className="" style={{ width: "120px" }}>
                                                        <TextField
                                                            required={(percentageData.length == 0) ? true : false}
                                                            fullWidth
                                                            autoComplete='off'
                                                            size='small'
                                                            id="outlined-basic"
                                                            label="टक्केवारी"
                                                            style={{ backgroundColor: "#F4F6F7" }}
                                                            //style={{ width: "120px", marginLeft: "15px", backgroundColor: "#fff" }}
                                                            value={m1}
                                                            onChange={(e) => setM1(e.target.value)}
                                                            variant="outlined"
                                                            margin='dense'
                                                            InputLabelProps={{ style: { fontSize: 14 } }}
                                                        />
                                                    </div>

                                                    <div style={{ width: "130px" }} className="mt-2">
                                                        <button type='button' className={saveBtnPer} onClick={() => addPercentageData()}
                                                            disabled={((optionPrizeFor == 2 || optionPrizeFor == 3) ? ((subjectType == '' || subjectObj == null || m1 == '' || meritObj == null) ? true : false) : ((subjectType == '' || subjectObj == null || classObj == null || gender == '' || m1 == '' || meritObj == null) ? true : false))}
                                                        >
                                                            <AddCircleIcon fontSize='medium' style={{ color: "#1F883C" }} />
                                                        </button>
                                                        <button type='button' className={updateBtnPer}
                                                            disabled={((optionPrizeFor == 2 || optionPrizeFor == 3) ? ((subjectType == '' || subjectObj == null || m1 == '' || meritObj == null) ? true : false) : ((subjectType == '' || subjectObj == null || classObj == null || gender == '' || m1 == '' || meritObj == null) ? true : false))}
                                                        >
                                                            <CheckCircleIcon fontSize='medium' style={{ color: "#1F883C" }} />
                                                        </button>
                                                        <button type="button" className='btn p-0 mt-2' onClick={() => clearPerSectionValues()}>
                                                            <CancelIcon fontSize='medium' style={{ color: "#FA4A3F" }} />
                                                        </button>
                                                    </div>

                                                    <div className="table-responsive mt-4 mb-2" style={{ maxHeight: "300px" }}>
                                                        <table className="table">
                                                            <thead className=''>
                                                                <tr>
                                                                    <th hidden={(optionPrizeFor == 2 || optionPrizeFor == 3) ? true : false} style={{ fontSize: "14px", fontWeight: "600", lineHeight: "1.4", minWidth: "20px" }}>इयत्ता</th>
                                                                    <th style={{ fontSize: "14px", fontWeight: "600", lineHeight: "1.4", minWidth: "20px" }}>विषय प्रकार</th>
                                                                    <th style={{ fontSize: "14px", fontWeight: "600", lineHeight: "1.4", minWidth: "20px" }}>विषय</th>
                                                                    <th style={{ fontSize: "14px", fontWeight: "600", lineHeight: "1.4", minWidth: "20px" }}>क्रमांक</th>
                                                                    <th hidden={(optionPrizeFor == 2 || optionPrizeFor == 3) ? true : false} style={{ fontSize: "14px", fontWeight: "600", lineHeight: "1.4", minWidth: "20px" }}>लिंग</th>
                                                                    <th style={{ fontSize: "14px", fontWeight: "600", lineHeight: "1.4", minWidth: "20px" }}>टक्केवारी</th>
                                                                    <th hidden={(EditFlag == 1) ? true : false} style={{ fontSize: "14px", fontWeight: "600", minWidth: "20px" }}>Edit</th>
                                                                    <th style={{ fontSize: "14px", fontWeight: "600", lineHeight: "1.4", minWidth: "20px" }}>Delete</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {(() => {
                                                                    if (percentageData.length != 0) {
                                                                        return (
                                                                            percentageData.map((item, index) => {
                                                                                //console.log(item.validityDate)
                                                                                return (
                                                                                    <tr key={index}>
                                                                                        <td style={{ fontSize: "15px", fontWeight: "500" }} hidden={(optionPrizeFor == 2 || optionPrizeFor == 3) ? true : false}>
                                                                                            {
                                                                                                classData.map(e => {
                                                                                                    return (e.id == item.classId) ? e.nameMr : ''
                                                                                                })
                                                                                            }
                                                                                        </td>
                                                                                        <td style={{ fontSize: "15px", fontWeight: "500" }}>
                                                                                            {
                                                                                                (item.subjectCategory == 1)
                                                                                                    ?
                                                                                                    "Academic"
                                                                                                    :
                                                                                                    "Non-Academic"
                                                                                            }
                                                                                        </td>
                                                                                        <td style={{ fontSize: "15px", fontWeight: "500" }}>
                                                                                            {
                                                                                                subjectData.map(e => {
                                                                                                    return (e.id == item.subjectId) ? e.nameMr : ''
                                                                                                })
                                                                                            }
                                                                                        </td>
                                                                                        <td style={{ fontSize: "15px", fontWeight: "500" }}>{item.noOfStudent}</td>
                                                                                        <td style={{ fontSize: "15px", fontWeight: "500" }} hidden={(optionPrizeFor == 2 || optionPrizeFor == 3) ? true : false}>
                                                                                            {(() => {
                                                                                                if (item.genderId == 1) {
                                                                                                    return "विद्यार्थी / विद्यार्थिनी"
                                                                                                }
                                                                                                else if (item.genderId == 2) {
                                                                                                    return "विद्यार्थिनी"
                                                                                                }
                                                                                                else {
                                                                                                    return "विद्यार्थी"
                                                                                                }
                                                                                            })()}
                                                                                        </td>
                                                                                        <td style={{ fontSize: "15px", fontWeight: "500" }}>{item.amountDivideInStudent}</td>
                                                                                        <td hidden={(EditFlag == 1) ? true : false}>
                                                                                            <Tooltip title="Edit">
                                                                                                <button type='button' className='btn btn-sm' onClick={() => getDataForUpdate(item)}>
                                                                                                    <EditIcon fontSize='medium' style={{ color: '#587FF8' }} />
                                                                                                </button>
                                                                                            </Tooltip>
                                                                                        </td>
                                                                                        <td >
                                                                                            <Tooltip title="Delete">
                                                                                                <button type='button' className='btn btn-sm'>
                                                                                                    <DeleteIcon fontSize='medium' style={{ color: '#D73016' }} />
                                                                                                </button>
                                                                                            </Tooltip>
                                                                                        </td>
                                                                                    </tr>
                                                                                )
                                                                            })
                                                                        )
                                                                    }
                                                                })()}

                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div> */}

                                                {
                                                    (() => {
                                                        if (option == 2) {
                                                            return (
                                                                <div className="row my-2">
                                                                    <label className="col-sm-3" style={{ fontSize: "14px", fontWeight: "550", marginTop: "16px" }}>संस्था पातळीवरील बक्षिसाची माहिती:</label>
                                                                    <div className='col-sm-6'>
                                                                        <TextField
                                                                            required={(option == 2) ? true : false}
                                                                            fullWidth
                                                                            autoComplete='off'
                                                                            size='small'
                                                                            id="outlined-basic"
                                                                            label="संस्था पातळीवरील बक्षिसाची माहिती"
                                                                            value={sansthaInfo}
                                                                            onChange={(e) => setSansthaInfo(e.target.value)}
                                                                            variant="outlined"
                                                                            margin='dense'
                                                                            InputLabelProps={{ style: { fontSize: 14 } }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            )
                                                        }
                                                    })()
                                                }

                                                <div className="row my-2" hidden={(optionPrizeFor == 2 || optionPrizeFor == 3) ? true : false}>
                                                    <label className='col-sm-2' style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >लिंग :</label>
                                                    <div className='col-sm-6 mt-2'>
                                                        <RadioGroup row name="customized-radios" value={gender} onChange={(e) => setGender(e.target.value)}>
                                                            <FormControlLabel value={1} control={<Radio color='primary' />} label="विद्यार्थी / विद्यार्थिनी" />
                                                            <FormControlLabel value={2} control={<Radio color='primary' />} label="विद्यार्थिनी" />
                                                            <FormControlLabel value={3} control={<Radio color='primary' />} label="विद्यार्थी" />
                                                        </RadioGroup>
                                                    </div>
                                                </div>


                                                <div className="col-sm-12 mt-3">
                                                    <div className="offset-sm-2">
                                                        <button type="submit" className="btn btn-sm btn-primary">Save</button>
                                                        {/* <button type="submit" onClick={() => (state2.button = 2)} disabled={updateBtnDisabled} className={updateBtn} style={{ margin: '0px 4px' }}>Update</button> */}
                                                        <button type="button" className="btn btn-danger btn-sm" style={{ margin: '0px 4px' }} onClick={() => clearForm()}>Cancel</button>
                                                    </div>
                                                </div>
                                            </form >
                                            {/* prize distribution form ends */}
                                        </div>
                                    </>
                                )
                            }
                        })()}
                    </div>
                </div>
            }

            {/* Delete condition Modal */}
            <div className="modal fade" id="conditionModal" tabIndex="-1" aria-labelledby="conditionModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header mt-3">
                            <h4 className="modal-title" id="conditionModalLabel">Are you sure you want to delete this condition?</h4>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                            <button type="button" onClick={() => DeleteData()} className="btn btn-danger" data-bs-dismiss="modal">Delete</button>
                        </div>
                    </div>
                </div>
            </div>

            {/* delete amount deatails */}
            <Modal show={modal}>
                <Modal.Header>
                    <Modal.Title>Are you sure, you want to Delete?</Modal.Title>
                </Modal.Header>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setModal(false)}>Close</Button>
                    <Button variant="danger" onClick={() => deleteAmtData(deleteAmtId)}>Delete</Button>
                </Modal.Footer>
            </Modal>
            {/* <ToastContainer position="top-right" theme="colored" /> */}
        </>
    )
}

export default EndowmentMaster