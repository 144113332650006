const InwardOutwardJSON = [
    {
        id: 1104,
        MenuType: "normal",
        PathName: "/Home/ie1104",
        Title: "Inward Entry",
        LinkIcon: "move_to_inbox"
    },
    {
        id: 1105,
        MenuType: "normal",
        PathName: "/Home/oe1105",
        Title: "Outward Entry",
        LinkIcon: "outbox"
    },
    {
        id: 1106,
        MenuType: "normal",
        PathName: "/Home/ir1106",
        Title: "Inward Report",
        LinkIcon: "all_inbox"
    },
    {
        id: 1107,
        MenuType: "normal",
        PathName: "/Home/or1107",
        Title: "Outward Report",
        LinkIcon: "upload_file"
    },

];

export default InwardOutwardJSON;