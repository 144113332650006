import React, { useState, useEffect } from 'react';
import TitleLabel from "../../CommonComponent/TitleLabel";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from '@mui/material/TextField';
import { useSelector } from "react-redux";
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import Loader from '../../CommonComponent/Loader';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Paper from "@mui/material/Paper";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { getExportedReportAPIURL } from '../../CommonServices/APIURL';
import {
    classWithDivisionURL, getSubjectDataForSubMarkSheet, printSubjectMarkSheet, printSubjectMarkSheetExamWise,
    printSubjectMarkSheetTermWise, PrintAllAndFinalSubjectMarkSheet, printAllAndTermWiseSubMarkSheet,
    printAllFinalAndAboveEightSubMarkSheet, printAllAboveEightAndTermWiseSubMarkSheet, printAboveEightSubjectwiseSubMarkSheet, getExamListForResultSheet,
    printSubjectWiseAndFinalAboveTenSubMarkSheet, printFinalMarkSheetAllSubjectJrCollegeReport
} from '../Services/ExamReportAPIURL';
import subjectMarkTermData from '../../CommonComponent/LocalJSON/SubjectMarkTermData';
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles(theme => ({
    menuPaper: {
        maxHeight: 200,
        "& ul": {
            backgroundColor: "#F4ECF7",
            //maxHeight: 160,
        },
        "& li": {
            // backgroundColor: "#F4ECF7", fontWeight: "550", fontSize: "14px" 
            fontSize: "14px",
            fontWeight: "550"
        },
    }
}));


const SubjectMarkSheet = () => {
    const classes = useStyles();
    const titleId = "Subject Marks Sheet";
    const authUser = useSelector((state) => state.user.value);

    // loader on/off
    const [loaderOption, setLoaderOption] = useState(false);

    //const [reportOptionId, setReportOptionId] = useState(1);
    const [id, setId] = useState();
    const [classDivData, setClassDivData] = useState([]);
    const [classDivObj, setClassDivObj] = useState(null);
    const [classId, setClassId] = useState('');
    const [termObj, setTermObj] = useState('');
    const [subjectData, setSubjectData] = useState([]);
    const [subjectObj, setSubjectObj] = useState(null);
    const [examData, setExamData] = useState([]);
    const [examObj, setExamObj] = useState(null);
    const [printBlank, setPrintBlank] = useState(0);
    const [termExam, setTermExam] = useState(0);
    const [option, setOption] = useState(1);
    const [withObservation, setWithObservation] = useState(0);
    const [pageOption, setPageOption] = useState(1);
    const [govtRule, setGovtRule] = useState(0);

    const HandleTermExamCheckbox = (e) => {
        setTermExam(e.target.checked);
        if (e.target.checked == true) {
            setTermExam(1);
        }
        else {
            setTermExam(0);
            setExamObj(null);
        }
    }

    const HandlePrintBlankCheckbox = (e) => {
        setPrintBlank(e.target.checked);
        if (e.target.checked == true) {
            setPrintBlank(1);
        }
        else {
            setPrintBlank(0);
        }
    }

    const HandleWithObservationCheckbox = (e) => {
        setWithObservation(e.target.checked);
        if (e.target.checked == true) {
            setWithObservation(1);
        }
        else {
            setWithObservation(0);
        }
    }

    const HandleGovtRuleCheckbox = (e) => {
        setGovtRule(e.target.checked);
        if (e.target.checked == true) {
            setGovtRule(1);
        }
        else {
            setGovtRule(0);
        }
    }

    useEffect(() => {
        getData();
    }, [authUser])


    //class-division list
    const getData = async () => {
        if (authUser.deptId != null || authUser.branchId != null) {
            await axios(`${classWithDivisionURL}?sid=${authUser.deptId}&bid=${authUser.branchId}`)
                .then((response) => {
                    setClassDivData(response.data);

                }).catch((error) => {
                    console.log(error);
                })
        }
    }

    const getExamListTermWise = async (tId) => {
        setExamData([]);
        if (classDivObj != null) {
            //console.log(`${getExamListForResultSheet}?classId=${classDivObj.classId}&divId=${classDivObj.divisionId}&acYear=${authUser.sessionYear}&sid=${authUser.deptId}&termId=${tId}&branchMedium=${authUser.branchMedium}`)
            await axios(`${getExamListForResultSheet}?classId=${classDivObj.classId}&divId=${classDivObj.divisionId}&acYear=${authUser.sessionYear}&sid=${authUser.deptId}&termId=${tId}&branchMedium=${authUser.branchMedium}`)
                .then((response) => {
                    setExamData(response.data);

                }).catch((error) => {
                    console.log(error);
                })
        }
    }

    //Subject list for subject wise option
    const getSubjects = async (cId, dId) => {
        //console.log(`${getSubjectDataForSubMarkSheet}?sid=${authUser.deptId}&bid=${authUser.branchId}&acYear=${authUser.sessionYear}&classId=${cId}&divId=${dId}`)
        await axios(`${getSubjectDataForSubMarkSheet}?sid=${authUser.deptId}&bid=${authUser.branchId}&acYear=${authUser.sessionYear}&classId=${cId}&divId=${dId}&branchMedium=${authUser.branchMedium}`)
            .then((response) => {
                //console.log(response.data);
                setSubjectData(response.data);

            }).catch((error) => {
                console.log(error);
            })
    }

    //Subject & Term wise --
    const subjectMarksheetPrint = async () => {
        setLoaderOption(true);
        // console.log(`${printSubjectMarkSheet}?langId=${authUser.branchMedium}&subId=${subjectObj.id}&viewType=${subjectObj.viewType}&classId=${classDivObj.classId}&divId=${classDivObj.divisionId}&termId=${termObj}&acYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&printBlank=${printBlank}&branchMedium=${authUser.branchMedium}&withObservation=${withObservation}&govRule=${govtRule}`)
        await axios.get(`${printSubjectMarkSheet}?langId=${authUser.branchMedium}&subId=${subjectObj.id}&viewType=${subjectObj.viewType}&classId=${classDivObj.classId}&divId=${classDivObj.divisionId}&termId=${termObj}&acYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&printBlank=${printBlank}&branchMedium=${authUser.branchMedium}&withObservation=${withObservation}&govRule=${govtRule}`)
            .then((response) => {
                // console.log(response.data)
                if (response.data === "NOTFOUND") {
                    setLoaderOption(false);
                    toast.warn("Data Not Found.")
                }
                else if (response.data === "TwoTeacherAllocated") {
                    setLoaderOption(false);
                    toast.warn("This subject is allocated to two or more teachers.")
                }
                else if (response.data === "Subject Teacher Not Assigned") {
                    setLoaderOption(false);
                    toast.warn("Subject Teacher not found for this class-division.")
                }
                else if (response.data === "Class Teacher Not Assigned") {
                    setLoaderOption(false);
                    toast.warn("Class Teacher not found for this class-division.")
                }
                else if (response.data != null) {
                    // console.log(response.data)
                    window.open(`${getExportedReportAPIURL}${response.data}`);
                    setLoaderOption(false);
                }
            })
            .catch(error => {
                setLoaderOption(false);
                toast.error("Error Occurred, Try after sometime.");
                console.log("Subject MarkSheet failed: " + error)
            })
    }

    //Subject,Term & exam wise --
    const subjectMarksheetExamWisePrint = async () => {
        setLoaderOption(true);
        // console.log(`${printSubjectMarkSheetExamWise}?langId=${authUser.branchMedium}&subId=${subjectObj.id}&viewType=${subjectObj.viewType}&classId=${classDivObj.classId}&divId=${classDivObj.divisionId}&termId=${termObj.id}&acYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&examId=${examObj.id}&printBlank=${printBlank}&branchMedium=${authUser.branchMedium}&withObservation=${withObservation}&govRule=${govtRule}`)
        await axios.get(`${printSubjectMarkSheetExamWise}?langId=${authUser.branchMedium}&subId=${subjectObj.id}&viewType=${subjectObj.viewType}&classId=${classDivObj.classId}&divId=${classDivObj.divisionId}&termId=${termObj}&acYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&examId=${examObj.id}&printBlank=${printBlank}&branchMedium=${authUser.branchMedium}&withObservation=${withObservation}&govRule=${govtRule}`)
            .then((response) => {

                if (response.data === "NOTFOUND") {
                    setLoaderOption(false);
                    toast.warn("Data Not Found.")
                }
                else if (response.data === "TwoTeacherAllocated") {
                    setLoaderOption(false);
                    toast.warn("This subject is allocated to two or more teachers.")
                }
                else if (response.data === "Subject Teacher Not Assigned") {
                    setLoaderOption(false);
                    toast.warn("Subject Teacher not found for this class-division.")
                }
                else if (response.data === "Class Teacher Not Assigned") {
                    setLoaderOption(false);
                    toast.warn("Class Teacher not found for this class-division.")
                }
                else if (response.data != null) {
                    // console.log(response.data)
                    window.open(`${getExportedReportAPIURL}${response.data}`);
                    setLoaderOption(false);
                }
            })
            .catch(error => {
                setLoaderOption(false);
                toast.error("Error Occurred, Try after sometime.");
                console.log("Subject MarkSheet failed: " + error)
            })
    }

    //Subject wise & Term is final --
    const subjectMarksheetTermAnnualPrint = async () => {
        setLoaderOption(true);
        // console.log(`${printSubjectMarkSheetTermWise}?langId=${authUser.branchMedium}&subId=${subjectObj.id}&viewType=${subjectObj.viewType}&classId=${classDivObj.classId}&divId=${classDivObj.divisionId}&acYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&printBlank=${printBlank}&branchMedium=${authUser.branchMedium}`)
        await axios.get(`${printSubjectMarkSheetTermWise}?langId=${authUser.branchMedium}&subId=${subjectObj.id}&viewType=${subjectObj.viewType}&classId=${classDivObj.classId}&divId=${classDivObj.divisionId}&acYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&printBlank=${printBlank}&branchMedium=${authUser.branchMedium}`)
            .then((response) => {

                if (response.data === "NOTFOUND") {
                    setLoaderOption(false);
                    toast.warn("Data Not Found.")
                }
                else if (response.data === "TwoTeacherAllocated") {
                    setLoaderOption(false);
                    toast.warn("This subject is allocated to two or more teachers.")
                }
                else if (response.data === "Subject Teacher Not Assigned") {
                    setLoaderOption(false);
                    toast.warn("Subject Teacher not found for this class-division.")
                }
                else if (response.data === "Class Teacher Not Assigned") {
                    setLoaderOption(false);
                    toast.warn("Class Teacher not found for this class-division.")
                }
                else if (response.data != null) {
                    // console.log(response.data)
                    window.open(`${getExportedReportAPIURL}${response.data}`);
                    setLoaderOption(false);
                }
            })
            .catch(error => {
                setLoaderOption(false);
                toast.error("Error Occurred, Try after sometime.");
                console.log("Subject MarkSheet failed: " + error)
            })
    }

    //All Subjects & Final term --
    const allAndFinalSubjectMarkSheetPrint = async () => {
        setLoaderOption(true);
        // console.log(`${PrintAllAndFinalSubjectMarkSheet}?langId=${authUser.branchMedium}&classId=${classDivObj.classId}&divId=${classDivObj.divisionId}&acYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&printBlank=${printBlank}&branchMedium=${authUser.branchMedium}&pageSize=${pageOption}`)
        await axios.get(`${PrintAllAndFinalSubjectMarkSheet}?langId=${authUser.branchMedium}&classId=${classDivObj.classId}&divId=${classDivObj.divisionId}&acYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&printBlank=${printBlank}&branchMedium=${authUser.branchMedium}&pageSize=${pageOption}`)
            .then((response) => {

                if (response.data === "NOTFOUND") {
                    setLoaderOption(false);
                    toast.warn("Data Not Found.")
                }
                else if (response.data === "Subject Teacher Not Assigned") {
                    setLoaderOption(false);
                    toast.warn("Subject Teacher not found for this class-division.")
                }
                else if (response.data === "Class Teacher Not Assigned") {
                    setLoaderOption(false);
                    toast.warn("Class Teacher not found for this class-division.")
                }
                else if (response.data != null) {
                    // console.log(response.data)
                    window.open(`${getExportedReportAPIURL}${response.data}`);
                    setLoaderOption(false);
                }
            })
            .catch(error => {
                setLoaderOption(false);
                toast.error("Error Occurred, Try after sometime.");
                console.log("Subject MarkSheet failed: " + error)
            })
    }

    //All subjects & Term wise --
    const allAndTermWiseSubMarkSheetPrint = async () => {
        setLoaderOption(true);
        // console.log(`${printAllAndTermWiseSubMarkSheet}?langId=${authUser.branchMedium}&classId=${classDivObj.classId}&divId=${classDivObj.divisionId}&termId=${termObj}&acYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&printBlank=${printBlank}&branchMedium=${authUser.branchMedium}&pageSize=${pageOption}&govRule=${govtRule}`)
        await axios.get(`${printAllAndTermWiseSubMarkSheet}?langId=${authUser.branchMedium}&classId=${classDivObj.classId}&divId=${classDivObj.divisionId}&termId=${termObj}&acYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&printBlank=${printBlank}&branchMedium=${authUser.branchMedium}&pageSize=${pageOption}&govRule=${govtRule}`)
            .then((response) => {

                if (response.data === "NOTFOUND") {
                    setLoaderOption(false);
                    toast.warn("Data Not Found.")
                }
                else if (response.data === "Subject Teacher Not Assigned") {
                    setLoaderOption(false);
                    toast.warn("Subject Teacher not found for this class-division.")
                }
                else if (response.data === "Class Teacher Not Assigned") {
                    setLoaderOption(false);
                    toast.warn("Class Teacher not found for this class-division.")
                }
                else if (response.data != null) {
                    // console.log(response.data)
                    window.open(`${getExportedReportAPIURL}${response.data}`);
                    setLoaderOption(false);
                }
            })
            .catch(error => {
                setLoaderOption(false);
                toast.error("Error Occurred, Try after sometime.");
                console.log("Subject MarkSheet failed: " + error)
            })
    }

    //All subjects,above 8 class-div & Final term --
    const allFinalAndAboveEightSubMarkSheetPrint = async () => {
        setLoaderOption(true);
        // console.log(`${printAllFinalAndAboveEightSubMarkSheet}?langId=${authUser.branchMedium}&classId=${classDivObj.classId}&divId=${classDivObj.divisionId}&acYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&printBlank=${printBlank}&branchMedium=${authUser.branchMedium}&pageSize=${pageOption}`)
        await axios.get(`${printAllFinalAndAboveEightSubMarkSheet}?langId=${authUser.branchMedium}&classId=${classDivObj.classId}&divId=${classDivObj.divisionId}&acYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&printBlank=${printBlank}&branchMedium=${authUser.branchMedium}&pageSize=${pageOption}`)
            .then((response) => {

                if (response.data === "NOTFOUND") {
                    setLoaderOption(false);
                    toast.warn("Data Not Found.")
                }
                else if (response.data === "Subject Teacher Not Assigned") {
                    setLoaderOption(false);
                    toast.warn("Subject Teacher not found for this class-division.")
                }
                else if (response.data === "Class Teacher Not Assigned") {
                    setLoaderOption(false);
                    toast.warn("Class Teacher not found for this class-division.")
                }
                else if (response.data != null) {
                    // console.log(response.data)
                    window.open(`${getExportedReportAPIURL}${response.data}`);
                    setLoaderOption(false);
                }
            })
            .catch(error => {
                setLoaderOption(false);
                toast.error("Error Occurred, Try after sometime.");
                console.log("Subject MarkSheet failed: " + error)
            })
    }

    //All subjects,above 8 class-div & Term wise --
    const allAboveEightAndTermSubMarksheetPrint = async () => {
        setLoaderOption(true);
        // console.log(`${printAllAboveEightAndTermWiseSubMarkSheet}?langId=${authUser.branchMedium}&classId=${classDivObj.classId}&divId=${classDivObj.divisionId}&termId=${termObj}&acYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&printBlank=${printBlank}&branchMedium=${authUser.branchMedium}&pageSize=${pageOption}`)
        await axios.get(`${printAllAboveEightAndTermWiseSubMarkSheet}?langId=${authUser.branchMedium}&classId=${classDivObj.classId}&divId=${classDivObj.divisionId}&termId=${termObj}&acYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&printBlank=${printBlank}&branchMedium=${authUser.branchMedium}&pageSize=${pageOption}`)
            .then((response) => {

                if (response.data === "NOTFOUND") {
                    setLoaderOption(false);
                    toast.warn("Data Not Found.")
                }
                else if (response.data === "Subject Teacher Not Assigned") {
                    setLoaderOption(false);
                    toast.warn("Subject Teacher not found for this class-division.")
                }
                else if (response.data === "Class Teacher Not Assigned") {
                    setLoaderOption(false);
                    toast.warn("Class Teacher not found for this class-division.")
                }
                else if (response.data != null) {
                    // console.log(response.data)
                    window.open(`${getExportedReportAPIURL}${response.data}`);
                    setLoaderOption(false);
                }
            })
            .catch(error => {
                setLoaderOption(false);
                toast.error("Error Occurred, Try after sometime.");
                console.log("Subject MarkSheet failed: " + error)
            })
    }

    //Subject wise,above 8 & Final --
    const aboveEightsubjectWiseSubMarkSheetPrint = async () => {
        setLoaderOption(true);
        // console.log(`${printAboveEightSubjectwiseSubMarkSheet}?langId=${authUser.branchMedium}&subId=${subjectObj.id}&viewType=${subjectObj.viewType}&classId=${classDivObj.classId}&divId=${classDivObj.divisionId}&acYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&printBlank=${printBlank}&branchMedium=${authUser.branchMedium}&pageSize=${pageOption}`)
        await axios.get(`${printAboveEightSubjectwiseSubMarkSheet}?langId=${authUser.branchMedium}&subId=${subjectObj.id}&viewType=${subjectObj.viewType}&classId=${classDivObj.classId}&divId=${classDivObj.divisionId}&acYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&printBlank=${printBlank}&branchMedium=${authUser.branchMedium}&pageSize=${pageOption}`)
            .then((response) => {

                if (response.data === "NOTFOUND") {
                    setLoaderOption(false);
                    toast.warn("Data Not Found.")
                }
                else if (response.data === "TwoTeacherAllocated") {
                    setLoaderOption(false);
                    toast.warn("This subject is allocated to two or more teachers.")
                }
                else if (response.data === "Subject Teacher Not Assigned") {
                    setLoaderOption(false);
                    toast.warn("Subject Teacher not found for this class-division.")
                }
                else if (response.data === "Class Teacher Not Assigned") {
                    setLoaderOption(false);
                    toast.warn("Class Teacher not found for this class-division.")
                }
                else if (response.data != null) {
                    // console.log(response.data)
                    window.open(`${getExportedReportAPIURL}${response.data}`);
                    setLoaderOption(false);
                }
            })
            .catch(error => {
                setLoaderOption(false);
                toast.error("Error Occurred, Try after sometime.");
                console.log("Subject MarkSheet failed: " + error)
            })
    }

    //subject wise,above 10 & Final --
    const aboveTenSubjectWiseFinalSubMarkSheetPrint = async () => {
        setLoaderOption(true);
        // console.log(`${printSubjectWiseAndFinalAboveTenSubMarkSheet}?langId=${authUser.branchMedium}&subId=${subjectObj.id}&viewType=${subjectObj.viewType}&classId=${classDivObj.classId}&divId=${classDivObj.divisionId}&sessionYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&printBlank=${printBlank}&medium=${authUser.branchMedium}`)
        await axios.get(`${printSubjectWiseAndFinalAboveTenSubMarkSheet}?langId=${authUser.branchMedium}&subId=${subjectObj.id}&viewType=${subjectObj.viewType}&classId=${classDivObj.classId}&divId=${classDivObj.divisionId}&sessionYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&printBlank=${printBlank}&medium=${authUser.branchMedium}`)
            .then((response) => {

                if (response.data === "NOTFOUND") {
                    setLoaderOption(false);
                    toast.warn("Data Not Found.")
                }
                else if (response.data === "TwoTeacherAllocated") {
                    setLoaderOption(false);
                    toast.warn("This subject is allocated to two or more teachers.")
                }
                else if (response.data === "Subject Teacher Not Assigned") {
                    setLoaderOption(false);
                    toast.warn("Subject Teacher not found for this class-division.")
                }
                else if (response.data === "Class Teacher Not Assigned") {
                    setLoaderOption(false);
                    toast.warn("Class Teacher not found for this class-division.")
                }
                else if (response.data != null) {
                    // console.log(response.data)
                    window.open(`${getExportedReportAPIURL}${response.data}`);
                    setLoaderOption(false);
                }
            })
            .catch(error => {
                setLoaderOption(false);
                toast.error("Error Occurred, Try after sometime.");
                console.log("Subject MarkSheet failed: " + error)
            })
    }

    //All Subject,jr college & Final
    const jrCollegeAllSubjectFinalMarkSheetPrint = async () => {
        setLoaderOption(true);
        // console.log(`${printFinalMarkSheetAllSubjectJrCollegeReport}?langId=${authUser.branchMedium}&classId=${classDivObj.classId}&divId=${classDivObj.divisionId}&acYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&printBlank=${printBlank}&branchMedium=${authUser.branchMedium}&pageSize=${pageOption}`)
        await axios.get(`${printFinalMarkSheetAllSubjectJrCollegeReport}?langId=${authUser.branchMedium}&classId=${classDivObj.classId}&divId=${classDivObj.divisionId}&acYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&printBlank=${printBlank}&branchMedium=${authUser.branchMedium}&pageSize=${pageOption}`)
            .then((response) => {

                if (response.data === "NOTFOUND") {
                    setLoaderOption(false);
                    toast.warn("Data Not Found.")
                }
                else if (response.data === "Subject Teacher Not Assigned") {
                    setLoaderOption(false);
                    toast.warn("Subject Teacher not found for this class-division.")
                }
                else if (response.data === "Class Teacher Not Assigned") {
                    setLoaderOption(false);
                    toast.warn("Class Teacher not found for this class-division.")
                }
                else if (response.data != null) {
                    // console.log(response.data)
                    window.open(`${getExportedReportAPIURL}${response.data}`);
                    setLoaderOption(false);
                }
            })
            .catch(error => {
                setLoaderOption(false);
                toast.error("Error Occurred, Try after sometime.");
                console.log("Subject MarkSheet failed: " + error)
            })
    }

    //For subject wise option
    const getReportForSpecificSubjectTermWise = () => {
        if (termExam == 0) {
            subjectMarksheetPrint();
        }
        else if (termExam == 1) {
            subjectMarksheetExamWisePrint();
        }
    }

    //for All subjects option
    const getReportForallsubjectsTermWise = () => {
        if (classDivObj.classId === 9 || classDivObj.classId === 10) {
            allAboveEightAndTermSubMarksheetPrint();
        }
        else {
            allAndTermWiseSubMarkSheetPrint();
        }
    }

    const getSubjectmarksheetReport = e => {
        e.preventDefault();
        if (option == 1) {
            if (termObj == 3) {
                if (classDivObj.classId === 9 || classDivObj.classId === 10) {
                    allFinalAndAboveEightSubMarkSheetPrint();
                }
                else {
                    if (authUser.deptId === 4) {
                        jrCollegeAllSubjectFinalMarkSheetPrint();
                    }
                    else {
                        allAndFinalSubjectMarkSheetPrint();
                    }
                }
            }
            else {
                getReportForallsubjectsTermWise();
            }
        }
        else if (option == 2) {
            if (termObj == 3) {
                if (classDivObj.classId === 9 || classDivObj.classId === 10) {
                    aboveEightsubjectWiseSubMarkSheetPrint();
                }
                else {
                    if (classDivObj != null) {
                        if (authUser.deptId == 4 || (authUser.deptId != 1 && classDivObj.classId > 10)) {
                            aboveTenSubjectWiseFinalSubMarkSheetPrint();
                        }
                        else {
                            subjectMarksheetTermAnnualPrint();
                        }
                    }
                }
            }
            else {
                getReportForSpecificSubjectTermWise();
            }
        }
    }

    return (
        <>
            {
                (loaderOption == true)
                    ?
                    <Loader />
                    :
                    <>
                        <TitleLabel titleId={titleId} />

                        <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: "350px" }}>
                            {/* Form Data start */}
                            <form onSubmit={getSubjectmarksheetReport}>

                                <input type="hidden" value={id} onChange={(e) => setId(e.target.value)} name="" />

                                <div className="row">
                                    <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Class-Division :</label>
                                    <div className='col-sm-3'>
                                        <Autocomplete
                                            id="combo-box-demo"
                                            PaperComponent={({ children }) => (
                                                <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                            )}
                                            options={classDivData}
                                            style={{ fontSize: '14px' }}
                                            getOptionLabel={option => (authUser.branchMedium == 1) ? option.classNameMr + " " + option.divisionNameMr : option.classNameEn + " " + option.divisionNameEn}
                                            size="small"
                                            value={classDivObj}
                                            onChange={(event, newValue) => {
                                                setClassDivObj(newValue);
                                                setTermObj('');
                                                setSubjectObj(null);
                                                setExamObj(null);
                                                setTermExam(0);
                                                setGovtRule(0);
                                                setOption(1);
                                                setPageOption(1);
                                                if (newValue != null) {
                                                    setClassId(newValue.classId);
                                                    getSubjects(newValue.classId, newValue.divisionId);
                                                }

                                            }}
                                            renderInput={params => (
                                                <TextField {...params} margin="dense" label="Select Class-Division" InputLabelProps={{ style: { fontSize: 14 } }} variant="outlined" required />
                                            )}
                                        />
                                    </div>

                                    <label className="col-sm-1" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Term :</label>
                                    <div className='col-sm-3'>
                                        <FormControl fullWidth margin='dense'>
                                            <InputLabel size="small" id='demo-simple-select-label' style={{ fontSize: '14px' }}>Term</InputLabel>
                                            <Select
                                                MenuProps={{ classes: { paper: classes.menuPaper } }}
                                                size='small'
                                                fullWidth
                                                labelId="demo-simple-select-label"
                                                required
                                                id="demo-simple-select"
                                                value={termObj}
                                                label="Term"
                                                onChange={(e) => {
                                                    setTermObj(e.target.value);
                                                    getExamListTermWise(e.target.value);
                                                    setSubjectObj(null);
                                                    setExamObj(null);
                                                    setTermExam(0);
                                                    setGovtRule(0);
                                                    setOption(1);
                                                    setPageOption(1);
                                                }}
                                            >
                                                {subjectMarkTermData.map((item, index) => {
                                                    return (
                                                        <MenuItem value={item.id} key={index}>{(authUser.branchMedium == 1) ? item.nameMr : item.nameEn}</MenuItem>
                                                    )
                                                })}
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div>

                                <div className="row my-2">
                                    <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Option :</label>
                                    <div className="col-sm-3">
                                        <FormControl fullWidth margin='dense'>
                                            <InputLabel size="small" id='demo-simple-select-label' style={{ fontSize: '14px' }}>Option</InputLabel>
                                            <Select
                                                MenuProps={{ classes: { paper: classes.menuPaper } }}
                                                size='small'
                                                labelId="demo-simple-select-label"
                                                required
                                                id="demo-simple-select"
                                                value={option}
                                                label="Option"
                                                onChange={(e) => {
                                                    setOption(e.target.value);
                                                    setSubjectObj(null);
                                                    setExamObj(null);
                                                    setTermExam(0);
                                                    setGovtRule(0);
                                                    setPageOption(1);
                                                }}
                                            //readOnly={(classDivObj == null || classDivObj == "") ? true : false}
                                            >

                                                <MenuItem value={1}>All Subjects</MenuItem>
                                                <MenuItem value={2}>Subject Wise</MenuItem>

                                            </Select>
                                        </FormControl>
                                    </div>

                                    {(() => {
                                        if (option == 2) {
                                            return (
                                                <>

                                                    <label className="col-sm-1" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Subject :</label>
                                                    <div className='col-sm-3'>
                                                        <Autocomplete
                                                            id="combo-box-demo"
                                                            PaperComponent={({ children }) => (
                                                                <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                                            )}
                                                            options={subjectData}
                                                            style={{ fontSize: '14px' }}
                                                            getOptionLabel={option => (authUser.branchMedium == 1) ? option.subjectNameMr : option.subjectNameEn}
                                                            size="small"
                                                            value={subjectObj}
                                                            onChange={(event, newvalue) => {
                                                                setSubjectObj(newvalue);
                                                                setExamObj(null);
                                                                setTermExam(0);
                                                                setGovtRule(0);
                                                            }}
                                                            renderInput={params => (
                                                                <TextField {...params} margin='dense' label="Select Subject" InputLabelProps={{ style: { fontSize: 14 } }} variant="outlined"
                                                                    required={(option == 2) ? true : false} />
                                                            )}
                                                        />
                                                    </div>

                                                </>
                                            )
                                        }
                                    })()}
                                    <div className='col-sm-3 mt-2' hidden={(option == 2 && termObj == 3 && (classDivObj.classId == 9 || classDivObj.classId == 10)) ? true : false}>
                                        <FormControlLabel control={<Checkbox checked={printBlank} onChange={HandlePrintBlankCheckbox} />} sx={{ '.MuiFormControlLabel-label': { fontWeight: 550, fontSize: "15px", marginTop: "2px" } }} label="Print Blank" />
                                    </div>
                                </div>

                                <div className="row my-2">
                                    {(() => {
                                        if ((option == 2 && termObj == 3 && (classDivObj.classId == 9 || classDivObj.classId == 10))) {
                                            return (
                                                <>
                                                    <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Page Size :</label>
                                                    <div className="col-sm-3">
                                                        <FormControl fullWidth margin='dense'>
                                                            <InputLabel size="small" id='demo-simple-select-label' style={{ fontSize: '14px' }}>Page Size</InputLabel>
                                                            <Select
                                                                MenuProps={{ classes: { paper: classes.menuPaper } }}
                                                                size='small'
                                                                labelId="demo-simple-select-label"
                                                                required={((option == 2 && termObj == 3 && (classDivObj.classId == 9 || classDivObj.classId == 10))) ? true : false}
                                                                id="demo-simple-select"
                                                                value={pageOption}
                                                                label="Page Size"
                                                                onChange={(e) => {
                                                                    setPageOption(e.target.value);
                                                                    setExamObj(null);
                                                                    setTermExam(0);
                                                                    setGovtRule(0);
                                                                }}
                                                            //readOnly={(classDivObj == null || classDivObj == "") ? true : false}
                                                            >

                                                                <MenuItem value={1}>Portrait</MenuItem>
                                                                <MenuItem value={2}>Landscape</MenuItem>
                                                                <MenuItem value={3}>Legal</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </div>

                                                    <div className='col-sm-3 mt-2'>
                                                        <FormControlLabel control={<Checkbox checked={printBlank} onChange={HandlePrintBlankCheckbox} />} sx={{ '.MuiFormControlLabel-label': { fontWeight: 550, fontSize: "15px", marginTop: "2px" } }} label="Print Blank" />
                                                    </div>
                                                </>
                                            )
                                        }
                                    })()}

                                </div>

                                {(() => {
                                    if (option == 2) {
                                        return (
                                            <>
                                                <div className="row my-2">
                                                    {(() => {
                                                        if (termObj !== 3) {
                                                            return (
                                                                <>{/* col-sm-2 mt-1*/}
                                                                    {/* <div className='row'> */}
                                                                    <div className='col-sm-2 mt-1'>
                                                                        <FormControlLabel control={<Checkbox checked={withObservation} onChange={HandleWithObservationCheckbox} />} sx={{ '.MuiFormControlLabel-label': { fontWeight: 550, fontSize: "15px", marginTop: "2px" } }} label="Observation" />
                                                                    </div>
                                                                    {(() => {
                                                                        if (classDivObj != null) {
                                                                            return (
                                                                                <div className='col-sm-2 mt-1' hidden={(classDivObj.classId > 8) ? true : false}>
                                                                                    <FormControlLabel control={<Checkbox checked={govtRule} onChange={HandleGovtRuleCheckbox} />} sx={{ '.MuiFormControlLabel-label': { fontWeight: 550, fontSize: "15px", marginTop: "2px" } }} label="Govt. Rule" />
                                                                                </div>
                                                                            )
                                                                        }
                                                                    })()}
                                                                    <div className='col-sm-2 mt-1'>
                                                                        <FormControlLabel control={<Checkbox checked={termExam} onChange={HandleTermExamCheckbox} />} sx={{ '.MuiFormControlLabel-label': { fontWeight: 550, fontSize: "15px", marginTop: "2px" } }} label="Term Exam" />
                                                                    </div>
                                                                    {/* </div> */}
                                                                </>
                                                            )
                                                        }
                                                    })()}

                                                    {(() => {
                                                        if (termExam == 1) {
                                                            return (
                                                                <>
                                                                    {/* <label className="col-sm-1" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Exam:</label> */}
                                                                    <div className='col-sm-3'>
                                                                        <Autocomplete
                                                                            id="combo-box-demo"
                                                                            PaperComponent={({ children }) => (
                                                                                <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                                                            )}
                                                                            options={examData}
                                                                            style={{ fontSize: '14px' }}
                                                                            getOptionLabel={option => (authUser.branchMedium == 1) ? option.nameMr : option.nameEn}
                                                                            size="small"
                                                                            value={examObj}
                                                                            onChange={(event, newvalue) => {
                                                                                setExamObj(newvalue);
                                                                            }}
                                                                            renderInput={params => (
                                                                                <TextField {...params} margin="dense" label="Select Exam" InputLabelProps={{ style: { fontSize: 14 } }} variant="outlined" required={(termExam == 1) ? true : false} />
                                                                            )}
                                                                        />
                                                                    </div>
                                                                </>
                                                            )
                                                        }
                                                    })()}
                                                </div>

                                                {/* <div className="row">
                                                        <div className="col-sm-4 offset-sm-2">
                                                            <button type="button" hidden={(termObj == 3) ? true : false} disabled={((termExam == 1) ? (classDivObj == null || classDivObj == "" || subjectObj == null || subjectObj == "" || termObj == null || termObj == "" || examObj == '' || examObj == null) : (classDivObj == null || classDivObj == "" || subjectObj == null || subjectObj == "" || termObj == null || termObj == "")) ? true : false} className="btn btn-primary btn-sm" onClick={() => getReportForSpecificSubjectTermWise()}>Show</button>
                                                        </div>
                                                    </div> */}

                                                {/* {(() => {

                                                        if (termObj == 3) {
                                                            return (
                                                                <>
                                                                    {(() => {
                                                                        if (classDivObj.classId === 9 || classDivObj.classId === 10) {
                                                                            return (
                                                                                <>
                                                                                    <div className="row">
                                                                                        <div className="col-sm-4 offset-sm-2">
                                                                                            <button type="button" disabled={(classDivObj == null || classDivObj == "" || subjectObj == null || subjectObj == "" || termObj == null || termObj == "") ? true : false} className="btn btn-primary btn-sm" onClick={() => aboveEightsubjectWiseSubMarkSheetPrint()}>Show</button>
                                                                                        </div>
                                                                                    </div>
                                                                                </>
                                                                            )
                                                                        }
                                                                        else {
                                                                            if (authUser.deptId == 4) {
                                                                                return (
                                                                                    <>
                                                                                        <div className="row">
                                                                                            <div className="col-sm-4 offset-sm-2">
                                                                                                <button type="button" disabled={(classDivObj == null || classDivObj == "" || subjectObj == null || subjectObj == "" || termObj == null || termObj == "") ? true : false} className="btn btn-primary btn-sm" onClick={() => aboveTenSubjectWiseFinalSubMarkSheetPrint()}>Show</button>
                                                                                            </div>
                                                                                        </div>
                                                                                    </>
                                                                                )
                                                                            }
                                                                            else {
                                                                                return (
                                                                                    <>
                                                                                        <div className="row">
                                                                                            <div className="col-sm-4 offset-sm-2">
                                                                                                <button type="button" disabled={(classDivObj == null || classDivObj == "" || subjectObj == null || subjectObj == "" || termObj == null || termObj == "") ? true : false} className="btn btn-primary btn-sm" onClick={() => subjectMarksheetTermAnnualPrint()}>Show</button>
                                                                                            </div>
                                                                                        </div>
                                                                                    </>
                                                                                )
                                                                            }
                                                                        }
                                                                    })()}
                                                                </>
                                                            )
                                                        }
                                                    })()} */}
                                            </>
                                        )
                                    }
                                    {/* else if (option == 1) {
                                            return (
                                                <>
                                                    {(() => {
                                                        if (termObj == 3) {
                                                            return (
                                                                <>
                                                                    {(() => {
                                                                        if (classDivObj.classId === 9 || classDivObj.classId === 10) {
                                                                            return (
                                                                                <>
                                                                                    <div className="row">
                                                                                        <div className="col-sm-4 offset-sm-2">
                                                                                            <button type="button" onClick={() => allFinalAndAboveEightSubMarkSheetPrint()} disabled={(classDivObj == null || classDivObj == "" || termObj == null || termObj == "") ? true : false} className="btn btn-primary btn-sm" >Show</button>
                                                                                        </div>
                                                                                    </div>
                                                                                </>
                                                                            )
                                                                        }
                                                                        else {
                                                                            return (
                                                                                <>
                                                                                    <div className="row">
                                                                                        <div className="col-sm-4 offset-sm-2">
                                                                                            <button type="button" onClick={() => allAndFinalSubjectMarkSheetPrint()} disabled={(classDivObj == null || classDivObj == "" || termObj == null || termObj == "") ? true : false} className="btn btn-primary btn-sm" >Show</button>
                                                                                        </div>
                                                                                    </div>
                                                                                </>
                                                                            )
                                                                        }
                                                                    })()}
                                                                </>
                                                            )
                                                        }
                                                        else {
                                                            return (
                                                                <>
                                                                    <div className="row">
                                                                        <div className="col-sm-4 offset-sm-2">
                                                                            <button type="button" hidden={(termObj == 3) ? true : false} onClick={() => getReportForallsubjectsTermWise()} disabled={((termExam == 1) ? (classDivObj == null || classDivObj == "" || termObj == null || termObj == "" || examObj == '' || examObj == null) : (classDivObj == null || classDivObj == "" || termObj == null || termObj == "")) ? true : false} className="btn btn-primary btn-sm" >Show</button>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            )
                                                        }
                                                    })()}
                                                </>
                                            )
                                        } */}
                                })()}

                                <div className="row mt-2">
                                    <div className="col-sm-4 offset-sm-2">
                                        <button type="submit" className="btn btn-primary btn-sm" >Show</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </>
            }

            {/* <ToastContainer position="top-right" theme="colored" /> */}
        </>
    )
}

export default SubjectMarkSheet