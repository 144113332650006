import { BaseIP, BaseURL, BaseURLMaster, dBaseURL, dBaseURLMaster } from "../../CommonServices/APIURL";

export const studentBaseUrlStudEntryMaster = BaseURL + "administration/studentEntry/";

export const BaseURLLc = BaseURL + "administration/LC/"

export const getStudentList = BaseURLLc + "getStudentLcBaseData/";

//localhost:8080/institute/sss/admission/reports/generateFeeReceiptReport?langId=1&id=1&acYear=2021&sid=3&bid=1
export const printAttendanceCertificate = BaseURL + "academic/reports/getAttendanceCertificate";

//http://192.168.195.102:8081/institute-sss/institute/sss/administration/LC/getStudentLcBaseDataByRegNo?regNo=13005&sid=3&bid=1
export const getStudentDetailsByRegNoAPIURL = BaseURL + "administration/LC/getStudentLcBaseDataByRegNo";