import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { getCityData } from '../../../CommonServices/InstituteMasterAPIURL';
import TitleLabel from '../../../CommonComponent/TitleLabel';
import { Autocomplete, Paper, TextField } from '@mui/material';
import { toast } from 'react-toastify';
import { getCityMasterAPIURL, saveCityMasterAPIURL, updateCityMasterAPIURL } from '../Services/SansthaCityMasterAPIURL';
import SansthaCityMasterTable from './SansthaCityMasterTable';

const SansthaCityMaster = () => {
    const titleId = "City Master";
    const authUser = useSelector((state) => state.user.value);
    // loader on/off
    const [loaderOption, setLoaderOption] = useState(false);

    //Note: This below states are declared for Save & Update(Disabled/unDisabled)
    const saveBtnStyle = "sansthaCitySaveBtn btn btn-primary btn-sm";
    const updateBtnStyle = "sansthaCityUpdateBtn btn btn-primary btn-sm";
    const displayNoneBtnStyle = "d-none";
    const [saveBtn, setSaveBtn] = useState(saveBtnStyle);
    const [updateBtn, setUpdateBtn] = useState(displayNoneBtnStyle);

    const [saveBtnDisabled, setSaveBtnDisabled] = useState(false);
    const [updateBtnDisabled, setUpdateBtnDisabled] = useState(false);

    const [id, setId] = useState();
    const [cityData, setCityData] = useState([]);
    const [city, setCity] = useState(null);
    const [kendra, setKendra] = useState("");
    const [kendraNo, setKendraNo] = useState("");
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);

    const TableHeading = [{ label: 'ID', key: 'id' }, { label: `City`, key: (authUser.branchMedium == 1) ? 'cityMr' : 'cityEn' }, { label: `Kno`, key: 'kendraNo' }, { label: `Kendra Name`, key: 'kendraName' }, { label: 'Edit', value: 'edit', isAction: true }, { label: 'Delete', value: 'delete', isAction: true }];

    useEffect(() => {
        getData();
    }, [authUser])

    const getData = async () => {
        // console.log(getCityData)
        await axios.get(`${getCityData}`)
            .then((response) => {
                //console.log(response.data)
                setCityData(response.data);
            })

        // console.log(getCityMasterAPIURL)
        await axios.get(`${getCityMasterAPIURL}`)
            .then((response) => {
                // console.log(response.data)
                setData(response.data);
                setFilteredData(response.data);
            })
    }

    const ClearData = () => {
        setId("");
        setCity(null);
        setKendra("");
        setKendraNo("");
        setSaveBtn(saveBtnStyle);
        setUpdateBtn(displayNoneBtnStyle);
    }

    const saveBtnClick = async () => {
        setLoaderOption(true);

        const saveObject = {
            "cityId": city.id,
            "kendraNo": kendraNo,
            "kendraName": kendra
        }
        //console.log(JSON.stringify(saveObject))
        await axios.post(`${saveCityMasterAPIURL}`, saveObject)
            .then((response) => {
                setLoaderOption(false);
                if (response.data == "ALREADYEXISTS") {
                    setLoaderOption(false);
                    toast.warn(`Kendra With this name or no is already exists.`)
                }
                else if (response.data == "SAVED") {
                    getData();
                    ClearData();
                    setSaveBtn(saveBtnStyle);
                    setUpdateBtn(displayNoneBtnStyle);
                    setLoaderOption(false);
                    toast.success("Saved successfully.");
                } else {
                    setLoaderOption(false);
                    toast.error("Operation failed.");
                }
            });


    };

    const updateBtnClick = async () => {
        setLoaderOption(true);
        const updateObject = {
            "id": id,
            "cityId": city.id,
            "kendraNo": kendraNo,
            "kendraName": kendra
        };

        await axios.put(`${updateCityMasterAPIURL}`, updateObject)
            .then((response) => {
                setLoaderOption(false);
                if (response.data == "ALREADYEXISTS") {
                    setLoaderOption(false);
                    toast.warn(`Kendra With this name or no is already exists.`)
                }
                else if (response.data == "UPDATED") {
                    getData();
                    ClearData();
                    setSaveBtn(saveBtnStyle);
                    setUpdateBtn(displayNoneBtnStyle);
                    setLoaderOption(false);
                    toast.success("Update sucessfully.");
                } else {
                    setLoaderOption(false);
                    toast.danger("Operation failed.");
                }
            });
    };

    const state = {
        button: 1
    };

    const onSubmit = e => {
        e.preventDefault();
        if (state.button === 1) {
            saveBtnClick();
        }
        if (state.button === 2) {
            updateBtnClick();
        }
    };

    return (
        <div>
            <TitleLabel titleId={titleId} />
            <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", position: "relative" }}>
                <form onSubmit={onSubmit}>
                    <input type="hidden" value={id} onChange={(e) => setId(e.target.value)} name="" />

                    <div className="row">
                        <label className="col-sm-1" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >City :</label>
                        <div className='col-sm-4'>
                            <Autocomplete
                                id="combo-box-demo"
                                PaperComponent={({ children }) => (
                                    <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                )}
                                // freeSolo
                                // disableClearable
                                onKeyPress={(e) => (e.key == "Enter") ? city : ""}
                                options={cityData}
                                value={city}
                                onChange={(event, newValue) => {
                                    //setNarrationId(newValue.id);
                                    //console.log(newValue.narrationDetail);
                                    setCity(newValue);
                                }}
                                style={{ fontSize: '14px' }}
                                getOptionLabel={option => (authUser.branchMedium == 1) ? option.nameMr : option.nameEn}
                                size="small"
                                renderInput={params => (
                                    <TextField {...params}
                                        margin='dense' label="City" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                )}
                            />
                        </div>
                    </div>

                    <div className="row my-2">
                        <label className="col-sm-1" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Kno :</label>
                        <div className='col-sm-2'>
                            <TextField
                                required
                                onKeyDown={(e) => (e.key === " " && kendraNo.length === 0) ? e.preventDefault() : ""}
                                fullWidth
                                margin='dense'
                                type='number'
                                value={kendraNo}
                                onChange={e => setKendraNo(e.target.value)}
                                onKeyPress={(e) => (e.key == "Enter") ? kendraNo : ""}
                                autoComplete='off'
                                size='small'
                                id="outlined-basic"
                                label="Kno"
                                variant="outlined"
                                InputLabelProps={{ style: { fontSize: 14 } }}
                                inputProps={{ maxLength: 120 }}
                            />
                        </div>
                    </div>

                    <div className="row my-2">
                        <label className="col-sm-1" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Kendra :</label>
                        <div className='col-sm-4'>
                            <TextField
                                required
                                onKeyDown={(e) => (e.key === " " && kendra.length === 0) ? e.preventDefault() : ""}
                                fullWidth
                                margin='dense'
                                value={kendra}
                                onChange={e => setKendra(e.target.value)}
                                onKeyPress={(e) => (e.key == "Enter") ? kendra : ""}
                                autoComplete='off'
                                size='small'
                                id="outlined-basic"
                                label="Kendra"
                                variant="outlined"
                                InputLabelProps={{ style: { fontSize: 14 } }}
                                inputProps={{ maxLength: 120 }}
                            />
                        </div>
                    </div>

                    <div className="row mt-3">
                        <div className="col-sm-12 offset-sm-1">
                            <button type="submit" onClick={() => (state.button = 1)} className={saveBtn} >Save</button>
                            <button type="submit" onClick={() => (state.button = 2)} className={updateBtn} >Update</button>
                            <button type="button" onClick={ClearData} className="btn btn-danger btn-sm" style={{ margin: '0px 4px' }} >Cancel</button>
                        </div>
                    </div>
                </form>

                <div className="tableData">
                    <SansthaCityMasterTable
                        TableHeading={TableHeading}
                        setId={setId}
                        setKendra={setKendra}
                        setKendraNo={setKendraNo}
                        setCity={setCity}
                        cityData={cityData}
                        getData={getData}
                        data={data}
                        setFilteredData={setFilteredData}
                        filteredData={filteredData}
                        setData={setData}
                        saveBtnStyle={saveBtnStyle}
                        updateBtnStyle={updateBtnStyle}
                        displayNoneBtnStyle={displayNoneBtnStyle}
                        setSaveBtn={setSaveBtn}
                        setUpdateBtn={setUpdateBtn}
                    />
                </div>
            </div>
        </div>
    )
}

export default SansthaCityMaster
