import { Autocomplete, Checkbox, FormControl, FormControlLabel, InputLabel, MenuItem, Paper, Select, TextField } from '@mui/material'
import React, { useEffect } from 'react'
import makeStyles from "@mui/styles/makeStyles";
import ReceiptIcon from '@mui/icons-material/Receipt';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import WorkIcon from '@mui/icons-material/Work';
import CreditCardIcon from '@mui/icons-material/CreditCard';

const useStyles = makeStyles(theme => ({
    menuPaper: {
        maxHeight: 200,
        "& ul": {
            backgroundColor: "#F4ECF7",
            //maxHeight: 160,
        },
        "& li": {
            // backgroundColor: "#F4ECF7", fontWeight: "550", fontSize: "14px" 
            fontSize: "14px",
            fontWeight: "550"
        },
    }
}));

const GroupHeadData = [
    {
        id: 1,
        Head1: "Income",
        Head2: "Asset"
    },
    {
        id: 2,
        Head1: "Expenditure",
        Head2: "Liability",
    },
];

const GroupBookData = [
    {
        id: 1,
        Heading: "Income And Expenditure"
    },
    {
        id: 2,
        Heading: "Balance Sheet"
    }
]


const AccountGroupUpdateForm = ({
    id, groupCode, groupHead, groupBook, glNameMarathi, glNameEnglish, index, isForward, sectionData,
    sectionId, sectionObj, recAndPayGroupData, recAndPayGroupObj, incExpAndBalSheetData, incExpAndBalSheetObj,
    setId, setGroupCode, setGroupHead, setGroupBook, setGlNameMarathi, setGlNameEnglish, setIndex,
    setIsForward, setSectionData, setSectionId, setSectionObj, setRecAndPayGroupData, setRecAndPayGroupObj,
    setIncExpAndBalSheetData, setIncExpAndBalSheetObj, HandleForwardCheckbox, authUser, setAccGroupForm, getIncExpAndBalSheetGroups,
    updateAccountGroup
}) => {
    const classes = useStyles();

    useEffect(() => {
        function handlekeydownEvent(event) {
            if (event.key === "Enter") {
                // console.log("Enter is pressed!")
                event.preventDefault();

                let btn1 = document.querySelector('.accountGroupUpdationBtn')
                if (btn1 !== null) {
                    btn1.click();
                }

            }
        }

        document.addEventListener('keypress', handlekeydownEvent)
        return () => {
            document.removeEventListener('keypress', handlekeydownEvent)
        }
    }, []);

    return (
        <>
            <form onSubmit={updateAccountGroup}>
                <input type="hidden" value={id} onChange={(e) => setId(e.target.value)} name="" />

                <div className="row">
                    <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Group Code :</label>
                    <div className='col-sm-4'>
                        <TextField
                            required
                            fullWidth
                            type="number"
                            autoComplete='off'
                            size='small'
                            id="outlined-basic"
                            label="Group Code"
                            variant="outlined"
                            margin='dense'
                            onWheel={(e) => e.target.blur()}
                            value={groupCode}
                            onChange={e => setGroupCode(e.target.value)}
                            onKeyPress={(e) => (e.key == "Enter") ? groupCode : ""}
                            InputLabelProps={{ style: { fontSize: 14 } }}
                            inputProps={{ maxLength: 120 }}
                        />
                    </div>
                </div>

                <div className="row my-2">
                    <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >G.L Name :</label>
                    <div className='col-sm-4'>
                        <TextField
                            required
                            fullWidth
                            onKeyDown={(e) => (e.key === " " && glNameMarathi.length === 0) ? e.preventDefault() : ""}
                            autoComplete='off'
                            size='small'
                            id="outlined-basic"
                            label="G.L Name Marathi"
                            variant="outlined"
                            margin='dense'
                            value={glNameMarathi}
                            onChange={(e) => setGlNameMarathi(e.target.value)}
                            onKeyPress={(e) => (e.key == "Enter") ? glNameMarathi : ""}
                            InputLabelProps={{ style: { fontSize: 14 } }}
                            inputProps={{ maxLength: 120 }}
                        />
                    </div>

                    <div className='col-sm-4'>
                        <TextField
                            required
                            fullWidth
                            onKeyDown={(e) => (e.key === " " && glNameEnglish.length === 0) ? e.preventDefault() : ""}
                            autoComplete='off'
                            size='small'
                            id="outlined-basic"
                            label="G.L Name English"
                            variant="outlined"
                            margin='dense'
                            value={glNameEnglish}
                            onChange={(e) => setGlNameEnglish(e.target.value)}
                            onKeyPress={(e) => (e.key == "Enter") ? glNameEnglish : ""}
                            InputLabelProps={{ style: { fontSize: 14 } }}
                            inputProps={{ maxLength: 120 }}
                        />
                    </div>
                </div>

                <div className="row my-2">
                    <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Group Book :</label>
                    <div className='col-sm-4'>
                        <FormControl fullWidth margin='dense'>
                            <InputLabel id='demo-simple-select-label' size="small" style={{ fontSize: '14px' }}>Group Book</InputLabel>
                            <Select
                                size='small'
                                fullWidth
                                required
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                MenuProps={{ classes: { paper: classes.menuPaper } }}
                                value={groupBook}
                                onChange={(e) => {
                                    setGroupBook(e.target.value);
                                    setGroupHead('');
                                    setIncExpAndBalSheetData([]);
                                    setIncExpAndBalSheetObj(null);
                                    getIncExpAndBalSheetGroups(e.target.value);
                                    // setSectionObj([]);
                                    // setRecAndPayGroupObj(null);
                                    // setIndex('');
                                    // setIsForward(0);
                                }}
                                onKeyPress={(e) => (e.key == "Enter") ? groupBook : ""}
                                label="Group Book"
                            >
                                {GroupBookData.map((item, index) => {
                                    return (
                                        <MenuItem key={index} value={item.id}>{item.Heading}</MenuItem>
                                    )
                                })}
                            </Select>
                        </FormControl>
                    </div>
                </div>

                <div className="row my-2">
                    <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Group Head :</label>
                    <div className='col-sm-4'>
                        <FormControl fullWidth margin='dense'>
                            <InputLabel id='demo-simple-select-label' size="small" style={{ fontSize: '14px' }}>Group Head</InputLabel>
                            <Select
                                size='small'
                                fullWidth
                                required
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                MenuProps={{ classes: { paper: classes.menuPaper } }}
                                value={groupHead}
                                onChange={(e) => {
                                    setGroupHead(e.target.value);
                                    setIncExpAndBalSheetData([]);
                                    setIncExpAndBalSheetObj(null);
                                    // setRecAndPayGroupObj(null);
                                    // setIndex('');
                                    // setSectionObj([]);
                                    // setIsForward(0);
                                }}
                                onKeyPress={(e) => (e.key == "Enter") ? groupHead : ""}
                                label="Group Head"
                            >
                                {GroupHeadData.map((item) => {
                                    if (`${groupBook}` == 1) {
                                        return (
                                            <MenuItem key={item.id} value={item.id}>{item.Head1}</MenuItem>
                                        )
                                    }
                                    else if (`${groupBook}` == 2) {
                                        return (
                                            <MenuItem key={item.id} value={item.id}>{item.Head2}</MenuItem>
                                        )
                                    }
                                })}
                            </Select>
                        </FormControl>
                    </div>
                </div>

                <div className="row my-2">
                    <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Section :</label>
                    <div className='col-sm-4'>
                        <Autocomplete
                            multiple
                            filterSelectedOptions
                            PaperComponent={({ children }) => (
                                <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                            )}
                            id="combo-box-demo"
                            options={sectionData}
                            onChange={(e, newValue) => {
                                //console.log(newValue.map(e=>e.id).join(','))
                                setSectionId(newValue.map(e => e.id).join(','));
                                setSectionObj(newValue);
                                // setRecAndPayGroupObj(null);
                                // setIndex('');
                                // setIsForward(0);
                            }}
                            disableCloseOnSelect
                            value={sectionObj}
                            onKeyPress={(e) => (e.key == "Enter") ? sectionObj : ""}
                            style={{ fontSize: '14px' }}
                            //getOptionLabel={option => (authUser.branchMedium == 1) ? option.nameMr : option.nameEn}
                            getOptionLabel={option => option.nameMr}
                            size="small"
                            renderInput={params => (
                                <TextField {...params} margin='dense' label="Select Section" variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }}
                                    required={(sectionObj.length == 0) ? true : false}
                                />
                            )}
                        />
                    </div>
                </div>

                <div className="row my-2" hidden={(groupBook == '') ? true : false}>
                    <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >{(groupBook == 1) ? 'Inc. & Exp.' : 'Bal. Sheet'}  Group :</label>
                    <div className='col-sm-4'>
                        <Autocomplete
                            fullWidth
                            PaperComponent={({ children }) => (
                                <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                            )}
                            id="combo-box-demo"
                            options={incExpAndBalSheetData}
                            onChange={(event, newValue) => {
                                setIncExpAndBalSheetObj(newValue)
                                // setRecAndPayGroupObj(null);
                                // setIndex('');
                                // setIsForward(0);
                            }}
                            value={incExpAndBalSheetObj}
                            onKeyPress={(e) => (e.key == "Enter") ? incExpAndBalSheetObj : ""}
                            style={{ fontSize: '14px' }}
                            getOptionLabel={option => (authUser.branchMedium == 1) ? option.nameMr : option.nameEn}
                            renderOption={(props, option) => (
                                <div className='' {...props} key={option.id}>
                                    <div className=''>
                                        {(authUser.branchMedium == 1) ? option.nameMr : option.nameEn} <br />
                                        <div
                                            className=''
                                            style={{
                                                display: "flex",
                                                flex: 1,
                                                fontSize: "12px",
                                                flexDirection: "row",
                                                justifyContent: "space-between",
                                                padding: "0px 10px",
                                                color: "#464964",
                                                fontWeight: "550"
                                                //gridRow: 1
                                            }}
                                        >
                                            <span>{(option.groupType == 1) ? ((option.groupHead == 1) ? <ReceiptIcon style={{ color: "#354AFA", fontSize: "14px" }} /> : <CurrencyRupeeIcon style={{ color: "#354AFA", fontSize: "14px" }} />) : ((option.groupHead == 1) ? <WorkIcon style={{ color: "#354AFA", fontSize: "14px" }} /> : <CreditCardIcon style={{ color: "#354AFA", fontSize: "14px" }} />)} {option.groupHeadName}</span>
                                        </div>
                                    </div>
                                </div>
                            )}
                            size="small"
                            renderInput={params => (
                                <TextField {...params} margin='dense' label={(groupBook == 1) ? 'Select Inc. & Exp. Group' : 'Select Bal. Sheet Group'} variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }}

                                />
                            )}
                        />
                    </div>
                </div>

                <div className="row my-2">
                    <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Rec. & Pay. Group :</label>
                    <div className='col-sm-4'>
                        <Autocomplete
                            fullWidth
                            PaperComponent={({ children }) => (
                                <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                            )}
                            id="combo-box-demo"
                            options={recAndPayGroupData}
                            onChange={(event, newValue) => {
                                setRecAndPayGroupObj(newValue)
                                // setIndex('');
                                // setIsForward(0);
                            }}
                            value={recAndPayGroupObj}
                            onKeyPress={(e) => (e.key == "Enter") ? recAndPayGroupObj : ""}
                            style={{ fontSize: '14px' }}
                            getOptionLabel={option => (authUser.branchMedium == 1) ? option.nameMr : option.nameEn}
                            renderOption={(props, option) => (
                                <div className='' {...props} key={option.id}>
                                    <div className=''>
                                        {(authUser.branchMedium == 1) ? option.nameMr : option.nameEn} <br />
                                        <div
                                            className=''
                                            style={{
                                                display: "flex",
                                                flex: 1,
                                                fontSize: "12px",
                                                flexDirection: "row",
                                                justifyContent: "space-between",
                                                padding: "0px 10px",
                                                color: "#464964",
                                                fontWeight: "550"
                                                //gridRow: 1
                                            }}
                                        >
                                            <span>{(option.rcHeader == 1) ? <ReceiptIcon style={{ color: "#354AFA", fontSize: "14px" }} /> : <CurrencyRupeeIcon style={{ color: "#354AFA", fontSize: "14px" }} />} {option.rcHeaderName}</span>
                                        </div>
                                    </div>
                                </div>
                            )}
                            size="small"
                            renderInput={params => (
                                <TextField {...params} margin='dense' label="Select Receipt & Payment Group" variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }}

                                />
                            )}
                        />
                    </div>
                </div>

                <div className="row my-2">
                    <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Index :</label>
                    <div className='col-sm-2'>
                        <TextField
                            fullWidth
                            autoComplete='off'
                            type="number"
                            size='small'
                            id="outlined-basic"
                            label="Index"
                            onWheel={(e) => e.target.blur()}
                            variant="outlined"
                            margin='dense'
                            value={index}
                            onChange={(e) => setIndex(e.target.value)}
                            onKeyPress={(e) => (e.key == "Enter") ? index : ""}
                            InputLabelProps={{ style: { fontSize: 14 } }}
                        />
                    </div>

                    <div className='col-sm-2 mt-2'>
                        <FormControlLabel control={<Checkbox checked={isForward} onChange={HandleForwardCheckbox} />} label="Forward" />
                    </div>
                </div>

                <div className="row mt-3">
                    <div className="col-sm-12 offset-sm-2">
                        <button type="submit" className="accountGroupUpdationBtn btn btn-sm btn-primary" >Update</button>
                        <button type="button" onClick={() => setAccGroupForm(1)} className="btn btn-danger btn-sm" style={{ margin: '0px 4px' }} >Cancel</button>
                    </div>
                </div>
            </form>
        </>
    )
}

export default AccountGroupUpdateForm