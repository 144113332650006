import React, { useState, useEffect } from 'react';
import TitleLabel from "../../CommonComponent/TitleLabel";
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { useSelector } from "react-redux";
import { useParams } from 'react-router-dom';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import axios from 'axios';
import Paper from "@mui/material/Paper";
import { useNavigate } from 'react-router-dom';
import Autocomplete from "@mui/material/Autocomplete";
import { ToastContainer, toast } from 'react-toastify';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import SearchIcon from '@mui/icons-material/Search';
import AddNewConcession from '../../CommonComponent/AddNewConcession';
import AddNewSubCaste from '../../CommonComponent/AddNewSubCaste';
import AddNewCaste from '../../CommonComponent/AddNewCaste';
import AddNewReligionModal from '../../CommonComponent/AddNewReligionModal';
import Loader from '../../CommonComponent/Loader';

//APIURL
import { BaseURLMasterCasteCategory } from '../../CommonServices/CasteCategoryMasterAPIURL';
import { ClassMasterGetMapping, getClassWithDiv } from '../../CommonServices/ClassMasterAPIURL';
import { BaseURLMasterConsession } from '../../CommonServices/ConcessionMasterAPIURL';
import { BaseURLMasterCastes } from '../../CommonServices/CasteMasterAPIURL';
import { SubCasteGetMapping } from '../../CommonServices/SubCasteMasterAPIURL';
import { ReligionMasterGetMapping } from '../../CommonServices/StudentRegistrationAPIURL';
import { CityMasterGetMapping } from '../../CommonServices/CityMasterAPIURL';
import { MotherTongueMasterGetMapping } from '../../CommonServices/MotherTongueMasterAPIURL';

import { getAdmissionFormSaleAPIURL, saveAdmissionFormReceiveDataAPIURL, getAdmissionFormReceiveAPIURL, updateAdmissionFormReceiveDataAPIURL } from '../Services/AdmFormReceiveAPIURL'
import moment from 'moment';
import { Box } from '@mui/material';
import AddNewCityModal from '../../CommonComponent/AddNewCityModal';

const AdmissionFormReceive = () => {
    //const titleId = "Admission Form Receive";
    const [titleId, setTitleId] = useState("")
    const authUser = useSelector((state) => state.user.value);
    const navigate = useNavigate();
    let { fNo, EditFlag } = useParams();

    //Note: This below states are declared for Save & Update(Disabled/unDisabled)
    const saveBtnStyle = "btn btn-primary btn-sm";
    const updateBtnStyle = "btn btn-primary btn-sm";
    const displayNoneBtnStyle = "d-none";
    const [saveBtn, setSaveBtn] = useState(saveBtnStyle);
    const [updateBtn, setUpdateBtn] = useState(displayNoneBtnStyle);
    const [saveBtnDisabled, setSaveBtnDisabled] = useState(false);
    const [updateBtnDisabled, setUpdateBtnDisabled] = useState(true);

    const [loaderOption, setLoaderOption] = useState(false);

    //Data
    const [data, setData] = useState([]);
    const [religionData, setReligionData] = useState([]);
    const [concessionData, setConcessionData] = useState([]);
    const [casteData, setCasteData] = useState([]);
    const [subCasteData, setSubCasteData] = useState([]);
    const [categoryData, setCategoryData] = useState([]);
    const [classDivData, setClassDivData] = useState([]);
    const [classData, setClassData] = useState([]);
    const [cityData, setCityData] = useState([]);
    const [motherTongueData, setMotherTongueData] = useState([]);

    //Form Data
    const [id, setId] = useState();
    const [formNo, setFormNo] = useState("");
    const [categoryObj, setCategoryObj] = useState(null);
    const [classObj, setClassObj] = useState(null);

    const [firstNameMr, setFirstNameMr] = useState("");
    const [firstNameEn, setFirstNameEn] = useState("");
    const [middleNameMr, setMiddleNameMr] = useState("");
    const [middleNameEn, setMiddleNameEn] = useState("");
    const [lastNameMr, setLastNameMr] = useState("");
    const [lastNameEn, setLastNameEn] = useState("");
    const [motherNameMr, setMotherNameMr] = useState("");
    const [motherNameEn, setMotherNameEn] = useState("");

    const [gender, setGender] = useState(1);
    const [examLanguage, setExamLanguage] = useState(1);

    const [currentAdd, setCurrentAdd] = useState("");
    const [parmanentAdd, setParmanentAdd] = useState("");
    const [mobileNo, setMobileNo] = useState("");
    const [mobileNo2, setMobileNo2] = useState("");
    const [cityObj, setCityObj] = useState(null);
    const [birthPlace, setBirthPlace] = useState("");
    const [birthDate, setBirthDate] = useState(null);

    const [concessionObj, setConcessionObj] = useState(null);
    const [religionObj, setReligionObj] = useState(null);

    const [casteObj, setCasteObj] = useState(null);
    const [subCasteObj, setSubCasteObj] = useState(null);

    const [saralId, setSaralId] = useState("");
    const [aadharNo, setAadharNo] = useState("");
    const [previousSchool, setPreviousSchool] = useState("");

    const [lastClassAttend, setLastClassAttend] = useState(null);
    const [lastSchoolUdiese, setLastSchoolUdiese] = useState("");

    const [parentIncome, setParentIncome] = useState("");
    const [parentOccupation, setParentOccupation] = useState("");
    const [payingFree, setPayingFree] = useState(1)
    const [motherTongueObj, setmotherTongueObj] = useState(null);

    const [markObtained, setMarkObtained] = useState("");
    const [totalMark, setTotalMark] = useState("");
    const [percantage, setPercantage] = useState("");

    const [mathsMark, setMathsMark] = useState("");
    const [scienceMark, setScienceMark] = useState("");
    const [englishMark, setEnglishMark] = useState("");

    const [studentHeight, setStudentHeight] = useState("");
    const [studentWeight, setStudentWeight] = useState("");

    const [seatNo, setSeatNo] = useState('');


    useEffect(() => {
        if (EditFlag == 1) {
            setTitleId("Admission Form Receive");
        }
        else if (EditFlag == 2) {
            setTitleId("Update Received Form ");
        }
        if (authUser.sessionYear !== null && authUser.branchId !== null && authUser.deptId !== null) {
            getData();
        }
    }, [authUser])

    const getData = () => {
        if (EditFlag == 2) {
            getEditData();
            setSaveBtnDisabled(true);
            setUpdateBtnDisabled(false);
            setSaveBtn(displayNoneBtnStyle);
            setUpdateBtn(updateBtnStyle)
        }
        else if (EditFlag == 1) {
            getNewData();
            setSaveBtnDisabled(false);
            setUpdateBtnDisabled(true);
            setSaveBtn(saveBtnStyle);
            setUpdateBtn(displayNoneBtnStyle)
        }
    }

    const getEditData = async () => {
        setLoaderOption(true);
        // console.log(`${getAdmissionFormReceiveAPIURL}?formNo=${fNo}&sid=${authUser.deptId}&bid=${authUser.branchId}&acYear=${authUser.sessionYear}`)
        await axios.get(`${getAdmissionFormReceiveAPIURL}?formNo=${fNo}&sid=${authUser.deptId}&bid=${authUser.branchId}&acYear=${authUser.sessionYear}`)
            .then((res) => {
                if (res.data !== null) {
                    // console.log(JSON.stringify(res.data))
                    const tempData = res.data;
                    setId(tempData.id);
                    setFormNo(tempData.formNo);
                    setFirstNameMr(tempData.firstNameMr);
                    setFirstNameEn(tempData.firstNameEn);
                    setMiddleNameEn(tempData.middleNameEn);
                    setMiddleNameMr(tempData.middleNameMr);
                    setLastNameEn(tempData.lastNameEn);
                    setLastNameMr(tempData.lastNameMr);
                    setMotherNameEn(tempData.motherNameEn);
                    setMotherNameMr(tempData.motherNameMr);
                    setGender(tempData.gender);
                    setExamLanguage(tempData.examLanguage)
                    setCurrentAdd(tempData.currentAddress);
                    setParmanentAdd(tempData.permanentAddress);
                    setMobileNo(tempData.mobileNo);
                    setMobileNo2(tempData.phoneNo);
                    setBirthPlace(tempData.placeOfBirth);
                    setBirthDate(tempData.dateOfBirth);
                    setSaralId(tempData.saralId);
                    setAadharNo(tempData.aadhaarNo);
                    setPreviousSchool(tempData.oldSchool);
                    setLastSchoolUdiese(tempData.oldSchoolUDiseNo);
                    setParentIncome(tempData.parentIncome);
                    setParentOccupation(tempData.parentOccupation);
                    setPayingFree(tempData.payingOrFree);
                    setMarkObtained(tempData.marksObtained);
                    setTotalMark(tempData.totalMarks);
                    setPercantage(tempData.percentage);
                    setStudentHeight(tempData.height);
                    setStudentWeight(tempData.weight);
                    setScienceMark(tempData.scienceMarks);
                    setMathsMark(tempData.mathsMarks);
                    setEnglishMark(tempData.englishMarks);
                    setSeatNo(tempData.seatNo);

                    axios.get(`${BaseURLMasterCasteCategory}`)
                        .then((response) => {
                            const tempCategoryData = response.data;
                            setCategoryData(response.data);
                            setCategoryObj(tempCategoryData.find((e) => e.id == tempData.categoryId))
                        })

                    axios.get(`${getClassWithDiv}?sid=${authUser.deptId}&bid=${authUser.branchId}`)
                        .then((response) => {
                            const tempClassData = response.data;
                            setClassDivData(response.data);
                            setClassObj(tempClassData.find((e) => e.divisionId == tempData.classId))
                            //setLastClassAttend(tempClassData.find((e) => e.divisionId == tempData.lastClass))
                        })
                    axios.get(`${ClassMasterGetMapping}`)
                        .then((response) => {
                            setClassData(response.data);
                            const tempClassData = response.data;
                            setLastClassAttend(tempClassData.find((e) => e.id == tempData.lastClass))
                        })

                    axios.get(`${CityMasterGetMapping}`)
                        .then((response) => {
                            const tempCityData = response.data;
                            setCityData(response.data);
                            setCityObj(tempCityData.find((e) => e.id == tempData.cityId))
                        })

                    axios.get(`${BaseURLMasterConsession}`)
                        .then((response) => {
                            const tempConcessionData = response.data;
                            setConcessionData(response.data);
                            setConcessionObj(tempConcessionData.find((e) => e.id == tempData.concessionId))
                        })

                    axios.get(`${ReligionMasterGetMapping}`)
                        .then((response) => {
                            const tempReligionData = response.data;
                            setReligionData(response.data);
                            setReligionObj(tempReligionData.find((e) => e.id == tempData.religionId))
                        })

                    axios.get(`${BaseURLMasterCastes}`)
                        .then((response) => {
                            const tempCasteData = response.data;
                            setCasteData(response.data);
                            setCasteObj(tempCasteData.find((e) => e.id == tempData.casteId))
                        })

                    axios.get(`${SubCasteGetMapping}`)
                        .then((response) => {
                            const tempSubCasteData = response.data;
                            setSubCasteData(response.data);
                            setSubCasteObj(tempSubCasteData.find((e) => e.id == tempData.subCasteId))
                        })

                    axios.get(`${MotherTongueMasterGetMapping}`)
                        .then((response) => {
                            const tempMotherTongueData = response.data;
                            setMotherTongueData(response.data);
                            setmotherTongueObj(tempMotherTongueData.find((e) => e.id == tempData.motherTongue))
                            setLoaderOption(false)
                        })

                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const getNewData = async () => {
        setLoaderOption(true)

        await axios.get(`${BaseURLMasterConsession}`)
            .then((response) => {
                setConcessionData(response.data);
            })
        await axios.get(`${BaseURLMasterCastes}`)
            .then((response) => {
                setCasteData(response.data);
            })
        await axios.get(`${SubCasteGetMapping}`)
            .then((response) => {
                setSubCasteData(response.data);
            })
        await axios.get(`${ReligionMasterGetMapping}`)
            .then((response) => {
                setReligionData(response.data);
            })
        await axios.get(`${BaseURLMasterCasteCategory}`)
            .then((response) => {
                setCategoryData(response.data);
            })
        await axios.get(`${getClassWithDiv}?sid=${authUser.deptId}&bid=${authUser.branchId}`)
            .then((response) => {
                setClassDivData(response.data);
            })

        await axios.get(`${ClassMasterGetMapping}`)
            .then((response) => {
                setClassData(response.data);
            })
        await axios.get(`${CityMasterGetMapping}`)
            .then((response) => {
                setCityData(response.data);
            })
        await axios.get(`${MotherTongueMasterGetMapping}`)
            .then((response) => {
                setMotherTongueData(response.data);
                setLoaderOption(false)
            })

    }

    const getSaleFormData = async () => {
        if (formNo !== "") {
            await axios.get(`${getAdmissionFormSaleAPIURL}?acYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&formNo=${formNo}`)
                .then((res) => {
                    if (res.data === "NOTFOUND") {
                        toast.error(`Form number ${formNo} data not exist.`)
                    }
                    else if (res.data !== null) {
                        const tempData = res.data;
                        // console.log(JSON.stringify(tempData))
                        setFirstNameEn(tempData.firstNameEn)
                        setFirstNameMr(tempData.firstNameMr)
                        setMiddleNameEn(tempData.middleNameEn)
                        setMiddleNameMr(tempData.middleNameMr)
                        setLastNameEn(tempData.lastNameEn)
                        setLastNameMr(tempData.lastNameMr)
                        setMotherNameEn(tempData.motherNameEn)
                        setMotherNameMr(tempData.motherNameMr)
                        setGender(tempData.gender)
                        setExamLanguage(tempData.examLanguage)
                        setMobileNo(tempData.mobileNo)
                        setPercantage(tempData.percentage)
                        setCategoryObj(categoryData.find((e) => e.id == tempData.categoryId))
                        setClassObj(classDivData.find((e) => e.id == tempData.classId))
                    }
                    else {
                        toast.error("Something went wrong.")
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        }
        else {
            toast.warn("Form Number is Empty")
        }

    }

    const saveFun = async () => {
        setLoaderOption(true);
        const saveObject = {
            "formNo": formNo,
            "firstNameMr": firstNameMr,
            "firstNameEn": firstNameEn,
            "middleNameMr": middleNameMr,
            "middleNameEn": middleNameEn,
            "lastNameMr": lastNameMr,
            "lastNameEn": lastNameEn,
            "motherNameEn": motherNameEn,
            "motherNameMr": motherNameMr,
            "currentAddress": currentAdd,
            "permanentAddress": parmanentAdd,
            "gender": gender,
            "examLanguage": examLanguage,
            "mobileNo": mobileNo,
            "phoneNo": mobileNo2,
            "categoryId": categoryObj.id,
            "cityId": cityObj.id,
            "placeOfBirth": birthPlace,
            "dateOfBirth": moment(birthDate).format("DD/MM/YYYY"),
            "concessionId": (concessionObj == null) ? 0 : concessionObj.id,
            "religionId": religionObj.id,
            "casteId": casteObj.id,
            "subCasteId": (subCasteObj == null) ? 0 : subCasteObj.id,
            "saralId": saralId,
            "aadhaarNo": aadharNo,
            "oldSchool": previousSchool,
            "lastClass": (lastClassAttend == null) ? 0 : lastClassAttend.id,
            "oldSchoolUDiseNo": lastSchoolUdiese,
            "parentIncome": parentIncome,
            "parentOccupation": parentOccupation,
            "payingOrFree": payingFree,
            "motherTongue": (motherTongueObj == null) ? 0 : motherTongueObj.id,
            "marksObtained": markObtained,
            "totalMarks": totalMark,
            "percentage": percantage,
            "classId": (classObj == null) ? 0 : classObj.divisionId,
            "height": studentHeight,
            "weight": studentWeight,
            "sessionYear": authUser.sessionYear,
            "sectionId": authUser.deptId,
            "branchId": authUser.branchId,
            "scienceMarks": scienceMark,
            "mathsMarks": mathsMark,
            "englishMarks": englishMark,
            "seatNo": (seatNo == '') ? null : seatNo
        }
        // console.log('====================================');
        // console.log(saveObject);
        // console.log('====================================');
        await axios.post(saveAdmissionFormReceiveDataAPIURL, saveObject)
            .then((res) => {
                if (res.data === "SAVED") {
                    setLoaderOption(false);
                    toast.success("Saved Successfully");
                    clearData();
                }
                else if (res.data === "FORMNOTFOUND") {
                    toast.warn("Form Number Incorrect");
                }
                else if (res.data === "ALREADYEXIST") {
                    setLoaderOption(false);
                    toast.warn("Form Already Saved");
                }
                else {
                    setLoaderOption(false);
                    toast.error("Something went wrong")
                }
            })
            .catch((err) => {
                setLoaderOption(false);
                toast.error("Something went wrong, please check.")
                console.log(err)
            })
    }

    const updateData = async () => {
        setLoaderOption(true);
        // console.log(classObj.divisionId + "classObj.divisionIdclassObj.divisionId")
        const updateObject = {
            "id": id,
            "formNo": formNo,
            "firstNameMr": firstNameMr,
            "firstNameEn": firstNameEn,
            "middleNameMr": middleNameMr,
            "middleNameEn": middleNameEn,
            "lastNameMr": lastNameMr,
            "lastNameEn": lastNameEn,
            "motherNameEn": motherNameEn,
            "motherNameMr": motherNameMr,
            "currentAddress": currentAdd,
            "permanentAddress": parmanentAdd,
            "gender": gender,
            "examLanguage": examLanguage,
            "mobileNo": mobileNo,
            "phoneNo": mobileNo2,
            "categoryId": categoryObj.id,
            "cityId": cityObj.id,
            "placeOfBirth": birthPlace,
            "dateOfBirth": moment(birthDate).format("DD/MM/YYYY"),
            "concessionId": (concessionObj == null) ? 0 : concessionObj.id,
            "religionId": religionObj.id,
            "casteId": casteObj.id,
            "subCasteId": (subCasteObj == null) ? 0 : subCasteObj.id,
            "saralId": saralId,
            "aadhaarNo": aadharNo,
            "oldSchool": previousSchool,
            "lastClass": lastClassAttend ? lastClassAttend.id : null,
            "oldSchoolUDiseNo": lastSchoolUdiese,
            "parentIncome": parentIncome,
            "parentOccupation": parentOccupation,
            "payingOrFree": payingFree,
            "motherTongue": motherTongueObj ? motherTongueObj.id : null,
            "marksObtained": markObtained,
            "totalMarks": totalMark,
            "percentage": percantage,
            "classId": classObj.divisionId,
            "height": studentHeight,
            "weight": studentWeight,
            "sessionYear": authUser.sessionYear,
            "sectionId": authUser.deptId,
            "branchId": authUser.branchId,
            "scienceMarks": scienceMark,
            "mathsMarks": mathsMark,
            "englishMarks": englishMark,
            "seatNo": (seatNo == '') ? null : seatNo
        }

        await axios.put(updateAdmissionFormReceiveDataAPIURL, updateObject)
            .then((res) => {
                if (res.data === "SAVED") {
                    setLoaderOption(false);
                    toast.success("Updated Successfully");
                    navigate("/Home/adm302/2");
                }
                else {
                    setLoaderOption(false);
                    toast.error("Something went wrong")
                }
            })
            .catch((err) => {
                setLoaderOption(false);
                toast.error("Something went wrong, please check.")
                console.log(err)
            })

    }

    const state = {
        button: 0
    };
    const onSubmit = e => {
        e.preventDefault();
        if (state.button === 1) {
            saveFun();
        }
        if (state.button === 2) {
            updateData();
        }
    };

    const clearData = () => {
        setId();
        setFormNo("");
        setCategoryObj(null);
        setClassObj(null);

        setFirstNameMr("");
        setFirstNameEn("");
        setMiddleNameMr("");
        setMiddleNameEn("");
        setLastNameMr("");
        setLastNameEn("");
        setMotherNameMr("");
        setMotherNameEn("");

        setGender(1);
        setExamLanguage(1);

        setCurrentAdd("");
        setParmanentAdd("");
        setMobileNo("");
        setCityObj(null);
        setBirthPlace("");
        setBirthDate(null);

        setConcessionObj(null);
        setReligionObj(null);

        setCasteObj(null);
        setSubCasteObj(null);

        setSaralId("");
        setAadharNo("");
        setPreviousSchool("");

        setLastClassAttend(null);
        setLastSchoolUdiese("");

        setParentIncome("");
        setParentOccupation("");
        setPayingFree(1)
        setmotherTongueObj(null);

        setMarkObtained("");
        setTotalMark("");
        setPercantage("");

        setMathsMark("");
        setScienceMark("");
        setEnglishMark("");

        setStudentHeight("");
        setStudentWeight("");

        setSeatNo('');
    }


    return (
        (loaderOption === true) ?
            <Loader />
            :
            <>
                <TitleLabel titleId={titleId} />
                <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: "500px" }}>
                    <form onSubmit={onSubmit}>
                        <div className="row">
                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Form No:</label>
                            <div className='col-sm-2'>
                                <TextField
                                    required
                                    value={formNo}
                                    type="number"
                                    onChange={(e) => setFormNo(e.target.value)}
                                    fullWidth
                                    autoComplete='off'
                                    size='small'
                                    id="outlined-basic"
                                    label="Form No"
                                    onWheel={(e) => e.target.blur()}
                                    variant="outlined"
                                    margin='dense'
                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                    inputProps={{ maxLength: 10 }}
                                />

                            </div>
                            <div className='col-sm-2'>
                                <button type='button' style={{ marginTop: "8px" }} className='btn btn-primary btn-sm' onClick={() => getSaleFormData()}>
                                    <SearchIcon fontSize='small' style={{ marginLeft: "-6px", fontWeight: 600 }} /> SEARCH
                                </button>
                            </div>
                        </div>
                        <hr />

                        <div className="row my-2">
                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Seat No. :</label>
                            <div className='col-sm-3'>
                                <TextField
                                    //required
                                    value={seatNo}
                                    onChange={(e) => setSeatNo(e.target.value)}
                                    fullWidth
                                    autoComplete='off'
                                    size='small'
                                    id="outlined-basic"
                                    label="Seat No."
                                    variant="outlined"
                                    margin='dense'
                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                    inputProps={{ maxLength: 120 }}
                                />
                            </div>
                        </div>

                        <div className="row my-2">
                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Category :</label>
                            <div className="col-sm-3">
                                <Autocomplete
                                    id="combo-box-demo"
                                    PaperComponent={({ children }) => (
                                        <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                    )}
                                    options={categoryData}
                                    value={categoryObj}
                                    onChange={(event, newValue) => {
                                        setCategoryObj(newValue)
                                    }}
                                    style={{ fontSize: '14px' }}
                                    getOptionLabel={option => option.nameMr + " / " + option.nameEn}
                                    size="small"
                                    renderInput={params => (
                                        <TextField {...params} margin='dense' label="Select Category" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                    )}
                                />
                            </div>

                            <label className="col-sm-1" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Class :</label>
                            <div className="col-sm-2">
                                <Autocomplete
                                    id="combo-box-demo"
                                    PaperComponent={({ children }) => (
                                        <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                    )}
                                    options={classDivData}
                                    value={classObj}
                                    onChange={(event, newValue) => {
                                        setClassObj(newValue)
                                    }}
                                    style={{ fontSize: '14px' }}
                                    getOptionLabel={option => (authUser.branchMedium == 1) ? option.classNameMr + " " + option.divisionNameMr : option.classNameEn + " " + option.divisionNameEn}
                                    size="small"
                                    renderInput={params => (
                                        <TextField {...params} margin='dense' label="Select Class" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} />
                                    )}
                                />
                            </div>
                        </div>

                        <div className="row my-2">
                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >First Name :</label>
                            <div className='col-sm-3'>
                                <TextField
                                    required={(authUser.branchMedium == 1) ? true : false}
                                    value={firstNameMr}
                                    onChange={(e) => setFirstNameMr(e.target.value)}
                                    fullWidth
                                    autoComplete='off'
                                    size='small'
                                    id="outlined-basic"
                                    label="First Name Mr"
                                    variant="outlined"
                                    margin='dense'
                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                    inputProps={{ maxLength: 120 }}
                                />
                            </div>

                            <div className='col-sm-3'>
                                <TextField
                                    required={(authUser.branchMedium == 2) ? true : false}
                                    value={firstNameEn}
                                    onChange={(e) => setFirstNameEn(e.target.value)}
                                    fullWidth
                                    autoComplete='off'
                                    size='small'
                                    id="outlined-basic"
                                    label="First Name En"
                                    variant="outlined"
                                    margin='dense'
                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                    inputProps={{ maxLength: 120 }}
                                />
                            </div>
                        </div>

                        <div className="row my-2">
                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Middle Name :</label>
                            <div className='col-sm-3'>
                                <TextField
                                    required={(authUser.branchMedium == 1) ? true : false}
                                    value={middleNameMr}
                                    onChange={(e) => setMiddleNameMr(e.target.value)}
                                    fullWidth
                                    autoComplete='off'
                                    size='small'
                                    id="outlined-basic"
                                    label="Middle Name Mr"
                                    variant="outlined"
                                    margin='dense'
                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                    inputProps={{ maxLength: 120 }}
                                />
                            </div>

                            <div className='col-sm-3'>
                                <TextField
                                    required={(authUser.branchMedium == 2) ? true : false}
                                    value={middleNameEn}
                                    onChange={(e) => setMiddleNameEn(e.target.value)}
                                    fullWidth
                                    autoComplete='off'
                                    size='small'
                                    id="outlined-basic"
                                    label="Middle Name En"
                                    variant="outlined"
                                    margin='dense'
                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                    inputProps={{ maxLength: 120 }}
                                />
                            </div>

                        </div>

                        <div className="row my-2">
                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Last Name :</label>
                            <div className='col-sm-3'>
                                <TextField
                                    required={(authUser.branchMedium == 1) ? true : false}
                                    value={lastNameMr}
                                    onChange={(e) => setLastNameMr(e.target.value)}
                                    fullWidth
                                    autoComplete='off'
                                    size='small'
                                    id="outlined-basic"
                                    label="Last Name Mr"
                                    variant="outlined"
                                    margin='dense'
                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                    inputProps={{ maxLength: 120 }}
                                />
                            </div>

                            <div className='col-sm-3'>
                                <TextField
                                    required={(authUser.branchMedium == 2) ? true : false}
                                    value={lastNameEn}
                                    onChange={(e) => setLastNameEn(e.target.value)}
                                    fullWidth
                                    autoComplete='off'
                                    size='small'
                                    id="outlined-basic"
                                    label="Last Name En"
                                    variant="outlined"
                                    margin='dense'
                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                    inputProps={{ maxLength: 120 }}
                                />
                            </div>

                        </div>

                        <div className="row my-2">
                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Mother Name :</label>
                            <div className='col-sm-3'>
                                <TextField
                                    required={(authUser.branchMedium == 1) ? true : false}
                                    value={motherNameMr}
                                    onChange={(e) => setMotherNameMr(e.target.value)}
                                    fullWidth
                                    autoComplete='off'
                                    size='small'
                                    id="outlined-basic"
                                    label="Mother Name Mr"
                                    variant="outlined"
                                    margin='dense'
                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                    inputProps={{ maxLength: 120 }}
                                />
                            </div>

                            <div className='col-sm-3'>
                                <TextField
                                    required={(authUser.branchMedium == 2) ? true : false}
                                    value={motherNameEn}
                                    onChange={(e) => setMotherNameEn(e.target.value)}
                                    fullWidth
                                    autoComplete='off'
                                    size='small'
                                    id="outlined-basic"
                                    label="Mother Name En"
                                    variant="outlined"
                                    margin='dense'
                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                    inputProps={{ maxLength: 120 }}
                                />
                            </div>
                        </div>

                        <div className="row my-2">
                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "14px" }} >Gender :</label>
                            <div className='col-sm-3'>
                                <RadioGroup row aria-label="gender" margin="dense" name="customized-radios" style={{ marginTop: "6px" }} value={gender} onChange={(e) => setGender(parseInt(e.target.value))}>
                                    <FormControlLabel value={1} control={<Radio color='primary' />} label="Male" />
                                    <FormControlLabel value={2} control={<Radio color='primary' />} label="Female" />
                                </RadioGroup>
                            </div>
                        </div>

                        <div className="row my-2">
                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Current Address :</label>
                            <div className='col-sm-10'>
                                <TextField
                                    // required
                                    value={currentAdd}
                                    onChange={(e) => {
                                        setCurrentAdd(e.target.value);
                                        setParmanentAdd(e.target.value);
                                    }}
                                    fullWidth
                                    autoComplete='off'
                                    size='small'
                                    id="outlined-basic"
                                    label="Current Address"
                                    variant="outlined"
                                    margin='dense'
                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                    inputProps={{ maxLength: 120 }}
                                />
                            </div>
                        </div>

                        <div className="row my-2">
                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Parmanent Address :</label>
                            <div className='col-sm-10'>
                                <TextField
                                    // required
                                    value={parmanentAdd}
                                    onChange={(e) => setParmanentAdd(e.target.value)}
                                    fullWidth
                                    autoComplete='off'
                                    size='small'
                                    id="outlined-basic"
                                    label="Parmanent Address"
                                    variant="outlined"
                                    margin='dense'
                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                    inputProps={{ maxLength: 120 }}
                                />
                            </div>
                        </div>

                        <div className="row my-2">
                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Mobile No 1 :</label>
                            <div className='col-sm-3'>
                                <TextField
                                    required
                                    value={mobileNo}
                                    onChange={(e) => setMobileNo(e.target.value)}
                                    fullWidth
                                    autoComplete='off'
                                    size='small'
                                    id="outlined-basic"
                                    label="Mobile No 1"
                                    variant="outlined"
                                    margin='dense'
                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                    inputProps={{ maxLength: 120 }}
                                />
                            </div>

                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Mobile No 2 :</label>
                            <div className='col-sm-3'>
                                <TextField
                                    value={mobileNo2}
                                    onChange={(e) => setMobileNo2(e.target.value)}
                                    fullWidth
                                    autoComplete='off'
                                    size='small'
                                    id="outlined-basic"
                                    label="Mobile No 2"
                                    variant="outlined"
                                    margin='dense'
                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                    inputProps={{ maxLength: 120 }}
                                />
                            </div>
                        </div>

                        <div className="row my-2">
                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Birth Date :</label>
                            <div className='col-sm-3'>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        label="Date Of Birth"
                                        value={birthDate}
                                        onChange={(newValue) => {
                                            setBirthDate(newValue);
                                        }}
                                        inputFormat="dd/MM/yyyy"
                                        renderInput={(params) =>
                                            <TextField
                                                {...params}
                                                margin='dense'
                                                variant="outlined"
                                                InputLabelProps={{ style: { fontSize: 14 } }}
                                                // required
                                                fullWidth
                                                autoComplete='off'
                                                size="small" />}
                                    />
                                </LocalizationProvider>
                            </div>

                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >City :</label>
                            <div className="col-sm-3">
                                <Autocomplete
                                    id="combo-box-demo"
                                    PaperComponent={({ children }) => (
                                        <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                    )}
                                    options={cityData}
                                    value={cityObj}
                                    onChange={(event, newValue) => {
                                        setCityObj(newValue)
                                    }}
                                    style={{ fontSize: '14px' }}
                                    getOptionLabel={option => (authUser.branchMedium == 1) ? option.nameMr : option.nameEn}
                                    size="small"
                                    renderInput={params => (
                                        <TextField {...params} margin='dense' label="Select City" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                    )}
                                />
                            </div>
                            <div className="col-sm-1">
                                <button type='button' className='btn p-0 mt-3' data-bs-toggle="modal" data-bs-target="#cityModal">
                                    <NoteAddIcon fontSize='medium' style={{ color: "#1F883C" }} />
                                </button>
                            </div>
                        </div>

                        <div className="row my-2">
                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Birth Place :</label>
                            <div className='col-sm-3'>
                                <TextField
                                    //required
                                    value={birthPlace}
                                    onChange={(e) => setBirthPlace(e.target.value)}
                                    fullWidth
                                    autoComplete='off'
                                    size='small'
                                    id="outlined-basic"
                                    label="Birth Place"
                                    variant="outlined"
                                    margin='dense'
                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                    inputProps={{ maxLength: 120 }}
                                />
                            </div>

                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Concession :</label>
                            <div className="col-sm-3">
                                <Autocomplete
                                    id="combo-box-demo"
                                    PaperComponent={({ children }) => (
                                        <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                    )}
                                    options={concessionData}
                                    onChange={(event, newValue) => {
                                        setConcessionObj(newValue);
                                    }}
                                    value={concessionObj}
                                    style={{ fontSize: '14px' }}
                                    getOptionLabel={option => (authUser.branchMedium == 1) ? option.nameMr : option.nameEn}
                                    size="small"
                                    renderInput={params => (
                                        <TextField {...params} margin='dense' label="Select Concession" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} />
                                    )}
                                />
                            </div>
                            <div className="col-sm-1">
                                <button type='button' className='btn p-0 mt-3' data-bs-toggle="modal" data-bs-target="#concessionModal">
                                    <NoteAddIcon fontSize='medium' style={{ color: "#1F883C" }} />
                                </button>
                            </div>
                        </div>

                        <div className="row my-2">
                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Religion :</label>
                            <div className="col-sm-3">
                                <Autocomplete
                                    id="combo-box-demo"
                                    PaperComponent={({ children }) => (
                                        <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                    )}
                                    options={religionData}
                                    onChange={(event, newValue) => {
                                        setReligionObj(newValue);
                                    }}
                                    value={religionObj}
                                    style={{ fontSize: '14px' }}
                                    getOptionLabel={option => (authUser.branchMedium == 1) ? option.nameMr : option.nameEn}
                                    size="small"
                                    renderInput={params => (
                                        <TextField {...params} margin='dense' label="Select Religion" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                    )}
                                />
                            </div>
                            <div className="col-sm-1">
                                <button type='button' className='btn p-0 mt-3' data-bs-toggle="modal" data-bs-target="#religionModal">
                                    <NoteAddIcon fontSize='medium' style={{ color: "#1F883C" }} />
                                </button>
                            </div>

                            <label className="col-sm-1" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Caste :</label>
                            <div className="col-sm-3">
                                <Autocomplete
                                    id="combo-box-demo"
                                    PaperComponent={({ children }) => (
                                        <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                    )}
                                    options={casteData}
                                    onChange={(event, newValue) => {
                                        setCasteObj(newValue);
                                    }}
                                    value={casteObj}
                                    style={{ fontSize: '14px' }}
                                    getOptionLabel={option => (authUser.branchMedium == 1) ? option.nameMr : option.nameEn}
                                    renderOption={(props, option) => (
                                        <Box component="li" {...props} key={option.id}>
                                            {(authUser.branchMedium == 1) ? option.nameMr : option.nameEn}
                                        </Box>
                                    )}
                                    size="small"
                                    renderInput={params => (
                                        <TextField {...params} margin='dense' label="Select Caste" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                    )}
                                />
                            </div>
                            <div className="col-sm-1">
                                <button type='button' className='btn p-0 mt-3' data-bs-toggle="modal" data-bs-target="#casteModal">
                                    <NoteAddIcon fontSize='medium' style={{ color: "#1F883C" }} />
                                </button>
                            </div>
                        </div>

                        <div className="row my-2">
                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >SubCaste :</label>
                            <div className="col-sm-3">
                                <Autocomplete
                                    id="combo-box-demo"
                                    PaperComponent={({ children }) => (
                                        <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                    )}
                                    options={subCasteData}
                                    onChange={(event, newValue) => {
                                        setSubCasteObj(newValue);
                                    }}
                                    value={subCasteObj}
                                    style={{ fontSize: '14px' }}
                                    getOptionLabel={option => (authUser.branchMedium == 1) ? option.nameMr : option.nameEn}
                                    size="small"
                                    renderInput={params => (
                                        <TextField {...params} margin='dense' label="Select SubCaste" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }}
                                        //required 
                                        />
                                    )}
                                />
                            </div>
                            <div className="col-sm-1">
                                <button type='button' className='btn p-0 mt-3' data-bs-toggle="modal" data-bs-target="#subCasteModal">
                                    <NoteAddIcon fontSize='medium' style={{ color: "#1F883C" }} />
                                </button>
                            </div>
                        </div>

                        <div className="row my-2">
                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >saral Id :</label>
                            <div className='col-sm-3'>
                                <TextField
                                    value={saralId}
                                    onChange={(e) => setSaralId(e.target.value)}
                                    fullWidth
                                    autoComplete='off'
                                    size='small'
                                    id="outlined-basic"
                                    label="saral Id"
                                    variant="outlined"
                                    margin='dense'
                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                    inputProps={{ maxLength: 120 }}
                                />
                            </div>

                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Aadhar :</label>
                            <div className='col-sm-3'>
                                <TextField
                                    value={aadharNo}
                                    onChange={(e) => setAadharNo(e.target.value)}
                                    onWheel={(e) => e.target.blur()}
                                    fullWidth
                                    type="number"
                                    autoComplete='off'
                                    size='small'
                                    id="outlined-basic"
                                    label="Aadhar No"
                                    variant="outlined"
                                    margin='dense'
                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                    inputProps={{ maxLength: 12 }}
                                />
                            </div>
                        </div>

                        <div className="row my-2">
                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Previous School :</label>
                            <div className='col-sm-8'>
                                <TextField
                                    //required
                                    value={previousSchool}
                                    onChange={(e) => setPreviousSchool(e.target.value)}
                                    fullWidth
                                    autoComplete='off'
                                    size='small'
                                    id="outlined-basic"
                                    label="Previous School"
                                    variant="outlined"
                                    margin='dense'
                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                    inputProps={{ maxLength: 120 }}
                                />
                            </div>
                        </div>

                        <div className="row my-2">
                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Last Class Attended :</label>
                            <div className='col-sm-3'>
                                <Autocomplete
                                    id="combo-box-demo"
                                    PaperComponent={({ children }) => (
                                        <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                    )}
                                    options={classData}
                                    value={lastClassAttend}
                                    onChange={(event, newValue) => {
                                        setLastClassAttend(newValue)
                                    }}
                                    style={{ fontSize: '14px' }}
                                    getOptionLabel={option => (authUser.branchMedium == 1) ? option.nameMr : option.nameEn}
                                    size="small"
                                    renderInput={params => (
                                        <TextField {...params} margin='dense' label="Select Class" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} />
                                    )}
                                />
                            </div>

                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Last School U-Dise :</label>
                            <div className='col-sm-3'>
                                <TextField
                                    value={lastSchoolUdiese}
                                    onChange={(e) => setLastSchoolUdiese(e.target.value)}
                                    fullWidth
                                    autoComplete='off'
                                    size='small'
                                    id="outlined-basic"
                                    label="Last School U-Dise"
                                    variant="outlined"
                                    margin='dense'
                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                    inputProps={{ maxLength: 120 }}
                                />
                            </div>
                        </div>

                        <div className="row my-2">
                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Parent Income :</label>
                            <div className='col-sm-3'>
                                <TextField
                                    // required
                                    value={parentIncome}
                                    onChange={(e) => setParentIncome(e.target.value)}
                                    onWheel={(e) => e.target.blur()}
                                    fullWidth
                                    type="number"
                                    autoComplete='off'
                                    size='small'
                                    id="outlined-basic"
                                    label="Parent Income"
                                    variant="outlined"
                                    margin='dense'
                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                    inputProps={{ maxLength: 120 }}
                                />
                            </div>

                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Parent Occupation :</label>
                            <div className='col-sm-3'>
                                <TextField
                                    fullWidth
                                    value={parentOccupation}
                                    onChange={(e) => setParentOccupation(e.target.value)}
                                    autoComplete='off'
                                    size='small'
                                    id="outlined-basic"
                                    label="Parent Occupation"
                                    variant="outlined"
                                    margin='dense'
                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                    inputProps={{ maxLength: 120 }}
                                />
                            </div>
                        </div>

                        <div className="row my-2">
                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Paying / Free :</label>
                            <div className='col-sm-3'>
                                <FormControl fullWidth >
                                    <InputLabel id='demo-simple-select-label' size="small" style={{ fontSize: '14px', marginTop: "8px" }}>Select Option</InputLabel>
                                    <Select
                                        size='small'
                                        required
                                        fullWidth
                                        margin='dense'
                                        value={payingFree}
                                        onChange={(e) => setPayingFree(e.target.value)}
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        label="Select Option"
                                        style={{ marginTop: "8px" }}
                                    >
                                        <MenuItem style={{ backgroundColor: "#F4ECF7", fontWeight: "550", fontSize: "14px" }} value={1}>Paying</MenuItem>
                                        <MenuItem style={{ backgroundColor: "#F4ECF7", fontWeight: "550", fontSize: "14px" }} value={2}>Free</MenuItem>
                                    </Select>
                                </FormControl>

                            </div>

                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >MotherTongue :</label>
                            <div className="col-sm-3">
                                <Autocomplete
                                    id="combo-box-demo"
                                    PaperComponent={({ children }) => (
                                        <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                    )}
                                    options={motherTongueData}
                                    value={motherTongueObj}
                                    onChange={(event, newValue) => {
                                        setmotherTongueObj(newValue)
                                    }}
                                    style={{ fontSize: '14px' }}
                                    getOptionLabel={option => (authUser.branchMedium == 1) ? option.nameMr : option.nameEn}
                                    size="small"
                                    renderInput={params => (
                                        <TextField {...params} margin='dense' label="Select MotherTongue" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} />
                                    )}
                                />
                            </div>
                        </div>

                        <div className="row py-2 mb-2" style={{ backgroundColor: "#FBEEE6", border: "1px solid #922B21" }}>
                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Marks Obtained :</label>
                            <div className='col-sm-2'>
                                <TextField
                                    value={markObtained}
                                    onChange={(e) => setMarkObtained(e.target.value)}
                                    onWheel={(e) => e.target.blur()}
                                    fullWidth
                                    type='number'
                                    autoComplete='off'
                                    size='small'
                                    id="outlined-basic"
                                    label="Marks obtained"
                                    variant="outlined"
                                    margin='dense'
                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                    inputProps={{ maxLength: 120 }}
                                />
                            </div>
                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Total Marks :</label>
                            <div className='col-sm-2'>
                                <TextField
                                    value={totalMark}
                                    onChange={(e) => setTotalMark(e.target.value)}
                                    onWheel={(e) => e.target.blur()}
                                    fullWidth
                                    type='number'
                                    autoComplete='off'
                                    size='small'
                                    id="outlined-basic"
                                    label="Total"
                                    variant="outlined"
                                    margin='dense'
                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                    inputProps={{ maxLength: 120 }}
                                />
                            </div>

                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Percentage :</label>
                            <div className='col-sm-2'>
                                <TextField
                                    value={percantage}
                                    onChange={(e) => setPercantage(e.target.value)}
                                    onWheel={(e) => e.target.blur()}
                                    fullWidth
                                    type='number'
                                    autoComplete='off'
                                    size='small'
                                    id="outlined-basic"
                                    label="Percentage"
                                    variant="outlined"
                                    margin='dense'
                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                    inputProps={{ maxLength: 120 }}
                                />
                            </div>

                            {/*  */}

                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Maths Marks :</label>
                            <div className='col-sm-2'>
                                <TextField
                                    value={mathsMark}
                                    onChange={(e) => setMathsMark(e.target.value)}
                                    onWheel={(e) => e.target.blur()}
                                    fullWidth
                                    type='number'
                                    autoComplete='off'
                                    size='small'
                                    id="outlined-basic"
                                    label="Maths"
                                    variant="outlined"
                                    margin='dense'
                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                    inputProps={{ maxLength: 120 }}
                                />
                            </div>
                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Science Marks :</label>
                            <div className='col-sm-2'>
                                <TextField
                                    value={scienceMark}
                                    onChange={(e) => setScienceMark(e.target.value)}
                                    onWheel={(e) => e.target.blur()}
                                    fullWidth
                                    type='number'
                                    autoComplete='off'
                                    size='small'
                                    id="outlined-basic"
                                    label="Science"
                                    variant="outlined"
                                    margin='dense'
                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                    inputProps={{ maxLength: 120 }}
                                />
                            </div>

                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >English Marks :</label>
                            <div className='col-sm-2'>
                                <TextField
                                    value={englishMark}
                                    onChange={(e) => setEnglishMark(e.target.value)}
                                    onWheel={(e) => e.target.blur()}
                                    fullWidth
                                    type='number'
                                    autoComplete='off'
                                    size='small'
                                    id="outlined-basic"
                                    label="English"
                                    variant="outlined"
                                    margin='dense'
                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                    inputProps={{ maxLength: 120 }}
                                />
                            </div>
                        </div>

                        <div className="row my-2">
                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Student Height :</label>
                            <div className='col-sm-2'>
                                <TextField
                                    value={studentHeight}
                                    onChange={(e) => setStudentHeight(e.target.value)}
                                    onWheel={(e) => e.target.blur()}
                                    fullWidth
                                    type='number'
                                    autoComplete='off'
                                    size='small'
                                    id="outlined-basic"
                                    label="In Cm"
                                    variant="outlined"
                                    margin='dense'
                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                    inputProps={{ maxLength: 120 }}
                                />
                            </div>

                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Student Weight :</label>
                            <div className='col-sm-2'>
                                <TextField
                                    value={studentWeight}
                                    onChange={(e) => setStudentWeight(e.target.value)}
                                    onWheel={(e) => e.target.blur()}
                                    fullWidth
                                    type='number'
                                    autoComplete='off'
                                    size='small'
                                    id="outlined-basic"
                                    label="In Kg"
                                    variant="outlined"
                                    margin='dense'
                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                    inputProps={{ maxLength: 120 }}
                                />
                            </div>

                        </div>
                        <div className="row my-2">
                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "14px" }} >Exam Language :</label>
                            <div className='col-sm-3'>
                                <RadioGroup row aria-label="examLanguage" margin="dense" name="customized-radios" style={{ marginTop: "6px" }} value={examLanguage} onChange={(e) => setExamLanguage(parseInt(e.target.value))}>
                                    <FormControlLabel value={1} control={<Radio color='primary' />} label="Marathi" />
                                    <FormControlLabel value={2} control={<Radio color='primary' />} label="English" />
                                </RadioGroup>
                            </div>
                        </div>

                        <div className="row mt-3">
                            <div className="col-sm-10 offset-sm-2">
                                <button type='submit' className={saveBtn} onClick={() => (state.button = 1)} disabled={saveBtnDisabled}>SAVE</button>

                                <button type='submit' className={updateBtn} onClick={() => (state.button = 2)} disabled={updateBtnDisabled}>UPDATE</button>

                                <button type='button' className='btn btn-danger ml-2' onClick={() => navigate("/Home/adm302/0")}>CANCLE</button>
                            </div>

                        </div>
                    </form>
                </div>
                {/* <ToastContainer position="top-right" theme="colored" /> */}
                <AddNewReligionModal setReligionData={setReligionData} />
                <AddNewCaste setCasteData={setCasteData} />
                <AddNewSubCaste setSubCasteData={setSubCasteData} />
                <AddNewConcession setConcessionData={setConcessionData} />
                <AddNewCityModal setCityData={setCityData} />
            </>
    )
}

export default AdmissionFormReceive