import React, { useState, useEffect } from 'react';
import TitleLabel from "../../CommonComponent/TitleLabel";
import axios from 'axios';
import Paper from "@mui/material/Paper";
import TextField from '@mui/material/TextField';
import Autocomplete from "@mui/material/Autocomplete";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import Loader from '../../CommonComponent/Loader';
import Tooltip from '@mui/material/Tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faSave, faTrash } from '@fortawesome/free-solid-svg-icons';
import { deleteTimeTableDataAPIURL, getClassesByEmployeeAPIURL, getDivisionByEmployeeAPIURL, getLecturescountAPIURL, getSubjectByEmployeeAPIURL, getTimeTableDataAPIURL, saveTimeTableDataAPIURL, updateTimeTableDataAPIURL } from '../Services/ManageTimeTableAPIURL';
import { EmployeeMasterGetMapping } from '../../CommonServices/ClassTeacherAllocationAPIURL';
import EditTimeTableDataModal from './TimeTableModal/EditTimeTableDataModal';
import DeleteModalBox from '../../CommonComponent/DeleteModalBox';


const weekData = [
    {
        id: 1,
        weekName: 'Monday'
    },
    {
        id: 2,
        weekName: 'Tuesday'
    },
    {
        id: 3,
        weekName: 'Wednsday'
    },
    {
        id: 4,
        weekName: 'Thursday'
    },
    {
        id: 5,
        weekName: 'Friday'
    },
    {
        id: 6,
        weekName: 'Saturday'
    },

]

// const data = [
//     {
//         id: 1,
//         timeSlot: '8:00==9:00',
//         periods: " - ",
//     }
// ]

const ManageTimeTable = () => {
    const authUser = useSelector((state) => state.user.value);
    const titleId = "Manage Time Table";
    const [loaderOption, setLoaderOption] = useState(false);

    //Note: This below states are declared for Save & Update(Disabled/unDisabled)
    const saveBtnStyle = "ManageTimeTableSaveBtn btn btn-primary btn-sm";
    const updateBtnStyle = "ManageTimeTableUpdateBtn btn btn-primary btn-sm";
    const displayNoneBtnStyle = "d-none";
    const [saveBtn, setSaveBtn] = useState(saveBtnStyle);
    const [updateBtn, setUpdateBtn] = useState(displayNoneBtnStyle);

    const [id, setId] = useState('');
    const [empData, setEmpData] = useState([]);
    const [empObj, setEmpObj] = useState(null);
    const [weekObj, setWeekObj] = useState(null);

    const [data, setData] = useState([]);

    const [classData, setClassData] = useState([]);
    const [classObj, setClassObj] = useState(null);
    const [divData, setDivData] = useState([]);
    const [divObj, setDivObj] = useState(null);
    const [subjectData, setSubjectData] = useState([]);
    const [subjectObj, setSubjectObj] = useState(null);
    const [periodType, setPeriodType] = useState('');
    const [batchName, setBatchName] = useState('');
    const [timeSlotId, setTimeSlotId] = useState('');
    const [lecturesCount, setLecturesCount] = useState(0);


    const TableHeading = [
        { label: 'SrNo', key: 'id' },
        { label: `Time Slot`, key: 'timeingOfPeriod' },
        { label: `Periods`, key: 'timeingOfPeriod' },
        { label: 'Action', value: 'action', isAction: true },
        // { label: 'Save', value: 'save', isAction: true, display: (EditFlag == 1) ? 'none' : '' },
    ];

    useEffect(() => {
        getData();
    }, [authUser])


    const getData = async () => {
        setLoaderOption(true);
        if (authUser.branchId != null) {
            await axios.get(`${EmployeeMasterGetMapping}?bid=${authUser.branchId}`)
                .then((response) => {
                    setEmpData(response.data);
                    setLoaderOption(false);
                })
        }
    }

    const getClasessByEmpId = async (emId) => {
        await axios.get(`${getClassesByEmployeeAPIURL}?empId=${emId}&year=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}`)
            .then((response) => {
                setClassData(response.data);
                setLoaderOption(false);
            })
    }

    const getClassByEmpAndDiv = async (cId) => {
        await axios.get(`${getDivisionByEmployeeAPIURL}?empId=${empObj.id}&classId=${cId}&year=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}`)
            .then((response) => {
                setDivData(response.data);
                setLoaderOption(false);
            })
    }

    const getSubByEmpClassAndDiv = async (dId) => {
        // console.log(`${getSubjectByEmployeeAPIURL}?empId=${empObj.id}&classId=${classObj.id}&divId=${dId}&year=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}`);
        await axios.get(`${getSubjectByEmployeeAPIURL}?empId=${empObj.id}&classId=${classObj.id}&divId=${dId}&year=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}`)
            .then((response) => {
                setSubjectData(response.data);
                // console.log(response.data);
                setLoaderOption(false);
            })
    }

    const getTimeTableData = async (e) => {
        if (e) {
            e.preventDefault();
        }

        setLoaderOption(true);
        setData([]);
        await axios.get(`${getTimeTableDataAPIURL}?empId=${empObj.id}&weekDay=${weekObj.id}&year=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}`)
            .then(res => {
                setData(res.data);
                setLoaderOption(false);
            }).catch(err => {
                console.log('time table get err' + err);
                setLoaderOption(false);
                toast.error("Something went wrong, please check.")
            })

        await axios.get(`${getLecturescountAPIURL}?empId=${empObj.id}&year=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}`)
            .then(res => {
                setLecturesCount(res.data);
                setLoaderOption(false);
            }).catch(err => {
                console.log('lectures count get err' + err);
                setLoaderOption(false);
                toast.error("Something went wrong, please check.")
            })
    }

    const getAllocatedData = (item) => {
        if (item.allocatedLect != "-") {
            setSaveBtn(displayNoneBtnStyle);
            setUpdateBtn(updateBtnStyle);
            setId(item.id);
            setClassObj(classData.find(e => e.id == item.classId))

            axios.get(`${getDivisionByEmployeeAPIURL}?empId=${item.empId}&classId=${item.classId}&year=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}`)
                .then((response) => {
                    setDivData(response.data);
                    setDivObj(response.data.find(e => e.id == item.divId))
                    setLoaderOption(false);
                })

            axios.get(`${getSubjectByEmployeeAPIURL}?empId=${item.empId}&classId=${item.classId}&divId=${item.divId}&year=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}`)
                .then((response) => {
                    setSubjectData(response.data);
                    setSubjectObj(response.data.find(e => e.id == item.subId))
                    setLoaderOption(false);
                })


            setPeriodType(item.lectType);
            // setBatchName(item.)
        }
        else {
            setSaveBtn(saveBtnStyle);
            setUpdateBtn(displayNoneBtnStyle);
        }

    }


    const saveTimeTable = async (e) => {
        // e.preventDefault();
        setLoaderOption(true);
        document.body.style.overflow = "auto";
        const saveObj = {
            "classId": classObj.id,
            "divId": divObj.id,
            "empId": empObj.id,
            "subjectId": subjectObj.id,
            "weekDay": weekObj.id,
            "timeSlotDetailId": timeSlotId,
            "lectureType": periodType,
            "batchId": (batchName == '') ? 0 : batchName,
            "sessionYear": authUser.sessionYear,
            "sectionId": authUser.deptId,
            "branchId": authUser.branchId
        }
        // console.log(JSON.stringify(saveObj))

        await axios.post(`${saveTimeTableDataAPIURL}`, saveObj)
            .then(res => {

                if (res.data == "SAVED") {
                    setLoaderOption(false);
                    getTimeTableData();

                    const modal = document.getElementById('timeTableModal');
                    if (modal) {
                        // change state like in hidden modal
                        modal.classList.remove('show');
                        modal.setAttribute('aria-hidden', 'true');
                        modal.setAttribute('style', 'display: none');
                    } else {
                        console.error('Modal element not found');
                    }

                    // get modal backdrop
                    const modalBackdrops = document.getElementsByClassName('modal-backdrop');
                    if (modalBackdrops.length > 0) {
                        // remove opened modal backdrop
                        document.body.removeChild(modalBackdrops[0]);
                        // console.log("Modal backdrop removed successfully.");
                    } else {
                        console.log("No modal backdrop found.");
                    }


                    toast.success("Time table saved successfully.");
                }
                else if (res.data == "ALREADYEXISTS") {
                    setLoaderOption(false);
                    const modal = document.getElementById('timeTableModal');
                    if (modal) {
                        // change state like in hidden modal
                        modal.classList.remove('show');
                        modal.setAttribute('aria-hidden', 'true');
                        modal.setAttribute('style', 'display: none');
                    } else {
                        console.error('Modal element not found');
                    }

                    // get modal backdrop
                    const modalBackdrops = document.getElementsByClassName('modal-backdrop');
                    if (modalBackdrops.length > 0) {
                        // remove opened modal backdrop
                        document.body.removeChild(modalBackdrops[0]);
                        // console.log("Modal backdrop removed successfully.");
                    } else {
                        console.log("No modal backdrop found.");
                    }
                    toast.warn("Already time slot allocated to another teacher.")
                }
            })
    }

    const updateTimeTable = async (e) => {
        // e.preventDefault();
        setLoaderOption(true);
        document.body.style.overflow = "auto";
        const updateObj = {
            "id": id,
            "classId": classObj.id,
            "divId": divObj.id,
            "empId": empObj.id,
            "subjectId": subjectObj.id,
            "weekDay": weekObj.id,
            "timeSlotDetailId": timeSlotId,
            "lectureType": periodType,
            "batchId": (batchName == '') ? 0 : batchName,
            "sessionYear": authUser.sessionYear,
            "sectionId": authUser.deptId,
            "branchId": authUser.branchId
        }
        // console.log(JSON.stringify(updateObj))
        await axios.put(`${updateTimeTableDataAPIURL}`, updateObj)
            .then(res => {
                // console.log(res.data)
                if (res.data == "UPDATED") {
                    setLoaderOption(false);
                    getTimeTableData();

                    const modal = document.getElementById('timeTableModal');
                    if (modal) {
                        // change state like in hidden modal
                        modal.classList.remove('show');
                        modal.setAttribute('aria-hidden', 'true');
                        modal.setAttribute('style', 'display: none');
                    } else {
                        console.error('Modal element not found');
                    }

                    // get modal backdrop
                    const modalBackdrops = document.getElementsByClassName('modal-backdrop');
                    if (modalBackdrops.length > 0) {
                        // remove opened modal backdrop
                        document.body.removeChild(modalBackdrops[0]);
                        // console.log("Modal backdrop removed successfully.");
                    } else {
                        console.log("No modal backdrop found.");
                    }

                    toast.success("Time table Updated successfully.");
                }
                else if (res.data == "ALREADYEXISTS") {
                    setLoaderOption(false);
                    const modal = document.getElementById('timeTableModal');
                    if (modal) {
                        // change state like in hidden modal
                        modal.classList.remove('show');
                        modal.setAttribute('aria-hidden', 'true');
                        modal.setAttribute('style', 'display: none');
                    } else {
                        console.error('Modal element not found');
                    }

                    // get modal backdrop
                    const modalBackdrops = document.getElementsByClassName('modal-backdrop');
                    if (modalBackdrops.length > 0) {
                        // remove opened modal backdrop
                        document.body.removeChild(modalBackdrops[0]);
                        // console.log("Modal backdrop removed successfully.");
                    } else {
                        console.log("No modal backdrop found.");
                    }
                    toast.warn("Already time slot allocated to another teacher.")
                }
            })
    }

    const state = {
        button: 1
    };

    const onSubmit = e => {
        e.preventDefault();
        if (state.button === 1) {
            saveTimeTable();
        }
        if (state.button === 2) {
            updateTimeTable();
        }
    };

    const [deleteId, setDeleteId] = useState("");
    const DeleteData = (id) => {

        axios.delete(`${deleteTimeTableDataAPIURL}?id=${id}`)
            .then((response) => {
                if (response.data != null) {
                    getTimeTableData();
                    toast.success("Deleted sucessfully");
                }
            });
    }


    if (loaderOption == true) {
        return (
            <Loader />
        )
    }
    else {
        return (
            <>
                <TitleLabel titleId={titleId} />
                <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: "350px" }}>

                    <form onSubmit={getTimeTableData}>
                        <input type="hidden" value={id} onChange={(e) => setId(e.target.value)} name="" />

                        <div className="row my-2">
                            <label htmlFor="lastname3" className="col-sm-1" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>Employee:</label>
                            <div className='col-sm-4'>
                                <Autocomplete
                                    id="combo-box-demo"
                                    options={empData}
                                    PaperComponent={({ children }) => (
                                        <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                    )}
                                    onChange={(event, newValue) => {
                                        setEmpObj(newValue);
                                        setData([]);
                                        setWeekObj(null);
                                        setClassObj(null);
                                        setDivData([]);
                                        setDivObj(null);
                                        setSubjectData([]);
                                        setSubjectObj(null);
                                        setPeriodType('');
                                        setBatchName('');
                                        if (newValue != null) {
                                            getClasessByEmpId(newValue.id);
                                        }

                                    }}
                                    value={empObj}
                                    onKeyPress={(e) => (e.key == "Enter") ? empObj : ""}
                                    style={{ fontSize: '14px' }}
                                    getOptionLabel={option => (authUser.branchMedium == 1) ? option.employeeNameMr : option.employeeNameEn}
                                    size="small"
                                    renderInput={params => (
                                        <TextField {...params} margin='dense' label="Select Employee" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }}
                                            required
                                        />
                                    )}
                                />
                            </div>

                            <label htmlFor="lastname3" className="" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px", width: '120px' }}>Week Days :</label>
                            <div className='col-sm-2'>
                                <Autocomplete
                                    id="combo-box-demo"
                                    options={weekData}
                                    PaperComponent={({ children }) => (
                                        <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                    )}
                                    onChange={(event, newValue) => {
                                        setWeekObj(newValue);
                                        setData([]);
                                        setClassObj(null);
                                        setDivData([]);
                                        setDivObj(null);
                                        setSubjectData([]);
                                        setSubjectObj(null);
                                        setPeriodType('');
                                        setBatchName('');

                                    }}
                                    value={weekObj}
                                    onKeyPress={(e) => (e.key == "Enter") ? weekObj : ""}
                                    style={{ fontSize: '14px' }}
                                    getOptionLabel={option => option.weekName}
                                    size="small"
                                    renderInput={params => (
                                        <TextField {...params} margin='dense' label="Select Week Day" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }}
                                            required
                                        />
                                    )}
                                />
                            </div>

                            <div className="col-sm-3 mt-3">
                                <button type="submit" className="btn btn-sm btn-primary">View</button>
                            </div>
                        </div>

                        {
                            (data.length != 0)
                                ?
                                <>
                                    <div className="row  mt-5">
                                        <div className="offset-sm-1" style={{width:'230px'}}>
                                            <p className='' style={{ backgroundColor: "#0A8101", borderRadius: "4px", fontWeight: "600", fontSize: "15px", margin: "4px", padding: "4px", color: "#fff", lineHeight: "1.4" }}>Lectures Allocated :  {lecturesCount}</p>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-sm-8 offset-sm-1">
                                            <div className="table-responsive">

                                                <table className="table table-bordered">
                                                    <thead className="table-Default">
                                                        <tr>

                                                            {TableHeading.map((item, index) => {
                                                                return (
                                                                    <th style={{ fontSize: "14px", fontWeight: "600" }} key={index} scope="col">{item.label}</th>
                                                                )
                                                            })}

                                                        </tr>
                                                    </thead>
                                                    <tbody style={{ borderTop: "2px solid #464de4", borderBottom: "2px solid #464de4" }}>
                                                        {
                                                            data.map((e, index) => {
                                                                return (
                                                                    <>
                                                                        <tr>
                                                                            <td style={{ fontSize: "14px", fontWeight: "500" }}>{index + 1}</td>
                                                                            <td style={{ fontSize: "14px", fontWeight: "500" }}>{e.fromTime + " - " + e.toTime}</td>
                                                                            <td style={{ fontSize: "14px", fontWeight: "500", textAlign: (e.allocatedLect == "-" ? 'center' : '') }}>{e.allocatedLect}</td>

                                                                            <td>
                                                                                <Tooltip title="Edit">
                                                                                    <span className='btn btn-primary btn-sm mx-1 p-1'
                                                                                        onClick={() => { setTimeSlotId(e.timeSlotDetailId); getAllocatedData(e); }}
                                                                                        data-bs-toggle="modal" data-bs-target="#timeTableModal">
                                                                                        <FontAwesomeIcon icon={faEdit} />
                                                                                    </span>
                                                                                </Tooltip>
                                                                                <Tooltip title="Delete">
                                                                                    <span className='btn btn-danger btn-sm mx-1 p-1'
                                                                                        onClick={() => setDeleteId(e.id)}
                                                                                        data-bs-toggle="modal" data-bs-target="#exampleModal">
                                                                                        <FontAwesomeIcon icon={faTrash} />
                                                                                    </span>
                                                                                </Tooltip>
                                                                            </td>
                                                                        </tr>
                                                                    </>
                                                                )
                                                            })
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </>
                                :
                                ""
                        }
                    </form>
                </div>

                <EditTimeTableDataModal
                    getClassByEmpAndDiv={getClassByEmpAndDiv}
                    getSubByEmpClassAndDiv={getSubByEmpClassAndDiv}
                    classData={classData}
                    classObj={classObj}
                    setClassObj={setClassObj}
                    divData={divData}
                    divObj={divObj}
                    setDivObj={setDivObj}
                    subjectData={subjectData}
                    subjectObj={subjectObj}
                    setSubjectObj={setSubjectObj}
                    periodType={periodType}
                    setPeriodType={setPeriodType}
                    batchName={batchName}
                    setBatchName={setBatchName}
                    authUser={authUser}
                    setClassData={setClassData}
                    setDivData={setDivData}
                    setSubjectData={setSubjectData}
                    saveTimeTable={onSubmit}
                    saveBtn={saveBtn}
                    updateBtn={updateBtn}
                    state={state}

                />

                <DeleteModalBox deleteId={deleteId} DeleteData={DeleteData} />
            </>
        )
    }
}

export default ManageTimeTable
