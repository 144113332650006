import React, { useState, useEffect } from 'react';
import TitleLabel from "../../CommonComponent/TitleLabel";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from '@mui/material/TextField';
import { useSelector } from "react-redux";
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import Loader from '../../CommonComponent/Loader';
import Paper from "@mui/material/Paper";
import { classWithDivURL, printYearlyAttendanceSummaryAPIURL } from '../Services/CatalogReportAPIURL';
import makeStyles from "@mui/styles/makeStyles";
import { getMasterReportAPIURL } from '../../Masters/Services/MasterReportAPIURL';

const useStyles = makeStyles(theme => ({
    select: {
        maxHeight: 160,
        "& ul": {
            backgroundColor: "#F4ECF7",
            //maxHeight: 160,
        },
        "& li": {
            // backgroundColor: "#F4ECF7", fontWeight: "550", fontSize: "14px" 
            fontSize: "14px",
            fontWeight: "550"
        },
    },
    menuPaper: {
        maxHeight: 200,
        "& ul": {
            backgroundColor: "#F4ECF7",
            //maxHeight: 160,
        },
        "& li": {
            // backgroundColor: "#F4ECF7", fontWeight: "550", fontSize: "14px" 
            fontSize: "14px",
            fontWeight: "550"
        },
    }
}));


const YearlyAttendanceSummary = () => {
    const classes = useStyles();
    const titleId = "Yearly Attendance Summary";
    const authUser = useSelector((state) => state.user.value);

    // loader on/off
    const [loaderOption, setLoaderOption] = useState(false);

    //console.log(`date=${moment(dateFrom).format("MM/YYYY")}`)
    const [id, setId] = useState();
    const [classDivData, setClassDivData] = useState([]);
    const [classDivObj, setClassDivObj] = useState(null);




    useEffect(() => {
        getData();
    }, [authUser])


    const getData = async () => {
        if (authUser.deptId != null && authUser.branchId != null) {
            await axios(`${classWithDivURL}?sid=${authUser.deptId}&bid=${authUser.branchId}`)
                .then((response) => {
                    setClassDivData(response.data);

                }).catch((error) => {
                    console.log(error);
                })
        }
    }


    const getStudYearlyAttendSummaryReport = async (e) => {
        e.preventDefault();
        setLoaderOption(true);
        await axios.get(`${printYearlyAttendanceSummaryAPIURL}?langId=${authUser.branchMedium}&year=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&classId=${classDivObj.classId}&divId=${classDivObj.divisionId}`)
            .then((response) => {
                if (response.data == "NOTFOUND") {
                    //console.log(response.data)
                    setLoaderOption(false);
                    toast.warn("Data not exists.")
                }
                else if (response.data != null) {
                    //console.log(response.data)   

                    window.open(`${getMasterReportAPIURL}${response.data}`);
                    setLoaderOption(false);
                }
            })
            .catch(error => {
                setLoaderOption(false);
                toast.error("Something went wrong, try after sometime.")
                console.log("Category Report failed: " + error)
            })

    }


    return (
        <>
            {
                (loaderOption == true)
                    ?
                    <Loader />
                    :
                    <div>
                        <TitleLabel titleId={titleId} />

                        <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: "350px" }}>
                            {/* Form Data start */}
                            <form onSubmit={getStudYearlyAttendSummaryReport}>
                                <div className="form-data mt-1">
                                    <input type="hidden" value={id} onChange={(e) => setId(e.target.value)} name="" />

                                    <div className="row">
                                        <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Class-Division :</label>
                                        <div className='col-sm-3'>
                                            <Autocomplete
                                                id="combo-box-demo"
                                                PaperComponent={({ children }) => (
                                                    <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                                )}
                                                options={classDivData}
                                                style={{ fontSize: '14px' }}
                                                getOptionLabel={option => (authUser.branchMedium == 1) ? option.classNameMr + " " + option.divisionNameMr : option.classNameEn + " " + option.divisionNameEn}
                                                size="small"
                                                value={classDivObj}
                                                onChange={(event, newvalue) => {
                                                    setClassDivObj(newvalue);
                                                }}

                                                renderInput={params => (
                                                    <TextField {...params} margin="dense" label="Select Class-Division" InputLabelProps={{ style: { fontSize: 14 } }} variant="outlined" required />
                                                )}
                                            />
                                        </div>
                                    </div>


                                    <div className="row mt-3">
                                        <div className="col-sm-4 offset-sm-2">
                                            <button type="submit" className="btn btn-primary btn-sm" >Show</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
            }

            {/* <ToastContainer position="top-right" theme="colored" /> */}
        </>
    )
}


export default YearlyAttendanceSummary
