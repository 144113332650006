import React, { useState, useEffect } from 'react';
import TitleLabel from "../../CommonComponent/TitleLabel";
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Autocomplete from "@mui/material/Autocomplete";
import axios from 'axios';
import Paper from "@mui/material/Paper";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import Loader from '../../CommonComponent/Loader';
import { useNavigate, useParams } from 'react-router-dom';
import { DatePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { getInvestmentEntryAPIURL, saveInvestmentEntryAPIURL, updateInvestmentEntryAPIURL } from '../Services/InvestmentEntryAPIURL';
import { getTypeMasteAPIURL } from '../Services/TypeMasteAPIURL';
import { getBankData } from '../../FeeManagement/Services/FeeReceiptAPIURL';
import moment from 'moment';


const InvestmentEntry = () => {
  const authUser = useSelector((state) => state.user.value);
  const titleId = "Investment Entry Form";
  const branchId = authUser.branchId;
  const sectionId = authUser.deptId;

  const navigate = useNavigate();
  let { ieId, EditFlag } = useParams();

  const [loaderOption, setLoaderOption] = useState(false);

  //Note: This below states are declared for Save & Update(Disabled/unDisabled)
  const saveBtnStyle = "investmentEntrySaveBtn btn btn-primary btn-sm";
  const updateBtnStyle = "investmentEntryUpdateBtn btn btn-primary btn-sm";
  const displayNoneBtnStyle = "d-none";
  const [saveBtn, setSaveBtn] = useState(saveBtnStyle);
  const [updateBtn, setUpdateBtn] = useState(displayNoneBtnStyle);

  const [saveBtnDisabled, setSaveBtnDisabled] = useState(false);
  const [updateBtnDisabled, setUpdateBtnDisabled] = useState(false);

  const [id, setId] = useState('');
  const [typeMasteData, setTypeMasterData] = useState([]);
  const [typeMasterObj, setTypeMasterObj] = useState(null);
  const [receiptNo, setReceiptNo] = useState('');
  const [date, setDate] = useState(null);
  const [amount, setAmount] = useState('');
  const [expiryDate, setExpiryDate] = useState(null);
  const [interestRate, setInterestRate] = useState('');
  const [BankData, setBankData] = useState([]);
  const [bankObj, setBankObj] = useState("");

  useEffect(() => {
    if (EditFlag == 1) {
      getData();
      setSaveBtnDisabled(false);
      setUpdateBtnDisabled(true);
    }
    else if (EditFlag == 2) {
      getEditDetails();
      setSaveBtnDisabled(true);
      setUpdateBtnDisabled(false);
    }
  }, []);

  const getData = async () => {
    await axios.get(`${getTypeMasteAPIURL}`)
      .then((response) => {
        setTypeMasterData(response.data);
      })

    await axios.get(`${getBankData}`)
      .then((response) => {
        setBankData(response.data);
      })
  }

  const getEditDetails = async () => {
    setLoaderOption(true);
    await axios.get(`${getInvestmentEntryAPIURL}?sid=${authUser.deptId}&bid=${authUser.branchId}`)
      .then((response) => {
        setSaveBtn(displayNoneBtnStyle);
        setUpdateBtn(updateBtnStyle);
        response.data.map(e => {
          if (e.id == ieId) {
            setId(e.id);
            let typeMasterId = e.typeMasterId;
            setReceiptNo(e.receiptNo);
            setBankObj(e.bankName);
            setDate(e.date);
            setExpiryDate(e.expiryDate);
            setAmount(e.amount);
            setInterestRate(e.interestRate);

            axios.get(`${getTypeMasteAPIURL}`)
              .then((response) => {
                setTypeMasterData(response.data);
                setTypeMasterObj(response.data.find(item => item.id == typeMasterId))
              })

            axios.get(`${getBankData}`)
              .then((response) => {
                setBankData(response.data);
              })
          }
        })
        setLoaderOption(false);
      })
  }

  const saveBtnClick = () => {
    setLoaderOption(true);
    const saveObject = {
      "id": id,
      "typeMasterId": typeMasterObj.id,
      "date": moment(date).format("DD/MM/YYYY"),
      "receiptNo": receiptNo,
      "bankName": bankObj,
      "amount": amount,
      "expiryDate": moment(expiryDate).format("DD/MM/YYYY"),
      "interestRate": interestRate,
      "sectionId": sectionId,
      "branchId": branchId

    };
    // console.log(JSON.stringify(saveObject))
    axios.post(`${saveInvestmentEntryAPIURL}`, saveObject)
      .then((response) => {
        if (response.data == "SAVED") {
          getData();
          setLoaderOption(false);
          ClearData();
          toast.success("Saved sucessfully.");
        }
        else {
          toast.error("Operation failed.");
        }
      });
  };

  const updateBtnClick = () => {
    setLoaderOption(true);
    const updateObject = {
      "id": id,
      "typeMasterId": typeMasterObj.id,
      "date": moment(date).format("DD/MM/YYYY"),
      "receiptNo": receiptNo,
      "bankName": bankObj,
      "amount": amount,
      "expiryDate": moment(expiryDate).format("DD/MM/YYYY"),
      "interestRate": interestRate,
      "sectionId": sectionId,
      "branchId": branchId

    };
    // console.log(JSON.stringify(updateObject))
    axios.put(`${updateInvestmentEntryAPIURL}`, updateObject)
      .then((response) => {
        if (response.data == "UPDATED") {
          getData();
          setLoaderOption(false);
          ClearData();
          toast.success("Update sucessfully.");
        }
        else if (response.data == "ALREADYEXISTS") {
          setLoaderOption(false);
          toast.warn(`Author with this name is already exists.`)
        }
      });
  };

  const ClearData = () => {
    navigate('/Home/aci623/')
    setSaveBtnDisabled(false);
    setUpdateBtnDisabled(true);
    setSaveBtn(saveBtnStyle);
    setUpdateBtn(displayNoneBtnStyle);
  }

  const state = {
    button: 1
  };

  const onSubmit = e => {
    e.preventDefault();
    if (state.button === 1) {
      saveBtnClick();
    }
    if (state.button === 2) {
      updateBtnClick();
    }
  };

  if (loaderOption == true) {
    return (
      <Loader />
    )
  }
  else {
    return (
      <>
        <TitleLabel titleId={titleId} />
        <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: '500px' }}>
          <form onSubmit={onSubmit}>
            <input type="hidden" value={id} onChange={(e) => setId(e.target.value)} name="" />
            <div className="row my-2">
              <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Type :</label>
              <div className='col-sm-4'>
                <Autocomplete
                  PaperComponent={({ children }) => (
                    <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                  )}
                  id="combo-box-demo"
                  onChange={(e, newValue) => {
                    setTypeMasterObj(newValue);
                  }}
                  value={typeMasterObj}
                  options={typeMasteData}
                  onKeyPress={(e) => (e.key == "Enter") ? typeMasterObj : ""}
                  style={{ fontSize: '14px' }}
                  getOptionLabel={option => (authUser.branchMedium == 1) ? option.nameMr : option.nameEn}
                  size="small"
                  renderInput={params => (
                    <TextField {...params} label="Select Type" margin='dense' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                  )}
                />
              </div>
            </div>


            <div className="row my-3">
              <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px", marginRight: "0px" }} >Date :</label>
              <div className='col-sm-3'>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label="Date"
                    value={date}
                    // minDate={defaultMinDate}
                    // maxDate={defaultMaxDate}
                    onChange={(newValue) => {
                      setDate(newValue);
                      //(newValue > defaultMaxDate || newValue < defaultMinDate) ? setDateCheck("Invalid date") : setDateCheck(null)
                      // const maxDate = new Date(defaultMaxDate);
                      // maxDate.setDate(maxDate.getDate() + 1); // Add one day to max date to include the full day
                      // if (newValue < defaultMinDate || newValue >= maxDate) {
                      //   setDateCheck("Invalid date");
                      // } else {
                      //   setDateCheck("");
                      // }
                    }}
                    onKeyPress={(e) => (e.key == "Enter") ? date : ""}
                    inputFormat="dd/MM/yyyy"
                    // Show defaultMaxDate selected when the DatePicker is opened
                    // If dateFrom is not set, defaultMaxDate is used
                    //onOpen={() => setdate(date || defaultMaxDate)}
                    renderInput={(params) =>
                      <TextField
                        {...params}
                        margin='dense'
                        variant="outlined"
                        InputLabelProps={{ style: { fontSize: 14 } }}
                        required
                        fullWidth
                        autoComplete='off'
                        size="small" />}
                  />
                </LocalizationProvider>
                {/* <span><em style={{ fontSize: "12px", color: "red", fontWeight: "600" }}>{dateCheck}</em></span> */}
              </div>

              <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: '16px' }} >Receipt No. :</label>
              <div className='col-sm-3'>
                <TextField
                  required
                  fullWidth
                  type="text"
                  autoComplete='off'
                  size='small'
                  id="outlined-basic"
                  label="Receipt No."
                  variant="outlined"
                  margin='dense'
                  value={receiptNo}
                  //style={{ color: closingAmt < 0 ? "#EA2C02" : "#0B9202" }}
                  onChange={e => setReceiptNo(e.target.value)}
                  onKeyPress={(e) => (e.key === "Enter") ? receiptNo : ""}
                  InputLabelProps={{ style: { fontSize: 14 } }}
                />

              </div>
            </div>

            <div className="row my-2">
              <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Bank Name:</label>
              <div className='col-sm-4'>
                <Autocomplete
                  id="combo-box-demo"
                  PaperComponent={({ children }) => (
                    <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                  )}
                  freeSolo
                  disableClearable
                  onKeyPress={(e) => (e.key == "Enter") ? bankObj : ""}
                  options={(bankObj === "") ? [] : BankData}
                  value={bankObj}
                  onChange={(event, newValue) => {
                    //setNarrationId(newValue.id);
                    //console.log(newValue.narrationDetail);
                    setBankObj(newValue.nameMr);
                  }}
                  inputValue={bankObj}
                  onInputChange={(event, newInputValue) => {
                    //setNarrationId(newInputValue.id);
                    //console.log(newInputValue);
                    setBankObj(newInputValue);
                  }}
                  style={{ fontSize: '14px' }}
                  getOptionLabel={option => {

                    if (typeof option === 'string') {
                      return option;
                    }
                    // Add "xxx" option created dynamically
                    if (option.inputValue) {
                      //console.log("option........"+JSON.stringify(option.inputValue))
                      return option.nameMr;
                    }
                    // Regular option
                    return option.nameMr;
                  }

                  }
                  size="small"
                  renderInput={params => (
                    <TextField {...params}
                      margin='dense' label="Bank Name" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                  )}
                />
              </div>

              <label className="col-sm-1" style={{ fontSize: "14px", fontWeight: "600", marginTop: '16px' }} >Amount :</label>
              <div className='col-sm-3'>
                <TextField
                  required
                  fullWidth
                  type="text"
                  autoComplete='off'
                  size='small'
                  id="outlined-basic"
                  label="Amount"
                  variant="outlined"
                  margin='dense'
                  value={amount}
                  //style={{ color: closingAmt < 0 ? "#EA2C02" : "#0B9202" }}
                  onChange={e => setAmount(e.target.value)}
                  onKeyPress={(e) => (e.key === "Enter") ? amount : ""}
                  InputLabelProps={{ style: { fontSize: 14 } }}
                />

              </div>
            </div>


            <div className="row my-2">
              <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px", marginRight: "0px" }} >Validity Date :</label>
              <div className='col-sm-3'>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label="Validity Date"
                    value={expiryDate}
                    // minDate={defaultMinDate}
                    // maxDate={defaultMaxDate}
                    onChange={(newValue) => {
                      setExpiryDate(newValue);
                      //(newValue > defaultMaxDate || newValue < defaultMinDate) ? setDateCheck("Invalid date") : setDateCheck(null)
                      // const maxDate = new Date(defaultMaxDate);
                      // maxDate.setDate(maxDate.getDate() + 1); // Add one day to max date to include the full day
                      // if (newValue < defaultMinDate || newValue >= maxDate) {
                      //   setDateCheck("Invalid date");
                      // } else {
                      //   setDateCheck("");
                      // }
                    }}
                    onKeyPress={(e) => (e.key == "Enter") ? expiryDate : ""}
                    inputFormat="dd/MM/yyyy"
                    // Show defaultMaxDate selected when the DatePicker is opened
                    // If dateFrom is not set, defaultMaxDate is used
                    //onOpen={() => setdate(date || defaultMaxDate)}
                    renderInput={(params) =>
                      <TextField
                        {...params}
                        margin='dense'
                        variant="outlined"
                        InputLabelProps={{ style: { fontSize: 14 } }}
                        required
                        fullWidth
                        autoComplete='off'
                        size="small" />}
                  />
                </LocalizationProvider>
                {/* <span><em style={{ fontSize: "12px", color: "red", fontWeight: "600" }}>{dateCheck}</em></span> */}
              </div>

              <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: '16px' }} >Interest Rate :</label>
              <div className='col-sm-3'>
                <TextField
                  required
                  fullWidth
                  type="text"
                  autoComplete='off'
                  size='small'
                  id="outlined-basic"
                  label="Interest Rate"
                  variant="outlined"
                  margin='dense'
                  value={interestRate}
                  //style={{ color: closingAmt < 0 ? "#EA2C02" : "#0B9202" }}
                  onChange={e => setInterestRate(e.target.value)}
                  onKeyPress={(e) => (e.key === "Enter") ? interestRate : ""}
                  InputLabelProps={{ style: { fontSize: 14 } }}
                />

              </div>
            </div>

            <div className="row mt-3">
              <div className="col-sm-12 offset-sm-2">
                <button type="submit" disabled={saveBtnDisabled} onClick={() => (state.button = 1)} className={saveBtn} >Save</button>
                <button type="submit" disabled={updateBtnDisabled} onClick={() => (state.button = 2)} className={updateBtn} >Update</button>
                <button type="button" onClick={ClearData} className="btn btn-danger btn-sm" style={{ margin: '0px 4px' }} >Cancel</button>
              </div>
            </div>
          </form>
        </div>
      </>
    )
  }
}

export default InvestmentEntry
