import React, { useState, useEffect } from 'react';
import TitleLabel from "../../CommonComponent/TitleLabel";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from '@mui/material/TextField';
import { useSelector } from "react-redux";
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import Loader from '../../CommonComponent/Loader';
import Paper from "@mui/material/Paper";
import { getExportedReportAPIURL } from '../../CommonServices/APIURL';
import { classWithDivisionURL, printExamMarkAllocationList, getExamData } from '../Services/ExamReportAPIURL';

const ExamMarkAllocationReport = () => {
    const titleId = "Exam Mark Allocation List";
    const authUser = useSelector((state) => state.user.value);

    // loader on/off
    const [loaderOption, setLoaderOption] = useState(false);

    const [reportOptionId, setReportOptionId] = useState(1);
    const [id, setId] = useState();
    const [classDivData, setClassDivData] = useState([]);
    const [classDivObj, setClassDivObj] = useState(null);
    const [examData, setExamData] = useState([]);
    const [examObj, setExamObj] = useState(null);

    useEffect(() => {
        getData();
    }, [authUser])


    const getData = async () => {
        if (authUser.deptId != null || authUser.branchId != null) {
            await axios(`${classWithDivisionURL}?sid=${authUser.deptId}&bid=${authUser.branchId}`)
                .then((response) => {
                    setClassDivData(response.data);

                }).catch((error) => {
                    console.log(error);
                })
        }
        await axios(`${getExamData}`)
            .then((response) => {
                setExamData(response.data);

            }).catch((error) => {
                console.log(error);
            })
    }

    const ExamMarkAllocationPrint = async (e) => {
        e.preventDefault();
        setLoaderOption(true);
        //console.log(`${printExamMarkAllocationList}?acYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&langId=${authUser.branchMedium}&examId=${examObj.id}&classId=${classDivObj.classId}&divisionId=${classDivObj.divisionId}&branchMedium=${authUser.branchMedium}`)
        await axios.get(`${printExamMarkAllocationList}?acYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&langId=${authUser.branchMedium}&examId=${examObj.id}&classId=${classDivObj.classId}&divisionId=${classDivObj.divisionId}&branchMedium=${authUser.branchMedium}`)
            .then((response) => {

                if (response.data === "NOTFOUND") {
                    setLoaderOption(false);
                    toast.warn("Data not exist with this class-Division and exam");
                }
                else if (response.data != null) {
                    // console.log(response.data)
                    window.open(`${getExportedReportAPIURL}${response.data}`);
                    setLoaderOption(false);
                }
            })
            .catch(error => {
                setLoaderOption(false);
                toast.error("Error Occurred, Try after sometime.");
                console.log("Exam Mark Allocation Report failed: " + error)
            })
    }

    if (loaderOption == true) {
        return <><Loader /></>
    }
    else {
        return (
            <>
                <TitleLabel titleId={titleId} />

                <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: "350px" }}>
                    {/* Form Data start */}
                    <form onSubmit={ExamMarkAllocationPrint}>
                        <div className="form-data">
                            <input type="hidden" value={id} onChange={(e) => setId(e.target.value)} name="" />

                            <div className="row">
                                <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Class-Division :</label>
                                <div className='col-sm-3'>
                                    <Autocomplete
                                        id="combo-box-demo"
                                        PaperComponent={({ children }) => (
                                            <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                        )}
                                        options={classDivData}
                                        style={{ fontSize: '14px' }}
                                        getOptionLabel={option => (authUser.branchMedium == 1) ? option.classNameMr + " " + option.divisionNameMr : option.classNameEn + " " + option.divisionNameEn}
                                        size="small"
                                        value={classDivObj}
                                        onChange={(event, newvalue) => {
                                            setClassDivObj(newvalue);
                                        }}

                                        renderInput={params => (
                                            <TextField {...params} margin="dense" label="Select Class-Division" InputLabelProps={{ style: { fontSize: 14 } }} variant="outlined" required />
                                        )}
                                    />
                                </div>
                            </div>

                            <div className="row my-2">
                                <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Exam :</label>
                                <div className='col-sm-3'>
                                    <Autocomplete
                                        id="combo-box-demo"
                                        PaperComponent={({ children }) => (
                                            <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                        )}
                                        options={examData}
                                        style={{ fontSize: '14px' }}
                                        getOptionLabel={option => (authUser.branchMedium == 1) ? option.nameMr : option.nameEn}
                                        size="small"
                                        value={examObj}
                                        onChange={(event, newvalue) => {
                                            setExamObj(newvalue);
                                        }}

                                        renderInput={params => (
                                            <TextField {...params} margin="dense" label="Select Exam" InputLabelProps={{ style: { fontSize: 14 } }} variant="outlined" required />
                                        )}
                                    />
                                </div>
                            </div>

                            <div className="row mt-3">
                                <div className="col-sm-4 offset-sm-2">
                                    <button type="submit" className="btn btn-primary btn-sm">Show</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <ToastContainer position="top-right" theme="colored" />
            </>
        )
    }


}

export default ExamMarkAllocationReport