import React, { useState, useEffect } from 'react';
import TitleLabel from "../../CommonComponent/TitleLabel";
import { useSelector } from "react-redux";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import TextField from '@mui/material/TextField';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { toast } from 'react-toastify';
import { getExportedReportAPIURL } from '../../CommonServices/APIURL';
import Loader from '../../CommonComponent/Loader';
import axios from 'axios';
import { getInwardEntryReportAPIURL } from '../Services/InwardOutwardReportAPI';
import InwardReportTableView from './InwardReportTableView';

const InwardReport = () => {
    const titleId = "Inward Report";
    const authUser = useSelector((state) => state.user.value);
    const navigate = useNavigate();

    const [loaderOption, setLoaderOption] = useState(false);

    const defaultMinDate = new Date(`${authUser.sessionYear}/04/01`);
    const defaultMaxDate = new Date(`${authUser.sessionYear + 1}/03/31`);
    // const defaultMinDate = new Date(2022, 3, 1); // April 1st, 2022
    // const defaultMaxDate = new Date(2023, 2, 31); // March 31st, 2023

    const [dateFrom, setDateFrom] = useState(null);
    const [dateTo, setDateTo] = useState(null);
    const [id, setId] = useState();
    const [dateCheckFrom, setDateCheckFrom] = useState('');
    const [dateCheckTo, setDateCheckTo] = useState('');
    const [data, setData] = useState([]);

    const defaultFromDate = new Date(authUser.sessionYear, 3, 1);
    const defaultToDate = new Date(parseInt(authUser.sessionYear) + 1, 2, 31);

    useEffect(() => {
        if (authUser.sessionYear != '') {
            setDateFrom(moment(defaultFromDate).format("YYYY-MM-DD"))
            setDateTo(moment(defaultToDate).format("YYYY-MM-DD"))
        }
    }, [authUser])

    // console.log("============" + dateCheckFrom + "===========" + dateCheckTo)

    const getInwardReport = async (e) => {
        e.preventDefault();
        setLoaderOption(true);
        setData([]);
        if (moment(dateFrom).format("DD/MM/YYYY") !== 'Invalid date' && moment(dateTo).format("DD/MM/YYYY") !== 'Invalid date' &&
            dateCheckFrom !== 'Invalid date' && dateCheckTo !== 'Invalid date') {

            await axios.get(`${getInwardEntryReportAPIURL}?year=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&fromDate=${moment(dateFrom).format("YYYY-MM-DD")}&toDate=${moment(dateTo).format("YYYY-MM-DD")}`)
                .then(res => {
                    if (res.data == "NOTFOUND") {
                        setLoaderOption(false);
                        toast.warn("Data not found.")
                    }
                    else {
                        setData(res.data);
                        setLoaderOption(false);
                    }
                }).catch(err => {
                    console.log("Inward Report err " + err)
                    setLoaderOption(false);
                    toast.error("Something went wrong, please check.")
                })
        }
        else {
            setLoaderOption(false);
            toast.error("Improper date, please check.")
        }
    }


    if (loaderOption == true) {
        return (
            <Loader />
        )
    }
    else {
        return (
            <>
                {
                    (loaderOption == true)
                        ?
                        <Loader />
                        :
                        <>
                            <TitleLabel titleId={titleId} />
                            <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: "350px" }}>
                                {/* Form Data start */}
                                <form onSubmit={getInwardReport}>
                                    <div className="form-data mt-1">
                                        <input type="hidden" value={id} onChange={(e) => setId(e.target.value)} name="" />

                                        <div className="row">
                                            <label className="" style={{ width: "110px", fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>Date From :</label>
                                            <div className='col-sm-3'>
                                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                    <DatePicker
                                                        label="Date From"
                                                        value={dateFrom}
                                                        minDate={defaultMinDate}
                                                        maxDate={defaultMaxDate}
                                                        inputFormat="dd/MM/yyyy"
                                                        onChange={(newValue) => {
                                                            setDateFrom(newValue);
                                                            setData([]);
                                                            // (newValue > defaultMaxDate || newValue < defaultMinDate) ? setDateCheckFrom("Invalid date") : setDateCheckFrom("")                                                      
                                                            const maxDate = new Date(defaultMaxDate);
                                                            maxDate.setDate(maxDate.getDate() + 1); // Add one day to max date to include the full day
                                                            if (newValue < defaultMinDate || newValue >= maxDate) {
                                                                setDateCheckFrom("Invalid date");
                                                            } else {
                                                                setDateCheckFrom("");
                                                            }
                                                        }}
                                                        openTo="day"
                                                        // Show defaultMaxDate selected when the DatePicker is opened
                                                        // If dateFrom is not set, defaultMaxDate is used
                                                        onOpen={() => setDateFrom(dateFrom || defaultMaxDate)}
                                                        renderInput={(params) =>
                                                            <TextField
                                                                {...params}
                                                                margin="dense"
                                                                variant="outlined"
                                                                InputLabelProps={{ style: { fontSize: 14 } }}
                                                                required
                                                                fullWidth
                                                                autoComplete='off'
                                                                size="small" />}
                                                    />
                                                </LocalizationProvider>
                                            </div>

                                            <label className="col-sm-1" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>Date To :</label>
                                            <div className='col-sm-3'>
                                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                    <DatePicker
                                                        label="Date To"
                                                        value={dateTo}
                                                        minDate={defaultMinDate}
                                                        maxDate={defaultMaxDate}
                                                        inputFormat="dd/MM/yyyy"
                                                        onChange={(newValue) => {
                                                            setDateTo(newValue);
                                                            setData([]);
                                                            // (newValue > defaultMaxDate || newValue < defaultMinDate) ? setDateCheckTo("Invalid date") : setDateCheckTo("")
                                                            const maxDate = new Date(defaultMaxDate);
                                                            maxDate.setDate(maxDate.getDate() + 1); // Add one day to max date to include the full day
                                                            if (newValue < defaultMinDate || newValue >= maxDate) {
                                                                setDateCheckTo("Invalid date");
                                                            } else {
                                                                setDateCheckTo("");
                                                            }
                                                        }}
                                                        openTo="day"
                                                        // Show defaultMaxDate selected when the DatePicker is opened
                                                        // If dateFrom is not set, defaultMaxDate is used
                                                        onOpen={() => setDateTo(dateTo || defaultMaxDate)}
                                                        renderInput={(params) =>
                                                            <TextField
                                                                {...params}
                                                                margin="dense"
                                                                variant="outlined"
                                                                InputLabelProps={{ style: { fontSize: 14 } }}
                                                                required
                                                                fullWidth
                                                                autoComplete='off'
                                                                size="small" />}
                                                    />
                                                </LocalizationProvider>
                                            </div>
                                        </div>

                                        <div className="row mt-3">
                                            <div className="col-sm-12" style={{ marginLeft: "110px" }}>
                                                <button type="submit" className="btn btn-primary btn-sm">Show</button>
                                            </div>
                                        </div>

                                    </div>
                                </form>

                                <div className="mt-5">
                                    {
                                        data.length != 0
                                            ?
                                            <InwardReportTableView
                                                data={data}
                                                dateFrom={dateFrom}
                                                dateTo={dateTo}
                                                dateCheckFrom={dateCheckFrom}
                                                dateCheckTo={dateCheckTo}
                                                setLoaderOption={setLoaderOption}
                                                loaderOption={loaderOption}
                                            />
                                            :
                                            ''
                                    }
                                </div>
                            </div>
                        </>
                }
            </>
        )
    }
};


export default InwardReport
