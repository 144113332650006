import React, { useState, useEffect } from 'react';
import TitleLabel from "../../CommonComponent/TitleLabel";
import axios from 'axios';
import Paper from "@mui/material/Paper";
import TextField from '@mui/material/TextField';
import Autocomplete from "@mui/material/Autocomplete";
import PrintIcon from '@mui/icons-material/Print';
import { ToastContainer, toast } from 'react-toastify';
import { useSelector } from "react-redux";
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { printLcAPIURL, getPrintLcAPIURL, GetStudentLcData, lcPrintClassDivWise } from '../Services/LcPrintAPIURL';
import { getClassDivisionURL } from '../Services/PramotShiftStudentsAPIURL';
import { GetStudentLcDataDaud } from '../../CommonServices/LeavingCertificateAPIURL';
import Loader from '../../CommonComponent/Loader';
import AddStudenyBySearching from '../../CommonComponent/AddStudenyBySearching';

import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles(theme => ({
    select: {
        maxHeight: 160,
        "& ul": {
            backgroundColor: "#F4ECF7",
            //maxHeight: 160,
        },
        "& li": {
            // backgroundColor: "#F4ECF7", fontWeight: "550", fontSize: "14px" 
            fontSize: "14px",
            fontWeight: "550"
        },
    },

}));


const LcPrint = () => {
    const classes = useStyles();
    // loader on/off
    const [loaderOption, setLoaderOption] = useState(false);

    const authUser = useSelector((state) => state.user.value);
    const titleId = "Leaving Certificate";

    const [data, setData] = useState([]);
    const [classDivData, setClassDivData] = useState([]);
    const [classDivObj, setClassDivObj] = useState(null);
    const [studentId, setStudentId] = useState("");
    const [studentObj, setStudentObj] = useState(null);
    const [btnDisabled, setBtnDisabled] = useState("disabled");
    const [languageId, setLanguageId] = useState(1);
    const [option, setOption] = useState(1);
    const [waterMark, setWaterMark] = useState(0);

    const [division, setDivision] = useState("");
    const [classData, setclassData] = useState("");
    const [selectedStudent, setSelectedStudent] = useState("");

    const [studentLcNo, setStudentLcNo] = useState('');
    const [studentDetails, setStudentDetails] = useState({});


    const HandleWaterMarkCheckbox = (e) => {
        // setWaterMark(e.target.checked);
        // if (e.target.checked == true) {
        //     setWaterMark(1);
        // }
        // else {
        //     setWaterMark(0);
        // }
        setWaterMark(e.target.checked ? 1 : 0);
    }



    //Student Entry required data
    const [countData, setCountData] = useState(null);
    const [offset, setOffset] = useState(0);
    const [limits, setLimits] = useState(200);
    const [numCal, setNumCall] = useState(0);

    useEffect(() => {
        if (authUser.deptId !== null && authUser.branchId !== null) {
            getData();
        }
    }, [numCal, authUser])


    const getClassData = async () => {
        if (authUser.deptId != null && authUser.branchId != null) {
            await axios.get(`${getClassDivisionURL}?sid=${authUser.deptId}&bid=${authUser.branchId}`)
                .then((response) => {
                    setClassDivData(response.data)
                })
        }
    }

    useEffect(() => {
        getClassData();
    }, [authUser])


    const getData = async () => {
        await axios.get(`${GetStudentLcData}?sid=${authUser.deptId}&bid=${authUser.branchId}&limit=${limits}&offset=${offset}`)
            .then((res) => {
                if (res.data !== null) {
                    const tempData = res.data;
                    //console.log(tempData.length)
                    setData(data.concat(tempData));
                    //setFilteredData(filteredData.concat(tempData));
                    //console.log("Data : " + JSON.stringify(data))
                    //console.log("____________________________________________")
                    if (tempData.length === limits) {
                        setOffset(offset + limits);
                        setNumCall(numCal + 1);
                        //console.log("Offset ===============" + offset);
                    }
                }
            })
    }

    useEffect(() => {
        if (studentId == "" || studentId == null) {
            setBtnDisabled("disabled")
        }
        else {
            setBtnDisabled("")
        }
    }, [studentId])

    const printLcReport = async () => {
        setLoaderOption(true);
        // console.log(`${printLcAPIURL}?sid=${authUser.deptId}&bid=${authUser.branchId}&langId=${(languageId == 1) ? 1 : 2}&lcNo=${studentLcNo}&waterMark=${waterMark}&regNo=${studentId}`)
        await axios(`${printLcAPIURL}?sid=${authUser.deptId}&bid=${authUser.branchId}&langId=${(languageId == 1) ? 1 : 2}&lcNo=${studentLcNo}&waterMark=${waterMark}&regNo=${studentId}`)
            .then(res => {
                setLoaderOption(false);
                if (res.data === "NOTFOUND") {
                    setLoaderOption(false);
                    toast.warn("Data not found.")

                }
                else if (res.data === "NAMENOTFOUND") {
                    setLoaderOption(false);
                    toast.warn(`This student name is not found with language ${languageId == 1 ? "Marathi" : "English"}`)
                }
                else if (res.data != null) {
                    window.open(`${getPrintLcAPIURL}${res.data}`)
                    setLoaderOption(false);
                }
            })
            .catch((error) => {
                setLoaderOption(false);
                toast.error("Error Occurred, Try after sometime.");
                console.log("error name: " + error);
            });
    }

    const printLcReportClassDivWise = async () => {
        setLoaderOption(true);
        // console.log(`${lcPrintClassDivWise}?sid=${authUser.deptId}&bid=${authUser.branchId}&langId=${(languageId == 1) ? 1 : 2}&waterMark=${waterMark}&classId=${classDivObj.classId}&divId=${classDivObj.divisionId}&acYear=${authUser.sessionYear}`)
        await axios(`${lcPrintClassDivWise}?sid=${authUser.deptId}&bid=${authUser.branchId}&langId=${(languageId == 1) ? 1 : 2}&waterMark=${waterMark}&classId=${classDivObj.classId}&divId=${classDivObj.divisionId}&acYear=${authUser.sessionYear}`)
            .then(res => {
                // console.log(res.data);
                if (res.data === "NOTFOUND") {
                    setLoaderOption(false);
                    toast.warn("Data not found for this class-division.")

                }
                else if (res.data === "NAMENOTFOUND") {
                    setLoaderOption(false);
                    toast.warn(`This student name is not found with language ${languageId == 1 ? "Marathi" : "English"}`)
                }
                else if (res.data != null) {
                    window.open(`${getPrintLcAPIURL}${res.data}`)
                    setLoaderOption(false);
                }

            })
            .catch((error) => {

                toast.error("Error Occurred, Try after sometime.");
                console.log("error name: " + error);
                setLoaderOption(false);
            });
    }


    const getStudentLcReport = e => {
        e.preventDefault();
        if (option == 1) {
            printLcReport();
        }
        else if (option == 2) {
            printLcReportClassDivWise();
        }
    }


    return (
        <>
            {
                (loaderOption == true)
                    ?
                    <Loader />
                    :
                    <div>
                        <TitleLabel titleId={titleId} />
                        <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: '350px' }}>
                            <form onSubmit={getStudentLcReport}>
                                <div className='row'>
                                    <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Language:</label>
                                    <div className='col-sm-3'>

                                        <FormControl fullWidth margin='dense'>
                                            <InputLabel size="small" id='demo-simple-select-label' style={{ fontSize: '14px' }}>Select Language</InputLabel>
                                            <Select
                                                MenuProps={{ classes: { paper: classes.select } }}
                                                size='small'
                                                required
                                                fullWidth

                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={languageId}
                                                label="Select Language"
                                                onChange={(e) => {
                                                    setLanguageId(e.target.value);
                                                    setOption(1);
                                                    setClassDivObj(null);
                                                    setStudentObj(null);
                                                    setclassData('');
                                                    setDivision('');
                                                    setStudentId('');
                                                    setSelectedStudent('');
                                                }}
                                            >
                                                <MenuItem value={1}>Marathi</MenuItem>
                                                <MenuItem value={2}>English</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div>

                                <div className='row my-2'>
                                    <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Option :</label>
                                    <div className='col-sm-3'>

                                        <FormControl fullWidth margin='dense'>
                                            <InputLabel size="small" id='demo-simple-select-label' style={{ fontSize: '14px' }}>Select Option</InputLabel>
                                            <Select
                                                MenuProps={{ classes: { paper: classes.select } }}
                                                size='small'
                                                required
                                                fullWidth

                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={option}
                                                label="Select Option"
                                                onChange={(e) => {
                                                    setOption(e.target.value);
                                                    setClassDivObj(null);
                                                    setStudentObj(null);
                                                    setclassData('');
                                                    setDivision('');
                                                    setStudentId('');
                                                    setSelectedStudent('');
                                                }}
                                            >
                                                <MenuItem value={1}>Student Wise</MenuItem>
                                                <MenuItem value={2}>Class-division Wise</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div>

                                {(() => {
                                    if (option == 1) {
                                        return (
                                            <>
                                                {/* <div className='row my-2'>
                                                    <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Student :</label>
                                                    <div className='col-sm-3'>
                                                        <Autocomplete
                                                            id="combo-box-demo"
                                                            //disableClearable

                                                            options={data}
                                                            style={{ fontSize: '14px' }}
                                                            PaperComponent={({ children }) => (
                                                                <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                                            )}
                                                            onChange={(event, item) => {
                                                                setStudentId(item.lcNo)
                                                                setStudentObj(item)
                                                            }}
                                                            value={studentObj}

                                                            getOptionLabel={option => (authUser.branchMedium == 1) ? option.regCode + " - " + option.lcNo + " - " + ((option.studentNameMr == "") ? option.studentNameEn : option.studentNameMr) + "  " + option.classNameMr + "  " + option.lcDate : option.regCode + " - " + option.lcNo + " - " + ((option.studentNameEn == "") ? option.studentNameMr : option.studentNameEn) + "  " + option.classNameEn + "  " + option.lcDate}
                                                            size="small"
                                                            renderInput={params => (
                                                                <TextField {...params} margin='dense' inputProps={{ ...params.inputProps, autoComplete: "off" }} label="Select Student" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }}
                                                                    required={(option == 1) ? true : false} />
                                                            )}
                                                        />
                                                    </div>
                                                    <div className='col-sm-2 mt-1 '>
                                                        <FormControlLabel control={<Checkbox checked={Boolean(waterMark)} onChange={HandleWaterMarkCheckbox} />} label="Watermark" />
                                                    </div>
                                                    
                                                </div> */}
                                                <div className="my-2">
                                                    <AddStudenyBySearching
                                                        setclassData={setclassData}
                                                        setDivision={setDivision}
                                                        setStudentId={setStudentId}
                                                        setSelectedStudent={setSelectedStudent}
                                                        classData={classData}
                                                        division={division}
                                                        studentId={studentId}
                                                        selectedStudent={selectedStudent}
                                                        setStudentLcNo={setStudentLcNo}
                                                        label="LC No"
                                                        setStudentDetails={setStudentDetails}
                                                        className="col-sm-2"
                                                        readOnly={false}
                                                        setLoaderOption={setLoaderOption}
                                                    />

                                                    <div className='col-sm-2 mt-1 offset-sm-2'>
                                                        <FormControlLabel control={<Checkbox checked={Boolean(waterMark)} onChange={HandleWaterMarkCheckbox} />} label="Watermark" />
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    }
                                    else if (option == 2) {
                                        return (
                                            <>
                                                <div className='row my-2'>
                                                    <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Class-division :</label>
                                                    <div className='col-sm-3'>
                                                        <Autocomplete
                                                            id="combo-box-demo"
                                                            //disableClearable

                                                            options={classDivData}
                                                            style={{ fontSize: '14px' }}
                                                            PaperComponent={({ children }) => (
                                                                <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                                            )}
                                                            onChange={(event, item) => {
                                                                setClassDivObj(item)
                                                            }}
                                                            value={classDivObj}

                                                            getOptionLabel={option => (authUser.branchMedium == 1) ? option.classNameMr + " " + option.divisionNameMr : option.classNameEn + " " + option.divisionNameEn}
                                                            size="small"
                                                            renderInput={params => (
                                                                <TextField {...params} margin='dense' label="Select Class-division" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }}
                                                                    required={(option == 2) ? true : false} />
                                                            )}
                                                        />
                                                    </div>
                                                    <div className='col-sm-2 mt-1'>
                                                        <FormControlLabel control={<Checkbox checked={Boolean(waterMark)} onChange={HandleWaterMarkCheckbox} />} label="Watermark" />
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    }

                                })()}

                                <div className="row mt-2">
                                    <div className='col-sm-12 offset-sm-2'>
                                        <button
                                            type='submit'
                                            className='btn btn-primary btn-sm'
                                        >
                                            <PrintIcon fontSize="small" /> Print
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
            }

            <ToastContainer position="top-right" theme="colored" />
        </>
    )
}




export default LcPrint