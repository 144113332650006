import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import TitleLabel from "../../CommonComponent/TitleLabel";
import Autocomplete from "@mui/material/Autocomplete";
import axios from 'axios';
import Paper from "@mui/material/Paper";
import { ToastContainer, toast } from 'react-toastify';
import { ClassMasterGetMapping } from '../../CommonServices/ClassMasterAPIURL';
import { DivisioneMasterGetMapping, DivisionePutMapping, DivisionePostMapping } from '../../CommonServices/DivisionMasterAPIURL';
import MasterFormDivisionTable from './MasterFormDivisionTable';
import { useSelector } from "react-redux";
import Loader from '../../CommonComponent/Loader';

const MasterFormDivision = () => {
  const titleId = "Division";
  const authUser = useSelector((state) => state.user.value);

  const [loaderOption, setLoaderOption] = useState(false);

  //Note: This below states are declared for Save & Update(Disabled/unDisabled)

  const saveBtnStyle = "divMasterSaveBtn btn btn-primary btn-sm";
  const updateBtnStyle = "divMasterUpdateBtn btn btn-primary btn-sm";
  const displayNoneBtnStyle = "d-none";
  const [saveBtn, setSaveBtn] = useState(saveBtnStyle);
  const [updateBtn, setUpdateBtn] = useState(displayNoneBtnStyle);

  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState(data);
  const [id, setId] = useState()
  const [divisionNameMr, setDivisionNameMr] = useState("")
  const [divisionNameEn, setDivisionNameEn] = useState("")

  const [classData, setClassData] = useState([]);
  const [classId, setClassId] = useState(null);
  const [classObj, setClassObj] = useState(null);



  useEffect(() => {
    getData();
  }, [])

  const getData = () => {
    // console.log(DivisioneMasterGetMapping);
    axios.get(`${DivisioneMasterGetMapping}`)
      .then((response) => {
        setData(response.data);
        setFilteredData(response.data);
      })
    axios.get(`${ClassMasterGetMapping}`)
      .then((response) => {
        setClassData(response.data);
      })
  }

  const saveBtnClick = () => {
    setLoaderOption(true);
    const saveObject = {
      id: id,
      nameMr: divisionNameMr,
      nameEn: divisionNameEn,
      classId: classId,
    };
    axios.post(`${DivisionePostMapping}`, saveObject)
      .then((response) => {
        if (response.data != null) {
          getData();
          setLoaderOption(false);
          toast.success("Saved sucessfully.");
        } else {
          toast.error("Operation failed.");
        }
      });

    setDivisionNameMr("");
    setDivisionNameEn("");
    setId("");
    setClassId(null);
    setClassObj(null);
    setSaveBtn(saveBtnStyle);
    setUpdateBtn(displayNoneBtnStyle);
  };

  const updateBtnClick = () => {
    setLoaderOption(true);
    const updateObject = {
      id: id,
      nameMr: divisionNameMr,
      nameEn: divisionNameEn,
      classId: classId,
    };
    axios.put(`${DivisionePutMapping}` + id, updateObject)
      .then((response) => {
        if (response.data != null) {
          getData();
          setLoaderOption(false);
          toast.success("Update sucessfully.");
        }
      });
    setDivisionNameMr("");
    setDivisionNameEn("");
    setId("");
    setClassId(null);
    setClassObj(null);
    setSaveBtn(saveBtnStyle);
    setUpdateBtn(displayNoneBtnStyle);
  };

  const ClearData = () => {
    setDivisionNameMr("");
    setDivisionNameEn("");
    setId("");
    setClassId(null);
    setClassObj(null);
    setSaveBtn(saveBtnStyle);
    setUpdateBtn(displayNoneBtnStyle);
  }

  const state = {
    button: 1
  };

  const onSubmit = e => {
    e.preventDefault();
    if (state.button === 1) {
      saveBtnClick();
    }
    if (state.button === 2) {
      updateBtnClick();
    }
  };

  const handleKeyDownMr = (e) => {
    if ((e.key === " " && divisionNameMr.length === 0)) {
      e.preventDefault();
    }
  };

  const handleKeyDownEn = (e) => {
    if (e.key === " " && divisionNameEn.length === 0) {
      e.preventDefault();
    }
  };

  useEffect(() => {

    function handlekeydownEvent(event) {
      if (event.key === "Enter") {
        // console.log("Enter is pressed!")
        event.preventDefault();

        let btn1 = document.querySelector('.divMasterSaveBtn')
        if (btn1 !== null) {
          btn1.click();
        }
        let btn2 = document.querySelector('.divMasterUpdateBtn')
        if (btn2 !== null) {
          btn2.click();
        }

      }
    }

    document.addEventListener('keypress', handlekeydownEvent)
    return () => {
      document.removeEventListener('keypress', handlekeydownEvent)
    }

  }, []);

  const TableHeading = [{ label: 'ID', key: 'id' }, { label: `Class`, key: (authUser.branchMedium == 1) ? 'classNameMr' : 'classNameEn' }, { label: `Division Name Mr`, key: 'nameMr' }, { label: `Division Name En`, key: 'nameEn' }, { label: 'Edit', value: 'edit', isAction: true }, { label: 'Delete', value: 'delete', isAction: true }];

  if (loaderOption == true) {
    return <Loader />
  }
  else {
    return (
      <>
        <TitleLabel titleId={titleId} />
        <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px" }}>
          <form onSubmit={onSubmit}>
            <input type="hidden" value={id} onChange={(e) => setId(e.target.value)} name="" />
            <div className='row my-2'>
              <div className="col-sm-2">
                <label style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }} >Class:</label>
              </div>
              <div className="col-sm-4 ">
                <Autocomplete
                  id="combo-box-demo"
                  PaperComponent={({ children }) => (
                    <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                  )}
                  options={classData}
                  value={classObj}
                  onChange={(event, newValue) => {
                    setClassId(newValue.id);
                    setClassObj(newValue);
                  }}
                  onKeyPress={(e) => (e.key == "Enter") ? classObj : ""}
                  style={{ fontSize: '14px' }}
                  getOptionLabel={option => (authUser.branchMedium == 1) ? option.nameMr : option.nameEn}
                  size="small"
                  renderInput={params => (
                    <TextField {...params} margin='dense' label="Select Class" variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                  )}
                />
              </div>
            </div>

            <div className="row my-3">
              <label className="col-sm-2" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }} >{titleId} Name:</label>
              <div className="col-sm-4">
                <TextField
                  required
                  fullWidth
                  onKeyDown={handleKeyDownMr}
                  autoComplete='off'
                  size='small'
                  id="outlined-basic"
                  label="Division Name Marathi"
                  variant="outlined"
                  margin='dense'
                  value={divisionNameMr}
                  onChange={e => setDivisionNameMr(e.target.value)}
                  onKeyPress={(e) => (e.key == "Enter") ? divisionNameMr : ""}
                  InputLabelProps={{ style: { fontSize: 14 } }}
                  inputProps={{ maxLength: 120 }}
                />
              </div>

              <div className="col-sm-4">
                <TextField
                  required
                  fullWidth
                  onKeyDown={handleKeyDownEn}
                  autoComplete='off'
                  InputLabelProps={{ style: { fontSize: 14 } }}
                  size='small'
                  id="outlined-basic"
                  label="Division Name English"
                  variant="outlined"
                  margin='dense'
                  value={divisionNameEn}
                  onChange={e => setDivisionNameEn(e.target.value)}
                  onKeyPress={(e) => (e.key == "Enter") ? divisionNameEn : ""}
                  inputProps={{ maxLength: 120 }}
                />
              </div>
            </div>

            <div className="col-sm-12 mt-3">
              <div className="offset-sm-2">
                <button type="submit" onClick={() => (state.button = 1)} className={saveBtn} >Save</button>
                <button type="submit" onClick={() => (state.button = 2)} className={updateBtn} >Update</button>
                <button type="button" onClick={ClearData} className="btn btn-danger btn-sm" style={{ margin: '0px 4px' }} >Cancel</button>

              </div>
            </div>
          </form>

          <div>
            <MasterFormDivisionTable
              TableHeading={TableHeading}
              setId={setId}
              setDivisionNameEn={setDivisionNameEn}
              setDivisionNameMr={setDivisionNameMr}
              classId={classId}
              classData={classData}
              setClassId={setClassId}
              setClassObj={setClassObj}
              getData={getData}
              data={data}
              setData={setData}
              setFilteredData={setFilteredData}
              filteredData={filteredData}
              saveBtnStyle={saveBtnStyle}
              updateBtnStyle={updateBtnStyle}
              displayNoneBtnStyle={displayNoneBtnStyle}
              setSaveBtn={setSaveBtn}
              setUpdateBtn={setUpdateBtn}
            />
          </div>
        </div>
        <ToastContainer position="top-right" theme="colored" />

      </>
    )
  }

};

export default MasterFormDivision;
