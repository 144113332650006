import { DatePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { Autocomplete, Box, FormControl, InputLabel, MenuItem, Paper, Select, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react'
import TitleLabel from '../../CommonComponent/TitleLabel';
import EmployeeBioAttendanceTable from './EmployeeBioAttendanceTable';
import makeStyles from "@mui/styles/makeStyles";
import axios from 'axios';
import { useSelector } from 'react-redux';
import { BaseURLInstituteMaster } from '../../CommonServices/InstituteMasterAPIURL';
import { getEmployeeAttendaceDetailsByEmpId, getEmployeeAttendanceDataAPIURL, getMonthWiseEmployeeAttendAPIURL } from '../Services/EmployeeTransactionsAPIURL';
import { toast } from 'react-toastify';
import moment from 'moment';
import Loader from '../../CommonComponent/Loader';
import EmpBioAttendanceMonthWise from './EmpBioAttendanceMonthWise'; import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal'
import { EmployeeMasterBranchWiseGetData } from '../../CommonServices/EmployeeMasterAPIURL';

const useStyles = makeStyles(theme => ({
  menuPaper: {
    maxHeight: 200,
    "& ul": {
      backgroundColor: "#F4ECF7",
      //maxHeight: 160,
    },
    "& li": {
      // backgroundColor: "#F4ECF7", fontWeight: "550", fontSize: "14px" 
      fontSize: "14px",
      fontWeight: "550"
    },
  }
}));

const EmployeeBiometricAttendace = () => {
  const titleId = "Employee Attendance";
  const classes = useStyles();
  const authUser = useSelector((state) => state.user.value);

  //Note: This below states are declared for Save & Update(Disabled/unDisabled)
  const saveBtnStyle = "empAttendanceSaveBtn btn btn-primary btn-sm";
  const updateBtnStyle = "empAttendanceUpdateBtn btn btn-primary btn-sm";
  const displayNoneBtnStyle = "d-none";
  const [saveBtn, setSaveBtn] = useState(saveBtnStyle);
  const [updateBtn, setUpdateBtn] = useState(displayNoneBtnStyle);

  const [loaderOption, setLoaderOption] = useState(false);

  const [employeeData, setEmployeeData] = useState([]);
  const [employeeObj, setEmployeeObj] = useState(null);
  const [fromDate, setFromDate] = useState(new Date());
  //console.log(fromDate)
  const [toDate, setToDate] = useState(new Date());
  const [option, setOption] = useState(1);
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [schoolData, setSchoolData] = useState([]);
  const [schoolObj, setSchoolObj] = useState(null);

  const [attendFor, setAttendFor] = useState(1);
  const [chkDate, setChkDate] = useState(false);

  const [tableheading, setTableHeading] = useState([]);

  useEffect(() => {
    getData();
  }, [authUser]);


  const getData = async () => {
    // console.log(`${EmployeeMasterBranchWiseGetData}?bid=${authUser.branchId}`);
    if (authUser.branchId != null && authUser.branchId != 50) {
      await axios.get(`${EmployeeMasterBranchWiseGetData}?bid=${authUser.branchId}`)
        .then((response) => {
          setEmployeeData(response.data);
        })
    }

    await axios.get(BaseURLInstituteMaster)
      .then(res => {
        //const newData = [{ id: 1, schoolNameMr: "All", schoolNameEn: "All" }, ...res.data]
        setSchoolData(res.data);
      })
  }

  //get employee list by school id for sanstha
  const getEmployeeDetails = async (schoolid) => {
    //console.log(`${EmployeeMasterBranchWiseGetData}?bid=${schoolid}`)
    await axios.get(`${EmployeeMasterBranchWiseGetData}?bid=${schoolid}`)
      .then((response) => {
        setEmployeeData(response.data);
      })
  }

  //get employee details
  const getEmpAttendaceDetails = async (e) => {
    e.preventDefault();
    setLoaderOption(true);
    setData([]);
    setFilteredData([]);
    //date wise emp attend
    if (attendFor == 1) {
      if (moment(fromDate).format('DD/MM/YYYY') != 'Invalid date' && moment(toDate).format("DD/MM/YYYY") != 'Invalid date') {
        // console.log(`${getEmployeeAttendanceDataAPIURL}?option=${option}&attDateFrom=${moment(fromDate).format("YYYY-MM-DD")}&attDateTo=${(option == 1) ? null : moment(toDate).format("YYYY-MM-DD")}&empId=${(option == 1) ? 0 : employeeObj.employeeId}&bid=${(authUser.branchId == 50) ? schoolObj.id : authUser.branchId}&year=${authUser.sessionYear}`)
        await axios.get(`${getEmployeeAttendanceDataAPIURL}?option=${option}&attDateFrom=${moment(fromDate).format("YYYY-MM-DD")}&attDateTo=${(option == 1) ? null : moment(toDate).format("YYYY-MM-DD")}&empId=${(option == 1) ? 0 : employeeObj.employeeId}&bid=${(authUser.branchId == 50) ? schoolObj.id : authUser.branchId}&year=${authUser.sessionYear}`)
          .then(res => {
            if (res.data.length == 0) {
              setLoaderOption(false);
              toast.warn("Data not found.")
            }
            else {
              setData(res.data);
              setFilteredData(res.data);
              setLoaderOption(false);
            }
          }).catch(err => {
            setLoaderOption(false);
            console.log("get emp attendance " + err);
            toast.error("Something went wrong, please check.")
          })
      }
      else {
        setLoaderOption(false);
        toast.error("Improper date, please check.")
      }
    }
    //month wise emp attend
    else if (attendFor == 2) {
      if (moment(fromDate).format('DD/MM/YYYY') != 'Invalid date') {
        // console.log(`${getMonthWiseEmployeeAttendAPIURL}?option=${option}&date=${moment(fromDate).format("MM/YYYY")}&empId=${(option == 1) ? 0 : employeeObj.employeeId}&bid=${(authUser.branchId == 50) ? schoolObj.id : authUser.branchId}&year=${authUser.sessionYear}`)
        await axios.get(`${getMonthWiseEmployeeAttendAPIURL}?option=${option}&date=${moment(fromDate).format("MM/YYYY")}&empId=${(option == 1) ? 0 : employeeObj.employeeId}&bid=${(authUser.branchId == 50) ? schoolObj.id : authUser.branchId}&year=${authUser.sessionYear}`)
          .then(res => {
            if (res.data.length == 0) {
              setLoaderOption(false);
              toast.warn("Data not found.")
            }
            else {
              //get single employee
              const objIds = res.data.reduce((a, { empNameMr, empNameEn, empId, isPresent, day, date, bioStatus, isLeave }) => {
                a[empId] = a[empId] || { monthDetails: [] }
                return {
                  ...a, ...{
                    [empId]: {
                      empNameMr,
                      empNameEn,
                      empId,
                      monthDetails: a[empId].monthDetails.concat({
                        day: day,
                        date: date,
                        isPresent: isPresent,
                        isLeave: isLeave
                      }),
                      bioStatus
                    }
                  }
                }
              }, {})
              const result = Object.values(objIds)
              //console.log(JSON.stringify(result))
              setData(result);
              setFilteredData(result);

              //TableHeading for moth wise emp attend
              const objIds2 = res.data.reduce((a, { day, date }) => {
                a[date] = a[date] || { date, day, monthDetails: [] }
                return {
                  ...a, ...{
                    [date]: {
                      date: date,
                      day: day,
                    }
                  }
                }
              }, {})
              const result1 = Object.values(objIds2)
              //console.log(JSON.stringify(result1.sort((a, b) => a.date > b.date ? 1 : -1)))
              setTableHeading(result1.sort((a, b) => a.date > b.date ? 1 : -1));
              setLoaderOption(false);
            }
          }).catch(err => {
            setLoaderOption(false);
            console.log("get emp attendance " + err);
            toast.error("Something went wrong, please check.")
          })
      }
      else {
        setLoaderOption(false);
        toast.error("Improper date, please check.")
      }
    }
  }

  //clear textfields
  const clearData = () => {
    setEmployeeData([]);
    setEmployeeObj(null);
    setFromDate(new Date());
    setToDate(new Date());
    setData([]);
    setFilteredData([]);
    setOption(1);
    setAttendFor(1);
    setSchoolObj(null);
  }

  //All Employees TableHeading
  const TableHeading1 = [
    { label: 'Emp. Id', key: 'empId', textAlign: '' },
    { label: `Employee Name`, key: (authUser.branchMedium == 1) ? 'empNameMr' : 'empNameEn', textAlign: '' },
    { label: `Employee Type`, key: 'empType' },
    { label: 'Bio.Id', key: 'bioId', textAlign: '' },
    { label: `Bio.Id Status`, key: 'bioStatus', isAction: true },
    { label: `Duty_In`, key: 'onTime', textAlign: 'center' },
    { label: `Duty_Off`, key: 'offTime', textAlign: 'center' },
    { label: `Status`, key: 'status', textAlign: 'center', isAction: true },
  ];

  //Employee wise TableHeading
  const TableHeading2 = [
    { label: 'Id', key: 'empId', textAlign: '' },
    { label: `Date`, key: 'attDate', textAlign: '' },
    { label: `Duty_In`, key: 'onTime', textAlign: 'center' },
    { label: `Duty_Off`, key: 'offTime', textAlign: 'center' },
    { label: `Status`, key: 'status', textAlign: 'center', isAction: true },
  ];

  //states to get employee attedace if emp prsesent
  const [showModal, setShowModal] = useState(false);
  const [empAttendDate, setEmpAttendDate] = useState('');
  const [empName, setEmpName] = useState('');
  const [empOnTime, setEmpOnTime] = useState('');
  const [empOffTime, setEmpOffTime] = useState('');
  const [empType, setEmpType] = useState('');
  const [empBioId, setEmpBioId] = useState('');

  //close the modal
  const handleClose = () => {
    setShowModal(false);
  };


  return (
    <>
      {
        (loaderOption == true)
          ?
          <Loader />
          :
          <>
            <TitleLabel titleId={titleId} />
            <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: '350px' }}>
              <form onSubmit={getEmpAttendaceDetails}>
                <div className="row" hidden={(authUser.branchId != 50) ? true : false}>
                  <label className="" style={{ width: "140px", fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >School :</label>
                  <div className='col-sm-6'>
                    <Autocomplete
                      id="combo-box-demo"
                      options={schoolData}
                      PaperComponent={({ children }) => (
                        <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                      )}
                      onChange={(event, newValue) => {
                        setSchoolObj(newValue);
                        setOption(1);
                        setAttendFor(1);
                        setEmployeeData([]);
                        setEmployeeObj(null);
                        setFromDate(new Date());
                        setToDate(new Date());
                        setData([]);
                        setFilteredData([]);
                        setChkDate(false);
                        if (newValue != null) {
                          getEmployeeDetails(newValue.id);
                        }
                      }}
                      value={schoolObj}
                      style={{ fontSize: '14px' }}
                      getOptionLabel={option => ((authUser.branchMedium == 1) ? option.schoolNameMr : option.schoolNameEn)}
                      size="small"
                      renderInput={params => (
                        <TextField {...params} margin='dense' label="Select School" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required={(authUser.branchId == 50) ? true : false} />
                      )}
                    />
                  </div>
                </div>

                <div className="row my-2">
                  <label className="" style={{ width: "140px", fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Option :</label>
                  <div className='col-sm-3'>
                    <FormControl fullWidth margin='dense'>
                      <InputLabel id='demo-simple-select-label' size="small" style={{ fontSize: '14px' }}>Select Option</InputLabel>
                      <Select
                        size='small'
                        required
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={attendFor}
                        label="Select Option"
                        MenuProps={{ classes: { paper: classes.menuPaper } }}
                        onChange={(e) => {
                          setAttendFor(e.target.value)
                          setOption(1);
                          setEmployeeObj(null);
                          setFromDate(new Date());
                          setToDate(new Date());
                          setData([]);
                          setFilteredData([]);
                          setChkDate(false);
                        }}
                        onKeyPress={(e) => (e.key == "Enter") ? attendFor : ""}
                      >
                        <MenuItem value={1}>Date Wise</MenuItem>
                        <MenuItem value={2}>Month Wise</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                </div>

                <div className="row my-2">
                  <label className="" style={{ width: "140px", fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Option :</label>
                  <div className='col-sm-3'>
                    <FormControl fullWidth margin='dense'>
                      <InputLabel id='demo-simple-select-label' size="small" style={{ fontSize: '14px' }}>Select Option</InputLabel>
                      <Select
                        size='small'
                        required
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={option}
                        label="Select Option"
                        MenuProps={{ classes: { paper: classes.menuPaper } }}
                        onChange={(e) => {
                          setOption(e.target.value);
                          setEmployeeObj(null);
                          setFromDate(new Date());
                          setToDate(new Date());
                          setData([]);
                          setFilteredData([]);
                          setChkDate(false);
                        }}
                        onKeyPress={(e) => (e.key == "Enter") ? option : ""}
                      >
                        <MenuItem value={1}>All Employees</MenuItem>
                        <MenuItem value={2}>Employee Wise</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                </div>

                {(() => {
                  if (option == 2) {
                    return (
                      <>
                        <div className="row my-2">
                          <label className="" style={{ width: "140px", fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Employee :</label>
                          <div className='col-sm-4'>
                            <Autocomplete
                              id="combo-box-demo"
                              options={employeeData}
                              PaperComponent={({ children }) => (
                                <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                              )}
                              onChange={(event, newValue) => {
                                setEmployeeObj(newValue);
                                setFromDate(new Date());
                                setToDate(new Date());
                                setData([]);
                                setFilteredData([]);
                                setChkDate(false);
                              }}
                              value={employeeObj}
                              style={{ fontSize: '14px' }}
                              getOptionLabel={option => option.employeeId + " - " + ((authUser.branchMedium == 1) ? option.employeeNameMr : option.employeeNameEn)}
                              renderOption={(props, option) => (
                                <div className='' {...props}>
                                  <div className=''>
                                    {option.employeeId + " - " + ((authUser.branchMedium == 1) ? option.employeeNameMr : option.employeeNameEn)}
                                  </div>
                                </div>
                              )}
                              size="small"
                              renderInput={params => (
                                <TextField {...params} margin='dense' label="Select Employee" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required={(option == 2) ? true : false} />
                              )}
                            />
                          </div>
                        </div>
                      </>
                    )
                  }
                })()}

                <div className="row my-2">
                  {(() => {
                    if (attendFor == 1) {
                      return (
                        <>
                          <label htmlFor="lastname3" className="" style={{ width: "140px", fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>Date From :</label>
                          <div className='col-sm-3'>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <DatePicker
                                label="Date From"
                                value={fromDate}
                                onChange={(newValue) => {
                                  setFromDate(newValue);
                                  setData([]);
                                  setFilteredData([]);
                                  setChkDate(false);
                                }}
                                onKeyPress={(e) => (e.key == "Enter") ? fromDate : ""}
                                inputFormat="dd/MM/yyyy"
                                renderInput={(params) =>
                                  <TextField
                                    {...params}
                                    margin='dense'
                                    variant="outlined"
                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                    required
                                    fullWidth
                                    autoComplete='off'
                                    size="small" />}
                              />
                            </LocalizationProvider>
                          </div>
                        </>
                      )
                    }
                    else {
                      return (
                        <>
                          <label htmlFor="lastname3" className="" style={{ width: "140px", fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>Date From :</label>
                          <div className='col-sm-3'>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <DatePicker
                                label="Date From"
                                value={fromDate}
                                onChange={(newValue) => {
                                  // if (isValidDate(newValue)) {
                                  //   console.log(newValue);
                                  // }
                                  setFromDate(newValue);
                                  setData([]);
                                  setFilteredData([]);
                                  setChkDate(false);
                                }}
                                onKeyPress={(e) => (e.key == "Enter") ? fromDate : ""}
                                views={['year', 'month']}
                                inputFormat="MM/yyyy"
                                mask="__/____"
                                disableMaskedInput={false}
                                renderInput={(params) =>
                                  <TextField
                                    {...params}
                                    margin='dense'
                                    variant="outlined"
                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                    required
                                    fullWidth
                                    autoComplete='off'
                                    size="small" />}
                              />
                            </LocalizationProvider>
                          </div>
                        </>
                      )
                    }
                  })()}

                  <label hidden={(option == 1 || attendFor == 2) ? true : false} htmlFor="lastname3" className="" style={{ width: "80px", fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>To :</label>
                  <div hidden={(option == 1 || attendFor == 2) ? true : false} className='col-sm-3'>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        label="Date"
                        value={toDate}
                        onChange={(newValue) => {
                          setToDate(newValue);
                          setData([]);
                          setFilteredData([]);
                          setChkDate(false);
                        }}
                        onKeyPress={(e) => (e.key == "Enter") ? toDate : ""}
                        inputFormat="dd/MM/yyyy"
                        renderInput={(params) =>
                          <TextField
                            {...params}
                            margin='dense'
                            variant="outlined"
                            InputLabelProps={{ style: { fontSize: 14 } }}
                            required={(option == 2 || attendFor == 2) ? true : false}
                            fullWidth
                            autoComplete='off'
                            size="small" />}
                      />
                    </LocalizationProvider>
                  </div>
                </div>

                <div className="row mt-3">
                  <div className="col-sm-4" style={{ marginLeft: "140px" }}>
                    <button type='submit' className={saveBtn}>Show</button>
                    <button type='button' className='btn btn-sm btn-danger ml-2' onClick={() => clearData()}>Cancel</button>
                  </div>
                </div>
              </form>

              <div className="mt-5">
                {
                  (() => {
                    if (data.length != 0 || filteredData.length != 0) {
                      if (attendFor == 1) {
                        return (
                          <EmployeeBioAttendanceTable
                            TableHeading={(option == 1) ? TableHeading1 : TableHeading2}
                            data={data}
                            setData={setData}
                            filteredData={filteredData}
                            setFilteredData={setFilteredData}
                            option={option}
                            employeeObj={employeeObj}
                            schoolObj={schoolObj}
                            authUser={authUser}
                          />
                        )
                      }
                      else if (attendFor == 2) {
                        return (
                          <>
                            <EmpBioAttendanceMonthWise
                              option={option}
                              data={data}
                              setData={setData}
                              filteredData={filteredData}
                              setFilteredData={setFilteredData}
                              employeeObj={employeeObj}
                              schoolObj={schoolObj}
                              authUser={authUser}
                              tableheading={tableheading}
                              fromDate={fromDate}
                              setLoaderOption={setLoaderOption}
                              setShowModal={setShowModal}
                              setEmpAttendDate={setEmpAttendDate}
                              setEmpName={setEmpName}
                              setEmpOnTime={setEmpOnTime}
                              setEmpOffTime={setEmpOffTime}
                              setEmpType={setEmpType}
                              setEmpBioId={setEmpBioId}
                            />
                          </>
                        )
                      }
                    }
                  })()
                }
              </div>
            </div>

            <Modal show={showModal} onHide={handleClose} size='md'>
              <Modal.Header>
                <h3 style={{ textAlign: 'center' }}>Employee Attendace Details</h3>
              </Modal.Header>
              <Form>
                <Modal.Body>
                  <Form.Group className="mb-2" controlId="exampleForm.ControlInput2">

                    <Box>
                      <Form.Label style={{ width: "150px", fontSize: "16px", fontWeight: "600" }}>Biometric Id : </Form.Label>
                      <span style={{ color: "#045DB7", fontSize: "15px", fontWeight: "600" }}> {empBioId}</span>
                    </Box>

                    <Box>
                      <Form.Label style={{ width: "150px", fontSize: "16px", fontWeight: "600" }}>Employee Name : </Form.Label>
                      <span style={{ color: "#045DB7", fontSize: "15px", fontWeight: "600" }}> {empName}</span>
                    </Box>

                    <Box>
                      <Form.Label style={{ width: "150px", fontSize: "16px", fontWeight: "600" }}>Employee Type : </Form.Label>
                      <span style={{ color: "#045DB7", fontSize: "15px", fontWeight: "600" }}> {empType}</span>
                    </Box>

                    <Box>
                      <Form.Label style={{ width: "150px", fontSize: "16px", fontWeight: "600" }}>Date : </Form.Label>
                      <span style={{ color: "#045DB7", fontSize: "15px", fontWeight: "600" }}> {empAttendDate}</span>
                    </Box>

                    <Box>
                      <Form.Label style={{ width: "150px", fontSize: "16px", fontWeight: "600" }}>Duty_In : </Form.Label>
                      <span style={{ color: "#045DB7", fontSize: "15px", fontWeight: "600" }}> {empOnTime}</span>
                    </Box>

                    <Box>
                      <Form.Label style={{ width: "150px", fontSize: "16px", fontWeight: "600" }}>Duty_Off : </Form.Label>
                      <span style={{ color: "#045DB7", fontSize: "15px", fontWeight: "600" }}> {empOffTime}</span>
                    </Box>

                  </Form.Group>

                </Modal.Body>
                <Modal.Footer>
                  <Button variant="danger" type='button' onClick={handleClose}>
                    Close
                  </Button>
                </Modal.Footer>
              </Form>
            </Modal>
          </>
      }



    </>
  )
}

export default EmployeeBiometricAttendace