import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { BaseURLInstituteMaster } from '../../../CommonServices/InstituteMasterAPIURL';
import TitleLabel from '../../../CommonComponent/TitleLabel';
import { Autocomplete, Paper, TextField } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { getVotersKendraMasterAPIURL, saveVotersKendraMasterAPIURL, updateVotersKendraMasterAPIURL } from '../Services/VotersKendraMasterAPIURL';
import { toast } from 'react-toastify';

const VotersKendraMaster = () => {
    const titleId = "Voters Kendra Master";
    const authUser = useSelector((state) => state.user.value);

    const navigate = useNavigate();
    let { vkId, EditFlag } = useParams();

    // loader on/off
    const [loaderOption, setLoaderOption] = useState(false);

    //Note: This below states are declared for Save & Update(Disabled/unDisabled)
    const saveBtnStyle = "votersKendraSaveBtn btn btn-primary btn-sm";
    const updateBtnStyle = "votersKendraUpdateBtn btn btn-primary btn-sm";
    const displayNoneBtnStyle = "d-none";
    const [saveBtn, setSaveBtn] = useState(saveBtnStyle);
    const [updateBtn, setUpdateBtn] = useState(displayNoneBtnStyle);

    const [saveBtnDisabled, setSaveBtnDisabled] = useState(false);
    const [updateBtnDisabled, setUpdateBtnDisabled] = useState(false);

    const [id, setId] = useState();
    const [schholData, setSchoolData] = useState([]);
    const [votingKendra, setVotingKendra] = useState("");
    const [kendraAddress, setKendraAddress] = useState("");
    const [votingKendraNo, setVotingKendraNo] = useState("");


    useEffect(() => {
        if (EditFlag == 1) {
            getData();
            setSaveBtnDisabled(false);
            setUpdateBtnDisabled(true);
        }
        else if (EditFlag == 2) {
            getEditDetails();
            setSaveBtnDisabled(true);
            setUpdateBtnDisabled(false);
        }
    }, [authUser])

    const getData = async () => {
        console.log(BaseURLInstituteMaster)
        await axios.get(`${BaseURLInstituteMaster}`)
            .then((response) => {
                //console.log(response.data)
                setSchoolData(response.data);
            })

    }


    const getEditDetails = async () => {
        setLoaderOption(true);
        // console.log(`${getVotersKendraMasterAPIURL}`);
        await axios.get(`${getVotersKendraMasterAPIURL}`)
            .then((response) => {
                const newData = response.data.find(e => e.id == vkId)
                setSaveBtn(displayNoneBtnStyle);
                setUpdateBtn(updateBtnStyle);
                setVotingKendra(newData.voterKendraName);
                setVotingKendraNo(newData.voterKendraNo);
                setKendraAddress(newData.voterKendraAdd)
                setId(newData.id)
                setLoaderOption(false);

            }).catch(error => {
                setLoaderOption(false);
                console.log(error);
                toast.error("Something went wrong, please check.")
            })
    }

    const ClearData = () => {
        setId("");
        setVotingKendra("");
        setKendraAddress("");
        setVotingKendraNo("");
        navigate('/Home/m1402/')
        setSaveBtn(saveBtnStyle);
        setUpdateBtn(displayNoneBtnStyle);
    }

    const saveBtnClick = async () => {
        setLoaderOption(true);
        const saveObj = {
            "id": id,
            "voterKendraNo": votingKendraNo,
            "voterKendraName": votingKendra,
            "voterKendraAdd": kendraAddress
        }
        // console.log(JSON.stringify(saveObj))
        await axios.post(saveVotersKendraMasterAPIURL, saveObj)
            .then((response) => {
                if (response.data == "ALREADYEXISTS") {
                    setLoaderOption(false);
                    toast.warn(`Data with this details is already exists.`)
                }
                else if (response.data == "SAVED") {
                    ClearData();
                    getData();
                    setLoaderOption(false);
                    toast.success("Saved successfully.")
                }

            }).catch(error => {
                setLoaderOption(false);
                console.log(error);
                toast.error("Something went wrong, please check.")
            })
    }

    const updateBtnClick = async () => {
        setLoaderOption(true);
        const updateObj = {
            "id": vkId,
            "voterKendraNo": votingKendraNo,
            "voterKendraName": votingKendra,
            "voterKendraAdd": kendraAddress
        }
        // console.log(JSON.stringify(updateObj))
        await axios.put(updateVotersKendraMasterAPIURL, updateObj)
            .then((response) => {
                if (response.data == "ALREADYEXISTS") {
                    setLoaderOption(false);
                    toast.warn(`Data with this details is already exists.`)
                }
                else if (response.data == "UPDATED") {
                    ClearData();
                    getData();
                    setLoaderOption(false);
                    toast.success("Updated successfully.")
                }

            }).catch(error => {
                setLoaderOption(false);
                console.log(error);
                toast.error("Something went wrong, please check.")
            })
    }

    const state = {
        button: 1
    };

    const onSubmit = e => {
        e.preventDefault();
        if (state.button === 1) {
            saveBtnClick();
        }
        if (state.button === 2) {
            updateBtnClick();
        }
    };

    useEffect(() => {

        function handlekeydownEvent(event) {
            if (event.key === "Enter") {
                // console.log("Enter is pressed!")
                event.preventDefault();
                if (EditFlag == 1) {
                    // console.log('save Enter is pressed!');
                    let btn1 = document.querySelector('.votersKendraSaveBtn')
                    if (btn1 !== null) {
                        btn1.click();
                    }
                    setSaveBtnDisabled(false);
                    setUpdateBtnDisabled(true);
                }
                if (EditFlag == 2) {
                    // console.log('update Enter is pressed!');
                    let btn2 = document.querySelector('.votersKendraUpdateBtn')
                    if (btn2 !== null) {
                        btn2.click();
                    }
                    setSaveBtnDisabled(true);
                    setUpdateBtnDisabled(false);
                }
            }
        }

        document.addEventListener('keypress', handlekeydownEvent)
        return () => {
            document.removeEventListener('keypress', handlekeydownEvent)
        }

    }, []);


    return (
        <div>
            <TitleLabel titleId={titleId} />
            <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", position: "relative" }}>
                <form onSubmit={onSubmit}>
                    <input type="hidden" value={id} onChange={(e) => setId(e.target.value)} name="" />

                    <div className="row">
                        <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >मतदान केंद्र नंबर :</label>
                        <div className='col-sm-2'>
                            <TextField
                                required
                                onKeyDown={(e) => (e.key === " " && votingKendraNo.length === 0) ? e.preventDefault() : ""}
                                fullWidth
                                margin='dense'
                                type='number'
                                value={votingKendraNo}
                                onChange={e => setVotingKendraNo(e.target.value)}
                                onKeyPress={(e) => (e.key == "Enter") ? votingKendraNo : ""}
                                autoComplete='off'
                                size='small'
                                id="outlined-basic"
                                label="मतदान केंद्र नंबर"
                                variant="outlined"
                                InputLabelProps={{ style: { fontSize: 14 } }}
                                inputProps={{ maxLength: 120 }}
                            />
                        </div>
                    </div>

                    <div className="row my-2">
                        <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >मतदान केंद्राचे नाव :</label>
                        <div className='col-sm-5'>
                            <Autocomplete
                                id="combo-box-demo"
                                PaperComponent={({ children }) => (
                                    <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                )}
                                freeSolo
                                disableClearable
                                onKeyPress={(e) => (e.key == "Enter") ? votingKendra : ""}
                                options={(votingKendra === "") ? [] : schholData}
                                value={votingKendra}
                                onChange={(event, newValue) => {
                                    //setNarrationId(newValue.id);
                                    //console.log(newValue.narrationDetail);
                                    setVotingKendra((authUser.branchMedium == 1) ? newValue.schoolNameMr : newValue.schoolNameEn);
                                }}
                                inputValue={votingKendra}
                                onInputChange={(event, newInputValue) => {
                                    //setNarrationId(newInputValue.id);
                                    //console.log(newInputValue);
                                    setVotingKendra(newInputValue);
                                }}
                                style={{ fontSize: '14px' }}
                                getOptionLabel={option => {

                                    if (typeof option === 'string') {
                                        return option;
                                    }
                                    // Add "xxx" option created dynamically
                                    if (option.inputValue) {
                                        //console.log("option........"+JSON.stringify(option.inputValue))
                                        return (authUser.branchMedium == 1) ? option.id + " . " + option.schoolNameMr : option.id + " . " + option.schoolNameEn;
                                    }
                                    // Regular option
                                    return (authUser.branchMedium == 1) ? option.id + " . " + option.schoolNameMr : option.id + " . " + option.schoolNameEn;
                                }

                                }
                                size="small"
                                renderInput={params => (
                                    <TextField {...params}
                                        margin='dense' label="मतदान केंद्राचे नाव" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                )}
                            />
                        </div>
                    </div>

                    <div className="row my-2">
                        <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >मतदान केंद्राचा पत्ता :</label>
                        <div className='col-sm-5'>
                            <TextField
                                required
                                multiline
                                rows={2}
                                onKeyDown={(e) => (e.key === " " && kendraAddress.length === 0) ? e.preventDefault() : ""}
                                fullWidth
                                margin='dense'
                                value={kendraAddress}
                                onChange={e => setKendraAddress(e.target.value)}
                                onKeyPress={(e) => (e.key == "Enter") ? kendraAddress : ""}
                                autoComplete='off'
                                size='small'
                                id="outlined-basic"
                                label="मतदान केंद्राचा पत्ता"
                                variant="outlined"
                                InputLabelProps={{ style: { fontSize: 14 } }}
                                inputProps={{ maxLength: 120 }}
                            />
                        </div>
                    </div>

                    <div className="row mt-3">
                        <div className="col-sm-12 offset-sm-2">
                            <button type="submit" disabled={saveBtnDisabled} onClick={() => (state.button = 1)} className={saveBtn} >Save</button>
                            <button type="submit" disabled={updateBtnDisabled} onClick={() => (state.button = 2)} className={updateBtn} >Update</button>
                            <button type="button" onClick={ClearData} className="btn btn-danger btn-sm" style={{ margin: '0px 4px' }} >Cancel</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}


export default VotersKendraMaster
