import React, { useEffect, useState } from 'react'
import TitleLabel from "../../CommonComponent/TitleLabel";
import Autocomplete from "@mui/material/Autocomplete";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import TextField from '@mui/material/TextField';
import { useSelector } from "react-redux";
import axios from 'axios';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { ToastContainer, toast } from 'react-toastify';
import moment from 'moment';
import Loader from '../../CommonComponent/Loader';
import Paper from "@mui/material/Paper";
import { getExportedReportAPIURL } from '../../CommonServices/APIURL';
import makeStyles from "@mui/styles/makeStyles";
import { getFeeList } from '../../CommonServices/FeeMasterAPIURL';
import { printFeeCollectionSummaryReportAPIURL } from '../Services/FeeReportAPIURL';


const useStyles = makeStyles(theme => ({
    select: {
        maxHeight: 160,
        "& ul": {
            backgroundColor: "#F4ECF7",
            //maxHeight: 160,
        },
        "& li": {
            // backgroundColor: "#F4ECF7", fontWeight: "550", fontSize: "14px" 
            fontSize: "14px",
            fontWeight: "550"
        },
    },

}));

const feeData = [
    {
        id: 1,
        feeLabel: "One Time"
    },
    {
        id: 2,
        feeLabel: "Yearly"
    },
    {
        id: 3,
        feeLabel: "Donation"
    },
    {
        id: 4,
        feeLabel: "Sanstha Receipt"
    },
    {
        id: 5,
        feeLabel: "EBC Fee"
    }
]

const FeeCollectionSummary = () => {
    const classes = useStyles();
    const titleId = "Fee Collection Summary";
    const authUser = useSelector((state) => state.user.value);

    // loader on/off
    const [loaderOption, setLoaderOption] = useState(false);

    const defaultMinDate = new Date(`${authUser.sessionYear}/04/01`);
    const defaultMaxDate = new Date(`${authUser.sessionYear + 1}/05/31`);

    const [dateCheckFrom, setDateCheckFrom] = useState(null);
    const [dateCheckTo, setDateCheckTo] = useState(null);

    const [id, setId] = useState();
    const [dateFrom, setDateFrom] = useState(null);
    //console.log(moment(dateFrom).format("DD/MM/YYYY"))
    const [dateTo, setDateTo] = useState(null);
    const [reportOptionId, setReportOptionId] = useState(1);
    const [option, setOption] = useState(1);

    const [feeTypeData, setFeeTypeData] = useState(feeData);
    const [feeType, setFeeType] = useState(null);
    const [feeTypeId, setFeeTypeId] = useState('');

    const defaultFromDate = new Date(authUser.sessionYear, 3, 1);
    const defaultToDate = new Date(parseInt(authUser.sessionYear) + 1, 4, 31);

    useEffect(() => {
        getData();
    }, [authUser])


    const getData = async () => {
        // await axios(`${getFeeList}`)
        //     .then(res => {
        //         const feeDataForReceipt = (res.data).filter(e => e.feeType !== 5)
        //         //setFeeData(res.data);
        //         //setLoaderOption(false);
        //         console.log(JSON.stringify(res.data))                
        //         setFeeTypeData(feeDataForReceipt);

        //     })
    }

    useEffect(() => {
        if (authUser.sessionYear != '') {
            setDateFrom(moment(defaultFromDate).format("YYYY-MM-DD"))
            setDateTo(moment(defaultToDate).format("YYYY-MM-DD"))
        }
    }, [authUser])

    const printFeeCollectionSummaryReport = async (e) => {
        e.preventDefault();
        setLoaderOption(true);
        if (moment(dateFrom).format("DD/MM/YYYY") !== "Invalid date" && moment(dateTo).format("DD/MM/YYYY") !== "Invalid date" && dateCheckFrom != "Invalid date" && dateCheckTo != "Invalid date") {
            // console.log(`${printFeeCollectionSummaryReportAPIURL}?langId=${authUser.branchMedium}&option=${option}&dateFrom=${moment(dateFrom).format('YYYY-MM-DD')}&dateTo=${moment(dateTo).format('YYYY-MM-DD')}&year=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&reportOption=${reportOptionId}&feeType=${feeType.id}`)
            await axios.get(`${printFeeCollectionSummaryReportAPIURL}?langId=${authUser.branchMedium}&option=${option}&dateFrom=${moment(dateFrom).format('YYYY-MM-DD')}&dateTo=${moment(dateTo).format('YYYY-MM-DD')}&year=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&reportOption=${reportOptionId}&feeType=${feeType.id}`)
                .then(res => {
                    if (res.data == "NOTFOUND") {
                        setLoaderOption(false);
                        toast.warn("Data not found with this details.")
                    }
                    else {
                        window.open(`${getExportedReportAPIURL}${res.data}`);
                        setLoaderOption(false);
                    }
                }).catch(err => {
                    console.log("Fee Collection Summary err" + err);
                    setLoaderOption(false);
                    toast.error("Something went wrong, please check.")
                })
        }
        else {
            setLoaderOption(false);
            toast.error("Improper date, please check.")
        }
    }

    if (loaderOption == true) {
        return (
            <>
                <Loader />
            </>
        )
    }
    else {
        return (
            <>
                <TitleLabel titleId={titleId} />

                <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: "350px" }}>
                    <form onSubmit={printFeeCollectionSummaryReport}>
                        <div className="form-data">
                            <input type="hidden" value={id} onChange={(e) => setId(e.target.value)} name="" />

                            <div className="row my-2">
                                <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Report Option :</label>
                                <div className='col-sm-3'>
                                    <FormControl fullWidth margin='dense'>
                                        <InputLabel size="small" id='demo-simple-select-label' style={{ fontSize: '14px' }}>Select Report Option</InputLabel>
                                        <Select
                                            MenuProps={{ classes: { paper: classes.select } }}
                                            size='small'
                                            required
                                            fullWidth
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={reportOptionId}
                                            label="Select Report Option"
                                            onChange={(e) => {
                                                setReportOptionId(e.target.value);
                                                setFeeType(null);
                                                setOption(1);
                                                setDateFrom(moment(defaultFromDate).format("YYYY-MM-DD"))
                                                setDateTo(moment(defaultToDate).format("YYYY-MM-DD"))
                                            }}
                                        >
                                            <MenuItem value={1}>PDF</MenuItem>
                                            <MenuItem value={2}>Excel</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>


                            <div className="row my-2">
                                <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Fee Type :</label>
                                <div className='col-sm-3'>
                                    <Autocomplete
                                        id="combo-box-demo"
                                        PaperComponent={({ children }) => (
                                            <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                        )}
                                        options={feeTypeData}
                                        style={{ fontSize: '14px' }}
                                        getOptionLabel={option => option.feeLabel}
                                        size="small"
                                        value={feeType}
                                        onChange={(event, newvalue) => {
                                            setFeeType(newvalue);
                                            setOption(1);
                                            setDateFrom(moment(defaultFromDate).format("YYYY-MM-DD"))
                                            setDateTo(moment(defaultToDate).format("YYYY-MM-DD"))
                                        }}
                                        renderInput={params => (
                                            <TextField {...params} margin="dense" label="Select Fee Type" InputLabelProps={{ style: { fontSize: 14 } }} variant="outlined" required />
                                        )}
                                    />
                                </div>
                            </div>

                            <div className="row my-2">
                                <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Option :</label>
                                <div className='col-sm-3'>
                                    <FormControl fullWidth margin='dense'>
                                        <InputLabel size="small" id='demo-simple-select-label' style={{ fontSize: '14px' }}>Select Option</InputLabel>
                                        <Select
                                            MenuProps={{ classes: { paper: classes.select } }}
                                            size='small'
                                            required
                                            fullWidth
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={option}
                                            label="Select Option"
                                            onChange={(e) => {
                                                setOption(e.target.value);
                                                setDateFrom(moment(defaultFromDate).format("YYYY-MM-DD"))
                                                setDateTo(moment(defaultToDate).format("YYYY-MM-DD"))
                                            }}
                                        >
                                            <MenuItem value={1}>Detail View</MenuItem>
                                            <MenuItem value={2}>Short View</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>

                            <div className="row my-2">
                                <label className="col-sm-2" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>Date From :</label>
                                <div className='col-sm-3'>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DatePicker
                                            label="Date From"
                                            value={dateFrom}
                                            minDate={defaultMinDate}
                                            maxDate={defaultMaxDate}
                                            inputFormat="dd/MM/yyyy"
                                            onChange={(newValue) => {
                                                setDateFrom(newValue);
                                                //(newValue > defaultMaxDate || newValue < defaultMinDate) ? setDateCheckFrom("Invalid date") : setDateCheckFrom(null)
                                                const maxDate = new Date(defaultMaxDate);
                                                maxDate.setDate(maxDate.getDate() + 1); // Add one day to max date to include the full day
                                                if (newValue < defaultMinDate || newValue >= maxDate) {
                                                    setDateCheckFrom("Invalid date");
                                                } else {
                                                    setDateCheckFrom("");
                                                }
                                            }}
                                            openTo="day"
                                            // Show defaultMaxDate selected when the DatePicker is opened
                                            // If dateFrom is not set, defaultMaxDate is used
                                            onOpen={() => setDateFrom(dateFrom || defaultMaxDate)}
                                            renderInput={(params) =>
                                                <TextField
                                                    {...params}
                                                    variant="outlined"
                                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                                    required
                                                    fullWidth
                                                    margin="dense"
                                                    autoComplete='off'
                                                    size="small" />}
                                        />
                                    </LocalizationProvider>
                                    {/* <span><em style={{ fontSize: "12px", color: "red", fontWeight: "600" }}>{dateCheckFrom}</em></span> */}
                                </div>

                                <label className="col-sm-1" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>Date To :</label>
                                <div className='col-sm-3'>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DatePicker
                                            label="Date To"
                                            value={dateTo}
                                            minDate={defaultMinDate}
                                            maxDate={defaultMaxDate}
                                            inputFormat="dd/MM/yyyy"
                                            onChange={(newValue) => {
                                                setDateTo(newValue);
                                                //(newValue > defaultMaxDate || newValue < defaultMinDate) ? setDateCheckTo("Invalid date") : setDateCheckTo(null)
                                                const maxDate = new Date(defaultMaxDate);
                                                maxDate.setDate(maxDate.getDate() + 1); // Add one day to max date to include the full day
                                                if (newValue < defaultMinDate || newValue >= maxDate) {
                                                    setDateCheckTo("Invalid date");
                                                } else {
                                                    setDateCheckTo("");
                                                }
                                            }}
                                            openTo="day"
                                            // Show defaultMaxDate selected when the DatePicker is opened
                                            // If dateFrom is not set, defaultMaxDate is used
                                            onOpen={() => setDateTo(dateTo || defaultMaxDate)}
                                            renderInput={(params) =>
                                                <TextField
                                                    {...params}
                                                    variant="outlined"
                                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                                    required
                                                    fullWidth
                                                    margin="dense"
                                                    autoComplete='off'
                                                    size="small" />}
                                        />
                                    </LocalizationProvider>
                                    {/* <span><em style={{ fontSize: "12px", color: "red", fontWeight: "600" }}>{dateCheckTo}</em></span> */}
                                </div>
                            </div>

                            <div className="row mt-3">
                                <div className="col-sm-4 offset-sm-2">
                                    <button type="submit" className="btn btn-primary btn-sm" >Show</button>
                                </div>
                            </div>
                        </div>
                    </form>

                </div>
            </>
        )
    }
}

export default FeeCollectionSummary
