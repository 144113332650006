import { Autocomplete, Paper, TextField } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Loader from '../../CommonComponent/Loader';
import TitleLabel from '../../CommonComponent/TitleLabel';
import { useNavigate, useParams } from 'react-router-dom';
import { getItemCategoryMasterAPIURL } from '../Services/ItemCategoryMasterAPIURL';
import { getItemMasterAPIURL, saveItemMasterAPIURL, updateItemMasterAPIURL } from '../Services/ItemMasterAPIURL';

const ItemMaster = () => {
    const titleId = 'Item Master';
    const authUser = useSelector((state) => state.user.value);
    const [loaderOption, setLoaderOption] = useState(false);

    const navigate = useNavigate();
    let { itId, EditFlag } = useParams();

    //Note: This below states are declared for Save & Update(Disabled/unDisabled)
    const saveBtnStyle = "itemMasterSaveBtn btn btn-primary btn-sm";
    const updateBtnStyle = "itemMasterUpdateBtn btn btn-primary btn-sm";
    const displayNoneBtnStyle = "d-none";
    const [saveBtn, setSaveBtn] = useState(saveBtnStyle);
    const [updateBtn, setUpdateBtn] = useState(displayNoneBtnStyle);

    const [saveBtnDisabled, setSaveBtnDisabled] = useState(false);
    const [updateBtnDisabeld, setUpdateBtnDisabled] = useState(false);

    const [id, setId] = useState("");
    const [itemCategoryData, setItemCategoryData] = useState([]);
    const [itemCategoryObj, setItemCategoryObj] = useState(null);
    const [itemName, setItemName] = useState('');
    const [unit, setUnit] = useState('');
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);

    useEffect(() => {
        getData();
    }, [authUser])

    const getData = () => {
        if (EditFlag == 1) {
            getApiData();
            setSaveBtnDisabled(false);
            setUpdateBtnDisabled(true);
        }
        else if (EditFlag == 2) {
            getEditDetails();
            setSaveBtnDisabled(true);
            setUpdateBtnDisabled(false);
        }
    }

    const getEditDetails = async () => {
        setLoaderOption(true);
        if (authUser.deptId != null && authUser.branchId != null) {
            await axios.get(`${getItemMasterAPIURL}?sid=${authUser.deptId}&bid=${authUser.branchId}`)
                .then(res => {
                    setSaveBtn(displayNoneBtnStyle);
                    setUpdateBtn(updateBtnStyle);

                    const selectedItem = res.data.find(e => e.id == itId)

                    setId(selectedItem.id);
                    setItemName(selectedItem.itemName);
                    setUnit(selectedItem.unit);
                    const selectedItemCategory = selectedItem.categoryId;

                    axios.get(`${getItemCategoryMasterAPIURL}?sid=${authUser.deptId}&bid=${authUser.branchId}`)
                        .then(res => {
                            setItemCategoryData(res.data);
                            setItemCategoryObj(res.data.find(e => e.id == selectedItemCategory))
                        }).catch(err => {
                            console.log("Item category get err " + err);
                        })

                    setLoaderOption(false);

                }).catch(err => {
                    setLoaderOption(false);
                    console.log("Get item master err " + err);
                    toast.error("Something went wrong, please check.")
                })
        }
    }

    const getApiData = async () => {

        if (authUser.deptId != null && authUser.branchId != null) {
            await axios.get(`${getItemCategoryMasterAPIURL}?sid=${authUser.deptId}&bid=${authUser.branchId}`)
                .then(res => {
                    setItemCategoryData(res.data);
                }).catch(err => {
                    console.log("Item category get err " + err);
                })

            await axios.get(`${getItemMasterAPIURL}?sid=${authUser.deptId}&bid=${authUser.branchId}`)
                .then(res => {
                    setData(res.data);
                    setFilteredData(res.data);
                }).catch(err => {
                    console.log("Get item master err " + err);
                })
        }
    }

    const clearData = () => {
        setSaveBtn(saveBtnStyle);
        setUpdateBtn(displayNoneBtnStyle);
        setItemCategoryObj(null);
        setItemName('');
        setUnit('');
        setSaveBtnDisabled(false);
        setUpdateBtnDisabled(true);
        navigate('/Home/sm1302/');
    }

    const state = {
        button: 1
    }

    const saveBtnClick = async () => {
        setLoaderOption(true);
        const saveObj = {
            "categoryId": itemCategoryObj.id,
            "itemName": itemName,
            "unit": unit,
            "sectionId": authUser.deptId,
            "branchId": authUser.branchId
        }
        // console.log(JSON.stringify(saveObj))
        await axios.post(saveItemMasterAPIURL, saveObj)
            .then(res => {
                if (res.data == "SAVED") {
                    getData();
                    clearData();
                    setLoaderOption(false);
                    toast.success("Saved successfully.");
                }
                else if (res.data == "ALREADYEXISTS") {
                    setLoaderOption(false);
                    toast.warn("Item with this details is already exists.");
                }
                else {
                    setLoaderOption(false);
                    toast.error("Item master save failed.")
                }
            }).catch(err => {
                setLoaderOption(false);
                console.log("Item master save err " + err);
                toast.error("Something went wrong, please check.")
            })
    }

    const updateBtnClick = async () => {
        setLoaderOption(true);
        const updateObj = {
            "id": id,
            "categoryId": itemCategoryObj.id,
            "itemName": itemName,
            "unit": unit,
            "sectionId": authUser.deptId,
            "branchId": authUser.branchId
        }
        await axios.put(updateItemMasterAPIURL, updateObj)
            .then(res => {
                if (res.data == "UPDATED") {
                    getData();
                    clearData();
                    setLoaderOption(false);
                    toast.success("Updated successfully.");
                }
                else if (res.data == "ALREADYEXISTS") {
                    setLoaderOption(false);
                    toast.warn("Item with this details is already exists.");
                }
                else {
                    setLoaderOption(false);
                    toast.error("Item master update failed.");
                }
            }).catch(err => {
                setLoaderOption(false);
                console.log("Item master save err " + err);
                toast.error("Something went wrong, please check.")
            })
    }

    const onSubmit = e => {
        e.preventDefault();
        if (state.button == 1) {
            saveBtnClick();
        }
        if (state.button == 2) {
            updateBtnClick();
        }
    }

    useEffect(() => {
        function handlekeydownEvent(event) {
            if (event.key === "Enter") {
                // console.log("Enter is pressed!")
                event.preventDefault();

                let btn1 = document.querySelector('.itemMasterSaveBtn')
                if (btn1 !== null) {
                    btn1.click();
                }
                let btn2 = document.querySelector('.itemMasterUpdateBtn')
                if (btn2 !== null) {
                    btn2.click();
                }
            }
        }

        document.addEventListener('keypress', handlekeydownEvent)
        return () => {
            document.removeEventListener('keypress', handlekeydownEvent)
        }
    }, [])

    return (
        <>
            {
                (loaderOption == true)
                    ?
                    <Loader />
                    :
                    <>
                        <TitleLabel titleId={titleId} />
                        <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: "350px" }}>

                            <form onSubmit={onSubmit}>
                                <input type="hidden" value={id} onChange={(e) => setId(e.target.value)} name="" />

                                <div className="row">
                                    <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Item Category :</label>
                                    <div className='col-sm-4'>
                                        <Autocomplete
                                            PaperComponent={({ children }) => (
                                                <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                            )}
                                            id="combo-box-demo"
                                            onChange={(e, newValue) => {
                                                setItemCategoryObj(newValue);
                                            }}
                                            value={itemCategoryObj}
                                            options={itemCategoryData}
                                            onKeyPress={(e) => (e.key == "Enter") ? itemCategoryObj : ""}
                                            style={{ fontSize: '14px' }}
                                            getOptionLabel={option => option.categoryName}
                                            size="small"
                                            renderInput={params => (
                                                <TextField {...params} label="Select Item Category" margin='dense' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                            )}
                                        />
                                    </div>
                                </div>

                                <div className="row my-2">
                                    <label htmlFor="lastname3" className="col-sm-2" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>Item Name :</label>
                                    <div className='col-sm-4'>
                                        <TextField
                                            required
                                            fullWidth
                                            onKeyDown={(e) => (e.key === " " && itemName.length === 0) ? e.preventDefault() : ""}
                                            autoComplete='off'
                                            className=''
                                            size='small'
                                            id="outlined-basic"
                                            label="Item Name"
                                            variant="outlined"
                                            margin='dense'
                                            value={itemName}
                                            onChange={(e) => setItemName(e.target.value)}
                                            onKeyPress={(e) => (e.key == "Enter") ? itemName : ""}
                                            inputProps={{ maxLength: 120 }}
                                            InputLabelProps={{ style: { fontSize: 14 } }}
                                        />
                                    </div>
                                </div>

                                <div className="row my-2">
                                    <label htmlFor="lastname3" className="col-sm-2" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>Unit :</label>
                                    <div className='col-sm-3'>
                                        <TextField
                                            required
                                            fullWidth
                                            onKeyDown={(e) => (e.key === " " && unit.length === 0) ? e.preventDefault() : ""}
                                            autoComplete='off'
                                            className=''
                                            size='small'
                                            id="outlined-basic"
                                            label="Unit"
                                            variant="outlined"
                                            margin='dense'
                                            value={unit}
                                            onChange={(e) => setUnit(e.target.value)}
                                            onKeyPress={(e) => (e.key == "Enter") ? unit : ""}
                                            inputProps={{ maxLength: 120 }}
                                            InputLabelProps={{ style: { fontSize: 14 } }}
                                        />
                                    </div>
                                </div>

                                <div className="row mt-3">
                                    <div className="col-sm-12 offset-sm-2">
                                        <button type='submit' disabled={saveBtnDisabled} onClick={() => (state.button = 1)} className={saveBtn}>Save</button>
                                        <button type='submit' disabled={updateBtnDisabeld} onClick={() => (state.button = 2)} className={updateBtn}>Update</button>
                                        <button type='button' onClick={() => clearData()} className='btn btn-sm btn-danger ml-2'>Cancel</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </>
            }
        </>
    )
}

export default ItemMaster