import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom';
import TitleLabel from '../../CommonComponent/TitleLabel'
import { useSelector } from 'react-redux';
import TextField from '@mui/material/TextField';
import { useNavigate } from 'react-router-dom';
import Loader from '../../CommonComponent/Loader';
import { ToastContainer, toast } from 'react-toastify';
import { saveAuthorMasterAPIURL, getAuthorMasterData, getAuthorMasterById, updateAuthorMaster } from '../LibraryServices/AuthorMasterAPIURL';
import axios from 'axios';

const AuthorMaster = () => {
    const authUser = useSelector((state) => state.user.value);
    const navigate = useNavigate();
    let { aId, EditFlag } = useParams();
    const [titleId, setTitleId] = useState("");

    const [loaderOption, setLoaderOption] = useState(false);

    //Note: This below states are declared for Save & Update(Disabled/unDisabled)
    const saveBtnStyle = "authorLibrarySaveBtn btn btn-primary btn-sm";
    const updateBtnStyle = "authorLibraryUpdateBtn btn btn-primary btn-sm";
    const displayNoneBtnStyle = "d-none";
    const [saveBtn, setSaveBtn] = useState(saveBtnStyle);
    const [updateBtn, setUpdateBtn] = useState(displayNoneBtnStyle);

    const [id, setId] = useState('');
    const [authorName, setAuthorName] = useState("");
    const [shortName, setShortName] = useState("");
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [saveBtnDisabled, setSaveBtnDisabled] = useState(false);
    const [updateBtnDisabled, setUpdateBtnDisabled] = useState(false);


    useEffect(() => {
        if (EditFlag == 1) {
            setTitleId("Author Master");
        }
        else if (EditFlag == 2) {
            setTitleId("Update Author Master");
        }
        getData();
    }, [authUser])


    const getData = () => {
        if (EditFlag == 1) {
            if (authUser.branchId != null) {
                getApiData();
            }
            setSaveBtnDisabled(false);
            setUpdateBtnDisabled(true);
        }
        else if (EditFlag == 2) {
            getEditDetails();
            setSaveBtnDisabled(true);
            setUpdateBtnDisabled(false);
        }
    }

    const getEditDetails = async () => {
        setLoaderOption(true);
        console.log(`${getAuthorMasterById}?id=${aId}`);
        await axios.get(`${getAuthorMasterById}?id=${aId}`)
            .then((response) => {

                setSaveBtn(displayNoneBtnStyle);
                setUpdateBtn(updateBtnStyle);
                setAuthorName(response.data.authorName);
                setShortName(response.data.shortName);
                setId(response.data.id)
                setLoaderOption(false);

            }).catch(error => {
                setLoaderOption(false);
                console.log(error);
                toast.error("Something went wrong, please check.")
            })
    }

    const getApiData = async () => {
        //setLoaderOption(true);
        console.log(`${getAuthorMasterData}?branchId=${authUser.branchId}`)
        await axios.get(`${getAuthorMasterData}?branchId=${authUser.branchId}`)
            .then(res => {
                setData(res.data);
                setFilteredData(res.data);
                setLoaderOption(false);
            })
        // .catch(error => {
        //     setLoaderOption(false);
        //     console.log(error);
        //     toast.error("Something went wrong, please check.")
        // })
    }

    const clearData = () => {
        setSaveBtn(saveBtnStyle);
        setUpdateBtn(displayNoneBtnStyle);
        navigate('/Home/l804/')
    }


    const saveBtnClick = async () => {
        setLoaderOption(true);
        const saveObj = {
            "id": id,
            "branchId": authUser.branchId,
            "authorName": authorName,
            "shortName": shortName
        }
        // console.log(JSON.stringify(saveObj))
        await axios.post(saveAuthorMasterAPIURL, saveObj)
            .then((response) => {
                if(response.data == "EXISTS"){
                    setLoaderOption(false);
                    toast.warn(`Author with this name is already exists.`)
                }
                else if (response.data == "SAVED") {
                    clearData();
                    getData();
                    setLoaderOption(false);
                    toast.success("Author master saved successfully.")
                }

            }).catch(error => {
                setLoaderOption(false);
                console.log(error);
                toast.error("Something went wrong, please check.")
            })
    }

    const updateBtnClick = async () => {
        setLoaderOption(true);
        const updateObj = {
            "id": id,
            "branchId": authUser.branchId,
            "authorName": authorName,
            "shortName": shortName
        }
        // console.log(JSON.stringify(updateObj))
        await axios.put(updateAuthorMaster, updateObj)
            .then((response) => {
                if(response.data == "EXISTS"){
                    setLoaderOption(false);
                    toast.warn(`Author with this name is already exists.`)
                }
                else if (response.data == "SAVED") {
                    clearData();
                    getData();
                    setLoaderOption(false);
                    toast.success("Author master updated successfully.")
                }

            }).catch(error => {
                setLoaderOption(false);
                console.log(error);
                toast.error("Something went wrong, please check.")
            })
    }


    const state = {
        button: 1
    };

    const onSubmit = e => {
        e.preventDefault();
        if (state.button === 1) {
            saveBtnClick();
        }
        if (state.button === 2) {
            updateBtnClick();
        }
    };

    useEffect(() => {

        function handlekeydownEvent(event) {
            if (event.key === "Enter") {
                console.log("Enter is pressed!")
                event.preventDefault();
                if (EditFlag == 1) {
                    console.log('save Enter is pressed!');
                    let btn1 = document.querySelector('.authorLibrarySaveBtn')
                    if (btn1 !== null) {
                        btn1.click();
                    }
                    setSaveBtnDisabled(false);
                    setUpdateBtnDisabled(true);
                }
                if (EditFlag == 2) {
                    console.log('update Enter is pressed!');
                    let btn2 = document.querySelector('.authorLibraryUpdateBtn')
                    if (btn2 !== null) {
                        btn2.click();
                    }
                    setSaveBtnDisabled(true);
                    setUpdateBtnDisabled(false);
                }
            }
        }

        document.addEventListener('keypress', handlekeydownEvent)
        return () => {
            document.removeEventListener('keypress', handlekeydownEvent)
        }

    }, []);


    return (
        <>
            {
                (loaderOption == true)
                    ?
                    <Loader />
                    :
                    <div>
                        <TitleLabel titleId={titleId} />

                        <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: "300px" }}>
                            <form onSubmit={onSubmit}>
                                <input type="hidden" value={id} onChange={(e) => setId(e.target.value)} />

                                <div className="row my-2">
                                    <label className='col-sm-2' style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>Author Name :</label>
                                    <div className="col-sm-4">
                                        <TextField
                                            fullWidth
                                            required
                                            onKeyDown={(e) => (e.key === " " && authorName.length === 0) ? e.preventDefault() : ""}
                                            autoComplete='off'
                                            margin='dense'
                                            size='small'
                                            variant="outlined"
                                            InputLabelProps={{ style: { fontSize: 14 } }}
                                            onKeyPress={(e) => (e.key == "Enter") ? authorName : ""}
                                            label="Author Name"
                                            value={authorName}
                                            onChange={(e) => setAuthorName(e.target.value)}
                                        />
                                    </div>
                                </div>

                                <div className="row my-2">
                                    <label className='col-sm-2' style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>Short Name :</label>
                                    <div className="col-sm-4">
                                        <TextField
                                            fullWidth                                            
                                            onKeyDown={(e) => (e.key === " " && shortName.length === 0) ? e.preventDefault() : ""}
                                            autoComplete='off'
                                            margin='dense'
                                            size='small'
                                            variant="outlined"
                                            InputLabelProps={{ style: { fontSize: 14 } }}
                                            onKeyPress={(e) => (e.key == "Enter") ? shortName : ""}
                                            label="Short Name"
                                            value={shortName}
                                            onChange={(e) => setShortName(e.target.value)}
                                        />
                                    </div>
                                </div>

                                <div className="row mt-3">
                                    <div className="col-sm-4 offset-sm-2">
                                        <button type='submit' disabled={saveBtnDisabled} className={saveBtn} onClick={() => (state.button = 1)}>Save</button>
                                        <button type='submit' disabled={updateBtnDisabled} className={updateBtn} onClick={() => (state.button = 2)} style={{ margin: "0px 4px" }}>Update</button>
                                        <button type='button' onClick={() => clearData()} className="btn btn-sm btn-danger" style={{ margin: "0px 4px" }}>Cancel</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
            }
            <ToastContainer position="top-right" theme="colored" />
        </>
    )
}

export default AuthorMaster