import { FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import TitleLabel from '../../CommonComponent/TitleLabel'
import LeaveMasterTable from './LeaveMasterTable';
import { saveLeaveMasterAPIURL, updateLeaveMasterAPIURL, getLeaveMasterAPIURL } from '../Services/LeaveMasterAPIURL';
import axios from 'axios';
import { toast } from 'react-toastify';
import Loader from '../../CommonComponent/Loader';
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles(theme => ({
    menuPaper: {
        maxHeight: 200,
        "& ul": {
            backgroundColor: "#F4ECF7",
            //maxHeight: 160,
        },
        "& li": {
            // backgroundColor: "#F4ECF7", fontWeight: "550", fontSize: "14px" 
            fontSize: "14px",
            fontWeight: "550"
        },
    }
}));

const LeaveMaster = () => {
    const authUser = useSelector((state) => state.user.value);
    const titleId = "Leave Master";
    const classes = useStyles();

    const TableHeading = [
        { label: 'ID', key: 'id' },
        { label: `Leave Name`, key: 'leaveName' },
        { label: `Leave Type`, key: 'leaveType' },
        { label: `short Name`, key: 'shortName' },
        { label: 'Edit', value: 'edit', isAction: true },
        { label: 'Delete', value: 'delete', isAction: true }];

    //Note: This below states are declared for Save & Update(Disabled/unDisabled)
    const saveBtnStyle = "leaveMasterSaveBtn btn btn-primary btn-sm";
    const updateBtnStyle = "leaveMasterUpdateBtn btn btn-primary btn-sm";
    const displayNoneBtnStyle = "d-none";
    const [saveBtn, setSaveBtn] = useState(saveBtnStyle);
    const [updateBtn, setUpdateBtn] = useState(displayNoneBtnStyle);
    const [loaderOption, setLoaderOption] = useState(false);

    const [saveBtnDisabled, setSaveBtnDisabled] = useState(false);
    const [updateBtnDisabled, setUpdateBtnDisabled] = useState(false);

    const [id, setId] = useState('');
    const [leaveName, setLeaveName] = useState('');
    const [leaveType, setLeaveType] = useState('');
    const [shortName, setShortName] = useState('');
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);

    useEffect(() => {
        getData();
    }, [])


    const getData = async () => {
        await axios.get(getLeaveMasterAPIURL)
            .then(res => {
                setData(res.data);
                setFilteredData(res.data);
            }).catch(err => {
                console.log("Leave Master get " + err)
            })
    }

    const clearData = () => {
        setSaveBtn(saveBtnStyle);
        setUpdateBtn(displayNoneBtnStyle);
        setId('');
        setLeaveName('');
        setLeaveType('');
        setShortName('');
    }

    const saveBtnClick = async () => {
        setLoaderOption(true);
        const saveObj = {
            "leaveName": leaveName,
            "leaveType": leaveType,
            "shortName": shortName
        }
        // console.log(JSON.stringify(saveObj))
        await axios.post(saveLeaveMasterAPIURL, saveObj)
            .then(res => {
                if (res.data == "SAVED") {
                    setLoaderOption(false);
                    getData();
                    clearData();
                    toast.success("Saved successfully.")
                }
                else if (res.data == "ALREADYEXISTS") {
                    setLoaderOption(false);
                    toast.warn("This leave name is already exists.")
                }
                else {
                    setLoaderOption(false);
                    toast.error('Leave master save failed')
                }
            }).catch(err => {
                setLoaderOption(false);
                toast.error("Something went wrong, please check.");
                console.log("Leave master save" + err);
            })
    }

    const updateBtnClick = async () => {
        setLoaderOption(true);
        const updateObj = {
            "id": id,
            "leaveName": leaveName,
            "leaveType": leaveType,
            "shortName": shortName
        }
        // console.log(JSON.stringify(updateObj))
        await axios.put(updateLeaveMasterAPIURL, updateObj)
            .then(res => {
                if (res.data == "UPDATED") {
                    setLoaderOption(false);
                    getData();
                    clearData();
                    toast.success("Updated successfully.")
                }
                else if (res.data == "ALREADYEXISTS") {
                    setLoaderOption(false);
                    toast.warn("This leave name is already exists.")
                }
                else {
                    setLoaderOption(false);
                    toast.error('Leave master update failed')
                }
            }).catch(err => {
                setLoaderOption(false);
                toast.error("Something went wrong, please check.");
                console.log("Leave master update" + err);
            })
    }

    const state = {
        button: 1
    }

    const onSubmit = e => {
        e.preventDefault();
        if (state.button == 1) {
            saveBtnClick();
        }
        else if (state.button == 2) {
            updateBtnClick();
        }
    }

    useEffect(() => {

        function handlekeydownEvent(event) {
            if (event.key === "Enter") {
                // console.log("Enter is pressed!")
                event.preventDefault();

                let btn1 = document.querySelector('.leaveMasterSaveBtn')
                if (btn1 !== null) {
                    btn1.click();
                }
                let btn2 = document.querySelector('.leaveMasterUpdateBtn')
                if (btn2 !== null) {
                    btn2.click();
                }

            }
        }

        document.addEventListener('keypress', handlekeydownEvent)
        return () => {
            document.removeEventListener('keypress', handlekeydownEvent)
        }

    }, []);

    return (
        <>
            {
                (loaderOption == true)
                    ?
                    <Loader />
                    :
                    <>
                        <TitleLabel titleId={titleId} />
                        <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: '350px' }}>
                            <form onSubmit={onSubmit}>
                                <div className="form-data mt-1">
                                    <input type="hidden" value={id} onChange={(e) => setId(e.target.value)} name="" />

                                    <div className="row">
                                        <label htmlFor="lastname3" className="col-sm-2" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>Leave Name :</label>
                                        <div className='col-sm-4'>
                                            <TextField
                                                required
                                                fullWidth
                                                onKeyDown={(e) => (e.key === " " && leaveName.length === 0) ? e.preventDefault() : ''}
                                                autoComplete='off'
                                                className=''
                                                size='small'
                                                id="outlined-basic"
                                                label="Leave Name"
                                                variant="outlined"
                                                margin='dense'
                                                value={leaveName}
                                                onChange={(e) => setLeaveName(e.target.value)}
                                                onKeyPress={(e) => (e.key == "Enter") ? leaveName : ""}
                                                inputProps={{ maxLength: 120 }}
                                                InputLabelProps={{ style: { fontSize: 14 } }}
                                            />
                                        </div>
                                    </div>

                                    <div className="row my-2">
                                        <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Leave Type :</label>
                                        <div className='col-sm-3'>
                                            <FormControl fullWidth margin='dense'>
                                                <InputLabel id='demo-simple-select-label' size="small" style={{ fontSize: '14px' }}>Select Leave Type</InputLabel>
                                                <Select
                                                    size='small'
                                                    required
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={leaveType}
                                                    label="Select Leave Type"
                                                    MenuProps={{ classes: { paper: classes.menuPaper } }}
                                                    onChange={(e) => {
                                                        setLeaveType(e.target.value);
                                                    }}
                                                    onKeyPress={(e) => (e.key == "Enter") ? leaveType : ""}
                                                >
                                                    <MenuItem value={1}>Pay</MenuItem>
                                                    <MenuItem value={2}>Without Pay</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <label htmlFor="lastname3" className="col-sm-2" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>Short Name :</label>
                                        <div className='col-sm-3'>
                                            <TextField
                                                required
                                                fullWidth
                                                onKeyDown={(e) => (e.key === " " && shortName.length === 0) ? e.preventDefault() : ''}
                                                autoComplete='off'
                                                className=''
                                                size='small'
                                                id="outlined-basic"
                                                label="Short Name"
                                                variant="outlined"
                                                margin='dense'
                                                value={shortName}
                                                onChange={(e) => setShortName(e.target.value)}
                                                onKeyPress={(e) => (e.key == "Enter") ? shortName : ""}
                                                inputProps={{ maxLength: 120 }}
                                                InputLabelProps={{ style: { fontSize: 14 } }}
                                            />
                                        </div>
                                    </div>

                                    <div className="row mt-3">
                                        <div className="col-sm-4 offset-sm-2">
                                            <button type='submit' disabled={saveBtnDisabled} className={saveBtn} onClick={() => (state.button = 1)}>Save</button>
                                            <button type='submit' disabled={updateBtnDisabled} className={updateBtn} onClick={() => (state.button = 2)}>Update</button>
                                            <button type='button' className="btn btn-sm btn-danger ml-2" onClick={() => clearData()}>Cancel</button>
                                        </div>
                                    </div>
                                </div>
                            </form>

                            <div className='mt-2'>
                                <LeaveMasterTable
                                    TableHeading={TableHeading}
                                    data={data}
                                    filteredData={filteredData}
                                    setFilteredData={setFilteredData}
                                    setSaveBtn={setSaveBtn}
                                    setUpdateBtn={setUpdateBtn}
                                    updateBtnStyle={updateBtnStyle}
                                    displayNoneBtnStyle={displayNoneBtnStyle}
                                    setId={setId}
                                    setLeaveName={setLeaveName}
                                    setLeaveType={setLeaveType}
                                    setShortName={setShortName}
                                    getData={getData}
                                    setLoaderOption={setLoaderOption}
                                />
                            </div>
                        </div>
                    </>
            }
        </>
    )
}

export default LeaveMaster