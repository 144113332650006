const admitedTypeData = [
    {
        id: 1,
        name: "Regular"
    },
    {
        id: 2,
        name: "Age Appropriate"
    },
    {
        id: 3,
        name: "RTE 25% Quota"
    },
    {
        id: 4,
        name: "Day Boarding"
    }
];

export default admitedTypeData;