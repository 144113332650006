import moment from 'moment';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf, faFileExcel } from '@fortawesome/free-solid-svg-icons'; // Import FontAwesome icons
import { getExportedReportAPIURL } from '../../CommonServices/APIURL';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import axios from 'axios';
import Loader from '../../CommonComponent/Loader';
import { PrintOutwardEntryExcelReportAPIURL, PrintOutwardEntryPdfReportAPIURL } from '../Services/OutwardEntryAPIURL';

const OutwardReportTableView = ({ data, dateFrom, dateTo, dateCheckFrom, dateCheckTo, setLoaderOption, loaderOption }) => {
    const authUser = useSelector((state) => state.user.value);

    const printOutwardPdfReport = async () => {

        setLoaderOption(true);
        if (moment(dateFrom).format("DD/MM/YYYY") != 'Invalid date' && moment(dateTo).format("DD/MM/YYYY") != 'Invalid date' && dateCheckFrom != 'Invalid date' && dateCheckTo != 'Invalid date') {

            // console.log(`${PrintOutwardEntryPdfReportAPIURL}?langId=${authUser.branchMedium}&year=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&fromDate=${moment(dateFrom).format("YYYY-MM-DD")}&toDate=${moment(dateTo).format("YYYY-MM-DD")}`)
            await axios.get(`${PrintOutwardEntryPdfReportAPIURL}?langId=${authUser.branchMedium}&year=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&fromDate=${moment(dateFrom).format("YYYY-MM-DD")}&toDate=${moment(dateTo).format("YYYY-MM-DD")}`)
                .then(res => {
                    if (res.data == "NOTFOUND") {
                        setLoaderOption(false);
                        toast.warn("Data not found.")
                    }
                    else {
                        window.open(`${getExportedReportAPIURL}${res.data}`);
                        setLoaderOption(false);
                    }
                }).catch(err => {
                    setLoaderOption(false);
                    console.log("Balance sheet schedule report err " + err);
                    toast.error("Something went wrong, please chcek.")
                })

        }
        else {
            setLoaderOption(false);
            toast.error("Improper date, please check.")
        }
    }

    const printOutawrdExcelReport = async () => {

        setLoaderOption(true);
        if (moment(dateFrom).format("DD/MM/YYYY") != 'Invalid date' && moment(dateTo).format("DD/MM/YYYY") != 'Invalid date' && dateCheckFrom != 'Invalid date' && dateCheckTo != 'Invalid date') {

            // console.log(`${PrintOutwardEntryExcelReportAPIURL}?langId=${authUser.branchMedium}&year=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&fromDate=${moment(dateFrom).format("YYYY-MM-DD")}&toDate=${moment(dateTo).format("YYYY-MM-DD")}`)
            await axios.get(`${PrintOutwardEntryExcelReportAPIURL}?langId=${authUser.branchMedium}&year=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&fromDate=${moment(dateFrom).format("YYYY-MM-DD")}&toDate=${moment(dateTo).format("YYYY-MM-DD")}`)
                .then(res => {
                    if (res.data == "NOTFOUND") {
                        setLoaderOption(false);
                        toast.warn("Data not found.")
                    }
                    else {
                        window.open(`${getExportedReportAPIURL}${res.data}`);
                        setLoaderOption(false);
                    }
                }).catch(err => {
                    setLoaderOption(false);
                    console.log("Balance sheet schedule report err " + err);
                    toast.error("Something went wrong, please chcek.")
                })

        }
        else {
            setLoaderOption(false);
            toast.error("Improper date, please check.")
        }
    }

    if (loaderOption == true) {
        return (
            <>
                <Loader />
            </>
        )
    }
    else {
        return (
            <>
                <div className='table-responsive'>
                    <div style={{ display: "flex", justifyContent: "flex-end", marginBottom: "10px" }}>
                        <FontAwesomeIcon
                            icon={faFilePdf}
                            style={{ fontSize: "28px", color: "red", cursor: "pointer", marginRight: "15px" }}
                            onClick={() => printOutwardPdfReport()}
                        />
                        <FontAwesomeIcon
                            icon={faFileExcel}
                            style={{ fontSize: "28px", color: "green", cursor: "pointer" }}
                            onClick={() => printOutawrdExcelReport()}
                        />
                    </div>
                    <table className="table table-bordered">
                        <thead className="table-Default">
                            <tr>
                                <th rowSpan={2} style={{ fontSize: "14px", fontWeight: "600", verticalAlign: "middle", lineHeight: '1.5' }}>Sr.No.</th>
                                <th rowSpan={2} style={{ fontSize: "14px", fontWeight: "600", textAlign: "center", verticalAlign: "middle", lineHeight: '1.5' }}>Date of Entry</th>
                                <th rowSpan={2} style={{ fontSize: "14px", fontWeight: "600", textAlign: "center", verticalAlign: "middle", lineHeight: '1.5' }}>Duplicate No</th>
                                <th rowSpan={2} style={{ fontSize: "14px", fontWeight: "600", textAlign: "center", verticalAlign: "middle", lineHeight: '1.5' }}>To Whom Sent</th>
                                <th rowSpan={2} style={{ fontSize: "14px", fontWeight: "600", textAlign: "center", verticalAlign: "middle", lineHeight: '1.5' }}>Place</th>
                                <th rowSpan={2} style={{ fontSize: "14px", fontWeight: "600", textAlign: "center", verticalAlign: "middle", lineHeight: '1.5' }}>Reference</th>
                                <th rowSpan={2} style={{ fontSize: "14px", fontWeight: "600", textAlign: "center", verticalAlign: "middle", lineHeight: '1.5' }}>Content Of Outward Letter</th>

                                {/* Merged Header for "Details Of Reply" */}
                                <th colSpan={3} style={{ fontSize: "14px", fontWeight: "600", textAlign: "center" }}>Postage</th>

                                <th rowSpan={2} style={{ fontSize: "14px", fontWeight: "600", textAlign: "center", verticalAlign: "middle", lineHeight: '1.5' }}>Remark</th>
                            </tr>

                            {/* Sub-columns under merged headers */}
                            <tr>
                                <th style={{ fontSize: "14px", fontWeight: "600", textAlign: "center", lineHeight: '1.5' }}>Received Rs.</th>
                                <th style={{ fontSize: "14px", fontWeight: "600", textAlign: "center", lineHeight: '1.5' }}>Dispatched Rs.</th>
                                <th style={{ fontSize: "14px", fontWeight: "600", textAlign: "center", lineHeight: '1.5' }}>Balance</th>
                            </tr>
                        </thead>
                        <tbody style={{ borderTop: "2px solid #464de4", borderBottom: "2px solid #464de4" }}>
                            {
                                data.map((item, index) => {
                                    return (
                                        <>
                                            <tr key={index}>
                                                <td style={{ fontSize: "14px", fontWeight: "500", lineHeight: "1.4" }}>{index + 1}</td>
                                                <td style={{ fontSize: "14px", fontWeight: "500", lineHeight: '1.5' }}>{moment(item.entryDate).format("DD/MM/YYYY")}</td>
                                                <td style={{ fontSize: "14px", fontWeight: "500", lineHeight: '1.5' }}>{item.duplicateNo}</td>
                                                <td style={{ fontSize: "14px", fontWeight: "500", lineHeight: '1.5' }}>{item.toWhomSent}</td>
                                                <td style={{ fontSize: "14px", fontWeight: "500", lineHeight: '1.5' }}>{item.address}</td>
                                                <td style={{ fontSize: "14px", fontWeight: "500", lineHeight: '1.5' }}>{item.reference}</td>
                                                <td style={{ fontSize: "14px", fontWeight: "500", lineHeight: '1.5' }}>{item.contentOfOutward}</td>
                                                <td style={{ fontSize: "14px", fontWeight: "500", lineHeight: '1.5' }}>{parseFloat(item.receivedAmount).toFixed('2')}</td>
                                                <td style={{ fontSize: "14px", fontWeight: "500", lineHeight: '1.5' }}>{parseFloat(item.dispatchedAmount).toFixed('2')}</td>
                                                <td style={{ fontSize: "14px", fontWeight: "500", lineHeight: '1.5' }}>{parseFloat(item.balanceAmount).toFixed('2')}</td>
                                                <td style={{ fontSize: "14px", fontWeight: "500", lineHeight: '1.5' }}>{item.remark}</td>
                                            </tr>
                                        </>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </>
        );
    }
};

export default OutwardReportTableView
