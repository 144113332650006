import React, { useState, useEffect } from 'react';
import TitleLabel from "../../CommonComponent/TitleLabel";
import axios from 'axios';
import Paper from "@mui/material/Paper";
import TextField from '@mui/material/TextField';
import Autocomplete from "@mui/material/Autocomplete";
import makeStyles from "@mui/styles/makeStyles";
import TablePagination from '@mui/material/TablePagination';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector } from "react-redux";
import { useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { getClassWithDiv, } from '../../Academic/Services/StudentRollNoAPIURL';
import { getStudentListAccountNo, saveStudentBankData } from '../Services/StudentBankAPIURL';
import ReadOnlyRow from './StudentBankDetailsTableRows/ReadOnlyRow';
import EditableRow from './StudentBankDetailsTableRows/EditableRow';
import Loader from '../../CommonComponent/Loader';

const useStyles = makeStyles({
    caption: {
        padding: 0,
        fontWeight: 600,
        fontSize: "12px"
    },
    toolbar: {
        fontSize: "12px",
        fontWeight: 600
    }
});

const StudentBankDetails = () => {

    const authUser = useSelector((state) => state.user.value);
    const titleId = "Student Bank Details";

    const [loaderOption, setLoaderOption] = useState(false);
    const classes = useStyles();

    const TableHeading = [
        { label: 'Reg No', key: 'regno' },
        { label: `Student Name`, key: 'sname' },
        { label: 'Roll No', key: 'rollno' },
        { label: `Account Name`, key: 'accountName' },
        { label: `Bank Ac No`, key: 'bankAcNo' },
        { label: `Bank Name`, key: 'bankName' },
        { label: `Branch Name`, key: 'branchName' },
        { label: `Bank IFSC Code`, key: 'ifsc' },
        { label: `Edit`, key: 'edit', isAction: true }
    ];

    const [classDivObj, setClassDivObj] = useState(null);
    const [divisionData, setDivisionData] = useState([]);

    const [editBankId, setEditBankId] = useState(null);

    const [editFormData, setEditFormData] = useState({
        id: "",
        regCode: "",
        studentNameMr: "",
        studentNameEn: "",
        rollNo: "",
        accountName: "",
        bankAccountNo: "",
        bankName: "",
        branchName: "",
        bankIFSCCode: "",
        sectionId: "",
        branchId: "",
        classId: "",
        divisionId: ""
    });

    //console.log("division data: " + JSON.stringify(editFormData));
    const [isAscending, setAscending] = useState(false);
    const [sortedBy, setSortedBy] = useState(null);
    const [data, setData] = useState([]);
    const [tempData, setTempData] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");


    useEffect(() => {
        getData();
    }, [authUser]);

    const getData = async () => {
        if (authUser.deptId != null || authUser.branchId != null) {
            await axios(`${getClassWithDiv}?sid=${authUser.deptId}&bid=${authUser.branchId}`)
                .then(res => {
                    setDivisionData(res.data);
                })
        }
    }


    const [filteredData, setFilteredData] = useState(null);
    const onSort = (key, isAction) => {
        if (!isAction) {
            setSortedBy(key)
            if (isAscending) setFilteredData([...data.sort((a, b) => a[key] < b[key] ? 1 : -1)])
            else setFilteredData([...data.sort((a, b) => a[key] > b[key] ? 1 : -1)])
            setAscending(!isAscending)
        }
    }

    useEffect(() => {
        setFilteredData(data.filter((item) =>
            item.studentNameMr.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.studentNameEn.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.bankName.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.rollNo.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.accountName.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.branchName.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.bankIFSCCode.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.bankAccountNo.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.regCode.toString().toLowerCase().includes(searchTerm.toLowerCase())))
    }, [searchTerm])


    const getStudentBankData = async (divId) => {
        setLoaderOption(true);
        setData([]);
        setFilteredData([]);
        setTempData([]);
        setEditBankId(null);
        setEditFormData({
            id: "",
            regCode: "",
            studentNameMr: "",
            studentNameEn: "",
            rollNo: "",
            accountName: "",
            bankAccountNo: "",
            bankName: "",
            branchName: "",
            bankIFSCCode: "",
            sectionId: "",
            branchId: "",
            classId: "",
            divisionId: ""
        })
        let link = getStudentListAccountNo + "?acyr=" + authUser.sessionYear + "&sid=" + authUser.deptId + "&bid=" + authUser.branchId + "&cdid=" + divId;
        // console.log(link);
        await axios(`${getStudentListAccountNo}?acyr=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&cdid=${divId}`)
            .then(res => {
                setData(res.data);
                setFilteredData(res.data);
                setTempData(res.data);
                setLoaderOption(false);
            }
            )
    }



    const handleEditClick = (event, bank) => {
        event.preventDefault();
        setEditBankId(bank.regCode);
        const formValues = {
            id: bank.id,
            regCode: bank.regCode,
            studentNameMr: bank.studentNameMr,
            studentNameEn: bank.studentNameEn,
            rollNo: bank.rollNo,
            accountName: bank.accountName,
            bankAccountNo: bank.bankAccountNo,
            bankName: bank.bankName,
            branchName: bank.branchName,
            bankIFSCCode: bank.bankIFSCCode,
            sectionId: bank.sectionId,
            branchId: bank.branchId,
            classId: bank.classId,
            divisionId: bank.divisionId
        };

        setEditFormData(formValues);
    };

    const handleCancelClick = () => {
        //console.log("handleCancelClick");
        setEditBankId(null);
    };

    const handleEditFormChange = (event) => {
        event.preventDefault();

        const fieldName = event.target.getAttribute("name");
        const fieldValue = event.target.value;
        //console.log(fieldValue)
        const newFormData = { ...editFormData };
        newFormData[fieldName] = fieldValue;
        //console.log(JSON.stringify(newFormData))
        setEditFormData(newFormData);
    };

    const handleEditFormSubmit = (event) => {
        setLoaderOption(true);
        event.preventDefault();

        const editedBank = {
            id: editFormData.id,
            regCode: editFormData.regCode,
            studentNameMr: editFormData.studentNameMr,
            studentNameEn: editFormData.studentNameEn,
            rollNo: editFormData.rollNo,
            accountName: editFormData.accountName,
            bankAccountNo: editFormData.bankAccountNo,
            bankName: editFormData.bankName,
            branchName: editFormData.branchName,
            bankIFSCCode: editFormData.bankIFSCCode,
            sectionId: editFormData.sectionId,
            branchId: editFormData.branchId,
            classId: editFormData.classId,
            divisionId: editFormData.divisionId
        };

        // if (editFormData.rollNo == 0) {
        //     setEditBankId(null);
        //     setSearchTerm("");            
        //     setLoaderOption(false);
        //     toast.warn("Roll no is not assigned to this student.")
        // }
        // else {
        let aaa = saveStudentBankData + "," + JSON.stringify(editedBank);
        //console.log(JSON.stringify(editedBank));
        axios.post(`${saveStudentBankData}`, editedBank)
            .then((response) => {
                if (response.data == "SAVED") {
                    const newBanks = [...filteredData];
                    const index = filteredData.findIndex((bank) => bank.regCode === editBankId);
                    newBanks[index] = editedBank;
                    setFilteredData(newBanks);
                    setData(newBanks);
                    //setTempData(newBanks);
                    setSearchTerm("");
                    setEditBankId(null);
                    setLoaderOption(false);
                    toast.success("Bank details updation successfully done.");
                }

            })
            .catch(error => {
                console.log("Bank Update Fire Query Error: " + error)
                setLoaderOption(false);
            })
        //}
    };

    //console.log(JSON.stringify(tempData))

    useEffect(() => {

        let updatedData = tempData;

        tempData.map((e, index) => {
            if (e.regCode == editFormData.regCode) {
                updatedData[index] = {
                    id: editFormData.id,
                    regCode: editFormData.regCode,
                    studentNameMr: editFormData.studentNameMr,
                    studentNameEn: editFormData.studentNameEn,
                    rollNo: editFormData.rollNo,
                    accountName: editFormData.accountName,
                    bankAccountNo: editFormData.bankAccountNo,
                    bankName: editFormData.bankName,
                    branchName: editFormData.branchName,
                    bankIFSCCode: editFormData.bankIFSCCode,
                    sectionId: editFormData.sectionId,
                    branchId: editFormData.branchId,
                    classId: editFormData.classId,
                    divisionId: editFormData.divisionId
                };
            }
        })

        setData(updatedData);


        if (searchTerm == "") {
            setFilteredData(updatedData);

        }



    }, [searchTerm, editFormData])


    if (loaderOption == true) {
        return <Loader />
    }
    else {
        return (
            <>
                <TitleLabel titleId={titleId} />
                <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: '350px' }}>
                    <div className='row'>
                        <div className='col-sm-4'>
                            <Autocomplete
                                id="combo-box-demo"
                                options={divisionData}
                                style={{ fontSize: '14px' }}
                                PaperComponent={({ children }) => (
                                    <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                )}
                                onChange={(event, newValue) => {
                                    setClassDivObj(newValue)
                                    getStudentBankData(newValue.divisionId);
                                    setAscending(false);
                                    setSortedBy(null);
                                }}
                                value={classDivObj}
                                getOptionLabel={option => (authUser.branchMedium == 1) ? option.classNameMr + " " + option.divisionNameMr : option.classNameEn + " " + option.divisionNameEn}
                                size="small"
                                renderInput={params => (
                                    <TextField {...params} label="Select Class-Division" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                )}
                            />
                        </div>
                    </div>

                    {
                        (data == null || data == "") ?
                            <h4 className='mt-5'>No Records, Select Class/Division</h4>
                            :

                            <div>
                                <div className='row col-sm-4 my-1' style={{ float: 'right' }}>
                                    <div>
                                        <input
                                            className='form-control form-control-sm'
                                            type="text"
                                            style={{ width: "250px", display: "block", float: "right", marginBottom: "6px", border: "1px solid #C2C1C1" }}
                                            placeholder="Search Here"
                                            onChange={(event) => { setSearchTerm(event.target.value) }}
                                            value={searchTerm}
                                        />
                                    </div>
                                </div>

                                <div className='table-responsive' style={{ maxHeight: "520px" }}>
                                    <form onSubmit={handleEditFormSubmit}>
                                        <table className="table table-bordered ">
                                            <thead className="table-Default" style={{ position: "sticky", overflow: "hidden", top: "0", backgroundColor: "#EAECEE", borderBottom: "2px solid #464de4" }}>
                                                <tr style={{ borderBottom: "2px solid #464de4", position: "sticky", overflow: "hidden" }}>
                                                    {TableHeading.map((item, index) => {
                                                        return (
                                                            <th style={{ fontSize: "14px", fontWeight: "600" }} onClick={() => onSort(item.key, item.isAction)} key={index} scope="col">{`${item.label} ${sortedBy === item.key ? isAscending ? '↑' : '↓' : ''}`}</th>
                                                        )
                                                    })}
                                                </tr>
                                            </thead>
                                            <tbody style={{ borderTop: "2px solid #464de4", borderBottom: "2px solid #464de4" }}>
                                                {filteredData
                                                    .map((bank, index) => {
                                                        return (
                                                            <>
                                                                {
                                                                    editBankId === bank.regCode ? (
                                                                        <EditableRow
                                                                            editFormData={editFormData}
                                                                            handleCancelClick={handleCancelClick}
                                                                            handleEditFormChange={handleEditFormChange}
                                                                        />
                                                                    ) : (
                                                                        <ReadOnlyRow
                                                                            bank={bank}
                                                                            handleEditClick={handleEditClick}
                                                                        />
                                                                    )
                                                                }
                                                            </>
                                                        )
                                                    })}
                                            </tbody>
                                            <tfoot style={{ padding: "0px" }}>
                                                {/* <TablePagination
                                    style={{ padding: "0px" }}
                                    rowsPerPageOptions={[10, 25, 50, 100]}
                                    count={data.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onRowsPerPageChange={onRowsPerPageChange}
                                    onPageChange={onPageChange}
                                    classes={{ toolbar: classes.toolbar, caption: classes.caption }}
                                    backIconButtonProps={{ "aria-label": "Previous Page" }}
                                    nextIconButtonProps={{ "aria-label": "Next Page" }} /> */}
                                            </tfoot>
                                        </table>
                                    </form>
                                </div>
                            </div>

                    }
                </div>
                <ToastContainer position="top-right" theme="colored" />
            </>
        )
    }

}

export default StudentBankDetails