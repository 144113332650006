import React, { useEffect, useState } from 'react'
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import TextField from '@mui/material/TextField';
import Autocomplete from "@mui/material/Autocomplete";
import Paper from "@mui/material/Paper";
import TitleLabel from '../../CommonComponent/TitleLabel';
import { useSelector } from "react-redux";
import { useDispatch } from 'react-redux';

import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import MenuIcon from '@mui/icons-material/Menu';
import WidgetsIcon from '@mui/icons-material/Widgets';
import LockIcon from '@mui/icons-material/Lock';

//table
import TablePagination from '@mui/material/TablePagination'
import makeStyles from "@mui/styles/makeStyles";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash, faEye } from '@fortawesome/free-solid-svg-icons'
import DeleteModalBox from '../../CommonComponent/DeleteModalBox';
import Tooltip from '@mui/material/Tooltip';

//
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
//

//API Call
import { getAllModuleDataAPIURL } from '../Service/ModuleMasterAPIURL';
import { getAllFormDataAPIURL } from '../Service/FormMasterAPIURL';
import {
    saveServiceFormDataWithFileAPIURL,
    saveServiceFormDataWithoutFileAPIURL,
    getServiceFormDataAPIURL,
    deleteServiceFormDataAPIURL,
    viewFileAPIURL,
    updateServiceFormStatusAPIURL
} from '../Service/UserErrorSubmissionAPIURL';

import { getRemarkDataByServiceFormIdAPIURL } from '../Service/allEnquiryAPIURL';

const useStyles = makeStyles({
    caption: {
        padding: 0,
        fontWeight: 600,
        fontSize: "12px"
    },
    toolbar: {
        fontSize: "12px",
        fontWeight: 600
    },
    selectLabel: {
        fontSize: "12px",
        fontWeight: 500,
        margin: "-10px",
        padding: "0"
    },
    select: {
        fontSize: "12px",
        margin: "0",
        padding: "0"
    },
    displayedRows: {
        fontSize: "12px",
        margin: "-22px",
        padding: "0"
    },
    menuItem: {
        fontSize: "12px",
        fontWeight: 500,
    }
});
const UserErrorSubmissionForm = () => {

    const authUser = useSelector((state) => state.user.value)
    // console.log(authUser)
    const dispatch = useDispatch();
    //Note: This below states are declared for Save & Update(Disabled/unDisabled)
    const saveBtnStyle = "btn btn-primary btn-sm";
    const updateBtnStyle = "btn btn-primary btn-sm";
    const displayNoneBtnStyle = "d-none";
    const [saveBtn, setSaveBtn] = useState(saveBtnStyle);
    const [updateBtn, setUpdateBtn] = useState(displayNoneBtnStyle);
    const [saveBtnDisabled, setSaveBtnDisabled] = useState(false);
    const [updateBtnDisabled, setUpdateBtnDisabled] = useState(true);

    //Table Start

    const [TableHeading, setTableHeading] = useState([]);

    const TableHeading1 = [
        { label: 'ID', key: 'id' },
        { label: `Date`, key: 'raisedDate' },
        { label: `Module`, key: 'moduleName' },
        { label: `Form`, key: 'formName' },
        { label: 'View', value: 'type', isAction: true },
        { label: 'Status', value: 'status', isAction: true },
        { label: 'Delete', value: 'delete', isAction: true }];

    const TableHeading2 = [
        { label: 'ID', key: 'id' },
        { label: `Date`, key: 'raisedDate' },
        { label: `Module`, key: 'moduleName' },
        { label: `Branch Name`, key: 'branchName' },
        { label: `Form`, key: 'formName' },
        { label: 'View', value: 'type', isAction: true },
        { label: 'Status', value: 'status', isAction: true },
        { label: 'Delete', value: 'delete', isAction: true }];

    const classes = useStyles();
    const [isAscending, setAscending] = useState(false);
    const [sortedBy, setSortedBy] = useState(null);
    const [searchTerm, setSearchTerm] = useState("")
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10)

    const onPageChange = (event, nextPage) => {
        setPage(nextPage);
    }
    const onRowsPerPageChange = (e) => {
        setRowsPerPage(parseInt(e.target.value));
        setPage(0);
    }

    const onSort = (key, isAction) => {
        if (!isAction) {
            setSortedBy(key)
            if (isAscending) setFilteredData([...data.sort((a, b) => a[key] < b[key] ? 1 : -1)])
            else setFilteredData([...data.sort((a, b) => a[key] > b[key] ? 1 : -1)])
            setAscending(!isAscending)
        }
    }

    useEffect(() => {
        setFilteredData(
            data.filter(
                (item) =>
                    item.raisedDate.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    item.moduleName.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    item.formName.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    item.id.toString().toLowerCase().includes(searchTerm.toLowerCase())
            )
        );
    }, [searchTerm]);

    //Table End
    //Main Data

    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);

    const [remarkData, setRemarkData] = useState([]);

    const [moduleData, setModuleData] = useState([]);
    const [allFormData, setAllFormData] = useState([]);
    const [formData, setFormData] = useState([]);

    const [moduleObj, setModuleObj] = useState(null);
    const [formObj, setFormObj] = useState(null);
    const [file, setFile] = useState(null);
    const [details, setDetails] = useState("");

    const [deleteId, setDeleteId] = useState("");

    const [viewData, setViewData] = useState({
        "date": "",
        "moduleName": "",
        "formName": "",
        "details": "",
        "fileName": "",
        "status": ""
    })

    //Review
    const [remarks, setRemarks] = useState("");
    const [remarkOpt, setRemarkOpt] = useState("");
    const [remarkId, setRemarkId] = useState("");

    const getData = async () => {
        await axios
            .get(getAllModuleDataAPIURL)
            .then((res) => {
                if (res.data !== null) {
                    setModuleData(res.data);
                }
            })
            .catch((err) => {
                console.log(err)
            })

        await axios
            .get(getAllFormDataAPIURL)
            .then((res) => {
                if (res.data !== null) {
                    setAllFormData(res.data);
                    // console.log(JSON.stringify(res.data));
                }
            })
            .catch((err) => {
                console.log(err)
            })

        getTableData();

    }

    const getTableData = async () => {
        if (authUser.branchId == 50) {
            setTableHeading(TableHeading2)
        }
        else {
            setTableHeading(TableHeading1)
        }
        await axios
            .get(`${getServiceFormDataAPIURL}?roleId=${authUser.roleId}&bid=${authUser.branchId}&sid=${authUser.deptId}`)
            .then((res) => {
                if (res.data !== null) {
                    setData(res.data);
                    setFilteredData(res.data);
                    // console.log(JSON.stringify(res.data));
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const getRemarkData = async (id) => {
        await axios.get(`${getRemarkDataByServiceFormIdAPIURL}?formId=${id}&userType=1`)
            .then((res) => {
                if (res.data !== null) {
                    setRemarkData(res.data)
                    // console.log(JSON.stringify(res.data))
                }
            })
            .catch((err) => {
                console.log(err);
            })
    }

    useEffect(() => {

        getData();

    }, [authUser])

    const state = {
        button: 1
    };

    const onSubmit = e => {
        e.preventDefault();
        if (state.button === 1) {
            saveFun();
        }
        if (state.button === 2) {
            updateFun();
        }
    };

    const saveFun = async () => {
        // moduleId,formId,description,file,roleId,bid,sid
        // moduleId,formId,description,userId,branchId,sectionId

        const formData = new FormData();
        formData.append("moduleId", moduleObj.id);
        formData.append("formId", formObj.id);
        formData.append("description", details);
        formData.append("file", file);
        formData.append("roleId", authUser.roleId);
        formData.append("bid", authUser.branchId);
        formData.append("sid", authUser.deptId);

        const saveObj = {
            "moduleId": moduleObj.id,
            "formId": formObj.id,
            "description": details,
            "userId": authUser.roleId,
            "branchId": authUser.branchId,
            "sectionId": authUser.deptId
        }
        if (file !== null) {
            await axios.post(saveServiceFormDataWithFileAPIURL, formData)
                .then((res) => {
                    if (res.data === "SAVED") {
                        toast.success("Saved Sucessfully.");
                        getTableData();
                        clearForm();
                    }
                })
                .catch(err => console.log(err))
        }
        else {
            await axios.post(saveServiceFormDataWithoutFileAPIURL, saveObj)
                .then((res) => {
                    if (res.data === "SAVED") {
                        toast.success("Saved Sucessfully.");
                        getTableData();
                        clearForm()
                    }
                })
                .catch(err => console.log(err))
        }

    }

    const updateFun = async () => {


    }

    const updateTask = async (e) => {
        e.preventDefault();
        let userType = 1;

        await axios.post(`${updateServiceFormStatusAPIURL}?statusId=${remarkOpt}&id=${remarkId}&remark=${remarks}&userType=${userType}`)
            .then((res) => {
                if (res.data === "SAVED") {
                    toast.success("Sucessfully Saved")
                    document.getElementById("closeBtn").click();
                    getTableData();
                }
                else {
                    toast.error("Something went wrong")
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const DeleteData = async (id) => {
        await axios.delete(`${deleteServiceFormDataAPIURL}?id=${id}`)
            .then((response) => {
                if (response.data === "DELETED") {
                    getTableData();
                    toast.error("Deleted sucessfully");
                }
            });

    }

    const clearForm = () => {
        setModuleObj(null);
        setFormObj(null);
        setFormData([]);
        setFile(null);
        setDetails("")
        document.getElementById("file").value = null;

    }

    const [age, setAge] = React.useState('');

    const handleChange = (event) => {
        setAge(event.target.value);
    };
    return (
        <>
            <TitleLabel titleId="Customer Support" />
            <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 20px #ABB2B9", marginTop: "16px", minHeight: "200px" }}>
                <form onSubmit={onSubmit}>
                    <div className="form-data mt-1">

                        <div className="row">
                            <label htmlFor="lastname3" className="col-sm-1" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>Module:</label>
                            <div className="col-sm-3">
                                <Autocomplete
                                    value={moduleObj}
                                    id="combo-box-demo"
                                    options={moduleData}
                                    onChange={(event, newValue) => {
                                        setFormData([])
                                        setFormObj(null)
                                        setModuleObj(newValue);
                                        if (newValue !== null) {
                                            setFormData(allFormData.filter(e => e.moduleId === newValue.id))
                                        }
                                        else {
                                            setFormData([])
                                            setFormObj(null)
                                        }
                                    }}
                                    PaperComponent={({ children }) => (
                                        <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                    )}
                                    style={{ fontSize: '14px' }}
                                    getOptionLabel={option => option.moduleName}
                                    size="small"
                                    renderInput={params => (
                                        <TextField {...params} margin='dense' label="Select Module" variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                    )}
                                />
                            </div>

                            <label htmlFor="lastname3" className="col-sm-1" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>Form:</label>
                            <div className="col-sm-3">
                                <Autocomplete
                                    value={formObj}
                                    id="combo-box-demo"
                                    options={formData}
                                    onChange={(event, newValue) => {
                                        setFormObj(newValue)
                                    }}
                                    PaperComponent={({ children }) => (
                                        <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                    )}
                                    style={{ fontSize: '14px' }}
                                    getOptionLabel={option => option.formName}
                                    size="small"
                                    renderInput={params => (
                                        <TextField {...params} margin='dense' label="Select Form" variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                                    )}
                                />
                            </div>

                            <label htmlFor="lastname3" className="col-sm-1" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>Document:</label>
                            <div className="col-sm-3">
                                <input type="file" id="file" onChange={(e) => setFile(e.target.files[0])} className='form-control form-control-sm mt-3' />
                            </div>

                        </div>

                        <div className="row">
                            <label htmlFor="lastname3" className="col-sm-1" style={{ fontSize: "15px", fontWeight: "600", marginTop: "16px" }}>Details:</label>
                            <div className='col-sm-11'>
                                <TextField
                                    fullWidth
                                    value={details}
                                    onChange={(e) => setDetails(e.target.value)}
                                    multiline
                                    rows={4}
                                    autoComplete='off'
                                    size='small'
                                    id="outlined-basic"
                                    label="Details"
                                    variant="outlined"
                                    margin='dense'
                                    required
                                    InputLabelProps={{ style: { fontSize: 14, marginRight: '1px' } }}
                                />
                            </div>
                        </div>

                        <div className="col-sm-12 mt-3">
                            <div className="offset-sm-1">
                                <button type="submit" disabled={saveBtnDisabled} onClick={() => (state.button = 1)} className={saveBtn}>Save</button>
                                <button type="submit" disabled={updateBtnDisabled} onClick={() => (state.button = 2)} className={updateBtn} >Update</button>
                                <button type="button" onClick={() => { clearForm() }} className="btn btn-danger btn-sm mx-2">Cancel</button>
                            </div>
                        </div>
                    </div>
                </form>
                <hr />

                <div className='mt-4'>
                    <div className='row mt-1'>
                        <div>
                            <input
                                className='form-control form-control-sm'
                                type="text"
                                style={{ width: "250px", display: "block", float: "right", marginBottom: "6px", border: "1px solid #C2C1C1" }}
                                placeholder="Search Here"
                                value={searchTerm}
                                onChange={(event) => { setSearchTerm(event.target.value); }}
                            />
                        </div>
                    </div>

                    <div className='table-responsive' >
                        <table className="table table-bordered">
                            <thead className="table-Default">
                                <tr>
                                    {TableHeading.map((item, index) => {
                                        return (
                                            <th style={{ fontSize: "14px", fontWeight: "600" }} onClick={() => onSort(item.key, item.isAction)} key={index} scope="col">{`${item.label} ${sortedBy === item.key ? isAscending ? '↑' : '↓' : ''}`}</th>
                                        )
                                    })}
                                </tr>
                            </thead>
                            <tbody style={{ borderTop: "2px solid #464de4", borderBottom: "2px solid #464de4" }}>
                                {filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((item, index) => {
                                        // console.log(item.userFlag)
                                        return (
                                            <React.Fragment key={index}>
                                                <tr style={(item.userFlag === 1) ? { fontWeight: 600, backgroundColor: "#F4ECF7" } : { fontWeight: 500 }}>
                                                    <td style={{ fontSize: "15px" }}>{item.id}</td>
                                                    <td style={{ fontSize: "15px" }}>{item.raisedDate}</td>
                                                    {
                                                        (authUser.branchId === 50) ? <td style={{ fontSize: "13px" }}>{item.branchId + " - " + item.branchName}</td> : ""
                                                    }
                                                    <td style={{ fontSize: "15px" }}>{item.moduleName}</td>
                                                    <td style={{ fontSize: "15px" }}>{item.formName}</td>

                                                    <td style={{ fontSize: "15px", fontWeight: "500" }}>
                                                        <Tooltip title="View">
                                                            <span className='btn btn-primary btn-sm mx-1 p-1'
                                                                data-bs-toggle="modal" data-bs-target="#exampleModalView"
                                                                onClick={() => {
                                                                    const TempData = {
                                                                        "date": item.raisedDate,
                                                                        "moduleName": item.moduleName,
                                                                        "formName": item.formName,
                                                                        "details": item.description,
                                                                        "fileName": item.fileName,
                                                                        "status": item.statusId

                                                                    }
                                                                    setViewData(TempData);
                                                                    getRemarkData(item.id);
                                                                    let updateData = data;
                                                                    updateData[index] = ({ ...item, userFlag: 0 })
                                                                    setFilteredData(updateData);
                                                                    setData(updateData);
                                                                }}
                                                            >
                                                                <FontAwesomeIcon icon={faEye} />
                                                            </span>
                                                        </Tooltip>
                                                    </td>
                                                    <td style={{ fontSize: "15px", fontWeight: "500" }}>
                                                        {(() => {
                                                            if (item.statusId === 1) {
                                                                return "Pending"
                                                            }
                                                            else if (item.statusId === 2) {
                                                                return "In-Process"
                                                            }
                                                            else if (item.statusId === 3) {
                                                                return (
                                                                    <button
                                                                        type="button"
                                                                        className='btn btn-sm btn-primary p-1'
                                                                        style={{ fontSize: "12px" }}
                                                                        data-bs-toggle="modal" data-bs-target="#exampleModalUpdate"
                                                                        onClick={() => setRemarkId(item.id)}
                                                                    >
                                                                        Completed
                                                                    </button>
                                                                )
                                                            }
                                                            else if (item.statusId === 4) {
                                                                return "Closed"
                                                            }
                                                            else if (item.statusId === 5) {
                                                                return "Rejected"
                                                            }
                                                            else {
                                                                return ""
                                                            }
                                                        })()}
                                                    </td>

                                                    <td style={{ fontSize: "15px", fontWeight: "500" }}>
                                                        <Tooltip title="Delete">
                                                            <button type='button' className='btn btn-danger btn-sm mx-1 p-1'
                                                                data-bs-toggle="modal" data-bs-target="#exampleModal"
                                                                onClick={() => setDeleteId(item.id)}
                                                                disabled={(item.statusId === 1) ? false : true}
                                                            >
                                                                <FontAwesomeIcon icon={faTrash} />
                                                            </button>
                                                        </Tooltip>
                                                    </td>
                                                </tr>
                                            </React.Fragment>
                                        )
                                    })}
                            </tbody>
                            <tfoot style={{ padding: "0px" }}>
                                <tr>
                                    <TablePagination
                                        style={{ padding: "0px" }}
                                        rowsPerPageOptions={[10, 25, 50, 100]}
                                        count={data.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onRowsPerPageChange={onRowsPerPageChange}
                                        onPageChange={onPageChange}
                                        labelRowsPerPage="Rows Per Page :"
                                        classes={{ menuItem: classes.menuItem, displayedRows: classes.displayedRows, selectIcon: classes.selectIcon, toolbar: classes.toolbar, caption: classes.caption, selectLabel: classes.selectLabel, select: classes.select }}
                                        backIconButtonProps={{ "aria-label": "Previous Page" }}
                                        nextIconButtonProps={{ "aria-label": "Next Page" }} />
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>

            {/* View Modal */}
            <div className="modal fade" id="exampleModalView" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content  p-0">
                        <div className="modal-header  p-1" style={{ backgroundColor: "#E3C6EF" }}>
                            <h4 className="modal-title" id="exampleModalLabel"></h4>
                        </div>
                        <div className="modal-body p-2" style={{ fontSize: "15px" }}>
                            <div className="row mt-1">
                                <div className='col-2 col-sm-2' style={{ fontWeight: 600, fontSize: "15px" }}>Date: </div>
                                <div className='col col-sm-8' style={{ fontWeight: 500, fontSize: "15px" }}>{viewData.date}</div>
                            </div>

                            <div className="row mt-1">
                                <div className='col-2 col-sm-2' style={{ fontWeight: 600, fontSize: "15px" }}>Module: </div>
                                <div className='col col-sm-8' style={{ fontWeight: 500, fontSize: "15px" }}>{viewData.moduleName}</div>
                            </div>

                            <div className="row mt-1">
                                <div className='col-2 col-sm-2' style={{ fontWeight: 600, fontSize: "15px" }}>Form: </div>
                                <div className='col col-sm-8' style={{ fontWeight: 500, fontSize: "15px" }}>{viewData.formName}</div>
                            </div>

                            <div className="row mt-1">
                                <div className='col-2 col-sm-2' style={{ fontWeight: 600, fontSize: "15px" }}>Status: </div>
                                <div className='col col-sm-8' style={{ fontWeight: 500, fontSize: "15px" }}>{(viewData.status === 1) ? "Pending" : ""}</div>
                            </div>
                            {
                                (viewData.fileName !== "") ?
                                    <div className="row mt-1">
                                        <div className='col-2 col-sm-2' style={{ fontWeight: 600, fontSize: "15px" }}>File: </div>
                                        <div className='col col-sm-8' style={{ fontWeight: 500, fontSize: "15px" }}>
                                            <span
                                                onClick={() => window.open(`${viewFileAPIURL}${viewData.fileName}`)}
                                                className='badge rounded-pill bg-danger' style={{ cursor: "pointer" }}>View File</span>
                                        </div>
                                    </div>
                                    : ""

                            }

                            <div className="row mt-1">
                                <div className='col-sm-2' style={{ fontWeight: 600, fontSize: "15px" }}>Details: </div>
                                <div className='col-sm-10 text-wrap text-break' style={{ fontWeight: 500, fontSize: "15px" }}>
                                    <p className="text-wrap text-break">{viewData.details}</p>
                                </div>
                            </div>
                            {
                                (remarkData !== null && remarkData.length !== 0) ?
                                    <div className="row mt-1">
                                        <div className='col-sm-2' style={{ fontWeight: 600, fontSize: "15px" }}>Remarks: </div>
                                        <div className='col-sm-12' style={{ fontWeight: 500, fontSize: "15px" }}>
                                            {
                                                remarkData.map((item, index) => {
                                                    return (
                                                        (item.userType === 1) ?
                                                            <div className='container' key={index}>
                                                                <div style={{ fontSize: "10px", textAlign: "right", width: "100%", display: "inline-block" }}>{item.remarkDate}</div>
                                                                <div className='m-0 p-1' style={{ backgroundColor: "#EBDEF0", borderRadius: "8px 0px 0px 8px", width: "85%", float: "right" }}>
                                                                    <div className='col-sm-12' style={{ fontSize: "14px", textAlign: "left" }}>{item.remark}</div>
                                                                </div>
                                                            </div>
                                                            :
                                                            <div className='container' key={index}>
                                                                <div style={{ fontSize: "10px", textAlign: "left", width: "100%", display: "inline-block" }}>{item.remarkDate}</div>
                                                                <div className='m-0 p-1' style={{ backgroundColor: "#D5F5E3", borderRadius: "0px 8px 8px 0px", width: "85%", float: "left" }}>
                                                                    <div className='col-sm-12' style={{ fontSize: "14px", textAlign: "left" }}>{item.remark}</div>
                                                                </div>
                                                            </div>

                                                    )
                                                })
                                            }

                                        </div>
                                    </div>
                                    : ""
                            }
                        </div>
                        <div className="modal-footer p-0">
                            <button type="button" className="btn btn-danger btn-sm" data-bs-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>

            {/* update task modal */}
            <div className="modal fade" id="exampleModalUpdate" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content  p-0">
                        <div className="modal-header  p-1" style={{ backgroundColor: "#FFC433" }}>
                            <h4 className="modal-title" id="exampleModalLabel"></h4>
                        </div>
                        <div className="modal-body p-4" style={{ fontSize: "13px", fontWeight: 500 }}>
                            Hello, <br /><br />
                            Your problem related to the particular task has been solved by our technical team, Please check it.<br />

                            If there is still problem, then select option "Pending" and add remark otherwise select "Close" and save it. <br /><br />
                            Thankyou. <br /><br />

                            <form onSubmit={updateTask}>
                                <div className="row">
                                    <select value={remarkOpt} onChange={(e) => setRemarkOpt(e.target.value)} className='form-select form-select-sm' required>
                                        <option value="">Select Option</option>
                                        <option value="1">Pending</option>
                                        <option value="4">Close</option>
                                    </select>
                                </div>
                                {
                                    (remarkOpt === "1") ?
                                        <div className="row">
                                            <TextField
                                                fullWidth
                                                value={remarks}
                                                onChange={(e) => setRemarks(e.target.value)}
                                                multiline
                                                rows={3}
                                                autoComplete='off'
                                                size='small'
                                                id="outlined-basic"
                                                label="Remarks"
                                                variant="outlined"
                                                margin='dense'
                                                required
                                                InputLabelProps={{ style: { fontSize: 14, marginRight: '1px' } }}
                                            />
                                        </div>
                                        : ""
                                }

                                <div className="mt-2">
                                    <button type="submit" className='btn btn-sm btn-primary'>SAVE</button>
                                    <button type="button" id='closeBtn' className="btn btn-danger btn-sm mx-2" data-bs-dismiss="modal">Close</button>
                                </div>
                            </form>

                        </div>
                        {/* <div className="modal-footer p-0">
                            <button type="button" className="btn btn-danger btn-sm" data-bs-dismiss="modal">Close</button>
                        </div> */}
                    </div>
                </div>
            </div>

            <ToastContainer position="top-right" theme="colored" />
            <DeleteModalBox deleteId={deleteId} DeleteData={DeleteData} />
        </>
    )
}

export default UserErrorSubmissionForm