import React, { useState, useEffect } from 'react';
import TitleLabel from "../../CommonComponent/TitleLabel";
import axios from 'axios';
import Paper from "@mui/material/Paper";
import TextField from '@mui/material/TextField';
import Autocomplete from "@mui/material/Autocomplete";
import { ToastContainer, toast } from 'react-toastify';
import { useSelector } from "react-redux";
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { getClassWithDiv } from '../../Administration/Services/GeneralRegisterAPIURL';
import { getMasterReportAPIURL } from '../../Masters/Services/MasterReportAPIURL';
import Loader from '../../CommonComponent/Loader';
import { printAdmFormSaleClassDivWiseAPIURL, printAdmFormSaleReportAPIURL } from '../Services/AdmisionReportAPIURL';
import { Checkbox, FormControlLabel } from '@mui/material';

const AdmissionFormSaleReport = () => {

    const authUser = useSelector((state) => state.user.value);
    const titleId = "Admission Form Sale";

    const [loaderOption, setLoaderOption] = useState(false);
    const [view, setView] = useState(1);
    const [option, setOption] = useState(1);
    const [data, setData] = useState([]);
    const [classDivObj, setClassDivObj] = useState(null);
    const [withPhNoChkBox, setWithPhNoChkBox] = useState(0);

    const HandleWithPhNoCheckbox = e => {
        setWithPhNoChkBox(e.target.checked);
        if (e.target.checked == true) {
            setWithPhNoChkBox(1);
        }
        else if (e.target.checked == false) {
            setWithPhNoChkBox(0);
        }
    }

    useEffect(() => {
        getData();
    }, [authUser]);

    const getData = async () => {
        if (authUser.deptId != null && authUser.branchId != null) {
            await axios(`${getClassWithDiv}?sid=${authUser.deptId}&bid=${authUser.branchId}`)
                .then(res => {
                    setData(res.data);
                })
        }
    }

    const generateAdmFormSaleClassDivWiseReport = async () => {
        setLoaderOption(true);
        // console.log(`${printAdmFormSaleClassDivWiseAPIURL}?langId=${authUser.branchMedium}&divId=${classDivObj.divisionId}&acYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&phNo=${withPhNoChkBox}`)
        await axios.get(`${printAdmFormSaleClassDivWiseAPIURL}?langId=${authUser.branchMedium}&divId=${classDivObj.divisionId}&acYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&phNo=${withPhNoChkBox}`)
            .then((response) => {
                if (response.data == "NOTFOUND") {
                    //console.log(response.data)
                    setLoaderOption(false);
                    toast.warn("Data not exists.")
                }
                else if (response.data != null) {
                    //console.log(response.data)   

                    window.open(`${getMasterReportAPIURL}${response.data}`);
                    setLoaderOption(false);
                }
            })
            .catch(error => {
                setLoaderOption(false);
                toast.error("Something went wrong, try after sometime.")
                console.log("Category Report failed: " + error)
            })
    }

    const generateAdmFormSaleReport = async () => {
        setLoaderOption(true);
        // console.log(`${printAdmFormSaleReportAPIURL}?langId=${authUser.branchMedium}&acYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&phNo=${withPhNoChkBox}`)
        await axios.get(`${printAdmFormSaleReportAPIURL}?langId=${authUser.branchMedium}&acYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}&phNo=${withPhNoChkBox}`)
            .then((response) => {
                if (response.data == "NOTFOUND") {
                    //console.log(response.data)
                    setLoaderOption(false);
                    toast.warn("Data not exists.")
                }
                else if (response.data != null) {
                    //console.log(response.data)   

                    window.open(`${getMasterReportAPIURL}${response.data}`);
                    setLoaderOption(false);
                }
            })
            .catch(error => {
                setLoaderOption(false);
                toast.error("Something went wrong, try after sometime.")
                console.log("Category Report failed: " + error)
            })
    }

    const getAdmissionForSaleReport = e => {
        e.preventDefault();
        if (option == 1) {
            generateAdmFormSaleReport();
        }
        else if (option == 2) {
            generateAdmFormSaleClassDivWiseReport();
        }
    }

    if (loaderOption == true) {
        return <Loader />
    }
    else {
        return (
            <>
                <TitleLabel titleId={titleId} />
                <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: "350px" }}>
                    <form onSubmit={getAdmissionForSaleReport}>
                        <div className='row'>
                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Report Option :</label>
                            <div className='col-sm-3'>
                                <FormControl fullWidth margin='dense'>
                                    <InputLabel id='demo-simple-select-label' style={{ fontSize: '14px' }}>Report Option</InputLabel>
                                    <Select
                                        size='small'
                                        required
                                        fullWidth
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={option}
                                        label="Report Option"
                                        onChange={(e) => {
                                            setOption(e.target.value);
                                            setClassDivObj(null);
                                            setWithPhNoChkBox(0);
                                        }}
                                    >
                                        <MenuItem style={{ backgroundColor: "#F4ECF7", fontWeight: "550", fontSize: "14px" }} value={1}>All Students</MenuItem>
                                        <MenuItem style={{ backgroundColor: "#F4ECF7", fontWeight: "550", fontSize: "14px" }} value={2}>Class-Division Wise</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                        </div>

                        {(() => {
                            if (option == 2) {
                                return (
                                    <>

                                        <div className='row my-2'>
                                            <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }}>Class-Division :</label>
                                            <div className='col-sm-3'>
                                                <Autocomplete
                                                    id="combo-box-demo"
                                                    PaperComponent={({ children }) => (
                                                        <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                                                    )}
                                                    options={data}
                                                    style={{ fontSize: '14px' }}
                                                    getOptionLabel={option => (authUser.branchMedium == 1) ? option.classNameMr + " " + option.divisionNameMr : option.classNameEn + " " + option.divisionNameEn}
                                                    size="small"
                                                    value={classDivObj}
                                                    onChange={(event, newValue) => {
                                                        setClassDivObj(newValue);
                                                        setWithPhNoChkBox(0);
                                                    }}
                                                    renderInput={params => (
                                                        <TextField {...params} margin='dense' label="Select Class-Division" variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required={(option == 2) ? true : false} />
                                                    )}
                                                />
                                            </div>
                                        </div>
                                    </>
                                )
                            }
                        })()}

                        <div className="row mt-2">
                            <div className='col-sm-4 offset-sm-2'>
                                <FormControlLabel control={<Checkbox checked={withPhNoChkBox} onChange={HandleWithPhNoCheckbox} />} sx={{ '.MuiFormControlLabel-label': { fontWeight: 550, fontSize: "15px", marginTop: "2px" } }} label="With Phone No." />
                            </div>
                        </div>

                        <div className="row mt-1">
                            <div className="col-sm-4 offset-sm-2">
                                <button type="submit" className="btn btn-primary btn-sm">Show</button>
                            </div>
                        </div>
                    </form>
                </div>
                {/* <ToastContainer position="top-right" theme="colored" /> */}
            </>
        )
    }

}

export default AdmissionFormSaleReport