import React, { useState, useEffect } from 'react';
import TitleLabel from "../../../CommonComponent/TitleLabel";
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { useSelector } from "react-redux";
import Paper from "@mui/material/Paper";
import TextField from '@mui/material/TextField';
import Autocomplete from "@mui/material/Autocomplete";
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import Loader from '../../../CommonComponent/Loader';
import { getSchoolDataAPIURL } from '../../../Administration/Services/TransferStudentsAPIURL';
import { getSchoolListForEndowment } from '../Services/EndowmentMasterAPIURL';
import makeStyles from "@mui/styles/makeStyles";
import { getStudentListEndowWiseAPIURL, printSansthaAwardDistributionReportAPIURL, printSansthaAwardDistributionSchoolReportAPIURL, printStudentWiseAwardDistForStudentAPIURL, printStudWiseSansthaAeardDisForSansthaAPIURL, printStudWiseSansthaAeardDisForSchoolAPIURL } from '../Services/EndowmentReportAPIURL';
import { getExportedReportAPIURL } from '../../../CommonServices/APIURL';
import PrintIcon from '@mui/icons-material/Print';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
  select: {
    maxHeight: 160,
    "& ul": {
      backgroundColor: "#F4ECF7",
      //maxHeight: 160,
    },
    "& li": {
      // backgroundColor: "#F4ECF7", fontWeight: "550", fontSize: "14px" 
      fontSize: "14px",
      fontWeight: "550"
    },
  },

}));

const StudentWiseSansthaAwardDistReport = () => {
  const classes = useStyles();
  const authUser = useSelector((state) => state.user.value);
  const titleId = "Studentwise Award Distribution";

  const [loaderOption, setLoaderOption] = useState(false);
  const [optionId, setOptionId] = useState(1);
  const [schoolData, setSchoolData] = useState([]);
  const [schoolObj, setSchoolObj] = useState(null);
  const [studentData, setStudentData] = useState([]);
  const [studentObj, setStudentObj] = useState(null);

  useEffect(() => {
    getData();
  }, [authUser])


  const getData = async () => {
    await axios.get(`${getSchoolListForEndowment}`)
      .then(res => {
        setSchoolData(res.data);
      })

    if (authUser.sessionYear != '' && authUser.deptId != null && authUser.branchId != null) {
      // console.log(`${getStudentListEndowWiseAPIURL}?year=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}`)
      await axios.get(`${getStudentListEndowWiseAPIURL}?year=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}`)
        .then(res => {
          setStudentData(res.data);
        })
    }

    // if (authUser.branchId != null) {
    //   if (authUser.branchId == 50) {
    //     setOptionId(1);
    //   }
    //   else {
    //     setOptionId(3);
    //   }
    // }
  }

  const printSansthaAwardDistReport = async (e) => {
    e.preventDefault();
    setLoaderOption(true);
    if (optionId == 3) {
      // console.log(`${printStudentWiseAwardDistForStudentAPIURL}?sessionYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}`)
      await axios.get(`${printStudentWiseAwardDistForStudentAPIURL}?sessionYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}`)
        .then((response) => {
          if (response.data == "NOTFOUND") {
            //console.log(response.data)  
            setLoaderOption(false);
            toast.warn("Data not found.")
          }
          else if (response.data != null) {
            //console.log(response.data)                    
            window.open(`${getExportedReportAPIURL}${response.data}`);
            setLoaderOption(false);
          }
        })
        .catch(error => {
          toast.error("Something went wrong, try after sometime.")
          console.log("Endowment Report failed: " + error)
        })
    }
    else {
      if (authUser.branchId == 50) {
        // console.log(`${printStudWiseSansthaAeardDisForSansthaAPIURL}?sessionYear=${authUser.sessionYear}&endowmentId=${(optionId == 2) ? schoolObj.endowmentId : 0}`)
        await axios.get(`${printStudWiseSansthaAeardDisForSansthaAPIURL}?sessionYear=${authUser.sessionYear}&endowmentId=${(optionId == 2) ? schoolObj.endowmentId : 0}`)
          .then((response) => {
            if (response.data == "NOTFOUND") {
              //console.log(response.data)  
              setLoaderOption(false);
              toast.warn("Data not found.")
            }
            else if (response.data != null) {
              //console.log(response.data)                    
              window.open(`${getExportedReportAPIURL}${response.data}`);
              setLoaderOption(false);
            }
          })
          .catch(error => {
            toast.error("Something went wrong, try after sometime.")
            console.log("Endowment Report failed: " + error)
          })
      }
      else {
        // console.log(`${printStudWiseSansthaAeardDisForSchoolAPIURL}?sessionYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}`)
        await axios.get(`${printStudWiseSansthaAeardDisForSchoolAPIURL}?sessionYear=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}`)
          .then((response) => {
            if (response.data == "NOTFOUND") {
              //console.log(response.data)  
              setLoaderOption(false);
              toast.warn("Data not found.")
            }
            else if (response.data != null) {
              //console.log(response.data)                    
              window.open(`${getExportedReportAPIURL}${response.data}`);
              setLoaderOption(false);
            }
          })
          .catch(error => {
            toast.error("Something went wrong, try after sometime.")
            console.log("Endowment Report failed: " + error)
          })
      }
    }
  }


  return (
    <>
      {
        (loaderOption == true)
          ?
          <Loader />
          :
          <div>
            <TitleLabel titleId={titleId} />

            <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 5px 15px grey", marginTop: "16px", minHeight: '300px' }}>
              <form onSubmit={printSansthaAwardDistReport}>
                <div className='row my-2'>
                  <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "10px" }}>Report Option :</label>
                  <div className='col-sm-3'>
                    <FormControl fullWidth >
                      <InputLabel id='demo-simple-select-label' size="small" style={{ fontSize: '14px' }}>Select Report Option</InputLabel>
                      <Select
                        MenuProps={{ classes: { paper: classes.select } }}
                        size='small'
                        required
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={optionId}
                        margin='dense'
                        label="Select Report Option"
                        onChange={(e) => {
                          setOptionId(e.target.value);
                        }}
                      >

                        <MenuItem value={1}>All</MenuItem>
                        <MenuItem value={2} hidden={(authUser.branchId == 50) ? false : true}>School Wise</MenuItem>
                        <MenuItem value={3}>Student Wise</MenuItem>
                      </Select>
                    </FormControl>

                  </div>
                </div>

                {(() => {
                  if (optionId == 2) {
                    return (
                      <>
                        <div className="row my-2">
                          <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >School :</label>
                          <div className='col-sm-4'>
                            <Autocomplete
                              id="combo-box-demo"
                              options={schoolData}
                              PaperComponent={({ children }) => (
                                <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                              )}
                              value={schoolObj}
                              onChange={(event, newValue) => {
                                setSchoolObj(newValue);
                              }}
                              style={{ fontSize: '14px' }}
                              getOptionLabel={option => option.endowmentId + " . " + option.schoolName}
                              size="small"
                              renderInput={params => (
                                <TextField {...params} margin='dense' label="Select School" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                              )}
                            />
                          </div>
                        </div>
                      </>
                    )
                  }
                  // else if (optionId == 3) {
                  //   return (
                  //     <>
                  //       <div className="row my-2">
                  //         <label className="col-sm-2" style={{ fontSize: "14px", fontWeight: "600", marginTop: "16px" }} >Student :</label>
                  //         <div className='col-sm-6'>
                  //           <Autocomplete
                  //             id="combo-box-demo"
                  //             options={studentData}
                  //             PaperComponent={({ children }) => (
                  //               <Paper style={{ background: "#F4ECF7", fontWeight: "600" }}>{children}</Paper>
                  //             )}
                  //             value={studentObj}
                  //             onChange={(event, newValue) => {
                  //               setStudentObj(newValue);
                  //             }}
                  //             style={{ fontSize: '14px' }}
                  //             getOptionLabel={(option) =>
                  //               option.regNo +
                  //               " . " +
                  //               (authUser.branchMedium == 1
                  //                 ? option.studentNameMr + " (" + option.schoolNameMr + ")"
                  //                 : option.studentNameEn + " (" + option.schoolNameEn + ")")
                  //             }

                  //             size="small"
                  //             renderInput={params => (
                  //               <TextField {...params} margin='dense' label="Select Student" autoComplete='off' variant="outlined" InputLabelProps={{ style: { fontSize: 14 } }} required />
                  //             )}
                  //           />
                  //         </div>
                  //       </div>
                  //     </>
                  //   )
                  // }
                })()}

                <div className="col-sm-12 mt-3">
                  <div className="offset-sm-2">
                    <button type="submit" className="btn btn-primary btn-sm">
                      <PrintIcon fontSize="small" /> Print
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>

      }
      <ToastContainer position="top-right" theme="colored" />
    </>
  )
}


export default StudentWiseSansthaAwardDistReport
